import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from '../selectors';
import apis from '@/apis';

export function* getBrEvaluation() {
  try {
    const page = yield select(selectors.mgRate.getRatePage);
    const payload = {
      page: page,
    };
    //call api
    const response = yield call(apis.brokerPageApi.getBrEvaluation, payload);
    const list = response.rltObj.listData;
    yield put(actions.getBrEvaluationSuccess(list));
    yield put(actions.setTotalPage(response.rltObj.totalCnt));
  } catch (e) {
    yield put(actions.getBrEvaluationFailure(e));
  }
}
