/**
 * @module form buiFormCancelAction
 */
 function buiFormCancelAction(buiFormUtil, buiFormCancel) {
	var xStart = buiFormUtil.offsetLeft + buiFormCancel.offsetLeft;
	var yStart = buiFormUtil.offsetTop + buiFormCancel.offsetTop;
	var xEnd = xStart + buiFormCancel.offsetWidth;
	var yEnd = yStart + buiFormCancel.offsetHeight;
	
	if (event.target.parentElement.classList.contains('typed')) {
		if (event.offsetX >= xStart && event.offsetX <= xEnd && event.offsetY >= yStart && event.offsetY <= yEnd) {
			event.target.value = '';	
			event.target.classList.remove('typed');
			event.target.style.removeProperty('cursor');
		}
	}
}


/**
 * @module form buiFormCheckValue
 */
 function buiFormCheckValue(formElem) {
	formElem.value.length > 0 ? formElem.parentElement.classList.add('typed') : formElem.parentElement.classList.remove('typed');
}


export function buiFormFunc(elem) {
	const buiFormElem = elem;
	const buiForm = elem.parentElement	
	let buiFormUtil = buiForm.querySelector('.form-func');

	if (!buiFormUtil) {
		const createFormUtil = document.createElement('span');
		createFormUtil.className = "form-func";
		buiForm.appendChild(createFormUtil);
		buiFormUtil = createFormUtil;
	}

	let buiFormCancel = buiForm.querySelector('.form-cancel');
	
	if (buiFormElem.dataset.buiFormCancel != undefined) {
		if (!buiFormCancel) {
			const createFormCancel = document.createElement('span');
			createFormCancel.className = "form-cancel";
			buiFormUtil.insertBefore(createFormCancel, buiFormUtil.children[0]);
			buiFormCancel = createFormCancel;
		}
	}

	buiFormCheckValue(buiFormElem);
	
	if (buiFormUtil != null) buiFormElem.style.setProperty('padding-right', buiFormUtil.offsetWidth + 'rem');

	elem.addEventListener('input', function() {
		buiFormCheckValue(buiFormElem);
		if (buiFormElem.dataset.buiFormCancel != undefined) buiFormCancelAction(buiFormUtil, buiFormCancel);

		if (buiFormUtil != null) buiFormElem.style.setProperty('padding-right', buiFormUtil.offsetWidth + 'rem');
	}, false);

	elem.addEventListener('mouseout', function() {
		buiFormCheckValue(buiFormElem);
		if (buiFormElem.dataset.buiFormCancel != undefined) buiFormCancelAction(buiFormUtil, buiFormCancel);

		if (buiFormUtil != null) buiFormElem.style.setProperty('padding-right', buiFormUtil.offsetWidth + 'rem');
	}, false);

	elem.addEventListener('focusout', function() {
		buiFormCheckValue(buiFormElem);
		if (buiFormElem.dataset.buiFormCancel != undefined) buiFormCancelAction(buiFormUtil, buiFormCancel);

		if (buiFormUtil != null) buiFormElem.style.setProperty('padding-right', buiFormUtil.offsetWidth + 'rem');
	}, false);

	elem.addEventListener('click', function() {
		if (buiFormElem.dataset.buiFormCancel != undefined) buiFormCancelAction(buiFormUtil, buiFormCancel);
	}, false);
}


/**
 * @module form buiFormCancel
 */
export function buiFormCancel(formElem) {
	if (formElem.readOnly) return;

	// check value
	buiFormCheckValue(formElem);
	formElem.addEventListener('input', function(e) {
		buiFormCheckValue(formElem);
	});

	// form util
	var formUtil = formElem.parentElement.querySelector('.form-func');
	if(!formUtil) {
		formUtil = document.createElement('span');
		formUtil.classList.add('form-func');
		formElem.parentElement.appendChild(formUtil);
	}

	// form cancel
	var formCancel = formElem.parentElement.querySelector('.form-cancel');
	if(!formCancel) {
		formCancel = document.createElement('span');
		formCancel.classList.add('form-cancel');
		formUtil.prepend(formCancel);
	}

	formElem.style.paddingRight = formUtil.offsetWidth + 'rem';

	var xStart = formUtil.offsetLeft + formCancel.offsetLeft;
	var yStart = formUtil.offsetTop + formCancel.offsetTop;
	var xEnd = xStart + formCancel.offsetWidth;
	var yEnd = yStart + formCancel.offsetHeight;

	formElem.addEventListener('mousemove', function(e) {
		if (formElem.classList.contains('typed')) {
			if (e.offsetX >= xStart && e.offsetX <= xEnd && e.offsetY >= yStart && e.offsetY <= yEnd) {
				formElem.style.setProperty('cursor', 'default');
			} else {
				formElem.style.removeProperty('cursor');
			}
		}
	});

	formElem.addEventListener('click', function(e) {
		if (formElem.classList.contains('typed')) {
			if (e.offsetX >= xStart && e.offsetX <= xEnd && e.offsetY >= yStart && e.offsetY <= yEnd) {
				formElem.value = '';
				formElem.classList.remove('typed');
				formElem.style.removeProperty('cursor');
			}
		}
	});
}