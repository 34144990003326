import { actions } from '@/data';
import { STATUS } from '@/data/utils';
import toggle from '@/hocs/toggle';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import useSetTimeCount from '@/pages/Login/hooks/useSetTimeCount';

const Step2 = ({ getValues, setVarificationCode }) => {
  const dispatch = useDispatch();

  const nameValue = getValues('name')?.trim();
  const phoneNumberValue = getValues('phoneNumber')?.trim();

  const [loadingStatus, setLoadingStatus] = useState(STATUS.NOTASKED);
  const [seconds, setSeconds] = useState(300); // 5분은 300초입니다.

  //"resent" "confirm"
  const [codeStatus, setCodeStatus] = useState();

  const [formatTime] = useSetTimeCount({ seconds, setSeconds });

  const resetTimer = () => {
    setSeconds(300);
    setCodeStatus('resent');
  };

  const resendCode = () => {
    if (loadingStatus === STATUS.LOADING) return;
    dispatch(
      actions.auth.sms1({
        name: nameValue,
        cellphone: phoneNumberValue,
        resend: true,
        options: { setLoadingStatus, callback: resetTimer, preventStepChange: true },
      })
    );
  };

  const handleInput = (e) => {
    const value = e.target.value;
    setVarificationCode(value);
  };

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="certificationNumber">
            인증번호 입력
          </label>
          <span className="form-para">인증번호를 입력해 주세요.</span>
        </div>
        <div className="form-body">
          <div className="form-area">
            <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
              <input
                type="text"
                className="form-elem"
                id="certificationNumber"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="4"
                placeholder="인증 번호"
                onChange={handleInput}
                autoComplete="off"
              />
              <span className="form-func">
                <span className="form-cancel"></span>
                <span className="form-data em style-a accent-05">{formatTime(seconds)}</span>
              </span>
            </span>
            <button type="button" className="btn module-a style-b type-line normal-04 large-1x" onClick={resendCode}>
              <span className="btn-text">
                {loadingStatus === STATUS.LOADING ? (
                  <>
                    <CommnonSpinner
                      show={true}
                      // position="relative"
                      // padding="10px"
                      spinnerWrapperBackground={'transparent'}
                      spinnerColor="#6a6a6a"
                    />
                  </>
                ) : (
                  '재요청'
                )}
              </span>
            </button>
          </div>
          <div className="form-noti">
            <p className="para valid">
              {codeStatus === 'resent' && '휴대폰인증번호가 전송되었습니다.'}
              {/* {codeStatus === 'confirm' && '휴대폰인증이 완료되었습니다.'} */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default toggle(Step2);
