import React, { useEffect } from 'react';
import { getSaveIdCookie } from '@/data/cookie';

const useSetSavedId = ({ setSavedIdCookie, setCheckSaveId, setValue }) => {
  useEffect(async () => {
    const savedIdCookie = await getSaveIdCookie();
    setSavedIdCookie(savedIdCookie);
    setCheckSaveId(savedIdCookie ? true : false);
    setValue('id', savedIdCookie);
  }, []);

  return;
};

export default useSetSavedId;
