// import { apiClient } from '@/apis/clients';
import * as axios from 'axios';
import env from '@/resources/env';

export const api = {
  // 추천
  async getShareToken(day = 30) {
    try {
      const result = await axios.get(`${env.SERVER_URL}/v1/share-token/generate/${day}`);
      return result;
    } catch (e) {
      throw Error(e.message);
    }
  },

  async vlidationShareToken(token) {
    try {
      return await axios.get(`${env.SERVER_URL}/v1/share-token/isValid/${token}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
