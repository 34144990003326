import { loading, success, failure } from '@/data/utils';

//매물정보
export const GET_MANAGE_CONTRACT_LIST = 'BROKERPAGE/GET_MANAGE_CONTRACT_LIST';
export const GET_MANAGE_CONTRACT_LIST_LOADING = loading(GET_MANAGE_CONTRACT_LIST);
export const GET_MANAGE_CONTRACT_LIST_SUCCESS = success(GET_MANAGE_CONTRACT_LIST);
export const GET_MANAGE_CONTRACT_LIST_FAILURE = failure(GET_MANAGE_CONTRACT_LIST);
export const GET_MANAGE_CONTRACT_DETAIL = 'BROKERPAGE/GET_MANAGE_CONTRACT_DETAIL';
export const GET_MANAGE_CONTRACT_DETAIL_LOADING = loading(GET_MANAGE_CONTRACT_DETAIL);
export const GET_MANAGE_CONTRACT_DETAIL_SUCCESS = success(GET_MANAGE_CONTRACT_DETAIL);
export const GET_MANAGE_CONTRACT_DETAIL_FAILURE = failure(GET_MANAGE_CONTRACT_DETAIL);
export const RESET_CONTRACT_DETAIL = 'BROKERPAGE/RESET_CONTRACT_DETAIL';
export const SET_MANAGE_CONTRACT_SCROLL_POSITION = 'BROKERPAGE/SET_MANAGE_CONTRACT_SCROLL_POSITION';
export const MANAGE_CONTRACT_MEMO = 'BROKERPAGE/MANAGE_CONTRACT_MEMO';
export const MANAGE_CONTRACT_BROKER_COMMENT = 'BROKERPAGE/MANAGE_CONTRACT_BROKER_COMMENT';

export const CONTRACT_SEARCH = 'BROKERPAGE/CONTRACT_SEARCH';
export const CONTRACT_SEARCH_LOADING = loading(CONTRACT_SEARCH);
export const CONTRACT_SEARCH_SUCCESS = success(CONTRACT_SEARCH);
export const CONTRACT_SEARCH_FAILURE = failure(CONTRACT_SEARCH);

export const SET_CONTRACT_CURRENT_PAGE = 'BROKERPAGE/SET_CONTRACT_CURRENT_PAGE';
export const SET_NEXT_CONTRACT_DATA = 'BROKERPAGE/SET_NEXT_CONTRACT_DATA';
export const SET_TOTAL_CNT = 'BROKERPAGE/SET_TOTAL_CNT';

export const SET_PRE_EX_CONTRACT = 'BROKERPAGE/SET_PRE_EX_CONTRACT';
export const SAVE_PRE_CONTRACT = 'BROKERPAGE/SAVE_PRE_CONTRACT';

export const CONTRACT_FAVORITE_DESIGNATE = 'BROKERPAGE/CONTRACT_FAVORITE_DESIGNATE';
export const CONTRACT_FAVORITE_DE_DESIGNATE = 'BROKERPAGE/CONTRACT_FAVORITE_DE_DESIGNATE';
export const FAKE_SALE_REPORT = 'BROKERPAGE/FAKE_SALE_REPORT';

export const SET_FAKE_SALE_REPORT_RES = 'BROKERPAGE/SET_FAKE_SALE_REPORT_RES';
export const SET_CONTRACT_INFO = 'BROKERPAGE/SET_CONTRACT_INFO';
export const SET_CONTRACT_INFO_MEMBERS = 'BROKERPAGE/SET_CONTRACT_INFO_MEMBERS';
export const SET_CONTRACT_INFO_DETAILS = 'BROKERPAGE/SET_CONTRACT_INFO_DETAILS';
export const SET_CONTRACT_INFO_INI = 'BROKERPAGE/SET_CONTRACT_INFO_INI';

export const CANCEL_CONTRACT = 'BROKERPAGE/CANCEL_CONTRACT';
export const SAVE_CONTRACT_CONTACT_INFO = 'BROKERPAGE/SAVE_CONTRACT_CONTACT_INFO';
export const EDIT_CONTRACT_CONTACT_INFO = 'BROKERPAGE/EDIT_CONTRACT_CONTACT_INFO';
export const DELETE_CONTRACT_CONTACT_INFO = 'BROKERPAGE/DELETE_CONTRACT_CONTACT_INFO';

export const SAVE_CONTRACT_DETAIL_INFO = 'BROKERPAGE/SAVE_CONTRACT_DETAIL_INFO';
export const EDIT_CONTRACT_DETAIL_INFO = 'BROKERPAGE/EDIT_CONTRACT_DETAIL_INFO';
export const DELETE_CONTRACT_DETAIL_INFO = 'BROKERPAGE/DELETE_CONTRACT_DETAIL_INFO';

export const DELETE_CONTRACT = 'BROKERPAGE/DELETE_CONTRACT';

export const CONTRACT_INFO_SAVE = 'BROKERPAGE/CONTRACT_INFO_SAVE';
export const CONTRACT_INFO_EX_SAVE = 'BROKERPAGE/CONTRACT_INFO_EX_SAVE';
export const CONTRACT_INFO_EDIT = 'BROKERPAGE/CONTRACT_INFO_EDIT';
export const CONTRACT_INFO_SAVE_RES = 'BROKERPAGE/CONTRACT_INFO_SAVE_RES';

export const CONTRACT_CHANGE_CATEGORY = 'BROKERPAGE/CONTRACT_CHANGE_CATEGORY';
export const CONTRACT_CHANGE_GUBUN = 'BROKERPAGE/CONTRACT_CHANGE_GUBUN';
export const CONTRACT_CHANGE_TRADETYPE = 'BROKERPAGE/CONTRACT_CHANGE_TRADETYPE';
export const CONTRACT_CHANGE_AREASIZE = 'BROKERPAGE/CONTRACT_CHANGE_AREASIZE';
export const CONTRACT_CHANGE_PRICE = 'BROKERPAGE/CONTRACT_CHANGE_PRICE';
export const CONTRACT_CHANGE_PRICE2 = 'BROKERPAGE/CONTRACT_CHANGE_PRICE2';
export const CONTRACT_CHANGE_SITU = 'BROKERPAGE/CONTRACT_CHANGE_SITU';
export const CONTRACT_CHANGE_BROKERAGE_TYPE = 'BROKERPAGE/CONTRACT_CHANGE_BROKERAGE_TYPE';
export const CONTRACT_CHANGE_ORDER = 'BROKERPAGE/CONTRACT_CHANGE_ORDER';
export const CONTRACT_CHANGE_ORDER_DIR = 'BROKERPAGE/CONTRACT_CHANGE_ORDER_DIR';
export const CONTRACT_CHANGE_PAGE = 'BROKERPAGE/CONTRACT_CHANGE_PAGE';
export const CONTRACT_CHANGE_KEYWORD = 'BROKERPAGE/CONTRACT_CHANGE_KEYWORD';

//=======================
//중개사 매물정보수정
export const MODIFY_GOODS = 'BROKERPAGE/MODIFY_GOODS';
export const CHANGE_MODIFY_GOODS = 'BROKERPAGE/CHANGE_MODIFY_GOODS';
export const SET_MODIFY_CONTRACT_INI = 'BROKERPAGE/SET_MODIFY_CONTRACT_INI';

export const GET_CALL_REPORT = 'BROKERPAGE/GET_CALL_REPORT';
export const SET_CALL_REPORT = 'BROKERPAGE/SET_CALL_REPORT';

export const CONTRACT_REFRESH_SEARCH_CONDITION = 'BROKERPAGE/CONTRACT_REFRESH_SEARCH_CONDITION';
export const CONTRACT_KEYWORD_SEARCH = 'BROKERPAGE/CONTRACT_KEYWORD_SEARCH';
export const CONTRACT_KEYWORD_SEARCH_RES = 'BROKERPAGE/CONTRACT_KEYWORD_SEARCH_RES';

//다윈외거래 계약체결
export const SET_CONTRACT_PRE_CLIENT_TYPE = 'BROKERPAGE/SET_CONTRACT_PRE_CLIENT_TYPE';

// 계약등록필요 계약체결
export const POST_CONTRACT = 'BROKERPAGE/POST_CONTRACT';
