import React from 'react';

export const useFbPixel = () => {
  const [fbPixel, setFbPixel] = React.useState(null);
  React.useEffect(() => {
    import('react-facebook-pixel')
      .then((pixel) => pixel.default)
      .then((ReactPixel) => {
        ReactPixel.init('2410886338974162');
        ReactPixel.pageView();
        setFbPixel(ReactPixel);
        // console.log('PIXEL', ReactPixel)
      });
  }, []);
  return fbPixel;
};
