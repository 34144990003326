import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '../helpers';
import { getLogInfo } from '@/services/Helper/userAgentHelper';
import { stringUtil } from '@/services';

export const api = {
  async login({ email, password }) {
    try {
      const logInfo = getLogInfo();
      return await apiClient.post('/auth/login', { email, password, logInfo: logInfo });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkAccessToken() {
    try {
      const logInfo = getLogInfo();
      return await apiClient.get(`/auth/check-access-token?logInfo=${logInfo}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async refreshAccessToken() {
    try {
      return await apiClient.get('/auth/refresh-access-token');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async social1(payload) {
    const logInfo = getLogInfo();
    try {
      payload.logInfo = logInfo;
      return await apiClient.post('/auth/login/social/step1', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async social2({ name, cellphone, socialToken }) {
    const logInfo = getLogInfo();

    try {
      return await apiClient.post('/auth/login/social/step2', {
        cellphone: stringUtil.removeDash(cellphone),
        name,
        socialToken,
        logInfo,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },
  async social3({ name, cellphone, authNum, socialToken }) {
    const logInfo = getLogInfo();
    try {
      return await apiClient.post('/auth/login/social/step3', {
        cellphone: stringUtil.removeDash(cellphone),
        name,
        authNum,
        socialToken,
        osType: window.dawin_instance.osType,
        logInfo,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async socialLoginCheckExist(stype, uid, email, profile) {
    try {
      return await apiClient.post('/auth/login/social/step2', {
        type: 'Kakao',
        uid: 1,
        email: 'moonpace@gmail.com',
        profile: null,
      });
    } catch (e) {
      return false;
    }
  },

  async signup({ email, password, profileImage, name }) {
    try {
      const formData = new FormData();
      formData.append('principal', email);
      formData.append('credentials', password);
      formData.append('name', name);
      if (profileImage) formData.append('file', profileImage);

      return await apiClient.post('/auth/signup', formData);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // @todo api 완성되면 param 확인 필요
  async agentSignUp({ email, password, profileImage, name }) {
    try {
      const formData = new FormData();
      formData.append('principal', email);
      formData.append('credentials', password);
      formData.append('name', name);
      if (profileImage) formData.append('file', profileImage);

      return await apiClient.post('/auth/signup/broker', formData);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkEmailExistence({ email }) {
    try {
      return await apiClient.post('/auth/email/exists', { email });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async password(token) {
    try {
      const headers = { api_key: `Bearer ${token}` };
      return await apiClient.patch('/auth/password', { headers });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async sms1({ name, cellphone }) {
    try {
      const logInfo = getLogInfo();
      return await apiClient.post('/auth/login/sms/step1', {
        name: name,
        cellphone: stringUtil.removeDash(cellphone),
        logInfo,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async sms2({ name, cellphone, authNum }) {
    try {
      const logInfo = getLogInfo();
      return await apiClient.post('/auth/login/sms/step2', {
        name,
        cellphone: stringUtil.removeDash(cellphone),
        authNum,
        logInfo,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async sms3({ smsAuthToken, memberIdx }) {
    try {
      const logInfo = getLogInfo();
      return await apiClient.post('/auth/login/sms/step3', {
        smsAuthToken,
        memberIdx,
        logInfo,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async sms4({ memberIdx, uid, type, email, accessToken, ip_address }) {
    try {
      const logInfo = getLogInfo();
      return await apiClient.post('/auth/login/sms/step4', {
        memberIdx,
        uid,
        type,
        email,
        smsAuthToken: accessToken,
        ip_address,
        osType: window.dawin_instance.osType,
        logInfo,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async socialSignin({ socialToken }) {
    const logInfo = getLogInfo();
    try {
      return await apiClient.post('/auth/login/social/signin', {
        // memberIdx,
        // uid,
        // type,
        // email,
        // smsAuthToken: accessToken,
        socialToken,
        osType: window.dawin_instance.osType,
        logInfo,
      });
    } catch (e) {
      throw Error(e.messge);
    }
  },

  async changeCellphoneNumber({ name, cellphone }) {
    try {
      return await apiClient.post('/my/phone_change_step01', {
        name: name,
        cellphone: stringUtil.removeDash(cellphone),
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async changeCellphoneNumberMatch({ name, cellphone, authNum }) {
    try {
      return await apiClient.post('/my/phone_change_step02', {
        name: name,
        cellphone: stringUtil.removeDash(cellphone),
        authNum: authNum,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkEmail({ mailToken }) {
    try {
      return await apiClient.post('/auth/check/mail_auth', { authToken: mailToken });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async findId1(cellphone) {
    try {
      return await apiClient.post('/auth/find-email-step1', { cellphone: cellphone });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async findId2(cellphone, authNum) {
    try {
      return await apiClient.post('/auth/find-email-step2', { cellphone, authNum });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async findPw1(email) {
    try {
      return await apiClient.post('/auth/find-password', { email });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async changePwCheckEmail(mailToken) {
    try {
      return await apiClient.get('/findpwd/changepwd' + objectToQuerystring({ token: mailToken }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async changePw(data) {
    try {
      return await apiClient.post('/findpwd/changepwdPrc', data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * @deprecated
   * push token(알림 설정)
   */
  async saveAppInfo(data) {
    try {
      return await apiClient.post('/push_token/token_info_add', data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //push token(알림 설정)
  async getAppInfo(data) {
    try {
      return await apiClient.post('/push_token/get_token_info', data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getTopic() {
    try {
      return await apiClient.get('/push_token/get_topic');
    } catch (e) {
      throw Error(e.message);
    }
  },

  // date : (날짜 [2021-07-02])
  // topic_code : (주제코드)
  // count : (카운트)
  async getTopicHistory(payload) {
    try {
      return await apiClient.get(`/push_token/push_send_list${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //회원탈퇴
  async byeDawin() {
    try {
      return await apiClient.post('/auth/secession');
    } catch (e) {
      throw Error(e.message);
    }
  },

  //네이버 연동 철회
  async disconnectNaver(accessToken) {
    try {
      return await apiClient.post('/auth/naver_prc', { accessToken });
    } catch (e) {
      throw Error(e.message);
    }
  },
  // async changePwLast(data) {
  //   try {
  //     //authNum: "561252", sToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZHgiOjExL…m8ifQ.ESolKQuOII0WE-GTN4l8fUKxfwFfN59XS3MtpruATr4"}
  //     return await apiClient.post('/findpwd/changepwdPrc', data { authNum, sToken, passwd });
  //   } catch (e) {
  //     throw Error(e.message);
  //   }
  // },

  async getMyNickname() {
    try {
      return await apiClient.get('/my/nick_name');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async setMyNickname(nickName) {
    try {
      return await apiClient.post('/my/nick_name', { nickName });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkNickname(nickName) {
    try {
      return await apiClient.post('/my/nick_name/check', { nickName });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkMemberRequiredData(mbr_idx) {
    try {
      return await apiClient.post('/auth/check_mem_data', { mbr_idx });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 이벤트 용 API
  // 이벤트로 가입한 유저 정보를 저장하기 위함
  async memberFromEvent(cd, mbr_idx) {
    try {
      return await apiClient.get(`/event/ev_join?dwcd=${cd}&mbr_idx=${mbr_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
