import React from 'react';
import GoodsPriceItem from './GoodsPriceItem';

// { marketData: goodsMarketData?.[item.trade_type], recentContractData: goodsRecentTradeData }
export default function GoodsPriceForm({ goods, onChangeCallback, marketPriceData }) {
  //   const updatePrice = (changedValue, { tradeType, priceKey }) => {
  //     const target = goods.find((g) => g.trade_type === tradeType);
  //     target[priceKey] = changedValue;
  //     callback(goods);
  //   };
  return (
    <>
      {goods?.length > 0 ? (
        <div className="form-list">
          {/* 가격 입력란 */}
          {goods.map((item) => (
            <GoodsPriceItem key={item.trade_type} data={item} goods={goods} onChangeCallback={onChangeCallback} marketPriceData={marketPriceData} />
          ))}
        </div>
      ) : (
        false
      )}
    </>
  );
}
