import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// 시세리스트 보기로 이동
export const routeToMarketList = (isMobile) => (dispatch) => {
  const path = getMarketListPath();
  const watching = location.search.indexOf('d_market_list') > -1;

  if (watching || !isMobile) {
    dispatch(actions.router.replace(path));
  } else {
    dispatch(actions.router.push(path));
  }
};

// 시세리스트 URL
export const getMarketListPath = () => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  /* 종속되는 파라미터들 제거 */
  // 시세상세
  delete queryObject.d_trade_type;
  delete queryObject.d_area;
  // 매물상세
  delete queryObject.d_goods;

  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};
