import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { ChatController } from './ChatController';
import toggle from '@/hocs/toggle';

const ChatInitializer = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectors.auth.getUserInfo);

  const userId = String(userInfo?.mbr_idx);

  const [controller] = useState(() => new ChatController());

  const chatData = controller.init(userId);

  useEffect(() => {
    dispatch(actions.chat.setChatData(chatData));
  }, [chatData]);

  useEffect(() => {
    dispatch(actions.chat.setController(controller));
  }, [controller]);

  return <></>;
};

export default toggle(ChatInitializer);
