import { selectors } from '@/data';
import React from 'react';
import { useSelector } from 'react-redux';
import ReplyContents from './components/replyContents';
import ReplyLike from './ReplyLike';
import ReplyTool from './components/replyTool';
import ReplyWriterInfo from './components/replyWriterInfo';
import LoadingComp from '@/components/Spinner/MaskedSpinner';
import DeletedComment from '../../../components/deletedComment';
import ReplyComponent from './components/ReplyComponents';
import { TagType } from '@/services/Community';
import { useGetComplexHistoryBulkByUserIds } from '@/combinedComp/Community/hooks';

const Reply = ({
  isMore,
  isActiveComment,
  comment,
  replies,
  setReplies,
  setActiveComment,
  deletedId,
  cancelUpdateComment,
  onUpdateComment,
  onDeleteComment,
  showReply,
  status,
  feedData,
  isFeedDeleted,
}) => {
  const isMobile = useSelector(selectors.common.isMobile);
  // const { mbr_idx } = useSelector(selectors.auth.getUserInfo);

  const hasComplex = feedData?.tags?.filter((tag) => tag.type == TagType.COMPLEX_TAG).length > 0;

  // 집주인 인증배지 데이터 요청
  const getFilteredId = () => {
    const filteredIds = replies?.data?.filter((reply) => reply?.writer?.type === 'CUSTOMER').map((comment) => comment?.writer?.id);
    return filteredIds;
  };
  const getStatus = () => {
    const isReady = replies !== undefined && replies !== null && hasComplex;
    return isReady;
  };
  useGetComplexHistoryBulkByUserIds({ feeds: replies, userIds: getFilteredId(), isReady: getStatus() });

  return (
    <>
      {status === 'LOADING' ? (
        <div style={{ position: 'relative', height: '45px' }}>
          <LoadingComp size={isMobile ? 10 : 20} />
        </div>
      ) : (
        !isMore &&
        showReply &&
        replies?.data?.map((reply, index) => (
          <>
            <ReplyComponent
              feedData={feedData}
              reply={reply}
              deletedId={deletedId}
              isFeedDeleted={isFeedDeleted}
              index={index}
              cancelUpdateComment={cancelUpdateComment}
              onUpdateComment={onUpdateComment}
              onDeleteComment={onDeleteComment}
              isActiveComment={isActiveComment}
              setActiveComment={setActiveComment}
              replies={replies}
              setReplies={setReplies}
              comment={comment}
            />
          </>
        ))
      )}
    </>
  );
};

export default Reply;
