import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//계약자 정보 저장
export const saveContactUs = (param) => ({
  type: AT.SAVE_CONTRACT_CONTACT_INFO,
  param,
});
//계약자 정보 수정
export const editContactUs = (param) => ({
  type: AT.EDIT_CONTRACT_CONTACT_INFO,
  param,
});
//계약자 정보 삭제
export const deleteContactUs = (param) => ({
  type: AT.DELETE_CONTRACT_CONTACT_INFO,
  param,
});
//계약상세 내용 추가, 수정, 삭제
export const saveContractDetailInfo = (param) => ({
  type: AT.SAVE_CONTRACT_DETAIL_INFO,
  param,
});
export const editContractDetailInfo = (param) => ({
  type: AT.EDIT_CONTRACT_DETAIL_INFO,
  param,
});
export const deleteContractDetailInfo = (param) => ({
  type: AT.DELETE_CONTRACT_DETAIL_INFO,
  param,
});
//검색
export const changePage = (page) => ({
  type: AT.CONTRACT_CHANGE_PAGE,
  page,
});
export const changeSearchWord = (keyword) => ({
  type: AT.CONTRACT_CHANGE_KEYWORD,
  keyword,
});

export const setNextData = (yn) => ({
  type: AT.SET_NEXT_CONTRACT_DATA,
  yn,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_CONTRACT_CURRENT_PAGE,
  page,
});
export const cancelContract = (payload, callback) => ({
  type: AT.CANCEL_CONTRACT,
  payload,
  callback,
});
export const deleteContract = (payload) => ({
  type: AT.DELETE_CONTRACT,
  payload,
});
export const getContractList = (payload) => ({
  type: AT.GET_MANAGE_CONTRACT_LIST,
  payload,
});

export const getContractListLoading = () => ({
  type: AT.GET_MANAGE_CONTRACT_LIST_LOADING,
});
export const getContractListSuccess = (contractList) => ({
  type: AT.GET_MANAGE_CONTRACT_LIST_SUCCESS,
  contractList,
});
export const getContractListFailure = () => ({
  type: AT.GET_MANAGE_CONTRACT_LIST_FAILURE,
});

export const getContractDetail = (contract_idx, onSuccess) => ({
  type: AT.GET_MANAGE_CONTRACT_DETAIL,
  contract_idx,
  onSuccess,
});
export const getContractDetailFailure = () => ({
  type: AT.GET_MANAGE_CONTRACT_DETAIL_FAILURE,
});
export const getContractDetailSuccess = (contractDetail) => ({
  type: AT.GET_MANAGE_CONTRACT_DETAIL_SUCCESS,
  contractDetail,
});
export const getContractDetailLoading = () => ({
  type: AT.GET_MANAGE_CONTRACT_DETAIL_LOADING,
});
export const resetContractDetail = () => ({
  type: AT.RESET_CONTRACT_DETAIL,
});

export const setKeywordSearchResult = (keywordList) => ({
  type: AT.CONTRACT_KEYWORD_SEARCH_RES,
  keywordList,
});
export const setContractInfoMembers = (data) => ({
  type: AT.SET_CONTRACT_INFO_MEMBERS,
  data,
});
export const setContractInfoDetails = (data) => ({
  type: AT.SET_CONTRACT_INFO_DETAILS,
  data,
});
export const setPreExContract = (brokerage) => ({
  type: AT.SET_PRE_EX_CONTRACT,
  brokerage,
});
export const savePreContract = (payload, redirect) => ({
  type: AT.SAVE_PRE_CONTRACT,
  payload,
  redirect,
});

// 계약등록필요 계약체결
export const postContract = (data, setViewOn, setSubmitStatus, dispatch) => ({
  type: AT.POST_CONTRACT,
  data,
  setViewOn,
  setSubmitStatus,
  dispatch,
});
