import { actions } from '@/data';
import { STEPS } from '@/pages/Login/BasicForm/helpers';
import { store } from '@/data/configureStore';
import router from '@/services/router';
import * as communicationActions from '@/services/user/communication';

const realtorLogin = () => (dispatch) => {
  const { getState } = store;
  const state = getState();

  if (state?.common?.isMobile) {
    dispatch(router.push('/login/broker'));
  } else {
    dispatch(actions.auth.setLoginStep(STEPS.BROKER));
    dispatch(actions.common.toggleLoginPopup(true));
  }
};

/** @deprecated */
const consult = {
  userValidCheck: (userInfo) => (dispatch) => {
    if (!userInfo) {
      dispatch(actions.common.pleaseLoginPopup(true));
      return false;
    } else if (userInfo.isBroker) {
      dispatch(actions.common.alert({ contents: '중개사는 상담이 불가능합니다.' }));
      return false;
    } else {
      return true;
    }
  },
  chat: (realtorDetail, { chatParam, userInfo }) => (dispatch) => {
    const { broker_office_idx } = realtorDetail;

    const isValid = dispatch(consult.userValidCheck(userInfo));

    if (isValid) {
      const to = `${broker_office_idx}`;
      const title = chatParam.title;

      dispatch(communicationActions.chat(to, { title }));
    }
  },
  call: (realtorDetail, { chatTitle = '', userInfo }) => (dispatch) => {
    const { office_name, phone_number, phone } = realtorDetail;

    const phoneNumber = phone_number || phone;

    const isValid = dispatch(consult.userValidCheck(userInfo));

    if (isValid) {
      dispatch(
        communicationActions.callOrChat(phoneNumber, office_name, () => {
          dispatch(consult.chat(realtorDetail, { userInfo, chatTitle }));
        })
      );
    }
  },
};

const realtorActions = { realtorLogin, consult };

export default realtorActions;
