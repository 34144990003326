import React from 'react';
import { call, put, select, delay } from 'redux-saga/effects';
import { actions, selectors } from '@/data';
import { STATUS } from '@/data/utils';
import { setUserCookie, clearAuthCookie, setAuthCookie, getTokenCookie, setTokenCookie, setSaveIdCookie } from '@/data/cookie';
import apis from '@/apis';
import { STEPS } from '@/pages/Login/BasicForm/helpers';
import * as find from './find/sagas';
import { setCookieByKey } from '@/data/cookie';
import { COOKIE_CONSTS } from '@/resources/global';
import { routeToNextStep, getTempNextStepPathForMarketing } from '@/pages/Login/helpsers';
import { defaultRoutingAfterLogin, sendLoginLogs } from './services';
import { getDeviceIdOrGenerate } from '@/services/Device';
// import { routeToNextStep } from '@/pages/Login/helpsers';

export { find };

export function* login({ data }) {
  try {
    yield put(actions.auth.loginLoading());

    // const querystring = parseCallBack();
    const isSignUpUser = yield select(selectors.auth.getIsSignUpUser);
    const isBroker = yield data?.userInfo?.mbr_type === 'BU';
    const path = yield window.location.pathname;

    const actionAfterLogin = yield select(selectors.common.getActionAfterLogin);
    const osTypeInstance = yield select(selectors.app.getOsTypeInstance);

    const isApp = osTypeInstance?.isApp;

    /**
     * 로그인 로그
     */
    sendLoginLogs({ isApp, isSignUpUser, data });

    /**
     * DW-246 GA 분석을 위해 querystring 세분화 - 로그인 성공 단계
     */
    const marketingUserInfo = yield select(selectors.auth.getMarketingUserInfo);

    if (marketingUserInfo?.isMarketing && marketingUserInfo?.signUpProcessing) {
      // marketing_user: 마케팅 URL로 접속 후 회원가입 전환 한 유저
      // isMarketing 정보만 사용하는 경우 기존 회원이 회원가입이 아닌 로그인 절차를 진행한 것일 수도 있음 -> signUpProcessing 도 true인 경우만 표시

      // login_step=success&marketingUserInfo.value_user=true
      const addedPath = getTempNextStepPathForMarketing('success', marketingUserInfo.value);
      yield put(actions.router.replace(addedPath));

      // 정상 url로
      const deletedPath = getTempNextStepPathForMarketing(null, marketingUserInfo.value);
      yield put(actions.router.replace(deletedPath));
    } else {
      yield put(routeToNextStep('success'));
      // success를 찍고 바로 제거해주어야 GA행동흐름에 좀 더 정확한 집계 가능
      yield put(routeToNextStep(null));
    }

    /**
     * 로그인 정보 저장
     * 쿠키, redux state
     * 순서 중요 - setUserSession은 액션이므로 컴포넌트의 re-rendering 을 일으킨다.
     * 그때 authCookie 를 사용하는 api 콜이 일어나면 로그인이 성공했음에도 불구하고 콜 실패할 수도 있다.
     */
    yield setAuthCookie(data.accessToken, data.userInfo);
    yield put(actions.auth.setUserSession(data.userInfo));

    /**
     * 로그인 성공 후 액션
     * 다른 화면으로 이동 등.
     */
    if (actionAfterLogin) {
      // 공통적으로 실행되는 액션이 아님
      // 예) "로그인이 필요한 기능입니다" 팝업을 통해 로그인 한 경우, 유저가 하고자 했던 액션을 로그인 후 실행시켜 준다.
      actionAfterLogin({ data, isSignUpUser });
    } else if (isBroker) {
      // 중개사 로그인
      yield put(actions.router.replace('/brokerPage'));
    } else {
      // 일반 로그인
      const osTypeInstance = yield select(selectors.app.getOsTypeInstance);
      const isApp = osTypeInstance?.isApp;

      yield defaultRoutingAfterLogin({ path, isBroker, isApp });
    }

    // if (querystring.redirectToevent) {
    //   const url = querystring.redirectToevent + '?tk=' + data.accessToken;
    //   window.location.href = url;
    //   return;
    // }

    /**
     * 로그인 완료
     */
    yield put(actions.auth.loginSuccess(data));
    yield put(actions.common.toggleLoginPopup(false));
  } catch (e) {
    yield put(actions.auth.loginFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(
      actions.common.alert({
        contents: (
          <>
            로그인에 실패하였습니다.
            <br />
            {e.message}
          </>
        ),
      })
    );
  }
}

export function* dawinLogin(action) {
  try {
    // yield put(actions.auth.loginLoading());
    const result = yield call(apis.authApi.login, action);
    yield put(actions.auth.login(result));
    // yield put(actions.auth.loginSuccess());
    yield setSaveIdCookie(action.saveID ? action.email : false);
    yield put(actions.common.toggleLoginPopup(false));
  } catch (e) {
    yield put(actions.auth.loginFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(
      actions.common.alert({
        contents: (
          <>
            로그인에 실패하였습니다.
            <br />
            {e.message}
          </>
        ),
      })
    );
  }
}

export function* sms1({ name, cellphone, resend, options }) {
  const { setLoadingStatus, callback } = options || {};
  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    yield put(actions.auth.smsLoading());
    const step = yield select(selectors.auth.getLoginStep);

    let result = null;
    if (step === STEPS.SOCIAL2 || step === STEPS.SOCIAL3) {
      const socialToken = yield select(selectors.auth.getAuthToken);
      result = yield call(apis.authApi.social2, { name, cellphone, socialToken });
      yield put(actions.auth.setLoginStep(STEPS.SOCIAL3));
    } else {
      result = yield call(apis.authApi.sms1, { name, cellphone });
      yield put(actions.auth.setLoginStep(STEPS.PHONE2));
    }
    yield put(actions.common.alert({ contents: '문자로 전송된 인증번호를 확인해주세요.' }));
    if (resend) {
      callback && callback();
    }
    yield put(actions.auth.smsSuccess(result));
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    yield put(actions.auth.smsFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(actions.common.alert({ contents: '로그인에 실패하였습니다.' }));
  }
}

export function* sms2({ name, cellphone, authNum, options }) {
  const { setLoadingStatus } = options || {};
  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    yield put(actions.auth.smsLoading());
    const step = yield select(selectors.auth.getLoginStep);
    let result = null;
    if (step === STEPS.SOCIAL3) {
      const socialToken = yield select(selectors.auth.getAuthToken);
      result = yield call(apis.authApi.social3, { name, cellphone, authNum, socialToken });
      if (result.step === 'login_success') {
        yield put(actions.auth.login(result));
      } else if (result.step === 'email_send') {
        yield put(actions.common.alert({ contents: result.rltinfo.accepted[0] }));
        yield put(actions.auth.setLoginStep(STEPS.EMAIL));
      }
    } else {
      result = yield call(apis.authApi.sms2, { name, cellphone, authNum });
      yield put(actions.auth.setIdCandidates(result));
      if (result.step === 'sms_step3') {
        yield put(actions.auth.setLoginStep(STEPS.PHONE3));
        yield put(actions.auth.setAuthToken(result.smsAuthToken));
      } else if (result.step === 'sms_step4') {
        // 일반회원은 휴대폰 번호로 로그인을 지원하지 않기로 함. 소셜인증 스텝으로 넘기지 않고 끝냄
        yield put(actions.auth.setLoginStep(STEPS.BROKER));
        yield put(
          actions.common.alert({
            contents: (
              <>
                회원정보를 찾을 수 없습니다.
                <br />
                새로운 회원으로 가입해주세요.
              </>
            ),
          })
        );
        yield put(actions.auth.smsFailure('회원정보 없음'));
      } else if (result.step === 'login_success') {
        yield put(actions.auth.login(result));
      }
      yield put(actions.auth.smsSuccess());
    }
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    yield put(actions.auth.smsFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(actions.common.alert({ contents: e.message }));
  }
}

export function* sms3({ smsAuthToken, memberIdx, options }) {
  const { setLoadingStatus } = options || {};
  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    yield put(actions.auth.smsLoading());
    const result = yield call(apis.authApi.sms3, { smsAuthToken, memberIdx });
    if (result.step === 'login_success') {
      yield put(actions.auth.login(result));
    } else if (result.step === 'sms_step4') {
      yield put(actions.auth.setLoginStep(STEPS.PHONE4));
    }
    yield put(actions.auth.smsSuccess(result));
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
    yield put(actions.common.toggleLoginPopup(false));
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    yield put(actions.auth.smsFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(actions.common.alert({ contents: '로그인에 실패하였습니다.' }));
  }
}

/**@comment memberIdx 없는경우 0000 (step3을 거치지 않고 넘어오는 경우)  */
export function* sms4({ memberIdx = '0000', uid, stype, email, profile, afterAgree }) {
  try {
    const accessToken = yield select(selectors.auth.getAuthToken);
    if (memberIdx === '0000' && !afterAgree) {
      //약관동의 필요
      yield put(
        actions.auth.setAgreeAction(() =>
          actions.auth.sms4({
            memberIdx,
            uid,
            type: stype,
            email,
            profile: profile,
            accessToken,
            afterAgree: true,
          })
        )
      );
      yield put(actions.auth.setLoginStep(STEPS.AGREE));
    } else {
      yield put(actions.auth.smsLoading());
      const result = yield call(apis.authApi.sms4, {
        memberIdx,
        uid,
        type: stype,
        email,
        profile: profile,
        accessToken,
      });

      if (result.step === 'login_success') {
        yield put(actions.auth.login(result));
      }
      yield put(actions.auth.smsSuccess(result));
    }
  } catch (e) {
    yield put(actions.auth.smsFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(actions.common.alert({ contents: '로그인에 실패하였습니다.' }));
  }
}

export function* social1({ stype, uid, email, profile, afterAgree, accessToken }) {
  try {
    let result = null;
    if (typeof afterAgree == 'undefined' || !afterAgree) {
      yield put(actions.auth.loginLoading());
      result = yield apis.authApi.social1({ type: stype, uid, email, profile, accessToken });
    } else {
      result = yield select(selectors.auth.getLogin); // 이전 api 조회 결과 참조
    }

    if ((result.result === 'no_member' || result.step === 'social_signin') && !afterAgree) {
      // DW-1023 일반회원 가입 처리시점 변경
      // if (result.step === 'social_signin' && !afterAgree) {
      //약관동의 필요
      yield put(actions.auth.setAgreeAction(() => actions.auth.socialSignin({ stype, uid, email, profile, afterAgree: true })));
      yield put(actions.auth.setAuthToken(result.socialToken));
      yield put(actions.auth.setLoginStep(STEPS.AGREE));
    } else {
      if (result.step === 'social_step2') {
        yield put(actions.common.alert({ contents: '소셜 로그인 최초 시도 시 휴대폰 인증이 필요합니다.' }));
        yield put(actions.auth.setLoginStep(STEPS.SOCIAL2));
        yield put(actions.auth.setAuthToken(result.socialToken));
      } else if (result.step === 'social_step3') {
        yield put(actions.common.alert({ contents: '등록된 휴대폰번호로 인증번호를 전송하였습니다.' }));
        yield put(actions.auth.setLoginStep(STEPS.SOCIAL3, result.cellphone, result.name));
        yield put(actions.auth.setAuthToken(result.socialToken));
      } else if (result.step === 'login_success') {
        yield put(actions.auth.login(result));
      }
    }
    yield put(actions.auth.loginSuccess(result)); //약관 -> 스피너
  } catch (e) {
    yield put(actions.auth.loginFailure(e));
    // yield put(actions.auth.setLoginStep(STEPS.FIRST));
    yield put(actions.common.alert({ contents: '로그인에 실패하였습니다.' }));
  }
}

// 회원가입 처리
export function* socialSignin({ stype, uid, email, profile, afterAgree }) {
  try {
    yield put(actions.auth.loginLoading());
    const socialToken = yield select(selectors.auth.getAuthToken);
    const result = yield apis.authApi.socialSignin({ socialToken });

    // social1 에서 result 데이터를 사용해야하므로 순서 중요
    yield put(actions.auth.loginSuccess(result));
    yield put(actions.auth.social1({ stype, uid, email, profile, afterAgree }));
  } catch (e) {
    yield put(actions.auth.loginFailure(e));
    yield put(actions.common.alert({ contents: '로그인에 실패하였습니다.' }));
  }
}

export function* checkEmail({ mailToken }) {
  try {
    const result = yield call(apis.authApi.changePwCheckEmail, mailToken);
    yield put(actions.auth.emailConfirmed(result));
  } catch (e) {
    yield put(actions.auth.emailConfirmed(false));
  }
}

export function* logout({ redirect, option }) {
  const { isBrokerPage = false, actionAfterLogout } = option || {};
  // 앱 푸시토큰 삭제
  try {
    const osTypeInstance = yield select(selectors.app.getOsTypeInstance);
    const isApp = osTypeInstance?.isApp;

    if (isApp) {
      const deviceId = getDeviceIdOrGenerate();
      yield call(apis.notificationApi.deletePushToken, deviceId || '');
      setCookieByKey(COOKIE_CONSTS.APPINFO_UPDATE_DATE, '');
    }
  } catch (e) {
    // 앱 푸시토큰 삭제 실패
  }

  try {
    // state 유지 시키지 않고 location 변경
    if (redirect) {
      if (isBrokerPage) {
        location.href = '/brokerPage';
      } else {
        location.href = '/';
      }
    }

    //로그아웃
    yield put(actions.auth.resetAuth());
    yield clearAuthCookie();

    if (actionAfterLogout) {
      yield actionAfterLogout();
    }
  } catch (e) {
    console.log(e);
  }
}
export function* checkAuth() {
  try {
    yield put(actions.auth.checkAuthloading());

    const currToken = yield getTokenCookie();

    if (currToken) {
      const data = yield call(apis.authApi.checkAccessToken);

      if (data.state && data.state === 1) {
        // const userInfo = getUserCookie();
        // yield put(actions.auth.setUserSession(userInfo));
      } else if (data.state && data.state === 2) {
        const result = yield call(apis.authApi.refreshAccessToken);
        if (result.newAccessToken) yield setTokenCookie(result.newAccessToken);
      } else if (data.state && data.state === 3) {
        yield put(actions.auth.logout(true));
      }

      if (data.state && (data.state === 1 || data.state === 2)) {
        // DW-246 GA 분석을 위해 querystring 세분화 - 자동 로그인 유저
        yield put(routeToNextStep('revisit'));
        yield put(routeToNextStep(null));
      }

      yield put(actions.auth.checkAuthSuccess());
    }
  } catch (e) {
    yield put(actions.auth.checkAuthFailure());
    yield put(actions.auth.logout(true));
  }
}

export function* signup(action) {
  try {
    yield put(actions.auth.signupLoading());

    const { formValues } = action;
    const userSession = yield apis.authApi.signup(formValues);
    yield put(actions.auth.setUserSession(userSession));
    yield setAuthCookie(userSession.accessToken, userSession.userInfo);
    yield put(actions.router.push('/'));

    yield put(actions.auth.signupSuccess(userSession));
  } catch (e) {
    yield put(actions.auth.signupFailure(e));
    alert(e);
  }
}

export function* agentSignUp(action) {
  try {
    yield put(actions.auth.signupLoading());

    const { formValues } = action;
    const userSession = yield apis.authApi.signup(formValues);
    yield put(actions.auth.setUserSession(userSession));
    yield setAuthCookie(userSession.accessToken, userSession.userInfo);
    yield put(actions.router.push('/'));

    yield put(actions.auth.signupSuccess(userSession));
  } catch (e) {
    yield put(actions.auth.signupFailure(e));
    alert(e);
  }
}

export function* byeDawin() {
  try {
    const result = yield apis.authApi.byeDawin();
    if (result?.rltObj) {
      yield put(
        actions.common.alert({
          contents: (
            <>
              회원 탈퇴처리가 완료되었습니다.
              <br /> 그동안 다윈중개를 이용해 주셔서 감사합니다.
            </>
          ),
        })
      );
      yield delay(2000);
      yield put(actions.auth.logout(true));
    }
  } catch {
    yield put(actions.common.alert({ contents: '요청에 실패하였습니다.' }));
  }
}

export function* disconnectNaver({ accessToken, closeThisWindow }) {
  try {
    yield apis.authApi.disconnectNaver(accessToken);
    if (closeThisWindow) {
      window.close();
    }
  } catch {
    if (closeThisWindow) {
      window.close();
    }
  }
}

// 일반유저 휴대폰번호 변경
export function* changeCellphoneNumber({ cellphone }) {
  try {
    let user = yield select(selectors.auth.getUserInfo);
    yield call(apis.authApi.changeCellphoneNumber, { name: user.mbr_name, cellphone });
    yield put(actions.common.alert({ contents: '인증번호를 전송했습니다.' }));
  } catch (e) {
    // yield put(actions.auth.smsFailure(e));
  }
}

// 일반유저 휴대폰번호 변경 완료
export function* changeCellphoneNumberMatch({ cellphone, authNum, successCallback }) {
  try {
    let user = yield select(selectors.auth.getUserInfo);
    yield call(apis.authApi.changeCellphoneNumberMatch, { name: user.mbr_name, cellphone, authNum });
    const newUserInfo = { ...user, mbr_phone: cellphone };
    yield put(actions.auth.setUserSession(newUserInfo));
    yield setUserCookie(newUserInfo);
    if (successCallback) successCallback();
  } catch (e) {
    yield put(
      actions.common.alert({
        contents: (
          <div className="board-body">
            <p className="para">
              <span className="wbr">인증번호가 일치하지 않습니다.</span>
              <span className="wbr">다시 시도해주세요.</span>
            </p>
          </div>
        ),
      })
    );
  }
}

export function* saveNickname({ nickname, setStatus, callback }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    yield call(apis.authApi.setMyNickname, nickname);
    yield put(actions.auth.setNickname(nickname));
    callback && callback(); // "닉네임이 저장되었습니다." 팝업 보여주기 위한 처리
    setStatus && setStatus({ status: STATUS.SUCCESS });
  } catch (e) {
    if (setStatus) {
      if (e?.message === 'nick name have bad word') {
        setStatus({
          status: STATUS.FAILURE,
          error: '적절하지 않은 단어가 포함되어 있습니다.',
        });
      } else {
        setStatus({
          status: STATUS.FAILURE,
          error: e.message?.indexOf('e.message') > -1 ? '사용할 수 없는 닉네임입니다.' : '닉네임 설정을 실패하였습니다.',
        });
      }
    }
  }
}

export function* getNickname({ setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    const result = yield call(apis.authApi.getMyNickname);
    yield put(actions.auth.setNickname(result?.nickName));
    setStatus && setStatus({ status: STATUS.SUCCESS, data: result });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

// 유저 정보 변경 후 DB에서 재조회해서
// export function* updateUserInfo() {}
