import { useEffect, useState } from 'react';
import getPositionPrms from '@/services/apps/permission/getPositionPrms';
import { notificationPrmsUpdate } from '@/services/apps/permission/notificationPrms';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';

const useAppPermission = ({ useSelector, selectors, isLoggedIn }) => {
  const dispatch = useDispatch();

  const [listenerAdded, setListenerAdded] = useState(false);
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const isApp = osTypeInstance?.isApp;

  useEffect(async () => {
    try {
      if (isApp && isLoggedIn) {
        // 하루 한번만 push token 업데이트
        // 마지막 토큰 업데이트 date

        // 1. 앱 푸시 권한 요청
        await window.dawin_instance.bridge.permissionFunction.requestNotificationPermission();

        // 2. 위치 권한 요청
        await getPositionPrms();

        // 앱 푸시토큰 업데이트 (db)
        notificationPrmsUpdate();

        if (!listenerAdded) {
          // onForeground listener에 앱 푸시 권한 요청 로직 등록
          window.dawin_instance.bridge.appLifecycleFunction.onForeground(() => {
            // 푸시토큰은 푸시토큰이 업데이트 됐을 때 마다 업데이트해야하기 때문에 등록
            // 다른 권한들은 실행 시점이 유저 액션에 따르므로 onForeground에서 실행할 필요없음
            notificationPrmsUpdate();
            dispatch(actions.auth.checkAuth());
          });

          setListenerAdded(true);
        }
      }
    } catch {
      console.error('[APP] permission 오류 ');
    }
  }, [isLoggedIn, isApp]);
};

export default useAppPermission;
