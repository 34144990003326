import actions from './actions';
import reviewService from '@/services/review';

/**
 * @deprecated ./actions/index.js realtorLogin 함수로 변경
 */
export const actionRealtorLogin = actions.realtorLogin;

// realtor 데이터 정제(같은 데이터인데 key 값이 다른 경우. 공통 컴포넌트 사용 편의를 위해)
export const getCleanRealtorData = (data) => {
  if (!data) return null;

  const result = { ...data };

  result.mbr_image_full_path = data.image || data.mbr_image_full_path;
  result.mbr_name = data.name || data.mbr_name;
  result.broker_point = reviewService.formatReviewScore(data.total_rate || data.broker_point);
  result.broker_point_cnt = data.rate_count || data.broker_point_cnt;
  result.addr1 = data.address || data.addr1;
  result.mainPhoneNumber = data.phone || data.phone_number;

  delete result.image;
  delete result.name;
  delete result.total_rate;
  delete result.rate_count;
  delete result.address;

  return result;
};

const serviceRealtor = { actions, getCleanRealtorData };
export default serviceRealtor;
