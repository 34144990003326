import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import * as selling_selectors from '@/data/selling/selectors';
import apis from '@/apis';

export function* getMgCounselingList() {
  try {
    const mgstate = yield select(selectors.mgCounseling.getMgCounseling);
    const payload = {
      keyword: mgstate.keyword,
      limit: mgstate.limit,
    };
    const response = yield call(apis.brokerPageApi.mgCounselingList, payload);
    const list = response.rltObj;
    yield put(actions.getMgCounselingListSuccess(list));
  } catch (e) {
    yield put(actions.getMgCounselingListFailure({ error: 'mgc 잘못됐음1.' }));
  }
}
export function* getMgCounselingGoodsList() {
  try {
    const complex_idx = yield select(selling_selectors.getComplexIdx);
    const payload = yield {
      complex_idx: complex_idx,
    };
    //yield put(actions.getMgCounselingGoodsListLoading());
    const response = yield call(apis.brokerPageApi.getMgCounselingGoodsList, payload);
    const { list } = response;
    yield put(actions.getMgCounselingGoodsListSuccess(list));
  } catch (e) {
    yield put(actions.getMgCounselingGoodsListFailure({ error: 'mgc 잘못됐음2.' }));
  }
}
//신규상담 저장
export function* save_MGC() {
  try {
    const new_list = yield select(selectors.mgCounseling.getNewCounseling);
    console.log(new_list);
    if (new_list.sel != '1') {
      const payload1 = {
        sel: new_list.sel == '1' ? 'S' : 'B',
        buyer_cp: new_list?.cp,
        contents: new_list.contents,
        goodsIdx: new_list?.goodsIdx,
      };
      const response = yield call(apis.brokerPageApi.saveMGC, payload1);
    } else {
      const payload2 = {
        memo_type: 'BRK',
        memo_target: '매도(임대)상담',
        extra_contents: '',
        contents: new_list?.contents,
      };
      const response2 = yield call(apis.brokerPageApi.saveMGC2, payload2, new_list?.goodsCommonIdx);
    }
    yield put(allActions.common.alert({ contents: '상담 등록 성공했습니다.' }));
    yield put(actions.in_cp(''));
    yield put(actions.in_contents(''));
    yield put(actions.in_sel('1'));
    yield put(actions.getMgCounselingList());
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
    // yield put(actions.saveMGCfailure({ error: 'mgc 저장이 안됨.' }));
  }
}
