import React from 'react';
import { actions } from '@/data';
import { createChatRoom } from '@/data/chat/services';
import { OSTYPE } from '@/services/apps/types/OsType';

export const chat = (targetUserIdx, chatParam) => async (dispatch) => {
  try {
    await createChatRoom({ to: targetUserIdx, chatParam });
  } catch (e) {
    dispatch(actions.common.alert({ contents: e.message }));
  }
};

export const callOrChat = (phoneNumber, name, chatFunc) => (dispatch) => {
  // chatFunc 필수
  if (!chatFunc) throw Error('chatFunc(채팅하기 함수) 필수');

  if (phoneNumber) {
    dispatch(call(phoneNumber, name));
  } else {
    dispatch(openNoPhoneNumberOrChatAlert(name, chatFunc));
  }
};

export const call = (phoneNumber, name) => (dispatch) => {
  const phoneNumberString = `${phoneNumber?.substring(0, 4)}-${phoneNumber?.substring(4, 8)}-${phoneNumber?.substring(8, 12)}`;
  const osType = window.dawin_instance.osType;

  if (osType === OSTYPE.DESKTOP) {
    dispatch(
      actions.common.alert({
        contents: (
          <>
            <strong style={{ fontSize: '20px' }}>{name}</strong>
            <br />
            <strong style={{ fontSize: '20px' }}>{phoneNumberString}</strong>
            <br />
            <br />
            <span>
              위 전화번호로 연락하시면
              <br />
              부동산 중개업소에서
              <br />
              친절하게 안내해드립니다.
            </span>
          </>
        ),
      })
    );
  } else if (osType === OSTYPE.WEB_ANDROID || osType === OSTYPE.WEB_IOS) {
    window.location.href = 'tel:' + phoneNumber;
  } else {
    window.dawin_instance.bridge.osFunction.executeCallApp(phoneNumber);
  }
};

export const openNoPhoneNumberOrChatAlert = (userName, chatFunc) => (dispatch) => {
  // chatFunc 필수
  if (!chatFunc) throw Error('chatFunc(채팅하기 함수) 필수');

  dispatch(
    actions.common.confirm({
      contents: (
        <>
          <strong style={{ fontSize: '20px' }}>{userName}</strong>
          <br />
          <span>
            전화번호가 등록되지 않았습니다.
            <br />
            채팅으로 문의해보세요
          </span>
        </>
      ),
      cancelButtonText: '창닫기',
      confirmButtonText: '채팅하기',
      onConfirm: () => {
        chatFunc();
      },
    })
  );
};
