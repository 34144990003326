import { validate } from '../utils';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';

const useSearchCommunity = ({ inputRef, totalSearch, setShow, setKeyword, setShowResetBtn }) => {
  const dispatch = useDispatch();

  const handleSearchText = (data) => {
    const value = data.target.value;
    setKeyword(value);

    const isValid = validate(value);
    if (isValid) {
      dispatch(actions.community.searchTag({ keyword: value, totalSearch }));
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const forceSearchText = (e) => {
    e.preventDefault();

    const value = inputRef.current.value.trim();

    if (validate(value)) {
      dispatch(actions.community.searchTag({ keyword: value, totalSearch }));
      setShow(true);
    }
  };

  return { handleSearchText, forceSearchText };
};

export default useSearchCommunity;
