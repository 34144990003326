import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.GET_UNREAD_NOTI_COUNT, sagas.getUnreadNotiCount);
  yield takeEvery(AT.GET_MY_INTEREST_GOODS, sagas.getMyInterestGoods);
  yield takeEvery(AT.GET_MY_HOMES_DATA, sagas.getMyHomesData);
  yield takeEvery(AT.GET_MY_HOME_DATA, sagas.getMyHomeData);
  yield takeEvery(AT.SAVE_MODIFY_MY_HOME, sagas.saveModifyMyHomeData);
  yield takeEvery(AT.DELETE_MY_HOME, sagas.deleteMyHomeData);
  yield takeEvery(AT.SAVE_MY_HOME, sagas.saveMyHome);
  yield takeEvery(AT.MY_HOME_DATA_SORTING, sagas.sortMyHome);
  yield takeEvery(AT.MY_AREA_DATA_SORTING, sagas.sortMyArea);
  yield takeEvery(AT.MY_COMPLEX_DATA_SORTING, sagas.sortMyComplex);

  yield takeEvery(AT.GET_MY_AREAS_DATA, sagas.getMyAreasData);
  yield takeEvery(AT.GET_MY_AREA_DATA, sagas.getMyAreaData);
  yield takeEvery(AT.DELETE_MY_AREA, sagas.deleteMyArea);
  yield takeEvery(AT.SAVE_MY_AREA, sagas.saveMyArea);

  yield takeEvery(AT.GET_MY_CPS_DATA, sagas.getMyCpsData);
  yield takeEvery(AT.DELETE_MY_CP, sagas.deleteMyCp);
  yield takeEvery(AT.SAVE_MY_CP, sagas.saveMyCp);
  yield takeEvery(AT.GET_MY_GOODS, sagas.getMyGoods);

  // 1:1 문의
  yield takeEvery(AT.GET_MY_QNA_LIST, sagas.getMyQNAList);
}
