import { useEffect } from 'react';

const useSetDisableSearchInput = ({ isDisabled, setShowResetBtn }) => {
  useEffect(() => {
    if (isDisabled) {
      setShowResetBtn(true);
    } else {
      setShowResetBtn(false);
    }
  }, [isDisabled]);

  return;
};

export default useSetDisableSearchInput;
