import { apiClient, apiClient2 } from '@/apis/clients';
import * as querystring from '@/services/router/querystring';

export const api = {
  // 결제 내역 조회
  async getMyOrderHistory({ page, limit, sortOrder = -1 }) {
    try {
      return await apiClient2.get(`/transaction-api/v1/orders?page=${page}&limit=${limit}&sortOrder=${sortOrder}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * [결제 예약] 사용자 직접 결제 > token 인증 / 시스템 자동 결제 > apiKey 인증
   * @param payload { productId, pgType = 'TOSS', benefitId }
   */
  async postOrder(payload) {
    try {
      return await apiClient2.post(`/transaction-api/v1/orders`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * [자동결제 실행 요청] 사용자 직접 결제 > token 인증 / 시스템 자동 결제 > apiKey 인증
   * @param orderId
   * @param paylaod { billingKey }
   */
  async patchOrder(orderId, payload) {
    try {
      return await apiClient2.patch(`/transaction-api/v1/orders/${orderId}/auto-billing`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 멤버십 상품 보유 내역 조회 (유효한 상품만 조회됨)
   */
  async getMyMembership() {
    try {
      return await apiClient2.get(`/transaction-api/v1/memberships/status`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 멤버십 변경 예약 api
   */
  async postMembership(data) {
    try {
      return await apiClient2.post(`/transaction-api/v1/memberships/product/reservation`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * @deprecated
   * api 삭제됨. 구독해지 기능이 필요할 때는 다른 api 를 사용해야 함.
   *
   * 멤버십 상태 변경 - 현재 구독해지로 사용중
   * @param productId
   */
  async patchMembershipState(productId) {
    const payload = { isActive: false };

    try {
      return await apiClient2.patch(`/transaction-api/v1/memberships/${productId}/status`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 멤버십 상품 변경
   * @param productId
   * @param payload {
                      "billingKey": "string",
                      "pgType": "TOSS",
                      "benefitId": "string",
                      "nextProductId": "string"
                    }
   */
  async patchMembershipProduct({ productId, payload }) {
    try {
      return await apiClient2.patch(`/transaction-api/v1/memberships/${productId}/product`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 결제수단 조회
   */
  async getPaymentMethod(id) {
    try {
      return await apiClient2.get(`/billing-api/v1/users/${id}/payment-methods`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 결제수단 등록
   * @param payload { authKey }
   */
  async postPaymentMethod({ id, payload }) {
    try {
      return await apiClient2.post(`/billing-api/v1/users/${id}/payment-methods`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 결제수단 삭제
   * @param payload { billingKey }
   */
  async deletePaymentMethod(mbrIdx, billingKey) {
    try {
      return await apiClient2.delete(`/billing-api/v1/users/${mbrIdx}/payment-methods/${encodeURIComponent(billingKey)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 결제수단 벌크 삭제
   * @param payload { billingKey }
   */
  async deletePaymentMethodBulk(mbrIdx, billingKeyList) {
    try {
      return await apiClient2.post(`/billing-api/v1/users/${mbrIdx}/delete/payment-methods`, billingKeyList);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * Get clinet key
   */
  async getTossClientKey() {
    try {
      return await apiClient2.get(`/billing-api/v1/client-keys`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // POST
  // /api/billing-api/v1/orders/{id}
  // Request order

  // GET
  // /api/billing-api/v1/orders/{id}
  // Get order

  // POST
  // /api/billing-api/v1/orders/{id}/cancel
  // Cancel order

  /**
   * Get valid user benefits
   */
  async getBenefits({ limit, offset }) {
    try {
      return await apiClient2.get(`/benefit-api/v1/users/self/benefits?limit=${limit}&offset=${offset}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * Bulk get product user benefits
   * @param productIds { productId, pgType = 'TOSS', benefitId }
   */
  async getBenefitByProductIds({ productIds }) {
    try {
      return await apiClient2.post(`/benefit-api/v1/products/bulk/users/self/benefits`, { productIds, onlyBest: true });
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * Use User benefit
   * @param id
   * @param payload { orderId }
   */
  async patchBenefit({ id, payload }) {
    try {
      return await apiClient2.patch(`/benefit-api/v1/users/self/benefits/${id}`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * Get products
   * @param {String} name
   * @param {String} type PU | BU
   * @param {String} buyCategory ONCE | SUB
   * @param {String} packageId
   * @returns
   */
  async getProducts(products) {
    try {
      return await apiClient2.post(`/transaction-api/v1/orders/expected-price/bulk/products`, { products });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 임시 - 멤버십 조회
  async getTempMembership() {
    // 응답 예시
    // {
    //   "mbrIdx":99857,
    //   "membership":"BASIC", // 등록된 membership 이 없는 경우 "FREE"를 입력
    //   "updateDate":1671517042000
    // }
    try {
      return await apiClient.get('/broker-my/temp/membership/self');
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 임시 - 멤버십 등록
  async postTempMembership(payload) {
    try {
      // payload {
      //     "membership": "BASIC" // membership은 BASIC / PLUS / PREMIUM 입력해주시면 됩니다.
      // }
      return await apiClient.post('/broker-my/temp/membership/self', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 현성님이, API 작성중..!! endpoint또는 내용은 변경될수있음
  async requestPayment({ amount, orderId, paymentKey, username, phone }) {
    try {
      return await apiClient.post('/payment/request-payment', {
        amount,
        orderId,
        paymentKey,
        username,
        phone,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 현성님이, API 작성중..!! endpoint또는 내용은 변경될수있음
  async cancelPayment({ paymentKey, orderId }) {
    try {
      return await apiClient.post('/payment/cancel-payment', {
        paymentKey,
        orderId,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 현성님이, API 작성중..!! endpoint또는 내용은 변경될수있음
  async getPaymentList({ paymentKey, orderId }) {
    try {
      return await apiClient.post('/payment/order-list', {
        paymentKey,
        orderId,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },
};
