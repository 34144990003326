import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { objectToQuerystring } from '@/services/Helper';

const usePostWithOutLogIn = ({ query }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      const currentPath = `${location.pathname}${location.search}`;

      // 로그인 되어있지 않은 경우 입력창을 닫음
      delete query.createPoint;
      dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));

      // 로그인 요청. 로그인 성공 시 다시 구해줘 다윈으로
      const onSuccess = () => dispatch(actions.router.push(currentPath));
      const option = { onSuccess };
      dispatch(actions.common.pleaseLoginPopup(true, option));
    }
  }, [isLoggedIn]);
  return;
};

export default usePostWithOutLogIn;
