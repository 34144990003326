import * as brokerOfficeInfo from './brokerOfficeInfo/selectors';
import * as bkQnA from './bkQnA/selectors';
import * as jointBrokerage from './jointBrokerage/selectors';
import * as mgCounseling from './mgCounseling/selectors';
import * as mgCustomer from './mgCustomer/selectors';
import * as mgContract from './mgContract/selectors';
import * as mgGoods from './mgGoods/selectors';
import * as mgQa from './mgQa/selectors';
import * as mgRate from './mgRate/selectors';
import * as mgComplexImg from './mgComplexImg/selectors';
import * as dataRoom from './dataRoom/selectors';
import * as suggestion from './suggestion/selectors';
import * as notice from './notice/selectors';
import * as internalNotice from './internalNotice/selectors';
import * as oneOnOne from './oneOnOne/selectors';
import * as setArea from './setArea/selectors';
import * as dailyReport from './dailyReport/selectors';
import * as community from './community/selectors';

export { brokerOfficeInfo };
export { bkQnA };
export { jointBrokerage };
export { mgCounseling };
export { mgCustomer };
export { mgGoods };
export { mgContract };
export { mgQa };
export { mgRate };
export { mgComplexImg };
export { dataRoom };
export { suggestion };
export { notice };
export { internalNotice };
export { oneOnOne };
export { setArea };
export { dailyReport };
export { community };

export const getERROR = (state) => state?.brokerPage?.error;

export const getBrokerAlarmList = (state) => state?.brokerPage?.brokerAlarmList;
export const getDeleteAlarm = (state) => state?.brokerPage?.deleteAlarm;
export const getDeleteRAAlarm = (state) => state?.brokerPage?.deleteRAAlarm;

export const getNotice = (state) => state?.brokerPage?.notice;
export const getBoardNew = (state) => state?.brokerPage?.board_new;
