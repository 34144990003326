import * as AT from '../actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';
import webNotificationReducer from './webNotificationReducer';

const INITIAL_STATE = {
  currentTab: null,
  goods: Remote.NotAsked,
  //   webNotification: [], // 웹 알림 (앱 푸시알림과는 다름) useWebNotification hook 참조
};

export default function myRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_MY_KEYWORD: {
      return {
        ...state,
        keyword: action.val,
      };
    }

    case AT.CHANGE_MY_HOME_STATUS: {
      return {
        ...state,
        myHomeStatus: action.val,
      };
    }
    case AT.MY_HOME_DATA_SORTING: {
      var min_home_Indx = 0;
      var max_home_Indx = state.myHomeData.length - 1;

      if (max_home_Indx < action.toIndex) {
        return { ...state };
      }
      if (action.toIndex < min_home_Indx) {
        return { ...state };
      }
      state.myHomeData.splice(action.toIndex, 0, state.myHomeData.splice(action.fromIndex, 1)[0]);
      return {
        ...state,
      };
    }
    case AT.MY_COMPLEX_DATA_SORTING: {
      var min_cp_Indx = 0;
      var max_cp_Indx = state.myCpsData.length - 1;

      if (max_cp_Indx < action.toIndex) {
        return { ...state };
      }
      if (action.toIndex < min_cp_Indx) {
        return { ...state };
      }
      state.myCpsData.splice(action.toIndex, 0, state.myCpsData.splice(action.fromIndex, 1)[0]);
      return {
        ...state,
      };
    }
    case AT.MY_AREA_DATA_SORTING: {
      var min_area_Indx = 0;
      var max_area_Indx = state.myAreaData.length - 1;

      if (max_area_Indx < action.toIndex) {
        return { ...state };
      }
      if (action.toIndex < min_area_Indx) {
        return { ...state };
      }
      state.myAreaData.splice(action.toIndex, 0, state.myAreaData.splice(action.fromIndex, 1)[0]);
      return {
        ...state,
      };
    }
    case AT.CHANGE_MY_AREA_STATUS: {
      return {
        ...state,
        myAreaStatus: action.val,
      };
    }
    case AT.CHANGE_MY_CP_STATUS: {
      return {
        ...state,
        myCpStatus: action.val,
      };
    }
    case AT.CHANGE_MY_HOME_PRE_STATUS: {
      return {
        ...state,
        myHomePreStatus: action.val,
      };
    }
    case AT.CHANGE_MY_AREA_PRE_STATUS: {
      return {
        ...state,
        myAreaPreStatus: action.val,
      };
    }
    case AT.CHANGE_MY_CP_PRE_STATUS: {
      return {
        ...state,
        myCpPreStatus: action.val,
      };
    }
    case AT.GET_MY_HOME_DATA: {
      return {
        ...state,
      };
    }
    case AT.GET_MY_HOMES_DATA: {
      return {
        ...state,
      };
    }
    case AT.UPDATE_SELECTED_MY_COMPLEX: {
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.val,
          complex_name: action.val?.complex?.complex_name ? action.val?.complex?.complex_name : action.val?.complex_name,
          selectedComplex: {
            ...action.val,
            complex_name: action.val?.complex?.complex_name ? action.val?.complex?.complex_name : action.val?.complex_name,
          },
        },
      };
    }

    case loading(AT.GET_UNREAD_NOTI_COUNT):
      return { ...state, [AT.GET_UNREAD_NOTI_COUNT]: Remote.Loading };
    case success(AT.GET_UNREAD_NOTI_COUNT): {
      return { ...state, notiCount: action.data };
    }
    case failure(AT.GET_UNREAD_NOTI_COUNT):
      return { ...state, [AT.GET_UNREAD_NOTI_COUNT]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_MY_HOME_DATA):
      return { ...state, [AT.GET_MY_HOME_DATA]: Remote.Loading };
    case success(AT.GET_MY_HOME_DATA): {
      return { ...state, selected: action.data };
    }
    case failure(AT.GET_MY_HOME_DATA):
      return { ...state, [AT.GET_MY_HOME_DATA]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_MY_INTEREST_GOODS):
      return { ...state, [AT.GET_MY_INTEREST_GOODS]: Remote.Loading };
    case success(AT.GET_MY_INTEREST_GOODS): {
      return { ...state, myInterestGoods: action.data };
    }
    case failure(AT.GET_MY_INTEREST_GOODS):
      return { ...state, [AT.GET_MY_INTEREST_GOODS]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_MY_HOMES_DATA):
      return { ...state, [AT.GET_MY_HOMES_DATA]: Remote.Loading };
    case success(AT.GET_MY_HOMES_DATA): {
      return { ...state, myHomeData: action.data };
    }
    case failure(AT.GET_MY_HOMES_DATA):
      return { ...state, [AT.GET_MY_HOMES_DATA]: Remote.Failure(action.error), error: action.error };

    case loading(AT.SAVE_MY_HOME):
      return { ...state, [AT.SAVE_MY_HOME]: Remote.Loading };
    case success(AT.SAVE_MY_HOME): {
      return { ...state, selected: '' };
    }
    case failure(AT.SAVE_MY_HOME):
      return { ...state, [AT.SAVE_MY_HOME]: Remote.Failure(action.error), error: action.error };

    case loading(AT.SAVE_MODIFY_MY_HOME):
      return { ...state, [AT.SAVE_MODIFY_MY_HOME]: Remote.Loading };
    case success(AT.SAVE_MODIFY_MY_HOME): {
      return { ...state, selected: '' };
    }
    case failure(AT.SAVE_MODIFY_MY_HOME):
      return { ...state, [AT.SAVE_MODIFY_MY_HOME]: Remote.Failure(action.error), error: action.error };

    case loading(AT.DELETE_MY_HOME):
      return { ...state, [AT.DELETE_MY_HOME]: Remote.Loading };
    case success(AT.DELETE_MY_HOME): {
      return { ...state, selected: '' };
    }
    case failure(AT.DELETE_MY_HOME):
      return { ...state, [AT.DELETE_MY_HOME]: Remote.Failure(action.error), error: action.error };

    //관심지역 설정
    case AT.SET_MY_B_DONG_CODE: {
      return {
        ...state,
        newArea: action.b_dong_code,
      };
    }
    case loading(AT.GET_MY_AREA_DATA):
      return { ...state, [AT.GET_MY_AREA_DATA]: Remote.Loading };
    case success(AT.GET_MY_AREA_DATA): {
      return { ...state, selected: action.data };
    }
    case failure(AT.GET_MY_AREA_DATA):
      return { ...state, [AT.GET_MY_AREA_DATA]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_MY_AREAS_DATA):
      return { ...state, [AT.GET_MY_AREAS_DATA]: Remote.Loading };
    case success(AT.GET_MY_AREAS_DATA): {
      return { ...state, myAreaData: action.data };
    }
    case failure(AT.GET_MY_AREAS_DATA):
      return { ...state, [AT.GET_MY_AREAS_DATA]: Remote.Failure(action.error), error: action.error };
    case loading(AT.SAVE_MY_AREA):
      return { ...state, [AT.SAVE_MY_AREA]: Remote.Loading };
    case success(AT.SAVE_MY_AREA): {
      return { ...state, selected: '' };
    }
    case failure(AT.SAVE_MY_AREA):
      return { ...state, [AT.SAVE_MY_AREA]: Remote.Failure(action.error), error: action.error };

    case loading(AT.DELETE_MY_AREA):
      return { ...state, [AT.DELETE_MY_AREA]: Remote.Loading };
    case success(AT.DELETE_MY_AREA): {
      return { ...state, selected: '' };
    }
    case failure(AT.DELETE_MY_AREA):
      return { ...state, [AT.DELETE_MY_AREA]: Remote.Failure(action.error), error: action.error };

    //관심단지 설정
    case AT.SET_MY_CP: {
      return {
        ...state,
        myCpData: { ...state.myCpData, newCp: action.idx },
      };
    }
    // case loading(AT.GET_MY_CP_DATA):
    //   return { ...state, [AT.GET_MY_CP_DATA]: Remote.Loading };
    // case success(AT.GET_MY_CP_DATA): {
    //   return { ...state, selected: action.data };
    // }
    // case failure(AT.GET_MY_CP_DATA):
    //   return { ...state, [AT.GET_MY_CP_DATA]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_MY_CPS_DATA):
      return { ...state, [AT.GET_MY_CPS_DATA]: Remote.Loading };
    case success(AT.GET_MY_CPS_DATA):
      return { ...state, myCpsData: action.data };
    case failure(AT.GET_MY_CPS_DATA):
      return { ...state, [AT.GET_MY_CPS_DATA]: Remote.Failure(action.error), error: action.error };

    case loading(AT.SAVE_MY_CP):
      return { ...state, [AT.SAVE_MY_CP]: Remote.Loading };
    case success(AT.SAVE_MY_CP):
      return { ...state, selected: '' };
    case failure(AT.SAVE_MY_CP):
      return { ...state, [AT.SAVE_MY_CP]: Remote.Failure(action.error), error: action.error };

    case loading(AT.DELETE_MY_CP):
      return { ...state, [AT.DELETE_MY_CP]: Remote.Loading };
    case success(AT.DELETE_MY_CP):
      return { ...state, selected: '' };
    case failure(AT.DELETE_MY_CP):
      return { ...state, [AT.DELETE_MY_CP]: Remote.Failure(action.error), error: action.error };
    case AT.SET_CURRENT_TAB: {
      return { ...state, currentTab: action.tabType };
    }
    case loading(AT.GET_MY_GOODS):
      return { ...state, goods: Remote.Loading };
    case success(AT.GET_MY_GOODS):
      return { ...state, goods: Remote.Success(action.payload) };
    case failure(AT.GET_MY_GOODS):
      return { ...state, goods: Remote.Failure(action.error) };
    default: {
      return { ...state, webNotification: webNotificationReducer(state.webNotification, action) };
    }
  }
}
