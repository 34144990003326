import * as AT from './actionTypes';

const INITIAL_STATE = {};

export default function chat(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_CONTROLLER:
      return { ...state, controller: action.payload };
    case AT.SET_CHAT_DATA:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
}
