export const codes = {
  s: 'S',
  se: 'SE',
  e: 'E',
  ne: 'NE',
  n: 'N',
  nw: 'NW',
  w: 'W',
  sw: 'SW',
};

const _names = {
  [codes.s]: '남',
  [codes.se]: '남동',
  [codes.e]: '동',
  [codes.ne]: '북동',
  [codes.n]: '북',
  [codes.nw]: '북서',
  [codes.w]: '서',
  [codes.sw]: '남서',
};

export const getName = (code) => {
  return _names[code];
};

export default {
  codes,
  getName,
};
