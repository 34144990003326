import React from 'react';
import { call, put, select, delay } from 'redux-saga/effects';
import { actions, selectors } from '@/data';
import apis from '@/apis';
import { objectToQuerystring } from '@/apis/helpers';

export function* getInterestComplex({ setData, dongCode, goods, recentTrade }) {
  try {
    // console.log(isBasic);
    const params = {};
    if (dongCode) params.dongCode = dongCode;
    if (goods) params.goods = goods;
    if (recentTrade) params.recentTrade = recentTrade;
    const querystring = objectToQuerystring(params);

    const result = yield call(apis.personalizeApi.getInterestComplex, querystring);
    setData(result);
  } catch (e) {
    //
  }
}
