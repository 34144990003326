import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import { FeedType } from '@/services/Community';

const useCheckValidity = ({ feed }) => {
  const isBroker = useSelector(selectors.auth.getUserInfo).isBroker;
  const isPostForBuy = feed?.feedType === FeedType.POST_FOR_BUY;
  const isFeedDeleted = feed?.status === 'DELETED';
  const isLeftWriter = feed?.writer?.status !== 'NM' && feed?.writer?.status !== 'DO';

  return isBroker && isPostForBuy && !isFeedDeleted && !isLeftWriter;
};

export default useCheckValidity;
