import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//요약본
export const getList = () => ({
  type: AT.GET_SUGGESTION_LIST,
});
export const getDetail = (idx) => ({
  type: AT.GET_SUGGESTION_DETAIL,
  idx,
});
export const setAttach = (idx, file_name) => ({
  type: AT.SET_SUGGESTION_NEW_ATTACH,
  idx,
  file_name,
});
export const setTitle = (val) => ({
  type: AT.SET_SUGGESTION_NEW_TITLE,
  val,
});
export const setStartDate = (val) => ({
  type: AT.SET_START_DATE,
  val,
});
export const setEndDate = (val) => ({
  type: AT.SET_END_DATE,
  val,
});
export const setCategory = (val) => ({
  type: AT.SET_SUGGESTION_NEW_CATEGORY,
  val,
});
export const setCont = (val) => ({
  type: AT.SET_SUGGESTION_NEW_CONT,
  val,
});
export const setKeyword = (keyword) => ({
  type: AT.SET_SUGGESTION_KEYWORD,
  keyword,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_SUGGESTION_CURRENT_PAGE,
  page,
});
export const setTotalCnt = (totalCnt) => ({
  type: AT.SET_SUGGESTION_TOTAL_CNT,
  totalCnt,
});
export const saveNew = () => ({
  type: AT.SAVE_SUGGESTION_NEW,
});
export const getSuggestionListLoading = () => ({
  type: AT.GET_SUGGESTION_LIST_LOADING,
});
export const getSuggestionListSuccess = (conlist) => ({
  type: AT.GET_SUGGESTION_LIST_SUCCESS,
  conlist,
});
export const getSuggestionListFailure = (error) => ({
  type: AT.GET_SUGGESTION_LIST_FAILURE,
  error,
});

export const getSuggestionDetailLoading = () => ({
  type: AT.GET_SUGGESTION_DETAIL_LOADING,
});
export const getSuggestionDetailSuccess = (detail) => ({
  type: AT.GET_SUGGESTION_DETAIL_SUCCESS,
  detail,
});
export const getSuggestionDetailFailure = (error) => ({
  type: AT.GET_SUGGESTION_DETAIL_FAILURE,
  error,
});
