import React from 'react';
import { Link } from 'react-router-dom';

const RealtorBtn = () => {
  return (
    <li className="navi-item">
      <Link className="navi-name agent" to="/brokerPage">
        공인중개사
      </Link>
    </li>
  );
};

export default RealtorBtn;
