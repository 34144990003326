import React from 'react';
import { STATUS } from '@/data/utils';
import useAddress from '@/components/Address/useAddress/index.js';

/**
 *
 * @param {Object} data { sido, sigungu, sigungu1, sigungu2, dong, dong1, dong2 }
 *  sigungu는 sigungu1, sigungu2 중 마지막 값
 *    ex) sigungu1: 경기도 성남시, sigungu2: 성남시 수정구
 *  dong은 dong1, dong2 중 마지막 값
 *    ex) dong1: 충청북도 괴산군 괴산읍, don2: 검승리
 * @param {function} setData
 * @param {function} showGlobalSpinner 리스트 조회 API 처리 중 전체 화면 스피너 표출 여부
 * @param {boolean} tenDigitCode true이면 address 값 반환 시 10자리로 반환 (집내놓기 부분에 )
 */
export default function Address({
  data,
  setData,
  onlyAvailable = true,
  disabled = false,
  showGlobalSpinner,
  addAllOptionOnSido = false,
  addAllOptionOnSigungu = false,
  addAllOptionOnDong = false,
  tenDigitCode,
  refresh = false,
  initialCode,
}) {
  const {
    sidoCode,
    sigunguCode1,
    sigunguCode2,
    dongCode1,
    dongCode2,
    sidoData,
    sigunguData1,
    sigunguData2,
    dongData1,
    dongData2,
    siDoOptions,
    siGunGuOptionsFirst,
    siGunGuOptionsSecond,
    dongRiOptionsFirst,
    dongRiOptionsSecond,
    onSiDoSelect,
    onSiGunGuSelectFirst,
    onSiGunGuSelectSecond,
    onDongRiSelectFirst,
    onDongRiSelectSecond,
  } = useAddress({
    data,
    setData,
    onlyAvailable,
    showGlobalSpinner,
    addAllOptionOnSido,
    addAllOptionOnSigungu,
    addAllOptionOnDong,
    tenDigitCode,
    refresh,
    initialCode,
  });

  const formStyle = disabled ? { cursor: 'no-drop' } : {};
  const rowStyle = disabled ? { filter: 'grayscale(1) opacity(0.5)' } : {};

  return (
    <div style={rowStyle}>
      <span className="ins">
        <div className="inp-horiz">
          <select
            id=""
            title="시/도 선택"
            value={sidoCode}
            className="selec"
            onChange={onSiDoSelect}
            disabled={sidoData.status === STATUS.LOADING || disabled}
            style={formStyle}>
            {siDoOptions}
          </select>

          {sidoCode != '36' && (
            <select
              id=""
              title="시/군/구 선택"
              value={sigunguCode1}
              className="selec"
              onChange={onSiGunGuSelectFirst}
              disabled={sigunguData1.status === STATUS.LOADING || disabled}
              style={formStyle}>
              {siGunGuOptionsFirst}
            </select>
          )}

          {sigunguData2?.list?.length > 0 && (
            <select
              id=""
              title="구 선택"
              value={sigunguCode2}
              className="selec"
              onChange={onSiGunGuSelectSecond}
              disabled={sigunguData2.status === STATUS.LOADING || disabled}
              style={formStyle}>
              {siGunGuOptionsSecond}
            </select>
          )}

          <select
            id=""
            title="읍/면/동 선택"
            value={dongCode1}
            className="selec"
            onChange={onDongRiSelectFirst}
            disabled={dongData1.status === STATUS.LOADING || disabled}
            style={formStyle}>
            {dongRiOptionsFirst}
          </select>

          {dongData2?.list?.length > 0 && (
            <select
              id=""
              title="동/리 선택"
              value={dongCode2}
              className="selec"
              onChange={onDongRiSelectSecond}
              disabled={dongData2.status === STATUS.LOADING || disabled}
              style={formStyle}>
              {dongRiOptionsSecond}
            </select>
          )}
        </div>
      </span>
    </div>
  );
}
