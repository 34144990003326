import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

const Mypage = () => {
  const isBroker = useSelector(selectors.auth.getUserInfo)?.isBroker;

  return (
    <li className="navi-item">
      <Link className="navi-name my-page" to={isBroker ? '/brokerPage' : '/my'}>
        마이페이지
      </Link>
    </li>
  );
};

export default Mypage;
