import React from 'react';

export default {
  // 거래타입 별 클래스명
  classname_by_tradetype: { 중개거래: 'type01', 직거래: 'type04', 갱신: 'type02', 신규: 'type03' },

  // 거래타입별 표출 문구
  text_by_tradetype: { 중개거래: '중개', 직거래: '직거래', 갱신사용: '갱신권', 갱신: '재계약', 신규: '신규' },

  // 실거래 테이블 > 가격 > 매매인 경우 설명
  price_column_help_for_s: (
    <>
      <span className="trade-tag type01">중개</span> : 중개사를 통한 중개거래
      <br />
      <span className="trade-tag type04">직거래</span> : 거래 당사자 간의 직접 거래
      <br />
      <br />
      * 국토교통부 출처
      <br />* 21년 11월 1일 이후 거래분만 제공
    </>
  ),

  // 실거래 테이블 > 가격 > 전세, 월세인 경우 설명
  price_column_help_for_c_m: (
    <>
      <span className="trade-tag type03">신규</span> : 신규로 체결한 계약
      <br />
      <span className="trade-tag type02">재계약</span> : 갱신계약 중 갱신요구권을 사용하지 않은 계약
      <br />
      <span className="trade-tag type02">갱신권</span> : 갱신계약 중 갱신요구권을 사용한 계약
      <br />
      <br />
      * 국토교통부 출처
      <br />
      * 21년 6월 1일 이후 체결된 임대차 계약에만 제공
      <br />
      * 구분 정보는 집계 방식에 따라 최대 2달이 소요될 수 있습니다.
      <br />* 미신고 계약은 정보가 표시되지 않을 수 있습니다.
    </>
  ),
};

// "trade_method": "중개거래",
// "broker_location": "경남 거제시",
// "prev_deposit": "70,000",
// "prev_monthly": "51",
// "contract_term": "22.02~23.02",
// "contract_type": "갱신",
// "use_renewal_right": "사용"
