import { failure, loading, success } from '@/data/utils';

export const SET_MY_KEYWORD = 'MY/SET_MY_KEYWORD';

//우리집설정
export const CHANGE_MY_HOME_STATUS = 'MY/CHANGE_MY_HOME_STATUS';
export const CHANGE_MY_HOME_PRE_STATUS = 'MY/CHANGE_MY_HOME_PRE_STATUS';
export const UPDATE_SELECTED_MY_COMPLEX = 'MY/UPDATE_SELECTED_MY_COMPLEX';

export const MY_HOME_DATA_SORTING = 'MY/MY_HOME_DATA_SORTING';
export const MY_COMPLEX_DATA_SORTING = 'MY/MY_COMPLEX_DATA_SORTING';
export const MY_AREA_DATA_SORTING = 'MY/MY_AREA_DATA_SORTING';

export const GET_UNREAD_NOTI_COUNT = 'MY/GET_UNREAD_NOTI_COUNT';
export const GET_UNREAD_NOTI_COUNT_SUCCESS = success(GET_UNREAD_NOTI_COUNT);
export const GET_UNREAD_NOTI_COUNT_FAILURE = failure(GET_UNREAD_NOTI_COUNT);
export const GET_UNREAD_NOTI_COUNT_LOADING = loading(GET_UNREAD_NOTI_COUNT);

export const GET_MY_INTEREST_GOODS = 'MY/GET_MY_INTEREST_GOODS';
export const GET_MY_INTEREST_GOODS_SUCCESS = success(GET_MY_INTEREST_GOODS);
export const GET_MY_INTEREST_GOODS_FAILURE = failure(GET_MY_INTEREST_GOODS);
export const GET_MY_INTEREST_GOODS_LOADING = loading(GET_MY_INTEREST_GOODS);
export const GET_MY_HOMES_DATA = 'MY/GET_MY_HOMES_DATA';
export const GET_MY_HOMES_DATA_SUCCESS = success(GET_MY_HOMES_DATA);
export const GET_MY_HOMES_DATA_FAILURE = failure(GET_MY_HOMES_DATA);
export const GET_MY_HOMES_DATA_LOADING = loading(GET_MY_HOMES_DATA);
export const GET_MY_HOME_DATA = 'MY/GET_MY_HOME_DATA';
export const GET_MY_HOME_DATA_SUCCESS = success(GET_MY_HOME_DATA);
export const GET_MY_HOME_DATA_FAILURE = failure(GET_MY_HOME_DATA);
export const GET_MY_HOME_DATA_LOADING = loading(GET_MY_HOME_DATA);
export const SAVE_MY_HOME = 'MY/SAVE_MY_HOME';
export const SAVE_MY_HOME_SUCCESS = success(SAVE_MY_HOME);
export const SAVE_MY_HOME_FAILURE = failure(SAVE_MY_HOME);
export const SAVE_MY_HOME_LOADING = loading(SAVE_MY_HOME);
export const SAVE_MODIFY_MY_HOME = 'MY/SAVE_MODIFY_MY_HOME';
export const SAVE_MODIFY_MY_HOME_SUCCESS = success(SAVE_MODIFY_MY_HOME);
export const SAVE_MODIFY_MY_HOME_FAILURE = failure(SAVE_MODIFY_MY_HOME);
export const SAVE_MODIFY_MY_HOME_LOADING = loading(SAVE_MODIFY_MY_HOME);
export const DELETE_MY_HOME = 'MY/DELETE_MY_HOME';
export const DELETE_MY_HOME_SUCCESS = success(DELETE_MY_HOME);
export const DELETE_MY_HOME_FAILURE = failure(DELETE_MY_HOME);
export const DELETE_MY_HOME_LOADING = loading(DELETE_MY_HOME);

//관심지역 설정
export const CHANGE_MY_AREA_STATUS = 'MY/CHANGE_MY_AREA_STATUS';
export const CHANGE_MY_AREA_PRE_STATUS = 'MY/CHANGE_MY_AREA_PRE_STATUS';
export const SET_MY_B_DONG_CODE = 'MY/SET_MY_B_DONG_CODE';
//export const UPDATE_SELECTED_MY_COMPLEX = 'MY/UPDATE_SELECTED_MY_COMPLEX';

export const GET_MY_AREAS_DATA = 'MY/GET_MY_AREAS_DATA';
export const GET_MY_AREAS_DATA_SUCCESS = success(GET_MY_AREAS_DATA);
export const GET_MY_AREAS_DATA_FAILURE = failure(GET_MY_AREAS_DATA);
export const GET_MY_AREAS_DATA_LOADING = loading(GET_MY_AREAS_DATA);
export const GET_MY_AREA_DATA = 'MY/GET_MY_AREA_DATA';
export const GET_MY_AREA_DATA_SUCCESS = success(GET_MY_AREA_DATA);
export const GET_MY_AREA_DATA_FAILURE = failure(GET_MY_AREA_DATA);
export const GET_MY_AREA_DATA_LOADING = loading(GET_MY_AREA_DATA);
export const SAVE_MY_AREA = 'MY/SAVE_MY_AREA';
export const SAVE_MY_AREA_SUCCESS = success(SAVE_MY_AREA);
export const SAVE_MY_AREA_FAILURE = failure(SAVE_MY_AREA);
export const SAVE_MY_AREA_LOADING = loading(SAVE_MY_AREA);
export const DELETE_MY_AREA = 'MY/DELETE_MY_AREA';
export const DELETE_MY_AREA_SUCCESS = success(DELETE_MY_AREA);
export const DELETE_MY_AREA_FAILURE = failure(DELETE_MY_AREA);
export const DELETE_MY_AREA_LOADING = loading(DELETE_MY_AREA);

//관심단지 설정
export const CHANGE_MY_CP_STATUS = 'MY/CHANGE_MY_CP_STATUS';
export const CHANGE_MY_CP_PRE_STATUS = 'MY/CHANGE_MY_CP_PRE_STATUS';
export const SET_MY_CP = 'MY/SET_MY_CP';
//export const UPDATE_SELECTED_MY_COMPLEX = 'MY/UPDATE_SELECTED_MY_COMPLEX';

export const GET_MY_CPS_DATA = 'MY/GET_MY_CPS_DATA';
export const GET_MY_CPS_DATA_SUCCESS = success(GET_MY_CPS_DATA);
export const GET_MY_CPS_DATA_FAILURE = failure(GET_MY_CPS_DATA);
export const GET_MY_CPS_DATA_LOADING = loading(GET_MY_CPS_DATA);
export const GET_MY_CP_DATA = 'MY/GET_MY_CP_DATA';
export const GET_MY_CP_DATA_SUCCESS = success(GET_MY_CP_DATA);
export const GET_MY_CP_DATA_FAILURE = failure(GET_MY_CP_DATA);
export const GET_MY_CP_DATA_LOADING = loading(GET_MY_CP_DATA);
export const SAVE_MY_CP = 'MY/SAVE_MY_CP';
export const SAVE_MY_CP_SUCCESS = success(SAVE_MY_CP);
export const SAVE_MY_CP_FAILURE = failure(SAVE_MY_CP);
export const SAVE_MY_CP_LOADING = loading(SAVE_MY_CP);
export const DELETE_MY_CP = 'MY/DELETE_MY_CP';
export const DELETE_MY_CP_SUCCESS = success(DELETE_MY_CP);
export const DELETE_MY_CP_FAILURE = failure(DELETE_MY_CP);
export const DELETE_MY_CP_LOADING = loading(DELETE_MY_CP);

export const SET_CURRENT_TAB = 'MY/SET_CURRENT_TAB';

// 내가 내놓은 매물
export const GET_MY_GOODS = 'GET_MY_GOODS';
export const GET_MY_GOODS_SUCCESS = success(GET_MY_GOODS);
export const GET_MY_GOODS_FAILURE = failure(GET_MY_GOODS);
export const GET_MY_GOODS_LOADING = loading(GET_MY_GOODS);

// 웹 알림 (앱 푸시알림과는 다름) useWebNotification hook 참조
export const ADD_WEB_NOTIFICATION = 'ADD_WEB_NOTIFICATION';
export const UPDATE_WEB_NOTIFICATION = 'UPDATE_WEB_NOTIFICATION';
export const CLEAN_WEB_NOTIFICATION = 'CLEAN_WEB_NOTIFICATION';

// 1:1 문의
export const GET_MY_QNA_LIST = 'MY/GET_MY_QNA_LIST';
