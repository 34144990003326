import * as AT from './actionTypes';

// export const setError = (error) => ({
//   type: AT.SET_ERROR,
//   error,
// });

export const getNewsales = (data) => ({
  type: AT.GET_NEWSALES,
  data,
});

export const getNewsalesLoading = () => ({
  type: AT.GET_NEWSALES_LOADING,
});

export const getNewsalesSuccess = (data) => ({
  type: AT.GET_NEWSALES_SUCCESS,
  data,
});

export const getNewsalesFailure = (error) => ({
  type: AT.GET_NEWSALES_FAILURE,
  error,
});

export const getRebuilds = (data) => ({
  type: AT.GET_REBUILDS,
  data,
});

export const getRebuildsLoading = () => ({
  type: AT.GET_REBUILDS_LOADING,
});

export const getRebuildsSuccess = (data) => ({
  type: AT.GET_REBUILDS_SUCCESS,
  data,
});

export const getRebuildsFailure = (error) => ({
  type: AT.GET_REBUILDS_FAILURE,
  error,
});

export const selectItem = (complex_idx) => ({
  type: AT.NEWSALE_SELECT_ITEM,
  complex_idx,
});

export const getNewsalePrice = (complex_idx) => ({
  type: AT.GET_NEWSALE_PRICE,
  complex_idx,
});

export const setNewsalePrice = (list) => ({
  type: AT.SET_NEWSALE_PRICE,
  list,
});

export const getNewsaleSchedule = (complex_idx) => ({
  type: AT.GET_NEWSALE_SCHEDULE,
  complex_idx,
});

export const setNewsaleSchedule = (list) => ({
  type: AT.SET_NEWSALE_SCHEDULE,
  list,
});

export const getRebuildSchedule = (complex_idx) => ({
  type: AT.GET_REBUILD_SCHEDULE,
  complex_idx,
});

export const setRebuildSchedule = (data) => ({
  type: AT.SET_REBUILD_SCHEDULE,
  data,
});

export const getNews = (date, category) => ({
  type: AT.GET_NEWS,
  date,
  category,
});

export const setNews = (data) => ({
  type: AT.SET_NEWS,
  data,
});

// 매일경제 뉴스 협조 요청 data
export const getMKLetterNews = (date) => ({
  type: AT.GET_MK_LETTER_NEWS,
  date,
});

export const setMKLetterNews = (data) => ({
  type: AT.SET_MK_LETTER_NEWS,
  data,
});

export const getNewsSearchConditions = () => ({
  type: AT.GET_NEWS_SEARCH_CONDITIONS,
});

export const setNewsSearchConditions = (data) => ({
  type: AT.SET_NEWS_SEARCH_CONDITIONS,
  data,
});
