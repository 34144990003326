import React, { useEffect } from 'react';
import PopupWrapperComp from '@/components/PopupWrapperComp';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import Contents from './Contents';

const ReportBroker = ({ handleClose, isMobile }) => {
  const dispatch = useDispatch();
  const step1State = useSelector(selectors.common.getReportBrokerState);
  const isBusy = step1State === 'Loading';
  const isSuccess = step1State === 'Success';

  useEffect(() => {
    return () => {
      dispatch(actions.common.reportBrokerNotasked());
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (isMobile) {
        dispatch(actions.router.goBack());
      } else {
        dispatch(actions.common.reportBrokerNotasked());
        if (handleClose) {
          handleClose();
        }
      }
    }
  }, [isSuccess]);

  return (
    <div className="report">
      {isMobile ? (
        <Contents isMobile={isMobile} isBusy={isBusy} />
      ) : (
        <PopupWrapperComp title="중개사 신고하기" handleClose={handleClose} type="large">
          <Contents isMobile={isMobile} isBusy={isBusy} />
        </PopupWrapperComp>
      )}
    </div>
  );
};

export default ReportBroker;
