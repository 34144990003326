import { OSTYPE } from '@/services/apps/types/OsType';
import { getPermissionAsBoolean } from '@/services/apps/types/PermissionType';

export default async () => {
  const ostype = window.dawin_instance?.osType;

  try {
    if (ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS) {
      const locationPermission = await window.dawin_instance.bridge.permissionFunction.getLocationPermissionState();

      if (getPermissionAsBoolean(locationPermission)) {
        return true;
      } else {
        const result = await window.dawin_instance.bridge.permissionFunction.requestLocationPermission();

        if (getPermissionAsBoolean(result)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      // throw Error(`OS 타입 오류! ${JSON.stringify({ ostype })}`);
    }
  } catch (e) {
    // throw Error(e.message);
  }
};
