import { STATUS } from '@/data/utils';
import { parseCallBack } from '@/services/Helper';
import { useEffect } from 'react';

const useSetAfterSuccessNicknameChange = ({ data, replace, setShow }) => {
  useEffect(() => {
    const path = location.pathname;
    const query = parseCallBack();
    const nicknameQuery = query.feedDetail;

    if (data?.status === STATUS.SUCCESS) {
      if (path === '/buiyng' || (path === '/community' && !nicknameQuery)) {
        replace();
      } else {
        setShow(false);
      }
    }
  }, [data]);
  return;
};

export default useSetAfterSuccessNicknameChange;
