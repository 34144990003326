import useCommonContact from '@/hooks/useCommonContact';
import subjects from '@/services/contact/chat/subjects';

const useChat = ({ feed, noMembership, hasMembership, userInfo }) => {
  const { chat: commonChat } = useCommonContact(true, userInfo);

  const chat = () => {
    if (hasMembership) {
      commonChat(`${feed?.writer?.id}`, { subjectType: subjects.codes.COMMUNITY_POST_FOR_BUY, subjectId: feed?.id });
    } else {
      noMembership();
    }
  };

  return { chat };
};

export default useChat;
