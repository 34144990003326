const constants = {
  RATING: 'RATING',
  // =================== Rating =================== //
  RATING_BUYER: 'RATING_BUYER',
  RATING_SELLER: 'RATING_SELLER',
  SURVEY_NOMEM: 'SURVEY_NOMEM',

  RATING_BUYER: 'RATING_BUYER',

  // =================== MODAL =================== //
  MODAL_RATING_POSTED: 'MODAL_RATING_POSTED',
  MODAL_RATING_FAILED: 'MODAL_RATING_FAILED',
};

export default constants;
