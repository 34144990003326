import React from 'react';

function getMessageByRequireCheck(require_check) {
  let alertMessage = '';

  switch (require_check) {
    case 'Y': {
      alertMessage = (
        <>
          현재 사무소 정보가 변경된 것으로 확인됩니다. <br />
          마이페이지에서 정보를 갱신해주세요
        </>
      );
      break;
    }
    case 'P': {
      alertMessage = (
        <>
          정보 조회중 오류가 발생했습니다.(error:nsdi)
          <br />
          반복적일 경우, 고객센터로 문의주세요.(1544-6075)
        </>
      );
      break;
    }
    case 'A': {
      alertMessage = (
        <>
          정보 조회중 오류가 발생했습니다.(error:address)
          <br />
          반복적일 경우, 고객센터로 문의주세요.(1544-6075)
        </>
      );
      break;
    }
    case 'N':
    default:
      break;
  }

  return alertMessage;
}

export default getMessageByRequireCheck;
