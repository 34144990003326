import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// 매물리스트 보기로 이동
export const routeToGoodsList = (trade_type, pyeong, isMobile, options) => (dispatch) => {
  const path = getGoodsListPath(trade_type, pyeong, options);
  const watching = location.search.indexOf('d_trade_type') > -1 && location.search.indexOf('d_area') > -1;

  if (watching || !isMobile) {
    dispatch(actions.router.replace(path));
  } else {
    dispatch(actions.router.push(path));
  }
};

// 매물리스트 URL
export const getGoodsListPath = (trade_type, pyeong, options) => {
  const querystring = window.location.search;
  let queryObject = parseCallBack(querystring) || {};
  options?.initial_path && (queryObject = { d_complex: options?.d_complex });
  queryObject.d_trade_type = trade_type;
  queryObject.d_area = pyeong;
  /* 종속되는 파라미터들 제거 */
  // 매물상세
  delete queryObject.d_goods;
  const newQuerystring = objectToQuerystring(queryObject);
  return `${options?.initial_path || location.pathname}${newQuerystring}`;
};
