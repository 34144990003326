import { api } from '@/apis/community';
import { actions, selectors } from '@/data';
import { STATUS } from '@/data/utils';
import { FeedTypeKor, TagType } from '@/services/Community';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFeedId from '../../../hooks/useFeedId';
import CommentWriterInfo from '@/combinedComp/Community/DetailNew/components/CommentArea/Comment/components/commentWriterInfo.js';
import CommentTool from './components/commentTool';
import CommentContents from './components/commentContents';
import CommentLike from './components/CommentLike';
import ReplyArea from '../ReplyArea';
import DeletedComment from '../components/deletedComment';
import { useCheckIsOwner } from '@/combinedComp/Community/hooks';
// import { postsCommentItem } from '@/resources/design/v2/js/bui.template';

const Comment = ({ commentCount, setCommentCount, commentData, feedData }) => {
  const contentRef = useRef();
  const commentRef = useRef();
  const feedId = useFeedId();
  const dispatch = useDispatch();
  const [reply, setReply] = useState(false);
  const [replies, setReplies] = useState(null);
  const [isMore, setIsMore] = useState(commentData.commentCount > 0);
  const [isActiveComment, setActiveComment] = useState('');
  const [comment, setComment] = useState(commentData);
  const [deletedId, setDeletedId] = useState([]);
  const [isMoreReplies, setIsMoreReplies] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [status, setStatus] = useState(STATUS.NOTASKED);
  const [replyCount, setReplyCount] = useState(0);

  useEffect(() => {
    setReplyCount(comment?.commentCount);
    // postsCommentItem('[data-bui-expand="postsCommentItem"]');
  }, []);

  var chat_title = '';

  useEffect(() => {
    chat_title = '#' + FeedTypeKor[commentData?.feedType] + ' ';
    commentData?.tags?.map((ii, kk) => {
      chat_title = chat_title + '#' + ii?.name + ' ';
    });
  }, [commentData]);

  const onReply = () => {
    setReply(!reply);
  };

  const onMoreReplies = async () => {
    if (replies?.next) {
      const body = {
        limit: 10,
        feedId: feedId,
        commentId: comment.id,
        cursor: replies.next,
      };

      const res = await api.getComments(body);
      const updateReplies = {
        data: replies.data.concat(res.data),
        next: res.next,
      };
      setReplies(updateReplies);

      if (res?.data.length < 10) {
        setIsMoreReplies(false);
      } else {
        setIsMoreReplies(true);
      }
    }
  };

  const onDeleteComment = (e, commentIdx, toolMode) => {
    e.preventDefault();
    dispatch(
      actions.common.confirm({
        contents: '글을 삭제하시겠습니까?',
        onConfirm: async () => {
          await api.deleteComment(commentIdx);
          setDeletedId([...deletedId, commentIdx]);
          if (toolMode === 'comment') {
            setCommentCount(commentCount - 1);
          } else {
            setReplyCount(replyCount - 1);
          }
        },
      })
    );
  };

  const onUpdateComment = (e, commentIdx) => {
    e.preventDefault();
    setActiveComment(commentIdx);
  };

  const cancelUpdateComment = () => {
    setActiveComment('');
  };

  const showReplies = async () => {
    if (!showReply) {
      setShowReply(true);
      const body = {
        limit: 10,
        feedId: feedId,
        commentId: comment.id,
      };
      const res = await api.getComments(body);
      setReplies(res);
      setIsMore(false);

      if (res.data.length < 10) {
        setIsMoreReplies(false);
      } else {
        setIsMoreReplies(true);
      }
    } else {
      setShowReply(false);
    }
  };

  const addReplyCount = () => {
    setReplyCount(replyCount + 1);
  };

  const [active, setActive] = useState(false);
  // const mbr_idx = useSelector(selectors.auth.getUserInfo);
  const isSameWriter = feedData?.writer?.id == comment?.writer?.id;
  const isCommentDeleted = comment?.status === 'DELETED' || deletedId.indexOf(comment?.id) > -1;
  const isCommentBlinded = comment?.status === 'BLINDED';
  const isFeedDeleted = feedData?.status === 'DELETED';

  const complexTagInfo = feedData?.tags?.filter((i) => i.type === TagType.COMPLEX_TAG);
  const complexId = complexTagInfo ? complexTagInfo[0]?.key : undefined;
  const writerId = commentData?.writer?.id;
  const isOwner = useCheckIsOwner({ complexId, writerId });

  return (
    <div
      ref={commentRef}
      className={`comment-item ${active ? 'active' : ''} ${isSameWriter ? 'author' : ''} ${isCommentDeleted || isCommentBlinded ? 'deleted' : ''}`}
      data-bui-expand="postsCommentItem">
      <div className="comment-wrap">
        <div className="comment-inform">
          {isCommentDeleted || isCommentBlinded ? (
            <></>
          ) : (
            <div className="comment-info">
              <CommentWriterInfo
                commentData={comment}
                deletedId={deletedId}
                isActiveComment={isActiveComment}
                isOwner={isOwner}
                parentData={feedData}
              />
              {!isFeedDeleted && (
                <CommentTool
                  comment={comment}
                  onUpdateComment={onUpdateComment}
                  onDeleteComment={onDeleteComment}
                  deletedId={deletedId}
                  isActiveComment={isActiveComment}
                />
              )}
            </div>
          )}
          <CommentContents
            comment={comment}
            deletedId={deletedId}
            isActiveComment={isActiveComment}
            setActiveComment={setActiveComment}
            replies={replies}
            setReplies={setReplies}
            contentRef={contentRef}
            setComment={setComment}
            cancelUpdateComment={cancelUpdateComment}
            onUpdateComment={onUpdateComment}
          />
          <CommentLike
            comment={comment}
            deletedId={deletedId}
            replyCount={replyCount}
            setStatus={setStatus}
            showReplies={showReplies}
            setReply={setReply}
            reply={reply}
            commentRef={commentRef.current}
            active={active}
            setActive={setActive}
            hasIcon={true}
            isFeedDeleted={isFeedDeleted}
          />
        </div>
      </div>
      <ReplyArea
        isMore={isMore}
        showReply={showReply}
        replies={replies}
        comment={comment}
        isActiveComment={isActiveComment}
        setReplies={setReplies}
        setActiveComment={setActiveComment}
        deletedId={deletedId}
        cancelUpdateComment={cancelUpdateComment}
        onUpdateComment={onUpdateComment}
        onDeleteComment={onDeleteComment}
        status={status}
        reply={reply}
        setReply={setReply}
        showReplies={showReplies}
        onReply={onReply}
        contentRef={contentRef}
        addReplyCount={addReplyCount}
        isMoreReplies={isMoreReplies}
        onMoreReplies={onMoreReplies}
        feedData={feedData}
        isFeedDeleted={isFeedDeleted}
      />
    </div>
  );
};

export default Comment;
