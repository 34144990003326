import React from 'react';
import { SELLING } from '@/resources/text/common';

// 팝업에 들어가는 텍스트들을 constant 에 있는 갑과 동일하게 만들어서 팝업 중앙에 들어가는 텍스트를 분기하는 과정을 없애기 위함
export const popUpText = {
  // redirect popup을 위한 별도 텍스트
  TO_MANUAL_LOGIN_FIRST: '직접 집내놓기는 로그인 이후 가능합니다.',

  // @/pages/Selling/constants 에 있는 값들과 일치시킴
  // 매물등록메인 : 각 페이지로 분기
  POPUP_TYPE_ENTER_MANUAL_PAGE: SELLING.main.notice,
  POPUP_TYPE_ENTER_PHONE_PAGE: SELLING.main.notice,

  POPUP_TYPE_TEMP_EXIST: (
    <>
      임시저장 정보가 존재합니다.
      <br />
      이어서 등록하시겠습니까?
    </>
  ),
  POPUP_TYPE_SIMPLE_LOGIN_USER: '직접 집내놓기 서비스를 이용하기 위해서는 본인 인증을 거쳐야 합니다.',

  // 전화로 매물등록 : 요청
  POPUP_TYPE_PHONE_REQUEST: '매물 등록을 요청하시겠습니까?',
  POPUP_TYPE_PHONE_REQUEST_LOADING: '매물 등록 요청 중입니다.',
  POPUP_TYPE_PHONE_REQUEST_DONE: (
    <>
      매물 등록 신청이 완료되었습니다.
      <br />
      고객센터(1544-6075)에서 연락드리겠습니다.
    </>
  ),

  // 전화로 매물등록 : 약관 자세히보기
  POPUP_TYPE_VIEW_AGREEMENT_DETAIL: (
    <>
      <strong>1. 개인정보 수집 항목 (필수)</strong>
      <br />
      성명, 전화번호, 매물 소재지
      <br />
      <br />
      <strong>2. 이용목적</strong>
      <br />
      매물등록대행 서비스 이용
      <br />
      <br />
      <strong>3. 보유기간</strong>
      <br />
      매물등록대행 서비스 이용 목적이 달성된 후에는 해당 정보를 지체없이 파기
      <br />
      <br />※ 위의 개인정보 수집 및 이용동의에 거부할 수 있으며, 거부시 서비스 이용이 제한됨을 알려드립니다.
    </>
  ),

  // 직접 매물등록 : 요청(최종요청)
  POPUP_TYPE_MANUAL_REQUEST: '매물 등록을 요청하시겠습니까?',
  POPUP_TYPE_MODIFY_REQUEST: '매물 정보를 수정하시겠습니까?',
  POPUP_TYPE_MANUAL_REQUEST_LOADING: '매물 등록 요청 중입니다.',
  POPUP_TYPE_MANUAL_REQUEST_DONE: '매물 등록이 완료되었습니다.',
  POPUP_TYPE_MODIFY_REQUEST_DONE: '매물 정보 수정이 완료되었습니다.',

  POPUP_TYPE_EMPTY_INPUT_FIELD: '입력되지 않은 내용이 있습니다.',

  // 직접 매물등록 : 요청(임시등록)
  POPUP_TYPE_TEMP_STORE_REQUEST: '임시저장 하시겠습니까?',
  POPUP_TYPE_TEMP_STORE_REQUEST_LOADING: '임시저장 중입니다.',
  POPUP_TYPE_TEMP_STORE_REQUEST_DONE: '임시저장이 완료되었습니다.',

  // 직접 매물등록 : 주변 중개사 목록 가져오기
  POPUP_TYPE_GET_NEARBY_AGENT_LOADING: '주변 중개사 목록을 가져오는 중입니다.',

  POPUP_BROKER_CANNOT_REGISTER: '중개사회원은 매물 등록을 하실 수 없습니다.',
};

export default popUpText;
