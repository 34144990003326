import InputPhoneNumber from '@/components/Form/Input/InputPhoneNumber';
import React from 'react';

const Step1 = ({ register, errors }) => {
  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-body">
          <div className="form-area type-d">
            <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
              <input type="text" className="form-elem" id="" autoComplete="off" placeholder="이름" {...register('name', { required: true })} />
            </span>
            <div className="form-noti">{errors?.name?.type === 'required' && <p className="para invalid">이름을 입력해주세요.</p>}</div>
          </div>
          <div className="form-area type-d">
            <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
              <InputPhoneNumber placeholder="전화번호" register={register} />
            </span>
            <div className="form-noti">{errors?.phoneNumber?.type === 'required' && <p className="para invalid">전화번호를 입력해주세요.</p>}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
