import { MODE } from '@/combinedComp/DualAddress';
import { TagType } from '@/services/Community';
import { useEffect } from 'react';

const useInitializeFlagTag = ({ defaultDualAddressData, setDualAddressData, tags, setTags }) => {
  useEffect(() => {
    if (defaultDualAddressData) {
      setDualAddressData({ mode: MODE.TEXT, ...defaultDualAddressData });
    }

    if (tags.filter((ii) => ii.type == TagType.FLAG_TAG).length == 0) {
      setTags([
        ...tags.filter((i) => i.type != TagType.FLAG_TAG),
        { type: TagType.FLAG_TAG, name: 'WANT_TO_CALL', key: 'WANT_TO_CALL' },
        { type: TagType.FLAG_TAG, name: 'WANT_TO_CHAT', key: 'WANT_TO_CHAT' },
      ]);
    }
  }, [defaultDualAddressData]);

  return;
};

export default useInitializeFlagTag;
