import * as AT from './actionTypes';

export const getERROR = (state) => state?.myPage?.error;

export const getMyAlarmList = (state) => state?.myPage?.myAlarmList;

// ===================== MyInfo===================== //

// 상세정보 보기로 넘어가는 매물의 정보 => selling reducer 에 index 저장 및 활용
// export const getSelectedItemInfo = (state) => state?.myPage?.selectdItemInfo;
// ===================== MyBuy===================== //
export const getChangPassword = (state) => state?.myPage[AT.MP_CHANGE_PASSWORD]?.getOrElse(false);
export const getChangPasswordIsLoading = (state) => state?.myPage[AT.MP_CHANGE_PASSWORD].isLoading();
export const getChangPasswordIsFailure = (state) => state?.myPage[AT.MP_CHANGE_PASSWORD].isFailure();
export const getChangPasswordIsSuccess = (state) => state?.myPage[AT.MP_CHANGE_PASSWORD].isSuccess();
export const getMySocialData = (state) => state?.myPage?.mySocialList;

// ===================== MyBuy===================== //
// export const getFavoriteItemsList = (state) => state?.myPage?.myFavoriteItemsList;
export const getFavoriteItemsList = (state) => state?.myPage[AT.GET_FAVORITE_ITEMS_LIST].getOrElse([]);
export const getFavItemsListIsLoading = (state) => state?.myPage[AT.GET_FAVORITE_ITEMS_LIST].isLoading();
export const getFavItemsListIsFailure = (state) => state?.myPage[AT.GET_FAVORITE_ITEMS_LIST].isFailure();

export const getInterestedComplexList = (state) => state?.myPage[AT.GET_INTERESTED_COMPLEX_LIST].getOrElse([]);
export const getInterestedComplexListIsLoading = (state) => state?.myPage[AT.GET_INTERESTED_COMPLEX_LIST].isLoading();
export const getInterestedComplexListIsFailure = (state) => state?.myPage[AT.GET_INTERESTED_COMPLEX_LIST].isFailure();

export const getContractedItemsList = (state) => state?.myPage[AT.GET_CONTRACTED_ITEMS_LIST].getOrElse([]);
export const getContractedItemsListIsLoading = (state) => state?.myPage[AT.GET_CONTRACTED_ITEMS_LIST].isLoading();
export const getContractedItemsListIsFailure = (state) => state?.myPage[AT.GET_CONTRACTED_ITEMS_LIST].isFailure();

// ===================== MySell ===================== //
export const getMySellItemsList = (state) => state?.myPage[AT.GET_MY_SELL_ITEMS_LIST].getOrElse([]);
export const getMySellItemsListIsLoading = (state) => state?.myPage[AT.GET_MY_SELL_ITEMS_LIST].isLoading();
export const getMySellItemsListIsFailure = (state) => state?.myPage[AT.GET_MY_SELL_ITEMS_LIST].isFailure();

export const getTradeItemIdx = (state) => state?.myPage?.tradeItemIdx;
export const getTradeAgentType = (state) => state?.myPage?.tradeAgentType;
export const getTradeAgentIdx = (state) => state?.myPage?.tradeAgentIdx;
export const postTradeCompleteIsLoading = (state) => state?.myPage[AT.POST_REPORT_ITEM_TRADE_COMPLETE].isLoading();
export const postTradeCompleteIsSuccess = (state) => state?.myPage[AT.POST_REPORT_ITEM_TRADE_COMPLETE].isSuccess();
export const postTradeCompleteIsFailure = (state) => state?.myPage[AT.POST_REPORT_ITEM_TRADE_COMPLETE].isFailure();

export const getDeleteItemIdx = (state) => state?.myPage?.deleteItemIdx;
export const getDeleteStatus = (state) => state?.myPage?.deleteStatus;
export const getDeleteReason = (state) => state?.myPage?.deleteReason;
export const getDeleteReasonTxt = (state) => state?.myPage?.deleteReasonTxt;
export const postDeleteItemIsLoading = (state) => state?.myPage[AT.POST_DELETE_ITEM].isLoading();
export const postDeleteItemIsSuccess = (state) => state?.myPage[AT.POST_DELETE_ITEM].isSuccess();
export const postDeleteItemIsFailure = (state) => state?.myPage[AT.POST_DELETE_ITEM].isFailure();

export const getRegCancelItemIdx = (state) => state?.myPage?.regCancelItemIdx;
export const postRegCancelItemIsLoading = (state) => state?.myPage[AT.POST_REG_CANCEL_ITEM].isLoading();
export const postRegCancelItemIsSuccess = (state) => state?.myPage[AT.POST_REG_CANCEL_ITEM].isSuccess();
export const postRegCancelItemIsFailure = (state) => state?.myPage[AT.POST_REG_CANCEL_ITEM].isFailure();

export const getReRegItemIdx = (state) => state?.myPage?.reRegItemIdx;
export const postReRegItemIsLoading = (state) => state?.myPage[AT.POST_REREG_ITEM].isLoading();
export const postReRegItemIsSuccess = (state) => state?.myPage[AT.POST_REREG_ITEM].isSuccess();
export const postReRegItemIsFailure = (state) => state?.myPage[AT.POST_REREG_ITEM].isFailure();

// ===================== Rating ===================== //
// export const getRatingType = (state) => state?.myPage?.ratingType;

// ===================== 2.3 MySell ===================== //
export const getMySellCount = (state) => state?.myPage?.overallCount;
export const getMySellCountLoading = (state) => state?.myPage[AT.GET_MY_SELL_COUNT]?.isLoading();

export const getMySellList = (state) => state?.myPage?.overall;
export const getMySellIsLoading = (state) => state?.myPage[AT.GET_MY_SELL_LIST]?.isLoading();
