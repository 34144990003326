let instance;

class AuthSession {
  constructor(data) {
    if (instance) return instance;

    this.deviceId = data.deviceId;
    this.sessionId = data.sessionId;
    this.accessToken = data.accessToken;
    this.userInfo = data.userInfo;
    instance = this;
  }

  getDeviceId() {
    return this.deviceId;
  }

  getSessionId() {
    return this.sessionId;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getUserInfo() {
    return this.userInfo;
  }

  updateAccessToken(newAccessToken) {
    this.accessToken = newAccessToken;
  }

  updateUserInfo(newUserInfo) {
    this.userInfo = newUserInfo;
  }
}
export default AuthSession;
