// 매물 상태. 아직 활용하는 곳은 없음

import { formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';

export const GOODS_STATUS = {
  IN: 'IN', // 작성중, 임시저장
  SB: 'SB', // 게시중
  DR: 'DR', // 거래완료
  ER: 'ER', // 거래완료 - 다윈 외 거래
  TR: 'TR', // 내려간매물
};

export const GOODS_STATUS_TEXT = {
  [GOODS_STATUS.IN]: '임시저장',
  [GOODS_STATUS.SB]: '게시중',
  [GOODS_STATUS.DR]: '거래완료',
  [GOODS_STATUS.ER]: '거래완료',
  [GOODS_STATUS.TR]: '내려간매물',
};

export const REALTY_TYPES = [
  { cd: 'APT', name: '아파트', viewName: '아파트', disabled: false },
  { cd: 'OFT', name: '오피스텔', viewName: '오피스텔', disabled: false },
  //   { cd: 'OFFICE', name: '상가/사무실', viewName: '상가/사무실', disabled: false },
  { cd: 'STUDIO', name: '원룸/투룸', viewName: '원룸/투룸 (준비 중)', disabled: true },
  { cd: 'VILLA', name: '주택/빌라', viewName: '주택/빌라 (준비 중)', disabled: true },
];

export const TRADE_TYPES = [
  { cd: 's', name: '매매' },
  { cd: 'c', name: '전세' },
  { cd: 'm', name: '월세' },
];

export const PRICE_MAX_LENGTH = 7;

export const getEnterDateText = (enter_flexible, enter_date) => {
  if (enter_flexible === 'Y') {
    // 협의가능
    return '협의가능';
  } else {
    // 즉시입주, 날짜지정
    if (enter_date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const enterDate = new Date(enter_date);
      enterDate.setHours(0, 0, 0, 0);

      let diff = enterDate.getTime() - today.getTime();
      diff = diff / (1000 * 3600 * 24);

      return diff > 0 ? `${formatDate(enter_date)} 이후` : '즉시입주';
    } else {
      return '';
    }
  }
};

// 일반회원 매물신고 타입
export const GoodsReportTypeKor = {
  A: '이미 거래 완료된 매물',
  B: '매도자의 거래 의사가 없음',
  C: '기타 사유',
};
