import { KAKAO_SHARE_TEMPLATE_ID, SHARE_POPUP_TYPES } from '../constants';
import { DEV } from '@/resources/env';

const useGetTemplateId = (templateType) => {
  let templateId;

  if (templateType === SHARE_POPUP_TYPES.BASIC) {
    templateId = DEV ? KAKAO_SHARE_TEMPLATE_ID.BASIC_TEST : KAKAO_SHARE_TEMPLATE_ID.BASIC_PROD;
  } else if (templateType === SHARE_POPUP_TYPES.PROFILE) {
    templateId = DEV ? KAKAO_SHARE_TEMPLATE_ID.PROFILE_TEST_NEW : KAKAO_SHARE_TEMPLATE_ID.PROFILE_PROD;
  } else if (templateType === SHARE_POPUP_TYPES.LIST) {
    templateId = DEV ? KAKAO_SHARE_TEMPLATE_ID.LIST_TEST : KAKAO_SHARE_TEMPLATE_ID.LIST_PROD;
  } else if (templateType === SHARE_POPUP_TYPES.FEED) {
    templateId = DEV ? KAKAO_SHARE_TEMPLATE_ID.FEED_TEST : KAKAO_SHARE_TEMPLATE_ID.FEED_PROD;
  }

  return templateId;
};

export default useGetTemplateId;
