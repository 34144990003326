import { loading, success, failure } from '@/data/utils';

export const GET_DETAIL_ = 'GET_DETAIL';
export const GET_DETAIL_SUCCESS_ = success('GET_DETAIL');
export const GET_DETAIL_LOADING_ = loading('GET_DETAIL');

export const SET_BROKER_INFO = 'BROKERPAGE/SET_BROKER_INFO';
export const SET_OFFICE_INTRO = 'BROKERPAGE/SET_OFFICE_INTRO';
export const SET_OFFICE_INFO = 'BROKERPAGE/SET_OFFICE_INFO';
export const SET_INFO_SECTION = 'BROKERPAGE/SET_INFO_SECTION';

//내정보 - 개인정보
export const GET_BROKER_OFFICE_INFO = 'BROKERPAGE/GET_BROKER_OFFICE_INFO';
export const BROKER_PASS_CHANGE = 'BROKERPAGE/BROKER_PASS_CHANGE';
export const CHANGE_BROKER_INFO_DATA = 'BROKERPAGE/CHANGE_BROKER_INFO_DATA';
export const BROKER_SECESSION = 'BROKERPAGE/BROKER_SECESSION';
export const SET_BROKER_PASS_CHANGE_RESULT = 'BROKERPAGE/SET_BROKER_PASS_CHANGE_RESULT';

//내정보 - 사무실정보
export const GET_MY_OFFICE_LIST = 'BROKERPAGE/GET_MY_OFFICE_LIST';
export const GET_MY_OFFICE_LIST_LOADING = loading(GET_MY_OFFICE_LIST);
export const GET_MY_OFFICE_LIST_FAIL = failure(GET_MY_OFFICE_LIST);
export const SET_SELECTED_MODI_OFFICE_ADDRESS_DETAIL = 'BROKERPAGE/SET_SELECTED_MODI_OFFICE_ADDRESS_DETAIL';
export const SET_SELECTED_MODI_OFFICE_ADDRESS = 'BROKERPAGE/SET_SELECTED_MODI_OFFICE_ADDRESS';
export const SEARCH_XY_MODI = 'BROKERPAGE/SEARCH_XY_MODI';
export const SET_OFFICE_MODI_LIST = 'BROKERPAGE/SET_OFFICE_MODI_LIST';
export const SET_OFFICE_MODI_XY = 'BROKERPAGE/SET_OFFICE_MODI_XY';
export const SET_OFFICE_B_DONG_CODE = 'BROKERPAGE/SET_OFFICE_B_DONG_CODE';
export const SET_SELECTED_MODI_OFFICE = 'BROKERPAGE/SET_SELECTED_MODI_OFFICE';
export const DEL_MY_OFFICE_SET = 'BROKERPAGE/DEL_MY_OFFICE_SET';
export const DEL_MY_OFFICE_ADDRESS_SET = 'BROKERPAGE/DEL_MY_OFFICE_ADDRESS_SET';
export const UPDATE_MY_OFFICE_ADDRESS = 'BROKERPAGE/UPDATE_MY_OFFICE_ADDRESS';

export const SET_COR_NUMBER = 'BROKERPAGE/SET_COR_NUMBER';
export const SET_EDIT_OFFICE_TYPE = 'BROKERPAGE/SET_EDIT_OFFICE_TYPE';
export const SET_EDIT_OFFICE_PHONE_NUM = 'BROKERPAGE/SET_EDIT_OFFICE_PHONE_NUM';
export const SET_EDIT_OFFICE_LAT_LNG = 'BROKERPAGE/SET_EDIT_OFFICE_LAT_LNG';
export const CH_OFFICE_ADDRESS = 'BROKERPAGE/CH_OFFICE_ADDRESS';
export const SET_EDIT_OFFICE_IMAGES = 'BROKERPAGE/SET_EDIT_OFFICE_IMAGES';
export const AS_EDIT_IMAGE_ITEM = 'BROKERPAGE/AS_EDIT_IMAGE_ITEM';
export const AS_DEL_IMAGE_ITEM = 'BROKERPAGE/AS_DEL_IMAGE_ITEM';
export const OFFICE_INFO_CHANGE = 'BROKERPAGE/OFFICE_INFO_CHANGE';
export const SET_BIZ_IMAGE = 'BROKERPAGE/SET_BIZ_IMAGE';
export const SET_BROKER_REG_IMAGE = 'BROKERPAGE/SET_BROKER_REG_IMAGE';
export const SET_OFFICE_IMAGE = 'BROKERPAGE/SET_OFFICE_IMAGE';

//내정보 - 사무실소개
export const SET_STAFF_LIST = 'BROKERPAGE/SET_STAFF_LIST';
export const ADD_STAFF = 'BROKERPAGE/ADD_STAFF';
export const SET_STAFF_GUBUN = 'BROKERPAGE/SET_STAFF_GUBUN';
export const SET_STAFF_CP = 'BROKERPAGE/SET_STAFF_CP';
export const SET_STAFF_NAME = 'BROKERPAGE/SET_STAFF_NAME';
export const SET_STAFF_IMAGE = 'BROKERPAGE/SET_STAFF_IMAGE';
export const SET_YOUTUBE = 'BROKERPAGE/SET_YOUTUBE';
export const SET_ONE_LINE = 'BROKERPAGE/SET_ONE_LINE';
export const SET_INTRO = 'BROKERPAGE/SET_INTRO';
export const SET_PROFILE_IMAGE = 'BROKERPAGE/SET_PROFILE_IMAGE';
export const SET_DED_CERTI_IMAGE = 'BROKERPAGE/SET_DED_CERTI_IMAGE';
export const SET_EDIT_CAREER = 'BROKERPAGE/SET_EDIT_CAREER';
export const SET_EDIT_PHONE_NUM = 'BROKERPAGE/SET_EDIT_PHONE_NUM';

export const DEL_OFFICE_IMAGE = 'BROKERPAGE/DEL_OFFICE_IMAGE';
export const DEL_STAFF_PROFILE = 'BROKERPAGE/DEL_STAFF_PROFILE';
export const DEL_STAFF = 'BROKERPAGE/DEL_STAFF';
export const DEL_PROFILE_IMAGE = 'BROKERPAGE/DEL_PROFILE_IMAGE';
export const DEL_DED_CERTI = 'BROKERPAGE/DEL_DED_CERTI';

export const SAVE_OFFICE_INTRO = 'BROKERPAGE/SAVE_OFFICE_INTRO';
