// import Mask from '@/components/Mask';
import React, { useEffect, useMemo, useState } from 'react';
import Header from './components/Header';
// import { Wrapper } from '../styled';
// import Nickname from '../../components/Nickname';
import SaveSubmitBtn from './components/SaveSubmitBtn';
import ComplexPost from './ComplexPost';
import LocalPost from './LocalPost';
import QnaPost from './QnaPost';
import OneStepPostForBuy from './PostForBuy';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { parseCallBack } from '@/services/Helper';
// import NickNameSetting from '@/components/NicknameSetting/Change';
import { objectToQuerystring } from '@/apis/helpers';
import constants from '@/pages/Buying/InfoSection/Detail/History/PirceHistory/constants';
import Modal from '@/pages/My/MyInfo/components/Modal';

const Post = ({
  feedType,
  createPoint,
  setFeedType,
  contents,
  setContents,
  tags,
  setTags,
  numericTags,
  setNumericTags,
  addedTagsRef,
  imagesRef,
  submit,
  status,
  defaultDualAddressData,
  isUpdate,
  updateFeedDetail,
  safetyPop,
  setSafetyPop,
  dualAddressData,
  setDualAddressData,
  headerHashtags,
}) => {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const query = parseCallBack();
  const postType = query.postType;
  const isBroker = userInfo?.mbr_type === 'BU';
  const isPostForBuy = postType === 'POST_FOR_BUY';

  useEffect(() => {
    if (isBroker && isPostForBuy) {
      dispatch(
        actions.common.alert({
          contents: (
            <div className="board-body">
              <p className="para">
                <span className="wbr">중개사 회원은 구해줘다윈 서비스를 이용하실 수 없습니다.</span>
                <span className="wbr">개인적인 목적으로 구해줘다윈 서비스를 이용하시려면, 일반 회원으로 로그인해주세요.</span>
              </p>
            </div>
          ),
          onConfirm: () => {
            dispatch(actions.router.goBack());
          },
        })
      );
    }
  }, [userInfo, feedType]);

  // const [showNickname, setShowNickname] = useState(false);
  const [openPhoneChangePop, setOpenPhoneChangePop] = useState(false);
  const [current, setCurrent] = useState(constants.PHONE_CHANGE);

  return (
    <>
      <div className={`front-popup module-a style-a type-a medium ${active ? 'active' : ''}`} id="postsWriteHelpDawin" data-bui-toggle="frontPopup">
        <div className="popup-page-body">
          <div className="popup-local">
            <Header isUpdate={isUpdate} postType={postType} feedType={feedType} />
            <form className="popup-local-body">
              <div className="popup-content">
                <div className="popup-content-body">
                  <fieldset className="submit-form module-a type-c large">
                    <legend>게시물 등록 서식</legend>
                    <PostForm
                      feedType={feedType}
                      createPoint={createPoint}
                      addedTagsRef={addedTagsRef}
                      contents={contents}
                      setContents={setContents}
                      setFeedType={setFeedType}
                      tags={tags}
                      setTags={setTags}
                      numericTags={numericTags}
                      setNumericTags={setNumericTags}
                      imagesRef={imagesRef}
                      submit={submit}
                      status={status}
                      defaultData={defaultDualAddressData}
                      isUpdate={isUpdate}
                      updateFeedDetail={updateFeedDetail}
                      safetyPop={safetyPop}
                      setSafetyPop={setSafetyPop}
                      dualAddressData={dualAddressData}
                      setDualAddressData={setDualAddressData}
                      headerHashtags={headerHashtags}
                      setActive={setActive}
                      // setShowNickname={setShowNickname}
                      openPhoneChangePop={openPhoneChangePop}
                      setOpenPhoneChangePop={setOpenPhoneChangePop}
                      current={current}
                      setCurrent={setCurrent}
                    />
                  </fieldset>
                </div>
              </div>
              <SaveSubmitBtn
                status={status}
                isUpdate={isUpdate}
                feedType={feedType}
                contents={contents}
                tags={tags}
                addedTagsRef={addedTagsRef}
                numericTags={numericTags}
                imagesRef={imagesRef}
                submit={submit}
              />
            </form>
          </div>
        </div>
      </div>
      {openPhoneChangePop && <Modal setIsModalOpen={setOpenPhoneChangePop} current={current} setCurrent={setCurrent} />}
      {/* <NickNameSetting show={showNickname} setShow={setShowNickname} /> */}
    </>
  );
};

export default Post;

const PostForm = ({
  feedType,
  createPoint,
  setActive,
  // setShowNickname,
  ...rest
}) => {
  const nickname = useSelector(selectors.auth.getNickname);
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const isBroker = userInfo?.mbr_type === 'BU';
  const dispatch = useDispatch();

  const query = parseCallBack();

  useEffect(() => {
    if (!isBroker && !nickname) {
      // setShowNickname(true);
      query.nicknameSetting = 'post';
      dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
    } else {
      // 새로고침하면 nickanmesetting이 자꾸 url로 들어가기때문에 지워주는 설정
      delete query.nicknameSetting;
      dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
      setActive(true);
    }
  }, [nickname, isBroker]);

  // 닉네임 설정 완료 후 active를 true로 변환해야하기때문
  useEffect(() => {
    if (!isBroker && nickname && query.postType) {
      setActive(true);
    }
  }, [query]);

  const ThisPost = useMemo(() => {
    if (feedType === 'POST_FOR_BUY') {
      if (userInfo?.mbr_type === 'BU') {
        setActive(false);
      }
      return OneStepPostForBuy;
    } else if (feedType === 'COMPLEX_POST') {
      return ComplexPost;
    } else if (feedType === 'LOCAL_POST') {
      return LocalPost;
    } else {
      return QnaPost;
    }
  }, [feedType, nickname, userInfo]);

  return <ThisPost createPoint={createPoint} feedType={feedType} {...rest} />;
};
