import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const SUGGESTION = { page: 1, total: 40, detail: {}, conlist: {} };

export default function suggestion(state = SUGGESTION, action = {}) {
  switch (action.type) {
    case AT.GET_SUGGESTION_DETAIL: {
      return {
        ...state,
      };
    }
    case AT.GET_SUGGESTION_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_SUGGESTION_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.SET_END_DATE: {
      return {
        ...state,
        endDate: action.val,
      };
    }
    case AT.SET_START_DATE: {
      return {
        ...state,
        startDate: action.val,
      };
    }
    case AT.SET_SUGGESTION_NEW_ATTACH: {
      return {
        ...state,
        new: { ...state.new, file_name: action.file_name, attach: action.idx },
      };
    }
    case AT.SET_SUGGESTION_NEW_CATEGORY: {
      return {
        ...state,
        new: { ...state.new, category: action.val },
      };
    }
    case AT.SET_SUGGESTION_NEW_TITLE: {
      return {
        ...state,
        new: { ...state.new, title: action.val },
      };
    }
    case AT.SET_SUGGESTION_NEW_CONT: {
      return {
        ...state,
        new: { ...state.new, cont: action.val },
      };
    }
    case AT.SET_SUGGESTION_TOTAL_CNT: {
      return {
        ...state,
        totalCnt: action.totalCnt,
      };
    }
    case AT.SET_SUGGESTION_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }

    case loading(AT.GET_SUGGESTION_LIST):
      return { ...state, [AT.GET_SUGGESTION_LIST]: Remote.Loading };
    case success(AT.GET_SUGGESTION_LIST):
      return { ...state, conlist: action.conlist };
    case failure(AT.GET_SUGGESTION_LIST):
      return {
        ...state,
        [AT.GET_SUGGESTION_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.GET_SUGGESTION_DETAIL):
      return { ...state, [AT.GET_SUGGESTION_DETAIL]: Remote.Loading };
    case success(AT.GET_SUGGESTION_DETAIL):
      return { ...state, detail: action.detail };
    case failure(AT.GET_SUGGESTION_DETAIL):
      return {
        ...state,
        [AT.GET_SUGGESTION_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
