import { apiClient2 } from '@/apis/clients';
import axios from 'axios';

const prefix = '/resource-api';

export const api = {
  async postResource(payload) {
    try {
      return await apiClient2.post(`${prefix}/v1/resources`, payload);
    } catch (e) {
      throw Error(e);
    }
  },

  async getSingedUrl() {
    try {
      return await apiClient2.get(`${prefix}/v2/resources/signed-urls`);
    } catch (e) {
      throw Error(e);
    }
  },

  async uploadImageToS3(presignedUrl, file) {
    try {
      return await axios.put(presignedUrl, file, {
        headers: {
          Accept: 'application/json',
          'Content-Type': file.type,
        },
      });
    } catch (e) {
      throw Error(e);
    }
  },
};
