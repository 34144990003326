import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import { createLoadingSelector } from '@/data/utils';
import { actionTypes as AT, selectors } from '@/data';
import { STEPS, QUERYSTRING_BY_LOGIN_STEP } from '@/pages/Login/BasicForm/helpers';
// import Phone from '@/pages/Login/components/Phone';
// import Phone2 from '@/pages/Login/components/Phone/Step2';
// import Phone3 from '@/pages/Login/components/Phone/Step3';
import Phone4 from '@/pages/Login/components/Phone/Step4';
// import Broker from '@/pages/Login/components/Broker';
// import Agree from '@/pages/Login/components/Agree';
import Agree from '@/pages/Login/components/Agree/indexNew';
import Email from '@/pages/Login/components/Email';
import { routeToNextStep } from '@/pages/Login/helpsers';
import { appLogEvent } from '@/services/Logger';
import { useParams } from 'react-router-dom';
import router from '@/services/router';
import IdLogin from '../../LoginWeb/components/AgentUserView/components/IdLogin';
import PhoneNumberLogin from '../../LoginWeb/components/AgentUserView/components/PhoneNumberLogin';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import AppLoginCardStyle from '../../../components/MainApp/AppLoginCardStyle';
// import constants from '../../../components/MainApp/constants';

const selectPhoneIsSubmitting = createLoadingSelector([AT.SMS]);

function Core({ mainComponent, isMobile, allowLoginBeforePhoneConfirm, onOptionalPhoneConfirmAction, isPhoneNumberAuthOnly }) {
  const dispatch = useDispatch();

  const { loginType } = useParams();

  const phoneIsSubmitting = useSelector(selectPhoneIsSubmitting);

  /**
   * @todo loginType
   * Redux 의 loginStep state는 차차 loginType 으로 변경할 예정.
   *
   * loginType 으로 변경하려 했지만 웹에서는 팝업이라서 불가능...
   */
  const stepByReduxState = useSelector(selectors.auth.getLoginStep);
  const stepByParam = loginType;
  const step = `${stepByReduxState === STEPS.BROKER || STEPS.BROKER_PHONE ? stepByReduxState : Number(stepByReduxState) || stepByParam || 0}`;

  /**
   * @todo loginType
   * loginType 적용시 setLoginStep도 모두 제거해야 함
   */
  useEffect(() => {
    if (loginType) dispatch(actions.auth.setLoginStep(loginType));
  }, [loginType]);

  useEffect(() => {
    // DW-246 GA 분석을 위해 querystring 세분화
    // 변경된 step에 따라 일괄 처리
    // QUERYSTRING_BY_LOGIN_STEP 에 정의된 경우에만 routing
    // 예외1: 카카오, 네이버, 애플 로그인은 step으로 관리되지 않기 때문에 별도처리. 각 컴포넌트에서 routing
    // 예외2: success는 saga > login 에서 별도처리.
    const value = QUERYSTRING_BY_LOGIN_STEP[step];

    if (value) {
      dispatch(routeToNextStep(value));
    }

    if (step === STEPS.AGREE) {
      appLogEvent({ key: 'login_step_agree' });
    }
  }, [step]);

  const data = useSelector(selectors.auth.getUserInfoForLogin);
  const setData = (d) => {
    dispatch(actions.auth.setUserInfoForLogin(d));
  };

  const queryString = router.querystring.parse();

  const goBack = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (allowLoginBeforePhoneConfirm) {
      // 휴대폰 인증단계 건너 뛰는 경우 > 로그인 완료 시킴
      onOptionalPhoneConfirmAction();
    } else if (!step || step == STEPS.FIRST || isPhoneNumberAuthOnly) {
      // history 뒤로가기
      // 1. 로그인 첫 스텝인 경우
      // 2. 휴대폰 인증 만 하는 경우 (로그인 첫 스텝을 거쳐서 오지 않기 때문에)
      dispatch(actions.router.goBack());
    } else if (step == STEPS.BROKER) {
      dispatch(actions.auth.setLoginStep(STEPS.FIRST));
      dispatch(actions.router.goBack());
    } else {
      dispatch(actions.auth.setLoginStep(STEPS.FIRST));
    }
  };

  const renderForm = () => {
    switch (step) {
      case STEPS.PHONE4:
        return <Phone4 data={data} isBusy={phoneIsSubmitting} dispatch={dispatch} />;
      case STEPS.BROKER:
        return <IdLogin data={data} isBusy={phoneIsSubmitting} dispatch={dispatch} />;
      case STEPS.PHONE1: //휴대폰번호 입력
      case STEPS.SOCIAL2:
      case STEPS.PHONE2: //휴대폰 인증
      case STEPS.SOCIAL3:
      case STEPS.PHONE3:
      case STEPS.BROKER_PHONE:
        return <PhoneNumberLogin data={data} isBusy={phoneIsSubmitting} dispatch={dispatch} />;
      case STEPS.EMAIL:
        return <Email />;
      case STEPS.AGREE:
        return <Agree />;
      case STEPS.FIRST:
      default:
        return <>{mainComponent}</>;
    }
  };

  // console.log({ step, test: step !== STEPS.FIRST, isMobile });
  return (
    <>
      {/* 로그인 첫 화면에서 뒤로가기 버튼이 필요없더라도 그 다음 스텝부터는 첫 화면으로 돌아올 back 버튼 필요 */}
      {/* {step !== STEPS.FIRST && isMobile && (
        <div className="header sub-header">
          <span className="btn-left" style={{ display: queryString.step === STEPS.PHONE3 ? 'none' : '' }}>
            <a className="btn-img btn-back" onClick={goBack}>
              뒤로
            </a>
          </span>
        </div>
      )} */}
      {renderForm()}
    </>
  );
}

export default Core;
