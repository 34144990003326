// ===================== 공통 ===================== //
export const REGQNA_REQUEST_ERROR = 'REGQNA/REGQNA_REQUEST_ERROR';
export const REGQNA_RESET_ERROR_STATE = 'REGQNA/REGQNA_RESET_ERROR_STATE';

// ===================== 문의 제목,본문 ===================== //

export const SET_REGQNA_CATEGORY_IDX = 'REGQNA/SET_REGQNA_CATEGORY_IDX';
export const SET_REGQNA_TITLE = 'REGQNA/SET_REGQNA_TITLE';
export const SET_REGQNA_BODY = 'REGQNA/SET_REGQNA_BODY';

// ===================== POST ===================== //
export const POST_REGQNA = 'REGQNA/POST_REGQNA';
