import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

const MobileView = ({ children }) => {
  const isMobile = useSelector(selectors.common.isMobile);
  return isMobile ? <>{children}</> : false;
};

export default MobileView;
