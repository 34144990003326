import * as AT from './actionTypes';

export const getAttach = (state) => state?.brokerPage?.internalNotice?.new?.attach;
export const getKeyword = (state) => state?.brokerPage?.internalNotice?.keyword;
export const getPage = (state) => state?.brokerPage?.internalNotice?.page;
export const getTotalCnt = (state) => state?.brokerPage?.internalNotice?.totalCnt;
export const getList = (state) => state?.brokerPage?.internalNotice?.conlist;
export const getRealList = (state) => state?.brokerPage?.internalNotice?.real;
export const getDetail = (state) => state?.brokerPage?.internalNotice?.detail;
export const getDetailIdx = (state) => state?.brokerPage?.internalNotice?.detailIdx;
export const getPreIdx = (state) => state?.brokerPage?.internalNotice?.detail?.pre_idx;
export const getPreTitle = (state) => state?.brokerPage?.internalNotice?.detail?.pre_title;
export const getNextIdx = (state) => state?.brokerPage?.internalNotice?.detail?.next_idx;
export const getNextTitle = (state) => state?.brokerPage?.internalNotice?.detail?.next_title;
