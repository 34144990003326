import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  // 요청_전화로 집내놓기
  yield takeLatest(AT.SELL_CALL_REQUEST, sagas.sellCallRequest);
  // 직접 집내놓기 - step1로
  yield takeEvery(AT.INIT_AND_GO_FIRST_STEP, sagas.initAndGoFirstStep);
  // 요청_직접 집내놓기
  yield takeEvery(AT.SELL_MANUAL_GET_STEP_DATA, sagas.getManualStepData);
  // [[NEW LOGIC]]-----------------------------------------------------------
  yield takeLatest(AT.SELL_MANUAL_HANDLE_NEXT_STEP, sagas.handleManualNextStep);

  // ============================ Step 1============================ //
  // ============================ 단지 목록 받기 && 단지 선택 && 단지 정보, 위치 정보 ============================ //
  yield takeEvery(AT.GET_COMPLEX_LIST, sagas.getComplexList);
  yield takeEvery(AT.GET_COMPLEX_LOCATION, sagas.getComplexLocation);
  yield takeEvery(AT.GET_COMPLEX_LOCATION2, sagas.getComplexLocation2);

  // ============================ 단지 상세 정보 ============================ //
  yield takeEvery(AT.GET_ADDR_SiDo, sagas.getAddrSiDo);
  yield takeEvery(AT.GET_ADDR_SiGunGu, sagas.getAddrSiGunGu);
  yield takeEvery(AT.GET_ADDR_DongRi, sagas.getAddrDongRi);

  yield takeEvery(AT.GET_COMPLEX_DONG_LIST, sagas.getComplexDongList);
  yield takeEvery(AT.GET_COMPLEX_DONG_LIST2, sagas.getComplexDongList2);
  yield takeEvery(AT.GET_FLOOR_LIST, sagas.getFloorList);
  yield takeEvery(AT.GET_FLOOR_LIST2, sagas.getFloorList2);
  yield takeEvery(AT.GET_HO_LIST, sagas.getHoList);
  yield takeEvery(AT.GET_HO_LIST2, sagas.getHoList2);

  // ===================== Step 3 ===================== //
  yield takeEvery(AT.SAVE_IMAGE, sagas.saveImage);
  // [[NEW LOGIC]]-----------------------------------------------------------
  yield takeEvery(AT.SAVE_IMAGE2, sagas.saveImage2);
  yield takeEvery(AT.DELETE_IMAGE, sagas.deleteImage);

  // ===================== Step 4 ===================== //
  yield takeEvery(AT.GET_NEARBY_AGENTS, sagas.getNearbyAgents);
  // ===================== Step 5 ===================== //
  // ===================== 2.3 ===================== //

  yield takeLatest(AT.CREATE_GOODS, sagas.createGoods);
  yield takeLatest(AT.CREATE_REREGISTER_GOODS, sagas.createReRegisterGoods);
  yield takeLatest(AT.UPDATE_GOODS, sagas.updateGoods);
  yield takeLatest(AT.GET_GOODS, sagas.getGoods);
  yield takeLatest(AT.GET_GOODS_MEMO, sagas.getGoodsMemo);
  yield takeLatest(AT.GET_BROKERS_BY_GOODS_POSITION, sagas.getBrokersByGoodsPosition);
  yield takeLatest(AT.GET_BROKERS_BY_GOODS, sagas.getBrokersByGoods);
}
