import { TagType } from '@/services/Community';
import React from 'react';
import { useCheckIsOwner } from '@/combinedComp/Community/hooks';
import ReplyLike from '../../ReplyLike';
import ReplyContents from '../replyContents';
import ReplyTool from '../replyTool';
import ReplyWriterInfo from '../replyWriterInfo';

const ReplyComponent = ({
  feedData,
  reply,
  deletedId,
  isFeedDeleted,
  index,
  cancelUpdateComment,
  onUpdateComment,
  onDeleteComment,
  isActiveComment,
  setActiveComment,
  replies,
  setReplies,
  comment,
}) => {
  const isSameAuthor = feedData?.writer?.id == reply?.writer?.id;
  const isReplyDeleted = reply?.status === 'DELETED' || deletedId.indexOf(reply?.id) > -1;
  const isReplyBlinded = reply?.status === 'BLINDED';

  const complexTagInfo = feedData?.tags?.filter((i) => i.type === TagType.COMPLEX_TAG);
  const complexId = complexTagInfo[0]?.key;
  const writerId = reply?.writer?.id;
  const isOwner = useCheckIsOwner({ complexId, writerId });

  return (
    <div className="reply-list" key={index}>
      <div
        className={`reply-item ${isSameAuthor ? 'author' : ''} ${isReplyBlinded || isReplyDeleted ? 'deleted' : ''}`}
        data-bui-expand="postsReplyItem">
        <div className="reply-wrap">
          <div className="reply-inform">
            {isReplyDeleted || isReplyBlinded ? (
              <></>
            ) : (
              <div className="reply-info">
                {reply?.status === 'DELETED' || reply?.status === 'BLINDED' ? (
                  <></>
                ) : (
                  <ReplyWriterInfo reply={reply} deletedId={deletedId} isOwner={isOwner} parentData={feedData} />
                )}
                {!isFeedDeleted && (
                  <ReplyTool
                    cancelUpdateComment={cancelUpdateComment}
                    onUpdateComment={onUpdateComment}
                    onDeleteComment={onDeleteComment}
                    reply={reply}
                    deletedId={deletedId}
                    isActiveComment={isActiveComment}
                  />
                )}
              </div>
            )}

            <ReplyContents
              isActiveComment={isActiveComment}
              setActiveComment={setActiveComment}
              replies={replies}
              setReplies={setReplies}
              deletedId={deletedId}
              comment={comment}
              reply={reply}
              cancelUpdateComment={cancelUpdateComment}
            />

            <ReplyLike reply={reply} deletedId={deletedId} hasIcon={true} isFeedDeleted={isFeedDeleted} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyComponent;
