import dayjs from 'dayjs';
import React from 'react';
import { DATE_FORMAT_TYPE, formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';
// adminContents

const AdminComment = ({ feedData }) => {
  const { adminContents } = feedData;
  const { contents, updatedAt } = adminContents;
  const date = formatDate(updatedAt, { type: DATE_FORMAT_TYPE.B }); //dayjs(updatedAt).format('YYYY-MM-DD');
  return (
    <div className="posts-comment">
      <div className="guide-board module-a style-b type-a attr-comment">
        <div className="board-wrap">
          <div className="board-head">
            <p className="board-subject">
              <span className="board-name">다윈중개에서 손님 요청사항을 더 알아봤어요</span>
            </p>
          </div>
        </div>
      </div>
      <div className="posts-wrap">
        <div className="posts-inform">
          <div className="posts-body">
            <p>{contents}</p>
          </div>
          <div className="data-list">
            <span className="data-item posted">
              <span className="head">등록일</span>
              <span className="body">{date}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminComment;
