import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

const UserView = ({ children }) => {
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  return isLoggedIn ? <>{children}</> : false;
};

export default UserView;
