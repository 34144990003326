import { loading, success, failure } from '@/data/utils';

export const DEL_COMPLEX_IMAGE = 'BROKERPAGE/DEL_COMPLEX_IMAGE';
export const SET_COMPLEX_IMAGE = 'BROKERPAGE/SET_COMPLEX_IMAGE';

export const GET_COMPLEX_IMG_LIST = 'BROKERPAGE/GET_COMPLEX_IMG_LIST';
export const GET_COMPLEX_BASIC_INFO = 'BROKERPAGE/GET_COMPLEX_BASIC_INFO';
export const GET_COMPLEX_IMG_LIST_LOADING = loading(GET_COMPLEX_IMG_LIST);
export const GET_COMPLEX_IMG_LIST_SUCCESS = success(GET_COMPLEX_IMG_LIST);
export const GET_COMPLEX_IMG_LIST_FAILURE = failure(GET_COMPLEX_IMG_LIST);
