import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';
import { STATUS } from '@/data/utils';
import _padEnd from 'lodash-es/padEnd';
import { getSpecificLocation } from '@/data/address/services';

/**
 * @param {Object} data { sido, sigungu, sigungu1, sigungu2, dong, dong1, dong2 }
 *  sigungu는 sigungu1, sigungu2 중 마지막 값
 *    ex) sigungu1: 경기도 성남시, sigungu2: 성남시 수정구
 *  dong은 dong1, dong2 중 마지막 값
 *    ex) dong1: 충청북도 괴산군 괴산읍, don2: 검승리
 * @param {function} setData
 * @param {function} showGlobalSpinner 리스트 조회 API 처리 중 전체 화면 스피너 표출 여부
 * @param {boolean} tenDigitCode true이면 address 값 반환 시 10자리로 반환 (집내놓기 부분에 )
 */
const useAddress = ({
  data,
  setData,
  onlyAvailable = true,
  disabled = false,
  showGlobalSpinner = false,
  addAllOptionOnSido = false,
  addAllOptionOnSigungu = false,
  addAllOptionOnDong = false,
  tenDigitCode = false,
  refresh = false,
  initialCode,
  isSiGunGuOptional = false,
  isDongOptional = false,
}) => {
  const dispatch = useDispatch();
  // 시도
  const sidoCode = data?.sido?.code || '';

  // 시군구 2차 검증을 위한 4자리 코드 (1-4자리)
  const sigunguCode = data?.sigungu?.code || '';
  const sigunguCode1 = data?.sigungu1?.code || '';
  const sigunguCode2 = data?.sigungu2?.code || '';

  // 읍면동리 2차 검증을 위한 8자리 코드 (1-8자리)
  const dongCode = data?.dong?.code || '';
  // 1차, 2차로 나뉠경우 첫번째 select박스에 있는 값은 보여줘야함. ex) 41130(충주시) 41137(충주시 xx군)
  const dongCode1 = data?.dong1?.code || '';
  const dongCode2 = data?.dong2?.code || '';

  const only_broker = false; //gmh 추후 변경될수 있음

  const [sidoData, setSidoData] = useState({ status: STATUS.NOTASKED });
  const [sigunguData1, setSigunguData1] = useState({ status: STATUS.NOTASKED });
  const [sigunguData2, setSigunguData2] = useState({ status: STATUS.NOTASKED });
  const [dongData1, setDongData1] = useState({ status: STATUS.NOTASKED });
  const [dongData2, setDongData2] = useState({ status: STATUS.NOTASKED });

  useEffect(() => {
    dispatch(actions.address.getSidoList({ onlyAvailable, only_broker, setter: setSidoData }));
  }, []);

  useEffect(async () => {
    try {
      if (initialCode) {
        const data = await getSpecificLocation(initialCode);

        setData({
          sido: data.sido,
          sigungu: data.si || data.sigungu,
          sigungu1: data.si || data.sigungu,
          sigungu2: data.si ? data.sigungu : null,
          dong: data.dong,
          dong1: data.dong,
        });
      }
    } catch (e) {
      //
    }
  }, [initialCode]);

  // 시군구 리스트 조회
  useEffect(() => {
    if (sidoCode && sidoCode !== 'all') {
      if (sidoCode != SEJONG.cd.sido) {
        dispatch(actions.address.getSigunguList1({ sidoCode: sidoCode.substring(0, 2), setter: setSigunguData1, showGlobalSpinner }));
      } else {
        // 세종시
        dispatch(actions.address.getDongList1({ sigunguCode: SEJONG.cd.sigungu, setter: setDongData1, showGlobalSpinner }));
      }
    }
  }, [sidoCode]);

  // 시군구2 또는 동리스트 조회
  useEffect(() => {
    if (sigunguCode1 && sigunguCode1 !== 'all') {
      // 2번째 시군구 call해서 있으면 store의 si_gun_gu_list_second에 할당
      dispatch(
        actions.address.getSigungu2OrDongList({
          sigunguCode: sigunguCode1.substring(0, 5),
          sigungu2Setter: setSigunguData2,
          dongSetter: setDongData1,
          showGlobalSpinner,
        })
      );
    }
  }, [sigunguCode1]);

  // *** @todo initialize 할 때만 이 코드 돌아야 할 것 같은데? 이것 때문에 sigungu1, sigungu2 에는 code만 들어감.
  // dualAddress/helpers/geInitialData 함수에서 sigungu1은 다섯자리이되 마지막숫자는 무조건 0으로 반환하는데
  // 서울시 광진구 등 몇몇 코드는 이 규칙을 따르지 않음 (광진구: 11215)
  // sigunguData1 에 sigunguCode1에 해당하는 코드가 없는 경우 sigunguCode2로 데이터를 변경한다.
  useEffect(() => {
    const list = sigunguData1?.list;
    if (sigunguCode1 && list?.length > 0) {
      const codes = list.map((item) => item.code);
      if (codes.indexOf(sigunguCode1) < 0) {
        // 22.05.09 sigungu1 Object 내부의 code 변화 sigunguCode2 => sigunguCode1
        setData({ ...data, sigungu1: { code: sigunguCode1 }, sigungu2: { code: sigunguCode2 } });
      }
    }
  }, [sigunguCode1, sigunguData1]);

  useEffect(() => {
    if (sigunguCode2) {
      // 2번째 시군구 call해서 있으면 store의 si_gun_gu_list_second에 할당
      dispatch(actions.address.getDongList1({ sigunguCode: sigunguCode2.substring(0, 5), setter: setDongData1, showGlobalSpinner }));
    }
  }, [sigunguCode2]);

  // 시군구코드 5자리 완성되면 동리 리스트 콜
  useEffect(() => {
    // 동코드 변경시 동에서 끝나면 DongRiSecond 콜하지 x
    if (dongCode1) {
      const eight_digit_code = dongCode1.substr(0, 8); // 00000000xx -> 0000000로 마지막 ri 콜할 때 사용되는 param
      const eup_myeon_dong_code = dongCode1.substr(5, 3); // xxxxx000xx -> 000이  읍면동

      // 202 보다 크면 읍/면 코드이고 202이하면 동코드라 두번째 동리를 콜하지 x
      if (eup_myeon_dong_code > 202) {
        dispatch(actions.address.getDongList2({ dongri_eight_digit_code: eight_digit_code, setter: setDongData2, showGlobalSpinner }));
      } else {
        if (refresh) {
          setData(null);
          setSidoData({ status: STATUS.NOTASKED });
          setSigunguData1({ status: STATUS.NOTASKED });
          setSigunguData2({ status: STATUS.NOTASKED });
          setDongData1({ status: STATUS.NOTASKED });
          setDongData2({ status: STATUS.NOTASKED });
          dispatch(actions.address.getSidoList({ onlyAvailable, only_broker, setter: setSidoData }));
        }
      }
    }
  }, [dongCode1]);

  let siDoOptions = useMemo(() => {
    let result = [
      <option key="" value="">
        시/도 선택
      </option>,
    ];

    if (addAllOptionOnSido) {
      result.push(
        <option key="all" value="all">
          전국
        </option>
      );
    }

    if (sidoData.status === STATUS.SUCCESS && sidoData?.list?.length > 0) {
      sidoData.list.forEach((item) => {
        result.push(
          <option
            key={item.code}
            value={getCodeByType(item.code, tenDigitCode)}
            name={item.name}
            data-position={`{ "lng": ${item.lng}, "lat": ${item.lat} }`}>
            {item.name}
          </option>
        );
      });
    }
    return <>{result}</>;
  }, [sidoData?.list]);

  const onSiDoSelect = (e) => {
    if (!disabled) {
      const key = e.target.value;
      const name = e.target.options[e.target.selectedIndex]?.text;
      const position = JSON.parse(e.target.options[e.target.selectedIndex]?.getAttribute('data-position'));
      const sido = { code: key, name: name, position };
      setData({ ...data, sido: sido, sigungu: {}, sigungu1: {}, sigungu2: {}, dong: {}, dong1: {}, dong2: {} });
      setSigunguData1({ status: STATUS.NOTASKED });
      setSigunguData2({ status: STATUS.NOTASKED });
      setDongData1({ status: STATUS.NOTASKED });
      setDongData2({ status: STATUS.NOTASKED });
    }
  };

  const siGunGuOptionsFirst = useMemo(() => {
    let result = [
      <option key="" value="">
        시/군/구 {isSiGunGuOptional && '(선택)'}
      </option>,
    ];

    if (addAllOptionOnSigungu) {
      result.push(
        <option key="all" value="all">
          전체 지역
        </option>
      );
    }

    if (sidoCode && sigunguData1.status === STATUS.SUCCESS && sigunguData1?.list?.length > 0) {
      sigunguData1?.list?.forEach((item, key) => {
        result.push(
          <option
            key={key}
            value={getCodeByType(item.code, tenDigitCode)}
            name={item.name}
            data-position={`{ "lng": ${item.lng}, "lat": ${item.lat} }`}>
            {item.name}
          </option>
        );
      });
    }

    return <>{result}</>;
  }, [sidoCode, sigunguData1?.list]);

  const siGunGuOptionsSecond = useMemo(() => {
    let result = [
      <option key="" value="">
        구 {isSiGunGuOptional && '(선택)'}
      </option>,
    ];

    if (addAllOptionOnSigungu) {
      result.push(
        <option key="all" value="all">
          전체 지역
        </option>
      );
    }

    if (sidoCode && sigunguData2.status === STATUS.SUCCESS && sigunguData2?.list?.length > 0) {
      sigunguData2?.list?.forEach((item, key) => {
        if (result.findIndex((el) => el.key == item.code) < 0) {
          result.push(
            <option
              key={key}
              value={getCodeByType(item.sigungu_code, tenDigitCode)}
              name={item.sigungu}
              data-position={`{ "lng": ${item.lng}, "lat": ${item.lat} }`}>
              {item.sigungu}
            </option>
          );
        }
      });
    }

    return <>{result}</>;
  }, [sidoCode, sigunguData2?.list]);

  const onSiGunGuSelectFirst = (e) => {
    if (!disabled) {
      const key = e.target.value;
      const name = e.target.options[e.target.selectedIndex]?.text;
      const position = JSON.parse(e.target.options[e.target.selectedIndex]?.getAttribute('data-position'));
      const sigungu = { code: key, name: name, position };

      if (sigungu.code === 'all') {
        setData({
          ...data,
          sigungu: sigungu,
          sigungu1: sigungu,
          sigungu2: {},
          dong: { code: 'all' },
          dong1: { code: 'all' },
          dong2: { code: 'all' },
        });
      } else {
        setData({ ...data, sigungu: sigungu, sigungu1: sigungu, sigungu2: {}, dong: {}, dong1: {}, dong2: {} });
      }
      setSigunguData2({ status: STATUS.NOTASKED });
      setDongData1({ status: STATUS.NOTASKED });
      setDongData2({ status: STATUS.NOTASKED });
    }
  };

  const onSiGunGuSelectSecond = (e) => {
    if (!disabled) {
      const key = e.target.value;
      const name = e.target.options[e.target.selectedIndex]?.text;
      const position = JSON.parse(e.target.options[e.target.selectedIndex]?.getAttribute('data-position'));
      const sigungu = { code: key, name: name, position };
      setData({ ...data, sigungu: sigungu, sigungu2: sigungu, dong: {}, dong1: {}, dong2: {} });
      setDongData1({ status: STATUS.NOTASKED });
      setDongData2({ status: STATUS.NOTASKED });
    }
  };

  const dongRiOptionsFirst = useMemo(() => {
    let result = [
      <option key="" value="">
        읍/면/동 {isDongOptional && '(선택)'}
      </option>,
    ];

    if (addAllOptionOnDong) {
      result.push(
        <option key="all" value="all">
          전체 지역
        </option>
      );
    }

    if (dongData1?.status === STATUS.SUCCESS && dongData1?.list?.length > 0) {
      dongData1.list.forEach((item) => {
        result.push(
          <option
            key={item.dong_code}
            value={getCodeByType(item.dong_code, tenDigitCode)}
            name={item.name}
            data-position={`{ "lng": ${item.lng}, "lat": ${item.lat} }`}>
            {item.name}
          </option>
        );
      });
    }

    return <>{result}</>;
  }, [sigunguCode, dongData1?.list]);

  const dongRiOptionsSecond = useMemo(() => {
    let result = [
      <option key="" value="">
        동/리 {isDongOptional && '(선택)'}
      </option>,
    ];

    if (addAllOptionOnDong) {
      result.push(
        <option key="all" value="all">
          전체 지역
        </option>
      );
    }

    if (dongCode && dongData2?.status === STATUS.SUCCESS && dongData2?.list?.length > 0) {
      dongData2.list.forEach((item) => {
        result.push(
          <option
            key={item.dong_code}
            value={getCodeByType(item.dong_code, tenDigitCode)}
            name={item.ri}
            data-position={`{ "lng": ${item.lng}, "lat": ${item.lat} }`}>
            {item.ri}
          </option>
        );
      });
    }

    return <>{result}</>;
  }, [dongCode, dongData2?.list]);

  const onDongRiSelectFirst = (e) => {
    if (!disabled) {
      const key = e.target.value;
      const name = e.target.options[e.target.selectedIndex]?.text;
      const position = JSON.parse(e.target.options[e.target.selectedIndex]?.getAttribute('data-position'));
      const dong = { code: key, name: name, position };
      setData({ ...data, dong: dong, dong1: dong, dong2: {} });
      setDongData2({ status: STATUS.NOTASKED });
    }
  };

  const onDongRiSelectSecond = (e) => {
    if (!disabled) {
      const key = e.target.value;
      const name = e.target.options[e.target.selectedIndex]?.text;
      const position = JSON.parse(e.target.options[e.target.selectedIndex]?.getAttribute('data-position'));
      const dong = { code: key, name: name, position };
      setData({ ...data, dong: dong, dong2: dong });
    }
    if (refresh) {
      setData(null);
      setSidoData({ status: STATUS.NOTASKED });
      setSigunguData1({ status: STATUS.NOTASKED });
      setSigunguData2({ status: STATUS.NOTASKED });
      setDongData1({ status: STATUS.NOTASKED });
      setDongData2({ status: STATUS.NOTASKED });
      dispatch(actions.address.getSidoList({ onlyAvailable, only_broker, setter: setSidoData }));
    }
  };

  return {
    sidoCode,
    sigunguCode,
    sigunguCode1,
    sigunguCode2,
    dongCode,
    dongCode1,
    dongCode2,
    sidoData,
    sigunguData1,
    sigunguData2,
    dongData1,
    dongData2,
    siDoOptions,
    siGunGuOptionsFirst,
    siGunGuOptionsSecond,
    dongRiOptionsFirst,
    dongRiOptionsSecond,
    onSiDoSelect,
    onSiGunGuSelectFirst,
    onSiGunGuSelectSecond,
    onDongRiSelectFirst,
    onDongRiSelectSecond,
  };
};

export default useAddress;

// 세종시 시도, 시군구 코드는 36(시도) 110(시군구)로만 행정코드가 있으므로 예외처리 해준다.
const SEJONG = { cd: { sido: '36', sigungu: '36110' } };

const getCodeByType = (code, isTenDigit) => {
  return isTenDigit ? _padEnd(code, 10, '0') : code;
};
