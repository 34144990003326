import React, { useState } from 'react';
import FindIdPopup from './components/FindIdPopup';
import FindPasswordPopup from './components/FindPasswordPopup';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import reactDom from 'react-dom/';
import { POPUP_TYPES } from '@/pages/Login/views/constants';

const ExtraBtns = ({ handleClose }) => {
  const dispatch = useDispatch();

  const [showFindIdPopup, setShowFindIdPopup] = useState(false);
  const [showFindPasswordPoup, setShowFindPasswordPopup] = useState(false);

  const handleOpenPopup = (e) => {
    e.preventDefault();
    const { name } = e.currentTarget;

    if (name === POPUP_TYPES.FIND_ID) {
      setShowFindIdPopup(true);
    } else if (name === POPUP_TYPES.FIND_PW) {
      setShowFindPasswordPopup(true);
    } else {
      dispatch(
        actions.common.alert({
          contents: (
            <>
              현재 국내 모든 지역에서 매물 제공 중입니다.
              <br />* 개설 등록하신 개업 공인중개사만 회원 가입 가능합니다.
            </>
          ),
          onConfirm: () => {
            // handleClose && handleClose();
            dispatch(actions.router.push('/agentSignUp/steps/2'));
            dispatch(actions.common.toggleLoginPopup(false));
          },
        })
      );
    }
  };

  const links = [
    { name: POPUP_TYPES.FIND_ID, text: '아이디찾기' },
    { name: POPUP_TYPES.FIND_PW, text: '비밀번호찾기' },
    { name: POPUP_TYPES.REALTOR_SIGNUP, text: '중개사회원가입' },
  ];

  return (
    <>
      <div className="button-display module-a style-a type-c">
        <span className="button-area">
          {links?.map(({ name, text }) => (
            <>
              <Link className="btn" name={name} to="#" onClick={handleOpenPopup}>
                <span className="btn-text">{text}</span>
              </Link>
            </>
          ))}
        </span>
      </div>

      {showFindIdPopup
        ? reactDom.createPortal(<FindIdPopup show={showFindIdPopup} setShow={setShowFindIdPopup} />, document.getElementById('modal-root'))
        : false}

      {showFindPasswordPoup
        ? reactDom.createPortal(
            <FindPasswordPopup show={showFindPasswordPoup} setShow={setShowFindPasswordPopup} />,
            document.getElementById('modal-root')
          )
        : false}
    </>
  );
};

export default ExtraBtns;
