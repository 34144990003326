import React from 'react';
import { PostDate, WriterInfo } from '@/combinedComp/Community/sharedComponents';

const NestWriterInfo = ({ reply, deletedId, isOwner, parentData }) => {
  return deletedId?.indexOf(reply?.id) > -1 ? null : (
    <p className="data-list">
      <WriterInfo data={reply} isOwner={isOwner} parentData={parentData} />
      <PostDate data={reply} />
    </p>
  );
};

export default NestWriterInfo;
