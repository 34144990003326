import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';
import { history } from '@/data/configureStore';

/**
 * 제목, 닫기 header
 */
const NavWithClose = ({ title }) => {
  const dispatch = useDispatch();

  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(actions.router.replace('/home'));
  };

  return (
    <header id="header" className="header">
      <h2 className="title" id="mobile_sub_header_title">
        {title}
      </h2>
      <span className="btn-right">
        <a className="btn-img btn-close" onClick={handleBackClick}>
          뒤로
        </a>
      </span>
    </header>
  );
};

export default NavWithClose;
