import apis from '@/apis';

// 상세조회를 위해 사용할 api
export default (subjectType, userType) => {
  const apiKey = apiMapping[subjectType];

  return apisByUserType[userType][apiKey];
};

const apisByUserType = {
  guest: {
    getCommunityDetail: async (feedId) => await apis.communityApi.getFeed(feedId),
    getGoodsDetail: async (goodsIdx) => await apis.goodsApi.getGoodsDetail(goodsIdx),
    getCommonGoodsDetail: async (goodsCommonIdx) => await apis.sellingApi.getGoods(goodsCommonIdx),
  },
  realtor: {
    getCommunityDetail: async (feedId) => await apis.communityApi.getFeed(feedId),
    getGoodsDetail: async (goodsIdx) => await apis.goodsApi.getGoodsDetail(goodsIdx),
    getCommonGoodsDetail: async (goodsCommonIdx) => {
      const authority = await apis.brokerPageApi.getGoodsAuthority({ goodsCommonIdx });
      const INVALID_STATUS = ['VIEWABLE_WITH_COUPON', 'BLOCKED_BY_MEMBERSHIP', 'BLOCKED_BY_MEMBERSHIP_AREA', 'BLOCKED_BY_GOODS_STATUS'];

      if (INVALID_STATUS.includes(authority.status)) {
        if (authority.status === 'VIEWABLE_WITH_COUPON') {
          const tickets = await apis.coinApi.getMyCoinSummary('GOODS-TICKET');
          authority.otherData = { tickets };
        }

        authority.goodsCommonIdx = goodsCommonIdx;
        throw Error(JSON.stringify(authority));
      } else {
        const goodsDetail = await apis.brokerPageApi.getManageGoodsDetail({ goodsCommonIdx });
        return goodsDetail?.goodsCommonData;
      }
    },
  },
};

const apiMapping = {
  CONSULTING: null,
  GOODS: 'getGoodsDetail',
  USER_GOODS: 'getCommonGoodsDetail',
  COMMUNITY: 'getCommunityDetail',
  COMMUNITY_POST_FOR_BUY: 'getCommunityDetail',
};
