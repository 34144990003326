import React, { useEffect, useState } from 'react';
import { getFeedsByUser, getStatisticsByUser } from '@/data/community/services';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import FeedList from './components/feedList';
import CommentList from './components/commentList';
import MyComplexHistory from './components/MyComplexHistory';
import { useGetComplexHistoryByOne } from '@/combinedComp/Community/hooks';

const Core = ({ targetUserId }) => {
  const myInfo = useSelector(selectors.auth.getUserInfo);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  const [feeds, setFeeds] = useState();
  const [commentFeeds, setCommentFeeds] = useState();
  const [statistics, setStatistics] = useState();

  // id
  const myId = myInfo?.mbr_idx;
  const id = targetUserId || myId;

  useEffect(() => {
    if (id) {
      getFeedsByUser(id, 'feed', setFeeds);
      getFeedsByUser(id, 'comment', setCommentFeeds);
      getStatisticsByUser(id, setStatistics);
    }
  }, [id]);

  useGetComplexHistoryByOne({ userId: myId, isReady: true });

  return (
    <>
      {/* {(isCustomer || isMe) && <MyComplexHistory userId={mbr_idx} />} */}
      <MyComplexHistory userId={id} />
      <FeedList feeds={feeds} isLoggedIn={isLoggedIn} statistics={statistics} />
      <CommentList commentFeeds={commentFeeds} isLoggedIn={isLoggedIn} statistics={statistics} />
    </>
  );
};

export default Core;
