import * as AT from './actionTypes';

// 유저 별 소유 단지 정보 불러오기
export const setComplexHistoryByUsers = (payload) => ({
  type: AT.USER_SET_COMPLEX_HISTORY_BY_USERS,
  payload,
});

export const setRealtorsInfo = (payload) => ({
  type: AT.SET_REALTORS_INFO,
  payload,
});

export const resetRealtorsInfo = (payload) => ({
  type: AT.RESET_REALTORS_INFO,
  payload,
});
