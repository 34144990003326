import * as AT from './actionTypes';

export const getDetail = (state) => state?.buying?.detail[AT.GET_DETAIL].getOrElse({});
export const getAreaList = (state) => state?.buying?.detail?.areaList;
export const getSelectedSize = (state) => state?.buying?.detail?.selectedSize;
export const getselectedType = (state) => state?.buying?.detail?.selectedType;
export const getTradehistory = (state) => state?.buying?.detail?.tradeHistory;
export const getTrade = (state) => state?.buying?.detail?.trade;
export const getCharterPrediction = (state) => state?.buying?.detail?.charterPrediction;
export const getTax = (state) => state?.buying?.detail?.tax;
export const getBrokerList = (state) => state?.buying?.detail?.brokerList;
export const getSchool = (state) => state?.buying?.detail?.school;
export const getKindetgarten = (state) => state?.buying?.detail?.kindergarten;
export const getDaycare = (state) => state?.buying?.detail?.daycare;
export const getSales = (state) => state?.buying?.detail?.sales;
export const getSalesLoading = (state) => state?.buying?.detail?.loadingSales;
// 네이버매물 - 작업중
export const getFacilityData = (state) => state?.buying?.detail?.facilityData;
export const getFacilitySchoolData = (state) => state?.buying?.detail?.facilitySchoolData;
export const getFacilityCode = (state) => state?.buying?.detail?.facilityCode;
export const getFacilityDataUser = (state) => state?.buying?.detail?.facilityDataUser;
export const getMarketData = (state) => state?.buying?.detail?.marketData;
export const getMarketDataStatus = (state) => state?.buying?.detail?.marketDataStatus;
export const getSelectedMarketItems = (state) => state?.buying?.detail?.selectedMarketItems;
export const getNaverSales = (state) => state?.buying?.detail?.naverData;
export const getNaverSaleDetail = (state) => state?.buying?.detail?.saleDetailNaver;
// 네이버매물 - 작업중
export const getSaleDetail = (state) => state?.buying?.detail?.saleDetail;
export const getMySaleDetail = (state) => state?.buying?.detail?.saleMyDetail;
export const getBrokerOfficeList = (state) => state?.buying?.detail?.brokerOfficeList;
export const getSchoolDetail = (state) => state?.buying?.detail?.schoolDetail;
export const getKinderDetail = (state) => state?.buying?.detail?.kindergartenDetail;
export const getDaycareDetail = (state) => state?.buying?.detail?.daycareDetail;
export const getBrokerDetail = (state) => state?.buying?.detail?.brokerDetail;
export const askToBroker = (state) => state?.buying?.detail[AT.ASK_TO_BROKER].getOrElse(false);
export const getSchoolInfoWithKakao = (state) => state?.buying?.detail?.schoolInfo;
export const updateNewFeed = (state) => state?.buying?.detail?.newFeed;
export const getBrokerListBySelectedGoods = (state) => state?.buying?.detail?.brokerListBySelectedGoods;
