import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const MG_COUNSELING_STATE = {
  limit: 5,
  keyword: '',
  new: { sel: 1, goodsIdx: '' },
};

export default function mgCounseling(state = MG_COUNSELING_STATE, action = {}) {
  switch (action.type) {
    case AT.GET_MG_COUNSELING_LIST: {
      return {
        ...state,
      };
    }
    case AT.GET_MG_COUNSELING_GOODS_LIST: {
      return {
        ...state,
      };
    }
    case AT.SAVE_MGC: {
      return {
        ...state,
      };
    }

    case loading(AT.GET_MG_COUNSELING_LIST):
      return { ...state, [AT.GET_MG_COUNSELING_LIST]: Remote.Loading };
    case success(AT.GET_MG_COUNSELING_LIST):
      return { ...state, mgcList: action.mgcList };
    case failure(AT.GET_MG_COUNSELING_LIST):
      return {
        ...state,
        [AT.GET_MG_COUNSELING_LIST]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.GET_MG_COUNSELING_GOODS_LIST):
      return { ...state, [AT.GET_MG_COUNSELING_GOODS_LIST]: Remote.Loading };
    case success(AT.GET_MG_COUNSELING_GOODS_LIST):
      return { ...state, mgCounselingGoodsList: action.goodsList };
    case failure(AT.GET_MG_COUNSELING_GOODS_LIST):
      return {
        ...state,
        [AT.GET_MG_COUNSELING_GOODS_LIST]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.SAVE_MGC):
      return { ...state };
    case success(AT.SAVE_MGC):
      return { ...state, new: { goodsIdx: '', sel: 1 } };
    case failure(AT.SAVE_MGC):
      return {
        ...state,
        [AT.SAVE_MGC]: Remote.Failure(action.error),
        error: action.error,
      };

    case AT.CHANGE_GOODS_IDX: {
      return {
        ...state,
        new: { ...state.new, goodsIdx: action.goodsIdx },
      };
    }
    case AT.CHANGE_GOODS_COMMON_IDX: {
      return {
        ...state,
        new: { ...state.new, goodsCommonIdx: action.goodsCommonIdx },
      };
    }
    case AT.SET_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.SET_LIMIT: {
      return {
        ...state,
        limit: state.limit + 5,
      };
    }
    case AT.RESET_LIMIT: {
      return {
        ...state,
        limit: 5,
      };
    }
    case AT.SET_IN_SEL: {
      return {
        ...state,
        new: { ...state.new, sel: action.sel },
      };
    }
    case AT.SET_IN_CP: {
      return {
        ...state,
        new: { ...state.new, cp: action.cp },
      };
    }
    case AT.SET_IN_CONTENTS: {
      return {
        ...state,
        new: { ...state.new, contents: action.contents },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
