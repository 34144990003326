import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// 단지상세보기로 이동
// initial_area: (optional) 대표평형이 아닌 평형으로 최초 rendering
// initial_type: (optional) 매매가 아닌 거래타입으로 최초 rendering
export const routeToComplexDetail = (complex_idx, initial_area, initial_type, options) => (dispatch) => {
  const path = getComplexDetailPath(complex_idx, initial_area, initial_type, options);
  const watching = location.search.indexOf('d_complex') > -1;
  if (watching) {
    dispatch(actions.router.replace(path));
  } else {
    dispatch(actions.router.push(path));
  }
};

// 단지상세보기 URL
// 2021-11-24 배지율 수정
// 기존 : 종속되는 파라미터들 만 제거함.
// 분석과 화면이 결합됨에 따라 제어해야하는 컴포넌트들이 많아짐
// 따라서 단지상세로 갈 때 쿼리스트링에 단지상세와 관련된 파라미터 만 포함하도록 변경함.
export const getComplexDetailPath = (complex_idx, initial_area, initial_type, options) => {
  // const querystring = window.location.search;
  // const queryObject = parseCallBack(querystring) || {};
  const queryObject = {};
  queryObject.d_complex = complex_idx;

  initial_area && (queryObject.initial_area = initial_area);
  initial_type && (queryObject.initial_trade_type = initial_type);

  // /* 종속되는 파라미터들 제거 */
  // // 재건축사업성 상세
  // delete queryObject.open_recon;
  // // 시세 리스트
  // delete queryObject.d_market_list;
  // // 시세 상세
  // delete queryObject.d_trade_type;
  // delete queryObject.d_area;
  // // 매물 상세
  // delete queryObject.d_goods;
  // // 교육 상세
  // delete queryObject.edu_detail;

  const newQuerystring = objectToQuerystring(queryObject);
  return `${options?.initial_path || location.pathname}${newQuerystring}`;
};

// 단지상세 닫기
export const closeComplexPath = () => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  /* 종속되는 파라미터들 제거 */
  delete queryObject.d_complex;
  // 재건축사업성 상세
  delete queryObject.open_recon;
  // 시세 리스트
  delete queryObject.d_market_list;
  // 시세 상세
  delete queryObject.d_trade_type;
  delete queryObject.d_area;
  // 매물 상세
  delete queryObject.d_goods;
  // 교육 상세
  delete queryObject.edu_detail;

  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};
