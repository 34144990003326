import { useEffect } from 'react';

const useFetchMoreLoggedIn = ({ isLoggedIn, fetchMoreListItems }) => {
  useEffect(() => {
    if (isLoggedIn) {
      fetchMoreListItems();
    }
  }, [isLoggedIn]);

  return;
};

export default useFetchMoreLoggedIn;
