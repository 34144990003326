import React from 'react';
import { routeToNextStep } from '@/pages/Login/helpsers';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { OSTYPE } from '@/services/apps/types/OsType';

// 애플 로그인은 iOS 어플에만 노출
const Apple = () => {
  const dispatch = useDispatch();
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const osType = osTypeInstance?.osType;

  async function loginWithApple(e) {
    e.preventDefault();
    dispatch(routeToNextStep('apple'));

    try {
      const result = await window.dawin_instance.bridge.thirdPartyFunction.appleSignIn();
      dispatch(actions.auth.social1({ stype: 'Apple', accessToken: result.accessToken }));
    } catch (e) {
      if (e.message !== 'user cancelled') {
        dispatch(actions.common.alert({ contents: '애플로 로그인 처리 중 오류가 발생하였습니다.' }));
      }
    }
  }

  if (osType === OSTYPE.APP_IOS) {
    return (
      <span className="button-area">
        <button type="submit" className="btn module-b style-b type-fill social-03 large-1x flex symbol-ltr-brand-apple" onClick={loginWithApple}>
          <span className="btn-text">애플로 시작하기</span>
        </button>
      </span>
    );
  } else return false;
};

export default Apple;
