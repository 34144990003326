import React, { useEffect, useMemo, useState } from 'react';
import { COOKIE_CONSTS } from '@/resources/global';
import { getCookieByKey, setCookieByKey } from '@/data/cookie';
import { useDispatch } from 'react-redux';
import { goToDetail } from '../hooks/useSearch';
import toggle from '@/hocs/toggle';

const CurrentSearchList = ({ setShow, setSearchMode, interestComplex, handleRedirectToOther, gaTracking, inputRef }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const handleClick = (item) => {
    setShow(false);
    // setSearchMode(false);
    dispatch(goToDetail(item, true, handleRedirectToOther));

    const keyword = item.title || item.complex_name;
    inputRef.current.value = keyword;
    gaTracking(keyword);
  };

  useEffect(async () => {
    const searchHistory = await getCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY);
    if (searchHistory) {
      setList(searchHistory);
    }
  }, []);

  const elements = useMemo(() => {
    return list.map((item, key) => {
      let date = new Date(item.createdAt);
      date = `${date.getMonth() + 1}.${date.getDate()}`;

      const onDelete = (e) => {
        e.stopPropagation();
        var temp = [...list];
        temp.splice(key, 1);
        setList(temp);
        setCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY, temp);
      };

      const action = () => {
        handleClick(item);
      };

      return (
        <li key={key} className="data-item" onClick={action}>
          <div className="data-wrap">
            <span className="data-head">
              <span className="data-subject">
                <a className="data-name">{item.title}</a>
              </span>
            </span>
            <span className="data-info">
              <span className="data-list">
                <span className="data-item date">
                  <span className="data-head">날짜</span>
                  <span className="data-body">{date}</span>
                  <span className="data-func">
                    <button type="button" className="btn delete" onClick={onDelete}>
                      <span className="btn-text">삭제</span>
                    </button>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </li>
      );
    });
  }, [list]);

  const filteredComplex = interestComplex
    .sort((a, b) => {
      const date1 = new Date(a.update_date).getTime();
      const date2 = new Date(b.update_date).getTime();
      return date2 - date1;
    })
    .filter((a, key) => key < 3);

  const interests = useMemo(() => {
    return filteredComplex
      ?.filter((data) => data.complex)
      ?.map((data, key) => {
        const action = () => {
          handleClick(item);
        };
        const item = data.complex;

        return (
          <li key={key} className="data-item" onClick={action}>
            <div className="data-wrap">
              <div className="data-head">
                <span className="data-subject">
                  <a className="data-name">{item.complex_name}</a>
                </span>
                <span className="data-summary">{item.complex_address}</span>
              </div>
            </div>
          </li>
        );
      });
  }, [filteredComplex]);

  return (
    <div className="data-board data-finder-associative" style={{ display: 'unset' }}>
      <div className="board-wrap">
        <div className="board-body">
          <div className="data-board">
            <div className="board-wrap">
              <div className="board-head">
                <p className="board-subject">
                  <strong className="board-name">최근 검색어</strong>
                </p>
              </div>
              <div className="board-body">
                <div className="data-display">
                  <ul className="data-list">{!!elements && elements.length > 0 ? elements : <li>최근 검색어가 없습니다.</li>}</ul>
                </div>
              </div>
            </div>
          </div>
          <div className="data-board">
            <div className="board-wrap">
              <div className="board-head">
                <p className="board-subject">
                  <strong className="board-name">관심단지</strong>
                </p>
              </div>
              <div className="board-body">
                <div className="data-display">
                  <ul className="data-list">
                    {!!interests && interests.length > 0 ? (
                      interests
                    ) : (
                      <li className="data-item">
                        <div className="data-wrap">
                          <div className="data-head">
                            <span className="data-subject">
                              <a className="data-name">관심단지가 없습니다.</a>
                            </span>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default toggle(CurrentSearchList);
