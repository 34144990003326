import * as AT from './actionTypes';
import { produce } from 'immer';

const INITIAL_STATE = {
  myMembershipInfo: null,
};

export default function payment(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_MY_MEMBERSHIP:
      return produce(state, (draft) => {
        draft.myMembershipInfo = action.data;
      });
    default: {
      return state;
    }
  }
}
