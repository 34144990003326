// 스텝
const STEPS = {
  FIRST: '0',
  PHONE1: '1',
  PHONE2: '2',
  PHONE3: '3',
  PHONE4: '4',
  BROKER: 'broker',
  BROKER_PHONE: 'brokerPhone',
  EMAIL: '20',
  SOCIAL1: '30',
  SOCIAL2: '31',
  SOCIAL3: '32',
  AGREE: '40',
  END: '99',
};

// 로그인 스텝에 따른 쿼리스트링
const QUERYSTRING_BY_LOGIN_STEP = {
  // 중개사 로그인
  [STEPS.BROKER]: 'broker',
  // 중개사 전화번호 로그인
  [STEPS.BROKER_PHONE]: 'brokerPhone',
  // 휴대폰 번호 입력
  [STEPS.PHONE1]: 'phone',
  [STEPS.SOCIAL2]: 'phone', // 최초 소셜 로그인
  // 휴대폰 번호 인증
  [STEPS.PHONE2]: 'phone_confirm',
  [STEPS.SOCIAL3]: 'phone_confirm',
  // 휴대폰 번호에 연결된 다수의 회원정보 존재
  [STEPS.PHONE3]: 'multiple_account',
  // 휴대폰 번호로 최초 로그인 > 연동할 소셜 로그인 선택
  [STEPS.PHONE4]: 'select_social',
  [STEPS.EMAIL]: 'emailcheck',
  [STEPS.AGREE]: 'agree',
};

// 생일 정보 없을 때 00000000
// gender 없을 때 null
const makeProfile = (gender, birth) => {
  birth = birth ? birth : '';
  birth = birth.replace(/-/gi, '');
  const birthLengh = 8;
  const b = Array(birthLengh - String(birth).length + 1).join('0') + birth;
  let s = null;

  if (gender) {
    if (gender.toLowerCase() === 'f' || gender === 'female') {
      s = 2;
    } else if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'male') {
      s = 1;
    }
  }

  return { b: b, s: s };
};
export { STEPS, QUERYSTRING_BY_LOGIN_STEP, makeProfile };
