import { selectors } from '@/data';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { WriterInfo as FeedWriterInfo, PostDate as FeedPostDate, Tool as FeedTool } from '@/combinedComp/Community/sharedComponents';
import CallChatBtns from './CallChatBtns';
import { useSetUserName, useShowDeletePopup } from './hooks';

const FeedTop = ({ feedData, detail_item, isOwner }) => {
  const feed = useSelector(selectors.community.getFeed);
  const [deleteStatus, setDeleteStatus] = useState();
  const type = 'FEED';
  const userName = useSetUserName({ feed });

  // 글 삭제 후 팝업
  useShowDeletePopup({ deleteStatus });

  return !detail_item || detail_item.feedId ? (
    <>
      <p className="data-list">
        <FeedWriterInfo data={feedData} isOwner={isOwner} />
        {feedData && <FeedPostDate data={feedData} />}
        <CallChatBtns feed={feed} userName={userName} />
      </p>
      <FeedTool setDeleteStatus={setDeleteStatus} data={feedData} type={type} />
    </>
  ) : (
    false
  );
};

export default FeedTop;
