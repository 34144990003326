import React, { useState } from 'react';
// import Filter from '@/pages/Buying/FilterSection';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import OptionButton from './OptionButton';
import OptionSlider from './OptionSlider';
import OptionSlider2 from './OptionSlider2';
import { BUYING_SEARCH_OPTIONS } from '@/resources/global';
import { FILTER_TYPES } from './constants';
import router from '@/services/router';

const FilterPopup = ({ show }) => {
  const dispatch = useDispatch();

  const handleCategory = (data) => dispatch(actions.buying.changeCategory(data));
  const handleTradetype = (data) => dispatch(actions.buying.changeTradetype(data));
  const handleAreasize = (data) => dispatch(actions.buying.changeAreasize(data));

  const querystring = router.querystring.parse();
  const [filterType] = useState(querystring.searchfilter);
  const isAllFilter = !filterType || filterType === 'true';

  const handleCntRange = (data) => {
    if (data[1] === 3000) data[1] = null;
    dispatch(actions.buying.changeCntRange(data));
  };
  const handleYearRange = (data = []) => {
    if (data[0] === -30) data[0] = null;
    if (data[1] === 0) data[1] = null;
    dispatch(actions.buying.changeYearRange(data));
  };
  // const handlePriceRange = (data) => {
  //   if (data[0] === 0 && data[1] === 30) {
  //     dispatch(actions.buying.changePriceRange(null));
  //   } else {
  //     dispatch(actions.buying.changePriceRange(data));
  //   }
  // };

  const selectedCategory = useSelector(selectors.buying.getCategory);
  const selectedTradetype = useSelector(selectors.buying.getTradetype);
  const selectedAreasize = useSelector(selectors.buying.getAreasize);
  const selectedCntRange = useSelector(selectors.buying.getCntRange);
  const selectedYearRange = useSelector(selectors.buying.getYearRange);

  // 닫기
  const handleClose = () => {
    // setShowFilterOption(false);
    dispatch(actions.router.goBack());
  };

  let selectedYearRangeTemp = selectedYearRange ? [...selectedYearRange] : [-30, 0];
  if (selectedYearRangeTemp[0] === null) selectedYearRangeTemp[0] = -30;
  if (selectedYearRangeTemp[1] === null) selectedYearRangeTemp[1] = 0;

  return (
    <div
      className={`front-popup module-a style-a type-a medium ${show ? 'enabled active' : ''}`}
      id="dataFinderFilter"
      data-bui-toggle="contentsPopup"
      tabIndex="0">
      <div className="popup-page-body">
        <div className="popup-local">
          <div className="popup-local-head">
            <h2 className="popup-local-subject">
              <span className="popup-local-name">지도 검색 필터</span>
            </h2>
          </div>
          <div className="popup-local-body">
            <div className="popup-content">
              {/* <!-- popup-content-body --> */}
              <form className="popup-content-body">
                {/* <!-- submit-form --> */}
                <fieldset className="submit-form module-a type-c medium">
                  <legend>매물 검색 서식</legend>
                  <div className="form-list">
                    {/* 매물유형: 아파트 / 오피스텔 */}
                    {isAllFilter || filterType === FILTER_TYPES.CATEGORY ? (
                      <OptionButton
                        title="매물유형"
                        type="radio"
                        name="radio-0001"
                        options={BUYING_SEARCH_OPTIONS.A}
                        selected={selectedCategory}
                        onSelect={handleCategory}
                      />
                    ) : (
                      false
                    )}
                    {isAllFilter || filterType === FILTER_TYPES.TRADETYPE ? (
                      <OptionButton
                        title="거래유형"
                        type="checkbox"
                        name="check-0002"
                        options={BUYING_SEARCH_OPTIONS.C2}
                        selected={selectedTradetype}
                        onSelect={handleTradetype}
                      />
                    ) : (
                      false
                    )}
                    {isAllFilter || filterType === FILTER_TYPES.AREASIZE ? (
                      <OptionButton
                        title="면적"
                        type="checkbox"
                        options={BUYING_SEARCH_OPTIONS.D3}
                        selected={selectedAreasize}
                        onSelect={handleAreasize}
                      />
                    ) : (
                      false
                    )}

                    {/* DX-1165 세부유형 삭제 */}
                    {/* <OptionButton
                      title="세부유형"
                      name="radio-0003"
                      type="radio"
                      options={BUYING_SEARCH_OPTIONS.B}
                      selected={selectedDealtype}
                      onSelect={handleDealtype}
                    /> */}

                    {isAllFilter || filterType === FILTER_TYPES.HOUSECNT ? (
                      <OptionSlider
                        id="filterPopupSliderHouseCnt"
                        title="세대수"
                        marks={houseCntMarks}
                        range={selectedCntRange ? selectedCntRange : [0, 3000]}
                        rangeString="0, 1,000, 2.000, 3,000 이상"
                        min={0}
                        max={3000}
                        step={100}
                        handleChange={handleCntRange}
                      />
                    ) : (
                      false
                    )}
                    {isAllFilter || filterType === FILTER_TYPES.YEAR ? (
                      <OptionSlider2
                        id="filterPopupSliderHouseYear"
                        title="준공년도"
                        marks={houseYearMarks}
                        range={selectedYearRangeTemp}
                        rangeString="30년차 이상, 20년차, 10년차, 현재"
                        min={-30}
                        max={0}
                        step={1}
                        handleChange={handleYearRange}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </fieldset>
                {/* <!-- //submit-form --> */}
              </form>
              {/* <!-- //popup-content-body --> */}
            </div>
          </div>
          {/* <!-- //popup-local-body --> */}
          {/* <!-- //popup-local-util --> */}
          <div className="popup-local-util">
            <div className="button-display module-a style-a type-d">
              <span className="button-area">
                <button onClick={handleClose} type="submit" className="btn module-b style-a type-fill large-2x accent-01 flex">
                  <span className="btn-text">적용</span>
                </button>
              </span>
            </div>
          </div>
          {/* <!-- //popup-local-util --> */}
          <div className="popup-local-func">
            <span className="button-area">
              <button onClick={handleClose} type="button" className="btn popup-close">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                  <title>닫기</title>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg>
              </button>
            </span>
          </div>
        </div>
        {/* <!-- //popup-local --> */}
      </div>
    </div>
  );
};

export default FilterPopup;

// 0~3000으로 100세대 간격이동, 표시는 0, 1000, 2000, 3000이상
const houseCntMarks = [
  { value: 0, label: '0' },
  { value: 1000, label: '1000' },
  { value: 2000, label: '2000' },
  { value: 3000, label: '3000이상' },
];

// -30년부터 1년단위 간격이동, 표시는 30년차 이상, 20년차, 10년차, 현재년 (30년차 이상, 20년차, 10년차, 2021년)
const houseYearMarks = [
  { value: -30, label: '30년차이상' },
  { value: -20, label: '20년차' },
  { value: -10, label: '10년차' },
  { value: 0, label: '현재' },
];
