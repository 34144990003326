import { isNil, isEmpty, test } from 'ramda';
import apis from '@/apis';

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const isEmail = test(emailRegex);

export const composeValidators = (...args) => (value) => {
  for (const validator of args) {
    const error = validator(value);

    if (error) {
      return error;
    }
  }

  return undefined;
};

const pwPattern1 = /[0-9]/;
const pwPattern2 = /[a-zA-Z]/;
export const createConfirmedPasswordValidator = (password) => (value) => (password === value ? undefined : '비밀번호가 동일하지 않습니다.');
export const passwordCheck = (value) =>
  !pwPattern1.test(value) || !pwPattern2.test(value) || value.length < 8 || value.length > 15 ? '영문, 숫자를 포함한 8~15자리' : undefined;

export const required = (value) => (!isNil(value) && !isEmpty(value) ? undefined : '필수 입력');
export const checked = (value) => (value ? undefined : true);

export const validEmail = (value) => (isEmail(value) ? undefined : 'Invalid Email');

export const validUniqueEmail = async (value) => {
  if (required(value)) return required(value);
  if (validEmail(value)) return validEmail(value);

  try {
    const res = await apis.authApi.checkEmailExistence({ address: value });
    return res ? 'Duplicated email' : undefined;
  } catch (e) {
    return undefined;
  }
};

export const validName = (value) => (value.length >= 2 ? undefined : '유효하지 않음');
/** @deprecated */
export const validPhoneNumber = (value) => {
  let standardLength = 13;
  if (value.substr(0, 3) != '010') {
    standardLength = 12;
  }
  return value.length >= standardLength ? undefined : '유효하지 않은 핸드폰번호';
};
