import { loading, success, failure } from '@/data/utils';

//데일리 리포트
export const SET_DAILY_REPORT_DATE = 'BROKERPAGE/SET_DAILY_REPORT_VAL';

export const SET_INSERT_DATA = 'BROKERPAGE/SET_INSERT_DATA';
export const SAVE_DAILY_REPORT = 'BROKERPAGE/SAVE_DAILY_REPORT';
export const DAILY_REPORT_INI = 'BROKERPAGE/DAILY_REPORT_INI';

export const GET_DAILY_REPORT_LIST = 'BROKERPAGE/GET_DAILY_REPORT_LIST';
export const GET_DAILY_REPORT_LIST_LOADING = loading(GET_DAILY_REPORT_LIST);
export const GET_DAILY_REPORT_LIST_SUCCESS = success(GET_DAILY_REPORT_LIST);
export const GET_DAILY_REPORT_LIST_FAILURE = failure(GET_DAILY_REPORT_LIST);
