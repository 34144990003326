import { apiClient2 } from '@/apis/clients';

export const api = {
  // userId 한개 단위로 소유 단지 인증 내역 요청 api
  async getComplexHistoryByUserId(userId) {
    try {
      return await apiClient2.get(`/user-api/v1/users/${userId}/complex-histories`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // userId 벌크로 소유 단지 인증 내역 요청 api
  async getComplexHistoryBulkByUserIds(payload) {
    try {
      return await apiClient2.post(`/user-api/v1/users/ids/complex-histories`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
