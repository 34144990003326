import * as AT from './actionTypes';
import Remote from '@/data/remote';
import CONSTANTS from '@/pages/BrokerPage/constants';
import { failure, loading, success } from '@/data/utils';
import dayjs from 'dayjs';

const today = new Date();

export const MG_GOODS = {
  gubun: CONSTANTS.ALL_GOODS,
  keyword: '',
  category: ['all'],
  tradetype: ['all'],
  areasize: '',
  price: '',
  price2: '',
  status: 'all',
  brokerageType: 'all',
  page: 1,
  total: '',
  order: '',
  orderDir: '',
  option: [],
  fakeSaleReportRes: false,
  goodsList: [],
  nextDataYn: false,
  contractInfo: {
    goods_idx: '',
    buyer_name: '',
    buyer_cp: '',
    trade_type: '1',
    contract_price: '',
    contract_date: '',
    intermediate_date1: '',
    intermediate_date2: '',
    intermediate_price1: '',
    intermediate_price2: '',
    balance_price: '',
    balance_date: '',
    sale_price: '',
    deposit: '',
    monthly_price: '',
    fee_rate: '',
    buyer_agree: 'Y',
  },
  details: [
    { detailType: 'MC', schedule: dayjs(today).format('YYYY-MM-DD'), price: undefined },
    // { detailType: 'BP', schedule: '', price: '' },
  ],
  members: [],
  ip: { schedule: [], price: [] },
  seller: { name: [], phone: [] },
  buyer: { name: [], phone: [] },
  broker: { name: [], phone: [] },
};
const DETAIL_INITIAL = {
  goods_common_idx: '',
  reg_date: '',
  reg_name: '',
  reg_cp: '',
  trade_type: '',
  customer_memo_cnt: 0,
  category: '',
  room_type: '',
  address: '',
  lat: 0.0,
  lng: 0.0,
  complex_name: '',
  dong: 0,
  ho: 0,
  total_floor: 0,
  floor: 0,
  area1: 0,
  area2: 0,
  pyeong1: 0,
  pyeong2: 0,
  parking_flag: 'Y',
  room_cnt: 0,
  bathroom_cnt: 0,
  history: [],
  enter_type: '',
  enter_date: '',
  owner_live: '',
  balcony: '',
  direction: '',
  animal: '',
  front_door_type: '',
  images: [],
  cpx_images: [],
  owner_comment: '',
  seller_qa: [],
  buyer_qa: [],
};

export default function mgGoods(state = MG_GOODS, action = {}) {
  switch (action.type) {
    //=======================manage_goods=======================
    case AT.GOODS_CHANGE_CATEGORY: {
      return { ...state, category: action.category };
    }

    case AT.GOODS_CHANGE_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.SET_MANAGE_GOODS_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.scroll,
      };
    }
    case AT.SET_TOTAL_CNT: {
      return {
        ...state,
        totalCnt: action.totalCnt,
      };
    }
    case AT.GOODS_CHANGE_TRADETYPE:
      var item = action.tradetype;
      if (state.tradetype.indexOf(item) < 0) {
        //1.선택
        if (state.tradetype.indexOf('all') > -1 && state.tradetype.length === 1) {
          return { ...state, tradetype: [...state.tradetype.filter((size) => size !== 'all'), item] };
        } else if ((state.tradetype.indexOf('all') < 0 && state.tradetype.length === 2) || item === 'all') {
          return { ...state, tradetype: ['all'] };
        } else {
          return { ...state, tradetype: [...state.tradetype, item] };
        }
      } else {
        //2.해제
        if (state.tradetype.length === 1) {
          //모두 선택 해제한 경우
          return { ...state, tradetype: ['all'] };
        } else {
          return {
            ...state,
            tradetype: state.tradetype.filter((size) => size !== item && size !== 'all'),
          };
        }
      }
    case AT.RESET_GOODS_DETAIL: {
      return {
        ...state,
        goodsDetail: {},
        modigoods: {},
        goodsMemo: [],
      };
    }
    case AT.REMOVE_GOODS_NEW: {
      const new_goodsList = state?.goodsList?.map?.((item) =>
        item?.goods_idx == action.goods_common_idx ? { ...item, goods_new_yn: 'N' } : { ...item }
      );
      return {
        ...state,
        goodsList: new_goodsList,
      };
    }
    case AT.GOODS_CHANGE_AREASIZE: {
      return {
        ...state,
        areasize: action.areasize,
      };
    }
    case AT.GOODS_CHANGE_PRICE: {
      return {
        ...state,
        price: action.price,
      };
    }
    case AT.GOODS_CHANGE_PRICE2: {
      return {
        ...state,
        price2: action.price2,
      };
    }
    case AT.GOODS_CHANGE_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }
    case AT.GOODS_CHANGE_BROKERAGE_TYPE: {
      return {
        ...state,
        brokerageType: action.brokerageType,
      };
    }
    case AT.GOODS_CHANGE_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.GOODS_CHANGE_GUBUN: {
      return {
        ...state,
        gubun: action.gubun,
      };
    }
    case AT.GET_MANAGE_GOODS_DETAIL: {
      let goods = state.goodsList?.map((ii) => {
        if (ii?.goods_common_idx == action.goods_common_idx) {
          ii = { ...ii, read_yn: 'Y' };
        }
        return ii;
      });
      return {
        ...state,
        goodsList: goods,
      };
    }
    case AT.SET_MANAGE_GOODS_DETAIL: {
      return {
        ...state,
        goodsDetail_status: action.status,
        goodsDetail: action.goodsDetail,
        modigoods: action.goodsDetail,
      };
    }
    case AT.SET_GOODS_COMMON_DETAIL: {
      return {
        ...state,
        goodsCommonDetail: action.data,
      };
    }
    case AT.MANAGE_GOODS_BROKER_COMMENT: {
      return {
        ...state,
        param: action.param,
      };
    }
    case AT.MANAGE_GOODS_MEMO: {
      return {
        ...state,
        param: action.param,
      };
    }
    case AT.SET_GOODS_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.SET_NEXT_GOODS_DATA: {
      return {
        ...state,
        nextDataYn: action.yn,
      };
    }
    case loading(AT.GET_MANAGE_GOODS_DETAIL):
      return { ...state, [AT.GET_MANAGE_GOODS_DETAIL]: Remote.Loading };
    case failure(AT.GET_MANAGE_GOODS_DETAIL):
      return {
        ...state,
        [AT.GET_MANAGE_GOODS_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_MANAGE_GOODS_LIST):
      return { ...state, [AT.GET_MANAGE_GOODS_LIST]: Remote.Loading };
    case success(AT.GET_MANAGE_GOODS_LIST): {
      if (state?.page > 1) {
        // let goods_origin = state.goodsList;
        // action.goodsList?.map?.((ii) => goods_origin.push(ii));
        return {
          ...state,
          goodsList: [
            // ...state.goodsList,
            ...action.goodsList,
          ],
          [AT.GET_MANAGE_GOODS_LIST]: Remote.Success([]),
        };
      } else {
        return { ...state, goodsList: action.goodsList, [AT.GET_MANAGE_GOODS_LIST]: Remote.Success([]) };
      }
    }
    case failure(AT.GET_MANAGE_GOODS_LIST):
      return {
        ...state,
        [AT.GET_MANAGE_GOODS_LIST]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_MANAGE_GOODS_MEMO):
      return { ...state, [AT.GET_MANAGE_GOODS_MEMO]: Remote.Loading };
    case success(AT.GET_MANAGE_GOODS_MEMO): {
      return { ...state, goodsMemo: action.memoList };
    }
    case AT.SET_MANAGE_GOODS_LIST_COUNT:
      return { ...state, goodsListCount: action.goodsListCount };

    case failure(AT.GET_MANAGE_GOODS_MEMO):
      return {
        ...state,
        [AT.GET_MANAGE_GOODS_MEMO]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_GOODS_HISTORY):
      return { ...state, [AT.GET_GOODS_HISTORY]: Remote.Loading };
    case success(AT.GET_GOODS_HISTORY): {
      return { ...state, goodsHistory: action.historyList };
    }
    case failure(AT.GET_GOODS_HISTORY):
      return {
        ...state,
        [AT.GET_GOODS_HISTORY]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_BROKER_COMMENT):
      return { ...state, [AT.GET_BROKER_COMMENT]: Remote.Loading };
    case success(AT.GET_BROKER_COMMENT): {
      return { ...state, brokerComments: action.commentList };
    }
    case failure(AT.GET_BROKER_COMMENT):
      return {
        ...state,
        [AT.GET_BROKER_COMMENT]: Remote.Failure(action.error),
        error: action.error,
      };
    case AT.GOODS_SEARCH_SUCCESS: {
      return {
        ...state,
        goodsList: action.goodsList,
      };
    }
    case AT.GOODS_FAVORITE_DESIGNATE: {
      return {
        ...state,
      };
    }
    case AT.GOODS_FAVORITE_DE_DESIGNATE: {
      return {
        ...state,
      };
    }
    case AT.FAKE_SALE_REPORT: {
      return {
        ...state,
        param: action.param,
      };
    }
    case AT.SET_FAKE_SALE_REPORT_RES: {
      return {
        ...state,
        fakeSaleReportRes: action.res,
      };
    }
    // case AT.CONTRACT_INFO_SAVE: {
    //   return {
    //     ...state,
    //     contractInfo: action.payload,
    //   };
    // }
    case AT.SET_CONTRACT_INFO: {
      return {
        ...state,
        contractInfo: action.payload,
      };
    }
    case AT.SET_CONTRACT_DETAILS: {
      let originData = state?.details?.filter((ii) => ii.detailType != action.detailType);
      let schedule = state?.ip?.schedule ?? [];
      let price = state?.ip?.price ?? [];

      if (action.detailType != 'IP') {
        let [changeData] = state?.details?.filter((ii) => ii.detailType == action.detailType) ?? {};
        changeData = { ...changeData, detailType: action.detailType };
        changeData[`${action.key}`] = action.value;
        originData.push(changeData);
      } else {
        if (action.key == 'schedule') {
          schedule[action.index] = action.value;
        } else {
          price[action.index] = action.value;
        }
      }

      if (action.detailType === 'IP') {
        if (state?.ip?.price?.length > 0) {
          state?.ip.price?.map((i, k) => {
            originData.push({ detailType: 'IP', schedule: state?.ip?.schedule[k], price: i });
          });
        }
      }

      return {
        ...state,
        ip: { ...state?.ip, price: price, schedule: schedule },
        details: originData,
      };
    }
    case AT.SET_CONTRACT_MEMBERS: {
      const memberTypeDef = { s: 'seller', b: 'buyer', r: 'broker' };
      const memberType = memberTypeDef[action.memberType];
      const nextMemberData = state?.[memberType]?.[action.key] || [];
      nextMemberData[action.index] = action.value;

      return { ...state, [memberType]: { ...state[memberType], [action.key]: nextMemberData } };
    }
    case AT.SET_CONTRACT_INFO_INI: {
      return {
        ...state,
        contractInfo: MG_GOODS.contractInfo,
      };
    }
    case AT.SET_MODIFY_GOODS_INI: {
      return {
        ...state,
        modigoods: {},
      };
    }
    case AT.GET_CALL_REPORT: {
      return {
        ...state,
      };
    }
    case AT.SET_CALL_REPORT: {
      return {
        ...state,
        callReportList: action.list,
      };
    }

    case AT.GOODS_REFRESH_SEARCH_CONDITION: {
      return {
        ...state,
        gubun: MG_GOODS.gubun,
        keyword: MG_GOODS.keyword,
        category: MG_GOODS.category,
        tradetype: MG_GOODS.tradetype,
        areasize: MG_GOODS.areasize,
        price: MG_GOODS.price,
        price2: MG_GOODS.price2,
        situ: MG_GOODS.situ,
        page: MG_GOODS.page,
        brokerageType: MG_GOODS.brokerageType,
      };
    }

    //중개사 매물정보수정
    case AT.CHANGE_MODIFY_GOODS: {
      return {
        ...state,
        modigoods: {
          ...state.modigoods,
          [action.changetype]: action.val,
        },
      };
    }
    case AT.GOODS_KEYWORD_SEARCH: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.GOODS_KEYWORD_SEARCH_RES: {
      return {
        ...state,
        keywordList: action.keywordList,
      };
    }

    //다윈외 거래 계약체결
    case AT.SET_CONTRACT_PRE_CLIENT_TYPE: {
      return {
        ...state,
        contractInfo: { ...state.contractInfo, client_type: action.client_type },
      };
    }
    //관련 구해줘다윈
    case AT.GET_ASSOCIATED_COMMUNITY_SUCCESS: {
      return {
        ...state,
        feeds: action.data,
      };
    }
    case AT.INITIALIZE: {
      return { ...MG_GOODS };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
