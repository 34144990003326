import realtyTypes from '@/services/goods/realtyTypes';
import { getName } from '@/services/goods/realtyTypesExpanded';

const getCommonGoodsTitle = (goodsCommonData) => {
  const { goods_name, realty_type, jibun, dong_name, mount_yn } = goodsCommonData;

  const codes = realtyTypes.codes;

  let goodsTitle = '';
  switch (realty_type) {
    case codes.apt:
    case codes.oft:
      goodsTitle = goods_name ?? '';
      break;

    case codes.vll:
    case codes.dsd:
    case codes.yun:
    case codes.dgg:
    case codes.one:
    case codes.dan:
    case codes.sto:
    case codes.off:
      if (goods_name) {
        goodsTitle = [dong_name, goods_name]?.filter(Boolean).join(' ');
      } else {
        const isMountain = mount_yn === 'Y' ? '산' : '';
        const jibunStr = jibun ? isMountain + jibun + '번지' : '';
        const realtyType = getName(realty_type);
        goodsTitle = [dong_name, jibunStr, realtyType]?.filter(Boolean).join(' ');
      }
      break;

    default:
      break;
  }

  return goodsTitle;
};

export default { getCommonGoodsTitle };
