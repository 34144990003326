import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { TagType } from '@/services/Community';
import { backToText } from '@/services/Helper/linkify';
import { SQUARE_METER_UNIT } from '@/services/Community';

const isNumericTagTypes = (type) => {
  return type === TagType.AREA_TAG || type === TagType.PRICE_TAG;
};

const useUpdate = ({ updateFeedIdx, setFeedType, setTags, setNumericTags, setContents, addedTagsRef, imagesRef }) => {
  const dispatch = useDispatch();

  const [feed, setFeed] = useState();

  useEffect(() => {
    if (updateFeedIdx) {
      dispatch(actions.community.getFeed(updateFeedIdx, setFeed));
    }
  }, [updateFeedIdx]);

  useEffect(() => {
    if (feed) {
      const { feedType, tags, contents, images } = feed;

      // Feed Type
      if (feedType) {
        setFeedType(feedType);
      }

      // Tags
      if (tags && tags.length > 0) {
        const filteredTags = tags.filter((item) => item.type !== TagType.HASH_TAG && !isNumericTagTypes(item.type));
        setTags(filteredTags);

        // HashTags
        let hashTags = tags.filter((tag) => tag.type === TagType.HASH_TAG);
        if (hashTags?.length > 0) {
          hashTags = hashTags.map((item) => `#${item.name}`);
          addedTagsRef.current = hashTags;
        }

        // NumbericTags
        const areaTag = tags.filter((item) => item.type === TagType.AREA_TAG)?.[0];
        const priceTag = tags.filter((item) => item.type === TagType.PRICE_TAG)?.[0];

        const tempNumeric = {};

        if (areaTag) {
          const areaKey = areaTag?.key?.split(':');
          tempNumeric.area = {
            min: Math.round(areaKey?.[0] / SQUARE_METER_UNIT) || undefined,
            max: Math.round(areaKey?.[1] / SQUARE_METER_UNIT) || undefined,
          };
        }

        if (priceTag) {
          const priceKey = priceTag?.key?.split(':');
          const minPrice = priceKey?.[0] ? Number(priceKey?.[0]) : undefined;
          const maxPrice = priceKey?.[1] ? Number(priceKey?.[1]) : undefined;
          tempNumeric.price = { min: minPrice, max: maxPrice };
        }
        if (areaTag || priceTag) {
          setNumericTags(tempNumeric);
        }
      }

      // Images
      if (images) {
        imagesRef.current = images.map((item) => ({ image: item.url, id: item?.key }));
      }

      // Contents
      if (contents) {
        const text = backToText(contents)
          .replace(/<br>/g, '\n')
          .replace(/<script>/g, '');
        setContents(text);
      }
    }
  }, [feed]);

  return feed;
};

export default useUpdate;
