import React from 'react';

/**
 * 중개사서비스 이용약관
 */
const brokerServiceTermsOfuse = (
  <div className="docs-policy">
    <p className="subsection-head">
      <strong>제1조 (목적)</strong>
    </p>
    <p className="subsection-body">
      본 정책은 중개사 회원(이하 ‘책임중개사’라 합니다)들의 신뢰를 높이고, 주식회사 다윈프로퍼티(이하 ‘회사’라 합니다)가 운영하는 인터넷 서비스 (이하
      ‘다윈중개”라 합니다)의 질을 높임으로써 건전하고 투명한 부동산 거래 활성화를 목적으로 합니다.
    </p>

    <p className="subsection-head">
      <strong>제2조 (용어 정의)</strong>
    </p>
    <p className="subsection-body">
      이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
      <br />
      “다윈중개’라 함은 회사가 컴퓨터 등 정보 통신설비를 이용하여 서비스를 제공할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 인터넷 사이트 및
      모바일 어플리케이션을 운영하는 사업자의 의미로도 사용합니다.
      <br />① “이용자”라 함은 다윈중개에 접속하여 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
      <br />② “회원”이라 함은 다윈중개에 접속하여 회사에 개인 정보 또는 사무실 정보를 제공하여 회원 등록을 하고 회사가 제공하는 서비스를 이용하는
      고객으로 일반 개인회원(이하 ‘개인회원’이라고 합니다)과 중개사 회원(이하 ‘책임중개사’라고 합니다)을 의미합니다. 회사는 서비스의 원활한 제공을
      위해 회원의 등급을 회사 내부의 규정에 따라 나눌 수 있습니다.
      <br />③ “비회원’이라 함은 회원 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
      <br />④ “이메일”이라 함은 회원의 식별과 서비스 이용을 위하여 회원이 제공하고 회사가 승인한 회원 본인의 이메일 주소를 말합니다.
      <br />⑤ “비밀번호”라 함은 이메일로 식별되는 회원의 본인 여부를 검증하고 회원의 개인정보보호를 위해 회원 자신이 설정하여 회사에 등록한 고유의
      문자와 숫자의 조합을 말합니다.
      <br />⑥ “매물정보”라 함은 회사가 개인회원으로부터 제공받아 이용자에게 노출하는 부동산 거래 정보 및 거래 물건에 대한 다양한 부가 정보를 말합니다.
      <br />⑦ “콘텐츠”라 함은 다윈중개에 게시된 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보를 말합니다.
      <br />⑧ “서비스”라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 다윈중개의 서비스를
      의미합니다.
      <br />⑨ “운영자”라 함은 회사가 제공하는 다윈중개 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 자를 말합니다.
      <br />위 항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 의합니다.
    </p>

    <p className="subsection-head">
      <strong>제3조 (약관 등의 명시와 개정)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 다윈중개 인터넷 사이트 및 모바일 어플리케이션에 공지합니다. 다만, <br />
      약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
      <br />② 회사는 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
      <br />③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 이용자가 알기 쉽도록 표시하여 공지합니다.
      <br />④ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제①항의 방식에 따라 그 개정약관의 적용일자 7일 전부터
      적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정인 경우 적용일자 30일 전부터 적용일자 전일까지 서비스 홈페이지에 공지하고 기존
      회원에게는 이메일 주소로 개정약관을 발송하여 고지합니다.
      <br />⑤ 회사가 전항에 따라 회원에게 통지하면서 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는
      뜻을 명확하게 고지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 변경된 약관을 승인한 것으로 봅니다. 비회원은 개정된 약관의
      적용일부터 회사의 콘텐츠 및 서비스를 이용하는 경우 개정된 약관이 적용됩니다.
      <br />⑥ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며,이 경우 회원은 이용계약을 해지할 수 있습니다.
      다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
      <br />⑦ 회사가 약관의 변경 시 본 조에 따른 조치를 하였음에도 불구하고 이용자가 변경된 약관을 인지하지 못하여 발생하는 손해에 대해 회사는
      책임지지 아니합니다.
    </p>

    <p className="subsection-head">
      <strong>제4조 (약관의 해석)</strong>
    </p>
    <p className="subsection-body">
      ① 이 약관은 이용자의 동의와 회사의 승낙으로 효력이 발생합니다. 이용자의 동의는 회원인 경우 회원가입 시 동의절차를 밟으며, 비회원인 경우
      웹사이트에 접속하여 서비스를 이용하는 동안 이 약관에 동의한 것으로 간주합니다.
      <br />② 회사는 필요한 경우 전체 서비스 중 일부 콘텐츠, 특정 서비스의 이용에 대하여 개별약관 또는 운영원칙, 이용안내(이하 개별약관)를 설정할 수
      있으며, 이 약관과 개별약관의 내용이 상충되는 경우에는 해당 콘텐츠, 특정 서비스에 적용되는 개별약관이 우선 적용됩니다.
      <br />
      ③ 개별약관에서 정하지 아니한 사항에 대하여는 이 약관의 각 조건이 적용될 수 있는 사항인 경우에 이 약관이 공통적 또는 보충적으로 적용됩니다.
      <br />④ 본 약관에서 정하지 아니한 사항이나 해석에 대해서는 개별정책, 관계법령 또는 상관례에 따릅니다.
    </p>

    <p className="subsection-head">
      <strong>제5조 (책임중개사 회원가입 및 회원정보의 변경)</strong>
    </p>
    <p className="subsection-body">
      ① 회원이 되고자 하는 책임중개사가 약관의 내용에 대하여 동의를 한 다음 회원 가입신청을 하고, 회사는 이러한 신청에 대하여 해당 책임중개사로부터
      중개업소 등록 여부를 확인할 수 있는 아래의 정보 등을 등록하고 이를 확인 후 승낙함으로써 체결됩니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 개인정보 (개업공인중개사 성명, 이메일주소, 비밀번호, 휴대폰 번호, 프로필 사진)
      <br />
      2. 사무소 정보 (사무소명, 사업자종류, 사업자등록증, 개설등록증, 주소, 연락처 등)
    </p>

    <p className="subsection-body">
      ② 회사는 제1항과 같이 책임중개사로 가입할 것을 신청한 책임중개사 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 등록 내용에 허위, 기재누락, 오기가 있는 경우
      <br />
      2. 관련 법령에 따라 유효하게 등록된 부동산중개사무소가 아닌 경우
      <br />
      3. 가입신청자가 이전에 회원자격을 상실한 적이 있는 경우 (다만 회원자격 상실 후 회사가 필요하다고 판단하여 회원재가입 승낙을 얻은 경우에는 예외로
      합니다.)
      <br />
      4. 회사로부터 책임중개사 회원자격 정지 조치 등을 받은 책임중개사가 그 조치기간 중에 이용계약을 임의 해지하고 재이용 신청을 하는 경우
      <br />
      5. 회사로부터 책임중개사 회원 강제탈퇴 조치를 받은 경우
      <br />
      6. 사이트 이용료를 납부하지 않은 경우
      <br />
      7. 기타 책임중개사로 등록하는 것이 다윈중개의 기술상 현저히 지장이 있다고 판단되는 경우
      <br />
      8. 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
    </p>

    <p className="subsection-body">
      ③ 책임중개사 가입 계약의 성립시기는 회사의 승낙이 책임중개사에게 도달한 시점으로 합니다.
      <br />④ 책임중개사는 회원 가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정하여 회사에 그 변경사항을 알려야 합니다.
      <br />⑤ 제④항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
      <br />
      ⑥ 책임중개사 회원가입은 반드시 본인의 진정한 정보를 통하여만 가입할 수 있으며 회사는 책임중개사가 등록한 정보에 대하여 확인조치를 할 수
      있습니다. 책임중개사는 회사의 확인조치에 대하여 적극 협력하여야 하며, 만일 이를 준수하지 아니할 경우 회사는 책임중개사가 등록한 정보가 부정한
      것으로 처리할 수 있습니다.
      <br />⑦ 회사는 책임중개사 회원 등급과 개인 회원의 평가결과 등에 따라 노출 순위 등에 차등을 둘 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제6조 (서비스 이용)</strong>
    </p>
    <p className="subsection-body">
      ① 책임중개사 회원은 회사에서 제공하는 서비스를 통해 가입하여 발급된 ID로만 서비스를 이용할 수 있습니다.
      <br />② 회사에서 제공하는 각 서비스는 서로의 ID 및 책임중개사 회원 정보를 공유하지 않습니다.
      <br />③ 회사는 책임중개사 회원의 정보 및 ID도용(부정 접속)을 막고자 IP접속 보안과 맥(MAC)정보를 수집 보관하여 접속 PC의 IP주소와 맥(MAC)정보를
      관리 합니다.
    </p>

    <p className="subsection-head">
      <strong>제7조 (서비스 이용료 및 유료정보 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사가 제공하는 매물 정보 확인 등 중개 거래에 필요한 서비스는 원칙적으로 유료입니다. 단, 회사가 책임중개사 회원 인증이 필요하다고 하지 <br />
      않는 서비스에 대해서는 무료로 서비스를 제공 받을 수 있습니다.
      <br />② 회사에서 제공하는 정보 중 회사에서 정한 정보는 별도의 추가 요금을 지불하여야 사용가능합니다.
      <br />③ 서비스 이용료는 책임중개사가 다윈중개 서비스에서 제공하는 매물 정보와 매물 의뢰자 또는 매수의뢰자 회원의 정보 등 다윈중개만이 제공할 수
      있는 콘텐츠를 이용하는 요금이기 때문에 다윈중개 서비스 이용 중 다음 각 호에 해당되는 경우 원칙적으로 서비스를 이용한 해당 월에 상응하는 이용료는
      환불 되지 않습니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 책임중개사 회원이 회원 가입 탈퇴 등 자발적으로 이용 계약을 해지한 경우
      <br />
      2. 제10조 및 제14조 내지 제16조에 의해 계약해지 또는 책임중개사 회원의 의무 위반에 해당하는 경우
      <br />
      3. 책임중개사 회원의 사정으로 인해 일정 기간 영업활동을 일시 정지한 경우
    </p>

    <p className="subsection-body">
      ④ 서비스 이용료는 서비스를 이용하는 책임중개사 회원 등급에 따라 차별화 될 수 있으며 회사는 이러한 서비스 이용료에 대해서 명시해야 합니다.
      <br />⑤ 서비스 이용료는 다음 각 호에 한해서 이용료를 환불하거나 또는 사용기간 연장이 가능합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 이용료 환불
      <br />
      1) 책임중개사 회원 사망
      <br />
      2) 중개 사무소 폐업
      <br />
      3) 기타 적합한 환불 사유라고 인정되는 경우
      <br />
      2. 사용 기간 연장
      <br />
      1) 책임중개사 회원의 사정으로 인해 일정 기간 영업활동 일시 정지를 신청한 경우
      <br />
      2) 기타 사용 기간 연장에 적합한 사유라고 인정되는 경우
      <br />
      3) 사용 기간 연장에 해당되는 경우 책임중개사는 해당 증빙 서류 등을 첨부하여 고객센터로 제출하여야 하고, 고객센터에서는 이를 검토 후 사용 기간
      연장 여부를 결정합니다.
    </p>

    <p className="subsection-head">
      <strong>제8조 (서비스 이용시간)</strong>
    </p>
    <p className="subsection-body">
      ① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 가능함을 원칙으로 합니다. 다만 정기 점검 등의 필요로
      회사가 정한 날이나 시간은 그러하지 않습니다.
      <br />② 회사는 서비스가 중단되는 경우 사전 혹은 사후에 이를 서비스 화면으로 공지합니다.
    </p>

    <p className="subsection-head">
      <strong>제9조 (서비스 제공의 중지 등)</strong>
    </p>
    <p className="subsection-body">① 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.</p>

    <p className="subsection-body attr-inddent">
      1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
      <br />
      2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
      <br />
      3. 기타 불가항력적 사유가 있는 경우
    </p>

    <p className="subsection-body">
      ② 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는
      일부를 제한하거나 중지할 수 있습니다.
      <br />③ 회사는 제①항 및 제②항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 지체없이 책임중개사 회원에게
      알려야 합니다.
    </p>

    <p className="subsection-head">
      <strong>10조1 (이용 계약의 종료, 해지)</strong>
    </p>
    <p className="subsection-body">① 책임중개사 회원의 자발적 탈퇴</p>

    <p className="subsection-body attr-inddent">
      1. 책임중개사는 언제든지 회사에게 회원 이용 계약 해지 및 회원 탈퇴 의사를 통지할 수 있고 회사는 특별한 사유가 없는 한 이를 즉시 수락하여야
      합니다. 다만, 책임중개사는 탈퇴 의사를 통지하기 전에 모든 진행중인 절차를 완료, 철회 또는 취소해야만 합니다. 이 경우 철회 또는 취소로 인한
      불이익은 책임중개사 회원 본인이 부담하여야 합니다.
      <br />
      2. 책임중개사가 발한 의사표시로 인해 발생한 불이익에 대한 책임은 책임중개사 본인이 부담하여야 하며, 이용계약이 종료되면 회사는 책임중개사에게
      부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
      <br />
      3. 책임중개사의 의사로 이용계약을 해지한 후, 추후 재가입을 희망할 경우에는 이용계약 해지 후 1년이 지나야 재가입이 가능합니다. 재가입을 희망할
      경우, 신규 회원 가입 절차와 동일하게 다시 가입하여야 서비스 이용이 가능합니다.
    </p>

    <p className="subsection-body">② 회사의 강제 탈퇴 및 해지</p>

    <p className="subsection-body attr-inddent">
      1. 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 강제 탈퇴 및 해지할 수 있습니다.
      <br />
      1) 다른 책임중개사 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 미풍양속에 위배되는 행위를 한 경우
      <br />
      2) 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
      <br />
      3) 제5조 제2항의 승낙거부 사유가 추후 발견된 경우
      <br />
      4) 회사가 정한 서비스 운영정책을 이행하지 않거나 위반한 경우
      <br />
      5) 본 약관 제14조 내지 제16조의 책임중개사 회원 의무 등을 위반한 경우
      <br />
      6) 다윈 규정 외 초과중개보수를 요구하고 실제 수령한 경우
      <br />
      7) 휴업, 폐업, 행정처분 사실을 속이고 영업한 경우
      <br />
      8) 매도인이 모르는 상태에서 실제 계약없이 계약체결신고를 한 경우
      <br />
      9) 다윈매물 외부 광고시 매도자가 등록한 가격과 다른 가격으로 광고한 경우
      <br />
      10) 누적으로 경고를 2회 받은 경우
      <br />
      11) 기타 회사가 합리적 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우
      <br />
      2. 회사가 책임중개사를 강제 탈퇴 및 해지를 하는 경우 회사는 책임중개사에게 이메일, 전화, 기타의 방법을 통하여 강제 탈퇴 및 해지 사유를 밝혀 해지
      의사를 통지합니다. 이용계약은 회사의 해지의사를 책임중개사에게 통지한 시점에 종료됩니다.
      <br />
      3. 본 항에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 책임중개사에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다. 이용계약의 종료와
      관련하여 발생한 손해는 이용계약이 종료된 해당 책임중개사 회원이 책임을 부담하여야 하고, 회사는 일체의 책임을 지지 않습니다.
      <br />
      4. 본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, 책임중개사 회원의 재가입 신청에 대하여 회사는 이에 대한 승낙을 거절할 수 있습니다.
    </p>

    <p className="subsection-body">
      ③ 책임중개사가 계약을 해지하는 경우, 관련법 및 개인정보처리방침에 따라 회사가 책임중개사 회원 정보를 보유하는 경우를 제외하고는 해지 즉시
      책임중개사의 모든 데이터는 소멸됩니다.
    </p>

    <p className="subsection-head">
      <strong>제10조2 (탈퇴 또는 해지 전 개시된 중개행위)</strong>
    </p>
    <p className="subsection-body">
      제10조 제1항 1.2문에도 불구하고, 일반회원 및/또는 책임중개사가 회사의 서비스를 이용을 개시하여 그 이용으로 인해 매물에 관한 정보를 교환하거나
      매물을 확인하는 등 중개행위 등 여타의 절차가 개시되었으나 중개행위 등 여타의 절차가 완료되기 전에 일반회원 및/또는 책임중개사가 제10조에 따라
      자발적 또는 강제탈퇴 및 해지로 회사의 서비스를 이용할 수 없게 되었음에도, 진행 중인 중개행위 등 일체의 절차를 완료, 취소, 철회 등을 하지 않고
      중개행위 등 여타의 절차를 진행하여 완료한 경우, 이미 개시된 중개행위 등 여타의 절차에 대해서는 회사 (다윈프로퍼티)의 서비스를 이용 중인 상태가
      계속되는 것이어서 이용약관 및 중개사 회원 약관이 모두 동일하게 적용됩니다. 이에 따라 회사가 정한 서비스 이용 방법, 서비스 준칙 등을 모두
      준수해야 합니다.
    </p>

    <p className="subsection-head">
      <strong>제11조 (개인정보보호)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 책임중개사의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는
      관련법 및 회사의 개인정보취급방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보취급 방침이 적용되지
      않습니다.
      <br />② 회사는 책임중개사의 회원가입 시 서비스 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로
      합니다.
    </p>

    <p className="subsection-body attr-inddent">
      (1) 이메일주소
      <br />
      (2) 비밀번호
      <br />
      (3) 휴대폰 번호
      <br />
      (4) 프로필 사진
      <br />
      (5) 사무소 정보 (사무소명, 대표자명, 사무소 주소, 사업자종류, 사업자등록증, 개설등록증, 연락처 등)
    </p>
    <p className="subsection-body">
      ③ 회사가 책임중개사의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
      <br />④ 제공된 개인정보 및 사무소정보는 당해 책임중개사의 동의 없이 목적 외의 이용이나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로
      합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
      <br />
      2. 도용방지를 위하여 본인확인에 필요한 경우
      <br />
      3. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
    </p>

    <p className="subsection-body">
      ⑤ 회사가 제②항과 제③항에 의해 책임중개사의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의
      수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 “정보통신망 이용 촉진 및 정보보호 등에 관한
      법률” 에서 규정한 사항을 미리 명시하거나 고지해야 하며 책임중개사는 언제든지 이 동의를 철회할 수 있습니다.
      <br />⑥ 회사는 책임중개사의 개인정보를 보호하기 위해 “개인정보처리방침”을 수립하고 개인정보 보호책임자를 지정하여 이를 게시하고 운영합니다.
      <br />⑦ 책임중개사는 언제든지 회사가 갖고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한
      조치를 취할 의무를 집니다. 책임중개사가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
      <br />⑧ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이
      파기합니다. 다만, 아래의 경우에는 책임중개사 회원 정보를 보관합니다. 이 경우 회사는 보관하고 있는 책임중개사 회원 정보를 그 보관의 목적으로만
      이용합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간
      동안 책임중개사 회원 정보를 보관합니다.
      <br />
      2. 회사가 이용계약을 해지하거나 회사로부터 서비스 이용정지조치를 받은 책임중개사에 대해서는 재 가입에 대한 승낙거부사유가 존재하는지 여부를
      확인하기 위한 목적으로 이용계약 종료 후 3년간 아이디, 전화번호를 비롯하여 이용계약 해지와 서비스 이용정지와 관련된 정보 등의 필요정보를
      보관합니다.
    </p>

    <p className="subsection-body">
      ⑨ 회사는 새로운 업체가 제휴사 또는 제휴영업점의 지위를 취득할 경우 회사는 이메일, 이동전화 단문메시지서비스(SMS), 푸쉬알림(App push), 사이트
      공지 등으로 책임중개사에게 공지합니다. 이때 책임중개사가 별도의 이의제기를 하지 않을 경우 다윈중개 서비스 제공이라는 필수적인 목적을 위해 해당
      개인 정보 제공 및 활용에 동의한 것으로 간주 합니다.
      <br />
      ⑩ 모든 책임중개사는 다윈중개로부터 제공받은 정보를 다른 목적으로 이용하거나 타인에게 유출 또는 제공해서는 안되며, 위반 사용으로 인한 모든 책임을
      부담해야 합니다. 또한 책임중개사는 자신의 개인정보를 책임 있게 관리하여 타인이 책임중개사의 개인정보를 부정하게 이용하지 않도록 해야 합니다.
      <br />⑪ 회사는 책임중개사의 개인정보를 보호하기 위해 “정보통신망 이용촉진 및 정보보호 등에 관한 법률”상의 개인정보 유효기간제도에 따라 1년간
      미접속한 책임중개사의 개인정보를 파기 또는 분리하여 별도록 저장/관리하며(휴면계정), 책임중개사의 별도의 요청이 없는 한 사용이 제한됩니다.
    </p>

    <p className="subsection-head">
      <strong>제12조 (회사의 의무)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며 계속적이고,안정적으로 책임중개사에게 서비스를 제공하기 위해 최선을
      다합니다.
      <br />
      ② 회사는 책임중개사가 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을
      공시하고 준수합니다.
      <br />③ 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실된 때에는
      지체없이 이를 수리 복구하여야 합니다. 다만, 천재지변, 비상사태 또는 그 밖에 부득이한 경우에는 그 서비스를 일시 중단하거나 중지할 수 있습니다.
      <br />④ 회사는 서비스 및 매물정보 이용과 관련하여 책임중개사로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.
      책임중개사가 제기한 의견이나 불만사항에 대해서는 다윈 사이트 등을 통하여 책임중개사에게 처리과정 및 결과를 전달합니다.
      <br />⑤ 회사는 책임중개사가 회사에서 제공하는 유료의 콘텐츠 및 서비스를 이용하는 경우 책임중개사가 요청하는 때 그 이용내역을 확인할 수 있도록
      조치합니다.
    </p>

    <p className="subsection-head">
      <strong>제13조 (책임중개사의 아이디 및 비밀번호에 대한 의무)</strong>
    </p>
    <p className="subsection-body">
      ① 아이디와 비밀번호에 관한 관리책임은 책임중개사에게 있습니다.
      <br />② 책임중개사는 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
      <br />③ 책임중개사가 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는
      경우에는 그에 따라야 합니다. 만약 회사로 통보하지 않아 발생하는 모든 결과에 대한 책임은 책임중개사 회원에게 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제14조 (책임중개사의 의무)</strong>
    </p>
    <p className="subsection-body">
      ① 책임중개사는 다음 각호의 행위를 하여서는 안됩니다. 만약 다음 각호와 같은 행위가 확인되면 회사는 해당 책임중개사에게 서비스 이용에 대한 제재를
      가할 수 있으며 민형사상의 책임을 물을 수 있습니다.
    </p>
    <p className="subsection-body attr-inddent">
      1. 책임중개사 개인 정보 또는 사무소 정보를 허위 또는 위변조, 타인의 정보 도용하여 등록한 경우
      <br />
      2. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
      <br />
      3. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
      <br />
      4. 회사 또는 기타 제3자의 저작권 등 지적재산권에 대한 침해
      <br />
      5. 회사 또는 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
      <br />
      6. 외설 또는 폭력적인 메시지, 화상, 음향, 기타 공공질서 및 미풍양속에 반하는 정보를 다윈 중개 사이트에 공개 또는 게시하는 행위
      <br />
      7. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
      <br />
      8. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
    </p>
    <p className="subsection-body">
      ② 회사는 전항에 규정된 위반 행위를 하는 책임중개사 회원에 대해 서비스 일시 중단 또는 강제탈퇴 등의 조치를 취할 수 있으며 필요한 경우 이에 대한
      시정을 요구할 수 있습니다.
      <br />③ 책임중개사 회원은 회사의 사전승낙 없이는 다윈중개 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과와 책임중개사 회원이
      약관에 위반한 영업활동을 하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다.
      <br />④ 회원이 본조의 규정을 위반한 경우 회사는 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민,
      형사상 책임도 물을 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제15조 (서비스 이용권 양도 금지 등)</strong>
    </p>
    <p className="subsection-body">
      책임중개사 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도 또는 증여할 수 없으며, 이를 담보로
      제공할 수 없습니다.
    </p>

    <p className="subsection-head">
      <strong>제16조 (저작권의 귀속 및 이용제한)</strong>
    </p>
    <p className="subsection-body">
      ① 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 책임중개사가 다윈중개를 이용하여 작성한 저작물에 대한 저작권은 해당 책임중개사
      회원에게 귀속됩니다.
      <br />② 책임중개사는 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송
      기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
    </p>

    <p className="subsection-head">
      <strong>제17조 (책임의 한계 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 무료로 제공하는 정보 및 서비스에 관하여 개인정보처리방침 또는 관계법령의 벌칙, 과태료 규정 등 강행규정에 위배되지 않는 한 원칙적으로
      손해를 배상할 책임이 없습니다.
      <br />② 회사는 책임중개사의 귀책사유로 인한 정보 및 서비스 이용의 장애에 관한 책임을 지지 않습니다.
      <br />③ 회사는 책임중개사가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 회사의 고의 또는 중대한 과실이 없는 한 책임을 지지
      않습니다.
    </p>

    <p className="subsection-head">
      <strong>제18조 (손해배상 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 책임중개사가 다윈중개 서비스를 이용함에 있어 회사의 고의 또는 과실로 인해 손해가 발생한 경우에는 민법 등 관련 법령이 규율하는 범위
      내에서 그 손해를 배상합니다.
      <br />② 책임중개사가 이 약관을 위반하거나 관계 법령을 위반하여 회사에 손해가 발생한 경우에는 회사에 그 손해를 배상하여야 합니다.
    </p>

    <p className="subsection-head">
      <strong>제19조 (분쟁해결)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 책임중개사 또는 책임중개사와 개인 회원 상호간 분쟁에서 발생하는 문제에 대해서 일체의 책임을 지지 않습니다. 책임중개사 상호간 또는 개인
      회원과의 분쟁은 당사자간에 직접 해결해야 합니다.
      <br />② 책임중개사 상호간 또는 개인 회원과의 서비스 이용과 관련하여 발생한 분쟁에 대해 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
      시·도지사가 의뢰하는 분쟁조정 기관의 조정에 따를 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제20조 (재판권 및 준거법)</strong>
    </p>
    <p className="subsection-body">
      ① 회사와 책임중개사 간 제기된 소송은 대한민국법을 준거법으로 합니다.
      <br />② 회사와 책임중개사간 발생한 분쟁에 관한 소송은 제소 당시의 회사의 주소를 관할하는 지방법원의 전속관할로 합니다.
    </p>

    <p className="section-head">
      <strong>부칙</strong>
    </p>

    <p className="subsection-head">
      <strong>제1조 (시행일)</strong>
    </p>
    <p className="subsection-body">이 약관은 2019년 04월 01일부터 적용됩니다.</p>
  </div>
);

export default brokerServiceTermsOfuse;
