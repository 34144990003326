import React, { useMemo, useRef } from 'react';

const OptionButton = ({ title, name = '', type = 'radio', options, selected, onSelect }) => {
  const list = useMemo(() => {
    if (options) {
      const keys = Object.keys(options);
      return keys?.map((key) => {
        const item = options[key];
        const select = Array.isArray(selected) ? selected.indexOf(key) > -1 : selected === key;

        return (
          <span key={key} className="option-item">
            <label className={`${type} form module-b style-c accent-01 medium`}>
              <input type={type} className="form-elem" name={name} checked={select} onClick={() => onSelect(key)} readOnly />{' '}
              <span className="form-text">{item}</span>
            </label>
          </span>
        );
      });
    } else {
      return false;
    }
  }, [options, selected]);

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name">{title}</label>
        </div>
        <div className="form-body">
          <div className="form-area">
            <div className="option-list module-b style-a type-a">
              {list}
              {title === '매물유형' && <TempType />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TempType = () => {
  return (
    <>
      <span className="option-item">
        <label className="radio form module-b style-c accent-01 small">
          <input type="radio" className="form-elem" name="radio-0001" disabled="disabled" /> <span className="form-text">원룸/투룸 (준비 중)</span>
        </label>
      </span>
      <span className="option-item">
        <label className="radio form module-b style-c accent-01 small">
          <input type="radio" className="form-elem" name="radio-0001" disabled="disabled" /> <span className="form-text">주택/빌라 (준비 중)</span>
        </label>
      </span>
    </>
  );
};

export default OptionButton;
