import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// 매물상세 보기로 이동
/**
 * 현재 area, tradeType이 있어야만 보이는 상태. 추후 개선 필요
 */
export const routeToGoodsDetail = (d_complex,goods_idx, open, isMobile) => (dispatch) => {
  const path = getGoodsDetailPath(d_complex, goods_idx, open);
  const watching = location.search.indexOf('d_complex') > -1;

  if (watching || !isMobile) {
    dispatch(actions.router.push(path));
  } else {
    dispatch(actions.router.push(path));
  }
};

// 매물상세 URL
export const getGoodsDetailPath = (d_complex,goods_idx, open) => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};
  queryObject.d_complex = d_complex
  queryObject.d_trade_type = 'all';
  queryObject.d_area = 'all';
  queryObject.goods_idx = goods_idx;
  // if (open) {
  //   queryObject.goods_idx = goods_idx;
  // } else {
  //   delete queryObject.goods_idx;
  // }
  // if (open) {
  //   queryObject.d_goods = goods_idx;
  // } else {
  //   delete queryObject.d_goods;
  // }
  
  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};
