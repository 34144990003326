import { selectors } from '@/data';
import { TagType } from '@/services/Community';
import { groupBy } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useSetAddressTag = ({ tmp_tag_arr, payload, isBroker, setPayload }) => {
  const areaList = useSelector(selectors.brokerPage.setArea.getSetAreaList);

  useEffect(() => {
    tmp_tag_arr = payload?.tags;
    if (areaList?.result && areaList?.result?.length > 0 && isBroker) {
      areaList?.result?.map((ii) => {
        tmp_tag_arr.push({ type: TagType.ADDRESS_TAG, key: ii.b_dong_code, name: ii.dong });
      });
      const code = groupBy((i) => i.b_dong_code?.substring(0, 5) + '00000')(areaList?.result);
      const name = groupBy((i) => i.sigungu)(areaList?.result);
      let code_arr = Object.keys(code);
      let name_arr = Object.keys(name);
      code_arr.map((ii, key) => {
        tmp_tag_arr.push({ type: TagType.ADDRESS_TAG, key: `${ii}:${ii}`, name: name_arr[key] });
      });
      //2022. 2. 22 gmh 동이 포함된 구 추가
    }
    setPayload({ ...payload, tags: tmp_tag_arr });
  }, [areaList]);

  return;
};

export default useSetAddressTag;
