import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from './Logo';
import Menu from './Menu';
import User from './User';
import Login from '@/pages/Login';
import { selectors, actions } from '@/data';
import MobileNav from '@/components/Navigation/Mobile';
// import Login from '@/pages/Login/Popup';

const Navigation = ({ type, title, componentProps }) => {
  const dispatch = useDispatch();

  const user = useSelector(selectors.auth.getUserInfo);
  const showLoginPopup = useSelector(selectors.common.showLoginPopup);
  const isMobile = useSelector(selectors.common.isMobile);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);

  const isApp = osTypeInstance?.isApp;

  const currPath = location.pathname;

  // Navigation 표출 조건
  const isNoShowPage = isMobile && (currPath.indexOf('/selling') > -1 || currPath.indexOf('/agentSignUp') > -1 || currPath.indexOf('/login') > -1);
  const notAllowed = isApp && !isLoggedIn;

  // 로고 & 유저 버튼 표출 여부
  // 1. pc 화면인 경우 표시
  // 2. 메인 화면인 경우
  const isMain = currPath === '/';
  const showComponentsOnTop = !isMobile || isMain;

  const setShowLoginPopup = (value) => {
    dispatch(actions.common.toggleLoginPopup(value));
  };

  return (
    <>
      {/* @todo 로그인 팝업 import 위치 적절한가? - NO! */}
      {showLoginPopup && <Login />}

      {/* {location.pathname.indexOf('/login') < 0 && showLoginPopup && <Login />} */}
      {isMobile && <MobileNav user={user} type={type} title={title} componentProps={componentProps} />}

      <div id="skip" className="page-skip">
        <Link className="skip-item" to="#content">
          본문 바로가기
        </Link>
      </div>

      {!isNoShowPage && !notAllowed && (
        <>
          <div className="page-head" id="header">
            {showComponentsOnTop && <Logo />}
            <Menu />
            {showComponentsOnTop && <User user={user} isMobile={isMobile} setShowLogin={setShowLoginPopup} />}
          </div>
          <hr />
        </>
      )}
    </>
  );
};

export default React.memo(Navigation);
