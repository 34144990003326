import { getComplexAddress, getComplexDetailBulk } from '@/data/user/services';

export const getMyAreas = ({ myAreas }) => {
  const result = myAreas?.map((area) => {
    const mainName = area?.district[0]?.law_sido + ' ' + area?.district[0]?.law_sigungu + ' ' + area?.district[0]?.law_dong;
    const dongCode = (area?.b_dong_code).toString();
    const name = area?.district[0]?.law_dong;

    return { mainName, dongCode, name };
  });

  return result;
};

export const getMyComplexes = ({ myComplexes }) => {
  const result = myComplexes?.map((complex) => {
    const subName = complex?.complex?.complex_address;
    const mainName = complex?.complex?.complex_name;
    const dongCode = complex?.complex?.dong_code;
    const complexCode = complex?.complex?.complex_idx;
    const name = complex?.complex?.dong_name;
    const complexType = complex?.complex?.complex_type;

    return { subName, mainName, dongCode, complexCode, name, complexType };
  });

  return result;
};

export const getRecentlyViewedAreas = async () => {
  let array = [];
  const recentlyViewedAreas = JSON.parse(localStorage.getItem('fav_local'));
  if (recentlyViewedAreas) {
    const result = await getComplexAddress(recentlyViewedAreas);
    if (result) {
      for (var key in result) {
        const mainName = result[key].fullname;
        const dongCode = (result[key]?.code).toString();
        const name = result[key].name;
        array.push({ mainName, dongCode, name });
      }
    }
  }

  return array;
};

export const getRecentlyViewedComplexes = async () => {
  let array = [];
  const recentlyViewedComplexes = JSON.parse(localStorage.getItem('fav_complex'));

  if (recentlyViewedComplexes) {
    const result = await getComplexDetailBulk(recentlyViewedComplexes);

    if (result) {
      for (var key in result) {
        const subName = result[key]?.complex_address;
        const mainName = result[key]?.complex_name;
        const dongCode = (result[key]?.dong_code).toString();
        const complexCode = result[key]?.complex_idx;
        const name = result[key]?.dong_name;
        const complexType = result[key]?.complex_type;
        array.push({ subName, mainName, dongCode, complexCode, name, complexType });
      }
    }
  }

  return array;
};
