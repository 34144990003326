import React from 'react';
import styled from 'styled-components';

export { default as SpinnerWithCover } from './SpinnerWithCover';

const SpinnerRoot = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => (props.left ? `${props.left}%` : `50%`)};
  transform: translate(-50%, -50%);

  .spinnerCore {
    height: ${(props) => (props.size ? `${props.size}px` : `28px`)};
    width: ${(props) => (props.size ? `${props.size}px` : `28px`)};

    animation: rotate 0.8s infinite linear;
    border: ${(props) => props.width}px solid ${(props) => props.color};
    border-right-color: transparent;
    border-radius: 50%;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
// 2021-02-15 정석규 수정
// Spinner props 추가
export default function Spinner({ width = 4, color = '#fff', className, ...rest }) {
  // [[ new ]]
  // 2021-03-08 정석규
  // style jsx 사용을 지양하기 위해 변경함.
  // (최종적으로 `styled-component` 제외하고 다른 스타일링 dependencies 제거를 목적으로 함.)
  return (
    <SpinnerRoot width={width} color={color} className={className} {...rest}>
      <div className="spinnerCore"></div>
    </SpinnerRoot>
  );
}
