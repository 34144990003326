import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
// import { numberInputOnly } from '@/services/Helper';
// import { TYPES } from '@/components/GlobalDualPopups/helper';
import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import useSetTimeCount from '@/pages/Login/hooks/useSetTimeCount';

const Step2 = ({ phoneNum, isBusy, step1State, step2State }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const handleChange = (e) => {
    const tv = e.target.value.replace(/\D+/g, '');
    setInput(tv);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isBusy) return;

    dispatch(actions.auth.findId2(input));
  };

  const resetTimer = () => {
    setSeconds(300);
    // setCodeStatus('resent');
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isBusy) return;

    dispatch(actions.auth.findId1(phoneNum.current));
    resetTimer();
  };

  const [seconds, setSeconds] = useState(300); // 5분은 300초입니다.

  const [formatTime] = useSetTimeCount({ seconds, setSeconds });

  return (
    <>
      <div className="popup-local-body">
        <div className="popup-content">
          <div className="popup-content-body">
            <fieldset className="submit-form module-a type-c medium">
              <legend>아이디찾기 입력서식</legend>
              <div className="form-list">
                <div className="form-item">
                  <div className="form-wrap">
                    <div className="form-head">
                      <label className="form-name" htmlFor="certificationNumber">
                        인증번호 입력
                      </label>
                      <span className="form-para">인증번호를 입력해 주세요.</span>
                    </div>
                    <div className="form-body">
                      <div className="form-area">
                        <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                          <input
                            type="text"
                            className="form-elem"
                            id="certificationNumber"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="4"
                            placeholder="인증 번호"
                            value={input}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <span className="form-func">
                            <span className="form-cancel"></span>
                            <span className="form-data em style-a accent-05">
                              {formatTime(seconds)}
                              {/* 01:24 */}
                            </span>
                          </span>
                        </span>
                        <button type="button" className="btn module-a style-b type-line normal-04 large-1x" onClick={handleClick}>
                          <span className="btn-text">
                            {step1State === 'Loading' ? (
                              <>
                                <CommnonSpinner
                                  show={true}
                                  // position="relative"
                                  // padding="10px"
                                  spinnerWrapperBackground={'transparent'}
                                  spinnerColor="#6a6a6a"
                                />
                              </>
                            ) : (
                              '재요청'
                            )}
                          </span>
                        </button>
                      </div>
                      {/* 
                      <div className="form-noti">
                        <p className="para valid">휴대폰인증번호가 전송되었습니다.</p>
                      </div>
                      <div className="form-noti">
                        <p className="para valid">휴대폰인증이 완료되었습니다.</p>
                      </div>
                       */}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="popup-local-util">
        <div className="button-display module-a style-a type-d">
          <span className="button-area">
            <button type="submit" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={handleSubmit}>
              <span className="btn-text">
                {step2State === 'Loading' ? (
                  <>
                    <CommnonSpinner show={true} position="relative" padding="10px" spinnerWrapperBackground={'transparent'} />
                  </>
                ) : (
                  '인증하기'
                )}
              </span>
            </button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Step2;
