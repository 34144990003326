import React, { useEffect } from 'react';
import { actions } from '@/data';
import { useDispatch } from 'react-redux';

const useSetTimeCount = ({ setSeconds, seconds }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (seconds > 0) {
      const countdown = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(countdown);
    }

    if (seconds === 0) {
      dispatch(actions.common.alert({ contents: '인증 시간이 초과되었습니다. 재요청 버튼을 눌러 새로운 인증번호를 발급받으세요.' }));
    }
  }, [seconds]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return [formatTime];
};

export default useSetTimeCount;
