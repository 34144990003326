import React, { useState } from 'react';
import img_noti01 from '@/resources/design/mobile/images/new/img_noti01.png';
import img_noti02 from '@/resources/design/mobile/images/new/img_noti02.png';
import { useHistory } from 'react-router-dom';

const PostForBuyNoticeTypeA = () => {
  const history = useHistory();

  const goDetail = () => {
    // setShowDetail(true);
    if (location.search?.indexOf('postForBuyGuide') < 0) {
      history.push(`${location.pathname}${location.search}&postForBuyGuide=true`);
    }
  };

  return (
    <>
      <div className="notice-area-wrap">
        <div className="notice-list">
          <picture>
            <img src={img_noti01} alt="딱 맞는 집을 추천 이미지" />
          </picture>
          <div className="notice-text">
            <strong>구하는 집의 조건만 입력하세요.</strong>
            많은 중개사들이 딱 맞는 집을 알아서 찾아드립니다.
            <a onClick={goDetail} className="btn btn-basic">
              자세히보기 &gt;
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostForBuyNoticeTypeA;
