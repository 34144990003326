import React from 'react';

const Title = ({ titleText }) => {
  return (
    <div className="popup-local-head">
      <h2 className="popup-local-subject">
        <span className="popup-local-name">{titleText}</span>
      </h2>
    </div>
  );
};

export default Title;
