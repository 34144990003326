import React, { useEffect } from 'react';
import AddressSelect from './AddressSelect';
import ComplexSelect from './ComplexSelect';
import { dataFinder } from '@/resources/design/v2/js/bui.template';

const SelectSearchView = ({
  addressData,
  setAddressData,
  addAllOption,
  disabled,
  tenDigitCode,
  dongCode,
  complexData,
  setComplexData,
  markTypes,
  preventUpdateComplexData,
  brokerNumber,
  setBrokerNumber,
  feedType,
  localAddressMode,
}) => {
  useEffect(() => {
    dataFinder('[data-bui-form="dataFinder"]');
  }, []);

  return (
    <>
      <div className="form-area type-d">
        <AddressSelect
          data={addressData}
          setData={setAddressData}
          addAllOptionOnDong={addAllOption}
          disabled={disabled}
          tenDigitCode={tenDigitCode}
        />
        {!localAddressMode ? (
          <ComplexSelect
            selectedIdx={`${complexData?.complex_idx}`}
            dongCode={dongCode}
            setData={setComplexData}
            markTypes={markTypes}
            addAllOption={addAllOption}
            preventUpdateComplexData={preventUpdateComplexData}
            disabled={disabled}
            addressData={addressData}
            brokerNumber={brokerNumber}
            setBrokerNumber={setBrokerNumber}
            feedType={feedType}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SelectSearchView;
