import { actions, selectors } from '@/data';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReplyInput from '../../ReplyArea/components/replyInput';

const CommentContents = ({
  comment,
  deletedId,
  isActiveComment,
  setActiveComment,
  replies,
  setReplies,
  setComment,
  contentRef,
  cancelUpdateComment,
  onUpdateComment,
}) => {
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false);
  const [fold, setFold] = useState(true);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  const actionFold = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      dispatch(actions.common.pleaseLoginPopup(true));
      return;
    } else {
      setFold(!fold);
      setShowButton(false);
    }
  };

  useEffect(() => {
    const element = contentRef.current;
    const n1 = element?.clientHeight;
    const n2 = element?.scrollHeight;
    if (n1 < n2) {
      setShowButton(true);
    }
  }, [contentRef]);

  return isActiveComment === comment?.id ? (
    <ReplyInput
      commentId={comment?.id}
      isUpdate={true}
      replies={replies}
      setReplies={setReplies}
      contents={comment.contents}
      setActiveComment={setActiveComment}
      setComment={setComment}
      cancelUpdateComment={cancelUpdateComment}
      onUpdateComment={onUpdateComment}
    />
  ) : (
    <div className="comment-body">
      <p className="para">
        <span className="wbr" style={{ overflowWrap: 'break-word' }}>
          {comment?.status === 'DELETED' || deletedId.indexOf(comment?.id) > -1 ? '삭제된 댓글입니다.' : comment?.contents}
          {comment?.status === 'BLINDED' ? '부적절한 글로 가려진 댓글입니다.' : null}
        </span>
      </p>

      {showButton ? (
        <a className="text-moreviw" onClick={showButton ? actionFold : null}>
          더보기
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CommentContents;
