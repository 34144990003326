export const changeDateFormat = ({ originalDate, showTime }) => {
  const date = new Date(originalDate);

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  const hours = ('0' + date.getHours()).slice(-2);
  const mins = ('0' + date.getMinutes()).slice(-2);

  let newDate;
  if (showTime) {
    newDate = `${year}.${month}.${day} ${hours}:${mins}`;
  } else {
    newDate = `${year}.${month}.${day}`;
  }

  return newDate;
};

const DATE_FORMAT_TYPE = {
  A: 'fullDateWithoutTime', // YYYY.MM.DD
  B: 'fullDateWithTime', // YYYY.MM.DD 오전 HH:MM
  C: 'dateWithoutDay', // YY년 M월
  D: 'shortenDateWithoutTime', // YY.MM.DD
};

const formatDate = (originalDate, options) => {
  if (originalDate) {
    const { type = DATE_FORMAT_TYPE.A } = options || {};

    const date = new Date(originalDate);

    const fourDigitsYear = date.getFullYear();
    const twoDigitsYear = String(date.getFullYear()).slice(-2);

    const monthWithoutZero = date.getMonth() + 1;
    const monthWithZero = ('0' + (date.getMonth() + 1)).slice(-2);

    const day = ('0' + date.getDate()).slice(-2);

    let hours = date.getHours();
    const ampm = hours >= 12 ? '오후' : '오전';
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = ('0' + hours).slice(-2);
    const mins = ('0' + date.getMinutes()).slice(-2);

    let newDate;

    switch (type) {
      case DATE_FORMAT_TYPE.A:
        newDate = `${fourDigitsYear}.${monthWithZero}.${day}`;
        break;
      case DATE_FORMAT_TYPE.B:
        newDate = `${fourDigitsYear}.${monthWithZero}.${day} ${ampm} ${formattedHours}:${mins}`;
        break;
      case DATE_FORMAT_TYPE.C:
        newDate = `${twoDigitsYear}년 ${monthWithoutZero}월`;
        break;
      case DATE_FORMAT_TYPE.D:
        newDate = `${twoDigitsYear}.${monthWithZero}.${day}`;
        break;
      default:
        newDate = `${fourDigitsYear}.${monthWithZero}.${day}`;
        break;
    }

    return newDate || '';
  }
};

export { DATE_FORMAT_TYPE, formatDate };
