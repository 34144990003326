const constants = {
  REGQNA: 'REGQNA',
  POST_DONE: 'POST_DONE',

  REGQNA_OPTIONS: ['', '시스템오류', '집구하기', '집내놓기', '마이페이지', '결제오류', '기타'],
  REGQNA_CODES: ['', 'FQ1', 'FQ2', 'FQ3', 'FQ4', 'FQ5', 'ETC'],

  REGQNA_TITLE_PLACEHOLDER: '제목입력',
  REGQNA_BODY_PLACEHOLDER: '문의할 내용을 입력해주세요.',
};

export default constants;
