import React from 'react';
import { actions } from '@/data';
import { OSTYPE } from '@/services/apps/types/OsType';
import callPopupContents from './callPopupContents';

const CALL_NOTI_DATE = 'callNotiDate';
const callActions = {
  // 전화하기, 전화번호가 없는 경우 채팅하기.
  callOrChat: (phoneNumber, userName, { chatFunc, callOrChat, fromBrokerToGuest }) => (dispatch) => {
    if (phoneNumber) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - 14);
      const twoWeeksAgo = date.getTime();
      const savedTime = localStorage.getItem(CALL_NOTI_DATE);

      if (fromBrokerToGuest || (savedTime && Number(savedTime) > twoWeeksAgo)) {
        dispatch(callActions.call(phoneNumber, userName, { fromBrokerToGuest }));
      } else {
        dispatch(callActions.callWithNoti(phoneNumber, userName, { fromBrokerToGuest }));
      }
    } else {
      dispatch(callActions.openNoPhoneNumberOrChatAlert(userName, chatFunc, callOrChat));
    }
  },
  callWithNoti: (phoneNumber, userName, { fromBrokerToGuest }) => (dispatch) => {
    dispatch(
      actions.common.alert({
        contents: (
          <>
            <div className="board-head">
              <p className="board-subject">
                <span className="board-name">통화 전에 알아두세요!</span>
              </p>
            </div>
            <div className="board-body">
              <p className="para">
                <span className="wbr">다윈중개사의 안심번호로 연결되며,</span>
                <span className="wbr">상담품질 관리를 위해 통화내용이 녹음됩니다.</span>
              </p>
              <p className="para">
                <span className="wbr">발신하는 고객님의 전화번호가</span>
                <span className="wbr">중개사님께 노출될 수 있습니다.</span>
              </p>
            </div>
          </>
        ),
        onConfirm: () => {
          dispatch(callActions.call(phoneNumber, userName, { fromBrokerToGuest }));

          const date = new Date();
          date.setHours(0, 0, 0, 0);
          const epochtime = date.getTime().toString();

          localStorage.setItem(CALL_NOTI_DATE, epochtime);
        },
      })
    );
  },
  // 전화하기
  call: (phoneNumber, userName, { fromBrokerToGuest }) => (dispatch) => {
    const osType = window.dawin_instance.osType;

    if (osType === OSTYPE.APP_ANDROID || osType === OSTYPE.APP_IOS) {
      window.dawin_instance.bridge.osFunction.executeCallApp(phoneNumber);
    } else if (osType === OSTYPE.WEB_ANDROID || osType === OSTYPE.WEB_IOS) {
      window.location.href = 'tel:' + phoneNumber;
    } else if (osType === OSTYPE.DESKTOP) {
      const phoneNumberString = `${phoneNumber?.substring(0, 4)}-${phoneNumber?.substring(4, 8)}-${phoneNumber?.substring(8, 12)}`;
      const callNotiText = callPopupContents.getCallPopupContents(phoneNumberString, userName, { fromBrokerToGuest });

      dispatch(
        actions.common.alert({
          contents: callNotiText,
        })
      );
    }
  },
  // 전화번호가 등록되지 않은
  openNoPhoneNumberOrChatAlert: (userName, chatFunc, callOrChat) => (dispatch) => {
    const action = callOrChat ? actions.common.confirm : actions.common.alert;

    dispatch(
      action({
        contents: (
          <>
            <strong>{userName}</strong>
            <br />
            <span>
              전화번호가 등록되지 않았습니다.
              {callOrChat && (
                <>
                  <br />
                  채팅으로 문의해보세요
                </>
              )}
            </span>
          </>
        ),
        cancelButtonText: '창닫기',
        confirmButtonText: callOrChat ? '채팅하기' : '확인',
        onConfirm: () => {
          if (callOrChat) chatFunc();
        },
      })
    );
  },
};

export default callActions;
