import React from 'react';
import FeedTop from './components/FeedTop';
import Contents from './components/Contents';
import Images from './components/Images';
import RouteBtns from './components/RouteBtns';
import HashTags from './components/HashTags';
import LikeComment from './components/LikeComment';
import NewGoodsMessage from './components/NewGoodsMessage';
import AdminComment from './components/AdminComment';

const FeedArea = ({ feedData, commentCount, detail_item, isOwner }) => {
  const isFeedDeleted = feedData?.status === 'DELETED';

  return (
    <div className="posts-wrap">
      <div className="posts-inform">
        <div className="posts-info">
          <FeedTop feedData={feedData} detail_item={detail_item} isOwner={isOwner} />
        </div>
        <NewGoodsMessage feedData={feedData} isFeedDeleted={isFeedDeleted} />
        {!isFeedDeleted && (
          <>
            <div className="posts-body">
              <Contents feedData={feedData} detail_item={detail_item} />
              <Images feedData={feedData} detail_item={detail_item} />
            </div>

            {/* 다윈측 추가 정보 입력 */}
            {feedData?.adminContents ? <AdminComment feedData={feedData} /> : <></>}

            <div className="related-items">
              <RouteBtns feedData={feedData} />
            </div>
            <HashTags feedData={feedData} detail_item={detail_item} />
          </>
        )}
        <div className="posts-data">
          <LikeComment feedData={feedData} commentCount={commentCount} detail_item={detail_item} hasIcon={true} />
        </div>
      </div>
    </div>
  );
};

export default FeedArea;
