import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { STATUS } from '@/data/utils';
import { useEffect, useState } from 'react';

const useFetchMoreFeeds = ({ feeds, isLoggedIn, payload, setFeeds, scrollDiv }) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const element = scrollDiv.current;

  const handleScroll = () => {
    // [배지율 수정] 안드로이드에서 계산 값에 오차가 있음
    // if (element.clientHeight + element.scrollTop < element.scrollHeight - 20 || isFetching) return;
    if (element.clientHeight + element.scrollTop < element.scrollHeight - 300) {
      setIsFetching(false);
      return;
    } else {
      setIsFetching(true);
    }
  };

  const fetchMoreListItems = () => {
    // 이 부분을 saga로 옮길 수 있는 고려 해야함
    if (feeds?.status === STATUS.LOADING) {
      return;
    } else {
      if (!isLoggedIn) {
        dispatch(actions.common.pleaseLoginPopup(true));
        return;
      } else {
        if (feeds?.next && isFetching) {
          const temp = { ...payload, cursor: feeds?.next };
          setIsFetching(false);
          dispatch(actions.community.getFeeds(temp, feeds, setFeeds));
        } else {
          setIsFetching(false);
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [element]);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchMoreListItems();
    }
  }, [isLoggedIn]);

  return { fetchMoreListItems };
};

export default useFetchMoreFeeds;
