import { useEffect } from 'react';

const isIe = !!document.documentMode;

const useScrollToHighlight = ({ highlightIdx }) => {
  useEffect(() => {
    if (highlightIdx > -1) {
      document.getElementById('mainSearchResultItem_' + highlightIdx)?.scrollIntoView(isIe ? false : { block: 'nearest' });
    }
  }, [highlightIdx]);
};

export default useScrollToHighlight;
