import React, { useEffect } from 'react';
import ImageUploade from './components/ImageUpload';
import { FeedType } from '@/services/Community';
// import { buiFormfileUploader } from '@/resources/design/v2/js/bui.template';

const Images = ({ isUpdate, imagesRef, updateFeedDetail, feedType }) => {
  const array = Array(3).fill(undefined);

  const handleImageUpload = (uploadedImageId, image) => {
    const prev = imagesRef?.current;
    if (uploadedImageId) {
      imagesRef.current = [...prev, { id: uploadedImageId, image: image }];
    }
  };

  const handleImageDelete = (deleteImageId) => {
    if (deleteImageId) {
      const images = imagesRef?.current;
      const idx = images.map((item) => item.id).indexOf(deleteImageId);
      if (idx > -1) images.splice(idx, 1);
    }
  };

  // useEffect(() => {
  // buiFormfileUploader('[data-bui-form-file="uploader"]');
  // }, []);

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="#">
            사진첨부
          </label>
          {feedType === FeedType.POST_FOR_BUY && <p className="form-summary">구하는 집을 설명할 그림이나 사진이 있나요? (선택항목)</p>}
        </div>
        <div className="form-body">
          <div className="file-uploader module-a">
            <div className="file-list">
              {array?.map((image, index) => (
                <>
                  <ImageUploade
                    defaultImage={!isUpdate ? imagesRef?.current?.[index]?.image : updateFeedDetail?.images?.[index]?.url}
                    defaultId={!isUpdate ? imagesRef?.current?.[index]?.id : updateFeedDetail?.images?.[index]?.key}
                    handleImageUpload={handleImageUpload}
                    handleImageDelete={handleImageDelete}
                    labelText={'사진추가'}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
