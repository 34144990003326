/*global kakao*/
import * as actions from '@/data/rootActions';
import { OSTYPE } from '../apps/types/OsType';
import constrainMapBounds from './constrainMapBounds';

export { constrainMapBounds };

// 기본 좌표: 도곡역인근
export const DEFAULT_COORDINATE = { lat: 37.49092448461496, lng: 127.05542210563566 };

// 한국 BBOX
export const KOREA_BBOX = {
  wLng: 125.06666667, //극서
  eLng: 131.87222222, //극동
  sLat: 33.1, //극남
  nLat: 38.45, //극북
};

/**
 * 서비스 가능 지역 여부 판별
 */
export const getIsAvalilableCoord = ({ lng, lat }) => {
  /**
   * 카카오 api 응답 여부로 지도 서비스 제한하려 했지만 단점이 많음.
   * api를 조회하는데 시간이 걸리고
   * 국가별로 api response에 예외가 있으며(ex.일본)
   * redux state 변경 시점, 지도 로딩시점, 다시 이전 지도 위치로 rollback 등
   * 고려해야할 사항이 많아 코드가 clean해지기 어렵다.
   * 그래서 대한민국 영역(극동 극서 극남 극북 좌표 영역) 안에 포함되는지로 판별한다.*/
  return lng >= KOREA_BBOX.wLng && lng <= KOREA_BBOX.eLng && lat >= KOREA_BBOX.sLat && lat <= KOREA_BBOX.nLat;
};

export const updateRegionCode = (data, dispatch) => {
  const item = {
    code: data.code,
    address_name: data.fullname,
    region_4depth_name: '',
    region_3depth_name: data.dong?.name,
    region_2depth_name: [data.si?.name, data.sigungu?.name].filter((e) => e).join(' '),
    region_1depth_name: data.sido?.name,
    x: data.lng,
    y: data.lat,
  };
  dispatch(actions.buying.changeRegionCode(item));
};

export function createMap(container, zoomlevel, lat, lng, setMap) {
  const newMap = new kakao.maps.Map(container, {
    center: new kakao.maps.LatLng(lat, lng),
    level: zoomlevel,
  });
  if (setMap) setMap(newMap);
  return newMap;
}

/**
 * 현재 위치 정보 접근허용 및 조회 (국내로 제한)
 * */
export async function getCurrentPosition(callback, errorCallback, options = {}) {
  const { notAvailableCallback, timeout } = options;

  const osType = window.dawin_instance?.osType;
  const isApp = osType === OSTYPE.APP_ANDROID || osType === OSTYPE.APP_IOS;

  if (isApp) {
    try {
      const position = await window.dawin_instance.bridge.locationFunction.getCoordination();
      const positionAsFormat = { coords: { latitude: position?.latitude, longitude: position?.longitude } };

      if (getIsAvalilableCoord({ lat: positionAsFormat.coords.latitude, lng: positionAsFormat.coords.longitude })) {
        if (callback) callback(positionAsFormat);
      } else {
        if (notAvailableCallback) notAvailableCallback(positionAsFormat);
      }
    } catch (err) {
      // code는 navigator.geolocation.getCurrentPosition 과 동일한 방식으로 오류 넘김 (오류코드 1: 권한 없음)
      const code = err.message.indexOf('PERMISSION_DENIED') > -1 ? 1 : null;
      if (errorCallback) errorCallback({ type: 'app', code, message: err.message });
    }
  } else {
    navigator.geolocation.getCurrentPosition(
      function(position) {
        if (getIsAvalilableCoord({ lat: position.coords.latitude, lng: position.coords.longitude })) {
          if (callback) callback(position);
        } else {
          if (notAvailableCallback) notAvailableCallback(position);
        }
      },
      function(err) {
        if (errorCallback) errorCallback(err);
      },
      { timeout: timeout }
    );
  }
}

/**
kakao map 객체, 최대 제한 개수, 대상 리스트, 포함 필수 좌표
1.최대 제한 개수: 꼭 10이 아니라도 제한 가능하도록
2.포함 필수 좌표: 예를 들어 조건이 "우리집 주변의 중개사 최대 10인" 인 경우에 포함 필수 좌표는 우리집 좌표가 됨.
*/
export function limitAgents(newMap, max, targetList, coordinates) {
  const goodsPosition = new kakao.maps.LatLng(Number(coordinates.lat), Number(coordinates.lng));

  const bounds = new kakao.maps.LatLngBounds();
  bounds.extend(goodsPosition); // 매물위치

  const sortedEstate = targetList.sort((a, b) => a['distancekm'] - b['distancekm']); //매물과의 거리 오름차순대로 정렬
  sortedEstate.slice(0, max ?? 10).map((item) => {
    // 정렬된 중개사 max명까지만
    const position = new kakao.maps.LatLng(Number(item.lat), Number(item.lng));
    bounds.extend(position); // 중개사 위치
  });
  newMap.setBounds(bounds);

  return newMap;
}

export const GOODS_MAX_ZOOMLEVEL = 7;
