/**
 * 매물 옵션 정보
 * 매물 옵션은 더 많이 있는데, 대부분 백엔드에서 받은 데이터로 그리기 때문에 여기 모두 정의되어있지는 않다.
 * 이 파일에 있는 코드들은 별도로 표출해야하는 부분이 있기 때문에 정의했다.
 */
export const codes = {
  slh: 'SLH',
  hud: 'HUD',
  top: 'TOP',
  all: 'ALL',
  fal: 'FAL',
  par: 'PAR',
  sto: 'STO',
};

const _names = {
  [codes.slh]: '복층',
  [codes.hud]: '반지하',
  [codes.top]: '옥탑',
  [codes.all]: '건물 전체',
  [codes.fal]: '층 전체',
  [codes.par]: '층 일부',
  [codes.sto]: '상가',
};

export const getName = (code) => {
  return _names[code];
};

export default {
  codes,
  getName,
};
