import * as actions from '@/data/rootActions';
import { OSTYPE } from '../types/OsType';

/**
 * 앱이 아닌 환경에서 앱 다운로드로 이동
 */
const download = () => (dispatch) => {
  // let link = '';
  // link = 'https://dawinweb.page.link/wFWu';
  // 9i5w 이거는 뭐지?

  const osType = window.dawin_instance.osType;

  if (osType === OSTYPE.APP_ANDROID || osType === OSTYPE.APP_IOS) {
    return;
  }

  if (osType === OSTYPE.WEB_ANDROID) {
    window.open('https://play.google.com/store/apps/details?id=xyz.dawin.asset');
  } else if (osType === OSTYPE.WEB_IOS) {
    window.open('https://itunes.apple.com/kr/app/id1457802907');
  } else {
    window.open('https://play.google.com/store/apps/details?id=xyz.dawin.asset');
  }
};

export default download;
