import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { actions } from '@/data';
import { useDispatch } from 'react-redux';
import { STATUS } from '@/data/utils';
import { useShowLoadingBarOnLoading } from '@/pages/Login/views/hooks';
import useSetSavedId from './hooks/useSetSavedId';

const IdLogin = () => {
  const dispatch = useDispatch();

  const [savedIdCookie, setSavedIdCookie] = useState();
  const [checkSaveId, setCheckSaveId] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(STATUS.NOTASKED);

  const handleSaveId = (e) => {
    setCheckSaveId(e.target.checked);
  };

  // react-hook-form 관련
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ shouldFocusError: true });

  const onSubmit = () => {
    if (loadingStatus === STATUS.LOADING) return;

    const idValue = getValues('id').trim();
    const pwValue = getValues('pw').trim();

    dispatch(actions.auth.dawinLogin({ email: idValue, password: pwValue, saveID: checkSaveId, setLoadingStatus }));
  };

  // 쿠키에 저장된 아이디를 세팅해줌
  useSetSavedId({ setSavedIdCookie, setCheckSaveId, setValue });

  // 로딩바 관련
  const showLoadingBarOnLoading = useShowLoadingBarOnLoading();

  return (
    <form>
      <fieldset className="submit-form module-a type-c large">
        <legend>로그인서식</legend>
        <div className="form-list">
          <div className="form-item">
            <div className="form-wrap">
              <div className="form-body">
                {/* 아이디 */}
                <div className="form-area type-d">
                  <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                    <input
                      type="text"
                      className="form-elem"
                      id=""
                      autoComplete="off"
                      placeholder="아이디(이메일)"
                      defaultValue={savedIdCookie}
                      {...register('id', { required: '아이디(이메일)을 입력해주세요.' })}
                    />
                  </span>
                  <div className="form-noti">{errors?.id && <p className="para invalid">{errors?.id?.message}</p>}</div>
                </div>

                {/* 비밀번호 */}
                <div className="form-area type-d">
                  <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                    <input
                      type="password"
                      className="form-elem"
                      id=""
                      autoComplete="off"
                      placeholder="비밀번호"
                      {...register('pw', { required: '비밀번호를 입력해주세요.' })}
                    />
                  </span>
                  <div className="form-noti">{errors?.pw && <p className="para invalid">{errors?.pw?.message}</p>}</div>
                </div>

                {/* 아이디 저장 */}
                <div className="form-area">
                  <div className="option-list module-b style-a type-a">
                    <span className="option-item">
                      <label className="form checkbox module-a style-b type-line accent-01 small-1x">
                        <input type="checkbox" className="form-elem" checked={checkSaveId} onChange={handleSaveId} />
                        <span className="form-text">아이디(이메일) 저장</span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      {/* 로그인 버튼 */}
      <div className="button-display module-a style-a type-a">
        <div className="button-area">
          <button type="submit" className="btn module-b style-b type-fill accent-01 large-1x flex" onClick={handleSubmit(onSubmit)}>
            <span className="btn-text">{showLoadingBarOnLoading({ loadingStatus, text: '로그인' })}</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default IdLogin;
