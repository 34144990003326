import * as actions from '@/data/rootActions';

const handleGoBack = (goBackTo) => (dispatch) => {
  if (history.length < 3) {
    // 공유 등의 방법으로 접근했을 때, 지정한 곳으로 이동하거나 pathname으로 이동
    const pathname = goBackTo ? goBackTo : location.pathname;
    dispatch(actions.router.replace(pathname));
  } else {
    dispatch(actions.router.goBack());
  }
};

export default handleGoBack;
