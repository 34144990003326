import dayjs from 'dayjs';

/**
 *
 * @param {string | number} date 날짜
 * @param {string} format 포맷 https://day.js.org/docs/en/display/format
 * @returns
 */
export const format = (date, _format = 'YYYY.MM.DD') => {
  if (!date) return;

  // 크롬 외(사파리, firefox)에서 '2024.03.01'는 Invalid Date. '2024/03/01' '2024-03-01'는 인식한다.
  // 또한 "2017-01-22 11:57:00"는 Invalid Date, "2017-01-22T11:57:00"는 인식한다.
  const _date =
    typeof date === 'string'
      ? date
          .replace(/ /g, 'T')
          .replace(/-/g, '/')
          .replace(/\./g, '/')
      : date;

  return dayjs(_date).format(_format);
};

/**
 *
 * @param {string | number} startDate
 * @param {string | number} endDate
 * @param options { format = 'YYYY.MM.DD', separator='~' }
 * @returns
 */
export const range = (startDate, endDate, options = {}) => {
  const { format: _format = 'YYYY.MM.DD', separator = '~' } = options;
  return [format(startDate, _format), startDate !== endDate ? format(endDate, _format) : null].filter((item) => !!item).join(separator);
};
