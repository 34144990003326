// import NicknameChangePopUp from '@/components/NicknameSetting/Change';

import React from 'react';
import toggle from '@/hocs/toggle';
import handleGoBack from '@/services/Helper/handleGoBack';
import { useDispatch } from 'react-redux';
import NicknameSetting from '@/components/NicknameSetting/Change';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import { actions } from '@/data';

const NicknameChangePopUp = ({ isMobile }) => {
  const dispatch = useDispatch();

  const query = parseCallBack();
  delete query.nicknameSetting;

  const communityText = {
    titleText: '닉네임 등록',
    boldText: '닉네임 입력',
    guideText: '글, 댓글에서 사용할 닉네임을 입력해주세요.',
    btnText: '등록',
  };

  // 글작성, 댓글/대댓글 작성시 닉네임 없는 일반유저는 닉네임 설정 팝업이 나와야함
  // 닫기와 등록 버튼에 요구하는 액션이 다름
  // 1. replace함수는 글작성시 + 등록 버튼
  // 2. 그외에는 전부 setShow로 빠지도록 함 (글작성 + 닫기, 댓글/대댓글 + 닫기, 댓글/대댓글 + 등록 )
  const replace = () => {
    dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
  };

  const setShow = () => {
    // dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
    // dispatch(actions.router.go(-2));
    dispatch(handleGoBack());
  };

  return <NicknameSetting show={true} setShow={setShow} isMobile={isMobile} communityText={communityText} replace={replace} />;
};

export default toggle(NicknameChangePopUp);
