import * as AT from './actionTypes';

export const getUserInfo = (state) => state?.auth?.userSession;
export const isLoggedIn = (state) => (state?.auth?.userSession ? true : false);
export const getNextAction = (state) => state?.auth?.agreeAction;
export const getLogin = (state) => state?.auth[AT.LOG_IN]?.getOrElse(false);
export const getSmsResult = (state) => state?.auth[AT.SMS].getOrElse(false);
export const getSmsCheckIsSuccess = (state) => state?.auth[AT.SMS].isSuccess();
export const getIdCandidates = (state) => state?.auth?.idCandidates;
export const getEmailConfirmed = (state) => state?.auth?.emailConfirmed;
export const getPageTo = (state) => state?.auth?.pageTo;

export const getAgentSignUpStatus = (state) => state?.auth[AT.AGENT_SIGNUP];

export const getUserInfoForLogin = (state) => state?.auth?.userInfoForLogin;
export const getLoginStep = (state) => state?.auth?.loginStep;
export const getAuthToken = (state) => state?.auth?.authToken;
export const getLoginData = (state) => state?.auth?.loginData;

export const getFindId1 = (state) => state?.auth[AT.FIND_ID_1]?.getOrElse(false);
export const getFindId2 = (state) => state?.auth[AT.FIND_ID_2]?.getOrElse([]);
export const getFindId1State = (state) => state?.auth[AT.FIND_ID_1]?.which();
export const getFindId2State = (state) => state?.auth[AT.FIND_ID_2]?.which();
export const getFindPwState = (state) => state?.auth[AT.FIND_PW]?.which();
export const getChangePwState = (state) => state?.auth[AT.CHANGE_PW]?.which();

// export const getAlarmSetting = (state) => state?.auth?.alarmOn;
export const getAppInfo = (state) => state?.auth?.appInfo;

export const getNickname = (state) => state?.auth?.nickname;

export const getMarketingUserInfo = (state) => state?.auth?.marketingUserInfo;
export const getIsSignUpUser = (state) => state?.auth?.isSignUpUser;

export const getDeviceId = (state) => state?.auth?.deviceId;
export const getSessionId = (state) => state?.auth?.sessionId;

export const isPhoneNumberAuthOnly = (state) => state?.auth?.phoneNumberAuthOnly;
