import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';
import SideNavWithBtn from '@/components/Navigation/items/SideNavWithBtn';

/**
 * 뒤로가기, 메뉴 header
 * background 투명 / 버튼 흰색
 */
const NavNoBackground = ({ goBackTo }) => {
  const dispatch = useDispatch();

  const handleBackClick = (e) => {
    e.preventDefault();
    if (goBackTo) {
      dispatch(actions.router.replace(goBackTo));
    } else {
      dispatch(actions.router.goBack());
    }
  };

  return (
    <header id="header" className="header header-submain">
      <span className="btn-left" style={{ position: 'absolute', zIndex: 10 }}>
        <a className="btn-img btn-back-wh" onClick={handleBackClick}>
          뒤로
        </a>
      </span>
      <SideNavWithBtn showAlarm={false} showBurger={true} color="white" />
    </header>
  );
};

export default NavNoBackground;
