export const codes = {
  apt: 'APT',
  oft: 'OFT',
  vll: 'VLL', // 빌라인데 등록자(유저)가 다세대인지 연립인지 모를때
  dsd: 'DSD',
  yun: 'YUN',
  dan: 'DAN',
  dgg: 'DGG',
  one: 'ONE', // 나중을 위해 미리 등록(현재는 안씀)
  sto: 'STO', // 상가
  off: 'OFF', // 사무실
};

export const validRealtyTypes = ['APT', 'OFT', 'ONE', 'DSD', 'YUN', 'VLL', 'DGG', 'DAN'];

/** @deprecated getName 사용권장 */
export const names = {
  [codes.apt]: '아파트',
  [codes.oft]: '오피스텔',
  [codes.vll]: '빌라/원룸',
  [codes.dsd]: '다세대',
  [codes.yun]: '연립',
  [codes.dan]: '단독주택',
  [codes.dgg]: '다가구',
  [codes.one]: '원룸/투룸',
  [codes.sto]: '상가',
  [codes.off]: '사무실',
};

export const _namesForComplexName = {
  [codes.apt]: '아파트',
  [codes.oft]: '오피스텔',
  [codes.vll]: '빌라',
  [codes.dsd]: '빌라(다세대)',
  [codes.yun]: '빌라(연립)',
  [codes.dan]: '단독주택',
  [codes.dgg]: '다가구',
  [codes.one]: '원룸/투룸',
  [codes.sto]: '상가',
  [codes.off]: '사무실',
};

const _names = {
  [codes.apt]: '아파트',
  [codes.oft]: '오피스텔',
  [codes.vll]: '빌라/원룸',
  [codes.dsd]: '다세대',
  [codes.yun]: '연립',
  [codes.dan]: '단독주택',
  [codes.dgg]: '다가구',
  [codes.one]: '원룸/투룸',
  [codes.sto]: '상가',
  [codes.off]: '사무실',
};

const _groupCodeMapping = {
  [codes.apt]: codes.apt,
  [codes.oft]: codes.oft,
  [codes.vll]: codes.vll,
  [codes.dsd]: codes.vll,
  [codes.yun]: codes.vll,
  [codes.dan]: codes.dan,
  [codes.dgg]: codes.dgg,
  [codes.one]: codes.one,
  [codes.sto]: codes.sto,
  [codes.off]: codes.off,
};

export const getName = (code) => {
  return _names[code];
};

export const getNameForComplexName = (code) => {
  return _namesForComplexName[code];
};

export const getNameWithGroup = (code) => {
  const mainName = getName(_groupCodeMapping[code]);
  const subName = _groupCodeMapping[code] === code ? '' : `(${getName(code)})`;

  return `${mainName} ${subName}`;
};

export const getGroupCode = (code) => {
  return _groupCodeMapping[code];
};

export default {
  codes,
  names,
  nameToCode: Object.entries(_names).reduce((acc, curr) => {
    const [key, value] = curr;
    acc[value] = key;
    return acc;
  }, {}),
  getName,
  getNameWithGroup,
  getGroupCode,
};

// 커뮤니티에서 사용
export const REALTY_TYPES_CODE_TO_NAME = {
  APT: _names.apt,
  OFT: '오피스텔',
  VLL: '빌라/주택',
  ONE: '원룸/투룸',
};

// 커뮤니티에서 사용
export const REALTY_TYPES_NAME_TO_CODE = {
  아파트: 'APT',
  오피스텔: 'OFT',
  '빌라/주택': 'VLL',
  '원룸/투룸': 'ONE',
};
