import { loading, success, failure } from '@/data/utils';

//공동중개

export const SET_JOINT_KEYWORD = 'BROKERPAGE/SET_JOINT_KEYWORD';
export const SET_JOINT_CURRENT_PAGE = 'BROKERPAGE/SET_CURRENT_PAGE';
export const SET_JOINT_TOTAL_CNT = 'BROKERPAGE/SET_TOTAL_CNT';
export const SET_JOINT_NEW_TITLE = 'BROKERPAGE/SET_JOINT_NEW_TITLE';
export const SET_JOINT_NEW_CONT = 'BROKERPAGE/SET_JOINT_NEW_CONT';
export const SET_JOINT_NEW_ATTACH = 'BROKERPAGE/SET_JOINT_NEW_ATTACH';

export const SAVE_JOINT_REPLY = 'BROKERPAGE/SAVE_JOINT_REPLY';
export const SAVE_JOINT_NEW = 'BROKERPAGE/SAVE_JOINT_NEW';

export const EDIT_JOINT = 'BROKERPAGE/EDIT_JOINT';
export const DEL_JOINT = 'BROKERPAGE/DEL_JOINT';

export const GET_JOINT_BROKERAGE_LIST = 'BROKERPAGE/GET_JOINT_BROKERAGE_LIST';
export const GET_JOINT_BROKERAGE_LIST_LOADING = loading(GET_JOINT_BROKERAGE_LIST);
export const GET_JOINT_BROKERAGE_LIST_SUCCESS = success(GET_JOINT_BROKERAGE_LIST);
export const GET_JOINT_BROKERAGE_LIST_FAILURE = failure(GET_JOINT_BROKERAGE_LIST);

export const GET_JOINT_BROKERAGE_DETAIL = 'BROKERPAGE/GET_JOINT_BROKERAGE_DETAIL';
export const GET_JOINT_BROKERAGE_DETAIL_LOADING = loading(GET_JOINT_BROKERAGE_DETAIL);
export const GET_JOINT_BROKERAGE_DETAIL_SUCCESS = success(GET_JOINT_BROKERAGE_DETAIL);
export const GET_JOINT_BROKERAGE_DETAIL_FAILURE = failure(GET_JOINT_BROKERAGE_DETAIL);
