import React from 'react';

const GuideText = () => {
  return (
    <div className="form-head">
      <label className="form-name" htmlFor="reportReason">
        신고할 사유를 선택해주세요.
      </label>
    </div>
  );
};

export default GuideText;
