import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import * as selectors from '@/data/rootSelectors';
import global from '@/resources/global';
import apis from '@/apis';
import { keys } from 'regenerator-runtime';

export const LABEL_ID = {
  본인인증: 'step1_label_1',
  관계: 'step1_label_2',
  집주인연락처: 'step1_label_3',
  매물종류: 'step1_label_4',
  매물주소: 'step1_label_5',
  단지선택: 'step1_label_6',
  면적정보: 'step1_label_7',
  방구조: 'step1_label_8',
  거래유형: 'step1_label_9',
  집주인이름: 'step1_label_10',
  세입자이름: 'step1_label_11',
  세입자연락처: 'step1_label_12',
  매도인구분: 'step1_label_13',
  입주희망일: 'step2_label_1',
  방수욕실수: 'step2_label_2',
  방향: 'step2_label_4',
  관리비: 'step2_label_5',
  물건특징: 'step2_label_8',
  특정중개사선택: 'step4_label_1',
};

// @ todo 지역 선택하는 select component 이걸로 다 공용으로 사용하도록 하기
// 직접 집내놓기, 개발호재 알리기, 주간 실거래가, 랭킹 검색결과에서 사용 완료 (중개사 회원가입 x)

// @param requestedFrom
// SELLING_MANUAL : (집내놓기) 직접 집내놓기
// AGENT_SIGNUP : 중개사 회원가입
// RANKING_RESULT : (분석) 랭킴 검색결과
// WEEKLY_PRICE : (분석) 주간 실거래가
// ADD_POSITIVES  (분석) 개발호재 알리기
export default function AddrSelectComp({ requestedFrom, shouldUpdateMyBDongCode, isActive, showInterestedArea, showAllDepth }) {
  const dispatch = useDispatch();

  /**
   * props 설명
   * - shouldUpdateMyBDongCode : 개인화 부분 My (우리집 정보,관심지역 정보 ,관심단지 정보)에서
   * select-list 말고 관심지역 , 관심단지에서 b_dong_code로 불러오기 위해 피룡한지 검증
   *
   * - isActive : disable check없이 항상 active한 상태로 하고 싶을떄
   */

  // [[NEW LOGIC]]-----------------------------------------------------------
  const siDoCode = useSelector(selectors.address.getSiDoCode);

  const siGunGuCode = useSelector(selectors.address.getSiGunGuCode);
  const siGunGuName = useSelector(selectors.address.getSiGunGuName);

  const dongRiCode = useSelector(selectors.address.getDongRiCode);
  // 1차, 2차로 나뉠경우 첫번쨰 select박스에 있는 값은 보여줘야함. ex) 41130(충주시) 41137(충주시 xx군)
  const siGunGuFirstCode = useSelector(selectors.address.getSiGunGuFirstCode);
  const dongRiFirstCode = useSelector(selectors.address.getDongRiFirstCode);
  const riCode = useSelector(selectors.address.getRiCode);

  const [showSpinner, setShowSpinner] = useState(false);
  const [isClickedInterestedArea, setIsClickedInterestedArea] = useState(false);

  // 기존 disableForm 조건처리
  // const shouldDisableForm = !!!phone_verified || !!!reg_type || !!!category;
  let shouldDisableForm;
  if (isActive) {
    shouldDisableForm = false;
  } else {
    // shouldDisableForm = !!!phone_verified || !!!reg_type || !!!category
  }
  // const titleStyle = shouldDisableForm ? { color: '#cccccc' } : {};
  const formStyle = shouldDisableForm
    ? {
        cursor: 'no-drop',
      }
    : {};

  // const titleStyle = shouldDisableForm ? { color: '#cccccc' } : {};
  // 집내놓기 순서대로 활성화되는 부분이요.. 제목만 색이 연해서 인식이 잘 안되는데.. row에 filter: grayscale(1) opacity(0.5); 해서 흐릿하게 하는건 어떨까 하니다.
  const titleStyle = {};
  const rowStyle = shouldDisableForm
    ? {
        filter: 'grayscale(1) opacity(0.5)',
      }
    : {};
  // [[NEW LOGIC]]-----------------------------------------------------------

  const si_do_list = useSelector(selectors.address.getSiDoList);
  const si_gun_gu_list = useSelector(selectors.address.getSiGunGuList);
  // 두번째 시군구가 있을 경우
  const si_gun_gu_list_second = useSelector(selectors.address.getSiGunGuSecondList);
  const dong_ri_list = useSelector(selectors.address.getDongRiList);
  const dong_ri_list_second = useSelector(selectors.address.getDongRiSecondList);

  const only_available = requestedFrom === global.ADDR_SELECT_COMP_CODE.SELLING_MANUAL ? true : false;
  //gmh 추후 변경될수 있음
  const only_broker = false;

  useEffect(() => {
    dispatch(actions.address.getAddrSiDo(only_available, only_broker));
  }, []);

  useEffect(() => {
    if (siDoCode) {
      dispatch(actions.address.changeSiDoCode(siDoCode));

      // 첫번째 시군구 리스트 불러오기
      dispatch(actions.address.getAddrSiGunGuFirst(siDoCode, { showSpinner }));
    }
    setIsClickedInterestedArea(false);
  }, [siDoCode, showSpinner, isClickedInterestedArea]);

  // 첫번째 시군구 검색조건되면 검색
  useEffect(() => {
    if (siGunGuCode) {
      const four_digit_code = siGunGuCode.substr(0, 4);
      dispatch(actions.address.changeSiGunGuCode(siGunGuCode, siGunGuName));
      dispatch(actions.address.changeSiGunGuFourDigitCode(four_digit_code));

      // 2번째 시군구 call해서 있으면 store의 si_gun_gu_list_second에 할당
      dispatch(actions.address.getAddrSiGunGuSecond(four_digit_code, { showSpinner }));
      dispatch(actions.address.getAddrDongRiFirst(siGunGuCode, { showSpinner }));
    }
  }, [siGunGuCode, showSpinner, isClickedInterestedArea]);

  // 시군구코드 5자리 완성되면 동리 리스트 콜
  useEffect(() => {
    // 동코드 변경시 동에서 끝나면 DongRiSecond 콜하지 x
    if (dongRiCode) {
      // 00000000xx -> 0000000로 마지막 ri 콜할 때 사용되는 param
      const eight_digit_code = dongRiCode.substr(0, 8);
      // xxxxx000xx -> 000이  읍면동
      const eup_myeon_dong_code = dongRiCode.substr(5, 3);

      dispatch(actions.address.changeDongRiCode(dongRiCode));
      dispatch(actions.address.changeDongRiEightDigitCode(eight_digit_code));

      // 202 보다 크면 읍/면 코드이고 202이하면 동코드라 두번째 동리를 콜하지 x
      if (eup_myeon_dong_code > 202) {
        dispatch(actions.address.getAddrDongRiSecond(eight_digit_code, { showSpinner }));
      } else {
        dispatch(actions.address.getComplexList({ showSpinner }));
      }
    }
  }, [dongRiCode, showSpinner, isClickedInterestedArea]);

  useEffect(() => {
    // 마지막 2자리 코드 ri코드가 00이면 동으로 끝나므로 00 이상인 코드면 다시 단지 검색
    if (riCode && riCode != '00') {
      dispatch(actions.address.getComplexList({ showSpinner }));
    }
  }, [riCode, showSpinner]);

  function resetNextOptions() {
    //하위 데이터 초기화
    // [[NEW LOGIC]]-----------------------------------------------------------
    // dispatch(
    //   actions.address.updateSavedForm({
    //     tb_cb_complex_idx: '',
    //     dong: '',
    //     floor: '',
    //     total_floor: '',
    //     ho: '',
    //     area_no: '',
    //   })
    // );
    // [[NEW LOGIC]]-----------------------------------------------------------
  }

  let renderSiDoOptions = useMemo(() => {
    let result = [
      <option key="" value="">
        시/도 선택
      </option>,
    ];
    if (si_do_list) {
      si_do_list.forEach((e) => {
        if (result.findIndex((el) => el.key == e.code) < 0) {
          result.push(
            <option key={e.code} value={e.code} name={e.name}>
              {e.name}
            </option>
          );
        }
      });
    }
    return <>{result}</>;
  }, [si_do_list]);

  const onSiDoSelect = (e) => {
    const key = e.target.value;
    const name = e.target.name;

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);

    /**
     * 2021-09-09 @장혁진 수정
     * 세종특별시는 시도/ 시군구가 세종특별시로 같으므로
     * 시도 선택하면 시군구 패스하고 바로 읍면동으로 select하도록 분기처리함
     */
    if (key != '36') {
      dispatch(actions.address.changeSiDoCode(key, name));
    } else {
      // 세종시 시도, 시군구 코드는 36(시도) 110(시군구)로만 행정코드가 있으므로 예외처리 해준다.
      const SAEJONGSI_SIDO_SIGUNGU_CODE = '36110';
      dispatch(actions.address.setSiGunGuFirstCode('3611'));
      dispatch(actions.address.changeSiDoCode(key, name));
      dispatch(actions.address.changeSiGunGuCode(SAEJONGSI_SIDO_SIGUNGU_CODE, name));
    }
    resetNextOptions();
  };

  /**
   * 2021-08-12 @장혁진 추가
   * 기존 sigungu 를 2단계로 쪼갬
   * e.code는 5자리 시군구인데 마지막 1자리를 빼고 4 digit code로 저장
   *
   */
  const renderSiGunGuOptionsFirst = useMemo(() => {
    let result = [
      <option key="" value="">
        시/군/구 선택
      </option>,
    ];
    // if (sido_code) {
    if (siDoCode) {
      si_gun_gu_list.forEach((e, key) => {
        if (result.findIndex((el) => el.key == e.code) < 0) {
          result.push(
            <option key={key} value={e.code} name={e.name}>
              {e.name}
            </option>
          );
        }
      });
    }

    return <>{result}</>;
  }, [siDoCode, si_gun_gu_list]);

  const renderSiGunGuOptionsSecond = useMemo(() => {
    let result = [
      <option key="" value="">
        구 선택
      </option>,
    ];
    if (siDoCode) {
      si_gun_gu_list_second?.forEach((e, key) => {
        if (result.findIndex((el) => el.key == e.code) < 0) {
          result.push(
            <option key={key} value={e.sigungu_code} name={e.sigungu}>
              {e.sigungu}
            </option>
          );
        }
      });
    }

    return <>{result}</>;
  }, [siDoCode, si_gun_gu_list_second]);

  const onSiGunGuSelect = (e) => {
    const key = e.target.value;
    const name = e.target.name;

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);
    dispatch(actions.address.changeSiGunGuCode(key, name));
    resetNextOptions();
  };

  const onSiGunGuSelectFirst = (e) => {
    const target = e.target;
    const key = e.target.value;
    const name = target.options[target.selectedIndex].text;

    dispatch(actions.address.changeSiGunGuName(name));

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);
    dispatch(actions.address.setSiGunGuFirstCode(key));
    dispatch(actions.address.changeSiGunGuCode(key, name));
    resetNextOptions();
  };

  const onSiGunGuSelectSecond = (e) => {
    const key = e.target.value;
    const name = e.target.options[e.target.selectedIndex].text;

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);
    dispatch(actions.address.changeSiGunGuCode(key, siGunGuName));
    dispatch(actions.address.changeSiGunGuNameSecond(name));
    resetNextOptions();
  };

  /**
   * 2021-08-12 @장혁진 추가
   * 기존 dong-ri 를 2단계로 쪼갬
   */
  const renderDongRiOptions = useMemo(() => {
    let result = [
      <option key="" value="">
        읍/면/동 선택
      </option>,
    ];
    // if (sigungu_code && dong_ri_list) {
    if (siGunGuCode && dong_ri_list) {
      dong_ri_list.forEach((e) => {
        if (result.findIndex((el) => el.key == e.code) < 0) {
          result.push(
            <option key={e.code} value={e.code} name={e.name}>
              {e.name}
            </option>
          );
        }
      });
    }

    return <>{result}</>;
  }, [siGunGuCode, dong_ri_list]);

  const renderDongRiOptionsFirst = useMemo(() => {
    let result = [
      <option key="" value="">
        읍/면/동 선택
      </option>,
    ];
    // if (sigungu_code && dong_ri_list) {
    if (siGunGuCode && dong_ri_list) {
      dong_ri_list.forEach((e) => {
        if (result.findIndex((el) => el.key == e.code) < 0) {
          result.push(
            <option key={e.dong_code} value={e.dong_code} name={e.name}>
              {e.name}
            </option>
          );
        }
      });
    }

    return <>{result}</>;
  }, [siGunGuCode, dong_ri_list]);

  const renderDongRiOptionsSecond = useMemo(() => {
    let result = [
      <option key="" value="">
        동/리 선택
      </option>,
    ];
    // if (sigungu_code && dong_ri_list) {
    if (dongRiCode) {
      dong_ri_list_second?.forEach((e) => {
        if (result.findIndex((el) => el.key == e.dong_code) < 0) {
          result.push(
            <option key={e.dong_code} value={e.dong_code} name={e.name}>
              {e.ri}
            </option>
          );
        }
      });
    }

    return <>{result}</>;
  }, [dongRiCode, dong_ri_list_second]);

  const onDongRiSelect = (e) => {
    const key = e.target.value;
    const name = e.target.name;

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);
    dispatch(actions.address.changeDongRiCode(key, name));
    resetNextOptions();
  };

  const onDongRiSelectFirst = (e) => {
    const key = e.target.value;
    const name = e.target.name;

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);

    dispatch(actions.address.changeDongRiCode(key, name));

    dispatch(actions.address.setDongRiFirstCode(key));
    if (shouldUpdateMyBDongCode) {
      dispatch(actions.my.setBDongCode(key));
    }
    resetNextOptions();
  };

  const onDongRiSelectSecond = (e) => {
    const key = e.target.value;
    const name = e.target.name;

    // 최초 유저 interaction 시 스피너 활성화.
    setShowSpinner(true);

    dispatch(actions.address.changeDongRiCode(key, name));
    dispatch(actions.address.setRiCode(key.substr(-2)));
    if (shouldUpdateMyBDongCode) {
      dispatch(actions.my.setBDongCode(key));
    }
    resetNextOptions();
  };

  return (
    <div className="form-body">
      <div className="form-area type-d">
        <span className="form select module-b style-b type-line normal-04 large-1x flex">
          <select
            id=""
            title="시/도 선택"
            value={siDoCode}
            className="form-elem"
            onChange={onSiDoSelect}
            // disabled={shouldDisableForm}
            style={formStyle}>
            {renderSiDoOptions}
          </select>
        </span>

        {siDoCode != '36' && (
          <span className="form select module-b style-b type-line normal-04 large-1x flex">
            <select
              id=""
              title="시/군/구 선택"
              value={siGunGuFirstCode}
              className="form-elem"
              onChange={onSiGunGuSelectFirst}
              // disabled={shouldDisableForm}
              style={formStyle}>
              {renderSiGunGuOptionsFirst}
            </select>
          </span>
        )}

        {si_gun_gu_list_second?.length > 0 && (
          <span className="form select module-b style-b type-line normal-04 large-1x flex">
            <select
              id=""
              title="구 선택"
              value={siGunGuCode}
              className="form-elem"
              onChange={onSiGunGuSelectSecond}
              // disabled={shouldDisableForm}
              style={formStyle}>
              {renderSiGunGuOptionsSecond}
            </select>
          </span>
        )}

        {showAllDepth != false ? (
          <span className="form select module-b style-b type-line normal-04 large-1x flex">
            <select
              id=""
              title="읍/면/동 선택"
              // value={law_dong_first_code}
              value={dongRiFirstCode}
              className="form-elem"
              onChange={onDongRiSelectFirst}
              // disabled={shouldDisableForm}
              style={formStyle}>
              {renderDongRiOptionsFirst}
            </select>
          </span>
        ) : (
          undefined
        )}

        {!showAllDepth && dong_ri_list_second?.length > 0 ? (
          <select
            id=""
            title="동/리 선택"
            value={dongRiCode}
            className="form-elem"
            onChange={onDongRiSelectSecond}
            // disabled={shouldDisableForm}
            style={formStyle}>
            {renderDongRiOptionsSecond}
          </select>
        ) : (
          undefined
        )}
      </div>
    </div>
  );
  // }
}

const TitleText = ({
  titleStyle = {},
  requestedFrom = global.ADDR_SELECT_COMP_CODE.SELLING_MANUAL,
  setIsClickedInterestedArea,
  showInterestedArea,
}) => {
  /**
   * 2021-09-16 @장혁진 추가
   * 주소 select에 관심지역 리스트 가져와 보여주는 기획 추가
   */

  const dispatch = useDispatch();
  const myAreasData = useSelector(selectors.my.getMyAreaData);
  const [dongCode, setBDongCode] = useState('');

  const selectInterestedArea = (bCode) => {
    console.log(bCode);
    // 클릭 시 법정동코드 10자리를 받아 redux 업데이트
    const b_dong_code = String(bCode);

    // 관심지역 click시
    setIsClickedInterestedArea(true);

    // 관심 지역 클릭 시 select박스를 해당 법정동코드 10자리로 변경.
    dispatch(actions.address.changeSiDoCode(b_dong_code.substr(0, 2)));
    dispatch(actions.address.setSiGunGuFirstCode(b_dong_code.substr(0, 4).concat('0')));
    dispatch(actions.address.changeSiGunGuCode(b_dong_code.substr(0, 5)));

    dispatch(actions.address.setDongRiFirstCode(b_dong_code));
    dispatch(actions.address.changeDongRiCode(b_dong_code));

    dispatch(actions.address.getComplexList({ showSpinner: true }));
  };

  const myAreasList =
    myAreasData && myAreasData?.length > 0
      ? myAreasData?.map((area, index) => (
          <li style={{ cursor: 'pointer' }} onClick={() => selectInterestedArea(area.b_dong_code)} key={index} value={area.b_dong_code}>
            {area.district[0]?.law_sido} {area.district[0]?.law_sigungu} {area.district[0]?.law_dong}
          </li>
        ))
      : false;

  switch (requestedFrom) {
    case global.ADDR_SELECT_COMP_CODE.SELLING_MANUAL: {
      return (
        <div>
          <label htmlFor="" className="lbl" id={LABEL_ID['매물주소']} style={titleStyle}>
            주소
          </label>
          {showInterestedArea ? myAreasList : null}
        </div>
      );
    }
    case global.ADDR_SELECT_COMP_CODE.ADD_POSITIVES: {
      return (
        <label htmlFor="" className="lbl">
          지역
        </label>
      );
    }
    default: {
      return <></>;
    }
  }
};

const NoticeText = ({ requestedFrom = global.ADDR_SELECT_COMP_CODE.SELLING_MANUAL }) => {
  switch (requestedFrom) {
    case global.ADDR_SELECT_COMP_CODE.SELLING_MANUAL: {
      return <></>;
    }
    case global.ADDR_SELECT_COMP_CODE.RANKING_RESULT:
    case global.ADDR_SELECT_COMP_CODE.WEEKLY_PRICE:
    case global.ADDR_SELECT_COMP_CODE.ADD_POSITIVES: {
      return <></>;
    }
    default:
      return <></>;
  }
};

const StyledSpan = styled.span`
  margin-right: 5px;
`;
export const AddrSelectCompReadOnly = () => {
  const isMobile = useSelector(selectors.common.isMobile);

  // const savedForm = useSelector(selectors.address.getSavedForm);

  const complexIdx = savedForm?.tb_cb_complex_idx;
  // const law_addr1 = savedForm?.law_addr1 ?? '';
  // const law_addr2 = savedForm?.law_addr2 ?? '';
  // const law_addr3 = savedForm?.law_addr3 ?? '';

  // [DX-1915] [w]삼호3차 주소 법정동 주소로 수정부탁드립니다.
  const [lawAddr, setLawAddr] = useState('');

  const dong = savedForm?.dong ?? '';
  const floor = savedForm?.floor ?? '';
  const ho = savedForm?.ho ?? '';

  // [DX-1915] [w]삼호3차 주소 법정동 주소로 수정부탁드립니다.
  useEffect(() => {
    async function fetchComplexDetail(complexIdx) {
      const { info } = await apis.buyingApi.getDetail(complexIdx);
      setLawAddr(`${info?.complex_address} ${info?.complex_name}` ?? '');
    }

    if (complexIdx) {
      fetchComplexDetail(complexIdx);
    }
  }, [complexIdx]);

  const data = {
    // law_addr1,
    // law_addr2,
    // law_addr3,
    lawAddr,
    dong,
    floor,
    ho,
  };

  return (
    <div className="row">
      <label htmlFor="" className="lbl">
        매물주소
      </label>
      <span className="ins">{isMobile ? <ReadOnlyMobileView data={data} /> : <ReadOnlyDesktopView data={data} />}</span>
    </div>
  );
};

function ReadOnlyMobileView({ data }) {
  const { lawAddr, dong, floor, ho } = data;

  return (
    <>
      <div className="inp-horiz" style={{ justifyContent: 'flex-start' }}>
        <StyledSpan>{lawAddr}</StyledSpan>
      </div>
      <div className="inp-horiz" style={{ justifyContent: 'flex-start' }}>
        <StyledSpan>{dong}동</StyledSpan>
        <StyledSpan>{floor}층</StyledSpan>
        <StyledSpan>{ho}호</StyledSpan>
      </div>
    </>
  );
}

function ReadOnlyDesktopView({ data }) {
  const { lawAddr, dong, floor, ho } = data;
  return (
    <div className="inp-horiz" style={{ justifyContent: 'flex-start' }}>
      <StyledSpan>{lawAddr}</StyledSpan>
      <StyledSpan>{dong}동</StyledSpan>
      <StyledSpan>{floor}층</StyledSpan>
      <StyledSpan>{ho}호</StyledSpan>
    </div>
  );
}
