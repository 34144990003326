import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { STEPS } from '@/pages/Login/BasicForm/helpers';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import { STATUS } from '@/data/utils';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Btn from './components/Btn';
import Step1 from './components/Step1';
import useGetCandidateIds from './hooks/useGetCandidateIds';

const PhoneNumberLogin = () => {
  const loginStep = useSelector(selectors.auth.getLoginStep);

  const [loadingStatus, setLoadingStatus] = useState(STATUS.NOTASKED);
  const [varificationCode, setVarificationCode] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    // dispatch(actions.auth.setLoginStep(STEPS.PHONE1));
  }, []);

  const reset = () => {
    setVarificationCode();
  };

  const candidateList = useGetCandidateIds();
  const [selectedId, setSelectedId] = useState(null);

  return (
    <form>
      <fieldset className="submit-form module-a type-c large">
        <legend>로그인서식</legend>
        <div className="form-list">
          <Step1 register={register} errors={errors} />

          <Step2
            show={loginStep === STEPS.PHONE2 || loginStep === STEPS.SOCIAL3 || loginStep === STEPS.PHONE3}
            getValues={getValues}
            setVarificationCode={setVarificationCode}
            reset={reset}
          />

          <Step3 show={loginStep === STEPS.PHONE3} memberList={candidateList?.meberList} selectedId={selectedId} setSelectedId={setSelectedId} />

          <Btn
            handleSubmit={handleSubmit}
            loginStep={loginStep}
            loadingStatus={loadingStatus}
            setLoadingStatus={setLoadingStatus}
            getValues={getValues}
            reset={reset}
            candidateList={candidateList}
            selectedId={selectedId}
            varificationCode={varificationCode}
            errors={errors}
          />
        </div>
      </fieldset>
    </form>
  );
};

export default PhoneNumberLogin;
