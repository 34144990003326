import { actions } from '@/data';
import { parseCallBack, objectToQuerystring } from '@/services/Helper';

export const LOGIN_QUERYPARAM_KEY = 'login_step';

export const routeToNextStep = (step) => (dispatch) => {
  const path = getNextStepPath(step);
  dispatch(actions.router.replace(path));
};

export const getNextStepPath = (step) => {
  const querystring = parseCallBack();

  // 추가
  if (step) querystring[LOGIN_QUERYPARAM_KEY] = step;
  // 제거
  else delete querystring[LOGIN_QUERYPARAM_KEY];

  return `${location.pathname}${objectToQuerystring(querystring)}`;
};

export const getTempNextStepPathForMarketing = (step, value) => {
  const querystring = parseCallBack();

  const key = value === 'true' || !value ? 'marketing_user' : `${value}_user`;

  // 추가
  if (step) {
    querystring[LOGIN_QUERYPARAM_KEY] = step;
    querystring[key] = true;
  }
  // 제거
  else {
    delete querystring[LOGIN_QUERYPARAM_KEY];
    delete querystring[key];
  }

  return `${location.pathname}${objectToQuerystring(querystring)}`;
};
