import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import Spinner from '@/components/Spinner';

const SpinnerBackdrop = styled.div`
  position: absolute;
  z-index: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinnerWithoutCover {
    position: unset;
    transform: unset;
    padding: 10px;
    background: #404040;
    border-radius: 25px;
    opacity: 0.8;
  }
`;

export default function SpinnerWithoutCover({ show, theme = 'light', ...rest }) {
  const spinnerColor = theme === 'dark' ? `#fff` : 'white'; //`#0000004f`;

  return show ? (
    <SpinnerBackdrop {...rest}>
      <Spinner width={3} className="spinnerWithoutCover" size={20} color={spinnerColor} />
    </SpinnerBackdrop>
  ) : null;
}
