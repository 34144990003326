import { makeTagsToApiForm } from '@/combinedComp/Community/helpers';
import { STATUS } from '@/data/utils';
import { FeedType, TagType } from '@/services/Community';
import React from 'react';

const SaveSubmitBtn = ({ status, isUpdate, feedType, contents, tags, addedTagsRef, numericTags, imagesRef, submit }) => {
  const handleSubmit = (e) => {
    // 구해줘다윈을 제외한 다른 post에서는 FLAG_TAG 제외
    const payload = {
      feedType,
      contents: contents,
      tags:
        feedType != FeedType.POST_FOR_BUY
          ? makeTagsToApiForm({ tags, addedTagsRef, numericTags }).filter((i) => i.type != TagType.FLAG_TAG)
          : makeTagsToApiForm({ tags, addedTagsRef, numericTags }),
      images: imagesRef?.current?.map((item) => item.id) || [],
    };
    submit(payload, e);
  };

  return (
    <div className="popup-local-util">
      <div className="button-display module-a style-a type-d">
        <span className="button-area">
          <button
            type="submit"
            className="btn module-b style-a type-fill large-2x accent-01 flex"
            onClick={handleSubmit}
            disabled={status?.status === STATUS?.LOADING}
            data-log-id="REGISTER_POST_FEED_BUTTON">
            <span className="btn-text">{isUpdate ? '저장' : '등록'}</span>
          </button>
        </span>
      </div>
    </div>
  );
};

export default SaveSubmitBtn;
