import { actions, selectors } from '@/data';
import React from 'react';
import { useSelector } from 'react-redux';
import { store } from '@/data/configureStore';
import useReportPopup from '@/combinedComp/Community/DetailNew/hooks/useReportPopup';
import { Link } from 'react-router-dom';

const ReplyTool = ({ onUpdateComment, onDeleteComment, reply, deletedId, isActiveComment }) => {
  const { mbr_idx } = useSelector(selectors.auth.getUserInfo) || {};
  const writerId = reply?.writer?.id;
  const status = reply?.status;
  const openReportPopup = useReportPopup();
  const id = reply?.id;

  const report = (e) => {
    const { dispatch } = store;

    if (status === 'DELETED') {
      dispatch(
        actions.common.alert({
          contents: '삭제된 글은 신고할 수 없습니다.',
        })
      );
    } else {
      openReportPopup(e, 'COMMENT', id);
    }
  };

  return (
    <div className="button-area">
      {mbr_idx == writerId ? (
        deletedId?.indexOf(id) > -1 || isActiveComment === reply?.id ? (
          <></>
        ) : (
          <>
            <Link className="btn modify" to="#" onClick={(e) => onUpdateComment(e, id)}>
              <span className="btn-text">수정</span>
            </Link>
            <button className="btn delete" type="button" onClick={(e) => onDeleteComment(e, id, 'reply')}>
              <span className="btn-text">삭제</span>
            </button>
          </>
        )
      ) : (
        <>
          {mbr_idx != writerId && status !== 'DELETED' && status !== 'BLINDED' && (
            <a
              className="btn report"
              data-bui-toggle-button="reportPosts"
              // href="#reportPosts"
              // onclick="contentsPopup.active(this.dataset.buiToggleButton);"
              onClick={report}>
              <span className="btn-text">신고</span>
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default ReplyTool;
