import * as AT from './actionTypes';

export const getCustomer = (state) => state?.brokerPage?.mgCustomer?.mgcList;
export const getEditCustomer = (state) => state?.brokerPage?.mgCustomer?.editInfo;
export const getDelCustomer = (state) => state?.brokerPage?.mgCustomer?.delInfo;
export const getEditMemo = (state) => state?.brokerPage?.mgCustomer?.editmemo;
export const getSel = (state) => state?.brokerPage?.mgCustomer?.selcustom;
export const getSelCustom = (state) => state?.brokerPage?.mgCustomer?.selList;
export const getNewCustomer = (state) => state?.brokerPage?.mgCustomer?.newCustom;
export const getCustomerList = (state) => state?.brokerPage?.mgCustomer?.mgCustomerList;
export const getMgCustomer = (state) => state?.brokerPage?.mgCustomer;
