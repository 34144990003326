export const codes = {
  s: 's',
  c: 'c',
  m: 'm',
};

const _names = {
  [codes.s]: '매매',
  [codes.c]: '전세',
  [codes.m]: '월세',
};

export const getName = (code) => {
  return _names[code];
};

export default {
  codes,
  getName,
};
