import { taggedSum } from 'daggy';
import { STATUS } from './utils';

const Remote = taggedSum('Remote', {
  NotAsked: [],
  Loading: [],
  Failure: ['error'],
  Success: ['data'],
});
//console.log(Remote.of);
//Remote.of = Remote.Success;

Remote.prototype.map = function(f) {
  return this.cata({
    Success: (x) => Remote.Success(f(x)),
    Failure: () => this,
    Loading: () => this,
    NotAsked: () => this,
  });
};

Remote.prototype.ap = function(that) {
  return this.cata({
    Success: (f) => that.map(f),
    Failure: () =>
      that.cata({
        Success: (f) => this,
        Failure: () => this,
        Loading: () => this,
        NotAsked: () => that,
      }),
    Loading: () =>
      that.cata({
        Success: (f) => this,
        Failure: () => that,
        Loading: () => that,
        NotAsked: () => that,
      }),
    NotAsked: () => this,
  });
};

Remote.prototype.toJSON = function() {
  return {
    data: { __remote: this['@@values'][0] || [] },
    __serializedType__: this['@@tag'],
  };
};

Remote.prototype.chain = function(f) {
  return this.cata({
    Success: (x) => f(x),
    Failure: () => this,
    Loading: () => this,
    NotAsked: () => this,
  });
};

Remote.prototype.getOrElse = function(defaultValue) {
  return this.cata({
    Success: (value) => value,
    Failure: () => defaultValue,
    Loading: () => defaultValue,
    NotAsked: () => defaultValue,
  });
};

Remote.prototype.isSuccess = function() {
  return this.cata({
    Success: () => true,
    Failure: () => false,
    Loading: () => false,
    NotAsked: () => false,
  });
};

// jy _ 로딩 state 잡기
Remote.prototype.isLoading = function() {
  return this.cata({
    Success: () => false,
    Failure: () => false,
    Loading: () => true,
    NotAsked: () => false,
  });
};

// jy _ 실패 state 잡기
Remote.prototype.isFailure = function() {
  return this.cata({
    Success: () => false,
    Failure: () => true,
    Loading: () => false,
    NotAsked: () => false,
  });
};

Remote.prototype.getOrFail = function(defaultValue) {
  return this.cata({
    Success: (value) => value,
    Failure: (value) => {
      throw value;
    },
    Loading: () => {
      return defaultValue;
    },
    NotAsked: () => {
      return defaultValue;
    },
  });
};

Remote.prototype.which = function() {
  // return this['@@tag'];
  return this.cata({
    Success: () => 'Success',
    Failure: () => 'Failure',
    Loading: () => 'Loading',
    NotAsked: () => 'NotAsked',
  });
};

Remote.prototype.getWithStatus = function() {
  return this.cata({
    Success: (value) => [STATUS.SUCCESS, value],
    Failure: (error) => [STATUS.FAILURE, error],
    Loading: () => [STATUS.LOADING],
    NotAsked: () => [STATUS.NOTASKED],
  });
};

Remote.prototype.getStatus = function() {
  return this.cata({
    Success: () => STATUS.SUCCESS,
    Failure: () => STATUS.FAILURE,
    Loading: () => STATUS.LOADING,
    NotAsked: () => STATUS.NOTASKED,
  });
};

export default Remote;
