import React from 'react';
import useSearch from './hooks/useSearch';
import DefaultView from './DefaultView';
// import DualAddressView from './DualAddressView';

/**
 * @param {boolean} totalSearch 모든 타입 조회
 * @param {Array} markTypes API 조회 결과 중 표시할 타입 제한 APT, OFT. 없는 경우 모두 표출
 * @param {Array} markRule API 조회 결과 중 표시할 결과 제한. 타입 제한 만으로는 안되는 경우
 * @param {boolean} complexOnly 결과에서 단지정보(APT || OFT)만 표출
 * @param {function} onSelected 검색결과 선택 시, 기본동작이 아닐 경우
 * @param {boolean} showFilter 필터 표출 여부
 * @param {boolean} showSearchHistory 최근 검색어 표출 여부
 * @param {boolean} showKeywordDeleteButon X 버튼 표출 여부
 * @param {string[]} searchHistoryTypes ['']
 * @param {boolean} fixTopOnFocus input에 focus되면 최상단 고정
 * @param {boolean} redirectToBuying Buying으로 라우팅 여부
 * @param {function} handleRedirectToOther 다른 URL로 라우팅 처리하는 function. complex_idx를 파라미터로 전달함
 *
 **/

// const VIEW_TYPE = {default: DefaultView, DualAd};
const Search = ({
  totalSearch = false,
  markTypes,
  markRule,
  complexOnly = false,
  onSelected,
  showFilter,
  showSearchHistory = true,
  showKeywordDeleteButon,
  redirectToBuying,
  handleRedirectToOther,
  placeholder,
  defaultInput = '',
  wrapperClass = '',
  inputWrapperClass,
  inputLegend,
  inputFormName,
  View = DefaultView,
  enableSearchTracking,
  disabled,
}) => {
  let props = {
    totalSearch,
    markTypes,
    complexOnly,
    showFilter,
    showKeywordDeleteButon,
    showSearchHistory,
    placeholder,
    wrapperClass,
    inputWrapperClass,
    inputLegend,
    inputFormName,
    defaultInput,
    handleRedirectToOther,
    enableSearchTracking,
    disabled,
  };

  const propsFromHook = useSearch({
    defaultInput,
    complexOnly,
    markRule,
    markTypes,
    onSelected,
    redirectToBuying,
    handleRedirectToOther,
    enableSearchTracking,
  });

  props = { ...props, ...propsFromHook };

  return (
    <>
      <View {...props} />
    </>
  );
};

export default Search;
