import React, { useState, useEffect, useRef } from 'react';
import toggle from '@/hocs/toggle';
import FilterTags from '../FilterTags';
import { TagType } from '@/services/Community';
import DualAddress, { EVERY_BUILDINGS } from '@/combinedComp/DualAddress';

const SearchComplex = ({ tags, setTags, defaultData }) => {
  const [dualAddressData, setDualAddressData] = useState(defaultData);

  const isFirstRender = useRef(true);

  // 지역 태그 업데이트
  useEffect(() => {
    if (!isFirstRender.current) {
      const address = dualAddressData?.selectedAddress; // 시군구, 동
      const complex = dualAddressData?.selectedComplex; // 단지

      let filteredTags = tags?.filter((tag) => tag.type.indexOf('ADDRESS') !== 0 && tag.type !== TagType.COMPLEX_TAG);

      const sigungu = address?.sigungu;
      const dong = address?.dong;
      const newTags = [];

      if (dong?.code) newTags.push({ type: TagType.ADDRESS_TAG, key: dong?.code, name: dong?.name });
      else if (sigungu?.code) newTags.push({ type: TagType.ADDRESS_TAG, key: sigungu?.code, name: sigungu?.name });

      if (complex?.complex_idx) {
        filteredTags = filteredTags?.filter((tag) => tag.type !== TagType.GOODS_TYPE_TAG);
        newTags.push({ type: TagType.COMPLEX_TAG, key: `${complex.complex_idx}`, name: complex.complex_name });
      }

      setTags([...filteredTags, ...newTags]);
    } else {
      isFirstRender.current = false;
    }
  }, [dualAddressData]);

  return (
    <>
      {tags.length > 0 && (
        <div className="community-filter-area">
          <FilterTags tags={tags} setTags={setTags} dualAddressData={dualAddressData} setDualAddressData={setDualAddressData} />
        </div>
      )}

      <div style={{ background: '#efefef', border: '1px solid #dbdbdb', marginTop: '7px' }}>
        <DualAddress data={dualAddressData} setData={setDualAddressData} markTypes={EVERY_BUILDINGS} />
      </div>
    </>
  );
};

export default toggle(SearchComplex);
