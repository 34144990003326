import { actions } from '@/data';
import { readNoti } from '@/data/notification/services';
import { DATE_FORMAT_TYPE, formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';
import React from 'react';
import { useDispatch } from 'react-redux';

const NotiHistoryCard = ({ historyData }) => {
  const { timestamp, title, body, data, categoryKey, isRead } = historyData;

  const dispatch = useDispatch();
  const go = () => {
    // 노티 읽음처리
    if (data) {
      const { pathname, search } = new URL(data.linkurl || data.linkUrl);
      if (!search) {
        if (isRead) {
          dispatch(actions.router.replace(pathname));
        } else {
          readNoti(categoryKey ?? 'ALL', timestamp, pathname, dispatch);
        }
      } else {
        if (isRead) {
          dispatch(actions.router.replace(pathname + search));
        } else {
          readNoti(categoryKey ?? 'ALL', timestamp, pathname + search, dispatch);
        }
      }
    } else {
      //추후개선 꼭
      readNoti(categoryKey ?? 'ALL', timestamp, '/');
    }
  };
  return (
    <>
      <a className={`noticenter-card ${!isRead ? 'unchecked' : ''}`} onClick={go}>
        <div className="content">
          <div>{title}</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{body}</div>
        </div>
        <div className="date">{formatDate(timestamp, { type: DATE_FORMAT_TYPE.B })}</div>
      </a>
    </>
  );
};

export default NotiHistoryCard;
