import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import { withRouter } from 'react-router-dom';
import { parseCallBack } from '@/services/Helper';
import styled from 'styled-components';

import CommunityPopups from '@/combinedComp/Community/components/CommunityPopups';
import InterestGoods from '@/pages/My/InterestGoods';
import PushNotificationHistory from '@/pages/My/PushNotificationHistory';
import PleaseLoginPopup from '@/components/PleaseLoginPopup';
import NoticeForShare from '@/components/NoticeForShare';
import WeekendPush from '@/pages/My/WeekendPush';
import NicknameChangePopUp from './NicknameChangePopUp';
// import BrokerageCommissionRate from '@/components/policy/BrokerageCommissionRate';
import TermPopups from './TermPopups';
import routerService from '@/services/router';
import MoreRealtyTypeServicePopup from '@/pages/Event/ServiceOpen_MoreRealtyType/Popup';
import FeeCalculator from '../FeeCalculator';

const PopupToast = lazy(() => import('@/components/PopupToast'));
// const DownloadAppPopupToast = lazy(() => import('@/components/PopupToast/extends/DownloadApp'));

/* querystring 값에 의해 제어되는 global 컴포넌트들 */
const GlobalComponents = () => {
  const dispatch = useDispatch();

  const querystring = parseCallBack();
  const isMobile = useSelector(selectors.common.isMobile);
  const isSharedUrl = window.location.search.indexOf('socialshare') > -1;
  const showPleaseLoginPopup = useSelector(selectors.common.showPleaseLoginPopup);

  const onClose = (type) => {
    dispatch(routerService.replace(`${location.pathname}${routerService.querystring.remove(type)}`));
  };

  return (
    <Suspense fallback={<></>}>
      {/* 커뮤니티 관련 팝업 */}
      <CommunityPopups />

      {querystring?.setting && <SettingWrapper isMobile={isMobile}>{querystring?.setting === 'goods' && <InterestGoods />}</SettingWrapper>}

      {/* 알림 히스토리 */}
      <PushNotificationHistory show={!!querystring.pushNotificationHistory} />

      {/* 주말푸쉬 */}
      <WeekendPush show={!!querystring.weekendPush} />

      {/* 팝업 토스트 */}
      <PopupToast />

      {/* 공유하기 URL로 접근하는 경우 팝업 */}
      {isSharedUrl ? <NoticeForShare /> : false}

      {/* 로그인이 필요한 화면 팝업 */}
      <PleaseLoginPopup show={showPleaseLoginPopup} />

      {/* 닉네임 세팅 팝업 */}
      <NicknameChangePopUp show={!!querystring.nicknameSetting} />

      {/* 이벤트 팝업 */}
      <MoreRealtyTypeServicePopup show={querystring['event'] === 'sellingTypeExpanded'} />

      {/* 중개수수료계산기 */}
      <FeeCalculator show={!!querystring.calculator} />

      {/* 이용약관들 */}
      <TermPopups show={!!querystring.terms} type={querystring.terms} isMobile={isMobile} onClose={onClose} />
    </Suspense>
  );
};
const SettingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => (props.isMobile ? 'background-color: white;' : '')}
  z-index: 1010;
`;
export default withRouter(GlobalComponents);
