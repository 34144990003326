import {
  apiClient,
  // , kakaoClient
} from '@/apis/clients';

import { objectToQuerystring } from '@/apis/helpers';
import CONSTANTS from '../../pages/Rating/constants';

export const api = {
  async postRating(payload) {
    try {
      // @todo <프론트_요청사항> idx:14
      const path = '/my/broker-evaluation';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async postRatingUrl(payload) {
    try {
      const path = '/my/broker-evaluation-url';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 로그인된 유저의 매물 평가여부 조회
  async hasBrokerEvaluationByGoodsCommonIdx(goodsCommonIdx) {
    try {
      const path = `/my/broker-evaluation?goodsCommonIdx=${goodsCommonIdx}`;
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 로그인 관계없이 매물번호/매도매수구분으로 매물 평가여부 조회
  async hasBrokerEvaluationByGoodsIdx(goodsIdx, evalType) {
    try {
      const path = `/my/broker-evaluation/evalType?goodsIdx=${goodsIdx}&evalType=${evalType}`;
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
