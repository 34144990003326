import * as AT from './actionTypes';
import Remote from '@/data/remote';

export const BROKERINFO_STATE = {
  broker_office_idx: '',
  brokerInfo: {},
  officeInfo: {},
  officeIntro: {
    officeType: 'Y',
    officeImages: [],
  },
  oldpass: '',
  newpass: '',
  resultpass: false,
  formData: {},
  corNumbers: '',
};

export default function brokerInfo(state = BROKERINFO_STATE, action = {}) {
  switch (action.type) {
    case AT.GET_BROKER_OFFICE_INFO: {
      return {
        ...state,
      };
    }
    case AT.SET_BROKER_INFO: {
      return {
        ...state,
        brokerInfo: action.brokerInfo,
      };
    }
    case AT.SET_OFFICE_INTRO: {
      return {
        ...state,
        officeIntro: action.officeIntro,
      };
    }
    case AT.SET_OFFICE_INFO: {
      return {
        ...state,
        officeInfo: action.officeInfo,
      };
    }
    case AT.SET_STAFF_LIST: {
      if (!action.delList) return { ...state };
      else
        return {
          ...state,
          officeIntro: {
            ...state.officeIntro,
            staff: state.officeIntro.staff.filter((item, key) => action.delList.indexOf(item.br_idx) < 0),
          },
        };
    }
    case AT.SET_STAFF_GUBUN: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.map((item, key) => {
            if (item.br_idx == action.index) return { ...item, br_gubun: action.gubun };
            else return { ...item };
          }),
        },
      };
    }
    case AT.SET_STAFF_CP: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.map((item, key) => {
            if (item.br_idx == action.index) return { ...item, br_cp: action.cp };
            else return { ...item };
          }),
        },
      };
    }
    case AT.SET_STAFF_NAME: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.map((item, key) => {
            if (item.br_idx == action.index) return { ...item, br_name: action.name };
            else return { ...item };
          }),
        },
      };
    }
    case AT.SET_STAFF_IMAGE: {
      console.log(action.img_url);
      console.log(action.index);
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.map((item, key) => {
            if (item.br_idx == action.index) return { ...item, br_profile_img: action.img_url };
            else return { ...item };
          }),
        },
      };
    }
    case AT.ADD_STAFF: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.concat({
            br_idx: 'a' + Math.floor(Math.random() * 101),
            br_gubun: 'SB',
            br_cp: '',
            br_name: '',
            br_profile_img: '',
            yn: 'Y',
          }),
        },
      };
    }
    case AT.DEL_STAFF_PROFILE: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.map((item, key) => {
            if (item.br_idx == action.index) return { ...item, br_profile_img: '' };
            else return { ...item };
          }),
        },
      };
    }
    case AT.DEL_STAFF: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          belong_ch: 'Y',
          staff: state.officeIntro.staff.filter((item, key) => item.br_idx != action.index),
        },
      };
    }
    case AT.SET_YOUTUBE: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          youtube: action.youtube,
        },
      };
    }
    case AT.SET_ONE_LINE: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          oneline_intro: action.oneline,
        },
      };
    }
    case AT.SET_INTRO: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          introduce: action.intro,
        },
      };
    }
    case AT.SET_EDIT_CAREER: {
      return {
        ...state,
        brokerInfo: {
          ...state.brokerInfo,
          career: action.value,
        },
      };
    }
    case AT.SET_EDIT_PHONE_NUM: {
      return {
        ...state,
        brokerInfo: {
          ...state.brokerInfo,
          mbr_cp: action.phonenum,
        },
      };
    }
    case AT.SET_PROFILE_IMAGE: {
      return {
        ...state,
        brokerInfo: {
          ...state.brokerInfo,
          mbr_image_full_path: action.img_url,
        },
      };
    }
    case AT.DEL_PROFILE_IMAGE: {
      return {
        ...state,
        brokerInfo: {
          ...state.brokerInfo,
          mbr_image_full_path: '',
        },
      };
    }
    case AT.DEL_DED_CERTI: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          deduction_certificate: '',
        },
      };
    }
    case AT.SET_DED_CERTI_IMAGE: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          deduction_certificate: action.img_url,
        },
      };
    }
    case AT.SET_OFFICE_IMAGE: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          office_img_ch: 'Y',
          office_images: state.officeIntro.office_images.concat({
            img_idx: '',
            img_type: '',
            img_full_path: action.img_url,
          }),
        },
      };
    }
    case AT.DEL_OFFICE_IMAGE: {
      return {
        ...state,
        officeIntro: {
          ...state.officeIntro,
          office_img_ch: 'Y',
          office_images: state.officeIntro.office_images.filter((item, key) => item.img_idx != action.img_idx),
        },
      };
    }

    //===================brokerInfo - BasicInfo=======================
    case AT.BROKER_PASS_CHANGE: {
      return {
        ...state,
        oldpass: action.oldpass,
        newpass: action.newpass,
        newpass_repeat: action.newpass_repeat,
      };
    }

    case AT.SET_BROKER_PASS_CHANGE_RESULT: {
      return {
        ...state,
        resultpass: action.resultpass,
      };
    }
    //====================brokerInfo - OfficeInfo====================
    case AT.SET_COR_NUMBER: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          phone: action.corNumbers,
        },
      };
    }
    case AT.CH_OFFICE_ADDRESS: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          latlng: { lat: action.lat, lng: action.lng },
          addr1: action.addr,
          law_code: action.b_dong_code,
        },
      };
    }
    case AT.SET_SELECTED_MODI_OFFICE_ADDRESS: {
      let latlng = action.addressList.find((el) => el.address == action.address) || {};
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            lat: latlng.lat,
            lng: latlng.lng,
            address: latlng.address,
            b_dong_code: latlng.b_dong_code,
          },
        },
      };
    }
    case AT.UPDATE_MY_OFFICE_ADDRESS: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            lat: action.payload.lat,
            lng: action.payload.lng,
            address: action.payload.address_name,
            b_dong_code: action.payload.b_dong_code,
          },
        },
      };
    }
    case AT.SET_SELECTED_MODI_OFFICE_ADDRESS_DETAIL: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            address_detail: action.value,
          },
        },
      };
    }
    case AT.SET_EDIT_OFFICE_TYPE: {
      return {
        ...state,
        officeInfo: { ...state.officeInfo, biz_gbn: action.officeType },
      };
    }
    case AT.SET_EDIT_OFFICE_PHONE_NUM: {
      return {
        ...state,
        officeInfo: { ...state.officeInfo, phone: action.officePhoneNum },
      };
    }
    case AT.SET_EDIT_OFFICE_LAT_LNG: {
      return {
        ...state,
        officeLatLng: action.officeLatLng,
      };
    }
    case AT.SET_EDIT_OFFICE_IMAGES: {
      return {
        ...state,
        officeImages: action.officeImages,
      };
    }
    case AT.SET_SELECTED_MODI_OFFICE: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeIdx: action.code,
          selectedOfficeInfo: state.officeList.find((el) => el.code == action.code) || {},
        },
      };
    }
    case AT.SET_OFFICE_MODI_XY: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            lat: action.latlng.lat,
            lng: action.latlng.lng,
            address: action.latlng.address,
            b_dong_code: action.latlng.b_dong_code,
          },
        },
      };
    }
    case AT.SET_OFFICE_B_DONG_CODE: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            b_dong_code: action.b_dong_code,
          },
        },
      };
    }
    case AT.DEL_MY_OFFICE_SET: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            code: '',
            name: '',
            reg_date: '',
            broker_office_name: '',
          },
        },
      };
    }
    case AT.DEL_MY_OFFICE_ADDRESS_SET: {
      return {
        ...state,
        officeInfo: {
          ...state.officeInfo,
          selectedOfficeInfo: {
            ...state.officeInfo.selectedOfficeInfo,
            address: '',
            lat: '',
            lng: '',
            address_detail: '',
            b_dong_code: '',
          },
        },
      };
    }
    case AT.GET_MY_OFFICE_LIST_LOADING:
      return { ...state, officeList: 'loading' };
    case AT.GET_MY_OFFICE_LIST_FAIL:
      return { ...state, officeList: 'fail' };
    case AT.GET_MY_OFFICE_LIST:
      return {
        ...state,
      };
    case AT.SET_OFFICE_MODI_LIST: {
      return {
        ...state,
        officeList: action.list,
      };
    }
    case AT.OFFICE_INFO_CHANGE: {
      return {
        ...state,
      };
    }

    case AT.SET_BIZ_IMAGE: {
      return {
        ...state,
        officeInfo: { ...state.officeInfo, biz_license_img: action.img_url },
      };
    }

    case AT.SET_BROKER_REG_IMAGE: {
      return {
        ...state,
        officeInfo: { ...state.officeInfo, broker_reg_license_img: action.img_url },
      };
    }

    case AT.AS_EDIT_IMAGE_ITEM: {
      switch (action.corpORopen) {
        case 0: {
          // 0 이면 사업자
          return {
            ...state,
            officeInfo: { ...state.officeInfo, biz_license_img: action.itemImageObj },
          };
        }
        case 1: {
          // 1 이면 개설
          return {
            ...state,
            officeInfo: { ...state.officeInfo, broker_reg_license_img: action.itemImageObj },
          };
        }
        case 2: {
          // 2 면 프로필
          return {
            ...state,
            officeInfo: { ...state.officeInfo, mbr_image_full_path: action.itemImageObj },
          };
        }
      }
      break;
    }
    case AT.AS_DEL_IMAGE_ITEM: {
      switch (action.corpORopen) {
        case 0: {
          // 0 이면 사업자
          return {
            ...state,
            officeInfo: { ...state.officeInfo, biz_license_img: '' },
          };
        }
        case 1: {
          // 1 이면 개설
          return {
            ...state,
            officeInfo: { ...state.officeInfo, broker_reg_license_img: '' },
          };
        }
        case 2: {
          // 2 면 프로필
          return {
            ...state,
            brokerInfo: { ...state.brokerInfo, mbr_image_full_path: '' },
          };
        }
      }
      break;
    }
    case AT.CHANGE_BROKER_INFO_DATA: {
      return {
        ...state,
      };
    }

    case AT.BROKER_SECESSION: {
      return {
        ...state,
      };
    }
    case AT.SET_INFO_SECTION: {
      return {
        ...state,
        infoSection: action.section,
      };
    }
    default: {
      return { ...state };
    }
  }
}
