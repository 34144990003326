import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as aActions from '@/data/rootActions';
import * as selectors from '../selectors';
import apis from '@/apis';

//bkQnAlist
export function* getBkQnAList() {
  try {
    const page = yield select(selectors.bkQnA.getPage);
    const keyword = yield select(selectors.bkQnA.getKeyword);
    const payload = {
      page: page,
      keyword: keyword ? keyword : '',
    };
    const response = yield call(apis.brokerPageApi.getbkQnAList, payload);
    //const { list } = response;
    yield put(actions.getbkQnAListSuccess(response.rltObj));
    yield put(actions.setTotalCnt(response.totalCnt));
    yield put(actions.setKeyword(''));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.getbkQnAListFailure(e));
  }
}
export function* getBkQnADetail({ idx }) {
  try {
    const payload = {
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.getbkQnADetail, payload);
    //const { list } = response;
    yield put(actions.getbkQnADetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.getbkQnADetailFailure(e));
  }
}
export function* saveBkQnAReply({ val, pu_idx }) {
  try {
    const payload = {
      board_idx: pu_idx,
      comment: val,
    };
    //call api
    const response = yield call(apis.brokerPageApi.savebkQnAReply, payload);
  } catch (e) {
    yield put(actions.getbkQnADetailFailure(e));
  }
}
export function* delBkQnAReply({ c_idx }) {
  try {
    const payload = {
      cmd_idx: c_idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.delbkQnAReply, payload);
  } catch (e) {
    yield put(actions.getbkQnADetailFailure(e));
  }
}
export function* editBkQnAReply({ val, c_idx }) {
  try {
    const payload = {
      cmd_idx: c_idx,
      comment: val,
    };
    //call api
    const response = yield call(apis.brokerPageApi.editbkQnAReply, payload);
  } catch (e) {
    yield put(actions.getbkQnADetailFailure(e));
  }
}
export function* saveBkQnANew() {
  try {
    const newData = yield select(selectors.bkQnA.getNew);
    const payload = {
      title: newData.title,
      contents: newData.cont,
      file_idx: newData.attach,
    };
    //call api
    const response = yield call(apis.brokerPageApi.savebkQnANew, payload);
    yield put(actions.getList());
  } catch (e) {
    yield put(actions.getbkQnADetailFailure(e));
  }
}
export function* editBkQnANew({ board_idx, title, content, setViewEditWrite }) {
  try {
    const file_idx = yield select(selectors.bkQnA.getAttach);
    const payload = {
      board_idx: board_idx,
      title: title,
      contents: content,
      file_idx: file_idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.editbkQnA, payload);
    if (response.rltObj === true) {
      yield put(aActions.common.alert({ contents: '해당 게시물을 수정하였습니다.' }));
      yield put(actions.getList());
      yield put(actions.getDetail(board_idx));
      setViewEditWrite(false);
    } else {
      yield put(
        aActions.common.alert({
          contents: (
            <>
              게시물 수정중 오류가 발생하였습니다.
              <br />
              다시시도해주세요
            </>
          ),
        })
      );
      yield put(actions.getList());
      yield put(actions.getDetail(board_idx));
    }
  } catch (e) {
    yield put(actions.getbkQnADetailFailure(e));
  }
}
export function* delBkQnANew({ pu_idx, setViewDetail }) {
  try {
    const payload = {
      board_idx: pu_idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.delbkQnA, payload);
    if (response.rltObj === true) {
      yield put(aActions.common.alert({ contents: '해당 게시물을 삭제하였습니다.' }));
      yield put(actions.getList());
      setViewDetail(false);
    } else {
      yield put(aActions.common.alert({ contents: '게시물 삭제중 오류가 발생하였습니다.</br> 다시시도해주세요' }));
      yield put(actions.getList());
    }
  } catch (e) {
    yield put(aActions.common.alert({ contents: '게시물 삭제중 오류가 발생하였습니다.<br/> 다시시도해주세요' }));
  }
}
