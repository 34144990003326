import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from '@/data';

const LoginBtn = ({ isMobile, setShowLogin }) => {
  const dispatch = useDispatch();

  const onLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isMobile) {
      dispatch(actions.router.push('/login'));
    } else {
      setShowLogin(true);
    }
  };

  return (
    <li className="navi-item">
      <Link className="navi-name" to="#" onClick={onLogin}>
        로그인
      </Link>
    </li>
  );
};

export default LoginBtn;
