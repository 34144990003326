import * as AT from '../actionTypes';
import Remote from '@/data/remote';

const INITIAL_STATE = {
  [AT.ADD_QUESTION]: Remote.NotAsked,
  [AT.REPORT_BROKER]: Remote.NotAsked,
};

export default function footer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_FAQ_LIST:
      return { ...state, faqList: action.list };
    case AT.ADD_QUESTION_LOADING:
      return { ...state, [AT.ADD_QUESTION]: Remote.Loading };
    case AT.ADD_QUESTION_SUCCESS:
      return { ...state, [AT.ADD_QUESTION]: Remote.Success(action.data) };
    case AT.ADD_QUESTION_FAILURE:
      return { ...state, [AT.ADD_QUESTION]: Remote.Failure(action.error) };
    case AT.ADD_QUESTION_NOTASKED:
      return { ...state, [AT.ADD_QUESTION]: Remote.NotAsked };
    case AT.SET_QNA_CATEGORY_LIST:
      return { ...state, qnaCategoryList: action.list };
    case AT.REPORT_BROKER_LOADING:
      return { ...state, [AT.REPORT_BROKER]: Remote.Loading };
    case AT.REPORT_BROKER_SUCCESS:
      return { ...state, [AT.REPORT_BROKER]: Remote.Success(true) };
    case AT.REPORT_BROKER_NOTASKED:
      return { ...state, [AT.REPORT_BROKER]: Remote.NotAsked };
    case AT.REPORT_BROKER_FAILURE:
      return { ...state, [AT.REPORT_BROKER]: Remote.Failure(action.error) };
    case AT.SET_MY_BROKER_LIST:
      return { ...state, myBrokerList: action.data };
    case AT.SET_SEARCH_BROKER_LIST:
      return { ...state, searchBrokerList: action.data };
    default:
      return state;
  }
}
