import React from 'react';

const ApplyBtn = ({ handleApply, selectedRadioItem }) => {
  return (
    <div className="popup-local-util">
      <div className="button-display module-a style-a type-d">
        <span className="button-area">
          <button
            type="submit"
            className="btn module-b style-a type-fill accent-01 large-1x flex"
            onClick={handleApply}
            disabled={!selectedRadioItem}>
            <span className="btn-text">적용</span>
          </button>
        </span>
      </div>
    </div>
  );
};

export default ApplyBtn;
