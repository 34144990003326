import { validate } from '../utils';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';

const useSearch = ({ inputRef, totalSearch, setShow, setKeyword }) => {
  const dispatch = useDispatch();

  const handleSearchText = (data) => {
    const value = data.target.value.trim();
    setKeyword(value);

    if (validate(value)) {
      dispatch(actions.common.searchKeyword({ keyword: value, totalSearch }));
      setShow(true);
    }
  };

  const forceSearchText = () => {
    const value = inputRef.current.value.trim();

    if (validate(value)) {
      dispatch(actions.common.searchKeyword({ keyword: value, totalSearch }));
      setShow(true);
    }
  };

  return { handleSearchText, forceSearchText };
};

export default useSearch;
