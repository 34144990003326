import openAppSetting from '../setting/openAppSetting';
import * as commonActions from '@/data/common/actions';
import React from 'react';
import { OSTYPE } from '@/services/apps/types/OsType';

const CONTENTS_BY_PRMS = {
  position: (
    <>
      위치 접근 권한이 꺼져있습니다.
      <br />
      설정에서 권한을 허용해 주세요.
    </>
  ),
  camera: (
    <>
      카메라 권한이 꺼져있습니다.
      <br />
      사진 촬영을 위해 설정에서 권한을 허용해 주세요.
    </>
  ),
};

const noticeForDeniedPrms = (prms) => (dispatch) => {
  const ostype = window.dawin_instance?.osType;
  const isApp = ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS;

  if (isApp) {
    // PERMISSION_DENIED
    const contents = CONTENTS_BY_PRMS[prms];
    dispatch(
      commonActions.confirm({
        contents,
        onConfirm: () => openAppSetting(),
        confirmButtonText: '설정',
        cancelButtonText: '취소',
      })
    );
  }
};

export default noticeForDeniedPrms;
