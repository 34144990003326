import * as AT from './actionTypes';
import Remote from '@/data/remote';
import footerReducer from './footer/reducers';

const INITIAL_STATE = {
  isMobile: window.innerWidth < 768,
  showPopup: false,
  complexAreas: {},
  [AT.SEARCH_KEYWORD]: Remote.NotAsked,
  pleaseLoginPopup: false,
  loginPopup: false,
  [AT.ADD_QUESTION]: Remote.NotAsked,
  [AT.REPORT_BROKER]: Remote.NotAsked,
  openPopup: null,
  alert: {},
  confirm: {},
  [AT.ADD_FAV_COMPLEX]: Remote.NotAsked,
  openMobileFavoBox: false,
  tempFavCompList: null, //단지 리스트 재조회 되기 전 관심단지 표시
  tempFavLocalList: null,
  tempFavGoodsList: [], //재조회 되기 전 관심매물 표시
  recommendDownloadApp: false,
  isDistrictViewOn: false,
  // 2021-02-15 정석규 추가 - default value가 없어서 에러남
  complexArea: [],
  showSpinner: false,
  appData: {},
  dynamicImportCssList: {},
  globalGallery: [],
};

export default function common(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.IS_MOBILE:
      return { ...state, isMobile: action.width < 768 }; //768
    case AT.GET_COMPLEX_AREA_SUCCESS:
      return { ...state, complexAreas: { ...state.complexAreas, [action.complex_idx]: action.list } };
    case AT.SET_COMPLEX_AREA2:
      return { ...state, complexArea: action.list };
    case AT.TOGGLE_POPUP:
      return { ...state, showPopup: !state.showPopup };
    case AT.SEARCH_KEYWORD_LOADING:
      return { ...state, [AT.SEARCH_KEYWORD]: Remote.Loading };
    case AT.SEARCH_KEYWORD_SUCCESS:
      return { ...state, [AT.SEARCH_KEYWORD]: Remote.Success(action.data) };
    case AT.SEARCH_KEYWORD_FAILURE:
      return { ...state, [AT.SEARCH_KEYWORD]: Remote.Failure(action.error) };
    case AT.PLEASE_LOGIN_POPUP:
      return { ...state, pleaseLoginPopup: action.show, pleaseLoginPopupOption: action.option };
    case AT.ACTION_AFTER_LOGIN:
      return { ...state, actionAfterLogin: action.callback };
    case AT.TOGGLE_LOGIN_POPUP:
      return { ...state, loginPopup: action.show };
    case AT.OPEN_GLOBAL_DUAL_POPUP:
      return { ...state, openPopup: action.popupType };
    case AT.CLOSE_GLOBAL_DUAL_POPUP:
      return { ...state, openPopup: null };

    case AT.ALERT:
      return {
        ...state,
        alert: {
          contents: action.contents,
          onConfirm: action.onConfirm,
          confirmButtonText: action.confirmButtonText,
          options: action.options,
        },
      };
    case AT.CLEAR_ALERT:
      return {
        ...state,
        alert: {},
      };

    case AT.CONFIRM:
      return {
        ...state,
        confirm: {
          contents: action.contents,
          onConfirm: action.onConfirm,
          onCancel: action.onCancel,
          confirmButtonText: action.confirmButtonText,
          cancelButtonText: action.cancelButtonText,
          options: action.options,
        },
      };
    case AT.CLEAR_CONFIRM:
      return {
        ...state,
        confirm: {},
      };

    case AT.ADD_FAV_COMPLEX_LOADING:
      return { ...state, [AT.ADD_FAV_COMPLEX]: Remote.Loading };
    case AT.ADD_FAV_COMPLEX_SUCCESS:
      return { ...state, [AT.ADD_FAV_COMPLEX]: Remote.Success(action.data) };
    case AT.ADD_FAV_COMPLEX_FAILURE:
      return { ...state, [AT.ADD_FAV_COMPLEX]: Remote.Failure(action.error) };
    case AT.OPEN_MOBILE_FAVO_BOX:
      return { ...state, openMobileFavoBox: action.show };

    case AT.INITIAL_UPDATE_TEMP_FAV_COMP_LIST:
      return { ...state, tempFavCompList: action.data };
    case AT.UPDATE_TEMP_FAV_COMP_LIST:
      return { ...state, tempFavCompList: { ...state.tempFavCompList, [action.complex_idx]: action.isFav } };

    case AT.INITIAL_UPDATE_TEMP_FAV_LOCAL_LIST:
      return { ...state, tempFavLocalList: action.data };
    case AT.UPDATE_TEMP_FAV_LOCAL_LIST:
      return { ...state, tempFavLocalList: { ...state.tempFavLocalList, [action.local_idx]: action.isFav } };

    case AT.SET_FAV_COMPLEX_LIST:
      return { ...state, favComplexLocList: action.data };
    case AT.UPDATE_TEMP_FAV_GOODS_LIST:
      return { ...state, tempFavGoodsList: { ...state.tempFavGoodsList, [action.goodsIdx]: action.isFav } };
    case AT.SET_CERTIFICATED_INFO:
      return { ...state, certificatedInfo: action.data };
    case AT.TOGGLE_DISTRICT_VIEW: {
      if (action.manual !== undefined) {
        // 수동 토글링 (사용자가 명시한대로 boolean값을 바꿈)
        return { ...state, isDistrictViewOn: action.manual };
      }
      // 자동 토글링 (무조건 전 state flip)
      return { ...state, isDistrictViewOn: !state.isDistrictViewOn };
    }
    case AT.TOGGLE_GLOBAL_SPINNER: {
      return { ...state, showSpinner: action.payload };
    }
    case AT.UPDATE_APP_DATA: {
      return { ...state, appData: { ...state.appData, ...action.payload } };
    }
    case AT.ADD_DYNAMIC_CSS_IMPORT: {
      return { ...state, dynamicImportCssList: { ...state.dynamicImportCssList, [action.key]: action.hrefs } };
    }
    case AT.OPEN_GLOBAL_GALLERY: {
      return {
        ...state,
        globalGallery: { ...state.globalGallery, elements: action.elements, customCaptions: action.customCaptions, options: action.options },
      };
    }
    case AT.CLOSE_GLOBAL_GALLERY:
      return { ...state, globalGallery: [] };
    default:
      return { ...state, ...footerReducer(state, action) };
  }
}
