import { parseCallBack } from '@/services/Helper';
import { useEffect } from 'react';

const useSetFeedType = ({ setFeedType }) => {
  const query = parseCallBack();

  useEffect(() => {
    const postType = query?.postType;
    if (postType) {
      setFeedType(postType);
    }
  }, [query]);

  return;
};

export default useSetFeedType;
