import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const MG_COMPLEX_IMG = {
  page: 1,
  total: 40,
  // complexImgList: [
  //   {
  //     complexIdx: 111,
  //     compelx_name: '주공1단지',
  //     images: [
  //       {
  //         img_idx: 211,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 212,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 213,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 214,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //     ],
  //   },
  //   {
  //     complexIdx: 112,
  //     compelx_name: '주공2단지',
  //     images: [
  //       {
  //         img_idx: 231,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //     ],
  //   },
  //   {
  //     complexIdx: 113,
  //     compelx_name: '주공3단지',
  //     images: [
  //       {
  //         img_idx: 221,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 222,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 223,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 224,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 225,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 226,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 227,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //       {
  //         img_idx: 228,
  //         img_full_path: 'https://dawins3data.s3.ap-northeast-2.amazonaws.com/realtordoc/ldF5c08b1b39169c144af6576679ec3b4c6813040320190004.jpg',
  //       },
  //     ],
  //   },
  //   {
  //     complexIdx: 114,
  //     compelx_name: '주공4단지',
  //     images: [],
  //   },
  // ],
};

export default function mgRate(state = MG_COMPLEX_IMG, action = {}) {
  switch (action.type) {
    case AT.GET_COMPLEX_IMG_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_COMPLEX_IMAGE: {
      return {
        ...state,
      };
    }
    case AT.DEL_COMPLEX_IMAGE: {
      // let temp = state.complexImgList;
      // let re = temp.map((item, key) => {
      //   if (item.complexIdx == action.complexIdx) {
      //     let re_image = item.images.filter((i, k) => action.img_idx != i.img_idx);
      //     return { ...item, images: re_image };
      //   } else {
      //     return item;
      //   }
      // });
      // return {
      //   ...state,
      //   complexImgList: re,
      // };
      return {
        ...state,
      };
    }
    case AT.GET_COMPLEX_BASIC_INFO: {
      return {
        ...state,
        complexIdx: action.info[0].tb_cb_complex_idx,
        complex_name: action.info[0].complex_name,
        display_address: action.info[0].display_address,
      };
    }
    case loading(AT.GET_COMPLEX_IMG_LIST):
      return { ...state, [AT.GET_COMPLEX_IMG_LIST]: Remote.Loading };
    case success(AT.GET_COMPLEX_IMG_LIST):
      return {
        ...state,
        complexImgList: action.complexImgList,
      };
    case failure(AT.GET_COMPLEX_IMG_LIST):
      return {
        ...state,
        complexImgList: '',
        complex_name: '',
        display_address: '',
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
