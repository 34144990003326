import { failure, loading, success } from '@/data/utils';

export const SET_LOGIN_STEP = 'SET_LOGIN_STEP';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_AGREE_ACTION = 'SET_AGREE_ACTION';

export const SET_USERINFO_FOR_LOGIN = 'SET_USERINFO_FOR_LOGIN';
export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = success(LOG_IN);
export const LOG_IN_FAILURE = failure(LOG_IN);
export const LOG_IN_LOADING = loading(LOG_IN);

export const DAWIN_LOGIN = 'DAWIN_LOGIN';

export const SMS = 'SMS';
export const SMS1 = 'SMS1';
export const SMS2 = 'SMS2';
export const SMS3 = 'SMS3';
export const SMS4 = 'SMS4';
export const SMS_SUCCESS = success(SMS);
export const SMS_FAILURE = failure(SMS);
export const SMS_LOADING = loading(SMS);
export const SET_ID_CANDIDATES = 'SET_ID_CANDIDATES';

export const SOCIAL_SIGNIN = 'SOCIAL_SIGNIN';

export const CHANGE_CELLPHONENUMBER = 'CHANGE_CELLPHONENUMBER'; // 휴대폰번호변경
export const CHANGE_CELLPHONENUMBER_MATCH = 'CHANGE_CELLPHONENUMBER_MATCH'; // 휴대폰번호변경 -> 인증

export const SOCIAL = 'SOCIAL';
export const SOCIAL_SUCCESS = success(SOCIAL);
export const SOCIAL_FAILURE = failure(SOCIAL);
export const SOCIAL_LOADING = loading(SOCIAL);
export const SOCIAL1 = 'SOCIAL1';
export const SOCIAL2 = 'SOCIAL2';

export const CHECK_EMAIL = 'CHECK_EMAIL';
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';

export const PHONE_NUMBER_AUTH_ONLY = 'PHONE_NUMBER_AUTH_ONLY';

export const LOG_OUT = 'LOG_OUT';

export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = success(CHECK_AUTH);
export const CHECK_AUTH_FAILURE = failure(CHECK_AUTH);
export const CHECK_AUTH_LOADING = loading(CHECK_AUTH);

export const RESET_AUTH = 'RESET_AUTH';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = success(SIGNUP);
export const SIGNUP_FAILURE = failure(SIGNUP);
export const SIGNUP_LOADING = loading(SIGNUP);

export const AGENT_SIGNUP = 'AGENT_SIGNUP';
export const AGENT_SIGNUP_SUCCESS = success(AGENT_SIGNUP);
export const AGENT_SIGNUP_FAILURE = failure(AGENT_SIGNUP);
export const AGENT_SIGNUP_LOADING = loading(AGENT_SIGNUP);
export const AGENT_SIGNUP_RESET_ERROR = 'AGENT_SIGNUP_RESET_ERROR';

export const SET_USER_SESSION = 'SET_USER_SESSION';

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';

export const PAGE_TO = 'PAGE_TO';

export const FIND_ID_1 = 'FIND_ID_1';
export const FIND_ID_1_SUCCESS = success('FIND_ID_1');
export const FIND_ID_1_FAILURE = failure('FIND_ID_1');
export const FIND_ID_1_LOADING = loading('FIND_ID_1');
export const FIND_ID_1_NOTASKED = 'FIND_ID_1_NOTASKED';

export const FIND_ID_2 = 'FIND_ID_2';
export const FIND_ID_2_SUCCESS = success('FIND_ID_2');
export const FIND_ID_2_FAILURE = failure('FIND_ID_2');
export const FIND_ID_2_LOADING = loading('FIND_ID_2');
export const FIND_ID_2_NOTASKED = 'FIND_ID_2_NOTASKED';

export const FIND_PW = 'FIND_PW';
export const FIND_PW_SUCCESS = success('FIND_PW');
export const FIND_PW_FAILURE = failure('FIND_PW');
export const FIND_PW_LOADING = loading('FIND_PW');
export const FIND_PW_NOTASKED = 'FIND_PW_NOTASKED';

export const CHANGE_PW = 'CHANGE_PW';
export const CHANGE_PW_SUCCESS = success('CHANGE_PW');
export const CHANGE_PW_FAILURE = failure('CHANGE_PW');
export const CHANGE_PW_LOADING = loading('CHANGE_PW');
export const CHANGE_PW_NOTASKED = 'CHANGE_PW_NOTASKED';

export const SET_APP_INFO = 'SET_APP_INFO'; //state 저장
export const TOGGLE_ALARM_ON = 'TOGGLE_ALARM_ON';

export const BYE_DAWIN = 'BYE_DAWIN';
export const APP_SETTING = 'APP_SETTING';
export const DISCONNECT_NAVER = 'DISCONNECT_NAVER'; //네이버 로그인 연동 철회

// 닉네임
export const GET_NICK_NAME = 'GET_NICK_NAME'; // 조회
export const SET_NICK_NAME = 'SET_NICK_NAME'; // 설정
export const SAVE_NICK_NAME = 'SAVE_NICK_NAME'; // 설정

// 마케팅 URL 접근 유저 정보
export const SET_MARKETING_USER_INFO = 'SET_MARKETING_USER_INFO';

// 회원가입 유저
export const IS_SIGN_UP_USER = 'IS_SIGN_UP_USER';
