import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions, selectors as allSelectors } from '@/data';
import * as selectors from './selectors';
import { router } from '@/data/rootActions';
import apis from '@/apis';
import text from '@/resources/text';
import global from '@/resources/global';
import { STATUS } from '@/data/utils';
import { createNewGood, getInfoAndTrimData, updateGoodStatus } from './services';
import goodsService from '@/services/goods';

// 매물 생성
export function* createGoods({ savedForm, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });

    // 매물 등록
    let response = yield call(apis.sellingApi.createGoods, savedForm);
    // 등록한 정보로 store update
    yield put(actions.updateSavedForm(response));
    yield put(actions.updateLastSavedForm(response));

    const EXCEPTIONS = [goodsService.realtyTypes.codes.sto, goodsService.realtyTypes.codes.off];

    if (!EXCEPTIONS.includes(savedForm.realty_type)) {
      // 등기부등본 부동산 고유번호 등록
      try {
        const result = yield call(apis.sellingApi.updateGoodsRegisteredId, response.goods_common_idx);
      } catch {
        yield put(
          allActions.common.confirm({
            contents: '등록 요청하신 주소가 등기부등본 시스템에서 조회되지 않습니다. 다시한번 주소를 확인해주세요.',
            cancelButtonText: '주소확인',
            onCancel: () => {
              document.getElementById('sellingManual_address')?.scrollIntoView();
            },
            confirmButtonText: '다음',
            onConfirm: () => {
              setStatus && setStatus({ status: STATUS.SUCCESS, data: response });
            },
          })
        );
        setStatus && setStatus({ status: STATUS.NOTASKED });
        return;
      }
    }
    setStatus && setStatus({ status: STATUS.SUCCESS, data: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

// 매물 재등록
export function* createReRegisterGoods({ goods_common_idx, goods_name, dispatch, setMySellList, callback }) {
  try {
    setMySellList && setMySellList({ status: STATUS.LOADING });

    const trimData = yield getInfoAndTrimData({ goods_common_idx, goods_name });

    const newGoodData = yield createNewGood({ trimData, goods_common_idx });

    // 거래완료된 매물이 재등록될 때, 거래완료 탭에도 남아있도록 주석처리함
    // yield call(apis.sellingApi.deleteGoods, goods_common_idx);

    yield updateGoodStatus({ newGoodData, dispatch, setMySellList });

    callback && callback();
  } catch (error) {
    setMySellList && setMySellList({ status: STATUS.FAILURE });
    yield put(
      allActions.common.alert({
        contents: error.message,
        onConfirm: () => {
          dispatch(allActions.my.setCurrentTab('IN'));
        },
      })
    );
  }
}

// 매물 생성
export function* updateGoods({ goods_common_idx, savedForm, setStatus, callback }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });

    const response = yield call(apis.sellingApi.updateGoods, goods_common_idx, savedForm);

    // 등록한 정보로 store update
    yield put(actions.updateSavedForm(response));
    yield put(actions.updateLastSavedForm(response));
    if (callback) callback();

    setStatus && setStatus({ status: STATUS.SUCCESS, data: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

export function* getGoods({ goods_common_idx, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    let response = yield call(apis.sellingApi.getGoods, goods_common_idx);
    yield put(actions.storeSavedForm(response));
    yield put(actions.updateLastSavedForm(response));
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

export function* getGoodsMemo({ goods_common_idx, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    let response = yield call(apis.sellingApi.getGoodsMemo, goods_common_idx);
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

// 매물을 중개할 수 있는 중개사 리스트 조회 (위치기반)
export function* getBrokersByGoodsPosition({ goods_common_idx, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    let response = yield call(apis.sellingApi.getBrokersByGoodsPosition, goods_common_idx);
    yield put(actions.updateSavedForm(response));
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

// 매물의 중개사 리스트 조회
export function* getBrokersByGoods({ goods_common_idx, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    let response = yield call(apis.sellingApi.getBrokersByGoods, goods_common_idx);
    yield put(actions.updateSavedForm(response));
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

export function* getMembereshipBrokersByGoods({ goods_common_idx, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    let response = yield call(apis.sellingApi.getMembershipBrokersByGoods, goods_common_idx);
    yield put(actions.updateSavedForm(response));
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

export function* getDawinCareInfoByGoods({ goods_common_idx, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    let response = yield call(apis.sellingApi.getDawinCareInfoByGoods, goods_common_idx);
    yield put(actions.updateSavedForm(response));
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

// ===================== 요청_전화로 집내놓기 ===================== //
export function* sellCallRequest({ payload }) {
  try {
    yield put(actions.sellCallRequestLoading());
    yield call(apis.sellingApi.sellCallRequest, payload);
    yield put(actions.sellCallRequestSuccess());
  } catch (e) {
    yield put(actions.sellCallRequestFailure(text.sellingText.sellingTextCommon.SELL_REQUEST_CALL_ERROR));
  }
}

export function* initAndGoFirstStep() {
  try {
    let data = yield call(apis.sellingApi.handleManualInitialStep);
    if (data) {
      yield put(router.push('/selling/selling_manual/1'));
    } else {
      yield put(actions.sellCallRequestFailure('임시저장 데이터를 초기화 하는데 실패했습니다.'));
    }
  } catch {
    yield put(actions.sellCallRequestFailure('임시저장 데이터를 초기화 하는 중 오류가 발생하였습니다.'));
  }
}

// ===================== 요청_직접 집내놓기 ===================== //
/*
***** Dawin 2.3 UPDATE *****
Step구분 없이 한 번에 처리

*/
export function* getManualStepData({ currentStep }) {
  try {
    const { category, lng, lat, tb_cb_complex_idx } = yield select(selectors.getItemInfo);

    // 메인에서 직접 집내놓기 넘어올 때 이전 작업 내용을 확인할 때는 category 정보가 없기 때문에, APT 로 임의로 설정 후 요청 보낸다.
    // 이후 step 1~4 에 진입할 때는 itemInfo 에 category 가 지정된 상태이기 때문에, 지정된 대로 입력되어 전달됨
    let payload = {
      category: category,
      step: currentStep,
    };

    yield put(actions.getManualStepDataLoading());
    let data = yield call(apis.sellingApi.getManualStepData, payload);
    const { last_category, last_step } = data;

    /** @comment
     * 최초에는 category가 없음.
     * 이런 경우 /goods/step01에서 last_category와 last_step를 응답으로 보냄
     * 해당 정보를 사용해서 재조회 **/
    if (!category && last_category) {
      var laststepNum = last_step.toString().replace('step_', '');
      if (currentStep == laststepNum) {
        payload = {
          category: last_category,
          step: laststepNum,
        };
        data = yield call(apis.sellingApi.getManualStepData, payload);
      } else {
        //해당 페이지로 라우팅해주면 사이드이펙트로 데이터 조회함
        yield put(router.replace('/selling/selling_manual/' + laststepNum));
      }
    }

    //4단계 realtorselected 리스트로 변경
    if (currentStep === 4) {
      let realtorselected = data?.realtorselected?.split(',');
      if (realtorselected) {
        yield put(actions.setSelectedAgents(realtorselected));
      }
      data.realtorselected = realtorselected;
    }

    yield put(actions.getManualStepDataSuccess(data));

    //step1 호출
    if (currentStep === 2 || (currentStep === 4 && !(lng && lat && tb_cb_complex_idx))) {
      //step2 일때: 전세/월세인 경우 반려동물 옵션 표출
      //stpe4 일때: 매물 위치를 가져와야 함
      payload = {
        category: last_category,
        step: 1,
      };
      data = yield call(apis.sellingApi.getManualStepData, payload);
      yield put(actions.getManualStepDataSuccess(data));
    }
  } catch (e) {
    yield put(actions.getManualStepDataFailure(text.sellingText.sellingTextCommon.SELL_REQUEST_GET_PAGE_DATA_ERROR));
  }
}

// [[Dawin 2.3]]-----------------------------------------------------------
//좀 더 생각해보기...
export function* getSellingInfo({ payload }) {
  const { showSpinner, goods_common_idx, stepId, prepareAlertPayload } = payload;

  const pathname = yield select((state) => state?.router?.location?.pathname);
  // 현재 위치가 /selling 일 때만 history push
  const shouldPushHistory = /^\/(selling|myPage.*|brokerPage.*)$/.test(pathname);

  const sellingData = yield select(selectors.getSellingData);

  if (sellingData) {
    // client-side data exists
    if (prepareAlertPayload) {
      yield put(allActions.common.alert(prepareAlertPayload(sellingData.most_recent_step)));
    }
  } else {
    // db 호출 필요
    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(true));
    }

    let response = yield call(apis.sellingApi.getGoods, goods_common_idx);

    if (!response) {
      // 임시저장된 매물제출서가 없음
      // if (shouldPushHistory) {
      //   yield call(apis.sellingApi.createSavedForm, mbr_idx, {
      //     reg_mbr_name: userInfo?.mbr_name,
      //   });
      //   response = yield call(apis.sellingApi.updateSavedForm, mbr_idx, {
      //     most_recent_step: 1,
      //   });
      //   yield put(actions.storeSavedForm(response));
      //   const pushURL = path ? `${path}/selling_manual/1` : `/selling/selling_manual/1`;
      //   yield put(allActions.router.push(pushURL));
      // } else {
      //   // 집내놓기 상세스텝에서 새로고침 될 때 (!= step5).
      //   if (Number.parseInt(stepId) !== 5 && stepId !== 'edit') {
      //     const pushURL = path ? `${path}` : `/selling`;
      //     yield put(allActions.router.push(pushURL));
      //   }
      // }
      // if (showSpinner) {
      //   yield put(allActions.common.toggleGlobalSpinner(false));
      // }
      /*
      데이터가 없다? goods_idx가 잘못되었거나 => 애초에 myPAge에서 노출되지 않을것
      따라서 첫 등록? 아니..
      */
    } else {
      // 임시저장된 매물제출서가 존재
      // 이미지 조회
      const { category } = response;

      if (category) {
        const imageResponse = yield call(apis.sellingApi.readSavedFormImage, mbr_idx, category);
        response.images = imageResponse;
      }

      yield put(actions.storeSavedForm(response));

      if (showSpinner) {
        yield put(allActions.common.toggleGlobalSpinner(false));
      }

      if (prepareAlertPayload) {
        yield put(allActions.common.alert(prepareAlertPayload(response.most_recent_step)));
      }
    }
  }
}

export function* postSavedForm({ savedForm }) {
  // savedForm?.goods =
  // console.log(savedForm, 'POST SAVED FORM!!');
  const fixed_goods = savedForm?.goods.map((g) => {
    if (g.trade_type !== 'm') {
      return g;
    } else {
      return { trade_type: g.trade_type, price2: g.price3, price3: g.price4 };
    }
  });
  savedForm.goods = fixed_goods;
  let response = yield call(apis.sellingApi.createGoods, savedForm);
  console.log(response);
}

// [[Dawin 2.3]]-----------------------------------------------------------

// [[NEW LOGIC]]-----------------------------------------------------------

export function* handleManualNextStep({ currentStep, isTemp }) {
  currentStep = parseInt(currentStep);
  try {
    yield put(actions.handleManualNextStepLoading());
    const itemInfo = yield select(selectors.getItemInfo);
    const {
      // step1
      category,
      reg_type,
      owner_cp,
      reg_mbr_name,
      sido_code,
      sigungu_code,
      dong_code,
      dongnm,
      lat,
      lng,
      dong,
      floor,
      total_floor,
      ho,
      tb_cb_complex_idx,
      area1,
      area2,
      area_selected,
      trade_type,
      price1,
      price2,
      price3,
      price4,
      contractimg,
      susuryo_agree,
      certificated,

      // step2
      animal,
      balcony,
      expense,
      options,
      room_cnt,
      direction,
      heat_type,
      enter_date,
      enter_type,
      owner_live,
      bathroom_cnt,
      expense_item,
      parking_flag,
      elevator_flag,
      goods_feature,
      owner_comment,
      goods_feature_etc,
      room_type,
      // step4
      agency_open_flag,
      realtorselected,
    } = itemInfo;

    let payload = {
      step: `step_${currentStep}`,
      istmp: isTemp,
      category: category,
    };
    switch (currentStep) {
      case 1: {
        payload = {
          step: 'step_1',
          istmp: isTemp,
          category,
          reg_type,
          owner_cp,
          reg_mbr_name,
          sido_code,
          sigungu_code,
          dong_code,
          lat,
          lng,
          dong,
          floor,
          total_floor,
          ho,
          dongnm,
          tb_cb_complex_idx,
          area1,
          area2,
          area_selected,
          trade_type,
          price1,
          price2,
          price3,
          price4,
          contractimg,
          susuryo_agree,
          room_type,
          certificated,
        };
        break;
      }
      case 2: {
        payload = {
          step: 'step_2',
          istmp: isTemp,
          category,
          animal,
          balcony,
          expense,
          options,
          room_cnt,
          direction,
          heat_type,
          enter_date,
          enter_type,
          owner_live,
          bathroom_cnt,
          expense_item,
          parking_flag,
          elevator_flag,
          goods_feature,
          owner_comment,
          goods_feature_etc,
        };
        break;
      }
      case 3: {
        payload = {
          step: `step_3`,
          istmp: isTemp,
          category: category,
        };
        break;
      }
      case 4: {
        const realtorStr = realtorselected ? realtorselected.join(',') : '';
        payload = {
          step: 'step_4',
          istmp: isTemp,
          category: category,
          agency_open_flag,
          realtorselected: realtorStr.indexOf(',') === 0 ? realtorStr.replace(',', '') : realtorStr,
        };
        break;
      }
    }

    if (currentStep === 4 && isTemp === global.MANUAL_REQUEST_STATE_CODE.TEMP) {
      yield call(apis.sellingApi.handleManualLastStep, payload);
    } else {
      yield call(apis.sellingApi.handleManualNextStep, payload);
    }

    yield put(actions.handleManualNextStepSuccess());
  } catch (e) {
    let errMessage = text.sellingText.sellingTextCommon.SELL_REQUEST_MANUAL_ERROR; //일반오류
    if (e.message == 'Duplicated') {
      errMessage = text.sellingText.sellingTextCommon.SELL_REQUEST_DULPLICATED_ERROR; //중복
    } else if (isTemp == global.MANUAL_REQUEST_STATE_CODE.TEMP) {
      errMessage = text.sellingText.sellingTextCommon.SELL_REQUEST_TEMP_ERROR; //임시저장
    }
    yield put(actions.handleManualNextStepFailure(errMessage));
  }
}

// @todo loading state 넣은 형태로 변경 필요
// ===================== 직접 매물등록 =====================
// ===================== Step 1 =====================
// ===================== 단지 목록 받기 && 단지 선택 && 단지 정보 , 위치 정보 ===================== //
export function* getComplexList({ payload = {} }) {
  const { showSpinner } = payload;
  try {
    const data = {
      dong_code: yield select(selectors.getDongRiCode),
    };
    yield put(actions.getComplexListLoading());

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }
    const response = yield call(apis.sellingApi.getComplexList, data);
    const { list } = response;

    yield put(actions.getComplexListSuccess(list));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getComplexListFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_LIST_ERROR));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getComplexLocation() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const complexIdx = yield itemInfo.tb_cb_complex_idx;

    if (complexIdx) {
      const payload = {
        complexIdx: complexIdx,
      };

      yield put(actions.getComplexLocationLoading());

      const response = yield call(apis.sellingApi.getComplexLocation, payload);
      const { complexData } = response;

      // 위치 받아오면, 직접 집내놓기 내에서 사용되는 지도의 중심점도 설정
      yield put(actions.changeMapCenter(complexData));
      yield put(actions.getComplexLocationSuccess(complexData));
      yield put(actions.getManualStepDataSuccess(complexData));
    } else {
      yield put(actions.changeMapCenter(null));
      yield put(actions.getComplexLocationSuccess(null));
    }
  } catch (e) {
    yield put(actions.getComplexLocationFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_LOCATION_ERROR));
  }
}

export function* getComplexLocation2() {
  try {
    const savedForm = yield select(selectors.getSavedForm);
    const complexIdx = savedForm?.tb_cb_complex_idx;

    if (complexIdx) {
      const payload = {
        complexIdx: complexIdx,
      };

      yield put(actions.getComplexLocationLoading());

      const response = yield call(apis.sellingApi.getComplexLocation, payload);
      const { complexData } = response;

      // 위치 받아오면, 직접 집내놓기 내에서 사용되는 지도의 중심점도 설정
      yield put(actions.changeMapCenter(complexData));
      yield put(actions.getComplexLocationSuccess(complexData));
      // new logic
      yield put(
        actions.updateSavedForm({
          tb_cb_complex_idx: complexData.complex_idx,
          lat: complexData.lat,
          lng: complexData.lng,
        })
      );
    } else {
      yield put(actions.changeMapCenter(null));
      yield put(actions.getComplexLocationSuccess(null));
    }
  } catch (e) {
    yield put(actions.getComplexLocationFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_LOCATION_ERROR));
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------

// ============================ 단지 상세 정보 ============================ //
export function* getAddrSiDo({ only_available, only_broker, payload = {} }) {
  const { showSpinner } = payload;
  try {
    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(true));
    }
    const { list } = yield call(apis.sellingApi.getAddrSiDo, only_available, only_broker);
    if (!list) {
      yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
      yield put(actions.setAddrSiDo([]));
    } else {
      yield put(actions.setAddrSiDo(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getAddrSiGunGu({ sido_code, only_available, only_broker, payload = {} }) {
  const { showSpinner } = payload;

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.sellingApi.getAddrSiGunGu, {
      si_do_code: sido_code ? sido_code : yield select(selectors.getSiDoCode),
      only_available,
      only_broker,
    });

    if (!list) {
      yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
      yield put(actions.setAddrSiGunGu([]));
    } else {
      yield put(actions.setAddrSiGunGu(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getAddrDongRi({ si_gun_gu_code, only_available, only_broker, payload = {} }) {
  const { showSpinner } = payload;

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.sellingApi.getAddrDongRi, {
      si_gun_gu_code: si_gun_gu_code ? si_gun_gu_code : yield select(selectors.getSiGunGuCode),
      only_available,
      only_broker,
    });
    if (!list) {
      yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
      yield put(actions.setAddrDongRi([]));
    } else {
      yield put(actions.setAddrDongRi(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getComplexDongList() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const complexIdx = yield itemInfo.tb_cb_complex_idx; //select(selectors.getComplexIdx);
    if (complexIdx) {
      const payload = { tb_cb_complex_idx: complexIdx };
      yield put(actions.getComplexDongListLoading());
      const response = yield call(apis.sellingApi.getComplexDongList, payload);
      const { list } = response;
      yield put(actions.getComplexDongListSuccess(list));
    } else {
      yield put(actions.getComplexDongListSuccess(false));
    }
  } catch (e) {
    yield put(actions.getComplexDongListFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_DETAIL_ERROR));
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------
export function* getComplexDongList2({ payload = {} }) {
  const { showSpinner } = payload;

  try {
    const savedForm = yield select(selectors.getSavedForm);
    const complexIdx = savedForm?.tb_cb_complex_idx;

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    if (complexIdx) {
      const payload = { tb_cb_complex_idx: complexIdx };
      yield put(actions.getComplexDongListLoading());
      const response = yield call(apis.sellingApi.getComplexDongList, payload);
      const { list } = response;
      yield put(actions.getComplexDongListSuccess(list));
    } else {
      yield put(actions.getComplexDongListSuccess(false));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getComplexDongListFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_DETAIL_ERROR));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------

export function* getFloorList() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const tb_cb_complex_idx = yield select(selectors.getComplexIdx);
    const dong = itemInfo.dong;
    const data = {
      tb_cb_complex_idx: tb_cb_complex_idx,
      complex_dong: dong,
    };

    if (tb_cb_complex_idx && dong) {
      const result = yield call(apis.sellingApi.getFloorList, data);
      if (!result?.list) {
        yield put(actions.setFloorList(false));
      } else {
        const list = result?.list;
        yield put(actions.setFloorList(list));

        const total_floor_no = itemInfo.total_floor;
        if (list.length > 0 && !total_floor_no) {
          // total_floor_no가 있는 경우 업데이트 X, 사용자가 이미 변경한 데이터일 수 있음
          yield put(actions.changeItemInfo('total_floor', list[list.length - 1].floor));
        }
      }
    } else {
      yield put(actions.setFloorList(false));
    }
  } catch (e) {
    // yield put(
    //   actions.sellRequestError({
    //     error: text.sellingText.sellingTextCommon.GET_COMPLEX_DETAIL_ERROR,
    //   })
    // );
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------
export function* getFloorList2({ payload = {} }) {
  const { showSpinner } = payload;
  try {
    const savedForm = yield select(selectors.getSavedForm);
    const complexIdx = savedForm?.tb_cb_complex_idx;
    const dong = savedForm?.goods_dong;

    const data = {
      tb_cb_complex_idx: complexIdx,
      complex_dong: dong,
    };

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    if (complexIdx && dong) {
      const result = yield call(apis.sellingApi.getFloorList, data);
      if (!result?.list) {
        yield put(actions.setFloorList(false));
      } else {
        const list = result?.list;
        yield put(actions.setFloorList(list));

        // const total_floor_no = itemInfo[CONSTANTS.TARGET_DATA_KEY_TOTAL_FLOOR_NO];
        const total_floor_no = savedForm?.ground_floor;

        if (list.length > 0 && !total_floor_no) {
          // total_floor_no가 있는 경우 업데이트 X, 사용자가 이미 변경한 데이터일 수 있음
          // yield put(actions.changeItemInfo(CONSTANTS.TARGET_DATA_KEY_TOTAL_FLOOR_NO, list[list.length - 1].floor));
          yield put(
            actions.updateSavedForm({
              ground_floor: list[list.length - 1].floor,
            })
          );
        }
      }
    } else {
      yield put(actions.setFloorList(false));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    // yield put(
    //   actions.sellRequestError({
    //     error: text.sellingText.sellingTextCommon.GET_COMPLEX_DETAIL_ERROR,
    //   })
    // );

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------

export function* getHoList() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const tb_cb_complex_idx = yield select(selectors.getComplexIdx);
    const complex_dong = yield itemInfo.dong;
    const complex_floor = yield itemInfo.floor;
    if (tb_cb_complex_idx && complex_dong && complex_floor) {
      const data = { tb_cb_complex_idx, complex_dong, complex_floor };
      const { list } = yield call(apis.sellingApi.getHoList, data);
      yield put(actions.setHoList(list));
    } else {
      yield put(actions.setHoList(false));
    }
  } catch (e) {
    // yield put(
    //   actions.sellRequestError({
    //     error: text.sellingText.sellingTextCommon.GET_COMPLEX_DETAIL_ERROR,
    //   })
    // );
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------
export function* getHoList2({ payload = {} }) {
  const { showSpinner } = payload;

  try {
    const savedForm = yield select(selectors.getSavedForm);
    const tb_cb_complex_idx = savedForm?.tb_cb_complex_idx;
    const complex_dong = savedForm?.goods_dong;
    const complex_floor = savedForm?.goods_floor;

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }
    // if (tb_cb_complex_idx && complex_dong && complex_floor) {
    if (tb_cb_complex_idx && complex_dong) {
      const data = { tb_cb_complex_idx, complex_dong, complex_floor };
      const { list } = yield call(apis.sellingApi.getHoList, data);
      yield put(actions.setHoList(list));
    } else {
      yield put(actions.setHoList(false));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    // yield put(
    //   actions.sellRequestError({
    //     error: text.sellingText.sellingTextCommon.GET_COMPLEX_DETAIL_ERROR,
    //   })
    // );

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------

// ===================== Step 3 ===================== //
export function* saveImage({ inout, key, img_full_pateh, idxSetter }) {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const data = {
      acttype: 'S',
      category: itemInfo.category,
      inout,
      key,
      img_full_pateh,
    };
    const result = yield call(apis.sellingApi.saveImage, data);
    idxSetter(result.rlt);
    yield put(actions.saveIamgeSuccess());
  } catch {
    //저장실패
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------
export function* saveImage2({ inout, key, img_full_pateh, idxSetter, userInfo, payload = {} }) {
  const { showSpinner } = payload;

  try {
    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(true));
    }

    const savedForm = yield select(selectors.getSavedForm);
    const data = {
      acttype: 'S',
      category: savedForm.category,
      inout,
      key,
      img_full_pateh,
    };
    const result = yield call(apis.sellingApi.saveImage, data);
    idxSetter(result.rlt);

    const imageResponse = yield call(apis.sellingApi.readSavedFormImage, userInfo.mbr_idx, savedForm.category);

    yield put(actions.updateSavedForm({ images: imageResponse }));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch {
    //저장실패
    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------
export function* deleteImage({ inout, tmpIdx, imgSetter, idxSetter }) {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const data = {
      acttype: 'D',
      category: itemInfo.category,
      inout,
      tmpIdx,
    };
    yield call(apis.sellingApi.saveImage, data);
    imgSetter(null); //삭제되면 미리보기 제거
    idxSetter(null);
    yield put(actions.deleteImageSuccess());
  } catch {
    //저장실패
  }
}

// Step 4
export function* getNearbyAgents() {
  try {
    const { lng, lat } = yield select(selectors.getItemInfo);
    const payload = yield {
      cu_lat: lat,
      cu_lng: lng,
    };

    yield put(actions.getNearybyAgentsLoading());

    const response = yield call(apis.sellingApi.getNearbyAgents, payload);
    yield put(actions.getNearybyAgentsSuccess(response?.rlt));
  } catch (e) {
    yield put(actions.getNearybyAgentsFailure(text.sellingText.sellingTextCommon.GET_NEARBY_AGENTS_ERROR));
  }
}
