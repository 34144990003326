export const PERMISSION_TYPE = {
  GRANTED: 'granted',
  DENIED: 'denied',
  PERMANENTLY_DENIED: 'permanentlyDenied',
};

export const getPermissionAsBoolean = (type) => {
  const map = {
    [PERMISSION_TYPE.GRANTED]: true,
    [PERMISSION_TYPE.DENIED]: false,
    [PERMISSION_TYPE.PERMANENTLY_DENIED]: false,
  };

  return map[type];
};
