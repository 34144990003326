import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

const useSetTool = (data, postType) => {
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const loginUserId = userInfo?.mbr_idx; //number type

  const [writerId, setWriterId] = useState(); //string type
  const [postId, setPostId] = useState(); //string type
  const [postStatus, setPostStatus] = useState();

  useEffect(() => {
    setWriterId(data?.writer?.id);
    setPostId(data?.id);
    setPostStatus(data?.status);
  }, [data]);

  return { loginUserId, writerId, postId, postType, postStatus };
};

export default useSetTool;
