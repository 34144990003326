import { OSTYPE } from '../apps/types/OsType';

export const appLogEvent = ({ key, data = {} }) => {
  const osType = window.dawin_instance.osType;
  try {
    if (osType === OSTYPE.APP_ANDROID || osType === OSTYPE.APP_IOS) {
      window.dawin_instance.bridge?.loggingFunction.sendCustomLog(key, data);
    }
  } catch (e) {
    console.log(e.message);
  }
};
