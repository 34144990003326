import { OSTYPE } from '@/services/apps/types/OsType';
import { getPermissionAsBoolean } from '@/services/apps/types/PermissionType';

export default async () => {
  const ostype = window?.dawin_instance?.osType;

  try {
    if (ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS) {
      // iOS는 카메라에 접근시 자체적으로 권한 체크와 허용 절차를 밟음
      const cameraPermission = await window.dawin_instance.bridge.permissionFunction.getCameraPermissionState();

      if (getPermissionAsBoolean(cameraPermission)) {
        return cameraPermission;
      } else {
        //"denied", "permanentlyDenied"
        return await window.dawin_instance.bridge.permissionFunction.requestCameraPermission();
      }
    } else if (ostype === OSTYPE.DESKTOP || ostype === OSTYPE.WEB_ANDROID || (ostype === OSTYPE) === OSTYPE.WEB_IOS) {
      // 권한이 필요없음.
    } else {
      throw Error(`OS 타입 오류! ${JSON.stringify({ ostype })}`);
    }
  } catch (e) {
    // alert(e.message);
    // 앱 업데이트하기 전, 네이티브 getCameraPrms 함수가 없는 경우
    // throw Error(e.message);
  }
};
