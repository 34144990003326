import { useEffect } from 'react';

function useDocumentEvent(type, handler, options) {
  useEffect(() => {
    document.addEventListener(type, handler, options);
    return () => {
      document.removeEventListener(type, handler);
    };
  });
}

export default useDocumentEvent;
