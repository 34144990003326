import React from 'react';

const Images = ({ feedData, detail_item }) => {
  const imageData = feedData?.images;

  return !detail_item || (detail_item.feedId && feedData?.images.length > 0)
    ? imageData?.map((image, index) => (
        <p key={index}>
          <img src={image?.url} alt="" />
        </p>
      ))
    : false;
};

export default Images;
