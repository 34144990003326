import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import apis from '@/apis';

//Ratelist
export function* getComplexImgList({ complexIdx }) {
  try {
    const payload = {
      complexIdx: complexIdx ? complexIdx : '',
    };
    //call api
    const response = yield call(apis.brokerPageApi.getComplexImgList, payload);
    const { list } = response;
    const { info } = response;
    if (info) yield put(actions.getCpBasicInfo(info));
    if (list) {
      yield put(actions.getComplexImgListSuccess(list));
    } else {
      yield put(actions.getComplexImgListSuccess(''));
    }
  } catch (e) {
    yield put(actions.getComplexImgListFailure(e));
  }
}
export function* uploadComplexImage({ complexIdx, fullurl, file_name, content_type }) {
  try {
    const payload = {
      complexIdx,
      img_url: fullurl,
      file_name,
      content_type,
    };
    //call api
    const response = yield call(apis.brokerPageApi.uploadComplexImage, payload);
    if (response.rltObj) {
      const complex_idx = yield select(selectors.mgComplexImg.getSelComplexIdx);
      yield put(actions.getComplexImgList(complex_idx));
    } else {
      yield put(allActions.common.alert({ contents: '사진 업로드 중에 오류가 발생했습니다.' }));
    }
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}
export function* delComplexImage({ complex_upload_img_idx }) {
  try {
    const payload = {
      complex_upload_img_idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.delComplexImage, payload);
    if (response.rltObj) {
      const complex_idx = yield select(selectors.mgComplexImg.getSelComplexIdx);
      yield put(actions.getComplexImgList(complex_idx));
    } else {
      yield put(allActions.common.alert({ contents: '사진 삭제하는 중에 오류가 발생했습니다.' }));
    }
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}
