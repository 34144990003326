import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

export const api = {
  //매물리스트
  async getGoodsListByComplex(complexId, payload) {
    try {
      const url = `/goods_common/complex/${complexId}${objectToQuerystring(payload)}`;
      return await apiClient.get(url);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 동코드에 대한 매물리스트
   * @param page 페이지
   * @param perPage 페이지 당 개수
   * @param tradeTypes 매매 s, 전세 c, 월세 m
   **/
  async getGoodsListByDong(bdong_code, payload) {
    try {
      const url = `/goods_common/address/${bdong_code}${objectToQuerystring(payload)}`;

      return await apiClient.get(url);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //매물상세
  async getGoodsDetail(goods_idx) {
    try {
      const url = '/goods_common/goods/' + goods_idx;
      return await apiClient.get(url);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //매물상세 > 중개사 사무소 리스트
  async getBrokerOfficeList(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/brokers`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 허위매물 신고
   * 
   * @param {*} payload
   * - fakeReportType : (option) 신고구분
        (default) ETC : 기타사유
        FR1 : 이미 거래 완료된 매물
        FR2 : 매도자가 거래 의사가 없음
     - fakeReason : (option) 신고사유 설명
   */
  async reportFakeGoods({ payload }) {
    try {
      return await apiClient.post(`/goods_common/${payload?.goodsCommonIdx}/fakeReport`, { fakeReason: payload?.reason });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물을 읽은 중개사 리스트
  async getReadBrokerList(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/read_brokers`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물에 해야할 일
  async getTodoList(goodsCommonIdx) {
    try {
      return await apiClient.get(`/goods_common/${goodsCommonIdx}/toDo`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // goods_coomon_idx로 매물단지 명 벌크로 조회
  async getBulkGoodsInfo(goodsCommonIdxList) {
    try {
      return await apiClient.post('/goods_common/complex-informations/bulk', { goodsCommonIdx: goodsCommonIdxList });
    } catch (e) {
      throw Error(e.message);
    }
  },
};
