import { getMyAreas, getMyComplexes, getMyHome, getRecentlyViewedAreas, getRecentlyViewedComplexes } from '../data';
import { TYPES } from '../util';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';

const useGetDataByType = ({ onClosePopup }) => {
  const dispatch = useDispatch();
  const myAreas = useSelector(selectors.my.getMyAreaData);
  const myComplexes = useSelector(selectors.my.getMyCpsData);

  const getDataByType = async (type) => {
    try {
      let data;

      switch (type) {
        case TYPES.myArea:
          data = getMyAreas({ myAreas });
          break;
        case TYPES.myComplex:
          data = getMyComplexes({ myComplexes });
          break;
        case TYPES.myHome:
          data = await getMyHome();
          break;
        case TYPES.recentArea:
          data = await getRecentlyViewedAreas();
          break;
        case TYPES.recentComplex:
          data = await getRecentlyViewedComplexes();
          break;
        default:
          break;
      }

      return data;
    } catch (e) {
      dispatch(actions.common.alert({ contents: e.message }));
      onClosePopup();
    }
  };

  return getDataByType;
};

export default useGetDataByType;
