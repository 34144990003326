import { actions } from '@/data';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OsType from '@/services/apps/types/OsType';

/**
 * 앱 osType instance redux state에 저장
 */
const useAppOsType = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.app.setOsTypeInstance(new OsType(window.dawin_instance.osType)));
  }, []);
};

export default useAppOsType;
