import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import apis from '@/apis';
import React from 'react';
import { Link } from 'react-router-dom';

export function* getSetArea() {
  try {
    const response = yield call(apis.brokerPageApi.getSetArea);
    yield put(actions.setDataArea(response));
    yield put(actions.getSetAreaSuccess(response));
  } catch (e) {
    yield put(actions.getSetAreaFailure(e));
    console.log(e.message);
  }
}

export function* getPossibleArea() {
  try {
    yield put(allActions.common.toggleGlobalSpinner(true));
    const response = yield call(apis.brokerPageApi.getPossibleArea);
    yield put(actions.getPossibleAreaSuccess(response));
    yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner(false));
    yield put(actions.getPossibleAreaFailure(e));
  }
}

export function* getBrokerAreaDistrict({ param }) {
  try {
    const loading = yield select(selectors.setArea.getBrokerDistrictLoading);

    if (!loading) {
      yield put(actions.getBrokerAreaDistrictLoading());
      // yield put(allActions.common.toggleGlobalSpinner(true));
      const response = yield call(apis.brokerPageApi.getBrokerAreaDistrict, param);
      yield put(actions.getBrokerAreaDistrictSuccess(response));
      // yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    // yield put(allActions.common.toggleGlobalSpinner(false));
    yield put(actions.getBrokerAreaDistrictFailure(e));
  }
}

//활동지역 삭제
export function* deleteBrokerDistrict({ code }) {
  try {
    yield put(allActions.common.toggleGlobalSpinner(true));
    const param = {
      districtCodes: code?.map?.((i) => i)?.join(',') ?? code,
    };
    const response = yield call(apis.brokerPageApi.deleteBrokerDistrict, param);
    if (response) {
      yield put(actions.getBrokerDistrict());
    }
    yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner(false));
    yield put(actions.getBrokerDistrictFailure(e));
  }
}

export function* getBrokerDistrict() {
  try {
    yield put(allActions.common.toggleGlobalSpinner(true));
    const response = yield call(apis.brokerPageApi.getBrokerDistrict);
    yield put(actions.getBrokerDistrictSuccess(response));
    yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner(false));
    yield put(actions.getBrokerDistrictFailure(e));
  }
}

export function* saveActiveArea({ data, setTempBrokerDistrict, callback, freeMembership, routeToMembershipExPage, setHasAreasEdited }) {
  try {
    const payload = [];
    data?.map?.((ii) => {
      payload.push({ districtCode: ii.districtCode, priority: ii.priority });
    });

    yield put(allActions.common.toggleGlobalSpinner(true));

    const response = yield call(apis.brokerPageApi.saveActiveArea, payload);
    if (response) {
      if (freeMembership) {
        setHasAreasEdited(true);
        yield put(
          allActions.common.alert({
            contents: (
              <>
                <div className="board-body">
                  <p className="para">
                    <span className="wbr">
                      중개지역 변경사항이 저장되었습니다. 멤버십 서비스를 이용하시면, 설정한 지역의 매물 상세주소와 고객 연락처를 확인할 수 있습니다.
                    </span>
                  </p>
                </div>
                <div className="board-util">
                  <div className="button-display module-a style-a type-c">
                    <span className="button-area">
                      <Link
                        className="btn module-a style-c type-fill accent-03 small symbol-rtl-chevron-right flex"
                        to="#"
                        onClick={routeToMembershipExPage}>
                        <span className="btn-text">멤버십 자세히보기</span>
                      </Link>
                    </span>
                  </div>
                </div>
              </>
            ),
            onConfirm: () => {
              if (callback) {
                callback();
              }
            },
          })
        );
      } else {
        yield put(
          allActions.common.alert({
            contents: '중개지역 변경사항이 저장되었습니다.',
            onConfirm: () => {
              if (callback) {
                callback();
              }
            },
          })
        );
      }
    }
    yield put(actions.getBrokerDistrict());
    yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner(false));

    yield put(allActions.common.alert({ contents: e.message }));
    yield put(actions.getBrokerDistrict());
    setTempBrokerDistrict(data);
    console.log(e.message);
  }
}

export function* saveSetDefaultArea() {
  try {
    const response = yield call(apis.brokerPageApi.saveSetDefaultArea);
    if (response?.rltObj) {
      yield put(
        allActions.common.alert({
          contents: '기본으로 설정하였습니다.',
        })
      );
      yield put(actions.getPossibleArea());
    }
    yield put(allActions.router.replace('/brokerPage/setArea'));
  } catch (e) {
    yield put(actions.getPossibleAreaFailure(e));
  }
}
