import * as AT from './actionTypes';
import Remote from '@/data/remote';
import CONSTANTS from '@/pages/BrokerPage/constants';
import { failure, loading, success } from '@/data/utils';

export const MG_GOODS = {
  gubun: CONSTANTS.ALL_GOODS,
  keyword: '',
  category: 'all',
  tradetype: ['all'],
  areasize: '',
  price: '',
  price2: '',
  situ: 'all',
  brokerageType: 'all',
  page: 1,
  total: '',
  order: '',
  orderDir: '',
  option: [],
  fakeSaleReportRes: false,
  goodsList: {},
  nextDataYn: false,
  contractInfo: {
    goods_idx: '',
    buyer_name: '',
    buyer_cp: '',
    trade_type: '1',
    contract_price: '',
    contract_date: '',
    intermediate_date1: '',
    intermediate_date2: '',
    intermediate_price1: '',
    intermediate_price2: '',
    balance_price: '',
    balance_date: '',
    sale_price: '',
    deposit: '',
    monthly_price: '',
    fee_rate: '',
    buyer_agree: 'Y',
  },
};
const DETAIL_INITIAL = {
  goods_common_idx: '',
  reg_date: '',
  reg_name: '',
  reg_cp: '',
  trade_type: '',
  customer_memo_cnt: 0,
  category: '',
  room_type: '',
  address: '',
  lat: 0.0,
  lng: 0.0,
  complex_name: '',
  dong: 0,
  ho: 0,
  total_floor: 0,
  floor: 0,
  area1: 0,
  area2: 0,
  pyeong1: 0,
  pyeong2: 0,
  parking_flag: 'Y',
  room_cnt: 0,
  bathroom_cnt: 0,
  history: [],
  enter_type: '',
  enter_date: '',
  owner_live: '',
  balcony: '',
  direction: '',
  animal: '',
  front_door_type: '',
  images: [],
  cpx_images: [],
  owner_comment: '',
  seller_qa: [],
  buyer_qa: [],
};

export default function mgContract(state = MG_GOODS, action = {}) {
  switch (action.type) {
    //=======================manage_goods=======================
    case AT.CONTRACT_CHANGE_CATEGORY: {
      return {
        ...state,
        category: action.category,
      };
    }
    case AT.CONTRACT_CHANGE_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.SET_MANAGE_CONTRACT_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.scroll,
      };
    }
    case AT.SET_TOTAL_CNT: {
      return {
        ...state,
        totalCnt: action.totalCnt,
      };
    }
    case AT.RESET_CONTRACT_DETAIL: {
      return {
        ...state,
        contractDetail: {},
      };
    }
    case AT.CONTRACT_CHANGE_AREASIZE: {
      return {
        ...state,
        areasize: action.areasize,
      };
    }
    case AT.CONTRACT_CHANGE_PRICE: {
      return {
        ...state,
        price: action.price,
      };
    }
    case AT.CONTRACT_CHANGE_PRICE2: {
      return {
        ...state,
        price2: action.price2,
      };
    }
    case AT.CONTRACT_CHANGE_SITU: {
      return {
        ...state,
        situ: action.situ,
      };
    }
    case AT.CONTRACT_CHANGE_BROKERAGE_TYPE: {
      return {
        ...state,
        brokerageType: action.brokerageType,
      };
    }
    case AT.CONTRACT_CHANGE_ORDER: {
      return {
        ...state,
        order: action.order,
      };
    }
    case AT.CONTRACT_CHANGE_ORDER_DIR: {
      return {
        ...state,
        orderDir: action.orderDir,
        order: action.order,
      };
    }
    case AT.CONTRACT_CHANGE_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.CONTRACT_CHANGE_GUBUN: {
      return {
        ...state,
        gubun: action.gubun,
      };
    }
    case AT.GET_MANAGE_CONTRACT_DETAIL: {
      return {
        ...state,
      };
    }
    case AT.MANAGE_CONTRACT_MEMO: {
      return {
        ...state,
        param: action.param,
      };
    }
    case AT.SET_CONTRACT_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.SET_NEXT_CONTRACT_DATA: {
      return {
        ...state,
        nextDataYn: action.yn,
      };
    }

    case loading(AT.GET_MANAGE_CONTRACT_DETAIL):
      return { ...state, [AT.GET_MANAGE_CONTRACT_DETAIL]: Remote.Loading };
    case failure(AT.GET_MANAGE_CONTRACT_DETAIL):
      return {
        ...state,
        [AT.GET_MANAGE_CONTRACT_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };
    case success(AT.GET_MANAGE_CONTRACT_DETAIL): {
      return { ...state, contractDetail: action.contractDetail, [AT.GET_MANAGE_CONTRACT_DETAIL]: Remote.Success([]) };
    }
    case loading(AT.GET_MANAGE_CONTRACT_LIST):
      return { ...state, [AT.GET_MANAGE_CONTRACT_LIST]: Remote.Loading };
    case success(AT.GET_MANAGE_CONTRACT_LIST): {
      if (state?.page > 1) {
        let contract_origin = [...state.contractList];
        action.contractList?.map?.((ii) => contract_origin.push(ii));
        return { ...state, contractList: contract_origin, [AT.GET_MANAGE_CONTRACT_LIST]: Remote.Success([]) };
      } else {
        return { ...state, contractList: action.contractList, [AT.GET_MANAGE_CONTRACT_LIST]: Remote.Success([]) };
      }
    }
    case failure(AT.GET_MANAGE_CONTRACT_LIST):
      return {
        ...state,
        [AT.GET_MANAGE_CONTRACT_LIST]: Remote.Failure(action.error),
        error: action.error,
      };
    case AT.CONTRACT_SEARCH_SUCCESS: {
      return {
        ...state,
        goodsList: action.goodsList,
      };
    }
    case AT.CONTRACT_FAVORITE_DESIGNATE: {
      return {
        ...state,
      };
    }
    case AT.CONTRACT_FAVORITE_DE_DESIGNATE: {
      return {
        ...state,
      };
    }
    case AT.FAKE_SALE_REPORT: {
      return {
        ...state,
        param: action.param,
      };
    }
    case AT.SET_FAKE_SALE_REPORT_RES: {
      return {
        ...state,
        fakeSaleReportRes: action.res,
      };
    }
    case AT.SET_CONTRACT_INFO: {
      return {
        ...state,
        contractInfo: { ...state.contractInfo, [action.key]: action.value },
      };
    }
    case AT.SET_PRE_EX_CONTRACT: {
      return {
        ...state,
        brokerage: action.brokerage,
      };
    }
    case AT.SET_CONTRACT_INFO_MEMBERS: {
      return {
        ...state,
        members: action.data,
      };
    }
    case AT.SET_CONTRACT_INFO_DETAILS: {
      return {
        ...state,
        details: action.data,
      };
    }
    case AT.SET_CONTRACT_INFO_INI: {
      return {
        ...state,
        contractInfo: MG_GOODS.contractInfo,
      };
    }
    case AT.SET_MODIFY_CONTRACT_INI: {
      return {
        ...state,
        modigoods: {},
      };
    }
    case AT.GET_CALL_REPORT: {
      return {
        ...state,
      };
    }
    case AT.SET_CALL_REPORT: {
      return {
        ...state,
        callReportList: action.list,
      };
    }

    case AT.CONTRACT_REFRESH_SEARCH_CONDITION: {
      return {
        ...state,
        gubun: MG_GOODS.gubun,
        keyword: MG_GOODS.keyword,
        category: MG_GOODS.category,
        tradetype: MG_GOODS.tradetype,
        areasize: MG_GOODS.areasize,
        price: MG_GOODS.price,
        price2: MG_GOODS.price2,
        situ: MG_GOODS.situ,
        page: MG_GOODS.page,
        brokerageType: MG_GOODS.brokerageType,
      };
    }

    //중개사 매물정보수정
    case AT.CHANGE_MODIFY_GOODS: {
      return {
        ...state,
        modigoods: {
          ...state.modigoods,
          [action.changetype]: action.val,
        },
      };
    }
    case AT.MODIFY_GOODS: {
      return {
        ...state,
      };
    }

    case AT.CONTRACT_KEYWORD_SEARCH: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.CONTRACT_KEYWORD_SEARCH_RES: {
      return {
        ...state,
        keywordList: action.keywordList,
      };
    }

    //다윈외 거래 계약체결
    case AT.SET_CONTRACT_PRE_CLIENT_TYPE: {
      return {
        ...state,
        contractInfo: { ...state.contractInfo, client_type: action.client_type },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
