// import { validPhoneNumber as helper } from '@/services/Helper';
import React from 'react';

const WhiteList = ['Down', 'ArrowDown', 'Up', 'ArrowUp', 'Left', 'ArrowLeft', 'Right', 'ArrowRight', 'Backspace', 'Delete', 'Enter', 'Esc', 'Escape'];

const InputPhoneNumber = (props) => {
  const { placeholder = '휴대폰 번호를 입력해주세요.', className = 'form-elem', maxLength = 11 } = props;

  const reactHookFormProps = props?.register ? props.register('phoneNumber', { required: true }) : {};

  function validate(e) {
    const key = e.key;
    const length = `${e.target.value}${key}`?.length;

    const isAllowed = WhiteList.includes(key);

    const isNumber = /^[0-9]*$/.test(key);

    const validLength = maxLength > -1 ? length <= maxLength : true;

    if (!isAllowed && (!isNumber || !validLength)) {
      e.preventDefault();
      return;
    }
  }

  return (
    <>
      <input
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        title="휴대폰 번호"
        autoComplete="off"
        placeholder={placeholder}
        className={className}
        onKeyDown={validate}
        {...props}
        {...reactHookFormProps}
      />
    </>
  );
};

export default InputPhoneNumber;
