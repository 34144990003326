import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//요약본
export const getList = () => ({
  type: AT.GET_NOTICE_LIST,
});
export const getDetail = (idx) => ({
  type: AT.GET_NOTICE_DETAIL,
  idx,
});
export const setAttach = (file_url) => ({
  type: AT.SET_NOTICE_NEW_ATTACH,
  file_url,
});
export const setTitle = (val) => ({
  type: AT.SET_NOTICE_NEW_TITLE,
  val,
});
export const setCont = (val) => ({
  type: AT.SET_NOTICE_NEW_CONT,
  val,
});
export const setNoticeKeyword = (keyword) => ({
  type: AT.SET_NOTICE_KEYWORD,
  keyword,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_NOTICE_CURRENT_PAGE,
  page,
});
export const setNoticeTotalCnt = (totalCnt) => ({
  type: AT.SET_NOTICE_TOTAL_CNT,
  totalCnt,
});
export const getNoticeListLoading = () => ({
  type: AT.GET_NOTICE_LIST_LOADING,
});
export const getNoticeListSuccess = (conlist) => ({
  type: AT.GET_NOTICE_LIST_SUCCESS,
  conlist,
});
export const setNoticeReal = (realList) => ({
  type: AT.SET_NOTICE_REAL,
  realList,
});
export const getNoticeListFailure = (error) => ({
  type: AT.GET_NOTICE_LIST_FAILURE,
  error,
});

export const getNoticeDetailLoading = () => ({
  type: AT.GET_NOTICE_DETAIL_LOADING,
});
export const getNoticeDetailSuccess = (detail) => ({
  type: AT.GET_NOTICE_DETAIL_SUCCESS,
  detail,
});
export const getNoticeDetailFailure = (error) => ({
  type: AT.GET_NOTICE_DETAIL_FAILURE,
  error,
});
