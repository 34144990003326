import React from 'react';
import { Link } from 'react-router-dom';

const CallBtns = ({ typeArray, chat }) => {
  const isChatRequested = typeArray?.includes('WANT_TO_CHAT');

  return isChatRequested ? (
    <Link
      className="btn module-a style-c type-fill accent-02 small-1x"
      to="#"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        chat();
      }}>
      <span className="btn-text">채팅하기</span>
    </Link>
  ) : (
    false
  );
};

export default CallBtns;
