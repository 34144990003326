import React, { useEffect } from 'react';
import AddressSelect from './AddressSelect';
import ComplexSelect from './ComplexSelect';
import { dataFinder } from '@/resources/design/v2/js/bui.template';
import useSetMarkTypes from './hooks/useSetMarkTypes';

const SelectSearchView = ({
  addressData,
  setAddressData,
  addAllOption,
  disabled,
  tenDigitCode,
  dongCode,
  complexData,
  setComplexData,
  preventUpdateComplexData,
  feedType,
  tags,
}) => {
  useEffect(() => {
    dataFinder('[data-bui-form="dataFinder"]');
  }, []);

  const complexTags = tags?.filter((tag) => tag?.type === 'GOODS_TYPE_TAG');
  const showComplexSelect = complexTags?.length === 1 && (complexTags[0]?.name === '오피스텔' || complexTags[0]?.name === '아파트');

  // 매물유형 선택에따라 단지명 선택부분에 들어오는 data 변경
  const markTypes = useSetMarkTypes({ complexTags, tags });

  return (
    <div className="form-area type-d">
      {/* 주소 선택 */}
      <AddressSelect data={addressData} setData={setAddressData} addAllOptionOnDong={addAllOption} disabled={disabled} tenDigitCode={tenDigitCode} />

      {/* 단지 선택 */}
      {showComplexSelect && (
        <ComplexSelect
          selectedIdx={`${complexData?.complex_idx}`}
          dongCode={dongCode}
          setData={setComplexData}
          markTypes={markTypes}
          addAllOption={addAllOption}
          preventUpdateComplexData={preventUpdateComplexData}
          disabled={disabled}
          addressData={addressData}
          feedType={feedType}
        />
      )}
    </div>
  );
};

export default SelectSearchView;
