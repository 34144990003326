import React from 'react';
import { useDispatch } from 'react-redux';
import { routeToEdit } from '@/combinedComp/Community/helpers';

const useEditFeed = () => {
  const dispatch = useDispatch();

  const onEditFeed = (e, postId) => {
    e.preventDefault();
    dispatch(routeToEdit(postId));
  };
  return { onEditFeed };
};

export default useEditFeed;
