import { apiClient, naverClient, apiClient2 } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import { REBUILD_SCORE_COUPON } from '@/resources/global';

export const api = {
  async getComplexArea(complex_idx) {
    try {
      return await apiClient.get('/complex/' + complex_idx + '/area');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexArea2(complex_idx) {
    try {
      return await apiClient.get('/complex/' + complex_idx + '/area-select-list');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async searchKeyword(keyword, saletype, withMinhouseholdcnt) {
    try {
      let data = { keyword, saletype };
      if (withMinhouseholdcnt) {
        data.minhouseholdcnt = 100;
      }
      return await apiClient.get('/analysis/search' + objectToQuerystring(data)); //
    } catch (e) {
      throw Error(e.message);
    }
  },

  async uploadFiles(data) {
    try {
      return await apiClient.post('/image', data);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async uploadAnyFiles(data) {
    try {
      return await apiClient.post('/file', data);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFaqList(userType) {
    try {
      return await apiClient.get('/help/faq?gubun=' + userType);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async addQuestion(data) {
    try {
      return await apiClient.post('/help/qna_prc', data);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getQnaCategoryList() {
    try {
      return await apiClient.get('/help/qna_catelist');
    } catch (e) {
      throw Error(e.message);
    }
  },

  //내가 선택한 중개사
  async getMyBrokers() {
    try {
      return await apiClient.post('/broker/my_broker');
    } catch (e) {
      throw Error(e.message);
    }
  },

  //중개사 검색
  async searchBroker({ dongcode, keywords, sort }) {
    try {
      return await apiClient.post('/broker/search_broker', { dongcode, keywords, sort });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 중개지역 설정에 따른 중개사 리스트
  async searchBrokerBySetting(data) {
    try {
      return await apiClient.get('/broker/find' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //중개사 신고
  async reportBroker({ brokerofficeidx, rept, reptExplain }) {
    try {
      return await apiClient.post('/broker/broker_complain', {
        brokerofficeidx: brokerofficeidx,
        rept,
        reptExplain,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getFavComplexList(category) {
    try {
      return await apiClient.get('/complex/attentionList?category=' + category);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async addFavComplex(complexIdx, areano) {
    try {
      return await apiClient.post('/complex/inFavorComplex', { complexIdx, areano });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async removeFavComplex(complexIdx, areano) {
    try {
      return await apiClient.post('/complex/delFavorComplex', { complexIdx, areano });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async handleFavGoods(acttype, goodsIdx) {
    try {
      if (acttype === 'S') {
        return await apiClient.post('/personalization/interestGoods', { goods_idx: goodsIdx });
      } else {
        return await apiClient.delete(`/personalization/interestGoods/${goodsIdx}`);
      }
    } catch (e) {
      throw Error(e.message);
    }
  },

  //쿠폰정보
  async getCouponInfo() {
    try {
      return await apiClient.post('/coupon/coupon_info', { cpcode: REBUILD_SCORE_COUPON });
    } catch (e) {
      throw Error(e.message);
    }
  },

  //쿠폰사용
  async useCoupon() {
    try {
      return await apiClient.post('/coupon/coupon_use', { cpcode: REBUILD_SCORE_COUPON });
    } catch (e) {
      throw Error(e.message);
    }
  },

  //쿠폰발행
  async publishCoupon() {
    try {
      return await apiClient.post('/coupon/coupon_publish', { cpcode: REBUILD_SCORE_COUPON });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async sendSms_downloadApp(phoneNumber) {
    try {
      return await apiClient.post('/sms/app_down_sms', { toCp: phoneNumber });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async postLog(payload) {
    try {
      return await apiClient2.post('/log-collector-api/v1/log', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // payload: {"callbackUrl": "string"}
  async createNiceSession(payload) {
    try {
      return await apiClient2.post('/nice-api/v1/nice/sessions', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updateNiceSession(payload) {
    try {
      return await apiClient2.put('/nice-api/v1/nice/sessions', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async bulkGetUsers(userIds) {
    if (!userIds || userIds.length === 0) {
      return {
        'users': []
      };
    }
    try {
      return await apiClient.post('/users/bulk', {
        'userIds': userIds
      });
    } catch (e) {
      throw Error(e.message);
    }
  }
};
