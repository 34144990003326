import React, { useMemo } from 'react';
import { TagType } from '@/services/Community';

const FilterTags = ({ tags, setTags, dualAddressData, setDualAddressData, setData, data }) => {
  const taglist = useMemo(() => {
    const addressTags = tags.filter((tag) => tag.type === TagType.ADDRESS_TAG);

    return tags
      .filter((i) => i.type != TagType.FLAG_TAG)
      .map((item, key) => {
        function onDelete(e) {
          e.preventDefault();
          if (addressTags.length !== 1 || item.type !== TagType.ADDRESS_TAG) {
            const deleted = tags.filter((tag) => !(item.type === tag.type && item.key === tag.key));
            setTags(deleted);
            const newDualAddressData = dualAddressData;

            if (item.type === TagType.ADDRESS_TAG) {
              if (item.key?.length === 5) {
                newDualAddressData.selectedAddress.sigungu = {};
                newDualAddressData.selectedAddress.sigungu1 = {};
                newDualAddressData.selectedAddress.sigungu2 = {};
              } else if (item.key?.length === 10) {
                newDualAddressData.selectedAddress.dong = {};
                newDualAddressData.selectedAddress.dong1 = {};
                newDualAddressData.selectedAddress.dong2 = {};
              }
            } else if (item.type === TagType.COMPLEX_TAG) {
              newDualAddressData.selectedComplex = {};
            }
            setDualAddressData(newDualAddressData);
          } else if (addressTags.length === 1) {
            setTags([]);
            setData({
              sido: { code: '', name: '' },
              sigungu: { code: '', name: '' },
              sigungu1: { code: '', name: '' },
              sigungu2: { code: '', name: '' },
              dong: { code: '', name: '' },
              dong1: { code: '', name: '' },
              dong2: { code: '', name: '' },
            });
          }
        }

        return (
          <span className="hashtag" key={item.code || key}>
            {item.name}
            <a onClick={onDelete} className="btn btn-close" />
          </span>
        );
      });
  }, [tags]);

  return <div className="filter-result">{taglist}</div>;
};

export default FilterTags;
