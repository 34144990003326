import React from 'react';

const OptionItem = ({ name, children, value, checked, onClick }) => {
  return (
    <span className="option-item">
      <label className="radio form module-b style-c accent-01 medium">
        <input type="radio" className="form-elem" name={name} value={value} onClick={onClick} checked={checked} />
        <span className="form-text">{children}</span>
      </label>
    </span>
  );
};

export default OptionItem;
