import { loading, success, failure } from '@/data/utils';

//1:1문의
export const SET_ONEONONE_KEYWORD = 'BROKERPAGE/SET_ONEONONE_KEYWORD';
export const SET_ONEONONE_LIMIT = 'BROKERPAGE/SET_ONEONONE_LIMIT';
export const SET_ONEONONE_TITLE = 'BROKERPAGE/SET_ONEONONE_TITLE';
export const SET_ONEONONE_NEW_ATTACH = 'BROKERPAGE/SET_ONEONONE_NEW_ATTACH';
export const SET_ONEONONE_CATEGORY = 'BROKERPAGE/SET_ONEONONE_CATEGORY';
export const SET_ONEONONE_CONT = 'BROKERPAGE/SET_ONEONONE_CONT';

export const SAVE_ONEONONE_NEW_QNA = 'BROKERPAGE/SAVE_ONEONONE_NEW_QNA';

export const GET_ONEONONE_LIST = 'BROKERPAGE/GET_ONEONONE_LIST';
export const GET_ONEONONE_LIST_LOADING = loading(GET_ONEONONE_LIST);
export const GET_ONEONONE_LIST_SUCCESS = success(GET_ONEONONE_LIST);
export const GET_ONEONONE_LIST_FAILURE = failure(GET_ONEONONE_LIST);
