import { FeedTypeKor } from '@/services/Community';
import React from 'react';
import CloseButton from '../../components/CloseButton';

const Header = ({ isUpdate, postType, feedType }) => {
  const title = feedType ? FeedTypeKor[feedType] : FeedTypeKor[postType];
  return (
    <div className="popup-local-head">
      <h2 className="popup-local-subject">
        <span className="popup-local-name">{isUpdate ? `${title} 수정하기` : `${title} 작성하기`}</span>
        <CloseButton />
      </h2>
    </div>
  );
};

export default Header;
