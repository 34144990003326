import { put } from 'redux-saga/effects';
import * as actions from './actions';
import apis from '@/apis';
import { objectToQuerystring } from '@/apis/helpers';

//분양정보 리스트
export function* getNewsales({ data }) {
  try {
    const result = yield apis.newsaleApi.getNewsales(data);
    yield put(actions.getNewsalesSuccess(result));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}

//재건축정보 리스트
export function* getRebuilds({ data }) {
  try {
    const result = yield apis.newsaleApi.getRebuilds(data);
    yield put(actions.getRebuildsSuccess(result));
  } catch (e) {
    yield put(actions.getRebuildsFailure(e));
  }
}

//평당 분양가 리스트
export function* getNewsalePrice({ complex_idx }) {
  try {
    const result = yield apis.newsaleApi.getNewsalePrice(complex_idx);
    yield put(actions.setNewsalePrice(result?.list));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}

//분양 일정
export function* getNewsaleSchedule({ complex_idx }) {
  try {
    const result = yield apis.newsaleApi.getNewsaleSchedule(complex_idx);
    yield put(actions.setNewsaleSchedule(result?.data));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}

//재건축 현황
export function* getRebuildSchedule({ complex_idx }) {
  try {
    const result = yield apis.newsaleApi.getRebuildSchedule(complex_idx);
    yield put(actions.setRebuildSchedule(result));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}

//뉴스
export function* getNews({ date, category = 0 }) {
  try {
    const result = yield apis.newsaleApi.getNews(objectToQuerystring({ date, category }));
    yield put(actions.setNews(result.list));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}

// 매일경제 매부리레터 뉴스
export function* getMKLetterNews({ date }) {
  try {
    const result = yield apis.newsaleApi.getMKLetterNews(objectToQuerystring({ date }));
    yield put(actions.setMKLetterNews(result.list));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}

//뉴스 카테고리
export function* getNewsSearchConditions() {
  try {
    const category = yield apis.newsaleApi.getNewsCategory();
    const dates = yield apis.newsaleApi.getNewsDates();
    yield put(actions.setNewsSearchConditions({ category: category.list, dates: dates.list }));
  } catch (e) {
    yield put(actions.getNewsalesFailure(e));
  }
}
