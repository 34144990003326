import dayjs from 'dayjs';

const defaultFormat = (_date = '', _format = 'YY.MM.DD', cutYear = true) => {
  const date = _date ? dayjs(_date) : dayjs();

  let format = _format;

  const todayYear = dayjs().year();

  if (cutYear && date.year() === todayYear) {
    format = 'MM.DD';
  }

  return date.format(format);
};

export default defaultFormat;
