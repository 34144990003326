import apis from '@/apis';
import { STATUS } from '@/data/utils';
import { actions } from '..';
import { getDeviceIdOrGenerate } from '@/services/Device';

export const getSettings = async (setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.notificationApi.getSettings();
    setData({ status: STATUS.SUCCESS, ...result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
    return false;
  }
};

// 전체
export const toggleAll = async (on, setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.notificationApi.toggleAll({ status: on });
    setData({ status: STATUS.SUCCESS, ...result });
    return result;
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
    return false;
  }
};

// 단일
export const toggleSingle = async (category_key, on, setData) => {
  try {
    setData && setData({ status: STATUS.LOADING });
    const result = await apis.notificationApi.toggleSingle(category_key, { status: on });
    setData && setData({ status: STATUS.SUCCESS });
    return result;
  } catch (e) {
    if (setData) {
      setData({ status: STATUS.FAILURE, error: e.message });
    }
    return false;
  }
};

// 야간
export const toggleNight = async (on, setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.notificationApi.toggleNight({ status: on });
    setData({ status: STATUS.SUCCESS, ...result });
    return result;
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
    return false;
  }
};

// export const toggleGeneral = async (status, setData) => {
//   try {
//     setData({ status: STATUS.LOADING });
//     const result = await apis.notificationApi.setGeneralPush({ status });
//     setData({ status: STATUS.SUCCESS, ...result });
//   } catch (e) {
//     setData({ status: STATUS.FAILURE, error: e.message });
//   }
// };

//노티 이력 호출
// export const getNotiHistory = async (setData, category, isBroker) => {
//   try {
//     setData({ status: STATUS.LOADING });
//     const result = await apis.notificationApi.getNotiHistory(category);
//     // setData({ status: STATUS.SUCCESS, ...result});

//     // 중개사 유저일때는 카테고리 분리X
//     if (isBroker) {
//       setData({ status: STATUS.SUCCESS, ...result });
//     } else {
//       const majorCategory = ['SELLING', 'CHAT', 'MYHOME', 'COMMUNITY'];
//       const majorNoti = result.push_history_list.filter((history) => majorCategory.includes(history.category_key));
//       const etcNoti = result.push_history_list.filter((history) => !majorCategory.includes(history.category_key));
//       // const etcNoti = result.push_history_list.filter((history) => history.category_key !== 'SELLING'&& history.category_key !=='CHAT' && history.category_key !=='MYHOME' && history.category_key !=='COMMUNITY')
//       setData({ status: STATUS.SUCCESS, majorNoti: majorNoti, etcNoti: etcNoti });
//       // console.log(result)
//       // setData({ status: STATUS.SUCCESS, ...result });
//     }
//   } catch (e) {
//     setData({ status: STATUS.FAILURE, error: e.message });
//   }
// };

export const getNotiHistory = async (setData, option, setShowMore) => {
  const limit = 6;
  try {
    setData({ status: STATUS.LOADING });
    let result = '';
    if (option === 'SELECTED') {
      result = await apis.notificationApi.getSelectedNotiHistory('SELLING,CHAT,MYHOME', { limit });
    } else if (option === 'UNREAD') {
      result = await apis.notificationApi.getNotiHistory({ limit, is_read: false });
    } else {
      result = await apis.notificationApi.getNotiHistory({ limit });
    }
    const isNext = result.push_history_list.length === limit;
    setShowMore(isNext);
    setData({ status: STATUS.SUCCESS, isNext, ...result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};
export const getMoreNotiHistory = async (setData, data, option, last_timestamp, setShowMore) => {
  const limit = 5;
  const old = { ...data };
  try {
    let result = '';
    if (option === 'SELECTED') {
      result = await apis.notificationApi.getSelectedNotiHistory('SELLING,CHAT,MYHOME', { limit, last_timestamp });
    } else if (option === 'UNREAD') {
      result = await apis.notificationApi.getNotiHistory({ limit, is_read: false, last_timestamp });
    } else {
      result = await apis.notificationApi.getNotiHistory({ limit, last_timestamp });
    }
    const more = { ...result };
    const total = [...old.push_history_list].concat([...more.push_history_list]);
    const newList = { ...data, push_history_list: total };
    const isNext = more.push_history_list.length === limit;
    setShowMore(isNext);
    setData({ status: STATUS.SUCCESS, ...newList });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

//노티 읽음처리
export const readNoti = async (category, timestamp, path, dispatch) => {
  // const { dispatch } = store;
  try {
    const result = await apis.notificationApi.readNoti(category, timestamp);
    dispatch(actions.my.getUnreadNotiCount());
    if (result) {
      if (path) {
        dispatch(actions.router.replace(path));
      }
      if (!path) {
        dispatch(actions.router.replace('/'));
      }
    }
  } catch (e) {
    console.error(e.message);
  }
};

/**
 * 모두 읽음 처리
 * @param {Function} setUnseenNoti
 * @return {Boolean} 모두 읽음 여부 state 업뎃
 */
export const readAllNoti = async (setData) => {
  try {
    const result = await apis.notificationApi.readAllNoti();
    if (result) {
      setData({ status: STATUS.SUCCESS, hasUnRead: false });
    }
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
    console.error(e.message);
  }
};

/**
 * 안읽은 알람의 수
 * @param {Function} setUnseenNoti
 * @returns {Object} unread_count: 전체알람, primary_unread_count: 주요알람
 */
export const unreadNotiCount = async (setData) => {
  try {
    const result = await apis.notificationApi.unreadNotiCount();
    if (result) {
      setData({ status: STATUS.SUCCESS, hasUnRead: result.unread_count > 0, hasUnReadMajor: result.primary_unread_count > 0 });
    }
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
    console.error(e.message);
  }
};

/**
 * 푸시토큰 업데이트
 */
export const savePushToken = async (pushToken) => {
  try {
    //'4E6ED6EE-A619-40AD-8BBC-E9DC43F9CE50'
    //'fnsrUXHhgE2qgXA5xV0cbC:APA91bEzaI2iQjACsMNGd7hhpRY4DKx2kEDK5VLJv5VbIn11dyJADHK4SqJ67v5P0VNjQg7C6SheRTEDqUQhKobqAtrhjfMMMaLYHDiWOPVssiKwS9h05CT34bMonI5BJ8aLZtmrvS5t';
    const deviceId = getDeviceIdOrGenerate();
    return await apis.notificationApi.patchPushToken({ device_id: deviceId, push_token: pushToken });
  } catch {
    //
  }
};

export const deletePushToken = async () => {
  try {
    const deviceId = getDeviceIdOrGenerate();
    return await apis.notificationApi.deletePushToken(deviceId);
  } catch {
    //
  }
};

export const getPushToken = async () => {
  try {
    return await apis.notificationApi.getPushToken();
  } catch {
    //
  }
};

// 급매물 알림 설정한 지역 목록 조회
export const checkingQuickSaleregions = async () => {
  try {
    await apis.notificationApi.checkingQuickSaleregions();
  } catch (e) {
    throw Error(e.message);
  }
};

export const updateQuickSaleRegions = async (regions) => {
  try {
    await apis.notificationApi.updateQuickSaleRegions(regions);
  } catch (e) {
    throw Error(e.message);
  }
};

export const checkingQuickSaleGoodsType = async () => {
  try {
    await apis.notificationApi.checkingQuickSaleGoodsType();
  } catch (e) {
    throw Error(e.message);
  }
};

// 급매물 알림 설정할 매물 유형 변경
export const updateQuickSaleGoodsType = async (tradeTypes) => {
  try {
    await apis.notificationApi.updateQuickSaleGoodsType(tradeTypes);
  } catch (e) {
    throw Error(e.message);
  }
};
