import { loading, success, failure } from '@/data/utils';

export const GET_MG_CUSTOM = 'BROKERPAGE/GET_MG_CUSTOM';
export const GET_MG_CUSTOM_SUCCESS = success('SELLING/GET_MG_CUSTOM');
export const GET_MG_CUSTOM_FAILURE = failure('SELLING/GET_MG_CUSTOM');
export const GET_MG_CUSTOM_LOADING = loading('SELLING/GET_MG_CUSTOM');

export const SET_SEL_CUSTOM = 'BROKERPAGE/SET_SEL_CUSTOM';
export const GET_SEL_CUSTOM = 'BROKERPAGE/GET_SEL_CUSTOM';
export const GET_SEL_CUSTOM_SUCCESS = success('SELLING/GET_SEL_CUSTOM');
export const GET_SEL_CUSTOM_FAILURE = failure('SELLING/GET_SEL_CUSTOM');
export const GET_SEL_CUSTOM_LOADING = loading('SELLING/GET_SEL_CUSTOM');

export const REG_NEW_CUSTOM = 'BROKERPAGE/REG_NEW_CUSTOM';
export const REG_NEW_CUSTOM_SUCCESS = success('SELLING/REG_NEW_CUSTOM');
export const REG_NEW_CUSTOM_LOADING = loading('SELLING/REG_NEW_CUSTOM');
export const REG_NEW_CUSTOM_FAILURE = failure('SELLING/REG_NEW_CUSTOM');

export const EDIT_CUSTOMINFO = 'BROKERPAGE/EDIT_CUSTOMINFO';
export const DELETE_CUSTOMINFO = 'BROKERPAGE/DELETE_CUSTOMINFO';
export const EDIT_NAME_CUSTOMINFO = 'BROKERPAGE/EDIT_NAME_CUSTOMINFO';
export const EDIT_CP_CUSTOMINFO = 'BROKERPAGE/EDIT_CP_CUSTOMINFO';
export const EDIT_CUSTOMINFO_SUCCESS = success('SELLING/EDIT_CUSTOMINFO');
export const EDIT_CUSTOMINFO_FAILURE = failure('SELLING/EDIT_CUSTOMINFO');
export const EDIT_CUSTOMINFO_LOADING = loading('SELLING/EDIT_CUSTOMINFO');

export const SET_MG_CUSTOM_KEYWORD = 'BROKERPAGE/SET_MG_CUSTOM_KEYWORD';
export const SET_EDIT_CUSTOMINFO = 'BROKERPAGE/SET_EDIT_CUSTOMINFO';

export const SET_NEW_MEMO_CONTENT = 'BROKERPAGE/SET_NEW_MEMO_CONTENT';
export const SET_NEW_MEMO_CUSTOMER_IDX = 'BROKERPAGE/SET_NEW_MEMO_CUSTOMER_IDX';

export const SET_NEW_CUSTOM_NAME = 'BROKERPAGE/SET_NEW_CUSTOM_NAME';
export const SET_NEW_CUSTOM_CP = 'BROKERPAGE/SET_NEW_CUSTOM_CP';
export const SET_NEW_CUSTOM_CONTENT = 'BROKERPAGE/SET_NEW_CUSTOM_CONTENT';

export const SET_EDIT_MEMO = 'BROKERPAGE/SET_EDIT_MEMO';
export const SET_MEMO_INFO = 'BROKERPAGE/SET_MEMO_INFO';
export const EDIT_MEMO = 'BROKERPAGE/EDIT_MEMO';
export const DEL_MEMO = 'BROKERPAGE/DEL_MEMO';
export const EDIT_MEMO_SUCCESS = success('SELLING/EDIT_MEMO');
export const EDIT_MEMO_FAILURE = failure('SELLING/EDIT_MEMO');
export const EDIT_MEMO_LOADING = loading('SELLING/EDIT_MEMO');
