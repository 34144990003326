import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

export const api = {
  // ===================== 요청_전화로 매물등록 ===================== //
  async adressCallRequest(payload) {
    const prefix = '/request/adress';
    try {
      return await apiClient.post(prefix, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // --UPDATE--
  async updateSavedForm(mbr_idx, data) {
    try {
      const response = await apiClient.put(`/goods/savedForm/${mbr_idx}`, data);
      // const response = await apiClient.post(`/goods/savedForm/${mbr_idx}`, data);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },

  // *type : APT or OFT
  async getComplexList(payload) {
    try {
      const prefix = '/complex/select-list';
      return await apiClient.get(prefix + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexListWithBcfiCnt(payload) {
    try {
      const prefix = '/complex/select-list';
      return await apiClient.get(prefix + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexLocation(payload) {
    try {
      const prefix = '/complex/';
      const { complexIdx } = payload;
      const suffix = '/location';
      return await apiClient.get(prefix + complexIdx + suffix);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getAddrSiDo(only_available, only_broker) {
    try {
      return await apiClient.get('/address/si-do' + objectToQuerystring({ only_available: only_available, only_broker: only_broker }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexAddress(dongCodeList) {
    try {
      return await apiClient.post('/address/dong-codes/bulk', { dongCodes: dongCodeList });
    } catch (e) {
      throw Error(e.message);
    }
  },

  //TODO:  2021-08-11 @장혁진  우선 코드는 유지했는데 검색 고도화 안정되면 삭제?
  async getAddrSiGunGu({ si_do_code, only_available, only_broker }) {
    if (si_do_code) {
      try {
        const prefix = '/address/si-do/';
        const suffix = '/si-gun-gu';
        // console.log('API_getAddrSiGunGu',si_do_code)
        return await apiClient.get(prefix + si_do_code + suffix + objectToQuerystring({ only_available: only_available, only_broker: only_broker }));
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  /**
   * getAddrSiGunGuFirst는 기존 getAddrSiGunGu와 같은데 우선 위의 코드는 유지
   * 2뎁스로 DongRi도 쪼개서 검색 고도화
   */
  async getAddrSiGunGuFirst({ si_do_code }) {
    if (si_do_code) {
      try {
        const prefix = '/address/si-do/';
        const suffix = '/si-gun-gu-first';
        // console.log('API_getAddrSiGunGu',si_do_code)
        return await apiClient.get(prefix + si_do_code + suffix);
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  // 첫번째부터 4번째까지 법정동 코드
  async getAddrSiGunGuSecond({ sigungu_four_digit_code }) {
    if (sigungu_four_digit_code) {
      try {
        const prefix = '/address/si-do/';
        const suffix = '/si-gun-gu-second';
        // console.log('API_getAddrSiGunGu',si_do_code)
        return await apiClient.get(prefix + sigungu_four_digit_code + suffix);
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  //TODO:  2021-08-11 @장혁진  우선 코드는 유지했는데 검색 고도화 안정되면 삭제?
  async getAddrDongRi({ si_gun_gu_code, only_available, only_broker }) {
    if (si_gun_gu_code) {
      try {
        return await apiClient.get(
          '/address/si-gun-gu/' + si_gun_gu_code + '/dong-ri' + objectToQuerystring({ only_available: only_available, only_broker: only_broker })
        );
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  // 뎁스를 2단계로 쪼갬
  async getAddrDongRiFirst({ si_gun_gu_code }) {
    if (si_gun_gu_code) {
      try {
        return await apiClient.get('/address/si-gun-gu/' + si_gun_gu_code + '/dong-ri-first');
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  async getAddrDongRiSecond({ dongri_eight_digit_code }) {
    if (dongri_eight_digit_code) {
      try {
        return await apiClient.get('/address/si-gun-gu/' + dongri_eight_digit_code + '/dong-ri-second');
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  async getComplexDongList(data) {
    try {
      if (data) {
        return await apiClient.get('/complex/complex_dong_list' + objectToQuerystring(data));
      } else {
        return null;
      }
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFloorList(data) {
    try {
      return await apiClient.get('/complex/complex_dong_floor_list' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getHoList(data) {
    try {
      if (data) {
        // return await apiClient.get('/complex/' + complexIdx + '/dong' + dongNo + '/floor' + floor);
        return await apiClient.get('/complex/complex_dong_ho_list' + objectToQuerystring(data));
      } else {
        return {};
      }
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getSpecificLocation(data) {
    try {
      return await apiClient.get(`/address/dong-codes/${data}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async coord2DongCode({ lng, lat }) {
    try {
      if (!(lat && lng)) {
        throw Error(`invalid lat(${lat}) / lng${lng}`);
      }
      return await apiClient.get(`/address/dong-codes/latlng?lng=${lng}&lat=${lat}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
