import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';
import { EDU_DETAIL } from '@/data/buying/utils';

// 교육 상세 (어린이집 유치원 학교)
export const routeToEdu = (isMobile, type) => (dispatch) => {
  const path = getEduPath(type);
  const watching = location.search.indexOf(EDU_DETAIL) > -1;

  if (watching || !isMobile) {
    dispatch(actions.router.replace(path));
  } else {
    dispatch(actions.router.push(path));
  }
};

// URL
export const getEduPath = (type) => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  queryObject[EDU_DETAIL] = type;

  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};

// GOBACK URL
export const getEduBackPath = () => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  delete queryObject[EDU_DETAIL];

  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};
