export const hidden = `
  display: block;
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
  border: 0;
  clip-path: inset(100%);
  pointer-events: none;
`;
