import { actions } from '@/data';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useGetSafeCallNumber = ({ typeArray, userInfo, feed }) => {
  const dispatch = useDispatch();
  const feedId = feed?.id;

  useEffect(() => {
    if (typeArray?.includes('WANT_TO_CALL') && userInfo?.mbr_type == 'BU') {
      dispatch(actions.community.getPhoneNumber(feedId));
    }
  }, []);

  return;
};

export default useGetSafeCallNumber;
