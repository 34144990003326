import * as AT from './actionTypes';

export const getAttach = (state) => state?.brokerPage?.bkQnA?.new?.attach;
export const getFilename = (state) => state?.brokerPage?.bkQnA?.new?.file_name;
export const getNew = (state) => state?.brokerPage?.bkQnA?.new;
export const getEdit = (state) => state?.brokerPage?.bkQnA?.edit;
export const getDel = (state) => state?.brokerPage?.bkQnA?.del;
export const getKeyword = (state) => state?.brokerPage?.bkQnA?.keyword;
export const getPage = (state) => state?.brokerPage?.bkQnA?.page;
export const getTotalCnt = (state) => state?.brokerPage?.bkQnA?.totalCnt;
export const getList = (state) => state?.brokerPage?.bkQnA?.conlist;
export const getDetail = (state) => state?.brokerPage?.bkQnA?.detail;
export const getDetailIdx = (state) => state?.brokerPage?.bkQnA?.detailIdx;
export const getPreIdx = (state) => state?.brokerPage?.bkQnA?.detail?.pre_idx;
export const getPreTitle = (state) => state?.brokerPage?.bkQnA?.detail?.pre_title;
export const getNextIdx = (state) => state?.brokerPage?.bkQnA?.detail?.next_idx;
export const getNextTitle = (state) => state?.brokerPage?.bkQnA?.detail?.next_title;
