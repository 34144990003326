// 개인화 요약정보
export const GET_PERSONAL_SUMMARY = 'GET_PERSONAL_SUMMARY';
export const SET_PERSONAL_SUMMARY = 'SET_PERSONAL_SUMMARY';

// 관심단지
export const GET_INTEREST_COMPLEX = 'GET_INTEREST_COMPLEX';
export const SET_INTEREST_COMPLEX = 'SET_INTEREST_COMPLEX';

// 우리집
export const GET_MY_HOME = 'GET_MY_HOME';
export const SET_MY_HOME = 'SET_MY_HOME';
