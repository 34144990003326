import * as AT from './actionTypes';
import CONSTANTS from '../../pages/Analysis/constants';
import Remote from '@/data/remote';

const INITIAL_STATE = {
  error: '',

  // ===================== 팝업 ===================== //
  popUpType: CONSTANTS.POPUP_RECOMM_NOT_YET,

  // ===================== 지도 동작 ===================== //
  showAnalysis: false,
  showFunctionalPopup: false,

  categorySelected: null, //CONSTANTS.ANALYSIS,
  candidateList: CONSTANTS.DUMMY_SEARCH_CANDIDATE_LIST,
  targetCandidate: {},
  displayList: [],
  // mapClickedLatLng: {},

  // ===================== 개발호재 알리기 ===================== //
  positivesType: CONSTANTS.POSITIVES_TYPE_TS,
  positivesTxt: '',
  positivesUrl: '',

  // ===================== 주간실거래가 ===================== //
  weeklyPriceList: [],
  weeklyPriceGeneralInfo: {},

  // ===================== 교육(구 학군) ===================== //
  schoolTypeList: [
    { type: '중학교', included: true },
    { type: '고등학교', included: true },
    { type: '학원가', included: true },
  ],
  isFirstSchoolType: true,
  academyMarkerList: [],
  schoolInfo: {},

  // 랭킹
  rankingType: '',
  rankingSpan: '', // 1: 1개월간, 2: 1년간
  rankingList: null,
  rankingCnt: 10,
  searchAnotherRankingForMobile: false,
  //아파트 비교
  aptCompare: [],
  aptCategoryTemplate: [],
  aptColors: [],
  //재건축 사업성
  reconstructType: 'a',
  [AT.COMPARE_APT]: Remote.NotAsked,
  isSizeMeter: false,

  // 거래량
  tradeVolumeMonthRange: 3, // defulat 2 : 1개월

  // 가격 변동
  priceFlucRange: 3,
  priceFlucIsSales: true,

  // 일자리 수
  jobNumberRange: 2,
  jobNumberIsCurrent: true,

  // 개발호재
  prevPositivesBuildPolygons: [],
  prevPositivesReBuildPolygons: [],
  prevPositivesSubwayPolygons: [],
  positivesSelectList: [
    { type: 'SUBWAY', included: true },
    { type: 'BUILD', included: true },
    { type: 'REBUILD', included: true },
  ],
  isPositivesFirstSelect: true,

  reconDetailFrom: '',

  // 추천
  recommendStep: 0,
  recommendSelectedInfo: null,
  recommendResult: [],
  isRecommendPopup: false,
  isRecommendResultPopup: false,
  isCompleteSelect: false,
  completeSelectFilterOrd: -1,
  selectedFilterData: null,
};

export default function myPageRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.ANALYSIS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case AT.ANALYSIS_RESET_ERROR_STATE: {
      return {
        ...state,
        error: '',
      };
    }

    // ===================== 지도 동작 ===================== //]
    case AT.TOGGLE_ANALYSIS:
      return {
        ...state,
        showAnalysis: action.show,
      };
    case AT.TOGGLE_FUNCTIONAL_POPUP:
      return {
        ...state,
        showFunctionalPopup: action.show,
      };
    case AT.TOGGLE_FUNCTIONAL_POPUP_DESC:
      return {
        ...state,
        showFunctionalPopupDesc: action.show,
      };
    case AT.SET_ANALYSIS_CATEGORY: {
      return {
        ...state,
        categorySelected: action.categorySelected,
      };
    }

    // case AT.SET_ANALYSIS_MAP_CENTER: {
    //   return {
    //     ...state,
    //     mapCenter: action.mapCenter,
    //   };
    // }

    // case AT.SET_MAP_CLICKED_LATLNG: {
    //   return {
    //     ...state,
    //     mapClickedLatLng: action.latlng,
    //   }
    // }

    // ===================== 지도 표시 내용 ===================== //

    case AT.SET_ANALYSIS_CANDIDATE_LIST_SEARCH: {
      return {
        ...state,
        candidateList: action.candidateList,
      };
    }

    case AT.SET_TARGET_CANDIDATE: {
      return {
        ...state,
        targetCandidate: state.candidateList.find((el) => el.idx == action.targetCandidateIdx),
      };
    }

    // case AT.SET_ANALYSIS_MAP_ZOOMLEVEL: {
    //   // @todo targetCandidate 를
    //   return {
    //     ...state,
    //     mapZoomLevel: action.zoomLevel,
    //   };
    // }

    case AT.SET_ANALYSIS_DISPLAY_LIST_MAP: {
      return {
        ...state,
        displayList: action.displayList,
      };
    }

    case AT.SET_POP_UP_TYPE: {
      return {
        ...state,
        popUpType: action.popUpType,
      };
    }
    // ===================== 개발호재 알리기 ===================== //
    case AT.SET_POSITIVES_TYPE: {
      return {
        ...state,
        positivesType: action.positivesType,
      };
    }
    case AT.SET_POSITIVES_TXT: {
      return {
        ...state,
        positivesTxt: action.positivesTxt,
      };
    }
    case AT.SET_POSITIVES_URL: {
      return {
        ...state,
        positivesUrl: action.positivesUrl,
      };
    }

    // 재건축 사업성
    case AT.SET_IS_SIZE_METER: {
      return {
        ...state,
        isSizeMeter: action.value,
      };
    }

    // ===================== 주간 실거래가 ===================== //

    case AT.SET_ANALYSIS_WEEKLY_PRICE_LIST: {
      return {
        ...state,
        weeklyPriceList: action.list,
      };
    }

    case AT.SET_ANALYSIS_WEEKLY_PRICE_GENERAL_INFO: {
      return {
        ...state,
        weeklyPriceGeneralInfo: action.generalInfo,
      };
    }

    // ===================== 교육 ===================== //
    case AT.SET_ANALYSIS_SCHOOL_TYPE: {
      const isIncluded = state.schoolTypeList[action.idx]['included'];
      state.schoolTypeList[action.idx]['included'] = !isIncluded;
      return {
        ...state,
      };
    }
    case AT.SET_IS_FIRST_SCHOOL_TYPE: {
      return { ...state, isFirstSchoolType: action.value };
    }
    case AT.SET_ACADEMY_MARKER_LIST: {
      return { ...state, academyMarkerList: action.value };
    }
    case AT.SET_SCHOOL_INFO: {
      return { ...state, schoolInfo: action.value };
    }

    //랭킹
    case AT.SET_ANALYSIS_RANKING_TYPE:
      return { ...state, rankingType: action.rankingType, rankingSpan: null };
    case AT.SET_ANALYSIS_RANKING_SPAN:
      return { ...state, rankingSpan: action.rankingSpan };
    case AT.SET_ANALYSIS_RANKING_RESULT_LIST:
      return { ...state, rankingList: action.list };
    case AT.RESET_RANKING_CNT:
      return { ...state, rankingCnt: 10 };
    case AT.MORE_RANKING_CNT:
      return { ...state, rankingCnt: state.rankingCnt + 5 };
    case AT.OPEN_DETAIL_SECTION:
      return { ...state, detailSection: action.sectionType };
    case AT.CLOSE_DETAIL_SECTION:
      return { ...state, detailSection: null };
    case AT.SEARCH_ANOTHER_RANKING_FOR_MOBILE:
      return { ...state, searchAnotherRankingForMobile: action.data };

    //아파트 비교
    case AT.ADD_APT_COMPARE:
      //검색창&지도 동기화 해야하는데
      //검색결과 데이터와 지도 데이터의 tb_cb_complex_idx 타입이 달라 string형으로 변환해 비교.
      var idx_list = state.aptCompare.map((o) => '' + o.tb_cb_complex_idx);
      var new_data = action.complex_data;
      if (idx_list.indexOf('' + new_data?.tb_cb_complex_idx) > -1) {
        return { ...state, aptCompare: state.aptCompare.filter((o) => o.tb_cb_complex_idx != new_data.tb_cb_complex_idx) };
      } else {
        return { ...state, aptCompare: [...state.aptCompare, new_data] };
      }
    case AT.RESET_APT_COMPARE:
      return { ...state, aptCompare: [] };
    case AT.COMPARE_APT_SUCCESS:
      return { ...state, [AT.COMPARE_APT]: Remote.Success(action.data) };
    case AT.COMPARE_APT_FAILURE:
      return { ...state, [AT.COMPARE_APT]: Remote.Failure(action.error) };
    case AT.COMPARE_APT_LOADING:
      return { ...state, [AT.COMPARE_APT]: Remote.Loading };
    case AT.COMPARE_APT_NOTASKED:
      return { ...state, [AT.COMPARE_APT]: Remote.NotAsked };
    case AT.SET_COMPARE_APT_CATEGORY_TEMPLATE:
      return { ...state, aptCategoryTemplate: action.data };
    case AT.SET_APT_COLOR:
      return { ...state, aptColors: action.data };
    case AT.SET_MIN_MAX_PRICE: //최고가 최저가
      return { ...state, minMaxPrice: action.data };
    case AT.SET_RECONSTRUCT_TYPE:
      return { ...state, reconstructType: action.value };
    case AT.SET_RECONSTRUCT_SCORE_DETAIL:
      return { ...state, reconstructScoreDetail: action.data };
    case AT.SET_RECONSTRUCT_INVEST_INFO:
      return { ...state, reconstructInvestInfo: action.data };
    case AT.SET_RECONSTRUCT_NEAR_COMPLEX:
      return { ...state, reconstructNearComplex: action.data };

    // 거래량
    case AT.SET_TRADEVOLUME_MONTH_RANGE:
      return { ...state, tradeVolumeMonthRange: action.value };

    // 가격변동
    case AT.SET_PRICE_FLUC_RANGE:
      return { ...state, priceFlucRange: action.value };
    case AT.SET_PRICE_FLUC_IS_SALES:
      return { ...state, priceFlucIsSales: action.value };

    // 일자리 수
    case AT.SET_JOB_NUMBER_RANGE:
      return { ...state, jobNumberRange: action.value };
    case AT.SET_JOB_NUMBER_IS_CURRENT:
      return { ...state, jobNumberIsCurrent: action.value };

    // 개발호재
    case AT.SET_PREV_POSITIVES_BIULD_POLYGONS:
      return { ...state, prevPositivesBuildPolygons: action.value };
    case AT.SET_PREV_POSITIVES_REBIULD_POLYGONS:
      return { ...state, prevPositivesReBuildPolygons: action.value };
    case AT.SET_PREV_POSITIVES_SUBWAY_POLYGONS:
      return { ...state, prevPositivesSubwayPolygons: action.value };

    // positiveSelectStatus
    case AT.SET_POSITIVES_SELECT_LIST: {
      const isIncluded = state.positivesSelectList[action.idx].included;
      state.positivesSelectList[action.idx].included = !isIncluded;
      return { ...state };
    }

    case AT.SET_RECON_DETAIL_FROM: {
      return { ...state, reconDetailFrom: action.value };
    }

    // 추천
    case AT.SET_RECOMMEND_STEP:
      return { ...state, recommendStep: action.recommendStep };
    case AT.SET_RECOMMEND_SELECTED_INFO:
      return { ...state, recommendSelectedInfo: action.recommendSelectedInfo };
    case AT.SET_RECOMMEND_RESULT:
      return { ...state, recommendResult: action.recommendResult };
    case AT.SET_IS_RECOMMEND_RESULT_POPUP:
      return { ...state, isRecommendResultPopup: action.isRecommendResultPopup };
    case AT.SET_IS_RECOMMEND_POPUP:
      return { ...state, isRecommendPopup: action.isRecommendPopup };
    case AT.SET_IS_COMPLETE_SELECT:
      return { ...state, isCompleteSelect: action.isCompleteSelect };
    case AT.SET_COMPLETE_SELECT_ORD:
      return { ...state, completeSelectFilterOrd: action.completeSelectFilterOrd };
    case AT.SET_SELECTED_FILTER_DATA:
      return { ...state, selectedFilterData: action.selectedFilterData };

    default:
      return { ...state };
  }
}

// const isIncluded = state.schoolTypeList[action.idx]['included'];
// state.schoolTypeList[action.idx]['included'] = !isIncluded;
// return {
//   ...state,
// };
