import brokerageCommisionRate from './brokerageCommisionRate';
import locationBasedService from './locationBasedService';
import operationalPolicy from './operationalPolicy';
import privacyPolicy from './privacyPolicy';
import termsOfUse from './termsOfUse';
import termsOfUseForBrokerService from './termsOfUseForBrokerService';

export default {
  brokerageCommisionRate,
  locationBasedService,
  operationalPolicy,
  privacyPolicy,
  termsOfUse,
  termsOfUseForBrokerService,
};
