import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useUserPopup from '@/combinedComp/Community/DetailNew/hooks/useUserPopup';
import OwnerBadge from '@/combinedComp/Community/components/OwnerBadge';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import CommonRealtorIntroPopup from '@/components/popups/CommonRealtorIntroPopup';
import reactDom from 'react-dom';
import { FeedType } from '@/services/Community';
import subjects from '@/services/contact/chat/subjects';

const WriterInfo = ({ data, isOwner, parentData }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectors.auth.getUserInfo);

  // 댓글, 대댓글의 경우 parentData에 feed data 가 넘어온다.
  const hasParentData = !!parentData;

  const feedWriter = hasParentData ? parentData?.writer?.id : data?.writer?.id;
  const itsMe = feedWriter == userInfo?.mbr_idx;

  const feedType = hasParentData ? parentData.feedType : data?.feedType;
  const subjectType = feedType === FeedType.POST_FOR_BUY && itsMe ? subjects.codes.COMMUNITY_POST_FOR_BUY : subjects.codes.COMMUNITY;
  const subjectId = hasParentData ? parentData.id : data?.id;
  const chatParam = { subjectType, subjectId: `${subjectId}` };

  const [showRealtorIntroPopup, setShowRealtorIntroPopup] = useState(false);

  const viewUserDetail = useUserPopup();

  const [writerName, setWriterName] = useState(data?.writer?.name);
  const [writerType, setWriterType] = useState(data?.writer?.type);
  const [writerId, setWriterId] = useState(data?.writer?.id);
  const [writerOfficeName, setWriterOfficeName] = useState(data?.writer?.officeName);

  const isBroker = writerType == 'BROKER';
  const isLeftWriter = data?.writer?.status !== 'NM' && data?.writer?.status !== 'DO';
  const leftWriterStyle = isLeftWriter ? 'unknown' : '';

  useEffect(() => {
    setWriterName(data?.writer?.name);
    setWriterType(data?.writer?.type);
    setWriterId(data?.writer?.id);
    setWriterOfficeName(data?.writer?.officeName);
  }, [data]);

  const handleClick = (e) => {
    e.preventDefault();

    if (isLeftWriter) {
      return;
    } else {
      if (writerType == 'BROKER') {
        setShowRealtorIntroPopup(true);
      } else {
        let name;
        name = writerName;
        viewUserDetail(e, writerId, name, writerType);
        const query = parseCallBack();
        query['userHistory'] = 'true';
        dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
      }
    }
  };

  const writerTypeIcon = () => {
    if (writerType === 'SYSTEM') {
      return 'attr-bot';
    } else if (writerType === 'BROKER') {
      return 'attr-broker';
    } else {
      return '';
    }
  };

  return data?.status === 'DELETED' ? (
    false
  ) : (
    <>
      <span className={`data-item writer ${writerTypeIcon()} ${leftWriterStyle}`}>
        <span className="head">글쓴이</span>
        <span className="body">
          <Link to="#" onClick={handleClick}>
            {isBroker ? writerOfficeName : writerName}
          </Link>
          <OwnerBadge isOwner={isOwner} writerName={writerName} writerId={writerId} />
        </span>
      </span>
      {showRealtorIntroPopup ? (
        reactDom.createPortal(
          <CommonRealtorIntroPopup
            realtorIdx={writerId}
            onClose={() => setShowRealtorIntroPopup(false)}
            chatParam={chatParam}
            fromData={{ from: 'community' }}
          />,
          document.getElementById('modal-root')
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default WriterInfo;
