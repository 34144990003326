import * as AT from './actionTypes';
import * as detail from './detail/actions';

export { detail };

export const setError = (error) => ({
  type: AT.SET_ERROR,
  error,
});

export const setListWide = (data) => ({
  type: AT.SET_LIST_WIDE,
  data,
});

/**
 * @param step 상세보기 스텝
 * @param idx url로 idx 전달
 * @param {boolean} single_return
 * Buying > InfoSection에서 step에 해당하는 컴포넌트만 리턴. (InfoSection이 아닌경우 해당X)
 * InfoSection은 default로 현재 스텝 이하에 해당하면 모두 리턴하지만
 * single_return: true일 경우 하나만 돌려줌
 */
export const setStep = (step, idx, single_return = false) => ({
  type: AT.SET_STEP,
  step,
  idx,
  single_return,
});

/** 검색
 * @param {number} zoomlevel (option) 전역 지도 위치정보가 아닌 다른 정보를 사용해야할 때
 * @param {list} bound (option) 전역 지도 위치정보가 아닌 다른 정보를 사용해야할 때
 * (전역 지도 위치정보: state > buying 의 zoomlevel, bounds)
 */
export const search = ({ searchType, setStates, map, callback } = {}) => ({
  type: AT.SEARCH,
  searchType,
  setStates,
  map,
  callback,
});
export const searchLoading = () => ({
  type: AT.SEARCH_LOADING,
});
export const searchSuccess = (list) => ({
  type: AT.SEARCH_SUCCESS,
  list,
});
export const searchFailure = (error) => ({
  type: AT.SEARCH_FAILURE,
  error,
});

/**
 *
 * @param {JSON} payload
 * category=(APT:아파트, OFT:오피스텔, default:아파트)
 * tradeTypes=(s:매매, c:전세, m:월세, default:전체)
 * rebuild=(c:입주예정, b:재건축, default:전체)
 * areaSizes=99-132 (면적이 여럿일 경우 "|"로 구분해서 입력) (default:전체)
 * sorting=(price:가격순, date:날짜순, area:면적순) default는 지도 중심동에 가까운 순
 * sortingOrder=(asc:오름차순, desc:내림차순) default는 내림차순
 * page:(페이지 default:1)
 * perPage:(페이지당 조회 수 default:20)
 * @param {String} pnuCode (optional) PNU코드. ex 동코드 구코드
 */
export const searchGoods = (payload, pnuCode, setStatus) => ({
  type: AT.SEARCH_GOODS,
  payload,
  pnuCode,
  setStatus,
});
export const searchGoodsLoading = () => ({
  type: AT.SEARCH_GOODS_LOADING,
});
export const searchGoodsSuccess = (data) => ({
  type: AT.SEARCH_GOODS_SUCCESS,
  data,
});
export const searchGoodsFailure = (error) => ({
  type: AT.SEARCH_GOODS_FAILURE,
  error,
});

// export const getNewHighList = () => ({
//   type: AT.GET_NEW_HIGH_LIST,
// });

export const setNewHighList = (data) => ({
  type: AT.SET_NEW_HIGH_LIST,
  data,
});

export const setHistoryList = (list) => ({
  type: AT.SET_HISTORY_LIST,
  list,
});

export const getChart = (complexId, pyeong_name, setChartData) => ({
  type: AT.GET_CHART,
  complexId,
  pyeong_name,
  setChartData,
});

export const getCharts = (items, setChartData) => ({
  type: AT.GET_CHARTS,
  items,
  setChartData,
});

//검색 조건
export const changeSearchWord = (searchWord) => ({
  type: AT.CHANGE_SEARCH_WORD,
  searchWord,
});

export const changeCategory = (category) => ({
  type: AT.CHANGE_CATEGORY,
  category,
});

export const changeDealtype = (dealtype) => ({
  type: AT.CHANGE_DEALTYPE,
  dealtype,
});

export const initializeDealtype = () => ({
  type: AT.INITIALIZE_DEALTYPE,
});

export const changeTradetype = (tradetype) => ({
  type: AT.CHANGE_TRADETYPE,
  tradetype,
});

export const initializeTradetype = () => ({
  type: AT.INITIALIZE_TRADETYPE,
});

export const changeAreasize = (areasize) => ({
  type: AT.CHANGE_AREASIZE,
  areasize,
});

export const initializeAreasize = () => ({
  type: AT.INITIALIZE_AREASIZE,
});

export const changePriceRange = (payload) => ({
  type: AT.CHANGE_PRICE_RANGE,
  payload,
});

export const changeCntRange = (payload) => ({
  type: AT.CHANGE_CNT_RANGE,
  payload,
});

export const changeYearRange = (payload) => ({
  type: AT.CHANGE_YEAR_RANGE,
  payload,
});

export const refreshSearchCondition = () => ({
  type: AT.REFRESH_SEARCH_CONDITION,
});

export const setSizemarkType = (sizemark) => ({
  type: AT.SET_SIZEMARK_TYPE,
  sizemark,
});

//지도 마커 타입
export const changeMarker = (mapMarkerType) => ({
  type: AT.CHANGE_MAP_MARKER_TYPE,
  mapMarkerType,
});

// 개발호재 마커 표출 여부
// 개발호재는 다른 지도 마커들과 함께 나와야 하므로 changeMarker 액션을 사용할 수 없다.
export const showPositiveMarker = (show) => ({
  type: AT.SHOW_POSITIVE_MARKER,
  show,
});

//지도 상태
export const changeMapState = (zoomlevel, bounds, center) => ({
  type: AT.CHANGE_MAP_STATE,
  zoomlevel,
  bounds,
  center,
});

// 법정동 주소 정보
export const changeRegionCode = (payload) => ({
  type: AT.CHANGE_REGION_CODE,
  payload,
});

/**
 * @comment
 * changeMapState, changeMapZoomlevel, changeMapBounds 는 바뀐 지도의 상태를 저장하는 action
 * moveCenter는 이동시킬 중심좌표를 전달하는 action
 *
 * MapCompGroup을 사용하는 경우 moveCenter 값이 변경되면
 * 지도 중심 좌표가 이동하는 effect 발생(zoom level 4 자동)
 * 그외 경우는 구현필요
 **/
export const changeMapZoomlevel = (zoomlevel) => ({
  type: AT.CHANGE_MAP_ZOOMLEVEL,
  zoomlevel,
});

/** @param {Array} data [위도 latitude, 경도 longitude] */
export const moveCenter = (center) => ({
  type: AT.MOVE_CENTER,
  center,
});

/**
 * @deprecated
 */
export const selectItem = (complex_idx) => ({
  type: AT.SELECT_ITEM,
  complex_idx,
});

/**
 * @param item
 *  선택된 카드 아이템의 모든 정보.
 *  상세보기에서 표기해야하지만 상세보기 api의 response에는 담겨있지 않는 값이나,
 *  선택된 아이템이 지도 밖으로 나갔을 때 모바일 preview를 보여주기 위해 추가함.
 *  기본적으로 카드리스트에서 complex_idx가 일치하는 정보를 set 한다.
 *  분양, 재건축, 중개사 정보 화면 등 리스트 조회 없이 바로 detail로 넘어가는 경우엔
 *  api로 해당 정보를 단일 조회하여 set한다.
 **/
export const setSelectedItem = (complex_idx, item) => ({
  type: AT.SET_SELECTED_ITEM,
  complex_idx,
  item,
});

// [DX-1470] `지도` 클릭했다가 `주변`을 클릭하면 기존 지도하위메뉴가 닫히게 수정
// 항상 `주변` 과 `지도` 중 하나만 활성화 하기위해 `지도` toggle state를 local -> redux store로 옮김.
export const toggleMapLayerList = (manual) => ({
  type: AT.TOGGLE_MAPLAYER_LIST,
  manual,
});

//주변
// 2021-01-21 정석규 수정
// `surrounding` 인자를 사용하는곳이 없으므로 안전하다고 판단하고 이름을 변경함 (`surrounding` -> `manual`)
// 변경이유는 위의 `toggleMapLayerList` 와 맞추기 위함.
export const toggleSurroundingList = (manual) => ({
  type: AT.TOGGLE_SURROUNDING_LIST,
  manual,
});

export const toggleSurroundingItem = ({ keyword, category }) => ({
  type: AT.TOGGLE_SURROUNDING_ITEM,
  keyword,
  category,
});

export const setSurroundingItem = ({ list, keyword, category }) => ({
  type: AT.SET_SURROUNDING_ITEM,
  list,
  keyword,
  category,
});

export const reloadSurroundings = () => ({
  type: AT.RELOAD_SURROUNDINGS,
});

export const reloadSurroundingsSuccess = (list) => ({
  type: AT.RELOAD_SURROUNDINGS_SUCCESS,
  list,
});

//선택된 주변 아이콘ID
export const selectSurrItem = (id) => ({
  type: AT.SELECT_SURR_ITEM,
  id,
});

// 단지 리스트에서 마우스오버 된 아이템의 idx
export const cardMouseover = (id) => ({
  type: AT.CARD_MOUSEOVER,
  id,
});

export const disableNaverCall = () => ({
  type: AT.NAVER_CALL_DISABLED,
});

export const enableNaverCall = () => ({
  type: AT.NAVER_CALL_ENABLED,
});

export const incrementProxyIndex = () => ({
  type: AT.INCREMENT_PROXY_INDEX,
});

export const setSelectedExGoodsDetail = (data) => ({
  type: AT.SET_SELECTED_EX_GOODS_DETAIL,
  data,
});

export const showPageDescription = (show) => ({
  type: AT.SHOW_PAGE_DESCRIPTION,
  show,
});

export const setComplexDetailMode = (mode) => ({
  type: AT.SET_COMPLEX_DETAIL_MODE,
  mode,
});

export const setSelectedDawinBroker = (data) => ({
  type: AT.SET_SELECTED_DAWIN_BROKER,
  data,
});

// 일반유저의 매물 신고
export const reportGood = ({ payload, callback }) => ({
  type: AT.REPORT_GOOD,
  payload,
  callback,
});

// 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
export const getThisDongRealtorList = ({ payload }) => ({
  type: AT.GET_THIS_DONG_REALTOR_LIST,
  payload,
});
export const setThisDongRealtorList = ({ payload }) => ({
  type: AT.SET_THIS_DONG_REALTOR_LIST,
  payload,
});
export const restThisDongRealtorList = () => ({
  type: AT.RESET_THIS_DONG_REALTOR_LIST,
});

export const getRealtorListOnMap = ({ callback, payload }) => ({
  type: AT.GET_REALTORLIST_ON_MAP,
  callback,
  payload,
});
