import React from 'react';
import img_banner01 from '@/assets/images/@temp/img_banner01.png';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';

const Banner = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handlerClick = (e) => {
    e.preventDefault();
    handleClose && handleClose();
    dispatch(actions.router.push(`/agentSignUp/academy`));
    dispatch(actions.common.toggleLoginPopup(false));
  };

  return (
    <div className="info-board module-b style-a type-a">
      <div className="board-wrap">
        <div className="board-head">
          <p className="board-name"></p>
        </div>
        <div className="board-body">
          <a href="#" className="" onClick={handlerClick}>
            <img src={img_banner01} alt="img_banner01" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
