import React from 'react';
import { SHARE_FROM } from '../../constants';
import { getPath } from '@/components/popups/SocialSharePopup/utils.js';

const FaceBookShare = ({ shareData }) => {
  const { oneLineMessage, templateArgs } = shareData || {};
  const { COMMON_PATH } = templateArgs || {};

  const newUrl = `${getPath({ path: COMMON_PATH, from: SHARE_FROM.FACEBOOK })}`;
  const encodedUrl = encodeURIComponent(newUrl);
  const body = `${oneLineMessage} | ${encodedUrl}`;

  const onClick = (e) => {
    e.preventDefault();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${body}`, oneLineMessage, 'width=450,height=720');
  };

  return (
    <a onClick={onClick} className="facebook">
      페이스북
    </a>
  );
};

export default FaceBookShare;
