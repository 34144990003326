import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { selectors } from '@/data';
// import { appFunc_getOsType } from '@/services/Helper';
// import { OS_TYPES } from '@/resources/global';
import { OSTYPE } from '@/services/apps/types/OsType';
import { actions } from '@/data';

const useAppVersionCheck = ({ dispatch }) => {
  // const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);

  useEffect(async () => {
    // const appVersion = await window.dawin_instance?.bridge?.appInfoFunction.getAppVersion();

    let oldOsType, isOldApp;

    try {
      window.App.getOSType(); //안드로이드
      oldOsType = OSTYPE.APP_ANDROID;
      isOldApp = true;
    } catch {
      try {
        getOSType(); //iOS
        oldOsType = OSTYPE.APP_IOS;
        isOldApp = true;
      } catch {
        //
      }
    }

    // console.log('이전 앱?', isOldApp);
    if (isOldApp) {
      // 앱 업데이트 유도

      dispatch(
        actions.common.alert({
          contents: `앱 스토어에서 새로운 버전으로 업데이트한 후 사용해주세요.`,
          confirmButtonText: '앱 업데이트',
          onConfirm: () => {
            // https 프로토콜의 경우 브라우저로 페이지가 랜딩 되었다 다시 이동, market scheme 사용시 바로 이동
            // location.href = 'itms-apps://itunes.apple.com/app/1457802907';
            // 비툴즈로부터 둘 다 사용가능하다는 답변O. 테스트해보니 안됨;
            console.log({ oldOsType });

            if (oldOsType === OSTYPE.APP_ANDROID) {
              // window.dawin_instance.bridge.osFunction.executeExternalBrowser(
              //   'market://play.google.com/store/apps/details?id=xyz.dawin.asset'
              // );

              location.href = 'https://play.google.com/store/apps/details?id=xyz.dawin.asset';
              console.log('android app download');
            } else if (oldOsType === OSTYPE.APP_IOS) {
              // window.dawin_instance.bridge.osFunction.executeExternalBrowser('itms-apps://itunes.apple.com/kr/app/id1457802907');

              location.href = 'https://itunes.apple.com/kr/app/id1457802907';
              console.log('ios app download');
            }
          },
          options: { strictClose: true },
        })
      );
    }

    // 앱스토어에 갔다가 되돌아 왔을때 등등 다양한경우
    // 업데이트가 안됐을 수 있기 때문에 location 변경시 마다 확인
  }, [location.href]);
};

export default useAppVersionCheck;

//아이폰
//     link = 'https://itunes.apple.com/kr/app/id1457802907';
//     // link = 'https://apps.apple.com/kr/app/apple-store/id1457802907';
//   } else if (ostype === OS_TYPES.MOBILE_WEB && userAgent.match('android')) {
//     //안드로이드 기기
//     link = 'https://play.google.com/store/apps/details?id=xyz.dawin.asset';

//아이폰
// link = 'https://itunes.apple.com/kr/app/id1457802907';
// // link = 'https://apps.apple.com/kr/app/apple-store/id1457802907';
// } else if (osType === OSTYPE.WEB_ANDROID) {
// //안드로이드 기기
// link = 'https://play.google.com/store/apps/details?id=xyz.dawin.asset';
