import { v1, v4 } from 'uuid';

// deviceId localstorage key
const LOG_DEVICE_ID = 'log_device_id';

/**
 * deviceId 생성
 * 앱: 네이티브 함수로 가져옴
 * 웹: uuid.v1 uuid.v4 결합한 문자열
 */
const getDeviceIdOrGenerate = () => {
  const deviceIdData = JSON.parse(localStorage.getItem(LOG_DEVICE_ID));
  let deviceId = deviceIdData?.value;

  if (!deviceId) {
    try {
      deviceId = window.dawin_instance.bridge.appInfoFunction.getDeviceId();
    } catch {
      deviceId = `${v1()}${v4()}`.replace(/-/gi, '');
    }
    localStorage.setItem(LOG_DEVICE_ID, JSON.stringify({ value: deviceId }));
  }

  return deviceId;
};

export default getDeviceIdOrGenerate;
