import React from 'react';

const Badge = ({ text, className, description }) => {
  return (
    <span className={`info-tag ${className}`} data-tooltip-text={description} onClick={(e) => e.stopPropagation()}>
      {text}
    </span>
  );
};

export default React.memo(Badge);
