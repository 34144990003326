import * as AT from './actionTypes';

export const isMobile = (state) => state?.common?.isMobile;
export const getComplexAreas = (state) => state?.common?.complexAreas;
export const getComplexArea = (state) => state?.common?.complexArea; //면적명, 평형명, 공금면적, 전용면적, 타입
export const getSearchResult = (state) => state?.common?.[AT.SEARCH_KEYWORD].getOrElse(false);
export const showPleaseLoginPopup = (state) => state?.common?.pleaseLoginPopup;
export const getPleaseLoginPopupOption = (state) => state?.common?.pleaseLoginPopupOption;
export const showLoginPopup = (state) => state?.common?.loginPopup;
export const getActionAfterLogin = (state) => state?.common?.actionAfterLogin;

export const getFaqList = (state) => state?.common?.faqList;
export const getQnaCategoryList = (state) => state?.common?.qnaCategoryList;
export const getAddQnaState = (state) => state?.common?.[AT.ADD_QUESTION].which();

//중개사 신고하기 > 중개사 목록 조회용
export const getReportBrokerState = (state) => state?.common?.[AT.REPORT_BROKER].which();
export const getMyBrokerList = (state) => state?.common?.myBrokerList;
export const getSearchBrokerList = (state) => state?.common?.searchBrokerList;

export const getGlobalDualPopup = (state) => state?.common?.openPopup;

// Info Popups
export const getAlert = (state) => state?.common?.alert;
export const getConfirm = (state) => state?.common?.confirm;

export const getGlobalGallery = (state) => state?.common?.globalGallery;

export const openMobileFavoBox = (state) => state?.common?.openMobileFavoBox;
export const getTempFavCompList = (state) => state?.common?.tempFavCompList;
export const getFavComplexLocList = (state) => state?.common?.favComplexLocList;
export const getTempFavGoodsList = (state) => state?.common?.tempFavGoodsList;
export const getTempFavLocalList = (state) => state?.common?.tempFavLocalList;

export const getCertificatedInfo = (state) => state?.common?.certificatedInfo;

export const getIsDistrictViewOn = (state) => state?.common?.isDistrictViewOn;

export const showSpinner = (state) => state.common.showSpinner;
