import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const getAddrSiDo = (only_available = false, only_broker = false, payload) => ({
  // 시, 도 목록 받아오기.
  type: AT.ADDRESS_GET_ADDR_SiDo,
  only_available,
  only_broker,
  payload,
});

export const setAddrSiDo = (list) => ({
  // 시, 도 목록 받아온거 저장
  type: AT.ADDRESS_SET_ADDR_SiDo,
  list,
});

export const changeSiDoCode = (si_do_code, si_do_name) => ({
  // 사용자 선택에 따라 시, 도 code 변경 (step1)
  type: AT.ADDRESS_CHANGE_SiDoCode,
  si_do_code,
  si_do_name,
});

export const changeSiDoName = (si_do_name) => ({
  type: AT.ADDRESS_CHANGE_SiDoName,
  si_do_name,
});

//  export const setItemInfo = (itemInfo) => ({ type: AT.ADDRESS_SET_ITEM_INFO, itemInfo });

// // 매물 정보를 하나의 객체로 관리, 키 값을 받아서 해당 키값의 데이터를 수정하는 형태
export const changeItemInfo = (targetDataKey, changeContent, gubun) => ({
  type: AT.ADDRESS_CHANGE_ITEM_INFO,
  targetDataKey,
  changeContent,
  gubun,
});

export const getAddrSiGunGu = (sido_code, only_available = false, only_broker = false, payload) => ({
  // 시, 군, 구 목록 받아오기.
  type: AT.ADDRESS_GET_ADDR_SiGunGu,
  sido_code,
  only_available,
  only_broker,
  payload,
});

export const getAddrSiGunGuFirst = (sido_code, payload) => ({
  // 시, 군, 구1 목록 불러오기
  type: AT.ADDRESS_GET_ADDR_SiGunGuFirst,
  sido_code,
  payload,
});

export const getAddrSiGunGuSecond = (sigungu_four_digit_code, payload) => ({
  // 시, 군, 구2 목록 불러오기
  type: AT.ADDRESS_GET_ADDR_SiGunGuSecond,
  sigungu_four_digit_code,
  payload,
});

export const setAddrSiGunGuFirst = (list) => ({
  // 시, 군, 구1 목록 받아온거 저장.
  type: AT.ADDRESS_SET_ADDR_SiGunGuFirst,
  list,
});

export const setAddrSiGunGuSecond = (list) => ({
  // 시, 군, 구 목록2 받아온거 저장.
  type: AT.ADDRESS_SET_ADDR_SiGunGuSecond,
  list,
});

// 시군구 첫번쨰 select박스에 보여줄 코드를 저장해놓음. 2번쨰 시군구 선택하면 5번째 코드가 변경되며
// 시군구 코드 5자리가 변경되므로
export const setSiGunGuFirstCode = (si_gun_gu_first_code) => ({
  type: AT.ADDRESS_SET_SiGunGuFirstCode,
  si_gun_gu_first_code,
});

export const setDongRiFirstCode = (dong_ri_first_code) => ({
  type: AT.ADDRESS_SET_DongRiFirstCode,
  dong_ri_first_code,
});

export const setRiCode = (ri_code) => ({
  type: AT.ADDRESS_SET_RiCode,
  ri_code,
});

export const setAddrSiGunGu = (list) => ({
  // 시, 군, 구 목록 받아온거 저장.
  type: AT.ADDRESS_SET_ADDR_SiGunGu,
  list,
});

export const changeSiGunGuCode = (si_gun_gu_code, si_gun_gu_name) => ({
  // 사용자 선택에 따라 시, 군, 구 code 변경 (step1)
  type: AT.ADDRESS_CHANGE_SiGunGuCode,
  si_gun_gu_code,
  si_gun_gu_name,
});

/**
 * 2021-08-13 장혁진 추가
 * sigungu code를 2단계 쪼개고
 * 첫번째 검증때 필요한 코드
 */
export const changeSiGunGuFourDigitCode = (si_gun_gu_four_digit_code) => ({
  type: AT.ADDRESS_CHANGE_SiGunGuFourDigitCode,
  si_gun_gu_four_digit_code,
});

export const changeSiGunGuName = (si_gun_gu_name) => ({
  type: AT.ADDRESS_CHANGE_SiGunGuName,
  si_gun_gu_name,
});

//ADDRESS_CHANGE_SiGunGuNameSecond
export const changeSiGunGuNameSecond = (si_gun_gu_name_second) => ({
  type: AT.ADDRESS_CHANGE_SiGunGuNameSecond,
  si_gun_gu_name_second,
});

// 동, 리 1단계 가져오기
export const getAddrDongRiFirst = (si_gun_gu_code, payload) => ({
  type: AT.ADDRESS_GET_ADDR_DongRiFirst,
  si_gun_gu_code,
  payload,
});

// 동, 리 2단계 가져오기
export const getAddrDongRiSecond = (dongri_eight_digit_code, payload) => ({
  type: AT.ADDRESS_GET_ADDR_DongRiSecond,
  dongri_eight_digit_code,
  payload,
});

export const changeDongRiEightDigitCode = (dong_ri_eight_digit_code) => ({
  type: AT.ADDRESS_CHANGE_SiGunGuEightDigitCode,
  dong_ri_eight_digit_code,
});

export const getAddrDongRi = (si_gun_gu_code, only_available = false, only_broker = false, payload) => ({
  // 동, 리 목록 받아오기.
  type: AT.ADDRESS_GET_ADDR_DongRi,
  si_gun_gu_code,
  only_available,
  only_broker,
  payload,
});

export const setAddrDongRi = (list) => ({
  // 동, 리 목록 받아온거 저장.
  type: AT.ADDRESS_SET_ADDR_DongRi,
  list,
});

export const setAddrDongRiSecond = (list) => ({
  // 동, 리 목록2 받아온거 저장.
  type: AT.ADDRESS_SET_ADDR_DongRiSecond,
  list,
});

export const changeDongRiCode = (dong_ri_code, dong_ri_name) => ({
  // 사용자 선택에 따라 동, 리 code 변경 (step1)
  type: AT.ADDRESS_CHANGE_DongRiCode,
  dong_ri_code,
  dong_ri_name,
});

// export const storeSavedForm = (savedForm) => ({
//   type: AT.ADDRESS_MANUAL_STORE_SAVED_FORM,
//   savedForm,
// });
export const updateSavedForm = (payload) => ({
  // payload = { field: value }
  type: AT.ADDRESS_MANUAL_UPDATE_SAVED_FORM,
  payload,
});
// export const updateSavedFormDB = (payload) => ({
//   // payload = { field: value }
//   type: AT.ADDRESS_MANUAL_UPDATE_SAVED_FORM_DB,
//   payload,
// });
// export const registerSavedForm = (payload) => ({
//   type: AT.ADDRESS_MANUAL_REGISTER_SAVED_FORM,
//   payload,
// });
export const clearAddress = () => ({
  type: AT.ADDRESS_CLEAR,
});
// export const getCompleteForm = (payload) => ({
//   type: AT.ADDRESS_MANUAL_GET_COMPLETE_FORM,
//   payload,
// });
// export const updateCompleteForm = (payload) => ({
//   type: AT.ADDRESS_MANUAL_UPDATE_COMPLETE_FORM,
//   payload,
// });

// // 단지 세부정보 ============================ //
// // 단지 목록 가져오기 && 단지 선택 선택 && 단지 정보, 위치 정보 가져오기
export const getComplexList = (payload) => ({ type: AT.ADDRESS_GET_COMPLEX_LIST, payload });
export const getComplexListLoading = () => ({
  type: loading(AT.ADDRESS_GET_COMPLEX_LIST),
});
export const getComplexListSuccess = (complexList) => ({
  type: success(AT.ADDRESS_GET_COMPLEX_LIST),
  complexList,
});
export const getComplexListFailure = (error) => ({
  type: failure(AT.ADDRESS_GET_COMPLEX_LIST),
  error,
});

export const changeComplexIdx = (complexIdx) => ({ type: AT.ADDRESS_CHANGE_COMPLEX_IDX, complexIdx });
export const getComplexInfo = () => ({ type: AT.ADDRESS_GET_COMPLEX_INFO });
export const setComplexInfo = (info) => ({ type: AT.ADDRESS_SET_COMPLEX_INFO, info });

// 배지율 추가 ======= //
/**
 * 시도 목록 받아오기
 * @param {boolean} onlyAvailable 서비스 가능 지역만
 * @param {boolean} onlyBroker ??
 * @param {function} setter { status, result } 형식 json 데이터를 전달받는 function.
 *    status: LOADING, SUCCESS, FAILURE 세가지 중 하나의 상태
 *    result: api 조회 결과 (시도 리스트)
 *    + setter가 주어지지 않는 경우 redux state의 address에 global하게 저장
 */
export const getSidoList = ({ onlyAvailable = false, onlyBroker = false, setter, showGlobalSpinner }) => ({
  type: AT.GET_SIDO_LIST,
  onlyAvailable,
  onlyBroker,
  setter,
  showGlobalSpinner,
});

/**
 * 시군구 목록 받아오기
 * @param {} sidoCode
 * @param {function} setter { status, result } 형식 json 데이터를 전달받는 function.
 *    status: LOADING, SUCCESS, FAILURE 세가지 중 하나의 상태
 *    result: api 조회 결과 (시도 리스트)
 *    + setter가 주어지지 않는 경우 redux state의 address에 global하게 저장
 */
export const getSigunguList1 = ({ sidoCode, setter, showGlobalSpinner }) => ({
  type: AT.GET_SIGUNGU_LIST1,
  sidoCode,
  setter,
  showGlobalSpinner,
});

// 두번째 시군구 코드 리스트 조회, 없는 경우 동 리스트 조회
export const getSigungu2OrDongList = ({ sigunguCode, sigungu2Setter, dongSetter, showGlobalSpinner }) => ({
  type: AT.GET_SIGUNGU2_OR_DONG_LIST,
  sigunguCode,
  sigungu2Setter,
  dongSetter,
  showGlobalSpinner,
});

export const getSigunguList2 = ({ sigunguCode, setter, showGlobalSpinner }) => ({
  type: AT.GET_SIGUNGU_LIST2,
  sigunguCode,
  setter,
  showGlobalSpinner,
});

export const getDongList1 = ({ sigunguCode, setter, showGlobalSpinner }) => ({
  type: AT.GET_DONG_LIST1,
  sigunguCode,
  setter,
  showGlobalSpinner,
});

export const getDongList2 = ({ dongri_eight_digit_code, setter, showGlobalSpinner }) => ({
  type: AT.GET_DONG_LIST2,
  dongri_eight_digit_code,
  setter,
  showGlobalSpinner,
});

export const getComplexListByDong = ({ dongCode, setter, showGlobalSpinner }) => ({
  type: AT.GET_COMPLEX_LIST_BY_DONG,
  dongCode,
  setter,
  showGlobalSpinner,
});

export const getComplexLocation = () => ({ type: AT.ADDRESS_GET_COMPLEX_LOCATION });
// [[NEW LOGIC]]-----------------------------------------------------------
export const getComplexLocation2 = () => ({ type: AT.ADDRESS_GET_COMPLEX_LOCATION2 });
// [[NEW LOGIC]]-----------------------------------------------------------
export const getComplexLocationLoading = () => ({
  type: loading(AT.ADDRESS_GET_COMPLEX_LOCATION),
});
export const getComplexLocationSuccess = (complexLocation) => ({
  type: success(AT.ADDRESS_GET_COMPLEX_LOCATION),
  complexLocation,
});
export const getComplexLocationFailure = (error) => ({
  type: failure(AT.ADDRESS_GET_COMPLEX_LOCATION),
  error,
});

// ============================ 단지 세부정보 ============================ //

export const getComplexDongList = () => ({ type: AT.ADDRESS_GET_COMPLEX_DONG_LIST });
// [[NEW LOGIC]]-----------------------------------------------------------
export const getComplexDongList2 = (payload) => ({ type: AT.ADDRESS_GET_COMPLEX_DONG_LIST2, payload });
// [[NEW LOGIC]]-----------------------------------------------------------
export const getComplexDongListLoading = () => ({
  type: loading(AT.ADDRESS_GET_COMPLEX_DONG_LIST),
});
export const getComplexDongListSuccess = (complexDongList) => ({
  type: success(AT.ADDRESS_GET_COMPLEX_DONG_LIST),
  complexDongList,
});
export const getComplexDongListFailure = (error) => ({
  type: failure(AT.ADDRESS_GET_COMPLEX_DONG_LIST),
  error,
});

export const changeDongNo = (dongNo) => ({ type: AT.ADDRESS_CHANGE_DONG_NO, dongNo });

export const getFloorList = () => ({ type: AT.ADDRESS_GET_FLOOR_LIST });
export const getFloorList2 = (payload) => ({ type: AT.ADDRESS_GET_FLOOR_LIST2, payload });
export const setFloorList = (list) => ({ type: AT.ADDRESS_SET_FLOOR_LIST, list });
export const changeFloorNo = (floorNo) => ({ type: AT.ADDRESS_CHANGE_FLOOR_NO, floorNo });

/**
 * refactor @장혁진
 * selling에서 집내놓기에만 쓰고 address에서는 구조 그대로 가져와 사용안하고 정의만 되있는 듯?
 *
 */

/**
 * TODO: 사용 안하는 듯 보여 삭제?!
 */
export const getHoList = () => ({ type: AT.ADDRESS_GET_HO_LIST });
export const getHoList2 = (payload) => ({ type: AT.ADDRESS_GET_HO_LIST2, payload });
export const setHoList = (list) => ({ type: AT.ADDRESS_SET_HO_LIST, list });
export const changeHoNo = (hoNo) => ({ type: AT.ADDRESS_CHANGE_HO_NO, hoNo });

export const setItemSizeList = (itemSizeList) => ({ type: AT.ADDRESS_SET_ITEM_SIZE_LIST, itemSizeList });
export const changeItemSize = (itemSize) => ({ type: AT.ADDRESS_CHANGE_ITEM_SIZE, itemSize });
