import { selectApiLoadingStatus } from '@/data/loading/selectors';
import { pipe, useWith, values, groupBy, flatten, merge, curry, prop } from 'ramda';

export const loading = (action) => `${action}_LOADING`;

export const failure = (action) => `${action}_FAILURE`;

export const success = (action) => `${action}_SUCCESS`;

export const notAsked = (action) => `${action}_NOT_ASKED`;

export const createLoadingSelector = (actions) => (state) => actions.some((action) => selectApiLoadingStatus(state)[action]);

export const mergeTo = curry((fn, l, r) => pipe(useWith(merge, [groupBy(fn), groupBy(fn)])(l), values, flatten)(r));

export const mergeListBySeq = mergeTo(prop('seq'));

export const byCreateAt = (left, right) => +new Date(right.createAt) - +new Date(left.createAt);

export const STATUS = { NOTASKED: 'NOTASKED', LOADING: 'LOADING', SUCCESS: 'SUCCESS', FAILURE: 'FAILURE' };
