import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import SocialShareGroup from '@/components/popups/SocialSharePopup/components/SocialShareGroup';
import Mask from '@/components/Mask';
import toggle from '@/hocs/toggle';

/**
 * @param {Funtion} setShow
 * @param {Object} shareData 
   {
    popupTitle : 팝업 제목 (default. 공유하기),
    popupDesc : 팝업 설명,
    templateType : 팝업 템플렛 종류,
    templateId : 팝업 템플렛 아이디,
    templateArgs : 팝업 템플렛 데이터,
    oneLineMessage : 한줄 메세지,
    }
  */
const SocialSharePopup = ({ setShow, shareData }) => {
  const isMobile = useSelector(selectors.common.isMobile);

  const { popupTitle, popupDesc } = shareData || {};

  const closeSharePopup = () => {
    setShow(false);
  };

  return (
    <>
      <Mask show={true} />

      <div className="lyr" style={{ zIndex: '5500', padding: '20px', borderRadius: '5px', maxWidth: '350px' }}>
        {/* 닫기 버튼 */}
        <span style={{ position: 'absolute', right: isMobile ? '10px' : '20px', top: isMobile ? '5px' : '25px' }}>
          <a className="btn-img btn-close" onClick={closeSharePopup}></a>
        </span>

        {/* 팝업 타이틀 */}
        <div style={{ textAlign: 'left', color: 'black', fontSize: '19px', fontWeight: 530 }}>{popupTitle || '공유하기'}</div>

        {/* 안내문구 */}
        {popupDesc && <div style={{ paddingTop: '26px', textAlign: 'center', color: 'black', fontSize: '17px' }}>{popupDesc}</div>}

        {/* 카카오/페이스북/링크 공유 버튼 */}
        <div style={{ padding: '20px 10px 10px', width: '100%', height: '100%', boxSizing: 'border-box', textAlign: 'center' }}>
          <div className="share" style={{ wordBreak: 'keep-all' }}>
            <SocialShareGroup shareData={shareData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default toggle(SocialSharePopup);
