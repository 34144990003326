import { useCallback } from 'react';

const useKeyboardEvent = ({ maxLength, highlightIdx, setHighlightIdx, search, setShow, setKeyword }) => {
  const handleKeyboardEvent = useCallback(
    (e) => {
      const code = e.keyCode;

      if (code === 40 || code === 38 || code === 27 || code === 13) {
        e.preventDefault();
      }

      if (code === 40 && maxLength > highlightIdx + 1) {
        //아래
        setHighlightIdx(highlightIdx + 1);
      } else if (code === 38 && highlightIdx > 0) {
        //위
        setHighlightIdx(highlightIdx - 1);
      } else if (code === 13 && highlightIdx > -1) {
        //엔터
        search(highlightIdx);
        e.target.blur();
      } else if (code === 27) {
        //esc
        setShow(false);
        e.target.blur();
      }
    },
    [maxLength, highlightIdx]
  );

  return { handleKeyboardEvent };
};

export default useKeyboardEvent;
