import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import * as actions from '@/data/rootActions';
import { api } from '@/apis/community';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import useGetBrokerStatus from '@/pages/Community/hooks/useGetBrokerStatus';

const CommentInput = ({ commentCount, setCommentCount, feedData }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const nickname = useSelector(selectors.auth.getNickname);
  const detail_item = useSelector(selectors.community.getFeedDetailItem);
  const comments = useSelector(selectors.community.getComments);
  const inputRef = useRef();

  const isBroker = userInfo?.mbr_type === 'BU';
  const query = parseCallBack();
  query.nicknameSetting = 'community';

  const showNicknamePopup = () => {
    if (!isBroker && !nickname) {
      dispatch(actions.router.push(`${location.pathname}${objectToQuerystring(query)}`));
    }
  };

  const commentHandler = (e) => {
    e.preventDefault();
    setComment(e.target.value);
  };

  const onPressEnter = (e, feedId) => {
    if (e.key === 'Enter') {
      onCreateComment(e, feedId);
    }
  };

  const createComment = (feedId) => {
    if (detail_item && !detail_item.feedId) {
      (async () => {
        let res = await api.createTradesFeedId(detail_item.real_price_idx);
        dispatch(actions.community.createComment({ feedId: res.feedId, contents: comment }));
        setComment('');
        dispatch(actions.community.getFeed(res.feedId));
        setCommentCount(commentCount + 1);
        dispatch(actions.buying.detail.updateNewFeed(true));
      })();
    } else {
      (async () => {
        dispatch(actions.community.createComment({ feedId: feedId, contents: comment }, comments));
        setComment('');
        setCommentCount(commentCount + 1);
        dispatch(actions.buying.detail.updateNewFeed(true));
      })();
    }
  };

  const onCreateComment = (e, feedId) => {
    e.preventDefault();
    // (dawon) 유저 아이디가 없으면 상세페이지를 아예 못들어오지않나?
    if (!userInfo) {
      dispatch(actions.common.pleaseLoginPopup(true));
    } else if (comment) {
      if (nickname || userInfo?.mbr_type === 'BU') {
        createComment(feedId);
      } else {
        dispatch(actions.router.push(`${location.pathname}${objectToQuerystring(query)}`));
      }
    } else {
      dispatch(
        actions.common.alert({
          contents: '내용을 입력해주세요.',
        })
      );
    }
  };

  const [showBtns, setShowBtns] = useState(false);
  const [textareaDisabled, setTextareaDisabled] = useState(false);

  // 중개사 승인 상태 가져오기
  const { approval_status } = useGetBrokerStatus({ isBroker });

  const handleFocus = () => {
    if (isBroker) {
      if (approval_status === 'PS1' || approval_status === 'PS2') {
        dispatch(
          actions.common.alert({
            contents: '중개사 승인 후 작성가능합니다.',
          })
        );
        setTextareaDisabled(true);
        return;
      } else if (approval_status === 'CN' || approval_status === 'CA') {
        dispatch(
          actions.common.alert({
            contents: '중개사 정보 변경 승인 후 작성 가능합니다.',
          })
        );
        setTextareaDisabled(true);
        return;
      }
    }
    showNicknamePopup();
    setShowBtns(true);
  };
  return (
    <form className="comment-write">
      <fieldset className="submit-form">
        <legend>댓글 입력 서식</legend>
        <div className="form-list">
          <div className="form-item">
            <div className="form-wrap">
              <div className="form-head">
                <span className="form-name">댓글 내용</span>
              </div>
              <div className="form-body">
                <div className="form-area">
                  <span className="form textarea module-b style-b type-line normal-04 large-1x flex" data-bui-form-value="test">
                    <textarea
                      // onFocus={() => showNicknamePopup()}
                      onFocus={handleFocus}
                      className="form-elem"
                      rows="1"
                      placeholder="내용을 입력해주세요"
                      ref={inputRef}
                      value={comment}
                      onChange={commentHandler}
                      maxLength={1000}
                      onKeyPress={(e) => onPressEnter(e, feedData?.id)}
                      // onClick={() => setShowBtns(true)}
                      disabled={textareaDisabled}></textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showBtns ? (
          <div className="form-util">
            <div className="button-display">
              <span className="button-area">
                <button className="btn module-b style-b type-line normal-04 medium" type="button" onClick={() => setShowBtns(false)}>
                  <span className="btn-text">취소</span>
                </button>
                <button className="btn module-b style-b type-fill accent-02 medium" type="submit" onClick={(e) => onCreateComment(e, feedData?.id)}>
                  <span className="btn-text">등록</span>
                </button>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </fieldset>
    </form>
  );
};

export default CommentInput;
