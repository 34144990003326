import React, { useEffect } from 'react';
import toggle from '@/hocs/toggle';
import { GoodsTypeKor } from '@/services/Community';
import { EVERY_BUILDINGS } from '@/combinedComp/DualAddress';

const SearchResult = ({ data, setShow, highlightIdx, setHighlightIdx, search, wrapperClass = 'data-board data-finder-autocomplete' }) => {
  useEffect(() => {
    return () => {
      setHighlightIdx(-1);
    };
  }, []);

  let list = data && data.list;

  return <ResultList list={list} setShow={setShow} highlightIdx={highlightIdx} search={search} wrapperClass={wrapperClass} />;
};

export default toggle(SearchResult);

// const resultListZIndex = 1000;
const ResultList = ({ list, setShow, highlightIdx, search, wrapperClass }) => {
  const resultList =
    list &&
    list.map((item, key) => {
      return <Item key={key} order={key} setShow={setShow} isHighlight={highlightIdx === key} search={search} item={item} />;
    });
  return (
    // style={{ zIndex: resultListZIndex, border: '1px solid #d9d9d9', left: 'unset', top: 'unset' }}
    <div className={wrapperClass} style={{ display: 'unset' }}>
      <div className="board-wrap">
        <div className="board-head">
          <p className="board-subject">
            <strong className="board-name">추천 검색어</strong>
          </p>
        </div>
        <div className="board-body">
          {/* <!-- data-display --> */}
          <div className="data-display">
            {resultList && resultList?.length > 0 ? (
              <ul className="data-list">
                <>{resultList}</>
              </ul>
            ) : (
              <p className="para no-data">검색결과값이 없습니다.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

//STREET :수소 검색, STITION :역검색, APT 아파트 단지 검색, OFT : 오피스텔검색
const Item = ({ isHighlight, search, item, order }) => {
  const { addr, title, icontype } = item;

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    search(order);
  };

  const isBuilding = EVERY_BUILDINGS?.includes(icontype);

  return (
    <li className="data-item" id={'mainSearchResultItem_' + order}>
      <div className="data-wrap">
        <a className="data-head" onClick={handleClick} style={isHighlight ? { background: '#f0f0f0', display: 'block', width: '100%' } : {}}>
          <span className="data-subject">
            <span className="data-name">{title}</span>
            {isBuilding ? (
              <span className="mark module-a style-c type-fill normal-04 small-2x">
                <span className="mark-text">{GoodsTypeKor[icontype]}</span>
              </span>
            ) : (
              false
            )}
          </span>
          <span className="data-summary" style={{ display: 'block', color: '#888', fontSize: '13px' }}>
            {addr}
          </span>
        </a>
      </div>
    </li>
  );
};
