import { ReportTypeKor } from '@/services/code/reportCommunity.js';
import React, { useState } from 'react';

const ReportOptions = ({ payload, setPayload }) => {
  const reportReasons = ['A', 'B', 'C', 'D'];
  const [viewTextArea, setViewTextArea] = useState(false);
  const [selectedReason, setSelectedReason] = useState();

  const selectedCheck = (e) => {
    const reportReason = e.target.value;

    if (reportReason === 'E') {
      setViewTextArea(true);
      setPayload({ ...payload, reason: '' });
    } else {
      setViewTextArea(false);
      setPayload({ ...payload, reason: ReportTypeKor[reportReason] });
    }

    setSelectedReason(reportReason);
  };

  const changeTextArea = (e) => {
    setPayload({ ...payload, reason: e.target.value });
  };

  return (
    <div className="option-list">
      {reportReasons?.map((reason, index) => (
        <>
          <div className="option-item" key={index}>
            <div className="option-wrap">
              <span className="option-head">
                <span className="option-name">
                  <label className="form radio module-a style-c type-line accent-01 small-1x">
                    <input
                      type="radio"
                      className="form-elem"
                      name="radio-report-posts"
                      id="reportReason"
                      value={reason}
                      onChange={(e) => selectedCheck(e)}
                    />
                    <span className="form-text">{ReportTypeKor[reason]}</span>
                  </label>
                </span>
              </span>
            </div>
          </div>
        </>
      ))}

      <div className={`option-item ${viewTextArea ? 'checked-within' : ''}`}>
        <div className="option-wrap">
          <span className="option-head">
            <span className="option-name">
              <label className="form radio module-a style-c type-line accent-01 small-1x">
                <input type="radio" className="form-elem" name="radio-report-posts" id="reportReason" value="E" onChange={(e) => selectedCheck(e)} />
                <span className="form-text">{ReportTypeKor['E']}</span>
              </label>
            </span>
          </span>
        </div>
        {viewTextArea && selectedReason === 'E' ? (
          <div className="option-side">
            <span className="form textarea module-b style-b type-line normal-04 large-1x flex">
              <textarea className="form-elem" rows="6" placeholder="기타사유를 자세하게 입력해주세요." onChange={changeTextArea}></textarea>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ReportOptions;
