import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '@/data';
import Checkbox from '@/components/Checkbox';
import { SIZE_MARK_TYPE } from '@/resources/global';

function useFavoBox(idx, setToggle) {
  const dispatch = useDispatch();

  const isMobile = useSelector(selectors.common.isMobile);
  const sizemark = useSelector(selectors.buying.getSizemark);
  const markname = sizemark === SIZE_MARK_TYPE.METER ? 'area_name' : 'pyeong_name';

  const cmplxList = useSelector(selectors.common.getComplexAreas);
  const list = cmplxList[idx] ? cmplxList[idx] : [];

  const initial = useMemo(() => {
    let result = list.filter((i) => i.is_favorite).map((i) => '' + i.area_no);
    if ((list.length > 0 && result.length === list.length) || result.length === 0) {
      result = ['all'];
    }
    return result;
  }, [cmplxList]);

  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    setSelectedList(initial);
  }, [initial]);

  const handleChange = (e) => {
    let arr = null;
    // console.log('test클릭');
    const code = e.currentTarget.value;
    if (selectedList.indexOf(code) < 0) {
      if (selectedList.indexOf('all') > -1 && selectedList.length === 1) {
        arr = [...selectedList.filter((i) => i !== 'all'), code];
      } else if ((selectedList.indexOf('all') < 0 && selectedList.length === list.length - 1) || code === 'all') {
        arr = ['all'];
      } else {
        arr = [...selectedList, code];
      }
    } else {
      arr = [...selectedList.filter((i) => i !== code && i !== 'all')];
    }
    setSelectedList(arr);
  };

  const onSubmit = () => {
    if (selectedList.length > 0) {
      let data = selectedList;
      if (selectedList.indexOf('all') > -1) {
        data = list.map((i) => i.area_no);
      }
      dispatch(actions.common.addFavComplex(idx, data));
      if (setToggle) {
        setToggle(false);
      }
    } else {
      dispatch(actions.common.alert({ contents: '관심단지를 하나 이상 선택해주세요.' }));
    }
  };

  const chkList = useMemo(() => {
    if (isMobile && list.length > 0) {
      return [
        <Fragment key="all">
          {/* <div className="chk01">
            <input
              type="checkbox"
              id="favo_complex_all"
              name="favo_complex_all"
              onChange={handleChange}
              value="all"
              checked={selectedList.indexOf('all') > -1}
            />
            <label htmlFor="favo_complex_all" style={{ cursor: 'pointer' }}>
              전체
            </label>
          </div> */}
          <Checkbox
            id="favo_complex_all"
            name="favo_complex_all"
            value="all"
            onChange={handleChange}
            className="fit"
            text="전체"
            checked={selectedList.indexOf('all') > -1}
          />
        </Fragment>,
        ...list.map((item, index) => {
          return (
            <Checkbox
              key={index}
              name={'favo_complex_' + item.complex_idx}
              id={'favo_complex_' + item.complex_idx + '_' + index}
              value={item.area_no}
              onChange={handleChange}
              text={item[markname]}
              selected={selectedList.indexOf('' + item.area_no) > -1}
            />
            // <>
            //   <input
            //     type="checkbox"
            //     id={'favo_complex' + index}
            //     name={'favo_complex' + index}
            //     onChange={handleChange}
            //     value="all"
            //     checked={selectedList.indexOf('all') > -1}
            //   />
            //   <label htmlFor={'favo_complex' + index} style={{ cursor: 'pointer' }}>
            //     전체
            //   </label>
            // </>
          );
        }),
      ];
    } else if (!isMobile && list.length > 0) {
      return [
        <Fragment key="all">
          <Checkbox id="favo_complex_all" name="favo_complex_all" value="all" onChange={handleChange} className="fit" text="전체" checked={true} />
        </Fragment>,
      ];
    } else {
      return <div>단지 정보를 불러오는 중입니다.</div>;
    }
  }, [list, selectedList, markname]);

  return [chkList, onSubmit];
}

export default useFavoBox;
