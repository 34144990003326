import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import router from '@/services/router';
import utils from './utils';
import { DEV } from '@/resources/env';
import subjects from '@/services/contact/chat/subjects';
import useAsyncPopup from '../useAsyncPopup';
import useAsyncConfirm from '@/components/popups/InfoPopups/Confirm/hooks/useAsyncConfirm';
import { openWindow } from '@/services/apps/window';
import Paragraph from '@/components/Paragraph';

/**
 * 일반유저와 중개사 간 연락(전화, 채팅) 공통로직
 *
 * @param {boolean} fromBrokerToGuest true인 경우 중개사가 일반유저에게 연락을 취하는 방식으로 동작.
 * @param {object} userInfo 로그인한 현재 유저 정보
 *
 * @returns {object} { chat, call } 채팅하기 함수, 전화하기 함수
 */
const useCommonContact = (fromBrokerToGuest = false, userInfo) => {
  const dispatch = useDispatch();

  const isBroker = userInfo?.isBroker;

  const chatBaseUrl = fromBrokerToGuest ? '/brokerPage/chat' : '/chat';

  const controller = useSelector(selectors.chat.getController);

  const userValidCheck = () => {
    if (!userInfo) {
      dispatch(actions.common.pleaseLoginPopup(true));
      return false;
    } else if (!fromBrokerToGuest && isBroker) {
      dispatch(
        actions.common.alert({
          contents: (
            <>
              <Paragraph values={['현재 중개사회원 계정으로 로그인되어 있습니다.']} />
              <Paragraph values={['개인적인 목적으로 중개상담을 이용하시려면, 일반 회원으로 로그인해주세요.']} />
            </>
          ),
        })
      );
      return false;
    } else {
      return true;
    }
  };

  const chat = useCallback(
    async (targetUserId, chatParam = { subjectType: subjects.codes.CONSULTING }, option = {}) => {
      const isValid = userValidCheck(userInfo);

      if (!isValid) return false;

      async function startChat() {
        const userIds = [`${targetUserId}`];

        try {
          dispatch(actions.common.toggleGlobalSpinner(true));
          const response = await utils.contactApis.createChatRoom({ userIds, ...chatParam });

          // 채팅 목록 최신 상태로 업데이트
          await controller.reloadRooms();

          if (option.newWindowOpen) {
            const url = `${chatBaseUrl}/${response?.roomId}`;
            openWindow(url, { isDawinUrl: true });
          } else {
            dispatch(router.push(`${chatBaseUrl}/${response?.roomId}`));
          }
          dispatch(actions.common.toggleGlobalSpinner(false));
        } catch (e) {
          dispatch(actions.common.toggleGlobalSpinner(false));
          dispatch(actions.common.alert({ contents: e.message }));
        }
      }

      const CHAT_NOTI_DATE = 'chatNotiDate';

      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - 14);
      const twoWeeksAgo = date.getTime();
      const savedTime = localStorage.getItem(CHAT_NOTI_DATE);

      if (fromBrokerToGuest || (savedTime && Number(savedTime) > twoWeeksAgo)) {
        startChat();
      } else {
        dispatch(
          actions.common.alert({
            contents: (
              <>
                <div className="board-head">
                  <p className="board-subject">
                    <span className="board-name">채팅 전에 알아두세요!</span>
                  </p>
                </div>
                <div className="board-body">
                  <p className="para">
                    <span className="wbr">중개사님의 상담이력 관리를 위해</span>
                    <span className="wbr">고객님의 성함만 채팅방에 노출됩니다.</span>
                    <span className="wbr">고객님의 전화번호는 중개사님께 전달되지 않습니다.</span>
                  </p>
                </div>
              </>
            ),
            onConfirm: () => {
              startChat();

              const date = new Date();
              date.setHours(0, 0, 0, 0);
              const epochtime = date.getTime().toString();

              localStorage.setItem(CHAT_NOTI_DATE, epochtime);
            },
          })
        );
      }
    },
    [userInfo]
  );

  /**
   * @param {string} targetUserPhoneNumber 상대방 전화번호
   * @param {string} targetUserName 상대방 이름. 유저가 중개사에게 전화하는 경우 office_name, 중개사가 유저에게 전화하는 경우 mbr_name
   * @param {string} targetUserId 상대방 id. 전화번호가 없어서 채팅으로 연결해야하는 경우 사용
   * @param {string} chatParam 채팅 파라미터. subjectType, subjectId, title 등이 올 수 있다.
   * @param {string} callOrChat 전화가 안되면 채팅 유도. default: true
   *
   */
  const call = useCallback(
    (targetUserPhoneNumber, { targetUserName, targetUserId, chatParam, callOrChat = true }) => {
      if (callOrChat && DEV && (!targetUserId || !chatParam)) throw Error('필수값 누락 (채팅이 필요없는 경우 callOrChat option을 false로 사용가능)');

      const isValid = userValidCheck(userInfo);

      if (!isValid) return false;

      const chatFunc = callOrChat
        ? () => {
            chat(targetUserId, chatParam, { userInfo });
          }
        : null;

      dispatch(utils.callActions.callOrChat(targetUserPhoneNumber, targetUserName, { chatFunc, callOrChat, fromBrokerToGuest }));
    },
    [userInfo, fromBrokerToGuest]
  );

  /**
   *
   */
  return { chat, call };
};

export default useCommonContact;
