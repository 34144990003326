import React from 'react';

const DongFloorPyeong = ({ dong, goodsFloor, groundFloor, pyeong }) => {
  return (
    <>
      {/* 동 */}
      <span className="info_dong">{dong}동</span>

      {/* 층 */}
      <span className="info_floor">
        {goodsFloor ? <b>{`${goodsFloor}층`}</b> : <></>}
        {groundFloor ? `(${groundFloor}층)` : <></>}
      </span>

      {/* 평 */}
      <span className="info_type">{pyeong}</span>
    </>
  );
};

export default React.memo(DongFloorPyeong);
