import { loading, success, failure } from '@/data/utils';

//지역설정
export const VIEW_CHECKED = 'BROKERPAGE/VIEW_CHECKED';
export const SAVE_ACTIVE_AREA = 'BROKERPAGE/SAVE_ACTIVE_AREA';
export const ADD_ACTIVE_AREA = 'BROKERPAGE/ADD_ACTIVE_AREA';
export const DEL_ACTIVE_AREA = 'BROKERPAGE/DEL_ACTIVE_AREA';
export const DELETE_AREA_DISTIRCT = 'BROKERPAGE/DELETE_AREA_DISTIRCT';
export const SAVE_SET_DEFAULT_AREA = 'BROKERPAGE/SAVE_SET_DEFAULT_AREA';

export const SET_PREV_POLYGONS = 'BROKERPAGE/SET_PREV_POLYGONS';
export const ADD_BROKER_DISTRICT = 'BROKERPAGE/ADD_BROKER_DISTRICT';

export const GET_POSSIBLE_AREA = 'BROKERPAGE/GET_POSSIBLE_AREA';
export const GET_POSSIBLE_AREA_LOADING = loading(GET_POSSIBLE_AREA);
export const GET_POSSIBLE_AREA_SUCCESS = success(GET_POSSIBLE_AREA);
export const GET_POSSIBLE_AREA_FAILURE = failure(GET_POSSIBLE_AREA);

export const GET_BROKER_AREA_DISTRICT = 'BROKERPAGE/GET_BROKER_AREA_DISTRICT';
export const GET_BROKER_AREA_DISTRICT_LOADING = loading(GET_BROKER_AREA_DISTRICT);
export const GET_BROKER_AREA_DISTRICT_SUCCESS = success(GET_BROKER_AREA_DISTRICT);
export const GET_BROKER_AREA_DISTRICT_FAILURE = failure(GET_BROKER_AREA_DISTRICT);

export const GET_BROKER_DISTRICT = 'BROKERPAGE/GET_BROKER_DISTRICT';
export const GET_BROKER_DISTRICT_LOADING = loading(GET_BROKER_DISTRICT);
export const GET_BROKER_DISTRICT_SUCCESS = success(GET_BROKER_DISTRICT);
export const GET_BROKER_DISTRICT_FAILURE = failure(GET_BROKER_DISTRICT);

export const SET_DATA_AREA = 'BROKERPAGE/SET_DATA_AREA';

export const GET_SET_AREA = 'BROKERPAGE/GET_SET_AREA';
export const GET_SET_AREA_LOADING = loading(GET_SET_AREA);
export const GET_SET_AREA_SUCCESS = success(GET_SET_AREA);
export const GET_SET_AREA_FAILURE = failure(GET_SET_AREA);
