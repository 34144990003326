import React, { useMemo, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import { geInitialData } from './utils';
import View1 from './views/View1';
import View2 from './views/View2';
import View3 from './views/View3';

export { geInitialData };

// 모드 (단지로검색, 주소로검색)
export const MODE = { TEXT: 'TEXT', SELECT: 'SELECT' };

// markTypes (모든 건물들)
export const EVERY_BUILDINGS = ['APT', 'OFT', 'ABYG', 'JGC', 'OBYG'];

// markTypes (타입별 그룹)
export const MARK_TYPES_BY_GROUP = {
  APT: ['APT', 'ABYG', 'JGC'],
  OFT: ['OFT', 'OBYG'],
};

// View1은 서서히 줄여나가고 삭제하는게 목표
// View2: 현재 마이페이지에만 적용되어있음.
// View3: 커뮤니티
export const VIEW_TYPE = { default: View1, my: View2, community: View3 };

/**
 * @param {string} viewType (optional) default: "default"
 * @param {object} data (required) { mode, selectedAddress, selectedComplex } 형태의 json
 * @param {function} setData (required)
 * { mode, selectedAddress, selectedComplex } 형태의 json 데이터를 전달받는 함수
 *   1. mode: TEXT (단지로 검색) | SELECT (주소로 검색)
 *   2. selectedAddress: 선택된 주소 정보
 *    - { sido: { code, name }, sigungu: { code, name }, dong: { code, name } }
 *   3. selectedComplex: 선택된 단지 정보
 *    - { complex_idx, complex_type, complex_name }
 *
 * @param {boolean} tenDigitCode (optional) address 값 반환 시 10자리로 반환
 * @param {string} title (optional) default: 단지검색, 주소검색
 * @param {string} searchInputPlaceholder (optional) default: "단지검색"/"주소검색"
 * @param {string} toSelectButtonText (optional) default: '주소로 검색하기'
 * @param {string} toTextButtonText (optional) default: '단지로 검색하기'
 * @param {function} afterChangeComplexCallback 단지 변경 후 실행해야하는 action
 * @param {Array} quickSettingButtons (optional) 빠른 세팅을 돕는 버튼 타입들 중 표출할 타입을 배열형태로 받는다.
 *    - 가능한 옵션: components/AddressSimpleSelect 참조
 *
 * mode가 TEXT일 때 유효한 옵션들
 * @param {Array} markTypes (optional) 표시할 단지 타입 리스트.
 * @param {function} markRule (optional) 필터링 함수. 검색 결과 전체를 파라미터로 받아
 *
 * mode가 SELECT일 때 유효한 옵션들
 * @param {boolean} addAllOption (optional) 전체 선택 옵션 on/off
 *
 */

export default function DualAddress({
  viewType = 'default',
  data,
  setData,
  tenDigitCode = false,
  title: _title,
  searchInputPlaceholder,
  toSelectButtonText,
  toTextButtonText,
  disabled = false, // 아직 쓰이는지 모르겠음.
  afterChangeComplexCallback,
  markTypes,
  markRule,
  addAllOption = false,
  tags,
  feedType,
  localAddressMode,
  quickSettingButtons = [],
}) {
  const View = VIEW_TYPE[viewType];

  const [brokerNumber, setBrokerNumber] = useState(null);

  const isMobile = useSelector(selectors.common.isMobile);

  // 탭
  const mode = useMemo(() => {
    return data?.mode || MODE.TEXT;
  }, [data?.mode]);

  const setMode = useCallback(
    (value) => {
      setData({ ...data, mode: value });
    },
    [data]
  );

  // 주소 데이터
  const addressData = useMemo(() => data?.selectedAddress || {}, [data?.selectedAddress]);
  const setAddressData = useCallback(
    (nextAddress) => {
      setData({ ...data, selectedAddress: nextAddress });
    },
    [data]
  );

  // 단지 데이터
  const complexData = useMemo(() => data?.selectedComplex || {}, [data?.selectedComplex]);
  const setComplexData = useCallback(
    (nextComplex) => {
      const nextData = { ...data, selectedComplex: { ...nextComplex } };
      setData(nextData);
      if (afterChangeComplexCallback) afterChangeComplexCallback(nextData);
    },
    [data]
  );

  const preventUpdateComplexData = useRef(data?.mode === MODE.SELECT ? true : false);

  // 모드 변경
  const changeMode = (e) => {
    e.preventDefault();

    if (!disabled) {
      const nextMode = mode === MODE.TEXT ? MODE.SELECT : MODE.TEXT;
      setMode(nextMode);

      if (nextMode === MODE.SELECT && complexData?.complex_idx) {
        // SELECT 모드로 전환할 때, 단지 정보가 있는 경우 -> 동 코드가 업데이트 되어도 초기화 로직을 타지 않기 위해
        preventUpdateComplexData.current = true;
      }
    }
  };

  // mode: TEXT 인 경우 데이터 변경 시 실행하는 함수
  const onSearchComplexSelected = (selected, additionalData) => {
    const dong_code = `${selected.b_dong_code}`;
    const nextData = geInitialData(
      dong_code,
      { complex_idx: selected?.complex_idx, complex_type: selected?.icontype, complex_name: selected?.title },
      mode
    );
    nextData.selectedAddress.sido.name = selected.sido_name;
    nextData.selectedAddress.sigungu.name = selected.sigungu_name;
    nextData.selectedAddress.dong.name = selected.dong_name;

    setData(nextData);

    if (additionalData) nextData.additionalData = additionalData;
    if (afterChangeComplexCallback) afterChangeComplexCallback(nextData);
  };

  // 동코드
  const dongCode = useMemo(() => `${addressData?.dong?.code ? addressData?.dong?.code : ''}`, [addressData]);

  // 텍스트
  const toSelect = toSelectButtonText ? toSelectButtonText : '주소로 검색하기';
  const toText = toTextButtonText ? toTextButtonText : '단지로 검색하기';
  const defaultTitle = mode === MODE.TEXT ? '단지검색' : '주소검색';
  const title = _title ? _title : defaultTitle;

  const props = {
    title,
    toSelect,
    toText,
    disabled,
    isMobile,
    markTypes,
    markRule,
    searchInputPlaceholder,
    mode,
    changeMode,
    complexData,
    setComplexData,
    addressData,
    setAddressData,
    onSearchComplexSelected,
    addAllOption,
    tenDigitCode,
    dongCode,
    preventUpdateComplexData,
    tags,
    feedType,
    brokerNumber,
    setBrokerNumber,
    localAddressMode,
    quickSettingButtons,
  };

  return <View {...props} />;
}
