import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//요약본
export const getList = () => ({
  type: AT.GET_JOINT_BROKERAGE_LIST,
});
export const getDetail = (idx) => ({
  type: AT.GET_JOINT_BROKERAGE_DETAIL,
  idx,
});
export const setAttach = (idx, file_name) => ({
  type: AT.SET_JOINT_NEW_ATTACH,
  idx,
  file_name,
});
export const setTitle = (val) => ({
  type: AT.SET_JOINT_NEW_TITLE,
  val,
});
export const setCont = (val) => ({
  type: AT.SET_JOINT_NEW_CONT,
  val,
});
export const setKeyword = (keyword) => ({
  type: AT.SET_JOINT_KEYWORD,
  keyword,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_JOINT_CURRENT_PAGE,
  page,
});
export const setTotalCnt = (totalCnt) => ({
  type: AT.SET_JOINT_TOTAL_CNT,
  totalCnt,
});
export const saveReply = (val, pu_idx) => ({
  type: AT.SAVE_JOINT_REPLY,
  val,
  pu_idx,
});
export const saveNew = () => ({
  type: AT.SAVE_JOINT_NEW,
});
export const editBoard = (board_idx, title, content, setViewEditWrite) => ({
  type: AT.EDIT_JOINT,
  board_idx,
  title,
  content,
  setViewEditWrite,
});
export const delBoard = (pu_idx, setViewDetail) => ({
  type: AT.DEL_JOINT,
  pu_idx,
  setViewDetail,
});
// export const editJoint = () => ({
//   type: AT.EDIT_JOINT,
// });
// export const delJoint = () => ({
//   type: AT.DEL_JOINT,
// });

export const getJointBrokerageListLoading = () => ({
  type: AT.GET_JOINT_BROKERAGE_LIST_LOADING,
});
export const getJointBrokerageListSuccess = (conlist) => ({
  type: AT.GET_JOINT_BROKERAGE_LIST_SUCCESS,
  conlist,
});
export const getJointBrokerageListFailure = (error) => ({
  type: AT.GET_JOINT_BROKERAGE_LIST_FAILURE,
  error,
});

export const getJointBrokerageDetailLoading = () => ({
  type: AT.GET_JOINT_BROKERAGE_DETAIL_LOADING,
});
export const getJointBrokerageDetailSuccess = (detail) => ({
  type: AT.GET_JOINT_BROKERAGE_DETAIL_SUCCESS,
  detail,
});
export const getJointBrokerageDetailFailure = (error) => ({
  type: AT.GET_JOINT_BROKERAGE_DETAIL_FAILURE,
  error,
});
