import React, { useState, useRef } from 'react';
import { api } from '@/apis/community';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { STATUS } from '@/data/utils';
import SpinnerWithoutCover from '@/components/Spinner/SpinnerWithoutCover';

const AllowFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

const ImageUploade = ({ defaultImage, defaultId, handleImageUpload, handleImageDelete }) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState(defaultImage);
  const [id, setId] = useState(defaultId);
  const [status, setStatus] = useState(STATUS.NOTASKED);

  // 피드 수정 시, defaultImage는 api 조회 후 세팅해야 하므로 useState initialize 만으로는 세팅아 안됨.
  // 데이터를 불러왔을 때 상태를 업데이트 해 줘야 함.

  const inputRef = useRef(null);

  async function upload() {
    const imageFile = inputRef.current.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', async function() {
      const base64 = reader.result;
      try {
        setStatus(STATUS.LOADING);
        // const result = await api.uploadImage([base64.replace('data:image/jpeg;base64,', '')]);
        const result = await api.uploadImage([base64.replace(/^data:image\/[a-z]+;base64,/, '')]);
        setImage(base64);
        if (result?.data?.[0]) setId(result.data?.[0]);
        if (handleImageUpload && result?.data?.[0]) handleImageUpload(result.data?.[0], base64);
        setStatus(STATUS.SUCCESS);
      } catch (e) {
        dispatch(
          actions.common.alert({
            contents: (
              <div className="board-body">
                <p className="para">
                  <span className="wbr">죄송합니다.</span>
                  <span className="wbr">이미지 업로드를 실패하였습니다.</span>
                </p>
              </div>
            ),
          })
        );
        setStatus(STATUS.FAILURE);
      }
    });

    if (imageFile) {
      if (AllowFileTypes.indexOf(imageFile.type.toLowerCase()) < 0) {
        dispatch(
          actions.common.alert({
            contents: (
              <div className="board-body">
                <p className="para">
                  <span className="wbr">업로드한 파일은 지원되지 않는 파일 형식입니다.</span>
                  <span className="wbr">jpg, gif, png 등의 이미지 파일을 업로드해주세요.</span>
                </p>
              </div>
            ),
          })
        );
      } else {
        reader.readAsDataURL(imageFile);
      }
    }
    // setImage(URL.createObjectURL(imageFile));
  }

  const onDelete = () => {
    if (handleImageDelete && id) handleImageDelete(id);
    setImage(null);
    setId(null);
  };

  return (
    <>
      {image ? (
        <span className="file-item">
          <span className="form file" data-bui-form-file-name="20210714153438141154.jpg" style={{ '--background-image': 'url(' + image + ')' }}>
            <input type="file" className="form-elem" />
            <span className="form-func">
              <button type="button" className="btn delete" onClick={onDelete}>
                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" xmlns="http://www.w3.org/2000/svg">
                  <title>삭제</title>
                  <path
                    fill="#000"
                    d="m8.4 17 3.6-3.6 3.6 3.6 1.4-1.4-3.6-3.6L17 8.4 15.6 7 12 10.6 8.4 7 7 8.4l3.6 3.6L7 15.6Zm3.6 5q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"></path>
                </svg>
              </button>
            </span>
          </span>
        </span>
      ) : (
        <span className="file-item">
          <span className="form file">
            <input ref={inputRef} type="file" accept="image/*" className="form-elem" data-bui-form-file="uploader" onChange={upload} />
          </span>
        </span>
      )}
      <SpinnerWithoutCover show={status === STATUS.LOADING} />
    </>
  );
};

export default ImageUploade;
