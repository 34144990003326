import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.GET_NEWSALES, sagas.getNewsales);
  yield takeEvery(AT.GET_REBUILDS, sagas.getRebuilds);
  yield takeEvery(AT.GET_NEWSALE_PRICE, sagas.getNewsalePrice);
  yield takeEvery(AT.GET_NEWSALE_SCHEDULE, sagas.getNewsaleSchedule);
  yield takeEvery(AT.GET_REBUILD_SCHEDULE, sagas.getRebuildSchedule);
  yield takeEvery(AT.GET_NEWS, sagas.getNews);
  yield takeEvery(AT.GET_MK_LETTER_NEWS, sagas.getMKLetterNews);
  yield takeEvery(AT.GET_NEWS_SEARCH_CONDITIONS, sagas.getNewsSearchConditions);
}
