import { apiClient2 } from '@/apis/clients';

export const api = {
  async getMyCoinSummary(coinDefinitionId) {
    try {
      return await apiClient2.get(`/coin-api/v1/coin-definitions/${coinDefinitionId}/users/self/coin-summary`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
