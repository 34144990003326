import img_appprev01 from '@/resources/design/assets/images/img_appprev01.png';
import img_appprev02 from '@/resources/design/assets/images/img_appprev02.png';
import img_appprev03 from '@/resources/design/assets/images/img_appprev03.png';
import img_appprev04 from '@/resources/design/assets/images/img_appprev04.png';
import img_appprev05 from '@/resources/design/assets/images/img_appprev05.png';
import img_appprev06 from '@/resources/design/assets/images/img_appprev06.png';

const constants = [
  {
    title:'<p class="p_tit01">부동산 중개혁명<br><em>다윈중개</em></p> <p class="p_tit02">집 내놓을 때 수수료 0원, 집 구할 때 수수료 반값</p>',
    src: img_appprev01,
  },

    {
      title:'<p class="p_tit01">주인이 직접 올린<br><em>1만+건</em>의 다윈매물</p><p class="p_tit02">집주인/거주인이 직접 등록, 허위매물 제로</p>',
      src: img_appprev02,
    },
    {
      title: '<p class="p_tit01">가장 빠른 <em>신고가/신저가</em> 및<br><em>매물시세</em> 확인</p> <p class="p_tit02">네이버, KB부동산 매물을 기반으로 한 매물시세<br>전국 모든 단지 신고가/신저가 실시간 업데이트</p>',
      src: img_appprev03,
    },
    {
      title: '<p class="p_tit01">능력있는 <em>중개사</em>,<br>비교하고 선택하기</p> <p class="p_tit02">리뷰로 검증된 2200명의 다윈중개사로 안전한 거래</p>',
      src: img_appprev04,
    },
    {
      title: '<p class="p_tit01">구해줘 <em>다윈</em></p> <p class="p_tit02">내 예산과 관심 지역에 맞게<br>중개사들이 알아서 연락</p> ',
      src: img_appprev05,
    },
    {
      title: '<p class="p_tit01">다윈중개에만 있는<br><em>분석데이터</em></p> <p class="p_tit02">재건축 사업성, 아파트 비교까지</p>',
      src: img_appprev06,
    },
  ];

  export default constants;
