export const getError = (state) => state?.uploadImage?.error;

// ===================== Selling ===================== //
export const getSellingItemInteriorImagesBlob = (state) => state?.uploadImage?.sellingItemInteriorImagesBlob;
export const getSellingItemExteriorImagesBlob = (state) => state?.uploadImage?.sellingItemExteriorImagesBlob;

// ===================== AgentSignUp ===================== //
export const getCorpCertImage = (state) => state?.uploadImage?.corpCertImage;
export const getOpenCertImage = (state) => state?.uploadImage?.openCertImage;

export const getProfileImage = (state) => state?.uploadImage?.profileImage;
