import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';
import Header from './components/header';
import GuideText from './components/guideText';
import ReportBtn from './components/reportBtn';
import ReportOptions from './components/reportOptions';

const ReportPopUp = ({ viewReportPop }) => {
  const dispatch = useDispatch();
  const viewReportPopType = viewReportPop?.split('-')[0];
  const viewReportPopKey = viewReportPop?.split('-')[1];
  const [payload, setPayload] = useState({ type: viewReportPopType, key: viewReportPopKey });

  const handleClosePop = (e) => {
    e.preventDefault();
    document.body.style.overflow = '';
    dispatch(actions.router.goBack());
  };

  useEffect(() => {
    viewReportPop ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '');
  }, [viewReportPop]);

  return (
    <div className="content-popup module-a style-a type-a medium active" id="reportPosts" data-bui-toggle="contentsPopup">
      <div className="popup-page-body">
        <div className="popup-local">
          <Header />
          <div className="popup-local-body">
            <form className="popup-content">
              <div className="popup-content-body">
                <fieldset className="submit-form module-a type-c medium">
                  <legend>지역 선택 서식</legend>
                  <div className="form-list">
                    <div className="form-item">
                      <div className="form-wrap">
                        <GuideText />
                        <div className="form-body">
                          <div className="option-display module-a style-a type-c" data-bui-form="checkedWithin">
                            <ReportOptions payload={payload} setPayload={setPayload} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <ReportBtn payload={payload} handleClosePop={handleClosePop} />
            </form>
          </div>
          <div className="popup-local-func">
            <span className="button-area">
              <button type="button" className="btn popup-close" onClick={handleClosePop}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                  <title>닫기</title>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPopUp;
