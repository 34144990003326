import Remote from '@/data/remote';
import * as AT from './actionTypes';
import CONSTANTS from '../../pages/Rating/constants';

// Array.fill 함수 사용이 불가!
let temp = [];
for (let i = 0; i < 6; i++) {
  temp.push(0);
  //temp.push(0);
}

// CONSTANTS 에서 꺼내서 목록 집어넣는건 테스트 용도임 서버에서 개발 끝나면 [] 로 변경 필
const INITIAL_STATE = {
  error: '',

  ratingType: CONSTANTS.RATING_BUYER,

  ratingTargetItemIdx: '',
  ratingTargetBrokerIdx: '',

  // 0번은 전체 평균 , 1~5번은 점수로 평가하는 질문의 점수, 6번은 text 답변 (2020.05.31기준)
  ratingList: temp, // 그냥 넉넉하게 대충 길이 50으로 잡아놓음

  [AT.POST_RATING]: Remote.NotAsked,
  [AT.POST_RATING_URL]: Remote.NotAsked,
};

export default function ratingRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.RATING_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case AT.RATING_RESET_ERROR_STATE: {
      return {
        ...state,
        error: '',
      };
    }

    // ============================ Rating ============================ //
    case AT.SET_RATING_TYPE: {
      return {
        ...state,
        ratingType: action.ratingType,
      };
    }

    case AT.SET_RATING_TARGET_ITEM_IDX: {
      return {
        ...state,
        ratingTargetItemIdx: action.ratingTargetItemIdx,
      };
    }
    case AT.SET_RATING_GUBUN: {
      return {
        ...state,
        gubun: action.gubun,
      };
    }
    case AT.SET_RATING_TARGET_BROKER_IDX: {
      return {
        ...state,
        ratingTargetBrokerIdx: action.ratingTargetBrokerIdx,
      };
    }

    case AT.SET_Q_RATING: {
      state.ratingList[action.QIdx] = action.ratingContent;
      return {
        ...state,
      };
    }

    case AT.POST_RATING_LOADING:
      return { ...state, [AT.POST_RATING]: Remote.Loading };
    case AT.POST_RATING_SUCCESS:
      return { ...state, [AT.POST_RATING]: Remote.Success(true) };
    case AT.POST_RATING_FAILURE:
      return { ...state, [AT.POST_RATING]: Remote.Failure(action.error), error: action.error };
    case AT.POST_RATING_NOTASK:
      return { ...state, [AT.POST_RATING]: Remote.NotAsked };

    case AT.POST_RATING_URL_LOADING:
      return { ...state, [AT.POST_RATING_URL]: Remote.Loading };
    case AT.POST_RATING_URL_SUCCESS:
      return { ...state, [AT.POST_RATING_URL]: Remote.Success(true) };
    case AT.POST_RATING_URL_FAILURE:
      return { ...state, [AT.POST_RATING_URL]: Remote.Failure(action.error), error: action.error };
    case AT.POST_RATING_URL_NOTASK:
      return { ...state, [AT.POST_RATING_URL]: Remote.NotAsked };

    default: {
      return {
        ...state,
      };
    }
  }
}
