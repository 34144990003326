import React from 'react';

const ButtonLoadMore = ({ maxCount, setMaxCount }) => {
  return (
    <div className="button-display module-a style-a type-a attr-more-data">
      <span className="button-area">
        <button
          className="btn module-a style-b type-fill large normal-05 symbol-ltr-more-data flex"
          type="button"
          onClick={() => setMaxCount(maxCount + 3)}>
          <span className="btn-text">더 불러오기</span>
        </button>
      </span>
    </div>
  );
};

export default ButtonLoadMore;
