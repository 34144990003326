import { call, put, select, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as bActions from '../actions';
import { actions as allActions, selectors as allSelectors } from '@/data';
import * as selectors from '../selectors';
import apis from '@/apis';
import { STEPS } from '@/data/buying/utils';
import { eduDataStandardiztion } from './utils';
import { MARKET_DATA_STATUS } from './reducers';
import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { COMPLEX_DETAIL_AREASIZE_STORAGE_KEY } from '@/data/buying/utils';

export function* getDetail({ complexId, initial_area }) {
  try {
    yield put(actions.getDetailLoading());

    const [detail, areaList, brokerList, school, kindergarten, daycare] = yield all([
      apis.buyingApi.getDetail(complexId),
      apis.buyingApi.getAreaList(complexId),
      apis.buyingApi.getBrokerList(complexId).catch((e) => put(bActions.setError(e.message))),
      apis.buyingApi.getSchool(complexId).catch((e) => put(bActions.setError(e.message))),
      apis.buyingApi.getKindergarten(complexId).catch((e) => put(bActions.setError(e.message))),
      apis.buyingApi.getDaycare(complexId).catch((e) => put(bActions.setError(e.message))),
    ]);

    // 단지 상세
    yield put(actions.getDetailSuccess(detail?.info));

    // 평형
    const storageAreasize = localStorage.getItem(COMPLEX_DETAIL_AREASIZE_STORAGE_KEY);
    let nearestArea;

    // 유저가 선택한 평형과 가장 가까운 평형
    if (storageAreasize) {
      const areaListForSort = [...(areaList?.list || [])];

      const sortByDiff = areaListForSort
        ?.map((item) => {
          const diff = Math.abs(Number(item.pyeong) - Number(storageAreasize)); //절대값
          item.diff = diff;
          return item;
        })
        .sort((a, b) => a.diff - b.diff);
      nearestArea = sortByDiff?.[0]?.pyeong;
    }

    // 평형 정보 우선순위: 쿼리스트링(initial_area) > 유저가 선택한 직전 평형 값 > 기본값(대표평형)
    const size = initial_area || nearestArea || areaList.rep;

    yield put(actions.changeSize(size));

    yield put(actions.setAreaList(areaList.list));

    // const prediction = yield apis.buyingApi.getCharterPrediction(complexId); //6개월 이내 예상 전세
    // yield put(actions.setCharterPrediction(prediction));

    // 중개사
    // 단지 중심 중개사 목록 - 지정 중개사 없는 경우 default
    yield put(actions.setBrokerList(brokerList));

    // 학교
    yield put(actions.setSchool(school));

    // 유치원
    yield put(actions.setKindergarten(kindergarten));

    // 어린이집
    yield put(actions.setDaycare(daycare));

    // 2021-03-25 정석규 수정
    // 글로벌스피너가 이시점에 돌고있으면 종결함.
    const globalSpinner = yield select(allSelectors.common.showSpinner);
    if (globalSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getDetailSuccess({ error: '단지상세 정보를 불러오는 중 오류가 발생하였습니다.' }));
  }
}

export function* getTradeHistory({ givenAreaNo, year }) {
  try {
    const complexId = yield select(selectors.getSelectedItemId);
    const areaNo = givenAreaNo ? givenAreaNo : yield select(selectors.detail.getSelectedSize);
    const tradetype = yield select(selectors.detail.getselectedType);

    if (complexId && areaNo && tradetype) {
      yield put(actions.getTradehistoryLoading());
      const result = yield apis.buyingApi.getTradeHistory(complexId, areaNo, tradetype, year);
      // yield put(actions.getTradehistorySuccess(result.totaldata.list));
      yield put(actions.getTradehistorySuccess(result));
    }
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

export function* getTrade({ page, options, passedParams }) {
  try {
    const passedComplexIdx = passedParams?.complexIdx;
    const passedAreaNo = passedParams?.areaNo;
    const passedTradeType = passedParams?.tradeType;

    const complexId = yield passedComplexIdx ? passedComplexIdx : select(selectors.getSelectedItemId);
    const areaNo = yield passedAreaNo ? passedAreaNo : select(selectors.detail.getSelectedSize);
    const tradetype = yield passedTradeType ? passedTradeType : select(selectors.detail.getselectedType);
    const prev = yield select(selectors.detail.getTrade);

    if (complexId && areaNo && tradetype && page) {
      // 요청 파라미터
      const payload = { areaNo, tradtype: tradetype, page, perPage: 5, ...options };
      // 거래 내역 api 조회
      const result = yield apis.buyingApi.getTrade(complexId, payload);

      let all;
      if (page === 1) {
        all = result.list;
      } else {
        const prevList = prev.list || [];
        all = prevList.concat(result.list);
      }
      yield put(actions.getTradeSuccess({ hasNextPage: result.hasNextPage, list: all }));
    }
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

export function* getTax({ areaSize }) {
  try {
    const complexId = yield select(selectors.getSelectedItemId);
    const areaNo = yield areaSize ? areaSize : select(selectors.detail.getSelectedSize);
    if (complexId && areaNo) {
      const result = yield apis.buyingApi.getTax(complexId, areaNo);
      yield put(actions.setTax(result));
    }
  } catch (e) {
    yield put(bActions.setError(e));
  }

  const globalSpinner = yield select(allSelectors.common.showSpinner);
  if (globalSpinner) {
    // 2021-03-25 정석규
    // 글로벌 스피너가 돌고있으면 종결함
    yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getSchoolDetail({ id, onSuccess }) {
  try {
    // const categorySelect = yield select(rootSelectors.analysis.getSelectedCateogy);
    const result = yield apis.buyingApi.getSchoolDetail(id);

    if (result.list.length === 0) {
      yield put(allActions.common.alert({ contents: '해당 초등학교는 상세정보가 없습니다.' }));
    } else {
      yield put(actions.setSchoolDetail(result?.list?.[0]));

      if (onSuccess) {
        onSuccess();
      }
      // const currPath = window.location.pathname;
      // if (currPath?.indexOf('analysis/weeklyprice') > -1) {
      //   // pass
      // } else {
      //   if (currPath?.indexOf('analysis') > -1) {
      //     // 분석에서 실행하는 경우
      //     yield put(allActions.router.push('/analysis/school'));
      //   } else {
      //     const queryObject = parseCallBack();
      //     queryObject[EDU_DETAIL] = STEPS.SCHOOL;
      //     const path = currPath + objectToQuerystring(queryObject);
      //     if (currPath.indexOf(EDU_DETAIL) > -1) {
      //       yield put(allActions.router.replace(path));
      //     } else {
      //       yield put(allActions.router.push(path));
      //     }
      //     // yield put(bActions.setStep(STEPS.SCHOOL, id));
      //   }
      // }
    }
  } catch (e) {
    yield put(actions.setSchoolDetail(false));
  }
}

export function* getKinderDetail({ id, onSuccess }) {
  try {
    const result = yield apis.buyingApi.getKinderDetail(id);
    if (result.list.length === 0) {
      yield put(allActions.common.alert({ contents: '해당 유치원은 상세정보가 없습니다.' }));
    } else {
      yield put(actions.setKindergartenDetail(result?.list?.[0]));

      if (onSuccess) {
        onSuccess();
      }
    }
  } catch (e) {
    yield put(actions.setKindergartenDetail(false));
  }
}

export function* getDaycareDetail({ id, onSuccess }) {
  try {
    const result = yield apis.buyingApi.getDaycareDetail(id);
    if (result.list.length === 0) {
      yield put(allActions.common.alert({ contents: '해당 어린이집은 상세정보가 없습니다.' }));
    } else {
      yield put(actions.setDaycareDetail(result?.list?.[0]));

      if (onSuccess) {
        onSuccess();
      }
    }
  } catch (e) {
    yield put(actions.setDaycareDetail(false));
  }
}

// 매물 목록
export function* getSales({ complex_idx }) {
  try {
    yield put(actions.getSalesLoading(true));
    const category = yield select(selectors.getCategory);
    // 2021-11-12 배지율 수정
    // 현재 getSales는 시세정보에서 다윈 매물들을 표출하기 위해 사용되며
    // 단지상세정보를 보여주는 시점에 한 번만 호출한다.
    // 따라서 global 변수의 필터 정보에 따라 조회할 필요가 없다.
    // 오히려 지도 메인 필터가 걸려있는 경우  매물 데이터 누락되는 경우가 생긴다.

    // const tradeTypes = yield select(selectors.getTradetype);
    // const areasize = yield select(selectors.getAreasize);
    // , tradeTypes: tradeTypes.join('|'), areaSizes: areasize.join('|')
    const result = yield apis.goodsApi.getGoodsListByComplex(complex_idx, { category });
    const rslt = result?.rlt ? result : { rlt: [] };
    yield put(actions.getSalesSuccess(rslt));
    yield put(actions.getSalesLoading(false));
  } catch (e) {
    yield put(actions.getSalesLoading(false));
    yield put(bActions.setError(e));
  }
}

// 네이버매물 - 작업중
export function* getMarketData({ payload, setMarketData, reqType }) {
  yield put(actions.setMarketDataStatus(MARKET_DATA_STATUS.pending));

  try {
    const { complexIdx } = payload;
    const startProxyIndexRaw = yield select((state) => state.buying.startProxyIndexRaw);
    // 인덱스 업데이트 위치변경: 요청후 -> 요청전. (api콜이 오래걸릴수가 있기때문)
    yield put(bActions.incrementProxyIndex());

    const marketData = yield apis.buyingApi.getMarketData({ complexIdx, reqType });

    if (marketData) {
      if (setMarketData) {
        setMarketData(marketData);
      } else {
        yield put(actions.setMarketData(marketData));
      }
    } else {
      yield put(allActions.common.alert({ contents: '해당단지 시세정보 조회에 실패하였습니다.' }));
    }
  } catch (error) {
    yield put(allActions.common.alert({ contents: '해당단지 시세정보 조회에 실패하였습니다.' }));
  } finally {
    yield put(actions.setMarketDataStatus(MARKET_DATA_STATUS.idle));
  }
}

export function* getFacilityData({ payload }) {
  try {
    const { complexIdx } = payload;
    const { facilityData, facilitySchoolData } = yield apis.buyingApi.getFacilityData({ complexIdx });

    if (facilityData) {
      facilityData.sort?.((a, b) => {
        if (a.sort_order < b.sort_order) {
          return -1;
        }
        if (a.sort_order > b.sort_order) {
          return 1;
        }
        return 0;
      });
      yield put(actions.setFacilityData(facilityData));
    }

    if (facilitySchoolData) {
      yield put(actions.setFacilitySchoolData(facilitySchoolData));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getFacilityCode({ payload }) {
  try {
    const isGlobalSpinnerOn = yield select(allSelectors.common.showSpinner);
    if (!isGlobalSpinnerOn) {
      yield put(allActions.common.toggleGlobalSpinner(true));
    }

    const apiResponse = yield call(apis.sellingApi.getFacilityCode, payload);

    if (!Array.isArray(apiResponse)) {
      throw new Error(`Invalid api response: ${JSON.stringify(apiResponse)}`);
    }

    // save to redux
    // 제보 텍스트는 redux에서도 삭제.
    // 제보 이후에 다시 get해도 정보를 보여주지 않음. (유저는 insert만, 본인이 제보한 텍스트를 insert이후에 보여주지 않음.)
    yield put(actions.setFacilityCode(apiResponse.filter((item) => item.code_detail !== 'ETC')));
  } catch (error) {
    console.error(error);
    yield put(allActions.common.alert({ contents: error.message }));
  } finally {
    const isGlobalSpinnerOn = yield select(allSelectors.common.showSpinner);
    if (isGlobalSpinnerOn) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getFacilityDataUser({ payload }) {
  try {
    // call api
    const { complexIdx, userInfo } = payload;
    const { mbr_idx: mbrIdx } = userInfo;
    const apiResponse = yield call(apis.buyingApi.getFacilityDataUser, { complexIdx, mbrIdx });
    // save to redux
    // 제보 텍스트는 redux에서도 삭제.
    // 제보 이후에 다시 get해도 정보를 보여주지 않음. (유저는 insert만, 본인이 제보한 텍스트를 insert이후에 보여주지 않음.)
    delete apiResponse.ETC;
    yield put(actions.setFacilityDataUser(apiResponse));
  } catch (error) {
    // show error message
    console.error(error);
    yield put(allActions.common.alert({ contents: error.message }));
  } finally {
    // terminate the spinner if it's on
    const isGlobalSpinnerOn = yield select(allSelectors.common.showSpinner);
    if (isGlobalSpinnerOn) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* uploadFacilityDataUser({ payload }) {
  const globalSpinner = yield select(allSelectors.common.showSpinner);
  if (!globalSpinner) {
    yield put(allActions.common.toggleGlobalSpinner(true));
  }

  try {
    const { complexIdx, userInfo, facilityDataValues, setShow } = payload;
    const { mbr_idx } = userInfo;

    const facility_data_complete = Object.keys(facilityDataValues).map((code) => ({
      criteria_score: '1',
      source: 'USER',
      criteria: 'complex',
      code_detail: code,
      option: {
        option: facilityDataValues[code],
      },
    }));

    const insertFacilityDataResult = yield call(apis.buyingApi.uploadFacilityDataUser, {
      mbrIdx: mbr_idx,
      complexIdx,
      body: facility_data_complete,
    });

    yield put(allActions.common.alert({ contents: '단지시설정보를 제보해 주셔서 감사합니다.' }));
    yield setShow(false);
  } catch (error) {
    console.error(error);
  } finally {
    const globalSpinner = yield select(allSelectors.common.showSpinner);
    if (globalSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getNaverSales({ data }) {
  try {
    yield put(actions.getNaverSalesPending());
    const startProxyIndexRaw = yield select((state) => state.buying.startProxyIndexRaw);

    // 인덱스 업데이트 위치변경: 요청후 -> 요청전. (api콜이 오래걸릴수가 있기때문)
    yield put(bActions.incrementProxyIndex());
    const result = yield apis.buyingApi.getNaverSales(data, startProxyIndexRaw);
    // 2021-02-17 정석규 수정
    // 단기임대를 일단 제외한다
    // 현재 groupby 로직이 매매/전세 로직과 동일하게 타는데, 일단 이것부터 맞는지 확인해야하므로 (월세랑 더 비슷?)
    // 이해도가 더 높아지기 전까지 사용하지 않는다.
    result?.overviewList && (result.overviewList = result.overviewList.filter((item) => item.tradeType !== '단기임대'));
    result?.detailList && (result.detailList = result.detailList.filter((item) => item.tradeType !== '단기임대'));
    yield put(actions.getNaverSalesSuccess(result));
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

export function* getNaverSaleDetail({ data }) {
  try {
    yield put(actions.getNaverSaleDetailSuccess(data));
  } catch (e) {
    yield put(bActions.setError(e));
  }
}
// 네이버매물 - 작업중

//매물 상세
export function* getSaleDetail({ sale_id }) {
  try {
    const result = yield apis.goodsApi.getGoodsDetail(sale_id);
    if (result) {
      yield put(actions.getSaleDetailSuccess(result));
    } else {
      yield put(allActions.common.alert({ contents: '해당 매물의 상세 정보가 없습니다.' }));
    }
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

//매물 상세 -> 중개사 사무소 리스트
export function* getBrokers({ goods_idx }) {
  try {
    //선택된 중개사 조회
    //근방 10km 중개사 조회
    //선택된 중개사 목록 있으면 사용, 없으면 10km 이내
    const result = yield apis.goodsApi.getBrokerOfficeList(goods_idx);
    yield put(actions.getBrokersSuccess(result?.list[0]));
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

//중개사 상세보기
export function* getBrokerDetail({ broker_office_idx, goods_idx }) {
  try {
    const user = yield select(allSelectors.auth.getUserInfo);
    const result = yield apis.buyingApi.getBrokerDetail(broker_office_idx, goods_idx, user);
    yield put(actions.getBrokerDetailSuccess(result?.brokerInfo));
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

export function* askToBroker({ broker_office_idx, goods_idx, contents, resetForm }) {
  try {
    yield put(actions.askToBrokerLoading());
    const result = yield call(apis.buyingApi.askToBroker, { broker_office_idx, goods_idx, contents });
    resetForm();
    yield put(actions.askToBrokerSuccess(result));
  } catch (e) {
    yield put(bActions.setError(e));
    yield put(actions.askToBrokerFailure());
    yield put(allActions.common.alert({ contents: '문의하기 중 오류가 발생하였습니다.\n다시 시도해주세요' }));
  }
}

export function* getSchoolInfoWithKakao({ gubun, dongcode, keyword, id }) {
  try {
    const result = yield call(apis.buyingApi.getSchoolInfoWithKakao, { gubun, dongcode, keyword: keyword.replace(/ /gi, '') });
    const data = eduDataStandardiztion(gubun, result);
    if (data.detail) {
      yield put(bActions.selectSurrItem(id));
      // yield put(bActions.selectItem(null));
      yield put(actions.setSchoolInfoWithKakao(data));
      switch (gubun) {
        case 'D':
          yield put(actions.setDaycareDetail(result?.list?.[0]));
          break;
        case 'K':
          yield put(actions.setKindergartenDetail(result?.list?.[0]));
          break;
        case 'S':
          yield put(actions.setSchoolDetail(result?.list?.[0]));
          break;
        default:
          break;
      }
      yield put(bActions.setStep(STEPS.EDU));
    } else {
      yield put(allActions.common.alert({ contents: '해당 시설은 상세정보가 없습니다.' }));
    }
  } catch (e) {
    yield put(bActions.setError(e));
  }
}

export function* getAreaList({ complex_idx }) {
  try {
    const areaList = yield apis.buyingApi.getAreaList(complex_idx); //평형
    yield put(actions.changeSize(areaList.rep));
    yield put(actions.setAreaList(areaList.list));
  } catch (e) {
    yield put(actions.setAreaList(null));
  }
}

// 단지 시설 알리기
export function* reportComplexFacility({ complex_idx, options, text }) {
  try {
    const result = yield apis.buyingApi.reportComplexFacility(complex_idx, options.join(','), text);
    yield put(actions.getSalesSuccess(result));
  } catch (e) {
    yield put(bActions.setError(e));
  }
}
