import Spinner from '@/components/Spinner';
import { STATUS } from '@/data/utils';
import React, { useMemo } from 'react';
import NotiHistoryCard from './components/NotiHistoryCard';

const useNotiList = ({ notiHistory, setLastTimeStamp, setNotiHistory }) => {
  const NotiList = useMemo(() => {
    const { status, push_history_list } = notiHistory;
    if (status === STATUS.SUCCESS && push_history_list.length > 0) {
      setLastTimeStamp(push_history_list[push_history_list.length - 1].timestamp);
      return push_history_list.map((history) => {
        return <NotiHistoryCard historyData={history} key={history.messageId} notiHistory={notiHistory} setNotiHistory={setNotiHistory} />;
      });
    } else if (status === STATUS.SUCCESS) {
      return <div style={{ textAlign: 'center', padding: '10px', fontSize: '15px' }}>알림정보 없음</div>;
    } else if (status === STATUS.FAILURE) {
      return <div>죄송합니다. 알림설정 데이터 조회 중 오류가 발생하였습니다.</div>;
    } else if (status === STATUS.LOADING) {
      return <Spinner show={true} />;
    }
  }, [notiHistory, notiHistory.isNext]);

  return { NotiList };
};

export default useNotiList;
