import { useEffect, useState } from 'react';

const useSetMarkTypes = ({ complexTags, tags }) => {
  const [markTypes, setMakrTypes] = useState();

  useEffect(() => {
    if (complexTags?.length === 1 && complexTags?.some((tag) => tag?.name === '아파트')) {
      setMakrTypes('APT');
    } else if (complexTags?.length === 1 && complexTags?.some((tag) => tag?.name === '오피스텔')) {
      setMakrTypes('OFT');
    } else {
      setMakrTypes(undefined);
    }
  }, [tags]);

  return markTypes;
};

export default useSetMarkTypes;
