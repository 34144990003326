import React from 'react';

// 서비스 지역
export const SERVICE_AREA = '대한민국 전 지역';

// 메인
export const MAIN = {
  top: {
    mobile: (
      <p className="text02">
        <small className="more_s">{SERVICE_AREA}</small>
        <br />
        <b>부동산 중개혁신</b>
        <br />집 내놓을 때 중개수수료 0원
        <br />집 구할 때 중개수수료 반값
      </p>
    ),
    web: (
      <p className="text02">
        <small className="more_s">{SERVICE_AREA}</small>
        <br />
        <b>부동산 중개혁신</b>
        <br />
        <small>집 내놓을 때 중개수수료 0원</small>
        <br />
        <small>집 구할 때 중개수수료 반값</small>
      </p>
    ),
  },
};

//집내놓기
export const SELLING = {
  // 집내놓기 메인
  main: {
    top: {
      web: <>* 집내놓기 서비스는 현재 {SERVICE_AREA}에서 제공 중입니다.</>,
      mobile: (
        <>
          * 집내놓기 서비스는 현재 <b>{SERVICE_AREA}</b>에서 제공 중입니다.
        </>
      ),
    },
    // 서비스 지역 ("직접 집내놓기/전화로 집내놓기" 진입 전 알림 팝업)
    notice: (
      <>
        * 집내놓기 서비스는
        <br />
        현재 {SERVICE_AREA}에서 제공 중입니다.
      </>
    ),
  },
};

// 중개사 회원가입
export const AGENTSIGNUP = {
  //메인
  main: {
    //중개사회원가입 버튼 클릭 시 알림 팝업
    notice: (
      <>
        현재 국내 모든 지역에서 매물 제공 중입니다.
        <br />
        <br />* 개설 등록하신 개업 공인중개사만 회원 가입 가능합니다.
      </>
    ),
  },
  // 합동사무소
  share: {
    area: <p className="sc_dec_s">* 현재는 강남3구(강남, 서초, 송파), 분당/판교, 수지, 광교에서 운영중입니다.</p>,
  },
};

export default { SERVICE_AREA, MAIN, AGENTSIGNUP, SELLING };
