import apis from '@/apis';
import { actions } from '@/data';
import { STATUS } from '@/data/utils';
import React from 'react';
import { useDispatch } from 'react-redux';

const DuplicateChekcBtn = ({ setData, input, isLoading, setConfirm }) => {
  const dispatch = useDispatch();

  const handleCheck = async () => {
    setData({ status: STATUS.LOADING });
    if (input?.length < 2) {
      dispatch(
        actions.common.alert({
          contents: '닉네임은 두 글자 이상으로 설정해주세요.',
        })
      );
      setData({ status: STATUS.NOTASKED });
    } else if (!isLoading) {
      try {
        const result = await apis.authApi.checkNickname(input);
        if (result.conflict) {
          dispatch(
            actions.common.alert({
              contents: '이미 등록된 닉네임입니다. 다른 닉네임을 선택해주세요.',
            })
          );
          setConfirm(false);
          setData({ status: STATUS.NOTASKED });
        } else {
          dispatch(
            actions.common.alert({
              contents: '사용 가능한 닉네임입니다.',
            })
          );
          setConfirm(true);
          setData({ status: STATUS.NOTASKED });
        }
      } catch (e) {
        dispatch(
          actions.common.alert({
            contents: '닉네임 확인 중 오류가 발생하였습니다.',
          })
        );
        setData({ status: STATUS.FAILURE });
      }
    }
  };

  return (
    <button type="button" className="btn module-a style-b type-line normal-04 large-1x" onClick={handleCheck}>
      <span className="btn-text">중복확인</span>
    </button>
  );
};

export default DuplicateChekcBtn;
