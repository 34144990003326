import { actions } from '@/data';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useGetMyMembership = ({ user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isBroker = user?.mbr_type === 'BU';

    if (isBroker) {
      dispatch(actions.payment.getMyMembership());
    }
  }, [user]);

  return;
};

export default useGetMyMembership;
