import React, { useEffect, useRef } from 'react';
import { buiFormRange } from '@/resources/design/v2/js/bui.template';

const OptionSlider = ({ id, title, marks, range, rangeString, min, max, step, handleChange }) => {
  const minRef = useRef();
  const maxRef = useRef();

  const onChangeMin = (e) => {
    handleChange([Number(e.target.value), range[1] ?? max]);
  };

  const onChangeMax = (e) => {
    handleChange([range[0] ?? min, Number(e.target.value)]);
  };

  useEffect(() => {
    buiFormRange(`#${id}`);

    minRef.current.addEventListener('change', onChangeMin);
    maxRef.current.addEventListener('change', onChangeMax);

    return () => {
      minRef?.current?.removeEventListener('change', onChangeMin);
      maxRef?.current?.removeEventListener('change', onChangeMax);
    };
  }, [range]);

  const min_text = range[0] ?? min;
  const max_text = range[1] ?? max;

  return (
    <>
      <div className="form-item">
        <div className="form-wrap">
          <div className="form-head">
            <label className="form-name" htmlFor="#">
              {title}
            </label>
          </div>

          <div className="form-body">
            <div className="form-area">
              <div id={id} className="form range style-a type-a flex attr-tooltip" data-bui-step={rangeString}>
                <input
                  ref={minRef}
                  type="range"
                  className="form-elem range-min"
                  title="최소"
                  min={min}
                  max={max}
                  step={step}
                  defaultValue={min_text}
                />
                <input
                  ref={maxRef}
                  type="range"
                  className="form-elem range-max"
                  title="최대"
                  min={min}
                  max={max}
                  step={step}
                  defaultValue={max_text}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptionSlider;
