import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apis from '@/apis';
import { actions, selectors } from '@/data';

const useGetComplexHistoryBulkByUserIds = ({ feeds, isReady, userIds }) => {
  const filteredIds = [...new Set(userIds)];

  const dispatch = useDispatch();
  const complexHistoryByUser = useSelector(selectors.user.complexHistoryByUser);

  useEffect(async () => {
    if (isReady) {
      // 배열+객체 형태의 complexHistoryByUser 데이터에서 ID만 뽑아서 배열화
      const reduxWriterList = Object.keys(complexHistoryByUser);
      const newWriterList = filteredIds?.filter((writerId) => !reduxWriterList?.includes(writerId));

      if (newWriterList?.length > 0) {
        const result = await apis.userApi.getComplexHistoryBulkByUserIds({ memberIds: newWriterList });

        // 새로 받아온 데이터 정제
        let newData = {};
        for (let i = 0; i < result?.length; i++) {
          if (newData[result[i].mbr_idx]) {
            newData[result[i].mbr_idx] = { ...newData[result[i].mbr_idx], [result[i].tb_cb_complex_idx]: result[i] };
          } else {
            newData[result[i].mbr_idx] = { [result[i].tb_cb_complex_idx]: result[i] };
          }
        }

        // 정제된 데이터 기존 redux 합친 후 다시 redux에 저장
        const finalData = Object.assign(newData, complexHistoryByUser);

        dispatch(actions.user.setComplexHistoryByUsers({ ...finalData }));
      }
    }
  }, [feeds]);

  return;
};

export default useGetComplexHistoryBulkByUserIds;
