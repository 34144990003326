import React from 'react';
import classNames from 'classnames';

const PopupWrapperComp = ({ handleClose, title, children, type = 'basic' }) => {
  return (
    <div className={classNames('pop', { popbasic: type === 'basic', popxlg: type === 'large' })} style={{ position: 'fixed', zIndex: '5002' }}>
      <header id="header" className="header">
        <span className="btn-right">
          <a onClick={handleClose} className="btn-img btn-close-lg">
            닫기
          </a>
        </span>
        <h2 className={classNames({ title: type === 'basic', tit: type === 'large' })}>{title}</h2>
      </header>
      {children}
    </div>
  );
};

export default PopupWrapperComp;
