import React, { useState } from 'react';
import Core from '../components/Core';
import MainApp from '../../components/MainApp';
import TemplateFrontPopup from '@/components/templates/TemplateFrontPopup';

import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import { STEPS } from '../../BasicForm/helpers';
import UserTypeSelection from '../LoginWeb/components/UserTypeSelection';
import AgentUserView from '../LoginWeb/components/AgentUserView';
import ExtraBtns from '../LoginWeb/components/AgentUserView/components/ExtraBtns';
import Banner from '../LoginWeb/components/Banner';
import { USER_TYPES } from '@/pages/Login/views/constants';
import { useCloseLoginPopup, useSetUserType } from '../hooks';

const LoginApp = (props) => {
  const [userType, setUserType] = useState(USER_TYPES.NORMAL);
  const step = useSelector(selectors.auth.getLoginStep);
  const isRealtorView = userType === USER_TYPES.REALTOR;
  const showExtraTabs = isRealtorView || step === STEPS.FIRST;

  // 로그인 팝업 닫기 함수
  const [handleClose] = useCloseLoginPopup();

  // step이 BROKER일때 중개사회원 탭 보여주기
  useSetUserType({ step, setUserType });

  return (
    <>
      <TemplateFrontPopup
        onClose={handleClose}
        showCloseButton={step === STEPS.SOCIAL2 || step === STEPS.SOCIAL3 || step === STEPS.AGREE}
        popupId="loginBroker"
        title={step === STEPS.SOCIAL2 || step === STEPS.SOCIAL3 ? '전화번호 인증' : step === STEPS.AGREE ? '회원가입' : ''}>
        <div className="popup-local-body">
          <div className="popup-content">
            {showExtraTabs && <UserTypeSelection userType={userType} setUserType={setUserType} />}
            <div className="popup-content-body">
              {isRealtorView && showExtraTabs && <AgentUserView />}

              <Core mainComponent={<MainApp />} {...props} />

              {isRealtorView && showExtraTabs && <ExtraBtns handleClose={handleClose} />}

              {isRealtorView && <Banner handleClose={handleClose} />}
            </div>
          </div>
        </div>
      </TemplateFrontPopup>
    </>
  );
};

export default LoginApp;
