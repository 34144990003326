import React from 'react';

const useOptions = ({ needAppDownload, option }) => {
  const defaultTitle = (
    <>
      로그인이 <br />
      필요한 서비스입니다.
    </>
  );
  const defaultButtonText = '로그인';

  function getTitle() {
    if (needAppDownload) {
      return (
        <>
          다윈중개의 모든 기능을
          <br />
          앱에서 이용해보세요
        </>
      );
    } else if (option?.title) {
      return option?.title;
    } else {
      return defaultTitle;
    }
  }

  function getButtonText() {
    if (needAppDownload) {
      return '앱에서 보기';
    } else if (option?.buttonText) {
      return option?.buttonText;
    } else {
      return defaultButtonText;
    }
  }

  function getContents() {
    // 내용
    if (option?.text) {
      return <div className="board-body">{option?.text}</div>;
    } else {
      return null;
    }
  }

  function getStyle() {
    // 스타일
    const requiredWrapperStyle = {};
    return option?.wrapperStyle ? { ...option?.wrapperStyle } : requiredWrapperStyle;
  }

  return { getTitle, getButtonText, getContents, getStyle };
};

export default useOptions;
