import React, { useEffect, useState } from 'react';
import Mask from '@/components/Mask';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { parseCallBack } from '@/services/Helper';
import { objectToQuerystring } from '@/apis/helpers';
import { OSTYPE } from '@/services/apps/types/OsType';

const NoticeForShare = () => {
  const dispatch = useDispatch();

  const isSharedUrl = window.location.search.indexOf('socialshare') > -1;
  const timelimit = 3000; //3초
  const timeInterval = 10;

  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const osType = osTypeInstance?.osType;
  const isMobileWeb = osType === OSTYPE.WEB_ANDROID || osType === OSTYPE.WEB_IOS;
  let timer, interval;

  const close = () => {
    const queryObject = parseCallBack();
    delete queryObject.socialshare;
    const search = objectToQuerystring(queryObject);
    dispatch(actions.router.replace(window.location.pathname + search));
  };

  useEffect(() => {
    interval = setInterval(increase, timeInterval);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  const [barWidth, setBarWidth] = useState(0); //percent
  function increase() {
    setBarWidth((prev) => {
      if (prev < 100) {
        const next = prev + (timeInterval / timelimit) * 100;
        return next > 100 ? 100 : next;
      } else {
        return prev;
      }
    });
  }

  useEffect(() => {
    if (barWidth == 100) {
      // 딜레이 없이 닫으니까 bar 다 차기도 전에 닫히는 것 처럼 느껴짐
      timer = setTimeout(close, 500);
    }
  }, [barWidth]);

  const handleDownloadApp = () => {
    let link = '';

    if (osType === OSTYPE.WEB_IOS) {
      //아이폰
      link = 'https://itunes.apple.com/kr/app/id1457802907';
      // link = 'https://apps.apple.com/kr/app/apple-store/id1457802907';
    } else if (osType === OSTYPE.WEB_ANDROID) {
      //안드로이드 기기
      link = 'https://play.google.com/store/apps/details?id=xyz.dawin.asset';
    } else if (osType === OSTYPE.DESKTOP) {
      link = 'https://play.google.com/store/apps/details?id=xyz.dawin.asset';
    }
    if (link) {
      try {
        window.open(link);
      } catch {
        dispatch(actions.common.alert({ contents: '오류로 인하여 현재 앱 다운로드를 할 수 없습니다.' }));
      }
    }
  };

  if (isSharedUrl) {
    return (
      <>
        <Mask show={true} zIndex={1000} />
        <div className="lyr lyrpop_analy infopop" style={{ maxHeight: '655px', zIndex: 1000 }}>
          <div className="lyrpop_wrap">
            {/* progress bar */}
            <div style={{ background: '#483430' }}>
              <div style={{ height: '5px', background: '#ffffff', width: barWidth + '%' }}></div>
            </div>

            <a className="btn-img btn-close-wh" onClick={close}>
              닫기
            </a>
            <div className="lyr_inner">
              <div className="txt">
                <p className="p01">
                  <em className="yellow">2,000여명의 중개사들이 도와드립니다.</em>
                </p>
                <p className="p02">집내놓을 때</p>
                <p className="p03">
                  중개수수료 <em className="yellow">0원</em>
                </p>
              </div>
              <div className="content"></div>
            </div>
            {isMobileWeb && (
              <div className="lyr_outbtn">
                <div onClick={handleDownloadApp} className="btn_appdown_link">
                  앱다운받기
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return false;
  }
};

export default NoticeForShare;
