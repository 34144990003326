import React from 'react';
import { getTitle } from './util';
import useQuickSettingPopup from './hook/useQuickSettingPopup';
import AddressSimpleSelectPopup from './components/AddressSimpleSelectPopup';

/**
 * 간편 주소선택 컴포넌트
 *
 * @param {Array} types 간편 주소선택 할 버튼 종류 목록
 * @param {Function} applyCallback 적용 버튼 클릭시 callback
 */
const AddressSimpleSelect = ({ types, applyCallback }) => {
  const { quickSettingPopupType, handleQuickSettingPopup, handleClosePopup } = useQuickSettingPopup();

  return (
    <>
      <div className="button-display module-a style-a type-a">
        <span className="button-area">
          {types?.map((key) => (
            <a
              key={key}
              className="btn module-a style-c type-fill small-1x normal-05"
              onClick={(e) => {
                e.preventDefault();
                handleQuickSettingPopup(key);
              }}>
              <span className="btn-text">{getTitle(key)}</span>
            </a>
          ))}
        </span>
      </div>

      {/* 위치가... */}
      {quickSettingPopupType ? (
        <AddressSimpleSelectPopup type={quickSettingPopupType} onClosePopup={handleClosePopup} applyCallback={applyCallback} />
      ) : (
        false
      )}
    </>
  );
};

export default AddressSimpleSelect;
