import React, { useState, useEffect } from 'react';
import StyleFrontPopup from '@/publish/Front/_Component/popup/FrontPopup';
import BuiToggle from '@/assets/js/bui.toggle.js';
import CloseBtn from './components/CloseBtn';
import Header from './components/Header';

const DEFAULT_POPUP_ID = 'frontPopup';
const delay = 250; // layout.front.scss > .front-popup.module-a
// const DEFULT_SUBMIT_BUTTON_PROPS = { text: '확인', disabled: false, onClick: () => {}, preventDefaultClose: false };
const DEFULT_CLOSE_BUTTON_PROPS = { onClick: () => {}, preventDefaultClose: false };

/**
 * [탬플릿] 프론트 팝업 *
 *
 * @param {JSX | string} title 제목
 * @param {JSX} children 내용 (popup-local-body 영역부터 children에 포함됨)
 * @param {boolean} showCloseButton 닫기 버튼 영역 노출 여부 | default: true
 * @param {boolean} showSubmitButton submit 버튼 영역 노출 여부 | default: false
 * @param {function} onClose 닫기 action ({ onClick, preventDefault })
 * @param {object} submitButtonProps (optional) submit 버튼 props ({ text, disabled, onClick, preventDefaultClose })
 * @param {object} buiToggleOptions (optional) BuiToggle 객체 옵션 (@/assets/js/bui.toggle.js 참고)
 * @param {string} popupId (optional) 팝업 Id | default: "frontPopup"
 * @param {string} defaultActive (optional) mount 시 바로 active 시킬것인지 여부 | default: true
 *
 */
const TemplateFrontPopup = ({
  title,
  children,
  showCloseButton = true,
  // showSubmitButton = false,
  onClose,
  // submitButtonProps = {},
  closeButtonProps = {},
  buiToggleOptions,
  popupId = DEFAULT_POPUP_ID,
  defaultActive = true,
}) => {
  // submitButtonProps = { ...DEFULT_SUBMIT_BUTTON_PROPS, ...submitButtonProps };
  closeButtonProps = { ...DEFULT_CLOSE_BUTTON_PROPS, ...closeButtonProps };

  const [frontPopup] = useState(
    new BuiToggle('[data-bui-toggle="frontPopup"]', {
      reactTarget: 'html',
      reactTargetActiveClass: 'active-front-popup',
      focusin: true,
      ...buiToggleOptions,
    })
  );

  useEffect(() => {
    frontPopup.update(popupId);

    if (defaultActive) {
      frontPopup.active(popupId);
    }
  }, []);

  const handleClose = (e) => {
    e.stopPropagation();
    if (closeButtonProps.preventDefaultClose) {
      if (onClose) onClose(e);
    } else {
      frontPopup.inactive(popupId);

      setTimeout(() => {
        if (onClose) onClose(e);
      }, delay);
    }
  };

  return (
    <StyleFrontPopup popupName={popupId}>
      <div className="popup-local">
        <Header show={title} title={title} />

        {children}

        <CloseBtn show={showCloseButton} onClick={handleClose} />
      </div>
    </StyleFrontPopup>
  );
};

export default TemplateFrontPopup;
