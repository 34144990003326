import { success, loading, failure } from '@/data/utils';

export const INITIALIZE = 'INITIALIZE/BUYING_DETAIL';
export const GET_DETAIL = 'GET_DETAIL';
export const GET_DETAIL_SUCCESS = success('GET_DETAIL');
export const GET_DETAIL_LOADING = loading('GET_DETAIL');

export const CHANGE_SIZE = 'CHANGE_SIZE';
export const CHANGE_TYPE = 'CHANGE_TYPE';

export const GET_TRADE_HISTORY = 'GET_TRADE_HISTORY';
export const GET_TRADE_HISTORY_SUCCESS = success('GET_TRADE_HISTORY');
export const GET_TRADE_HISTORY_LOADING = loading('GET_TRADE_HISTORY');

export const GET_TRADE = 'GET_TRADE';
export const GET_TRADE_SUCCESS = success('GET_TRADE');

export const SET_CHARTER_PREDICTION = 'SET_CHARTER_PREDICTION';

export const GET_TAX = 'GET_TAX';
export const SET_TAX = 'SET_TAX';

export const GET_AREA_LIST = 'GET_AREA_LIST';
export const SET_AREA_LIST = 'SET_AREA_LIST';
export const SET_BROKER_LIST = 'SET_BROKER_LIST';
export const SET_SCHOOL = 'SET_SCHOOL';
export const GET_SCHOOL_DETAIL = 'GET_SCHOOL_DETAIL';
export const SET_SCHOOL_DETAIL = 'SET_SCHOOL_DETAIL';
export const SET_KINDERGARTEN = 'SET_KINDERGARTEN';
export const GET_KINDERGARTEN_DETAIL = 'GET_KINDERGARTEN_DETAIL';
export const SET_KINDERGARTEN_DETAIL = 'SET_KINDERGARTEN_DETAIL';
export const SET_DAYCARE = 'SET_DAYCARE';
export const GET_DAYCARE_DETAIL = 'GET_DAYCARE_DETAIL';
export const SET_DAYCARE_DETAIL = 'SET_DAYCARE_DETAIL';

export const GET_SALES = 'GET_SALES'; // 매물 목록
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_LOADING = 'GET_SALES_LOADING';

// 네이버매물 - 작업중
export const GET_MARKET_DATA = 'GET_MARKET_DATA';
export const SET_MARKET_DATA = 'SET_MARKET_DATA';
export const SET_MARKET_DATA_STATUS = 'SET_MARKET_DATA_STATUS';
export const SET_SELECTED_MARKET_ITEMS = 'SET_SELECTED_MARKET_ITEMS';
export const GET_FACILITY_DATA = 'GET_FACILITY_DATA';
export const SET_FACILITY_DATA = 'SET_FACILITY_DATA';
export const SET_FACILITY_SCHOOL_DATA = 'SET_FACILITY_SCHOOL_DATA';
export const GET_FACILITY_CODE = 'GET_FACILITY_CODE';
export const SET_FACILITY_CODE = 'SET_FACILITY_CODE';
export const GET_FACILITY_DATA_USER = 'GET_FACILITY_DATA_USER';
export const UPLOAD_FACILITY_DATA_USER = 'UPLOAD_FACILITY_DATA_USER';
export const SET_FACILITY_DATA_USER = 'SET_FACILITY_DATA_USER';
export const GET_NAVER_SALES = 'GET_NAVER_SALES'; // 매물 목록
export const GET_NAVER_SALES_PENDING = 'GET_NAVER_SALES_PENDING';
export const GET_NAVER_SALES_SUCCESS = 'GET_NAVER_SALES_SUCCESS';
export const GET_NAVER_SALE_DETAIL = 'GET_NAVER_SALE_DETAIL';
export const GET_NAVER_SALE_DETAIL_SUCCESS = 'GET_NAVER_SALE_DETAIL_SUCCESS';
export const TOGGLE_SHOW_NAVER_ITEMS = 'TOGGLE_SHOW_NAVER_ITEMS';
// 네이버매물 - 작업중

export const GET_SALE_DETAIL = 'GET_SALE_DETAIL'; // 매물 상세
export const GET_SALE_DETAIL_SUCCESS = success('GET_SALE_DETAIL');

export const GET_BROKERS = 'GET_BROKERS';
export const GET_BROKERS_SUCCESS = success('GET_BROKERS');
export const GET_BROKER_DETAIL = 'GET_BROKER_DETAIL';
export const GET_BROKER_DETAIL_SUCCESS = success('GET_BROKER_DETAIL');

export const ASK_TO_BROKER = 'ASK_TO_BROKER';
export const ASK_TO_BROKER_SUCCESS = success('ASK_TO_BROKER');
export const ASK_TO_BROKER_LOADING = loading('ASK_TO_BROKER');
export const ASK_TO_BROKER_FAILURE = failure('ASK_TO_BROKER');

export const GET_SCHOOL_INFO_WITH_KAKAO = 'GET_SCHOOL_INFO_WITH_KAKAO';
export const SET_SCHOOL_INFO_WITH_KAKAO = 'SET_SCHOOL_INFO_WITH_KAKAO';

//단지 시설 조회
export const GET_COMPLEX_FACILITY = 'GET_COMPLEX_FACILITY';
export const SET_COMPLEX_FACILITY = 'SET_COMPLEX_FACILITY';

//단지 시설 알리기
export const REPORT_COMPLEX_FACILITY = 'REPORT_COMPLEX_FACILITY';
export const REPORT_COMPLEX_FACILITY_SUCCESS = success('REPORT_COMPLEX_FACILITY');
// export const REPORT_COMPLEX_FACILITY_LOADING = loading('REPORT_COMPLEX_FACILITY');
// export const REPORT_COMPLEX_FACILITY_FAILURE = failure('REPORT_COMPLEX_FACILITY');

export const UPDATE_NEW_FEED = 'UPDATE_NEW_FEED';

export const SET_BROKER_LIST_BY_SELECTED_GOODS = 'SET_BROKER_LIST_BY_SELECTED_GOODS';
// export const GET_BROKER_LIST_BY_SELECTED_GOODS = 'GET_BROKER_LIST_BY_SELECTED_GOODS';
