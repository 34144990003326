import { getDeviceIdOrGenerate } from '../Device';

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  } else {
    os = 'other OS';
  }

  return os;
}

export function getDevice() {
  const ua = navigator.userAgent;
  if (ua.match(/iPad/i)) {
    return 'iPad';
  } else if (ua.match(/Android/i)) {
    return 'Android';
  } else if (ua.match(/iPhone|iPod/i)) {
    return 'iPhone';
  } else {
    return 'other device';
  }
}

export function getBrowser() {
  try {
    var ua = navigator.userAgent,
      tem,
      version = '',
      browser,
      M = ua.match(/(edge|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (ua.match(/edg/i)) {
      browser = 'Microsoft Edge';
    } else if (ua.match(/trident/i)) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      version = tem[1] || '';
      browser = 'IE';
    } else if (ua.match(/Seamonkey/i)) {
      browser = 'Seamonkey';
    } else if (ua.match(/Firefox/i)) {
      browser = 'Firefox';
    } else if (ua.match(/Chromium/i)) {
      browser = 'Chromium';
    } else if (ua.match(/Chrome/i)) {
      browser = 'Chrome';
    } else if (ua.match(/Safari/i)) {
      browser = 'Safari';
    } else if (ua.match(/OPR|Opera/i)) {
      tem = ua.match(/\bOPR|Opera\/(\d+)/);
      version = tem?.[1] || '';
      browser = 'Opera';
    } else if (ua.match(/MSIE/i)) {
      browser = 'Internet Explorer';
    } else {
      browser = 'other browser';
    }

    if (!version) {
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      version = M[1];
    }

    return `${browser} ${version}`;
  } catch (e) {
    console.log(e.message);
  }
}

export function getLogInfo() {
  const userData = {};
  try {
    const os = getOS();
    const device = getDevice();
    const browser = getBrowser();
    const deviceId = getDeviceIdOrGenerate();
    browser && (userData.browser = browser);
    os && (userData.os = os);
    device && (userData.device = device);
    deviceId && (userData.deviceId = deviceId);

    return encodeURIComponent(JSON.stringify(userData));
  } catch (e) {
    console.log(e.message);
  }
  return userData;
}
