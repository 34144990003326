export const SQUARE_METER_UNIT = 3.305785;

/**
 * 미터제곱 -> 평 변환 함수
 *
 * @param {Number} value 변환할 값
 * @param {Number} digits 소수점 n 자리, default: 0
 * @returns 평형
 */
export const metersquareToPyeong = (value) => {
  if (!value) return;

  const valueNum = Number(value);
  // const digitsNum = Number(digits);

  if (Number.isNaN(valueNum)) {
    console.error('invalid value');
    return;
  }
  // if (digits && Number.isNaN(digits)) {
  //   console.error('invalid value');
  //   return;
  // }

  let result = valueNum / SQUARE_METER_UNIT;

  result = Math.floor(result);

  return result;
};

export const pyeongToMetersquare = (value) => {
  if (!value) return;

  const valueNum = Number(value);

  if (Number.isNaN(valueNum)) {
    console.error('invalid value');
    return;
  }

  let result = valueNum * SQUARE_METER_UNIT;

  result = Math.floor(result);

  return result;
};

export default {
  metersquareToPyeong,
  pyeongToMetersquare,
  SQUARE_METER_UNIT,
};
