import * as AT from './actionTypes';
import * as detail from './detail/selectors';
import * as DETAIL_AT from './detail/actionTypes';

export { detail };

export const getListWide = (state) => state?.buying?.listWide;
export const getStep = (state) => state?.buying?.step;

export const getSearchWord = (state) => state?.buying?.searchWord;
export const getCategory = (state) => state?.buying?.category;
export const getDealtype = (state) => state?.buying?.dealtype;
export const getTradetype = (state) => state?.buying?.tradetype;
export const getAreasize = (state) => state?.buying?.areasize;
export const getPriceRange = (state) => state?.buying?.priceRange;
export const getCntRange = (state) => state?.buying?.cntRange;
export const getYearRange = (state) => state?.buying?.yearRange;
export const getSizemark = (state) => state?.buying?.sizemark;

export const getMapMarkerType = (state) => state?.buying?.mapMarkerType;
export const showPositiveMarker = (state) => state?.buying?.showPositiveMarker;
export const getZoomlevel = (state) => state?.buying?.zoomlevel;
export const getBounds = (state) => state?.buying?.bounds;
export const getCenter = (state) => state?.buying?.center;
export const getRegionCode = (state) => state?.buying?.regionCode;

export const getSelectedItem = (state) => state?.buying?.detail[DETAIL_AT.GET_DETAIL].getOrElse({});
export const getSelectedItemId = (state) => state?.buying?.detail[DETAIL_AT.GET_DETAIL].getOrElse({})?.complex_idx;
export const getMoveCenter = (state) => state?.buying?.moveCenterTo;
export const getShowMapLayerList = (state) => state?.buying?.showMapLayerList;
export const getShowSurroundings = (state) => state?.buying?.showSurroundings;
export const getSurroundings = (state) => state?.buying?.surroundings;
export const getSelectedSurrItem = (state) => state?.buying?.selectedSurrItem;

export const getList = (state) => state?.buying[AT.SEARCH].getOrElse([]);
export const getGoods = (state) => state?.buying[AT.SEARCH_GOODS].getOrElse(null);
export const getHistoryList = (state) => state?.buying?.historyList;

export const getError = (state) => state?.buying?.error;
export const getCardMouseover = (state) => state?.buying?.cardMouseover;

export const getSelectedExGoodsDetail = (state) => state?.buying?.selectedExGoodsDetail;

// 집구하기 가이드팝업
export const getShowPageDescription = (state) => state?.buying?.showPageDescription && location.pathname === '/buying' && location.search === '';

export const getComplexDetailMode = (state) => state?.buying?.complexDetailMode;

// 선택된 다윈 중개사
export const getSelectedDawinBroker = (state) => state?.buying.selectedDawinBroker;

// 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
export const getThisDongRealtorList = (state) => state?.buying.thisDongRealtorList;
