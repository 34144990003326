import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';

// working sagas
export function* uploadImageItem(imageCategory, imageFileName, imageFile) {
  try {
    yield call(apis.uploadImageApi.uploadImageItem, { imageCategory, imageFileName, imageFile });
    // putUrl, fullUrl, contentType
  } catch (e) {
    yield put(actions.errorUploadItemImage({ error: text.uploadImageText.uploadImageCommonText.COMMON_ERROR }));
  }
}
