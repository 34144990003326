import React, { Fragment } from 'react';
import { viewKorean } from '@/pages/BrokerPage/components/CommonUtil';
import { buiFormFunc } from '@/assets/js/bui.form';
import MarketPriceSummary from './components/MarketPriceSummary';

const PRICE_VIEW_ITEMS = {
  s: { title: '매매', placeholder: '매매희망가', priceKeys: ['price1'] },
  c: { title: '전세', placeholder: '전세희망가', priceKeys: ['price2'] },
  m: { title: '월세', placeholder: { price2: '보증금', price3: '월세' }, priceKeys: ['price2', 'price3'] },
};

export default function GoodsPriceItem({ data, onChangeCallback, marketPriceData }) {
  const tradeType = data.trade_type;

  const VIEW_ITEM = PRICE_VIEW_ITEMS[tradeType];

  const priceKeys = VIEW_ITEM.priceKeys;

  const isMonthly = tradeType === 'm';

  // 가격 input change
  const updatePrice = (e) => {
    e.preventDefault();

    let value = e.target.value;

    const priceKey = e.target.getAttribute('data-price-key');

    if (isNaN(value)) {
      value = Number.parseInt(value.replace(/[^\d]*/g, ''));

      if (isNaN(value)) {
        value = '';
      }
    }

    onChangeCallback(value, { priceKey, tradeType });
  };

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <p className="form-subject">
            <span className="form-name">{VIEW_ITEM.title}</span>
          </p>
        </div>
        <div className="form-body">
          <>
            <div className="form-area">
              {priceKeys.map((priceKey, idx) => {
                const placeholder = VIEW_ITEM.placeholder;
                const placeholderText = typeof placeholder === 'object' ? placeholder[priceKey] : placeholder;

                return (
                  <React.Fragment key={priceKey}>
                    <span key={priceKey} className="form textfield module-b style-b type-line normal-04 large-1x flex">
                      <input
                        type="number"
                        className="form-elem"
                        placeholder={placeholderText}
                        value={data[priceKey]}
                        data-price-key={priceKey}
                        onChange={updatePrice}
                        onFocus={(event) => buiFormFunc(event.target)}
                        onMouseOver={(event) => buiFormFunc(event.target)}
                      />
                      <span className="form-func">
                        <span className="form-unit">만원</span>
                      </span>
                    </span>
                    {idx < priceKeys.length - 1 ? <span className="sign">/</span> : <></>}
                  </React.Fragment>
                );
              })}
            </div>
            {priceKeys
              .map((priceKey) => {
                const price = data[priceKey];

                if (!price) return '';

                const priceText = `${viewKorean(price) || ''}원`;

                if (isMonthly && priceKey === 'price2') return `보증금: ${priceText}`;
                if (isMonthly && priceKey === 'price3') return `월세: ${priceText}`;
                return priceText;
              })
              .filter((value) => !!value)
              .join(' / ')}
          </>

          {/* 실거래가, 매물시세, 급매물가 */}
          {marketPriceData ? <MarketPriceSummary marketPriceData={marketPriceData} tradeType={tradeType} updatePrice={updatePrice} /> : <></>}
        </div>
      </div>
    </div>
  );
}
