// export const codes = {
//   apt: 'APT',
//   oft: 'OFT',
//   vll: 'VLL',
//   dsd: 'DSD',
//   yun: 'YUN',
//   dan: 'DAN',
//   dgg: 'DGG',
//   one: 'ONE',
// };

// const _names = {
//   [codes.apt]: '아파트',
//   [codes.oft]: '오피스텔',
//   [codes.vll]: '빌라',
//   [codes.dsd]: '빌라(다세대)',
//   [codes.yun]: '빌라(연립)',
//   [codes.dan]: '단독주택',
//   [codes.dgg]: '다가구',
//   [codes.one]: '원룸/투룸',
// };

import { codes, _namesForComplexName as _names } from './realtyTypes';

export const getName = (code) => {
  return _names[code];
};

export default {
  codes,
  names: _names,
  nameToCode: Object.entries(_names).reduce((acc, curr) => {
    const [key, value] = curr;
    acc[value] = key;
    return acc;
  }, {}),
  getName,
};
