import React from 'react';
import { formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';
import { TO_TEXT } from '@/services/goods/detail';

const MovingDate = ({ enter_date, enter_flexible }) => {
  const enterTypeInText = TO_TEXT.ENTER_TYPE[enter_flexible];

  const today = new Date();
  const moveInDate = new Date(enter_date);

  const isNegotiable = enter_flexible === 2 || enter_flexible === 'Y';
  const isImmediateMoveIn = today >= moveInDate;
  const hasSpecificMoveInDate = today < moveInDate;

  const getDisplayText = () => {
    if (isNegotiable || isImmediateMoveIn) return `입주일: ${enterTypeInText}`;
    if (hasSpecificMoveInDate) return `입주가능일: ${formatDate(enter_date)} 이후`;
  };

  return (
    <>
      {enter_date || enter_flexible ? (
        <span className="d_date">
          <b>{getDisplayText()}</b>
        </span>
      ) : null}
    </>
  );
};

export default React.memo(MovingDate);
