import React, { useState, useEffect, useRef } from 'react';
import Input from './Input';
import Result from './Result';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';
import { routeToComplexDetail } from '@/pages/Buying/route';
import { COOKIE_CONSTS } from '@/resources/global';
import { getCookieByKey, setCookieByKey } from '@/data/cookie';
import usePersonalize from './usePersonalize';
import useDocumentEvent from '@/hooks/useDocumentEvent';
import { FeedType, TagType } from '@/services/Community';

// RECENT: 최근검색어, MYCOMPLEX: 관심단지, MYHOME: 우리집
export const SEARCH_HISTORY_TYPES = { RECENT: 'RECENT', MYCOMPLEX: 'MYCOMPLEX', MYHOME: 'MYHOME' };

const isIe = !!document.documentMode;

/**
 * @param {boolean} totalSearch 모든 타입 조회
 * @param {Array} markTypes (optional) API 조회 결과 중 표시할 타입 제한 APT, OFT. 없는 경우 모두 표출
 * @param {function} onSelected 검색결과 선택 시, 기본동작이 아닐 경우
 * @param {boolean} complexOnly 결과에서 단지정보(APT || OFT)만 표출
 * @param {boolean} showSearchHistory 최근 검색어 표출 여부
 * @param {boolean} fixTopOnFocus input에 focus되면 최상단 고정
 * @param {boolean} redirectToBuying Buying으로 라우팅 여부
 * @param {function} handleRedirectToOther 다른 URL로 라우팅 처리하는 function. complex_idx를 파라미터로 전달함
 *
 **/
const Search = ({
  redirectToBuying,
  handleRedirectToOther,
  totalSearch = false,
  markTypes,
  markRule,
  // isMobile,
  onSelected,
  complexOnly = false,
  showKeywordDeleteButon,
  // showAnalysisLink = false, //분석 바로가기
  showSearchHistory = true,
  placeholder,
  wrapperClass = '',
  defaultInput = '',
  setBrokerNumber,
  tags,
  feedType,
}) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [highlightIdx, setHighlightIdx] = useState(-1);
  // const [showFilterOption, setShowFilterOption] = useState(false);

  const inputRef = useRef();

  let data = useSelector(selectors.common.getSearchResult);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const isMobile = useSelector(selectors.common.isMobile);

  // 분석 바로가기
  // const [links, setLinks] = useState([]);
  // const [searchMode, setSearchMode] = useState(!showAnalysisLink);

  useEffect(() => {
    if (defaultInput) {
      inputRef.current.value = defaultInput;
    }
  }, []);

  useEffect(() => {
    if (feedType === FeedType.POST_FOR_BUY) {
      const AddressTag = tags?.filter((el) => el.type === TagType.ADDRESS_TAG);

      if (AddressTag?.length > 0) {
        const code = AddressTag[0]?.key;

        if (code?.length < 6) {
          dispatch(actions.common.getSearchBrokerListBySetting(setBrokerNumber, { 'id-type': 'SI-GUN-GU', id: code, sort: 'evaluated-point' }));
        } else {
          dispatch(actions.common.getSearchBrokerListBySetting(setBrokerNumber, { 'id-type': 'DONG', id: code, sort: 'evaluated-point' }));
        }
      }
    }
  }, [tags]);

  // 관심단지 조회
  const interestComplex = usePersonalize(isLoggedIn);

  const list = data?.list;
  // 단지 결과만
  if (complexOnly && Array.isArray(list)) {
    const filtered = list?.filter((item) => item.estate === 'APT' || item.estate === 'OFT');
    data = { ...data, list: filtered };
  }

  if (markRule) {
    data = markRule(data);
  } else if (markTypes && Array.isArray(markTypes) && Array.isArray(list)) {
    // API 조회 결과 중 표시할 타입 제한
    const filtered = list?.filter((item) => markTypes.indexOf(item.estate) > -1);
    data = { ...data, list: filtered };
  }

  // 검색
  const search = (idx) => {
    const list = data?.list;
    const selected = list?.[idx];
    const title = selected?.title || '';

    if (selected) {
      setKeyword(title);
      inputRef.current.value = title;

      setShow(false);
      // setSearchMode(false);

      if (onSelected) {
        // 검색결과 기본동작이 아닐 경우
        onSelected(selected);
      } else {
        // 검색결과 기본동작: 단지 상세로 이동

        // 최근검색어 업데이트
        update_latest_search({ ...selected });
        // 단지 상세
        dispatch(goToDetail(selected, redirectToBuying, handleRedirectToOther));
      }
    }
  };

  //하이라이트 된 아이템 따라 스크롤
  useEffect(() => {
    if (highlightIdx > -1) {
      document.getElementById('mainSearchResultItem_' + highlightIdx)?.scrollIntoView(isIe ? false : { block: 'nearest' });
    }
  }, [highlightIdx]);

  // useEffect(async () => {
  //   if (showAnalysisLink) {
  //     const result = await apis.analysisApi.getAnalysisLinkMenu();
  //     setLinks(result);
  //   }
  // }, [showAnalysisLink]);

  const wrapperRef = useRef();
  const clickOutside = (event) => {
    const searchArea = wrapperRef.current;
    const isInside = searchArea?.contains(event.target);
    if (!isInside) {
      show && setShow(false);
      // searchMode && setSearchMode(false);
    }
  };

  useDocumentEvent('click', clickOutside);

  return (
    <>
      <div ref={wrapperRef} className={wrapperClass}>
        <Input
          inputRef={inputRef}
          setShow={setShow}
          totalSearch={totalSearch}
          keyword={keyword}
          setKeyword={setKeyword}
          isMobile={isMobile}
          highlightIdx={highlightIdx}
          setHighlightIdx={setHighlightIdx}
          maxLength={data.list?.length || 0}
          search={search}
          complexOnly={complexOnly}
          showKeywordDeleteButon={showKeywordDeleteButon}
          // searchMode={searchMode}
          // showAnalysisLink={showAnalysisLink}
          placeholder={placeholder}
        />
        <Result
          show={show}
          data={data}
          setShow={setShow}
          highlightIdx={highlightIdx}
          setHighlightIdx={setHighlightIdx}
          search={search}
          keyword={keyword}
          interestComplex={interestComplex}
          // setSearchMode={setSearchMode}
          markTypes={markTypes}
          showSearchHistory={showSearchHistory}
        />
        {/* Mask 검색 영역 밖을 클릭했을 때 결과 닫기 */}
        {/* {show && <div onClick={clickOutside} style={{ position: 'fixed', left: 0, top: '55px', width: '100%', height: '100%' }} />} */}
      </div>
    </>
  );
};

async function update_latest_search({ title, estate, lat, lng, complex_idx, complex_name }) {
  const epochTime = new Date().getTime();
  let search_history = await getCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY);
  search_history = search_history || [];

  if (search_history.length > 2) search_history.splice(0, 1);
  search_history.push({ createdAt: epochTime, title, estate, lat, lng, complex_idx, complex_name });
  setCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY, search_history);
}

export const goToDetail = (selected, redirectToBuying, handleRedirectToOther) => (dispatch) => {
  if (selected) {
    const { title, estate, lat, lng, complex_idx } = selected;

    const goComplexDetail = () => {
      if (!handleRedirectToOther) {
        dispatch(routeToComplexDetail(complex_idx));
      } else {
        handleRedirectToOther(complex_idx);
      }
    };

    if (lat && lng) {
      if (redirectToBuying) {
        dispatch(actions.router.push('/buying'));
      }
      switch (estate) {
        case 'STREET':
        case 'STATION':
          dispatch(actions.buying.moveCenter([lat, lng]));
          break;
        case 'OFT':
        case 'APT':
        default:
          dispatch(actions.buying.moveCenter([lat, lng]));
          goComplexDetail();
          break;
      }
    } else {
      alert('위치정보가 없습니다.');
    }
  }
};

export default Search;
