import * as actions from '@/data/rootActions';

// WEB은 단지상세 컴포넌트 내에서 일어나는 routing을 모두 replace
// MOBILE은 모두 push 한다. (안드로이드 back 버튼 때문)
// 그래서 뒤로가기도 그에 맞는 처리 필요
const handleGoBack = (goBackTo, isMobile) => (dispatch) => {
  if (isMobile && history.length > 2) {
    dispatch(actions.router.goBack());
  } else {
    // 공유하기로 접근해서 history가 짧은 경우나, 웹인 경우 지정한 곳으로 이동
    dispatch(actions.router.replace(goBackTo));
  }
};

export default handleGoBack;
