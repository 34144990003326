import React, { useState, useMemo, useRef, useEffect } from 'react';
import toggle from '@/hocs/toggle';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '@/data';
import { FeedType, TagType, CreatePoint, DefaultFeedTypeByCreatePoint } from '@/services/Community';
import { parseCallBack } from '@/services/Helper';
import { STATUS } from '@/data/utils';
import useUpdate from './hooks/useUpdate';
import linkify from '@/services/Helper/linkify';
import { validCheck } from './utils';
import useSetSpinner from './hooks/useSetSpinner';
import { geInitialData, getInitialAddress, getInitialAddressByRegionCode } from '@/combinedComp/DualAddress/utils';
import { apiClient } from '@/apis/clients';
import { getSpecificLocation } from '@/data/address/services';
import Post from './Post';
import useInitializeFlagTag from './components/PostForBuy/hooks/useInitializeFlagTag';
import { MODE } from '@/combinedComp/DualAddress';
import usePostWithOutLogIn from './hooks/usePostWithOutLogIn';
import useSetFeedType from './hooks/useSetFeedType';

const COMPLEX_DEFAULT_SET_LIST = [CreatePoint.COMPLEX_POST, CreatePoint.POST_FOR_BUY];

const Create = () => {
  const dispatch = useDispatch();
  const query = parseCallBack();

  const createPoint = query?.createPoint;
  const updateFeedIdx = query?.updateFeed;
  const isUpdate = !!updateFeedIdx;

  const nickname = useSelector(selectors.auth.getNickname);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const userInfo = useSelector(selectors.auth.getUserInfo);

  const [feedType, setFeedType] = useState(DefaultFeedTypeByCreatePoint[createPoint]);
  useSetFeedType({ setFeedType });

  const [tags, setTags] = useState([]);
  const [numericTags, setNumericTags] = useState({ price: undefined, area: undefined });
  const [contents, setContents] = useState(''); // 글 내용
  const [status, setStatus] = useState({});
  const addedTagsRef = useRef([]); // 사용자 작성 태그
  const imagesRef = useRef([]); // images

  const updateFeedDetail = useUpdate({ updateFeedIdx, setFeedType, setTags, setNumericTags, setContents, addedTagsRef, imagesRef });

  function submit(data, e) {
    e.preventDefault();
    if (!userInfo) {
      dispatch(actions.common.pleaseLoginPopup(true));
    } else if (nickname || userInfo?.mbr_type === 'BU') {
      setStatus({ status: STATUS.LOADING });
      if (validCheck(data, dispatch, userInfo)) {
        const payload = { ...data, contents: linkify(data.contents).replace(/(\n|\r\n)/g, '<br>') };

        if (!isUpdate) {
          dispatch(actions.community.createFeed(payload, setStatus, location, e));
        } else {
          dispatch(actions.community.editFeed(updateFeedIdx, payload, setStatus));
        }
      } else {
        setStatus({ status: STATUS.NOTASKED });
      }
    } else {
      dispatch(
        actions.common.alert({
          contents: '닉네임을 설정해주세요.',
        })
      );
    }
  }

  // 주소 정보 세팅
  const selectedComplex = useSelector(selectors.buying.getSelectedItem);
  const regionCode = useSelector(selectors.buying.getRegionCode);
  const isFirstTagUpdate = useRef(true);
  const needDefaultRegionCode = createPoint === CreatePoint.POST_FOR_BUY;

  const [complexAddressCode, setComplexAddressCode] = useState();
  const [complexAddressName, setComplexAddressName] = useState();

  useEffect(async () => {
    if (isFirstTagUpdate.current) {
      const c_DongCode = await apiClient.get(`/complex/${selectedComplex?.complex_idx}/dong-codes`);
      const c_AddressName = await getSpecificLocation(c_DongCode[0]);
      setComplexAddressCode(c_DongCode);
      setComplexAddressName(c_AddressName);
    }
  }, []);

  const defaultDualAddressData = useMemo(() => {
    let result;
    if (isUpdate) {
      if (updateFeedDetail?.tags) {
        const updateFeedType = updateFeedDetail.feedType;
        const updateTags = updateFeedDetail.tags;

        const addressTag = updateTags.filter((item) => item.type === TagType.ADDRESS_TAG)?.[0];
        const complexTag = updateTags.filter((item) => item.type === TagType.COMPLEX_TAG)?.[0];

        if (addressTag) {
          const addressCode = addressTag?.key;
          let complex;
          if (complexTag) {
            complex = {
              complex_idx: complexTag.key,
              complex_name: complexTag.name,
            };

            const goodsType = updateTags.filter((item) => item.type === TagType.GOODS_TYPE_TAG)?.[0];
            if (goodsType?.key) {
              complex.complex_type = goodsType?.key === '아파트' ? 'APT' : 'OFT';
            }
          } else if (updateFeedType === FeedType.POST_FOR_BUY) {
            complex = {
              complex_idx: 'all',
              complex_name: 'all',
              complex_type: 'all',
            };
          }

          result = geInitialData(addressCode, complex);

          const codeForCompare = addressCode.replace('00000', '');
          const isDongCode = codeForCompare?.length === 10;
          if (isDongCode) {
            result.selectedAddress.dong.name = addressTag.name;
          } else if (codeForCompare) {
            result.selectedAddress.sigungu.name = addressTag.name;
          }
          return result;
        } else {
          return { selectedAddress: {} };
        }
      } else {
        return false;
      }
    } else if (needDefaultRegionCode && regionCode?.code && isFirstTagUpdate.current && complexAddressCode && complexAddressName) {
      // region code가 필요한 경우 default address data setting
      // 주소 정보 세팅
      isFirstTagUpdate.current = false;
      result = getInitialAddressByRegionCode(regionCode);
      const queryString = parseCallBack();
      const d_trade_type = queryString['d_trade_type'];

      if (
        (d_trade_type && selectedComplex?.complex_idx && COMPLEX_DEFAULT_SET_LIST.indexOf(createPoint) > -1) ||
        feedType === FeedType.COMPLEX_POST
      ) {
        result.selectedAddress = getInitialAddress(complexAddressCode);
        result.selectedAddress.dong.name = complexAddressName?.name;
        result.selectedComplex = {
          complex_idx: selectedComplex.complex_idx,
          complex_name: selectedComplex.complex_name,
          complex_type: selectedComplex.complex_type,
        };

        const addressTags = [
          {
            type: TagType.ADDRESS_TAG,
            key: complexAddressName?.code,
            name: complexAddressName?.name,
          },
        ];
        setTags([...addressTags, { type: TagType.COMPLEX_TAG, key: `${selectedComplex.complex_idx}`, name: selectedComplex.complex_name }]);
      } else {
        const addressTags = [
          {
            type: TagType.ADDRESS_TAG,
            key: regionCode?.code,
            name: regionCode?.region_4depth_name ? regionCode?.region_4depth_name : regionCode?.region_3depth_name,
          },
        ];
        setTags(addressTags);
      }

      return result;
    } else if (isFirstTagUpdate.current && !needDefaultRegionCode) {
      // default address data 필요없는 경우
      isFirstTagUpdate.current = false;
      return { selectedAddress: { sido: {}, sigungu: {}, dong: {} } };
    }
  }, [regionCode, selectedComplex, isUpdate, updateFeedDetail, complexAddressCode, complexAddressName]);

  const [step, setStep] = useState(1);
  const [safetyPop, setSafetyPop] = useState(false);
  const [dualAddressData, setDualAddressData] = useState({ mode: MODE.TEXT });
  const [error, setError] = useState('');

  const headerHashtags = useMemo(() => {
    const typeArray = tags?.map((item) => item.type);

    const addressTag = tags[typeArray.indexOf(TagType.ADDRESS_TAG)];
    const complexTag = tags[typeArray.indexOf(TagType.COMPLEX_TAG)];
    const goodsTypeTag = tags[typeArray.indexOf(TagType.GOODS_TYPE_TAG)];

    const temp = [];
    addressTag && temp.push(addressTag);
    complexTag && temp.push(complexTag);

    // 단지가 선택되어있는 경우 매물유형은 step2에서 선택할 수 없고 header hash tag로 표시한다.
    if (goodsTypeTag && complexTag) {
      temp.push(goodsTypeTag);
    }

    if (temp && temp?.length > 0) {
      return temp?.map((item) => (
        <span className="hashtag" key={item.type}>
          {item.name}
        </span>
      ));
    } else {
      return false;
    }
  }, [tags]);

  useSetSpinner({ feedType, status, isUpdate });
  usePostWithOutLogIn({ query });
  useInitializeFlagTag({ defaultDualAddressData, setDualAddressData, tags, setTags });

  // update가 아닐때 + 업데이트인데 updateFeedDetail이 준비 되어있을때
  return isLoggedIn && (!isUpdate || updateFeedDetail) ? (
    <Post
      feedType={feedType}
      createPoint={createPoint}
      setFeedType={setFeedType}
      contents={contents}
      setContents={setContents}
      tags={tags}
      setTags={setTags}
      numericTags={numericTags}
      setNumericTags={setNumericTags}
      addedTagsRef={addedTagsRef}
      imagesRef={imagesRef}
      submit={submit}
      status={status}
      defaultDualAddressData={defaultDualAddressData}
      isUpdate={isUpdate}
      updateFeedDetail={updateFeedDetail}
      step={step}
      setStep={setStep}
      safetyPop={safetyPop}
      setSafetyPop={setSafetyPop}
      dualAddressData={dualAddressData}
      setDualAddressData={setDualAddressData}
      headerHashtags={headerHashtags}
      setError={setError}
      error={error}
    />
  ) : (
    <></>
  );
};

export default toggle(Create);
