import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as allActions from '@/data/rootActions';
import * as selectors from '../selectors';
import apis from '@/apis';

export function* getDailyReportList() {
  try {
    const report_date = yield select(selectors.dailyReport.getReportDate);
    const payload = {
      report_date: report_date,
    };
    yield put(allActions.common.toggleGlobalSpinner(true));
    yield put(actions.dailyReportIni());
    const response = yield call(apis.brokerPageApi.getDailyReport, payload);
    //const { list } = response;
    if (response.rltObj && response.rltObj.length > 0) {
      yield put(actions.getDailyReportSuccess(response.rltObj));
      yield put(allActions.common.toggleGlobalSpinner(false));
    } else {
      yield put(actions.dailyReportIni());
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* saveDailyReport() {
  try {
    const report = yield select(selectors.dailyReport.getReport);
    let payload_arr = [];
    report.seller ? payload_arr.push(report.seller) : false;
    report.buyer ? payload_arr.push(report.buyer) : false;
    report.etc ? payload_arr.push(report.etc) : false;
    report.advertisement ? payload_arr.push(report.advertisement) : false;
    report.visit ? payload_arr.push(report.visit) : false;
    report.tmp_contract ? payload_arr.push(report.tmp_contract) : false;
    report.contract ? payload_arr.push(report.contract) : false;
    report.balance ? payload_arr.push(report.balance) : false;
    report.local ? payload_arr.push(report.local) : false;
    const payload = {
      report: payload_arr,
    };
    const response = yield call(apis.brokerPageApi.saveDailyReport, payload);
    if (response) {
      yield put(
        allActions.common.alert({
          contents: '저장하였습니다',
        })
      );
    }
    yield put(actions.getDailyReportList());
  } catch (e) {
    yield put(
      allActions.common.alert({
        contents: e.message,
      })
    );
    //console.log(e.message);
  }
}
