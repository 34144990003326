import * as AT from './actionTypes';
const INITIAL_STATE = {
  contractReview: {
    origin: '',
    data: [],
    total: 0,
  },
};

export default function review(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_CONTRACT_REVIEWS: {
      const { origin, data, total } = action.payload || {};
      return { ...state, contractReview: { origin, total, data: [...state.contractReview.data, ...data] } };
    }
    case AT.RESET_CONTRACT_REVIEWS:
      return { ...state, contractReview: INITIAL_STATE.contractReview };
    default:
      return { ...state };
  }
}
