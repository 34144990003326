import React, { useMemo } from 'react';
import CONSTANTS from '../../constants';
import ChangeCellphoneNumber from './ChangeCellphoneNumber';

const Modal = ({ current, setCurrent, setIsModalOpen }) => {
  const handleClose = () => setIsModalOpen(false);

  // const [current, setCurrent] = useState(CONSTANTS.PHONE_CHANGE);
  const mainText = useMemo(() => {
    if (current === CONSTANTS.PHONE_CHANGE) {
      return <ChangeCellphoneNumber setIsModalOpen={setIsModalOpen} setCurrent={setCurrent} current={current} />;
    }
    if (current === CONSTANTS.PASSWORD_CHANGE) {
      return;
    }
  }, [current]);

  return (
    <div
      className="content-popup module-a style-a type-a medium enabled active"
      id="changeMobileNumber"
      data-bui-toggle="contentsPopup"
      style={{ zIndex: '500' }}>
      <div
        className="popup-page-body"
        // style={{ maxHeight: '75%' }}
      >
        <div className="popup-local">
          <div className="popup-local-head">
            <h2 className="popup-local-subject">
              <span className="popup-local-name">휴대폰번호 변경</span>
            </h2>
          </div>
          {mainText}
          <div className="popup-local-func" style={{ justifyContent: 'end' }}>
            <span className="button-area">
              <button onClick={handleClose} type="button" className="btn popup-close" style={{ marginLeft: '200px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                  <title>닫기</title>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
