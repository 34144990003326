import * as AT from './actionTypes';
import brokerOfficeInfoReducer from './brokerOfficeInfo/reducers';
import bkQnAReducer from './bkQnA/reducers';
import jointBrokerageReducer from './jointBrokerage/reducers';
import mgCounselingReducer from './mgCounseling/reducers';
import mgCustomerReducer from './mgCustomer/reducers';
import mgGoodsReducer from './mgGoods/reducers';
import mgContractReducer from './mgContract/reducers';
import mgQaReducer from './mgQa/reducers';
import mgRateReducer from './mgRate/reducers';
import mgComplexImgReducer from './mgComplexImg/reducers';
import dataRoomReducer from './dataRoom/reducers';
import suggestionReducer from './suggestion/reducers';
import noticeReducer from './notice/reducers';
import internalNoticeReducer from './internalNotice/reducers';
import oneOnOneReducer from './oneOnOne/reducers';
import setAreaReducer from './setArea/reducers';
import dailyReportReducer from './dailyReport/reducers';
import communityReducer from './community/reducers';

// CONSTANTS 에서 꺼내서 목록 집어넣는건 테스트 용도임 서버에서 개발 끝나면 [] 로 변경 필
const INITIAL_STATE = {
  error: '',
  brokerAlarmList: '',
  statusBoard: '',
};

export default function brokerPage(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.BROKER_PAGE_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    //=======================alarm=======================
    case AT.GET_BROKER_ALARM_LIST: {
      return {
        ...state,
        broker_office_idx: action.broker_office_idx,
        error: '',
      };
    }
    case AT.SET_BROKER_ALARM_LIST: {
      return {
        ...state,
        brokerAlarmList: action.brokerAlarmList,
      };
    }
    case AT.READ_ALARM: {
      return {
        ...state,
        broker_office_idx: action.broker_office_idx,
        goods_idx: action.goods_idx,
      };
    }
    case AT.DELETE_ALARM: {
      return {
        ...state,
        broker_office_idx: action.broker_office_idx,
        goods_idx: action.goods_idx,
      };
    }
    case AT.DELETE_RA_ALARM: {
      return {
        ...state,
        broker_office_idx: action.broker_office_idx,
        read: action.read,
      };
    }

    //=======================statusboard=======================
    case AT.GET_BROKER_BOARD_NEW: {
      return {
        ...state,
      };
    }
    case AT.SET_BOARD_NEW: {
      return {
        ...state,
        board_new: action.board_new,
      };
    }
    case AT.SET_ONEONEONE_NEW: {
      return {
        ...state,
        board_new: { ...state.board_new, oneonone_new: 0 },
      };
    }
    default: {
      return {
        ...state,
        brokerOfficeInfo: brokerOfficeInfoReducer(state.brokerOfficeInfo, action),
        bkQnA: bkQnAReducer(state.bkQnA, action),
        jointBrokerage: jointBrokerageReducer(state.jointBrokerage, action),
        mgGoods: mgGoodsReducer(state.mgGoods, action),
        mgContract: mgContractReducer(state.mgContract, action),
        mgCounseling: mgCounselingReducer(state.mgCounseling, action),
        mgCustomer: mgCustomerReducer(state.mgCustomer, action),
        mgQa: mgQaReducer(state.mgQa, action),
        mgRate: mgRateReducer(state.mgRate, action),
        mgComplexImg: mgComplexImgReducer(state.mgComplexImg, action),
        dataRoom: dataRoomReducer(state.dataRoom, action),
        suggestion: suggestionReducer(state.suggestion, action),
        notice: noticeReducer(state.notice, action),
        internalNotice: internalNoticeReducer(state.internalNotice, action),
        oneOnOne: oneOnOneReducer(state.oneOnOne, action),
        setArea: setAreaReducer(state.setArea, action),
        dailyReport: dailyReportReducer(state.dailyReport, action),
        community: communityReducer(state.community, action),
      };
    }
  }
}
