import { apiClient2 } from '@/apis/clients';
import { objectToQuerystring } from '@/services/Helper';

export const api = {
  async getUnreadNotiCount() {
    try {
      const path = '/notification-api/v2/push/histories/unread-counts';
      return await apiClient2.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getSettings() {
    try {
      return await apiClient2.get(`/notification-api/v2/notification-settings`);
    } catch (e) {
      throw Error(e.detail);
    }
  },
  async getSetting(category_key) {
    try {
      return await apiClient2.get(`/notification-api/v1/push/setting/single/${category_key}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async toggleAll(payload) {
    try {
      return await apiClient2.patch(`/notification-api/v2/notification-settings/general`, payload);
    } catch (e) {
      throw Error(e.detail);
    }
  },
  async toggleSingle(category_key, payload) {
    try {
      return await apiClient2.patch(`/notification-api/v2/notification-settings/categories?category_key=${category_key}`, payload);
    } catch (e) {
      throw Error(e.detail);
    }
  },
  async toggleNight(payload) {
    try {
      return await apiClient2.patch(`/notification-api/v2/notification-settings/night-general`, payload);
    } catch (e) {
      throw Error(e.detail);
    }
  },
  // async setGeneralPush(payload) {
  //   try {
  //     return await apiClient2.patch(`/notification-api/v1/push/setting/general`, payload);
  //   } catch (e) {
  //     throw Error(e.message);
  //   }
  // },
  // async getNotiHistory(category_key) {
  //   try {
  //     // return await apiClient2.get(`/notification-api/v1/push/history/${category_key}`);
  //     return await apiClient2.get(`/notification-api/v2/push/histories`);
  //   } catch (e) {
  //     throw Error(e.message);
  //   }
  // },
  async getNotiHistory(payload) {
    try {
      // return await apiClient2.get(`/notification-api/v1/push/history/${category_key}`);
      return await apiClient2.get(`/notification-api/v2/push/histories${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getSelectedNotiHistory(category, payload) {
    try {
      return await apiClient2.get(`/notification-api/v2/push/histories/categories/bulk/${category}${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async readNoti(category_key, timestamp) {
    try {
      // return await apiClient2.patch(`/notification-api/v1/push/history/single/${category_key}`, payload);
      return await apiClient2.patch(`/notification-api/v2/push/histories/${category_key}/${timestamp}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async readAllNoti() {
    try {
      return await apiClient2.patch(`/notification-api/v2/push/histories/all/isRead`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async unreadNotiCount() {
    try {
      return await apiClient2.get(`/notification-api/v2/push/histories/unread-counts`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 사용자 단위 푸시 토큰 CRUD
  async getPushToken() {
    try {
      return await apiClient2.get('/notification-api/v1/push/tokens');
    } catch (e) {
      throw Error(e.message);
    }
  },
  async patchPushToken(payload) {
    try {
      return await apiClient2.patch('/notification-api/v1/push/tokens', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deletePushToken(device_id) {
    try {
      return await apiClient2.delete('/notification-api/v1/push/tokens', { data: { device_id } });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkingQuickSaleregions() {
    const prefix = '/notification-api/v2/quick-sale-settings/regions';
    try {
      return await apiClient2.get(prefix);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updateQuickSaleRegions(regions) {
    const prefix = '/notification-api/v2/quick-sale-settings/regions';
    try {
      return await apiClient2.patch(prefix, regions);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async checkingQuickSaleGoodsType() {
    const prefix = '/notification-api/v2/quick-sale-settings/trade-types';
    try {
      return await apiClient2.get(prefix);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updateQuickSaleGoodsType(tradeTypes) {
    const prefix = '/notification-api/v2/quick-sale-settings/trade-types';
    try {
      return await apiClient2.patch(prefix, tradeTypes);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
