import { failure, loading, success } from '@/data/utils';

// ===================== 요청 : 전화로 집내놓기 ===================== //
// export const ADDRESS_CALL_REQUEST = 'ADDRESS/ADDRESS_CALL_REQUEST';
// export const ADDRESS_CALL_REQUEST_SUCCESS = success(ADDRESS_CALL_REQUEST);
// export const ADDRESS_CALL_REQUEST_FAILURE = failure(ADDRESS_CALL_REQUEST);
// export const ADDRESS_CALL_REQUEST_LOADING = loading(ADDRESS_CALL_REQUEST);

// ===================== 요청 : 직접 집내놓기 ===================== //
// export const ADDRESS_MANUAL_GET_STEP_DATA = 'ADDRESS/ADDRESS_MANUAL_GET_STEP_DATA';
// // [[NEW LOGIC]]-----------------------------------------------------------
// export const ADDRESS_MANUAL_GET_SAVED_FORM = 'ADDRESS/ADDRESS_MANUAL_GET_SAVED_FORM';
// export const ADDRESS_MANUAL_GET_NEW_FORM = 'ADDRESS/ADDRESS_MANUAL_GET_NEW_FORM';
// export const ADDRESS_MANUAL_STORE_SAVED_FORM = 'ADDRESS/ADDRESS_MANUAL_STORE_SAVED_FORM';
export const ADDRESS_MANUAL_UPDATE_SAVED_FORM = 'ADDRESS/ADDRESS_MANUAL_UPDATE_SAVED_FORM';
// export const ADDRESS_MANUAL_UPDATE_SAVED_FORM_DB = 'ADDRESS/ADDRESS_MANUAL_UPDATE_SAVED_FORM_DB';
// export const ADDRESS_MANUAL_REGISTER_SAVED_FORM = 'ADDRESS/ADDRESS_MANUAL_REGISTER_SAVED_FORM';
// export const ADDRESS_MANUAL_CLEAR_SAVED_FORM = 'ADDRESS/ADDRESS_MANUAL_CLEAR_SAVED_FORM';
// export const ADDRESS_MANUAL_GET_COMPLETE_FORM = 'ADDRESS/ADDRESS_MANUAL_GET_COMPLETE_FORM';
// export const ADDRESS_MANUAL_UPDATE_COMPLETE_FORM = 'ADDRESS/ADDRESS_MANUAL_UPDATE_COMPLETE_FORM';
// export const ADDRESS_MANUAL_HANDLE_MY_REREGISTER = 'ADDRESS/ADDRESS_MANUAL_HANDLE_MY_REREGISTER';
// export const ADDRESS_MANUAL_HANDLE_COMPLEX_PRICE_INFO = 'ADDRESS/ADDRESS_MANUAL_HANDLE_COMPLEX_PRICE_INFO';
// export const ADDRESS_MANUAL_GET_FACILITY_CODE = 'ADDRESS/ADDRESS_MANUAL_GET_FACILITY_CODE';
// export const ADDRESS_MANUAL_SET_FACILITY_CODE = 'ADDRESS/ADDRESS_MANUAL_SET_FACILITY_CODE';
// export const ADDRESS_MANUAL_GET_FACILITY_DATA = 'ADDRESS/ADDRESS_MANUAL_GET_FACILITY_DATA';
// [[NEW LOGIC]]-----------------------------------------------------------
// export const ADDRESS_MANUAL_GET_STEP_DATA_SUCCESS = success(ADDRESS_MANUAL_GET_STEP_DATA);
// export const ADDRESS_MANUAL_GET_STEP_DATA_FAILURE = failure(ADDRESS_MANUAL_GET_STEP_DATA);
// export const ADDRESS_MANUAL_GET_STEP_DATA_LOADING = loading(ADDRESS_MANUAL_GET_STEP_DATA);

// export const ADDRESS_MANUAL_HANDLE_NEXT_STEP = 'ADDRESS/ADDRESS_MANUAL_HANDLE_NEXT_STEP';
// export const ADDRESS_MANUAL_HANDLE_NEXT_STEP_SUCCESS = success(ADDRESS_MANUAL_HANDLE_NEXT_STEP);
// export const ADDRESS_MANUAL_HANDLE_NEXT_STEP_FAILURE = failure(ADDRESS_MANUAL_HANDLE_NEXT_STEP);
// export const ADDRESS_MANUAL_HANDLE_NEXT_STEP_LOADING = loading(ADDRESS_MANUAL_HANDLE_NEXT_STEP);

// // 오류 =====================
// export const ADDRESS_REQUEST_ERROR = 'ADDRESS/ADDRESS_REQUEST_ERROR';
// export const ADDRESS_RESET_ERROR_STATE = 'ADDRESS/ADDRESS_RESET_ERROR_STATE';

// ===================== itemInfo 변경 ===================== //

// // 매물수정하기 페이지로 넘어갈 때, 매물 정보를 받아와서 한 번에 매물 정보 입력시킬 때 사용
// export const ADDRESS_SET_ITEM_INFO = 'ADDRESS/ADDRESS_SET_ITEM_INFO';
export const ADDRESS_CHANGE_ITEM_INFO = 'ADDRESS/ADDRESS_CHANGE_ITEM_INFO';
export const ADDRESS_CLEAR = 'ADDRESS/ADDRESS_CLEAR';

export const ADDRESS_GET_ADDR_SiDo = 'ADDRESS/GET_ADDR_SiDo';
export const ADDRESS_SET_ADDR_SiDo = 'ADDRESS/SET_ADDR_SiDo';
export const ADDRESS_CHANGE_SiDoCode = 'ADDRESS/CHANGE_SiDoCode';
export const ADDRESS_CHANGE_SiDoName = 'ADDRESS/CHANGE_SiDoName';

export const ADDRESS_GET_ADDR_SiGunGu = 'ADDRESS/GET_ADDR_SiGunGu';
export const ADDRESS_SET_ADDR_SiGunGu = 'ADDRESS/SET_ADDR_SiGunGu';
export const ADDRESS_CHANGE_SiGunGuCode = 'ADDRESS/CHANGE_SiGunGuCode';
export const ADDRESS_CHANGE_SiGunGuName = 'ADDRESS/CHANGE_SiGunGuName';
export const ADDRESS_CHANGE_SiGunGuNameSecond = 'ADDRESS/CHANGE_SiGunGuNameSecond';

// 2021-08-11 @장혁진 추가 : 법정동코드 분기 후 ACTION TYPE 추가
export const ADDRESS_GET_ADDR_SiGunGuFirst = 'ADDRESS/GET_ADDR_SiGunGuFirst';
export const ADDRESS_SET_ADDR_SiGunGuFirst = 'ADDRESS/SET_ADDR_SiGunGuFirst';
export const ADDRESS_CHANGE_SiGunGuFourDigitCode = 'ADDRESS/CHANGE_SiGunGuFourDigitCode';
export const ADDRESS_CHANGE_SiGunGuEightDigitCode = 'ADDRESS/CHANGE_SiGunGuEightDigitCode';

export const ADDRESS_GET_ADDR_SiGunGuSecond = 'ADDRESS/GET_ADDR_SiGunGuSecond';
export const ADDRESS_SET_ADDR_SiGunGuSecond = 'ADDRESS/SET_ADDR_SiGunGuSecond';

export const ADDRESS_GET_ADDR_DongRi = 'ADDRESS/GET_ADDR_DongRi';
export const ADDRESS_SET_ADDR_DongRi = 'ADDRESS/SET_ADDR_DongRi';
export const ADDRESS_CHANGE_DongRiCode = 'ADDRESS/CHANGE_DongRiCode';

// 동리 2 단계로 쪼개기 - SET할 때 First는 안쓰고 기동 DongRi에서 저장..SiGunGu도 마찬가지..
// TODO: 코드 부채 정리 하기.
export const ADDRESS_GET_ADDR_DongRiFirst = 'ADDRESS/GET_ADDR_DongRiFirst';
export const ADDRESS_SET_ADDR_DongRiFirst = 'ADDRESS/SET_ADDR_DongRiFirst';
export const ADDRESS_GET_ADDR_DongRiSecond = 'ADDRESS/GET_ADDR_DongRiSecond';
export const ADDRESS_SET_ADDR_DongRiSecond = 'ADDRESS/SET_ADDR_DongRiSecond';

// 시군구 2차가 있을 경우 1차에서 나온 코드 저장
export const ADDRESS_SET_SiGunGuFirstCode = 'ADDRESS/SET_SiGunGuFirstCode';
// 동리 2차가 있을 경우 1차에서 나온 코드 저장
export const ADDRESS_SET_DongRiFirstCode = 'ADDRESS/SET_DongRiFirstCode';
// 마지막 2자리 코드 저장
export const ADDRESS_SET_RiCode = 'ADDRESS/SET_RiCode';

export const SET_ADDRESS_SELECTED_SI_GUN_GU_CODE_LIST = 'ADDRESS/SET_ADDRESS_SELECTED_SI_GUN_GU_CODE_LIST';

// APT, OFT 공용
export const ADDRESS_GET_COMPLEX_LIST = 'ADDRESS/GET_COMPLEX_LIST';
export const ADDRESS_GET_COMPLEX_LIST_SUCCESS = success('ADDRESS/GET_COMPLEX_LIST');
export const ADDRESS_GET_COMPLEX_LIST_FAILURE = failure('ADDRESS/GET_COMPLEX_LIST');
export const ADDRESS_GET_COMPLEX_LIST_LOADING = loading('ADDRESS/GET_COMPLEX_LIST');

// // APT, OFT 공용
export const ADDRESS_CHANGE_COMPLEX_IDX = 'ADDRESS/CHANGE_COMPLEX_IDX';
// // APT, OFT 공용
export const ADDRESS_GET_COMPLEX_INFO = 'ADDRESS/GET_COMPLEX_INFO';
export const ADDRESS_SET_COMPLEX_INFO = 'ADDRESS/SET_COMPLEX_INFO';

// 배지율 추가
export const GET_SIDO_LIST = 'ADDRESS/GET_SIDO_LIST';
export const GET_SIGUNGU_LIST1 = 'ADDRESS/GET_SIGUNGU_LIST1';
export const GET_SIGUNGU_LIST2 = 'ADDRESS/GET_SIGUNGU_LIST2';
export const GET_DONG_LIST1 = 'ADDRESS/GET_DONG_LIST1';
export const GET_DONG_LIST2 = 'ADDRESS/GET_DONG_LIST2';
export const GET_SIGUNGU2_OR_DONG_LIST = 'ADDRESS/GET_SIGUNGU2_OR_DONG_LIST';
export const GET_COMPLEX_LIST_BY_DONG = 'ADDRESS/GET_COMPLEX_LIST_BY_DONG';

// // APT, OFT 공용
export const ADDRESS_GET_COMPLEX_LOCATION = 'ADDRESS/GET_COMPLEX_LOCATION';
export const ADDRESS_GET_COMPLEX_LOCATION2 = 'ADDRESS/GET_COMPLEX_LOCATION2';
export const ADDRESS_GET_COMPLEX_LOCATION_SUCCESS = success('ADDRESS/GET_COMPLEX_LOCATION');
export const ADDRESS_GET_COMPLEX_LOCATION_FAILURE = failure('ADDRESS/GET_COMPLEX_LOCATION');
export const ADDRESS_GET_COMPLEX_LOCATION_LOADING = loading('ADDRESS/GET_COMPLEX_LOCATION');

// // 동 목록 받아오기
export const ADDRESS_GET_COMPLEX_DONG_LIST = 'ADDRESS/GET_COMPLEX_DONG_LIST';
export const ADDRESS_GET_COMPLEX_DONG_LIST2 = 'ADDRESS/GET_COMPLEX_DONG_LIST2';
export const ADDRESS_GET_COMPLEX_DONG_LIST_SUCCESS = success('ADDRESS/GET_COMPLEX_DONG_LIST');
export const ADDRESS_GET_COMPLEX_DONG_LIST_FAILURE = failure('ADDRESS/GET_COMPLEX_DONG_LIST');
export const ADDRESS_GET_COMPLEX_DONG_LIST_LOADING = loading('ADDRESS/GET_COMPLEX_DONG_LIST');

export const ADDRESS_CHANGE_DONG_NO = 'ADDRESS/CHANGE_DONG_NO';

export const ADDRESS_GET_FLOOR_LIST = 'ADDRESS/GET_FLOOR_LIST';
export const ADDRESS_GET_FLOOR_LIST2 = 'ADDRESS/GET_FLOOR_LIST2';
export const ADDRESS_SET_FLOOR_LIST = 'ADDRESS/SET_FLOOR_LIST';
export const ADDRESS_CHANGE_FLOOR_NO = 'ADDRESS/CHANGE_FLOOR_NO';

export const ADDRESS_GET_HO_LIST = 'ADDRESS/GET_HO_LIST';
export const ADDRESS_GET_HO_LIST2 = 'ADDRESS/GET_HO_LIST2';
export const ADDRESS_SET_HO_LIST = 'ADDRESS/SET_HO_LIST';
export const ADDRESS_CHANGE_HO_NO = 'ADDRESS/CHANGE_HO_NO';

export const ADDRESS_GET_ITEM_SIZE_LIST = 'ADDRESS/GET_ITEM_SIZE_LIST';
export const ADDRESS_SET_ITEM_SIZE_LIST = 'ADDRESS/SET_ITEM_SIZE_LIST';
export const ADDRESS_CHANGE_ITEM_SIZE = 'ADDRESS/CHANGE_ITEM_SIZE';
