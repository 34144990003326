import React from 'react';
import debounce from 'lodash/debounce';
import FilterButton from './FilterButton';
import { useSearch, useKeyboardEvent } from './hooks';

const SearchInput = ({
  inputRef,
  show,
  setShow,
  totalSearch,
  setKeyword,
  highlightIdx,
  setHighlightIdx,
  search,
  complexOnly,
  showKeywordDeleteButon,
  placeholder,
  showFilter,
  defaultInput,
  data,
  keyword,
  enableSearchTracking,
  disabled = false,
}) => {
  const maxLength = data.list?.length || 0;

  const { handleSearchText, forceSearchText } = useSearch({ inputRef, totalSearch, setShow, setKeyword });

  const { handleKeyboardEvent } = useKeyboardEvent({ maxLength, highlightIdx, setHighlightIdx, search, setShow });

  const handleDelete = () => {
    var element = inputRef.current;
    element.value = '';
    handleSearchText({ target: inputRef.current });
  };

  const isInputEmpty = !inputRef?.current?.value;

  return (
    <>
      <input
        ref={inputRef}
        type="search"
        onChange={debounce(handleSearchText, 300)}
        placeholder={getPlaceholder({ placeholder, complexOnly })}
        title={getTitle({ complexOnly })}
        className="form-elem"
        id="searchKeyword"
        autoComplete="off"
        onKeyDown={handleKeyboardEvent}
        onFocus={() => setShow(true)}
        defaultValue={defaultInput}
        style={{ paddingRight: isInputEmpty ? '104rem' : '132rem' }}
        disabled={disabled}
      />
      <span className="form-func">
        {show && keyword ? <span className="form-delete" onClick={handleDelete}></span> : <></>}

        <button type="button" className="btn search" onClick={forceSearchText}>
          <span className="btn-text">검색</span>
        </button>

        {/* onClick="contentsPopup.active('dataFinderFilter');" */}
        {showFilter && <FilterButton />}
      </span>
    </>
  );
};

const getPlaceholder = ({ placeholder, complexOnly }) => {
  if (placeholder) return placeholder;
  else if (complexOnly) return '단지명을 입력해주세요.';
  else return '지역, 지하철역, 단지명으로 검색';
};

const getTitle = ({ complexOnly }) => {
  if (complexOnly) return '단지명을 입력해주세요.';
  else return '지역, 지하철역, 단지명으로 검색';
};

export default SearchInput;
