import React from 'react';
import { FeedTypeKor } from '@/services/Community';
import { tagStyle } from '@/combinedComp/Community/helpers';

const FeedType = ({ feedType }) => {
  const feedTypeKor = FeedTypeKor[feedType];
  return (
    <span className="data-item type">
      <span className="head">분류</span>
      <span className="body">
        <span className={`mark module-c style-a type-fill ${tagStyle(feedType)} small-2x`}>
          <span className="mark-text">{feedTypeKor}</span>
        </span>
      </span>
    </span>
  );
};

export default FeedType;
