import React from 'react';
import classNames from 'classnames';

const TextAreaInput = ({ field, form, className, ...props }) => {
  const { touched, errors } = form;
  const hasError = touched[field.name] && errors[field.name];
  return (
    <>
      <textarea {...field} {...props} className={classNames(className, { error: hasError })} />
      {hasError && <div className="error">{errors[field.name]}</div>}
      <style jsx>
        {`
          textarea.error {
            border: 1px solid red !important;
          }

          div.error {
            color: red;
            margin-bottom: 1px;
            width: 100%;
            text-align: right;
          }
        `}
      </style>
    </>
  );
};

export default TextAreaInput;
