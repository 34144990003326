import { failure, loading, success } from '@/data/utils';
// ===================== 공통 ===================== //
export const RATING_ERROR = 'RATING/RATING_ERROR';
export const RATING_RESET_ERROR_STATE = 'RATING/RATING_RESET_ERROR_STATE';

// ===================== Rating ===================== //
export const SET_RATING_TYPE = 'RATING/SET_RATING_TYPE';
export const SET_RATING_TARGET_ITEM_IDX = 'RATING/SET_RATING_TARGET_ITEM_IDX';
export const SET_RATING_GUBUN = 'RATING/SET_RATING_GUBUN';
export const SET_RATING_TARGET_BROKER_IDX = 'RATING/SET_RATING_TARGET_BROKER_IDX';

export const SET_Q_RATING = 'RATING/SET_Q_RATING';

// ===================== Post Rating ===================== //
export const POST_RATING = 'RATING/POST_RATING';
export const POST_RATING_SUCCESS = success(POST_RATING);
export const POST_RATING_FAILURE = failure(POST_RATING);
export const POST_RATING_LOADING = loading(POST_RATING);
export const POST_RATING_NOTASK = 'RATING/POST_RATING_NOTASK';

export const POST_RATING_URL = 'RATING/POST_RATING_URL';
export const POST_RATING_URL_SUCCESS = success(POST_RATING_URL);
export const POST_RATING_URL_FAILURE = failure(POST_RATING_URL);
export const POST_RATING_URL_LOADING = loading(POST_RATING_URL);
export const POST_RATING_URL_NOTASK = 'RATING/POST_RATING_URL_NOTASK';
