import { autoComma } from '@/services/Helper';

export const getIsRebuild = (rebuild_progress) => {
  return rebuild_progress != null;
};

//금액 range 표시
export function fnrangeStr(start, end) {
  var str = '';
  if ((start == null || start <= 0) && (end == null || end <= 0)) return '1년내 실거래가 없음';
  if (start == null || start <= 0 || start == end) str += '';
  else str += priceToText2(start) + ' ~ ';
  if (end != null && end > 0) str += priceToText2(end);
  return str;
}

export function fnrangeStr2(start, end) {
  var str = '';
  if ((start == null || start <= 0) && (end == null || end <= 0)) return '1년내 실거래가 없음';
  if (start == null || start <= 0 || start == end) str += '';
  else str += priceToText3(start) + '~';
  if (end != null && end > 0) str += priceToText3(end);
  return str;
}

//금액표기(0억 0000)
export function fnMoneyAboutText(float) {
  var int = Math.floor(float); //소수점 버림

  var strInt = int.toString();
  var strFloat_1 = '';

  if (int > 999999999) {
    //억
    strFloat_1 = strInt.substring(0, strInt.length - 9);
    if (strInt.substring(2, 3) != '0') strFloat_1 += '.' + strInt.substring(2, 3);
  } else {
    strFloat_1 = strInt.substring(0, 1);
    if (int != 0) {
      if (strInt.substring(1, 2) != '0') strFloat_1 += '.' + strInt.substring(1, 2);
    }
  }
  var unit = '';
  switch (strInt.length) {
    case 6:
      unit = '십';
      break;
    case 7:
      unit = '백';
      break;
    case 8:
      unit = '천';
      break;
    case 9:
      unit = '억';
      break;
    case 10:
      unit = '억';
      break;
    case 11:
      unit = '억';
      break;
    case 12:
      unit = '억';
      break;
    case 13:
      unit = '조';
      break;
    default:
      break;
  }
  if (strFloat_1 == 0) return '-';
  return strFloat_1 + unit;
}

//INPUT: 6000 = 6000만원. '만'이하 제외된 값
//OUTPUT: 00억0000만
//lastText = false >> OUTPUT: 00억0000
export function priceToText(_price, lastText = true) {
  if (_price < 1 || !_price) return '0';
  const price = Math.round(_price).toString();

  const str1 = price.length < 9 ? '' : price.substring(0, price.length - 8) + '조 ';
  const str2 = price.length < 5 ? '' : price.substring(price.length - 8, price.length - 4) + '억';
  const str3 = price.substring(price.length - 4, price.length);
  return autoComma(str1) + autoComma(str2) + (str3 != 0 ? ' ' + autoComma(Number(str3)) + (lastText ? '만' : '') : '');
}
// 1, 000, 000, 00; //0,000
//INPUT: 60000000 = 6000만원
//OUTPUT: 00억0000만
export function priceToText2(price) {
  if (price < 1 || !price) return '';
  price = Math.floor(price / 10000);
  price = price.toString();
  const str1 = price.length < 5 ? '' : price.substring(0, price.length - 4) + '억';
  const str2 = price.substring(price.length - 4, price.length);
  return autoComma(str1) + (str2 != 0 ? '' + autoComma(Number(str2)) + '만' : '');
}

//INPUT: 60000000 = 6000만원
//OUTPUT: 0.0억 또는 0.0천만
export function priceToText3(price, cut) {
  if (price < 1 || !price) return false;

  if (cut) price = Number(price) * 10000;

  const length = price.toString().length;

  if (length < 8) {
    return parseInt(price / 10000) + '만';
  } else if (length < 9) {
    return parseFloat((price / 10000000)?.toFixed(1)) + '천만';
  } else {
    return parseFloat((price / 100000000)?.toFixed(1)) + '억';
  }
}

//INPUT: 60000000 = 6000만원
//OUTPUT: 0.0억 또는 0000만
export function priceToText4(price, cut) {
  if (price < 1 || !price) return '-';

  if (cut) price = Number(price) * 10000;

  const priceAsInteger = parseInt(price);

  if (priceAsInteger.toString().length < 9) {
    price = Math.floor(price / 10000);
    price = price.toString();
    const str2 = price.substring(price.length - 4, price.length);
    return autoComma(Number(str2)) + '만';
  } else {
    return parseFloat((price / 100000000)?.toFixed(1)) + '억';
  }
}

//INPUT: 6000 = 6000만원
//OUTPUT: 0.0억 또는 0천
export function priceToText5(_price) {
  if (_price < 1 || !_price) return false;

  const price = Number(_price) * 10000;

  const priceAsInteger = parseInt(price);

  if (priceAsInteger.toString().length < 8) {
    return parseFloat((price / 10000)?.toFixed(1)) + '만';
  } else if (priceAsInteger.toString().length < 9) {
    //천만원
    return parseFloat((price / 10000000)?.toFixed(1)) + '천';
  } else {
    return parseFloat((price / 100000000)?.toFixed(1)) + '억';
  }
}

//INPUT: 60000000 = 6000만원
//OUTPUT: 0.0억 또는 0천 0백
//집구하기 > 오피스텔 > 월세 표시
export function priceToText6(price, cut) {
  if (price < 1 || !price) return false;

  if (cut) price = Number(price) * 10000;

  if (price.toString().length < 8) {
    return parseFloat((price / 1000000)?.toFixed(1)) + '백';
  } else if (price.toString().length < 9) {
    return parseFloat((price / 10000000)?.toFixed(1)) + '천';
  } else {
    return parseFloat((price / 100000000)?.toFixed(1)) + '억';
  }
}

export function priceToText7(price) {
  if (price < 1 || !price) return null;

  const priceNormalized = Number.parseInt(price) * 10000;

  if (priceNormalized.toString().length <= 8) {
    return `${parseFloat(priceNormalized / 10000)}만`;
  }
  if (priceNormalized.toString().length >= 9) {
    const roundedToTheFirst = parseFloat(priceNormalized / 100000000).toFixed(1);
    const [intWithoutDecimal, firstDecimal] = roundedToTheFirst.split('.');
    if (Number.parseInt(firstDecimal) === 0) {
      return `${intWithoutDecimal}억`;
    } else {
      return `${roundedToTheFirst}억`;
    }
  }
}

//INPUT: 6000 = 6000만원
//OUTPUT: 0.00억 또는 0000만
//[DX-2229]소수점 둘째짜리까지 반올림 표시
export function priceToText8(price, cut) {
  if (price < 1 || !price) return '-';

  price = Number(price);

  if (cut) price = Number(price) * 10000;

  if (price.toString().length < 9) {
    price = Math.floor(price / 10000);
    price = price.toString();
    const str2 = price.substring(price.length - 4, price.length);
    return autoComma(Number(str2)) + '만';
  } else {
    return parseFloat(Math.round(price / 1000000) / 100) + '억';
  }
}

//INPUT: 6000 = 6000만원
//OUTPUT: 0.00억 또는 0000만
//[DX-2229]소수점 둘째짜리까지 반올림 표시
export function priceToTextJo(price, cut) {
  if (price < 1 || !price) return '-';

  price = Number(price);

  if (cut) price = Number(price) * 10000;

  if (price.toString().length < 9) {
    price = Math.floor(price / 10000);
    price = price.toString();
    const str2 = price.substring(price.length - 4, price.length);
    return autoComma(Number(str2)) + '만';
  } else {
    return parseFloat(Math.round(price / 1000000) / 100) + '억';
  }
}

//날짜 차이(d-day 계산)
export function getDday(day) {
  if (day) {
    const today = new Date();
    const diff = day.getTime() - today.getTime();
    const diffDay = diff / (1000 * 3600 * 24);
    const result = diffDay < 0 ? Math.ceil(diffDay) : Math.floor(diffDay);
    return isNaN(result) ? null : result;
  } else {
    return null;
  }
}

//1순위 청약일
export function getFrstRankerDate(schedule) {
  if (schedule) {
    const frstRanker = schedule['1순위'];
    const frstRankerDate = frstRanker ? frstRanker['해당지역'] : null;
    return new Date(frstRankerDate);
  } else {
    return null;
  }
}

// 입주년차 계산
export function getComplexOld(construct_year) {
  const current_year = new Date().getFullYear();
  let complex_old = current_year - Number(construct_year) + 1;
  complex_old < 1 && (complex_old = null);
  return complex_old;
}
