import * as AT from '../actionTypes';
import { produce } from 'immer';

const INITIAL_STATE = [];

// 웹 알림 (앱 푸시알림과는 다름) useWebNotification hook 참조
const webNotificationReducer = produce((draft, action = {}) => {
  switch (action.type) {
    case AT.ADD_WEB_NOTIFICATION:
      draft.push(action.payload);
      break;
    case AT.UPDATE_WEB_NOTIFICATION:
      var index = draft.findIndex((notification) => notification.idx === action.payload.idx);
      if (index !== -1) draft[index][action.payload.key] = action.payload.value;
      break;
    case AT.CLEAN_WEB_NOTIFICATION:
      draft.splice(0, draft.length);
      break;
    default:
      break;
  }
}, INITIAL_STATE);

export default webNotificationReducer;
