import { routerActions as router } from 'connected-react-router';
import * as auth from './auth/actions';
import * as buying from './buying/actions';
import * as selling from './selling/actions';
import * as common from './common/actions';
import * as uploadImage from './uploadImage/actions';
import * as myPage from './myPage/actions';
import * as brokerPage from './brokerPage/actions';
import * as rating from './rating/actions';
import * as regQNA from './regQNA/actions';
import * as agentSignUp from './agentSignUp/actions';
import * as analysis from './analysis/actions';
import * as newsale from './newsale/actions';
import * as personalize from './personalize/actions';
import * as my from './my/actions';
import * as address from './address/actions';
import * as community from './community/actions';
import * as chat from './chat/actions';
import * as user from './user/actions';
import * as resource from './resource/actions';
import * as payment from './payment/actions';
import * as app from './app/actions';
import * as academy from './academy/actions';
import * as ui from './ui/actions';
import * as review from './review/actions';

export {
  router,
  auth,
  common,
  buying,
  selling,
  uploadImage,
  myPage,
  brokerPage,
  rating,
  regQNA,
  agentSignUp,
  analysis,
  newsale,
  personalize,
  my,
  address,
  community,
  chat,
  user,
  resource,
  payment,
  app,
  academy,
  ui,
  review,
};
