//url 형식에 링크 걸어주기
const linkify = (inputText) => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" rel="noopener noreferrer">$2</a>');

  //모바일
  replacePattern2 = /(^|[^\/])(https:\/\/m\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" rel="noopener noreferrer">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const backToText = (inputText) => {
  var replacedText, replacePattern1, replacePattern2;
  // replacePattern = /(<a href=".*" target="_blank" rel="noopener noreferrer">)(</a>)/gim;
  replacePattern1 = /<a[\s]+([^>]+)>/g;
  replacedText = inputText.replace(replacePattern1, '');
  replacePattern2 = /<\/a>/g;
  replacedText = replacedText.replace(replacePattern2, '');

  return replacedText;
};

export default linkify;
