import { failure, loading, success } from '@/data/utils';
import * as AT from './actionTypes';

export const ratingError = ({ error }) => ({
  type: AT.RATING_ERROR,
  payload: {
    error,
  },
});
export const resetErrorState = () => ({
  type: AT.RATING_RESET_ERROR_STATE,
});

// ============================ Rating ============================ //
export const setRatingType = (ratingType) => ({
  type: AT.SET_RATING_TYPE,
  ratingType,
});

export const setRatingTargetItemIdx = (ratingTargetItemIdx) => ({
  type: AT.SET_RATING_TARGET_ITEM_IDX,
  ratingTargetItemIdx,
});
export const setRatingGubun = (gubun) => ({
  type: AT.SET_RATING_GUBUN,
  gubun,
});

export const setRatingTargetBrokerIdx = (ratingTargetBrokerIdx) => ({
  type: AT.SET_RATING_TARGET_BROKER_IDX,
  ratingTargetBrokerIdx,
});

// *QIdx : 몇 번 질문에 대한 rating인지! 2020.05.31 기준으로 : 0번은 최상단 전체 평가 / 1~4번은 점수 / 5번은 0,1 / 6번은 text
// *ratingContent :
export const setQRating = (QIdx, ratingContent) => ({
  type: AT.SET_Q_RATING,
  QIdx,
  ratingContent,
});

// ===================== Post Rating ===================== //
// 관심단지
export const postRating = () => ({
  type: AT.POST_RATING,
});
export const postRatingLoading = () => ({
  type: loading(AT.POST_RATING),
});
export const postRatingSuccess = () => ({
  type: success(AT.POST_RATING),
});
export const postRatingFailure = (error) => ({
  type: failure(AT.POST_RATING),
  error,
});
export const postRatingNotAsk = () => ({
  type: AT.POST_RATING_NOTASK,
});

export const postRatingUrl = () => ({
  type: AT.POST_RATING_URL,
});
export const postRatingUrlLoading = () => ({
  type: loading(AT.POST_RATING_URL),
});
export const postRatingUrlSuccess = () => ({
  type: success(AT.POST_RATING_URL),
});
export const postRatingUrlFailure = (error) => ({
  type: failure(AT.POST_RATING_URL),
  error,
});
export const postRatingUrlNotAsk = () => ({
  type: AT.POST_RATING_URL_NOTASK,
});
