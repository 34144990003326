import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { OSTYPE } from '@/services/apps/types/OsType';

const checkException = () =>
  window.location.pathname.indexOf('/login') < 0 &&
  window.location.pathname.indexOf('/agentSignUp') < 0 &&
  window.location.pathname.indexOf('/agentSignup') < 0 &&
  window.location.pathname.indexOf('/payment') < 0 &&
  window.location.pathname.indexOf('/niceCallback') < 0;

// 앱 사용 유저는 로그인 필수
const AppShouldLogin = () => {
  // const dispatch = useDispatch();

  const { pathname } = useLocation();
  const user = useSelector(selectors.auth.getUserInfo);

  const shouldLogin = useMemo(() => {
    const osType = window.dawin_instance.osType;
    const isApp = osType === OSTYPE.APP_IOS || osType === OSTYPE.APP_ANDROID;

    // memoFirstPath({ dispatch, isApp, user });

    return isApp && !user && checkException();
  }, [pathname]);

  if (shouldLogin) {
    return <Redirect to="/login" />;
  } else {
    return null;
  }
};

export default React.memo(AppShouldLogin);

const memoFirstPath = ({ dispatch, isApp, user }) => {
  const exception = window.location.pathname.indexOf('/agentSignUp') < 0 && window.location.pathname.indexOf('/payment') < 0;
  const needMemorize = isApp && !user && exception;

  if (needMemorize) {
    const prev = location.pathname;
    const goToFirstPath = () => dispatch(actions.router.replace(prev));
    dispatch(actions.common.actionAfterLogin(goToFirstPath));
  }
};
