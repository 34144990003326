import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const ONEONONE = { keyword: '', limit: 5, qnaList: {} };

export default function oneOnOne(state = ONEONONE, action = {}) {
  switch (action.type) {
    case AT.GET_ONEONONE_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_ONEONONE_KEYWORD: {
      return {
        ...state,
        keyword: action.val,
      };
    }
    case AT.SET_ONEONONE_TITLE: {
      return {
        ...state,
        new: { ...state.new, title: action.val },
      };
    }
    case AT.SET_ONEONONE_CONT: {
      return {
        ...state,
        new: { ...state.new, cont: action.val },
      };
    }
    case AT.SET_ONEONONE_NEW_ATTACH: {
      return {
        ...state,
        new: { ...state.new, file_name: action.file_name, attach: action.idx },
      };
    }
    case AT.SET_ONEONONE_CATEGORY: {
      return {
        ...state,
        new: { ...state.new, category: action.val },
      };
    }
    case AT.SET_ONEONONE_LIMIT: {
      var cnt = state.limit;
      switch (action.cate) {
        case 'plus':
          cnt = cnt + action.cnt;
          break;
        case 'refresh':
          cnt = 5;
          break;
        default:
          cnt = 5;
          break;
      }
      return {
        ...state,
        limit: cnt,
      };
    }

    case loading(AT.GET_ONEONONE_LIST):
      return { ...state, [AT.GET_ONEONONE_LIST]: Remote.Loading };
    case success(AT.GET_ONEONONE_LIST):
      return { ...state, qnaList: action.conlist, totalCnt: action.cnt };
    case failure(AT.GET_ONEONONE_LIST):
      return {
        ...state,
        [AT.GET_ONEONONE_LIST]: Remote.Failure(action.error),
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
}
