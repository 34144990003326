import React from 'react';

const useSetUserName = ({ feed }) => {
  const userName =
    feed?.writer?.type === 'BROKER' ? (
      <>
        {feed?.writer.officeName}
        <br />
        {feed?.writer?.name}
      </>
    ) : (
      feed?.writer?.name
    );

  return userName;
};

export default useSetUserName;
