import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';

/**
 * 뒤로가기, 제목 header
 * (우측 메뉴 버튼 없음)
 */
const OnlyTitle = ({ title }) => {
  const dispatch = useDispatch();

  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(actions.router.goBack());
  };

  return (
    <header id="header" className="header">
      <span className="btn-left">
        <a className="btn-img btn-back" onClick={handleBackClick}>
          뒤로
        </a>
      </span>
      <h2 className="title" id="mobile_sub_header_title">
        {title}
      </h2>
    </header>
  );
};

export default OnlyTitle;
