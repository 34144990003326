import * as sagas from './sagas';
import * as AT from './actionTypes';
import {
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';

export default function*() {
  yield takeLatest(AT.POST_REGQNA, sagas.postQNA);
}
