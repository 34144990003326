import apis from '@/apis';

export const getSpecificLocation = async (areaCode) => {
  try {
    return await apis.addressApi.getSpecificLocation(areaCode);
  } catch (e) {
    console.log('error');
  }
};

export const getSido = async ({ setData, query }) => {
  try {
    const result = await apis.addressApi.getAddrSiDo({ ...query });
    setData(result);
  } catch (e) {
    return [];
  }
};
