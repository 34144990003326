import * as AT from './actionTypes';

export const getItemType = (state) => state?.selling?.itemType; // APT, OFT

export const getError = (state) => state?.address?.error;
export const getItemInfo = (state) => state?.address?.itemInfo;

export const getStep = (state) => state?.address?.step;

export const getLastCategory = (state) => state?.address?.lastCategory;
export const getLastStep = (state) => state?.address?.lastStep;

// [[NEW LOGIC]]-----------------------------------------------------------
export const getSavedForm = (state) => state?.address.savedForm;
export const getComplexBrokerList = (state) => state.address.complexBrokerList;
//TODO: address store에서 사용여부 check
export const getFacilityCodeList = (state) => state.address.facilityCodeList;
// [[NEW LOGIC]]-----------------------------------------------------------

// 2021-08-11 @장혁진 수정
//  getSiGunGuList -> getSiGunGuFirstList / getSiGunGuSecondList  2뎁스로 쪼갬
//  getSiGunGuList -> getDongRiFirstList / getDongRiSecondList  2뎁스로 쪼갬
export const getSiDoList = (state) => state?.address?.si_do_list;
export const getSiGunGuList = (state) => state?.address?.si_gun_gu_list;
export const getDongRiList = (state) => state?.address?.dong_ri_list;

// 뎁스 2단계씩 쪼갬
// 그냥 si_gun_gu_list로 우선 할당.. 변수를 first로 바꿀지는 고민
export const getSiGunGuFirstList = (state) => state?.address?.si_gun_gu_list_first;
export const getSiGunGuSecondList = (state) => state?.address?.si_gun_gu_list_second;
export const getDongRiFirstList = (state) => state?.address?.dong_ri_list_first;
export const getDongRiSecondList = (state) => state?.address?.dong_ri_list_second;

// 법정동코드 쪼개기
export const getSiDoCode = (state) => state?.address?.si_do_code;
export const getSiGunGuCode = (state) => state?.address?.si_gun_gu_code;
export const getDongRiCode = (state) => state?.address?.dong_ri_code;
export const getSiGunGuFourDigitCode = (state) => state?.address?.si_gun_gu_four_digit_code;
export const getDongRiEightDigitCode = (state) => state?.address?.dong_ri_eight_digit_code;

export const getSiGunGuFirstCode = (state) => state?.address?.si_gun_gu_first_code;
export const getDongRiFirstCode = (state) => state?.address?.dong_ri_first_code;
export const getRiCode = (state) => state?.address?.ri_code;

export const getSiDoName = (state) => state?.address?.si_do_name;
export const getSiGunGuName = (state) => state?.address?.si_gun_gu_name;
export const getSiGunGuNameSecond = (state) => state?.address?.si_gun_gu_name_second;
export const getDongRiName = (state) => state?.address?.dong_ri_name;

export const getSellLat = (state) => state?.address?.lat;
export const getSellLng = (state) => state?.address?.lng;

// // 분석 > 랭킹에서 사용
// export const getSiGunGuCodeSelectedList = (state) => state?.address?.si_gun_gu_code_selected_list;

// // APT, OFT 공용
export const getComplexIdx = (state) => state?.address?.complexIdx;

// // APT, OFT 공용
export const getComplexList = (state) => state?.address[AT.ADDRESS_GET_COMPLEX_LIST].getOrElse([]);

// // APT, OFT 공용
export const getComplexInfo = (state) => state?.address?.complexInfo;

// // APT, OFT 공용
export const getComplexLocation = (state) => state?.address[AT.ADDRESS_GET_COMPLEX_LOCATION].getOrElse({});

export const getComplexDongList = (state) => state?.address[AT.ADDRESS_GET_COMPLEX_DONG_LIST].getOrElse([]);
export const getDongNo = (state) => state?.address?.dongNo;

export const getFloorList = (state) => state?.address?.floor_list;
export const getFloorNo = (state) => state?.address?.floorNo;

export const getHoList = (state) => state?.address?.ho_list;
export const getHoNo = (state) => state?.address?.hoNo;

export const getItemSizeList = (state) => state?.address?.itemSizeList;
export const getItemSize = (state) => state?.address?.itemSize;

export const getIsForSale = (state) => state?.address?.isForSale;
export const getSalesPrice = (state) => state?.address?.salesPrice;

export const getIsForJeonSae = (state) => state?.address?.isForJeonSae;
export const getJeonSaePrice = (state) => state?.address?.jeonSaePrice;

export const getIsForLease = (state) => state?.address?.isForLease;
export const getLeasePriceDeposit = (state) => state?.address?.leasePriceDeposit;
export const getLeasePriceFee = (state) => state?.address?.leasePriceFee;

// ===================== Step 2 ===================== //

export const getDateOption = (state) => state?.address?.dateOption;
export const getDesignatedDate = (state) => state?.address?.designatedDate;

export const getRoomNumOption = (state) => state?.address?.roomNumOption;
export const getBathroomNumOption = (state) => state?.address?.bathroomNumOption;

export const getLivingStatusOption = (state) => state?.address?.livingStatusOption;

export const getHouseDirectionOption = (state) => state?.address?.houseDirectionOption;

export const getBalconyOption = (state) => state?.address?.balconyOption;

export const getPetOption = (state) => state?.address?.petOption;

export const getOptionInfo = (state) => state?.address?.optionInfo;

export const getItemAdvantage = (state) => state?.address?.itemAdvantage;
export const getItemAdvantageETC = (state) => state?.address?.itemAdvantageETC;

export const getOwnerNote = (state) => state?.address?.ownerNote;

// ===================== Step 3 ===================== //

//uploadImage dir
// export const getItemImagesBlob = (state) => state?.address?.itemImagesBlob;

// ===================== Step 4 ===================== //

export const getAgentPreference = (state) => state?.address?.agentPreference;

// export const getNearbyAgents = (state) => state?.address[AT.GET_NEARBY_AGENTS]?.getOrElse([]);
// export const getNearbyAgentsIsLoading = (state) => state?.address[AT.GET_NEARBY_AGENTS]?.isLoading();
// export const getNearbyAgentsIsSuccess = (state) => state?.address[AT.GET_NEARBY_AGENTS]?.isSuccess();
// export const getNearbyAgentsIsFailure = (state) => state?.address[AT.GET_NEARBY_AGENTS]?.isFailure();

export const getSelectedAgentsList = (state) => state?.address?.selectedAgentsList;

export const getFocusedAgentIndex = (state) => state?.address?.focusedAgentIdx;

export const getMapCenter = (state) => state?.address?.mapCenter;

// ===================== Step 5 ===================== //
// ===================== Step 6 ===================== //
// export const getComplexListWithBcfiCnt = (state) => state?.address[AT.GET_COMPLEX_LIST_WITH_BCFI_CNT].getOrElse([]);
