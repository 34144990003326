import { failure, loading, success } from '@/data/utils';
// ===================== 공통 ===================== //
export const MP_REQUEST_ERROR = 'MYPAGE/MP_REQUEST_ERROR';
export const MP_RESET_ERROR_STATE = 'MYPAGE/MP_RESET_ERROR_STATE';

export const MP_TOGGLE_FAVORITE = 'MYPAGE/MP_TOGGLE_FAVORITE';

export const MP_CHANGE_PASSWORD = 'MYPAGE/MP_CHANGE_PASSWORD';
export const MP_CHANGE_PASSWORD_SUCCESS = success(MP_CHANGE_PASSWORD);
export const MP_CHANGE_PASSWORD_FAILURE = failure(MP_CHANGE_PASSWORD);
export const MP_CHANGE_PASSWORD_LOADING = loading(MP_CHANGE_PASSWORD);

export const MP_SMS = 'SMS';
export const MP_SMS_SUCCESS = success(MP_SMS);
export const MP_SMS_FAILURE = failure(MP_SMS);
export const MP_SMS_LOADING = loading(MP_SMS);

// ===================== MyInfo ===================== //
export const GET_SOCIAL_LIST = 'MYPAGE/GET_SOCIAL_LIST';
export const GET_SOCIAL_LIST_SUCCESS = success(GET_SOCIAL_LIST);
export const GET_SOCIAL_LIST_FAILURE = failure(GET_SOCIAL_LIST);
export const GET_SOCIAL_LIST_LOADING = loading(GET_SOCIAL_LIST);

// ===================== MyBuy ===================== //
export const GET_FAVORITE_ITEMS_LIST = 'MYPAGE/GET_FAVORITE_ITEMS_LIST';
export const GET_FAVORITE_ITEMS_LIST_SUCCESS = success(GET_FAVORITE_ITEMS_LIST);
export const GET_FAVORITE_ITEMS_LIST_FAILURE = failure(GET_FAVORITE_ITEMS_LIST);
export const GET_FAVORITE_ITEMS_LIST_LOADING = loading(GET_FAVORITE_ITEMS_LIST);

export const GET_INTERESTED_COMPLEX_LIST = 'MYPAGE/GET_INTERESTED_COMPLEX_LIST';
export const GET_INTERESTED_COMPLEX_LIST_SUCCESS = success(GET_INTERESTED_COMPLEX_LIST);
export const GET_INTERESTED_COMPLEX_LIST_FAILURE = failure(GET_INTERESTED_COMPLEX_LIST);
export const GET_INTERESTED_COMPLEX_LIST_LOADING = loading(GET_INTERESTED_COMPLEX_LIST);

export const GET_CONTRACTED_ITEMS_LIST = 'MYPAGE/GET_CONTRACTED_ITEMS_LIST';
export const GET_CONTRACTED_ITEMS_LIST_SUCCESS = success(GET_CONTRACTED_ITEMS_LIST);
export const GET_CONTRACTED_ITEMS_LIST_FAILURE = failure(GET_CONTRACTED_ITEMS_LIST);
export const GET_CONTRACTED_ITEMS_LIST_LOADING = loading(GET_CONTRACTED_ITEMS_LIST);

// ===================== MySell ===================== //
export const GET_MY_SELL_ITEMS_LIST = 'MYPAGE/GET_MY_SELL_ITEMS_LIST';
export const GET_MY_SELL_ITEMS_LIST_SUCCESS = success(GET_MY_SELL_ITEMS_LIST);
export const GET_MY_SELL_ITEMS_LIST_FAILURE = failure(GET_MY_SELL_ITEMS_LIST);
export const GET_MY_SELL_ITEMS_LIST_LOADING = loading(GET_MY_SELL_ITEMS_LIST);

export const SET_DELETE_ITEM_IDX = 'MYPAGE/SET_DELETE_ITEM_IDX';
export const SET_DELETE_REASON = 'MYPAGE/SET_DELETE_REASON';
export const SET_DELETE_STATUS = 'MYPAGE/SET_DELETE_STATUS';
export const SET_DELETE_REASON_ETC = 'MYPAGE/SET_DELETE_REASON_ETC';

export const POST_DELETE_ITEM = 'MYPAGE/POST_DELETE_ITEM';
export const POST_DELETE_ITEM_SUCCESS = success(POST_DELETE_ITEM);
export const POST_DELETE_ITEM_FAILURE = failure(POST_DELETE_ITEM);
export const POST_DELETE_ITEM_LOADING = loading(POST_DELETE_ITEM);

export const SET_REG_CANCEL_ITEM_IDX = 'MYPAGE/SET_REG_CANCEL_ITEM_IDX';

export const POST_REG_CANCEL_ITEM = 'MYPAGE/POST_REG_CANCEL_ITEM';
export const POST_REG_CANCEL_ITEM_SUCCESS = success(POST_REG_CANCEL_ITEM);
export const POST_REG_CANCEL_ITEM_FAILURE = failure(POST_REG_CANCEL_ITEM);
export const POST_REG_CANCEL_ITEM_LOADING = loading(POST_REG_CANCEL_ITEM);
export const POST_REG_CANCEL_ITEM_RESET = 'MYPAGE/POST_REG_CANCEL_ITEM_RESET';

export const SET_TRADE_ITEM_IDX = 'MYPAGE/SET_TRADE_ITEM_IDX';
export const SET_TRADE_AGENT_TYPE = 'MYPAGE/SET_TRADE_AGENT_TYPE';
export const SET_TRADE_AGENT_IDX = 'MYPAGE/SET_TRADE_AGENT_IDX';

export const POST_REPORT_ITEM_TRADE_COMPLETE = 'MYPAGE/POST_REPORT_ITEM_TRADE_COMPLETE';
export const POST_REPORT_ITEM_TRADE_COMPLETE_SUCCESS = success(POST_REPORT_ITEM_TRADE_COMPLETE);
export const POST_REPORT_ITEM_TRADE_COMPLETE_FAILURE = failure(POST_REPORT_ITEM_TRADE_COMPLETE);
export const POST_REPORT_ITEM_TRADE_COMPLETE_LOADING = loading(POST_REPORT_ITEM_TRADE_COMPLETE);

export const SET_REREG_ITEM_IDX = 'MYPAGE/SET_REREG_ITEM_IDX';

export const POST_REREG_ITEM = 'MYPAGE/POST_REREG_ITEM';
export const POST_REREG_ITEM_NON = 'MYPAGE/POST_REREG_ITEM_NON';
export const POST_REREG_ITEM_SUCCESS = success(POST_REREG_ITEM);
export const POST_REREG_ITEM_FAILURE = failure(POST_REREG_ITEM);
export const POST_REREG_ITEM_LOADING = loading(POST_REREG_ITEM);

// ===================== 2.3 MySELL ===================== //
export const GET_MY_SELL_COUNT = 'MYPAGE/GET_MY_SELL_COUNT';
export const GET_MY_SELL_COUNT_LOADING = loading(GET_MY_SELL_COUNT);
export const GET_MY_SELL_COUNT_SUCCESS = success(GET_MY_SELL_COUNT);
export const GET_MY_SELL_COUNT_FAILURE = failure(GET_MY_SELL_COUNT);

export const GET_MY_SELL_LIST = 'MYPAGE/GET_MY_SELL_LIST';
export const GET_MY_SELL_LIST2 = 'MYPAGE/GET_MY_SELL_LIST2';
export const RESET_MY_SELL_LIST = 'RESET_MY_SELL_LIST';
export const GET_MY_SELL_LIST_LOADING = loading(GET_MY_SELL_LIST);
export const GET_MY_SELL_LIST_SUCCESS = success(GET_MY_SELL_LIST);
export const GET_MY_SELL_LIST_FAILURE = failure(GET_MY_SELL_LIST);
