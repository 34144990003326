export const codes = { ope: 'OPE', div: 'DIV', hal: 'HAL', two: 'TWO', hou: 'HOU' };

/** @deprecated getName 사용권장 */
export const names = { [codes.ope]: '원룸(오픈형)', [codes.div]: '원룸(분리형)', [codes.hal]: '1.5룸', [codes.two]: '투룸', [codes.hou]: '거주형' };
export const _names = { [codes.ope]: '원룸(오픈형)', [codes.div]: '원룸(분리형)', [codes.hal]: '1.5룸', [codes.two]: '투룸', [codes.hou]: '거주형' };
// const description = {};

export const getName = (code) => {
  return _names[code] || '';
};

export default {
  codes,
  names,
  getName,
  view: {
    sale: {
      select: [
        { cd: codes.ope, name: _names[codes.ope], viewName: '원룸(오픈형) : 주방과 생활공간이 통합된 구조' },
        { cd: codes.div, name: _names[codes.div], viewName: '원룸(분리형) : 주방과 생활공간이 분리된 구조' },
        { cd: codes.hal, name: _names[codes.hal], viewName: '1.5룸 : 거실과 방이 분리된 구조' },
        { cd: codes.two, name: _names[codes.two], viewName: '투룸 : 거실과 분리된 방 두개의 구조' },
        { cd: codes.hou, name: _names[codes.hou], viewName: '거주형 : 거실과 세개 이상의 분리된 방 구조' },
        // { text: '쓰리룸이상', value: 'TU' },
        // { text: '복층형', value: 'LA' },
      ],
    },
  },
};
