import { loading, success, failure } from '@/data/utils';
// ===================== 공통 ===================== //
// @note request 보내는건 auth dir 에서 이루어짐
export const AGENT_SIGNUP_RESET = 'AGENT_SIGNUP/AGENT_SIGNUP_RESET';

export const AGENT_SIGNUP_ERROR = 'AGENT_SIGNUP/AGENT_SIGNUP_ERROR';
export const AGENT_SIGNUP_RESET_ERROR_STATE = 'AGENT_SIGNUP/AGENT_SIGNUP_RESET_ERROR_STATE';
// export const AGENT_SIGNUP_POST = 'AGENT_SIGNUP/AGENT_SIGNUP_POST';

export const SAVE_LOGIN_INFO = 'AGENT_SIGNUP/SAVE_LOGIN_INFO';

export const SET_DWCD = 'AGENT_SIGNUP/SET_DWCD';
export const SET_ACADEMY_DWCD = 'AGENT_SIGNUP/SET_ACADEMY_DWCD';
export const SET_IS_FEE_AGREED = 'AGENT_SIGNUP/SET_IS_FEE_AGREED';
export const SET_IS_ALL_AGREED = 'AGENT_SIGNUP/SET_IS_ALL_AGREED';
export const SET_IS_USAGE_TERM_AGREED = 'AGENT_SIGNUP/SET_IS_USAGE_TERM_AGREED';
export const SET_IS_AGENT_SERVICE_AGREED = 'AGENT_SIGNUP/SET_IS_AGENT_SERVICE_AGREED';
export const SET_IS_INFO_AGREED = 'AGENT_SIGNUP/SET_IS_INFO_AGREED';

export const CHECK_CODE = 'AGENT_SIGNUP/CHECK_CODE';
export const SET_CHECK_CODE = 'AGENT_SIGNUP/SET_CHECK_CODE';
export const GET_OFFICE_LIST = 'AGENT_SIGNUP/GET_OFFICE_LIST';
export const DEL_OFFICE_SET = 'AGENT_SIGNUP/DEL_OFFICE_SET';
export const DEL_ADDRES_SET = 'AGENT_SIGNUP/DEL_ADDRES_SET';
export const GET_OFFICE_LIST_LOADING = loading(GET_OFFICE_LIST);
export const GET_OFFICE_LIST_FAIL = failure(GET_OFFICE_LIST);
export const GET_ADDRESS_LIST = 'AGENT_SIGNUP/GET_ADDRESS_LIST';
export const GET_ADDRESS_LIST_LOADING = loading(GET_ADDRESS_LIST);
export const GET_ADDRESS_LIST_FAIL = failure(GET_ADDRESS_LIST);
export const GET_BROKER_OFFICE_LIST = 'AGENT_SIGNUP/GET_BROKER_OFFICE_LIST';
export const SET_OFFICE_LIST = 'AGENT_SIGNUP/SET_OFFICE_LIST';
export const SET_ADDRESS_LIST = 'AGENT_SIGNUP/SET_ADDRESS_LIST';
export const SET_BROKER_OFFICE_LIST = 'AGENT_SIGNUP/SET_BROKER_OFFICE_LIST';

export const SET_SELECTED_OFFICE = 'AGENT_SIGNUP/SET_SELECTED_OFFICE';
export const SET_SELECTED_OFFICE_ADDRESS = 'AGENT_SIGNUP/SET_SELECTED_OFFICE_ADDRESS';
export const SET_SELECTED_OFFICE_ADDRESS_DETAIL = 'AGENT_SIGNUP/SET_SELECTED_OFFICE_ADDRESS_DETAIL';
export const SEARCH_XY = 'AGENT_SIGNUP/SEARCH_XY';
export const SET_OFFICE_XY = 'AGENT_SIGNUP/SET_OFFICE_XY';
export const SET_B_DONG_CODE = 'AGENT_SIGNUP/SET_B_DONG_CODE';
export const SET_MAP_LEVEL = 'AGENT_SIGNUP/SET_MAP_LEVEL';
export const CHANGE_BROKER_IDX = 'AGENT_SIGNUP/CHANGE_BROKER_IDX';
export const SET_SELECTED_OFFICE_INFO = 'AGENT_SIGNUP/SET_SELECTED_OFFICE_INFO';

export const SET_OFFICE_TYPE = 'AGENT_SIGNUP/SET_OFFICE_TYPE';

export const AS_SET_MAP_ERROR = 'AGENT_SIGNUP/AS_SET_MAP_ERROR';

export const SET_OFFICE_PHONE_NUM = 'AGENT_SIGNUP/SET_OFFICE_PHONE_NUM';
export const GET_OFFICE_PHONE_NUM = 'AGENT_SIGNUP/GET_OFFICE_PHONE_NUM';

export const SET_BROKER_ID = 'AGENT_SIGNUP/SET_BROKER_ID';
export const SET_BROKER_PASSWORD = 'AGENT_SIGNUP/SET_BROKER_PASSWORD';
export const SET_BROKER_CAREER_TEXT = 'AGENT_SIGNUP/SET_BROKER_CAREER_TEXT';

export const AGENT_SIGNUP_HANDLE_NEXT_STEP = 'AGENT_SIGNUP/AGENT_SIGNUP_HANDLE_NEXT_STEP';

export const CHECK_ID_DUP = 'AGENT_SIGNUP/CHECK_ID_DUP';
export const CHECK_ID_DUP_SUCCESS = 'AGENT_SIGNUP/CHECK_ID_DUP_SUCCESS';
export const CHECK_ID_DUP_FAILURE = 'AGENT_SIGNUP/CHECK_ID_DUP_FAILURE';
export const SET_CHECK_ID_DUP = 'AGENT_SIGNUP/SET_CHECK_ID_DUP';

export const CHECK_ACADEMY = 'AGENT_SIGNUP/CHECK_ACADEMY';
export const SET_CHECK_ACADEMY_RESULT = 'AGENT_SIGNUP/SET_CHECK_ACADEMY_RESULT';

export const EDIT_ACADEMY = 'AGENT_SIGNUP/EDIT_ACADEMY';
export const EDIT_ACADEMY_SUCCESS = success(EDIT_ACADEMY);
export const CANCEL_ACADEMY = 'AGENT_SIGNUP/CANCEL_ACADEMY';
export const CANCEL_ACADEMY_SUCCESS = success(CANCEL_ACADEMY);

export const GET_USER_APPLY_ACADEMY_INFO = 'AGENT_SIGNUP/GET_APPLY_ACADEMY_INFO';
export const GET_USER_APPLY_ACADEMY_INFO_SUCCESS = success(GET_USER_APPLY_ACADEMY_INFO);

export const ALREADY_ACADEMY = 'AGENT_SIGNUP/ALREADY_ACADEMY';
export const ALREADY_ACADEMY_LOADING = loading(ALREADY_ACADEMY);
export const ALREADY_ACADEMY_FAILURE = failure(ALREADY_ACADEMY);
export const ALREADY_ACADEMY_SUCCESS = success(ALREADY_ACADEMY);

export const APPLY_ACADEMY_PRE_BILL = 'AGENT_SIGNUP/APPLY_ACADEMY_PRE_BILL';
export const SET_APPLY_ACADEMY_PRE_BILL_DATA = 'AGENT_SIGNUP/SET_APPLY_ACADEMY_PRE_BILL_DATA';
export const APPLY_ACADEMY_BILL = 'AGENT_SIGNUP/APPLY_ACADEMY_BILL';
export const GET_ACADEMY_DATE = 'AGENT_SIGNUP/GET_ACADEMY_DATE';
export const SET_ACADEMY_DATE = 'AGENT_SIGNUP/SET_ACADEMY_DATE';

export const SET_ACADEMY_NAME = 'AGENT_SIGNUP/SET_ACADEMY_NAME';
export const SET_ACADEMY_NUM = 'AGENT_SIGNUP/SET_ACADEMY_NUM';
export const SET_ACADEMY_INFO = 'AGENT_SIGNUP/SET_ACADEMY_INFO';
export const RESET_ACADEMY = 'AGENT_SIGNUP/RESET_ACADEMY';
export const RESET_ALREADY = 'AGENT_SIGNUP/RESET_ALREADY';

export const APPLY_SHARE_OFFICE = 'AGENT_SIGNUP/APPLY_SHARE_OFFICE';
export const SET_SHARE_DONG = 'AGENT_SIGNUP/SET_SHARE_DONG';
export const SET_SHARE_NAME = 'AGENT_SIGNUP/SET_SHARE_NAME';
export const SET_SHARE_NUM = 'AGENT_SIGNUP/SET_SHARE_NUM';
export const SET_SHARE_YEAR = 'AGENT_SIGNUP/SET_SHARE_YEAR';
export const RESET_NEW = 'AGENT_SIGNUP/RESET_NEW';

export const APPLY_TOUR_OFFICE = 'AGENT_SIGNUP/APPLY_TOUR_OFFICE';
export const GET_TOUR_OFFICE_LIST = 'AGENT_SIGNUP/GET_TOUR_OFFICE_LIST';
export const SET_TOUR_OFFICE_LIST = 'AGENT_SIGNUP/SET_TOUR_OFFICE_LIST';
export const SET_TOUR_OFFICE_NAME = 'AGENT_SIGNUP/SET_TOUR_OFFICE_NAME';
export const SET_TOUR_OFFICE_NAME2 = 'AGENT_SIGNUP/SET_TOUR_OFFICE_NAME2';
export const SET_TOUR_OFFICE = 'AGENT_SIGNUP/SET_TOUR_OFFICE';
export const SET_TOUR_NAME = 'AGENT_SIGNUP/SET_TOUR_NAME';
export const SET_TOUR_NUM = 'AGENT_SIGNUP/SET_TOUR_NUM';
export const SET_TOUR_DATE = 'AGENT_SIGNUP/SET_TOUR_DATE';
export const SET_TOUR_EXIST = 'AGENT_SIGNUP/SET_TOUR_EXIST';
export const RESET_TOUR = 'AGENT_SIGNUP/RESET_TOUR';

//시도, 시군구, 읍면동
//------------------------------------------
export const GET_RECOMMEND_ADDR_SiDo = 'AGENT_SIGNUP/GET_RECOMMEND_ADDR_SiDo';
export const SET_RECOMMEND_ADDR_SiDo = 'AGENT_SIGNUP/SET_RECOMMEND_ADDR_SiDo';
export const CHANGE_RECOMMEND_SiDoCode = 'AGENT_SIGNUP/CHANGE_RECOMMEND_SiDoCode';
export const CHANGE_RECOMMEND_SiDoName = 'AGENT_SIGNUP/CHANGE_RECOMMEND_SiDoName';

export const GET_RECOMMEND_ADDR_SiGunGu = 'AGENT_SIGNUP/GET_RECOMMEND_ADDR_SiGunGu';
export const SET_RECOMMEND_ADDR_SiGunGu = 'AGENT_SIGNUP/SET_RECOMMEND_ADDR_SiGunGu';
export const CHANGE_RECOMMEND_SiGunGuCode = 'AGENT_SIGNUP/CHANGE_RECOMMEND_SiGunGuCode';
export const CHANGE_RECOMMEND_SiGunGuName = 'AGENT_SIGNUP/CHANGE_RECOMMEND_SiGunGuName';

export const GET_RECOMMEND_ADDR_DongRi = 'AGENT_SIGNUP/GET_RECOMMEND_ADDR_DongRi';
export const SET_RECOMMEND_ADDR_DongRi = 'AGENT_SIGNUP/SET_RECOMMEND_ADDR_DongRi';
export const CHANGE_RECOMMEND_DongRiCode = 'AGENT_SIGNUP/CHANGE_RECOMMEND_DongRiCode';

//활동지역
export const ADD_AGENT_SIGNUP_ACTIVE_AREA = 'AGENT_SIGNUP/ADD_AGENT_SIGNUP_ACTIVE_AREA';
export const SAVE_AGENT_SIGNUP_BROKER_ACTIVE_AREA = 'AGENT_SIGNUP/SAVE_AGENT_SIGNUP_BROKER_ACTIVE_AREA';
export const DEL_AGENT_SIGNUP_ACTIVE_AREA = 'AGENT_SIGNUP/DEL_AGENT_SIGNUP_ACTIVE_AREA';
export const SAVE_AGENT_SIGNUP_ACTIVE_AREA = 'AGENT_SIGNUP/SAVE_AGENT_SIGNUP_ACTIVE_AREA';
export const CLEAR_AGENT_SGINUP_ACTIVE_AREA = 'AGENT_SIGNUP/CLEAR_AGENT_SGINUP_ACTIVE_AREA';
export const CLEAR_AGENT_SGINUP_ACTIVE_AREA2 = 'AGENT_SIGNUP/CLEAR_AGENT_SGINUP_ACTIVE_AREA2';
export const DEL_AGENT_SIGNUP_B_DONG_CODE = 'AGENT_SIGNUP/DEL_AGENT_SIGNUP_B_DONG_CODE';
