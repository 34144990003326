import React, { useEffect, useMemo, useState } from 'react';
import { COOKIE_CONSTS } from '@/resources/global';
import { getCookieByKey, setCookieByKey } from '@/data/cookie';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';
import { goToDetail } from '../';
import styled from 'styled-components';
import toggle from '@/hocs/toggle';

const CurrentSearchList = ({ setShow, setSearchMode, interestComplex }) => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);

  useEffect(async () => {
    const searchHistory = await getCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY);
    if (searchHistory) {
      setList(searchHistory);
    }
  }, []);

  const handleClick = (item) => {
    setShow(false);
    // setSearchMode(false);
    dispatch(goToDetail(item, true));
  };

  const elements = useMemo(() => {
    return list.map((item, key) => {
      let date = new Date(item.createdAt);
      date = `${date.getMonth() + 1}.${date.getDate()}`;

      const onDelete = (e) => {
        e.stopPropagation();
        var temp = [...list];
        temp.splice(key, 1);
        setList(temp);
        setCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY, temp);
      };

      const action = () => {
        handleClick(item);
      };

      return (
        <li key={key} className="link_item" onClick={action} style={{ cursor: 'pointer' }}>
          <span>{item.title}</span>
          <span className="item_info">
            <span className="date">{date}</span>
            <button className="btn-close-xs" onClick={onDelete}>
              X
            </button>
          </span>
        </li>
      );
    });
  }, [list]);

  const filteredComplex = interestComplex
    .sort((a, b) => {
      const date1 = new Date(a.update_date).getTime();
      const date2 = new Date(b.update_date).getTime();
      return date2 - date1;
    })
    .filter((a, key) => key < 3);

  const interests = useMemo(() => {
    return filteredComplex?.map((data, key) => {
      const action = () => {
        handleClick(item);
      };
      const item = data.complex;
      return (
        <li key={key} className="result_item" onClick={action}>
          <a className="link_item">{item.complex_name}</a>
          <span>{item.complex_address}</span>
        </li>
      );
    });
  }, [filteredComplex]);

  return (
    <div className="suggest_area" style={{ zIndex: 100, left: 'unset', top: 'unset' }}>
      <div className="recent_block">
        <h4>최근검색어</h4>
        {!!elements && elements.length > 0 ? (
          <ul>{elements}</ul>
        ) : (
          <ul>
            <li>최근 검색어가 없습니다.</li>
          </ul>
        )}
      </div>
      <div className="favorite_block">
        <h4>관심단지</h4>
        {!!interests && interests.length > 0 ? (
          <ul>{interests}</ul>
        ) : (
          <ul>
            <li>관심단지가 없습니다.</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default toggle(CurrentSearchList);

// const P = styled.p`
//   text-align: left;
//   color: #7d7d7d;
// `;

// const Item = styled.li`
//   display: flex;
//   justify-content: space-between;

//   span {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }

//   & > span:nth-child(1) {
//     /* text-align: left; */
//   }

//   & > span:nth-child(2) {
//     /* text-align: right; */
//     color: #999;
//     float: right;
//   }

//   .search_result_date {
//     margin-right: 5px;
//     color: #999;
//   }

//   .search_result_delete {
//     width: 20px;
//     height: 20px;
//   }
// `;
