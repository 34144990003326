import React from 'react';

const TemplateFormItem = ({ title, children }) => {
  return (
    <div className="form-item">
      <div className="form-wrap">
        {!!title && (
          <div className="form-head">
            <p className="form-subject">
              <span className="form-name">{title}</span>
            </p>
          </div>
        )}
        <div className="form-body">
          {children}
          {/* <div className="form-area">
            <div className="option-list module-b style-a type-a">
              <span className="option-item">
                <label className="radio form module-b style-c accent-01 medium">
                  <input type="radio" className="form-elem" name="radio-0001" /> <span className="form-text">주택</span>
                </label>
              </span>
              <span className="option-item">
                <label className="radio form module-b style-c accent-01 medium">
                  <input type="radio" className="form-elem" name="radio-0001" /> <span className="form-text">상가</span>
                </label>
              </span>
              <span className="option-item">
                <label className="radio form module-b style-c accent-01 medium">
                  <input type="radio" className="form-elem" name="radio-0001" /> <span className="form-text">오피스텔</span>
                </label>
              </span>
            </div>
          </div>
          <div className="form-area">
            <div className="option-list module-b style-a type-a">
              <span className="option-item">
                <label className="form checkbox module-a style-b type-line accent-01 small-1x">
                  <input type="checkbox" className="form-elem" /> <span className="form-text">주거용이며, 전용면적 32평(71.89m²)이하입니다.</span>
                </label>
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TemplateFormItem;
