import * as AT from './actionTypes';

export const setController = (payload) => ({
  type: AT.SET_CONTROLLER,
  payload,
});

export const setChatData = (payload) => ({
  type: AT.SET_CHAT_DATA,
  payload,
});
