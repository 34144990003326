import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

const BrowserView = ({ children }) => {
  const isMobile = useSelector(selectors.common.isMobile);
  return isMobile ? false : <>{children}</>;
};

export default BrowserView;
