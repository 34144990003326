import { FeedType } from '@/services/Community';

export default {
  [FeedType.POST_FOR_BUY]: {
    content: {
      title: '원하시는 내용을 구체적으로 입력해주세요.',
      placeholder: '다음 내용을 작성하시면 더 많은 중개사에게 빠른 응답을 받을 수 있어요.\n\n- 금액 범위, 입주시기, 평형, 기타 원하는 집의 조건 등',
    },
    hashtag: { placeholder: '입력예)\n#매매 #전세 #20평대 #15억' },
  },
  [FeedType.COMPLEX_POST]: {
    content: {
      title: '관련 내용을 작성해주세요.',
      placeholder: '예시) 9호선 삼성중앙역, 7호선 청담역 역세권입니다. 집 앞에 버스정류장이 있어 대중교통 이용이 용이합니다.',
    },
    hashtag: { placeholder: '입력예)\n#역세권 #주변개발호재 #차량지상통행금지' },
  },
  [FeedType.LOCAL_POST]: {
    content: { title: '관련 내용을 작성해주세요.', placeholder: '예시) GTX 준공이 곧 완료된다고 합니다.\n삼성동이 더 발전할 것 같아요.' },
    hashtag: { placeholder: '입력예)\n#역세권 #주변개발호재 #차량지상통행금지' },
  },
  [FeedType.QNA]: {
    content: { title: '부동산 관련해서 궁금한 내용을 작성해주세요.', placeholder: '예시) 종부세 줄이고 싶은데 어떤 방법이 있을까요?' },
    hashtag: { placeholder: '입력예)\n#매매 #임대차 #분양 #청약 #세금' },
  },
  [FeedType.ONE_ROOM_POST]: {
    content: { title: '원룸에 살면서 느끼고 경험하신 내용을 작성해주세요.' },
    hashtag: { placeholder: '입력예)\n#보증금 #월세 #방음 #층간소음 #대출' },
  },
  [FeedType.SYSTEM_POSTED_PRICE]: {},
  [FeedType.POST_FOR_SALE]: {},
};
