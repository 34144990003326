import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//검색
export const getQaList = () => ({
  type: AT.GET_MG_QA_LIST,
});
export const getQaListLoading = () => ({
  type: AT.GET_MG_QA_LIST_LOADING,
});
export const getQaListSuccess = (qaList) => ({
  type: AT.GET_MG_QA_LIST_SUCCESS,
  qaList,
});
export const getQaListFailure = (error) => ({
  type: AT.GET_MG_QA_LIST_FAILURE,
  error,
});
export const getQaDetail = (goods_idx) => ({
  type: AT.GET_QA_DETAIL,
  goods_idx,
});
export const getQaDetailLoading = () => ({
  type: AT.GET_QA_DETAIL_LOADING,
});
export const getQaDetailSuccess = (qaDetailList) => ({
  type: AT.GET_QA_DETAIL_SUCCESS,
  qaDetailList,
});
export const getQaDetailFailure = (error) => ({
  type: AT.GET_QA_DETAIL_FAILURE,
  error,
});
//답변등록
export const regQaAns = (qaIdx, val, goods_idx) => ({
  type: AT.REG_QA_ANSWER,
  qaIdx,
  val,
  goods_idx,
});
//답변수정
export const editQaAns = (qaAnidx, val, goods_idx) => ({
  type: AT.EDIT_QA_ANSWER,
  qaAnidx,
  val,
  goods_idx,
});
//답변 삭제
export const delQaAns = (qaAnidx, goods_idx) => ({
  type: AT.DEL_QA_ANSWER,
  qaAnidx,
  goods_idx,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_QA_CURRENT_PAGE,
  page,
});
export const setTotalPage = (cnt) => ({
  type: AT.SET_QA_TOTAL_PAGE,
  cnt,
});
export const setKeyword = (val) => ({
  type: AT.SET_QA_KEYWORD,
  val,
});
export const setTradetype = (val) => ({
  type: AT.SET_QA_TRADE_TYPE,
  val,
});
export const setCategory = (val) => ({
  type: AT.SET_QA_CATEGORY,
  val,
});
