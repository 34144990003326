import { useDispatch } from 'react-redux';
import { actions } from '@/data';

const useAddTags = ({ setKeyword, setKeywordTag, payload, setFeeds, setPayload }) => {
  const dispatch = useDispatch();

  const addTags = async (addTags) => {
    const temp = payload;
    if (temp?.cursor) {
      delete temp.cursor;
    }
    const newPayload = { ...temp, priorityTag: addTags };
    dispatch(actions.community.getFeeds(newPayload, undefined, setFeeds));
    setPayload({ ...temp, priorityTag: addTags });
    setKeyword(addTags?.name);
    setKeywordTag(addTags);
  };

  return { addTags };
};

export default useAddTags;
