import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';

// 글 작성 후 유저들에게 바로 보여주기 위한 처리
// api콜 요청을 줄이기 위해 > 유저가 현재 필터해놓은 조건에 부합할때에만 새로 api요청
const useUpdateNewFeeds = ({ payload, setFeeds }) => {
  const dispatch = useDispatch();
  const createdFeed = useSelector(selectors.community.createdFeed);

  useEffect(() => {
    const hasNewFeed = createdFeed?.status === true;
    const isSameFeedType = !payload?.feedTypes?.length || payload?.feedTypes?.includes(createdFeed?.data?.feedType);

    if (hasNewFeed && isSameFeedType) {
      dispatch(actions.community.getFeeds(payload, undefined, setFeeds));
      dispatch(actions.community.setCreatedFeed(false));
    }
  }, [createdFeed]);
  return;
};

export default useUpdateNewFeeds;
