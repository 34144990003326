import React from 'react';
import { Link } from 'react-router-dom';
import useReportPopup from '@/combinedComp/Community/DetailNew/hooks/useReportPopup';
import { STATUS } from '@/data/utils';
import { useDeleteFeed, useEditFeed } from './hooks';
import useSetTool from './hooks/useSetTool';

const Tool = ({ setDeleteStatus, data, type }) => {
  const { loginUserId, writerId, postId, postType, postStatus } = useSetTool(data, type);
  const { onEditFeed } = useEditFeed({});
  const { onDeleteFeed } = useDeleteFeed({ setDeleteStatus });
  const openReportPopup = useReportPopup();
  const isSameUser = loginUserId == writerId;
  const isDeleted = postStatus === 'DELETED';

  return isDeleted ? (
    false
  ) : (
    <div className="button-area">
      {isSameUser ? (
        <>
          <Link className="btn modify" to="#" onClick={(e) => onEditFeed(e, postId)}>
            <span className="btn-text">수정</span>
          </Link>

          <button className="btn delete" type="button" onClick={(e) => onDeleteFeed(e, postId)}>
            <span className="btn-text">삭제</span>
          </button>
        </>
      ) : (
        <Link to="#" className="btn report" data-bui-toggle-button="reportPosts" onClick={(e) => openReportPopup(e, postType, postId)}>
          <span className="btn-text">신고</span>
        </Link>
      )}
    </div>
  );
};

export default Tool;
