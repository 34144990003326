import { actions, selectors } from '@/data';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useGetBrokerStatus = ({ isBroker }) => {
  const dispatch = useDispatch();

  const brokerInfo = useSelector(selectors.brokerPage.brokerOfficeInfo.getBrokerInfo);
  const approval_status = brokerInfo?.approval_status;

  useEffect(() => {
    if (isBroker && !approval_status) {
      dispatch(actions.brokerPage.brokerOfficeInfo.getBrokerOfficeInfo());
    }
  }, [approval_status]);

  return { approval_status };
};

export default useGetBrokerStatus;
