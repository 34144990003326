import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//서식 및 양식
export const form_getList = () => ({
  type: AT.GET_DR_FORM_LIST,
});
export const form_getDetail = (idx) => ({
  type: AT.GET_DR_FORM_DETAIL,
  idx,
});
export const form_setAttach = (file_url) => ({
  type: AT.SET_DR_FORM_NEW_ATTACH,
  file_url,
});
export const form_setTitle = (val) => ({
  type: AT.SET_DR_FORM_NEW_TITLE,
  val,
});
export const form_setCont = (val) => ({
  type: AT.SET_DR_FORM_NEW_CONT,
  val,
});
export const form_setKeyword = (keyword) => ({
  type: AT.SET_DR_FORM_KEYWORD,
  keyword,
});
export const form_setCurrentPage = (page) => ({
  type: AT.SET_DR_FORM_CURRENT_PAGE,
  page,
});
export const form_setTotalCnt = (totalCnt) => ({
  type: AT.SET_DR_FORM_TOTAL_CNT,
  totalCnt,
});
export const form_getListLoading = () => ({
  type: AT.GET_DR_FORM_LIST_LOADING,
});
export const form_getListSuccess = (conlist) => ({
  type: AT.GET_DR_FORM_LIST_SUCCESS,
  conlist,
});
export const form_getListFailure = (error) => ({
  type: AT.GET_DR_FORM_LIST_FAILURE,
  error,
});

export const form_getDetailLoading = () => ({
  type: AT.GET_DR_FORM_DETAIL_LOADING,
});
export const form_getDetailSuccess = (detail) => ({
  type: AT.GET_DR_FORM_DETAIL_SUCCESS,
  detail,
});
export const form_getDetailFailure = (error) => ({
  type: AT.GET_DR_FORM_DETAIL_FAILURE,
  error,
});

//관련판례
export const precedent_getList = () => ({
  type: AT.GET_DR_PRECEDENT_LIST,
});
export const precedent_getDetail = (idx) => ({
  type: AT.GET_DR_PRECEDENT_DETAIL,
  idx,
});
export const precedent_setAttach = (file_url) => ({
  type: AT.SET_DR_PRECEDENT_NEW_ATTACH,
  file_url,
});
export const precedent_setTitle = (val) => ({
  type: AT.SET_DR_PRECEDENT_NEW_TITLE,
  val,
});
export const precedent_setCont = (val) => ({
  type: AT.SET_DR_PRECEDENT_NEW_CONT,
  val,
});
export const precedent_setKeyword = (keyword) => ({
  type: AT.SET_DR_PRECEDENT_KEYWORD,
  keyword,
});
export const precedent_setCurrentPage = (page) => ({
  type: AT.SET_DR_PRECEDENT_CURRENT_PAGE,
  page,
});
export const precedent_setTotalCnt = (totalCnt) => ({
  type: AT.SET_DR_PRECEDENT_TOTAL_CNT,
  totalCnt,
});
export const precedent_getListLoading = () => ({
  type: AT.GET_DR_PRECEDENT_LIST_LOADING,
});
export const precedent_getListSuccess = (conlist) => ({
  type: AT.GET_DR_PRECEDENT_LIST_SUCCESS,
  conlist,
});
export const precedent_getListFailure = (error) => ({
  type: AT.GET_DR_PRECEDENT_LIST_FAILURE,
  error,
});

export const precedent_getDetailLoading = () => ({
  type: AT.GET_DR_PRECEDENT_DETAIL_LOADING,
});
export const precedent_getDetailSuccess = (detail) => ({
  type: AT.GET_DR_PRECEDENT_DETAIL_SUCCESS,
  detail,
});
export const precedent_getDetailFailure = (error) => ({
  type: AT.GET_DR_PRECEDENT_DETAIL_FAILURE,
  error,
});

//유용한 사이트
export const useful_getList = () => ({
  type: AT.GET_DR_USEFUL_LIST,
});
export const useful_getDetail = (idx) => ({
  type: AT.GET_DR_USEFUL_DETAIL,
  idx,
});
export const useful_setAttach = (file_url) => ({
  type: AT.SET_DR_USEFUL_NEW_ATTACH,
  file_url,
});
export const useful_setTitle = (val) => ({
  type: AT.SET_DR_USEFUL_NEW_TITLE,
  val,
});
export const useful_setCont = (val) => ({
  type: AT.SET_DR_USEFUL_NEW_CONT,
  val,
});
export const useful_setKeyword = (keyword) => ({
  type: AT.SET_DR_USEFUL_KEYWORD,
  keyword,
});
export const useful_setCurrentPage = (page) => ({
  type: AT.SET_DR_USEFUL_CURRENT_PAGE,
  page,
});
export const useful_setTotalCnt = (totalCnt) => ({
  type: AT.SET_DR_USEFUL_TOTAL_CNT,
  totalCnt,
});
export const useful_getListLoading = () => ({
  type: AT.GET_DR_USEFUL_LIST_LOADING,
});
export const useful_getListSuccess = (conlist) => ({
  type: AT.GET_DR_USEFUL_LIST_SUCCESS,
  conlist,
});
export const useful_getListFailure = (error) => ({
  type: AT.GET_DR_USEFUL_LIST_FAILURE,
  error,
});

export const useful_getDetailLoading = () => ({
  type: AT.GET_DR_USEFUL_DETAIL_LOADING,
});
export const useful_getDetailSuccess = (detail) => ({
  type: AT.GET_DR_USEFUL_DETAIL_SUCCESS,
  detail,
});
export const useful_getDetailFailure = (error) => ({
  type: AT.GET_DR_USEFUL_DETAIL_FAILURE,
  error,
});
