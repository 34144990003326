import React, { useMemo } from 'react';
import { FeedType, TagType } from '@/services/Community';
import Contents from '@/combinedComp/Community/Create/Post/components/Contents';
import Images from '@/combinedComp/Community/Create/Post/components/Images';
import HashTagFixed from '@/combinedComp/Community/Create/Post/components/HashTagFixed';
import HashTagInput from '@/combinedComp/Community/Create/Post/components/HashTagInput';

const QnaPost = ({ feedType, addedTagsRef, contents, setContents, tags, numericTags, imagesRef, isUpdate, updateFeedDetail }) => {
  const needToMarkAddressKor = useMemo(() => feedType === FeedType.COMPLEX_POST || feedType === FeedType.LOCAL_POST, [feedType]);
  const addressTagsKor = useMemo(() => {
    const filteredTags = tags.filter((tag) => tag.type === TagType.ADDRESS_Dong_TAG || tag.type === TagType.COMPLEX_TAG).map((item) => item.name);
    return filteredTags.join(' ');
  }, [tags]);

  const handleContents = (e) => {
    setContents(e.target.value ? e.target.value : '');
  };

  return (
    <div className="form-list">
      <Contents
        needToMarkAddressKor={needToMarkAddressKor}
        addressTagsKor={addressTagsKor}
        contents={contents}
        handleContents={handleContents}
        feedType={feedType}
      />
      <Images isUpdate={isUpdate} imagesRef={imagesRef} updateFeedDetail={updateFeedDetail} feedType={feedType} />

      {/* TODO: @커뮤니티테스트 */}
      {/* <HashTagFixed tags={tags} feedType={feedType} numericTags={numericTags} /> */}
      <HashTagInput addedTags={addedTagsRef} feedType={feedType} isUpdate={isUpdate} updateFeedDetail={updateFeedDetail} />
    </div>
  );
};

export default QnaPost;
