import React from 'react';
import FavoButtonForGoods from '@/components/FavoButtonForGoods';

const FavBtn = ({ is_favorite, goods_idx }) => {
  return (
    <div className="favo_area">
      <FavoButtonForGoods is_favorite={is_favorite === 'Y'} goods_idx={goods_idx} />
    </div>
  );
};

export default React.memo(FavBtn);
