/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { useHistory } from 'react-router-dom';
import { jsx, css } from '@emotion/core';
import { priceToText } from '@/pages/Buying/util';
import Navigation from '@/combinedComp/Navigation';
import { GOODS_STATUS_TEXT } from '@/services/goods/core';
import { ENTER_TYPE_TEXT } from '@/services/goods/detail';
import { MovingDate } from '@/components/cards/GoodsPublicCard/components';

/* 
1. 관심매물
*/
const InterestGoodsMain = ({ myInterestGoods }) => {
  const isMobile = useSelector(selectors.common.isMobile);
  const dargBoxCss = (isDragging) => {
    return css`
      position: ${isDragging && !isMobile ? 'static!important' : null};
    `;
  };
  const dispatch = useDispatch();
  const myCpsData = useSelector(selectors.my.getMyCpsData);

  const addComplex = (e) => {
    e.preventDefault();
    dispatch(actions.my.myCpPageStatus('regi'));
    dispatch(actions.my.myCpPagePreStatus('main'));
    dispatch(actions.my.selectedComplex(''));
    // dispatch(actions.address.clearSavedForm());
    dispatch(actions.common.setComplexArea2(''));
    return;
  };
  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    if (result.source.index == result.destination.index) return;
    dispatch(actions.my.sortCp(result.source.index, result.destination.index));
  };
  const onDelete = () => {
    dispatch(
      actions.common.alert({
        contents: '관심단지를 삭제하였습니다.',
        onConfirm: () => dispatch(actions.my.getMyCps()),
      })
    );
  };

  const Article_card = ({ key, item, priority }) => {
    const goods = item?.goods;
    const enterFlexible = goods?.enter_flexible;

    const cancelFavo = (e, goods_idx) => {
      e.preventDefault();
      dispatch(
        actions.common.confirm({
          contents: '해당 매물을 관심매물 목록에서 삭제하시겠습니까??',
          cancelButtonText: '취소',
          onCancel: () => {},
          confirmButtonText: '확인',
          onConfirm: () => {
            dispatch(actions.common.handleFavGoods('D', goods_idx));
          },
        })
      );
      return;
    };

    const goToComplex = (e, tb_cb_complex_idx) => {
      e.preventDefault();
      dispatch(actions.router.push('/buying?d_complex=' + tb_cb_complex_idx));
      return;
    };

    const gotoGoodsList = (e, goods) => {
      e.preventDefault();
      dispatch(actions.router.push('/buying?d_complex=' + goods?.tb_cb_complex_idx + '&d_trade_type=all&d_area=all'));
    };

    const goToGoodsDetail = (e, goods) => {
      e.preventDefault();
      dispatch(actions.router.push('/buying?d_complex=' + goods?.tb_cb_complex_idx + '&d_trade_type=all&d_area=all&goods_idx=' + goods?.goods_idx));
      return;
    };

    const showGoods = (e, goods) => {
      e.stopPropagation();
      if (goods?.goods_status == 'SB') {
        goToGoodsDetail(e, goods);
      } else {
        dispatch(
          actions.common.confirm({
            contents: '거래할 수 없는 매물입니다. 해당 단지의 다른 매물들을 보시겠습니까?',
            onConfirm: () => {
              gotoGoodsList(e, goods);
            },
            confirmButtonText: '매물 보러가기',
            cancelButtonText: '취소',
          })
        );
      }
    };

    return (
      <>
        {goods?.goods_common_idx && (
          <li className="item-card apt noimg">
            <div className="item_info_area">
              <div className="favo_area">
                <button className="btn-img btn-favo on" onClick={(e) => cancelFavo(e, item?.goods_idx)}></button>
              </div>
              <a
                className="btn module-a style-b type-fill accent-05 small-1x"
                style={{ marginBottom: '10rem' }}
                onClick={(e) => showGoods(e, item?.goods)}>
                <span className="btn-text">{GOODS_STATUS_TEXT[goods?.goods_status]}</span>
              </a>
              <a className="item_info" onClick={(e) => showGoods(e, item?.goods)}>
                <span className="d_info01">
                  <span className="name-apt">{goods?.complex_name}</span> &nbsp;
                  <b className="info_dong">{goods?.goods_dong}동</b>
                  <span className="info_floor">
                    <b>{goods?.goods_floor}층</b>
                  </span>
                  <span className="info_type">{goods?.pyeong_name}평</span>
                </span>
                <span className="real-price">
                  <i className="type01">
                    {goods?.trade_type == 's' ? '매매' : goods?.trade_type == 'c' ? '전세' : goods?.trade_type == 'm' ? '월세' : undefined}
                  </i>
                  &nbsp;
                  {goods?.trade_type == 's'
                    ? priceToText(goods?.price1)
                    : goods?.trade_type == 'c'
                    ? priceToText(goods?.price2)
                    : goods?.trade_type == 'm'
                    ? priceToText(goods?.price2) + ' / ' + priceToText(goods?.price3)
                    : undefined}
                </span>
                <span className="d_date">
                  <b>
                    <MovingDate enter_date={goods?.enter_date} enter_flexible={goods?.enter_flexible} />
                  </b>
                </span>
                <span className="d_comment">{goods?.description}</span>
              </a>
              {/* <a className="go_detail" onClick={(e) => goToComplex(e, goods?.tb_cb_complex_idx)}>
              단지상세보러가기
            </a> */}
            </div>
          </li>
        )}
      </>
    );
  };

  // const article_cards = [];

  // myCpsData && myCpsData?.length > 0
  //   ? myCpsData.map((item, key) => {
  //       article_cards.push(<Article_card key={item.tb_ub_interest_complex_idx} item={item} priority={item.priority} />);
  //     })
  //   : false;
  const history = useHistory();
  const goToBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  return (
    <div id="page" className="page-front">
      <div id="skip" className="page-skip">
        <a className="skip-item">본문 바로가기</a>
      </div>
      {/* <!-- //page-head --> */}
      <Navigation />
      <hr />
      {/* <!-- page-body --> */}
      <div className="page-body">
        {/* <!-- local --> */}
        <div id="local">
          {/* <!-- local-head --> */}
          <div className="local-head">
            <h2 className="local-subject" id="localSubject">
              <span className="local-name">관심매물</span>
            </h2>
            <div className="local-control">
              <span className="button-area">
                <a className="btn back" onClick={(e) => goToBack(e)}>
                  <span className="btn-text">뒤로</span>
                </a>
              </span>
            </div>
          </div>
          {/* <!-- //local-head --> */}
          {/* <!-- local-body --> */}
          <div className="local-body">
            {/* <!-- content --> */}
            <div id="content">
              {/* <!-- content-body --> */}
              <div className="content-body">
                {/* <!-- 기존 코드 --> */}
                <ul className="item-card-container">
                  {myInterestGoods?.map((item, key) => {
                    return <Article_card key={item?.tb_ub_interest_goods_idx} item={item} />;
                  })}
                </ul>
                {/* <!-- 기존 코드 --> */}
                <div className="guide-board module-a type-a attr-info">
                  <div className="board-wrap">
                    <div className="board-head">
                      <p className="board-subject">
                        <span className="board-name">관심매물을 등록하면?</span>
                      </p>
                      <p className="board-summary"></p>
                    </div>
                    <div className="board-body">
                      {/* <!-- data-list --> */}
                      <ul className="data-list module-a type-c normal-03 small-2x">
                        <li className="data-item">관심 있는 매물을 한 번에 모아보고, 해당 매물 정보를 쉽게 확인할 수 있습니다.</li>
                      </ul>
                      {/* <!-- //data-list --> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- //content-body --> */}
            </div>
            {/* <!-- //content --> */}
          </div>
          {/* <!-- //local-body --> */}
        </div>
        {/* <!-- //local --> */}
      </div>
      {/* <!-- //page-body --> */}
      <hr />
      {/* <!-- page-foot --> */}
    </div>
  );
};

export default InterestGoodsMain;
