import React from 'react';
import KakaoLogin from '@/pages/Login/components/Kakao';
import NaverLogin from '@/pages/Login/components/Naver';

const MainWeb = () => {
  return (
    <fieldset className="submit-form module-a type-c large socail-login">
      <legend>로그인서식</legend>
      <div className="form-list">
        <div className="form-item">
          <div className="form-wrap">
            <div className="button-display module-a style-a type-d">
              <KakaoLogin />
              <NaverLogin />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default MainWeb;
