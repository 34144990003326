import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import { actions as rootActions, selectors as allSelectors } from '@/data';
import { routerActions as router } from 'connected-react-router';
import apis from '@/apis';
import * as detail from './detail/sagas';
import { STEPS, EDU_STEP_LIST, EDU_DETAIL, filterSurroundings } from '@/data/buying/utils';
import { parseCallBack } from '@/services/Helper';
import { objectToQuerystring } from '@/apis/helpers';
import { STATUS } from '@/data/utils';
import { GOODS_MAX_ZOOMLEVEL } from '@/services/Map';

export { detail };

const PRESCHOOL_CATEGORY = 'PS3';
const SCHOOL_CATEGORY = 'SC4';

export function* search({ searchType, setStates, map, callback }) {
  try {
    if (setStates) {
      setStates(STATUS.LOADING);
    }

    const passedMapData = {};
    if (map) {
      // props의 map 정보를 기준으로 조회
      const _zoomlevel = map.getLevel();
      const _bound = map.getBounds();
      const _sw = _bound.getSouthWest();
      const _ne = _bound.getNorthEast();

      passedMapData.zoomlevel = _zoomlevel;
      passedMapData.bound = [_sw.getLng(), _sw.getLat(), _ne.getLng(), _ne.getLat()];
    }

    const zoomLevel = yield passedMapData.zoomlevel || select(selectors.getZoomlevel);
    const bounds = yield passedMapData.bound || select(selectors.getBounds);
    const category = yield select(selectors.getCategory);
    const searchWord = yield select(selectors.getSearchWord);
    const dealtype = yield select(selectors.getDealtype);
    const tradeTypes = yield select(selectors.getTradetype);
    const areasize = yield select(selectors.getAreasize);
    const cntRange = yield select(selectors.getCntRange); // 세대수 범위
    const yearRange = yield select(selectors.getYearRange); //준공년월 범위

    const data = {
      zoomLevel,
      swLng: bounds[0],
      swLat: bounds[1],
      neLng: bounds[2],
      neLat: bounds[3],
      category,
      searchWord,
      tradeTypes: tradeTypes.filter((item) => item !== 'all').join('|'),
      rebuild: dealtype === 'all' ? undefined : dealtype,
      areaSizes: areasize.filter((item) => item !== 'all').join('|'),
    };

    // 세대수 필터
    if (cntRange) {
      if (cntRange[0]) data.minHouseCnt = cntRange[0];
      if (cntRange[1]) data.maxHouseCnt = cntRange[1];
    }

    // 준공일자 필터
    if (yearRange) {
      const minYear = yearRange[0];
      const maxYear = yearRange[1];

      const today = new Date();
      const year = today.getFullYear();
      let month = `${today.getMonth() + 1}`;
      month = month.length === 1 ? `0${month}` : month;

      if (yearRange[0]) {
        data.minConstructYM = `${year + minYear}${month}`;
      }
      if (yearRange[1]) {
        data.maxConstructYM = `${year + maxYear}${month}`;
      }
    }

    let result;

    if (searchType === 'goods' && zoomLevel < GOODS_MAX_ZOOMLEVEL) {
      result = yield apis.buyingApi.searchGoods(data);
      result = result.list;
    } else {
      result = yield apis.buyingApi.search(data);
      result = result.list;
    }

    if (callback) {
      callback(result);
    } else {
      yield put(actions.searchSuccess(result));
    }

    setStates && setStates(STATUS.SUCCESS);
  } catch (e) {
    yield put(actions.setError(e));
    setStates && setStates(STATUS.FAILURE);
  }
}

export function* searchGoods({ payload, pnuCode, setStatus }) {
  try {
    // realtyTypes=(APT:아파트, OFT:오피스텔, default:아파트)
    // tradeTypes=(s:매매, c:전세, m:월세, default:전체)
    // rebuild=(c:입주예정, b:재건축, default:전체)
    // areaSizes=99-132 (면적이 여럿일 경우 "|"로 구분해서 입력) (default:전체)
    // page:(페이지 default:1)
    // perPage:(페이지당 조회 수 default:20)
    setStatus && setStatus(STATUS.LOADING);

    const regionCode = yield select(selectors.getRegionCode);
    const code = pnuCode || regionCode?.code;

    payload.realtyTypes = payload.category;
    delete payload.category;

    if (payload.areaSizes.includes('all')) {
      delete payload.areaSizes;
    }

    if (payload.tradeTypes.includes('all')) {
      delete payload.tradeTypes;
    } else {
      payload.tradeTypes = payload.tradeTypes.join('|');
    }

    const result = yield apis.goodsApi.getGoodsListByDong(code, payload);

    if (payload?.page === 1) {
      yield put(actions.searchGoodsSuccess(result));
    } else {
      const goods = yield select(selectors.getGoods);
      yield put(actions.searchGoodsSuccess({ ...result, rlt: [...goods?.rlt, ...result?.rlt] }));
    }
    setStatus && setStatus(STATUS.SUCCESS);
  } catch (e) {
    setStatus && setStatus(STATUS.FAILURE);
    yield put(actions.setError(e));
  }
}

export function* getChart({ complexId, pyeong_name, setChartData }) {
  try {
    const tradetype = yield select(selectors.getTradetype);
    if (complexId && pyeong_name && tradetype) {
      const result = yield apis.buyingApi.getTradeHistory(complexId, pyeong_name, tradetype);
      yield setChartData(result.totaldata.list);
    }
  } catch (e) {
    yield put(actions.setError(e));
  }
}

export function* getCharts({ items, setChartData }) {
  try {
    const tradetype = yield select(selectors.getTradetype);
    const temp = {};
    for (let i = 0; i < items.length; i++) {
      const { complex_idx, pyeong_name } = items[i];
      const result = yield apis.buyingApi.getTradeHistory(complex_idx, pyeong_name, tradetype);
      temp[complex_idx] = result;
    }
    yield setChartData((prev) => {
      return { ...prev, ...temp };
    });
  } catch (e) {
    yield put(actions.setError(e));
  }
}

export function* toggleSurroundingItem({ keyword, category }) {
  try {
    if (category == 'EX1') {
      yield put(actions.setSurroundingItem({ list: {} }));
    } else {
      let list = [];

      const bounds = yield select(selectors.getBounds);
      list = yield apis.buyingApi.surroundingItem(keyword, category, bounds);
      list = filterSurroundings(category, [SCHOOL_CATEGORY, PRESCHOOL_CATEGORY], keyword, list);

      const key = keyword + '_' + category;
      const surroundings = yield select(selectors.getSurroundings);

      let result;
      if (surroundings[key] === undefined) {
        // on
        result = { [key]: list, ...surroundings };
      } else {
        // off
        const tempSurroundings = { ...surroundings };
        delete tempSurroundings[key];
        result = tempSurroundings;
      }

      yield put(actions.setSurroundingItem({ list: result }));
    }
  } catch (e) {
    yield put(actions.setError(e));
  }
}

export function* reloadSurroundings() {
  try {
    const bounds = yield select(selectors.getBounds);
    const surroundings = yield select(selectors.getSurroundings);
    const keywords = yield Object.keys(surroundings);

    const list = {};
    for (let keyword of keywords) {
      const split = keyword.split('_');
      const category = split[0]; // ex. 초등학교
      const category_code = split[1]; // ex. SC4

      const result = yield apis.buyingApi.surroundingItem(category, category_code, bounds);

      const filteredResult = filterSurroundings(category_code, [SCHOOL_CATEGORY, PRESCHOOL_CATEGORY], category, result);
      list[keyword] = filteredResult;
    }
    yield put(actions.reloadSurroundingsSuccess(list));
  } catch (e) {
    yield put(actions.setError(e));
  }
}

export function* setStep({ step, idx, single_return }) {
  try {
    const querystring = window.location.search;
    const queryObject = parseCallBack(querystring) || {};
    const queryStep = queryObject.d_step; // yield select(selectors.getStep);

    const isMobile = yield select(allSelectors.common.isMobile);
    const isEdu = EDU_STEP_LIST.indexOf(step) > -1 ? true : false;
    const reconnDetailFrom = yield select(allSelectors.analysis.getReconDetailFrom);

    if (isMobile) {
      // console.log('reconnDetailFrom', reconnDetailFrom);
      if (reconnDetailFrom === 'Buying') {
        yield put(rootActions.analysis.setReconDetailFrom(''));
        return;
      }
    }

    // /analysis 분석인 경우 analysis
    // / 메인인 경우 buying
    const currPath = window.location.pathname?.split('/')?.[1] || 'buying';

    if (!isEdu) {
      if (idx) {
        if (step == STEPS.DETAIL) {
          queryObject.d_complex = idx;
          delete queryObject.edu_detail;
          delete queryObject.d_goods;
        } else if (step == STEPS.SALE_DETAIL) {
          queryObject.d_goods = idx;
        }
      }

      if (single_return) {
        queryObject.single_return = true;
      } else {
        delete queryObject.single_return;
      }

      const url = `/${currPath}` + (Object.keys(queryObject).length > 0 ? objectToQuerystring(queryObject) : '');

      if (queryStep >= step) {
        yield put(router.replace(url));
      } else {
        yield put(router.push(url));
      }
    } else {
      //현재 교육 상세보기화면이면 replace
      const edu_detail_type = queryObject[EDU_DETAIL];
      queryObject[EDU_DETAIL] = step;

      const url = `/${currPath}` + (Object.keys(queryObject).length > 0 ? objectToQuerystring(queryObject) : '');

      if (EDU_STEP_LIST.indexOf(edu_detail_type) > -1) {
        yield put(router.replace(url));
      } else {
        yield put(router.push(url));
      }
    }
  } catch (e) {
    yield put(actions.setError(e));
  }
}

/** @deprecated */
export function* selectItem({ complex_idx }) {
  try {
    if (complex_idx) {
      yield put(actions.setSelectedItem(complex_idx, null));
    } else {
      yield put(actions.setSelectedItem(null, null));
      yield put(actions.detail.initialize());
    }
  } catch (e) {
    yield put(actions.setError(e));
  }
}

// 일반유저의 매물 신고
export function* reportGood({ payload, callback }) {
  try {
    yield apis.goodsApi.reportFakeGoods({ payload });
    yield put(
      rootActions.common.alert({
        contents: '신고해 주셔서 감사합니다. 매물의 진위 여부를 확인 후 처리됩니다.',
        onConfirm: () => {
          if (callback) {
            callback(false);
          }
        },
      })
    );
  } catch (e) {
    yield put(actions.setError(e));
    yield put(
      rootActions.common.alert({
        contents: e.message,
        onConfirm: () => {
          if (callback) callback(false);
        },
      })
    );
  }
}
// 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
export function* getThisDongRealtorList({ payload }) {
  try {
    const result = yield apis.brokerApi.getThisAreaRealtorList(payload);
    yield put(actions.setThisDongRealtorList({ payload: result }));
  } catch (e) {
    console.error(e.message);
  }
}

export function* getRealtorlistOnMap({ payload, callback }) {
  try {
    if (!callback) return;
    const result = yield apis.buyingApi.getBrokersOnMap(payload);
    callback(result);
  } catch (e) {
    //
  }
}
