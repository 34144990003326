import React from 'react';
import { commonText } from '../selling_common';
import { SERVICE_AREA } from '@/resources/text/common';

export const text = {
  SELL_PHONE_MAIN_DESCRIPTION: (
    <>
      정보를 남겨주시면
      <br />
      고객센터에서 연락드려
      <br />
      집내놓기를 도와드립니다.
      <br />
      <b>무료입니다.</b>
    </>
  ),
  BANNER_TEXT: '집내놓기가 어려우시다면? 전화한통으로 신청해보세요.',
  PLACEHOLDER_NAME: '이름을 입력해주세요.',
  PLACEHOLDER_PHONE: '핸드폰번호를 입력해주세요.',
  PLACEHOLDER_OWNER_PHONE: '집주인 연락처를 입력해주세요.',
  OWNER_PHONE_DESCRIPTION: '* 집주인이 매물등록을 동의하였는지 확인하기 위해 필요합니다.',
  SELL_PHONE_AVAILABLE_AREA: `* 등록가능지역 : ${commonText.SELL_LIMIT_AREA}`,
  SELL_PHONE_CAUTION_BOTTOM: (
    <>
      * 직접 등록하시는 것보다 시간이 더 많이 소요되니 컴퓨터 사용이 익숙하신 분들은 직접 매물을 등록해 주세요.
      <br />* 허위 매물 방지를 위해 매물 소유주만 신청이 가능합니다.
    </>
  ),
};
