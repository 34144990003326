import React from 'react';

const operationalPolicy = (
  <div className="docs-policy">
    <p className="subsection-head">
      <strong>제1조 (목적)</strong>
    </p>
    <p className="subsection-body">
      본 운영정책은 주식회사 다윈프로퍼티(이하 "회사"라고 합니다)가 제공하는 서비스를 운영함에 있어 발생할 수 있는 문제 상황에 대하여 일관성 있게
      대처하기 위한 서비스 운영 기준을 규정함을 목적으로 합니다. 회사는 더 나은 서비스 제공을 위하여 언제든지 운영정책을 변경할 수 있으며, 변경되는
      내용을 이용자가 쉽게 알 수 있는 방법(홈페이지 공지 등)으로 고지합니다.
    </p>

    <p className="subsection-head">
      <strong>제2조 (신고 및 이의 제기의 방법)</strong>
    </p>
    <p className="subsection-body">
      ① 서비스 이용 중 다른 이용자의 운영정책 위반 행위로 인해 피해를 입은 경우, 이를 회사에 제보하여 운영정책의 적용을 요청할 수 있습니다.
      <br />② 회사는 신고 내용을 확인하여 운영정책에 따른 제재 조치를 취할 수 있습니다.
      <br />③ 신고가 없더라도, 회사는 관련 법령 또는 약관에 위배되거나 본 운영정책에서 금지하는 활동이 발견된 경우, 운영정책에 따른 제재 조치를 취할
      수 있습니다.
      <br />④ 이용자는 제재와 관련된 이의 제기를 할 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제3조 (계정 및 서비스 이용금지 행위)</strong>
    </p>
    <p className="subsection-body">
      ① 계정 생성과 이용 시 금지하는 행위
      <br />
      (1) 타인의 개인정보나 허위의 정보를 입력하여 계정을 생성하거나 이용하는 행위
      <br />
      (2) 상업적, 홍보, 광고 또는 악의적 목적으로 계정을 생성하거나 이용하는 행위
      <br />
      (3) 서비스의 명칭, 회사의 임직원, 운영진 또는 타인을 사칭하거나 상대방이 오인하도록 하는 계정을 생성하거나 이용하는 행위
      <br />
      (4) 시스템의 취약점을 이용하여 계정을 생성, 이용 또는 탈퇴하는 행위
      <br />
      (5) 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해 또는 파괴하거나, 할 수 있는 방법으로 서비스에 접근하거나 이용하는 행위
      <br />
      (6) 계정을 타인에게 판매, 양도 또는 대여하거나, 타인에게 그 이용을 허락 또는 이를 시도하는 행위
      <br />
      (7) 타인의 계정을 취득하기 위해 구매, 양수 또는 교환을 시도하거나 이를 타인에게 알선하는 행위
      <br />
      (8) 타인을 기망하여 타인의 계정을 탈취하는 행위
      <br />
      (9) 정상적인 서비스 볼 수 없는 다량의 계정 생성 또는 반복적인 계정 생성과 탈퇴 행위
      <br />
      <br />② 서비스 이용 시 금지하는 행위
      <br />
      (1) 악성코드, 바이러스 등의 프로그램을 설치 또는 유포하여 서비스 이용을 저해하는 행위
      <br />
      (2) 타인의 개인정보를 탈취, 유포 또는 거래하려는 행위
      <br />
      (3) 유사한 내용을 반복적으로 등록하는 행위
      <br />
      (4) 시스템의 취약점을 이용하여 콘텐츠를 등록하거나 투표에 참여하는 행위
      <br />
      (5) 회사 또는 회사의 직원을 사칭하는 행위
      <br />
      (6) 회사가 허용하지 않은 악의적인 방법으로 서비스를 이용하는 등 서비스의 정상적인 운영을 방해하는 행위
      <br />
      <br />③ 콘텐츠 작성 시 금지하는 내용
      <br />
      (1) 타인을 비방하거나 타인의 명예를 손상시키는 내용
      <br />
      (2) 개인정보를 게시하거나, 사생활을 침해하는 내용
      <br />
      (3) 이용자에게 불쾌감, 혐오감 또는 성적 수치심을 유발하는 내용
      <br />
      (4) 음란물, 욕설 등 공서양속에 위반되는 내용의 정보, 문장, 도형 등<br />
      (5) 홍보 또는 영리목적의 내용
      <br />
      (6) 범죄행위와 관련이 있다고 판단되는 내용
      <br />
      (7) 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
      <br />
      (8) 유사한 내용을 반복적으로 등록하는 내용
      <br />
      (9) 특정 중개사에게 중개의뢰를 제한하거나 제한을 유도하는 행위
      <br />
      (10) 특정 중개사에게만 중개의뢰를 하도록 유도하는 행위
      <br />
      (11) 특정 가격 이하로 중개를 의뢰하지 아니하도록 유도하는 행위
      <br />
      (12) 해당 콘텐츠의 태그, 지역, 또는 단지 정보와 직접적인 연관이 없는 내용
      <br />
      (13) 기타 관계법령에 위반되거나 회사의 운영정책에 어긋나는 내용
      <br />
      (14) 그 외 선량한 풍속, 기타 사회질서를 해하는 내용
    </p>

    <p className="subsection-head">
      <strong>제4조 (이용 제한)</strong>
    </p>
    <p className="subsection-body">
      ① 회원이 관련 법령, 이용약관, 운영정책 등을 위반하는 경우에 회사는 회원의 계정과 서비스 이용을 제한할 수 있습니다.
      <br />② 이용 제한은 위반 활동의 누적 정도에 따라 한시적 제한에서 영구적 제한으로 단계적 제한하는 것을 원칙으로 합니다. 다만, 명백한 불법행위나
      긴급한 위험 또는 피해 차단이 요구되는 경우에는 즉시 영구적으로 이용이 제한될 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제5조 (제재 기준)</strong>
    </p>
    <p className="subsection-body">
      ① 이용자의 운영 정책 위반 횟수에 따라 다음의 기간 동안 콘텐츠 작성을 금지합니다.
      <br />
      (1) 10회 이상이 된 때 : 1일간
      <br />
      (2) 20회 이상이 된 때 : 3일간
      <br />
      (3) 30회 이상이 된 때 : 7일간
      <br />
      (4) 30회 초과한 경우 매 5회 위반 시마다 : 14일
      <br />
      <br />② 전항에도 불구하고 운영정책의 위반사항이 심각한 경우 컨텐츠 작성 영구 금지, 이용계약의 해지 등 별도의 제재를 적용할 수 있습니다.
    </p>
  </div>
);

export default operationalPolicy;
