import React from 'react';
import { call, put } from 'redux-saga/effects';
import * as actions from './actions';
import { store } from '@/data/configureStore';
import { actions as rootActions } from '@/data';
import apis from '@/apis';
import { STATUS } from '../utils';
import { requestPayment } from '@/services/payment';
import { decode } from 'punycode';
import { Button } from '@material-ui/core';

// [GET] 나의 실무교육 신청 내역
export function* getEnrollments({ setShowRegisterPopup, setShowPaymentPopup, setShowChangeCancelPopup, setEnrollmentData }) {
  const { dispatch } = store;

  try {
    const { data: enrollmentList } = yield call(apis.academyApi.getEnrollments);
    const currentCourse = enrollmentList?.at(-1);

    setEnrollmentData(enrollmentList);

    // 신청 내역이 아예 없을 경우 또는 마지막 이력이 환불인 경우
    if (enrollmentList?.length === 0 || currentCourse?.status === 'REFUNDED') {
      setShowRegisterPopup && setShowRegisterPopup(true);
    } else {
      if (currentCourse?.status === 'PAID') {
        // 신청후 결제까지 완료한 상태
        setShowChangeCancelPopup && setShowChangeCancelPopup(true);
      } else if (currentCourse?.status === 'ENROLLED') {
        if (currentCourse?.course?.enroll?.endAt < Date.now()) {
          setShowRegisterPopup && setShowRegisterPopup(true);
        } else {
          // 신청은 했지만 아직 결제가 필요한 상태
          setShowPaymentPopup && setShowPaymentPopup(true);
        }
      }
    }
  } catch (e) {
    yield put(
      rootActions.common.alert({
        contents: '정보를 불러오는데 오류가 있습니다. 잠시 후에 다시 시도해주세요.',
        onConfirm: () => {
          dispatch(rootActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
  }
}

// [POST] 실무교육 신청
export function* applyAcademy({ data, setLoadingStatus, setEnrollmentData, callback }) {
  const { dispatch } = store;

  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);

    yield call(apis.academyApi.applyAcademy, {
      courseId: data?.eduDate?.id,
    });

    yield put(actions.getEnrollments({ setEnrollmentData }));

    callback && callback();

    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);

    const routePage = () => {
      dispatch(rootActions.router.replace('/agentSignUp/academy'));
    };

    if (e.message === 'Not valid token') {
      yield put(
        rootActions.common.alert({
          contents: (
            <>
              아카데미 수강 신청 중 오류가 발생했습니다.
              <br /> 로그아웃 후 다시 신청해 주세요.
            </>
          ),
          onConfirm: routePage,
        })
      );
    } else {
      yield put(
        rootActions.common.alert({
          contents: '아카데미 수강 신청 중 오류가 발생했습니다.',
          onConfirm: routePage,
        })
      );
    }
  }
}

// [POST] 실무교육 결제하기 STEP1
export function* payAcademyFeeStepOne({ currentEnrollment, setLoadingStatus }) {
  const { dispatch } = store;

  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    const billData = yield call(apis.academyApi.payAcademyFeeStepOne, {
      enrollmentId: currentEnrollment?.id,
    });

    if (billData) {
      requestPayment({
        amount: currentEnrollment?.course?.price,
        orderName: '다윈중개 중개사 아카데미 교육',
        customerName: currentEnrollment?.user?.name,
        customerEmail: '',
        billId: billData.id,
      });
    }
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    yield put(
      rootActions.common.alert({
        contents: '아카데미 수강 신청 중 오류가 발생했습니다. 잠시후에 다시 시도해주세요.',
        onConfirm: () => {
          dispatch(rootActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
  }
}

// [POST] 실무교육 결제하기 STEP2
export function* payAcademyFeeStepTwo({ billId, paymentKey, redirectUrl }) {
  const { dispatch } = store;

  try {
    const result = yield call(apis.academyApi.payAcademyFeeStepTwo, {
      billId,
      providerKey: paymentKey,
    });

    if (result) {
      yield put(rootActions.router.push(redirectUrl ? redirectUrl : '/agentSignUp/academy'));
      yield put(
        rootActions.common.alert({
          // contents: (
          //   <>
          //     <div className="board-head">
          //       <p className="board-subject">
          //         <span className="board-name">실무교육 아카데미 결제 완료</span>
          //       </p>
          //     </div>
          //     <div className="board-body">
          //       <p className="para">
          //         <span className="wbr">실무교육 아카데미 결제가 완료되었습니다.</span>
          //         <span className="wbr">교육 일주일 전 SMS로 안내드리겠습니다.</span>
          //       </p>
          //     </div>
          //   </>
          // ),
          contents: (
            <>
              <div className="board-head">
                <p className="board-subject">
                  <span className="board-name">신청해 주셔서 감사합니다.</span>
                </p>
              </div>
              <div className="board-body">
                <p className="para">
                  <span className="wbr">다윈중개 홈페이지 및 외부 광고는 3일 이내에 게시됩니다</span>
                  <span className="wbr">매물 방문상담을 원하시면 채널톡으로 문의해주세요.</span>
                  {/* <Button variant="contained" onClick={rootActions.router.push(redirectUrl)}>
                    내 매물 보기
                  </Button>
                  <Button variant="contained" onClick={rootActions.router.push('/')}>
                    다윈중개 홈
                  </Button> */}
                </p>
              </div>
            </>
          ),
        })
      );
    }
  } catch (e) {
    yield put(
      rootActions.common.alert({
        contents: '결제요청 중 오류가 발생했습니다. 잠시후에 다시 시도해 주세요.',
        onConfirm: () => {
          dispatch(rootActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
  }
}

// [PUT] 실무교육 일정 변경
export function* editCourse({ currentEnrollment, selectedCourse, callback }) {
  try {
    const { id: enrollmentId } = currentEnrollment || {};
    const { id: courseId } = selectedCourse?.eduDate || {};

    yield call(apis.academyApi.editAcademy, { enrollmentId, courseId });

    yield put(
      rootActions.common.alert({
        contents: (
          <>
            <div className="board-head">
              <h2 className="board-subject">
                <span className="board-name">
                  실무교육 아카데미<span className="wbr">일정 변경 완료</span>
                </span>
              </h2>
            </div>
            <div className="board-body">
              <p className="para">실무교육 아카데미 교육일정 변경이 완료되었습니다. 교육 일주일 전 SMS로 안내드리겠습니다.</p>
            </div>
          </>
        ),
        onConfirm: () => {
          callback && callback();
        },
      })
    );
  } catch (e) {
    const errorMessage =
      e.message === '/api-core/failed-to-exchange-enrollment-error'
        ? '* 현재 해당 신청은 일정을 변경할 수 없는 상태입니다.'
        : '잠시후에 다시 시도해주세요.';

    yield put(
      rootActions.common.alert({
        contents: `아카데미 일정 변경 중 오류가 발생했습니다. ${errorMessage}`,
        onConfirm: () => {
          callback && callback();
        },
      })
    );
  }
}

// [POST] 실무교육 취소
export function* cancelCourse({ currentCourse, callback }) {
  const { dispatch } = store;

  try {
    yield put(rootActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));

    const billList = yield call(apis.academyApi.getBill, { enrollmentId: currentCourse.id });

    if (billList) {
      const foundBill = billList?.find((bill) => currentCourse?.id === bill.order.enrollmentId);
      const billId = foundBill ? foundBill.id : undefined;

      yield call(apis.academyApi.cancelAcademy, { billId });

      yield put(rootActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));

      yield put(
        rootActions.common.alert({
          contents: (
            <>
              <div className="board-head">
                <h2 className="board-subject">
                  <span className="board-name">실무교육 아카데미 취소 완료</span>
                </h2>
              </div>
              <div className="board-body">
                <p className="para">
                  신청하신 교육이 취소되었습니다. 결제 금액은 영업일 기준 3~5일 이내 환불될 예정이며 자세한 내용은 카드사로 문의 부탁드립니다.
                </p>
              </div>
            </>
          ),
          onConfirm: () => {
            callback && callback();
          },
        })
      );
    }
  } catch (e) {
    yield put(rootActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));

    if (e.message)
      yield put(
        rootActions.common.alert({
          contents: '아카데미 일정 취소 중 오류가 발생했습니다. 잠시후에 다시 시도해주세요.',
          onConfirm: () => {
            dispatch(rootActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
  }
}

// [POST] 실무교육 신청 로깅
export function* applyAcademyLog() {
  try {
    yield call(apis.academyApi.applyAcademyLog);
  } catch (e) {
    console.log(e);
  }
}
