import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import * as selectors from '@/data/rootSelectors';
import { api } from '@/apis/community';
import { STATUS } from '@/data/utils';
import useFeedId from '@/combinedComp/Community/DetailNew/hooks/useFeedId';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import useGetBrokerStatus from '@/pages/Community/hooks/useGetBrokerStatus';

const ReplyInput = ({
  commentId,
  replyId,
  setActiveComment,
  setComment,
  replies,
  setReplies,
  isUpdate,
  contents,
  isOnReply,
  addReplyCount,
  setOnReply,
  cancelUpdateComment,
  onUpdateComment,
}) => {
  // const closeReply = () => {
  //   contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   contentRef.current.focus();
  //   showReplies(false);
  //   onReply(false);
  // };

  const dispatch = useDispatch();
  const [text, setText] = useState(contents ? contents : '');
  const nickname = useSelector(selectors.auth.getNickname);
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const inputRef = useRef(null);
  const feedId = useFeedId();
  const [status, setStatus] = useState(STATUS.NOTASKED);
  const isMobile = useSelector(selectors.common.isMobile);
  const isLoading = status === 'LOADING';

  useEffect(() => {
    if (isUpdate) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [isOnReply]);

  const replyHandler = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      if (isUpdate) {
        onUpdateReplyComment(e);
      } else {
        onCreateReplyComment(e);
      }
    }
  };

  const onCreateReplyComment = async (e) => {
    setStatus(STATUS.LOADING);
    e.preventDefault();
    if (!userInfo) {
      dispatch(actions.common.pleaseLoginPopup(true));
    } else if (text) {
      if (nickname || userInfo?.mbr_type === 'BU') {
        const created = await api.createComment({
          feedId: feedId,
          commentId: commentId ? commentId : replyId,
          contents: text,
        });
        setReplies({
          data: replies ? replies.data.concat(created) : [created],
          next: replies ? replies.next : '',
        });
        setText('');
        addReplyCount();
        // setOnReply(false);
      } else {
        dispatch(
          actions.common.alert({
            contents: '닉네임을 설정해주세요.',
          })
        );
      }
    } else {
      dispatch(
        actions.common.alert({
          contents: '내용을 입력해주세요.',
        })
      );
    }
    setStatus(STATUS.SUCCESS);
  };

  const onUpdateReplyComment = async (e) => {
    setStatus(STATUS.LOADING);
    e.preventDefault();

    if (text) {
      let body = {
        contents: text,
      };
      // reply: 대댓글 id가 prop으로 내려오면 해당 reply업뎃 아니면  comment(댓글) 업뎃
      const updated = await api.modifyComment(replyId ? replyId : commentId, body);
      if (replies) {
        const res = replies.data.map((reply) => (reply.id == updated.id ? updated : reply));
        replies.data = res;
        setReplies(replies);
        setActiveComment('');
      } else {
        setComment(updated);
        setActiveComment('');
      }
    } else {
      dispatch(
        actions.common.alert({
          contents: '변경할 내용을 입력해주세요.',
        })
      );
    }
    setStatus(STATUS.SUCCESS);
  };

  const isBroker = userInfo?.mbr_type === 'BU';
  const query = parseCallBack();
  query.nicknameSetting = 'community';

  const submit = (e) => {
    e.preventDefault();

    if (!isBroker && !nickname) {
      dispatch(actions.router.push(`${location.pathname}${objectToQuerystring(query)}`));
    } else {
      if (isUpdate) {
        onUpdateReplyComment(e);
      } else {
        onCreateReplyComment(e);
      }
    }
  };

  const showNicknamePopup = () => {
    if (!isBroker && !nickname) {
      dispatch(actions.router.push(`${location.pathname}${objectToQuerystring(query)}`));
    }
  };

  const cancel = (e) => {
    e.preventDefault();
    setShowBtns(false);
    // setOnReply(false);
    cancelUpdateComment('');
  };

  // 승인보류중인 중개사들 글쓰기 막기
  const [showBtns, setShowBtns] = useState(false);
  const [textareaDisabled, setTextareaDisabled] = useState(false);

  // 중개사 승인 상태 가져오기
  const { approval_status } = useGetBrokerStatus({ isBroker });

  const handleFocus = () => {
    if (isBroker) {
      if (approval_status === 'PS1' || approval_status === 'PS2') {
        dispatch(
          actions.common.alert({
            contents: '중개사 승인 후 작성가능합니다.',
          })
        );
        setTextareaDisabled(true);
        return;
      } else if (approval_status === 'CN' || approval_status === 'CA') {
        dispatch(
          actions.common.alert({
            contents: '중개사 정보 변경 승인 후 작성 가능합니다.',
          })
        );
        setTextareaDisabled(true);
        return;
      }
    }
    showNicknamePopup();
    setShowBtns(true);
  };

  return (
    <form className="reply-write">
      <fieldset className="submit-form">
        <legend>답글 입력 서식</legend>
        <div className="form-list">
          <div className="form-item">
            <div className="form-wrap">
              <div className="form-head">
                <span className="form-name">답글 내용</span>
              </div>
              <div className="form-body">
                <div className="form-area">
                  <span className="form textarea module-b style-b type-line normal-04 large-1x flex" data-bui-form-value="test">
                    <textarea
                      ref={inputRef}
                      value={text}
                      onChange={replyHandler}
                      maxLength={1000}
                      onKeyPress={onPressEnter}
                      className="form-elem"
                      rows="1"
                      placeholder="내용을 입력해주세요"
                      // onFocus={() => showNicknamePopup()}
                      onFocus={handleFocus}
                      // onClick={() => setShowBtns(true)}
                      disabled={textareaDisabled}></textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showBtns ? (
          <div className="form-util">
            <div className="button-display">
              <span className="button-area">
                <button className="btn module-b style-b type-line normal-04 medium" type="button" onClick={cancel}>
                  <span className="btn-text">취소</span>
                </button>
                <button className="btn module-b style-b type-fill normal-03 medium" type="submit" onClick={submit}>
                  <span className="btn-text">등록</span>
                </button>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </fieldset>
    </form>
  );
};

export default ReplyInput;
