import React from 'react';

const brokerageCommisionRate = (
  <>
    {/* <!-- info-board --> */}
    <div className="info-board module-a style-a type-a">
      <div className="board-wrap">
        <div className="board-body">
          <p className="para">
            <span className="wbr">다윈중개의</span>
            <span className="wbr">
              매도인(임대인) <strong className="em accent-01">중개수수료는 0원</strong>입니다.
            </span>
            <span className="wbr">매수인(임차인) 중개수수료는 아래 표에서 확인해주세요.</span>
          </p>
          <p className="para">
            <span className="wbr">
              공동중개요율은 매도(임대)인이 집내놓기시 공동중개를 허용했을때만 발생하는 요율이며 매도(임대)인에게만 적용됩니다.
            </span>
          </p>
        </div>
      </div>
    </div>
    {/* <!-- //info-board --> */}
    <div className="docs-policy">
      <p className="subsection-head">
        <strong>1. 주택</strong>
      </p>
      <p className="subsection-body">[매매/교환]</p>
      <div className="data-table module-a style-b type-c small-2x">
        <table>
          <caption>주택 매매/교환 중개수수료율 표</caption>
          <colgroup>
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                거래금액
                <wbr />
                (매매/교환)
              </th>
              <th>
                법정
                <wbr />
                상한요율
              </th>
              <th>
                다윈
                <wbr />
                요율
              </th>
              <th>
                중개
                <wbr />
                보수금액
              </th>
              <th>
                공동
                <wbr />
                중개요율
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5천만원 미만</td>
              <td>0.6</td>
              <td>0.6</td>
              <td>30만원 이하</td>
              <td>0.4</td>
            </tr>
            <tr>
              <td>5천만 ~ 2억 미만</td>
              <td>0.5</td>
              <td>0.5</td>
              <td>25 ~ 100만원</td>
              <td>0.3</td>
            </tr>
            <tr>
              <td>2억 ~ 6억 미만</td>
              <td>0.4</td>
              <td>0.3</td>
              <td>60 ~ 180만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>6억 ~ 9억 미만</td>
              <td>0.4</td>
              <td>0.3</td>
              <td>180 ~ 270만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>9억 ~ 12억 미만</td>
              <td>0.5</td>
              <td>0.35</td>
              <td>315 ~ 420만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>12 ~ 15억 미만</td>
              <td>0.6</td>
              <td>0.35</td>
              <td>420 ~ 525만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>15억 이상</td>
              <td>0.7</td>
              <td>0.35</td>
              <td>525만원 이상</td>
              <td>0.2</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="subsection-body">[전/월세]</p>
      <div className="data-table module-a style-b type-c small-2x">
        <table>
          <caption>주택 전/월세 중개수수료율 표</caption>
          <colgroup>
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                거래금액
                <wbr />
                (전/월세)
              </th>
              <th>
                법정
                <wbr />
                상한요율
              </th>
              <th>
                다윈
                <wbr />
                요율
              </th>
              <th>
                중개
                <wbr />
                보수금액
              </th>
              <th>
                공동
                <wbr />
                중개요율
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5천만원 미만</td>
              <td>0.5</td>
              <td>0.5</td>
              <td>25만원 이하</td>
              <td>0.3</td>
            </tr>
            <tr>
              <td>5천만 ~ 1억 미만</td>
              <td>0.4</td>
              <td>0.4</td>
              <td>20 ~ 40만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>1억 ~ 3억 미만</td>
              <td>0.3</td>
              <td>0.3</td>
              <td>30 ~ 90만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>3억 ~ 6억 미만</td>
              <td>0.3</td>
              <td>0.3</td>
              <td>90 ~ 180만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>6억 ~ 9억 미만</td>
              <td>0.4</td>
              <td>0.3</td>
              <td>180 ~ 270만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>9억 ~ 12억 미만</td>
              <td>0.4</td>
              <td>0.3</td>
              <td>270 ~ 360만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>12억 ~ 15억 미만</td>
              <td>0.5</td>
              <td>0.3</td>
              <td>360 ~ 450만원</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>15억 이상</td>
              <td>0.6</td>
              <td>0.3</td>
              <td>450만원 이상</td>
              <td>0.2</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="subsection-head">
        <strong>2. 주거용 오피스텔 전용면적 85㎡(32평) 이하</strong>
      </p>
      <p className="subsection-body">[매매]</p>
      <div className="data-table module-a style-b type-c small-2x">
        <table>
          <caption>주거용 오피스텔 매매 중개수수료율 표</caption>
          <colgroup>
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                거래금액
                <wbr />
                (매매)
              </th>
              <th>
                법정
                <wbr />
                상한요율
              </th>
              <th>
                다윈
                <wbr />
                요율
              </th>
              <th>
                중개
                <wbr />
                보수금액
              </th>
              <th>
                공동
                <wbr />
                중개요율
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1억 미만</td>
              <td>0.5</td>
              <td>0.4</td>
              <td>40만원 이하</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>1억 이상</td>
              <td>0.5</td>
              <td>0.3</td>
              <td>30만원 이상</td>
              <td>0.2</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="subsection-body">[임대차]</p>
      <div className="data-table module-a style-b type-c small-2x">
        <table>
          <caption>주거용 오피스텔 임대차 중개수수료율 표</caption>
          <colgroup>
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                거래금액
                <wbr />
                (임대차)
              </th>
              <th>
                법정
                <wbr />
                상한요율
              </th>
              <th>
                다윈
                <wbr />
                요율
              </th>
              <th>
                중개
                <wbr />
                보수금액
              </th>
              <th>
                공동
                <wbr />
                중개요율
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1억 미만</td>
              <td>0.4</td>
              <td>0.3</td>
              <td>30만원 이하</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>1억 이상</td>
              <td>0.4</td>
              <td>0.25</td>
              <td>25만원 이상</td>
              <td>0.2</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="subsection-head">
        <strong>3. 비주거용 오피스텔, 상가/사무실 + 주거용 오피스텔 전용면적 85㎡(32평) 초과</strong>
      </p>
      <p className="subsection-body">[매매/임대차]</p>
      <div className="data-table module-a style-b type-c small-2x">
        <table>
          <caption>비주거용 오피스텔 주거용 오피스텔 매매/임대차 중개수수료율 표</caption>
          <colgroup>
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '20%' }} />
            <col scope="col" style={{ width: '22.5%' }} />
            <col scope="col" style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                거래금액
                <wbr />
                (매매/전세)
              </th>
              <th>
                법정
                <wbr />
                상한요율
              </th>
              <th>
                다윈
                <wbr />
                요율
              </th>
              <th>
                중개
                <wbr />
                보수금액
              </th>
              <th>
                공동
                <wbr />
                중개요율
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3천만원 미만</td>
              <td>0.9</td>
              <td>0.9</td>
              <td>27만원 이하</td>
              <td>0.5</td>
            </tr>
            <tr>
              <td>3천 ~ 1억 미만</td>
              <td>0.9</td>
              <td>0.6</td>
              <td>18 ~ 60만원</td>
              <td>0.4</td>
            </tr>
            <tr>
              <td>1억 이상</td>
              <td>0.9</td>
              <td>0.45</td>
              <td>45만원 이상</td>
              <td>0.2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
);

export default brokerageCommisionRate;
