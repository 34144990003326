import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import useSocialList from './useSocialList';
import useGetMyMembership from './useGetMyMembership';
import useGetBrokerRegions from './useGetBrokerRegions';
// import useMemoFirstPath from './useMemoFirstPath';

// App.js에서 호출
// 사이트 로딩시 실행해야 하는 action들
const useUser = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const user = useSelector(selectors.auth.getUserInfo);

  // (앱) 첫 랜딩 주소 저장: actionAfterLogin 사용
  // useMemoFirstPath();

  // 소셜 연동 정보 보회
  useSocialList({ dispatch, actions, user });

  // 그 외.
  useEffect(() => {
    if (isLoggedIn) {
      // dispatch(getSummary());

      // 닉네임 조회
      dispatch(actions.auth.getNickname());
      // 미확인 알림 개수 조회
      dispatch(actions.my.getUnreadNotiCount());
    }
  }, [isLoggedIn]);

  // 내 멤버쉽 조회 (중개사)
  useGetMyMembership({ user });

  // 내 중개 지역 조회 (중개사)
  useGetBrokerRegions({ user });
};

export default useUser;
