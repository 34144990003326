import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const MG_QA = { page: 1, total: 40, category: '', keyword: '', tradetype: '' };

export default function mgQa(state = MG_QA, action = {}) {
  switch (action.type) {
    case AT.GET_MG_QA_LIST: {
      return {
        ...state,
      };
    }
    case success(AT.GET_MG_QA_LIST): {
      return { ...state, qaList: action.qaList };
    }
    case failure(AT.GET_MG_QA_LIST):
      return {
        ...state,
        [AT.GET_MG_QA_LIST]: Remote.Failure(action.error),
        error: action.error,
      };

    case AT.GET_QA_DETAIL: {
      return {
        ...state,
        goods_idx: action.goods_idx,
      };
    }
    case success(AT.GET_QA_DETAIL):
      return { ...state, qaDetail: action.qaDetailList };
    case failure(AT.GET_QA_DETAIL):
      return {
        ...state,
        [AT.GET_QA_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };
    case AT.REG_QA_ANSWER: {
      return {
        ...state,
        qaIdx: action.qaIdx,
        val: action.val,
        goods_idx: action.goods_idx,
      };
    }
    case AT.EDIT_QA_ANSWER: {
      return {
        ...state,
        qaAnidx: action.qaAnidx,
        val: action.val,
        goods_idx: action.goods_idx,
      };
    }
    case AT.DEL_QA_ANSWER: {
      return {
        ...state,
        qaAnidx: action.qaAnidx,
        goods_idx: action.goods_idx,
      };
    }
    case AT.SET_QA_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.SET_QA_TOTAL_PAGE: {
      return {
        ...state,
        total: action.cnt,
      };
    }
    case AT.SET_QA_TRADE_TYPE: {
      return {
        ...state,
        tradetype: action.val,
      };
    }
    case AT.SET_QA_KEYWORD: {
      return {
        ...state,
        keyword: action.val,
      };
    }
    case AT.SET_QA_CATEGORY: {
      return {
        ...state,
        category: action.val,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
