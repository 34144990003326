import React from 'react';
import TradeTypeItem from './TradeTypeItem';

const TradeType = ({ tags, setTags }) => {
  const types = ['s', 'c', 'm'];

  return (
    <>
      <div className="form-item">
        <div className="form-wrap">
          <div className="form-head">
            <label className="form-name" htmlFor="#">
              거래유형
            </label>
            <p className="form-summary">복수 선택 가능합니다.</p>
          </div>
          <div className="form-body">
            <div className="form-area">
              <div className="option-list module-b style-a type-a">
                {types?.map((type, index) => (
                  <>
                    <TradeTypeItem type={type} tags={tags} setTags={setTags} key={index} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeType;
