import React from 'react';

const Email = () => {
  return (
    <section id="container" className="container">
      <div className="sub_container">
        <div className="cont_wrap">
          <div className="vt-area">
            <div className="ntc_txt">
              <p className="ntc_p v_nt">
                기존 다윈중개 회원가입 이력이 확인되어
                <br />
                해당 이메일로 인증메일을 발송했습니다.
                <br />
                <br />
                <big>
                  메일을 확인하신 후<br />
                  인증해주세요.
                </big>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Email;
