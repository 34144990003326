import React, { useState, useRef, useEffect } from 'react';
import TEXT from '@/combinedComp/Community/Create/components/DefaultForm/text';
import { TagType } from '@/services/Community';

const HashTagInput = ({ feedType, addedTags, isUpdate, updateFeedDetail }) => {
  const [value, setValue] = useState(addedTags?.current?.length > 0 ? addedTags?.current?.join(' ') : '#');
  const isDeleting = useRef(false);

  useEffect(() => {
    if (isUpdate && updateFeedDetail?.tags?.length > 0) {
      const hash = updateFeedDetail.tags.filter((tag) => tag.type === TagType.HASH_TAG);
      if (hash?.length > 0) {
        const hashToString = updateFeedDetail?.tags
          ?.filter((item) => item.type === TagType.HASH_TAG)
          .map((item) => `#${item.name}`)
          .join(' ');
        setValue(`${hashToString} #`);
      }
    }
  }, [isUpdate, updateFeedDetail]);

  const handleChange = (e) => {
    const regex_blank = new RegExp(/\s/);

    let nextValue = e.target.value; //.replace(/'##'/gi, '#');
    const last = nextValue.slice(-1);
    const lastTwo = nextValue.slice(-2);

    if (!isDeleting.current) {
      if (regex_blank.test(last)) {
        if (lastTwo === '  ') {
          nextValue = `${nextValue.substring(0, nextValue.length - 2)} #`;
        } else {
          nextValue = `${nextValue.substring(0, nextValue.length - 1)} #`;
        }
      }
    } else {
      if (regex_blank.test(last)) {
        nextValue = nextValue.substring(0, nextValue.length - 1);
      }
    }

    // "# " 인 경우
    if (lastTwo.indexOf('#') === 0 && regex_blank.test(last)) {
      nextValue = nextValue.substring(0, nextValue.length - 2);
    }

    // 첫 문자 # 만 남는 경우
    if (nextValue === '') {
      nextValue = '#';
    }

    addedTags.current = nextValue.split(' ');
    setValue(nextValue);
  };

  const checkKey = (e) => {
    var key = e.keyCode || e.charCode;

    // backspace 또는 delete인지 확인
    // (한글 keyCode는 무조건 229를 반환하는 버그 있음.
    //  그래서 삭제 중에도 한글을 삭제한 경우는 229를 반환함.
    //  그러다가 다행히 #을 만나면 backspace keyCode 8을 제대로 반환한다.)

    if (key === 8 || key == 46) {
      isDeleting.current = true;
    } else {
      isDeleting.current = false;
    }
  };
  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="#">
            {/* TODO: @커뮤니티테스트 */}
            {/* 추가 해시태그 */}
            해시태그
          </label>
          <p className="form-summary">추가하고 싶은 문구가 있다면 입력해주세요. (선택항목)</p>
        </div>
        <div className="form-body">
          <div className="form-area">
            <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
              <input
                className="form-elem"
                type="text"
                placeholder={TEXT[feedType]?.hashtag?.placeholder || ''}
                style={{ imeMode: 'active' }} // 기본 한글
                value={value}
                onChange={handleChange}
                onKeyDown={checkKey}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HashTagInput;
