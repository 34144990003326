import * as AT from '@/data/personalize/actionTypes';

export const getPersonalSummary = () => ({
  type: AT.GET_PERSONAL_SUMMARY,
});

export const setPersonalSummary = (data) => ({
  type: AT.SET_PERSONAL_SUMMARY,
  data,
});

/**
 * @param {Function} setData 데이터 setter
 * @param {String} bdongCode 법정동코드
 * @param {Number} goodsCnt 포함할 매물 개수
 * @param {Number} recentTradeCnt 포함할 최근 실거래 개수
 */
export const getInterestComplex = ({ setData, dongCode, goods, recentTrade }) => ({
  type: AT.GET_INTEREST_COMPLEX,
  setData,
  dongCode,
  goods,
  recentTrade,
});

export const setInterestComplex = (data) => ({
  type: AT.SET_INTEREST_COMPLEX,
  data,
});

export const getMyHome = () => ({
  type: AT.GET_MY_HOME,
});

export const setMyHome = (data) => ({
  type: AT.SET_MY_HOME,
  data,
});
