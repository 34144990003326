import { actions } from '@/data';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useGetBrokerRegions = ({ user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isBroker = user?.mbr_type === 'BU';

    if (isBroker) {
      dispatch(actions.brokerPage.setArea.getBrokerDistrict());
    }
  }, [user]);

  return;
};

export default useGetBrokerRegions;
