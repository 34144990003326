import React, { useMemo } from 'react';
import { FeedTypeKor, HUNDRED_MILLION, TagType } from '@/services/Community';

const priceToText = (price) => {
  return price ? price / HUNDRED_MILLION + '억' : '';
};
const HashTagFixed = ({ tags, feedType, numericTags }) => {
  const value = useMemo(() => {
    const feedTypeKor = FeedTypeKor[feedType] && `#${FeedTypeKor[feedType]}`;

    const address = tags
      .filter((item) => !!item.name && item.type === TagType.ADDRESS_TAG)
      .map((item) => `#${item.name}`)
      .join(' ');

    const goodsTypes = tags
      .filter((item) => !!item.name && item.type === TagType.GOODS_TYPE_TAG)
      .map((item) => `#${item.name}`)
      .join(' ');

    const realtyTypes = tags
      .filter((item) => !!item.name && item.type === TagType.SALE_TYPE_TAG)
      .map((item) => `#${item.name}`)
      .join(' ');

    const coplex = tags
      .filter((item) => !!item.name && item.type === TagType.COMPLEX_TAG)
      .map((item) => `#${item.name}`)
      .join(' ');

    const priceMin = `${priceToText(numericTags?.price?.min)}`;
    const priceMax = `${priceToText(numericTags?.price?.max)}`;
    const priceTag = `${numericTags?.price?.min === numericTags?.price?.max ? priceMax : priceMin + '~' + priceMax}`;

    const areaMin = `${numericTags?.area?.min ? numericTags?.area?.min + '평' : ''}`;
    const areaMax = `${numericTags?.area?.max ? numericTags?.area?.max + '평' : ''}`;
    const areaTag = `${numericTags?.area?.min === numericTags?.area?.max ? areaMax : areaMin + '~' + areaMax}`;

    const numerics = [];

    priceTag && numerics.push(priceTag);
    areaTag && numerics.push(areaTag);
    const numericString = numerics.map((item) => `#${item}`).join(' ');

    return `${feedTypeKor} ${address} ${goodsTypes} ${realtyTypes} ${coplex} ${numericString}`;
  }, [tags, feedType, numericTags]);

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="#">
            고정 해시태그
          </label>
          <p className="form-summary">선택하신 내용에 따라 자동으로 생성됩니다.</p>
        </div>
        <div className="form-body">
          <div className="form-area">
            <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
              <input
                className="form-elem"
                type="text"
                readOnly="readonly"
                value={value}
                // 이전에 있던 스타일
                //  style={{ wordBreak: 'keep-all' }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HashTagFixed;
