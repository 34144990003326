import * as AT from './actionTypes';
// import { failure, loading, success } from '@/data/utils';

export const search = () => ({
  type: AT.COMMUNITY_SEARCH,
});

export const showCreateFeed = (show) => ({
  type: AT.SHOW_CREATE_FEED,
  show,
});

export const changeCreatePoint = (point) => ({
  type: AT.CHANGE_CREATE_POINT,
  point,
});

export const createFeed = (payload, setStatus, location, e) => ({
  type: AT.CREATE_FEED,
  payload,
  setStatus,
  location,
  e,
});
export const editFeed = (feedId, payload, setStatus) => ({
  type: AT.EDIT_FEED,
  feedId,
  payload,
  setStatus,
});
export const deleteFeed = (feedId, setStatus) => ({
  type: AT.DELETE_FEED,
  feedId,
  setStatus,
});

export const setCreatedFeed = (createdFeed) => ({
  type: AT.SET_CREATED_FEED,
  createdFeed,
});

export const setDeletedFeeds = (deletedFeeds) => ({
  type: AT.SET_DELETED_FEEDS,
  deletedFeeds,
});

export const setFeedDetailItem = (detail_item) => ({
  type: AT.SET_FEED_DETAIL_ITEM,
  detail_item,
});

export const getFeeds = (payload, feeds, setFeeds, setNext) => ({
  type: AT.GET_COMMUNITY_FEEDS,
  payload,
  feeds,
  setFeeds,
  setNext,
});

//feed list에서 좋아요 버튼
export const like = (feedId, reactionType, setStatus) => ({
  type: AT.FEED_LIKE,
  feedId,
  reactionType,
  setStatus,
});
export const commentLike = (commentId, reactionType, setStatus) => ({
  type: AT.COMMENT_LIKE,
  commentId,
  reactionType,
  setStatus,
});
//신고하기
export const reports = (payload) => ({
  type: AT.FEED_REPORT,
  payload,
});

//tag 검색 //기존 common.searchKeyword 참조
export const searchTag = ({ keyword, totalSearch }) => ({
  type: AT.SEARCH_TAGS,
  keyword,
  totalSearch,
});
export const searchTagLoading = (data) => ({
  type: AT.SEARCH_TAGS_LOADING,
  data,
});

export const searchTagSuccess = (data) => ({
  type: AT.SEARCH_TAGS_SUCCESS,
  data,
});

export const searchTagFailure = (error) => ({
  type: AT.SEARCH_TAGS_FAILURE,
  error,
});

export const getFeed = (feedId, setFeed) => ({
  type: AT.GET_COMMUNITY_FEED,
  feedId,
  setFeed,
});

export const setFeed = (data) => ({
  type: AT.SET_COMMUNITY_FEED,
  data,
});

export const getPhoneNumber = (data, callback) => ({
  type: AT.GET_PHONE_NUMBER,
  data,
  callback,
});

export const setPhoneNumber = (data) => ({
  type: AT.SET_PHONE_NUMBER,
  data,
});

export const getComments = (payload, comments) => ({
  type: AT.GET_COMMENTS,
  payload,
  comments,
});

export const setComments = (data) => ({
  type: AT.SET_COMMENTS,
  data,
});

export const createComment = (payload, comments) => ({
  type: AT.CREATE_COMMENT,
  payload,
  comments,
});

// 글 작성 후 리스트 재조회
export const fireReload = (reload) => ({
  type: AT.FIRE_RELOAD,
  reload,
});

export const setUserHistoryTarget = (payload) => ({
  type: AT.SET_USER_HISTORY_TARGET,
  payload,
});

export const changeRegionCode = (payload) => ({
  type: AT.COMMUNITY_CHANGE_REGION_CODE,
  payload,
});

//test
// export const replyMode = (payload) => ({
//   type: AT.REPLY_MODE,
//   payload,
// });

export const setFeedStatus = (payload) => ({
  type: AT.SET_FEED_STATUS,
  payload,
});

// 댓글 생성 후 로딩바
export const setCommentStatus = (payload) => ({
  type: AT.SET_COMMENT_STATUS,
  payload,
});

// 댓글 더보기 버튼 클릭 후 로딩 바
export const setMoreCommentStatus = (payload) => ({
  type: AT.SET_MORE_COMMENT_STATUS,
  payload,
});

export const setLocalComplexTag = (payload) => ({
  type: AT.SET_LOCAL_COMPLEX_TAG,
  payload,
});
