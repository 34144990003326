export type ColorHex = `#${string}`

type ColorCodes = {
    base: '100' | '090'| '080'| '070'| '060'| '050'| '040'| '030'| '020'| '010'| '005'| '000';
    primary: '100'| '090'| '080'| '070'| '060'| '050'| '040'| '030'| '020'| '015'| '010'| '005'| '000';
    secondary: '100'| '090'| '080'| '070'| '060'| '050'| '040'| '030'| '020'| '010'| '005'| '000';
    tertiary: '100'| '090'| '080'| '070'| '060'| '050'| '040'| '030'| '020'| '010'| '005'| '000';
    fourth: '100'| '090'| '080'| '070'| '060'| '050'| '040'| '030'| '020'| '010'| '005'| '000';
    etc: '010'| '020'| '030'| '040'| '050'| '060'| '070';
    normal: '100' | '010'| '020'| '030'| '040'| '050'| '060'| '000';
    accent: '100'| '010'| '020'| '030'| '040'| '050'| '000'| '060'| '070';
    social: '100'| '010'| '020';
}

const _colorPalettes:{[K in keyof ColorCodes]: Record<ColorCodes[K], ColorHex>} = {
  base: {
    '100': '#000000',
    '090': '#1a1a1a',
    '080': '#353535',
    '070': '#4f4f4f',
    '060': '#6a6a6a',
    '050': '#848484',
    '040': '#9d9d9d',
    '030': '#b5b5b5',
    '020': '#cecece',
    '010': '#e6e6e6',
    '005': '#f2f2f2',
    '000': '#ffffff',
  },
  primary: {
    '100': '#000000',
    '090': '#180601',
    '080': '#491103',
    '070': '#7a1d05',
    '060': '#ab2907',
    '050': '#dc3409',
    '040': '#f64e23',
    '030': '#f87554',
    '020': '#fa9d85',
    '015': '#fbbcac',
    '010': '#ffded6',
    '005': '#fff1f0',
    '000': '#ffffff',
  },
  secondary: {
    '100': '#000000',
    '090': '#070912',
    '080': '#161a37',
    '070': '#242b5b',
    '060': '#333c80',
    '050': '#414da5',
    '040': '#5a67be',
    '030': '#7f89cc',
    '020': '#a4aadb',
    '010': '#dcdff5',
    '005': '#edeef8',
    '000': '#ffffff',
  },

  tertiary: {
    '100': '#000000',
    '090': '#0f1a10',
    '080': '#223a25',
    '070': '#345a3a',
    '060': '#2C6D22',
    '050': '#3D932E',
    '040': '#4DBB3B',
    '030': '#98c39e',
    '020': '#b8d6bc',
    '010': '#d8e9da',
    '005': '#f1f9f6',
    '000': '#ffffff',
  },

  fourth: {
    '100': '#000000',
    '090': '#332700',
    '080': '#664e00',
    '070': '#997500',
    '060': '#cc9d00',
    '050': '#ffb800',
    '040': '#ffcf30',
    '030': '#ffdb63',
    '020': '#ffe796',
    '010': '#fff3c9',
    '005': '#fff8e1',
    '000': '#ffffff',
  },

  etc: {
    '010': '#f2f4f6',
    '020': '#FF0000',
    '030': '#001DEE',
    '040': '#505F79',
    '050': '#eef8ff',
    '060': '#ffe500',
    '070': '#1362E3',
  },
  normal: {
    '100': '#000000',
    '010': '#353535',
    '020': '#6a6a6a',
    '030': '#9d9d9d',
    '040': '#cecece',
    '050': '#f2f2f2',
    '060': '#f9f9f9',
    '000': '#ffffff',
  },
  accent: {
    '100': '#424242',
    '010': '#f64e23',
    '020': '#5a67be',
    '030': '#4DBB3B',
    '040': '#ffcf30',
    '050': '#001DEE',
    '000': '#ffffff',
    '060': '#7686EB',
    '070': '#49C680',
  },
  social: {
    '100': '#ffe500',
    '010': '#00c300',
    '020': '#333333',
  },
};

export const getColor = <T extends keyof ColorCodes>(type: T, code: ColorCodes[T]): ColorHex => {
    return _colorPalettes[type][code]
};



export const hexToRgb = (hex: ColorHex) => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const shorthandHex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(shorthandHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgba = (hex: ColorHex, alpha: number = 1) => {
  const color = hexToRgb(hex);

  if (color) {
    return `rgba(${color.r},${color.g},${color.b},${alpha})`;
  } else {
    return null;
  }
};