import React, { useEffect } from 'react';
import toggle from '@/hocs/toggle';
import Current from './CurrentSearchList';
import { GoodsTypeKor } from '@/services/Community';
// import styled from 'styled-components';

const SearchResult = ({ data, setShow, highlightIdx, setHighlightIdx, search, keyword, interestComplex, setSearchMode, showSearchHistory }) => {
  useEffect(() => {
    return () => {
      setHighlightIdx(-1);
    };
  }, []);

  let list = data && data.list;

  return keyword ? (
    <ResultList list={list} setShow={setShow} highlightIdx={highlightIdx} search={search} />
  ) : (
    <Current show={showSearchHistory} setShow={setShow} interestComplex={interestComplex} setSearchMode={setSearchMode} />
  );
};

//STREET :수소 검색, STITION :역검색, APT 아파트 단지 검색, OFT : 오피스텔검색
const Item = ({ isHighlight, search, item, order }) => {
  const { addr, title, icontype } = item;
  const tagCategory = GoodsTypeKor[icontype];

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    search(order);
  };

  return (
    <li id={'mainSearchResultItem_' + order}>
      <a className="sch_word" onClick={handleClick} style={isHighlight ? { background: '#f0f0f0', display: 'block', width: '100%' } : {}}>
        {title}
        {tagCategory ? <span className="tag-category">{tagCategory}</span> : null}
        <span className="address" style={{ display: 'block', color: '#888', fontSize: '13px' }}>
          {addr}
        </span>
      </a>
    </li>
  );
};

export default toggle(SearchResult);

const resultListZIndex = 1000;
const ResultList = ({ list, setShow, highlightIdx, search }) => {
  const resultList =
    list &&
    list.map((item, key) => {
      return <Item key={key} order={key} setShow={setShow} isHighlight={highlightIdx === key} search={search} item={item} />;
    });

  return (
    <div className="search_result_area" style={{ zIndex: resultListZIndex, border: '1px solid #d9d9d9', left: 'unset', top: 'unset' }}>
      <ul>
        {list ? (
          <>{resultList}</>
        ) : (
          <li>
            <p className="sch_word">검색결과값이 없습니다.</p>
          </li>
        )}
      </ul>
    </div>
  );
};
