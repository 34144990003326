import { actions } from '@/data';
import { FeedType, TagType } from '@/services/Community';
import React from 'react';

// 유효성 체크
export const validCheck = (payload, dispatch, userInfo) => {
  let message = '';

  if (!payload.feedType) {
    message = '글 분류를 선택해주세요.'; // 이런 경우는 없겠지?
  } else if (payload.feedType === FeedType.LOCAL_POST) {
    const addressTags = (payload?.tags || [])?.filter((tag) => tag.type.indexOf('ADDRESS') === 0);
    if (addressTags?.length < 1) {
      message = '지역을 선택해주세요.';
    }
  } else if (payload.feedType === FeedType.COMPLEX_POST) {
    //
    const complexTag = (payload?.tags || [])?.filter((tag) => tag.type === TagType.COMPLEX_TAG);
    if (complexTag?.length < 1) {
      message = '단지를 선택해주세요.';
    }
  } else if (payload.feedType === FeedType.POST_FOR_BUY) {
    const tags = payload?.tags;
    const isCallSelected = tags?.some((tag) => tag.key === 'WANT_TO_CALL');
    const hasPhoneNumber = userInfo?.mbr_phone !== 'no';
    const goodsType = tags.filter((tag) => tag.type === TagType.GOODS_TYPE_TAG)?.[0];
    const tradeType = tags.filter((tag) => tag.type === TagType.SALE_TYPE_TAG)?.[0];
    const addressTags = (tags || [])?.filter((tag) => tag.type.indexOf('ADDRESS') === 0);

    if (!tradeType) {
      message = '거래유형을 선택해주세요';
    }

    if (!goodsType) {
      message = '매물유형을 선택해주세요';
    }

    // Create/PostForbuy/index.js의 validCheck와 로직이 나뉘어 있음. 하나로 합치기
    if (addressTags?.length < 1) {
      message = '단지 또는 주소를 선택해주세요.';
    }

    if (isCallSelected && !hasPhoneNumber) {
      message = (
        <>
          <div className="board-body">
            <p className="para">
              <span className="wbr">연락받을 전화번호 정보가 없습니다.</span>
              <span className="wbr">“변경하기” 버튼을 선택하여 전화번호를 등록해 주세요.</span>
            </p>
          </div>
        </>
      );
    }
  }

  if (!message && (!payload.contents || payload.contents.length < 3)) {
    message = '내용을 좀 더 길게 작성해주세요.';
  }

  if (message) {
    dispatch(
      actions.common.alert({
        contents: message,
      })
    );
    return false;
  } else {
    return true;
  }
};

export const close = () => (dispatch) => {
  dispatch(actions.router.goBack());
};
