import Cookies from 'js-cookie';
import { OSTYPE } from '../types/OsType';
import { COOKIE_CONSTS } from '@/resources/global';

const EXPIRE_IN = COOKIE_CONSTS.EXPIRE_IN;

export default {
  set: async function(key, val, options = {}) {
    const ostype = window.dawin_instance.osType;
    try {
      // console.log('[S] by key', key, { val });

      if (ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS) {
        await window.dawin_instance.bridge.storeFunction.set(key, JSON.stringify(val));
      } else {
        Cookies.set(key, val, { expires: EXPIRE_IN, ...options });
      }
    } catch {
      Cookies.set(key, val, { expires: EXPIRE_IN, ...options });
    }
  },
  get: async function(key) {
    const ostype = window.dawin_instance.osType;
    try {
      if (ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS) {
        const result = await window.dawin_instance.bridge.storeFunction.get(key);
        // console.log('[G - 1]', key, { app: jsonParse(result) });
        return jsonParse(result);
      } else {
        // console.log('[G - 2]', key, { web: jsonParse(Cookies.get(key)) });
        return jsonParse(Cookies.get(key));
      }
    } catch {
      // console.log('[G - 3]', key, { web: jsonParse(Cookies.get(key)) });
      return jsonParse(Cookies.get(key));
    }
  },
  del: async function(key) {
    const ostype = window.dawin_instance.osType;
    try {
      if (ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS) {
        await window.dawin_instance.bridge.storeFunction.del(key);
      } else {
        Cookies.remove(key);
      }
    } catch {
      Cookies.remove(key);
    }
  },
};

function jsonParse(str) {
  if (str === 'undefined' || str === undefined) {
    return undefined;
  } else {
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  }
}
