import InputPhoneNumber from '@/components/Form/Input/InputPhoneNumber';
import { actions } from '@/data';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const ChangeCellphoneNumber = ({ setIsModalOpen, setCurrent, current }) => {
  const dispatch = useDispatch();
  const [cellphoneNumber, setCellphoneNumber] = useState('');
  const onChangeCellPhoneNumber = useCallback((e) => setCellphoneNumber(e.target.value), [cellphoneNumber]);
  const handleClickChangePhoneNumber = () => {
    const regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    if (!regExp.test(cellphoneNumber)) {
      dispatch(actions.common.alert({ contents: '유효한 휴대폰번호를 입력해주세요' }));
    } else {
      // return
      dispatch(actions.auth.changeCellphoneNumber({ cellphone: cellphoneNumber }));
      setCellphoneNumber(cellphoneNumber);
    }
  };

  const [authNumber, setAuthNumber] = useState('');
  const onChangeAuthNumber = useCallback((e) => setAuthNumber(e.target.value), []);

  const handleAuthClick = () => {
    if (authNumber) {
      dispatch(
        actions.auth.changeCellphoneNumberMatch({
          cellphone: cellphoneNumber,
          authNum: authNumber,
          setCurrent: setCurrent,
          current: current,
          successCallback: () => {
            setIsModalOpen(false);
            setCurrent('PHONE_CHANGE_DONE');
          },
        })
      );
    } else {
      dispatch(actions.common.alert({ contents: '인증번호를 입력해주세요' }));
      setAuthNumber('');
    }
  };
  return (
    <div className="popup-local-body">
      <form className="popup-content">
        <div className="popup-content-body">
          <fieldset className="submit-form module-a type-c medium">
            <legend>매물 검색 서식</legend>
            <div className="form-list">
              <div className="form-item">
                <div className="form-wrap">
                  <div className="form-head">
                    <label className="form-name">휴대폰 번호 입력</label>
                    <span className="form-para">휴대폰 번호를 입력해 주세요.</span>
                  </div>
                  <div className="form-body">
                    <div className="form-area">
                      <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                        <InputPhoneNumber
                          id="mobilePhoneNumber"
                          placeholder=""
                          maxLength={11}
                          onChange={onChangeCellPhoneNumber}
                          value={cellphoneNumber}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-util">
              <div className="button-display module-a style-a type-d">
                <span className="button-area">
                  <button
                    type="button"
                    className="btn module-b style-b type-fill accent-01 large-1x flex"
                    disabled={cellphoneNumber.length !== 11}
                    onClick={handleClickChangePhoneNumber}>
                    <span className="btn-text">인증번호 요청</span>
                  </button>
                </span>
              </div>
            </div>
          </fieldset>

          <fieldset className="submit-form module-a type-c medium">
            <legend>매물 검색 서식</legend>
            <div className="form-list">
              <div className="form-item">
                <div className="form-wrap">
                  <div className="form-head">
                    <label className="form-name">인증번호 입력</label>
                    <span className="form-para">문자메시지로 온 인증번호를 입력해 주세요.</span>
                  </div>
                  <div className="form-body">
                    <div className="form-area">
                      <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                        <input
                          type="text"
                          className="form-elem"
                          id="certificationNumber"
                          inputMode="numeric"
                          maxLength={4}
                          placeholder=""
                          value={authNumber}
                          onChange={onChangeAuthNumber}
                          autoComplete="off"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div className="popup-content-util">
          <div className="button-display module-a style-a type-d">
            <span className="button-area">
              <button type="button" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={handleAuthClick}>
                <span className="btn-text">번호 인증 및 휴대폰 번호 변경</span>
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangeCellphoneNumber;
