import { FeedType } from '@/services/Community';
import React from 'react';

const Contents = ({ needToMarkAddressKor, addressTagsKor, contents, handleContents, feedType }) => {
  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="#">
            {needToMarkAddressKor && <strong>{addressTagsKor} </strong>}
            {feedType === FeedType.POST_FOR_BUY ? '매물설명' : '글 작성'}
          </label>
          {feedType === FeedType.POST_FOR_BUY && <p className="form-summary">금액, 입주시기, 평형 등 구하는 집의 조건을 자세히 알려주세요.</p>}
        </div>
        <div className="form-body">
          <div className="form-area">
            <span className="form textarea module-b style-b type-line normal-04 large-1x flex">
              <textarea
                className="form-elem"
                rows="6"
                onChange={handleContents}
                placeholder={
                  feedType === FeedType.POST_FOR_BUY
                    ? '예) 3억 이하 전세금 대출 가능한 집 찾습니다. 다윈초등학교 도보 10분 이내 집 찾습니다. 아이가 셋이라 30평 이상만 찾아요'
                    : ''
                }
                value={contents}></textarea>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contents;
