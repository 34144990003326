import { useEffect } from 'react';

const useSocialList = ({ dispatch, actions, user }) => {
  useEffect(() => {
    // 로그인 했을 때 뿐만 아니라
    // 애플계정으로 로그인 한 유저가 추가 정보 입력을 성공 한 후에도 재조회 해야한다.
    // 추가 정보 입력은 휴대폰 번호 인증으로 이루어지므로
    // mbr_phone 정보가 업데이트 된 시점을 기준으로 한다.
    if (user) {
      // 소셜리스트 조회
      dispatch(actions.myPage.getSocialList());
    }
  }, [user?.mbr_phone]);
};

export default useSocialList;
