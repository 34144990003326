import React, { useMemo, useState, useEffect, useRef } from 'react';
import AddrSelectComp from './AddrSelectComp';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { useFormikContext } from 'formik';
import useFocusOut from '@/hooks/useFocusOut';

const Option2 = () => {
  const dispatch = useDispatch();
  const dongcode = useSelector(selectors.selling.getDongRiCode);
  const brokerList = useSelector(selectors.common.getSearchBrokerList);
  const [show, setShow] = useState(true);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const searchWord = useRef();
  const { values } = useFormikContext();
  const { ref, isComponentVisible, setIsComponentVisible } = useFocusOut(true);

  useEffect(() => {
    const keyword = searchWord?.current?.value?.trim();
    if (dongcode && keyword && keyword.length > 0) {
      dispatch(actions.common.getSearchBrokerList(dongcode, keyword));
    }
  }, [dongcode]);

  const brokerCompList = useMemo(
    () =>
      brokerList?.map((item, key) => (
        <li key={key}>
          <a onClick={() => handleSelect(item)} className="sch_word">
            {item.office_name} ({item.mbr_name})<span className="address">{item.addr1}</span>
          </a>
        </li>
      )),
    [brokerList]
  );

  const handleSelect = (brokerOffice) => {
    if (brokerOffice) {
      setSelectedOffice(brokerOffice);
      values.brokerofficeidx = brokerOffice.broker_office_idx;
      setShow(false);
    }
  };

  const searchBrokerList = (e) => {
    const keyword = e.target.value.trim();
    if (keyword && keyword.length > 0 && dongcode) {
      dispatch(actions.common.getSearchBrokerList(dongcode, keyword));
    } else {
      dispatch(actions.common.getSearchBrokerList(dongcode, ''));
    }
  };

  const handleSearchClick = (e) => {
    const keyword = searchWord?.current?.value?.trim();

    if (!dongcode) {
      dispatch(actions.common.alert({ contents: '읍/면/동을 선택해주세요' }));
    } else if (!keyword) {
      dispatch(actions.common.alert({ contents: '검색어를 입력해주세요' }));
    } else if (brokerList.length <= 0) {
      dispatch(actions.common.alert({ contents: '검색 결과가 없습니다.' }));
    } else {
      dispatch(actions.common.getSearchBrokerList(dongcode, keyword.trim()));
    }
  };

  const handleFocus = () => {
    setShow(true);
    setIsComponentVisible(true);
  };

  const handleDelete = () => {
    setSelectedOffice(null);
  };

  return (
    <div className="selec-result">
      <AddrSelectComp />
      {dongcode && (
        <div className="inp-textbox">
          <input
            ref={searchWord}
            type="search"
            onChange={searchBrokerList}
            placeholder="중개사무소명을 검색하세요"
            name="중개사무소명을 검색하세요"
            className="inp"
            autoComplete="off"
            onFocus={handleFocus}
          />
          <button className="btn-img btn-sch" onClick={handleSearchClick}>
            검색
          </button>
          {/* <!-- 검색결과 영역 --> */}
          {brokerList && show && (
            <div className="box-list box-list-basic" ref={ref}>
              <ul>{brokerList.length === 0 ? <li className="sch_word">검색 결과가 없습니다.</li> : <>{brokerCompList}</>}</ul>
            </div>
          )}
        </div>
      )}
      {selectedOffice && (
        <div className="result_area">
          <span>{selectedOffice.office_name}</span> (<span>{selectedOffice?.mbr_name}</span>) <span>{selectedOffice?.phone[0]}</span>
          <a onClick={handleDelete} className="del">
            삭제
          </a>
        </div>
      )}
    </div>
  );
};

export default Option2;
