import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import InterestGoodsMain from './InterestGoodsMain';
import { parseCallBack, objectToQuerystring } from '@/services/Helper';
/* 
    관심매물  
    redux store로 status를 관리하여 각각의 comp를 불러온다.
    ex) homeStatus === 'main' main comp 노출
 */

const InterestGoods = () => {
  const dispatch = useDispatch();

  const myInterestGoods = useSelector(selectors.my.getMyInterestGoods);
  useEffect(() => {
    dispatch(actions.my.getMyInterestGoods());
  }, []);
  useEffect(() => {
    // if (myInterestGoods && myInterestGoods.length > 0) {
    // }
  }, [myInterestGoods]);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      // 로그인 요청. 로그인 성공 후 돌아 올 url
      const currentPath = `${location.pathname}${location.search}`;

      // 로그인 되어있지 않은 경우 입력창을 닫음
      const querystring = parseCallBack();
      delete querystring.setting;
      dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(querystring)}`));

      const onSuccess = () => dispatch(actions.router.push(currentPath));
      const option = { onSuccess };

      dispatch(actions.common.pleaseLoginPopup(true, option));
    }
  }, [isLoggedIn]);

  const goodsList = useMemo(() => {
    return (
      <>
        <InterestGoodsMain myInterestGoods={myInterestGoods} />
        {/* {myCpStatus == 'regi' && <RegiComplex />} */}
      </>
    );
  }, [myInterestGoods]);

  return goodsList;
};

export default InterestGoods;
