import { api } from '@/apis/common';
import { store } from '@/data/configureStore';
import { appLogEvent } from './appLogger';
import { getDeviceIdOrGenerate, getSessionIdOrGenerate } from '@/services/Device';

export { appLogEvent };

/**
 * 로그 콜렉터
 */
const MAX_LOG_LENGTH = 1;
const LOGS = 'logs';
const stage_def = {
  development: 'dev',
  test: 'test',
  rc: 'rc',
  production: 'prod',
};

// 로그

// class Log {
//   constructor(from, action, actorType, actorId, params) {
//     this.logType = 'USER_ACTION_LOG'; //
//     this.createdAt = new Date().getTime(); // timestamp
//     this.deviceId = getDeviceIdOrGenerate(); //'string', // 앱인 경우 네이티브 앱에서 제공하는 deviceId, 그 외 uuid 생성
//     this.sessionId = getSessionIdOrGenerate();
//     this.from = from; // 어디서 발생한 이벤트인지 ex.[메뉴명, 이벤트명]
//     this.action = action;
//     this.actorType = actorType;
//     this.actorId = actorId;
//     this.params = params;
//   }
// }

// class Logs {
//   constructor() {
//     this.logs = []
//   }

//   add(log) {
//     this.logs.push(log);
//   }

//   call() {

//   }
// }

const defaultLog = {
  deviceId: getDeviceIdOrGenerate(), //'string', // 앱인 경우 네이티브 앱에서 제공하는 deviceId, 그 외 uuid 생성
  sessionId: getSessionIdOrGenerate(),
  logType: '', //
  createdAt: '', // timestamp
  from: [], // 어디서 발생한 이벤트인지 ex.[메뉴명, 이벤트명]
  action: '', //사용자 액션 ex.버튼클릭: click
  actorType: '', // UNKNOWN, USER, GUEST
  actorId: '', // mbr_idx 'string'
  params: {}, // 논의 필요
};

// api body
const defaultPayload = {
  service: 'DAWIN_FRONT',
  stage: stage_def[process.env.NODE_ENV],
  logs: [],
};

/**
 * 로그를 MAX_LOG_LENGTH 만큼 local storage에 쌓거나 백엔드로 전송
 *
 * @param {} log
 */
const stackLogOrPost = async (log) => {
  // 기존
  const logs = JSON.parse(localStorage.getItem(LOGS)) || [];

  // 추가
  logs.push(log);

  if (logs?.length > MAX_LOG_LENGTH - 1) {
    try {
      // payload
      const payload = { ...defaultPayload, logs };
      // 전송
      await api.postLog(payload);
      // 전송 완료한 데이터 제거
      localStorage.removeItem(LOGS);
    } catch (e) {
      // 오류
      // 다음 turn에 또 다시 보내기 시도할테니 예외처리 없음
    }
  } else {
    localStorage.setItem(LOGS, JSON.stringify(logs));
  }
};

export const logEvent = ({ logType, from, action, params }) => {
  try {
    // 동작이 발생할 때마다 요청을 보내는 형태가 아닌 모아서 보내는 형태가 필요함.
    // 로그 전송 요청이 n 건 모이면 요청 보내기
    // T 시간마다 요청 보내기
    // 결론 > 결국 전역 state 관리가 들어가니까, 누적 데이터 갯수 트래킹이 더 나음.

    const { getState } = store;
    const state = getState();
    const userInfo = state?.auth?.userSession;

    const actorType = userInfo ? 'USER' : 'GUEST';
    const actorId = userInfo?.mbr_idx ? `${userInfo?.mbr_idx}` : '';

    // "USER_ACTION_LOG", "EVENT_FRIEND_LINK_ACCESS"

    const log = { ...defaultLog, logType, createdAt: new Date().getTime(), actorType, actorId, from, action, params };

    // 로컬에 쌓거나 전송
    stackLogOrPost(log);

    // api.postLog(payload);
  } catch (e) {
    //
  }
};
