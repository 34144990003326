export const eduDataStandardiztion = (gubun, data) => {
  const item = data?.list[0];
  let result = { gubun: gubun, detail: {} };
  if (item) {
    switch (gubun) {
      case 'D':
        result.detail.type = item.daycare_type;
        result.detail.name = item.daycare_name;
        result.detail.tel = item.tel;
        result.detail.student_max_cnt = item.student_max_cnt;
        result.detail.student_current_cnt = item.student_current_cnt;
        result.detail.is_certificated = item.is_certificated;
        result.detail.t_cnt_per_s = item.teacher_one_cnt;
        break;
      case 'K':
        result.detail.type = item.kindergarten_type;
        result.detail.name = item.kindergarten_name;
        result.detail.tel = item.tel;
        result.detail.student_current_cnt = item.student_current_cnt;
        break;
      case 'S':
        result.detail.type = item.category;
        result.detail.name = item.school_name;
        result.detail.tel = item.tel;
        result.detail.student_current_cnt = item.student_cnt;
        break;
      default:
        break;
    }
  } else {
    result.detail = false;
  }
  return result;
};
