import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';
/* 
export const myRequestError = ({ error }) => ({
  type: AT.MP_REQUEST_ERROR,
  payload: {
    error,
  },
}); */
export const setKeyword = (val) => ({
  type: AT.SET_MY_KEYWORD,
  val,
});
//우리집 설정
export const myHomePageStatus = (val) => ({
  type: AT.CHANGE_MY_HOME_STATUS,
  val,
});
export const myHomePagePreStatus = (val) => ({
  type: AT.CHANGE_MY_HOME_PRE_STATUS,
  val,
});
export const selectedComplex = (val) => ({
  type: AT.UPDATE_SELECTED_MY_COMPLEX,
  val,
});
export const saveMyHome = (savedForm, onSuccess) => ({
  type: AT.SAVE_MY_HOME,
  savedForm,
  onSuccess,
});
export const saveModifyHome = (onSuccess) => ({
  type: AT.SAVE_MODIFY_MY_HOME,
  onSuccess,
});
export const deleteMyHome = (idx, onDelete) => ({
  type: AT.DELETE_MY_HOME,
  idx,
  onDelete,
});
export const deleteMyHomeLoading = () => ({
  type: loading(AT.DELETE_MY_HOME),
});
export const deleteMyHomeSuccess = (data) => ({
  type: success(AT.DELETE_MY_HOME),
  data,
});
export const deleteMyHomeFailure = () => ({
  type: failure(AT.DELETE_MY_HOME),
});
export const saveModifyHomeLoading = () => ({
  type: loading(AT.SAVE_MODIFY_MY_HOME),
});
export const saveModifyHomeSuccess = (data) => ({
  type: success(AT.SAVE_MODIFY_MY_HOME),
  data,
});
export const saveModifyHomeFailure = () => ({
  type: failure(AT.SAVE_MODIFY_MY_HOME),
});
export const saveMyHomeLoading = () => ({
  type: loading(AT.SAVE_MY_HOME),
});
export const saveMyHomeSuccess = (data) => ({
  type: success(AT.SAVE_MY_HOME),
  data,
});
export const saveMyHomeFailure = () => ({
  type: failure(AT.SAVE_MY_HOME),
});

export const getMyhome = (idx) => ({
  type: AT.GET_MY_HOME_DATA,
  idx,
});
export const sortHome = (fromIndex, toIndex) => ({
  type: AT.MY_HOME_DATA_SORTING,
  fromIndex,
  toIndex,
});
export const sortCp = (fromIndex, toIndex) => ({
  type: AT.MY_COMPLEX_DATA_SORTING,
  fromIndex,
  toIndex,
});
export const sortArea = (fromIndex, toIndex) => ({
  type: AT.MY_AREA_DATA_SORTING,
  fromIndex,
  toIndex,
});
export const getMyHomeDataLoading = () => ({
  type: loading(AT.GET_MY_HOME_DATA),
});
export const getMyHomeDataSuccess = (data) => ({
  type: success(AT.GET_MY_HOME_DATA),
  data,
});
export const getMyHomeDataFailure = (error) => ({
  type: failure(AT.GET_MY_HOME_DATA),
  error,
});
//안읽은 알림수
export const getUnreadNotiCount = () => ({
  type: AT.GET_UNREAD_NOTI_COUNT,
});
export const getUnreadNotiCountLoading = () => ({
  type: loading(AT.GET_UNREAD_NOTI_COUNT),
});
export const getUnreadNotiCountSuccess = (data) => ({
  type: success(AT.GET_UNREAD_NOTI_COUNT),
  data,
});
export const getUnreadNotiCountFailure = (error) => ({
  type: failure(AT.GET_UNREAD_NOTI_COUNT),
  error,
});

export const getMyInterestGoods = () => ({
  type: AT.GET_MY_INTEREST_GOODS,
});
export const getMyInterestGoodsLoading = () => ({
  type: loading(AT.GET_MY_INTEREST_GOODS),
});
export const getMyInterestGoodsSuccess = (data) => ({
  type: success(AT.GET_MY_INTEREST_GOODS),
  data,
});
export const getMyInterestGoodsFailure = (error) => ({
  type: failure(AT.GET_MY_INTEREST_GOODS),
  error,
});

//우리집설정
// export const getMyhomes = (data) => ({
//   type: AT.GET_MY_HOMES_DATA,
//   data
// });
export const getMyhomes = (data) => ({
  type: AT.GET_MY_HOMES_DATA,
  data,
});
export const getMyHomesDataLoading = () => ({
  type: loading(AT.GET_MY_HOMES_DATA),
});
export const getMyHomesDataSuccess = (data) => ({
  type: success(AT.GET_MY_HOMES_DATA),
  data,
});
export const getMyHomesDataFailure = (error) => ({
  type: failure(AT.GET_MY_HOMES_DATA),
  error,
});

//-----------------------------------------
//관심지역 설정
export const setBDongCode = (b_dong_code) => ({
  type: AT.SET_MY_B_DONG_CODE,
  b_dong_code,
});
export const myAreaPageStatus = (val) => ({
  type: AT.CHANGE_MY_AREA_STATUS,
  val,
});
export const myAreaPagePreStatus = (val) => ({
  type: AT.CHANGE_MY_AREA_PRE_STATUS,
  val,
});
export const saveMyArea = (onSuccess) => ({
  type: AT.SAVE_MY_AREA,
  onSuccess,
});
export const deleteMyArea = (idx, onDelete) => ({
  type: AT.DELETE_MY_AREA,
  idx,
  onDelete,
});
export const deleteMyAreaLoading = () => ({
  type: loading(AT.DELETE_MY_AREA),
});
export const deleteMyAreaSuccess = (data) => ({
  type: success(AT.DELETE_MY_AREA),
  data,
});
export const deleteMyAreaFailure = () => ({
  type: failure(AT.DELETE_MY_AREA),
});
export const saveMyAreaLoading = () => ({
  type: loading(AT.SAVE_MY_AREA),
});
export const saveMyAreaSuccess = (data) => ({
  type: success(AT.SAVE_MY_AREA),
  data,
});
export const saveMyAreaFailure = () => ({
  type: failure(AT.SAVE_MY_AREA),
});

export const getMyArea = (idx) => ({
  type: AT.GET_MY_AREA_DATA,
  idx,
});
export const getMyAreaDataLoading = () => ({
  type: loading(AT.GET_MY_AREA_DATA),
});
export const getMyAreaDataSuccess = (data) => ({
  type: success(AT.GET_MY_AREA_DATA),
  data,
});
export const getMyAreaDataFailure = (error) => ({
  type: failure(AT.GET_MY_AREA_DATA),
  error,
});
export const getMyAreas = () => ({
  type: AT.GET_MY_AREAS_DATA,
});
export const getMyAreasDataLoading = () => ({
  type: loading(AT.GET_MY_AREAS_DATA),
});
export const getMyAreasDataSuccess = (data) => ({
  type: success(AT.GET_MY_AREAS_DATA),
  data,
});
export const getMyAreasDataFailure = (error) => ({
  type: failure(AT.GET_MY_AREAS_DATA),
  error,
});
//-----------------------------------------
//관심단지 설정
export const setComplex = (idx) => ({
  type: AT.SET_MY_CP,
  idx,
});
export const myCpPageStatus = (val) => ({
  type: AT.CHANGE_MY_CP_STATUS,
  val,
});
export const myCpPagePreStatus = (val) => ({
  type: AT.CHANGE_MY_CP_PRE_STATUS,
  val,
});
export const saveMyCp = (onSuccess) => ({
  type: AT.SAVE_MY_CP,
  onSuccess,
});
export const deleteMyCp = (idx, onDelete) => ({
  type: AT.DELETE_MY_CP,
  onDelete,
  idx,
});
export const deleteMyCpLoading = () => ({
  type: loading(AT.DELETE_MY_CP),
});
export const deleteMyCpSuccess = (data) => ({
  type: success(AT.DELETE_MY_CP),
  data,
});
export const deleteMyCpFailure = () => ({
  type: failure(AT.DELETE_MY_CP),
});
export const saveMyCpLoading = () => ({
  type: loading(AT.SAVE_MY_CP),
});
export const saveMyCpSuccess = (data) => ({
  type: success(AT.SAVE_MY_CP),
  data,
});
export const saveMyCpFailure = () => ({
  type: failure(AT.SAVE_MY_CP),
});

/**
 * @param interestComplexIdx : (option) 읽어올 idx지정 (default: 모든 관심단지 정보)
 * @param goods : (option) 매물정보 요청 갯수 (default : 0)
 * @param recentTrade : (option) 최근 거래내역 요청 갯수 (default : 0)
 * @param dongCode : (option) 해당동의 주요단지 결과를 리턴 (해당 paramter가 있는경우, 내 관심단지 정보는 가져오지 않음)
 * @param tb_cb_complex_idx : (option) 관심단지 중 해당 complex idx인 정보만 호출 (default : 전체)
 */
export const getMyCps = (payload) => ({
  type: AT.GET_MY_CPS_DATA,
  payload,
});
export const getMyCpsDataLoading = () => ({
  type: loading(AT.GET_MY_CPS_DATA),
});
export const getMyCpsDataSuccess = (data) => ({
  type: success(AT.GET_MY_CPS_DATA),
  data,
});
export const getMyCpsDataFailure = (error) => ({
  type: failure(AT.GET_MY_CPS_DATA),
  error,
});
export const setCurrentTab = (tabType) => ({
  type: AT.SET_CURRENT_TAB,
  tabType,
});

// 내가 내놓은 매물
export const getMyGoods = (payload) => ({
  type: AT.GET_MY_GOODS,
  payload,
});

export const getMyGoodsLoading = () => ({
  type: AT.GET_MY_GOODS_LOADING,
});

export const getMyGoodsSuccess = (payload) => ({
  type: AT.GET_MY_GOODS_SUCCESS,
  payload,
});

export const getMyGoodsFailure = (error) => ({
  type: AT.GET_MY_GOODS_FAILURE,
  error,
});

/**
 *
 * @param {object} payload { idx: 변경할 데이터의 idx , key, value }
 * @returns
 */
export const updateWebNotification = (payload) => ({
  type: AT.UPDATE_WEB_NOTIFICATION,
  payload,
});

export const addWebNotification = (payload) => ({
  type: AT.ADD_WEB_NOTIFICATION,
  payload,
});

export const cleanWebNotification = () => ({
  type: AT.CLEAN_WEB_NOTIFICATION,
});

// 1:1 문의
export const getMyQNAList = ({ payload, meta }) => ({
  type: AT.GET_MY_QNA_LIST,
  payload,
  meta,
});
