import * as AT from './actionTypes';
import { FeedType, TagType } from '@/services/Community';
import { failure, loading, success } from '@/data/utils';

export const COMMUNITY_STATE = {
  payload: {
    feedTypes: [FeedType.POST_FOR_BUY],
    tags: [
      { type: TagType.SALE_TYPE_TAG, name: '매매', key: '매매' },
      { type: TagType.SALE_TYPE_TAG, name: '전세', key: '전세' },
      { type: TagType.SALE_TYPE_TAG, name: '월세', key: '월세' },
      { type: TagType.GOODS_TYPE_TAG, name: '아파트', key: '아파트' },
      { type: TagType.GOODS_TYPE_TAG, name: '오피스텔', key: '오피스텔' },
    ],
    limit: 150,
    cursor: undefined,
  },
  userSetAreaList: [],
};

export default function community(state = COMMUNITY_STATE, action = {}) {
  switch (action.type) {
    case AT.SAVE_USER_SET_AREA_LIST: {
      let userSetArea = state?.userSetAreaList || [];
      if (action.arr && action.arr?.dong?.code) {
        if (action.arr?.dong?.code != 'all') {
          if (action.arr?.dong2?.code && action.arr?.dong2?.code != 'all') {
            userSetArea = userSetArea?.filter((ii) => ii.key != action.arr?.dong?.code && ii.key != action.arr?.dong2?.code);
            userSetArea.push({ type: TagType.ADDRESS_TAG, name: action.arr?.dong?.name, key: action.arr?.dong2?.code + '' });
          } else if (action.arr?.dong2?.code && action.arr?.dong2?.code == 'all') {
            userSetArea = userSetArea?.filter((ii) => ii.key != action.arr?.dong1?.code && ii.key != action.arr?.dong2?.code);
            userSetArea.push({ type: TagType.ADDRESS_TAG, name: action.arr?.dong1?.name, key: action.arr?.dong1?.code + '' });
          } else {
            userSetArea = userSetArea?.filter((ii) => ii.key != action.arr?.dong1?.code && ii.key != action.arr?.dong2?.code);
            userSetArea.push({ type: TagType.ADDRESS_TAG, name: action.arr?.dong?.name, key: action.arr?.dong?.code + '' });
          }
        } else {
          userSetArea = userSetArea?.filter((ii) => ii.key != action.arr?.sigungu?.code);
          userSetArea?.push({ type: TagType.ADDRESS_TAG, name: action.arr?.sigungu?.name, key: action.arr?.sigungu?.code + '' });
        }
      } else if (action?.arr?.length == 0) {
        userSetArea = [];
      } else {
      }
      return { ...state, userSetAreaList: userSetArea };
    }
    case AT.CHANGE_COMMUNITY_PAYLOAD: {
      let tags = state?.payload?.tags?.filter((ii) => ii.type != action.tag);
      action.value?.map((ii) => tags.push(ii));
      return { ...state, payload: { ...state.payload, tags: tags }, cursor: undefined, status: undefined };
    }
    case AT.REFRESH_ADDRESS_TAG: {
      // let tags = state?.payload?.tags?.filter((ii) => ii.type != TagType.ADDRESS_TAG);
      // action.value?.map((ii) => tags.push(ii));
      return { ...state, payload: { ...state.payload, tags: state?.payload?.tags?.filter((ii) => ii.type != TagType.ADDRESS_TAG) } };
    }
    case AT.SET_PAYLOAD_NEXT:
      return { ...state, payload: { ...state.payload, cursor: action.val } };
    case AT.SAVE_NEXT_CURSOR:
      return { ...state, next: action.val };
    case loading(AT.GET_BROKER_COMMUNITY_FEEDS):
      return { ...state, status: 'loading' };
    case success(AT.GET_BROKER_COMMUNITY_FEEDS):
      return { ...state, status: 'success', feeds: action.data };
    case failure(AT.GET_BROKER_COMMUNITY_FEEDS):
      return {
        ...state,
        status: 'failure',
        error: action.error,
      };
    case AT.CLEAR_BROKER_COMMUNITY_FEEDS:
      return { ...state, status: 'success', feeds: [] };

    case loading(AT.GET_SAFETY_NUMBER):
      return { ...state, status: 'loading' };
    case success(AT.GET_SAFETY_NUMBER):
      return { ...state, status: 'success', safetyNumber: action.data };
    case failure(AT.GET_SAFETY_NUMBER):
      return {
        ...state,
        status: 'failure',
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
