import { loading, success, failure } from '@/data/utils';

//매물정보

export const SET_QA_CURRENT_PAGE = 'BROKERPAGE/SET_QA_CURRENT_PAGE';
export const SET_QA_TOTAL_PAGE = 'BROKERPAGE/SET_QA_TOTAL_PAGE';
export const SET_QA_TRADE_TYPE = 'BROKERPAGE/SET_QA_TRADE_TYPE';
export const SET_QA_KEYWORD = 'BROKERPAGE/SET_QA_KEYWORD';
export const SET_QA_CATEGORY = 'BROKERPAGE/SET_QA_CATEGORY';

export const REG_QA_ANSWER = 'BROKERPAGE/REG_QA_ANSWER';
export const EDIT_QA_ANSWER = 'BROKERPAGE/EDIT_QA_ANSWER';
export const DEL_QA_ANSWER = 'BROKERPAGE/DEL_QA_ANSWER';

export const GET_MG_QA_LIST = 'BROKERPAGE/GET_MG_QA_LIST';
export const GET_MG_QA_LIST_LOADING = loading(GET_MG_QA_LIST);
export const GET_MG_QA_LIST_SUCCESS = success(GET_MG_QA_LIST);
export const GET_MG_QA_LIST_FAILURE = failure(GET_MG_QA_LIST);

export const GET_QA_DETAIL = 'BROKERPAGE/GET_QA_DETAIL';
export const GET_QA_DETAIL_LOADING = loading(GET_QA_DETAIL);
export const GET_QA_DETAIL_SUCCESS = success(GET_QA_DETAIL);
export const GET_QA_DETAIL_FAILURE = failure(GET_QA_DETAIL);
