import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

export const api = {
  // 등록
  async createGoods(data) {
    try {
      return await apiClient.post(`/goods_common`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updateGoods(goods_common_idx, data) {
    try {
      return await apiClient.put(`/goods_common/${goods_common_idx}`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getGoods(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getGoodsImages(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/image`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물 상태 변경
  async updateGoodsStatus(goods_common_idx, payload) {
    try {
      return await apiClient.patch(`/goods_common/${goods_common_idx}/status`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물 끌올
  async renewGoods(goods_common_idx) {
    try {
      return await apiClient.patch(`/goods_common/${goods_common_idx}/renew`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물 삭제
  async deleteGoods(goods_common_idx) {
    try {
      return await apiClient.delete(`/goods_common/${goods_common_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 부동산 고유번호 수정
  async updateGoodsRegisteredId(goods_common_idx) {
    try {
      return await apiClient.patch(`/goods_common/${goods_common_idx}/registered_id`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물을 중개할 수 있는 중개사 리스트 조회 (위치기반)
  async getBrokersByGoodsPosition(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/available_brokers`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물의 중개사 리스트 조회
  async getBrokersByGoods(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/brokers`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물의 멤버십 중개사 리스트 조회
  async getMembershipBrokersByGoods(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/membership_brokers`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물 지정중개사 추가
  async updateBrokersByGoods(goods_common_idx, data) {
    try {
      return await apiClient.post(`/goods_common/${goods_common_idx}/brokers`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물 지정중개사 삭제
  async deleteBrokersByGoods(goods_common_idx, broker_list) {
    try {
      // 삭제할 중개사 idx를 콤마(,)로 구분하여
      return await apiClient.delete(`/goods_common/${goods_common_idx}/brokers${objectToQuerystring({ broker_list })}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 매물의 다윈케어 정보 조회
  async getDawinCareInfoByGoods(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/paid-option`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // 매물유형/거래유형 별 필요한 option 정보 조회
  // [realtyType] APT:아파트, OFT:오피스텔, DSD:다세대(빌라), YUN:연립(빌라), DAN:단독, DGG:다가구, ONE:원투룸
  // [tradeTypes] s:매매, c:전세, m:월세 (값이 여럿일 경우 "|"로 구분해서 입력)
  async getOptionsByType(data) {
    try {
      return await apiClient.get(`/goods_common/option/list${objectToQuerystring(data)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 단지의 매물 정보 조회
  // tradeTypes: (option) s:매매, c:전세, m:월세 (값이 여럿일 경우 "|"로 구분해서 입력)
  // areaSizes : (option) 면적범위(최소-최대) (값이 여럿일 경우 "|"로 구분해서 입력)
  // 예 : areaSizes=59-80|103-130
  // sorting : (option) default 지도 중심동에 가까운 순
  // (price:가격순, date:날짜순, area:면적순)
  // sortingOrder : (option) default 내림차순
  // asc:오름차순, desc:내림차순
  // page : (option) 페이지 default:1
  // perPage : (option) 페이지당 조회 수 default:10000
  async getGoodsListByComplex(tb_cb_complex_idx, data) {
    try {
      return await apiClient.get(`/goods_common/complex/${tb_cb_complex_idx}${objectToQuerystring(data)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 지역별 매물 정보 조회
  // realtyTypes: 조회할 부동산 종류  (값이 여럿일 경우 "|"로 구분해서 입력)
  // APT:아파트, OFT:오피스텔, DSD:다세대(빌라), YUN:연립(빌라), DAN:단독, DGG:다가구, ONE:원투룸
  // tradeTypes: (option) s:매매, c:전세, m:월세 (값이 여럿일 경우 "|"로 구분해서 입력)
  // areaSizes : (option) 면적범위(최소-최대) (값이 여럿일 경우 "|"로 구분해서 입력)
  // 예 : areaSizes=59-80|103-130
  // sorting : (option) default 지도 중심동에 가까운 순
  // price:가격순, date:날짜순, area:면적순)
  // sortingOrder : (option) default 내림차순
  // asc:오름차순, desc:내림차순
  // page : (option) 페이지 default:1
  // perPage : (option) 페이지당 조회 수 default:10000
  async getGoodsListByAddress(address_code) {
    try {
      return await apiClient.get(`/goods_common/address/${address_code}${objectToQuerystring(data)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // json body
  // contents: 메모 내용
  // memo_type: (option) 매모 유형 default = 'USR'
  // SYS(시스템), ADM(관리자메모), BU(중개사메모), BP(중개사-고객상담메모), USR(유저메모), FAKE(허위매물 신고)
  // memo_target : 중개사
  // contents : 메모내용
  async createGoodsMemo(goods_common_idx, data) {
    try {
      return await apiClient.post(`/goods_common/${goods_common_idx}/memo`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getGoodsMemo(goods_common_idx) {
    try {
      return await apiClient.get(`/goods_common/${goods_common_idx}/memo`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async updateGoodsMemo(goods_common_idx, goods_memo_idx, payload) {
    try {
      return await apiClient.put(`/goods_common/${goods_common_idx}/memo/${goods_memo_idx}`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async delelteGoodsMemo(goods_common_idx, goods_memo_idx) {
    try {
      return await apiClient.delete(`/goods_common/${goods_common_idx}/memo/${goods_memo_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 요청_전화로 매물등록
  async sellCallRequest(payload) {
    const prefix = '/request/sell';
    try {
      return await apiClient.post(prefix, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 요청_직접 매물등록
  async handleManualInitialStep() {
    try {
      return await apiClient.get('/goods/step0');
    } catch (e) {
      throw Error(e.message);
    }
  },
  // api Method 는 step 별로 분리하지 않고 하나로 사용함
  async getManualStepData(payload) {
    const { step } = payload;
    const prefix = `/goods/step0${step.toString().replace('step_', '')}`;
    try {
      const { data } = await apiClient.post(prefix, payload);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  },

  async createSavedForm(mbr_idx, data) {
    try {
      const response = await apiClient.post(`/goods/savedForm/${mbr_idx}`, data);
      // console.log('savedForm!!', response);
      // const response = {};
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },

  // --READ--
  async readSavedForm(mbr_idx) {
    try {
      const response = await apiClient.get(`/goods/savedForm/${mbr_idx}`);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  // --UPDATE--
  async updateSavedForm(mbr_idx, data) {
    try {
      const response = await apiClient.put(`/goods/savedForm/${mbr_idx}`, data);
      // const response = await apiClient.post(`/goods/savedForm/${mbr_idx}`, data);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  // --DELETE--
  async deleteSavedForm(mbr_idx) {
    try {
      const response = await apiClient.delete(`/goods/savedForm/${mbr_idx}`);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  // --IMAGE READ--
  async readSavedFormImage(mbr_idx, category) {
    try {
      const response = await apiClient.get(`/goods/savedForm/image/${mbr_idx}/${category}`);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  // --IMAGE DELETE ALL--
  async deleteSavedFormImage(mbr_idx) {
    try {
      const response = await apiClient.delete(`/goods/savedForm/image/${mbr_idx}`);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async registerSavedForm(mbr_idx, mbr_name) {
    try {
      const body = {
        mbr_idx,
        mbr_name,
      };
      console.log('body!', body);
      console.log('mbr_idx', mbr_idx);
      console.log('mbr_name', mbr_name);
      const response = await apiClient.post(`/goods/savedForm/register/${body.mbr_idx.mbr_idx}`, body);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getCompleteForm(goods_idx) {
    try {
      const response = await apiClient.get(`/goods/savedForm/complete/${goods_idx}`);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async updateCompleteForm(goods_idx, data) {
    try {
      const response = await apiClient.put(`/goods/savedForm/complete/${goods_idx}`, data);
      // const response = await apiClient.post(`/goods/savedForm/complete/${goods_idx}`, data);
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFacilityCode(data) {
    try {
      return await apiClient.get('/complex-facility/code' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async insertFacilityData(data) {
    try {
      const { mbrIdx, complexIdx, body } = data;
      const facility_data_complete = Object.keys(body).map((code) => ({
        criteria_score: '2',
        source: 'SELLING',
        criteria: 'complex',
        code_detail: code,
        option: {
          option: body[code],
        },
      }));
      return await apiClient.post(`/complex-facility/selling/${mbrIdx}/${complexIdx}`, facility_data_complete);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFacilityData(data) {
    try {
      const { mbrIdx, complexIdx } = data;
      return await apiClient.get(`/complex-facility/selling/${mbrIdx}/${complexIdx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // [[NEW LOGIC]]-----------------------------------------------------------

  // api Method 는 step 별로 분리하지 않고 하나로 사용함
  async handleManualNextStep(payload) {
    const { step } = payload;
    const prefix = `/goods/nextstep0${step.replace('step_', '')}`;
    try {
      return await apiClient.post(prefix, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async handleManualLastStep(payload) {
    const prefix = '/goods/tempSave';
    try {
      return await apiClient.post(prefix, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // ===================== Step 1 ===================== //

  // *type : APT or OFT
  async getComplexList(payload) {
    try {
      const prefix = '/complex/select-list';
      return await apiClient.get(prefix + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexListWithBcfiCnt(payload) {
    try {
      const prefix = '/complex/select-list';
      return await apiClient.get(prefix + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexLocation(payload) {
    try {
      const prefix = '/complex/';
      const { complexIdx } = payload;
      const suffix = '/location';
      return await apiClient.get(prefix + complexIdx + suffix);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getAddrSiDo(only_available, only_broker) {
    try {
      return await apiClient.get('/address/si-do' + objectToQuerystring({ only_available: only_available, only_broker: only_broker }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getAddrSiGunGu({ si_do_code, only_available, only_broker }) {
    if (si_do_code) {
      try {
        const prefix = '/address/si-do/';
        const suffix = '/si-gun-gu';
        // console.log('API_getAddrSiGunGu',si_do_code)
        return await apiClient.get(prefix + si_do_code + suffix + objectToQuerystring({ only_available: only_available, only_broker: only_broker }));
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  async getAddrDongRi({ si_gun_gu_code, only_available, only_broker }) {
    if (si_gun_gu_code) {
      try {
        return await apiClient.get(
          '/address/si-gun-gu/' + si_gun_gu_code + '/dong-ri' + objectToQuerystring({ only_available: only_available, only_broker: only_broker })
        );
      } catch (e) {
        throw Error(e.message);
      }
    } else {
      return { list: [] };
    }
  },

  async getComplexDongList(data) {
    try {
      if (data) {
        return await apiClient.get('/complex/complex_dong_list' + objectToQuerystring(data));
      } else {
        return null;
      }
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFloorList(data) {
    try {
      return await apiClient.get('/complex/complex_dong_floor_list' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getHoList(data) {
    try {
      if (data) {
        // return await apiClient.get('/complex/' + complexIdx + '/dong' + dongNo + '/floor' + floor);
        return await apiClient.get('/complex/complex_dong_ho_list' + objectToQuerystring(data));
      } else {
        return {};
      }
    } catch (e) {
      throw Error(e.message);
    }
  },
  // ================ Step 3 ================ //
  async saveImage(data) {
    try {
      return await apiClient.post('/goods/goods_img_tprc', data);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // ================ Step 4 ================ //
  // @todo <프론트_요청사항> idx:20
  async getNearbyAgents(payload) {
    // console.log('API_getNearbyAgents',location)
    try {
      return await apiClient.post('/goods/broker_office', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // ================ Step 5 ================ //

  // ===================== 등기부등본 발급 처리  ===================== //
  async saveRealEstateUniqNo(data) {
    try {
      const result = await apiClient.post(`/goods/realEstateCopy/uniqNo`, data);
      return result;
    } catch (e) {
      throw Error(e.message);
    }
  },

  async saveRealEstateCopy(data) {
    try {
      return await apiClient.post(`/goods/realEstateCopy/register`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getFacilitiyData2(complexIdx) {
    try {
      const response = await apiClient.get(`complex-facility/${complexIdx}`);
      const facilArray = response.facilityData.map((el) => el.code_detail);
      let facilObj = {};
      if (facilArray.length >= 1) {
        for (let i = 0; i < facilArray.length; i++) {
          facilObj[facilArray[i]] = true;
        }
      }
      return facilObj;
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updatePrice(goods_common_idx, data) {
    try {
      return await apiClient.put(`/goods_common/${goods_common_idx}`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
