import axios from 'axios';
import { apiRequestInterceptor, apiRequestInterceptorNoAuth, apiResponseInterceptor, apiResponseInterceptor2 } from './helpers';
import env from '@/resources/env';

const SERVER_URL = env.SERVER_URL;
const KAKAO_URL = env.KAKAO_URL;

// 2.3 이전
const apiClient = axios.create({
  baseURL: `${SERVER_URL}/v1`,
});

const apiClientNoAuth = axios.create({
  baseURL: `${SERVER_URL}/v1`,
});

// 2.3 이후
const apiClient2 = axios.create({
  baseURL: `${SERVER_URL}/api`,
});

const kakaoClient = axios.create({
  baseURL: KAKAO_URL,
  headers: { Authorization: 'KakaoAK ' + env.KAKAO_RESTAPI_KEY },
});

apiClient.interceptors.request.use(apiRequestInterceptor);
apiClient.interceptors.response.use(...apiResponseInterceptor);

apiClientNoAuth.interceptors.request.use(apiRequestInterceptorNoAuth);
apiClientNoAuth.interceptors.response.use(...apiResponseInterceptor);

apiClient2.interceptors.request.use(apiRequestInterceptor);
apiClient2.interceptors.response.use(...apiResponseInterceptor2);

export { apiClient, apiClient2, kakaoClient, apiClientNoAuth };
