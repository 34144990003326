import * as core from './core';
import * as detail from './detail';
import roomTypes from './roomTypes';
import realtyTypes from './realtyTypes';
import realtyTypesExpanded from './realtyTypesExpanded';
// import RealtyType from './RealtyType';
import options from './options';
import area from './area';
import tradeTypes from './tradeTypes';
import directions from './directions';
import * as price from './price';
import areaType from './areaType';
import renewalRightUse from './renewalRightUse';

import view from './view';

export const NO_NEED_RESIDENCE = [realtyTypes.codes.sto, realtyTypes.codes.off];

export default {
  core,
  detail,
  roomTypes,
  realtyTypes,
  realtyTypesExpanded,
  options,
  directions,
  area,
  tradeTypes,
  price,
  areaType,
  renewalRightUse,
  view,
};
