import { actions } from '@/data';
import { formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const ComplexList = ({ showList, addressList }) => {
  const dispatch = useDispatch();

  const toComplexDetail = (e, element) => {
    e.preventDefault();
    const querystring = parseCallBack();
    delete querystring.userHistory;
    querystring.d_complex = element?.[1]?.complex_idx;
    dispatch(actions.router.push(`/buying${objectToQuerystring(querystring)}`));
  };

  return (
    <>
      {showList?.map((element) => {
        let certifiedDate;
        const date = element[1]?.createdAt;
        const address = addressList[element[1].dong_code]?.fullname;
        if (date) {
          certifiedDate = `${formatDate(date)}에 인증`;
        } else {
          certifiedDate = '';
        }

        return (
          <>
            <div className="posts-item">
              <div className="posts-wrap">
                <div className="posts-inform">
                  <div className="posts-head">
                    <p className="posts-subject">
                      <Link className="posts-name" to="#" onClick={(e) => toComplexDetail(e, element)}>
                        <span className="wbr">{element[1]?.complex_name}</span>
                      </Link>
                    </p>
                    <p className="posts-summary">{address}</p>
                  </div>
                  <div className="posts-info">
                    <p className="data-list">
                      <span className="data-item posted">
                        <span className="body">{certifiedDate}</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default ComplexList;
