import { FeedType } from '@/services/Community';
import React from 'react';
import { Link } from 'react-router-dom';

const OtherGoodsTag = ({ feedType, handleOtherGoods }) => {
  return feedType === FeedType.POST_FOR_SALE ? (
    <Link className="btn module-a style-c type-fill normal-05 medium" to="#" onClick={handleOtherGoods}>
      <span className="btn-text">매물목록</span>
    </Link>
  ) : (
    false
  );
};

export default OtherGoodsTag;
