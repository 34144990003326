export const SERVER_URL = process.env.SERVER_URL;
export const DEV = process.env.NODE_ENV === 'production' ? false : true;
export const SERVER_CHECKING = false;
export const KAKAO_URL = process.env.KAKAO_URL;
export const KAKAO_RESTAPI_KEY = process.env.KAKAO_RESTAPI_KEY;
export const KAKAO_JS_KEY = process.env.KAKAO_JS_KEY;
export const NAVER_CLIENT_ID = process.env.NAVER_CLIENT_ID;
export const NICE_SERVER_URL = 'http://localhost:8888';
export const PAYMENT_CLIENT = process.env.PAYMENT_CLIENT;
export const CHAT_SERVER_URL = process.env.CHAT_SERVER_URL;

const envs = {
  SERVER_URL,
  DEV,
  KAKAO_URL,
  KAKAO_RESTAPI_KEY,
  KAKAO_JS_KEY,
  NAVER_CLIENT_ID,
  SERVER_CHECKING,
  NICE_SERVER_URL,
  PAYMENT_CLIENT,
  CHAT_SERVER_URL,
};

export default envs;
