import React from 'react';

const DeletedComment = () => {
  return (
    <div className="info-board no-data attr-comment-delete">
      <div className="board-wrap">
        <div className="board-head">
          <p className="board-summary">
            <span className="wbr">삭제된 댓글입니다.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeletedComment;
