import React from 'react';
import { useDispatch } from 'react-redux';
import { STATUS } from '@/data/utils';
import useAddress from '@/components/Address/useAddress';

export default function Address({
  data,
  setData,
  onlyAvailable = true,
  disabled = false,
  showGlobalSpinner,
  addAllOptionOnDong = false,
  tenDigitCode,
  refresh = false,
}) {
  const dispatch = useDispatch();

  const {
    sidoCode,
    sigunguCode1,
    sigunguCode2,
    dongCode1,
    dongCode2,
    sidoData,
    sigunguData1,
    sigunguData2,
    dongData1,
    dongData2,
    siDoOptions,
    siGunGuOptionsFirst,
    siGunGuOptionsSecond,
    dongRiOptionsFirst,
    dongRiOptionsSecond,
    onSiDoSelect,
    onSiGunGuSelectFirst,
    onSiGunGuSelectSecond,
    onDongRiSelectFirst,
    onDongRiSelectSecond,
  } = useAddress({ data, setData, onlyAvailable, showGlobalSpinner, addAllOptionOnDong, tenDigitCode, refresh });

  const commonClassName = 'form select module-b style-b type-line normal-04 large-1x flex';

  return (
    <>
      <span className={commonClassName}>
        <select
          className="form-elem"
          id="selectCity"
          value={sidoCode}
          onChange={onSiDoSelect}
          disabled={sidoData.status === STATUS.LOADING || disabled}>
          {siDoOptions}
        </select>
      </span>
      {sidoCode != '36' && (
        <span className={commonClassName}>
          <select
            className="form-elem"
            value={sigunguCode1}
            onChange={onSiGunGuSelectFirst}
            disabled={sigunguData1.status === STATUS.LOADING || disabled}>
            {siGunGuOptionsFirst}
          </select>
        </span>
      )}
      {sigunguData2?.list?.length > 0 && (
        <span className={commonClassName}>
          <select
            className="form-elem"
            value={sigunguCode2}
            onChange={onSiGunGuSelectSecond}
            disabled={sigunguData2.status === STATUS.LOADING || disabled}>
            {siGunGuOptionsSecond}
          </select>
        </span>
      )}
      <span className={commonClassName}>
        <select className="form-elem" value={dongCode1} onChange={onDongRiSelectFirst} disabled={dongData1.status === STATUS.LOADING || disabled}>
          {dongRiOptionsFirst}
        </select>
      </span>
      {dongData2?.list?.length > 0 && (
        <span className={commonClassName}>
          <select className="form-elem" value={dongCode2} onChange={onDongRiSelectSecond} disabled={dongData2.status === STATUS.LOADING || disabled}>
            {dongRiOptionsSecond}
          </select>
        </span>
      )}
    </>
  );
}
