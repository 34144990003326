import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.GET_BROKER_BOARD_NEW, sagas.getBrokerBoardNew);

  yield takeEvery(AT.brokerOfficeInfo.GET_BROKER_OFFICE_INFO, sagas.brokerOfficeInfo.getBrokerOfficeInfo);
  yield takeEvery(AT.GET_BROKER_ALARM_LIST, sagas.getBrokerAlarmList);
  yield takeEvery(AT.DELETE_ALARM, sagas.deleteAlarm);
  yield takeEvery(AT.DELETE_RA_ALARM, sagas.deleteRAAlarm);

  //brokerOfficeInfo - basicinfo
  yield takeEvery(AT.brokerOfficeInfo.BROKER_PASS_CHANGE, sagas.brokerOfficeInfo.brokerPassChange);
  yield takeEvery(AT.brokerOfficeInfo.CHANGE_BROKER_INFO_DATA, sagas.brokerOfficeInfo.brokerInfoChange);
  yield takeEvery(AT.brokerOfficeInfo.BROKER_SECESSION, sagas.brokerOfficeInfo.brokerSecession);

  //brokerOfficeInfo - officeInfo
  yield takeEvery(AT.brokerOfficeInfo.OFFICE_INFO_CHANGE, sagas.brokerOfficeInfo.officeInfoChange);
  yield takeEvery(AT.brokerOfficeInfo.GET_MY_OFFICE_LIST, sagas.brokerOfficeInfo.getOfficeList);
  yield takeEvery(AT.brokerOfficeInfo.SEARCH_XY_MODI, sagas.brokerOfficeInfo.getSearchXY);

  //brokerOfficeIntro - officeIntro
  yield takeEvery(AT.brokerOfficeInfo.SAVE_OFFICE_INTRO, sagas.brokerOfficeInfo.officeIntroChange);

  //setArea
  yield takeEvery(AT.setArea.GET_POSSIBLE_AREA, sagas.setArea.getPossibleArea);
  yield takeEvery(AT.setArea.GET_BROKER_AREA_DISTRICT, sagas.setArea.getBrokerAreaDistrict);
  yield takeEvery(AT.setArea.GET_BROKER_DISTRICT, sagas.setArea.getBrokerDistrict);
  yield takeEvery(AT.setArea.SAVE_SET_DEFAULT_AREA, sagas.setArea.saveSetDefaultArea);
  // yield takeEvery(AT.setArea.DELETE_AREA_DISTIRCT, sagas.setArea.deleteBrokerDistrict);
  yield takeEvery(AT.setArea.SAVE_ACTIVE_AREA, sagas.setArea.saveActiveArea);
  yield takeEvery(AT.setArea.GET_SET_AREA, sagas.setArea.getSetArea);

  //mgCounseling
  yield takeEvery(AT.mgCounseling.GET_MG_COUNSELING_LIST, sagas.mgCounseling.getMgCounselingList);
  yield takeEvery(AT.mgCounseling.GET_MG_COUNSELING_GOODS_LIST, sagas.mgCounseling.getMgCounselingGoodsList);
  yield takeEvery(AT.mgCounseling.SAVE_MGC, sagas.mgCounseling.save_MGC);

  //mgCustomer
  yield takeEvery(AT.mgCustomer.GET_MG_CUSTOM, sagas.mgCustomer.mgCustomerList);
  yield takeEvery(AT.mgCustomer.EDIT_CUSTOMINFO, sagas.mgCustomer.editCustomerInfo);
  yield takeEvery(AT.mgCustomer.DELETE_CUSTOMINFO, sagas.mgCustomer.deleteCustomerInfo);
  yield takeEvery(AT.mgCustomer.REG_NEW_CUSTOM, sagas.mgCustomer.regNewCustom);
  yield takeEvery(AT.mgCustomer.GET_SEL_CUSTOM, sagas.mgCustomer.getSelCustom);
  yield takeEvery(AT.mgCustomer.EDIT_MEMO, sagas.mgCustomer.editmemo);
  yield takeEvery(AT.mgCustomer.DEL_MEMO, sagas.mgCustomer.delmemo);

  //manageGoods
  yield takeEvery(AT.mgGoods.GOODS_KEYWORD_SEARCH, sagas.mgGoods.searchKeyword);
  yield takeEvery(AT.mgGoods.GET_MANAGE_GOODS_LIST, sagas.mgGoods.manageGoodsList);
  yield takeEvery(AT.mgGoods.GET_MANAGE_GOODS_DETAIL, sagas.mgGoods.manageGoodsDetail);
  yield takeEvery(AT.mgGoods.GET_MANAGE_GOODS_MEMO, sagas.mgGoods.getGoodsMemo);
  yield takeEvery(AT.mgGoods.GET_GOODS_HISTORY, sagas.mgGoods.getGoodsHistory);
  yield takeEvery(AT.mgGoods.GET_BROKER_COMMENT, sagas.mgGoods.getBrokerComments);
  yield takeEvery(AT.mgGoods.GOODS_FAVORITE_DESIGNATE, sagas.mgGoods.brokerFavorite);
  yield takeEvery(AT.mgGoods.GOODS_FAVORITE_DE_DESIGNATE, sagas.mgGoods.brokerFavoriteCancel);
  yield takeEvery(AT.mgGoods.FAKE_SALE_REPORT, sagas.mgGoods.fakeSaleReport);
  yield takeEvery(AT.mgGoods.MANAGE_GOODS_MEMO, sagas.mgGoods.memoPrc);
  yield takeEvery(AT.mgGoods.MANAGE_GOODS_BROKER_COMMENT, sagas.mgGoods.commentPrc);
  yield takeEvery(AT.mgGoods.CONTRACT_INFO_SAVE, sagas.mgGoods.saveContractInfo);
  yield takeEvery(AT.mgGoods.CONTRACT_INFO_EX_SAVE, sagas.mgGoods.saveExContractInfo);
  yield takeEvery(AT.mgGoods.CONTRACT_INFO_EDIT, sagas.mgGoods.editContractInfo);
  yield takeEvery(AT.mgGoods.MODIFY_GOODS, sagas.mgGoods.saveModifyGoods);
  yield takeEvery(AT.mgGoods.GET_CALL_REPORT, sagas.mgGoods.getCallReport);
  yield takeEvery(AT.mgGoods.GET_ASSOCIATED_COMMUNITY, sagas.mgGoods.getAssociatedCommunity);
  yield takeEvery(AT.mgGoods.RESET_GOODS_DETAIL, sagas.mgGoods.resetGoodsDetail);

  //manageContract
  yield takeEvery(AT.mgContract.GET_MANAGE_CONTRACT_LIST, sagas.mgContract.manageContractList);
  yield takeEvery(AT.mgContract.GET_MANAGE_CONTRACT_DETAIL, sagas.mgContract.manageContractDetail);
  yield takeEvery(AT.mgContract.CANCEL_CONTRACT, sagas.mgContract.cancelContract);
  yield takeEvery(AT.mgContract.SAVE_CONTRACT_CONTACT_INFO, sagas.mgContract.saveContactUs);
  yield takeEvery(AT.mgContract.EDIT_CONTRACT_CONTACT_INFO, sagas.mgContract.editContactUs);
  yield takeEvery(AT.mgContract.DELETE_CONTRACT_CONTACT_INFO, sagas.mgContract.deleteContactUs);
  yield takeEvery(AT.mgContract.SAVE_CONTRACT_DETAIL_INFO, sagas.mgContract.saveContractDetailInfo);
  yield takeEvery(AT.mgContract.EDIT_CONTRACT_DETAIL_INFO, sagas.mgContract.editContractDetailInfo);
  yield takeEvery(AT.mgContract.DELETE_CONTRACT_DETAIL_INFO, sagas.mgContract.deleteContractDetailInfo);
  yield takeEvery(AT.mgContract.DELETE_CONTRACT, sagas.mgContract.deleteContract);
  yield takeEvery(AT.mgContract.SAVE_PRE_CONTRACT, sagas.mgContract.savePreContract);
  yield takeEvery(AT.mgContract.POST_CONTRACT, sagas.mgContract.postContract); // 계약등록필요 계약체결

  //mgQa
  yield takeEvery(AT.mgQa.GET_MG_QA_LIST, sagas.mgQa.getQaList);
  yield takeEvery(AT.mgQa.GET_QA_DETAIL, sagas.mgQa.getQaDetail);
  yield takeEvery(AT.mgQa.REG_QA_ANSWER, sagas.mgQa.regQaAnswer);
  yield takeEvery(AT.mgQa.EDIT_QA_ANSWER, sagas.mgQa.editQaAnswer);
  yield takeEvery(AT.mgQa.DEL_QA_ANSWER, sagas.mgQa.delQaAnswer);

  //mgRate
  yield takeEvery(AT.mgRate.GET_BROKERAGE_EVALUATION, sagas.mgRate.getBrEvaluation);

  //dailyReport
  yield takeEvery(AT.dailyReport.GET_DAILY_REPORT_LIST, sagas.dailyReport.getDailyReportList);
  yield takeEvery(AT.dailyReport.SAVE_DAILY_REPORT, sagas.dailyReport.saveDailyReport);
  // yield takeEvery(AT.mgGoodsInfo.SET_COMPLEX_IMAGE, sagas.mgGoodsInfo.uploadComplexImage);
  // yield takeEvery(AT.mgGoodsInfo.DEL_COMPLEX_IMAGE, sagas.mgGoodsInfo.delComplexImage);

  //mgGoodsInfo
  yield takeEvery(AT.mgComplexImg.GET_COMPLEX_IMG_LIST, sagas.mgComplexImg.getComplexImgList);
  yield takeEvery(AT.mgComplexImg.SET_COMPLEX_IMAGE, sagas.mgComplexImg.uploadComplexImage);
  yield takeEvery(AT.mgComplexImg.DEL_COMPLEX_IMAGE, sagas.mgComplexImg.delComplexImage);

  //community
  yield takeEvery(AT.community.GET_BROKER_COMMUNITY_FEEDS, sagas.community.getFeeds);
  yield takeEvery(AT.community.GET_SAFETY_NUMBER, sagas.community.getSafetyNumber);

  //jointBrokerage
  yield takeEvery(AT.jointBrokerage.GET_JOINT_BROKERAGE_LIST, sagas.jointBrokerage.getJointBrokerageList);
  yield takeEvery(AT.jointBrokerage.GET_JOINT_BROKERAGE_DETAIL, sagas.jointBrokerage.getJointBrokerageDetail);
  yield takeEvery(AT.jointBrokerage.SAVE_JOINT_REPLY, sagas.jointBrokerage.saveJointReply);
  yield takeEvery(AT.jointBrokerage.SAVE_JOINT_NEW, sagas.jointBrokerage.saveJointNew);
  yield takeEvery(AT.jointBrokerage.EDIT_JOINT, sagas.jointBrokerage.editJoint);
  yield takeEvery(AT.jointBrokerage.DEL_JOINT, sagas.jointBrokerage.delJoint);

  //중개 QnA
  yield takeEvery(AT.bkQnA.GET_BK_QNA_LIST, sagas.bkQnA.getBkQnAList);
  yield takeEvery(AT.bkQnA.GET_BK_QNA_DETAIL, sagas.bkQnA.getBkQnADetail);
  yield takeEvery(AT.bkQnA.SAVE_BK_QNA_REPLY, sagas.bkQnA.saveBkQnAReply);
  yield takeEvery(AT.bkQnA.DEL_BK_QNA_REPLY, sagas.bkQnA.delBkQnAReply);
  yield takeEvery(AT.bkQnA.EDIT_BK_QNA_REPLY, sagas.bkQnA.editBkQnAReply);
  yield takeEvery(AT.bkQnA.SAVE_BK_QNA_NEW, sagas.bkQnA.saveBkQnANew);
  yield takeEvery(AT.bkQnA.EDIT_BK_QNA, sagas.bkQnA.editBkQnANew);
  yield takeEvery(AT.bkQnA.DEL_BK_QNA, sagas.bkQnA.delBkQnANew);

  //자료실
  yield takeEvery(AT.dataRoom.GET_DR_FORM_LIST, sagas.dataRoom.form_getList);
  yield takeEvery(AT.dataRoom.GET_DR_FORM_DETAIL, sagas.dataRoom.form_getDetail);
  yield takeEvery(AT.dataRoom.GET_DR_PRECEDENT_LIST, sagas.dataRoom.precedent_getList);
  yield takeEvery(AT.dataRoom.GET_DR_PRECEDENT_DETAIL, sagas.dataRoom.precedent_getDetail);
  yield takeEvery(AT.dataRoom.GET_DR_USEFUL_LIST, sagas.dataRoom.useful_getList);
  yield takeEvery(AT.dataRoom.GET_DR_USEFUL_DETAIL, sagas.dataRoom.useful_getDetail);

  //고객센터 - 건의사항
  yield takeEvery(AT.suggestion.GET_SUGGESTION_LIST, sagas.suggestion.getSuggestionList);
  yield takeEvery(AT.suggestion.GET_SUGGESTION_DETAIL, sagas.suggestion.getSuggestionDetail);
  yield takeEvery(AT.suggestion.SAVE_SUGGESTION_NEW, sagas.suggestion.saveSuggestionNew);

  //내부중가사 - 공지사항
  yield takeEvery(AT.internalNotice.GET_INTERNAL_NOTICE_LIST, sagas.internalNotice.getNoticeList);
  yield takeEvery(AT.internalNotice.GET_INTERNAL_NOTICE_DETAIL, sagas.internalNotice.getNoticeDetail);

  //고객센터 - 공지사항
  yield takeEvery(AT.notice.GET_NOTICE_LIST, sagas.notice.getNoticeList);
  yield takeEvery(AT.notice.GET_NOTICE_DETAIL, sagas.notice.getNoticeDetail);

  //고객센터 - 1:1문의
  yield takeEvery(AT.oneOnOne.GET_ONEONONE_LIST, sagas.oneOnOne.getOneOnOneList);
  yield takeEvery(AT.oneOnOne.SAVE_ONEONONE_NEW_QNA, sagas.oneOnOne.saveNewQna);
}
