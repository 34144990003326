import * as AT from './actionTypes';

export const form_getAttach = (state) => state?.brokerPage?.dataRoom?.formNformat?.new?.attach;
export const form_getKeyword = (state) => state?.brokerPage?.dataRoom?.formNformat?.keyword;
export const form_getPage = (state) => state?.brokerPage?.dataRoom?.formNformat?.page;
export const form_getTotalCnt = (state) => state?.brokerPage?.dataRoom?.formNformat?.totalCnt;
export const form_getList = (state) => state?.brokerPage?.dataRoom?.formNformat?.conlist;
export const form_getDetail = (state) => state?.brokerPage?.dataRoom?.formNformat?.detail;
export const form_getDetailIdx = (state) => state?.brokerPage?.dataRoom?.formNformat?.detailIdx;
export const form_getPreIdx = (state) => state?.brokerPage?.dataRoom?.formNformat?.detail?.pre_idx;
export const form_getPreTitle = (state) => state?.brokerPage?.dataRoom?.formNformat?.detail?.pre_title;
export const form_getNextIdx = (state) => state?.brokerPage?.dataRoom?.formNformat?.detail?.next_idx;
export const form_getNextTitle = (state) => state?.brokerPage?.dataRoom?.formNformat?.detail?.next_title;

export const precedent_getAttach = (state) => state?.brokerPage?.dataRoom?.precedent?.new?.attach;
export const precedent_getKeyword = (state) => state?.brokerPage?.dataRoom?.precedent?.keyword;
export const precedent_getPage = (state) => state?.brokerPage?.dataRoom?.precedent?.page;
export const precedent_getTotalCnt = (state) => state?.brokerPage?.dataRoom?.precedent?.totalCnt;
export const precedent_getList = (state) => state?.brokerPage?.dataRoom?.precedent?.conlist;
export const precedent_getDetail = (state) => state?.brokerPage?.dataRoom?.precedent?.detail;
export const precedent_getDetailIdx = (state) => state?.brokerPage?.dataRoom?.precedent?.detailIdx;
export const precedent_getPreIdx = (state) => state?.brokerPage?.dataRoom?.precedent?.detail?.pre_idx;
export const precedent_getPreTitle = (state) => state?.brokerPage?.dataRoom?.precedent?.detail?.pre_title;
export const precedent_getNextIdx = (state) => state?.brokerPage?.dataRoom?.precedent?.detail?.next_idx;
export const precedent_getNextTitle = (state) => state?.brokerPage?.dataRoom?.precedent?.detail?.next_title;

export const useful_getAttach = (state) => state?.brokerPage?.dataRoom?.useful?.new?.attach;
export const useful_getKeyword = (state) => state?.brokerPage?.dataRoom?.useful?.keyword;
export const useful_getPage = (state) => state?.brokerPage?.dataRoom?.useful?.page;
export const useful_getTotalCnt = (state) => state?.brokerPage?.dataRoom?.useful?.totalCnt;
export const useful_getList = (state) => state?.brokerPage?.dataRoom?.useful?.conlist;
export const useful_getDetail = (state) => state?.brokerPage?.dataRoom?.useful?.detail;
export const useful_getDetailIdx = (state) => state?.brokerPage?.dataRoom?.useful?.detailIdx;
export const useful_getPreIdx = (state) => state?.brokerPage?.dataRoom?.useful?.detail?.pre_idx;
export const useful_getPreTitle = (state) => state?.brokerPage?.dataRoom?.useful?.detail?.pre_title;
export const useful_getNextIdx = (state) => state?.brokerPage?.dataRoom?.useful?.detail?.next_idx;
export const useful_getNextTitle = (state) => state?.brokerPage?.dataRoom?.useful?.detail?.next_title;
