import React, { useMemo, useRef } from 'react';
import { geInitialData, myAreaButtons, setAddressInputs } from './utils';
import SelectSearchView from './SelectSearch';
import MyAddressListBtns from './MyAddressListBtns';
import { useSetOpenPopup } from '../../hooks';

export { geInitialData };

// 모드 (단지로검색, 주소로검색)
export const MODE = { TEXT: 'TEXT', SELECT: 'SELECT' };

// markTypes (모든 건물들)
export const EVERY_BUILDINGS = ['APT', 'OFT', 'ABYG', 'JGC', 'OBYG'];

// markTypes (타입별 그룹)
export const MARK_TYPES_BY_GROUP = {
  APT: ['APT', 'ABYG', 'JGC'],
  OFT: ['OFT', 'OBYG'],
};

/**
 * @param {object} data (required) { mode, selectedAddress, selectedComplex } 형태의 json
 * @param {function} setData (required)
 * { mode, selectedAddress, selectedComplex } 형태의 json 데이터를 전달받는 함수
 *   1. mode: TEXT (단지로 검색) | SELECT (주소로 검색)
 *   2. selectedAddress: 선택된 주소 정보
 *    - { sido: { code, name }, sigungu: { code, name }, dong: { code, name } }
 *   3. selectedComplex: 선택된 단지 정보
 *    - { complex_idx, complex_type, complex_name }
 * @param {boolean} tenDigitCode (optional) address 값 반환 시 10자리로 반환
 * @param {string} title (optional) default: 단지검색, 주소검색
 *
 * mode가 TEXT일 때 유효한 옵션들
 * @param {Array} markTypes (optional) 표시할 단지 타입 리스트.
 * @param {function} markRule (optional) 필터링 함수. 검색 결과 전체를 파라미터로 받아
 *
 * mode가 SELECT일 때 유효한 옵션들
 * @param {boolean} addAllOption (optional) 전체 선택 옵션 on/off
 */
export default function Address({
  dualAddressData,
  setDualAddressData,
  tenDigitCode = false,
  title,
  addAllOption = false,
  tags,
  setTags,
  feedType,
  disabled = false, // 아직 쓰이는지 모르겠음.
  setSelectedGoodsTypes,
}) {
  // 주소 데이터
  const addressData = dualAddressData?.selectedAddress || {};
  const setAddressData = (nextAddress) => {
    setDualAddressData({ ...dualAddressData, mode: 'SELECT', selectedAddress: nextAddress });
  };

  // 단지 데이터
  const complexData = dualAddressData?.selectedComplex || { '123': 'qwe' };
  const setComplexData = (nextComplex) => {
    setDualAddressData({ ...dualAddressData, mode: 'SELECT', selectedComplex: nextComplex });
  };

  const preventUpdateComplexData = useRef(dualAddressData?.mode === MODE.SELECT ? true : false);

  // 동코드
  const dongCode = useMemo(() => `${addressData?.dong?.code ? addressData?.dong?.code : ''}`, [addressData]);

  const [openPopupCallback] = useSetOpenPopup();

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <p className="form-subject">
            <span className="form-name">{title}</span>
          </p>
          <p className="form-summary">어디에서 구해드릴까요? 지역(구, 동)이나, 단지를 선택해 주세요</p>
        </div>

        <div className="form-body bui-tab-target active" id="searchAddress" data-bui-form="dataFinder">
          <MyAddressListBtns
            btnList={myAreaButtons({ tags })}
            openPopupCallback={openPopupCallback}
            applyBtnCallback={setAddressInputs}
            setAddressData={setAddressData}
            setComplexData={setComplexData}
            tags={tags}
            setTags={setTags}
            data={dualAddressData}
            setDualAddressData={setDualAddressData}
            setSelectedGoodsTypes={setSelectedGoodsTypes}
          />
          <SelectSearchView
            addressData={addressData}
            setAddressData={setAddressData}
            addAllOption={addAllOption}
            disabled={disabled}
            tenDigitCode={tenDigitCode}
            dongCode={dongCode}
            complexData={complexData}
            setComplexData={setComplexData}
            preventUpdateComplexData={preventUpdateComplexData}
            feedType={feedType}
            tags={tags}
          />
        </div>
      </div>
    </div>
  );
}
