import React from 'react';
import styled from 'styled-components';
import { goodsUtil } from '@/services';
import { priceToText } from '@/pages/Buying/util';

const TRADE_TYPE_CLASS_OPTIONS = {
  s: 'type01',
  c: 'type02',
  m: 'type03',
};

const Price = (data) => {
  const { trade_type, price1, supply_pyeong } = data || {};

  const tradeTypeInText = goodsUtil.tradeTypes.getName(trade_type);

  const tradeTypeClass = TRADE_TYPE_CLASS_OPTIONS[trade_type];

  const priceInText = goodsUtil.price.getPrice(data, { formatter: priceToText });

  const showPerPrice = supply_pyeong && trade_type == 's';

  return (
    <span className="real-price">
      <i className={tradeTypeClass}>{tradeTypeInText}</i>
      {priceInText}

      {showPerPrice && <PerPrice className="per_price">{`평당 ${priceToText(Math.floor(Number(price1) / Number(supply_pyeong)))}`}</PerPrice>}
    </span>
  );
};

const PerPrice = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding: 2px 7px;
  height: 16px;
  line-height: 15px;
  background: #efefef;
  color: #8e8e8e;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 500;
  margin-top: -5px;
`;

export default React.memo(Price);
