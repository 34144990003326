import React from 'react';
import { Link } from 'react-router-dom';

const ComplexTag = ({ complexTag, handleComplex }) => {
  return complexTag ? (
    <Link className="btn module-a style-c type-fill normal-05 medium" to="#" onClick={handleComplex}>
      <span className="btn-text">{complexTag.name}</span>
    </Link>
  ) : (
    false
  );
};

export default ComplexTag;
