import * as AT from './actionTypes';

export const isMobile = (width) => ({
  type: AT.IS_MOBILE,
  width,
});

export const getComplexArea = (complex_idx) => ({
  type: AT.GET_COMPLEX_AREA,
  complex_idx,
});

export const setComplexArea = (complex_idx, list) => ({
  type: AT.GET_COMPLEX_AREA_SUCCESS,
  complex_idx,
  list,
});

/* 전용면적, 공급면적, 평형명, 등 모두 포함 */
export const getComplexArea2 = (complex_idx) => ({
  type: AT.GET_COMPLEX_AREA2,
  complex_idx,
});

export const setComplexArea2 = (list) => ({
  type: AT.SET_COMPLEX_AREA2,
  list,
});

export const togglePopup = () => ({
  type: AT.TOGGLE_POPUP,
});

export const searchKeyword = ({ keyword, totalSearch, withMinhouseholdcnt }) => ({
  type: AT.SEARCH_KEYWORD,
  keyword,
  totalSearch,
  withMinhouseholdcnt,
});

export const searchKeywordLoading = (data) => ({
  type: AT.SEARCH_KEYWORD_LOADING,
  data,
});

export const searchKeywordSuccess = (data) => ({
  type: AT.SEARCH_KEYWORD_SUCCESS,
  data,
});

export const searchKeywordFailure = (error) => ({
  type: AT.SEARCH_KEYWORD_FAILURE,
  error,
});

export const uploadFile = ({ category, file, setter }) => ({
  type: AT.UPLOAD_FILE,
  category,
  file,
  setter,
});
export const uploadAnyFile = ({ category, file, setter }) => ({
  type: AT.UPLOAD_ANY_FILE,
  category,
  file,
  setter,
});

/**
 * @param option (optional)
 * {
 *    text: 로그인 팝업 내용,
 *    onSuccess: 로그인 성공 한 경우 처리할 액션
 * }
 */
export const pleaseLoginPopup = (show, option = null) => ({
  type: AT.PLEASE_LOGIN_POPUP,
  show,
  option,
});

// pleaseLoginPopup 액션의 option.onSuccess를 저장
export const actionAfterLogin = (callback) => ({
  type: AT.ACTION_AFTER_LOGIN,
  callback,
});

export const toggleLoginPopup = (show) => ({
  type: AT.TOGGLE_LOGIN_POPUP,
  show,
});

export const getFaqList = () => ({
  type: AT.GET_FAQ_LIST,
});

export const setFaqList = (list) => ({
  type: AT.SET_FAQ_LIST,
  list,
});

export const addQuestion = (values, setLoadingStatus, callback) => ({
  type: AT.ADD_QUESTION,
  values,
  setLoadingStatus,
  callback,
});

export const addQuestionSuccess = (data) => ({
  type: AT.ADD_QUESTION_SUCCESS,
  data,
});

export const addQuestionNotAsked = (data) => ({
  type: AT.ADD_QUESTION_NOTASKED,
  data,
});

export const addQuestionFailure = (error) => ({
  type: AT.ADD_QUESTION_FAILURE,
  error,
});

export const addQuestionLoading = () => ({
  type: AT.ADD_QUESTION_LOADING,
});

export const getQnaCategoryList = () => ({
  type: AT.GET_QNA_CATEGORY_LIST,
});

export const setQnaCategoryList = (list) => ({
  type: AT.SET_QNA_CATEGORY_LIST,
  list,
});

export const reportBroker = (values) => ({
  type: AT.REPORT_BROKER,
  values,
});

export const reportBrokerFailure = (error) => ({
  type: AT.REPORT_BROKER_FAILURE,
  error,
});

export const reportBrokerSuccess = () => ({
  type: AT.REPORT_BROKER_SUCCESS,
});

export const reportBrokerLoading = () => ({
  type: AT.REPORT_BROKER_LOADING,
});

export const reportBrokerNotasked = () => ({
  type: AT.REPORT_BROKER_NOTASKED,
});

export const getMyBrokerList = () => ({
  type: AT.GET_MY_BROKER_LIST,
});

export const setMyBrokerList = (data) => ({
  type: AT.SET_MY_BROKER_LIST,
  data,
});

// sort": 정렬 방식, // "evaluated-count": 평가 갯수 정렬, "evaluated-point": 평점 정렬
export const getSearchBrokerList = (dongcode, keywords, sort) => ({
  type: AT.GET_SEARCH_BROKER_LIST,
  dongcode,
  keywords,
  sort,
});

// paload: { id_type, id, sort }
//    - id-type: id-type 에는 "SI-GUN-GU", "DONG", "COMPLEX" 3가지가 존재한다.
//    - id: 에는 타입에 따른 아이디가 입력된다.
//    - sort: sort 에는 "evaluated-point", "evaluated-count" 2가지가 존재한다.
export const getSearchBrokerListBySetting = (setData, payload) => ({
  type: AT.GET_SEARCH_BROKER_LIST_BY_SETTING,
  setData,
  payload,
});

export const setSearchBrokerList = (data) => ({
  type: AT.SET_SEARCH_BROKER_LIST,
  data,
});

/** @comment 웹에서는 팝업, 모바일에서는 새주소로 이동하는 경우 UI 처리
 *  components/GlobalDualPopups 컴포넌트 참조 **/
export const openGlobalDualPopup = (popupType) => ({
  type: AT.OPEN_GLOBAL_DUAL_POPUP,
  popupType,
});

export const closeGlobalDualPopup = () => ({
  type: AT.CLOSE_GLOBAL_DUAL_POPUP,
});

/**
 * (NEW) alert (버튼 1개 팝업)
 * @param {string || Object} contents (필수) 내용. (내용이 한 줄이라면 string으로 보내고, 마크업이 더 들어간 내용이라면 className="board-wrap" 아래 마크업부터 넣는다.)
 * @param {function} onConfirm (선택) 확인 버튼 클릭 시 발생하는 이벤트
 * @param {String} confirmButtonText (선택) 확인 버튼 대체 텍스트
 * @param {object} options (선택) strictClose {boolean} , showCloseButton {boolean}
 * **/
export const alert = ({ contents, onConfirm, confirmButtonText, options }) => ({
  type: AT.ALERT,
  contents,
  onConfirm,
  confirmButtonText,
  options,
});

export const clearAlert = () => ({
  type: AT.CLEAR_ALERT,
});

/**
 * (NEW) confrim (버튼 2개 팝업)
 * @param {string || Object} contents (필수) 내용. (내용이 한 줄이라면 string으로 보내고, 마크업이 더 들어간 내용이라면 className="board-wrap" 아래 마크업부터 넣는다.)
 * @param {function} onConfirm (선택) 확인 버튼 클릭 시 발생하는 이벤트 (오른쪽 버튼)
 * @param {function} onCancel (선택) 취소 버튼 클릭 시 발생하는 이벤트 (왼쪽 버튼)
 * @param {String} confirmButtonText (선택) 확인 버튼 대체 텍스트 (오른쪽 버튼)
 * @param {String} cancelButtonText (선택) 취소 버튼 대체 텍스트 (왼쪽 버튼)
 * @param {Object} options (선택) {originalCancelForX: true}를 넣으면 우측 상단에 x버튼 생성
 * **/
export const confirm = ({ contents, onConfirm, onCancel, confirmButtonText, cancelButtonText, ...rest }) => ({
  type: AT.CONFIRM,
  contents,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  ...rest,
});

export const clearConfirm = () => ({
  type: AT.CLEAR_CONFIRM,
});

//관심단지---
export const addFavComplex = (complex_idx, area_list) => ({
  type: AT.ADD_FAV_COMPLEX,
  complex_idx,
  area_list,
});

export const addFavComplexLoading = () => ({
  type: AT.ADD_FAV_COMPLEX_LOADING,
});

export const addFavComplexSuccess = (data) => ({
  type: AT.ADD_FAV_COMPLEX_SUCCESS,
  data,
});

export const addFavComplexFailure = (error) => ({
  type: AT.ADD_FAV_COMPLEX_FAILURE,
  error,
});

export const openMobileFavoBox = (show) => ({
  type: AT.OPEN_MOBILE_FAVO_BOX,
  show,
});

export const getFavComplexList = () => ({
  type: AT.GET_FAV_COMPLEX_LIST,
});

export const setFavComplexList = (data) => ({
  type: AT.SET_FAV_COMPLEX_LIST,
  data,
});

export const initalUpdateTempFavCompList = (data) => ({
  type: AT.INITIAL_UPDATE_TEMP_FAV_COMP_LIST,
  data,
});

//리스트 새로고침 되기 전에, 좋아요 추가된 단지 UI 표시용
export const updateTempFavCompList = (complex_idx, isFav) => ({
  type: AT.UPDATE_TEMP_FAV_COMP_LIST,
  complex_idx,
  isFav,
});

export const initalUpdateTempFavLocalList = (data) => ({
  type: AT.INITIAL_UPDATE_TEMP_FAV_LOCAL_LIST,
  data,
});

export const updateTempFavLocalList = (local_idx, isFav) => ({
  type: AT.UPDATE_TEMP_FAV_LOCAL_LIST,
  local_idx,
  isFav,
});

export const handleFavGoods = (acttype, goods_idx) => ({
  type: AT.HANDLE_FAV_GOODS,
  acttype,
  goods_idx,
});

//새로고침 되기 전에, 추가된 관심매물 UI 표시용
export const updateTempFavGoodsList = (goodsIdx, isFav) => ({
  type: AT.UPDATE_TEMP_FAV_GOODS_LIST,
  goodsIdx,
  isFav,
});

export const setCertificatedInfo = (data) => ({
  type: AT.SET_CERTIFICATED_INFO,
  data,
});

export const toggleDistrictView = (manual) => ({
  type: AT.TOGGLE_DISTRICT_VIEW,
  manual,
});

export const toggleGlobalSpinner = (payload) => ({
  type: AT.TOGGLE_GLOBAL_SPINNER,
  payload,
});

export const updateAppCallback = (payload) => ({
  type: AT.UPDATE_APP_DATA,
  payload,
});

/**
 * @param {string} key uniq 키
 * @param {string} href css href
 */
export const addDynamicCssImport = (key, hrefs) => ({
  type: AT.ADD_DYNAMIC_CSS_IMPORT,
  key,
  hrefs,
});

/**
 * @param {object} element
 ** (필수)
 ** [{ id: 0, src: "https://...", caption: "..." }] 형식
 *
 * @param {object} options
 ** (선택)
 ** settings, buttons, caption, thumbnails, progressBar에 대한 설정
 ** 옵션의 종류들은 https://yarnpkg.com/package/simple-react-lightbox#options 웹사이트 README 부분 참고 *
 */
// GlobalGallery (이미지 클릭시 확대되는) 열기
export const openGlobalGallery = ({ elements, customCaptions, options }) => ({
  type: AT.OPEN_GLOBAL_GALLERY,
  elements,
  customCaptions,
  options,
});

// GlobalGallery (이미지 클릭시 확대되는) 닫기
export const closeGlobalGallery = () => ({
  type: AT.CLOSE_GLOBAL_GALLERY,
});
