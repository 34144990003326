import { actions } from '@/data';
import { infoPopup } from '@/publish/Front/_Component/popup/InfoPopup';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const PostForBuySuccess = ({ status }) => {
  const dispatch = useDispatch();

  const routeToMyPost = (e) => {
    e.preventDefault();
    const id = status?.result?.id;
    dispatch(actions.router.replace(`/community?feedDetail=${id}`));
    infoPopup.inactive('infoPopup');
    dispatch(actions.common.clearAlert());
  };

  return (
    <>
      <div className="board-head">
        <p className="board-subject">
          <span className="board-name">구해줘다윈 요청이 접수되었습니다.</span>
        </p>
      </div>
      <div className="board-body">
        <p className="para">
          <span className="wbr">다윈중개 중개사들이 전화, 채팅 또는</span> <span className="wbr">댓글로 연락 드릴 예정입니다.</span>
        </p>
      </div>
      <div className="board-util">
        <div className="button-display module-a style-a type-c">
          <span className="button-area">
            <Link className="btn module-a style-c type-fill accent-03 small symbol-rtl-chevron-right flex" to="#" onClick={routeToMyPost}>
              <span className="btn-text">내 요청 보러가기</span>
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default PostForBuySuccess;
