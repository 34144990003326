import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// exceptions
const REGEX_SELLING_MANUAL = /^\/(selling|brokerPage)\/selling_manual.*$/;

/** @comment 스크롤 복원 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!REGEX_SELLING_MANUAL.test(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
