export const SHARE_POPUP_TYPES = {
  BASIC: 'basic',
  PROFILE: 'profile',
  LIST: 'list',
  FEED: 'feed',
};

export const KAKAO_SHARE_TEMPLATE_ID = {
  // 기본 템플릿 (이미지, 타이틀, 디스크립션, 버튼1개 형식)
  BASIC_TEST: 97134,
  BASIC_PROD: 97135,

  // 프로필 공유 템플릿 (다윈중개 로고 이미지, 프로필 이름, 프로필 설명, 프로필 이미지, 프로필 보기버튼 형식)
  PROFILE_TEST_NEW: 97144,
  PROFILE_TEST: 96917,
  PROFILE_PROD: 96920,

  // 리스트 공유 템플릿 (헤더, 리스트, 버튼 1개 형식)
  LIST_TEST: 97188,
  LIST_PROD: 97192,

  // 피드 공유 템플릿 (이미지, 작성자 이미지, 작성자 이름, 타이틀, 디스크립션, 라이크 수, 코멘트 수, 버튼1개 형식)
  FEED_TEST: 97258,
  FEED_PROD: 97305,
};

export const SHARE_FROM = {
  KAKAO: 'share_kakao',
  FACEBOOK: 'share_fb',
  URL: 'share_url',
  APP: 'share_app',
};
