import { actions } from '@/data';
import React from 'react';
import { useDispatch } from 'react-redux';

const useDeleteFeed = ({ setDeleteStatus }) => {
  const dispatch = useDispatch();

  const onDeleteFeed = (e, postId) => {
    e.preventDefault();

    dispatch(
      actions.common.confirm({
        contents: '글을 삭제하시겠습니까?',
        onConfirm: () => {
          dispatch(actions.community.deleteFeed(postId, setDeleteStatus));
        },
      })
    );
  };

  return { onDeleteFeed };
};

export default useDeleteFeed;
