import { SHARE_FROM, SHARE_POPUP_TYPES } from '../constants';
import { getPath } from '@/components/popups/SocialSharePopup/utils.js';

const useGetTemplateArgs = (templateType, templateArgs) => {
  const {
    MAIN_IMG_FIRST,
    TITLE,
    DESC,
    PROFILE_TITLE,
    PROFILE_DESC,
    PROFILE_IMG,
    BTN_NAME_FIRST,
    COMMON_PATH,
    HEADER,
    LIST_TITLE_FIRST,
    LIST_CONTENTS_FIRST,
    LIST_IMG_FIRST,
    LIST_TITLE_SECOND,
    LIST_CONTENTS_SECOND,
    LIST_IMG_SECOND,
    LIST_TITLE_THIRD,
    LIST_CONTENTS_THIRD,
    LIST_IMG_THIRD,
    LIST_TITLE_FOURTH,
    LIST_CONTENTS_FOURTH,
    LIST_IMG_FOURTH,
    LIST_TITLE_FIFTH,
    LIST_CONTENTS_FIFTH,
    LIST_IMG_FIFTH,
    // WRITER_IMG,
    // WRITER_NAME,
    LIKES,
    COMMENTS,
  } = templateArgs || {};

  let args = {};

  if (templateType === SHARE_POPUP_TYPES.BASIC) {
    args = {
      MAIN_IMG_FIRST: MAIN_IMG_FIRST || 'https://dawin-front-v2-public.s3.ap-northeast-2.amazonaws.com/images/dawin_logo_square.jpg',
      TITLE: TITLE,
      DESC: DESC,
      BTN_NAME_FIRST: BTN_NAME_FIRST || '자세히 보기',
      COMMON_PATH: `${getPath({ path: COMMON_PATH, from: SHARE_FROM.KAKAO })}`,
    };
  } else if (templateType === SHARE_POPUP_TYPES.PROFILE) {
    args = {
      MAIN_IMG_FIRST: MAIN_IMG_FIRST || 'https://dawin-front-v2-public.s3.ap-northeast-2.amazonaws.com/images/dawin_logo_square.jpg',
      PROFILE_TITLE: PROFILE_TITLE || '',
      PROFILE_DESC: PROFILE_DESC || '',
      PROFILE_IMG: PROFILE_IMG || 'https://dawin-front-v2-public.s3.ap-northeast-2.amazonaws.com/images/dawin_icon_square.jpg',
      BTN_NAME_FIRST: BTN_NAME_FIRST || '프로필 보기',
      COMMON_PATH: `${getPath({ path: COMMON_PATH, from: SHARE_FROM.KAKAO })}`,
    };
  } else if (templateType === SHARE_POPUP_TYPES.LIST) {
    args = {
      HEADER: HEADER || '다윈중개',
      LIST_TITLE_FIRST,
      LIST_CONTENTS_FIRST,
      LIST_IMG_FIRST,
      LIST_TITLE_SECOND,
      LIST_CONTENTS_SECOND,
      LIST_IMG_SECOND,
      LIST_TITLE_THIRD,
      LIST_CONTENTS_THIRD,
      LIST_IMG_THIRD,
      LIST_TITLE_FOURTH,
      LIST_CONTENTS_FOURTH,
      LIST_IMG_FOURTH,
      LIST_TITLE_FIFTH,
      LIST_CONTENTS_FIFTH,
      LIST_IMG_FIFTH,
      BTN_NAME_FIRST: BTN_NAME_FIRST || '더보기',
      COMMON_PATH: `${getPath({ path: COMMON_PATH, from: SHARE_FROM.KAKAO })}`,
    };
  } else if (templateType === SHARE_POPUP_TYPES.FEED) {
    args = {
      MAIN_IMG_FIRST: MAIN_IMG_FIRST || 'https://dawin-front-v2-public.s3.ap-northeast-2.amazonaws.com/images/dawin_logo_square.jpg',
      // WRITER_IMG: WRITER_IMG || 'https://dawin-front-v2-public.s3.ap-northeast-2.amazonaws.com/images/dawin_icon_square.jpg',
      // WRITER_NAME: WRITER_NAME,
      TITLE: TITLE,
      DESC: DESC,
      LIKES: LIKES,
      COMMENTS: COMMENTS,
      BTN_NAME_FIRST: BTN_NAME_FIRST || '게시물 보기',
      COMMON_PATH: `${getPath({ path: COMMON_PATH, from: SHARE_FROM.KAKAO })}`,
    };
  }
  return args;
};

export default useGetTemplateArgs;
