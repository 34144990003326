import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// 매물 상세로 바로 이동
// initial_area: (optional) 대표평형이 아닌 평형으로 최초 rendering
export const routeToGoodsDetailDirect = (complex_idx, trade_type, pyeong, goods_idx, initial_area) => (dispatch) => {
  const newPath = getGoodsDetailDirectPath(complex_idx, trade_type, pyeong, goods_idx, initial_area);
  const watching = location.search.indexOf('d_complex') > -1;
  if (watching) {
    dispatch(actions.router.replace(newPath));
  } else {
    dispatch(actions.router.push(newPath));
  }
};

export const getGoodsDetailDirectPath = (complex_idx, trade_type, pyeong, goods_idx, initial_area, pathname = location.pathname) => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  queryObject.d_complex = complex_idx;
  // queryObject.d_market_list = true;
  queryObject.d_trade_type = trade_type;
  queryObject.d_area = pyeong;

  delete queryObject.goods_idx;

  if (goods_idx) queryObject.goods_idx = goods_idx;
  if (initial_area) queryObject.initial_area = initial_area;

  const newQuerystring = objectToQuerystring(queryObject);

  return `${pathname}${newQuerystring}`;
};
