// ===================== 공통 ===================== //
export const ANALYSIS_ERROR = 'ANALYSIS/ANALYSIS_ERROR';
export const ANALYSIS_RESET_ERROR_STATE = 'ANALYSIS/ANALYSIS_RESET_ERROR_STATE';
export const OPEN_DETAIL_SECTION = 'OPEN_DETAIL_SECTION';
export const CLOSE_DETAIL_SECTION = 'CLOSE_DETAIL_SECTION';

// ===================== 지도 동작 ===================== //
export const TOGGLE_ANALYSIS = 'ANALYSIS/TOGGLE_ANALYSIS';
export const TOGGLE_FUNCTIONAL_POPUP = 'ANALYSIS/TOGGLE_FUNCTIONAL_POPUP';
export const TOGGLE_FUNCTIONAL_POPUP_DESC = 'ANALYSIS/TOGGLE_FUNCTIONAL_POPUP_DESC';
export const SET_ANALYSIS_CATEGORY = 'ANALYSIS/SET_ANALYSIS_CATEGORY';
// export const SET_ANALYSIS_MAP_CENTER = 'ANALYSIS/SET_ANALYSIS_MAP_CENTER';
// export const SET_ANALYSIS_MAP_ZOOMLEVEL = 'ANALYSIS/SET_ANALYSIS_MAP_ZOOMLEVEL';
// export const SET_MAP_CLICKED_LATLNG = 'ANALYSIS/SET_ANALYSIS_CLICKED_LATLNG';

// ===================== 지도 표시 내용 ===================== //
// 검색을 통해 찾는 기능 (검색어 입력 시, 검색창 하단에 검색어에 대한 후보 목록을 보여주기 위함)
export const GET_ANALYSIS_CANDIDATE_LIST_SEARCH = 'ANALYSIS/GET_ANALYSIS_CANDIDATE_LIST_SEARCH';
export const SET_ANALYSIS_CANDIDATE_LIST_SEARCH = 'ANALYSIS/SET_ANALYSIS_DISPLAY_LIST_SEARCH';

// 검색 후보 목록에서 하나의 옵션을 클릭할 때
export const SET_TARGET_CANDIDATE = 'ANALYSIS/SET_TARGET_CANDIDATE';

// 맵 이동하면,(줌레벨 변하거나) 맵의 변화에 따라 보여주는 목록을 받아온다
//
export const GET_ANALYSIS_DISPLAY_LIST_MAP = 'ANALYSIS/GET_ANALYSIS_DISPLAY_LIST_MAP';
export const SET_ANALYSIS_DISPLAY_LIST_MAP = 'ANALYSIS/SET_ANALYSIS_DISPLAY_LIST_MAP';

// ===================== 하단 Notice PopUp (카테고리 선택 팝업과 동일한 자리) ===================== //
export const SET_POP_UP_TYPE = 'ANALYSIS/SET_POP_UP_TYPE';

// 개발호재 알리기
export const SET_POSITIVES_TYPE = 'ANALYSIS/SET_POSITIVES_TYPE';
export const SET_POSITIVES_TXT = 'ANALYSIS/SET_POSITIVES_TXT';
export const SET_POSITIVES_URL = 'ANALYSIS/SET_POSITIVES_URL';

export const POST_ADD_POSITIVES = 'ANALYSIS/POST_ADD_POSITIVES';

// 주간실거래가
export const GET_ANALYSIS_WEEKLY_PRICE_INFO = 'ANALYSIS/GET_ANALYSIS_WEEKLY_PRICE_INFO';

export const SET_ANALYSIS_WEEKLY_PRICE_LIST = 'ANALYSIS/SET_ANALYSIS_WEEKLY_PRICE_LIST';
export const SET_ANALYSIS_WEEKLY_PRICE_GENERAL_INFO = 'ANALYSIS/SET_ANALYSIS_WEEKLY_PRICE_GENERAL_INFO';

// 교육
export const SET_ANALYSIS_SCHOOL_TYPE = 'ANALYSIS/SET_ANALYSIS_SCHOOL_TYPE'; // 학군
export const SET_ACADEMY_MARKER_LIST = 'SET_ACADEMY_MARKER_LIST';
export const SET_IS_FIRST_SCHOOL_TYPE = 'SET_IS_FIRST_SCHOOL_TYPE';
export const SET_SCHOOL_INFO = 'SET_SCHOOL_INFO';
export const GET_SCHOOL_INFO = 'GET_SCHOOL_INFO';
export const GET_SCHOOL_WITH_DETAIL = 'GET_SCHOOL_WITH_DETAIL';

//랭킹
export const SET_ANALYSIS_RANKING_TYPE = 'ANALYSIS/SET_ANALYSIS_RANKING_TYPE'; // 랭킹
export const SET_ANALYSIS_RANKING_SPAN = 'ANALYSIS/SET_ANALYSIS_RANKING_SPAN'; // 1개월, 1년 선택하는경우
export const GET_ANALYSIS_RANKING_RESULT_LIST = 'ANALYSIS/GET_ANALYSIS_RANKING_RESULT_LIST';
export const SET_ANALYSIS_RANKING_RESULT_LIST = 'ANALYSIS/SET_ANALYSIS_RANKING_RESULT_LIST';
export const RESET_RANKING_CNT = 'RESET_RANKING_CNT';
export const MORE_RANKING_CNT = 'MORE_RANKING_CNT';
export const SHOW_RANKING_LIST_FOR_MOBILE = 'SHOW_RANKING_LIST_FOR_MOBILE'; //리스트로 더 보기
export const SEARCH_ANOTHER_RANKING_FOR_MOBILE = 'SEARCH_ANOTHER_RANKING_FOR_MOBILE'; //랭킹 다시 검색

//아파트비교
export const ADD_APT_COMPARE = 'ADD_APT_COMPARE';
export const RESET_APT_COMPARE = 'RESET_APT_COMPARE';
export const COMPARE_APT = 'COMPARE_APT';
export const COMPARE_APT_SUCCESS = 'COMPARE_APT_SUCCESS';
export const COMPARE_APT_FAILURE = 'COMPARE_APT_FAILURE';
export const COMPARE_APT_LOADING = 'COMPARE_APT_LOADING';
export const COMPARE_APT_NOTASKED = 'COMPARE_APT_NOTASKED';
export const GET_COMPARE_GRAPH = 'GET_COMPARE_GRAPH';
export const SET_COMPARE_GRAPH = 'SET_COMPARE_GRAPH';
export const SET_COMPARE_APT_CATEGORY_TEMPLATE = 'SET_COMPARE_APT_CATEGORY_TEMPLATE';
export const SET_APT_COLOR = 'SET_APT_COLOR';

//재건축 사업성
export const GET_SELECTED_COMPLEX_DETAIL = 'GET_SELECTED_COMPLEX_DETAIL';
export const GET_MIN_MAX_PRICE = 'GET_MIN_MAX_PRICE';
export const SET_MIN_MAX_PRICE = 'SET_MIN_MAX_PRICE';
export const SET_RECONSTRUCT_TYPE = 'SET_RECONSTRUCT_TYPE';
export const GET_RECONSTRUCT_SCORE_DETAIL = 'GET_RECONSTRUCT_SCORE_DETAIL';
export const SET_RECONSTRUCT_SCORE_DETAIL = 'SET_RECONSTRUCT_SCORE_DETAIL';
export const GET_RECONSTRUCT_INVEST_INFO = 'GET_RECONSTRUCT_INVEST_INFO';
export const GET_RECONSTRUCT_INVEST_INFO_WITH_SELECT_CHANGE = 'GET_RECONSTRUCT_INVEST_INFO_WITH_SELECT_CHANGE';
export const SET_RECONSTRUCT_INVEST_INFO = 'SET_RECONSTRUCT_INVEST_INFO';
export const SET_RECONSTRUCT_NEAR_COMPLEX = 'SET_RECONSTRUCT_NEAR_COMPLEX';
export const SET_IS_SIZE_METER = 'SET_IS_SIZE_METER';

// 거래량
export const SET_TRADEVOLUME_MONTH_RANGE = 'SET_TRADEVOLUME_MONTH_RANGE';

// 가격변동
export const SET_PRICE_FLUC_RANGE = 'SET_PRICE_FLUC_RANGE';
export const SET_PRICE_FLUC_IS_SALES = 'SET_PRICE_FLUC_IS_SALES';

// 일자리 수
export const SET_JOB_NUMBER_RANGE = 'SET_JOB_NUMBER_RANGE';
export const SET_JOB_NUMBER_IS_CURRENT = 'SET_JOB_NUMBER_IS_CURRENT';

// 개발호재
export const SET_PREV_POSITIVES_BIULD_POLYGONS = 'SET_PREV_POSITIVES_BIULD_POLYGONS';
export const SET_PREV_POSITIVES_REBIULD_POLYGONS = 'SET_PREV_POSITIVES_REBIULD_POLYGONS';
export const SET_PREV_POSITIVES_SUBWAY_POLYGONS = 'SET_PREV_POSITIVES_SUBWAY_POLYGONS';
export const SET_POSITIVES_SELECT_LIST = 'SET_POSITIVES_SELECT_LIST';
export const SET_IS_POSITIVES_FIRST_SELECT = 'SET_IS_POSITIVES_FIRST_SELECT';

// 제보하기
export const POST_REPORT = 'POST_REPORT';

export const SET_RECON_DETAIL_FROM = 'SET_RECON_DETAIL_FROM';

// 추천
export const SET_IS_RECOMMEND_POPUP = 'SET_IS_RECOMMEND_POPUP';
export const SET_RECOMMEND_STEP = 'SET_RECOMMEND_STEP';
export const SET_RECOMMEND_SELECTED_INFO = 'SET_RECOMMEND_SELECTED_INFO';
export const REQ_GET_RECOMMEND_RESULT = 'REQ_GET_RECOMMEND_RESULT';
export const SET_RECOMMEND_RESULT = 'SET_RECOMMEND_RESULT';
export const SET_IS_RECOMMEND_RESULT_POPUP = 'SET_IS_RECOMMEND_RESULT_POPUP';
export const SET_IS_COMPLETE_SELECT = 'SET_IS_COMPLETE_SELECT';
export const SET_COMPLETE_SELECT_ORD = 'SET_COMPLETE_SELECT_ORD';
export const SET_SELECTED_FILTER_DATA = 'SET_SELECTED_FILTER_DATA';
