import { api as apis } from '@/apis/analysis';
import { STATUS } from '@/data/utils';
import { setCookieByKey } from '../cookie';
import CONSTANTS from '@/pages/Analysis/constants';

export const getComplexRankingInArea = async ({ complex_idx, payload, setData, dispatch }) => {
  try {
    const result = await apis.getComplexRankingInArea(complex_idx, payload);

    // [221101 배지율 수정] 단지 중심 이동 로직은 pages/Buying/Map 에 추가함 -> 주석처리.
    // const lat = result.complexInfo.lat;
    // const lng = result.complexInfo.lng;
    // dispatch(actions.buying.moveCenter([lat, lng]));

    const b_dong_code = String(result.complexInfo.b_dong_code);
    setCookieByKey('anls_rank_cd', { code: b_dong_code.substring(0, 5) });

    setData({ status: STATUS.SUCCESS, result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getBestComplexByDate = async (payload, setData) => {
  // from 월요일 날짜 to 일요일 날짜
  try {
    const result = await apis.getBestComplexByDate(payload);
    setData({ status: STATUS.SUCCESS, result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getDisplayListMapPayload = async ({
  dataType,
  bounds,
  zoomLevel,
  passedData,
  tradeVolumeMonthRange,
  priceFlucRange,
  priceFlucIsSales,
  jobNumberRange,
  jobNumberIsCurrent,
  globalSchoolTypeList,
  positivesSelectList,
  prevPositivesBuildPolygons,
  prevPositivesReBuildPolygons,
  prevPositivesSubwayPolygons,
}) => {
  let payload;

  if (dataType === CONSTANTS.ANALYSIS_TRADE_VOLUME) {
    // 거래량
    const bfMonth =
      tradeVolumeMonthRange === 0 ? 12 : tradeVolumeMonthRange === 1 ? 6 : tradeVolumeMonthRange === 2 ? 3 : tradeVolumeMonthRange >= 3 ? 2 : 2;

    payload = {
      category: dataType,
      swLng: bounds[0],
      swLat: bounds[1],
      neLng: bounds[2],
      neLat: bounds[3],
      zoomLevel: zoomLevel,
      bfMonth: passedData?.month ? passedData?.month : bfMonth,
    };
  } else if (dataType === CONSTANTS.ANALYSIS_TRADE_VOLUME_BY_ADDRESS) {
    // 동별 거래량
    const bfMonth =
      tradeVolumeMonthRange === 0 ? 12 : tradeVolumeMonthRange === 1 ? 6 : tradeVolumeMonthRange === 2 ? 3 : tradeVolumeMonthRange >= 3 ? 2 : 2;

    payload = {
      category: dataType,
      addressCodes: passedData?.addressCodes,
      bfMonth: passedData?.month ? passedData?.month : bfMonth,
    };
  } else if (dataType === CONSTANTS.ANALYSIS_PRICE_FLUC) {
    // 가격변동
    const bfMonth = priceFlucRange === 0 ? 120 : priceFlucRange === 1 ? 60 : priceFlucRange === 2 ? 12 : priceFlucRange >= 3 ? 3 : 12;

    payload = {
      category: dataType,
      swLng: bounds[0],
      swLat: bounds[1],
      neLng: bounds[2],
      neLat: bounds[3],
      zoomLevel: zoomLevel,
      bfMonth: passedData?.month ? passedData?.month : bfMonth,
      tradeType: priceFlucIsSales ? 'S' : 'C',
    };
  } else if (dataType === CONSTANTS.ANALYSIS_PRICE_FLUC_BY_ADDRESS) {
    // 동별 가격변동
    const bfMonth = priceFlucRange === 0 ? 120 : priceFlucRange === 1 ? 60 : priceFlucRange === 2 ? 12 : priceFlucRange >= 3 ? 3 : 12;

    payload = {
      category: dataType,
      addressCodes: passedData?.addressCodes,
      bfMonth: passedData?.month ? passedData?.month : bfMonth,
      tradeType: priceFlucIsSales ? 'S' : 'C',
    };
  } else if (dataType === CONSTANTS.ANALYSIS_JOB_NUM) {
    // 일자리수

    const bfYear = jobNumberRange === 0 ? 10 : jobNumberRange === 1 ? 5 : jobNumberRange === 2 ? 3 : 1;

    payload = {
      category: dataType,
      swLng: bounds[0],
      swLat: bounds[1],
      neLng: bounds[2],
      neLat: bounds[3],
      zoomLevel: zoomLevel,
      bfYear: jobNumberIsCurrent ? new Date().getFullYear() - new Date('2011-01-01').getFullYear() : bfYear,
    };
  } else if (dataType === CONSTANTS.ANALYSIS_SCHOOL) {
    // 학원가
    const schoolTypeList = passedData?.schoolTypeList || globalSchoolTypeList;

    let apiCategory = '';

    (schoolTypeList || []).map((item) => {
      if (item.included) {
        switch (item.type) {
          case '중학교':
            apiCategory += apiCategory.length === 0 ? 'm' : '|m';
            break;
          case '고등학교':
            apiCategory += apiCategory.length === 0 ? 'h' : '|h';
            break;
          case '학원가':
            apiCategory += apiCategory.length === 0 ? 'a' : '|a';
            break;
        }
      }
    });

    payload = {
      category: dataType,
      temp: apiCategory,
      swLng: bounds[0],
      swLat: bounds[1],
      neLng: bounds[2],
      neLat: bounds[3],
      zoomLevel: zoomLevel,
      // zoomLevel: zoomLevel >= 6 ? 6 : zoomLevel,
    };
  } else if (dataType === CONSTANTS.ANALYSIS_POSITIVES) {
    // 개발호재
    let positiveCategory = '';
    (positivesSelectList || []).map((data, idx) => {
      if (data.included) {
        switch (idx) {
          case 0:
            positiveCategory += positiveCategory.length === 0 ? 's' : '|s';
            break;
          case 1:
            positiveCategory += positiveCategory.length === 0 ? 'b' : '|b';
            break;
          case 2:
            positiveCategory += positiveCategory.length === 0 ? 'r' : '|r';
            break;
        }
      }
    });
    payload = {
      category: dataType,
      temp: positiveCategory,
      swLng: 125.94257539028908, // 서울, 수도권으로 고정
      swLat: 36.87052191095451,
      neLng: 127.76441920691401,
      neLat: 37.87843217818064,
      zoomLevel: 6, // api에서 lv6까지 데이터를 돌려줌
    };

    /* 최초 요청 이후 요청 하지 않음 */

    if (
      (prevPositivesBuildPolygons || []).length > 0 &&
      (prevPositivesReBuildPolygons || []).length > 0 &&
      (prevPositivesSubwayPolygons || []).length > 0
    ) {
      // const displayList = yield select(selectors.getDisplayList);
      // return yield put(actions.setDisplayListMap([...displayList]));
      // return yield setList([...displayList]);
      return;
    }
  } else {
    payload = {
      category: dataType,
      swLng: bounds[0],
      swLat: bounds[1],
      neLng: bounds[2],
      neLat: bounds[3],
      zoomLevel: zoomLevel,
    };
  }

  return payload;
};
