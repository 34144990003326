import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';
import { setCookieByKey } from '@/data/cookie';
import _ from 'lodash';

const INITIAL_STATE = {
  // @note post request 보내는건 auth dir 에서 이루어짐 (request 관련 error 는 거기서 받고,그거 제외하고는 여기서)
  error: '',
  isFeeAgreed: false,
  isAllAgreed: false,
  isUsageTermAgreed: false,
  isAgentServiceAgreed: false,
  isInfoAgreed: false,

  officeList: [],

  officeType: '',

  selectedOfficeIdx: '',
  selectedOfficeInfo: {},

  imgIdx: {},
  officePhoneNum: '',

  brokerId: '',
  brokerPassword: '',
  brokerCareerText: '',

  dongCode: '',
  sidoCode: '',
  sigugunCode: '',

  checkIdState: '',
  [AT.CHECK_ID_DUP]: Remote.NotAsked,
  [AT.APPLY_SHARE_OFFICE]: Remote.NotAsked,
  [AT.AGENT_SIGNUP_HANDLE_NEXT_STEP]: Remote.NotAsked,
  [AT.APPLY_TOUR_OFFICE]: Remote.NotAsked,
};

export default function agentSginUp(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SAVE_LOGIN_INFO:
      return {
        ...state,
        loginInfo: action.obj,
      };
    case AT.AGENT_SIGNUP_RESET:
      return {
        INITIAL_STATE,
      };

    case loading(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP):
      return { ...state, [AT.AGENT_SIGNUP_HANDLE_NEXT_STEP]: Remote.Loading };
    case success(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP):
      return {
        ...state,
        [AT.AGENT_SIGNUP_HANDLE_NEXT_STEP]: Remote.Success(action.data),
      };
    case failure(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP):
      return { ...state, [AT.AGENT_SIGNUP_HANDLE_NEXT_STEP]: Remote.Failure(action.error), error: action.error };

    case success(AT.CHECK_ID_DUP):
      return {
        ...state,
        checkIdState: action.data,
        [AT.CHECK_ID_DUP]: Remote.Success(action.data),
      };
    case failure(AT.CHECK_ID_DUP):
      return {
        ...state,
        [AT.CHECK_ID_DUP]: Remote.Failure(action.error),
        checkIdState: 'DUP',
        error: action.error,
      };
    case AT.SET_SHARE_DONG:
      return {
        ...state,
        new: { ...state?.new, dong: action.data },
      };
    case AT.SET_SHARE_NUM:
      return {
        ...state,
        new: { ...state?.new, num: action.data },
      };
    case AT.SET_SHARE_NAME:
      return {
        ...state,
        new: { ...state?.new, name: action.data },
      };
    case AT.SET_SHARE_YEAR:
      return {
        ...state,
        new: { ...state?.new, year: action.data },
      };
    case AT.RESET_NEW:
      return {
        ...state,
        new: '',
      };
    case loading(AT.APPLY_SHARE_OFFICE):
      return { ...state, [AT.APPLY_SHARE_OFFICE]: Remote.Loading };
    case success(AT.APPLY_SHARE_OFFICE):
      return {
        ...state,
        [AT.APPLY_SHARE_OFFICE]: Remote.Success(action.data),
      };
    case failure(AT.APPLY_SHARE_OFFICE):
      return { ...state, [AT.APPLY_SHARE_OFFICE]: Remote.Failure(action.error), error: action.error };

    case AT.GET_ACADEMY_DATE:
      return {
        ...state,
      };
    case AT.SET_ACADEMY_DATE:
      return {
        ...state,
        academy: { ...state?.academy, date: action.data },
      };

    case AT.SET_ACADEMY_NUM:
      return {
        ...state,
        academy: { ...state?.academy, num: action.data },
      };
    case AT.SET_ACADEMY_NAME:
      return {
        ...state,
        academy: { ...state?.academy, name: action.data },
      };

    case AT.SET_ACADEMY_INFO:
      return {
        ...state,
        academy: { ...state?.academy, selectedDate: action.data },
      };

    case AT.RESET_ACADEMY:
      return {
        ...state,
        [AT.EDIT_ACADEMY]: Remote.Loading,
        [AT.CANCEL_ACADEMY]: Remote.Loading,
        [AT.ALREADY_ACADEMY]: Remote.Loading,
        academy: { date: state?.academy?.date },
      };
    case AT.RESET_ALREADY:
      return {
        ...state,
        [AT.ALREADY_ACADEMY]: Remote.Loading,
        academy: { ...state?.academy, already: '', prebilldata: '' },
      };
    case AT.SET_APPLY_ACADEMY_PRE_BILL_DATA:
      return {
        ...state,
        academy: { ...state?.academy, prebilldata: action.data },
      };

    case AT.SET_CHECK_ACADEMY_RESULT:
      return {
        ...state,
        academy: { ...state?.academy, userInfo: action.result },
      };

    //일정 변경 성공
    case success(AT.EDIT_ACADEMY): {
      var edit_start_date = new Date(action.data.course.period.startAt);
      var edit_end_date = new Date(action.data.course.period.endAt);
      var edit_education_text =
        action.data.course.title + ' (' + (edit_start_date.getMonth() + 1) + '/' + edit_start_date.getDate() + '~' + edit_end_date.getDate() + ')';
      return {
        ...state,
        [AT.EDIT_ACADEMY]: Remote.Success(action.data),
        academy: { ...state?.academy, success: action.data, userApplyAcademyInfo: action.data, education_text: edit_education_text },
      };
    }
    case success(AT.CANCEL_ACADEMY): {
      return {
        ...state,
        [AT.CANCEL_ACADEMY]: Remote.Success(action.data),
        academy: { ...state?.academy, success: action.data, userApplyAcademyInfo: action.data, education_text: edit_education_text },
      };
    }

    case loading(AT.ALREADY_ACADEMY):
      return { ...state, [AT.ALREADY_ACADEMY]: Remote.Loading };
    case success(AT.ALREADY_ACADEMY): {
      if (action.data != '') {
        var already_start_date = new Date(action.data.course.period.startAt);
        var already_end_date = new Date(action.data.course.period.endAt);
        var already_edu_txt =
          action.data.course.title +
          ' (' +
          (already_start_date.getMonth() + 1) +
          '/' +
          already_start_date.getDate() +
          '~' +
          already_end_date.getDate() +
          ')';
        return {
          ...state,
          [AT.ALREADY_ACADEMY]: Remote.Success(action.data),
          academy: { ...state?.academy, already: action.data, education_text: already_edu_txt },
        };
      } else {
        return {
          ...state,
          academy: { ...state?.academy, already: '' },
        };
      }
    }
    case failure(AT.ALREADY_ACADEMY):
      return { ...state, [AT.ALREADY_ACADEMY]: Remote.Failure(action.error), error: action.error };

    case success(AT.GET_USER_APPLY_ACADEMY_INFO): {
      if (action?.data) {
        var user_start_date = new Date(action.data.course.period.startAt);
        var user_end_date = new Date(action.data.course.period.endAt);
        var user_edu_txt =
          action.data.course.title + ' (' + (user_start_date.getMonth() + 1) + '/' + user_start_date.getDate() + '~' + user_end_date.getDate() + ')';
        return {
          ...state,
          academy: { ...state?.academy, userApplyAcademyInfo: action.data, education_text: user_edu_txt },
        };
      } else {
        return {
          ...state,
          academy: { ...state?.academy, userApplyAcademyInfo: 'NO' },
        };
      }
    }
    //start tour 신청
    case loading(AT.APPLY_TOUR_OFFICE):
      return { ...state, [AT.APPLY_TOUR_OFFICE]: Remote.Loading };
    case success(AT.APPLY_TOUR_OFFICE):
      return {
        ...state,
        [AT.APPLY_TOUR_OFFICE]: Remote.Success(action.data),
      };
    case failure(AT.APPLY_TOUR_OFFICE):
      return { ...state, [AT.APPLY_TOUR_OFFICE]: Remote.Failure(action.error), error: action.error };

    case AT.GET_TOUR_OFFICE_LIST:
      return { ...state };
    case AT.SET_TOUR_OFFICE_LIST:
      return { ...state, tourOfficeList: action.data };
    case AT.SET_TOUR_NAME:
      return {
        ...state,
        tourNew: { ...state?.tourNew, name: action.data },
      };
    case AT.SET_TOUR_NUM:
      return {
        ...state,
        tourNew: { ...state?.tourNew, num: action.data },
      };
    case AT.SET_TOUR_OFFICE:
      return {
        ...state,
        tourNew: { ...state?.tourNew, office: action.data },
      };
    case AT.SET_TOUR_OFFICE_NAME:
      return {
        ...state,
        tourNew: { ...state?.tourNew, office_name: action.data },
      };
    case AT.SET_TOUR_OFFICE_NAME2:
      return {
        ...state,
        office_name: action.data,
      };
    case AT.SET_TOUR_DATE:
      return {
        ...state,
        tourNew: { ...state?.tourNew, tourdate: action.data },
      };
    case AT.SET_TOUR_EXIST:
      return {
        ...state,
        tourNew: { exist: action.exist },
      };
    case AT.RESET_TOUR:
      return {
        ...state,
        tourNew: '',
      };
    //end tour 신청

    case AT.SET_CHECK_ID_DUP: {
      return {
        ...state,
        checkIdState: '',
      };
    }
    case AT.SET_IS_FEE_AGREED: {
      return {
        ...state,
        isFeeAgreed: action.isFeeAgreed,
      };
    }
    case AT.SET_DWCD: {
      return {
        ...state,
        dwcd: action.dwcd,
      };
    }
    case AT.SET_ACADEMY_DWCD: {
      return {
        ...state,
        academy_dwcd: action.dwcd,
      };
    }
    case AT.SET_IS_ALL_AGREED: {
      return {
        ...state,
        isAllAgreed: action.isAllAgreed,
        isUsageTermAgreed: action.isAllAgreed,
        isAgentServiceAgreed: action.isAllAgreed,
        isInfoAgreed: action.isAllAgreed,
      };
    }
    case AT.SET_IS_USAGE_TERM_AGREED: {
      return {
        ...state,
        isUsageTermAgreed: action.isUsageTermAgreed,
      };
    }
    case AT.SET_IS_AGENT_SERVICE_AGREED: {
      return {
        ...state,
        isAgentServiceAgreed: action.isAgentServiceAgreed,
      };
    }
    case AT.SET_IS_INFO_AGREED: {
      return {
        ...state,
        isInfoAgreed: action.isInfoAgreed,
      };
    }

    case AT.SET_OFFICE_LIST: {
      return {
        ...state,
        officeList: action.list,
      };
    }

    case AT.SET_OFFICE_TYPE: {
      return {
        ...state,
        officeType: action.officeType,
      };
    }
    case AT.GET_OFFICE_LIST_LOADING:
      return { ...state, officeList: 'loading' };
    case AT.GET_OFFICE_LIST_FAIL:
      return { ...state, officeList: 'fail' };
    case AT.SET_SELECTED_OFFICE: {
      return {
        ...state,
        selectedOfficeIdx: action.code,
        selectedOfficeInfo: state?.officeList?.find((el) => el.code == action.code) || {},
      };
    }
    case AT.GET_ADDRESS_LIST_LOADING:
      return { ...state, addressList: 'loading' };
    case AT.GET_ADDRESS_LIST_FAIL:
      return { ...state, addressList: 'fail' };
    case AT.SET_ADDRESS_LIST: {
      return {
        ...state,
        addressList: action.list,
      };
    }

    case AT.SET_SELECTED_OFFICE_ADDRESS: {
      let latlng = state?.addressList?.find((el) => el.address == action.address) || {};
      return {
        ...state,
        selectedOfficeInfo: {
          ...state?.selectedOfficeInfo,
          address: latlng?.address,
          lat: latlng?.lat,
          lng: latlng?.lng,
          b_dong_code: latlng?.b_dong_code,
        },
      };
    }
    case AT.SET_SELECTED_OFFICE_ADDRESS_DETAIL: {
      return {
        ...state,
        selectedOfficeInfo: {
          ...state?.selectedOfficeInfo,
          address_detail: action.value,
        },
      };
    }
    case AT.SEARCH_XY: {
      return {
        ...state,
      };
    }
    case AT.DEL_OFFICE_SET: {
      return {
        ...state,
        selectedOfficeIdx: '',
        selectedOfficeInfo: '',
      };
    }
    case AT.DEL_ADDRES_SET: {
      return {
        ...state,
        selectedOfficeInfo: { ...state?.selectedOfficeInfo, address: '', lat: '', lng: '', b_dong_code: '', address_detail: '' },
      };
    }
    case AT.SET_OFFICE_XY: {
      return {
        ...state,
        selectedOfficeInfo: {
          ...state?.selectedOfficeInfo,
          lat: action.latlng.lat,
          lng: action.latlng.lng,
          address: action.latlng.address,
          b_dong_code: action.latlng.b_dong_code,
        },
      };
    }
    case AT.SET_B_DONG_CODE: {
      return {
        ...state,
        selectedOfficeInfo: {
          ...state?.selectedOfficeInfo,
          b_dong_code: action.b_dong_code,
        },
      };
    }
    case AT.SET_MAP_LEVEL: {
      return {
        ...state,
        maplevel: action.level,
      };
    }
    case AT.SET_CHECK_CODE: {
      return {
        ...state,
        exsist_broker: action.list,
      };
    }
    //추천인
    case AT.CHANGE_BROKER_IDX: {
      return {
        ...state,
        recommend_idx: action.broker_office_idx,
      };
    }
    case AT.SET_BROKER_OFFICE_LIST: {
      return {
        ...state,
        brokerOfficeList: action.list,
      };
    }

    case AT.SET_SELECTED_OFFICE_INFO: {
      return {
        ...state,
        selectedOfficeInfo: action.broker_office_info || {},
      };
    }

    case AT.AS_SET_MAP_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case AT.SET_BROKER_ID: {
      return {
        ...state,
        brokerId: action.brokerId,
      };
    }
    case AT.SET_OFFICE_PHONE_NUM: {
      return {
        ...state,
        officePhoneNum: action.officePhoneNum,
      };
    }
    case AT.SET_BROKER_PASSWORD: {
      return {
        ...state,
        brokerPassword: action.brokerPassword,
      };
    }
    case AT.SET_BROKER_CAREER_TEXT: {
      return {
        ...state,
        brokerCareerText: action.brokerCareerText,
      };
    }

    //시도 시군구
    //------------------------------------------------
    case AT.GET_RECOMMEND_ADDR_SiDo: {
      return {
        ...state,
      };
    }
    case AT.SET_RECOMMEND_ADDR_SiDo: {
      return {
        ...state,
        si_do_list: action.list,
      };
    }
    case AT.CHANGE_RECOMMEND_SiDoCode: {
      return {
        ...state,
        si_do_code: action.si_do_code,
        si_do_name: action.si_do_name,
        si_gun_gu_list: [],
        dong_ri_list: [],
        si_gun_gu_code: '',
        si_gun_gu_name: '',
        dong_ri_code: '',
        dong_ri_name: '',
      };
    }
    case AT.CHANGE_RECOMMEND_SiDoName:
      return { ...state, si_do_name: action.si_do_name };

    case AT.SET_RECOMMEND_ADDR_SiGunGu: {
      // checked 항목은 분석 > 랭킹 페이지에서 사용하기 위해 넣는다 (복수선택 사용할 경우를 위해)
      let temp = [];
      if (action.list) {
        action.list.forEach((el) => {
          el['checked'] = true;
          temp.push(el);
        });
      }
      return {
        ...state,
        si_gun_gu_list: [...temp],
      };
    }
    case AT.CHANGE_RECOMMEND_SiGunGuCode: {
      return {
        ...state,
        si_gun_gu_code: action.si_gun_gu_code,
        si_gun_gu_name: action.si_gun_gu_name,
        dong_ri_list: [],
        dong_ri_code: '',
        dong_ri_name: '',
      };
    }
    case AT.CHANGE_RECOMMEND_SiGunGuName:
      return { ...state, si_gun_gu_name: action.si_gun_gu_name };

    case AT.SET_RECOMMEND_ADDR_DongRi: {
      return {
        ...state,
        dong_ri_list: action.list,
      };
    }

    case AT.CHANGE_RECOMMEND_DongRiCode: {
      return {
        ...state,
        dong_ri_code: action.dong_ri_code,
        dong_ri_name: action.dong_ri_name,
      };
    }
    //------------------------------------------------
    case AT.ADD_AGENT_SIGNUP_ACTIVE_AREA: {
      if (state?.areaList?.areaList?.length > 0 && state?.areaList?.areaList?.some((ii) => ii?.sigungu_code == action.sigunguCode)) {
        return { ...state };
      }
      let origin_areaList = state?.areaList?.areaList ?? [];

      let sigungu_arr = [];
      action.dong.map((item) => {
        var ll = {
          sigungu: action.sigunguName,
          sigungu_code: parseInt(action.sigunguCode),
          dong_name: item.name,
          dong_code: parseInt(item.dong_code),
        };
        sigungu_arr.push(ll);
      });
      sigungu_arr.sort((a, b) => {
        if (a.dong_name > b.dong_name) return 1;
        if (a.dong_name < b.dong_name) return -1;
      });
      var lll = {
        sigungu: sigungu_arr,
        sigungu_code: action.sigunguCode,
        sigungu_name: action.sigunguName,
        close: 'y',
      };
      origin_areaList.push(lll);

      return {
        ...state,
        areaList: { ...state?.areaList, areaList: origin_areaList },
      };
    }
    case AT.CLEAR_AGENT_SGINUP_ACTIVE_AREA: {
      return {
        ...state,
        areaList: {
          ...state?.areaList,
          areaList: [...state?.areaList?.areaList?.filter((i) => i?.close != 'y')],
        },
      };
    }
    case AT.CLEAR_AGENT_SGINUP_ACTIVE_AREA2: {
      let new_arr = _.uniq(state.service_area?.map((i) => i.substring(0, 5)));
      return {
        ...state,
        areaList: {
          ...state?.areaList,
          areaList: [...state?.areaList?.areaList?.filter((i) => i?.close != 'y' || (i?.close == 'y' && new_arr?.includes(i.sigungu_code)))],
        },
      };
    }
    case AT.DEL_AGENT_SIGNUP_ACTIVE_AREA: {
      let re_areaList = state?.areaList.areaList.filter((ii) => ii.sigungu != action.item?.sigungu);

      return {
        ...state,
        areaList: { ...state?.areaList, areaList: re_areaList },
      };
    }
    case AT.DEL_AGENT_SIGNUP_B_DONG_CODE: {
      return {
        ...state,
        service_area: state?.service_area?.filter((ii) => ii != action.b_dong_code),
        service_dong_list: state?.service_dong_list?.filter((ii) => ii.code != action.b_dong_code),
      };
    }
    case AT.SAVE_AGENT_SIGNUP_ACTIVE_AREA: {
      const service_area = action.dong_list.map((i) => i.code);
      return {
        ...state,
        service_area: service_area,
        service_dong_list: action.dong_list,
      };
    }
    case AT.SAVE_AGENT_SIGNUP_BROKER_ACTIVE_AREA: {
      let sigungu_arr = [];
      let add_service_area = [];
      action.dong_list.map((item) => {
        var ll = {
          sigungu: action.sigungu[0].name,
          sigungu_code: parseInt(action.sigungu[0].code),
          dong_name: item.name,
          dong_code: parseInt(item.code),
          // sett: item.code == action.dong_code ? 'y' : 'n',
        };
        if (item.code == action.dong_code) {
          add_service_area.push({ code: action.dong_code, name: item.name });
        }
        sigungu_arr.push(ll);
      });
      sigungu_arr.sort((a, b) => {
        if (a.dong_name > b.dong_name) return 1;
        if (a.dong_name < b.dong_name) return -1;
      });
      let sigunguObj = [
        {
          sigungu: sigungu_arr,
          sigungu_code: action.sigungu[0].code,
          sigungu_name: action.sigungu[0].name,
        },
      ];
      return {
        ...state,
        areaList: { ...state?.areaList, areaList: sigunguObj },
        service_area: [action.dong_code],
        service_dong_list: add_service_area,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
