import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const DATAROOM = {
  page: 1,
  total: 40,
  formNformat: { page: 1, detail: {}, conlist: {} },
  precedent: { page: 1, detail: {}, conlist: {} },
  useful: { page: 1, detail: {}, conlist: {} },
};

export default function dataRoom(state = DATAROOM, action = {}) {
  switch (action.type) {
    //서식 및 양식
    case AT.GET_DR_FORM_DETAIL: {
      return {
        ...state,
        formNformat: { ...state.formNformat, detailIdx: action.idx },
      };
    }
    case AT.GET_DR_FORM_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_DR_FORM_KEYWORD: {
      return {
        ...state,
        formNformat: { ...state.formNformat, keyword: action.keyword },
      };
    }
    case AT.SET_DR_FORM_TOTAL_CNT: {
      return {
        ...state,
        formNformat: { ...state.formNformat, totalCnt: action.totalCnt },
      };
    }
    case AT.SET_DR_FORM_CURRENT_PAGE: {
      return {
        ...state,
        formNformat: { ...state.formNformat, page: action.page },
      };
    }

    case loading(AT.GET_DR_FORM_LIST):
      return { ...state, [AT.GET_DR_FORM_LIST]: Remote.Loading };
    case success(AT.GET_DR_FORM_LIST):
      return {
        ...state,
        formNformat: { ...state.formNformat, conlist: action.conlist },
      };
    case failure(AT.GET_DR_FORM_LIST):
      return {
        ...state,
        formNformat: {
          ...state.formNformat,
          [AT.GET_DR_FORM_DETAIL]: Remote.Failure(action.error),
        },
        error: action.error,
      };

    case loading(AT.GET_DR_FORM_DETAIL):
      return { ...state, [AT.GET_DR_FORM_DETAIL]: Remote.Loading };
    case success(AT.GET_DR_FORM_DETAIL):
      return {
        ...state,
        formNformat: {
          ...state.formNformat,
          detail: action.detail,
        },
      };
    case failure(AT.GET_DR_FORM_DETAIL):
      return {
        ...state,
        formNformat: {
          ...state.formNformat,
          [AT.GET_DR_FORM_DETAIL]: Remote.Failure(action.error),
        },
        error: action.error,
      };

    //관련판례
    case AT.GET_DR_PRECEDENT_DETAIL: {
      return {
        ...state,
        precedent: { ...state.precedent, detailIdx: action.idx },
      };
    }
    case AT.GET_DR_PRECEDENT_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_DR_PRECEDENT_KEYWORD: {
      return {
        ...state,
        precedent: { ...state.precedent, keyword: action.keyword },
      };
    }
    case AT.SET_DR_PRECEDENT_TOTAL_CNT: {
      return {
        ...state,
        precedent: { ...state.precedent, totalCnt: action.totalCnt },
      };
    }
    case AT.SET_DR_PRECEDENT_CURRENT_PAGE: {
      return {
        ...state,
        precedent: { ...state.precedent, page: action.page },
      };
    }

    case loading(AT.GET_DR_PRECEDENT_LIST):
      return { ...state, [AT.GET_DR_PRECEDENT_LIST]: Remote.Loading };
    case success(AT.GET_DR_PRECEDENT_LIST):
      return {
        ...state,
        precedent: {
          ...state.precedent,
          conlist: action.conlist,
        },
      };
    case failure(AT.GET_DR_PRECEDENT_LIST):
      return {
        ...state,
        precedent: {
          ...state.precedent,
          [AT.GET_DR_PRECEDENT_DETAIL]: Remote.Failure(action.error),
        },
        error: action.error,
      };

    case loading(AT.GET_DR_PRECEDENT_DETAIL):
      return { ...state, [AT.GET_DR_PRECEDENT_DETAIL]: Remote.Loading };
    case success(AT.GET_DR_PRECEDENT_DETAIL):
      return {
        ...state,
        precedent: {
          ...state.precedent,
          detail: action.detail,
        },
      };
    case failure(AT.GET_DR_PRECEDENT_DETAIL):
      return {
        ...state,
        precedent: {
          ...state.precedent,
          [AT.GET_DR_PRECEDENT_DETAIL]: Remote.Failure(action.error),
        },
        error: action.error,
      };

    //유용한사이트
    case AT.GET_DR_USEFUL_DETAIL: {
      return {
        ...state,
        useful: { ...state.useful, detailIdx: action.idx },
      };
    }
    case AT.GET_DR_USEFUL_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_DR_USEFUL_KEYWORD: {
      return {
        ...state,
        useful: { ...state.useful, keyword: action.keyword },
      };
    }
    case AT.SET_DR_USEFUL_TOTAL_CNT: {
      return {
        ...state,
        useful: { ...state.useful, totalCnt: action.totalCnt },
      };
    }
    case AT.SET_DR_USEFUL_CURRENT_PAGE: {
      return {
        ...state,
        useful: { ...state.useful, page: action.page },
      };
    }

    case loading(AT.GET_DR_USEFUL_LIST):
      return { ...state, [AT.GET_DR_USEFUL_LIST]: Remote.Loading };
    case success(AT.GET_DR_USEFUL_LIST):
      return {
        ...state,
        useful: {
          ...state.useful,
          conlist: action.conlist,
        },
      };
    case failure(AT.GET_DR_USEFUL_LIST):
      return {
        ...state,
        useful: {
          ...state.useful,
          [AT.GET_DR_USEFUL_DETAIL]: Remote.Failure(action.error),
        },
        error: action.error,
      };

    case loading(AT.GET_DR_USEFUL_DETAIL):
      return { ...state, [AT.GET_DR_USEFUL_DETAIL]: Remote.Loading };
    case success(AT.GET_DR_USEFUL_DETAIL):
      return {
        ...state,
        useful: {
          ...state.useful,
          detail: action.detail,
        },
      };
    case failure(AT.GET_DR_USEFUL_DETAIL):
      return {
        ...state,
        useful: {
          ...state.useful,
          [AT.GET_DR_USEFUL_DETAIL]: Remote.Failure(action.error),
        },
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
