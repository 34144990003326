import * as AT from '../actionTypes';
import Remote from '@/data/remote';

export const INITIAL_STATE = {
  [AT.FIND_ID_1]: Remote.NotAsked,
  [AT.FIND_ID_2]: Remote.NotAsked,
  [AT.FIND_PW]: Remote.NotAsked,
  [AT.CHANGE_PW]: Remote.NotAsked,
};

/** @commnet switch문 30개 초과(불가)로 reducer 분리 **/
export default function find(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.FIND_ID_1_NOTASKED: //ID찾기_1
      return { ...state, [AT.FIND_ID_1]: Remote.NotAsked };
    case AT.FIND_ID_1_SUCCESS:
      return { ...state, [AT.FIND_ID_1]: Remote.Success(action.data) };
    case AT.FIND_ID_1_FAILURE:
      return { ...state, [AT.FIND_ID_1]: Remote.Failure(action.error) };
    case AT.FIND_ID_1_LOADING:
      return { ...state, [AT.FIND_ID_1]: Remote.Loading };
    case AT.FIND_ID_2_SUCCESS: //ID찾기_2
      return { ...state, [AT.FIND_ID_2]: Remote.Success(action.data) };
    case AT.FIND_ID_2_FAILURE:
      return { ...state, [AT.FIND_ID_2]: Remote.Failure(action.error) };
    case AT.FIND_ID_2_LOADING:
      return { ...state, [AT.FIND_ID_2]: Remote.Loading };
    case AT.FIND_ID_2_NOTASKED:
      return { ...state, [AT.FIND_ID_2]: Remote.NotAsked };
    case AT.FIND_PW_SUCCESS: //PW찾기
      return { ...state, [AT.FIND_PW]: Remote.Success(action.data) };
    case AT.FIND_PW_FAILURE:
      return { ...state, [AT.FIND_PW]: Remote.Failure(action.error) };
    case AT.FIND_PW_LOADING:
      return { ...state, [AT.FIND_PW]: Remote.Loading };
    case AT.FIND_PW_NOTASKED:
      return { ...state, [AT.FIND_PW]: Remote.NotAsked };
    case AT.CHANGE_PW_SUCCESS: //PW변경
      return { ...state, [AT.CHANGE_PW]: Remote.Success(action.data) };
    case AT.CHANGE_PW_FAILURE:
      return { ...state, [AT.CHANGE_PW]: Remote.Failure(action.error) };
    case AT.CHANGE_PW_LOADING:
      return { ...state, [AT.CHANGE_PW]: Remote.Loading };
    case AT.CHANGE_PW_NOTASKED:
      return { ...state, [AT.CHANGE_PW]: Remote.NotAsked };
    default:
      return { ...state };
  }
}
