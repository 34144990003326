import apis from '@/apis';
import { STATUS } from '@/data/utils';
import { FeedType, TagType } from '@/services/Community';

export const getFeedsByUser = async (userId, by, setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.communityApi.getFeedsByUser(userId, by);
    setData({ status: STATUS.SUCCESS, ...result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getCommentsByUser = async (userId, setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.communityApi.getCommentsByUser(userId);
    setData({ status: STATUS.SUCCESS, ...result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

export const getStatisticsByUser = async (userId, setData) => {
  try {
    setData({ status: STATUS.LOADING });
    const result = await apis.communityApi.getStatisticsByUser(userId);
    setData({ status: STATUS.SUCCESS, ...result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};

// 매물 거주후기 조회: 특정유저(userId)가 등록한 특정단지(complexIdx)의 COMPLEX_POST
export const getGoodsFeeds = async ({ userId, complexIdx, setData }) => {
  try {
    setData({ status: STATUS.LOADING });

    const payload = {
      writerIds: [`${userId}`],
      feedTypes: [FeedType.COMPLEX_POST],
      tags: [{ type: TagType.COMPLEX_TAG, key: `${complexIdx}` }],
      limit: 100,
    };

    const result = await apis.communityApi.getFeeds(payload);

    setData({ status: STATUS.SUCCESS, ...result });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
  }
};
