import { TagType } from '@/services/Community';
import React from 'react';

const HashTags = ({ item }) => {
  const tags = item?.tags;

  const address = tags
    .filter((item) => !!item.name && item.type === TagType.ADDRESS_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const goodsTypes = tags
    .filter((item) => !!item.name && item.type === TagType.GOODS_TYPE_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const realtyTypes = tags
    .filter((item) => !!item.name && item.type === TagType.SALE_TYPE_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const coplex = tags
    .filter((item) => !!item.name && item.type === TagType.COMPLEX_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const extraHashTags = tags
    .filter((item) => !!item.name && item.type === TagType.HASH_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  return (
    <p className="data-list">
      <span className="data-item" key={item.id + '_hash_'}>
        {/* {`#${ii?.name ? ii?.name : ii?.key}`} */}
        {`${address} ${goodsTypes} ${realtyTypes} ${coplex} ${extraHashTags}`}
      </span>
      {/* {item?.tags
        ?.filter((item) => item.type != TagType.FLAG_TAG)
        ?.map((ii, kk) => {
          return (
          );
        })} */}
    </p>
  );
};

export default HashTags;
