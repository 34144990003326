import { actions, selectors } from '@/data';
import { STATUS } from '@/data/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useDeleteList = ({ feeds, setFeeds }) => {
  const dispatch = useDispatch();
  const deletedFeeds = useSelector(selectors.community.deletedFeeds);

  useEffect(() => {
    const newFeeds = feeds?.data?.filter((feed) => !deletedFeeds?.includes(feed?.id));

    if (newFeeds) {
      setFeeds({ status: STATUS.SUCCESS, data: newFeeds });
    }
  }, [deletedFeeds]);

  useEffect(() => {
    return () => {
      dispatch(actions.community.setDeletedFeeds([]));
    };
  }, []);

  return;
};

export default useDeleteList;
