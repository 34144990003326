import { all, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as rootSelectors from '@/data/rootSelectors';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';
import CONSTANTS from './constants';
import { STATUS } from '@/data/utils';

export function* toggleFavorite({ itemIdx, targetType, isCurrentlyFav }) {
  // console.log('toggleFavorite',itemIdx, targetType, isCurrentlyFav)
  try {
    const payload = {
      itemIdx: itemIdx,
      targetType: targetType,
      isCurrentlyFav: isCurrentlyFav,
      // 0 : 매물(item), 1 : 단지(complex)
      // areaNoList : (targetType == 0 ? '' : yield select(selectors.getAraeNoList))
    };
    yield call(apis.myPageApi.toggleFavorite, payload);
  } catch (e) {
    yield put(actions.myPageRequestError({ error: text.myPageText.TOGGLE_FAVORITE_ERROR }));
  }
}
// ===================== MyInfo ===================== //
// @todo <프론트_요청사항> idx:19
export function* changePassword({ currentPwd, newPassword, newConfirmedPassword }) {
  // password(현재 비밀번호)
  // newPassword(신규 비밀번호)
  // newConfirmedPassword(신규 비밀번호 확인)

  try {
    const payload = {
      password: currentPwd,
      newPassword: newPassword,
      newConfirmedPassword: newConfirmedPassword,
    };
    yield put(actions.changePasswordLoading());

    const result = yield call(apis.myPageApi.changePassword, payload);
    yield put(actions.changePasswordSuccess());
  } catch (e) {
    // console.log('saga_changePassword',e)
    // @todo 조정 필요 : 그냥 임의로 오류 내역에 현재 비밀번호가 일치하지 않는다는 내용이 담겨온다고 가정하고 넣어놓음
    yield put(actions.changePasswordFailure(e.message));
  }
}

export function* getSocialList() {
  try {
    const payload = {};
    yield put(actions.getSocialListLoading());
    const response = yield call(apis.myPageApi.getSocialList, payload);
    const removeBlank = (str) => str.replace(/(\s*)/g, '');
    const result = removeBlank(response.rlt); // 공백제거
    yield put(actions.getSocialListSuccess(result));
  } catch (e) {
    yield put(actions.getSocialListSuccess(''));
  }
}
// ===================== MyBuy ===================== //
export function* getFavoriteItemsList() {
  try {
    const payload = {};
    yield put(actions.getFavoriteItemsListLoading());
    const response = yield call(apis.myPageApi.getFavoriteItemsList, payload);

    const list = response.list;
    yield put(actions.getFavoriteItemsListSuccess(list));
  } catch (e) {
    yield put(actions.getFavoriteItemsListSuccess(text.myPageText.MY_BUY_FAVORITE_ITEMS_ERROR));
  }
}
export function* getInterestedComplexList() {
  try {
    const payload = {};
    yield put(actions.getInterestedComplexListLoading());
    const response = yield call(apis.myPageApi.getInterestedComplexList, payload);

    const list = response.list;
    yield put(actions.getInterestedComplexListSuccess(list));
  } catch (e) {
    console.log(text.myPageText.MY_BUY_INTERESTED_COMPLEX_ERROR);
    // yield put(actions.getInterestedComplexListFailure(text.myPageText.MY_BUY_INTERESTED_COMPLEX_ERROR));
  }
}
export function* getContractedItemsList() {
  try {
    const payload = {};
    yield put(actions.getContractedItmesListLoading());
    const response = yield call(apis.myPageApi.getContractedItemsList, payload);

    const list = response.list;
    yield put(actions.getContractedItmesListSuccess(list));
  } catch (e) {
    yield put(actions.getContractedItmesListFailure(text.myPageText.MY_BUY_CONTRACTED_ITEMS_ERROR));
  }
}
// ===================== MySell ===================== //
export function* getMySellItemsList({ payload }) {
  try {
    // const { caller } = payload;
    // const payload = {};
    yield put(actions.getMySellItemsListLoading());
    // const response = yield call(apis.myPageApi.getMySellItemsList, payload);
    const response = yield call(apis.myPageApi.getMySellItemsList);

    let list = response?.list;

    yield put(actions.getMySellItemsListSuccess(list));
  } catch (e) {
    console.log(e.message);
    yield put(actions.getMySellItemsListFailure(text.myPageText.MY_SELL_ERROR));
  }
}

// @todo <프론트_요청사항> idx:13
export function* deleteItem() {
  try {
    const payload = {
      reason_code: yield select(selectors.getDeleteReason),
      reason_etc: yield select(selectors.getDeleteReasonTxt),
      status: yield select(selectors.getDeleteStatus),
      goodsIdx: yield select(selectors.getDeleteItemIdx),
    };

    yield put(actions.postDeleteItemLoading());

    const result = yield call(apis.myPageApi.deleteItem, payload);

    yield put(actions.postDeleteItemSuccess(result));
    yield put(actions.getMySellItemsList());
  } catch (e) {
    yield put(actions.postDeleteItemFailure(text.myPageText.MY_SELL_ITEM_DELETE_ERROR));
  }
}

// @todo <프론트_요청사항> idx:15
export function* regCancelItem() {
  try {
    const payload = {
      status: yield select(selectors.getDeleteStatus),
      goodsIdx: yield select(selectors.getRegCancelItemIdx),
    };
    yield put(actions.postRegCancelItemLoading());

    yield call(apis.myPageApi.deleteItem, payload);

    yield put(actions.postRegCancelItemSuccess());
    yield put(actions.getMySellItemsList());
  } catch (e) {
    yield put(actions.postRegCancelItemFailure(text.myPageText.MY_SELL_ITEM_REG_CANCEL_ERROR));
  }
}

// @todo <프론트_요청사항> idx:16
export function* reRegItem() {
  try {
    const payload = {
      goodsIdx: yield select(selectors.getReRegItemIdx),
    };

    yield put(actions.postReRegItemLoading());

    yield call(apis.myPageApi.reRegItem, payload);
    yield put(actions.postReRegItemSuccess());
    const response = yield call(apis.myPageApi.getMySellItemsList, payload);
    let list = response?.list;
    yield put(actions.getMySellItemsListSuccess(list));
    yield put(actions.postReRegItemNonAsk());
  } catch (e) {
    yield put(actions.postReRegItemFailure(text.myPageText.MY_SELL_ITEM_REREG_ERROR));
  }
}

// @todo <프론트_요청사항> idx : 12
export function* postReportItemTradeComplete() {
  try {
    // tradeAgentType [number, 다윈중개사(0), 타 중개사(1)]
    const tradeAgentType = yield select(selectors.getTradeAgentType);
    const type =
      tradeAgentType == CONSTANTS.ITEM_REPORT_TRADE_COMPLETE_DAWIN_AGENT
        ? 0
        : tradeAgentType == CONSTANTS.ITEM_REPORT_TRADE_COMPLETE_NON_DAWIN_AGENT
        ? 1
        : 2;

    if (type == 2) {
      yield put(actions.postReportItemTradeCompleteFailure(text.myPageText.MY_ITEM_REPORT_TRADE_COMPLETE_ERROR));
    } else {
      yield put(actions.postReportItemTradeCompleteLoading());
      const payload = {
        type: type,
        agentIdx: yield select(selectors.getTradeAgentIdx),
        goodsIdx: yield select(selectors.getTradeItemIdx),
      };
      const result = yield call(apis.myPageApi.postReportItemTradeComplete, payload);
      yield put(actions.postReportItemTradeCompleteSuccess(result));
      yield put(actions.getMySellItemsList());
    }
  } catch (e) {
    yield put(actions.postReportItemTradeCompleteFailure(text.myPageText.MY_ITEM_REPORT_TRADE_COMPLETE_ERROR));
  }
}

// ===================== 2.3 MySell ===================== //

// export function* getMySellList(payload) {
//   try {
//     const {showSpinner} = payload;
//     if (showSpinner) {
//       // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
//       yield put(
//         allActions.common.toggleGlobalSpinner({
//           show: true,
//           theme: 'light',
//         })
//       );
//     }
//     yield put(actions.getMySellListLoading());
//     const response = yield call(apis.myPageApi.getMySellList, payload);
//     yield put(actions.getMySellListSuccess(response));
//     if (showSpinner) {
//       yield put(allActions.common.toggleGlobalSpinner(false));
//     }

//   } catch (e) {
//     yield put(actions.getMySellListSuccess(text.myPageText.MY_BUY_FAVORITE_ITEMS_ERROR));
//   }
// }
export function* getMySellList({ limit, offset, status, setter, setHasMore }) {
  try {
    setter ? setter(STATUS.LOADING) : yield put(actions.getMySellListLoading());
    const response = yield call(apis.myPageApi.getMySellList, limit, offset, status);

    // 더 가져올 데이터가 있는지 정보가 넘어오지 않아서 임시처리
    if (response?.length < 10) {
      setHasMore && setHasMore(false);
    }

    setter && setter(STATUS.SUCCESS);
    yield put(actions.getMySellListSuccess(response));
  } catch (e) {
    setter ? setter(STATUS.FAILURE) : yield put(actions.getMySellListSuccess(text.myPageText.MY_BUY_FAVORITE_ITEMS_ERROR));
  }
}
export function* getMySellCount({ statusArr }) {
  try {
    yield put(actions.getMySellCountLoading());
    const response = yield call(apis.myPageApi.getMySellCount, statusArr);
    yield put(actions.getMySellCountSuccess(response));
  } catch (e) {
    // yield put(actions.getMySellCountSuccess(text.myPageText.MY_BUY_FAVORITE_ITEMS_ERROR));
  }
}
export function* getMySellList2({ limit, offset, goodsStatus, setStatus }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    const response = yield call(apis.myPageApi.getMySellList2, limit, offset, goodsStatus);
    // yield put(actions.getMySellListSuccess(response));)
    setStatus && setStatus({ status: STATUS.SUCCESS, result: response });
  } catch (e) {
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}
