import * as AT from './actionTypes';

export const getStartDate = (state) => state?.brokerPage?.suggestion?.startDate;
export const getEndDate = (state) => state?.brokerPage?.suggestion?.endDate;
export const getAttach = (state) => state?.brokerPage?.suggestion?.new?.file_name;
export const getKeyword = (state) => state?.brokerPage?.suggestion?.keyword;
export const getPage = (state) => state?.brokerPage?.suggestion?.page;
export const getNew = (state) => state?.brokerPage?.suggestion?.new;
export const getTotalCnt = (state) => state?.brokerPage?.suggestion?.totalCnt;
export const getList = (state) => state?.brokerPage?.suggestion?.conlist;
export const getDetail = (state) => state?.brokerPage?.suggestion?.detail;
export const getDetailIdx = (state) => state?.brokerPage?.suggestion?.detailIdx;
export const getPreIdx = (state) => state?.brokerPage?.suggestion?.detail?.pre_idx;
export const getPreTitle = (state) => state?.brokerPage?.suggestion?.detail?.pre_title;
export const getNextIdx = (state) => state?.brokerPage?.suggestion?.detail?.next_idx;
export const getNextTitle = (state) => state?.brokerPage?.suggestion?.detail?.next_title;
