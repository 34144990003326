import * as actions from '@/data/rootActions';

const handleGoBack = () => (dispatch) => {
  if (history.length < 3) {
    // 공유 등의 방법으로 접근했을 때, 지정한 곳으로 이동하거나 pathname으로 이동
    // const pathname = location.pathname;
    // const paths = pathname?.replace?.('/', '')?.split?.('/');
    // const parentPath = goBackTo ? goBackTo : `/${paths?.[0]}` || '/';

    // 공유 등의 방법으로 접근하여 되돌아 갈 히스토리가 없을 때 -> home으로 보내기
    dispatch(actions.router.replace('/'));
  } else {
    dispatch(actions.router.goBack());
  }
};

export default handleGoBack;
