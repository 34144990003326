import { GoodsTypeKor, MY_AREA_BTNS } from '@/services/Community';
import { getMyAreas, getMyComplexes, getRecentlyViewedAreas } from './MyAddressListBtns/utils';

export const geInitialData = (bdongCode, complex, mode) => {
  const selectedAddress = getInitialAddress(bdongCode);
  const selectedComplex = complex;
  return { selectedAddress, selectedComplex, mode };
};

export const getInitialAddress = (bdongCode) => {
  const dong = `${bdongCode}`;

  // 동의 경우 optional하게 전체선택이 가능함.
  // 동 전체선택(시군구에서 선택이 끝)이라면 동코드는 끝 다섯자리가 0임.
  // 이 특징으로 dong 코드가 있는지 없는지 확인.
  const dongForCompare = dong.replace('00000', '');
  const isDongCode = dongForCompare?.length === 10;
  const sido = dong.substring(0, 2);
  const gun = dong.substring(2, 5);
  const sigunguCode1 = sido < 40 || (sido >= 40 && gun >= 700) ? `${dong.substring(0, 5)}` : `${dong.substring(0, 4)}0`;
  const sigunguCode2 = dong.substring(0, 5);
  const dong1 = isDongCode ? `${dong.substring(0, 8)}00` : null;
  const dong2 = isDongCode ? dong : null;

  return {
    sido: { code: sido },
    sigungu: { code: sigunguCode2 },
    sigungu1: { code: sigunguCode1 },
    sigungu2: { code: sigunguCode2 },
    dong: { code: dong2 },
    dong1: { code: dong1 },
    dong2: { code: dong2 },
  };
};

export function getInitialAddressByRegionCode(regionCode) {
  const code = regionCode?.code;
  const selectedAddress = getInitialAddress(code);

  selectedAddress.sido.name = regionCode?.region_1depth_name;
  selectedAddress.sigungu.name = regionCode?.region_2depth_name;
  selectedAddress.sigungu1.name = regionCode?.region_2depth_name;
  if (regionCode?.region_4depth_name) {
    selectedAddress.dong.name = regionCode?.region_4depth_name;
    selectedAddress.dong1.name = regionCode?.region_3depth_name;
    selectedAddress.dong2.name = regionCode?.region_4depth_name;
  } else {
    selectedAddress.dong.name = regionCode?.region_3depth_name;
    selectedAddress.dong1.name = regionCode?.region_3depth_name;
  }
  return { selectedAddress };
}

export const myAreaButtons = ({ tags }) => {
  const complexTags = tags?.filter((tag) => tag?.type === 'GOODS_TYPE_TAG');
  const showComplexSelect = complexTags?.length === 1 && (complexTags[0]?.name === '오피스텔' || complexTags[0]?.name === '아파트');

  let filteredBtns;
  if (showComplexSelect) {
    filteredBtns = MY_AREA_BTNS?.filter((button) => button?.type !== 'RECENTLY_VIEWED_AREAS');
  } else {
    filteredBtns = MY_AREA_BTNS?.filter((button) => button?.type === 'INTERESTED_AREAS' || button?.type === 'RECENTLY_VIEWED_AREAS');
  }
  return filteredBtns;
};

export const setAddressInputs = ({ selectedRadioItem, tags, data, setDualAddressData, setTags, setSelectedGoodsTypes, setAddressData }) => {
  const dong = `${selectedRadioItem?.dongCode}`;
  const sido = dong?.slice(0, 2);
  const gun = dong?.slice(2, 5);
  const sigunguCode1 = sido < 40 || (sido >= 40 && gun >= 700) ? `${dong?.slice(0, 5)}` : `${dong?.slice(0, 4)}0`;
  const sigunguCode2 = dong?.slice(0, 5);
  const dong1 = `${dong?.slice(0, 8)}00`;
  const dong2 = dong;

  const newAddressData = {
    sido: { code: sido },
    sigungu: { code: sigunguCode2 },
    sigungu1: { code: sigunguCode1 },
    sigungu2: { code: sigunguCode2 },
    dong: { code: dong2, name: selectedRadioItem?.name },
    dong1: { code: dong1 },
    dong2: { code: dong2 },
  };

  if (selectedRadioItem) {
    if (selectedRadioItem?.complexCode) {
      const newComplexData = {
        complex_idx: `${selectedRadioItem?.complexCode}`,
        complex_name: selectedRadioItem?.mainName,
        complex_type: selectedRadioItem?.complexType,
      };
      setDualAddressData({ ...data, mode: 'SELECT', selectedAddress: newAddressData, selectedComplex: newComplexData });

      const filteredTags = tags?.filter((tag) => tag?.type === 'GOODS_TYPE_TAG');
      const newGoodsTypeTag = {
        type: 'GOODS_TYPE_TAG',
        key: GoodsTypeKor[selectedRadioItem?.complexType],
        name: GoodsTypeKor[selectedRadioItem?.complexType],
      };

      setTags([...filteredTags, newGoodsTypeTag]);
      setSelectedGoodsTypes([selectedRadioItem?.complexType]);
    } else {
      setAddressData(newAddressData);
    }
  }
};
