import {
  apiClient,
  // , kakaoClient
} from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import { stringify } from '@/services/router/querystring';

export const api = {
  async getMyHomeData(payload) {
    try {
      const path = '/personalization/myHome';
      return await apiClient.get(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getMyInterestGoods() {
    try {
      const path = '/personalization/interestGoods';
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getMyHomesData(payload) {
    try {
      const path = '/personalization/myHome';
      return await apiClient.get(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveMyHome(payload) {
    try {
      const path = '/personalization/myHome';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async adjustPriority(category, payload) {
    try {
      const path = '/personalization/' + `${category}` + '/adjustPriority';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveModifyMyHome(payload) {
    try {
      const path = '/personalization/myHome';
      return await apiClient.put(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteMyHome(payload) {
    try {
      const path = '/personalization/myHome';
      return await apiClient.delete(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //관심지역 설정
  async getMyAreaData(payload) {
    try {
      const path = '/personalization/interestDistrict';
      return await apiClient.get(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getMyAreasData() {
    try {
      const path = '/personalization/interestDistrict';
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveMyArea(payload) {
    try {
      const path = '/personalization/interestDistrict';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteMyArea(payload) {
    try {
      const path = '/personalization/interestDistrict';
      return await apiClient.delete(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //관심단지 설정
  async getMyCpsData(payload) {
    try {
      const path = '/personalization/interestComplex';
      return await apiClient.get(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveMyCp(payload) {
    try {
      const path = '/personalization/interestComplex';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteMyCp(payload) {
    try {
      const path = '/personalization/interestComplex';
      return await apiClient.delete(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // [GET] QNA 질문
  async getMyQNAQuestions(payload) {
    try {
      return await apiClient.get('/my/looking/qnalist' + stringify(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // [GET] QNA 답변
  async getMyQNAAnsList(payload) {
    try {
      const result = await apiClient.get('/my/looking/qnaans' + stringify(payload));
      return result.list;
    } catch (e) {
      throw Error(e.message);
    }
  },
};
