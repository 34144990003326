import { actions } from '@/data';
import React from 'react';
import { useDispatch } from 'react-redux';

const ReportBtn = ({ payload, handleClosePop }) => {
  const dispatch = useDispatch();

  const handleReport = (e) => {
    e.preventDefault();
    if (payload?.reason) {
      dispatch(actions.community.reports(payload));
      handleClosePop();
    } else {
      dispatch(
        actions.common.alert({
          contents: '신고 이유를 입력해주세요.',
        })
      );
    }
  };

  return (
    <div className="popup-content-util">
      <div className="button-display module-a style-a type-d">
        <span className="button-area">
          <button type="submit" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={handleReport}>
            <span className="btn-text">신고하기</span>
          </button>
        </span>
      </div>
    </div>
  );
};

export default ReportBtn;
