import React from 'react';
import PostForBuySuccess from '@/combinedComp/Community/Create/components/PostForBuySuccess';
import { actions } from '@/data';
import { STATUS } from '@/data/utils';
import { FeedType } from '@/services/Community';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useSetSpinner = ({ feedType, status, isUpdate }) => {
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(actions.router.goBack());
  };

  const showMessage = () => {
    if (isUpdate) {
      dispatch(
        actions.common.alert({
          contents: '게시물이 수정되었습니다.',
          onConfirm: () => {
            goBack();
          },
        })
      );
    } else {
      if (feedType === FeedType.POST_FOR_BUY) {
        dispatch(
          actions.common.alert({
            contents: <PostForBuySuccess status={status} />,
            onConfirm: () => {
              goBack();
            },
          })
        );
      } else {
        dispatch(
          actions.common.alert({
            contents: '게시물이 등록되었습니다.',
            onConfirm: () => {
              goBack();
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    if (status.status === STATUS.SUCCESS) {
      actions.common.toggleGlobalSpinner(false);
      dispatch(actions.community.fireReload(true));
      showMessage();
    } else if (status.status === STATUS.LOADING) {
      actions.common.toggleGlobalSpinner(true);
    } else if (status.status === STATUS.FAILURE) {
      actions.common.toggleGlobalSpinner(false);
    }
  }, [status]);
  return;
};

export default useSetSpinner;
