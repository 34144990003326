import { put, select, call } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from '../selectors';
import apis from '@/apis';
import { STATUS } from '@/data/utils';

export function* getFeeds({ payload }) {
  try {
    payload?.setLoadingStatus(STATUS.LOADING);
    let re_payload = {};
    if (payload?.searchTag?.key) {
      re_payload = { ...payload, priorityTag: payload?.searchTag };
    } else {
      re_payload = payload;
    }
    if (re_payload?.searchTag) delete re_payload?.searchTag;
    let origin_data = yield select(selectors.community.getFeeds);
    // yield put(actions.getFeedsLoading());
    const result = yield call(apis.communityApi.getFeeds, re_payload);
    // const response = yield call(apis.addressApi.getComplexList, data);

    if (result?.data && result?.data?.length > 0) {
      if (origin_data && origin_data?.length > 0 && payload?.cursor) {
        result.data.map((item) => origin_data.push(item));
        yield put(actions.getFeedsSuccess(origin_data));
        if (payload?.setFeedList) {
          payload?.setFeedList(origin_data);
        }
      } else {
        yield put(actions.getFeedsSuccess(result?.data));
        if (payload?.setFeedList) {
          payload?.setFeedList(result?.data);
        }
      }
      yield put(actions.saveNextCursor(result?.next));
    } else {
      if (payload?.cursor) {
        yield put(actions.getFeedsSuccess(origin_data));
      } else {
        yield put(actions.getFeedsSuccess([]));
        payload?.setFeedList(result?.data);
      }
    }
    payload?.setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    console.log(e.message);
    payload?.setLoadingStatus(STATUS.FAILURE);
  }
}
export function* getSafetyNumber({ feedId, writer_name, writer_id, tags, setViewState1, setViewState2 }) {
  const argument = { writer_id: writer_id, writer_name: writer_name, tags: tags };
  try {
    const result = yield apis.communityApi.getSafetyNumber(feedId);
    yield put(actions.getSafetyNumberSuccess(result?.phone));
    if (result?.phone) setViewState1(writer_name);
    // if (result?.phone) setViewState2(argument);
    else setViewState2(argument);
  } catch (e) {
    setViewState2(argument);
    console.log(e.message);
  }
}
