import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const getMgCounselingList = () => ({
  type: AT.GET_MG_COUNSELING_LIST,
});

export const getMgCounselingGoodsList = () => ({
  type: AT.GET_MG_COUNSELING_GOODS_LIST,
});

export const saveMGC = () => ({
  type: AT.SAVE_MGC,
});
export const saveMGCloading = () => ({
  type: loading(AT.SAVE_MGC),
});
export const saveMGCsuccess = () => ({
  type: success(AT.SAVE_MGC),
});
export const saveMGCfailure = (error) => ({
  type: failure(AT.SAVE_MGC),
  error,
});
export const in_sel = (sel) => ({
  type: AT.SET_IN_SEL,
  sel,
});

export const in_cp = (cp) => ({
  type: AT.SET_IN_CP,
  cp,
});

export const in_contents = (contents) => ({
  type: AT.SET_IN_CONTENTS,
  contents,
});
export const changeKeyword = (keyword) => ({
  type: AT.SET_KEYWORD,
  keyword,
});
export const changeLimit = () => ({
  type: AT.SET_LIMIT,
});
export const resetLimit = () => ({
  type: AT.RESET_LIMIT,
});

export const getMgCounselingListLoading = () => ({
  type: loading(AT.GET_MG_COUNSELING_LIST),
});
export const getMgCounselingListSuccess = (mgcList) => ({
  type: success(AT.GET_MG_COUNSELING_LIST),
  mgcList,
});
export const getMgCounselingListFailure = (error) => ({
  type: failure(AT.GET_MG_COUNSELING_LIST),
  error,
});

export const getMgCounselingGoodsListLoading = () => ({
  type: loading(AT.GET_MG_COUNSELING_GOODS_LIST),
});
export const getMgCounselingGoodsListSuccess = (goodsList) => ({
  type: success(AT.GET_MG_COUNSELING_GOODS_LIST),
  goodsList,
});
export const getMgCounselingGoodsListFailure = (error) => ({
  type: failure(AT.GET_MG_COUNSELING_GOODS_LIST),
  error,
});
export const changeGoodsIdx = (goodsIdx) => ({
  type: AT.CHANGE_GOODS_IDX,
  goodsIdx,
});
export const changeGoodsCommonIdx = (goodsCommonIdx) => ({
  type: AT.CHANGE_GOODS_COMMON_IDX,
  goodsCommonIdx,
});
