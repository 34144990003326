import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import React from 'react';

const FeedLoading = () => {
  return (
    <div style={{ margin: '100px' }}>
      <CommnonSpinner show={true} position="relative" zIndex={100} spinnerWrapperBackground={'transparent'} spinnerColor={'#808080'} />
    </div>
  );
};

export default FeedLoading;
