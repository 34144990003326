import * as actions from '@/data/rootActions';

/**
 * router push
 * 동일한 path로 여러번 push 하지 않기 위해서
 */
const replace = (path) => (dispatch) => {
  dispatch(actions.router.replace(path));
};

export default replace;
