import React from 'react';
import LikeButton from './components/LikeButton';

const Like = ({ item, hasIcon }) => {
  return (
    <>
      {item?.reactionCount?.map((i) => {
        return i.type == 'LIKE' ? (
          <LikeButton key={'like' + item.id} my={item?.myReaction} likeCount={i.count} feedId={item?.id} hasIcon={hasIcon} />
        ) : null;
      })}
    </>
  );
};

export default Like;
