import * as AT from './actionTypes';

export const getMgCounseling = (state) => state?.brokerPage?.mgCounseling;
export const getNewSel = (state) => state?.brokerPage?.mgCounseling?.new;
export const getMgCounselingList = (state) => state?.brokerPage?.mgCounseling?.mgcList;
export const getGoodsList = (state) => state?.brokerPage?.mgCounseling?.mgCounselingGoodsList;
export const getGoodsIdx = (state) => state?.brokerPage?.mgCounseling?.new?.goodsIdx;
export const getGoodsCommonIdx = (state) => state?.brokerPage?.mgCounseling?.new?.goodsCommonIdx;

export const getNewCounseling = (state) => state?.brokerPage?.mgCounseling?.new;
