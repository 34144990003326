import * as AT from './actionTypes';

const INITIAL_STATE = {
  complexHistoryByUser: {},
  realtorsInfo: {
    realtorsId: [],
    realtorsInfo: [],
  },
};

export default function user(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.USER_SET_COMPLEX_HISTORY_BY_USERS:
      return { ...state, complexHistoryByUser: action.payload };
    case AT.SET_REALTORS_INFO:
      return {
        ...state,
        realtorsInfo: {
          realtorsId: [...state.realtorsInfo.realtorsId, ...action.payload.realtorsId],
          realtorsInfo: [...state.realtorsInfo.realtorsInfo, ...action.payload.realtorsInfo],
        },
      };
    case AT.RESET_REALTORS_INFO:
      return {
        ...state,
        realtorsInfo: INITIAL_STATE.realtorsInfo,
      };
    default:
      return { ...state };
  }
}
