import React, { useMemo, useState } from 'react';
import PostCard from '@/components/cards/PostCard';

const UNIT = 3;

const CommentList = ({ commentFeeds, isLoggedIn, statistics }) => {
  const totalList = useMemo(() => commentFeeds?.data?.map((item, key) => <PostCard item={item} key={key} isLoggedIn={isLoggedIn} />), [commentFeeds]);
  const [max, setMax] = useState(UNIT);
  const list = useMemo(() => totalList?.filter((v, key) => key < max), [totalList, max]);

  const readMore = (e) => {
    e.preventDefault();
    setMax(max + UNIT);
  };

  return (
    <div className="section module-a">
      <div className="section-wrap">
        <div className="section-head">
          <h3 className="section-subject">
            <span className="section-name">작성댓글 </span>
            <span className="section-data">{statistics?.commentCount}</span>
          </h3>
        </div>
        <div className="section-body">
          <div className="posts-display module-a style-a type-a">
            {list?.length > 0 ? (
              <div className="posts-list">
                {list}
                {/* {commentFeeds?.status === 'LOADING' && 'Loading...'} */}
              </div>
            ) : (
              <div className="info-board no-data attr-comment">
                <div className="board-wrap">
                  <div className="board-head">
                    <p className="board-summary">
                      <span className="wbr">목록에 표시할 정보가 없습니다.</span>
                    </p>
                  </div>
                </div>
              </div>
            )}

            {max < commentFeeds?.data?.length ? (
              <div className="button-display module-a style-a type-d">
                <span className="button-area">
                  <button className="btn module-a style-b type-line normal-04 large-1x flex" type="button" onClick={readMore}>
                    <span className="btn-text">더보기</span>
                  </button>
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentList;
