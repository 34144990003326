import { loading, success, failure } from '@/data/utils';

//공동중개

export const SET_START_DATE = 'BROKERPAGE/SET_START_DATE';
export const SET_END_DATE = 'BROKERPAGE/SET_END_DATE';
export const SET_SUGGESTION_KEYWORD = 'BROKERPAGE/SET_SUGGESTION_KEYWORD';
export const SET_SUGGESTION_CURRENT_PAGE = 'BROKERPAGE/SET_SUGGESTION_CURRENT_PAGE';
export const SET_SUGGESTION_TOTAL_CNT = 'BROKERPAGE/SET_SUGGESTION_TOTAL_CNT';
export const SET_SUGGESTION_NEW_TITLE = 'BROKERPAGE/SET_SUGGESTION_NEW_TITLE';
export const SET_SUGGESTION_NEW_CATEGORY = 'BROKERPAGE/SET_SUGGESTION_NEW_CATEGORY';
export const SET_SUGGESTION_NEW_CONT = 'BROKERPAGE/SET_SUGGESTION_NEW_CONT';
export const SET_SUGGESTION_NEW_ATTACH = 'BROKERPAGE/SET_SUGGESTION_NEW_ATTACH';

export const SAVE_SUGGESTION_NEW = 'BROKERPAGE/SAVE_SUGGESTION_NEW';

export const GET_SUGGESTION_LIST = 'BROKERPAGE/GET_SUGGESTION_LIST';
export const GET_SUGGESTION_LIST_LOADING = loading(GET_SUGGESTION_LIST);
export const GET_SUGGESTION_LIST_SUCCESS = success(GET_SUGGESTION_LIST);
export const GET_SUGGESTION_LIST_FAILURE = failure(GET_SUGGESTION_LIST);

export const GET_SUGGESTION_DETAIL = 'BROKERPAGE/GET_SUGGESTION_DETAIL';
export const GET_SUGGESTION_DETAIL_LOADING = loading(GET_SUGGESTION_DETAIL);
export const GET_SUGGESTION_DETAIL_SUCCESS = success(GET_SUGGESTION_DETAIL);
export const GET_SUGGESTION_DETAIL_FAILURE = failure(GET_SUGGESTION_DETAIL);
