export const getURLPrefix = () => {
  const isBrokerPage = getIsBrokerPage();
  const base = '/selling/manual';

  if (isBrokerPage) return `/brokerPage${base}`;
  else return base;
};

export const getIsBrokerPage = () => {
  return location.pathname.indexOf('/brokerPage') > -1;
};
