export const OSTYPE = {
  APP_IOS: 'iOS-App',
  APP_ANDROID: 'Android-App',
  WEB_IOS: 'iOS-Web',
  WEB_ANDROID: 'Android-Web',
  DESKTOP: 'Desktop',
};

export default class OSType {
  constructor(osType) {
    if (!Object.values(OSTYPE).includes(osType)) {
      throw new Error('잘못된 OsType');
    }

    const isApp = osType === OSTYPE.APP_ANDROID || osType === OSTYPE.APP_IOS;

    this.osType = osType;
    this.isApp = isApp;
    this.isNotApp = !isApp;
  }
}
