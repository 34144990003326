import React, { useEffect, useState } from 'react';
import { selectors } from '@/data';
import { useSelector } from 'react-redux';

const useGetCandidateIds = () => {
  const [candidateList, setCandidateList] = useState();
  const candidateIds = useSelector(selectors.auth.getIdCandidates);

  useEffect(() => {
    setCandidateList(candidateIds);
  }, [candidateIds]);

  return candidateList;
};

export default useGetCandidateIds;
