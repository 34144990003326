import { parseCallBack } from '@/services/Helper';
import { useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';

const useFeedId = () => {
  const querystring = parseCallBack();
  const detail_item = useSelector(selectors.community.getFeedDetailItem);
  const feedId = detail_item && detail_item.feedId ? detail_item.feedId : querystring['feedDetail'];
  return feedId;
};

export default useFeedId;
