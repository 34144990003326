import * as actions from '@/data/rootActions';

/**
 * router push
 * 동일한 path로 여러번 push 하지 않기 위해서
 */
const push = (path) => (dispatch) => {
  const currentPath = `${location.pathname}${location.search}`;
  const nextPath = path.trim();

  if (currentPath !== path) {
    dispatch(actions.router.push(nextPath));
  }
};

export default push;
