import terms from '../terms';
import types from '../types';

const mapping = {
  [types.BrokerageCommisionRate]: { contents: terms.brokerageCommisionRate, name: '다윈중개 중개수수료율' },
  [types.LocationBasedServiceTerm]: { contents: terms.locationBasedService, name: '위치정보 서비스 이용약관' },
  [types.OperationalPolicy]: { contents: terms.operationalPolicy, name: '운영정책' },
  [types.PrivacyPolicy]: { contents: terms.privacyPolicy, name: '개인정보취급방침' },
  [types.TermsOfUse]: { contents: terms.termsOfUse, name: '이용약관' },
  [types.TermsOfUseForBrokerService]: { contents: terms.termsOfUseForBrokerService, name: '중개사서비스 이용약관' },
};

export default mapping;
