import * as community from '../../services/Community';

/**
 * @deprecated
 */
export { community };

export const DAWIN_URL = {
  home: '/',
  buying: '/buying',
  analysis: '/analysis',
  selling: '/selling',
  newsale: '/newsale',
  reconstrct: '/reconstrct',
  news: '/news',
  myPage: '/myPage',
  aboutUs: '/aboutus',
  helper: '/faq',
  REPORT_BROKER: '/reportBroker',
  FIND_ID: '/find/id',
  FIND_PW: '/find/password',
  community: '/community',
  chat: '/chat',
  my: '/my',
};
export const MENU_LIST = [
  {
    key: DAWIN_URL.home,
    type: 'mob',
    text: '홈으로',
    path: DAWIN_URL.home,
    parent: null,
  },
  {
    key: DAWIN_URL.buying,
    type: 'all',
    text: '집구하기',
    path: DAWIN_URL.buying,
    parent: null,
  },
  // {   key: 2,   type: 'web',   text: '분석',   path: DAWIN_URL.analysis,
  // parent: 1, }, {   key: 3,   type: 'web',   text: '추천',   path: '#',   parent:
  // 1, },
  {
    key: DAWIN_URL.selling,
    type: 'all',
    text: '집내놓기',
    path: DAWIN_URL.selling,
    parent: null,
  },
  // {   key: 5,   type: 'all',   text: '분양',   path: DAWIN_URL.newsale,   parent:
  // null, },
  {
    key: DAWIN_URL.reconstrct,
    type: 'all',
    text: '재건축',
    path: DAWIN_URL.reconstrct,
    parent: null,
  },
  {
    key: DAWIN_URL.news,
    type: 'all',
    text: '부동산뉴스',
    path: DAWIN_URL.news,
    parent: null,
  },
  {
    key: DAWIN_URL.myPage,
    type: 'mob',
    text: '마이페이지',
    path: DAWIN_URL.myPage,
    parent: null,
  },
];

export const SIZE_MARK_TYPE = {
  METER: 1,
  PYEONG: 2,
};

export const BUYING_SEARCH_OPTIONS = {
  A: {
    APT: '아파트',
    OFT: '오피스텔',
  },
  B: {
    all: '전체', //세부유형
    // a: '분양',
    b: '재건축',
    c: '입주예정',
  },
  B2: {
    // a: '분양',
    b: '재건축',
    c: '입주예정',
  },
  C: {
    APT: {
      all: '거래유형',
      s: '매매',
      c: '전세',
      m: '월세',
    },
    OFT: {
      all: '거래유형',
      s: '매매',
      c: '전세',
      m: '월세',
    },
  },
  C2: {
    all: '전체',
    s: '매매',
    c: '전세',
    m: '월세',
  },
  D: {
    all: '면적',
    '0-66': '66m²미만',
    '66-99': '66-99m²',
    '99-132': '99-132m²',
    '132-165': '132-165m²',
    '165-': '165m²이상',
  },
  D2: {
    all: '면적',
    '0-66': '10평대 이하',
    '66-99': '20평대',
    '99-132': '30평대',
    '132-165': '40평대',
    '165-': '50평대 이상',
  },
  D3: {
    all: '전체',
    '0-66': '10평대 이하',
    '66-99': '20평대',
    '99-132': '30평대',
    '132-165': '40평대',
    '165-': '50평대 이상',
  },
};
export const COMMUINTY_CATEGORY = {
  A: '구해줘 다윈',
  B: '단지톡',
  C: '지역톡',
  D: '부동산Q&A',
  E: '원룸톡',
};
export const RECONSTRUCT_STATE = {
  1: '기본계획',
  2: '안전진단',
  3: '구역지정',
  4: '추진위',
  5: '조합설립',
  6: '사업시행',
  7: '관리처분',
  8: '철거신고',
  9: '착공신고',
  10: '분양승인',
};

export const REBUILD_SCORE_COUPON = '201584SDIMK85';

const globalVars = {
  MENU_LIST,
  SIZE_MARK_TYPE,
  BUYING_SEARCH_OPTIONS,
  RECONSTRUCT_STATE,
  // 마이페이지
  ITEM_STATUS_OPTIONS: {
    CONFIRM_STATUS: {
      PS1: '승인요청',
      PS2: '승인완료',
      PS3: '승인보류',
      PS4: '승인거절',
    },
    ITEM_STATUS: {
      BL: '승인전',
      SB: '정상',
      DR: '거래완료',
      RC: '등록취소',
      CF: '삭제요청',
      TR: '삭제완료',
    },
  },
  // 로딩팝업
  LOADING_SPINNER_COLOR: '#1e1e1e',

  ADDR_SELECT_COMP_CODE: {
    SELLING_MANUAL: 'SELLING_MANUAL',
    AGENT_SIGNUP: 'AGENT_SIGNUP',
    RANKING_RESULT: 'RANKING_RESULT',
    WEEKLY_PRICE: 'WEEKLY_PRICE',
    ADD_POSITIVES: 'ADD_POSITIVES',
  },

  // 직접 매물등록에서 임시저장 혹은 저장 구분하는 코드
  MANUAL_REQUEST_STATE_CODE: {
    COMPLETE: 'saved',
    TEMP: 'true',
  },

  // s매매/c전세/m월세
  TRADE_TYPE_CODE: {
    SALES: '1',
    JEONSAE: '2',
    RENT: '3',
  },

  // '${방 구조 코드 - 원룸[오픈형] : RO, 원룸[분리형] : RD, 1.5룸 : OR, 투룸 : TR, 쓰리룸 이상: TU, 복층형
  // : LA},'
  ROOM_TYPE_CODE: {
    RO: '원룸(오픈형)',
    RD: '원룸(분리형)',
    OR: '1.5룸',
    TR: '투룸',
    YU: '쓰리룸 이상',
    LA: '복층형',
  },
};

export const OS_TYPES = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
  MOBILE_WEB: 'mobile_web',
  ANDROID_WEB: 'android_web',
  IOS_WEB: 'ios_web',
};

export const COOKIE_CONSTS = {
  EXPIRE_IN: 365 * 1,
  ACCESS_TOKEN: '_accessToken',
  USER_INFO: '_userInfo',
  MAP_INFO: 'minfo',
  SAVE_ID: 'dsi',
  ANALYSIS: 'anls',
  APPINFO: 'ainfo',
  APPINFO_UPDATE_DATE: 'aud',
  SEARCH_HISTORY: 'search_history',
  SEARCH_HISTORY_BUNDLES: 'search_history_bundles',
};

// 메인 > 배너의 쿠키 key
export const BANNER_KEY = 'augustbanner';
export const BANNER_KEY2 = 'mypageRecommendAgent';
export const BANNER_KEY_RA_MAIN = 'mainRecommendAgent';

export default globalVars;
