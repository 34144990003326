import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import * as selectors from '@/data/rootSelectors';

/**
 * 먼저 카운트와 좋아요 on을 실행한 후에 api를 호출하고 api 응답이 실패하면 카운트를 다시 수정한다.
 */

// emoji yes or no ... 에 따라서 view 분기 로직 유지
const LikeButton = ({ my, likeCount, feedId, commentId, publishStatus, setDeletedStatus, hasIcon, isFeedDeleted }) => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(likeCount);
  const [myLike, setMyLike] = useState(my?.reactionType);
  const [status, setStatus] = useState({});
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  useEffect(() => {
    if (status?.reactionType == 'LIKE' && status?.status == 'FAILURE') {
      setCount(count - 1);
    } else if (status?.reactionType == 'UNLIKE' && status?.status == 'FAILURE') {
      setCount(count + 1);
    }
  }, [status]);

  const plusCount = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLoggedIn) {
      dispatch(actions.common.pleaseLoginPopup(true));
      //dispatch(actions.router.goBack());
    } else {
      setMyLike('LIKE');
      setCount(count + 1);
      if (commentId) {
        dispatch(actions.community.commentLike(commentId, 'LIKE', setStatus));
      } else {
        dispatch(actions.community.like(feedId, 'LIKE', setStatus));
      }
    }
  };

  const minusCount = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLoggedIn) {
      dispatch(actions.common.pleaseLoginPopup(true));
      //dispatch(actions.router.goBack());
    } else {
      setMyLike('UNLIKE');
      setCount(count - 1);
      //minus 동작은 없는것인가?
      if (commentId) {
        dispatch(actions.community.commentLike(commentId, 'UNLIKE', setStatus));
      } else {
        dispatch(actions.community.like(feedId, 'UNLIKE', setStatus));
      }
    }
  };

  const clickLike = (e) => {
    e.preventDefault();
    if (publishStatus === 'DELETED' || setDeletedStatus || isFeedDeleted) {
      return;
    } else {
      if (myLike != 'LIKE') {
        plusCount(e);
      } else {
        minusCount(e);
      }
    }
  };

  return hasIcon ? (
    <span className="data-item likeit">
      <button
        type="button"
        className="btn"
        onClick={(e) => {
          clickLike(e);
        }}>
        <span className="btn-text">
          좋아요 <span className="em">{count}</span>
        </span>
      </button>
    </span>
  ) : (
    <span
      className="data-item likeit"
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        clickLike(e);
      }}>
      <span className="head">좋아요</span>
      <span className="body">{count}</span>
    </span>
  );
};

export default LikeButton;
