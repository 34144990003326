import React, { useEffect, useMemo } from 'react';
import { FeedType, GoodsTypeKor, TagType } from '@/services/Community';
import Contents from '@/combinedComp/Community/Create/Post/components/Contents';
import Images from '@/combinedComp/Community/Create/Post/components/Images';
import HashTagFixed from '@/combinedComp/Community/Create/Post/components/HashTagFixed';
import HashTagInput from '@/combinedComp/Community/Create/Post/components/HashTagInput';
import DualAddress from '@/combinedComp/DualAddress';

const LocalPost = ({
  feedType,
  addedTagsRef,
  contents,
  setContents,
  tags,
  setTags,
  numericTags,
  imagesRef,
  defaultData,
  isUpdate,
  updateFeedDetail,
  dualAddressData,
  setDualAddressData,
  dualAddressMarkRule,
}) => {
  const needToMarkAddressKor = useMemo(() => feedType === FeedType.COMPLEX_POST || feedType === FeedType.LOCAL_POST, [feedType]);
  const addressTagsKor = useMemo(() => {
    const filteredTags = tags.filter((tag) => tag.type === TagType.ADDRESS_Dong_TAG || tag.type === TagType.COMPLEX_TAG).map((item) => item.name);
    return filteredTags.join(' ');
  }, [tags]);

  const handleContents = (e) => {
    setContents(e.target.value ? e.target.value : '');
  };

  useEffect(() => {
    const address = dualAddressData?.selectedAddress; // 시군구, 동
    const complex = dualAddressData?.selectedComplex; // 단지

    let filteredTags = tags?.filter((tag) => tag.type.indexOf('ADDRESS') !== 0 && tag.type !== TagType.COMPLEX_TAG);

    const sigungu = address?.sigungu;
    const dong = address?.dong;
    const newTags = [];

    if (dong?.code && dong?.code !== 'all') {
      newTags.push({ type: TagType.ADDRESS_TAG, key: dong?.code, name: dong?.name });
    } else if (sigungu?.code) newTags.push({ type: TagType.ADDRESS_TAG, key: sigungu?.code, name: sigungu?.name });

    if (complex?.complex_idx && complex?.complex_idx !== 'all') {
      filteredTags = filteredTags?.filter((tag) => tag.type !== TagType.GOODS_TYPE_TAG);
      newTags.push({ type: TagType.COMPLEX_TAG, key: `${complex.complex_idx}`, name: complex.complex_name });
      newTags.push({ type: TagType.GOODS_TYPE_TAG, key: GoodsTypeKor[complex.complex_type], name: GoodsTypeKor[complex.complex_type] });
    }

    setTags([...filteredTags, ...newTags]);
  }, [dualAddressData]);

  return (
    <div className="form-list">
      <DualAddress
        viewType="community"
        data={dualAddressData}
        setData={setDualAddressData}
        markRule={dualAddressMarkRule}
        title={'단지/주소검색'}
        searchInputPlaceholder={'단지명이나 주소로 검색해주세요.'}
        toSelectButtonText={'리스트에서 선택'}
        toTextButtonText={'단지 및 지역 검색'}
        addAllOption={true}
        tags={tags}
        feedType={feedType}
        localAddressMode={true}
      />
      <Contents
        needToMarkAddressKor={needToMarkAddressKor}
        addressTagsKor={addressTagsKor}
        contents={contents}
        handleContents={handleContents}
        feedType={feedType}
      />
      <Images isUpdate={isUpdate} imagesRef={imagesRef} updateFeedDetail={updateFeedDetail} feedType={feedType} />
      <HashTagFixed tags={tags} feedType={feedType} numericTags={numericTags} />
      <HashTagInput addedTags={addedTagsRef} feedType={feedType} isUpdate={isUpdate} updateFeedDetail={updateFeedDetail} />
    </div>
  );
};

export default LocalPost;
