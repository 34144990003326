import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery } from 'redux-saga/effects';

export default function*() {
  // [GET] 나의 실무교육 신청 내역
  yield takeEvery(AT.GET_ENROLLMENTS, sagas.getEnrollments);

  // [POST] 실무교육 신청
  yield takeEvery(AT.APPLY_ACADEMY, sagas.applyAcademy);

  // [POST] 실무교육 결제하기 STEP1
  yield takeEvery(AT.PAY_ACADEMY_FEE_STEP_ONE, sagas.payAcademyFeeStepOne);

  // [POST] 실무교육 결제하기 STEP2
  yield takeEvery(AT.PAY_ACADEMY_FEE_STEP_TWO, sagas.payAcademyFeeStepTwo);

  // [PUT] 실무교육 일정 변경
  yield takeEvery(AT.EDIT_COURSE, sagas.editCourse);

  // [POST] 실무교육 취소
  yield takeEvery(AT.CANCEL_COURSE, sagas.cancelCourse);

  // [POST] 실무교육 신청 로깅
  yield takeEvery(AT.APPLY_ACADEMY_LOG, sagas.applyAcademyLog);
}
