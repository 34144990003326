import React from 'react';
import { getDirectionText, LIVE_STATUS_TEXT } from '@/services/goods/detail';

const HouseOptions = ({ live_status, room_cnt, bathroom_cnt, direction, expense, pet }) => {
  const getLiveStatus = () => {
    return LIVE_STATUS_TEXT[live_status];
  };

  const getRoomCounts = () => {
    const counts = [room_cnt, bathroom_cnt]?.filter(Boolean)?.map((item, index) => {
      if (item && index === 0) {
        return `방${room_cnt}`;
      } else if (item && index === 1) {
        return `화장실${bathroom_cnt}`;
      }
    });
    const hasCounts = counts?.length > 0;
    return hasCounts ? counts?.join('/') : null;
  };

  const getHouseDirection = () => {
    return direction ? `${getDirectionText(direction)}향` : null;
  };

  const getMaintenanceFee = () => {
    return expense ? `관리비 ${expense}만원 (전기세, 가스비포함)` : null;
  };

  const getPetAcceptable = () => {
    return pet ? `반려동물 ${pet}` : null;
  };

  const infoTagList = [getLiveStatus(), getRoomCounts(), getHouseDirection(), getMaintenanceFee(), getPetAcceptable()]?.filter(Boolean);

  const hasInfoTagList = infoTagList?.length > 0;

  return (
    <>
      {hasInfoTagList ? (
        <span className="basic-info-wrap">
          {infoTagList?.map((item, index) => (
            <span className="info-tag" key={index}>
              {item}
            </span>
          ))}
        </span>
      ) : null}
    </>
  );
};

export default React.memo(HouseOptions);
