import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.GET_COMPLEX_AREA, sagas.getComplexArea);
  yield takeEvery(AT.GET_COMPLEX_AREA2, sagas.getComplexArea2);
  yield takeLatest(AT.SEARCH_KEYWORD, sagas.searchKeyword);
  yield takeEvery(AT.UPLOAD_FILE, sagas.uploadFile);
  yield takeEvery(AT.UPLOAD_ANY_FILE, sagas.uploadAnyFile);
  yield takeEvery(AT.ADD_FAV_COMPLEX, sagas.addFavComplex);
  yield takeEvery(AT.GET_FAV_COMPLEX_LIST, sagas.getFavComplexList);
  yield takeEvery(AT.HANDLE_FAV_GOODS, sagas.handleFavGoods);
  yield takeEvery(AT.GET_FAQ_LIST, sagas.footer.getFaqList);
  yield takeEvery(AT.ADD_QUESTION, sagas.footer.addQuestion);
  yield takeLatest(AT.GET_QNA_CATEGORY_LIST, sagas.footer.getQnaCategoryList);
  yield takeLatest(AT.REPORT_BROKER, sagas.footer.reportBroker);
  yield takeLatest(AT.GET_MY_BROKER_LIST, sagas.footer.getMyBrokerList);
  yield takeLatest(AT.GET_SEARCH_BROKER_LIST, sagas.footer.getSearchBrokerList);
  yield takeLatest(AT.GET_SEARCH_BROKER_LIST_BY_SETTING, sagas.getSearchBrokerListBySetting);
}
