import buiToggle from './bui.toggle.js';
import buiExpand from './bui.expand.js';
import buiTab from './bui.tab.js';

// Polyfill get Siblings
let getSiblings = function(e) {
  let siblings = [];
  if (!e.parentNode) {
    return siblings;
  }
  let sibling = e.parentNode.firstChild;
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
};

// 라디오 버튼 체크여부 확인
export function checkedWithin(selector) {
  document.querySelectorAll(selector).forEach(function(optionList) {
    optionList.querySelectorAll('.option-item').forEach(function(optionItem) {
      let formElem = optionItem.querySelector('.form-elem');
      formElem.addEventListener('change', function() {
        optionItem.classList.add('checked-within');

        getSiblings(optionItem).forEach(function(siblings, index) {
          siblings.classList.remove('checked-within');
        });
      });
    });
  });
}

/**
 * @module	buiToggle
 * @name	mapControlItem
 * @update	2022-05-10
 */
export const mapControlItem = new buiToggle('[data-bui-toggle="mapControlItem"]', {
  clickout: true,
});

/**
 * @module	buiToggle
 * @name	realEstateInfoItem
 * @update	2022-05-10
 */
export const realEstateInfoItem = new buiToggle('[data-bui-toggle="realEstateInfoItem"]', {
  clickout: true,
});

/**
 * @module	buiToggle
 * @name	frontPopup
 * @update	2022-05-10
 */
// export const frontPopup = new buiToggle('[data-bui-toggle="frontPopup"]', {
//   inactiveButton: true,
//   inactiveButtonClass: 'btn popup-close',
//   inactiveButtonText:
//     '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><title>닫기</title><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>',
//   inactiveButtonArea: '.popup-local-func .button-area',
//   reactTarget: 'html',
//   reactTargetActiveClass: 'active-front-popup',
//   focusin: true,
//   onloadCallBack: function(myToggle) {
//     const popupFuncContainer = myToggle.toggleTarget.querySelector('.popup-local');
//     const popupFunc = document.createElement('div');
//     popupFunc.className = 'popup-local-func';
//     popupFunc.innerHTML = '<span class="button-area"></span>';
//     popupFuncContainer.appendChild(popupFunc);
//   },
// });

/**
 * @module	buiToggle
 * @name	contentsPopup
 * @update	2022-05-10
 */
export const contentsPopup = new buiToggle('[data-bui-toggle="contentsPopup"]', {
  inactiveButton: true,
  inactiveButtonClass: 'btn popup-close',
  inactiveButtonText:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><title>닫기</title><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>',
  inactiveButtonArea: '.popup-local-func .button-area',
  reactTarget: 'html',
  reactTargetActiveClass: 'active-content-popup',
  focusin: true,
  onloadCallBack: function(myToggle) {
    const popupFuncContainer = myToggle.toggleTarget.querySelector('.popup-local');
    const popupFunc = document.createElement('div');
    popupFunc.className = 'popup-local-func';
    popupFunc.innerHTML = '<span class="button-area"></span>';
    popupFuncContainer.appendChild(popupFunc);
  },
  activeBeforeCallBack: function(myToggle) {
    myToggle.toggleTarget.classList.add('enabled');
  },
  inactiveAfterCallBack: function(myToggle) {
    setTimeout(function() {
      myToggle.toggleTarget.classList.remove('enabled');
    }, 250);
  },
});

/**
 * @module	buiToggle
 * @name	infoPopup
 * @update	2022-05-10
 */
export const infoPopup = new buiToggle('[data-bui-toggle="infoPopup"]', {
  inactiveButton: true,
  inactiveButtonClass: 'btn module-a style-a type-none normal-01 large-1x',
  inactiveButtonText: '<span class="btn-text">확인</span>',
  inactiveButtonArea: '.popup-local-func .button-area',
  reactTarget: 'html',
  reactTargetActiveClass: 'active-content-popup',
  focusin: true,
  onloadCallBack: function(myToggle) {
    const popupFuncContainer = myToggle.toggleTarget.querySelector('.popup-local');
    const popupFunc = document.createElement('div');
    popupFunc.className = 'popup-local-func';
    popupFunc.innerHTML = '<div class="button-display"><span class="button-area"></span></div>';
    popupFuncContainer.appendChild(popupFunc);
  },
  activeBeforeCallBack: function(myToggle) {
    myToggle.toggleTarget.classList.add('enabled');
  },
  inactiveAfterCallBack: function(myToggle) {
    setTimeout(function() {
      myToggle.toggleTarget.classList.remove('enabled');
    }, 250);
  },
});

/**
 * @module	buiExpand
 * @name	realEstateInfo
 * @update	2022-05-10
 */
export const realEstateInfo = new buiExpand('[data-bui-expand="realEstateInfo"]', {
  accordion: false,
  activeClass: 'active',
  buttonClass: 'btn expand',
  buttonText:
    '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" xmlns="http://www.w3.org/2000/svg"><title>펼치기</title><path d="M6.37,8.48a1.24,1.24,0,0,1,1.76,0L12,12.35l3.87-3.87a1.25,1.25,0,1,1,1.76,1.77L12,15.88,6.37,10.25A1.25,1.25,0,0,1,6.37,8.48Z"></path></svg>',
  buttonActiveText:
    '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" xmlns="http://www.w3.org/2000/svg"><title>접기</title><path d="M17.63,15.52a1.24,1.24,0,0,1-1.76,0L12,11.65,8.13,15.52a1.25,1.25,0,1,1-1.76-1.77L12,8.12l5.63,5.63A1.25,1.25,0,0,1,17.63,15.52Z"></path></svg>',
  buttonAppendTo: '.section-util .button-area',

  onloadCallBack: function(target) {
    const targetContainer = target.querySelector('.section-wrap');
    const targetUtil = document.createElement('div');
    targetUtil.className = 'section-util';
    targetUtil.innerHTML = '<div class="button-display"><span class="button-area"></span></div>';
    targetContainer.appendChild(targetUtil);

    function checkChecked() {
      const formItems = target.querySelectorAll('.form-item');
      formItems.forEach(function(formItem) {
        const formElem = formItem.querySelector('input[type=radio].form-elem');
        formElem.checked ? formItem.classList.add('active') : formItem.classList.remove('active');
      });
    }
    checkChecked();

    const formElems = target.querySelectorAll('input[type=radio].form-elem');
    formElems.forEach(function(formElem) {
      formElem.addEventListener('change', function() {
        checkChecked();
      });
    });
  },
});

/**
 * @module	buiToggle
 * @name	toastPopupStyleA
 * @update	2022-03-20
 */
export const toastPopupStyleA = new buiToggle('[data-bui-toggle="toastPopupStyleA"]', {
  inactiveButton: true,
  inactiveButtonText:
    '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" xmlns="http://www.w3.org/2000/svg"><title>닫기</title><path d="M10.5382 12L3 4.46179L4.46179 3L12 10.5382L19.5382 3L21 4.46179L13.4618 12L21 19.5382L19.5382 21L12 13.4618L4.46179 21L3 19.5382L10.5382 12Z"></path></svg>',
  inactiveButtonClass: 'btn popup-close',
  inactiveButtonArea: '.popup-local',
  activeBeforeCallBack: function(myToggle) {
    myToggle.toggleTarget.classList.add('enabled');
  },
  inactiveAfterCallBack: function(myToggle) {
    setTimeout(function() {
      myToggle.toggleTarget.classList.remove('enabled');
    }, 750);
  },
});

/**
 * @name	buiFormRange
 * @update	2022-03-20
 */
export function buiFormRange(selector) {
  const settings = {
    rangeMinimum: '.range-min',
    rangeMaximum: '.range-max',
    enteredTrack: 'entered-track',
    enteredValue: 'entered-value',
    thumbMinimum: 'thumb-minimum',
    thumbMaximum: 'thumb-maximum',
  };

  const formRanges = document.querySelectorAll(selector);

  formRanges.forEach(function(formRange) {
    const rangeMinimum = formRange.querySelector(settings.rangeMinimum);
    const rangeMaximum = formRange.querySelector(settings.rangeMaximum);

    let enteredValue, thumbMinimum, thumbMaximum;
    if (formRange.querySelector('.form-range-slider') === null) {
      var formRangeSlider = document.createElement('span');
      var enteredTrack = document.createElement('span');
      enteredValue = document.createElement('span');
      thumbMinimum = document.createElement('span');
      thumbMaximum = document.createElement('span');

      formRangeSlider.className = 'form-range-slider';
      enteredValue.className = settings.enteredValue;
      enteredTrack.className = settings.enteredTrack;
      thumbMinimum.className = settings.thumbMinimum;
      thumbMaximum.className = settings.thumbMaximum;

      if (rangeMinimum.disabled) thumbMinimum.classList.add('disabled');
      if (rangeMaximum.disabled) thumbMaximum.classList.add('disabled');

      formRangeSlider.appendChild(enteredTrack);
      formRangeSlider.appendChild(enteredValue);
      formRangeSlider.appendChild(thumbMinimum);
      formRangeSlider.appendChild(thumbMaximum);
      formRange.appendChild(formRangeSlider);

      const setpList = document.createElement('span');
      setpList.className = 'form-step-list';

      formRange.dataset.buiStep.split(', ').forEach(function(stepName) {
        const stopItem = document.createElement('span');
        stopItem.innerHTML = stepName;
        stopItem.className = 'form-step-item';
        setpList.appendChild(stopItem);
      });

      formRange.appendChild(setpList);
    } else {
      enteredValue = formRange.querySelector(`.${settings.enteredValue}`);
      thumbMinimum = formRange.querySelector(`.${settings.thumbMinimum}`);
      thumbMaximum = formRange.querySelector(`.${settings.thumbMaximum}`);
    }

    function setMinimumValue() {
      const min = parseInt(rangeMinimum.min);
      const max = parseInt(rangeMinimum.max);
      rangeMinimum.value = Math.min(parseInt(rangeMinimum.value), parseInt(rangeMaximum.value) - parseInt(rangeMaximum.step));
      const percent = ((rangeMinimum.value - min) / (max - min)) * 100;
      if (thumbMinimum?.style) thumbMinimum.style.left = percent + '%';
      if (enteredValue?.style) enteredValue.style.left = percent + '%';
      if (thumbMinimum?.dataset) thumbMinimum.dataset.buiValue = rangeMinimum.value;
    }
    setMinimumValue();

    function setMaximumValue() {
      const min = parseInt(rangeMaximum.min);
      const max = parseInt(rangeMaximum.max);
      rangeMaximum.value = Math.max(parseInt(rangeMaximum.value), parseInt(rangeMinimum.value) + parseInt(rangeMinimum.step));
      const percent = ((rangeMaximum.value - min) / (max - min)) * 100;
      if (thumbMaximum?.style) thumbMaximum.style.right = 100 - percent + '%';
      if (enteredValue?.style) enteredValue.style.right = 100 - percent + '%';
      if (thumbMaximum?.dataset) thumbMaximum.dataset.buiValue = rangeMaximum.value;
    }
    setMaximumValue();

    if (rangeMinimum) rangeMinimum.addEventListener('input', setMinimumValue);
    if (rangeMaximum) rangeMaximum.addEventListener('input', setMaximumValue);
    if (rangeMinimum) rangeMinimum.addEventListener('change', setMinimumValue);
    if (rangeMaximum) rangeMaximum.addEventListener('change', setMaximumValue);

    function minimumEventStart(event) {
      event.target.style.zIndex = '3';
      if (thumbMinimum?.classList) thumbMinimum.classList.add('active');
    }

    function minimumEventEnd(event) {
      event.target.style.zIndex = '2';
      if (thumbMinimum?.classList) thumbMinimum.classList.remove('active');
    }

    function maximumEventStart(event) {
      event.target.style.zIndex = '3';
      if (thumbMaximum?.classList) thumbMaximum.classList.add('active');
    }

    function maximumEventEnd(event) {
      event.target.style.zIndex = '2';
      if (thumbMaximum?.classList) thumbMaximum.classList.remove('active');
    }

    if (rangeMinimum) rangeMinimum.addEventListener('focus', minimumEventStart, false);
    if (rangeMinimum) rangeMinimum.addEventListener('mousedown', minimumEventStart, false);
    if (rangeMinimum) rangeMinimum.addEventListener('touchstart', minimumEventStart, false);
    if (rangeMinimum) rangeMinimum.addEventListener('focusout', minimumEventEnd, false);
    if (rangeMinimum) rangeMinimum.addEventListener('mouseup', minimumEventEnd, false);
    if (rangeMinimum) rangeMinimum.addEventListener('touchend', minimumEventEnd, false);

    if (rangeMaximum) rangeMaximum.addEventListener('focus', maximumEventStart, false);
    if (rangeMaximum) rangeMaximum.addEventListener('mousedown', maximumEventStart, false);
    if (rangeMaximum) rangeMaximum.addEventListener('touchstart', maximumEventStart, false);
    if (rangeMaximum) rangeMaximum.addEventListener('focusout', maximumEventEnd, false);
    if (rangeMaximum) rangeMaximum.addEventListener('mouseup', maximumEventEnd, false);
    if (rangeMaximum) rangeMaximum.addEventListener('touchend', maximumEventEnd, false);

    if (rangeMinimum)
      rangeMinimum.addEventListener('input', function(event) {
        if (thumbMinimum?.dataset) thumbMinimum.dataset.buiValue = rangeMinimum.value;
      });

    if (rangeMaximum)
      rangeMaximum.addEventListener('input', function(event) {
        if (thumbMaximum?.dataset) thumbMaximum.dataset.buiValue = rangeMaximum.value;
      });
  });
}

/**
 * @name buiFormCheckValue
 */
function buiFormCheckValue(formElem) {
  formElem.value.length > 0 ? formElem.classList.add('typed') : formElem.classList.remove('typed');
}

/**
 * @name buiFormCancel
 */
export function buiFormCancel(formElem) {
  if (formElem.readOnly) return;

  // check value
  buiFormCheckValue(formElem);

  // form util
  var formUtil = formElem.parentElement.querySelector('.form-func');
  if (!formUtil) {
    formUtil = document.createElement('span');
    formUtil.classList.add('form-func');
    formElem.parentElement.appendChild(formUtil);
  }

  // form cancel
  var formCancel = formElem.parentElement.querySelector('.form-cancel');
  if (!formCancel) {
    formCancel = document.createElement('span');
    formCancel.classList.add('form-cancel');
    formUtil.prepend(formCancel);
  }

  formElem.style.paddingRight = formUtil.offsetWidth + 'rem';

  var xStart = formUtil.offsetLeft + formCancel.offsetLeft;
  var yStart = formUtil.offsetTop + formCancel.offsetTop;
  var xEnd = xStart + formCancel.offsetWidth;
  var yEnd = yStart + formCancel.offsetHeight;

  formElem.addEventListener('input', function(e) {
    buiFormCheckValue(formElem);
    formElem.style.paddingRight = formUtil.offsetWidth + 'rem';
  });

  formElem.addEventListener('mousemove', function(e) {
    if (formElem.classList.contains('typed')) {
      if (e.offsetX >= xStart && e.offsetX <= xEnd && e.offsetY >= yStart && e.offsetY <= yEnd) {
        formElem.style.setProperty('cursor', 'default');
      } else {
        formElem.style.removeProperty('cursor');
      }
    }
  });

  formElem.addEventListener('click', function(e) {
    if (formElem.classList.contains('typed')) {
      if (e.offsetX >= xStart && e.offsetX <= xEnd && e.offsetY >= yStart && e.offsetY <= yEnd) {
        formElem.value = '';
        formElem.classList.remove('typed');
        formElem.style.removeProperty('cursor');
      }
    }
  });
}

// var formAddFile = function formAddFile(inputEl) {
// 	var curFiles = inputEl.files;

// 	if (curFiles.length === 0) {
// 	  inputEl.parentElement.classList.remove('typed');
// 	  inputEl.parentElement.setAttribute('data-bui-placeholder', inputEl.getAttribute('title'));
// 	  inputEl.parentElement.removeAttribute('style');
// 	  inputEl.parentElement.removeAttribute('data-bui-file-type');
// 	  inputEl.focus();
// 	} else {
// 	  inputEl.parentElement.setAttribute('data-bui-file-type', curFiles[0].type.split('/')[0]);
// 	  inputEl.parentElement.classList.add('typed');
// 	  inputEl.parentElement.setAttribute('data-bui-placeholder', inputEl.files[0].name);
// 	  inputEl.focus();
// 	}

// 	inputEl.parentElement.querySelector('.form-clear').addEventListener('click', function () {
// 	  inputEl.value = null;
// 	  inputEl.parentElement.classList.remove('typed');
// 	  inputEl.parentElement.setAttribute('data-bui-placeholder', inputEl.getAttribute('title'));
// 	  inputEl.parentElement.removeAttribute('style');
// 	  inputEl.parentElement.removeAttribute('data-bui-file-type');
// 	  inputEl.focus();
// 	});
//   };

//   var fileUploader = function fileUploader(selector) {
// 	var fileList = selector.files;

// 	if (fileList.length === 0) {
// 	  selector.parentElement.classList.remove('typed');
// 	  selector.parentElement.setAttribute('data-bui-file-type', 'none');
// 	  selector.parentElement.removeAttribute('style');
// 	  selector.parentElement.removeAttribute('title');
// 	} else {
// 	  selector.parentElement.setAttribute('data-bui-file-type', fileList[0].type.split('/')[0]);
// 	  selector.parentElement.classList.add('typed');

// 	  if (fileList[0].type.split('/')[0] === 'image') {
// 		selector.parentElement.setAttribute('style', 'background-image: url(' + URL.createObjectURL(fileList[0]) + ')');
// 	  } else {
// 		selector.parentElement.removeAttribute('style');
// 		selector.parentElement.removeAttribute('title');
// 	  }
// 	}
//   };

/**
 * @name buiFormTextField
 */
export function buiFormTextField(selector) {
  document.querySelectorAll(selector).forEach(function(textfield) {
    textfield.addEventListener(
      'focus',
      function(event) {
        buiFormCancel(event.target);
      },
      false
    );

    textfield.addEventListener(
      'mouseover',
      function(event) {
        buiFormCancel(event.target);
      },
      false
    );
  });
}

/**
 * @name dataFinder
 */
export function dataFinder(selector) {
  var datafinder = document.querySelector(selector);
  if (!datafinder) return;
  var dataForm = datafinder.querySelector('.form-elem');

  // focus event
  dataForm.addEventListener('focusin', function() {
    datafinder.classList.add('focus-within');
  });

  document.addEventListener('keydown', function(event) {
    if (event.keyCode === 9) {
      datafinder.querySelector('*:focus') != null ? datafinder.classList.add('focus-within') : datafinder.classList.remove('focus-within');
    }
  });

  document.addEventListener('mouseup', function(event) {
    if (!datafinder.contains(event.target)) {
      datafinder.classList.remove('focus-within');
      dataForm.blur();
    }
  });

  // input event
  dataForm.value.length > 0 ? datafinder.classList.add('typed') : datafinder.classList.remove('typed');
  dataForm.addEventListener('input', function() {
    dataForm.value.length > 0 ? datafinder.classList.add('typed') : datafinder.classList.remove('typed');
  });
  dataForm.addEventListener('click', function() {
    dataForm.value.length > 0 ? datafinder.classList.add('typed') : datafinder.classList.remove('typed');
  });
}

/**
 * @module	buiTab
 * @name	contentTab
 * @update	2022-03-20
 */
export const contentTab = new buiTab('[data-bui-tab="contentTab"]');

/**
 * @name	contebuiFormfileUploaderntTab
 * @update	2022-08-05
 */
export function buiFormfileUploader(selectors) {
  document.querySelectorAll(selectors).forEach(function(formElem) {
    if (formElem.parentElement.dataset.buiFormFileName) buiFormDeleteButton(formElem);
    formElem.addEventListener('change', function() {
      if (formElem.value.length > 0) {
        buiFormfileAdd(formElem);
        buiFormDeleteButton(formElem);
      } else {
        buiFormfileDelete(formElem);
      }
    });
  });
}

const buiFormDeleteButton = function(formElem) {
  let formFunc = formElem.parentElement.querySelector('.form-func');
  let formDelete = formElem.parentElement.querySelector('.btn.delete');

  if (!formFunc) {
    formFunc = document.createElement('span');
    formDelete = document.createElement('button');
    formFunc.className = 'form-func';
    formDelete.type = 'button';
    formDelete.className = 'btn delete';
    formDelete.innerHTML =
      '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" xmlns="http://www.w3.org/2000/svg"><title>삭제</title><path fill="#000" d="m8.4 17 3.6-3.6 3.6 3.6 1.4-1.4-3.6-3.6L17 8.4 15.6 7 12 10.6 8.4 7 7 8.4l3.6 3.6L7 15.6Zm3.6 5q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"></path></svg>';
    formFunc.appendChild(formDelete);
    formElem.parentElement.appendChild(formFunc);
  }

  formDelete.addEventListener('click', function() {
    formElem.value = '';
    buiFormfileDelete(formElem);
  });
};

const buiFormfileAdd = function(formElem) {
  formElem.parentElement.dataset.buiFormFileName = formElem.files[0].name;
  formElem.parentElement.dataset.buiFormFileType = formElem.files[0].type;
  formElem.parentElement.style.setProperty('--background-image', 'url(' + URL.createObjectURL(formElem.files[0]) + ')');
};

const buiFormfileDelete = function(formElem) {
  formElem.value = '';
  formElem.parentElement.removeChild(formElem.parentElement.querySelector('.form-func'));
  formElem.parentElement.removeAttribute('style');
  Object.keys(formElem.parentElement.dataset).forEach(function(dataKey) {
    delete formElem.parentElement.dataset[dataKey];
  });
};

/**
 * @module form buiFormResize
 */
function formResize(elem) {
  elem.parentNode.setAttribute('data-bui-form-value', elem.value);
  elem.value.length > 0 ? elem.parentNode.classList.add('typed') : elem.parentNode.classList.remove('typed');
}

export function buiFormResize(elem) {
  const formElems = document.querySelectorAll(elem);
  formElems.forEach(function(formElem) {
    formElem.querySelector('.form-elem').addEventListener('input', function(event) {
      formResize(event.currentTarget);
    });
  });
}

/**
 * @layout postsCommentWrite
 */
export function postsCommentWrite(selector) {
  const expandItems = document.querySelectorAll(selector);

  expandItems.forEach(function(expandItem) {
    let activeButton = expandItem.querySelector('.form.textarea .form-elem');
    let inActiveButton = expandItem.querySelector('.form-util button[type="reset"]');

    activeButton.addEventListener('focus', function(event) {
      expandItem.classList.add('active');
    });

    inActiveButton.addEventListener('click', function(event) {
      expandItem.classList.remove('active');
    });
  });
}

/**
 * @layout postsCommentLength
 */
function postsCommentLength(selector, target) {
  let expandItem = selector;
  let expandElem = expandItem.querySelector(target);
  if (!expandElem) return;

  let expandButton = expandElem.parentElement.parentElement.querySelector('.btn.expand');
  if (!expandButton) {
    expandButton = document.createElement('button');

    let buttonArea = document.createElement('div');
    buttonArea.className = 'button-area';
    buttonArea.appendChild(expandButton);

    expandElem.parentElement.parentElement.appendChild(buttonArea);
  }

  let expandButtonInacctiveText = '더보기';
  let expandButtonAcctiveText = '접기';

  expandItem.classList.contains('active-expand')
    ? (expandButton.innerHTML = expandButtonAcctiveText)
    : (expandButton.innerHTML = expandButtonInacctiveText);
  expandButton.type = 'button';
  expandButton.className = 'btn expand';

  expandElem.offsetHeight > parseInt(window.getComputedStyle(expandElem).getPropertyValue('line-height')) * 3
    ? expandItem.classList.add('expand-less')
    : expandItem.classList.remove('expand-less');
  window.addEventListener('resize', function() {
    expandElem.offsetHeight > parseInt(window.getComputedStyle(expandElem).getPropertyValue('line-height')) * 3
      ? expandItem.classList.add('expand-less')
      : expandItem.classList.remove('expand-less');
  });

  expandButton.addEventListener('click', function(event) {
    if (expandItem.classList.contains('active-expand')) {
      expandItem.classList.remove('active-expand');
      event.currentTarget.classList.remove('active-expand');
      event.currentTarget.innerHTML = expandButtonInacctiveText;
    } else {
      expandItem.classList.add('active-expand');
      event.currentTarget.classList.add('active-expand');
      event.currentTarget.innerHTML = expandButtonAcctiveText;
    }
  });
}

/**
 * @layout postsCommentItem
 */
export function postsCommentItem(selector) {
  const expandItems = document.querySelectorAll(selector);
  if (!expandItems) return;

  expandItems.forEach(function(expandItem) {
    let activeButton = expandItem.querySelector('.data-item.reply .btn');

    activeButton.addEventListener('click', function(event) {
      if (expandItem.classList.contains('active')) {
        expandItem.classList.remove('active');
        activeButton.classList.remove('active');
      } else {
        expandItem.classList.add('active');
        activeButton.classList.add('active');

        // 답글 말줄임
        expandItem.querySelectorAll('[data-bui-expand="postsReplyItem"]').forEach(function(replyItem) {
          postsCommentLength(replyItem, '.reply-body .para .wbr');
        });
      }
    });

    // 본문 말줄임 이벤트
    postsCommentLength(expandItem, '.comment-body .para .wbr');
  });
}

/**
 * @module	buiToggle
 * @name	postsFab
 * @update	2022-08-15
 */
// export const postsFab = new buiToggle('[data-bui-toggle="postsFab"]', {
//   reactTarget: 'html',
//   reactTargetActiveClass: 'active-posts-fab',
// });
