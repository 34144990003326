import { at } from 'lodash-es';
import * as AT from './actionTypes';
// ===================== 공통 ===================== //
export const analysisRequestError = ({ error }) => ({
  type: AT.ANALYSIS_ERROR,
  payload: {
    error,
  },
});

export const openDetailSection = (sectionType) => ({
  type: AT.OPEN_DETAIL_SECTION,
  sectionType,
});

export const closeDetailSection = () => ({
  type: AT.CLOSE_DETAIL_SECTION,
});

export const resetErrorState = () => ({
  type: AT.ANALYSIS_RESET_ERROR_STATE,
});

// ===================== 지도 동작 ===================== //
export const toggleAnalysis = (show) => ({
  type: AT.TOGGLE_ANALYSIS,
  show,
});

export const toggleFunctionalPopup = (show) => ({
  type: AT.TOGGLE_FUNCTIONAL_POPUP,
  show,
});

export const toggleFunctionalPopupDesc = (show) => ({
  type: AT.TOGGLE_FUNCTIONAL_POPUP_DESC,
  show,
});

export const setAnalysisCategory = (categorySelected) => ({
  type: AT.SET_ANALYSIS_CATEGORY,
  categorySelected,
});

// export const setMapCenter = (mapCenter) => ({
//   type: AT.SET_ANALYSIS_MAP_CENTER,
//   mapCenter,
// });

// export const setMapZoomLevel = (zoomLevel) => ({
//   type: AT.SET_ANALYSIS_MAP_ZOOMLEVEL,
//   zoomLevel,
// });

// export const setMapClickedLatLng = (latlng) => ({
//   type: AT.SET_MAP_CLICKED_LATLNG,
//   latlng,
// });

// ===================== 지도 표시 내용 ===================== //
/**
 * @param {Function} setList api에서 받은 데이터 리스트 setter. 넘어오지 않는 경우 global로 set (state.analysis.displayList)
 * @param {Object} map kakao.maps.Map 지도 객체. 넘어오지 않는 global 변수 참조 (state.buying의 지도 center, zoomlevel 등)
 * @param {String} dataType 조회 할 데이터 타입
 * @param {JSON} passedData 각 dataType 별로 getDisplayListMap 액션에 넘겨줘야 하는 데이터 (각각 다를 수 있으므로 json 형식으로)
 */
export const getDisplayListMap = ({ setStates, map, dataType, passedData, callback } = {}) => ({
  type: AT.GET_ANALYSIS_DISPLAY_LIST_MAP,
  setStates,
  map,
  dataType,
  passedData,
  callback,
});

export const setDisplayListMap = (displayList) => ({
  type: AT.SET_ANALYSIS_DISPLAY_LIST_MAP,
  displayList,
});

export const getCandidateListSearch = () => ({
  type: AT.GET_ANALYSIS_CANDIDATE_LIST_SEARCH,
});

export const setCandidateListSearch = (candidateList) => ({
  type: AT.SET_ANALYSIS_CANDIDATE_LIST_SEARCH,
  candidateList,
});

export const setTargetCandidate = (targetCandidateIdx) => ({
  type: AT.SET_TARGET_CANDIDATE,
  targetCandidateIdx,
});

// ===================== 하단 Notice PopUp (카테고리 선택 팝업과 동일한 자리) ===================== //
export const setPopUpType = (popUpType) => ({
  type: AT.SET_POP_UP_TYPE,
  popUpType,
});

// ===================== 개발호재 알리기 ===================== //
export const setPositivesType = (positivesType) => ({
  type: AT.SET_POSITIVES_TYPE,
  positivesType,
});
export const setPositivesTxt = (positivesTxt) => ({
  type: AT.SET_POSITIVES_TXT,
  positivesTxt,
});
export const setPositivesUrl = (positivesUrl) => ({
  type: AT.SET_POSITIVES_URL,
  positivesUrl,
});

export const postAddPositives = () => ({
  type: AT.POST_ADD_POSITIVES,
});

// ===================== 주간 실거래가 ===================== //
export const getWeeklyPriceInfo = (addressCode) => ({
  type: AT.GET_ANALYSIS_WEEKLY_PRICE_INFO,
  addressCode,
});
export const setWeeklyPriceList = (list) => ({
  type: AT.SET_ANALYSIS_WEEKLY_PRICE_LIST,
  list,
});

export const setWeeklyPriceGeneralInfo = (generalInfo) => ({
  type: AT.SET_ANALYSIS_WEEKLY_PRICE_GENERAL_INFO,
  generalInfo,
});

// ===================== 교육 ===================== //
export const setSchoolType = (idx) => ({
  type: AT.SET_ANALYSIS_SCHOOL_TYPE,
  idx,
});
export const setIsFirstSchoolType = (value) => ({
  type: AT.SET_IS_FIRST_SCHOOL_TYPE,
  value,
});
export const setAcademyMarkerList = (value) => ({
  type: AT.SET_ACADEMY_MARKER_LIST,
  value,
});
export const setSchoolInfo = (value) => ({
  type: AT.SET_SCHOOL_INFO,
  value,
});
export const getSchoolInfo = () => ({
  type: AT.GET_SCHOOL_INFO,
});
export const getSchoolWithDetail = (dongcode, schoolName) => ({
  type: AT.GET_SCHOOL_WITH_DETAIL,
  dongcode,
  schoolName,
});

//  랭킹
export const setRankingType = (rankingType) => ({
  type: AT.SET_ANALYSIS_RANKING_TYPE,
  rankingType,
});
export const setRankingSpan = (rankingSpan) => ({
  type: AT.SET_ANALYSIS_RANKING_SPAN,
  rankingSpan,
});
export const getRankingResultList = (addressCode) => ({
  type: AT.GET_ANALYSIS_RANKING_RESULT_LIST,
  addressCode,
});
export const setRankingResultList = (list) => ({
  type: AT.SET_ANALYSIS_RANKING_RESULT_LIST,
  list,
});
export const resetRankingCnt = () => ({
  type: AT.RESET_RANKING_CNT,
});
export const moreRankingCnt = () => ({
  type: AT.MORE_RANKING_CNT,
});
export const searchAnotherRankingForMobile = (data) => ({
  type: AT.SEARCH_ANOTHER_RANKING_FOR_MOBILE,
  data,
});

//아파트 비교
export const addAptCompare = (complex_data) => ({
  type: AT.ADD_APT_COMPARE,
  complex_data,
});
export const resetAptCompare = () => ({
  type: AT.RESET_APT_COMPARE,
});
export const compareApt = (aptList) => ({
  type: AT.COMPARE_APT,
  aptList,
});
export const compareAptLoading = () => ({
  type: AT.COMPARE_APT_LOADING,
});
export const compareAptSuccess = (data) => ({
  type: AT.COMPARE_APT_SUCCESS,
  data,
});
export const compareAptFailure = (error) => ({
  type: AT.COMPARE_APT_FAILURE,
  error,
});
export const compareAptNotAsked = () => ({
  type: AT.COMPARE_APT_NOTASKED,
});
export const setCompareAptCategoryTemplate = (data) => ({
  type: AT.SET_COMPARE_APT_CATEGORY_TEMPLATE,
  data,
});
export const setAptColor = (data) => ({
  type: AT.SET_APT_COLOR,
  data,
});
// export const getCompareGraph = (year) => ({
//   type: AT.GET_COMPARE_GRAPH,
//   year,
// });
// export const setCompareGraph = (data) => ({
//   type: AT.SET_COMPARE_GRAPH,
//   data,
// });

//재건축 사업성
export const getReconstructScoreDetail = (complex_idx) => ({
  type: AT.GET_RECONSTRUCT_SCORE_DETAIL,
  complex_idx,
});
export const setReconstructScoreDetail = (data) => ({
  type: AT.SET_RECONSTRUCT_SCORE_DETAIL,
  data,
});
export const getMinMaxPrice = (complex_idx) => ({
  type: AT.GET_MIN_MAX_PRICE,
  complex_idx,
});
export const setMinMaxPrice = (data) => ({
  type: AT.SET_MIN_MAX_PRICE,
  data,
});
export const setReconstructType = (value) => ({
  type: AT.SET_RECONSTRUCT_TYPE,
  value,
});
export const getReconstructInvestInfo = (complex_idx) => ({
  type: AT.GET_RECONSTRUCT_INVEST_INFO,
  complex_idx,
});
export const getReconstructInvestInfoWithSelectChange = (complex_idx, selectedSize) => ({
  type: AT.GET_RECONSTRUCT_INVEST_INFO_WITH_SELECT_CHANGE,
  complex_idx,
  selectedSize,
});

export const setReconstructInvestInfo = (data) => ({
  type: AT.SET_RECONSTRUCT_INVEST_INFO,
  data,
});
export const setReconstructNearComplex = (data) => ({
  type: AT.SET_RECONSTRUCT_NEAR_COMPLEX,
  data,
});
export const setIsSizeMeter = (value) => ({
  type: AT.SET_IS_SIZE_METER,
  value,
});

// 거래량
export const setTradeVolumeMonthRange = (value) => ({
  type: AT.SET_TRADEVOLUME_MONTH_RANGE,
  value,
});

// 가격변동
export const setPriceFlucRange = (value) => ({
  type: AT.SET_PRICE_FLUC_RANGE,
  value,
});

export const setPriceFlucIsSales = (value) => ({
  type: AT.SET_PRICE_FLUC_IS_SALES,
  value,
});

// 일자리 수
export const setJobNumberRange = (value) => ({
  type: AT.SET_JOB_NUMBER_RANGE,
  value,
});

export const setJobNumberIsCurrnet = (value) => ({
  type: AT.SET_JOB_NUMBER_IS_CURRENT,
  value,
});

// 개발호재
export const setPrevPositivesBuildPolygons = (value) => ({
  type: AT.SET_PREV_POSITIVES_BIULD_POLYGONS,
  value,
});

export const setPrevPositivesReBuildPolygons = (value) => ({
  type: AT.SET_PREV_POSITIVES_REBIULD_POLYGONS,
  value,
});

export const setPrevPositivesSubwayPolygons = (value) => ({
  type: AT.SET_PREV_POSITIVES_SUBWAY_POLYGONS,
  value,
});

export const setPositivesSelectList = (idx) => ({
  type: AT.SET_POSITIVES_SELECT_LIST,
  idx,
});

export const setIsPositivesFirstSelect = (value) => ({
  type: AT.SET_IS_POSITIVES_FIRST_SELECT,
  value,
});

// 제보
export const postReport = (complexIdx, report_type, contents, report_url, sigungu_code = null, positive_type = null) => ({
  type: AT.POST_REPORT,
  complexIdx,
  report_type,
  contents,
  report_url,
  sigungu_code,
  positive_type,
});

export const setReconDetailFrom = (value) => ({
  type: AT.SET_RECON_DETAIL_FROM,
  value,
});

// 추천
export const setIsRecommendPopup = (isRecommendPopup) => ({
  type: AT.SET_IS_RECOMMEND_POPUP,
  isRecommendPopup,
});

export const setRecommendStep = (recommendStep) => ({
  type: AT.SET_RECOMMEND_STEP,
  recommendStep,
});

export const setRecommendSelectedInfo = (recommendSelectedInfo) => ({
  type: AT.SET_RECOMMEND_SELECTED_INFO,
  recommendSelectedInfo,
});

export const reqGetRecommend = (payload) => ({
  type: AT.REQ_GET_RECOMMEND_RESULT,
  ...payload,
});

export const setRecommendResult = (recommendResult) => ({
  type: AT.SET_RECOMMEND_RESULT,
  recommendResult,
});

export const setIsRecommendResultPopup = (isRecommendResultPopup) => ({
  type: AT.SET_IS_RECOMMEND_RESULT_POPUP,
  isRecommendResultPopup,
});

export const setIsCompleteSelect = (isCompleteSelect) => ({
  type: AT.SET_IS_COMPLETE_SELECT,
  isCompleteSelect,
});

export const setCompleteSelectFilterOrd = (completeSelectFilterOrd) => ({
  type: AT.SET_COMPLETE_SELECT_ORD,
  completeSelectFilterOrd,
});

export const setSelectedFilterData = (selectedFilterData) => ({
  type: AT.SET_SELECTED_FILTER_DATA,
  selectedFilterData,
});
