import { success, loading, failure } from '@/data/utils';

export const IS_MOBILE = 'IS_MOBILE';
export const GET_COMPLEX_AREA = 'GET_COMPLEX_AREAS';
export const GET_COMPLEX_AREA_SUCCESS = success('GET_COMPLEX_AREAS');
export const GET_COMPLEX_AREA2 = 'GET_COMPLEX_AREA2';
export const SET_COMPLEX_AREA2 = 'SET_COMPLEX_AREA2';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';

export const SEARCH_KEYWORD = 'SEARCH_KEYWORD';
export const SEARCH_KEYWORD_SUCCESS = success(SEARCH_KEYWORD);
export const SEARCH_KEYWORD_LOADING = loading(SEARCH_KEYWORD);
export const SEARCH_KEYWORD_FAILURE = failure(SEARCH_KEYWORD);

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_ANY_FILE = 'UPLOAD_ANY_FILE';

export const PLEASE_LOGIN_POPUP = 'PLEASE_LOGIN_POPUP';
export const TOGGLE_LOGIN_POPUP = 'TOGGLE_LOGIN_POPUP';
export const ACTION_AFTER_LOGIN = 'ACTION_AFTER_LOGIN';

export const GET_FAQ_LIST = 'GET_FAQ_LIST'; //footer 도움말
export const SET_FAQ_LIST = 'SET_FAQ_LIST';

export const ADD_QUESTION = 'ADD_QUESTION';
export const ADD_QUESTION_SUCCESS = success(ADD_QUESTION);
export const ADD_QUESTION_LOADING = loading(ADD_QUESTION);
export const ADD_QUESTION_FAILURE = failure(ADD_QUESTION);
export const ADD_QUESTION_NOTASKED = 'ADD_QUESTION_NOTASKED';

export const REPORT_BROKER = 'REPORT_BROKER';
export const REPORT_BROKER_SUCCESS = success('REPORT_BROKER');
export const REPORT_BROKER_LOADING = loading('REPORT_BROKER');
export const REPORT_BROKER_FAILURE = failure('REPORT_BROKER');
export const REPORT_BROKER_NOTASKED = 'REPORT_BROKER_NOTASKED';

export const GET_MY_BROKER_LIST = 'GET_MY_BROKER_LIST';
export const SET_MY_BROKER_LIST = 'SET_MY_BROKER_LIST';
export const GET_SEARCH_BROKER_LIST = 'GET_SEARCH_BROKER_LIST';
export const SET_SEARCH_BROKER_LIST = 'SET_SEARCH_BROKER_LIST';

export const GET_SEARCH_BROKER_LIST_BY_SETTING = 'GET_SEARCH_BROKER_LIST_BY_SETTING';
export const SET_SEARCH_BROKER_LIST_BY_SETTING = 'SET_SEARCH_BROKER_LIST_BY_SETTING';

export const GET_QNA_CATEGORY_LIST = 'GET_QNA_CATEGORY_LIST';
export const SET_QNA_CATEGORY_LIST = 'SET_QNA_CATEGORY_LIST';

export const OPEN_GLOBAL_DUAL_POPUP = 'OPEN_GLOBAL_DUAL_POPUP';
export const CLOSE_GLOBAL_DUAL_POPUP = 'CLOSE_GLOBAL_DUAL_POPUP';

export const ALERT = 'ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const CONFIRM = 'CONFIRM';
export const CLEAR_CONFIRM = 'CLEAR_CONFIRM';

//관심단지
export const ADD_FAV_COMPLEX = 'ADD_FAV_COMPLEX';
export const ADD_FAV_COMPLEX_LOADING = loading(ADD_FAV_COMPLEX);
export const ADD_FAV_COMPLEX_SUCCESS = success(ADD_FAV_COMPLEX);
export const ADD_FAV_COMPLEX_FAILURE = failure(ADD_FAV_COMPLEX);
export const OPEN_MOBILE_FAVO_BOX = 'OPEN_MOBILE_FAVO_BOX';

export const GET_FAV_COMPLEX_LIST = 'GET_FAV_COMPLEX_LIST';
export const SET_FAV_COMPLEX_LIST = 'SET_FAV_COMPLEX_LIST';

export const INITIAL_UPDATE_TEMP_FAV_COMP_LIST = 'INITIAL_UPDATE_TEMP_FAV_COMP_LIST';
export const UPDATE_TEMP_FAV_COMP_LIST = 'UPDATE_TEMP_FAV_COMP_LIST';
export const INITIAL_UPDATE_TEMP_FAV_LOCAL_LIST = 'INITIAL_UPDATE_TEMP_FAV_LOCAL_LIST';
export const UPDATE_TEMP_FAV_LOCAL_LIST = 'UPDATE_TEMP_FAV_LOCAL_LIST';

//관심매물
export const HANDLE_FAV_GOODS = 'HANDLE_FAV_GOODS';
export const UPDATE_TEMP_FAV_GOODS_LIST = 'UPDATE_TEMP_FAV_GOODS_LIST';

//본인인증 정보
export const SET_CERTIFICATED_INFO = 'SET_CERTIFICATED_INFO';

// 지적편집도 on/off action type
// 지적도 on/off 시 html dom tree 상 다른 위치에 있는 지적도 범례를 껐다 키기 위함.
// 해당 컴포넌트의 parent인 <MapCompGroup/> 이 pages/Buying 과 pages/Analysis 에서 활용되기 때문에,
// 본 액션타입을 이곳 common 에 정의함.
export const TOGGLE_DISTRICT_VIEW = 'TOGGLE_DISTRICT_VIEW';

export const TOGGLE_GLOBAL_SPINNER = 'TOGGLE_GLOBAL_SPINNER';

export const UPDATE_APP_DATA = 'UPDATE_APP_DATA';

export const ADD_DYNAMIC_CSS_IMPORT = 'ADD_DYNAMIC_CSS_IMPORT';

// GlobalGallery (이미지 클릭시 확대되는) 열고/닫기
export const OPEN_GLOBAL_GALLERY = 'OPEN_GLOBAL_GALLERY';
export const CLOSE_GLOBAL_GALLERY = 'CLOSE_GLOBAL_GALLERY';
