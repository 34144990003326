import React, { useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '@/data';
import { BUYING_SEARCH_OPTIONS } from '@/resources/global';
import router from '@/services/router';
import { FILTER_TYPES } from '../FilterPopup/constants';

const Tags = () => {
  const dispatch = useDispatch();

  const selectedCategory = useSelector(selectors.buying.getCategory);
  const selectedDealtype = useSelector(selectors.buying.getDealtype);
  const selectedTradetype = useSelector(selectors.buying.getTradetype);
  const selectedAreasize = useSelector(selectors.buying.getAreasize);
  const selectedCntRange = useSelector(selectors.buying.getCntRange);
  const selectedYearRange = useSelector(selectors.buying.getYearRange);

  const filterInitialValue = useRef(
    {
      category: selectedCategory,
      dealtype: selectedDealtype,
      tradetype: selectedTradetype,
      areasize: selectedAreasize,
      cntRange: selectedCntRange,
      yearRange: selectedYearRange,
    },
    []
  );

  // const initializeCategory = useCallback(() => dispatch(actions.buying.changeCategory(filterInitialValue.category)), []);
  const initializeDealtype = useCallback(() => dispatch(actions.buying.initializeDealtype()), []);
  const initializeTradetype = useCallback(() => dispatch(actions.buying.initializeTradetype()), []);
  const initializeAreasize = useCallback(() => dispatch(actions.buying.initializeAreasize()), []);
  const initializeCntRange = () => dispatch(actions.buying.changeCntRange(filterInitialValue.cntRange));
  const initializeYearRange = () => dispatch(actions.buying.changeYearRange(filterInitialValue.yearRange));

  const openSearchFilter = (type) => {
    if (location.search?.indexOf('searchfilter') > -1) {
      dispatch(actions.router.goBack());
    } else {
      dispatch(router.push(`${location.pathname}${router.querystring.add('searchfilter', type)}`));
    }
  };

  const filterTags = useMemo(() => {
    const tags = [];
    // selectedCategory는 표시하지 않는다.
    if (selectedCategory) {
      tags.push(
        <Wrapper key="selectedCategory" onClick={() => openSearchFilter(FILTER_TYPES.CATEGORY)}>
          {BUYING_SEARCH_OPTIONS.A[selectedCategory]}
        </Wrapper>
      );
    }

    if (selectedDealtype != 'all') {
      tags.push(
        <Wrapper key="selectedDealtype" onDelete={initializeDealtype} onClick={() => openSearchFilter(FILTER_TYPES.DEALTYPE)}>
          {BUYING_SEARCH_OPTIONS.B[selectedDealtype]}
        </Wrapper>
      );
    }

    if (selectedTradetype?.length !== 1 || selectedTradetype?.[0] !== 'all') {
      tags.push(
        <Wrapper key="selectedTradetype" onDelete={initializeTradetype} onClick={() => openSearchFilter(FILTER_TYPES.TRADETYPE)}>
          {selectedTradetype.map((item) => BUYING_SEARCH_OPTIONS.C2[item]).join(', ')}
        </Wrapper>
      );
    }

    if (selectedAreasize?.length !== 1 || selectedAreasize?.[0] !== 'all') {
      tags.push(
        <Wrapper key="selectedAreasize" onDelete={initializeAreasize} onClick={() => openSearchFilter(FILTER_TYPES.AREASIZE)}>
          {selectedAreasize?.map?.((item) => BUYING_SEARCH_OPTIONS.D2[item]).join(', ')}
        </Wrapper>
      );
    }

    if (selectedCntRange) {
      const filteredSelectedCntRange = selectedCntRange.filter((item) => !!item);
      if (filteredSelectedCntRange?.length > 0) {
        let surFix = '';
        if (filteredSelectedCntRange.length === 1) surFix = selectedCntRange[0] ? ' 이상' : ' 이하';
        const text = `${filteredSelectedCntRange.join('~')}세대${surFix}`;
        tags.push(
          <Wrapper key="filteredSelectedCntRange" onDelete={initializeCntRange} onClick={() => openSearchFilter(FILTER_TYPES.HOUSECNT)}>
            {text}
          </Wrapper>
        );
      }
    }
    if (selectedYearRange) {
      const filteredSelectedYearRange = [...selectedYearRange]
        .reverse()
        .filter((item) => !!item)
        .map((item) => Math.abs(item));

      if (filteredSelectedYearRange?.length > 0) {
        let surFix = '';
        if (filteredSelectedYearRange.length === 1) surFix = selectedYearRange[0] ? ' 이하' : ' 이상';
        const text = `${filteredSelectedYearRange.join('~')}년차${surFix}`;
        tags.push(
          <Wrapper key="filteredSelectedYearRange" onDelete={initializeYearRange} onClick={() => openSearchFilter(FILTER_TYPES.YEAR)}>
            {text}
          </Wrapper>
        );
      }
    }

    return tags;
  }, [selectedCategory, selectedDealtype, selectedTradetype, selectedAreasize, selectedCntRange, selectedYearRange]);

  return (
    <div className="data-board data-finder-filter">
      <div className="board-wrap">
        <div className="board-head">
          <p className="board-subject">
            <strong className="board-name">검색 필터</strong>
          </p>
        </div>
        <div className="board-body">
          <div className="filter-area">{filterTags}</div>
        </div>
      </div>
    </div>
  );
};

export default Tags;

const Wrapper = ({ children, onDelete, onClick }) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(e);
  };
  return (
    <span className="mark module-a style-c type-fill normal-10 medium">
      <span className="mark-text" onClick={onClick}>
        {children}
        {onDelete && (
          <button type="button" className="btn delete" onClick={handleDelete}>
            <span className="btn-text">삭제</span>
          </button>
        )}
      </span>
    </span>
  );
};
