import React, { useEffect } from 'react';
import toggle from '@/hocs/toggle';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { detectIsMobileDevice } from '@/services/Helper';

import buiToggle from '@/assets/js/bui.toggle.js';
import useOptions from './hooks/useOptions';

const popupName = 'infoPopup_shouldLogin';

export const infoPopup = new buiToggle('[data-bui-toggle="infoPopup_shouldLogin"]', {
  reactTarget: 'html',
  reactTargetActiveClass: 'active-info-popup',
  focusin: true,
});

// 모바일의 경우 > 모바일 앱 다운로드
// 하지만 피드 상세보기에서 하는 동작은 모두 로그인 허용

/**
 * 로그인 요청 팝업
 * 모바일 웹에서는 앱다운로드로 유도(DW-260)
 *
 * @param option (optional)
 *  [wrapperStyle] root div inline style
 *  [buttonText] default '로그인'
 *  [title] default '로그인 후 이용할 수 있습니다.'
 *  [contents] default 값 없음. title 바로 아래 요소로 들어감.
 *  [onClose] close할 때 실행할 함수
 *  [onSuccess] 로그인 완료시 실행할 함수
 *  [dynamicLink] 앱 다운로드 시 이동시킬 dynamicLink. default 값은 downloadMobileWeb 함수에서 확인.
 */
const PleaseLoginPopup = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectors.common.isMobile);
  const option = useSelector(selectors.common.getPleaseLoginPopupOption) || {};

  /**
   * 모바일에서 앱다운로드 유도 하지않도록 수정 (24.07.18)
   * 다시 앱이 중요해질 수도 있으니 코드는 삭제하지않고 주석으로 남겨둠
      // const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
      // const isMobileBrowser = detectIsMobileDevice() || false;
      // const isApp = osTypeInstance?.isApp;
      // const isMobileException =
      //   location.search.indexOf('feedDetail') > -1 || location.pathname.toLowerCase().indexOf('/agentsignup/academy') > -1;
      // const needAppDownload = !isApp && isMobileBrowser && !isMobileException;
  */
  const needAppDownload = false;

  const { getTitle, getButtonText, getContents, getStyle } = useOptions({ needAppDownload, option });

  const title = getTitle();
  const buttonText = getButtonText();
  const contents = getContents();
  const wrapperStyle = getStyle();

  useEffect(() => {
    infoPopup.update(popupName);
    infoPopup.active(popupName);
  }, []);

  const handleSubmit = () => {
    dispatch(actions.common.pleaseLoginPopup(false));

    if (needAppDownload) {
      window.open(`https://dawinweb.page.link/${option.dynamicLink || 'wFWu'}`);
    } else {
      // 모바일 브라우저가 아니면 로그인
      if (isMobile) dispatch(actions.router.push('/login'));
      else dispatch(actions.common.toggleLoginPopup(true));

      // 로그인으로 이동하면 onSuccess 저장
      if (option?.onSuccess) dispatch(actions.common.actionAfterLogin(option.onSuccess));
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.common.pleaseLoginPopup(false));
    if (option?.onClose) option?.onClose();
  };

  return (
    <div style={wrapperStyle}>
      <div className="info-popup module-a style-b type-a small" data-bui-toggle={popupName} id={popupName}>
        <div className="popup-page-body">
          <div className="popup-local">
            <div className="popup-local-body">
              <div className="popup-content">
                <div className="popup-content-body">
                  <div className="info-board module-a style-a type-c">
                    <div className="board-wrap">
                      <div className="board-head">
                        <p className="board-subject">
                          <span className="board-name">{title}</span>
                        </p>
                      </div>
                      {contents ? <div className="board-body">{contents}</div> : false}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="popup-local-util">
              <div className="button-display">
                <div className="button-area">
                  <button className="btn popup-close" type="button" onClick={handleSubmit}>
                    <span className="btn-text">{buttonText}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="popup-local-func">
              <div className="button-area">
                <button className="btn popup-close" type="button" onClick={handleClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                    <title>닫기</title>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default toggle(PleaseLoginPopup);
