import React from 'react';

const getCallPopupContents = (phoneNumber, userName, { fromBrokerToGuest }) => {
  if (fromBrokerToGuest) {
    return (
      <>
        <div className="board-head">
          <p className="board-subject">
            <span className="board-name">{phoneNumber}</span>
          </p>
        </div>
        <div className="board-body">
          <p className="para">
            <span className="wbr">고객님의 전화번호입니다.</span>
            <span className="wbr">개인 정보 보호를 위해</span>
            <span className="wbr">안심번호 형태로 제공됩니다.</span>
          </p>
        </div>
      </>
    );
  } else {
    return (
      <>
        <strong style={{ fontSize: '20px' }}>{userName}</strong>
        <br />
        <strong style={{ fontSize: '20px' }}>{phoneNumber}</strong>
        <br />
        <br />
        <span>
          위 전화번호로 연락하시면
          <br />
          부동산 중개업소에서
          <br />
          친절하게 안내해드립니다.
        </span>
      </>
    );
  }
};

export default { getCallPopupContents };
