import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  // @note request 보내는건 auth dir 에서 이루어짐
  // yield takeLatest(AT.AGENT_SIGNUP_POST,sagas.postSignUpReq);

  // @note 시,도,군,동,리 등 목록 받아오는건 selling 에서 구현된거 사용

  yield takeEvery(AT.GET_OFFICE_LIST, sagas.getOfficeList);
  yield takeEvery(AT.SEARCH_XY, sagas.getSearchXY);
  yield takeEvery(AT.GET_ADDRESS_LIST, sagas.getAddressList);
  yield takeEvery(AT.GET_BROKER_OFFICE_LIST, sagas.getBrokerOfficeList);
  yield takeLatest(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP, sagas.handleAgentSignUpNextStep);
  yield takeLatest(AT.CHECK_CODE, sagas.checkCode);
  yield takeEvery(AT.CHECK_ID_DUP, sagas.checkId);
  yield takeEvery(AT.APPLY_TOUR_OFFICE, sagas.applyTourOffice);
  yield takeEvery(AT.APPLY_SHARE_OFFICE, sagas.applyShareOffice);
  yield takeEvery(AT.GET_TOUR_OFFICE_LIST, sagas.getTourOfficeList);

  yield takeEvery(AT.EDIT_ACADEMY, sagas.editAcademy);
  yield takeEvery(AT.CANCEL_ACADEMY, sagas.cancelAcademy);
  yield takeEvery(AT.GET_ACADEMY_DATE, sagas.getAcademyDate);
  yield takeEvery(AT.CHECK_ACADEMY, sagas.checkAcademy);
  yield takeEvery(AT.APPLY_ACADEMY_PRE_BILL, sagas.prebill);
  yield takeEvery(AT.APPLY_ACADEMY_BILL, sagas.bill);

  yield takeEvery(AT.GET_RECOMMEND_ADDR_SiDo, sagas.getAddrSiDo);
  yield takeEvery(AT.GET_RECOMMEND_ADDR_SiGunGu, sagas.getAddrSiGunGu);
  yield takeEvery(AT.GET_RECOMMEND_ADDR_DongRi, sagas.getAddrDongRi);
}
