import React from 'react';

const OwnerBadge = ({ isOwner }) => {
  return isOwner ? (
    <span className="mark module-d style-c type-fill accent-03 small-1x">
      <span className="mark-text">집주인 인증</span>
    </span>
  ) : null;
};

export default OwnerBadge;
