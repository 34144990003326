import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import DesignPopup, { designPopup } from '@/publish/Front/_Component/popup/DesignPopup';
import event_bg02 from '@/assets/images/@temp/newservice-selling.webp';
import toggle from '@/hocs/toggle';
import routerService from '@/services/router';

const MoreRealtyTypeServicePopup = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const isBroker = userInfo?.mbr_type === 'BU';

  const close = () => {
    designPopup.inactive('expandItemFinddawin');
    dispatch(routerService.replace(routerService.querystring.remove('event')));
  };

  useEffect(() => {
    designPopup.update('expandItemFinddawin');
    designPopup.active('expandItemFinddawin');
  }, []);

  return (
    <>
      <DesignPopup popupName="expandItemFinddawin">
        <form className="popup-local">
          <div className="popup-local-head">
            <h2 className="popup-local-subject">
              <span className="popup-local-name">매물확대 집내놓기</span>
            </h2>
          </div>

          <div className="popup-local-body">
            <div className="popup-content">
              <div className="popup-content-body">
                <div className="display-board">
                  <div className="board-wrap">
                    <div className="board-head"></div>
                    <div className="board-body">
                      <img src={event_bg02} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-local-util">
            <div className="button-display module-a style-a type-d">
              <span className="button-area">
                <button
                  type="submit"
                  className="btn module-b style-a type-fill accent-01 large-1x flex"
                  onClick={() => {
                    close();
                    // dispatch(routeToCreate(CreatePoint.POST_FOR_BUY, '/buying', 'POST_FOR_BUY'));
                    if (isBroker) {
                      dispatch(actions.router.push('/brokerPage/mng_goods'));
                    } else {
                      dispatch(actions.router.push('/selling/manual/default'));
                    }
                  }}>
                  <span className="btn-text">{isBroker ? '내매물 보러가기' : '지금 집내놓기'}</span>{' '}
                </button>
              </span>
            </div>
          </div>

          <div className="popup-local-func">
            <span className="button-area">
              <button type="button" className="btn popup-close" onClick={close}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                  <title>닫기</title>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg>
              </button>
            </span>
          </div>
        </form>
      </DesignPopup>
    </>
  );
};

export default toggle(MoreRealtyTypeServicePopup);
