import React from 'react';
import { MODE } from '../index.js';

const Tab = ({ changeMode, mode, localAddressMode, feedType }) => {
  return (
    <div className="tab-display module-a style-a stype-a small-1x">
      <ul className="tab-list">
        {!localAddressMode ? (
          <>
            <Item name={feedType === 'POST_FOR_BUY' ? '단지,지역 검색' : '단지 검색'} onClick={changeMode} selected={mode === MODE.TEXT} />
            <Item name={feedType === 'POST_FOR_BUY' ? '단지,지역 선택' : '단지 선택'} onClick={changeMode} selected={mode === MODE.SELECT} />
          </>
        ) : (
          <Item name="지역 선택" onClick={(e) => e.preventDefault()} selected={mode === MODE.SELECT} />
        )}
      </ul>
    </div>
  );
};

export default Tab;

const Item = ({ name, onClick, selected }) => {
  return (
    <li className={`tab-item ${selected ? 'current' : ''}`}>
      <a className="tab-text" onClick={onClick}>
        {name}
      </a>
    </li>
  );
};
