import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';
import { STATUS } from '@/data/utils';

const SelectSearchView = ({ selectedIdx, dongCode, setData, markTypes, addAllOption, preventUpdateComplexData, disabled }) => {
  const dispatch = useDispatch();
  const [complexListData, setComplexListData] = useState({ status: STATUS.NOTASKED });

  const formStyle = disabled ? { cursor: 'no-drop' } : {};
  const rowStyle = disabled ? { filter: 'grayscale(1) opacity(0.5)' } : {};

  useEffect(() => {
    const permitUpdate = !preventUpdateComplexData?.current;

    if (dongCode) {
      if (dongCode !== 'all') {
        permitUpdate && setData({});

        // 마지막 2자리 코드 ri코드가 00이면 동으로 끝나므로 00 이상인 코드면 다시 단지 검색
        // xxxxx000xx -> 000이  읍면동
        // 202 보다 크면 읍/면 코드이고 202이하면 동코드라 두번째 동리를 콜하지 x
        const riCode = dongCode?.substring(dongCode.length - 2, dongCode.length);
        const eup_myeon_dong_code = dongCode.substr(5, 3);

        if ((riCode && riCode != '00') || eup_myeon_dong_code < 203) {
          dispatch(actions.address.getComplexListByDong({ dongCode: dongCode, setter: setComplexListData }));
        }
      } else {
        permitUpdate && setData({ complex_idx: 'all', complex_name: '전체 단지' });
      }
    } else {
      permitUpdate && setData({});
    }

    if (preventUpdateComplexData?.current) preventUpdateComplexData.current = false;
  }, [dongCode]);

  let options = useMemo(() => {
    let result = [
      <option key="" value="">
        단지 선택
      </option>,
    ];

    if (addAllOption) {
      result =
        dongCode === 'all'
          ? [
              <option key="all" value="all">
                전체 단지
              </option>,
            ]
          : [
              <option key="" value="">
                단지 선택
              </option>,
              <option key="all" value="all">
                전체 단지
              </option>,
            ];
    }

    if (dongCode && dongCode !== 'all' && complexListData.status === STATUS.SUCCESS && complexListData?.list?.length > 0) {
      let filterData = complexListData.list;
      if (markTypes) {
        filterData = complexListData.list.filter((el) => markTypes.indexOf(el.complex_type) > -1);
      }
      filterData.forEach((el) => {
        result.push(
          <option key={el.complex_idx} value={el.complex_idx} name={el.complex_name} data-type={el.complex_type}>
            {el.complex_name}
          </option>
        );
      });
    }

    return result;
  }, [complexListData?.list, dongCode]);

  const onChange = (e) => {
    const targetElement = e.target.options[e.target.selectedIndex];
    const complex_name = targetElement?.text;
    const complex_type = targetElement?.getAttribute('data-type');
    const complex_idx = e.target.value;
    setData({ complex_idx, complex_name, complex_type });
  };

  return (
    <div className="form-item" style={rowStyle}>
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="selectComplaxName">
            단지명
          </label>
        </div>
        <div className="form-body">
          <div className="form-area">
            <span className="form select module-b style-b type-line normal-04 large-1x flex">
              <select className="form-elem" id="selectComplaxName" onChange={onChange} value={selectedIdx} style={formStyle} disabled={disabled}>
                {options}
              </select>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectSearchView;
