import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const viewChecked = (checked) => ({
  type: AT.VIEW_CHECKED,
  checked,
});
export const saveActiveArea = ({ data, setTempBrokerDistrict, callback, freeMembership, routeToMembershipExPage, setHasAreasEdited }) => ({
  type: AT.SAVE_ACTIVE_AREA,
  data,
  setTempBrokerDistrict,
  callback,
  freeMembership,
  routeToMembershipExPage,
  setHasAreasEdited,
});
export const saveSetDefaultArea = () => ({
  type: AT.SAVE_SET_DEFAULT_AREA,
});
export const addDongRi = (sigunguName, sigunguCode, dong) => ({
  type: AT.ADD_ACTIVE_AREA,
  sigunguName,
  sigunguCode,
  dong,
});
export const addBrokerDsitrict = (district) => ({
  type: AT.ADD_BROKER_DISTRICT,
  district,
});
export const deleteDongRi = (item) => ({
  type: AT.DEL_ACTIVE_AREA,
  item,
});
export const setDataArea = (data) => ({
  type: AT.SET_DATA_AREA,
  data,
});
export const getSetArea = () => ({
  type: AT.GET_SET_AREA,
});
export const getSetAreaLoading = () => ({
  type: AT.GET_SET_AREA_LOADING,
});
export const getSetAreaSuccess = (data) => ({
  type: AT.GET_SET_AREA_SUCCESS,
  data,
});
export const getSetAreaFailure = (error) => ({
  type: AT.GET_SET_AREA_FAILURE,
  error,
});
export const getPossibleArea = () => ({
  type: AT.GET_POSSIBLE_AREA,
});
export const getPossibleAreaLoading = () => ({
  type: AT.GET_POSSIBLE_AREA_LOADING,
});
export const getPossibleAreaSuccess = (res) => ({
  type: AT.GET_POSSIBLE_AREA_SUCCESS,
  res,
});
export const getPossibleAreaFailure = (error) => ({
  type: AT.GET_POSSIBLE_AREA_FAILURE,
  error,
});
export const getBrokerDistrict = () => ({
  type: AT.GET_BROKER_DISTRICT,
});
export const getBrokerDistrictLoading = () => ({
  type: AT.GET_BROKER_DISTRICT_LOADING,
});
export const getBrokerDistrictSuccess = (data) => ({
  type: AT.GET_BROKER_DISTRICT_SUCCESS,
  data,
});
export const getBrokerDistrictFailure = (error) => ({
  type: AT.GET_BROKER_DISTRICT_FAILURE,
  error,
});
export const getBrokerAreaDistrict = (param) => ({
  type: AT.GET_BROKER_AREA_DISTRICT,
  param,
});
export const deleteArea = (code) => ({
  type: AT.DELETE_AREA_DISTIRCT,
  code,
});
export const getBrokerAreaDistrictLoading = () => ({
  type: AT.GET_BROKER_AREA_DISTRICT_LOADING,
});
export const getBrokerAreaDistrictSuccess = (data) => ({
  type: AT.GET_BROKER_AREA_DISTRICT_SUCCESS,
  data,
});
export const getBrokerAreaDistrictFailure = (error) => ({
  type: AT.GET_BROKER_AREA_DISTRICT_FAILURE,
  error,
});
// 개발호재
export const setPrevPolygons = (data) => ({
  type: AT.SET_PREV_POLYGONS,
  data,
});
