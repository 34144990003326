import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import toggle from '@/hocs/toggle';

import Core from '../components/Core';
import MainWeb from '../../components/MainWeb';
import UserTypeSelection from './components/UserTypeSelection';
import Banner from './components/Banner';
import AgentUserView from './components/AgentUserView';
import TemplateFrontPopup from '@/components/templates/TemplateFrontPopup';
import ExtraBtns from './components/AgentUserView/components/ExtraBtns';

import { STEPS } from '../../BasicForm/helpers';
import { USER_TYPES } from '@/pages/Login/views/constants';
import { useCloseLoginPopup, useSetUserType, useShowLoginPopupOnLoginUrl } from '../hooks';
// import Home from '@/pages/Home';

function LoginWeb(props) {
  const [userType, setUserType] = useState(USER_TYPES.NORMAL);
  const step = useSelector(selectors.auth.getLoginStep);
  const isRealtorView = userType === USER_TYPES.REALTOR;
  const showExtraTabs = isRealtorView || step === STEPS.FIRST;

  // 로그인 팝업 닫기 함수
  const [handleClose] = useCloseLoginPopup();

  // step이 BROKER일때 중개사회원 탭 보여주기
  useSetUserType({ step, setUserType });

  // "/login"일때 로그인 팝업 열어주기
  useShowLoginPopupOnLoginUrl();

  const title = () => {
    switch (step) {
      case STEPS.AGREE:
        return '회원가입';
      case STEPS.SOCIAL2:
      case STEPS.SOCIAL3:
        return '전화번호 인증';
      default:
        return '로그인';
    }
  };
  return (
    <>
      {/* /login 루트로 로그인 팝업을 열면 뒷 부분이 안보이는 현상을 해결 하기 위해 */}
      {/* <div id="wrap" className="newmain">
        <Home />
      </div> */}

      <TemplateFrontPopup popupId="loginBroker" title={title()} onClose={handleClose}>
        <div className="popup-local-body">
          <div className="popup-content">
            {showExtraTabs && <UserTypeSelection userType={userType} setUserType={setUserType} />}

            <div className="popup-content-body">
              {isRealtorView && showExtraTabs && <AgentUserView />}

              <Core mainComponent={<MainWeb />} {...props} />

              {isRealtorView && showExtraTabs && <ExtraBtns handleClose={handleClose} />}

              {showExtraTabs && <Banner handleClose={handleClose} />}
            </div>
          </div>
        </div>
      </TemplateFrontPopup>
    </>
  );
}

export default toggle(LoginWeb);
