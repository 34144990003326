import React, { useState } from 'react';
import Alarm from '../Alarm';

const SideNavWithBtn = ({ initialState, color, showAlarm }) => {
  return (
    <>
      <div className="btn-right">{showAlarm ? <Alarm color={color} /> : false}</div>
    </>
  );
};

export default SideNavWithBtn;
