import SocialSharePopup from '@/components/popups/SocialSharePopup';
import { FeedTypeKor } from '@/services/Community';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CloseButton from './CloseButton';
import { SHARE_POPUP_TYPES } from '@/components/popups/SocialSharePopup/constants';
import useGetShareData from './hooks/useGetShareData';

const FeedHeader = ({ feedData }) => {
  const [sharePopUp, setSharePopUp] = useState(false);
  const feedTypeTag = FeedTypeKor[feedData?.feedType];
  const isBrokerPage = location.pathname.indexOf('/brokerPage') > -1;

  const share = (e) => {
    e.preventDefault();
    setSharePopUp(true);
  };

  const [shareData] = useGetShareData({ feedData });

  return (
    <>
      <div className="popup-local-head">
        <h2 className="popup-local-subject">
          <span className="popup-local-name">{isBrokerPage && feedTypeTag === '구해줘다윈' ? '집찾는손님' : feedTypeTag}</span>
        </h2>
        <div className="popup-local-control">
          <span className="button-area">
            <Link className="btn share" to="#" onClick={share}>
              <span className="btn-text">공유</span>
            </Link>
          </span>
        </div>

        <SocialSharePopup show={sharePopUp} setShow={setSharePopUp} shareData={shareData} />
      </div>
      <CloseButton />
    </>
  );
};

export default FeedHeader;
