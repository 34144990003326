import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as aActions from '@/data/rootActions';
import * as selectors from '../selectors';
import apis from '@/apis';

//suggestionlist
export function* getSuggestionList() {
  try {
    const page = yield select(selectors.suggestion.getPage);
    const startDate = yield select(selectors.suggestion.getStartDate);
    const endDate = yield select(selectors.suggestion.getEndDate);
    const keyword = yield select(selectors.suggestion.getKeyword);
    const payload = {
      page: page,
      startDate: startDate ? startDate : '',
      endDate: endDate ? endDate : '',
      keyword: keyword ? keyword : '',
      category: 'S',
    };
    const response = yield call(apis.brokerPageApi.getSuggestionList, payload);
    //const { list } = response;
    yield put(actions.getSuggestionListSuccess(response.rltObj));
    yield put(actions.setTotalCnt(response.totalCnt));
    yield put(actions.setKeyword(''));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.getSuggestionListFailure(e));
  }
}
export function* getSuggestionDetail({ idx }) {
  try {
    const payload = {
      category: 'S',
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.getSuggestionDetail, payload);
    //const { list } = response;
    yield put(actions.getSuggestionDetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.getSuggestionDetailFailure(e));
  }
}
export function* saveSuggestionNew() {
  try {
    const newData = yield select(selectors.suggestion.getNew);
    const payload = {
      category: 'S',
      sub_category: newData.category ? newData.category : '1',
      title: newData.title,
      contents: newData.cont,
      file_idx: newData.attach,
    };
    //call api
    const response = yield call(apis.brokerPageApi.saveNewSuggestion, payload);
    yield put(actions.getList());
  } catch (e) {
    yield put(actions.getSuggestionDetailFailure(e));
  }
}
