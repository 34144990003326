import * as AT from './actionTypes';
import Remote from '@/data/remote';

const INITIAL_STATE = {
  [AT.GET_NEWSALES]: Remote.NotAsked,
  [AT.GET_REBUILDS]: Remote.NotAsked,
  length: 0,
};

export default function newsale(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.GET_NEWSALES_LOADING:
      return { ...state, [AT.GET_NEWSALES]: Remote.Loading, length: 0 };
    case AT.GET_NEWSALES_SUCCESS:
      return { ...state, [AT.GET_NEWSALES]: Remote.Success(action.data.list), length: action.data.totalCnt };
    case AT.GET_NEWSALES_FAILURE:
      return { ...state, [AT.GET_NEWSALES]: Remote.Failure(action.error), length: 0 };
    case AT.GET_REBUILDS_LOADING:
      return { ...state, [AT.GET_REBUILDS]: Remote.Loading, length: 0 };
    case AT.GET_REBUILDS_SUCCESS:
      return { ...state, [AT.GET_REBUILDS]: Remote.Success(action.data.list), length: action.data.totalCnt };
    case AT.GET_REBUILDS_FAILURE:
      return { ...state, [AT.GET_REBUILDS]: Remote.Failure(action.error), length: 0 };
    case AT.SET_NEWSALE_PRICE:
      return { ...state, newsalePrice: action.list };
    case AT.SET_NEWSALE_SCHEDULE:
      return { ...state, newsaleSchedule: action.list };
    case AT.SET_REBUILD_SCHEDULE:
      return { ...state, rebuildSchedule: action.data.rebuildData };
    case AT.SET_NEWS:
      return { ...state, news: action.data };
    case AT.SET_NEWS_SEARCH_CONDITIONS:
      return { ...state, newsSearchConditions: action.data };
    case AT.SET_MK_LETTER_NEWS:
      return { ...state, mkNews: action.data };
    default:
      return { ...state };
  }
}
