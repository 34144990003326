import React from 'react';
import { priceToText } from '@/pages/Buying/util';

const MarketPriceSummaryItem = ({ title, minPrice, maxPrice, minMonthly, maxMonthly, updatePrice, suffix }) => {
  const isMonthly = minMonthly || maxMonthly;
  const isRange = getIsRange({ isMonthly, minPrice, maxPrice, minMonthly, maxMonthly });

  const updatePriceMin = () => {
    updatePrice(minPrice);
    if (isMonthly) updatePrice(minMonthly, 'price3');
  };

  const updatePriceMax = () => {
    updatePrice(maxPrice);
    if (isMonthly) updatePrice(maxMonthly, 'price3');
  };

  if (minPrice || maxPrice) {
    return (
      <li className="data-item">
        {title} :
        <span className="em style-b" onClick={updatePriceMin}>
          {priceToText(minPrice)}
          {minMonthly ? `/${minMonthly}만` : false}
        </span>
        {isRange ? (
          <>
            {` `}~{' '}
            <span className="em style-b" onClick={updatePriceMax}>
              {priceToText(maxPrice)}
              {maxMonthly ? `/${maxMonthly}만` : false}
            </span>
          </>
        ) : (
          false
        )}
        {suffix && suffix}
      </li>
    );
  } else {
    return false;
  }
};

export default MarketPriceSummaryItem;

const getIsRange = ({ isMonthly, minPrice, maxPrice, minMonthly, maxMonthly }) => {
  if (isMonthly) {
    return minPrice && maxPrice && minPrice != maxPrice && minMonthly != maxMonthly;
  } else {
    return minPrice && maxPrice && minPrice != maxPrice;
  }
};
