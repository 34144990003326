import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import apis from '@/apis';
import * as Messages from './messages';
import downloadApp from '@/services/apps/download';

const error_message = '관심단지 설정 중 오류가 발생하였습니다.';

const useFavoButton = ({ complex_idx }) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const fav_complex_list = useSelector(selectors.common.getTempFavCompList) || {}; //리로드하기 전 관심단지 상태 변화 표시용
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);

  const isFav = useMemo(() => {
    const result = Object?.keys(fav_complex_list)?.includes?.(`${complex_idx}`);
    return !!result;
  }, [fav_complex_list]);

  const add = async () => {
    try {
      const isApp = osTypeInstance?.isApp;
      const data = await apis.personalizeApi.postInterestComplex(complex_idx, 'Y');

      if (data?.tb_ub_interest_complex_idx) {
        dispatch(actions.common.updateTempFavCompList(complex_idx, true)); // 다른 곳에서도 관심단지 표시를 하기때문에

        if (isApp) {
          dispatch(actions.common.alert({ contents: Messages.ADD_FAV_COMPLEX_SUCCESS_APP }));
        } else {
          dispatch(
            actions.common.confirm({
              contents: <Messages.ADD_FAV_COMPLEX_SUCCESS_MOBILE />,
              cancelButtonText: '앱 다운로드',
              onCancel: () => dispatch(downloadApp()),
              confirmButtonText: '닫기',
            })
          );
        }
      }
    } catch (e) {
      dispatch(actions.common.alert({ contents: error_message }));
    }
  };

  const remove = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const interestComplex = await apis.personalizeApi.getInterestComplex(`?tb_cb_complex_idx=${complex_idx}`);
      let targetIdx = null;
      if (interestComplex && Array.isArray(interestComplex)) {
        const item = interestComplex[0];
        if (item && item.tb_cb_complex_idx === complex_idx) {
          targetIdx = item.tb_ub_interest_complex_idx;
        }
      }

      if (targetIdx) {
        await apis.personalizeApi.deleteInterestComplex(targetIdx);
        dispatch(actions.common.alert({ contents: '관심단지가 해제되었습니다.' }));
        dispatch(actions.common.updateTempFavCompList(complex_idx, false)); //맵에서도 관심단지 표시를 하기때문에 필요함
      }

      // info창에서 하트를 눌러서 관심단지 해제시
      let temp = fav_complex_list;
      delete temp[`${complex_idx}`];
      dispatch(actions.common.initalUpdateTempFavCompList(temp));
    } catch (e) {
      dispatch(actions.common.alert({ contents: error_message }));
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (isLoggedIn) isFav ? remove(e) : add();
    else {
      dispatch(actions.common.pleaseLoginPopup(true));
    }
  };
  // const close = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (isMobile) {
  //     dispatch(actions.common.openMobileFavoBox(null));
  //   } else {
  //     setToggle(false);
  //   }
  // };

  // const cancel = (e) => {
  //   if (is_favorite || (isLoggedIn && tempFavCompList[complex_idx])) {
  //     dispatch(actions.common.addFavComplex(complex_idx));
  //   }
  //   close(e);
  // };

  // let markOrNot = false;
  // if (isLoggedIn && tempFavCompList[complex_idx] != undefined) {
  //   markOrNot = tempFavCompList[complex_idx];
  // } else {
  //   markOrNot = is_favorite;
  // }

  return { isFav, add, remove, handleClick };
};

export default useFavoButton;
