import { actions } from '@/data';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useShowDeletePopup = ({ deleteStatus }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteStatus?.status == 'SUCCESS') {
      dispatch(
        actions.common.alert({
          contents: '글이 삭제되었습니다.',
          onConfirm: () => {
            dispatch(actions.router.goBack());
          },
        })
      );
    }
  }, [deleteStatus]);

  return;
};

export default useShowDeletePopup;
