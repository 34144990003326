import React from 'react';
export const text = {
  POST_ERROR_TEXT: '문의 등록 중 오류가 발생했습니다.',
  // MODAL_POST_DONE : <><b>1:1</b>문의 등록 요청이 완료되었습니다.</>,
  MODAL_POST_DONE: <>문의 등록 요청이 완료되었습니다.</>,

  UPPER_TEXT: '문의 내용을 작성해주시면, 빠르게 답변해 드리겠습니다.',

  REGQNA_OPTIONS: ['', '시스템오류', '집구하기', '집내놓기', '마이페이지', '결제오류', '기타'],
  REGQNA_OPTION_1: '시스템오류',
  REGQNA_OPTION_2: '집구하기',
  REGQNA_OPTION_3: '집내놓기',
  REGQNA_OPTION_4: '마이페이지',
  REGQNA_OPTION_5: '결제오류',
  REGQNA_OPTION_6: '기타',

  REGQNA_TITLE_PLACEHOLDER: '제목입력',
  REGQNA_BODY_PLACEHOLDER: '문의할 내용을 입력해주세요.',
};
