import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

export const api = {
  async uploadImageItem({ imageCategory, imageFileName, imageFile }) {
    try {
      // * category
      // * filename
      const { putUrl, fullUrl, contentType } = await apiClient.post(
        '/images' + objectToQuerystring({ category: imageCategory, filename: imageFileName })
      );

      const body = imageFile;
      const config = { headers: { 'Content-Type': `${contentType}` } };

      return await apiClient.put(`${putUrl}`, body, config);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
