import React from 'react';
import { MODE } from '../../index.js';
import Tab from '../../Tab';
import Search from '@/combinedComp/Search';
import InputSearchView from './InputSearch';
import SelectSearchView from './SelectSearch';
import { parseCallBack } from '@/services/Helper/queryString.js';
import AddressSimpleSelect from '@/components/AddressSimpleSelect/index.js';

// 개편 UI
const View3 = ({
  disabled,
  isMobile,
  markTypes,
  markRule,
  searchInputPlaceholder,
  mode,
  changeMode,
  complexData,
  setComplexData,
  addressData,
  setAddressData,
  onSearchComplexSelected,
  addAllOption,
  tenDigitCode,
  dongCode,
  preventUpdateComplexData,
  feedType,
  brokerNumber,
  setBrokerNumber,
  localAddressMode,
  quickSettingButtons,
}) => {
  const inputMode = localAddressMode ? MODE.SELECT : mode;

  const querystring = parseCallBack();
  const isPostForBuy = querystring?.['postType'] === 'POST_FOR_BUY';
  // const { showQuickSettingPopup, popupOption, handleQuickSettingPopup } = useQuickSettingPopup();

  return (
    <>
      <div className="form-item">
        <div className="form-wrap">
          <div className="form-head">
            <Tab mode={localAddressMode ? MODE.SELECT : inputMode} changeMode={changeMode} localAddressMode={localAddressMode} feedType={feedType} />
            {isPostForBuy && <p className="form-summary">어디에서 구해드릴까요? 지역(구, 동)이나, 단지를 선택해 주세요</p>}
          </div>
          <div className="form-body active" id={inputMode === MODE.TEXT ? 'searchComplex' : 'searchAddress'} onSubmit={(e) => e.preventDefault()}>
            <AddressSimpleSelect
              types={quickSettingButtons}
              applyCallback={(data) => {
                onSearchComplexSelected(
                  {
                    b_dong_code: data.dongCode,
                    complex_idx: data.complexCode,
                    title: data.mainName,
                    iconType: data.complexType,
                    complex_type: data.complexType,
                  },
                  { original: data }
                );
              }}
            />

            {inputMode === MODE.TEXT ? (
              <Search
                key={`TEXT-${complexData?.complex_idx}`}
                View={InputSearchView}
                totalSearch={true}
                showFilter={false}
                showSearchHistory={false}
                onSelected={onSearchComplexSelected}
                markTypes={markTypes}
                markRule={markRule}
                isMobile={isMobile}
                placeholder={searchInputPlaceholder || '단지나 아파트명을 검색하세요.'}
                defaultInput={complexData?.complex_name}
                setBrokerNumber={setBrokerNumber}
                feedType={feedType}
                disabled={disabled}
              />
            ) : (
              <SelectSearchView
                addressData={addressData}
                setAddressData={setAddressData}
                addAllOption={addAllOption}
                disabled={disabled}
                tenDigitCode={tenDigitCode}
                dongCode={dongCode}
                complexData={complexData}
                setComplexData={setComplexData}
                markTypes={markTypes}
                preventUpdateComplexData={preventUpdateComplexData}
                brokerNumber={brokerNumber}
                setBrokerNumber={setBrokerNumber}
                feedType={feedType}
                localAddressMode={localAddressMode}
              />
            )}
          </div>
        </div>
      </div>
      {/* <ListContentsPopup /> */}
    </>
  );
};

export default View3;
