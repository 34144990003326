import { apiClient } from '@/apis/clients';
import * as querystring from '@/services/router/querystring';

export const api = {
  /**
   * 중개사 상세
   * @param {string|number} brokerOfficeIdx 상세조회 할 중개사 idx
   * @param {string|number} goodsIdx (optional) 중개사에게 문의를 남길 매물 idx
   * @param {string|number} mbrIdx (optional) 중개사에게 문의를 남길 유저 idx
   * @returns
   */
  async getBrokerDetail(brokerOfficeIdx, goodsIdx, mbrIdx) {
    try {
      const data = { brokerIdx: brokerOfficeIdx };
      if (goodsIdx) data.goodsIdx = goodsIdx;
      if (mbrIdx) data.mbrIdx = mbrIdx;

      return await apiClient.get('/broker/broker-agent' + querystring.stringify(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
  async getThisAreaRealtorList(payload) {
    try {
      const data = { dongCode: payload };
      return await apiClient.get('/broker/dongCode' + querystring.stringify(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 중개사 정보 벌크 요청
  async getBulkRealtorsInfo(payload) {
    try {
      return await apiClient.post('/broker/informations/bulk', { brokerIdxes: payload });
    } catch (e) {
      throw Error(e.message);
    }
  },
};
