export const SELL_ADD_IMAGE_ITEM = 'UPLOAD_IMAGE/SELL_ADD_IMAGE_ITEM';
export const SELL_DELETE_IMAGE_ITEM = 'UPLOAD_IMAGE/SELL_DELETE_IMAGE_ITEM';

export const UPLOAD_ITEM_IMAGE = 'UPLOAD_IMAGE/UPLOAD_ITEM_IMAGE';

export const ERROR_UPLOAD_ITEM_IMAGE = 'UPLOAD_IMAGE/ERROR_UPLOAD_ITEM_IMAGE';

export const AS_ADD_IMAGE_ITEM = 'UPLOAD_IMAGE/AS_ADD_IMAGE_ITEM';
export const AS_DELETE_IMAGE_ITEM = 'UPLOAD_IMAGE/AS_DELETE_IMAGE_ITEM';
export const DELETE_AGENT_SIGNUP_IMAGE = 'UPLOAD_IMAGE/DELETE_AGENT_SIGNUP_IMAGE';
