import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

import AppShare from './components/AppShare';
import KakaoShare from './components/KakaoShare';
import FacebookShare from './components/FaceBookShare';
import LinkShare from './components/LinkShare';

/**
 * @param {Object} shareData 
   {
    popupTitle : 팝업 제목 (default. 공유하기),
    popupDesc : 팝업 설명,
    templateType : 팝업 템플렛 종류,
    templateId : 팝업 템플렛 아이디,
    templateArgs : 팝업 템플렛 데이터,
    oneLineMessage : 한줄 메세지,
    }
  */
const SocialShareGroup = ({ shareData }) => {
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const isApp = osTypeInstance?.isApp;

  return (
    <>
      {isApp ? (
        <AppShare shareData={shareData} />
      ) : (
        <ul>
          <li>
            <KakaoShare shareData={shareData} />
          </li>
          <li>
            <FacebookShare shareData={shareData} />
          </li>
          <li>
            <LinkShare shareData={shareData} />
          </li>
        </ul>
      )}
    </>
  );
};

export default SocialShareGroup;
