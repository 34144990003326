import React from 'react';
import { share_basic } from '@/resources';
import { getPath } from '@/components/popups/SocialSharePopup/utils.js';
import { SHARE_FROM } from '../../constants';

const AppShare = ({ shareData }) => {
  const { oneLineMessage, templateArgs } = shareData || {};
  const { COMMON_PATH } = templateArgs || {};

  const msg = `${oneLineMessage}\n\n${getPath({ path: COMMON_PATH, from: SHARE_FROM.APP })}`;

  const handleClick = (e) => {
    e.preventDefault();
    window.dawin_instance.bridge.osFunction.share(msg);
  };

  return (
    <a
      onClick={handleClick}
      className="btn-img btn-share-wh"
      style={{ background: `url(${share_basic}) no-repeat`, backgroundSize: 'contain', verticalAlign: 'top', width: '45px', height: '45px' }}>
      공유하기
    </a>
  );
};

export default AppShare;
