import React from 'react';
import { Field, useFormikContext } from 'formik';

const AgentCard = ({ data }) => {
  const { broker_office_idx, office_name, mbr_name, broker_point, broker_point_cnt, mbr_image_full_path } = data;
  const percent = (100 * broker_point) / 5;

  const { values } = useFormikContext();

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <li className={'agent-card' + (values.brokerofficeidx == broker_office_idx ? ' on' : '')}>
      <div className="itm_inner">
        <div className="thumbnail_area">
          <div className="thumbnail">
            <img src={mbr_image_full_path} alt="중개사사진" />
          </div>
        </div>
        <a className="agent_info" onClick={handleClick}>
          <div className="broker_info">
            <div className="star_score">
              <span className="st_off">
                <span className="st_on" style={{ width: percent + '%' }}>
                  {broker_point}
                </span>
              </span>
              <span href="" className="ct_review">
                ({broker_point_cnt ? broker_point_cnt : '0'})
              </span>
            </div>
            <p className="commtype">{office_name}</p>
            <p className="bk_name">{mbr_name}</p>
          </div>
        </a>
        <div className="check-box02">
          <div className="check">
            <label htmlFor="chkag01">
              <Field type="radio" name="brokerofficeidx" value={broker_office_idx} checked={values.brokerofficeidx == broker_office_idx} />
              <i></i>
            </label>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AgentCard;
