import React from 'react';
import { Link } from 'react-router-dom';

const CallBtns = ({ typeArray, phoneData, call }) => {
  const isCallRequested = typeArray?.includes('WANT_TO_CALL');
  const hasSafeCallNumber = phoneData !== undefined;

  return isCallRequested && hasSafeCallNumber ? (
    <Link className="btn module-a style-c type-line accent-02 small-1x" to="#" onClick={(e) => call(e)}>
      <span className="btn-text">전화하기</span>
    </Link>
  ) : (
    false
  );
};

export default CallBtns;
