import * as AT from './actionTypes';

export const getError = (state) => state?.selling?.error;
export const getItemInfo = (state) => state?.selling?.itemInfo;
export const getLastCategory = (state) => state?.selling?.lastCategory;
export const getLastStep = (state) => state?.selling?.lastStep;

// 요청_전화로 집내놓기
export const getSellCallRequestState = (state) => state?.selling[AT.SELL_CALL_REQUEST].which();
// 요청_직접 집내놓기
// 직접 매물등록 : 각 단계 정보 받아오기
export const getManualStepDataIsSuccess = (state) => state?.selling[AT.SELL_MANUAL_GET_STEP_DATA]?.isSuccess();
export const getManualStepDataIsFailure = (state) => state?.selling[AT.SELL_MANUAL_GET_STEP_DATA]?.isFailure();

// 직접 매물등록 : 각 단계 등록 혹은 임시저장
export const handleManualNextStep = (state) => state?.selling[AT.SELL_MANUAL_HANDLE_NEXT_STEP];
export const handleManualNextStepIsLoading = (state) => state?.selling[AT.SELL_MANUAL_HANDLE_NEXT_STEP]?.isLoading();
export const handleManualNextStepIsSuccess = (state) => state?.selling[AT.SELL_MANUAL_HANDLE_NEXT_STEP]?.isSuccess();
export const handleManualNextStepIsFailure = (state) => state?.selling[AT.SELL_MANUAL_HANDLE_NEXT_STEP]?.isFailure();

export const getSellerType = (state) => state?.selling?.sellerType;
export const getSavedForm = (state) => state?.selling.savedForm;

// [[Dawin 2.3]]-----------------------------------------------------------
export const getSellingData = (state) => state?.selling.data;
export const getSiDoList = (state) => state?.selling?.si_do_list;
export const getSiGunGuList = (state) => state?.selling?.si_gun_gu_list;
export const getDongRiList = (state) => state?.selling?.dong_ri_list;

export const getSiDoCode = (state) => state?.selling?.si_do_code;
export const getSiGunGuCode = (state) => state?.selling?.si_gun_gu_code;
export const getDongRiCode = (state) => state?.selling?.dong_ri_code;

export const getSiDoName = (state) => state?.selling?.si_do_name;
export const getSiGunGuName = (state) => state?.selling?.si_gun_gu_name;
export const getDongRiName = (state) => state?.selling?.dong_ri_name;

export const getSellLat = (state) => state?.selling?.lat;
export const getSellLng = (state) => state?.selling?.lng;
// APT, OFT 공용
export const getComplexIdx = (state) => state?.selling?.complexIdx;
// APT, OFT 공용
export const getComplexList = (state) => state?.selling[AT.GET_COMPLEX_LIST].getOrElse([]);
// APT, OFT 공용
export const getComplexInfo = (state) => state?.selling?.complexInfo;
export const getComplexDongList = (state) => state?.selling[AT.GET_COMPLEX_DONG_LIST].getOrElse([]);
export const getFloorList = (state) => state?.selling?.floor_list;
export const getHoList = (state) => state?.selling?.ho_list;
// ===================== Step 2 ===================== //
export const getOptionInfo = (state) => state?.selling?.optionInfo;
export const getItemAdvantage = (state) => state?.selling?.itemAdvantage;
// ===================== Step 4 ===================== //
export const getNearbyAgents = (state) => state?.selling[AT.GET_NEARBY_AGENTS]?.getOrElse([]);
export const getNearbyAgentsIsLoading = (state) => state?.selling[AT.GET_NEARBY_AGENTS]?.isLoading();
export const getNearbyAgentsIsSuccess = (state) => state?.selling[AT.GET_NEARBY_AGENTS]?.isSuccess();
export const getNearbyAgentsIsFailure = (state) => state?.selling[AT.GET_NEARBY_AGENTS]?.isFailure();
export const getSelectedAgentsList = (state) => state?.selling?.selectedAgentsList;
export const getFocusedAgentIndex = (state) => state?.selling?.focusedAgentIdx;
export const getMapCenter = (state) => state?.selling?.mapCenter;
