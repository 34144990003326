import * as AT from './actionTypes';
// import CONSTANTS from '../../pages/AgentSignUp/constants';
import Remote from '@/data/remote';
import { STATUS } from '../utils';
// import { failure, loading, success } from '@/data/utils';
// import { getCookieByKey, setCookieByKey } from '@/data/cookie';
// import { actions } from '../';
// import { CreatePoint } from '@/resources/global/Community';

const INITIAL_STATE = {
  showCreateFeed: false,
  [AT.SEARCH_TAGS]: Remote.NotAsked,
  // createPoint: CreatePoint.LOCAL_POST,
  replyMode: false,
  setFeedStatus: STATUS.NOTASKED,
  commentStatus: STATUS.NOTASKED,
  moreCommentStatus: STATUS.NOTASKED,
  deletedFeeds: [],
  localComplexTag: undefined,
};

export default function communtiy(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.COMMUNITY_SEARCH:
      return {
        ...state,
      };
    case AT.FEED_LIKE:
      return {
        ...state,
      };
    case AT.SEARCH_TAGS_LOADING:
      return { ...state, [AT.SEARCH_TAGS]: Remote.Loading };
    case AT.SEARCH_TAGS_SUCCESS:
      return { ...state, [AT.SEARCH_TAGS]: Remote.Success(action.data) };
    case AT.SEARCH_TAGS_FAILURE:
      return { ...state, [AT.SEARCH_TAGS]: Remote.Failure(action.error) };

    case AT.SHOW_CREATE_FEED:
      return { ...state, showCreateFeed: action.show };
    case AT.CHANGE_CREATE_POINT:
      return { ...state, createPoint: action.point };
    case AT.SET_COMMUNITY_FEED:
      return { ...state, getFeed: action.data };
    case AT.SET_PHONE_NUMBER:
      return { ...state, getPhoneNumber: action.data };
    case AT.SET_COMMENTS:
      return { ...state, getComments: action.data };
    case AT.CREATE_COMMENT:
      return { ...state, createComment: action.data };
    case AT.SET_FEED_DETAIL_ITEM:
      return { ...state, real_price_detail_item: action.detail_item };
    case AT.COMMENT_LIKE:
      return { ...state };
    case AT.FIRE_RELOAD:
      return { ...state, fireReload: action.reload };
    case AT.SET_USER_HISTORY_TARGET:
      return { ...state, userHistoryTarget: action.payload };
    case AT.COMMUNITY_CHANGE_REGION_CODE:
      return { ...state, myLocation: action.payload };

    //test
    // case AT.REPLY_MODE:
    //   return {
    //     ...state,
    //     replyMode: action.payload,
    //   };
    case AT.SET_FEED_STATUS:
      return { ...state, setFeedStatus: action.payload };
    // 댓글 생성 후 로딩바
    case AT.SET_COMMENT_STATUS:
      return { ...state, commentStatus: action.payload };
    // 댓글 더보기 버튼 클릭 후 로딩 바
    case AT.SET_MORE_COMMENT_STATUS:
      return { ...state, moreCommentStatus: action.payload };
    case AT.SET_CREATED_FEED:
      return { ...state, createdFeed: action.createdFeed };
    case AT.SET_DELETED_FEEDS:
      return { ...state, deletedFeeds: action.deletedFeeds };

    case AT.SET_LOCAL_COMPLEX_TAG:
      return { ...state, localComplexTag: action.payload };
    default:
      return { ...state };
  }
}
