import React from 'react';
import styled from 'styled-components';

export default function CommnonSpinner({
  show,
  position,
  padding = 0,
  zIndex = 1,
  background = 'transparent',
  spinnerColor,
  spinnerWrapperBackground,
}) {
  return show ? (
    <Background background={background} position={position} padding={padding} zIndex={zIndex}>
      <SpinnerWrapper spinnerWrapperBackground={spinnerWrapperBackground}>
        <MainSpinner spinnerColor={spinnerColor} width={2} size={19} />
      </SpinnerWrapper>
    </Background>
  ) : null;
}

// spinner의 배경 설정 속성
const Background = styled.div`
  position: ${(props) => `${props.position}`};
  padding: ${(props) => props.padding};
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  z-index: ${(props) => `${props.zIndex}`};

  background: ${(props) => (props.background === 'dark' ? `#0000009e` : props.background)};
`;

//spinner가 중앙에 위치할 수 있도록 하는 속성
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  background: ${(props) => (props.spinnerWrapperBackground ? `${props.spinnerWrapperBackground}` : `#404040`)};
  border-radius: 25px;
  opacity: 0.8;
`;

// 회전하는 spinner의 속성
const MainSpinner = styled.div`
  height: ${(props) => (props.size ? `${props.size}px` : `28px`)};
  width: ${(props) => (props.size ? `${props.size}px` : `28px`)};

  animation: rotate 0.8s infinite linear;
  border: ${(props) => props.width}px solid ${(props) => (props.spinnerColor ? `${props.spinnerColor}` : `white`)};
  border-right-color: transparent;
  border-radius: 50%;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
