import { SHARE_FROM } from './constants';

export const addLoc = (shareUrl, center) => {
  if (shareUrl.indexOf('loc=') === -1) {
    const lat = `${center[0]}`.replace('.', '');
    const lng = `${center[1]}`.replace('.', '');
    return shareUrl.indexOf('?') > -1 ? shareUrl + `&loc=${lat},${lng}` : shareUrl + `?loc=${lat},${lng}`;
  } else {
    return shareUrl;
  }
};

export const addToken = (shareUrl, token) => {
  if (shareUrl.indexOf('stk=') === -1) {
    return shareUrl.indexOf('?') > -1 ? shareUrl + `&stk=${token}` : shareUrl + `?stk=${token}`;
  } else {
    return shareUrl;
  }
};

// 모바일에서는 d_complex가 있는 상태(단지상세보기 상태) 에서 공유하기를 할 수 없으므로 상관 없지만
// 웹에서 단지 상세보기가 열린 상태로 공유했을 경우에는 URL을 전달받은 유저에게 단지상세보기가 아닌 분석 화면을 보여줘야 함.
export const removeDComplex = (shareUrl) => {
  const dComplexIndex = shareUrl.indexOf('d_complex');
  if (dComplexIndex > -1) {
    const prev = shareUrl.substring(0, dComplexIndex);
    let next = shareUrl.substring(dComplexIndex, shareUrl.length);
    next = next.substring(next.indexOf('&') + 1, next.length);
    return prev + next;
  } else {
    return shareUrl;
  }
};

export const getPath = ({ path, from }) => {
  let sharePath = path;
  if (from === SHARE_FROM.KAKAO) {
    sharePath = path?.split('://')[1].split('.xyz/')[1];
    // 로컬 테스트용
    if (path?.indexOf('localhost') > -1 || path?.indexOf('127.0.0.1') > -1 || path?.indexOf('192.168.0') > -1) {
      sharePath = path?.split('://')[1].split(':1235/')[1];
    }
  } else if (from === SHARE_FROM.FACEBOOK) {
    if (path.toString().indexOf('localhost:1235') > -1) {
      sharePath = path.replace('http://localhost:1235', 'https://test.dawin.xyz');
    }
  }

  if (from) {
    sharePath = sharePath?.includes('?') ? `${sharePath}&from=${from}` : `${sharePath}?from=${from}`;
  }

  return sharePath;
};

// let shareUrl = url || window.location.href;
// if (!shareUrl?.includes('/profile/realtor')) {
//   shareUrl = shareUrl.indexOf('?') > -1 ? shareUrl + '&socialshare' : shareUrl + '?socialshare';
// }
