import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '@/data';
import Header from './components/header';
import toggle from '@/hocs/toggle';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import Core from './Core';

const UserCommunityHistory = () => {
  const dispatch = useDispatch();

  const userHistoryTarget = useSelector(selectors.community.getUserHistoryTarget);
  const myInfo = useSelector(selectors.auth.getUserInfo);

  // id
  const userId = myInfo?.mbr_idx;
  const mbr_idx = userHistoryTarget?.id;

  const isMe = `${userId}` === `${mbr_idx}`;

  // name
  const writerName = userHistoryTarget?.writer;
  const name = isMe ? '내 활동 보기' : writerName;

  const querystring = parseCallBack();

  const handleClose = (e) => {
    e.preventDefault();
    delete querystring['userHistory'];
    dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(querystring)}`));
    dispatch(actions.community.setUserHistoryTarget(null));
  };

  return (
    <div className={`content-popup module-a style-a type-a medium active`} id="postsWriterInfo" data-bui-toggle="contentsPopup">
      <div className="popup-page-body">
        <div className="popup-local">
          <Header name={name} />
          <div className="popup-local-body">
            <div className="popup-content">
              <div className="popup-content-body">
                <Core targetUserId={userHistoryTarget?.id} />
              </div>
            </div>
          </div>
          <div className="popup-local-func">
            <span className="button-area">
              <button type="button" className="btn popup-close" onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                  <title>닫기</title>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default toggle(UserCommunityHistory);
