import { SHARE_POPUP_TYPES } from '@/components/popups/SocialSharePopup/constants';

const useGetShareData = ({ feedData }) => {
  const shareData = {
    popupTitle: '게시물 공유하기',
    templateType: SHARE_POPUP_TYPES.FEED,
    templateArgs: {
      MAIN_IMG_FIRST: feedData?.images?.[0]?.url,
      WRITER_NAME: feedData?.writer?.name,
      TITLE: '다윈중개 - 중개게시판',
      DESC: feedData?.contents,
      LIKES: feedData?.reactionCount?.filter((item) => item?.type === 'LIKE')?.[0].count,
      COMMENTS: feedData?.commentCount,
      BTN_NAME_FIRST: '게시물 보기',
      COMMON_PATH: location.href,
    },
    oneLineMessage: '다윈중개에 올라온 게시글을 확인해보세요.',

    // popupTitle: '게시물 공유하기',
    // templateType: SHARE_POPUP_TYPES.BASIC,
    // templateArgs: {
    //   TITLE: '다윈중개 - 중개게시판',
    //   DESC: '다윈중개에 올라온 게시글을 확인해보세요.',
    //   BTN_NAME_FIRST: '게시물 보기',
    //   COMMON_PATH: location.href,
    // },
    // oneLineMessage: '다윈중개에 올라온 게시글을 확인해보세요.',
  };
  return [shareData];
};

export default useGetShareData;
