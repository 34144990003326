import React from 'react';
import { Link } from 'react-router-dom';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncConfirm from '@/components/popups/InfoPopups/Confirm/hooks/useAsyncConfirm';

const MenuItem = ({ to, children, routeToBrokerPage }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectors.auth.getUserInfo);
  const isBroker = userInfo?.isBroker;

  const [openConfirm, ConfirmComponent] = useAsyncConfirm({
    popupId: 'menu-realtor-chat-route-confirm',
    contents: '중개사마이페이지로 이동합니다. 계속하시겠습니까?',
  });

  let on = false;
  if (location.pathname.indexOf('/selling/manual') !== -1 || location.pathname.indexOf('/my/mysell') !== -1) {
    if (to === '/my/mysell') on = true;
  } else if (to === '/' && (location.pathname === '/' || location.pathname === '')) {
    on = true;
  } else if (to !== '/' && location.pathname.indexOf(to) === 0) {
    on = true;
  }

  const handleClick = async (e) => {
    e.preventDefault();

    if (!on) {
      if (routeToBrokerPage && isBroker && location.pathname?.indexOf('/brokerPage') < 0) {
        const confirmed = await openConfirm();
        if (!confirmed) {
          return;
        }
      }
      dispatch(actions.router.push(to));
    } else {
      dispatch(actions.router.replace(to));
    }
  };

  return (
    <>
      <li className={`navi-item ${on ? 'current' : ''}`}>
        <Link className="navi-name" to={to} onClick={handleClick}>
          {children}
        </Link>
      </li>

      <ConfirmComponent />
    </>
  );
};

export default MenuItem;
