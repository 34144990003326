import React from 'react';
import { Link } from 'react-router-dom';
import TemplateContentsPopup from '@/components/templates/TemplateContentsPopup';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '@/data';
import serviceRealtor from '@/services/realtor';
import { routeToBrokerDetail } from '@/pages/Buying/route';
import useCommonContact from '@/hooks/useCommonContact';
import useRealtorData from './hooks/useRealtorData';
import CommonRealtorIntroPopupSkeleton from './components/CommonRealtorIntroPopupSkeleton';
import { STATUS } from '@/data/utils';

/**
 *
 * @param {object} chatParam title = "", subjectType, subjectId
 * @returns
 */
const CommonRealtorIntroPopup = ({ realtorIdx, onClose, chatParam = {}, fromData }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectors.auth.getUserInfo);
  const isMobile = useSelector(selectors.common.isMobile);

  const { data, status } = useRealtorData(realtorIdx);

  const profile = data?.profile;
  const cleanProfileData = serviceRealtor.getCleanRealtorData(profile);
  const consultingCnt = data?.consulting?.uniqueUsers,
    contractCnt = data?.contracts?.length;

  // id
  const { broker_office_idx, mbr_name, office_name, mbr_image_full_path, broker_point, broker_point_cnt, mainPhoneNumber } = cleanProfileData || {};

  const isMe = `${broker_office_idx}` === `${userInfo?.mbr_idx}`;

  const openRealtorDetailPopup = (e) => {
    e.preventDefault();
    dispatch(routeToBrokerDetail(broker_office_idx, { fromData, chatParam }));
  };

  const { chat, call } = useCommonContact(false, userInfo);

  const onChat = (e) => {
    e.preventDefault();
    e.stopPropagation();

    chat(broker_office_idx, chatParam, { userInfo });
  };

  const onCall = (e) => {
    e.preventDefault();
    e.stopPropagation();

    call(mainPhoneNumber, { targetUserName: office_name, targetUserId: broker_office_idx, chatParam });
  };

  return (
    <TemplateContentsPopup popupId="brokerInfo" title={office_name} onClose={onClose}>
      {status === STATUS.LOADING ? (
        <CommonRealtorIntroPopupSkeleton />
      ) : (
        <div className="popup-local-body">
          <div className="popup-content">
            <div className="popup-content-body">
              <div className="goods-display module-c style-c type-c">
                <div className="goods-list">
                  <div className="goods-item">
                    <div className="goods-wrap">
                      <div className="goods-figure">
                        <a className="goods-thumbnail">
                          <img src={mbr_image_full_path} alt="" />
                        </a>
                      </div>
                      <div className="goods-inform">
                        <a className="goods-info">
                          <p className="data-list">
                            <span className="data-item name">
                              <span className="head">이름</span>
                              <span className="body">{mbr_name}</span>
                            </span>
                            <span className="data-item utility">
                              <div className="button-display module-a style-a type-c">
                                <span className="button-area">
                                  <Link
                                    className="btn module-a style-c type-line normal-04 small-1x symbol-rtl-chevron-right"
                                    to={`/profile/realtor/${broker_office_idx}`}
                                    onClick={openRealtorDetailPopup}>
                                    <span className="btn-text">중개사정보 더보기</span>
                                  </Link>
                                </span>
                              </div>
                            </span>
                            <span className="data-item grade style-b">
                              <span className="head">평점</span>
                              <span className="body">
                                <span className="graph"></span>
                                <span className="point">{broker_point}</span>
                                <span className="count">{broker_point_cnt}건</span>
                              </span>
                            </span>
                            <span className="data-item brokerinfo">
                              <span className="head">기본정보</span>
                              <span className="body">
                                {contractCnt > 0 && (
                                  <span className="mark module-a style-b type-fill normal-05 small-1x">
                                    <span className="mark-text">
                                      다윈계약 <strong>{contractCnt}건</strong>
                                    </span>
                                  </span>
                                )}
                                {consultingCnt > 0 && (
                                  <span className="mark module-a style-b type-fill normal-05 small-1x">
                                    <span className="mark-text">
                                      상담고객 <strong>{consultingCnt}명</strong>
                                    </span>
                                  </span>
                                )}
                              </span>
                            </span>
                          </p>
                        </a>
                        {isMe ? (
                          <></>
                        ) : (
                          <div className="goods-util">
                            <span className="button-area">
                              <Link className="btn module-a style-c type-fill accent-01 large" to="#" onClick={onCall}>
                                <span className="btn-text">전화하기</span>
                              </Link>
                              <Link className="btn module-a style-c type-line accent-01 large" to="#" onClick={onChat}>
                                <span className="btn-text">채팅하기</span>
                              </Link>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </TemplateContentsPopup>
  );
};

export default CommonRealtorIntroPopup;
