import React from 'react';
import { routeToNextStep } from '@/pages/Login/helpsers';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { OSTYPE } from '@/services/apps/types/OsType';

// 애플 로그인은 iOS 어플에만 노출

/**
 * @deprecated
 */
const Apple = () => {
  const dispatch = useDispatch();
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const osType = osTypeInstance?.osType;

  async function loginWithApple(e) {
    e.preventDefault();
    dispatch(routeToNextStep('apple'));

    try {
      const result = await window.dawin_instance.bridge.thirdPartyFunction.appleSignIn();
      dispatch(actions.auth.social1({ stype: 'Apple', accessToken: result.accessToken }));
    } catch (e) {
      if (e.message !== 'user cancelled') {
        dispatch(actions.common.alert({ contents: '애플로 로그인 처리 중 오류가 발생하였습니다.' }));
      }
    }
  }

  if (osType === OSTYPE.APP_IOS) {
    return (
      <a className="btn btn-login-apple" onClick={loginWithApple}>
        애플로 시작하기
      </a>
    );
  } else return false;
};

export default Apple;
