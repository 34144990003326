/**
 * 맵 이동 제한
 * initialLevel 이하에서만 zoom, drag 가능
 * @param {kakao.maps.Map} map 카카오 맵 객체
 * @param {kakao.maps.LatLng} initialCenter 기준이 되는 좌표
 * @param {Number} initialLevel 기준이 되는 zoom level
 **/
var constrainMapBounds = function(map, initialCenter, initialLevel) {
  const level = map.getLevel();

  if (level < initialLevel) {
    map.setDraggable(true);
  } else {
    map.setDraggable(false);
    map.setLevel(initialLevel);
    map.setCenter(initialCenter);
  }
};

export default constrainMapBounds;
