import React from 'react';
import MenuItem from './MenuItem';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import { DAWIN_URL } from '@/resources/global';

const Menu = () => {
  const user = useSelector(selectors.auth.getUserInfo);
  const isBroker = user?.mbr_type === 'BU';

  const myUrl = isBroker ? '/brokerPage' : DAWIN_URL.my;
  const chatUrl = isBroker ? '/brokerPage/chat' : DAWIN_URL.chat;

  const reduxStateChatData = useSelector(selectors.chat.getChatData);

  const notReadChatCount = reduxStateChatData?.rooms?.entrySeq()?.reduce((prev, curr) => prev + curr[1].notReadCount, 0);
  const chatCountText = notReadChatCount > 99 ? '99+' : notReadChatCount;

  return (
    <div className="page-navi">
      <ul className="navi-list">
        <MenuItem to={DAWIN_URL.home}>홈</MenuItem>
        <MenuItem to={DAWIN_URL.buying}>매물지도</MenuItem>
        <MenuItem to={`${DAWIN_URL.my}/mysell`}>집내놓기</MenuItem>
        <MenuItem to={DAWIN_URL.analysis}>분석</MenuItem>
        <MenuItem to={DAWIN_URL.aboutUs}>서비스소개</MenuItem>
        <MenuItem to={chatUrl} routeToBrokerPage={true}>
          <>
            채팅상담
            {notReadChatCount > 0 ? <span className="count">{chatCountText}</span> : <></>}
          </>
        </MenuItem>
        <MenuItem to={myUrl}>마이페이지</MenuItem>
      </ul>
    </div>
  );
};

export default Menu;
