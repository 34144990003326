import * as AT from './actionTypes';

export const getContractList = (state) => state?.brokerPage?.mgContract?.contractList;
export const getContractListLoading = (state) => state?.brokerPage?.mgContract[AT.GET_MANAGE_CONTRACT_LIST]?.isLoading();
export const getContractDetail = (state) => state?.brokerPage?.mgContract?.contractDetail;
export const getBrokerage = (state) => state?.brokerPage?.mgContract?.brokerage;

export const getKeyword = (state) => state?.brokerPage?.mgContract?.keyword;
export const getKeywordList = (state) => state?.brokerPage?.mgContract?.keywordList;

export const getContractMembers = (state) => state?.brokerPage?.mgContract?.members;
export const getContractDetails = (state) => state?.brokerPage?.mgContract?.details;

export const getManageContractOrder = (state) => state?.brokerPage?.mgContract?.order;
export const getManageContractOrderDir = (state) => state?.brokerPage?.mgContract?.orderDir;
export const getManageContractPage = (state) => state?.brokerPage?.mgContract?.page;
export const getManageContractNext = (state) => state?.brokerPage?.mgContract?.nextDataYn;
