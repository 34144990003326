import { TagType } from '@/services/Community';
import { useEffect } from 'react';

const useSetAddressByFilter = ({ address, payload, setPayload, complexTag }) => {
  useEffect(() => {
    //전체 지역 설정했을경우
    if (address && address?.dong?.code) {
      if (address?.dong?.code != 'all') {
        if (address?.dong2?.code && address?.dong2?.code != 'all') {
          let tmp_dong_tag =
            payload?.tags?.filter((ii) => ii.type == TagType.ADDRESS_TAG && ii.key != address?.dong?.code && ii.key != address?.dong2?.code) || [];
          let tmp_tag_payload = payload?.tags?.filter((ii) => ii.type != TagType.ADDRESS_TAG) || [];
          tmp_dong_tag?.push({ type: TagType.ADDRESS_TAG, name: address?.dong?.name, key: address?.dong2?.code + '' });
          tmp_dong_tag?.map((iii) => {
            tmp_tag_payload.push(iii);
          });
          setPayload({ ...payload, tags: tmp_tag_payload });
        } else if (address?.dong2?.code && address?.dong2?.code == 'all') {
          let tmp_dong_tag =
            payload?.tags?.filter((ii) => ii.type == TagType.ADDRESS_TAG && ii.key != address?.dong1?.code && ii.key != address?.dong2?.code) || [];
          let tmp_tag_payload = payload?.tags?.filter((ii) => ii.type != TagType.ADDRESS_TAG) || [];
          tmp_dong_tag?.push({ type: TagType.ADDRESS_TAG, name: address?.dong1?.name, key: address?.dong1?.code + '' });
          tmp_dong_tag?.map((iii) => {
            tmp_tag_payload.push(iii);
          });
          setPayload({ ...payload, tags: tmp_tag_payload });
        } else {
          let tmp_dong_tag =
            payload?.tags?.filter((ii) => ii.type == TagType.ADDRESS_TAG && ii.key != address?.dong1?.code && ii.key != address?.dong2?.code) || [];
          let tmp_tag_payload = payload?.tags?.filter((ii) => ii.type != TagType.ADDRESS_TAG) || [];
          tmp_dong_tag?.push({ type: TagType.ADDRESS_TAG, name: address?.dong?.name, key: address?.dong?.code + '' });
          tmp_dong_tag?.map((iii) => {
            tmp_tag_payload.push(iii);
          });
          setPayload({ ...payload, tags: tmp_tag_payload });
        }
      } else {
        let tmp_sigungu_tag = payload?.tags?.filter((ii) => ii.type == TagType.ADDRESS_TAG && ii.key != address?.sigungu?.code) || [];
        let tmp_tag_payload = payload?.tags?.filter((ii) => ii.type != TagType.ADDRESS_TAG) || [];
        tmp_sigungu_tag?.push({ type: TagType.ADDRESS_TAG, name: address?.sigungu?.name, key: address?.sigungu?.code + '' });
        tmp_sigungu_tag?.map((iii) => {
          tmp_tag_payload.push(iii);
        });
        setPayload({ ...payload, tags: tmp_tag_payload });
      }
    }
    if (complexTag && complexTag?.selectedAddress?.dong?.code) {
      let tmp_tag_arr = payload?.tags;
      if (complexTag?.selectedAddress?.dong1?.code == 'all') {
        tmp_tag_arr = tmp_tag_arr?.filter((ii) => ii?.key != `${complexTag?.selectedAddress?.sigungu?.code + ''}`);
        tmp_tag_arr.push({
          type: TagType.ADDRESS_TAG,
          name: complexTag?.selectedAddress?.sigungu?.name,
          key: `${complexTag?.selectedAddress?.sigungu?.code + ''}`,
        });
      } else {
        if (complexTag?.selectedComplex?.complex_idx) {
          if (complexTag?.selectedComplex?.complex_idx == 'all') {
            tmp_tag_arr = tmp_tag_arr?.filter((ii) => ii?.key != `${complexTag?.selectedAddress?.dong1?.code}`);
            tmp_tag_arr.push({
              type: TagType.ADDRESS_TAG,
              name: complexTag?.selectedAddress?.dong1?.name,
              key: `${complexTag?.selectedAddress?.dong1?.code}`,
            });
          } else {
            tmp_tag_arr = tmp_tag_arr?.filter((ii) => ii?.key != `${complexTag?.selectedComplex?.complex_idx}`);
            tmp_tag_arr.push({
              type: TagType.COMPLEX_TAG,
              name: complexTag?.selectedComplex?.complex_name,
              key: `${complexTag?.selectedComplex?.complex_idx}`,
            });
          }
        }
      }
      setPayload({ ...payload, tags: tmp_tag_arr });
    }
  }, [address, complexTag]);

  return;
};

export default useSetAddressByFilter;
