import { actions } from '@/data';
import { store } from '@/data/configureStore';
import { FeedType, TagType } from '@/services/Community';

import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import _padEnd from 'lodash-es/padEnd';
import { SQUARE_METER_UNIT } from '@/services/Community';

export const getCreatePath = (createPoint, pathname, postType) => {
  const _pathname = pathname || location.pathname;
  const _querystring = location.search ? `${location.search}&` : '?';

  return `${_pathname}${_querystring}createPoint=${createPoint}&postType=${postType}`;
};

/**
 * routeToCreate
 * @param {function} createPoint (required) CreatePoint에 정의된 feed 작성 시점
 * @param {string} pathname (optional) 경로 ex. /, /buying, /community
 * @returns
 */
export const routeToCreate = (createPoint, pathname, postType) => (dispatch) => {
  const { getState } = store;
  const state = getState();

  const user = state?.auth?.userSession;
  const url = getCreatePath(createPoint, pathname, postType);

  if (!user) {
    const option = {
      onSuccess: () => {
        if (location.search?.indexOf('createPoint') < 0) {
          dispatch(actions.router.push(pathname));
          dispatch(actions.router.push(url));
        }
      },
    };
    dispatch(actions.common.pleaseLoginPopup(true, option));
  } else if (location.search?.indexOf('createPoint') < 0) {
    dispatch(actions.router.push(url));
  }
};

export const routeToEdit = (feedId) => (dispatch) => {
  const { getState } = store;
  const state = getState();

  // const querystring = location.search ? `${location.search}&` : '?';
  const user = state?.auth?.userSession;

  const query = parseCallBack();
  delete query['feedDetail'];
  query['updateFeed'] = feedId;

  const queryString = objectToQuerystring(query);

  if (!user) {
    dispatch(actions.common.pleaseLoginPopup(true));
  } else if (location.search?.indexOf('updateFeed') < 0) {
    dispatch(actions.router.push(location.pathname + queryString));
  }
};

// 상세페이지 클릭했을때 작동되는 route function
export const routeToFeedDetail = (feedId, isNoTwoButton) => (dispatch) => {
  const { getState } = store;
  const state = getState();
  const user = state?.auth?.userSession;
  const key = 'feedDetail';
  const key2 = 'goCommunity';
  const querystring = parseCallBack();

  if (!user) {
    dispatch(actions.common.pleaseLoginPopup(true));
  } else if (typeof querystring?.[key] === 'undefined') {
    querystring[key] = feedId || 'true'; // (지역상세 -> 피드없는 매물은 true)
    if (isNoTwoButton) querystring[key2] = isNoTwoButton || '';
    dispatch(actions.router.push(`${location.pathname}${objectToQuerystring(querystring)}`));
  } else {
    querystring[key] = feedId;
    if (isNoTwoButton) querystring[key2] = isNoTwoButton;
    dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(querystring)}`));
  }
};

export const routeToReport = (type, id) => (dispatch) => {
  const { getState } = store;
  const state = getState();

  const querystring = location.search ? `${location.search}&` : '?';
  const user = state?.auth?.userSession;

  if (!user) {
    dispatch(actions.common.pleaseLoginPopup(true));
  } else if (location.search?.indexOf('feedReport') < 0) {
    dispatch(actions.router.push(`${location.pathname}${querystring}feedReport=${type}-${id}`));
  } else {
    dispatch(actions.router.replace(`${location.pathname}${querystring}feedReport=${type}-${id}`));
  }
};

export const routeToInterestArea = () => (dispatch) => {
  const { getState } = store;
  const state = getState();

  const querystring = location.search ? `${location.search}&` : '?';
  const user = state?.auth?.userSession;

  if (!user) {
    dispatch(actions.common.pleaseLoginPopup(true));
  } else if (location.search?.indexOf('setInterestArea') < 0) {
    dispatch(actions.router.push(`${location.pathname}${querystring}setInterestArea=1`));
  } else {
    dispatch(actions.router.replace(`${location.pathname}${querystring}setInterestArea=1`));
  }
};

export const showUserHistory = (target) => (dispatch) => {
  // if (target.type === 'BROKER' || target.type === 'BU') {
  //   window.open('/agent/' + target.id + '', '_blank');
  // } else {
  dispatch(actions.community.setUserHistoryTarget(target));
  // }
};

export const formatAMPM = (date) => {
  var now_today = new Date();

  var now_year = now_today.getFullYear();
  var now_month = now_today.getMonth() + 1;
  var now = now_today.getDate();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var today = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = '';
  if (now_year + '.' + now_month + '.' + now == year + '.' + month + '.' + today) {
    strTime = year + '.' + month + '.' + today + ' ' + hours + ':' + minutes + ' ' + ampm;
  } else {
    strTime = year + '.' + month + '.' + today;
  }
  return strTime;
};

export const translateToNumericForm = (min = '', max = '') => {
  return `${min}:${max}`;
};

export const addNumericTagsToTags = (tags = [], numericTags = {}) => {
  const tempTags = [...tags];
  const area = numericTags.area;
  const price = numericTags.price;

  if (area?.min || area?.max)
    tempTags.push({
      type: TagType.AREA_TAG,
      key: translateToNumericForm(area.min * SQUARE_METER_UNIT || undefined, area.max * SQUARE_METER_UNIT || undefined),
    });
  if (price?.min || price?.max) tempTags.push({ type: TagType.PRICE_TAG, key: translateToNumericForm(price.min, price.max) });

  return tempTags;
};

export const makeTagsToApiForm = ({ tags = [], addedTagsRef, numericTags = {} }) => {
  const _tags = tags.map((item) => ({ type: item.type, key: item.key }));
  const tags_types = tags.map((item) => item.type);

  //주소 태그를 제외한 태그들 (numericTag 포함)
  const temp_noAddressTags = [..._tags].filter((item) => item.type !== TagType.ADDRESS_TAG); //tags 에서 주소 태그 제거
  const temp_tags = addNumericTagsToTags(temp_noAddressTags, numericTags); //numeric tag 추가

  //추가 해시 태그
  const addedTags = (addedTagsRef.current || []).filter((item) => item !== '#');
  const temp_addedTags = addedTags.map((item) => ({ type: TagType.HASH_TAG, key: item.replace('#', '') }));

  const result = [...temp_tags, ...temp_addedTags]; // 주소 태그를 제외한 모든 태그들

  // 주소 태그 추가
  const addressTag = _tags[tags_types.indexOf(TagType.ADDRESS_TAG)];

  // * ADDRESS_TAG는 여러개가 될 수도 있다.
  // * key 값은 10 자리로 고정한다.
  if (addressTag) {
    result.push({ type: TagType.ADDRESS_TAG, key: _padEnd(addressTag.key, 10, '0') });
  }

  return result;
};

export const tagStyle = (feedType) => {
  if (feedType === 'POST_FOR_BUY') {
    return 'accent-01';
    // TODO: @커뮤니티테스트
    // } else if (feedType === 'COMPLEX_POST') {
  } else if (feedType === 'COMPLEX_POST' || feedType === FeedType.FREESTYLE_POST) {
    return 'accent-02';
    // TODO: @커뮤니티테스트
    // } else if (feedType === 'LOCAL_POST') {
  } else if (feedType === 'LOCAL_POST' || feedType === FeedType.INFO_POST) {
    return 'accent-02';
  } else if (feedType === 'QNA') {
    return 'accent-03';
  } else if (feedType === 'SYSTEM_POSTED_PRICE') {
    return 'normal-03';
  } else if (feedType === 'POST_FOR_SALE') {
    return 'normal-03';
  }
};
