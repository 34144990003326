import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import { actions, selectors } from '@/data';
import apis from '@/apis';
import { STATUS } from '@/data/utils';

export function* findId1({ cellphone }) {
  try {
    yield put(actions.auth.findId1Loading());
    if (cellphone) {
      const result = yield call(apis.authApi.findId1, cellphone);
      result.cellphone = cellphone;
      yield put(actions.auth.findId1Success(result));

      yield put(actions.common.alert({ contents: '문자로 전송된 인증번호를 확인해주세요.' }));
    } else {
      yield put(actions.auth.findId1Failure('휴대폰번호 없음'));
      yield put(actions.common.alert({ contents: '휴대폰번호를 입력해주세요' }));
    }
  } catch (e) {
    yield put(actions.common.alert({ contents: '휴대폰 인증에 실패하였습니다.' }));
    yield put(actions.auth.findId1Failure(e));
  }
}

export function* findId2({ authNum }) {
  try {
    yield put(actions.auth.findId2Loading());
    const step1Data = yield select(selectors.auth.getFindId1);
    const cellphone = step1Data?.cellphone;
    if (cellphone && authNum) {
      const result = yield apis.authApi.findId2(cellphone, authNum);
      yield put(actions.auth.findId2Success(result?.memlist));
    } else {
      yield put(actions.auth.findId2Failure('휴대폰번호 또는 인증번호 없음'));
      yield put(actions.common.alert({ contents: '유효하지 않은 인증입니다.' }));
    }
  } catch (e) {
    yield put(
      actions.common.alert({
        contents: (
          <>
            휴대폰 인증에 실패하였습니다.
            <br />
            {e.message}
          </>
        ),
      })
    );
    yield put(actions.auth.findId2Failure(e));
  }
}

export function* findPw({ email, options }) {
  const { setLoadingStatus, callback } = options || {};
  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    if (email) {
      yield put(actions.auth.findPwLoading());
      const result = yield call(apis.authApi.findPw1, email);
      yield put(actions.auth.findPwSuccess(result));
      yield put(
        actions.common.alert({
          contents: (
            <>
              <div className="board-head">
                <h2 className="board-subject">
                  <span className="board-name">이메일 발송완료</span>
                </h2>
              </div>
              <div className="board-body">
                <p className="para">비밀번호 재설정을 위해 이메일을 확인해주세요.</p>
              </div>
            </>
          ),
          onConfirm: () => {
            callback && callback();
          },
        })
      );
    } else {
      yield put(actions.auth.findPwFailure('이메일 없음'));
      yield put(actions.common.alert({ contents: '유효하지 않은 이메일입니다.' }));
    }
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    yield put(
      actions.common.alert({
        contents: (
          <>
            이메일 전송을 실패하였습니다.
            <br />
            {e.message}
          </>
        ),
      })
    );
    yield put(actions.auth.findPwFailure(e));
  }
}

export function* changePw({ newPw, setLoadingStatus, callback }) {
  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);

    const data = yield select(selectors.auth.getEmailConfirmed);

    if (data) {
      yield put(actions.auth.changePwLoading());
      const result = yield call(apis.authApi.changePw, { authNum: data.authNum, token: data.sToken, passwd: newPw });
      yield put(actions.auth.changePwSuccess(result));
      yield put(
        actions.common.alert({
          contents: (
            <>
              <div className="board-head">
                <h2 className="board-subject">
                  <span className="board-name">비밀번호 재설정 완료</span>
                </h2>
              </div>
              <div className="board-body">
                <p className="para">비밀번호 재설정이 완료되었습니다.</p>
              </div>
            </>
          ),
          confirmButtonText: '로그인',
          onConfirm: () => {
            callback && callback();
          },
        })
      );
      setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
    }
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);

    yield put(
      actions.common.alert({
        contents: (
          <>
            비밀번호 변경을 실패하였습니다
            <br />
            {e.message}
          </>
        ),
      })
    );
    yield put(actions.auth.changePwFailure(e));
  }
}
