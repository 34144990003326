import * as AT from './actionTypes';

export const getNewsales = (state) => state?.newsale[AT.GET_NEWSALES].getOrElse([]);
export const getNewsalePrice = (state) => state?.newsale?.newsalePrice;
export const getNewsaleSchedule = (state) => state?.newsale?.newsaleSchedule;
export const getRebuilds = (state) => state?.newsale[AT.GET_REBUILDS].getOrElse([]);
export const getRebuildSchedule = (state) => state?.newsale?.rebuildSchedule;
export const getLength = (state) => state?.newsale?.length;
export const getNews = (state) => state?.newsale?.news;
export const getMKLetterNews = (state) => state?.newsale?.mkNews;
export const getNewsSearchConditions = (state) => state?.newsale?.newsSearchConditions;
