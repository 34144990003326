import React from 'react';
import ReplyInput from '../../replyInput';

const ReplyContents = ({ isActiveComment, reply, comment, replies, setReplies, setActiveComment, deletedId, cancelUpdateComment }) => {
  return (
    <>
      {isActiveComment === reply?.id ? (
        <ReplyInput
          commentId={comment?.id}
          isUpdate={true}
          replies={replies}
          setReplies={setReplies}
          contents={reply.contents}
          setActiveComment={setActiveComment}
          replyId={reply?.id}
          cancelUpdateComment={cancelUpdateComment}
        />
      ) : (
        <div className="reply-body">
          <p className="para">
            <span className="wbr" style={{ overflowWrap: 'break-word' }}>
              {reply?.status === 'DELETED' || deletedId.indexOf(reply?.id) > -1 ? '삭제된 답글입니다.' : reply?.contents}
              {reply?.status === 'BLINDED' ? '부적절한 글로 가려진 댓글입니다.' : null}
            </span>
          </p>
        </div>
      )}
    </>
  );
};

export default ReplyContents;
