// Polyfill getSiblings
var getSiblings = function(e) {
  // for collecting siblings
  let siblings = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;
  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
};

function numberWithCommas(num) {
  var len, point, str;
  num = num + '';
  point = num.length % 3;
  len = num.length;

  str = num.substring(0, point);
  while (point < len) {
    if (str != '') str += ',';
    str += num.substring(point, point + 3);
    point += 3;
  }
  return str;
}

/**
 * buiTab
 */
(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define([], function() {
      return factory(root);
    });
  } else if (typeof exports === 'object') {
    module.exports = factory(root);
  } else {
    root.buiTab = factory(root);
  }
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function(window) {
  'use strict';

  // Variables
  var defaults = {
    // general
    mode: 'normal',
    active: false,
    activeClass: 'current',
    initial: 0,

    // tab
    tabItem: '.tab-item',
    tabName: '.tab-text',
    buttonActiveText: null,
    buttonAppendTo: null,
    tabItemReact: false,

    // target
    target: null,
    targetClass: 'bui-tab-target',
    targetActiveClass: 'active',
    container: '.tab-content',

    /* callback */
    onloadCallBack: function() {
      return false;
    },
    eventCallBack: function() {
      return false;
    },
    activeCallBack: function() {
      return false;
    },
    inactiveCallBack: function() {
      return false;
    },
  };

  /**
   * Merge two or more objects together.
   * @param	{Object}	objects		The objects to merge together
   * @returns	{Object}				Merged values of defaults and options
   */
  var extend = function() {
    var merged = {};
    Array.prototype.forEach.call(arguments, function(obj) {
      for (var key in obj) {
        if (!obj.hasOwnProperty(key)) return;
        merged[key] = obj[key];
      }
    });
    return merged;
  };

  /**
   * Create the Constructor object
   */
  var Constructor = function(selector, options) {
    // Merge user options with defaults
    settings = extend(defaults, options || {});

    // Variables
    var publicAPIs = {};
    var settings;

    publicAPIs.settings = settings;
    publicAPIs.myTab = {};

    /**
     * Setup the DOM with the proper attributes
     */
    publicAPIs.setup = function() {
      // Variables
      var tabItems = document.querySelectorAll(selector);
      if (!tabItems) return;

      tabItems.forEach(function(value) {
        value.querySelectorAll(settings.tabName).forEach(function(tabName, index) {
          // publicAPIs.myTab[value] = {
          // 	tabItem: tabItem,
          // 	tabName: tabItem.querySelector(settings.tabName),
          // 	tabTarget: document.querySelector(tabItem.querySelector(settings.tabName).hash),
          // }

          // console.log(tabName.hash);
          const tabTarget = document.querySelector(tabName.hash);
          if (!tabTarget) return;
          tabTarget.classList.add(settings.targetClass);

          tabName.addEventListener('click', function(e) {
            e.preventDefault();
            e.currentTarget.parentElement.parentElement.querySelectorAll(settings.tabItem).forEach(function(tabItem) {
              tabItem.classList.remove(settings.activeClass);
              const eventTarget = document.querySelector(tabItem.querySelector(settings.tabName).hash);
              if (!eventTarget) return;
              eventTarget.classList.remove(settings.targetActiveClass);
            });

            e.currentTarget.parentElement.classList.add(settings.activeClass);
            document.querySelector(e.currentTarget.hash).classList.add(settings.targetActiveClass);
          });
        });
      });
    };

    /**
     * Initialize the instance
     */
    var init = function() {
      // Setup the DOM
      publicAPIs.setup();
    };

    // Initialize and return the Public APIs
    init();
    return publicAPIs;
  };

  // Return the Constructor
  return Constructor;
});
// export default buiTab;
