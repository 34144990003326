import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import * as selectors from '@/data/rootSelectors';
import { useInfiniteScroll } from '../ListNew/hooks/';
import { parseCallBack } from '@/services/Helper';
import CommentInput from './components/CommentInput';
import CommentsLoading from './components/CommentsLoading';
import { STATUS } from '@/data/utils';
import FeedHeader from './components/FeedHeader';
import FeedArea from './components/FeedArea';
import CommentArea from './components/CommentArea';
import { postsCommentWrite, postsCommentItem, buiFormResize } from '@/resources/design/v2/js/bui.template.js';
import FeedLoading from './components/FeedLoading';
import { useCheckIsOwner, useGetComplexHistoryByOne } from '@/combinedComp/Community/hooks';
import { TagType } from '@/services/Community';

const Detail = () => {
  const dispatch = useDispatch();
  const feedData = useSelector(selectors.community.getFeed);
  const comments = useSelector(selectors.community.getComments);
  const detail_item = useSelector(selectors.community.getFeedDetailItem);
  const feedStatus = useSelector(selectors.community.setFeedStatus);
  const querystring = parseCallBack();
  const feedId = querystring['feedDetail'];
  const targetId = feedId || detail_item?.feedId;
  const complexId = querystring['d_complex'];
  const scrollDiv = useRef();
  const loading = useRef(false);
  const [commentCount, setCommentCount] = useState(feedData?.commentCount);

  const fetchMoreCommentsItems = () => {
    if (isFetching && comments?.next) {
      loading.current = true;
      dispatch(
        actions.community.getComments({ feedId: detail_item?.feedId ? detail_item.feedId : feedId, limit: 5, cursor: comments.next }, comments)
      );
      setIsFetching(false);
    } else {
      setIsFetching(false);
    }
  };
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreCommentsItems, scrollDiv.current);

  useEffect(() => {
    if (feedId !== 'true' && feedId !== 'undefined') {
      dispatch(actions.community.getFeed(targetId));
      dispatch(actions.community.getComments({ feedId: targetId, limit: 5 }));
    }
  }, [targetId]);

  useEffect(() => {
    setCommentCount(feedData?.commentCount);
  }, [feedData]);

  const [show, setShow] = useState(true);

  useEffect(() => {
    dispatch(actions.community.setFeedStatus(STATUS.LOADING));
    if (feedStatus === 'FAILURE' || feedId === 'undefined' || (!complexId && feedId === 'true')) {
      setShow(false);
      dispatch(
        actions.common.alert({
          contents: '존재하지않는 게시글입니다.',
          onConfirm: () => {
            dispatch(actions.router.push('/community'));
            setShow(true);
          },
        })
      );
      dispatch(actions.community.setFeedStatus(STATUS.NOTASKED));
    }
  }, [feedStatus, feedId]);

  const isFeedDeleted = feedData?.status === 'DELETED';

  // 집주인 인증 api콜 하나 단위
  const userId = feedData?.writer?.id;
  const hasComplexId = feedData?.tags?.some((tag) => tag.type == TagType.COMPLEX_TAG);
  const userOnlyFeeds = feedData?.writer?.type === 'CUSTOMER';
  const isReady = hasComplexId && userOnlyFeeds && feedData;

  useGetComplexHistoryByOne({ userId, isReady });

  // 집주인 인지 아닌지 판단
  const complexTag = feedData?.tags?.filter((tag) => tag?.type === TagType.COMPLEX_TAG);
  const complexIdx = complexTag?.[0]?.key;
  const writerId = feedData?.writer?.id;
  const isOwner = useCheckIsOwner({ complexId: complexIdx, writerId });

  return show ? (
    <div className="front-popup module-a style-a type-a large active" id="postsRead" data-bui-toggle="frontPopup">
      <div className="popup-page-body">
        <div className="popup-local">
          <FeedHeader feedData={feedData || detail_item} />
          <div className="popup-local-body">
            <div className="popup-content" ref={scrollDiv}>
              <div className="popup-content-body">
                <div className="posts-read module-a style-a type-a">
                  <div className="posts-list">
                    <div className="posts-item">
                      {feedData || detail_item ? (
                        <>
                          <FeedArea feedData={feedData} commentCount={commentCount} detail_item={detail_item} isOwner={isOwner} />
                          <div className="posts-side">
                            <div className="comment-display">
                              {!isFeedDeleted && <CommentInput feedData={feedData} commentCount={commentCount} setCommentCount={setCommentCount} />}
                              <div className="comment-list">
                                <CommentArea feedData={feedData} commentCount={commentCount} setCommentCount={setCommentCount} />
                              </div>
                              <CommentsLoading />
                            </div>
                          </div>
                        </>
                      ) : (
                        <FeedLoading />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Detail;
