import React from 'react';
import { MODE } from '../../index.js';
import Search from '@/components/Search';
import AddressSelector from '@/components/Address';
import ComplexSelector from './ComplexSelector';

const View1 = ({
  title,
  mode,
  disabled,
  changeMode,
  toSelect,
  toText,
  onSearchComplexSelected,
  markTypes,
  markRule,
  isMobile,
  searchInputPlaceholder,
  complexData,
  tags,
  setBrokerNumber,
  feedType,
  addressData,
  setAddressData,
  addAllOption,
  tenDigitCode,
  dongCode,
  setComplexData,
  preventUpdateComplexData,
  brokerNumber,
}) => {
  return (
    <>
      <div className="filter_block">
        <div className="filter_title">{title}</div>
        <div className="filter_content">
          {!disabled && (
            <a onClick={changeMode} className="btn btn-small btn-schaddr" style={{ zIndex: 1 }}>
              {mode === MODE.TEXT ? toSelect : toText}
            </a>
          )}
          <div className="search_box">
            {mode === MODE.TEXT ? (
              <>
                <Search
                  onSelected={onSearchComplexSelected}
                  totalSearch={true}
                  markTypes={markTypes}
                  markRule={markRule}
                  isMobile={isMobile}
                  placeholder={searchInputPlaceholder || '단지나 아파트명을 검색하세요.'}
                  showSearchHistory={false}
                  defaultInput={complexData?.complex_name}
                  tags={tags}
                  setBrokerNumber={setBrokerNumber}
                  feedType={feedType}
                />
              </>
            ) : (
              <AddressSelector
                data={addressData}
                setData={setAddressData}
                addAllOptionOnDong={addAllOption}
                disabled={disabled}
                tenDigitCode={tenDigitCode}
              />
            )}
          </div>
        </div>
      </div>
      {mode === MODE.SELECT && (
        <div className="filter_block">
          <div className="filter_title">단지검색</div>
          <div className="filter_content">
            <ComplexSelector
              selectedIdx={`${complexData?.complex_idx}`}
              dongCode={dongCode}
              setData={setComplexData}
              markTypes={markTypes}
              addAllOption={addAllOption}
              preventUpdateComplexData={preventUpdateComplexData}
              disabled={disabled}
              addressData={addressData}
              brokerNumber={brokerNumber}
              setBrokerNumber={setBrokerNumber}
              feedType={feedType}
            />
          </div>
        </div>
      )}

      {/* {brokerNumber?.result?.length === 0 && feedType === FeedType.POST_FOR_BUY && (
      <div className="add_text">
        *해당 단지/지역에는 활동 중인 <b>중개사가 없습니다.</b>
        <br />
        그렇지만 인근 중개사나 다른 사용자들과 구하는 집에 대해 이야기를 나눌 수 있습니다.
      </div>
    )} */}
    </>
  );
};

export default View1;
