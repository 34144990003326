import toggle from '@/hocs/toggle';
import React from 'react';

const Header = ({ title }) => {
  return (
    <div className="popup-local-head">
      <h2 className="popup-local-subject">
        <span className="popup-local-name">{title}</span>
      </h2>
    </div>
  );
};

export default toggle(Header);
