import { getDeviceIdOrGenerate, getSessionIdOrGenerate } from '@/services/Device';
import { getMapCookie, getTokenCookie, getUserCookie } from '@/data/cookie';
import { getFormattedUserInfo } from '../auth';

export default async function loadApp() {
  const cookieUserInfo = await getUserCookie();
  const userInfo = getFormattedUserInfo(cookieUserInfo);
  const accessToken = await getTokenCookie();
  const deviceId = await getDeviceIdOrGenerate();
  const sessionId = await getSessionIdOrGenerate();
  const mapData = await getMapCookie();

  return { userInfo, accessToken, deviceId, sessionId, mapData };
}
