import { STEPS } from '@/pages/Login/BasicForm/helpers';
import React, { useEffect } from 'react';
import { USER_TYPES } from '../../constants';

const useSetUserType = ({ step, setUserType }) => {
  useEffect(() => {
    if (step === STEPS.BROKER) {
      setUserType(USER_TYPES.REALTOR);
    }
  }, [step]);

  return <div></div>;
};

export default useSetUserType;
