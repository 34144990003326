import realtyTypes from '@/services/goods/realtyTypesExpanded';
import roomTypes from '@/services/goods/roomTypes';

const { codes: realtyCodes, names: realtyNames } = realtyTypes;
const { codes: roomCodes } = roomTypes;

export const REALTY_TYPE_OPTIONS = [
  { key: ['all'], name: '전체' },
  { key: [realtyCodes.apt], name: realtyNames[realtyCodes.apt] },
  { key: [realtyCodes.oft], name: realtyNames[realtyCodes.oft] },
  { key: [realtyCodes.vll, realtyCodes.dsd, realtyCodes.yun], name: realtyNames[realtyCodes.vll] },
  { key: [realtyCodes.dan], name: realtyNames[realtyCodes.dan] },
  { key: [realtyCodes.dgg], name: realtyNames[realtyCodes.dgg] },
  { key: [roomCodes.ope, roomCodes.div, roomCodes.hal, roomCodes.two], name: realtyNames[realtyCodes.one] },
  { key: [realtyCodes.sto], name: realtyNames[realtyCodes.sto] },
  { key: [realtyCodes.off], name: realtyNames[realtyCodes.off] },
];
