import { OSTYPE } from '../types/OsType';

/**
 * 웹인 경우 새창에서 열기
 * 앱인 경우 외부 브라우저에서 열기 (ex. 크롬 브라우저)
 *
 * @param {string} url
 */
const openExternalWindow = async (url, options = {}) => {
  const { target = '_blank', features = 'noopener noreferrer' } = options;

  const ostype = window.dawin_instance?.osType;
  const isApp = ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS;

  if (isApp) {
    window.dawin_instance.bridge.osFunction.executeExternalBrowser(url);
  } else {
    window.open(url, target, features);
  }
};

export default openExternalWindow;
