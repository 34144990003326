import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';
import { STEPS } from '@/pages/Login/BasicForm/helpers';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { LOGIN_METHODS } from '@/pages/Login/views/constants';

const AgentUserView = () => {
  const [loginMethod, setLoginMethod] = useState(LOGIN_METHODS.ID);

  const step = useSelector(selectors.auth.getLoginStep);

  useEffect(() => {
    if (step === STEPS.BROKER) {
      setLoginMethod(LOGIN_METHODS.ID);
    }
  }, [step]);

  const dispatch = useDispatch();

  const changeLoginMethod = (e) => {
    const { name } = e.target;
    setLoginMethod(name);

    if (name === 'id') {
      dispatch(actions.auth.setLoginStep(STEPS.BROKER));
    } else {
      // dispatch(actions.auth.setLoginStep(STEPS.BROKER_PHONE));
      dispatch(actions.auth.setLoginStep(STEPS.PHONE1));
    }
  };

  return (
    <div className="tab-display module-a style-a type-c small-1x" data-bui-tab="contentTab">
      <div className="tab-list">
        <span className={`tab-item ${loginMethod === LOGIN_METHODS.ID && 'current'}`}>
          <a className="tab-text" href="#searchComplex" name={LOGIN_METHODS.ID} onClick={changeLoginMethod}>
            아이디(이메일) 로그인
          </a>
        </span>
        <span className={`tab-item ${loginMethod === LOGIN_METHODS.PHONE && 'current'}`}>
          <a className="tab-text" href="#searchAddress" name={LOGIN_METHODS.PHONE} onClick={changeLoginMethod}>
            전화번호 로그인
          </a>
        </span>
      </div>
    </div>
  );
};

export default AgentUserView;
