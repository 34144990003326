import { failure, loading, success } from '@/data/utils';

export const CREATE_GOODS = 'SELLING/CREATE_GOODS';
export const CREATE_REREGISTER_GOODS = 'SELLING/CREATE_REREGISTER_GOODS';
export const UPDATE_GOODS = 'SELLING/UPDATE_GOODS';
export const GET_GOODS = 'SELLING/GET_GOODS';
export const GET_GOODS_IMAGES = 'SELLING/GET_GOODS_IMAGES';
export const GET_GOODS_MEMO = 'SELLING/GET_GOODS_MEMO';
export const UPDATE_GOODS_STATUS = 'SELLING/UPDATE_GOODS_STATUS';
export const UPDATE_GOODS_REGISTERED_ID = 'SELLING/UPDATE_GOODS_REGISTERED_ID';
export const GET_BROKERS_BY_GOODS_POSITION = 'SELLING/GET_BROKERS_BY_GOODS_POSITION';
export const GET_BROKERS_BY_GOODS = 'SELLING/GET_BROKERS_BY_GOODS';
export const UPDATE_BROKERS_BY_GOODS = 'SELLING/UPDATE_BROKERS_BY_GOODS';
export const DELETE_BROKERS_BY_GOODS = 'SELLING/DELETE_BROKERS_BY_GOODS';
export const GET_OPTIONS_BY_TYPE = 'SELLING/GET_OPTIONS_BY_TYPE';
export const GET_GOODS_LIST_BY_COMPLEX = 'SELLING/GET_GOODS_LIST_BY_COMPLEX';
export const GET_GOODS_LIST_BY_ADDRESS = 'SELLING/GET_GOODS_LIST_BY_ADDRESS';

// ===================== 요청 : 전화로 집내놓기 ===================== //
export const SELL_CALL_REQUEST = 'SELLING/SELL_CALL_REQUEST';
export const SELL_CALL_REQUEST_SUCCESS = success(SELL_CALL_REQUEST);
export const SELL_CALL_REQUEST_FAILURE = failure(SELL_CALL_REQUEST);
export const SELL_CALL_REQUEST_LOADING = loading(SELL_CALL_REQUEST);

// ===================== 요청 : 직접 집내놓기 ===================== //
export const SELL_MANUAL_GET_STEP_DATA = 'SELLING/SELL_MANUAL_GET_STEP_DATA';
export const SELL_MANUAL_SET_SELLER_TYPE = 'SELLING/SELL_MANUAL_SET_SELLER_TYPE';
export const SELL_MANUAL_STORE_SAVED_FORM = 'SELLING/SELL_MANUAL_STORE_SAVED_FORM';
export const SELL_MANUAL_UPDATE_SAVED_FORM = 'SELLING/SELL_MANUAL_UPDATE_SAVED_FORM';
export const SELL_MANUAL_CLEAR_SAVED_FORM = 'SELLING/SELL_MANUAL_CLEAR_SAVED_FORM';

export const SELL_MANUAL_GET_STEP_DATA_SUCCESS = success(SELL_MANUAL_GET_STEP_DATA);
export const SELL_MANUAL_GET_STEP_DATA_FAILURE = failure(SELL_MANUAL_GET_STEP_DATA);
export const SELL_MANUAL_GET_STEP_DATA_LOADING = loading(SELL_MANUAL_GET_STEP_DATA);

export const SELL_MANUAL_HANDLE_NEXT_STEP = 'SELLING/SELL_MANUAL_HANDLE_NEXT_STEP';
export const SELL_MANUAL_HANDLE_NEXT_STEP_SUCCESS = success(SELL_MANUAL_HANDLE_NEXT_STEP);
export const SELL_MANUAL_HANDLE_NEXT_STEP_FAILURE = failure(SELL_MANUAL_HANDLE_NEXT_STEP);
export const SELL_MANUAL_HANDLE_NEXT_STEP_LOADING = loading(SELL_MANUAL_HANDLE_NEXT_STEP);

// 오류 =====================
export const SELL_REQUEST_ERROR = 'SELLING/SELL_REQUEST_ERROR';
export const SELL_RESET_ERROR_STATE = 'SELLING/SELL_RESET_ERROR_STATE';

// ===================== itemInfo 변경 ===================== //

// 매물수정하기 페이지로 넘어갈 때, 매물 정보를 받아와서 한 번에 매물 정보 입력시킬 때 사용
export const CHANGE_ITEM_INFO = 'SELLING/CHANGE_ITEM_INFO';

//직접 집내놓기 : 초기화 후  step1
export const INIT_AND_GO_FIRST_STEP = 'INIT_AND_GO_FIRST_STEP';

export const GET_ADDR_SiDo = 'SELLING/GET_ADDR_SiDo';
export const SET_ADDR_SiDo = 'SELLING/SET_ADDR_SiDo';
export const CHANGE_SiDoCode = 'SELLING/CHANGE_SiDoCode';

export const GET_ADDR_SiGunGu = 'SELLING/GET_ADDR_SiGunGu';
export const SET_ADDR_SiGunGu = 'SELLING/SET_ADDR_SiGunGu';
export const CHANGE_SiGunGuCode = 'SELLING/CHANGE_SiGunGuCode';

export const GET_ADDR_DongRi = 'SELLING/GET_ADDR_DongRi';
export const SET_ADDR_DongRi = 'SELLING/SET_ADDR_DongRi';
export const CHANGE_DongRiCode = 'SELLING/CHANGE_DongRiCode';

// APT, OFT 공용
export const GET_COMPLEX_LIST = 'SELLING/GET_COMPLEX_LIST';
export const GET_COMPLEX_LIST_SUCCESS = success('SELLING/GET_COMPLEX_LIST');
export const GET_COMPLEX_LIST_FAILURE = failure('SELLING/GET_COMPLEX_LIST');
export const GET_COMPLEX_LIST_LOADING = loading('SELLING/GET_COMPLEX_LIST');

// APT, OFT 공용
export const CHANGE_COMPLEX_IDX = 'SELLING/CHANGE_COMPLEX_IDX';

// APT, OFT 공용
export const GET_COMPLEX_LOCATION = 'SELLING/GET_COMPLEX_LOCATION';
export const GET_COMPLEX_LOCATION2 = 'SELLING/GET_COMPLEX_LOCATION2';
export const GET_COMPLEX_LOCATION_SUCCESS = success('SELLING/GET_COMPLEX_LOCATION');
export const GET_COMPLEX_LOCATION_FAILURE = failure('SELLING/GET_COMPLEX_LOCATION');
export const GET_COMPLEX_LOCATION_LOADING = loading('SELLING/GET_COMPLEX_LOCATION');

// 동 목록 받아오기
export const GET_COMPLEX_DONG_LIST = 'SELLING/GET_COMPLEX_DONG_LIST';
export const GET_COMPLEX_DONG_LIST2 = 'SELLING/GET_COMPLEX_DONG_LIST2';
export const GET_COMPLEX_DONG_LIST_SUCCESS = success('SELLING/GET_COMPLEX_DONG_LIST');
export const GET_COMPLEX_DONG_LIST_FAILURE = failure('SELLING/GET_COMPLEX_DONG_LIST');
export const GET_COMPLEX_DONG_LIST_LOADING = loading('SELLING/GET_COMPLEX_DONG_LIST');

export const GET_FLOOR_LIST = 'SELLING/GET_FLOOR_LIST';
export const GET_FLOOR_LIST2 = 'SELLING/GET_FLOOR_LIST2';
export const SET_FLOOR_LIST = 'SELLING/SET_FLOOR_LIST';
export const CHANGE_FLOOR_NO = 'SELLING/CHANGE_FLOOR_NO';

export const GET_HO_LIST = 'SELLING/GET_HO_LIST';
export const GET_HO_LIST2 = 'SELLING/GET_HO_LIST2';
export const SET_HO_LIST = 'SELLING/SET_HO_LIST';

export const CHANGE_ITEM_SIZE = 'SELLING/CHANGE_ITEM_SIZE';
// ===================== 직접 집내놓기 Step 3 ===================== //
// uploadImage dir
// 여기서 이미지 등록한다고 해서 바로 서버에 등록하는건 아님, 사용자가 끝까지 작성을 마치고 요청을 보내야 서버 이미지 업로드
// export const ADD_IMAGE_ITEM = 'SELLING/ADD_IMAGE_ITEM';
// export const DELETE_IMAGE_ITEM = 'SELLING/DELETE_IMAGE_ITEM';
export const SAVE_IMAGE = 'SELLING/SAVE_IMAGE';
export const SAVE_IMAGE2 = 'SELLING/SAVE_IMAGE2';
export const SAVE_IMAGE_SUCCESS = 'SELLING/SAVE_IMAGE_SUCCESS';
export const DELETE_IMAGE = 'SELLING/DELETE_IMAGE';
export const DELETE_IMAGE_SUCCESS = 'SELLING/DELETE_IMAGE_SUCCESS';

// ===================== 직접 집내놓기 Step 4 ===================== //
export const GET_NEARBY_AGENTS = 'SELLING/GET_NEARBY_AGENTS';
export const GET_NEARBY_AGENTS_SUCCESS = success(GET_NEARBY_AGENTS);
export const GET_NEARBY_AGENTS_FAILURE = failure(GET_NEARBY_AGENTS);
export const GET_NEARBY_AGENTS_LOADING = loading(GET_NEARBY_AGENTS);

export const CHANGE_SELECTED_AGENTS = 'SELLING/CHANGE_SELECTED_AGENTS';

export const SET_SELECTED_AGENTS = 'SET_SELECTED_AGENTS';

export const CHANGE_FOCUSED_AGENT_INDEX = 'SELLING/CHANGE_FOCUSED_AGENT_INDEX';

export const CHANGE_MAP_CENTER = 'SELLING/CHANGE_MAP_CENTER';

// ===================== 직접 집내놓기 Step 5 (마지막, 매물 등록 보내는 부분) ===================== //

// 아래 내용 사용하지 않고, uploadImage 라는 공통으로 사용 가능한 dir 로 옮김
// Step 3 에서 사용자가 이미지 등록하긴 하지만, 마지막 단계까지 마쳐야 서버에 요청 ㄱㄱ
// imageItemBlobs 에 있는 이미지 하나하나마다 action 을 호출할거라서 복수가 아닌 단수로 진행.
// export const SELL_UPLOAD_ITEM_IMAGE_GET_URL = 'SELLING/SELL_UPLOAD_ITEM_IMAGE_GET_URL'; // 이미지가 올라갈 url 받아오고
// export const SELL_UPLOAD_ITEM_IMAGE_PUT_URL = 'SELLING/SELL_UPLOAD_ITEM_IMAGE_PUT_URL'; // 받아온 url 에 이미지 등록

export const POST_SAVED_FORM = 'POST_SAVED_FORM_REQUEST';
export const POST_SAVED_FORM_FAILURE = failure(POST_SAVED_FORM);
export const POST_SAVED_FORM_SUCCESS = success(POST_SAVED_FORM);
export const POST_SAVED_FORM_LOADING = loading(POST_SAVED_FORM);

// db에 저장한 마지막 매물 정보 - 임시저장, 새로고침, 뒤로가기 등을 할 때 비교하기 위함
export const UPDATE_LAST_SAVED_FORM = 'UPDATE_LAST_SAVED_FORM';
