import * as AT from './actionTypes';

export const getAttach = (state) => state?.brokerPage?.jointBrokerage?.new?.attach;
export const getFilename = (state) => state?.brokerPage?.jointBrokerage?.new?.file_name;
export const getNew = (state) => state?.brokerPage?.jointBrokerage?.new;
export const getEdit = (state) => state?.brokerPage?.jointBrokerage?.edit;
export const getDel = (state) => state?.brokerPage?.jointBrokerage?.del;
export const getKeyword = (state) => state?.brokerPage?.jointBrokerage?.keyword;
export const getPage = (state) => state?.brokerPage?.jointBrokerage?.page;
export const getTotalCnt = (state) => state?.brokerPage?.jointBrokerage?.totalCnt;
export const getList = (state) => state?.brokerPage?.jointBrokerage?.conlist;
export const getDetail = (state) => state?.brokerPage?.jointBrokerage?.detail;
export const getDetailIdx = (state) => state?.brokerPage?.jointBrokerage?.detailIdx;
export const getPreIdx = (state) => state?.brokerPage?.jointBrokerage?.detail?.pre_idx;
export const getPreTitle = (state) => state?.brokerPage?.jointBrokerage?.detail?.pre_title;
export const getNextIdx = (state) => state?.brokerPage?.jointBrokerage?.detail?.next_idx;
export const getNextTitle = (state) => state?.brokerPage?.jointBrokerage?.detail?.next_title;
