import React from 'react';
import MoreReplyBtn from './components/moreReplyBtn';
import Reply from './components/Reply';
import ShowReplyInput from './components/showReplyInput';

const ReplyArea = ({
  isMore,
  showReply,
  replies,
  comment,
  isActiveComment,
  setReplies,
  setActiveComment,
  deletedId,
  cancelUpdateComment,
  onUpdateComment,
  onDeleteComment,
  status,
  reply,
  setReply,
  showReplies,
  onReply,
  contentRef,
  addReplyCount,
  isMoreReplies,
  onMoreReplies,
  feedData,
  isFeedDeleted,
}) => {
  return (
    <div className="comment-side">
      <div className="reply-display">
        {!isFeedDeleted && (
          <ShowReplyInput
            reply={reply}
            setReplies={setReplies}
            replies={replies}
            comment={comment}
            setReply={setReply}
            showReplies={showReplies}
            onReply={onReply}
            contentRef={contentRef}
            addReplyCount={addReplyCount}
            cancelUpdateComment={cancelUpdateComment}
          />
        )}
        <Reply
          comment={comment}
          isMore={isMore}
          isActiveComment={isActiveComment}
          replies={replies}
          setReplies={setReplies}
          setActiveComment={setActiveComment}
          deletedId={deletedId}
          cancelUpdateComment={cancelUpdateComment}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
          showReply={showReply}
          status={status}
          feedData={feedData}
          isFeedDeleted={isFeedDeleted}
        />
        {/* 대댓글 10개 넘으면 보이는 더보기 옵션 */}
        <MoreReplyBtn isMoreReplies={isMoreReplies} onMoreReplies={onMoreReplies} />
      </div>
    </div>
  );
};

export default ReplyArea;
