import React from 'react';
import { AGENTSIGNUP } from '@/resources/text/common';

export const text = {
  AGENT_SIGNUP: (
    <>
      중개사 <br /> 회원가입
    </>
  ),
  AGENT_SIGNUP_ERROR: `중개사 회원가입 중 오류가 발생했습니다.`,
  AGENT_SIGNUP_DONE: `중개사 회원가입이 완료되었습니다.`,
  RECHECK_EMAIL_VAL: '이메일 형식을 확인해주세요.',
  EMAIL_CHECKED: '사용하실 수 있는 아이디 입니다.',
  EMAIL_CHECKED_FAIL: (
    <>
      기존에 등록된 아이디입니다.
      <br /> 다른 아이디를 입력해 주세요.
    </>
  ),
  // ================================ Main ================================ //
  MAIN_SECTION: [
    {
      CLASSNUM: `01`,
      TIT: (
        <>
          반값 수수료가 손해?
          <br />
          온라인 부동산에서, 매물과 고객을 남들보다 먼저 만나보세요!
        </>
      ),
      DEC: (
        <>
          <b>온라인 부동산 플랫폼 다윈중개는 손님과 매물을 모아드립니다.</b>
          <br />
          다윈중개사로 활동하시면
          <br />
          거주/소유중인 이용자가 직접 등록한 15,000개 이상의 진성 매물,
          <br />
          그리고 20만명 이상의 다윈중개 회원을 잠재고객으로 만날 수 있습니다.
          <br />
          <br />
          <button className="btn btn-basic btn-xsmall">전화문의 1544-6075</button>
        </>
      ),
    },
    {
      CLASSNUM: `02`,
      TIT: (
        <>
          효과가 있을지 걱정되신다고요?
          <br />
          다윈 거래 vs 기존 영업 직접 비교해보세요.
        </>
      ),
      DEC: (
        <>
          <strong>다윈중개는 열린 플랫폼입니다.</strong>
          <br />
          기존의 방식으로 확보한 매물에 대해서는 동일하게 중개수수료를 받으시면 됩니다.
          <br />
          다윈을 통해 만난 고객에게만 다윈 수수료 혜택이 적용됩니다.
        </>
      ),
    },
    {
      CLASSNUM: `10`,
      TIT: <>매수인과 임차인 고객도 매월 늘어나고 있습니다</>,
      DEC: (
        <>
          <b>다윈중개만의 빅데이터 분석</b>
          <br />
          매물 가진 회원 뿐만 아니라, 맞춤형 매물을 찾는 회원까지
          <br />
          12만명의 잠재 손님들이 모여있습니다.
          <br />
          <br />
          <span className="sc_dec_blue">
            <strong>[구해줘다윈]</strong>#왕십리 근처 #5억대 전세 #3월 말까지 입주 희망
          </span>
          <br />
          관심 지역과 조건의 매물을 찾는 고객님들을 바로 만날 수 있어요.
        </>
      ),
    },
    {
      CLASSNUM: `04`,
      TIT: '얻어걸린 고객 No!',
      DEC: (
        <>
          사무실에 앉아 고객이 오기만을 기다리시나요?
          <br />
          광고 효과가 깜깜이인 네이버, 직방 광고에 의존하고 계신가요?
          <br />
          이제는 성실하고 책임있는 중개 실적만으로 고객들에게 이름을 알려보세요.
          <br />
          <b>고객으로부터 인정받은 진짜 중개사들이 최고가 되는 시스템</b>
          <br />
          스타 중개사의 꿈을 이루어드립니다.
        </>
      ),
    },
    {
      CLASSNUM: `05`,
      TIT: (
        <>
          매출로 이어지는 손님,
          <br />
          사무실에서 기다리지 말고, 직접 찾아보세요
        </>
      ),
      DEC: (
        <>
          목 좋은 사무실 구하기가 고객 만나는 지름길?
          <br />
          하루종일 사무실에서 대기하던 시대는 이제 No
          <br />
          사무실 안에서건, 밖에서건
          <br />
          <b>컴퓨터와 스마트폰으로 시간/공간 제약 없이도</b> 거래할 손님을 만나고 소통할 수 있습니다.
        </>
      ),
    },
    {
      CLASSNUM: `06`,
      TIT: <></>,
      DEC: (
        <p className="m_exp">
          * 다윈중개가 제공하는 매물에 대해서만 매도인 중개수수료 무료입니다.
          <br />
          개인적으로 확보한 매물에 대해서는 기존과 동일하게
          <br />
          중개수수료를 받으시면 됩니다.
        </p>
      ),
    },

    {
      CLASSNUM: `07`,
      TIT: <></>,
      DEC: (
        <>
          <span>중개수수료를 조금만 양보하면 공인중개사를 바라보는 사회적 시선이 달라집니다.</span>
          <span>다윈중개는 우리 공인중개사님들이 사회적으로 존중받는 전문가가 되길 응원합니다.</span>
          <span>다윈중개의 대표부터 주요 임원들은 모두 공인중개사 자격증을 가지고 있습니다.</span>
        </>
      ),
    },
  ],
  MAIN_MOBILE_SECTION: [
    {
      CLASSNUM: `01`,
      TIT: (
        <>
          반값 수수료가 손해?
          <br />
          온라인 부동산에서,
          <br />
          매물과 고객을 남들보다 먼저 만나보세요!
        </>
      ),
      DEC: (
        <>
          <b>온라인 부동산 플랫폼 다윈중개는 손님과 매물을 모아드립니다.</b>
          <br />
          다윈중개사로 활동하시면
          <br />
          거주/소유중인 이용자가 직접 등록한 15,000개 이상의 진성 매물,
          <br />
          그리고 20만명 이상의 다윈중개 회원을 잠재고객으로 만날 수 있습니다.
          <br />
          <br />
          <button className="btn btn-basic btn-xsmall">전화문의 1544-6075</button>
        </>
      ),
    },
    {
      CLASSNUM: `02`,
      TIT: (
        <>
          효과가 있을지 걱정되신다고요?
          <br />
          다윈 거래 vs 기존 영업
          <br />
          직접 비교해보세요.
        </>
      ),
      DEC: (
        <>
          <strong>다윈중개는 열린 플랫폼입니다.</strong>
          <br />
          기존의 방식으로 확보한 매물에 대해서는
          <br />
          동일하게 중개수수료를 받으시면 됩니다.
          <br />
          <br />
          다윈을 통해 만난 고객에게만
          <br />
          다윈 수수료 혜택이 적용됩니다.
        </>
      ),
    },
    {
      CLASSNUM: 10,
      TIT: (
        <>
          매수인과 임차인 고객도
          <br />
          매월 늘어나고 있습니다
        </>
      ),
      DEC: (
        <>
          <b>다윈중개만의 빅데이터 분석</b>
          <br />
          매물 가진 회원 뿐만 아니라,
          <br />
          맞춤형 매물을 찾는 회원까지
          <br />
          12만명의 잠재 손님들이 모여있습니다.
          <br />
          <br />
          <span className="sc_dec_blue">
            <strong>[구해줘다윈]</strong>#왕십리 근처 #5억대 전세 #3월 말까지 입주 희망
          </span>
          <br />
          관심 지역과 조건의 매물을 찾는 고객님들을
          <br />
          바로 만날 수 있어요.
        </>
      ),
    },
    {
      CLASSNUM: `04`,
      TIT: '얻어걸린 고객 No!',
      DEC: (
        <>
          사무실에 앉아 고객이 오기만을 기다리시나요?
          <br />
          광고 효과가 깜깜이인 네이버, 직방 광고에
          <br />
          의존하고 계신가요?
          <br />
          이제는 성실하고 책임있는 중개 실적만으로
          <br />
          고객들에게 이름을 알려보세요.
          <br />
          <br />
          <b>고객으로부터 인정받은</b>
          <br />
          <b>진짜 중개사들이 최고가 되는 시스템</b>
          <br />
          스타 중개사의 꿈을 이루어드립니다.
        </>
      ),
    },
    {
      CLASSNUM: `05`,
      TIT: (
        <>
          매출로 이어지는 손님,
          <br />
          사무실에서 기다리지 말고,
          <br />
          직접 찾아보세요
        </>
      ),
      DEC: (
        <>
          목 좋은 사무실 구하기가 고객 만나는 지름길?
          <br />
          하루종일 사무실에서 대기하던 시대는 이제 No
          <br />
          사무실 안에서건, 밖에서건
          <br />
          <b>컴퓨터와 스마트폰으로 시간/공간 제약 없이도</b>
          <br />
          거래할 손님을 만나고 소통할 수 있습니다.
        </>
      ),
    },
    {
      CLASSNUM: `06`,
      TIT: <></>,
      DEC: (
        <p className="m_exp">
          * 다윈중개가 제공하는 매물에 대해서만 <br />
          매도인 중개수수료 무료입니다.
          <br />
          개인적으로 확보한 매물에 대해서는 기존과 동일하게
          <br />
          중개수수료를 받으시면 됩니다.
        </p>
      ),
    },

    {
      CLASSNUM: `07`,
      TIT: <></>,
      DEC: (
        <>
          <span>중개수수료를 조금만 양보하면 공인중개사를 바라보는 사회적 시선이 달라집니다.</span>
          <span>다윈중개는 우리 공인중개사님들이 사회적으로 존중받는 전문가가 되길 응원합니다.</span>
          <span>다윈중개의 대표부터 주요 임원들은 모두 공인중개사 자격증을 가지고 있습니다.</span>
        </>
      ),
    },
  ],
  MAIN_SECTION_BUTTON: "'월 30만원으로 창업하기'",
  MAIN_SECTION_BUTTON_SUB: '부동산 중개전문 합동사무실',
  // ================================ Step 1 ================================ //
  MAIN_ALERT: AGENTSIGNUP.main.notice,
  // ================================ Step 2 ================================ //

  STEP2_FEE_AGREE: '중개수수료 금액에 동의합니다.',
  STEP2_FEE_TABLE_BUTTON: '다윈중개 중개보수요율표 보기',

  // ================================ Step 3 ================================ //
  OFFICE_LIST_ERROR: `중개사무소 목록을 불러오는 중 오류가 발생했습니다.`,
  NO_OFFICE_LIST: (
    <>
      검색 결과가 없습니다. <br />
      최근 개설등록한 중개사무소의 경우 2-3일 후 다시 회원가입을 시도해주세요.
      <br />
      오류가 지속되는 경우 다윈중개 고객센터(1544-6075)로 연락 주시기 바랍니다.
    </>
  ),
  OFFICE_CORP_NOTICE: '* 법인의 경우 분사무소는 별도로 회원가입을 해야합니다.',
  MAP_ERROR: '지도 생성 중 오류가 발생했습니다',

  // ================================ Step 4 ================================ //
  STEP4_UPPER_NOTICE: (
    <>
      안전하고 신뢰성 있는 중개를 위해 '대표 개업공인중개사'에 한해 회원가입이 가능합니다. 중개사무소 내 소속공인중개사 또는 중개보조원이 있을 경우,
      대표 개업공인중개사가 회원가입 후 '마이페이지'에서 등록해주세요.{' '}
    </>
  ),

  STEP4_NAME_PLACEHOLDER: '중개사무소를 선택해주세요.',

  STEP4_PHONE_NOTICE: '* 대표자 명의의 휴대폰번호로 인증해주세요',

  STEP4_PASSWORD_NOTICE: '* 비밀번호를 특수문자+영문+숫자 8-15로 입력하세요.',
  STEP4_PASSWORD_NONE: '* 비밀번호를 모두 입력해주세요',
  STEP4_PASSWORD_INCOR: '* 비밀번호가 동일하지 않습니다.',
  STEP4_PASSWORD_MIN_LIMIT: '* 비밀번호는 영문+숫자 8자 이상 15자 이하로 입력해야 합니다.',

  PHONE_NUM_CHECK_MODAL_TEXT: '휴대폰 인증 들어갈 자리',
  POPUP_TYPE_TEMP_STORE_REQUEST: '임시저장 하시겠습니까?',

  STEP4_CAREER_PLACEHOLDER:
    '중개의뢰 고객이 믿고 선택할 수 있도록, 내용을 상세하게 입력헤주세요. \n ex) 1. 금곡동 까치마을 주변에서 개업 (1995) \n 2. 분당구 중개사회 총무 (2010-2013) \n 3. 총 계약건수 200회 이상 등',

  // ================================ Step 5 ================================ //

  STEP_BUTTON_NEXT: '다음',
  OFFICE_TYPE_INDI: 'P',
  OFFICE_TYPE_CORP: 'C',

  ACADEMY_DONE: (
    <>
      <p className="cont">
        <big>
          <strong>다윈중개 실무교육 아카데미 신청</strong>이<br />
          완료되었습니다.
        </big>
      </p>
    </>
  ),
  SHARE_OFFICE_APPLY_DONE: (
    <>
      <p className="cont">
        <big>
          <strong>합동사무실 신청</strong>이<br />
          완료되었습니다.
        </big>
        <br />
        해당 지역에 합동사무실 오픈 시<br />
        다시 연락드리겠습니다.
      </p>
    </>
  ),
  OFFICE_TOUR_APPLY_DONE: (
    <>
      <p className="cont">
        <p>
          - 필요서류 송부
          <br />
          지원하신 분들은 이력서와 자기소개서를
          <br />
          <b>recruit@dawin.xyz</b>로 보내주시면
          <br />
          확인 후 1~2일내로 연락드리겠습니다.
        </p>
        <br />
        <p>
          - 합동사무실 투어
          <br />
          합동사무실 투어를 원하시는 분은
          <br />
          <b>매주 화 오후 2-3시</b>사이에 아래 사무실 주소로 방문해주세요.
          <br />
          [경기도 성남시 분당구 황새울로 123]
        </p>
      </p>
    </>
  ),

  ACADEMY_DETAIL: (
    <>
      <p className="cont">
        <p>
          <strong>1. 개인정보 수집 항목 (필수)</strong>
          <br />
          성명, 전화번호
        </p>
        <br />
        <p>
          <strong>2. 이용목적</strong>
          <br />
          실무교육 아카데미 이용
        </p>
        <br />
        <p>
          <strong>3. 보유기간</strong>
          <br />
          실무교육 아카데미 이용 목적이 달성된 후에는 정보를 지체없이 파기
        </p>
        <br />
        <p>※ 위의 개인정보 수집 및 이용동의에 거부할 수 있으며, 거부시 서비스 이용이 제한됨을 알려드립니다.</p>
      </p>
    </>
  ),
  OFFICE_TOUR_APPLY_DETAIL: (
    <>
      <p className="cont">
        <p>
          <strong>1. 개인정보 수집 항목 (필수)</strong>
          <br />
          성명, 전화번호
        </p>
        <br />
        <p>
          <strong>2. 이용목적</strong>
          <br />
          합동사무실 투어 서비스 이용
        </p>
        <br />
        <p>
          <strong>3. 보유기간</strong>
          <br />
          합동사무실 투어 서비스 이용 목적이 달성된 후에는 정보를 지체없이 파기
        </p>
        <br />
        <p>※ 위의 개인정보 수집 및 이용동의에 거부할 수 있으며, 거부시 서비스 이용이 제한됨을 알려드립니다.</p>
      </p>
    </>
  ),
  SHARE_OFFICE_APPLY_DETAIL: (
    <>
      <p className="cont">
        <p>
          <strong>1. 개인정보 수집 항목 (필수)</strong>
          <br />
          성명, 전화번호
        </p>
        <br />
        <p>
          <strong>2. 이용목적</strong>
          <br />
          합동사무실 신청 서비스 이용
        </p>
        <br />
        <p>
          <strong>3. 보유기간</strong>
          <br />
          합동사무실 신청 서비스 이용 목적이
          <br />
          달성된 후에는 정보를 지체없이 파기
        </p>
        <br />
        <p>※ 위의 개인정보 수집 및 이용동의에 거부할 수 있으며, 거부시 서비스 이용이 제한됨을 알려드립니다.</p>
      </p>
    </>
  ),
  OFFICE_TOUR_APPLY_EXIST: (
    <>
      <p className="cont">
        <big>
          <strong>이미 신청하신 내역이 있습니다.</strong>
          <br />
        </big>
        <br />
        확인하시고 변경을 원하시면 <br />
        고객센터로 연락해주세요(1544-6075).
        <br />
        다시 연락드리겠습니다.
        <br />
      </p>
    </>
  ),
};
