import Mask from '@/components/Mask';
import React from 'react';

const ViewSafetyPop = ({ safetyPop, setSafetyPop }) => {
  const closePop = () => {
    setSafetyPop(false);
  };

  return (
    <div className="dimmed_lyr_container" style={{ display: safetyPop ? 'block' : 'none' }}>
      <Mask show={true} />

      <div className="dimmed_layer" style={{ display: safetyPop ? 'block' : 'none' }}>
        <a className="btn-img btn-close-small" onClick={closePop}>
          닫기
        </a>
        <div className="lyr_inner">
          <div className="guide-box safenumber">
            <div className="lyr-title" style={{ fontWeight: '500' }}>
              안심번호 서비스 안내
            </div>
            <div className="lyr-content">
              <div className="lyr-block01">
                <p className="">010-1234-5678</p>
                <p className="">0508-4098-****</p>
              </div>
              <div className="lyr-block02" style={{ margin: '0 15px' }}>
                <p>
                  안심번호는 고객님의 실제 휴대폰번호가 노출되지 않도록 임시번호를 제공하는 서비스입니다.
                  <br />
                  <br />
                  일정시간이 지나면 번호는 자동으로 해제됩니다.
                  <br />
                  <br />
                  통화 내용은 녹음됩니다. 녹음에 동의하는 경우에 중개사 전화를 수신해 주세요.
                  <br />
                  <br />
                  (주)한국케이블텔레콤에 위탁하여 무료로 제공합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-horiz">
          <button className="btn btn-primary" onClick={closePop}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewSafetyPop;
