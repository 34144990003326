import React, { useEffect } from 'react';
import { parseCallBack } from '@/services/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { routeToInterestArea, routeToReport } from '../../helpers';
import CreateFeed from '@/combinedComp/Community/Create';
import ReportPopUpNew from '@/combinedComp/Community/ListNew/components/ReportPopUpNew';
import NewDetail from '@/combinedComp/Community/DetailNew';
import UserCommnuityHistoryNew from '@/pages/My/UserCommunityHistoryNew';
import RegiInterestArea from '@/pages/My/Area/RegiArea';
import PostForBuyingNoticeDetail from '@/combinedComp/Community/Create/components/PostForBuy/Notice/Detail';

const CommunityPopups = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectors.auth.getUserInfo);
  const isBroker = userInfo?.mbr_type === 'BU';
  const querystring = parseCallBack();
  const feedDetail = querystring['feedDetail']; //단지 상세정보 URL로 접근
  const viewReportPop = querystring['feedReport'];

  const setViewReportPop = (type, id) => {
    if (!viewReportPop) {
      dispatch(routeToReport(type, id));
    }
  };

  const viewInterestArea = querystring['setInterestArea'];
  const setViewInterestArea = () => {
    if (!viewInterestArea) {
      dispatch(routeToInterestArea());
    }
  };

  useEffect(() => {
    /**
     * 안드로이드에서 뒤로가기 했을 경우 redux 초기화. 다른 페이지 클릭시 이전 데이터 보이는 이슈 fix.
     */
    if (!feedDetail) {
      dispatch(actions.community.setComments(null));
      dispatch(actions.community.setFeedDetailItem(null));
      dispatch(actions.community.setFeed(null));
    }
  }, [feedDetail]);

  const goToBack = () => {
    dispatch(actions.router.goBack());
  };

  return (
    <>
      {/* 중개게시판 글쓰기 팝업 */}
      <CreateFeed show={!!querystring.createPoint || !!querystring.updateFeed} />

      {/* 중개게시판 글쓰기 팝업 > 구해줘 다윈 > 설명 팝업 */}
      <PostForBuyingNoticeDetail show={!!querystring.postForBuyGuide} />

      {/* 중개게시판 상세 페이지 팝업 */}
      {feedDetail && <NewDetail feedId={feedDetail} isBroker={isBroker} />}

      {/* 중개게시판 게시물/댓글/대댓글 신고 팝업 */}
      {viewReportPop && <ReportPopUpNew setViewReportPop={setViewReportPop} viewReportPop={viewReportPop} />}

      {/* 중개게시판 글쓴이 정보 팝업 */}
      <UserCommnuityHistoryNew show={!!querystring.userHistory} />

      {/* 중개게시판 관심지역 설정 팝업 */}
      {viewInterestArea && <RegiInterestArea setViewInterestArea={setViewInterestArea} viewInterestArea={viewInterestArea} onClose={goToBack} />}
    </>
  );
};

export default CommunityPopups;
