import * as AT from './actionTypes';

export const getFavoriteItemsList = (state) => state?.brokerPage?.mgGoods?.myFavoriteList;
export const getInterestedItemsList = (state) => state?.brokerPage?.mgGoods?.myInterestedList;
export const getContractedItemsList = (state) => state?.brokerPage?.mgGoods?.myContractedList;

export const getGoodsList = (state) => state?.brokerPage?.mgGoods?.goodsList;
export const getGoodsListLoading = (state) => state?.brokerPage?.mgGoods[AT.GET_MANAGE_GOODS_LIST]?.isLoading();
export const getGoodsListCount = (state) => state?.brokerPage?.mgGoods?.goodsListCount;
export const getfakeSaleReportRes = (state) => state?.brokerPage?.mgGoods?.fakeSaleReportRes;
export const getGoodsDetail = (state) => state?.brokerPage?.mgGoods?.goodsDetail;
export const getGoodsMemo = (state) => state?.brokerPage?.mgGoods?.goodsMemo;
export const getGoodsHistory = (state) => state?.brokerPage?.mgGoods?.goodsHistory;
export const getBrokerComments = (state) => state?.brokerPage?.mgGoods?.brokerComments;
export const getGoodsDetailStatus = (state) => state?.brokerPage?.mgGoods?.goodsDetail_status;
export const getScrollPosition = (state) => state?.brokerPage?.mgGoods?.scrollPosition;
export const getAssociatedCommunity = (state) => state?.brokerPage?.mgGoods?.feeds;

export const getKeyword = (state) => state?.brokerPage?.mgGoods?.keyword;
export const getKeywordList = (state) => state?.brokerPage?.mgGoods?.keywordList;
export const getCategory = (state) => state?.brokerPage?.mgGoods?.category;
export const getTradetype = (state) => state?.brokerPage?.mgGoods?.tradetype;
export const getAreasize = (state) => state?.brokerPage?.mgGoods?.areasize;
export const getPrice = (state) => state?.brokerPage?.mgGoods?.price;
export const getPrice2 = (state) => state?.brokerPage?.mgGoods?.price2;
export const getManageGoodsGubun = (state) => state?.brokerPage?.mgGoods?.gubun;
export const getSitu = (state) => state?.brokerPage?.mgGoods?.situ;
export const getStatus = (state) => state?.brokerPage?.mgGoods?.status;
export const getBrokerageType = (state) => state?.brokerPage?.mgGoods?.brokerageType;

export const getManageGoodsPage = (state) => state?.brokerPage?.mgGoods?.page;
export const getManageGoodsTotal = (state) => state?.brokerPage?.mgGoods?.totalCnt;

export const getContractInfo = (state) => state?.brokerPage?.mgGoods?.contractInfo;
export const getContractDetails = (state) => state?.brokerPage?.mgGoods?.details;
export const getContractMembers = (state) => state?.brokerPage?.mgGoods?.members;
export const getContractIp = (state) => state?.brokerPage?.mgGoods?.ip;
export const getContractBuyer = (state) => state?.brokerPage?.mgGoods?.buyer;
export const getContractSeller = (state) => state?.brokerPage?.mgGoods?.seller;
export const getContractBroker = (state) => state?.brokerPage?.mgGoods?.broker;

export const getClientType = (state) => state?.brokerPage?.mgGoods?.contractInfo?.client_type;

export const getCallReportList = (state) => state?.brokerPage?.mgGoods?.callReportList;

export const getModifyGoods = (state) => state?.brokerPage?.mgGoods?.modigoods;
export const getGoodsCommon = (state) => state?.brokerPage?.mgGoods?.goodsCommonDetail;
