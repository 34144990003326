import React from 'react';
import buiToggle from '@/assets/js/bui.toggle.js';

export const infoPopup = new buiToggle('[data-bui-toggle="infoPopup"]', {
	// inactiveButton: true,
	// inactiveButtonClass: 'btn popup-close',
	// inactiveButtonText: '<span class="btn-text">확인</span>',
	// inactiveButtonArea: '.popup-local-util .button-display .button-area',
	reactTarget: 'html',
	reactTargetActiveClass: 'active-info-popup',
	focusin: true,

	// onloadCallBack: function(myToggle) {
	// 	const popupUtilContainer = myToggle.toggleTarget.querySelector('.popup-local');
	// 	const popupUtil = document.createElement('div');
	// 	popupUtil.className = 'popup-local-util'
	// 	popupUtil.innerHTML = '<div class="button-display"><span class="button-area"></span></div>';
	// 	popupUtilContainer.appendChild(popupUtil);
	// }
});

const InfoPopup = ({ popupName, popupInactive, children }) => {
	return (
	<>
		{/* <!-- info-popup --> */}
		<div className="info-popup module-a style-b type-a small" data-bui-toggle="infoPopup" data-bui-toggle-inactive={ popupInactive } id={ popupName }>
			<div className="popup-page-body">
				{ children }
			</div>
		</div>
		{/* <!-- //info-popup --> */}
	</>
	);
};

export default InfoPopup;
