import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';

/**
 * 뒤로가기, 알람, 메뉴 header
 */
const Navigation = ({ goBackTo }) => {
  const dispatch = useDispatch();

  const handleBackClick = (e) => {
    e.preventDefault();
    if (goBackTo) {
      dispatch(actions.router.replace(goBackTo));
    } else {
      dispatch(actions.router.goBack());
    }
  };

  return (
    <header id="header" className="header">
      <span className="btn-left">
        <a className="btn-img btn-back" onClick={handleBackClick}>
          뒤로
        </a>
      </span>
    </header>
  );
};

export default Navigation;
