import { REALTY_TYPE_OPTIONS } from '@/pages/BrokerPage/constants/realtyTypes';

// 분석 상세 메뉴 구분자 ======================== //
export const base = {
  ANALYSIS: 'ANALYSIS', // '분석' 기본값
  ANALYSIS_WEEKLY_PRICE: 'ANALYSIS_WEEKLY_PRICE', // 주간실거래가
  ANALYSIS_TRADE_VOLUME: 'ANALYSIS_TRADE_VOLUME', // 거래량
  ANALYSIS_PRICE_FLUC: 'ANALYSIS_PRICE_FLUC', // 가격변동
  ANALYSIS_JEONSAE_PERCENTAGE: 'ANALYSIS_JEONSAE_PERCENTAGE', // 전세가율
  ANALYSIS_JOB_NUM: 'ANALYSIS_JOB_NUM', // 일자리수
  ANALYSIS_SCHOOL: 'ANALYSIS_SCHOOL', // 교육
  ANALYSIS_SUPPLY_AMT: 'ANALYSIS_SUPPLY_AMT', // 공급량
  ANALYSIS_POSITIVES: 'ANALYSIS_POSITIVES', // 개발호재
  ANALYSIS_RECON_FEASIBILITY: 'ANALYSIS_RECON_FEASIBILITY', // 재건축사업성
  ANALYSIS_APT_COMP: 'ANALYSIS_APT_COMP', // 아파트비교
  ANALYSIS_RANKING: 'ANALYSIS_RANKING', // 랭킹
  ANALYSIS_PUBLIC_NOTICE: 'ANALYSIS_PUBLIC_NOTICE', // 공시지가
};

const constants = {
  REALTY_TYPE_OPTIONS,
  BROKER_PAGE: 'BROKER_PAGE',
  BROKER_HOME: 'BROKER_HOME',
  MY_DAWIN: 'MY_DAWIN',
  BROKER_INFO: 'BROKER_INFO',
  SET_AREA: 'SET_AREA',
  ADD_USER: 'ADD_USER',
  MEMBERSHIP: 'MEMBERSHIP',
  STATUS_BOARD: 'STATUS_BOARD',
  DAILY_REPORT: 'DAILY_REPORT',
  INTERNAL: 'INTERNAL',
  CS_CENTER: 'CS_CENTER',
  MANAGE_BUSINESS: 'MANAGE_BUSINESS',
  MANAGE_GOODS: 'MANGE_GOODS',
  MANAGE_OPEN_GOODS: 'MANAGE_OPEN_GOODS',
  MANAGE_CONTRACT: 'MANAGE_CONTRACT',
  MANAGE_GOODS_REGISTER: 'MANAGE_GOODS_REGISTER',
  MANAGE_CUSTOMER: 'MANAGE_CUSTOMER',
  MANAGE_COUNSELING: 'MANAGE_COUNSELING',
  MANAGE_COMPLEX_IMG: 'MANAGE_COMPLEX_IMG',
  MANAGE_GOODS_INFOS: 'MANAGE_GOODS_INFOS',
  MANAGE_REG_GOODS: 'MANAGE_REG_GOODS',
  MANAGE_COMMUNITY: 'MANAGE_COMMUNITY',
  MANAGE_QA: 'MANAGE_QA',
  MANAGE_RATE: 'MANAGE_RATE',
  MANAGE_CHAT: 'MANAGE_CHAT',
  QNA: 'QNA',
  JOINT_BROKERAGE: 'JOINT_BROKERAGE',
  NEWS: 'NEWS',
  DATA_ROOM: 'DATA_ROOM',
  NOTICE: 'NOTICE',
  FAQ: 'BROKER_FAQ',
  INTERNAL_NOTICE: 'INTERNAL_NOTICE',
  SUGGESTION: 'SUGGESTION',
  ONEONONE: 'ONEONONE',
  TEST: 'TEST',

  BASIC_INFO: 'BASIC_INFO',
  OFFICE_INFO: 'OFFICE_INFO',
  OFFICE_INTRO: 'OFFICE_INTRO',

  FORM_FORMAT: 'FORM_FORMAT',
  PRECEDENT: 'PRECEDENT',
  USEFUL_SITE: 'USEFUL_SITE',

  USER_ADD: 'USER_ADD',
  CHECK_PAYMENT: 'CHECK_PAYMENT',

  //BROKER_ALARM_LIST : "BROKER_ALARM_LIST",
  // =================== BrokerInfo.BasicInfo =================== //
  PASSWORD_CURRENT_NOT_GIVEN: 'PASSWORD_CURRENT_NOT_GIVEN',
  PASSWORD_CURRENT_INCORRECT: 'PASSWORD_CURRENT_INCORRECT',

  PASSWORD_NEW_NOT_GIVEN: 'PASSWORD_NEW_NOT_GIVEN',

  PASSWORD_NEW_NOT_MATCH: 'PASSWORD_NEW_NOT_MATCH',
  PASSWORD_NEW_MATCH: 'PASSWORD_NEW_MATCH',

  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',

  PHONE_CHANGE: 'PHONE_CHANGE',
  PHONE_CHANGED: 'PHONE_CHANGED',

  USER_WITHDRAWAL: 'USER_WITHDRAWAL',

  // =================== BrokerInfo.OfficeInfo =================== //
  SI_DO_LIST: 'SI_DO_LIST',
  SI_GUN_GU_LIST: 'SI_GUN_GU_LIST',
  DONG_RI_LIST: 'DONG_RI_LIST',
  COMPLEX_LIST: 'COMPLEX_LIST',
  GOODS_LIST: 'GOODS_LIST',

  IMAGE_TYPE_CORP: 'IMAGE_TYPE_CORP', // 사업자등록증
  IMAGE_TYPE_OPEN: 'IMAGE_TYPE_OPEN', // 개설등록증

  IMAGE_FORMATS_ALLOWED: 'image/jpg, image/png, image/jpeg',
  //====================매물관리=====================//
  ALL_GOODS: 'ALL_GOODS',
  BROKER_GOODS_MODIFY: 'BROKER_GOODS_MODIFY',
  BROKER_FAVORITE_GOODS: 'BROKER_FAVORITE_GOODS',
  BROKERS_GOODS: 'BROKERS_GOODS',
  OPEN_GOODS: 'OPEN_GOODS',
  CONTRACT_GOODS: 'CONTRACT_GOODS',

  BROKERAGE_EVALUATION: 'BROKERAGE_EVALUATION',
  RECOMMEND: 'RECOMMEND',
  COMPLEX_INFO: 'COMPLEX_INFO',
  GOODSINFO_CHANGE: 'GOODSINFO_CHANGE',
  FAKE_SALE_REPORT: 'FAKE_SALE_REPORT',
  RELIEF_CALL: 'RELIEF_CALL',

  //-------------------------

  ...base,
  // ======================== MAP ======================== //
  MAP_TYPE_EMPTY: 'MAP_TYPE_EMPTY',

  // 기획안에 나온 순서로 정렬
  MAP_DYNAMIC_CIRCLE_LIST: [
    base.ANALYSIS_TRADE_VOLUME, // 거래량
    base.ANALYSIS_PRICE_FLUC, //가격 변동
    base.ANALYSIS_JEONSAE_PERCENTAGE, //전세가율
    base.ANALYSIS_JOB_NUM, //일자리 수
    base.ANALYSIS_SCHOOL, //교육 => 단, 교육은 조금 다르기 때문에, 별도로 구분
    base.ANALYSIS_SUPPLY_AMT, //공급량
    base.ANALYSIS_RECON_FEASIBILITY, //재건축사업성
    base.ANALYSIS_PUBLIC_NOTICE, //공시지가
  ],

  MAP_HISTORY_SUMMARY_GRAPH_LIST: [
    base.ANALYSIS_JOB_NUM, //일자리
    base.ANALYSIS_PUBLIC_NOTICE, //공시지가

    base.ANALYSIS_TRADE_VOLUME, // 거래량
    base.ANALYSIS_PRICE_FLUC, //가격 변동
    base.ANALYSIS_JEONSAE_PERCENTAGE, //전세가율
    base.ANALYSIS_SUPPLY_AMT, //공급량
    base.ANALYSIS_RECON_FEASIBILITY, //재건축사업성
  ],

  // 줌레벨 제한 있는 카테고리 목록 (지도 확대해달라는 텍스틒 표시해야 하는 목록)
  MAP_ZOOMLEVEL_LIMIT_LIST: [base.ANALYSIS, base.ANALYSIS_APT_COMP],

  // @todo 정해야함 => 임의로 1~3은 도단위, 4~6은 시,군,구 단위, 7~9는 동단위, 10~14 는 단지단위로 나눠놓음
  MAP_ZOOM_THRESHOLDS: [3, 6, 9, 14],

  MAP_SINGLE_MARKER_MULTIPLE: 1.5,
  MAP_RECON_FEASIBILITY_THRES: 50,

  TRAIN_SUBWAY: 'TRAIN_SUBWAY',
  CONSTRUCTION: 'CONSTRUCTION',
  RECONSTRUCTION: 'RECONSTRUCTION',
  // ======================== POPUP ======================== // @06/14 추천 탭으로 전환
  // 시도 시, 아직 구현되지 않았음을 표현하는 것으로 대체
  POPUP_RECOMM_NOT_YET: 'POPUP_RECOMM_NOT_YET',

  // ======================== 개발호재 ======================== //
  POSITIVES_TYPE_TS: 'POSITIVES_TYPE_TS',
  POSITIVES_TYPE_CONST: 'POSITIVES_TYPE_CONST',
  POSITIVES_TYPE_RECON: 'POSITIVES_TYPE_RECON',
  POSITIVES_TYPE_ETC: 'POSITIVES_TYPE_ETC',

  POSITIVES_TYPES_LIST: [
    {
      value: 'POSITIVES_TYPE_TS',
      txt: '기차/지하철',
    },
    {
      value: 'POSITIVES_TYPE_CONST',
      txt: '건설',
    },
    {
      value: 'POSITIVES_TYPE_RECON',
      txt: '재건축,재개발',
    },
    {
      value: 'POSITIVES_TYPE_ETC',
      txt: '기타',
    },
  ],

  // 랭킹 타입
  RANKING_TRADE_VOLUME: '1', //'RANKING_TRADE_VOLUME',
  RANKING_PRICE_INCREASE: '2', //'RANKING_PRICE_INCREASE',
  RANKING_PRICE_PER_PYEONG: '3', //'RANKING_PRICE_PER_PYEONG',
  RANKING_PRICE: '4', //'RANKING_PRICE',
  RANKING_JEONSAE_PRICE: '5', //'RANKING_JEONSAE_PRICE',
  // RANKING_OFFICIAL_PRICE: '6', //'RANKING_OFFICIAL_PRICE',
  RANKING_SCHOOL: '6', //'RANKING_SCHOOL',
  RANKING_RECENT: '7', //'RANKING_RECENT',
  RANKING_FLOOR_AREA: '8', //'RANKING_FLOOR_AREA',
  RANKING_AREA_PER_HOUSEHOLD: '9',
  RANKING_RECONSTRUCT_SCORE: '10', //'RANKING_PRICE_PER_PYEONG',
};

export default constants;
