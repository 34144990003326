import React from 'react';
import ReplyInput from './replyInput';

const ShowReplyInput = ({ reply, setReplies, replies, comment, setReply, showReplies, onReply, contentRef, addReplyCount, cancelUpdateComment }) => {
  // const closeReply = () => {
  //   contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   contentRef.current.focus();
  //   showReplies(false);
  //   onReply(false);
  // };

  return (
    <ReplyInput
      setReplies={setReplies}
      replies={replies}
      commentId={comment?.id}
      isOnReply={reply}
      setOnReply={setReply}
      addReplyCount={addReplyCount}
      cancelUpdateComment={cancelUpdateComment}
    />
  );
};

export default ShowReplyInput;
