import { loading, success, failure } from '@/data/utils';

export const SAVE_NEXT_CURSOR = 'BROKERPAGE/SAVE_NEXT_CURSOR';
export const SET_PAYLOAD_NEXT = 'BROKERPAGE/SET_PAYLOAD_NEXT';
export const CHANGE_COMMUNITY_PAYLOAD = 'BROKERPAGE/CHANGE_COMMUNITY_PAYLOAD';
export const REFRESH_ADDRESS_TAG = 'BROKERPAGE/REFRESH_ADDRESS_TAG';
export const SAVE_USER_SET_AREA_LIST = 'BROKERPAGE/SAVE_USER_SET_AREA_LIST';

export const GET_SAFETY_NUMBER = 'BROKERPAGE/GET_SAFETY_NUMBER';
export const GET_SAFETY_NUMBER_LOADING = loading(GET_SAFETY_NUMBER);
export const GET_SAFETY_NUMBER_SUCCESS = success(GET_SAFETY_NUMBER);
export const GET_SAFETY_NUMBER_FAILURE = failure(GET_SAFETY_NUMBER);

export const GET_BROKER_COMMUNITY_FEEDS = 'BROKERPAGE/GET_BROKER_COMMUNITY_FEEDS';
export const GET_BROKER_COMMUNITY_FEEDS_LOADING = loading(GET_BROKER_COMMUNITY_FEEDS);
export const GET_BROKER_COMMUNITY_FEEDS_SUCCESS = success(GET_BROKER_COMMUNITY_FEEDS);
export const GET_BROKER_COMMUNITY_FEEDS_FAILURE = failure(GET_BROKER_COMMUNITY_FEEDS);

export const CLEAR_BROKER_COMMUNITY_FEEDS = 'BROKERPAGE/CLEAR_BROKER_COMMUNITY_FEEDS';
