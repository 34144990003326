import * as AT from '@/data/rootActionTypes';
import Remote from '@/data/remote';
import findReducer from './find/reducers';
import AuthSession from '@/services/apps/load/AuthSession';
import { getFormattedUserInfo } from '@/services/apps/auth';

export function getAuth(initialData) {
  const INITIAL_STATE = {
    userSession: initialData.userInfo,
    deviceId: initialData.deviceId,
    sessionId: initialData.sessionId,
    [AT.LOG_IN]: Remote.NotAsked,
    [AT.SMS]: Remote.NotAsked,
    loginStep: '0',
    loginData: {},
    authToken: null,
    idCandidates: [],
    emailConfirmed: null,
    [AT.SIGNUP]: Remote.NotAsked,
    [AT.AGENT_SIGNUP_FAILURE]: Remote.NotAsked,
    [AT.CHECK_AUTH]: Remote.NotAsked,
    alarmOn: true,
    userInfoForLogin: {},
  };

  return function auth(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
      case AT.SET_USER_SESSION:
        var userInfo = getFormattedUserInfo(action.userSession);
        var initialData = new AuthSession();
        initialData.updateUserInfo(userInfo);
        return { ...state, userSession: userInfo };
      case AT.RESET_AUTH:
        return { ...INITIAL_STATE, userSession: null };
      case AT.SET_AUTH_TOKEN:
        return { ...state, authToken: action.token };
      case AT.SET_LOGIN_STEP:
        if (action.cellphone || action.name)
          return { ...state, loginStep: action.step, loginData: { cellphone: action.cellphone, name: action.name } };
        else return { ...state, loginStep: action.step, loginData: {} };
      case AT.SET_AGREE_ACTION:
        return { ...state, agreeAction: action.nextAction };
      case AT.CHECK_AUTH_LOADING: //check_auth에 상태가 필요한지?
        return { ...state, [AT.CHECK_AUTH]: Remote.Loading };
      case AT.CHECK_AUTH_SUCCESS:
        return { ...state, [AT.CHECK_AUTH]: Remote.Success(action.data) };
      case AT.CHECK_AUTH_FAILURE:
        return { ...state, [AT.CHECK_AUTH]: Remote.Failure(action.error) };
      case AT.SET_USERINFO_FOR_LOGIN:
        return { ...state, userInfoForLogin: action.data };
      case AT.LOG_IN_LOADING:
        return { ...state, [AT.LOG_IN]: Remote.Loading };
      case AT.LOG_IN_SUCCESS:
        return { ...state, [AT.LOG_IN]: Remote.Success(action.data) };
      case AT.LOG_IN_FAILURE:
        return { ...state, [AT.LOG_IN]: Remote.Failure(action.error) };
      case AT.SMS_LOADING:
        return { ...state, [AT.SMS]: Remote.Loading };
      case AT.SMS_SUCCESS:
        return { ...state, [AT.SMS]: Remote.Success(action.data) };
      case AT.SMS_FAILURE:
        return { ...state, [AT.SMS]: Remote.Failure(action.error) };
      case AT.SET_ID_CANDIDATES:
        return { ...state, idCandidates: action.data };
      case AT.EMAIL_CONFIRMED:
        return { ...state, emailConfirmed: action.data };
      case AT.SIGNUP_LOADING:
        return { ...state, [AT.SIGNUP]: Remote.Loading };
      case AT.SIGNUP_SUCCESS:
        return { ...state, [AT.SIGNUP]: Remote.Success(action.data) };
      case AT.SIGNUP_FAILURE:
        return { ...state, [AT.SIGNUP]: Remote.Failure(action.error) };
      case AT.AGENT_SIGNUP_LOADING:
        return { ...state, [AT.AGENT_SIGNUP]: Remote.Loading };
      case AT.AGENT_SIGNUP_SUCCESS:
        return { ...state, [AT.AGENT_SIGNUP]: Remote.Success(action.data) };
      case AT.AGENT_SIGNUP_FAILURE:
        return { ...state, [AT.AGENT_SIGNUP]: Remote.Failure(action.error) };
      case AT.AGENT_SIGNUP_RESET_ERROR:
        return { ...state, [AT.AGENT_SIGNUP]: Remote.NotAsked };
      case AT.TOGGLE_ALARM_ON:
        return { ...state, alarmOn: !state.alarmOn };
      case AT.SET_APP_INFO:
        return { ...state, appInfo: action.data, alarmOn: action.data?.use_yn === 'Y' ? true : false };
      case AT.SET_NICK_NAME:
        return { ...state, nickname: action?.nickname };
      case AT.SET_MARKETING_USER_INFO:
        return { ...state, marketingUserInfo: action?.payload };
      case AT.IS_SIGN_UP_USER:
        return { ...state, isSignUpUser: true };
      case AT.PHONE_NUMBER_AUTH_ONLY:
        return { ...state, phoneNumberAuthOnly: action?.value };
      default:
        return { ...state, ...findReducer(state, action) };
    }
  };
}
