import { put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import { selectors } from '@/data';
import apis from '@/apis';
import axios from 'axios';
import * as CONSTATANTS from './text';
import downloadApp from '@/services/apps/download';
import * as footer from './footer/sagas';
export { footer };
import { STATUS } from '@/data/utils';
import { store } from '@/data/configureStore';

export function* getComplexArea({ complex_idx }) {
  try {
    const result = yield apis.commonApi.getComplexArea(complex_idx);
    yield put(actions.setComplexArea(complex_idx, result.list));
  } catch (e) {
    alert('죄송합니다. 다시 시도해주세요.');
  }
}

export function* getComplexArea2({ complex_idx }) {
  try {
    if (complex_idx) {
      const result = yield apis.commonApi.getComplexArea2(complex_idx);
      yield put(actions.setComplexArea2(result.list));
    } else {
      yield put(actions.setComplexArea2([]));
    }
  } catch (e) {
    alert('죄송합니다. 다시 시도해주세요.');
  }
}

export function* searchKeyword({ keyword, totalSearch, withMinhouseholdcnt }) {
  try {
    yield put(actions.searchKeywordLoading());
    const category = totalSearch ? '' : yield select(selectors.buying.getCategory);
    const result = yield apis.commonApi.searchKeyword(keyword, category, withMinhouseholdcnt);
    yield put(actions.searchKeywordSuccess(result));
  } catch (e) {
    yield put(actions.searchKeywordFailure(e));
  }
}

const ALLOW_IMAGE_TYPE = ['image/jpeg', 'image/jpg', 'image/png'];

//이미지 업로드용
export function* uploadFile({ category, file, setter }) {
  try {
    const formData = yield new FormData();
    yield formData.append('file', file);
    const contentType = file.type?.toLowerCase();
    // const size = file.size;

    //(타입제한 jpg, png)//|| size > ALLOW_MAX_SIZE
    if (ALLOW_IMAGE_TYPE.indexOf(contentType) < 0) {
      yield put(allActions.common.alert({ contents: '사진은 jpg, png 파일로 업로드해주세요.' }));
      return;
    }

    const result = yield apis.commonApi.uploadFiles({ category: category, filename: file.name });

    yield axios
      .put(result.putUrl, file, {
        headers: {
          'Content-Type': contentType,
        },
      })
      .then(() => {
        setter(result.fullUrl);
      })
      .catch(() => {
        alert('이미지 업로드에 실패하였습니다.');
      });
  } catch (e) {
    alert(e.message);
  }
}
export function* uploadAnyFile({ category, file, setter }) {
  try {
    const formData = yield new FormData();
    yield formData.append('file', file);
    const contentType = file.type;

    const result = yield apis.commonApi.uploadAnyFiles({ category: category, filename: file.name });

    yield axios
      .put(result.putUrl, file, {
        headers: {
          'Content-Type': contentType,
        },
      })
      .then(() => {
        setter(result.idx, result.filename);
      })
      .catch(() => {
        //err
        alert('파일 업로드에 실패하였습니다.');
      });
  } catch (e) {
    alert(e.message);
  }
}

//관심단지
export function* addFavComplex({ complex_idx, area_list }) {
  try {
    yield put(actions.addFavComplexLoading());

    const data = area_list ? area_list.join(',') : '0';

    if (area_list) {
      yield apis.commonApi.addFavComplex(complex_idx, data);
    } else {
      yield apis.commonApi.removeFavComplex(complex_idx, data);
    }
    const updateTemp = area_list ? true : false;

    yield put(actions.updateTempFavCompList(complex_idx, updateTemp));
    yield put(actions.addFavComplexSuccess());

    /// 관심단지 설정 완료 alert
    const osTypeInstance = yield select(selectors.app.getOsTypeInstance);
    const isApp = osTypeInstance?.isApp;
    let contents = isApp ? CONSTATANTS.ADD_FAV_COMPLEX_SUCCESS_APP : CONSTATANTS.ADD_FAV_COMPLEX_SUCCESS_MOBILE;
    let alertType = isApp ? 'alert' : 'confirm';

    if (!area_list) {
      contents = '관심단지 해제하였습니다.';
      alertType = 'alert';
    }

    const onDownload = () => {
      //android ios 이슈 https://gomest.tistory.com/7
      // console.log('TEST: ' + osType);
      const { dispatch } = store;
      dispatch(downloadApp());
    };

    if (alertType === 'alert') {
      yield put(actions.alert({ contents: contents }));
    } else {
      yield put(
        actions.confirm({
          contents: contents,
          cancelButtonText: '닫기',
          onCancel: () => {},
          confirmButtontext: '앱 다운로드',
          onConfirm: () => onDownload(),
        })
      );
    }

    yield put(actions.openMobileFavoBox(false));
  } catch (e) {
    yield put(actions.addFavComplexFailure());
    alert('요청 처리 실패');
  }
}

export function* getFavComplexList() {
  try {
    const category = yield select(selectors.buying.getCategory);
    const result = yield apis.commonApi.getFavComplexList(category);
    yield put(actions.setFavComplexList(result?.list));
  } catch (e) {
    yield put(actions.setFavComplexList(null));
    // yield put(actions.setError(e));
  }
}

//관심매물 설정
export function* handleFavGoods({ acttype, goods_idx }) {
  try {
    yield apis.commonApi.handleFavGoods(acttype, goods_idx);
    yield put(actions.updateTempFavGoodsList(goods_idx, acttype === 'S'));

    const osTypeInstance = yield select(selectors.app.getOsTypeInstance);
    const isApp = osTypeInstance?.isApp;

    if (acttype === 'D') {
      yield put(actions.alert({ contents: '해당 매물이 관심매물 목록에서 삭제되었습니다.' }));
    } else if (isApp) {
      yield put(actions.alert({ contents: CONSTATANTS.ADD_FAV_GOODS_SUCCESS_APP }));
    } else {
      const { dispatch } = store;

      yield put(
        actions.confirm({
          contents: CONSTATANTS.ADD_FAV_GOODS_SUCCESS_MOBILE,
          cancelButtonText: '앱 다운로드',
          onCancel: () => {
            dispatch(downloadApp());
          },
          comfirmButtonText: '닫기',
        })
      );
    }

    yield put(allActions.my.getMyInterestGoods());
  } catch (e) {
    // yield put(actions.handleFavGoodsSuccess(result));
  }
}

// 중개지역 설정에 따른 중개사 리스트
export function* getSearchBrokerListBySetting({ setData, payload }) {
  try {
    setData({ status: STATUS.LOADING });
    // payload: { id-type, id, sort }
    const result = yield apis.commonApi.searchBrokerBySetting(payload);
    setData({ status: STATUS.SUCCESS, result: result.rltObj });
  } catch (e) {
    setData({ status: STATUS.FAILURE, error: e.message });
    //error alert(e.message)
  }
}
