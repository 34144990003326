import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// 매물 목록으로 바로 이동
// initial_area: (optional) 대표평형이 아닌 평형으로 최초 rendering
export const routeToGoodsListDirect = (complex_idx, trade_type, pyeong = 'all', initial_area, options) => (dispatch) => {
  const newPath = getGoodsListDirectPath(complex_idx, trade_type, pyeong, initial_area, options);
  const watching = location.search.indexOf('d_complex') > -1;

  if (watching) {
    dispatch(actions.router.replace(newPath));
  } else {
    dispatch(actions.router.push(newPath));
  }
};

export const getGoodsListDirectPath = (complex_idx, trade_type, pyeong, initial_area, options) => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  queryObject.d_complex = complex_idx;
  queryObject.d_trade_type = trade_type;
  if (pyeong) queryObject.d_area = pyeong;

  delete queryObject.goods_idx;

  if (initial_area) queryObject.initial_area = initial_area;

  const newQuerystring = objectToQuerystring(queryObject);
  return `${options?.initial_path || location.pathname}${newQuerystring}`;
};
