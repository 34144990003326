import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.SEARCH, sagas.search);
  yield takeLatest(AT.SEARCH_GOODS, sagas.searchGoods);
  yield takeEvery(AT.GET_CHART, sagas.getChart);
  yield takeEvery(AT.GET_CHARTS, sagas.getCharts);
  yield takeEvery(AT.TOGGLE_SURROUNDING_ITEM, sagas.toggleSurroundingItem);
  yield takeEvery(AT.RELOAD_SURROUNDINGS, sagas.reloadSurroundings);
  yield takeEvery(AT.SET_STEP, sagas.setStep);
  yield takeEvery(AT.SELECT_ITEM, sagas.selectItem);
  yield takeEvery(AT.detail.GET_DETAIL, sagas.detail.getDetail);
  yield takeEvery(AT.detail.GET_TRADE_HISTORY, sagas.detail.getTradeHistory);
  yield takeLatest(AT.detail.GET_TRADE, sagas.detail.getTrade);
  yield takeEvery(AT.detail.GET_TAX, sagas.detail.getTax);
  yield takeEvery(AT.detail.GET_BROKERS, sagas.detail.getBrokers);
  yield takeEvery(AT.detail.GET_SALES, sagas.detail.getSales);
  // 네이버매물 - 작업중
  yield takeEvery(AT.detail.GET_MARKET_DATA, sagas.detail.getMarketData);
  yield takeEvery(AT.detail.GET_FACILITY_DATA, sagas.detail.getFacilityData);
  yield takeEvery(AT.detail.GET_FACILITY_CODE, sagas.detail.getFacilityCode);
  yield takeEvery(AT.detail.GET_FACILITY_DATA_USER, sagas.detail.getFacilityDataUser);
  yield takeEvery(AT.detail.UPLOAD_FACILITY_DATA_USER, sagas.detail.uploadFacilityDataUser);
  yield takeEvery(AT.detail.GET_NAVER_SALES, sagas.detail.getNaverSales);
  yield takeEvery(AT.detail.GET_NAVER_SALE_DETAIL, sagas.detail.getNaverSaleDetail);
  // 네이버매물 - 작업중
  yield takeEvery(AT.detail.GET_SALE_DETAIL, sagas.detail.getSaleDetail);
  yield takeEvery(AT.detail.ASK_TO_BROKER, sagas.detail.askToBroker);
  yield takeEvery(AT.detail.GET_SCHOOL_DETAIL, sagas.detail.getSchoolDetail);
  yield takeEvery(AT.detail.GET_KINDERGARTEN_DETAIL, sagas.detail.getKinderDetail);
  yield takeEvery(AT.detail.GET_DAYCARE_DETAIL, sagas.detail.getDaycareDetail);
  yield takeEvery(AT.detail.GET_BROKER_DETAIL, sagas.detail.getBrokerDetail);
  yield takeEvery(AT.detail.GET_SCHOOL_INFO_WITH_KAKAO, sagas.detail.getSchoolInfoWithKakao);
  yield takeEvery(AT.detail.GET_AREA_LIST, sagas.detail.getAreaList);
  yield takeEvery(AT.detail.REPORT_COMPLEX_FACILITY, sagas.detail.reportComplexFacility);

  // 일반유저의 매물 신고
  yield takeEvery(AT.REPORT_GOOD, sagas.reportGood);

  // 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
  yield takeEvery(AT.GET_THIS_DONG_REALTOR_LIST, sagas.getThisDongRealtorList);
  // 지도 bound 내 중개사 리스트 조회
  yield takeLatest(AT.GET_REALTORLIST_ON_MAP, sagas.getRealtorlistOnMap);
}
