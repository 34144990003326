import React, { useState, useEffect, useRef } from 'react';
import PopupWrapperComp from '@/components/PopupWrapperComp';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
// import Step1 from './Step1';
// import Step2 from './Step2';
// import Result from './Result';
import TemplateContentsPopup from '@/components/templates/TemplateContentsPopup';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Result from './components/Result';

const steps = [Step1, Step2, Result];

const FindIdPopup = ({ setShow, isMobile }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const phoneNum = useRef(null);
  const step1State = useSelector(selectors.auth.getFindId1State);
  const step2State = useSelector(selectors.auth.getFindId2State);
  const isBusy = step1State === 'Loading' || step2State === 'Loading';

  useEffect(() => {
    return () => {
      dispatch(actions.auth.findId1Notasked());
      dispatch(actions.auth.findId2Notasked());
    };
  }, []);

  useEffect(() => {
    if (step1State === 'Success') {
      setStep(1);
    }
  }, [step1State]);

  useEffect(() => {
    if (step2State === 'Success') {
      setStep(2);
    }
  }, [step2State]);

  const Step = steps[step];

  return (
    <TemplateContentsPopup title="아이디찾기" onClose={() => setShow(false)}>
      <Step isMobile={isMobile} isBusy={isBusy} phoneNum={phoneNum} step1State={step1State} step2State={step2State} setShow={setShow} />
    </TemplateContentsPopup>
  );
};

export default FindIdPopup;
