import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';

const FavoButtonForGoods = ({ is_favorite = false, goods_idx }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const tempFavGoodsList = useSelector(selectors.common.getTempFavGoodsList);

  if (is_favorite === 'Y') {
    is_favorite = true;
  } else if (is_favorite === 'N') {
    is_favorite = false;
  }

  let markOrNot = false;
  if (isLoggedIn && tempFavGoodsList[goods_idx] != undefined) {
    markOrNot = tempFavGoodsList[goods_idx];
  } else {
    markOrNot = is_favorite;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!goods_idx) {
      dispatch(actions.common.alert({ contents: '매물 idx가 없습니다.' }));
      return;
    }

    if (isLoggedIn) {
      dispatch(actions.common.handleFavGoods(!markOrNot ? 'S' : 'D', goods_idx));
    } else {
      dispatch(actions.common.pleaseLoginPopup(true));
    }
  };

  return <button id={'goodsFavoBtn_' + goods_idx} onClick={handleClick} className={classNames('btn-img btn-favo', { on: markOrNot })}></button>;
};

// FavoButtonForGoods.defaultProps = {
//   is_favorite: false,
// };
export default FavoButtonForGoods;
