import * as AT from './actionTypes';
const INITIAL_STATE = {
  error: '',
  sellingItemInteriorImagesBlob: [], // { imageCategory : '', imageFileName : '', imageFile : ''(Blob)}
  sellingItemExteriorImagesBlob: [],

  corpCertImage: {},
  openCertImage: {},
  profileImage: {},

  // imagePutUrl: '',
  // imageFillUrl: '',
  // imageContentType: '',
  // imageCategory: '',
  // imageFilename: '',
  // imageFile: '',
};

export default function uploadImageRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.ERROR_UPLOAD_ITEM_IMAGE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case AT.SELL_ADD_IMAGE_ITEM: {
      if (!action.intORext) {
        // 0 이면 실내
        state.sellingItemInteriorImagesBlob.push(action.itemImageObj);
        return {
          ...state,
        };
      } else {
        state.sellingItemExteriorImagesBlob.push(action.itemImageObj);
        return {
          ...state,
        };
      }
    }
    case AT.SELL_DELETE_IMAGE_ITEM: {
      if (!action.intORext) {
        // 0 이면 실내
        return {
          ...state,
          sellingItemInteriorImagesBlob: state.sellingItemInteriorImagesBlob.filter((el) => el.imageFileName != action.itemImageObj.imageFileName),
        };
      } else {
        return {
          ...state,
          sellingItemExteriorImagesBlob: state.sellingItemExteriorImagesBlob.filter((el) => el.imageFileName != action.itemImageObj.imageFileName),
        };
      }
    }

    case AT.AS_ADD_IMAGE_ITEM: {
      switch (action.corpORopen) {
        case 0: {
          // 0 이면 사업자
          return {
            ...state,
            corpCertImage: action.itemImageObj,
          };
        }
        case 1: {
          // 1 이면 개설
          return {
            ...state,
            openCertImage: action.itemImageObj,
          };
        }
        case 2: {
          // 2 면 프로필
          return {
            ...state,
            profileImage: action.itemImageObj,
          };
        }
      }
      break;
    }

    case AT.AS_DELETE_IMAGE_ITEM: {
      switch (action.corpORopen) {
        case 0: {
          // 0 이면 사업자
          return {
            ...state,
            corpCertImage: {},
          };
        }
        case 1: {
          // 1 이면 개설
          return {
            ...state,
            openCertImage: {},
          };
        }
        case 2: {
          // 2 면 프로필
          return {
            ...state,
            profileImage: {},
          };
        }
      }
      break;
    }
    case AT.DELETE_AGENT_SIGNUP_IMAGE: {
      return {
        ...state,
        corpCertImage: {},
        openCertImage: {},
        profileImage: {},
      };
    }

    case AT.UPLOAD_ITEM_IMAGE: {
      return {
        ...state,
        // imageCategory: action.imageCategory,
        // imageFilename: action.imageFilename,
        // imageFile: action.imageFile
      };
    }

    default: {
      return state;
    }
  }
}
