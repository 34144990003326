import * as AT from './actionTypes';

export const getContractReviews = (payload) => ({
  type: AT.GET_CONTRACT_REVIEWS,
  payload,
});

export const setContractReviews = (payload) => ({
  type: AT.SET_CONTRACT_REVIEWS,
  payload,
});

export const resetContractReviews = () => ({
  type: AT.RESET_CONTRACT_REVIEWS,
});
