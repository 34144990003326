import * as AT from './actionTypes';

export const showCreateFeed = (state) => state?.community?.showCreateFeed;
export const getCreatePoint = (state) => state?.community?.createPoint;
export const getSearchResult = (state) => state?.community?.[AT.SEARCH_TAGS].getOrElse(false);
export const getFeed = (state) => state?.community?.getFeed;
export const getComments = (state) => state?.community?.getComments;
export const getReplies = (state) => state?.community?.getReplies;
export const getFireReload = (state) => state?.community?.fireReload;
export const getFeedDetailItem = (state) => state?.community?.real_price_detail_item;
export const getUserHistoryTarget = (state) => state?.community?.userHistoryTarget;
export const getMyLocation = (state) => state?.community?.myLocation;
export const getPhoneNumber = (state) => state?.community?.getPhoneNumber;

//test
// export const replyMode = (state) => state?.community?.replyMode;
export const setFeedStatus = (state) => state?.community?.setFeedStatus;
export const commentStatus = (state) => state?.community?.commentStatus;
export const moreCommentStatus = (state) => state?.community?.moreCommentStatus;
export const createdFeed = (state) => state?.community?.createdFeed;
export const deletedFeeds = (state) => state?.community?.deletedFeeds;

export const localComplexTag = (state) => state?.community?.localComplexTag;
