import { put, select, call, all } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from './selectors';
import apis from '@/apis';
import { STATUS } from '../utils';

//우리집 설정
export function* getUnreadNotiCount() {
  try {
    const result = yield apis.notificationApi.getUnreadNotiCount();
    yield put(actions.getUnreadNotiCountSuccess(result?.unread_count));
  } catch (e) {
    yield put(actions.getUnreadNotiCountFailure(e.message));
  }
}
export function* getMyHomeData({ idx }) {
  try {
    const payload = {
      myHomeIdx: idx,
    };
    const result = yield apis.myApi.getMyHomeData(payload);
    yield put(actions.selectedComplex(result[0]));
    yield put(allActions.selling.updateSavedForm(result[0]));
  } catch (e) {
    yield put(actions.getMyHomeDataFailure(e.message));
  }
}
export function* getMyHomesData({ data }) {
  if (!data) {
    data = {
      goods: 2,
      recentTrade: 2,
    };
  }
  const { goods, recentTrade } = data;
  try {
    yield put(actions.getMyHomesDataLoading());
    const payload = {
      goods: goods ?? 2,
      recentTrade: recentTrade ?? 2,
    };
    const result = yield apis.myApi.getMyHomesData(payload);
    yield put(actions.getMyHomesDataSuccess(result));
  } catch (e) {
    yield put(actions.getMyHomesDataFailure(e.message));
  }
}
//관심매물
export function* getMyInterestGoods() {
  try {
    yield put(actions.getMyInterestGoodsLoading());

    const result = yield apis.myApi.getMyInterestGoods();
    yield put(actions.getMyInterestGoodsSuccess(result));
  } catch (e) {
    yield put(actions.getMyInterestGoodsFailure(e.message));
  }
}
export function* saveMyHome({ savedForm, onSuccess }) {
  try {
    const selected = savedForm;
    // const selected = yield select(allActions.selling.getSavedForm());

    const payload = {
      tb_cb_complex_idx: selected.tb_cb_complex_idx ? selected.tb_cb_complex_idx : selected.complex_idx,
      dong: selected.goods_dong,
      ho: selected.goods_ho,
      area_no: selected.area_no,
      owner_yn: selected.owner_check === false ? 'N' : 'Y',
    };
    const result = yield apis.myApi.saveMyHome(payload);
    yield put(actions.saveMyHomeSuccess(result));
    onSuccess();
  } catch (e) {
    yield put(actions.saveMyHomeFailure(e.message));
  }
}
export function* sortMyHome() {
  try {
    const myHomes = yield select(selectors.getMyHomeData);

    let payload = {};
    myHomes.map((i, k) => {
      //console.log(i.tb_ub_my_home_idx, i.complex.complex_name);
      //console.log(k + 1);
      var index = i.tb_ub_my_home_idx + '';
      payload[index] = k + 1;
    });

    const result = yield apis.myApi.adjustPriority('myHome', payload);
    yield put(actions.getMyhomes());
  } catch (e) {
    yield put(actions.saveMyHomeFailure(e.message));
  }
}
export function* sortMyComplex() {
  try {
    const myCps = yield select(selectors.getMyCpsData);
    let payload = {};
    myCps.map((i, k) => {
      var index = i.tb_ub_interest_complex_idx + '';
      payload[index] = k + 1;
    });

    const result = yield apis.myApi.adjustPriority('interestComplex', payload);
    yield put(actions.getMyCps());
  } catch (e) {
    yield put(actions.saveMyHomeFailure(e.message));
  }
}
export function* sortMyArea() {
  try {
    const myArea = yield select(selectors.getMyAreaData);

    let payload = {};
    myArea.map((i, k) => {
      var index = i.tb_ub_interest_district_idx + '';
      payload[index] = k + 1;
    });

    const result = yield apis.myApi.adjustPriority('interestDistrict', payload);
    yield put(actions.getMyAreas());
  } catch (e) {
    yield put(actions.saveMyHomeFailure(e.message));
  }
}
export function* saveModifyMyHomeData({ onSuccess }) {
  try {
    const modiData = yield select(selectors.getSelectedComplex);
    const payload = {
      tb_ub_my_home_idx: modiData.tb_ub_my_home_idx,
      tb_cb_complex_idx: modiData.tb_cb_complex_idx ? modiData.tb_cb_complex_idx : modiData.complex_idx,
      dong: modiData.dong,
      ho: modiData.ho,
      area_no: modiData.area_no,
      owner_yn: modiData.owner_yn,
    };
    const result = yield apis.myApi.saveModifyMyHome(payload);
    yield put(actions.saveModifyHomeSuccess(result));
    onSuccess();
  } catch (e) {
    yield put(actions.saveModifyHomeFailure(e.message));
  }
}
export function* deleteMyHomeData({ idx, onDelete }) {
  try {
    // const deleteData = yield select(selectors.getSelectedComplex);
    const payload = {
      myHomeIdx: idx,
    };
    const result = yield apis.myApi.deleteMyHome(payload);
    yield put(actions.deleteMyHomeSuccess(result));
    onDelete();
  } catch (e) {
    yield put(actions.deleteMyHomeFailure(e.message));
  }
}
//관심지역 설정
export function* getMyAreaData({ idx }) {
  try {
    const payload = {};
    if (idx) payload.interestDistrictIdx = idx;
    const result = yield apis.myApi.getMyAreaData(payload);
    yield put(actions.selectedComplex(result[0]));
    yield put(allActions.selling.updateSavedForm(result[0]));
  } catch (e) {
    yield put(actions.getMyAreaDataFailure(e.message));
  }
}
export function* getMyAreasData() {
  try {
    yield put(actions.getMyAreasDataLoading());

    const result = yield apis.myApi.getMyAreasData();
    yield put(actions.getMyAreasDataSuccess(result));
  } catch (e) {
    yield put(actions.getMyAreasDataFailure(e.message));
  }
}
export function* saveMyArea({ onSuccess }) {
  try {
    const newArea = yield select(selectors.getMyNewArea);
    const payload = {
      b_dong_code: newArea,
    };
    const result = yield apis.myApi.saveMyArea(payload);
    yield put(actions.saveMyAreaSuccess(result));
    onSuccess();
    yield put(actions.getMyAreas());
  } catch (e) {
    yield put(actions.saveMyAreaFailure(e.message));
  }
}
export function* deleteMyArea({ idx, onDelete }) {
  try {
    const payload = {
      interestDistrictIdx: idx,
    };
    const result = yield apis.myApi.deleteMyArea(payload);
    yield put(actions.deleteMyAreaSuccess(result));
    onDelete();
  } catch (e) {
    yield put(actions.deleteMyAreaFailure(e.message));
  }
}

// 관심단지 설정
export function* getMyCpsData({ payload = {} }) {
  try {
    yield put(actions.getMyCpsDataLoading());
    const result = yield apis.myApi.getMyCpsData(payload);
    yield put(actions.getMyCpsDataSuccess(result));
  } catch (e) {
    yield put(actions.getMyCpsDataFailure(e.message));
  }
}

export function* saveMyCp({ onSuccess }) {
  try {
    const cp_idx = yield select(selectors.getMyNewCp);
    const payload = {
      tb_cb_complex_idx: cp_idx,
    };
    const result = yield apis.myApi.saveMyCp(payload);
    yield put(actions.saveMyCpSuccess(result));
    onSuccess();
    yield put(actions.getMyCps());
  } catch (e) {
    yield put(actions.saveMyCpFailure(e.message));
  }
}

export function* deleteMyCp({ idx, onDelete }) {
  try {
    const payload = {
      interestComplexIdx: idx,
    };
    const result = yield apis.myApi.deleteMyCp(payload);
    yield put(actions.deleteMyCpSuccess(result));
    onDelete();
  } catch (e) {
    yield put(actions.deleteMyCpFailure(e.message));
  }
}

export function* getMyGoods({ payload }) {
  try {
    yield put(actions.getMyGoodsLoading());
    const response = yield call(apis.myPageApi.getMySellList2, payload.limit, payload.offset, payload.goodsStatus);
    yield put(actions.getMyGoodsSuccess(response));
  } catch (e) {
    yield put(actions.getMyGoodsFailure(e.message));
  }
}

// [GET] 일반 1:1 문의 질문과 답변을 각각 받아와서 합친 값을 리턴
export function* getMyQNAList({ payload, meta }) {
  const { callback } = meta || {};

  try {
    callback && callback(STATUS.LOADING);

    // 해당 유저가 1:1문의에 올린 질문리스트 가져오기
    const QNAQustions = yield call(apis.myApi.getMyQNAQuestions, payload);

    let QNAList;
    if (QNAQustions?.list) {
      // 가져온 질문리스트에서 답변이 있는 질문들을 뽑아서 답변api 보낼 준비
      const answerRequests = QNAQustions.list
        ?.filter?.((item) => item?.ans === 'y')
        ?.map((item) => {
          const answerPayload = {
            qnaIdx: item.idx,
            qnaType: item.qna_category === 'GD' ? 'GD' : 'GN', //GN이 아니면 모두 GD
          };
          return call(apis.myApi.getMyQNAAnsList, answerPayload);
        });

      // 답변이 필요한 질문들만 답변 api 요청
      const answerResponses = yield all(answerRequests);

      // 가져온 답변을 해당 질문안에 넣어주기
      QNAQustions.list.forEach((question) => {
        const foundAnswer = answerResponses.find((answer) => question.idx === answer[0].qna_idx);
        if (foundAnswer) question.answer = foundAnswer;
      });
      // 질문과 답변이 매칭된 최종 QNAList를 화면으로 보내기
      QNAList = QNAQustions;
    }

    callback && callback(STATUS.SUCCESS, QNAList);
  } catch (e) {
    callback && callback(STATUS.FAILURE);
  }
}
