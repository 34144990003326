import React from 'react';

const List = ({ dataList, displayCount, setSelectedItem, selectedItem }) => {
  return (
    <ul className="goods-list">
      {dataList?.slice(0, displayCount).map((area) => (
        <>
          <li className="goods-item">
            <div className="goods-wrap">
              <div className="goods-select">
                <label className="form radio module-a style-c type-line accent-01 small-1x">
                  <input
                    type="radio"
                    className="form-elem"
                    name="checkListItem"
                    value={area}
                    onChange={() => setSelectedItem(area)}
                    checked={area?.complexCode ? selectedItem?.complexCode == area?.complexCode : selectedItem?.dongCode == area?.dongCode}
                  />
                </label>
              </div>
              <div className="goods-inform">
                <div className="goods-head">
                  <p className="goods-subject">
                    <span className="goods-name">{area?.mainName}</span>
                  </p>
                  {area?.subName && <p className="goods-summary">{area?.subName}</p>}
                </div>
              </div>
            </div>
          </li>
        </>
      ))}
    </ul>
  );
};

export default List;
