import * as AT from './actionTypes';
import Remote from '@/data/remote';

export const MARKET_DATA_STATUS = {
  idle: 'idle',
  pending: 'pending',
};

export const DETAIL_STATE = {
  [AT.GET_DETAIL]: Remote.NotAsked, //상세보기
  areaList: [],
  selectedSize: null, //상세보기 기준 평수(크기)
  selectedPeriod: 3, //상세보기 기준 기간(3년 5년 10년)
  selectedType: 'all', //상세보기 기준 타입(매매, 전세)
  // tradeType: 'all', //상세보기 거래타입
  tradeHistory: null, //거래 히스토리 for 차트
  trade: {}, // 거래목록
  charterPrediction: {},
  tax: {},
  brokerList: [],
  school: [],
  kindergarten: [],
  daycare: [],
  sales: [], //매물목록
  saleDetail: {},
  // 네이버매물 - 작업중
  facilityData: [],
  facilitySchoolData: [],
  facilityCode: [],
  facilityDataUser: {},
  marketData: [],
  marketDataStataus: MARKET_DATA_STATUS.idle,
  selectedMarketItems: null,
  naverData: {
    overviewList: null,
    detailList: null,
    isMoreData: false,
    currentPage: 1,
  },
  saleDetailNaver: null,
  showNaverItems: false,
  fetchNaverStatus: 'idle', // idle | pending
  // 네이버매물 - 작업중
  brokerOfficeList: null,
  [AT.ASK_TO_BROKER]: Remote.NotAsked, //중개사에 문의하기
  schoolInfo: { gubun: null },
  schoolDetail: {},
  [AT.REPORT_COMPLEX_FACILITY]: Remote.NotAsked,
};

export default function buyingtradeHistory(state = DETAIL_STATE, action = {}) {
  switch (action.type) {
    case AT.GET_DETAIL_LOADING:
      // 초기화 & Loading
      return { ...DETAIL_STATE, [AT.GET_DETAIL]: Remote.Loading };
    case AT.GET_DETAIL_SUCCESS:
      return { ...state, [AT.GET_DETAIL]: Remote.Success(action.data) };
    case AT.SET_AREA_LIST:
      return { ...state, areaList: action.list };
    case AT.GET_TRADE_HISTORY_SUCCESS:
      return { ...state, tradeHistory: action.tradeHistory };
    case AT.GET_TRADE_HISTORY_LOADING:
      return { ...state, tradeHistory: null };
    case AT.GET_TRADE_SUCCESS:
      return { ...state, trade: action.trade };
    case AT.CHANGE_SIZE: //size를 이용한 조회는 pyeong 칼럼을 이용한다.
      return { ...state, selectedSize: action.size };
    case AT.CHANGE_TYPE:
      return { ...state, selectedType: action.tradetype };
    case AT.SET_CHARTER_PREDICTION:
      return { ...state, charterPrediction: action.data };
    case AT.SET_TAX:
      return { ...state, tax: action.data?.list };
    case AT.SET_BROKER_LIST:
      return { ...state, brokerList: action.data?.list };
    case AT.SET_SCHOOL:
      return { ...state, school: action.data?.list };
    case AT.SET_DAYCARE:
      return { ...state, daycare: action.data?.list };
    case AT.SET_KINDERGARTEN:
      return { ...state, kindergarten: action.data?.list };
    case AT.GET_SALES_SUCCESS:
      return { ...state, sales: action.data?.rlt };
    case AT.GET_SALES_LOADING:
      return { ...state, loadingSales: action.data };
    case AT.GET_SALE_DETAIL_SUCCESS:
      return { ...state, saleDetail: action?.data };
    // 네이버매물 - 작업중
    case AT.SET_FACILITY_DATA:
      return { ...state, facilityData: action.payload };
    case AT.SET_FACILITY_SCHOOL_DATA:
      return { ...state, facilitySchoolData: action.payload };
    case AT.SET_FACILITY_CODE:
      return { ...state, facilityCode: action.payload };
    case AT.SET_FACILITY_DATA_USER:
      return { ...state, facilityDataUser: action.payload };
    case AT.SET_MARKET_DATA:
      return { ...state, marketData: action.payload };
    case AT.SET_MARKET_DATA_STATUS:
      return { ...state, marketDataStatus: action.payload };
    case AT.SET_SELECTED_MARKET_ITEMS:
      return { ...state, selectedMarketItems: action.items };
    case AT.GET_NAVER_SALES_PENDING:
      return { ...state, fetchNaverStatus: 'pending' };
    case AT.GET_NAVER_SALES_SUCCESS:
      return { ...state, naverData: action.data, fetchNaverStatus: 'idle' };
    case AT.GET_NAVER_SALE_DETAIL_SUCCESS:
      return { ...state, saleDetailNaver: action?.data };
    case AT.TOGGLE_SHOW_NAVER_ITEMS:
      return { ...state, showNaverItems: action?.data };
    // 네이버매물 - 작업중
    case AT.GET_BROKERS_SUCCESS:
      return { ...state, brokerOfficeList: action?.data };
    case AT.GET_BROKER_DETAIL_SUCCESS:
      return { ...state, brokerDetail: action?.data };
    case AT.ASK_TO_BROKER_LOADING:
      return { ...state, [AT.ASK_TO_BROKER]: Remote.Loading };
    case AT.ASK_TO_BROKER_SUCCESS:
      return { ...state, [AT.ASK_TO_BROKER]: Remote.Success(action.data) };
    case AT.ASK_TO_BROKER_FAILURE:
      return { ...state, [AT.ASK_TO_BROKER]: Remote.Failure(action.error) };
    case AT.SET_SCHOOL_DETAIL:
      return { ...state, schoolDetail: action.data };
    case AT.SET_KINDERGARTEN_DETAIL:
      return { ...state, kindergartenDetail: action.data };
    case AT.SET_DAYCARE_DETAIL:
      return { ...state, daycareDetail: action.data };
    case AT.SET_SCHOOL_INFO_WITH_KAKAO:
      return { ...state, schoolInfo: action.data };
    case AT.SET_COMPLEX_FACILITY:
      return { ...state, complexFacility: action.data };
    case AT.REPORT_COMPLEX_FACILITY_SUCCESS:
      return { ...state, [AT.REPORT_COMPLEX_FACILITY]: Remote.Success(true) };
    case AT.INITIALIZE:
      return { ...DETAIL_STATE };
    case AT.UPDATE_NEW_FEED:
      return { ...state, newFeed: action.data };
    case AT.SET_BROKER_LIST_BY_SELECTED_GOODS:
      return { ...state, brokerListBySelectedGoods: action.data };
    default:
      return { ...state };
  }
}
