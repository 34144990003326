/* eslint-disable react/react-in-jsx-scope */
import { all, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import apis from '@/apis';
import roomTypes from '@/services/goods/roomTypes';
import { STATUS } from '@/data/utils';
import { toast } from 'react-hot-toast';

//매물정보
export function* manageGoodsList({ payload }) {
  const { setLoadingStatus, pageNumber } = payload || {};

  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    const keyword = yield select(selectors.mgGoods.getKeyword);
    const category = yield select(selectors.mgGoods.getCategory);
    const tradeTypes = yield select(selectors.mgGoods.getTradetype);
    const price = yield select(selectors.mgGoods.getPrice);
    const price2 = yield select(selectors.mgGoods.getPrice2);
    const areasize = yield select(selectors.mgGoods.getAreasize);
    const status = yield select(selectors.mgGoods.getStatus);
    const brokerageType = yield select(selectors.mgGoods.getBrokerageType);
    const page = yield select(selectors.mgGoods.getManageGoodsPage);
    const glLoading = yield select(selectors.mgGoods.getGoodsListLoading);
    const originalGoodsList = yield select(selectors.mgGoods.getGoodsList);

    const { codes: roomCodes } = roomTypes;
    const all = ['all'];
    const oneRoom = [roomCodes.ope, roomCodes.div, roomCodes.hal, roomCodes.two];

    let realtyType;
    let roomType;
    if (JSON.stringify(category) === JSON.stringify(oneRoom)) {
      realtyType = '';
      roomType = category;
    } else if (JSON.stringify(category) === JSON.stringify(all)) {
      realtyType = '';
      roomType = '';
    } else {
      realtyType = category;
      roomType = '';
    }

    var retradetype = tradeTypes == 'all' ? '' : tradeTypes?.join?.(',');
    const data = {
      gubun: brokerageType == 'all' ? '' : brokerageType,
      realtyType,
      roomType,
      searchText: keyword,
      tradeTypes: retradetype,
      price: price,
      monthlyPrice: price2,
      // situ: situ == 0 ? '' : situ,
      area: areasize,
      // order: order,
      perPage: 20,
      page: pageNumber ? pageNumber : page,
      status: status != 'all' ? status : '',
    };

    yield put(actions.getGoodsListLoading());
    if (glLoading === true) return;

    const result = yield call(apis.brokerPageApi.search, data);
    const list = result.data;
    yield put(actions.setNextData(result.hasNextPage));
    if (list) {
      yield put(actions.setCurrentPage(pageNumber ? pageNumber : page));

      let newList = list;
      if (originalGoodsList && pageNumber) {
        newList = [...originalGoodsList, ...list];
      }
      yield put(actions.getGoodsListSuccess(newList));
    }

    const goodsCnt = yield call(apis.brokerPageApi.getGoodsCnt, data);
    yield put(actions.setGoodsListCount(goodsCnt?.count));
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    yield put(actions.brokerSearchFailure(e));
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
  }
}
//매물상세보기
export function* manageGoodsDetail({ goods_common_idx }) {
  try {
    const param = {
      goodsCommonIdx: goods_common_idx,
    };
    const result = yield call(apis.brokerPageApi.getManageGoodsDetail, param);
    yield put(actions.setGoodsDetail(true, result));
    // const result2 = yield call(apis.brokerPageApi.getGoodsCommonDetail, detail?.goods_common_idx);
    // yield put(actions.setGoodsCommonDetail(result2));
  } catch (e) {
    yield put(actions.setGoodsDetail(false, e.message));
    console.log(e.message);
  }
}

//memo list 불러오기
export function* getGoodsMemo({ goods_common_idx }) {
  try {
    const param = {
      goodsCommonIdx: goods_common_idx,
    };
    const result = yield call(apis.brokerPageApi.getGoodsMemo, param);
    yield put(actions.getGoodsMemoSuccess(result));
  } catch (e) {
    yield put(actions.getGoodsMemoFailure());
    console.log(e.message);
  }
}

//매물 이력 불러오기
export function* getGoodsHistory({ goods_common_idx }) {
  try {
    const param = {
      goodsCommonIdx: goods_common_idx,
    };
    const result = yield call(apis.brokerPageApi.getGoodsHistory, param);
    yield put(actions.getGoodsHistorySuccess(result));
  } catch (e) {
    yield put(actions.getGoodsHistoryFailure());
    console.log(e.message);
  }
}

//중개사 한마디 불러오기
export function* getBrokerComments({ goods_common_idx }) {
  try {
    const param = {
      goodsCommonIdx: goods_common_idx,
    };
    const result = yield call(apis.brokerPageApi.getBrokerComments, param);
    yield put(actions.getBrokerCommentSuccess(result));
  } catch (e) {
    yield put(actions.getBrokerCommentFailure());
    console.log(e.message);
  }
}

//안심번호 콜 내역
export function* getCallReport({ goods_common_idx, setContactHistory }) {
  try {
    const param = {
      goodsCommonIdx: goods_common_idx,
    };
    const result = yield call(apis.brokerPageApi.getCallReport, param);
    const detail = result.rltObj;
    if (detail) {
      yield put(actions.setCallReport(detail));
      setContactHistory(true);
    } else {
      yield put(
        allActions.common.alert({
          contents: '연락한 이력이 없습니다.',
          onConfirm: () => {
            setContactHistory(false);
          },
        })
      );
    }
  } catch (e) {
    yield put(actions.setCallReport(''));
    console.log('get call report fail');
  }
}

//중개사한마디
export function* commentPrc({ param }) {
  try {
    let data;
    let result;
    if (param?.mode == 's') {
      data = { goodsCommonIdx: param.goods_common_idx, contents: param.contents };
      result = yield call(apis.brokerPageApi.savebrokerComment, data);
    } else if (param?.mode == 'e') {
      data = { goodsMemoIdx: param.goods_memo_idx, contents: param.contents };
      result = yield call(apis.brokerPageApi.editbrokerComment, data);
    } else if (param?.mode == 'd') {
      data = { goodsMemoIdx: param.goods_memo_idx };
      result = yield call(apis.brokerPageApi.delbrokerComment, data);
    } else {
      return;
    }
    if (result) {
      yield put(actions.getBrokerCommentSuccess(result));
      yield put(actions.getBrokerComment(param.goods_common_idx));
    } else {
      return;
    }
  } catch (e) {
    yield put(allActions.common.alert({ contents: '중개사 한마디 처리중 오류발생' }));
  }
}

//메모 관련 동작
export function* memoPrc({ param }) {
  try {
    let data;
    let result;
    if (param?.mode == 's') {
      data = {
        memo_target: param.memo_target,
        extra_contents: param.extra_contents,
        goods_common_idx: param.goods_common_idx,
        contents: param.contents,
      };
      result = yield call(apis.brokerPageApi.saveGoodsMemo, data);
    } else if (param?.mode == 'e') {
      data = {
        memo_target: param.memo_target,
        extra_contents: param.extra_contents,
        goods_common_idx: param.goods_common_idx,
        goods_memo_idx: param.goods_memo_idx,
        contents: param.content,
      };
      result = yield call(apis.brokerPageApi.editGoodsMemo, data);
    } else if (param?.mode == 'd') {
      data = { goods_memo_idx: param.goods_memo_idx, goods_common_idx: param.goods_common_idx };
      result = yield call(apis.brokerPageApi.delGoodsMemo, data);
    } else {
      return;
    }
    if (result) {
      // yield put(actions.getGoodsDetail(param.goods_common_idx));
      yield put(actions.getGoodsMemo(param.goods_common_idx));
    }
  } catch (e) {
    console.log(e.message);
  }
}

//매물정보수정
export function* saveModifyGoods({ priceInputs, tradeTypes, callback }) {
  try {
    const goodsdetail = yield select(selectors.mgGoods.getGoodsDetail);
    const goodsCommonIdx = goodsdetail?.goodsCommonData?.goods_common_idx;
    const originalData = goodsdetail?.goodsCommonData?.goods;

    // 수정된 매물 가격과 기존가격이 같은지 비교
    let hasChange = false;
    for (let i = 0; i < originalData?.length; i++) {
      switch (originalData[i]?.trade_type) {
        case 's': {
          if (originalData[i]?.price1 !== priceInputs?.sellPrice) hasChange = true;
          break;
        }
        case 'c': {
          if (originalData[i]?.price2 !== priceInputs?.jeonsePrice) hasChange = true;
          break;
        }
        case 'm': {
          if (originalData[i]?.price2 !== priceInputs?.depositPrice || originalData[i]?.price3 !== priceInputs?.rentPrice) hasChange = true;
          break;
        }
        default:
          break;
      }
    }

    if (originalData?.length !== tradeTypes?.length) {
      hasChange = true;
    }

    if (hasChange) {
      let payload = [];
      for (let i = 0; i < tradeTypes?.length; i++) {
        switch (tradeTypes[i]) {
          case 's': {
            payload?.push({ trade_type: 's', price1: priceInputs?.sellPrice });
            break;
          }
          case 'c': {
            payload?.push({ trade_type: 'c', price2: priceInputs?.jeonsePrice });
            break;
          }
          case 'm': {
            payload?.push({ trade_type: 'm', price2: priceInputs?.depositPrice, price3: priceInputs?.rentPrice });
            break;
          }
          default:
            break;
        }
        yield call(apis.brokerPageApi.saveModifyGoods, { goods: payload }, goodsCommonIdx);
        callback(false); // 매물수정 팝업 닫기 콜백함수
        yield put(
          allActions.common.alert({
            contents: '매물정보를 수정하였습니다.',
          })
        );
        yield put(actions.getGoodsDetail(goodsdetail?.goodsCommonData?.goods_common_idx));
        yield put(actions.getGoodsList());
      }
    } else {
      yield put(
        allActions.common.alert({
          contents: '변경된 사항이 없습니다.',
          onConfirm: () => callback(false),
        })
      );
    }
  } catch (e) {
    yield put(
      allActions.common.alert({
        contents: e.message,
        onConfirm: () => callback(false),
      })
    );
    yield put(actions.getGoodsList());
  }
}
//계약정보체결
export function* saveContractInfo({ goContractDetail }) {
  try {
    const contract_info = yield select(selectors.mgGoods.getContractInfo);
    const details = yield select(selectors.mgGoods.getContractDetails);
    const seller = yield select(selectors.mgGoods.getContractSeller);
    const buyer = yield select(selectors.mgGoods.getContractBuyer);
    const broker = yield select(selectors.mgGoods.getContractBroker);
    let [mc_date] = contract_info?.details?.filter((i) => i?.detailType == 'MC')?.map((i) => i?.schedule);
    let [mc_price] = contract_info?.details?.filter((i) => i?.detailType == 'MC')?.map((i) => i?.price);
    let [bp_date] = contract_info?.details?.filter((i) => i?.detailType == 'BP')?.map((i) => i?.schedule);
    let [bp_price] = contract_info?.details?.filter((i) => i?.detailType == 'BP')?.map((i) => i?.price);
    if (!contract_info?.goodsCommonIdx) {
      yield put(allActions.common.alert({ contents: '매물 정보가 올바르지 않습니다.' }));
      return;
    }
    if (!mc_date) {
      yield put(allActions.common.alert({ contents: '계약 날짜를 입력해주세요.' }));
      return;
    }
    if (!mc_price || mc_price == 0) {
      yield put(allActions.common.alert({ contents: '계약금을 입력해주세요.' }));
      return;
    }

    if (!contract_info?.tradeType) {
      yield put(allActions.common.alert({ contents: '거래유형이 선택되지 않았습니다.' }));
      return;
    }
    if (contract_info?.tradeType) {
      if (contract_info?.tradeType == 's' && !contract_info?.price1) {
        yield put(allActions.common.alert({ contents: '매매 금액을 입력해주세요.' }));
        return;
      }
      if (contract_info?.tradeType == 'c' && !contract_info?.price2) {
        yield put(allActions.common.alert({ contents: '전세 보증금을 입력해주세요.' }));
        return;
      }
      if (contract_info?.tradeType == 'm' && !contract_info?.price2) {
        yield put(allActions.common.alert({ contents: '월세 보증금을 입력해주세요.' }));
        return;
      }
      if (contract_info?.tradeType == 'm' && !contract_info?.price3) {
        yield put(allActions.common.alert({ contents: '월세금을 입력해주세요.' }));
        return;
      }
    }

    let response;
    if (contract_info?.contractIdx) {
      const contractIdx = contract_info?.contractIdx;
      delete contract_info?.contractIdx;
      response = yield call(apis.brokerPageApi.updateContractInfo, contractIdx, contract_info);
      yield put(allActions.brokerPage.mgContract.getContractList());
    } else {
      delete contract_info?.contractIdx;
      response = yield call(apis.brokerPageApi.saveContractInfo, contract_info);
    }

    if (response) {
      yield put(
        allActions.common.alert({
          contents: '계약체결등록이 완료되었습니다.',
          onConfirm: () => {
            goContractDetail(response.contractIdx, response?.goodsCommonIdx);
          },
        })
      );
      // yield put(actions.getGoodsList());
      // yield put(allActions.brokerPage.mgContract.getContractList());
      // setViewOn();
    } else {
      yield put(allActions.common.alert({ contents: '계약체결등록 중 오류가 발생했습니다. 다시 시도해주세요.(1)' }));
    }
  } catch (e) {
    console.log(e.message);
    // yield put(actions.brokerSearchFailure(e));
  }
}
export function* saveExContractInfo({ setViewOn }) {
  try {
    const contract_info = yield select(selectors.mgGoods.getContractInfo);
    //var total_price = contract_info['contract_price'] + contract_info['balance_price'] + contract_info['intermediate_price1'] + contract_info['intermediate_price2'];
    //var total_price = contract_info['trade_type'] == '1' ? contract_info['sale_price'] : contract_info['deposit'];
    let payload = contract_info;
    if (contract_info['trade_type'] != '1') {
      payload = {
        ...payload,
        type: 'S',
        sale_price: '',
        deposit: parseInt(contract_info['deposit']),
        monthly_rent: contract_info['monthly_price'] ? contract_info['monthly_price'] : '',
      };
    } else {
      payload = {
        ...payload,
        type: 'S',
        sale_price: parseInt(contract_info['sale_price']),
        deposit: '',
        monthly_rent: '',
      };
    }
    if (!payload.category) {
      payload = {
        ...payload,
        category: 'EAPT',
      };
    }
    if (payload) {
      const response = yield call(apis.brokerPageApi.saveExContractInfo, payload);
      if (response) {
        yield put(
          allActions.common.alert({
            contents: '계약체결등록이 완료되었습니다.',
            onConfirm: () => {
              setViewOn();
            },
          })
        );
        yield put(actions.setContractInfoIni());
        yield put(actions.getGoodsList());
        yield put(actions.setContractPreType());
        yield put(allActions.selling.clearSavedForm());
      } else {
        yield put(allActions.common.alert({ contents: '계약체결등록 중 오류가 발생했습니다. 다시 시도해주세요.(1)' }));
        yield put(actions.setContractInfoIni());
        return;
      }
    } else {
      yield put(allActions.common.alert({ contents: '계약체결등록 중 오류가 발생했습니다. 다시 시도해주세요.(2)' }));
      yield put(actions.setContractInfoIni());
      return;
    }
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message, onConfirm: () => setViewOn() }));

    yield put(actions.setContractInfoIni());
    //yield put(actions.brokerSearchFailure(e));
  }
}
export function* editContractInfo({ temp }) {
  try {
    if (!temp.goods_idx || !temp.contract_idx) {
      yield put(allActions.common.alert({ contents: '올바르지 않습니다. 다시 시도해주세요.' }));
      return;
    } else {
      const payload = temp;
      const response = yield call(apis.brokerPageApi.saveContractInfo, payload);
      if (response.rltObj === true) {
        yield put(actions.setContractInfoIni());
        yield put(allActions.common.alert({ contents: '계약 내용을 변경하였습니다.' }));
        yield put(actions.getGoodsList());
      } else {
        yield put(
          allActions.common.alert({
            contents: '계약 내용 변경 중 오류가 발생했습니다.',
          })
        );
        return;
      }
    }
  } catch (e) {
    // eslint-disable-next-line react/react-in-jsx-scope
    yield put(
      allActions.common.alert({
        contents: e.message,
      })
    );
  }
}
//키워드검색
export function* searchKeyword({ keyword }) {
  try {
    //const keyword = yield select(selectors.mgGoods.getKeyword);
    const payload = {
      keyword: keyword,
      seletype: 'APT',
    };
    //call api
    const result = yield call(apis.brokerPageApi.keywordSearch, payload);
    yield put(actions.setKeywordSearchResult(result.list));
    //yield put(actions.setKeywordSearchResult(keywordList));
  } catch (e) {
    yield put(actions.brokerSearchFailure(e));
  }
}

//관심매물지정
export function* brokerFavorite({ goods_common_idx }) {
  try {
    yield put(actions.brokerSearchLoading());
    yield call(apis.brokerPageApi.brokerFavorite, { goods_common_idx });
    yield put(actions.getGoodsList());
    yield put(actions.getGoodsDetail(goods_common_idx));
    toast.success('중개사님의 관심매물로 저장되었습니다.');
  } catch (e) {
    yield put(actions.brokerSearchFailure(e));
    toast.error('관심 매물 등록 중 문제가 발생했습니다. 잠시 후에 시도해 주세요.');
  }
}
//관심매물해제
export function* brokerFavoriteCancel({ goods_common_idx }) {
  try {
    yield put(actions.brokerSearchLoading());
    yield call(apis.brokerPageApi.brokerFavoriteCancel, { goods_common_idx });
    yield put(actions.getGoodsList());
    yield put(actions.getGoodsDetail(goods_common_idx));
    toast.success('중개사님의 관심매물 목록에서 제외되었습니다.');
  } catch (e) {
    yield put(actions.brokerSearchFailure(e));
    toast.error('관심 매물 해제 중 문제가 발생했습니다. 잠시 후에 시도해 주세요.');
  }
}

//허위매물신고
export function* fakeSaleReport({ param }) {
  try {
    //call api
    const brokerInfo = yield select(selectors.brokerOfficeInfo.getBrokerInfo);

    const payload = {
      broker_office_idx: brokerInfo.broker_office_idx,
      goods_common_idx: param.goods_common_idx,
      fake_report_type: param.fake_report_type,
      fake_reason: param.fake_reason,
    };
    const response = yield call(apis.brokerPageApi.fakeSaleReport, payload);
    if (response.rltObj > 0) {
      yield put(allActions.common.alert({ contents: '신고를 완료하였습니다.' }));
      yield put(actions.setFakeSaleReport(false));
      yield put(actions.getGoodsList());
    }
  } catch (e) {
    yield put(
      allActions.common.alert({
        contents: '이미 등록된 신고 내용이 존재 합니다.',
        onConfirm: () => {
          put(actions.setFakeSaleReport(false));
          put(actions.getGoodsList());
        },
      })
    );
  }
}
//관련 다윈중개
export function* getAssociatedCommunity({ feedsId }) {
  try {
    const result_data = yield all(feedsId.map((url) => call(apis.brokerPageApi.getAssociatedCommunity, url)));
    // const result_data = yield call(apis.brokerPageApi.getAssociatedCommunity, feedsId);
    yield put(actions.getAssociatedCommunitySuccess(result_data));
  } catch (e) {
    console.log(e.message);
  }
}

export function* resetGoodsDetail({ detailIdx, callback }) {
  try {
    yield put(actions.removeNew(detailIdx));
    callback();
  } catch {
    console.error('saga - failed resetGoodsDetail');
  }
}
