import { OSTYPE } from '@/services/apps/types/OsType';

// 앱 설정 열기
export default function openAppSetting() {
  const ostype = window.dawin_instance?.osType;
  try {
    if (ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS) {
      window.dawin_instance?.bridge.osFunction.executeSettingApp();
    }
  } catch (e) {
    alert('죄송합니다. 앱 설정으로 이동 중 오류가 발생하였습니다.');
    // throw Error(e.message);
  }
}
