import React from 'react';
import Spinner from '../';

const LoadingComp = ({ size, zIndex, minHeight, left }) => {
  return (
    <>
      {/* <div className="mask">
        <style jsx>{`
          .mask {
            position: inherit;
            background-color: white;
          }
        `}</style>
      </div> */}
      <div>
        <style jsx>{`
          position: inherit;
          width: 100%;
          height: 100%;
          // background: white;
          z-index: 100;
        `}</style>
      </div>

      <div className="spinner">
        <div className="lyr_inner">
          <br />
          <br />
          <br />
          {/* <Spinner size="3" color="#9d3115" /> */}
          <Spinner color="#9d3115" size={size} left={left} />
        </div>
        <style jsx>{`
          .spinner {
            z-index:${zIndex ? `${zIndex}` : `5000`}
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 350px;
            min-height: ${minHeight ? minHeight : 100}px;
            max-height: 500px;
            padding-bottom: 20px;
          }
        `}</style>
      </div>
    </>
  );
};

export default LoadingComp;
