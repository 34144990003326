import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectors } from '@/data';
import { TagType } from '@/services/Community';
import constants from '@/pages/My/MyInfo/constants';
import Modal from '@/pages/My/MyInfo/components/Modal';
import ViewSafetyPop from '@/combinedComp/Community/Create/components/ViewSafetyPop';

const AnswerType = ({ tags, setTags, openPhoneChangePop, setOpenPhoneChangePop, current, setCurrent }) => {
  // const [openPhoneChangePop, setOpenPhoneChangePop] = useState(false);
  // const [current, setCurrent] = useState(constants.PHONE_CHANGE);
  const [openSafetyNumberPop, setOpenSafetyNumberPop] = useState(false);

  const writerInfo = useSelector(selectors.auth.getUserInfo);
  const writerPhone = writerInfo?.mbr_phone;
  const writerPhoneWithDash =
    writerPhone !== 'no' && `${writerPhone?.substring(0, 3)}-${writerPhone?.substring(3, 7)}-${writerPhone?.substring(7, 11)}`;
  const selectedFlagTag = tags.filter((item) => item.type === TagType.FLAG_TAG)?.map((item) => item.key);
  const isChatChecked = selectedFlagTag.indexOf('WANT_TO_CHAT') > -1;
  const isCallChecked = selectedFlagTag.indexOf('WANT_TO_CALL') > -1;

  const changePhoneNumber = (e) => {
    e.preventDefault();
    setOpenPhoneChangePop(true);
    setCurrent(constants.PHONE_CHANGE);
  };

  // 기존 로직 유지 - 리팩토링 필요
  const changeFlagType = ({ type }) => {
    const filteredTags = tags?.filter((tag) => tag.type !== TagType.FLAG_TAG || tag.key !== type);
    const already = tags?.filter((tag) => tag.type === TagType.FLAG_TAG && tag.key === type)?.[0];
    const flagTag = tags?.filter((tag) => tag.type == TagType.FLAG_TAG);

    /*
          2번 관련해서, 기본으로 두개 다 켜져있고,
          둘다 켜진 상태에서 하나 누름 → 해당 방법 꺼짐.
          하나만 켜진 상태에서 켜진걸 누름 → 누른건 꺼지고, 반대쪽이 켜짐.
          하나만 켜진 상태에서 꺼진걸 누름 → 둘다 켜짐.
        */

    if (already) {
      if (flagTag?.length == 1) {
        setTags([
          ...filteredTags,
          {
            type: TagType.FLAG_TAG,
            key: type == 'WANT_TO_CALL' ? 'WANT_TO_CHAT' : 'WANT_TO_CALL',
            name: type == 'WANT_TO_CALL' ? 'WANT_TO_CHAT' : 'WANT_TO_CALL',
          },
        ]);
      } else {
        setTags([...filteredTags]);
      }
    } else {
      setTags([...filteredTags, { type: TagType.FLAG_TAG, key: type, name: type }]);
    }
  };

  const viewSafetyNumber = (e) => {
    e.preventDefault();
    setOpenSafetyNumberPop(true);
  };

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="#">
            연락 받을 방법
          </label>
          <p className="form-summary">좋은 매물을 찾으면 다윈중개 중개사가 연락드립니다. 어떤 연락 방법을 선호하시나요?</p>
        </div>
        <div className="form-body">
          <div className="option-list module-a style-a type-a">
            <span className="option-item">
              <label className="form checkbox module-a style-c type-fill accent-01 small-1x">
                <input type="checkbox" className="form-elem" checked={isChatChecked} onChange={() => changeFlagType({ type: 'WANT_TO_CHAT' })} />
                <span className="form-text">다윈중개 채팅</span>
              </label>
            </span>
            <span className="option-item">
              <label className="form checkbox module-a style-c type-fill accent-01 small-1x">
                <input type="checkbox" className="form-elem" checked={isCallChecked} onChange={() => changeFlagType({ type: 'WANT_TO_CALL' })} />
                <span className="form-text">안심번호</span>
              </label>
              <a className="btn-ico small-1x normal-02 attr-help" href="#" onClick={viewSafetyNumber}>
                <span className="btn-text">자세히보기</span>
              </a>
            </span>
          </div>

          <div className="info-board consult-request">
            <div className="board-wrap">
              <div className="board-head">
                {writerPhone !== 'no' ? (
                  <p className="board-summary">
                    현재 등록된 전화번호는
                    <br />
                    <strong className="em">{writerPhoneWithDash}</strong> 입니다.
                  </p>
                ) : (
                  <p className="board-summary">
                    현재 등록된 전화번호가 없습니다.
                    <br />
                    중개사에게 연락 받을 전화번호를 등록해 주세요
                  </p>
                )}
              </div>
              <div className="board-util">
                <div className="button-display module-a style-a type-c">
                  <span className="button-area">
                    <Link className="btn module-a style-b type-line normal-04 medium" to="#" onClick={changePhoneNumber}>
                      <span className="btn-text">변경하기</span>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {openPhoneChangePop && <Modal setIsModalOpen={setOpenPhoneChangePop} current={current} setCurrent={setCurrent} />} */}
      {openSafetyNumberPop && <ViewSafetyPop safetyPop={openSafetyNumberPop} setSafetyPop={setOpenSafetyNumberPop} />}
    </div>
  );
};

export default AnswerType;
