import React, { useState } from 'react';
import ReactDOM from 'react-dom';

/**
 * 팝업 컴포넌트를 async 하게 사용
 * @param {ReactComponent} PopupComponent 팝업 컴포넌트
 * @returns ({ openAsyncPopup, AsyncPopupComponent, resolve })
 */
const useAsyncPopup = ({ PopupComponent }) => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState(() => {});

  const openAsyncPopup = async () => {
    setOpen(true);

    let tempResolve;

    const promise = new Promise((_resolve) => {
      tempResolve = _resolve;
    });

    setResolver({
      resolve: (value) => {
        setOpen(false, 'here');
        tempResolve(value);
      },
    });

    return promise;
  };

  const AsyncPopupComponent = () => {
    if (open) {
      return ReactDOM.createPortal(<PopupComponent />, document.getElementById('modal-root'));
    } else {
      return <></>;
    }
  };

  return { openAsyncPopup, AsyncPopupComponent, resolver };
};

export default useAsyncPopup;
