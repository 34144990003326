import { kakaoClient } from '@/apis/clients';
import router from '@/services/router';

export const api = {
  async getKakaoAddress(payload) {
    try {
      const result = await kakaoClient.get('v2/local/search/address.json' + router.querystring.stringify(payload));
      return result.data;
    } catch (e) {
      throw Error(e.message);
    }
  },
};
