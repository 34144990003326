import React from 'react';

const MoreReplyBtn = ({ isMoreReplies, onMoreReplies }) => {
  return (
    <>
      {isMoreReplies ? (
        <span className="button-area">
          <button className="btn module-a style-b type-line normal-04 large-1x flex" type="button" onClick={onMoreReplies}>
            <span className="btn-text">더보기</span>
          </button>
        </span>
      ) : null}
    </>
  );
};

export default MoreReplyBtn;
