import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as rootSelectors from '../rootSelectors';
import * as selectors from './selectors';
import { store } from '@/data/configureStore';
import { actions as allActions } from '@/data';
import { getCookieByKey, setCookieByKey } from '@/data/cookie';
import apis from '@/apis';
import text from '@/resources/text';
import CONSTANTS from '../../pages/AgentSignUp/constants';
import { STATUS } from '../utils';
import { stringUtil } from '@/services';

// ===================== post ===================== //
// @note request 보내는건 auth dir 에서 이루어짐
// export function * postSignUpReq() {
//     try {
//         const payload = {
//             // category : CONSTANTS.FAQ_OPTIONS[yield select(selectors.getFaqCategoryIdx)] ,
//             // title : yield select(selectors.getFaqTitle),
//             // body : yield select(selectors.getFaqBody)
//         }

//         // 중개사 회원가입 req는 authApi 안에 함께 구현해놓고 사용
//         yield call(apis.authApi.agentSignUp, payload);

//     } catch (e) {
//         yield put(actions.agentSignUpRequestERROR({error: text.agentSignUpText.AGENT_SIGNUP_ERROR}));
//     }
// }

export function* getOfficeList({ searchWord, searchWord2 }) {
  try {
    const payload = {
      //dong_code: yield select(rootSelectors.selling.getSiGunGuCode),
      dong_code: yield select(rootSelectors.selling.getDongRiCode),
      keyword: searchWord ? searchWord : '',
      keyword2: searchWord2 ? searchWord2 : 0,
    };
    yield put(allActions.common.toggleGlobalSpinner(true));
    const { listObj } = yield call(apis.agentSignUpApi.getOfficeList, payload);
    if (!listObj) {
      yield put(actions.setOfficeList([]));
      yield put(allActions.common.toggleGlobalSpinner(true));
    } else {
      yield put(actions.setOfficeList(listObj));
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getOfficeListFail());
    //yield put(actions.agentSignUpRequestERROR({ error: text.agentSignUpText.OFFICE_LIST_ERROR }));
  }
}
export function* getSearchXY({ address }) {
  try {
    const payload = {
      address: address,
    };
    // console.log('getOfficeList',payload)

    const { dataObj } = yield call(apis.agentSignUpApi.getSearchXY, payload);
    if (!dataObj) {
      yield put(actions.setOfficeXY(''));
    } else {
      yield put(actions.setOfficeXY(dataObj.resss));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getAddressList({ address }) {
  try {
    const payload = {
      address: address,
    };
    // console.log('getOfficeList',payload)
    yield put(actions.getAddressListLoading());
    const { dataObj } = yield call(apis.agentSignUpApi.getAddressList, payload);
    if (!dataObj?.resss) {
      yield put(actions.setAddressList(''));
    } else {
      yield put(actions.setAddressList(dataObj?.resss?.result_arr));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getBrokerOfficeList() {
  try {
    const typeText = 'select-list';
    const payload = {
      dong_code: yield select(rootSelectors.agentSignUp.getRecommendDongRiCode),
    };

    const { listObj } = yield call(apis.agentSignUpApi.getBrokerOfficeList, payload);
    if (!listObj) {
      yield put(actions.agentSignUpRequestERROR({ error: text.agentSignUpText.OFFICE_LIST_ERROR }));
      yield put(actions.setBrorkerOfficeList([]));
    } else {
      yield put(actions.setBrorkerOfficeList(listObj));
    }
  } catch (e) {
    yield put(actions.agentSignUpRequestERROR({ error: text.agentSignUpText.OFFICE_LIST_ERROR }));
  }
}
export function* checkCode({ code }) {
  try {
    const payload = {
      code: code,
    };

    const { listObj } = yield call(apis.agentSignUpApi.checkCode, payload);
    if (!listObj) {
      yield put(actions.setCheckCode(''));
    } else {
      yield put(actions.setCheckCode(listObj));
    }
  } catch (e) {
    console.log('error');
  }
}

export function* handleAgentSignUpNextStep({ currentStep, isTemp, changeStep }) {
  currentStep = parseInt(currentStep);
  try {
    const getOfficeInfo = yield select(selectors.getSelectedOfficeInfo);
    const cookie = yield getCookieByKey('agentSignup');

    yield put(actions.handleAgentSignUpNextStepLoading());
    let payload = {
      isTmp: isTemp,
    };
    if (currentStep == 3) {
      payload = {
        stepUrl: 'saveStep1',
        agree: 'Y',
      };
    } else if (currentStep == 4) {
      const getOfficeType = yield select(selectors.getOfficeType);
      const getOfficePhone = yield select(selectors.getOfficePhoneNum);
      const openImg = yield select(selectors.getOpenCertImage);
      const bizImg = yield select(selectors.getCorpCertImage);
      const service_area = yield select(selectors.getSetServiceArea);
      let bizImgResult = null;
      let openImgResult = null;
      if (bizImg.img_full_path) {
        bizImgResult = yield call(apis.agentSignUpApi.getImgIdx, {
          category: 'bizcert',
          img_full_path: bizImg.img_full_path,
        });
      } else {
        yield put(allActions.common.alert({ contents: '이미지 등록 오류' }));
      }

      if (openImg.img_full_path) {
        openImgResult = yield call(apis.agentSignUpApi.getImgIdx, {
          category: 'regcert ',
          img_full_path: openImg.img_full_path,
        });
      } else {
        yield put(allActions.common.alert({ contents: '이미지 등록 오류' }));
      }

      payload = {
        cookies: cookie ? cookie : '',
        stepUrl: 'saveStep2',
        step: 2,
        isTmp: isTemp,
        //broker_office_info_idx: getOfficeInfo.broker_office_info_idx,
        code: getOfficeInfo.code,
        step_status: isTemp == 'Y' ? 'TMP' : 'SAVE',
        isCompany: getOfficeType == 'OFFICE_TYPE_INDI' ? text.agentSignUpText.OFFICE_TYPE_INDI : text.agentSignUpText.OFFICE_TYPE_CORP,
        phone: stringUtil.removeDash(getOfficePhone),
        sido_code: yield select(selectors.getSiDoCode),
        sigungu_code: yield select(selectors.getSiGunGuCode),
        dong_code: yield select(selectors.getDongRiCode),
        regCert: openImgResult?.insertIdx,
        bizCert: bizImgResult?.insertIdx,
        service_area: service_area,
        //테스트용
        // regCert: '1',
        // bizCert: '2',
      };
    } else if (currentStep == 5) {
      const profileImg = yield select(selectors.getProfileImage);
      let profileImgResult = null;
      const getCareer = yield select(selectors.getBrokerCareerText);
      const getPwd = yield select(selectors.getBrokerPassword);
      const getBrokerId = yield select(selectors.getBrokerId);
      const recommend_idx = yield select(selectors.getBrokerOfficeIdx);
      const certificatedInfo = yield select(rootSelectors.common.getCertificatedInfo);
      const dwcd = yield select(selectors.getDwcd);

      // 실제 서비스
      if (!certificatedInfo) {
        yield put(allActions.common.alert({ contents: '휴대폰 인증 오류' }));
        return;
      }
      if (profileImg.img_full_path) {
        try {
          profileImgResult = yield call(apis.agentSignUpApi.getImgIdx, {
            category: 'prfimg',
            img_full_path: profileImg.img_full_path,
          });
        } catch {
          yield put(allActions.common.alert({ contents: '이미지 등록 오류' }));
        }
      }

      payload = {
        stepUrl: `saveStep3`,
        step: 3,
        step_status: isTemp == 'Y' ? 'TMP' : 'SAVE',
        isTmp: isTemp,
        prfimg: profileImgResult?.insertIdx,
        mobile_no: stringUtil.removeDash(certificatedInfo?.mobileno),
        uname: getOfficeInfo?.name,
        birthdate: certificatedInfo?.birthdate,
        genner: certificatedInfo?.gender,
        career: getCareer,
        passwd: getPwd,
        passwd_confirm: getPwd,
        email: getBrokerId,
        sido_code_re: yield select(selectors?.getRecommendSiDoCode),
        sigungu_code_re: yield select(selectors?.getRecommendSiGunGuCode),
        dong_code_re: yield select(selectors?.getRecommendDongRiCode),
        recommend_idx: recommend_idx ? recommend_idx : '',
        cookies: cookie ? cookie : '',
        broker_code: getOfficeInfo.code,
        broker_office_address: getOfficeInfo.address + ' ' + getOfficeInfo.address_detail,
        broker_office_name: getOfficeInfo.broker_office_name,
        dong_code: getOfficeInfo.b_dong_code,
        lat: getOfficeInfo.lat,
        lng: getOfficeInfo.lng,
        dwcd: dwcd,
      };
    }

    if (currentStep > 2) {
      const response = yield call(apis.agentSignUpApi.handleAgentSignUpNextStep, payload);

      if (response.rltObj) {
        if (response.cookies) {
          setCookieByKey('agentSignup', response.cookies);
        }
        yield put(actions.handleAgentSignUpNextStepSuccess(response));

        changeStep(currentStep);

        //마지막단계에서 로그인까지
        if (currentStep === 5) {
          if (response.loginObj) {
            yield put(actions.saveLoginInfo(response.loginObj));
            //yield setSaveIdCookie(getBrokerId, getBrokerId);
          } else {
            yield put(allActions.auth.loginFailure(e));
            yield put(
              allActions.common.alert({
                contents: <>로그인에 실패하였습니다.</>,
              })
            );
          }
        }
      } else {
        yield put(allActions.common.alert({ contents: '회원가입 처리 중 오류가 발생하였습니다.' }));
      }
    } else {
      changeStep(currentStep);
    }
  } catch (e) {
    yield put(actions.handleAgentSignUpNextStepFailure(e.message));
    const content = '회원가입 처리 중 오류가 발생하였습니다.\n' + e.message;
    yield put(
      allActions.common.alert({
        contents: content,
      })
    );
    throw Error(e.message);
  }
}

export function* checkId({ brokerId, changeModalType, toggleModalOpen }) {
  try {
    const result = yield call(apis.agentSignUpApi.checkIdDup, { email: brokerId });
    yield put(actions.chekIdSuccess(result.chemail));
    yield changeModalType(CONSTANTS.EMAIL_CHECKED);
    yield toggleModalOpen(true);
  } catch (e) {
    yield put(actions.chekIdFailure(e.message));
    yield put(
      allActions.common.alert({
        contents: e.message,
      })
    );
  }
}

export function* getAcademyDate({ callback, updateLoading }) {
  try {
    updateLoading(true);
    const result = yield call(apis.agentSignUpApi.getCourses);
    yield put(actions.setAcademyDate(result.data));

    if (callback) {
      callback(result.data);
    }
    updateLoading(false);
  } catch (e) {
    updateLoading(false);
    yield put(allActions.common.alert({ contents: '아카데미 정보를 조회하는 중 오류가 발생하였습니다.' }));
  }
}

export function* checkAcademy({ name, phone }) {
  const { dispatch } = store;
  try {
    const param = {
      name: name,
      phone: phone,
    };
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));
    const loginRes = yield call(apis.agentSignUpApi.checkUser, param);
    //gmh_dev dummy data
    // const loginRes = {
    //   id: 1,
    //   accessToken: 'xxxxxxxxx',
    // };
    setCookieByKey('academy_access', []);
    if (loginRes) {
      setCookieByKey('academy_access', loginRes.accessToken);
      yield put(actions.setCheckResult(loginRes));
      const token = {
        accessToken: loginRes.accessToken,
      };
      const userApplyAcademyInfo = yield call(apis.agentSignUpApi.getApplyAcademyInfo, token);
      console.log({ userApplyAcademyInfo });
      if (userApplyAcademyInfo && userApplyAcademyInfo.length > 0) {
        yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
        yield put(actions.getUserApplyAcademyInfoSuccess(userApplyAcademyInfo[0]));
      } else {
        yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
        yield put(actions.getUserApplyAcademyInfoSuccess());
      }
    } else {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(actions.setCheckResult());
      yield put(
        allActions.common.alert({
          contents: '정보를 불러오는데 오류가 있습니다.(user1)',
          onConfirm: () => {
            dispatch(allActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
      return;
    }
  } catch (e) {
    console.log('fail saga', e);
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
    yield put(
      allActions.common.alert({
        contents: `정보를 불러오는데 오류가 있습니다.(${e.message})`,
        onConfirm: () => {
          dispatch(allActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
    return;
    //yield put(actions.applyAcademycationFailure(e.message));
  }
}
export function* prebill({ setPrePop }) {
  const { dispatch } = store;
  const academy_dwcd = yield select(selectors.getAcademyDwcd);
  setCookieByKey('academy_dwcd', '');
  setCookieByKey('academy_dwcd', academy_dwcd);
  try {
    const userInfo = yield select(selectors.getUserInfo);
    const applyscuccess = yield select(selectors.getApplySuccess);
    const param = {
      id: applyscuccess.id,
      accessToken: userInfo.accessToken,
    };
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));
    const applyPreBillsRes = yield call(apis.agentSignUpApi.applyPreBill, param);

    if (applyPreBillsRes) {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(actions.setPreBillData(applyPreBillsRes));
      setPrePop(true);
      return;
    } else {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(
        allActions.common.alert({
          contents: '아카데미 수강 신청 중 오류가 발생했습니다.(prebill1)',
          onConfirm: () => {
            dispatch(allActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
      return;
    }
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
    yield put(
      allActions.common.alert({
        contents: `아카데미 수강 신청 중 오류가 발생했습니다.(${e.message})`,
        onConfirm: () => {
          dispatch(allActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
    return;
    //yield put(actions.applyAcademycationFailure(e.message));
  }
}
export function* bill({ billId, orderId, paymentKey, amount }) {
  const { dispatch } = store;
  //const academy_dwcd = yield select(selectors.getAcademyDwcd);
  const academy_dwcd = yield getCookieByKey('academy_dwcd');
  try {
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));
    const token = yield getCookieByKey('academy_access');
    if (!token) {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(
        allActions.common.alert({
          contents: '결제요청 중 오류가 발생했습니다.(bill3)',
          onConfirm: () => {
            dispatch(allActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
      return;
    }
    const param = {
      billId: billId,
      providerKey: paymentKey,
      accessToken: token,
      recommendation: academy_dwcd,
    };
    const applyBillRes = yield call(apis.agentSignUpApi.applyBill, param);
    if (applyBillRes) {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      setCookieByKey('academy_dwcd', '');
      yield put(allActions.router.push('/agentSignUp/academy?pop=end_payment'));
    } else {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(
        allActions.common.alert({
          contents: '결제요청 중 오류가 발생했습니다.(bill1)',
          onConfirm: () => {
            dispatch(allActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
      return;
    }
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
    yield put(
      allActions.common.alert({
        contents: `결제요청 중 오류가 발생했습니다.(${e.message})`,
        onConfirm: () => {
          dispatch(allActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
    return;
    //yield put(actions.applyAcademycationFailure(e.message));
  }
}
export function* editAcademy() {
  const { dispatch } = store;
  try {
    const user = yield select(selectors.getUserInfo);
    const userApplyAcademyInfo = yield select(selectors.getUserApplyAcademyInfo);
    const selectedDate = yield select(selectors.getSelectedDate);
    console.log({ userApplyAcademyInfo, selectedDate });
    const editParam = {
      accessToken: user.accessToken,
      courseId: selectedDate.id,
      origin_id: userApplyAcademyInfo.id,
    };
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));
    const editRes = yield call(apis.agentSignUpApi.editAcademy, editParam);
    //gmh_dev dummy data
    // const editRes = {
    //   id: 14,
    //   user: {
    //     id: 12,
    //     name: '오현성',
    //     phone: '01012345678',
    //   },
    //   course: {
    //     id: 11,
    //     title: '1차 교육과정',
    //     capacity: 10,
    //     enrollmentCount: 1,
    //     enroll: {
    //       startAt: 1633824000000,
    //       endAt: 1633996800000,
    //     },
    //     period: {
    //       startAt: 1634169600000,
    //       endAt: 1634342400000,
    //     },
    //   },
    //   status: 'PAID',
    // };
    if (editRes) {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(actions.editAcademySuccess(editRes));
    } else {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(
        allActions.common.alert({
          contents: '아카데미 일정 변경 중 오류가 발생했습니다.(edit1)',
          onConfirm: () => {
            dispatch(allActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
      return;
    }
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
    const error = e.message === '/api-core/failed-to-exchange-enrollment-error' ? '* 현재 해당 신청은 일정을 변경할 수 없는 상태입니다.' : e.message;
    yield put(
      allActions.common.alert({
        contents: `아카데미 일정 변경 중 오류가 발생했습니다.(${error})`,
        onConfirm: () => {
          dispatch(allActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
  }
}

export function* cancelAcademy() {
  const { dispatch } = store;
  try {
    const user = yield select(selectors.getUserInfo);
    const userApplyAcademyInfo = yield select(selectors.getUserApplyAcademyInfo);

    const billParam = {
      accessToken: user.accessToken,
      origin_id: userApplyAcademyInfo.id,
    };
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));
    const getBillRes = yield call(apis.agentSignUpApi.getBill, billParam);
    // const getBillRes = {
    //   id: 'asdfljlj-dfjadsiof',
    //   order: {
    //     userId: 88,
    //     name: '1차 수강신청',
    //     price: 50000,
    //     enrollmentId: 9,
    //   },
    //   payment: {
    //     completeAt: null,
    //   },
    //   refund: {
    //     completeAt: null,
    //   },
    //   status: 'RESERVED',
    // };
    if (getBillRes) {
      const refundParam = {
        bill_id: getBillRes[0].id,
        accessToken: user.accessToken,
      };
      const cancelRes = yield call(apis.agentSignUpApi.cancelAcademy, refundParam);
      //gmhdev dummy data
      // const cancelRes = {
      //   id: 'asdfljlj-dfjadsiof',
      //   order: {
      //     userId: 88,
      //     name: '1차 수강신청',
      //     price: 50000,
      //     enrollmentId: 9,
      //   },
      //   payment: {
      //     completeAt: 1634636794000,
      //   },
      //   refund: {
      //     completeAt: 1634723194000,
      //   },
      //   status: 'REFUNDED',
      // };

      if (cancelRes) {
        yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
        yield put(actions.cancelAcademySuccess(cancelRes));
      } else {
        yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
        yield put(
          allActions.common.alert({
            contents: '신청하신 교육을 취소하는 중에 오류가 발생했습니다.(refund)',
            onConfirm: () => {
              dispatch(allActions.router.replace('/agentSignUp/academy'));
            },
          })
        );
        return;
      }
    } else {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
      yield put(
        allActions.common.alert({
          contents: '아카데미 일정 취소 중 오류가 발생했습니다.(cancel1)',
          onConfirm: () => {
            dispatch(allActions.router.replace('/agentSignUp/academy'));
          },
        })
      );
      return;
    }
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: false }));
    yield put(
      allActions.common.alert({
        contents: `아카데미 일정 취소 중 오류가 발생했습니다.(${e.message})`,
        onConfirm: () => {
          dispatch(allActions.router.replace('/agentSignUp/academy'));
        },
      })
    );
  }
}

export function* applyShareOffice() {
  try {
    const dong = yield select(selectors.getShareNewDong);
    const name = yield select(selectors.getShareNewName);
    const num = yield select(selectors.getShareNewNum);
    const year = yield select(selectors.getShareNewYear);

    let json = '';
    switch (year) {
      case '0':
        json = { career: '없음' };
        break;
      case '1':
        json = { career: '1년 미만' };
        break;
      case '2':
        json = { career: '1-3년' };
        break;
      case '3':
        json = { career: '3년 이상' };
        break;
      default:
        json = { career: '특이사항 없음' };
        break;
    }
    const location_arr = dong.dongri.split(',');
    const payload = {
      ap_name: name,
      ap_phone: num,
      location: location_arr,
      antecedents: { extra_info: json },
    };

    yield put(actions.applyShareOfficeLoading());
    const result = yield call(apis.agentSignUpApi.applyShareOffice, payload);
    if (result.rltObj.yn && result.rltObj.office_yn) {
      yield put(actions.applyShareOfficeSuccess(result.rltObj.rlt));
    } else if (result.rltObj.yn && !result.rltObj.office_yn) {
      yield put(actions.applyShareOfficeSuccess('done'));
    } else {
      yield put(actions.applyShareOfficeFailure(false));
    }
  } catch (e) {
    yield put(actions.applyShareOfficeFailure(e.message));
  }
}

export function* getTourOfficeList() {
  try {
    const result = yield call(apis.agentSignUpApi.getTourOfficeList);

    if (result.rltObj) {
      yield put(actions.getTourOfficeListSuccess(result.rltObj));
    }
  } catch (e) {
    console.log('fail saga', e);
  }
}
export function* applyTourOffice() {
  try {
    const office = yield select(selectors.getTourOffice);
    const name = yield select(selectors.getTourNewName);
    const num = yield select(selectors.getTourNewNum);
    const tourdate = yield select(selectors.getTourNewTourDate);

    const payload = {
      ap_name: name,
      ap_phone: num,
      office_idx: office,
      tour_date: tourdate,
    };
    yield put(actions.setQqqq(false));

    const result = yield call(apis.agentSignUpApi.applyTourOffice, payload);
    if (result.rltObj.exist) {
      yield put(actions.applyOfficeTourSuccess(result.rltObj.rlt.tour_date));
    } else {
      if (result.rltObj === false) {
        yield put(actions.applyOfficeTourFailure(result.rltObj));
      } else {
        yield put(actions.applyOfficeTourSuccess(result.rltObj));
      }
    }
  } catch (e) {
    console.log('fail saga', e);
  }
}

//중개사 추천용 시도, 시군구, 읍면동
export function* getAddrSiDo({ only_available }) {
  try {
    const { list } = yield call(apis.sellingApi.getAddrSiDo, only_available);
    if (!list) {
      // yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
      yield put(actions.setAddrSiDo([]));
    } else {
      yield put(actions.setAddrSiDo(list));
    }
  } catch (e) {
    // yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
  }
}

export function* getAddrSiGunGu({ sido_code, only_available }) {
  try {
    const { list } = yield call(apis.sellingApi.getAddrSiGunGu, {
      si_do_code: sido_code ? sido_code : yield select(selectors.getRecommendSiDoCode),
      only_available,
    });
    if (!list) {
      // yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
      yield put(actions.setAddrSiGunGu([]));
    } else {
      yield put(actions.setAddrSiGunGu(list));
    }
  } catch (e) {
    // yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
  }
}

export function* getAddrDongRi({ si_gun_gu_code, only_available }) {
  try {
    const { list } = yield call(apis.sellingApi.getAddrDongRi, {
      si_gun_gu_code: si_gun_gu_code ? si_gun_gu_code : yield select(selectors.getRecommendSiGunGuCode),
      only_available,
    });
    if (!list) {
      // yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
      yield put(actions.setAddrDongRi([]));
    } else {
      yield put(actions.setAddrDongRi(list));
    }
  } catch (e) {
    // yield put(actions.sellRequestError({ error: text.sellingText.sellingTextCommon.GET_ADDR_ERROR }));
  }
}
