import { apiClient2 } from '@/apis/clients';
import router from '@/services/router';

export const api = {
  async postRealtorReview(data) {
    try {
      return await apiClient2.post('/review-api/v1/reviews', data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getReviewRequest(token) {
    try {
      return await apiClient2.get(`/review-api/v1/review-requests/${token}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async refuseReview(token, data) {
    try {
      return await apiClient2.patch(`/review-api/v1/review-requests/${token}/status/refuse`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 리뷰가 생성되지 않은 매물거래를 위한 api인데 요청하는 방식이 변경됨. 현재는 사용하는 곳 없음. 추후를 위해서 남겨두어야하나?
  async getMyReviewRequest(data) {
    try {
      return await apiClient2.post(`/review-api/v1/review-requests/find/reviewer/self`, data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 리뷰 요약정보 조회
  async getSummaries(payload) {
    try {
      return await apiClient2.post(`/review-api/v1/summaries/bulk`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 리뷰 코멘트 작성
  async patchReviewComment(reviewId, payload) {
    try {
      return await apiClient2.patch(`/review-api/v1/reviews/${reviewId}/comment`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * @param {object} payload
   * - 주요 parameter: targetUserId, paging { limit, offset, direction }
   * - ex. compositeValue[gte]=1&compositValue[lt]=2&createdAt[gt]=1000000000&reviewCaust=CONTRACT&targetUserId=2000&paging[limit]=1&paging[direction]=DESC&paging[offset]=0
   * @returns 리뷰 목록
   */
  async getReviews(payload) {
    try {
      return await apiClient2.get(`/review-api/v1/reviews${router.querystring.stringify(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // home 페이지와 /contractReview에 필요한 api
  async getDawinReviews(payload) {
    try {
      return await apiClient2.get(`/review-api/v1/reviews/details${router.querystring.stringify(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
