import React from 'react';
import { Link } from 'react-router-dom';

const SigunguTag = ({ sigunguTag, handleSigungu }) => {
  return sigunguTag ? (
    <Link className="btn module-a style-c type-fill normal-05 medium" to="#" onClick={handleSigungu}>
      <span className="btn-text">{sigunguTag.name}</span>
    </Link>
  ) : (
    false
  );
};

export default SigunguTag;
