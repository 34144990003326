import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import { STATUS } from '@/data/utils';
import React from 'react';

const useShowLoadingBarOnLoading = () => {
  const showLoadingBarOnLoading = ({ loadingStatus, text }) => {
    if (loadingStatus === STATUS.LOADING) {
      return <CommnonSpinner show={true} position="relative" padding="10px" spinnerWrapperBackground={'transparent'} />;
    } else {
      return text;
    }
  };

  return showLoadingBarOnLoading;
};

export default useShowLoadingBarOnLoading;
