import React from 'react';

const QuickSaleDescription = () => {
  return (
    <div className="guide-board module-a style-a type-a">
      <div className="board-wrap">
        <div className="board-head">
          <p className="board-subject">
            <span className="mark module-a style-c type-fill small-1x attr-distressed-sale symbol-ltr-bolt">
              <span className="mark-text">급매물이란?</span>
            </span>
          </p>
        </div>
        <div className="board-body">
          <p className="para normal-03 small-2x">
            <span className="wbr">실거래가나 시세보다 일정비율 이상 낮은 매물이며,</span> <span className="wbr">시장상황에 따라 변동됩니다.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuickSaleDescription;
