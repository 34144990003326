// import 'react-app-polyfill/stable';
// import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-map/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from '@/data/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { DEV } from './resources/env/index.js';
import TagManager from 'react-gtm-module';

import '@dawinproperty/dawin_apps_bridge';
import load from '@/services/apps/load';
import AuthSession from '@/services/apps/load/AuthSession';
// import { OSTYPE } from './services/apps/types/OsType';

load().then((data) => {
  // window.dawin_instance.osType = OSTYPE.APP_IOS;
  new AuthSession(data);
  const { store, history } = configureStore(data);

  // Google Analytics
  if (!DEV) {
    const tagManagerArgs = {
      gtmId: 'GTM-M5DS559',
    };
    TagManager.initialize(tagManagerArgs);

    // ga4
    // GA4.initialize();
  } else {
    const tagManagerArgs = {
      gtmId: 'GTM-N9J47QG',
    };
    TagManager.initialize(tagManagerArgs);
  }

  // prod 환경에서 console 안나오게
  // https://stackoverflow.com/questions/56276325/how-can-remove-console-log-in-the-production-build-of-a-react-application-create
  if (!DEV) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('dawinWrap')
  );
});
