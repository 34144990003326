import React from 'react';
import classNames from 'classnames';
import useFavoButton from './useFavoButton';

/* 모바일의 관심단지 등록 팝업은 css 문제로 컴포넌트 밖에 분리하여 컨트롤한다. */
const FavoButton = ({ is_favorite, complex_idx }) => {
  // const [toggle, setToggle] = useState(false); //웹은 각자 열림. global 하나로 관리x
  const { isFav, handleClick } = useFavoButton({ coco: 'default', complex_idx, is_favorite });

  return (
    <>
      <button
        id={'favoBtn_' + complex_idx}
        onClick={handleClick}
        onMouseUp={(e) => {
          //모바일 단지 미리보기 카드에 drag 모션 추가되면서
          //onClick이 아닌 mouseUp, touchEnd에 이벤트가 걸려있음
          e.stopPropagation();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
        }}
        className={classNames('btn-img btn-favo', { on: isFav })}></button>
      {/* <FavoBox
        show={(!isMobile && toggle) || (isMobile && openMobileFavoBox === complex_idx)}
        isMobile={isMobile}
        name={complex_name}
        idx={complex_idx}
        handleClose={close}
        handleCancel={cancel}
        setToggle={setToggle}
      /> */}
    </>
  );
};

FavoButton.defaultProps = {
  is_favorite: false,
};
export default FavoButton;
