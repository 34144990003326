export const naver = require('./js/naveridlogin_js_sdk_2.0.0');

export const markerGroup = {
  mk1: require('@/assets_prev/web/images/sub/ico_pointer.png'),
  mk2: require('@/assets_prev/web/images/sub/ico_pointer02.png'),
  mk3: require('@/assets_prev/web/images/sub/ico_pointer02_on.png'),
  mk4: require('@/assets_prev/web/images/sub/ico_pointer03.png'),
  mk5: require('@/assets_prev/web/images/sub/ico_price_s.png'),
  fav: require('@/assets_prev/web/images/sub/icon_like.png'),
};

export const sellingMainImgs = {
  mobile: [
    require('@/assets_prev/mobile/images/img_smain03.png'),
    require('@/assets_prev/mobile/images/img_smain04.png'),
    require('@/assets_prev/mobile/images/img_smain05.png'),
    require('@/assets_prev/mobile/images/img_smain06.png'),
  ],
  web: [
    require('@/assets_prev/web/images/sub/img_submain03.png'),
    require('@/assets_prev/web/images/sub/img_submain04.png'),
    require('@/assets_prev/web/images/sub/img_submain05.png'),
    require('@/assets_prev/web/images/sub/img_submain06.png'),
  ],
};

export const mainImages = {
  mobile: [
    require('@/assets_prev/mobile/images/img_dec05.png'),
    require('@/assets_prev/mobile/images/img_smain03.png'),
    require('@/assets_prev/mobile/images/img_dec02.png'),
    require('@/assets_prev/mobile/images/img_dec03.png'),
  ],
  web: [
    require('@/assets_prev/web/images/main/img_dec05.png'),
    require('@/assets_prev/web/images/main/img_dec01.png'),
    require('@/assets_prev/web/images/main/img_dec02.png'),
    require('@/assets_prev/web/images/main/img_dec03.png'),
  ],
};

// export const reconstDemo = require('@/assets_prev/mobile/images/recontr.gif');
export const reconstDemo = new URL('/src/assets_prev/mobile/images/recontr.gif', import.meta.url);
export const bk_default = require('@/assets_prev/web/images/img_bk/pf_default.png');
export const share_basic = require('@/assets_prev/mobile/images/share_basic.png');
export const share_wh = require('@/assets_prev/web/images/sub/btn_share_wh.png');
