import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
/**
 * 뒤로가기, 제목, 메뉴 header
 */
const GoBackMenuWithTitle = ({ title, goBackTo, type, confirmText }) => {
  const dispatch = useDispatch();
  const onGoBack = () => {
    if (confirmText) {
      dispatch(
        actions.common.confirm({
          contents: confirmText,
          onConfirm: () => {
            if (goBackTo) {
              if (goBackTo == '/agentSignUp') {
                // setCookieByKey('agentSignup', getCookies);
                // dispatch(actions.agentSignUp.handleAgentSignUpNextStep(3, 'Y'));

                dispatch(actions.agentSignUp.agentSignUpReset()); //중개사 회원가입 정보 리셋
                dispatch(actions.uploadImage.agentSignUpReset()); //중개사 회원가입 이미지 리셋 사업자등록증, 개설등록증, 프로필이미지
                dispatch(actions.selling.changeSiDoCode()); //중개사 회원가입 시도, 시군구, 읍면동 리셋
              }
              dispatch(actions.router.replace(goBackTo));
            } else dispatch(actions.router.goBack());
          },
        })
      );
    } else {
      if (goBackTo) {
        dispatch(actions.router.replace(goBackTo));
      } else {
        // 뒤로가기
        if (history.length === 1) {
          // 뒤로 갈 history가 없는 경우 메인으로(공유된 링크로 접속 시)
          dispatch(actions.router.push('/'));
        } else {
          dispatch(actions.router.goBack());
        }
      }
    }
  };
  return (
    <header
      id="header"
      className={type == 'AgentSignUp' && title == '' ? 'header-sumbain' : 'header'}
      style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center' }}>
      <span className="btn-left" style={{ position: 'absolute', zIndex: 10 }}>
        <a className="btn-img btn-back" onClick={onGoBack}>
          뒤로
        </a>
      </span>
      <h2 className="title">{title}</h2>
      {/* <SideNavWithBtn /> */}
    </header>
  );
};

export default GoBackMenuWithTitle;
