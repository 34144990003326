import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const saveNextCursor = (val) => ({
  type: AT.SAVE_NEXT_CURSOR,
  val,
});
export const setPayloadNextCursor = (val) => ({
  type: AT.SET_PAYLOAD_NEXT,
  val,
});
export const refreshAddressTag = () => ({
  type: AT.REFRESH_ADDRESS_TAG,
});
export const changePayload = (tag, value) => ({
  type: AT.CHANGE_COMMUNITY_PAYLOAD,
  tag,
  value,
});
export const saveUserSetAreaList = (arr) => ({
  type: AT.SAVE_USER_SET_AREA_LIST,
  arr,
});
export const getFeeds = (payload) => ({
  type: AT.GET_BROKER_COMMUNITY_FEEDS,
  payload,
});
export const clearFeeds = (payload) => ({
  type: AT.CLEAR_BROKER_COMMUNITY_FEEDS,
  payload,
});
export const getFeedsLoading = () => ({
  type: AT.GET_BROKER_COMMUNITY_FEEDS_LOADING,
});
export const getFeedsSuccess = (data) => ({
  type: AT.GET_BROKER_COMMUNITY_FEEDS_SUCCESS,
  data,
});
export const getFeedsFailure = (error) => ({
  type: AT.GET_BROKER_COMMUNITY_FEEDS_FAILURE,
  error,
});
export const getSafetyNumber = (feedId, writer_name, writer_id, tags, setViewState1, setViewState2) => ({
  type: AT.GET_SAFETY_NUMBER,
  feedId,
  writer_name,
  writer_id,
  tags,
  setViewState1,
  setViewState2,
});
export const getSafetyNumberLoading = () => ({
  type: AT.GET_SAFETY_NUMBER_LOADING,
});
export const getSafetyNumberSuccess = (data) => ({
  type: AT.GET_SAFETY_NUMBER_SUCCESS,
  data,
});
export const getSafetyNumberFailure = (error) => ({
  type: AT.GET_SAFETY_NUMBER_FAILURE,
  error,
});
