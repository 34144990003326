export const parse = function() {
  const params = {};
  const queryString = decodeURIComponent((document.location + '').substring(1));
  const regex = /([^#?&=]+)=([^#&]*)/g;
  let match;
  while ((match = regex.exec(queryString)) !== null) {
    params[match[1]] = match[2];
  }
  return params;
};

export const stringify = (obj) => {
  return Object.keys(obj).reduce(function(str, key, i) {
    let delimiter, val;
    delimiter = i === 0 ? '?' : '&';

    const tempVal = obj[key];
    val = encodeURIComponent(tempVal === 0 ? 0 : tempVal || '');

    if (val === 0 || val) {
      return [str, delimiter, encodeURIComponent(key), '=', val].join('');
    } else if (i === 0) {
      return [str, delimiter].join('');
    } else {
      return str;
    }
  }, '');
};

export const add = (key, value) => {
  const isArray = Array.isArray(key);
  const query = parse();

  if (isArray) {
    key.forEach((item) => {
      query[item.key] = item.value;
    });
  } else {
    query[key] = value;
  }

  return stringify(query);
};

export const remove = (key) => {
  const isArray = Array.isArray(key);
  const query = parse();

  if (isArray) {
    key.forEach((item) => {
      delete query[item];
    });
  } else {
    delete query[key];
  }

  return stringify(query);
};
