import React, { useState } from 'react';
import InterestedAreas from './components/InterestedAreas';

const MyAddressListBtns = ({
  btnList,
  openPopupCallback,
  applyBtnCallback,
  setAddressData,
  setComplexData,
  tags,
  setTags,
  data,
  setDualAddressData,
  setSelectedGoodsTypes,
}) => {
  const [dataList, setDataList] = useState([]);
  const [displayCount, setDisplayCount] = useState(3);
  const [title, setTitle] = useState();

  const handleOpenPopup = async (e, type) => {
    openPopupCallback(e, type, setTitle, setDataList);
  };

  return (
    <div className="button-display module-a style-a type-a">
      <span className="button-area">
        {btnList?.map((button) => (
          <>
            <a
              className="btn module-a style-c type-fill small-1x normal-05"
              href="#selectMyDataFavoritesLocations"
              onClick={(e) => handleOpenPopup(e, button?.type)}>
              <span className="btn-text">{button?.name}</span>
            </a>
          </>
        ))}
      </span>

      {/* 팝업들 */}
      <InterestedAreas
        title={title}
        dataList={dataList}
        applyBtnCallback={applyBtnCallback}
        displayCount={displayCount}
        setDisplayCount={setDisplayCount}
        setAddressData={setAddressData}
        setComplexData={setComplexData}
        data={data}
        setDualAddressData={setDualAddressData}
        tags={tags}
        setTags={setTags}
        setSelectedGoodsTypes={setSelectedGoodsTypes}
      />
    </div>
  );
};

export default MyAddressListBtns;
