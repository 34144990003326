import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '@/data';

const Result = ({ isMobile, setShow }) => {
  const dispatch = useDispatch();
  const list = useSelector(selectors.auth.getFindId2);

  const handleClick = (e) => {
    e.preventDefault();
    // if (isMobile) dispatch(actions.router.goBack());
    // else dispatch(actions.common.closeGlobalDualPopup());
    setShow(false);
  };

  return (
    <>
      <div className="popup-local-body">
        <div className="popup-content">
          <div className="popup-content-body">
            <fieldset className="submit-form module-a type-c medium">
              <legend>아이디찾기 입력서식</legend>
              <div className="form-list">
                <div className="form-item">
                  <div className="form-wrap">
                    <div className="form-body">
                      <div className="data-display">
                        <div className="data-list">
                          {list?.map((item, key) => (
                            <p className="data-item" key={key}>
                              <span className="data-name">{item.mbr_id}</span>
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="popup-local-util">
        <div className="button-display module-a style-a type-d">
          <span className="button-area">
            {/* <button type="submit" className="btn module-b style-a type-line accent-01 large-1x flex" disabled="disabled">
              <span className="btn-text">비밀번호 찾기</span>
            </button> */}
            <button type="submit" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={handleClick}>
              <span className="btn-text">로그인하러 가기</span>
            </button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Result;
