import { useEffect } from 'react';

const useAuthCheck = ({ dispatch, actions, isLoggedIn }) => {
  useEffect(() => {
    // auth check (로그인 직후에는 하지 않아도 됨 -> componentDidMount에 포함)
    if (isLoggedIn) {
      // 유효한 엑세스 토큰인지 체크
      dispatch(actions.auth.checkAuth());
    }
  }, []);
};

export default useAuthCheck;
