import { userNoPhonePopUp, userPhonePopUp } from '@/combinedComp/Community/DetailNew/utils';
import { useDispatch } from 'react-redux';

const useCall = ({ userName, chat, noMembership, hasMembership }) => {
  const dispatch = useDispatch();

  const call = (value) => {
    if (hasMembership) {
      if (value) {
        const phoneNumber = `${value?.substring(0, 4)}-${value?.substring(4, 8)}-${value?.substring(8, 12)}`;
        dispatch(userPhonePopUp({ userName, phoneNumber }));
      } else {
        dispatch(userNoPhonePopUp({ userName, chat }));
      }
    } else {
      noMembership();
    }
  };
  return { call };
};

export default useCall;
