import React from 'react';
export const text = {
  ERROR_TEXT: '중개평가를 등록하던 중 오류가 발생했습니다.',
  MODAL_RATING_POSTED: (
    <>
      <b>중개평가</b>가 완료되었습니다.
      <br />
      감사합니다.
    </>
  ),

  TITLE_TEXT: '중개평가하기',
  UPPER_TEXT: '고객님께서 남겨주시는 소중한 후기는 다른고객님들께 유익한 정보가 됩니다.',
  DONE_TEXT: '중개평가완료',

  // @todo 문구 정해야함
  RATING_NO_REPLY_TXT: '점수 항목은 모두 입력해주세요.',
  // 중개평가 본문 //
  Q1: '1. 친절하고 신뢰를 드렸나요?',
  Q2: '2. 전문적 지식과 경험을 가지고 잘 설명해 주었나요?',
  Q3: '3. 문의하신 내용에 대해 빠른 응대를 드렸나요?',
  Q4: '4. 거래 당사자간 의견을 잘 조율해 주셨나요?',
  Q5_SELLER: '5. 중개수수료를 요구 받으신 적이 있으신가요?(공동중개시 > 공동중개요율을 초과해 요구 받으신 적이 있나요?)',
  Q5_BUYER: '5. 약정된 중개수수료 이외의 다른 금품 등을 요구 받은적이 있으신가요?',
  Q6: '6. 중개사에게 하고싶은 말이나 평가하는 이유를 작성해주세요.',

  Q1_NO_MEM: '1. 친절하고 신뢰를 드렸나요?',
  Q2_NO_MEM: '2. 전문적 지식과 경험을 가지고 잘 설명해 주었나요?',
  Q3_NO_MEM: '3. 문의하신 내용에 대해 빠른 응대를 드렸나요?',
  Q4_NO_MEM: '4. 거래 당사자간 의견을 잘 조율해 주셨나요?',
  Q5_NO_MEM: '5. 약정된 중개수수료 이외의 다른 금품 등을 요구 받은적이 있으신가요?',
  Q6_NO_MEM: '6. 중개사에게 하고싶은 말이나 평가하는 이유를 작성해주세요.',
};
