import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

const INITIAL_STATE = {
  // error: '',
  // itemInfo: CONSTANTS.ITEM_INFO_FORMAT,
  // [AT.ADDRESS_CALL_REQUEST]: Remote.NotAsked, //요청상태_전화로 집내놓기
  // [AT.ADDRESS_MANUAL_GET_STEP_DATA]: Remote.NotAsked, //요청상태_직접 집내놓기
  // [AT.ADDRESS_MANUAL_HANDLE_NEXT_STEP]: Remote.NotAsked,
  // lastCategory: 0,
  // lastStep: 0,
  // // ===================== Step 1 ===================== //
  // regType: 1, // 1: 본인, 2: 세입자
  // hostPhone: '', // 세입자인 경우에만
  // itemType: CONSTANTS.APARTMENT, // APT, OFT

  si_do_code: '',
  si_gun_gu_first_code: '',
  si_gun_gu_code: '',
  si_gun_gu_name: '',
  si_gun_gu_name_second: '',
  // 시군구 두번째 검증 로직 시 넘겨줄 4자리 코드 (0000)
  si_gun_gu_four_digit_code: '',
  dong_ri_code: '',
  dong_ri_first_code: '',
  // 동리 두번째 검증 로직 시 넘겨줄 8자리 코드 (00000000)
  dong_ri_eight_digit_code: '',
  si_do_list: [],
  si_gun_gu_list: [],
  // 시군구 2번째 리스트가 있을수도 있고 없을 수도 있다.
  si_gun_gu_list_second: [],
  dong_ri_list: [],
  dong_ri_list_second: [],
  //@note 분석 > 랭킹의 리스트 팝업에서, 다수의 시,군을 선택하여 랭킹을 산출하기 때문에, 추가해놓음
  si_gun_gu_code_selected_list: [],
  ri_code: '',

  // TODO: validation 하는 코드 (si_gun_gu_first_code , dong_ri_first_code , ri_code 우선 여기서 savedForm처럼 하나로 관리하자)
  validationCodes: [],
  // APT, OFT 공용
  complexIdx: 0,
  complexInfo: {},
  [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.NotAsked,
  [AT.ADDRESS_GET_COMPLEX_LOCATION]: Remote.NotAsked,
  [AT.ADDRESS_GET_COMPLEX_DONG_LIST]: Remote.NotAsked,

  /**
   * TODO: 아래state부터 필요 없을듯. selling 바라보고 있어서..
   */

  // complexIdx: null, // 임의로 하나 받아옴
  // // @note 위치, 동 목록 등을 한 번에 받으려고 설정해놓았던 값,
  // // 근데 v2에서는 한 번에 불러오는 형태가 아닌, 부분부분 불러오는 형태인 것 같음
  // complexInfo: {}, // 위치 정보를 제외한 여러가지 정보를 담고있음. APT, OFT 공용
  // [AT.ADDRESS_GET_COMPLEX_LOCATION]: Remote.NotAsked,
  // [AT.ADDRESS_GET_COMPLEX_DONG_LIST]: Remote.NotAsked,
  // dongNo: '',
  // floor_list: [],
  // floorNo: '',
  // ho_list: [],
  // hoNo: '',
  // itemSizeList: [],
  // itemSize: 0,
  // isForSale: false,
  // salesPrice: 0,
  // isForJeonSae: false,
  // jeonSaePrice: 0,
  // isForLease: false,
  // leasePriceDeposit: 0,
  // leasePriceFee: 0,
  // // ===================== Step 2 ===================== //
  // optionInfo: CONSTANTS.OPTION_LIST,
  // itemAdvantage: CONSTANTS.ADVANTAGE_LIST,
  // itemAdvantageETC: '기타선택시 입력하세요.',
  // ownerNote:
  //   '빠른 거래를 위해 장점 위주로 간략하게 설명해주세요. \n ex1) 1년전 인테리어 공사로 깨끗합니다 \n ex2) 고층이라 조망권이 좋습니다.\n ex3) 단지내 어린이집이 있어 아이 키우기 너무 좋아요 등',
  // // ===================== Step 3 ===================== //
  // // ===================== Step 4 ===================== //
  // selectedAgentsList: [],
  // focusedAgentIdx: 0,
  // mapCenter: null,
  // // ===================== Step 5 ===================== //
  // sell_manual_request_payload: {},
  // // [[NEW LOGIC]]-----------------------------------------------------------
  //  savedForm: null,
  // complexBrokerList: null,
  // facilityCodeList: [],
  // // [[NEW LOGIC]]-----------------------------------------------------------
};

export default function addressRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // 주소 clear
    case AT.ADDRESS_CLEAR: {
      return {
        ...state,
        si_do_code: '',
        si_gun_gu_code: '',
        si_gun_gu_first_code: '',
        si_gun_gu_four_digit_code: '',
        dong_ri_code: '',
        dong_ri_first_code: '',
        dong_ri_eight_digit_code: '',
        ri_code: '',
        si_gun_gu_list: [],
        si_gun_gu_list_second: [],
        dong_ri_list: [],
        dong_ri_list_second: [],
      };
    }

    case AT.ADDRESS_GET_ADDR_SiDo: {
      return {
        ...state,
      };
    }
    case AT.ADDRESS_SET_ADDR_SiDo: {
      return {
        ...state,
        si_do_list: action.list,
      };
    }
    case AT.ADDRESS_CHANGE_SiDoCode: {
      let latlng = state.si_do_list.find((el) => el.code == action.si_do_code) || {};
      return {
        ...state,
        si_do_code: action.si_do_code,
        si_do_name: action.si_do_name,
        si_gun_gu_list: [],
        si_gun_gu_list_second: [],
        dong_ri_list: [],
        si_gun_gu_code: '',
        si_gun_gu_name: '',
        dong_ri_code: '',
        dong_ri_name: '',
        [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.Success([]),
        lat: latlng?.lat,
        lng: latlng?.lng,
      };
    }
    case AT.ADDRESS_CHANGE_SiDoName:
      return { ...state, si_do_name: action.si_do_name };

    case AT.ADDRESS_SET_ADDR_SiGunGu: {
      // checked 항목은 분석 > 랭킹 페이지에서 사용하기 위해 넣는다 (복수선택 사용할 경우를 위해)
      let temp = [];
      if (action.list) {
        action.list.forEach((el) => {
          el['checked'] = true;
          temp.push(el);
        });
      }
      return {
        ...state,
        si_gun_gu_list: [...temp],
      };
    }

    // 시군구 1차, 동리 1차 코드 저장하고 있기
    case AT.ADDRESS_SET_SiGunGuFirstCode: {
      return { ...state, si_gun_gu_first_code: action.si_gun_gu_first_code };
    }

    case AT.ADDRESS_SET_DongRiFirstCode: {
      return { ...state, dong_ri_first_code: action.dong_ri_first_code };
    }

    // ri-code 세팅
    case AT.ADDRESS_SET_RiCode: {
      return { ...state, ri_code: action.ri_code };
    }

    /**
     * @장혁진 추가
     * 시군구 2 리스트 있으면 si_gun_gu_list_second에 추가
     */
    case AT.ADDRESS_SET_ADDR_SiGunGuSecond: {
      let arr = action.list;
      let sigunguName = '';
      let regexAllCase;
      const sigunguFourCode = state.si_gun_gu_four_digit_code;
      const sigunguList = state.si_gun_gu_list;

      sigunguList.forEach((el) => {
        if (el.sigungu_code === sigunguFourCode) {
          sigunguName = el.name;
          regexAllCase = new RegExp(sigunguName, 'gi');
        }
      });

      arr.map((a) => {
        a.sigungu = a.sigungu.replace(regexAllCase, '');
        a.sigungu.substr(0);
      });

      return { ...state, si_gun_gu_list_second: arr };
    }
    case AT.ADDRESS_CHANGE_SiGunGuCode: {
      let latlng = state.si_gun_gu_list.find((el) => el.code == action.si_gun_gu_code) || {};
      return {
        ...state,
        si_gun_gu_code: action.si_gun_gu_code,
        si_gun_gu_name: action.si_gun_gu_name,
        dong_ri_list: [],
        dong_ri_list_second: [],
        dong_ri_code: '',
        dong_ri_name: '',
        [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.Success([]),
        lat: latlng?.lat,
        lng: latlng?.lng,
      };
    }
    case AT.ADDRESS_CHANGE_SiGunGuName:
      return { ...state, si_gun_gu_name: action.si_gun_gu_name };
    case AT.ADDRESS_CHANGE_SiGunGuNameSecond:
      return { ...state, si_gun_gu_name_second: action.si_gun_gu_name_second };

    // 법정동코드 분리
    case AT.ADDRESS_CHANGE_SiGunGuFourDigitCode: {
      return {
        ...state,
        si_gun_gu_four_digit_code: action.si_gun_gu_four_digit_code,
      };
    }

    case AT.ADDRESS_CHANGE_SiGunGuEightDigitCode: {
      return {
        ...state,
        dong_ri_eight_digit_code: action.dong_ri_eight_digit_code,
      };
    }
    case AT.ADDRESS_SET_ADDR_DongRi: {
      return {
        ...state,
        dong_ri_list: action.list,
      };
    }
    case AT.SET_ADDRESS_SELECTED_SI_GUN_GU_CODE_LIST: {
      const idx = state.si_gun_gu_code_selected_list.findIndex((el) => el == action.code);
      const originalState = state.si_gun_gu_code_selected_list[idx]['checked'];
      state.si_gun_gu_code_selected_list[idx]['checked'] = !originalState;

      return {
        ...state,
      };
    }
    // 동리 1차 변경시(읍/면/동코드)
    case AT.ADDRESS_CHANGE_DongRiCode: {
      let latlng = state.dong_ri_list.find((el) => el.code == action.dong_ri_code) || {};
      return {
        ...state,
        dong_ri_code: action.dong_ri_code,
        dong_ri_name: action.dong_ri_name,
        dong_ri_eight_digit_code: action.dong_ri_eight_digit_code,
        dong_ri_list_second: action.dong_ri_list_second,
        [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.Success([]),
        lat: latlng?.lat,
        lng: latlng?.lng,
      };
    }
    // 동리 2차가 있고 변경시 (리/가)
    case AT.ADDRESS_SET_ADDR_DongRiSecond: {
      return {
        ...state,
        dong_ri_list_second: action.list,
      };
    }

    // // ============================ 단지 목록 받기 && 단지 선택 && 단지 정보, 위치 정보 ============================ //
    case loading(AT.ADDRESS_GET_COMPLEX_LIST):
      return { ...state, [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.Loading };
    case success(AT.ADDRESS_GET_COMPLEX_LIST):
      return { ...state, [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.Success(action.complexList) };
    case failure(AT.ADDRESS_GET_COMPLEX_LIST):
      return { ...state, [AT.ADDRESS_GET_COMPLEX_LIST]: Remote.Failure(action.error), error: action.error };

    default: {
      return { ...state };
    }
  }
}
