import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { FeedType } from '@/services/Community';
import CallBtn from './CallBtn';
import ChatBtn from './ChatBtn';
import { useCall, useChat, useGetSafeCallNumber } from './hooks';
import { Link } from 'react-router-dom';
import { infoPopup } from '@/publish/Front/_Component/popup/InfoPopup';
import useCheckValidity from './hooks/useCheckValidity';
import { membershipUtil } from '@/services';

const CallChatBtns = ({ feed, userName }) => {
  const dispatch = useDispatch();

  const typeArray = feed?.tags?.map((item) => item.key);
  const phoneData = useSelector(selectors.community.getPhoneNumber);
  const userInfo = useSelector(selectors.auth.getUserInfo);

  // 유저가 구해줘다윈 작성시 "전화하기" 요청을 했다면 상세페이지 열릴때 안심번호 조회
  useGetSafeCallNumber({ typeArray, userInfo, feed });

  const routeToMembershipExPage = (e) => {
    e.preventDefault();
    dispatch(actions.router.push('/brokerPage/membership'));
    infoPopup.inactive('infoPopup');
    dispatch(actions.common.clearAlert());
  };

  const membershipInfo = useSelector(selectors.payment.getMyMembershipInfo);
  const hasMembership = !!membershipUtil.realtor.getBrokerMembership(membershipInfo);

  const noMembership = () => {
    dispatch(
      actions.common.alert({
        contents: (
          <>
            <div className="board-body">
              <p className="para">
                <span className="wbr">구해줘다윈 손님과 연락하기 기능은 멤버십 중개사회원에게만 제공됩니다.</span>
              </p>
            </div>
            <div className="board-util">
              <div className="button-display module-a style-a type-c">
                <span className="button-area">
                  <Link
                    className="btn module-a style-c type-fill accent-03 small symbol-rtl-chevron-right flex"
                    to="#"
                    onClick={routeToMembershipExPage}>
                    <span className="btn-text">멤버십 자세히보기</span>
                  </Link>
                </span>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  // 채팅하기 함수
  const { chat } = useChat({ feed, noMembership, hasMembership, userInfo });

  // 전화하기 함수
  const { call } = useCall({ userName, chat, noMembership, hasMembership });

  const handleCall = (e) => {
    e.stopPropagation();
    e.preventDefault();
    call(phoneData);
  };

  return useCheckValidity({ feed }) ? (
    <span className="data-item advice">
      <span className="head">상담</span>
      <span className="body">
        <CallBtn typeArray={typeArray} phoneData={phoneData} call={handleCall} />
        <ChatBtn typeArray={typeArray} chat={chat} />
      </span>
    </span>
  ) : (
    false
  );
};

export default CallChatBtns;
