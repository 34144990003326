import { put, call } from 'redux-saga/effects';
import * as actions from './actions';
import apis from '@/apis';
import { STATUS } from '../utils';

// const ALLOW_IMAGE_TYPE = ['image/jpeg', 'image/jpg', 'image/png'];
// const ALLOW_MAX_SIZE = 6 * 1024 * 1024; // base64 length 최대 6MB

export function* uploadResource({ resources, callback }) {
  try {
    yield put(actions.uploadResourceLoading());

    callback({ status: STATUS.LOADING });

    const files = [...resources];

    const fileInfoList = [];

    for (let i = 0; i < files.length; i++) {
      const signedUrl = yield call(apis.resourceApi.getSingedUrl);
      fileInfoList.push({ signedUrl, file: files[i] });
    }

    const filePromises = fileInfoList.map(({ signedUrl, file }) => {
      return new Promise((resolve, reject) => {
        async function send() {
          try {
            // const signedUrl = await apis.resourceApi.getSingedUrl();
            await apis.resourceApi.uploadImageToS3(signedUrl.url, file);
            resolve(signedUrl);
          } catch (err) {
            reject(err);
          }
        }

        send();
      });
    });

    const result = yield Promise.all(filePromises);

    callback({ status: STATUS.SUCCESS, result });
  } catch (e) {
    yield put(actions.uploadResourceFailure('resource upload error\n' + e.message));
    callback({ status: STATUS.FAILURE, error: e.message });
  }
}
