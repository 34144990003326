export const parseCallBack = function() {
  var params = {};
  var queryString = decodeURIComponent((document.location + '').substring(1));
  var regex = /([^#?&=]+)=([^#&]*)/g;
  var match;
  while ((match = regex.exec(queryString)) !== null) {
    params[match[1]] = match[2];
  }
  return params;
};

export const objectToQuerystring = (obj) => {
  return Object.keys(obj).reduce(function(str, key, i) {
    var delimiter, val;
    delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
};

export const addToQuerystring = (key, value) => {
  const query = parseCallBack();
  query[key] = value;
  return objectToQuerystring(query);
};

export const removeFromQuerystring = (key) => {
  const query = parseCallBack();
  delete query[key];
  return objectToQuerystring(query);
};
