import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CommonRealtorIntroPopupSkeleton = () => {
  return (
    <div className="popup-local-body">
      <div className="popup-content">
        <div className="popup-content-body">
          <div className="goods-display module-c style-c type-c">
            <div className="goods-list">
              <div className="goods-item">
                <div className="goods-wrap">
                  <div className="goods-figure">
                    <Skeleton circle width="72rem" height="72rem" />
                  </div>
                  <div className="goods-inform">
                    <a className="goods-info">
                      <p className="data-list">
                        <span className="data-item name">
                          <span className="head">이름</span>
                          <span className="body">
                            <Skeleton width={120} />
                          </span>
                        </span>
                        <span className="data-item utility">
                          <div className="button-display module-a style-a type-c">
                            <span className="button-area">
                              <Skeleton width={120} />
                            </span>
                          </div>
                        </span>
                        <span className="data-item grade style-b">
                          <span className="head">평점</span>
                          <span className="body">
                            <span className="graph"></span>
                            <span className="point">
                              <Skeleton width={120} />
                            </span>
                          </span>
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonRealtorIntroPopupSkeleton;
