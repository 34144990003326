import React from 'react';
import Alarm from './Alarm';
import RealtorBtn from './RealtorBtn';
import LoginBtn from './LoginBtn';
import Mypage from './Mypage';

const User = ({ isMobile, user, setShowLogin }) => {
  return (
    <div className="section page-util">
      <div className="section-wrap">
        <div className="section-head">
          <h2 className="section-subject">
            <span className="section-name">회원 메뉴</span>
          </h2>
        </div>
        <div className="section-body">
          <ul className="navi-list">
            {user ? (
              <>
                <Alarm />
                <Mypage />
                {!isMobile && <RealtorBtn />}
              </>
            ) : (
              <>
                <LoginBtn isMobile={isMobile} setShowLogin={setShowLogin} />
                <RealtorBtn />
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default User;
