import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success, notAsked } from '@/data/utils';
import CONSTANTS from '@/pages/Sale/constants';

const INITIAL_STATE = {
  error: '',
  itemInfo: CONSTANTS.ITEM_INFO_FORMAT,
  [AT.SELL_CALL_REQUEST]: Remote.NotAsked, //요청상태_전화로 집내놓기
  [AT.SELL_MANUAL_GET_STEP_DATA]: Remote.NotAsked, //요청상태_직접 집내놓기
  [AT.SELL_MANUAL_HANDLE_NEXT_STEP]: Remote.NotAsked,
  lastCategory: 0,
  lastStep: 0,
  si_do_code: '',
  si_do_name: '',
  si_gun_gu_code: '',
  si_gun_gu_name: '',
  dong_ri_code: '',
  dong_ri_name: '',
  si_do_list: [],
  si_gun_gu_list: [],
  dong_ri_list: [],
  complexIdx: null, // 임의로 하나 받아옴
  [AT.GET_COMPLEX_LIST]: Remote.NotAsked,
  [AT.GET_COMPLEX_LOCATION]: Remote.NotAsked,
  [AT.GET_COMPLEX_DONG_LIST]: Remote.NotAsked,
  floor_list: [],
  floorNo: '',
  ho_list: [],
  itemSize: 0,
  [AT.GET_NEARBY_AGENTS]: Remote.NotAsked,
  selectedAgentsList: [],
  focusedAgentIdx: 0,
  mapCenter: null,
  sellerType: null,
  savedForm: CONSTANTS.INITIAL_FORM_STATE,
  lastSavedForm: CONSTANTS.INITIAL_FORM_STATE,
};

export default function sellingRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // 전화로 매물등록
    case notAsked(AT.SELL_CALL_REQUEST):
      return { ...state, [AT.SELL_CALL_REQUEST]: Remote.NotAsked };
    case loading(AT.SELL_CALL_REQUEST):
      return { ...state, [AT.SELL_CALL_REQUEST]: Remote.Loading };
    case success(AT.SELL_CALL_REQUEST):
      return { ...state, [AT.SELL_CALL_REQUEST]: Remote.Success(true) };
    case failure(AT.SELL_CALL_REQUEST):
      return {
        ...state,
        [AT.SELL_CALL_REQUEST]: Remote.Failure(action.error),
        error: action.error,
      };
    case AT.INIT_AND_GO_FIRST_STEP:
      return { ...state, itemInfo: CONSTANTS.ITEM_INFO_FORMAT, lastStep: '' };
    case AT.SELL_MANUAL_SET_SELLER_TYPE:
      return { ...state, sellerType: action.sellerType };
    case AT.SELL_MANUAL_STORE_SAVED_FORM:
      return { ...state, savedForm: { ...action.savedForm } };
    case AT.SELL_MANUAL_UPDATE_SAVED_FORM: {
      return { ...state, savedForm: { ...state.savedForm, ...action.payload } };
    }
    case AT.SELL_MANUAL_CLEAR_SAVED_FORM: {
      return { ...state, savedForm: CONSTANTS.INITIAL_FORM_STATE };
    }
    case AT.UPDATE_LAST_SAVED_FORM: {
      return { ...state, lastSavedForm: action.payload };
    }
    // 직접 매물등록 : 각 단계 정보 받아오기
    case loading(AT.SELL_MANUAL_GET_STEP_DATA):
      return { ...state, [AT.SELL_MANUAL_GET_STEP_DATA]: Remote.Loading };
    // 직접 매물등록 페이지 진입 시, last_category&&last_step 라면, 이미 작업중이던 페이지가 있다는 의미
    case success(AT.SELL_MANUAL_GET_STEP_DATA): {
      const { last_category, last_step, tb_cb_complex_idx, sido_code, sigungu_code, dong_code } = action.data;
      if (last_category) {
        return {
          ...state,
          lastCategory: last_category ? last_category : state.lastCategory,
          lastStep: last_step ? last_step : state.lastStep,
          [AT.SELL_MANUAL_GET_STEP_DATA]: Remote.Success(action.data),
          itemInfo: { ...state.itemInfo, ...action.data, category: last_category ? last_category : state.lastCategory },
          complexIdx: tb_cb_complex_idx ? tb_cb_complex_idx : state.complexIdx,
        };
      } else {
        return {
          ...state,
          lastCategory: last_category ? last_category : state.lastCategory,
          lastStep: last_step ? last_step : state.lastStep,
          [AT.SELL_MANUAL_GET_STEP_DATA]: Remote.Success(action.data),
          itemInfo: { ...state.itemInfo, ...action.data },
          complexIdx: tb_cb_complex_idx ? tb_cb_complex_idx : state.complexIdx,
        };
      }
    }
    case failure(AT.SELL_MANUAL_GET_STEP_DATA):
      return {
        ...state,
        [AT.SELL_MANUAL_GET_STEP_DATA]: Remote.Failure(action.error),
        error: action.error,
      };
    // 직접 매물등록 : 각 단계 등록 혹은 임시저장
    case loading(AT.SELL_MANUAL_HANDLE_NEXT_STEP):
      return { ...state, [AT.SELL_MANUAL_HANDLE_NEXT_STEP]: Remote.Loading };
    case success(AT.SELL_MANUAL_HANDLE_NEXT_STEP):
      return { ...state, [AT.SELL_MANUAL_HANDLE_NEXT_STEP]: Remote.Success(true) };
    case failure(AT.SELL_MANUAL_HANDLE_NEXT_STEP):
      return {
        ...state,
        [AT.SELL_MANUAL_HANDLE_NEXT_STEP]: Remote.Failure(action.error),
        error: action.error,
      };

    case AT.SELL_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case AT.SELL_RESET_ERROR_STATE: {
      return {
        ...state,
        error: '',
      };
    }

    case AT.CHANGE_ITEM_INFO:
      if (action.targetDataKey == 'trade_type') {
        switch (action.gubun) {
          case 'add': {
            if (state.itemInfo['trade_type'] == '') {
              state.itemInfo['trade_type'] = action.changeContent;
            } else {
              state.itemInfo['trade_type'] = state.itemInfo['trade_type'] + ',' + action.changeContent;
            }
            break;
          }
          case 'minus': {
            var str = state.itemInfo['trade_type'].toString();
            var arr = str.split(',');
            var new_arr = '';
            for (var i = 0; i < arr.length; i++) {
              if (arr[i] == '') continue;

              if (arr[i].toString() != action.changeContent.toString()) {
                if (new_arr != '') new_arr += ',' + arr[i];
                else new_arr += arr[i];
              }
            }
            state.itemInfo['trade_type'] = new_arr;
            break;
          }
        }
      } else {
        state.itemInfo[action.targetDataKey] = action.changeContent;
      }
      if (action.targetDataKey != CONSTANTS.TARGET_DATA_KEY_GOODS_TYPE) {
        return {
          ...state,
          itemInfo: { [action.targetDataKey]: action.changeContent, ...state.itemInfo, changed: true },
        };
      } else {
        return {
          ...state,
          itemInfo: { [action.targetDataKey]: action.changeContent, ...state.itemInfo },
        };
      }

    // ============================== step 1 세부사항 관련 ============================== //

    case AT.SET_ADDR_SiDo: {
      return {
        ...state,
        si_do_list: action.list,
      };
    }
    case AT.CHANGE_SiDoCode: {
      let latlng = state.si_do_list.find((el) => el.code == action.si_do_code) || {};
      return {
        ...state,
        si_do_code: action.si_do_code,
        si_do_name: action.si_do_name,
        si_gun_gu_list: [],
        dong_ri_list: [],
        si_gun_gu_code: '',
        si_gun_gu_name: '',
        dong_ri_code: '',
        dong_ri_name: '',
        [AT.GET_COMPLEX_LIST]: Remote.Success([]),
        lat: latlng?.lat,
        lng: latlng?.lng,
      };
    }
    case AT.SET_ADDR_SiGunGu: {
      // checked 항목은 분석 > 랭킹 페이지에서 사용하기 위해 넣는다 (복수선택 사용할 경우를 위해)
      let temp = [];
      if (action.list) {
        action.list.forEach((el) => {
          el['checked'] = true;
          temp.push(el);
        });
      }
      return {
        ...state,
        si_gun_gu_list: [...temp],
      };
    }
    case AT.CHANGE_SiGunGuCode: {
      let latlng = state.si_gun_gu_list.find((el) => el.code == action.si_gun_gu_code) || {};
      return {
        ...state,
        si_gun_gu_code: action.si_gun_gu_code,
        si_gun_gu_name: action.si_gun_gu_name,
        dong_ri_list: [],
        dong_ri_code: '',
        dong_ri_name: '',
        [AT.GET_COMPLEX_LIST]: Remote.Success([]),
        lat: latlng?.lat,
        lng: latlng?.lng,
      };
    }
    case AT.SET_ADDR_DongRi: {
      return {
        ...state,
        dong_ri_list: action.list,
      };
    }

    case AT.CHANGE_DongRiCode: {
      let latlng = state.dong_ri_list.find((el) => el.code == action.dong_ri_code) || {};
      return {
        ...state,
        dong_ri_code: action.dong_ri_code,
        dong_ri_name: action.dong_ri_name,
        [AT.GET_COMPLEX_LIST]: Remote.Success([]),
        lat: latlng?.lat,
        lng: latlng?.lng,
      };
    }

    // ============================ 단지 목록 받기 && 단지 선택 && 단지 정보, 위치 정보 ============================ //
    case loading(AT.GET_COMPLEX_LIST):
      return { ...state, [AT.GET_COMPLEX_LIST]: Remote.Loading };
    case success(AT.GET_COMPLEX_LIST):
      return { ...state, [AT.GET_COMPLEX_LIST]: Remote.Success(action.complexList) };
    case failure(AT.GET_COMPLEX_LIST):
      return { ...state, [AT.GET_COMPLEX_LIST]: Remote.Failure(action.error), error: action.error };

    case AT.CHANGE_COMPLEX_IDX: {
      return {
        ...state,
        complexIdx: action.complexIdx,
        [AT.GET_COMPLEX_DONG_LIST]: Remote.Success([]),
      };
    }

    case loading(AT.GET_COMPLEX_LOCATION):
      return { ...state, [AT.GET_COMPLEX_LOCATION]: Remote.Loading };
    case success(AT.GET_COMPLEX_LOCATION):
      return { ...state, [AT.GET_COMPLEX_LOCATION]: Remote.Success(action.complexLocation) };
    case failure(AT.GET_COMPLEX_LOCATION):
      return {
        ...state,
        [AT.GET_COMPLEX_LOCATION]: Remote.Failure(action.error),
        error: action.error,
      };

    // ============================ 단지 상세정보 ============================ //
    case loading(AT.GET_COMPLEX_DONG_LIST):
      return { ...state, [AT.GET_COMPLEX_DONG_LIST]: Remote.Loading };
    case success(AT.GET_COMPLEX_DONG_LIST):
      return {
        ...state,
        [AT.GET_COMPLEX_DONG_LIST]: Remote.Success(action.complexDongList),
      };
    case failure(AT.GET_COMPLEX_DONG_LIST):
      return {
        ...state,
        [AT.GET_COMPLEX_DONG_LIST]: Remote.Failure(action.error),
        error: action.error,
      };
    case AT.SET_FLOOR_LIST: {
      return {
        ...state,
        floor_list: action.list,
      };
    }
    case AT.CHANGE_FLOOR_NO: {
      return {
        ...state,
        floorNo: action.floorNo,
      };
    }
    case AT.SET_HO_LIST: {
      let sortedList = action.list;

      // // Year int 값의 크기순으로 정렬
      const result = sortedList.sort(function(a, b) {
        return a.ho - b.ho;
      });

      return {
        ...state,
        ho_list: result,
      };
    }
    case AT.CHANGE_ITEM_SIZE: {
      return {
        ...state,
        itemSize: action.itemSize,
      };
    }
    // ===================== Step 3 ===================== //
    case AT.SAVE_IMAGE_SUCCESS:
      return {
        ...state,
        itemInfo: { ...state.itemInfo, insideImageCnt: state.itemInfo.insideImageCnt + 1 },
      };

    case AT.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        itemInfo: { ...state.itemInfo, insideImageCnt: state.itemInfo.insideImageCnt - 1 },
      };

    // ===================== Step 4 ===================== //
    case loading(AT.GET_NEARBY_AGENTS):
      return { ...state, [AT.GET_NEARBY_AGENTS]: Remote.Loading };
    case success(AT.GET_NEARBY_AGENTS): {
      return { ...state, [AT.GET_NEARBY_AGENTS]: Remote.Success(action.list) };
    }
    case failure(AT.GET_NEARBY_AGENTS):
      return {
        ...state,
        [AT.GET_NEARBY_AGENTS]: Remote.Failure(action.error),
        error: action.error,
      };

    case AT.CHANGE_SELECTED_AGENTS:
      var selectedAgentList = [];
      if (action.idx == -100) {
        //모두해제
      } else if (state.selectedAgentsList.indexOf(action.idx) > -1) {
        //선택해제
        selectedAgentList = state.selectedAgentsList.filter((item) => item !== action.idx);
      } else {
        //선택
        selectedAgentList = [...state.selectedAgentsList, action.idx];
      }
      return {
        ...state,
        selectedAgentsList: selectedAgentList,
        itemInfo: { ...state.itemInfo, realtorselected: selectedAgentList },
      };

    case AT.SET_SELECTED_AGENTS:
      return { ...state, selectedAgentsList: action.data };

    case AT.CHANGE_FOCUSED_AGENT_INDEX: {
      return {
        ...state,
        focusedAgentIdx: action.idx,
      };
    }

    case AT.CHANGE_MAP_CENTER: {
      return {
        ...state,
        mapCenter: action.info,
      };
    }
    default: {
      return { ...state };
    }
  }
}
