import React from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import { STATUS } from '@/data/utils';

const CommentsLoading = () => {
  const comments = useSelector(selectors.community.getComments);
  const moreCommentStatus = useSelector(selectors.community.moreCommentStatus);
  const hasMoreComments = comments?.next;
  const isLoading = moreCommentStatus === STATUS.LOADING;
  const spinnerStyle = { fontSize: '18px', paddingBottom: '10px', textAlign: 'center', fontWeight: 400, position: 'relative', marginTop: '50px' };

  return hasMoreComments && isLoading ? (
    <div style={spinnerStyle}>
      <CommnonSpinner show={true} position="relative" zIndex={100} spinnerWrapperBackground={'transparent'} spinnerColor={'#808080'} />
    </div>
  ) : (
    false
  );
};

export default CommentsLoading;
