import { appLogEvent } from './appLogger';
import { logEvent } from './index';

export const combinedLogger = ({ logType, from, action, params }) => {
  // api logging
  logEvent({
    logType,
    from,
    action,
    params,
  });

  // app logging
  appLogEvent({ key: logType });
};
