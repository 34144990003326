import React from 'react'
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';

const NavWithAlram2 = ({goBackTo}) => {
    const dispatch = useDispatch();

    const handleBackClick = (e) => {
      e.preventDefault();
      if (goBackTo) {
        dispatch(actions.router.replace(goBackTo));
      } else {
        dispatch(actions.router.goBack());
      }
    };
  
    return (
        <header className='header'>
            <span className="btn-left">
            <a className="btn-img btn-back" onClick={handleBackClick}>
          뒤로
        </a>

            </span>
            <span className="btn-right">
                {/* <span className="alarm">
                <a href="" className="btn-img btn-alarm">알람</a>
                <span className="new">N</span>
                </span> */}
                <a href="" className="btn-img btn-menu">메뉴</a>
            </span>
        </header>
    )
}

export default NavWithAlram2
