import {
  apiClient,
  // , kakaoClient
} from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import CONSTANTS from '../../data/myPage/constants';

export const api = {
  async toggleFavorite(payload) {
    try {
      let path = '';
      if (payload.targetType == 0) {
        path = '/favorite/goods';

        if (payload.isCurrentlyFav == 'n') {
          return await apiClient.post(path + objectToQuerystring({ goodsIdx: payload.itemIdx }));
        } else {
          path += `/${payload.itemIdx}`;
          return await apiClient.delete(path);
        }
      } else {
        path = '/favorite/complex';
        if (payload.isCurrentlyFav == 'n') {
          return await apiClient.post(path + objectToQuerystring({ goodsIdx: payload.itemIdx, areaNoList: payload.areaNoList }));
        } else {
          path += `/${payload.itemIdx}`;
          return await apiClient.delete(path + objectToQuerystring({ areaNoList: payload.areaNoList }));
        }
      }
    } catch (e) {
      throw Error(e.message);
    }
  },

  // ===================== MyInfo ===================== //
  async changePassword(payload) {
    try {
      const prefix = '/auth/password';
      return await apiClient.patch(prefix, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //내 소셜 불러오기
  async getSocialList() {
    try {
      const path = '/my/social';
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // ===================== 집 구하기, MyBuy ===================== //
  async getFavoriteItemsList(payload) {
    try {
      const path = '/personalization/interestGoods';

      return await apiClient.get(
        path
        // @note 요청 param 없음
        // + objectToQuerystring(payload)
      );
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getInterestedComplexList(payload) {
    try {
      const path = '/my/looking/favoriteComplex';

      return await apiClient.get(
        path
        // @note 요청 param 없음
        // + objectToQuerystring(payload)
      );
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getContractedItemsList(payload) {
    try {
      const path = '/my/looking/contractgoods';

      return await apiClient.get(
        path
        // @note 요청 param 없음
        // + objectToQuerystring(payload)
      );
    } catch (e) {
      throw Error(e.message);
    }
  },
  // ===================== MySell ===================== //

  async getMySellItemsList(payload) {
    try {
      const path = '/my/looking/sellinggoods';
      return await apiClient.get(
        path
        // @note 요청 param 없음
        // + objectToQuerystring(payload)
      );

      //   {
      //     "goods_idx": 487,
      //     "category": "OFT",
      //     "trade_type": "1",
      //     "price1": "122000",
      //     "price2": "0",
      //     "price3": "0",
      //     "area1": "48.48",
      //     "area2": "24.68",
      //     "dong": "1",
      //     "floor_kind": "저",
      //     "floor": 3,
      //     "total_floor": 15,
      //     "room_cnt": 1,
      //     "bathroom_cnt": 1,
      //     "animal": null,
      //     "parking_flag": null,
      //     "goods_status": "BL",
      //     "goods_process_status": "PS1",
      //     "room_type": "RO",
      //     "default_img_path": null,
      //     "trade_date": "",
      //     "deleted_date": "",
      //     "complex_name": "노빌리안골드",
      //     "room_type_txt": "원룸(오픈형)",
      //     "broker_office_idx": null,
      //     "isfav": "n",
      //     "iseval": "n",
      //     "real_estate_type": "OFT"
      // },
    } catch (e) {
      throw Error(e.message);
    }
  },

  // @todo <프론트_요청사항> idx:13
  async deleteItem(payload) {
    try {
      const path = '/my/goodsStatusPrc';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // @todo <프론트_요청사항> idx:15
  async regCancelItem(payload, goodsIdx) {
    try {
      const path = '/goods/' + goodsIdx;
      return await apiClient.delete(
        path
        // param 없음
        // + objectToQuerystring(payload)
      );
    } catch (e) {
      throw Error(e.message);
    }
  },

  // @todo <프론트_요청사항> idx:16
  async reRegItem(payload) {
    try {
      const path = '/my/re-registration';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // @todo <프론트_요청사항> idx : 12
  async postReportItemTradeComplete(payload) {
    try {
      const path = '/my/transactionCompletedPrc';
      return await apiClient.post(path, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // ===================== 2.3 MySell ===================== //

  async getMySellList(limit = 10, offset = 0, status) {
    try {
      let path;
      if (status) {
        path = `/goods_common/myPage/overall?limit=${limit}&offset=${offset}&status=${status}`;
      } else {
        path = `/goods_common/myPage/overall?limit=${limit}&offset=${offset}&status=IN&status=SB`;
      }
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getMySellList2(limit = 10, offset = 0, goodsStatus) {
    try {
      let path;
      if (goodsStatus) {
        if (goodsStatus === 'DR' || goodsStatus === 'ER') {
          const another = goodsStatus === 'ER' ? 'DR' : 'ER';
          path = `/goods_common/myPage/overall?limit=${limit}&offset=${offset}&status=${goodsStatus}&status=${another}`;
        } else {
          path = `/goods_common/myPage/overall?limit=${limit}&offset=${offset}&status=${goodsStatus}`;
        }
      } else {
        path = `/goods_common/myPage/overall?limit=${limit}&offset=${offset}&status=IN&status=SB`;
      }
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getMySellCount(statusArr) {
    try {
      let txt = statusArr?.map((item) => 'status=' + item)?.join('&');
      const path = '/goods_common/myPage/overall/count' + '?' + txt;
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getMySellImages(goods_common_idx) {
    try {
      const path = `/goods_common/${goods_common_idx}/image`;
      return await apiClient.get(path);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
