import React from 'react';
import Input from './Input';
import InputWrapper from './Input/Wrapper';
import Result from './Result';
import Tags from './Tags';
import Recommand from './Recommand';
import FilterPopup from './FilterPopup';
import router from '@/services/router';

const DefaultView = (props) => {
  const { show, showFilter, keyword, data, wrapperRef, wrapperClass, showSearchHistory, inputWrapperClass, inputLegend, inputFormName } = props;
  const filterPopupType = router.querystring.parse()?.searchfilter;

  return (
    <>
      <form ref={wrapperRef} className={wrapperClass || 'data-finder'} id="dataFinderMaps" onSubmit={(e) => e.preventDefault()}>
        <InputWrapper legend={inputLegend} wrapperClass={inputWrapperClass} formName={inputFormName}>
          <div className="form-body">
            <div className="form-area">
              <span className="form textfield">
                {/* 검색 */}
                <Input {...props} />
              </span>
            </div>
          </div>
        </InputWrapper>

        {/* 필터 태그 */}
        {showFilter && <Tags />}

        {/* 검색 결과 */}
        {show && keyword && <Result {...props} show={show} data={data} keyword={keyword} wrapperClass="data-board data-finder-autocomplete" />}

        {/* 최근검색어 & 관심단지 */}
        {show && !keyword && <Recommand show={showSearchHistory} {...props} />}

        {/* Mask 검색 영역 밖을 클릭했을 때 결과 닫기 */}
        {/* {show && <div onClick={clickOutside} style={{ position: 'fixed', left: 0, top: '55px', width: '100%', height: '100%' }} />} */}
      </form>
      {showFilter && <FilterPopup key={filterPopupType} {...props} show={location.search?.indexOf('searchfilter') > -1} />}
    </>
  );
};

export default DefaultView;
