import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as aActions from '@/data/rootActions';
import * as selectors from '../selectors';
import apis from '@/apis';

//jointBrokeragelist
export function* getJointBrokerageList() {
  try {
    const page = yield select(selectors.jointBrokerage.getPage);
    const keyword = yield select(selectors.jointBrokerage.getKeyword);
    const payload = {
      page: page,
      keyword: keyword ? keyword : '',
    };
    const response = yield call(apis.brokerPageApi.getJointBrokerageList, payload);
    //const { list } = response;
    yield put(actions.getJointBrokerageListSuccess(response.rltObj));
    yield put(actions.setTotalCnt(response.totalCnt));
    yield put(actions.setKeyword(''));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.getJointBrokerageListFailure(e));
  }
}
export function* getJointBrokerageDetail({ idx }) {
  try {
    const payload = {
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.getJointBrokerageDetail, payload);
    //const { list } = response;
    yield put(actions.getJointBrokerageDetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.getJointBrokerageDetailFailure(e));
  }
}
export function* saveJointReply({ val, pu_idx }) {
  try {
    const payload = {
      board_idx: pu_idx,
      comment: val,
    };
    //call api
    const response = yield call(apis.brokerPageApi.saveJointReply, payload);
  } catch (e) {
    yield put(actions.getJointBrokerageDetailFailure(e));
  }
}
export function* saveJointNew() {
  try {
    const newData = yield select(selectors.jointBrokerage.getNew);
    const payload = {
      title: newData.title,
      contents: newData.cont,
      file_idx: newData.attach,
    };
    //call api
    const response = yield call(apis.brokerPageApi.saveJointNew, payload);
    yield put(actions.getList());
  } catch (e) {
    yield put(actions.getJointBrokerageDetailFailure(e));
  }
}
export function* editJoint({ board_idx, title, content, setViewEditWrite }) {
  try {
    const file_idx = yield select(selectors.jointBrokerage.getAttach);
    //const editData = yield select(selectors.jointBrokerage.getEdit);
    const payload = {
      board_idx: board_idx,
      title: title,
      contents: content,
      file_idx: file_idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.editJoint, payload);
    if (response.rltObj === true) {
      yield put(aActions.common.alert({ contents: '해당 게시물을 수정하였습니다.' }));
      yield put(actions.getList());
      yield put(actions.getDetail(board_idx));
      setViewEditWrite(false);
    } else {
      yield put(
        aActions.common.alert({
          contents: (
            <>
              게시물 수정중 오류가 발생하였습니다.
              <br />
              다시시도해주세요
            </>
          ),
        })
      );
      yield put(actions.getList());
      yield put(actions.getDetail(board_idx));
    }
  } catch (e) {
    yield put(actions.getJointBrokerageDetailFailure(e));
  }
}
export function* delJoint({ pu_idx, setViewDetail }) {
  try {
    const payload = {
      board_idx: pu_idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.delJoint, payload);
    if (response.rltObj === true) {
      yield put(aActions.common.alert({ contents: '해당 게시물을 삭제하였습니다.' }));
      yield put(actions.getList());
      setViewDetail(false);
    } else {
      yield put(aActions.common.alert({ contents: '게시물 삭제중 오류가 발생하였습니다.</br> 다시시도해주세요' }));
      yield put(actions.getList());
    }
  } catch (e) {
    yield put(
      aActions.common.alert({
        contents: (
          <>
            게시물 삭제중 오류가 발생하였습니다.
            <br /> 다시시도해주세요
          </>
        ),
      })
    );
  }
}
