import { api as authApi } from './auth';
import { api as commonApi } from './common';
import { api as buyingApi } from './buying';
import { api as sellingApi } from './selling';
import { api as uploadImageApi } from './uploadImage';
import { api as myPageApi } from './myPage';
import { api as brokerPageApi } from './brokerPage';
import { api as ratingApi } from './rating';
import { api as regQNAApi } from './regQNA';
import { api as agentSignUpApi } from './agentSignUp';
import { api as analysisApi } from './analysis';
import { api as newsaleApi } from './newsale';
import { api as shareTokenApi } from './shareToken';
import { api as personalizeApi } from './personalize';
import { api as myApi } from './my';
import { api as addressApi } from './address';
import { api as paymentApi } from './payment';
import { api as communityApi } from './community';
import { api as chatApi } from './chat';
import { api as notificationApi } from './notification';
import { api as newsApi } from './news';
import { api as userApi } from './user';
import { api as resourceApi } from './resource';
import { api as kakaoApi } from './kakao';
import { api as academyApi } from './academy';
import { api as goodsApi } from './goods';
import { api as coinApi } from './coin';
import { api as targetingApi } from './targeting';
import { api as brokerApi } from './broker';
import { api as reviewApi } from './review';

const apis = {
  authApi,
  commonApi,
  buyingApi,
  sellingApi,
  uploadImageApi,
  myPageApi,
  brokerPageApi,
  ratingApi,
  regQNAApi,
  agentSignUpApi,
  analysisApi,
  newsaleApi,
  shareTokenApi,
  personalizeApi,
  myApi,
  addressApi,
  paymentApi,
  communityApi,
  chatApi,
  notificationApi,
  newsApi,
  userApi,
  resourceApi,
  kakaoApi,
  academyApi,
  goodsApi,
  coinApi,
  targetingApi,
  brokerApi,
  reviewApi,
};

export default apis;
