import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import Option1 from './components/Option1';
import Option2 from './components/Option2';
import { Formik, Form, Field } from 'formik';
import TextAreaInput from '@/components/Form/TextAreaInput';
import { required } from '@/services/FormHelper/validators';

const INITIAL_STATE = { brokerofficeidx: '', rept: '', reptExplain: '' };

const options = [Option1, Option2];
const Contents = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectors.common.isMobile);
  const [option, setOption] = useState(0);

  const changeOption = (e) => {
    setOption(e.target.value);
  };

  const handleSubmit = (values) => dispatch(actions.common.reportBroker(values));

  const validate = (values) => {
    const errors = {};

    //!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    if (!values.brokerofficeidx) {
      errors.brokerofficeidx = '중개사를 선택해주세요';
    } else if (!values.rept) {
      errors.rept = '신고 사유를 선택해주세요';
    } else if (values.rept === 'ETC' && !values.reptExplain) {
      errors.reptExplain = '신고 사유를 입력해주세요';
    }

    //...

    return errors;
  };

  const Option = options[option];
  const reasonList = useMemo(() => reasons.map((item, key) => <Reason key={key} code={item.code} text={item.text} />), []);
  return (
    <section id="container" className="container">
      <div className="cont_wrap">
        <div className="basic-inner">
          <p className="m_exp">신고할 내용을 선택해주세요.</p>
          <Formik onSubmit={handleSubmit} initialValues={INITIAL_STATE} validateOnChange={false} validateOnBlur={false} validate={validate}>
            {({ values, errors }) => {
              return (
                <Form>
                  <div className="selec-area">
                    <div className="radio-box">
                      <div className="radio">
                        <label htmlFor="selcbk01">
                          <input type="radio" name="agree" id="selcbk01" value={0} onClick={changeOption} />
                          <i></i>
                          <strong>선택한 중개사 중 선택</strong>
                        </label>
                      </div>
                      <div className="radio">
                        <label htmlFor="selcbk02">
                          <input type="radio" name="agree" id="selcbk02" value={1} onClick={changeOption} />
                          <i></i>
                          <strong>중개사무소 직접 검색</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                  {option ? (
                    <>
                      <Option />
                      <div className="selec-option">
                        <div className="radio-box rd-block">{reasonList}</div>
                        {values?.rept === 'ETC' && (
                          <Field
                            as="textarea"
                            name="reptExplain"
                            component={TextAreaInput}
                            validate={required}
                            className="txtarea"
                            placeholder="기타 선택시 입력해주세요."
                          />
                        )}
                        <div className="error">{errors.brokerofficeidx}</div>
                        <div className="error">{errors.rept}</div>
                        <div className="error">{errors.reptExplain}</div>
                        <div className={'btn-horiz' + (isMobile ? ' btn-area-bottom' : '')}>
                          <button id="" type="submit" className="btn btn-primary">
                            확인
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                </Form>
              );
            }}
          </Formik>
          <style jsx>
            {`
              div.error {
                color: red;
                width: 100%;
                text-align: left;
              }
            `}
          </style>
        </div>
      </div>
    </section>
  );
};

const Reason = ({ code, text }) => {
  return (
    <div className="radio">
      <label htmlFor={'rept' + code}>
        <Field type="radio" name="rept" id={'rept' + code} value={code} />
        <i></i>
        <strong>{text}</strong>
      </label>
    </div>
  );
};

const reasons = [
  { code: 'CI1', text: '휴폐업/행정처분 기간 중 영업한 경우' },
  { code: 'CI2', text: '다윈중개에서 제시한 중개보수 이외 초과보수를 요구한 경우' },
  { code: 'CI3', text: '중개사의 품위를 현저하게 훼손한 경우' },
  { code: 'CI4', text: '과도하게 다른 매물을 권유한 경우' },
  { code: 'CI5', text: '사이트에서 매물을 내리라고 권유한 경우' },
  { code: 'CI6', text: '동의 없이 내 매물을 사이트에서 내린 경우' },
  { code: 'ETC', text: '기타' },
];

export default Contents;
