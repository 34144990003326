import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

// 리소스 등록
export const uploadResources = ({ resources = {}, callback, errorCallback }) => ({
  type: AT.UPLOAD_RESOURCES,
  resources,
  callback,
  errorCallback,
});

export const uploadResourceLoading = () => ({
  type: loading(AT.UPLOAD_RESOURCES),
});

export const uploadResourceSuccess = (result) => ({
  type: success(AT.UPLOAD_RESOURCES),
  result,
});

export const uploadResourceFailure = (error) => ({
  type: failure(AT.UPLOAD_RESOURCES),
  error,
});
