import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { infoPopup } from '@/publish/Front/_Component/popup/InfoPopup';
import { actions, selectors } from '@/data';
import { INFO_POPUP_NAMES } from '../../utils';

/**
 * @desc 사용자에게 알림 문구 제공과 단순 닫기 액션을 요구할때 사용하는 팝업
 * * 호출예시: common.alert 검색
 */
const Alert = () => {
  const dispatch = useDispatch();
  const { contents, confirmButtonText = '확인', onConfirm, options } = useSelector(selectors.common.getAlert) || {};
  const { strictClose } = options || {};

  const confirm = () => {
    if (!strictClose) {
      close();
    }

    if (onConfirm) {
      onConfirm();
    }
  };

  const close = () => {
    infoPopup.inactive(INFO_POPUP_NAMES.ALERT);
    dispatch(actions.common.clearAlert());
  };

  const getContents = () => {
    if (typeof contents === 'string') {
      // contents가 단순히 한줄이라면 string으로 받아옴.
      return (
        <div className="board-body">
          <p className="para">
            <span className="wbr">{contents}</span>
          </p>
        </div>
      );
    } else {
      // 아니라면 마크업 자체로 받아옴
      return contents;
    }
  };

  useEffect(() => {
    // 한 번만 실행되면 코드
    infoPopup.update(INFO_POPUP_NAMES.ALERT);
  }, []);

  useEffect(() => {
    // contents가 변경이되면 active 시킴으로써 유저들에게 팝업을 보여주게 하는 코드
    if (contents) {
      infoPopup.active(INFO_POPUP_NAMES.ALERT);
    } else {
      infoPopup.inactive(INFO_POPUP_NAMES.ALERT);
    }
  }, [contents]);

  return (
    <div className="info-popup module-a style-b type-a small" data-bui-toggle={INFO_POPUP_NAMES.ALERT} id={INFO_POPUP_NAMES.ALERT}>
      <div className="popup-page-body">
        <div className="popup-local">
          <div className="popup-local-body">
            <div className="popup-content">
              <div className="popup-content-body">
                <div className="info-board module-a style-a type-c">
                  <div className="board-wrap">{getContents()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-local-util">
            <div className="button-display">
              <div className="button-area">
                <button className="btn popup-close" type="button" onClick={confirm}>
                  <span className="btn-text">{confirmButtonText}</span>
                </button>
              </div>
            </div>
          </div>
          {options?.showCloseButton && (
            <div className="popup-local-func">
              <div className="button-area">
                <button className="btn popup-close" type="button" onClick={close}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                    <title>닫기</title>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Alert);
