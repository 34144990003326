import dateService from '@/services/date';

const constants = {
  INITIAL_FORM_STATE: {
    susuryo_agree: 'Y',
    paid_option: 'N',
    // realty_type: 'APT',
    enter_flexible: 'N',
    enter_date: dateService.defaultFormat(undefined, 'YYYY-MM-DD', false),
    suggest_price: 'N',
    underground_floor: 0,
    // 테스트 코드
    // goods: [{ trade_type: 's', price1: 240000 }],
    // b_dong_code: '1168010300',
    // tb_cb_complex_idx: 39227,
    // complex_name: 'LG개포자이',
    // facility_data: {},
    // lat: '37.496437000000000',
    // lng: '127.07711500000000',
    // goods_dong: '103',
    // ground_floor: 22,
    // goods_floor: 2,
    // goods_ho: '201',
    // area_no: 2,
    // pyeong_name: '55',
  },

  // ======================== 팝업 ======================== //
  POPUP_TYPE_ENTER_MANUAL_PAGE: 'POPUP_TYPE_ENTER_MANUAL_PAGE',
  POPUP_TYPE_ENTER_PHONE_PAGE: 'POPUP_TYPE_ENTER_PHONE_PAGE',
  POPUP_TYPE_SIMPLE_LOGIN_USER: 'POPUP_TYPE_SIMPLE_LOGIN_USER',
  POPUP_BROKER_CANNOT_REGISTER: 'POPUP_BROKER_CANNOT_REGISTER', //중개사 매물 등록 불가
  POPUP_TYPE_TEMP_EXIST: 'POPUP_TYPE_TEMP_EXIST',
  POPUP_TYPE_EMPTY_INPUT_FIELD: 'POPUP_TYPE_EMPTY_INPUT_FIELD',
  POPUP_TYPE_PHONE_REQUEST: 'POPUP_TYPE_PHONE_REQUEST',
  POPUP_TYPE_PHONE_REQUEST_LOADING: 'POPUP_TYPE_PHONE_REQUEST_LOADING',
  POPUP_TYPE_PHONE_REQUEST_DONE: 'POPUP_TYPE_PHONE_REQUEST_DONE',
  POPUP_TYPE_VIEW_AGREEMENT_DETAIL: 'POPUP_TYPE_VIEW_AGREEMENT_DETAIL',
  POPUP_TYPE_MANUAL_REQUEST: 'POPUP_TYPE_MANUAL_REQUEST',
  POPUP_TYPE_MANUAL_REQUEST_LOADING: 'POPUP_TYPE_MANUAL_REQUEST_LOADING',
  POPUP_TYPE_MANUAL_REQUEST_DONE: 'POPUP_TYPE_MANUAL_REQUEST_DONE',
  POPUP_TYPE_MODIFY_REQUEST: 'POPUP_TYPE_MODIFY_REQUEST',
  POPUP_TYPE_MODIFY_REQUEST_LOADING: 'POPUP_TYPE_MODIFY_REQUEST_LOADING',
  POPUP_TYPE_MODIFY_REQUEST_DONE: 'POPUP_TYPE_MODIFY_REQUEST_DONE',
  POPUP_TYPE_TEMP_STORE_REQUEST: 'POPUP_TYPE_TEMP_STORE_REQUEST',
  POPUP_TYPE_TEMP_STORE_REQUEST_LOADING: 'POPUP_TYPE_TEMP_STORE_REQUEST_LOADING',
  POPUP_TYPE_TEMP_STORE_REQUEST_DONE: 'POPUP_TYPE_TEMP_STORE_REQUEST_DONE',
  POPUP_TYPE_GET_NEARBY_AGENT_LOADING: 'POPUP_TYPE_GET_NEARBY_AGENT_LOADING',

  // ======================== 지도 ======================== //
  MAP_TYPE_COMPLEX_LOCATION: 'MAP_TYPE_COMPLEX_LOCATION',
  // MAP_TYPE_COMPLEX_PRICE : "MAP_TYPE_COMPLEX_PRICE", // 단지 실거래가 보여주는건 지도 켜서 보여주지 않고, 별도 페이지로 이동해주는걸로
  MAP_TYPE_AGENT: 'MAP_TYPE_AGENT',

  // ======================== 직접 매물등록 step1 ======================== //

  APARTMENT: 'APT',
  OFFICETEL: 'OFT',

  INP_SALES_PRICE: 'INP_SALES_PRICE',

  INP_JEONSAE_PRICE: 'INP_JEONSAE_PRICE',
  INP_LEASE_PRICE_DEPOSIT: 'INP_LEASE_PRICE_DEPOSIT',
  INP_LEASE_PRICE_FEE: 'INP_LEASE_PRICE_FEE',

  // 옵션정보는 DB로 이동함. 더이상 필요 없는 값인 듯.
  OPTION_LIST: [
    { key: 'opt01', value: '01', name: '드레스룸', checked: false },
    { key: 'opt02', value: '02', name: '붙박이장', checked: false },
    { key: 'opt03', value: '03', name: '신발장', checked: false },
    { key: 'opt04', value: '04', name: '에어컨', checked: false },
    { key: 'opt05', value: '05', name: '세탁기', checked: false },
    { key: 'opt06', value: '06', name: '건조기', checked: false },
    { key: 'opt07', value: '07', name: '오븐', checked: false },
    { key: 'opt08', value: '08', name: '가스레인지', checked: false },
    { key: 'opt09', value: '09', name: '인덕션', checked: false },
    { key: 'opt10', value: '10', name: '식기세척기', checked: false },
    { key: 'opt11', value: '11', name: '욕조', checked: false },
    { key: 'opt16', value: '16', name: '디지털도어락', checked: false },
  ],
  ADVANTAGE_LIST: [
    { key: 'itm_char01', value: '01', name: '올 수리되어 깨끗함', checked: false },
    { key: 'itm_char02', value: '02', name: '로열층', checked: false },
    { key: 'itm_char03', value: '03', name: '층간소음이 없어 아이키우기 좋음', checked: false },
    { key: 'itm_char04', value: '04', name: '전망이 좋음', checked: false },
    { key: 'itm_char05', value: '05', name: '기타', checked: false },
  ],
  DANJI_OPTION_LIST: [
    { key: 'danji_opt01', name: 'danji-fit', value: '피트니스', code_detail: 'FIT' },
    { key: 'danji_opt02', name: 'danji-gx', value: 'GX룸', code_detail: 'GX' },
    { key: 'danji_opt03', name: 'danji-studyroom', value: '독서실', code_detail: 'STU' },
    { key: 'danji_opt04', name: 'danji-library', value: '도서관', code_detail: 'LIB' },
    { key: 'danji_opt05', name: 'danji-pool', value: '수영장', code_detail: 'POO' },
    { key: 'danji_opt06', name: 'danji-plublicpath', value: '사우나', code_detail: 'SAU' },
    { key: 'danji_opt07', name: 'danji-golf', value: '골프연습장', code_detail: 'GOL' },
    { key: 'danji_opt08', name: 'danji-guesthouse', value: '게스트하우스', code_detail: 'GUH' },
    { key: 'danji_opt09', name: 'danji-child', value: '어린이집', code_detail: 'CHI' },
    { key: 'danji_opt10', name: 'danji-deliverybox', value: '무인택배함', code_detail: 'DLB' },
    { key: 'danji_opt11', name: 'danji-cafe', value: '입주민카페', code_detail: 'CAF' },
    { key: 'danji_opt12', name: 'danji-kidscafe', value: '키즈카페', code_detail: 'KCF' },
    { key: 'danji_opt13', name: 'danji-waterpark', value: '워터파크', code_detail: 'WP' },
    { key: 'danji_opt14', name: 'danji-multiroom', value: '멀티룸', code_detail: 'MUL' },
    { key: 'danji_opt15', name: 'danji-cinema', value: '영화관', code_detail: 'THE' },
    { key: 'danji_opt16', name: 'danji-breakfast', value: '조식서비스', code_detail: 'RES' },
    { key: 'danji_opt17', name: 'danji-lounge', value: '라운지', code_detail: 'LOU' },
    { key: 'danji_opt18', name: 'danji-senior', value: '시니어센터', code_detail: 'SEN' },
    { key: 'danji_opt19', name: 'danji-badminton', value: '배드민턴장', code_detail: 'BAD' },
    { key: 'danji_opt20', name: 'danji-tabletennis', value: '탁구장', code_detail: 'TAT' },
    { key: 'danji_opt21', name: 'danji-basketball', value: '농구장', code_detail: 'BAS' },
    { key: 'danji_opt22', name: 'danji-tennis', value: '테니스장', code_detail: 'TAN' },
  ],
};

export default constants;
