// import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '@/data';
import { STATUS } from '@/data/utils';
import { STEPS } from '@/pages/Login/BasicForm/helpers';

const useSubmit = ({ loadingStatus, loginStep, getValues, setLoadingStatus, reset }) => {
  const dispatch = useDispatch();

  const varify = ({ varificationCode }) => {
    if (!varificationCode) {
      dispatch(actions.common.alert({ contents: '인증번호를 입력해주세요.' }));
      return;
    }

    const nameValue = getValues('name')?.trim();
    const phoneNumberValue = getValues('phoneNumber')?.trim();
    dispatch(actions.auth.sms2({ name: nameValue, cellphone: phoneNumberValue, authNum: varificationCode, options: { setLoadingStatus, reset } }));
  };

  const login = ({ candidateList, selectedId }) => {
    dispatch(actions.auth.sms3({ smsAuthToken: candidateList?.smsAuthToken, memberIdx: selectedId, options: { setLoadingStatus } }));
  };

  const sendVerifyCode = (callback) => {
    const nameValue = getValues('name')?.trim();
    const phoneNumberValue = getValues('phoneNumber')?.trim();
    dispatch(
      actions.auth.sms1({
        name: nameValue,
        cellphone: phoneNumberValue,
        options: { setLoadingStatus, callback },
      })
    );
  };

  const submit = ({ candidateList, selectedId, varificationCode }) => {
    if (loadingStatus === STATUS.LOADING) {
      return;
    }

    switch (loginStep) {
      case STEPS.PHONE3:
        return login({ candidateList, selectedId });
      case STEPS.PHONE2:
      case STEPS.SOCIAL3:
        return varify({ varificationCode });
      default:
        return sendVerifyCode();
    }
  };

  return [submit];
};

export default useSubmit;
