import CONSTANTS from '@/pages/Analysis/constants';
import { COMPLEX_DETAIL_MODE, COMPLEX_DETAIL_MODE_STORAGE_KEY } from '@/data/buying/utils';

// 지도 마커 타입
export const MARKER_TYPES = {
  COMPLEX_NAME: 'COMPLEX_NAME', // 단지명
  REALPRICE: 'REALPRICE', // 실거래가
  HOUSECNT_OLD: 'HOUSECNT_OLD', // 세대수_입주년차
  HOUSECNT: 'HOUSECNT', // 세대수
  OLD: 'OLD', // 입주년차
  TRADE_VOLUME: CONSTANTS.ANALYSIS_TRADE_VOLUME, //'TRADE_VOLUME', // 거래량
  TRADE_VOLUME_BY_ADDRESS: CONSTANTS.ANALYSIS_TRADE_VOLUME_BY_ADDRESS, //'TRADE_VOLUME_BY_ADDRESS', //단일 동 거래량
  PRICE_FLUC: CONSTANTS.ANALYSIS_PRICE_FLUC, // 가격변동
  PRICE_FLUC_BY_ADDRESS: CONSTANTS.ANALYSIS_PRICE_FLUC_BY_ADDRESS, // 가격변동
  RECON_FEASIBILITY: CONSTANTS.ANALYSIS_RECON_FEASIBILITY, // 재건축사업성
  FLOOR_AREA_RATIO: 'FLOOR_AREA_RATIO', // 용적률
  AREA_PER_HOUSEHOLD: 'AREA_PER_HOUSEHOLD', // 세대당 대지면적
  PUBLIC_NOTICE: CONSTANTS.ANALYSIS_PUBLIC_NOTICE, // 공시지가
  WEEKLY_PRICE: CONSTANTS.ANALYSIS_WEEKLY_PRICE, // 주간실거래가
  JEONSAE_PERCENTAGE: CONSTANTS.ANALYSIS_JEONSAE_PERCENTAGE, // 전세가율
  JOB_NUM: CONSTANTS.ANALYSIS_JOB_NUM, // 일자리수
  SCHOOL: CONSTANTS.ANALYSIS_SCHOOL, // 교육
  SUPPLY_AMT: CONSTANTS.ANALYSIS_SUPPLY_AMT, // 공급량
  POSITIVES: CONSTANTS.ANALYSIS_POSITIVES, // 개발호재
  APT_COMP: CONSTANTS.ANALYSIS_APT_COMP, // 아파트비교
  RANKING: CONSTANTS.ANALYSIS_RANKING, // 랭킹
  RECOMMEND: CONSTANTS.ANALYSIS_RECOMMEND, // 추천
  GOODS: 'GOODS',
};

// 마커 기본값
export const getDefaultMarkerType = () => {
  // const initialComplexDetailMode = localStorage.getItem(COMPLEX_DETAIL_MODE_STORAGE_KEY) || COMPLEX_DETAIL_MODE.GOODS_LIST;
  // return initialComplexDetailMode.indexOf(COMPLEX_DETAIL_MODE.COMPLEX) ? MARKER_TYPES.COMPLEX_NAME : MARKER_TYPES.GOODS;
  return MARKER_TYPES.COMPLEX_NAME;
};

// 마커 타입별 참조하는 데이터 타입
export const REFER_DATA = {
  [MARKER_TYPES.COMPLEX_NAME]: 'COMPLEX', // 단지정보
  [MARKER_TYPES.REALPRICE]: 'COMPLEX', // 단지정보
  [MARKER_TYPES.HOUSECNT]: 'COMPLEX', // 단지정보
  [MARKER_TYPES.OLD]: 'COMPLEX', // 단지정보
  [MARKER_TYPES.APT_COMP]: 'COMPLEX', // 아파트비교
  [MARKER_TYPES.GOODS]: 'GOODS', // 매물

  [MARKER_TYPES.RECON_FEASIBILITY]: CONSTANTS.ANALYSIS_RECON_FEASIBILITY, // 재건축사업성
  [MARKER_TYPES.FLOOR_AREA_RATIO]: 'COMPLEX', // 용적률
  [MARKER_TYPES.AREA_PER_HOUSEHOLD]: CONSTANTS.ANALYSIS_RECON_FEASIBILITY, // 재건축사업성

  [MARKER_TYPES.TRADE_VOLUME]: CONSTANTS.ANALYSIS_TRADE_VOLUME, // 거래량
  [MARKER_TYPES.TRADE_VOLUME_BY_ADDRESS]: CONSTANTS.ANALYSIS_TRADE_VOLUME_BY_ADDRESS, // 단지별 거래량
  [MARKER_TYPES.PRICE_FLUC]: CONSTANTS.ANALYSIS_PRICE_FLUC, // 가격변동
  [MARKER_TYPES.PRICE_FLUC_BY_ADDRESS]: CONSTANTS.ANALYSIS_PRICE_FLUC_BY_ADDRESS, // 가격변동
  [MARKER_TYPES.PUBLIC_NOTICE]: CONSTANTS.ANALYSIS_PUBLIC_NOTICE, // 공시지가
  [MARKER_TYPES.WEEKLY_PRICE]: CONSTANTS.ANALYSIS_WEEKLY_PRICE, // 주간실거래가
  [MARKER_TYPES.JEONSAE_PERCENTAGE]: CONSTANTS.ANALYSIS_JEONSAE_PERCENTAGE, // 전세가율
  [MARKER_TYPES.JOB_NUM]: CONSTANTS.ANALYSIS_JOB_NUM, // 일자리수
  [MARKER_TYPES.SCHOOL]: CONSTANTS.ANALYSIS_SCHOOL, // 교육
  [MARKER_TYPES.SUPPLY_AMT]: CONSTANTS.ANALYSIS_SUPPLY_AMT, // 공급량
  [MARKER_TYPES.POSITIVES]: CONSTANTS.ANALYSIS_POSITIVES, // 개발호재
  [MARKER_TYPES.RANKING]: CONSTANTS.ANALYSIS_RANKING, // 랭킹
  [MARKER_TYPES.RECOMMEND]: CONSTANTS.ANALYSIS_RECOMMEND, // 추천
};

// 기본 지도 마커타입별 정의
export const DEFAULT_MAP_MARKER_DEF = [
  {
    type: MARKER_TYPES.COMPLEX_NAME,
    name: '단지명',
    shortenedName: '단지명',
  },
  {
    type: MARKER_TYPES.REALPRICE,
    name: '평당 실거래가',
    shortenedName: '평당실거래',
  },
  {
    type: MARKER_TYPES.PUBLIC_NOTICE,
    name: '평당 공시지가',
    shortenedName: '평당공시가',
  },
  // { type: MARKER_TYPES.PRICE_FLUC, name: '가격변동', child: 'slider' },
  // { type: MARKER_TYPES.TRADE_VOLUME, name: '거래량', child: 'slider' },
  {
    type: MARKER_TYPES.RECON_FEASIBILITY,
    name: '재건축 사업성',
    shortenedName: '재건축',
  },
  {
    type: MARKER_TYPES.HOUSECNT,
    name: '세대수',
    shortenedName: '세대수',
  },
  {
    type: MARKER_TYPES.OLD,
    name: '입주년차',
    shortenedName: '입주년차',
  },
  { type: MARKER_TYPES.FLOOR_AREA_RATIO, name: '용적률', shortenedName: '용적률' },
  {
    type: MARKER_TYPES.AREA_PER_HOUSEHOLD,
    name: '세대당 대지지분',
    shortenedName: '대지지분',
  },
];
