import { parseCallBack } from '@/services/Helper';
import { useDispatch } from 'react-redux';
import { routeToReport } from '../../helpers';

const useReportPopup = () => {
  const dispatch = useDispatch();
  const querystring = parseCallBack();
  const viewReportPop = querystring['feedReport'];

  const openReportPopup = (e, type, feedId) => {
    e.preventDefault();
    if (!viewReportPop) {
      dispatch(routeToReport(type, feedId));
    }
  };

  return openReportPopup;
};

export default useReportPopup;
