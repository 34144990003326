import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

//분양, 재건축, 뉴스
export const api = {
  async getNewsales(data) {
    try {
      return await apiClient.post('/apartment/complexBunyang', data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getNewsalePrice(complex_idx) {
    try {
      return await apiClient.get('/apartment/complexBunyangPrice/' + complex_idx);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getNewsaleSchedule(complex_idx) {
    try {
      return await apiClient.get('/apartment/complexBunyangDetail/' + complex_idx);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //재건축
  async getRebuilds(data) {
    try {
      return await apiClient.post('/apartment/complexRebuild', data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //재건축 현황
  async getRebuildSchedule(complex_idx) {
    try {
      return await apiClient.get('/apartment/complexRebuildDetail/' + complex_idx);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //뉴스
  async getNews(data) {
    try {
      return await apiClient.get('/info/news' + data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getNewsCategory() {
    try {
      return await apiClient.get('/info/news/category');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getNewsDates() {
    try {
      return await apiClient.get('/info/news/date');
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 2021-07-13 장혁진 추가
   * 매일경제 협업 관련 배너 추가 및 연동.
   * /news에서 매일경제 '메부리레터' 연동 api 추가
   */

  async getMKLetterNews(data) {
    try {
      return await apiClient.get('/info/news/mkLetter' + data);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
