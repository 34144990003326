// [GET] 나의 실무교육 신청 내역
export const GET_ENROLLMENTS = 'ACADEMY/GET_ENROLLMENTS';

// [POST] 실무교육 신청
export const APPLY_ACADEMY = 'ACADEMY/ACADEMY_PAYMENT';

// [POST] 실무교육 결제하기 STEP1
export const PAY_ACADEMY_FEE_STEP_ONE = 'ACADEMY/PAY_ACADEMY_FEE_STEP_ONE';

// [POST] 실무교육 결제하기 STEP2
export const PAY_ACADEMY_FEE_STEP_TWO = 'ACADEMY/PAY_ACADEMY_FEE_STEP_TWO';

// [PUT] 실무교육 일정 변경
export const EDIT_COURSE = 'ACADEMY/EDIT_COURSE';

// [POST] 실무교육 취소
export const CANCEL_COURSE = 'ACADEMY/CANCEL_COURSE';

// [POST] 실무교육 신청 로깅
export const APPLY_ACADEMY_LOG = 'ACADEMY/APPLY_ACADEMY_LOG';
