const constants = {
  // ======================== 직접 매물등록 공통 ======================== //
  MOBILE: 'MOBILE',

  AGENT_SIGNUP_STEP1: 1,
  AGENT_SIGNUP_STEP2: 2,
  AGENT_SIGNUP_STEP3: 3,
  AGENT_SIGNUP_STEP4: 4,
  AGENT_SIGNUP_STEP5: 5,

  AGENT_SIGNUP_AGENT_LOCATION: 100,
  AGENT_SIGNUP_BK_PEE: 101,
  AGENT_SIGNUP_SHARE_HOME: 102,
  AGENT_SIGNUP_ACADEMY: 103,

  AGENT_SIGNUP_DONE: 999,

  AGENT_SIGNUP_PROC_BULLET_ID_PREFIX: 'AGENT_SIGNUP_PROC_BULLET_',

  SI_DO_LIST: 'SI_DO_LIST',
  SI_GUN_GU_LIST: 'SI_GUN_GU_LIST',
  DONG_RI_LIST: 'DONG_RI_LIST',
  OFFICE_LIST: 'OFFICE_LIST',

  DUMMY_OFFICE_LIST: [
    {
      broker_office_info_idx: 111,
      broker_office_name: 'dummy 중개사무소 이름 1',
      broker_name: 'dummy 대표 중개사 1',
      broker_office_address: 'dummy 중개사무소 주소 1',
      lat: '37.377000000000000',
      lng: '127.11870060000000',
    },
    {
      broker_office_info_idx: 222,
      broker_office_name: 'dummy 중개사무소 이름 2',
      broker_name: 'dummy 대표 중개사 2',
      broker_office_address: 'dummy 중개사무소 주소 2',
      lat: '37.380000000000000',
      lng: '127.11900060000000',
    },
    {
      broker_office_info_idx: 333,
      broker_office_name: 'dummy 중개사무소 이름 3',
      broker_name: 'dummy 대표 중개사 3',
      broker_office_address: 'dummy 중개사무소 주소 3',
      lat: '37.393000000000000',
      lng: '127.11840060000000',
    },
  ],
  OFFICE_TYPE_INDI: 'PER',
  OFFICE_TYPE_CORP: 'COP',

  // @todo api 확인 필요
  IMAGE_CATEGORY_AGENT_SIGNUP: 'broker-office',

  IMAGE_FORMATS_ALLOWED: 'image/jpg, image/png',
  IMAGE_TYPE_CORP: 'IMAGE_TYPE_CORP', // 사업자등록증
  IMAGE_TYPE_OPEN: 'IMAGE_TYPE_OPEN', // 개설등록증
  IMAGE_TYPE_PROFILE: 'IMAGE_TYPE_PROFILE', // 프로필사진

  IS_PASS_INCOR: 'IS_PASS_INCOR',
  IS_PASS_MIN_LIMIT_1: 'IS_PASS_MIN_LIMIT_1',
  IS_PASS_MIN_LIMIT_2: 'IS_PASS_MIN_LIMIT_2',
  IS_PASS_EQUAL: 'IS_PASS_EQUAL',

  // ======================== MAP ======================== //
  MAP_TYPE_OFFICE_LOCATION: 'MAP_TYPE_OFFICE_LOCATION',
  // ======================== MODAL ======================== //
  MODAL_PHONE_NUM_CHECK: 'MODAL_PHONE_NUM_CHECK',
  MODAL_AGENT_SIGNUP_DONE: 'MODAL_AGENT_SIGNUP_DONE',
  MAIN_ALERT: 'MAIN_ALERT',
  RECHECK_EMAIL_VAL: 'RECHECK_EMAIL_VAL',
  EMAIL_CHECKED: 'EMAIL_CHECKED',
  EMAIL_CHECKED_FAIL: 'EMAIL_CHECKED_FAIL',
  POPUP_TYPE_TEMP_STORE_REQUEST: 'POPUP_TYPE_TEMP_STORE_REQUEST',
  // ======================== share office ======================== //
  SHARE_OFFICE_APPLY_POPUP: 'SHARE_OFFICE_APPLY_POPUP',
  SHARE_OFFICE_APPLY_DONE: 'SHARE_OFFICE_APPLY_DONE',
  SHARE_OFFICE_APPLY_DONE2: 'SHARE_OFFICE_APPLY_DONE2',
  SHARE_OFFICE_APPLY_LOADING: 'SHARE_OFFICE_APPLY_LOADING',
  SHARE_OFFICE_APPLY_DETAIL: 'SHARE_OFFICE_APPLY_DETAIL',

  OFFICE_TOUR_APPLY_POPUP: 'OFFICE_TOUR_APPLY_POPUP',
  OFFICE_TOUR_APPLY_DONE: 'OFFICE_TOUR_APPLY_DONE',
  OFFICE_TOUR_APPLY_DONE2: 'OFFICE_TOUR_APPLY_DONE2',
  OFFICE_TOUR_APPLY_LOADING: 'OFFICE_TOUR_APPLY_LOADING',
  OFFICE_TOUR_APPLY_DETAIL: 'OFFICE_TOUR_APPLY_DETAIL',
  OFFICE_TOUR_APPLY_EXIST: 'OFFICE_TOUR_APPLY_EXIST',

  // ======================== academy ======================== //
  ACADEMY_POPUP: 'ACADEMY_POPUP',
  ACADEMY_DONE: 'ACADEMY_DONE',
  ACADEMY_LOADING: 'ACADEMY_LOADING',
  ACADEMY_DETAIL: 'ACADEMY_DETAIL',
};

export default constants;
