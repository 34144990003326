import { apiClient, apiClient2 } from '@/apis/clients';
import { history } from '@/data/configureStore';

export const getRooms = async () => {
  try {
    const getRoomsRes = await apiClient2.get(`/chat-api/v1/chat/rooms`);
    return getRoomsRes;
  } catch (e) {
    throw Error(e.message);
  }
};

export const createChatRoom = async ({ to, title }) => {
  const payload = {
    userIds: [to],
  };

  if (title) {
    payload.title = title;
  }

  try {
    const createChatRes = await apiClient2.post(`/chat-api/v1/chat/rooms`, payload);
    if (createChatRes) {
      history.push(`/chat/${createChatRes?.roomId}`);
    } else {
      throw Error('채팅방 생성에 실패하였습니다.');
    }
  } catch (e) {
    throw Error('채팅방 생성에 실패하였습니다. ' + e.message);
  }
};

export const getCursor = async ({ roomId, setCursorData }) => {
  try {
    const getCursorRes = await apiClient2.get(`/chat-api/v1/chat/rooms/${roomId}/cursors`);
    setCursorData(getCursorRes);
  } catch (e) {
    throw Error(e.message);
  }
};

export const updateCursor = async ({ setUpdateCursorData, roomId, cursor }) => {
  const updateCursorBody = {
    cursor: cursor,
  };
  try {
    const updateCursorRes = await apiClient2.put(`/chat-api/v1/chat/rooms/${roomId}/cursors`, updateCursorBody);
    setUpdateCursorData([...updateCursorRes]);
  } catch (e) {
    throw Error(e.message);
  }
};

// export const getMessage = async ({ roomId, limit, reverse = true, cursor }) => {
export const getMessage = async ({ roomId, limit, reverse = true }) => {
  try {
    const getMessageRes = await apiClient2.get(`/chat-api/v1/chat/rooms/${roomId}/messages?limit=${limit}&reverse=${reverse}`);

    return getMessageRes;
  } catch (e) {
    throw Error(e.message);
  }
};

export const pushMessage = async ({ messageType, roomId, content }) => {
  const pushMeesageBody = {
    type: messageType,
    content: content,
  };
  try {
    await apiClient2.post(`/chat-api/v1/chat/rooms/${roomId}/messages`, pushMeesageBody);
  } catch (e) {
    throw Error(e.message);
  }
};

export const chatResource = async ({ inputMessage }) => {
  const imageBase64File = {
    file: inputMessage,
    ttl: 86400,
  };
  try {
    return await apiClient2.post(`/resource-api/v1/resources`, imageBase64File);
    // const imageResource = await resourceApiClient.post(`/resources`, imageBase64File);
  } catch (e) {
    throw Error(e.message);
  }
};
