import { apiClient, apiClient2 } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

// @note auth dir 에 request 구현하여 사용!
// 추후 auth 와 분리하여 구현할 내용이 생긴다면 사용

const academyPrefix = '/education-api/v0';

export const api = {
  //   async postAgentSignUpReq({ formValues}) {
  //     try {
  //       return await apiClient.post('/auth/login',objectToQuerystring(formValues));
  //     } catch (e) {
  //       throw Error(e.message);
  //     }
  //   },

  async getOfficeList(payload) {
    try {
      // @todo API 완성되면 호출 제대로 하기! 그 전까지는 더미 사용
      //const list = CONSTANTS.DUMMY_OFFICE_LIST
      //return await apiClient.get('/broker/broker-office-search' + objectToQuerystring(payload));
      return await apiClient.get('/nsdi-proxy/nsdi' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getSearchXY(payload) {
    try {
      return await apiClient.get('/nsdi-proxy/nsdixycall' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getAddressList(payload) {
    try {
      return await apiClient.get('/nsdi-proxy/addressList' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getBrokerOfficeList(payload) {
    try {
      // @todo API 완성되면 호출 제대로 하기! 그 전까지는 더미 사용
      //const list = CONSTANTS.DUMMY_OFFICE_LIST
      return await apiClient.get('/broker/broker-office-list' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async checkCode(payload) {
    try {
      return await apiClient.get('/broker/checkCode' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async handleAgentSignUpNextStep(payload) {
    try {
      const { stepUrl } = payload;

      return await apiClient.post('/agent/' + stepUrl, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getImgIdx(payload) {
    try {
      return await apiClient.post('/agent/joinImg', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async checkIdDup(payload) {
    try {
      return await apiClient.post('/auth/check-exists-email', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveTemp(payload) {
    try {
      return await apiClient.post('/agent/joinStep', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async applyShareOffice(payload) {
    try {
      return await apiClient.post('/agent/office_apply', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getTourOfficeList() {
    try {
      return await apiClient.get('/agent/get_office_list');
    } catch (e) {
      throw Error(e.message);
    }
  },
  async applyTourOffice(payload) {
    try {
      return await apiClient.post('/agent/office_tour', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getCourses() {
    try {
      const result = await apiClient2.get(`${academyPrefix}/courses?filter-not-enroll-period=true`);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async checkUser(payload) {
    try {
      const result = await apiClient2.post(`${academyPrefix}/users`, payload);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async getApplyAcademyInfo(token) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
      },
    };
    let queryString = '?filter-passed-course=true&status=PAID';
    try {
      const result = await apiClient2.get(`${academyPrefix}/enrollments` + queryString, config);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async getAlreadyApply(param) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };
    let queryString = `?filter-passed-course=true&status=PAID`;
    try {
      const result = await apiClient2.get(`${academyPrefix}/enrollments` + queryString, config);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async getBill(param) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };

    let queryString = ``;
    if (param.origin_id) {
      queryString = `?enrollment-id=${param.origin_id}&status=PAYMENT-DONE`;
    } else {
      queryString = `?status=PAYMENT-DONE`;
    }
    try {
      const result = await apiClient2.get(`${academyPrefix}/bills` + queryString, config);
      return result?.data;
    } catch (e) {
      throw Error(e);
    }
  },
  async applyPreBill(param) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };
    try {
      const result = await apiClient2.post(`${academyPrefix}/bills`, { enrollmentId: param.id }, config);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async applyBill(param) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };
    try {
      const result = await apiClient2.post(
        `${academyPrefix}/bills/${param.billId}/payment`,
        { providerKey: param.providerKey, recommendation: param.recommendation },
        config
      );
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async applyAcademy(param) {
    const payload = {
      courseId: param.courseid,
    };
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };
    try {
      const result = await apiClient2.post(`${academyPrefix}/enrollments`, payload, config);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async applyAcademyLog(param) {
    try {
      const result = await apiClient2.post(`${academyPrefix}/applicants`, param);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
  async editAcademy(param) {
    const payload = {
      courseId: param.courseId,
    };
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };
    try {
      const result = await apiClient2.put(`${academyPrefix}/enrollments/${param.origin_id}/course`, payload, config);
      return result?.data;
    } catch (e) {
      throw Error(e.type);
    }
  },
  async cancelAcademy(param) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + param.accessToken,
      },
    };
    try {
      const result = await apiClient2.post(`${academyPrefix}/bills/${param.bill_id}/refund`, '', config);
      return result?.data;
    } catch (e) {
      throw Error(e.type);
    }
  },
};
