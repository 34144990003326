import { loading, success, failure } from '@/data/utils';
import * as brokerOfficeInfo from './brokerOfficeInfo/actionTypes';
import * as bkQnA from './bkQnA/actionTypes';
import * as jointBrokerage from './jointBrokerage/actionTypes';
import * as mgGoods from './mgGoods/actionTypes';
import * as mgContract from './mgContract/actionTypes';
import * as mgCounseling from './mgCounseling/actionTypes';
import * as mgCustomer from './mgCustomer/actionTypes';
import * as mgQa from './mgQa/actionTypes';
import * as mgRate from './mgRate/actionTypes';
import * as mgComplexImg from './mgComplexImg/actionTypes';
import * as dataRoom from './dataRoom/actionTypes';
import * as suggestion from './suggestion/actionTypes';
import * as notice from './notice/actionTypes';
import * as internalNotice from './internalNotice/actionTypes';
import * as oneOnOne from './oneOnOne/actionTypes';
import * as setArea from './setArea/actionTypes';
import * as dailyReport from './dailyReport/actionTypes';
import * as community from './community/actionTypes';

export { brokerOfficeInfo };
export { bkQnA };
export { jointBrokerage };
export { mgCounseling };
export { mgCustomer };
export { mgGoods };
export { mgContract };
export { mgQa };
export { mgRate };
export { mgComplexImg };
export { dataRoom };
export { suggestion };
export { notice };
export { internalNotice };
export { oneOnOne };
export { setArea };
export { dailyReport };
export { community };
// ===================== 공통 ===================== //
export const BROKER_PAGE_REQUEST_ERROR = 'BROKERPAGE/BROKER_PAGE_REQUEST_ERROR';

//알람리스트
export const GET_BROKER_ALARM_LIST = 'BROKERPAGE/GET_BROKER_ALARM_LIST';
export const SET_BROKER_ALARM_LIST = 'BROKERPAGE/SET_BROKER_ALARM_LIST';
export const DELETE_ALARM = 'BROKERPAGE/DELETE_ALARM';
export const DELETE_RA_ALARM = 'BROKERPAGE/DELETE_RA_ALARM';
export const READ_ALARM = 'BROKERPAGE/DELETE_RA_ALARM';

export const GET_BROKER_BOARD_NEW = 'BROKERPAGE/GET_BROKER_BOARD_NEW';
export const SET_ONEONEONE_NEW = 'BROKERPAGE/SET_ONEONEONE_NEW';
export const SET_BOARD_NEW = 'BROKERPAGE/SET_BOARD_NEW';
