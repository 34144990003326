import React from 'react';
import toggle from '@/hocs/toggle';

// const ButtonApply = ({ buttonText, onClickButton, disabled }) => {
const ButtonApply = ({ text, onClick, disabled }) => {
  return (
    <div className="popup-local-util">
      <div className="button-display module-a style-a type-d">
        <span className="button-area">
          <button type="button" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={onClick} disabled={disabled}>
            <span className="btn-text">{text}</span>
          </button>
        </span>
      </div>
    </div>
  );
};

export default toggle(ButtonApply);
