import { loading, success, failure } from '@/data/utils';

export const INITIALIZE = 'BROKERPAGE/MANAGE_GOODS/INITIALIZE';
//매물정보
export const GET_MANAGE_GOODS_LIST = 'BROKERPAGE/GET_MANAGE_GOODS_LIST';
export const GET_MANAGE_GOODS_LIST_LOADING = loading(GET_MANAGE_GOODS_LIST);
export const GET_MANAGE_GOODS_LIST_SUCCESS = success(GET_MANAGE_GOODS_LIST);
export const GET_MANAGE_GOODS_LIST_FAILURE = failure(GET_MANAGE_GOODS_LIST);
export const SET_MANAGE_GOODS_LIST_COUNT = 'BROKERPAGE/SET_MANAGE_GOODS_LIST_COUNT';
export const GET_MANAGE_GOODS_MEMO = 'BROKERPAGE/GET_MANAGE_GOODS_MEMO';
export const GET_MANAGE_GOODS_MEMO_LOADING = loading(GET_MANAGE_GOODS_MEMO);
export const GET_MANAGE_GOODS_MEMO_SUCCESS = success(GET_MANAGE_GOODS_MEMO);
export const GET_MANAGE_GOODS_MEMO_FAILURE = failure(GET_MANAGE_GOODS_MEMO);
export const GET_GOODS_HISTORY = 'BROKERPAGE/GET_GOODS_HISTORY';
export const GET_GOODS_HISTORY_LOADING = loading(GET_GOODS_HISTORY);
export const GET_GOODS_HISTORY_SUCCESS = success(GET_GOODS_HISTORY);
export const GET_GOODS_HISTORY_FAILURE = failure(GET_GOODS_HISTORY);
export const GET_BROKER_COMMENT = 'BROKERPAGE/GET_BROKER_COMMENT';
export const GET_BROKER_COMMENT_LOADING = loading(GET_BROKER_COMMENT);
export const GET_BROKER_COMMENT_SUCCESS = success(GET_BROKER_COMMENT);
export const GET_BROKER_COMMENT_FAILURE = failure(GET_BROKER_COMMENT);
export const GET_MANAGE_GOODS_DETAIL = 'BROKERPAGE/GET_MANAGE_GOODS_DETAIL';
export const GET_MANAGE_GOODS_DETAIL_LOADING = loading(GET_MANAGE_GOODS_DETAIL);
export const GET_MANAGE_GOODS_DETAIL_FAILURE = failure(GET_MANAGE_GOODS_DETAIL);
// export const GET_MANAGE_GOODS_LIST_SUCCESS = success(GET_MANAGE_GOODS_DETAIL);
export const SET_MANAGE_GOODS_DETAIL = 'BROKERPAGE/SET_MANAGE_GOODS_DETAIL';
export const SET_GOODS_COMMON_DETAIL = 'BROKERPAGE/SET_GOODS_COMMON_DETAIL';
export const RESET_GOODS_DETAIL = 'BROKERPAGE/RESET_GOODS_DETAIL';
export const REMOVE_GOODS_NEW = 'BROKERPAGE/REMOVE_GOODS_NEW';
export const SET_MANAGE_GOODS_DETAIL_INIT = 'BROKERPAGE/SET_MANAGE_GOODS_DETAIL_INIT';
export const SET_MANAGE_GOODS_SCROLL_POSITION = 'BROKERPAGE/SET_MANAGE_GOODS_SCROLL_POSITION';
export const MANAGE_GOODS_MEMO = 'BROKERPAGE/MANAGE_GOODS_MEMO';
export const MANAGE_GOODS_BROKER_COMMENT = 'BROKERPAGE/MANAGE_GOODS_BROKER_COMMENT';

export const GOODS_SEARCH = 'BROKERPAGE/GOODS_SEARCH';
export const GOODS_SEARCH_LOADING = loading(GOODS_SEARCH);
export const GOODS_SEARCH_SUCCESS = success(GOODS_SEARCH);
export const GOODS_SEARCH_FAILURE = failure(GOODS_SEARCH);

export const SET_GOODS_CURRENT_PAGE = 'BROKERPAGE/SET_GOODS_CURRENT_PAGE';
export const SET_NEXT_GOODS_DATA = 'BROKERPAGE/SET_NEXT_GOODS_DATA';
export const SET_TOTAL_CNT = 'BROKERPAGE/SET_TOTAL_CNT';

export const GOODS_FAVORITE_DESIGNATE = 'BROKERPAGE/GOODS_FAVORITE_DESIGNATE';
export const GOODS_FAVORITE_DE_DESIGNATE = 'BROKERPAGE/GOODS_FAVORITE_DE_DESIGNATE';
export const FAKE_SALE_REPORT = 'BROKERPAGE/FAKE_SALE_REPORT';

export const SET_FAKE_SALE_REPORT_RES = 'BROKERPAGE/SET_FAKE_SALE_REPORT_RES';
export const SET_CONTRACT_INFO = 'BROKERPAGE/SET_CONTRACT_INFO';
export const SET_CONTRACT_DETAILS = 'BROKERPAGE/SET_CONTRACT_DETAILS';
export const SET_CONTRACT_MEMBERS = 'BROKERPAGE/SET_CONTRACT_MEMBERS';
export const SET_CONTRACT_MEMBER = 'BROKERPAGE/SET_CONTRACT_MEMBER';
export const SET_CONTRACT_INFO_INI = 'BROKERPAGE/SET_CONTRACT_INFO_INI';

export const CONTRACT_INFO_SAVE = 'BROKERPAGE/CONTRACT_INFO_SAVE';
export const CONTRACT_INFO_EX_SAVE = 'BROKERPAGE/CONTRACT_INFO_EX_SAVE';
export const CONTRACT_INFO_EDIT = 'BROKERPAGE/CONTRACT_INFO_EDIT';
export const CONTRACT_INFO_SAVE_RES = 'BROKERPAGE/CONTRACT_INFO_SAVE_RES';

export const GET_ASSOCIATED_COMMUNITY = 'BROKERPAGE/GET_ASSOCIATED_COMMUNITY';
export const GET_ASSOCIATED_COMMUNITY_SUCCESS = 'BROKERPAGE/GET_ASSOCIATED_COMMUNITY_SUCCESS';

export const GOODS_CHANGE_CATEGORY = 'BROKERPAGE/GOODS_CHANGE_CATEGORY';
export const GOODS_CHANGE_GUBUN = 'BROKERPAGE/GOODS_CHANGE_GUBUN';
export const GOODS_CHANGE_TRADETYPE = 'BROKERPAGE/GOODS_CHANGE_TRADETYPE';
export const GOODS_CHANGE_AREASIZE = 'BROKERPAGE/GOODS_CHANGE_AREASIZE';
export const GOODS_CHANGE_PRICE = 'BROKERPAGE/GOODS_CHANGE_PRICE';
export const GOODS_CHANGE_PRICE2 = 'BROKERPAGE/GOODS_CHANGE_PRICE2';
export const GOODS_CHANGE_STATUS = 'BROKERPAGE/GOODS_CHANGE_STATUS';
export const GOODS_CHANGE_BROKERAGE_TYPE = 'BROKERPAGE/GOODS_CHANGE_BROKERAGE_TYPE';
export const GOODS_CHANGE_PAGE = 'BROKERPAGE/GOODS_CHANGE_PAGE';
export const GOODS_CHANGE_KEYWORD = 'BROKERPAGE/GOODS_CHANGE_KEYWORD';

//=======================
//중개사 매물정보수정
export const MODIFY_GOODS = 'BROKERPAGE/MODIFY_GOODS';
export const CHANGE_MODIFY_GOODS = 'BROKERPAGE/CHANGE_MODIFY_GOODS';
export const SET_MODIFY_GOODS_INI = 'BROKERPAGE/SET_MODIFY_GOODS_INI';

export const GET_CALL_REPORT = 'BROKERPAGE/GET_CALL_REPORT';
export const SET_CALL_REPORT = 'BROKERPAGE/SET_CALL_REPORT';

export const GOODS_REFRESH_SEARCH_CONDITION = 'BROKERPAGE/GOODS_REFRESH_SEARCH_CONDITION';
export const GOODS_KEYWORD_SEARCH = 'BROKERPAGE/GOODS_KEYWORD_SEARCH';
export const GOODS_KEYWORD_SEARCH_RES = 'BROKERPAGE/GOODS_KEYWORD_SEARCH_RES';

//다윈외거래 계약체결
export const SET_CONTRACT_PRE_CLIENT_TYPE = 'BROKERPAGE/SET_CONTRACT_PRE_CLIENT_TYPE';
