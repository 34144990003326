import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import * as selectors from '@/data/rootSelectors';

const ComboSelectComp = () => {
  const dispatch = useDispatch();

  const si_do_code = useSelector(selectors.selling.getSiDoCode);
  const si_gun_gu_code = useSelector(selectors.selling.getSiGunGuCode);

  const si_do_list = useSelector(selectors.selling.getSiDoList);
  const si_gun_gu_list = useSelector(selectors.selling.getSiGunGuList);
  const dong_list = useSelector(selectors.selling.getDongRiList);

  useEffect(() => {
    dispatch(actions.selling.getAddrSiDo(true));
  }, []);

  useEffect(() => {
    dispatch(actions.selling.getAddrSiGunGu());
  }, [si_do_code]);

  useEffect(() => {
    dispatch(actions.selling.getAddrDongRi());
  }, [si_gun_gu_code]);

  const changeSido = (e) => {
    dispatch(actions.selling.changeSiDoCode(e.target.value));
  };

  const changeSigungu = (e) => {
    dispatch(actions.selling.changeSiGunGuCode(e.target.value));
  };

  const changeDong = (e) => {
    dispatch(actions.selling.changeDongRiCode(e.target.value));
  };

  return (
    <>
      <div className="inp-group inp-horiz">
        <select title="시/도" className="selec" onChange={changeSido}>
          <option value="">시/도</option>
          {si_do_list.map((item, key) => (
            <option key={key} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
        <select title="시/군/구" className="selec" onChange={changeSigungu}>
          <option value="">시/군/구</option>
          {si_gun_gu_list.map((item, key) => (
            <option key={key} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
        <select title="읍/면/동" className="selec" onChange={changeDong}>
          <option value="">읍/면/동</option>
          {dong_list.map((item, key) => (
            <option key={key} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default ComboSelectComp;
