import { priceToText } from '@/pages/Buying/util';
import { goodsUtil } from '@/services';
import React from 'react';

const FakeContents = ({ data = {} }) => {
  const { complex_name, pyeong, trade_type, year, month, day, floors } = data;
  const pyeongText = showOrNot(pyeong, `${pyeong}평`),
    tradeTypeText = showOrNot(trade_type, goodsUtil.tradeTypes.getName(trade_type)),
    floorText = showOrNot(floors, `${floors}층`),
    dateText = showOrNot(year && month && day, `(${year}.${month}.${day}계약)`),
    priceText = `${goodsUtil.price.getPrice(data, { formatter: priceToText })}원`;

  return (
    <div className="ch-item">
      {/* <span className="ch-info-name">{complex_name}</span> */}
      <span className="ch-info-type">
        {complex_name} {pyeongText} {tradeTypeText} {priceText} {floorText} {dateText}
        <br />이 거래에 대해 이야기를 나눠보세요.
      </span>
    </div>
  );
};

export default FakeContents;

const showOrNot = (originData, showData) => {
  return originData ? showData : null;
};
