import React from 'react';

export const ADD_FAV_COMPLEX_SUCCESS_MOBILE = ({ isMobileBrowser }) => {
  return (
    <>
      <div className="board-head">
        <p className="board-subject">
          <span className="board-name">관심단지로 설정되었습니다</span>
        </p>
      </div>
      <div className="board-body">
        <p className="para">
          <span className="wbr">
            <strong>앱을 다운</strong> 받으시면
          </span>
          <span className="wbr">
            <strong>새로운 실거래가</strong>와 <strong>매물이 등록</strong>되었을 때
          </span>
          <span className="wbr">알림을 받으실 수 있습니다.</span>
        </p>
      </div>
    </>
  );
};

export const ADD_FAV_COMPLEX_SUCCESS_APP = (
  <>
    <div className="board-head">
      <p className="board-subject">
        <span className="board-name">관심단지로 설정되었습니다</span>
      </p>
    </div>
    <div className="board-body">
      <p className="para">
        <span className="wbr">
          <strong>새로운 실거래가</strong>와 <strong>매물이 등록</strong>되었을 때
        </span>
        <span className="wbr">알림을 받으실 수 있습니다.</span>
      </p>
    </div>
  </>
);
