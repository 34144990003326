import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from '../selectors';
import apis from '@/apis';

//qalist
export function* getQaList() {
  try {
    const tradeType = yield select(selectors.mgQa.getTradetype);
    const category = yield select(selectors.mgQa.getCategory);
    const keyword = yield select(selectors.mgQa.getKeyword);
    const page = yield select(selectors.mgQa.getQaPage);

    const payload = {
      tradeType: tradeType ? tradeType : '',
      category: category ? category : '',
      keyword: keyword ? keyword : '',
      page: page ? page : 1,
    };

    //yield put(actions.getQaListLoading());
    //call api
    const response = yield call(apis.brokerPageApi.getMgQaList, payload);
    yield put(actions.getQaListSuccess(response.rltObj));
    yield put(actions.setTotalPage(response.totalCnt));
  } catch (e) {
    yield put(actions.getQaListFailure(e));
  }
}

export function* getQaDetail({ goods_idx }) {
  try {
    const payload = {
      goods_idx: goods_idx,
    };
    //yield put(actions.getQaDetailLoading());
    //call api
    const response = yield call(apis.brokerPageApi.getQaDetail, payload);
    const list = response.rltObj;
    yield put(actions.getQaDetailSuccess(list));
  } catch (e) {
    yield put(actions.getQaDetailFailure(e));
  }
}
export function* regQaAnswer({ qaIdx, val, goods_idx }) {
  try {
    const payload = {
      qa_idx: qaIdx,
      qa_answer: val,
    };

    //call api
    const response = yield call(apis.brokerPageApi.regQaAnswer, payload);
    yield put(actions.getQaDetail(goods_idx));
  } catch (e) {
    yield put(actions.getQaDetailFailure(e));
  }
}
export function* editQaAnswer({ qaAnidx, val, goods_idx }) {
  try {
    const payload = {
      qa_answer_idx: qaAnidx,
      qa_answer: val,
    };
    //call api
    const response = yield call(apis.brokerPageApi.editQaAnswer, payload);
    yield put(actions.getQaDetail(goods_idx));
  } catch (e) {
    yield put(actions.getQaDetailFailure(e));
  }
}
export function* delQaAnswer({ qaAnidx, goods_idx }) {
  try {
    const payload = {
      qa_answer_idx: qaAnidx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.delQaAnswer, payload);
    yield put(actions.getQaDetail(goods_idx));
  } catch (e) {
    yield put(actions.getQaDetailFailure(e));
  }
}
