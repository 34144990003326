import { apiClient2, apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';

export const api = {
  // Reaction 생성
  async createReaction(feedId, payload) {
    try {
      return await apiClient2.post(`/community-api/v1/feeds/${feedId}/reactions`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //커뮤니티용 안심번호 가져오기
  async getSafetyNumber(id) {
    try {
      //safety number get
      return await apiClient2.get(`/community-api/v1/feeds/${id}/writer/contact`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Reaction 삭제
  async deleteReaction(feedId) {
    try {
      return await apiClient2.delete(`/community-api/v1/feeds/${feedId}/reactions`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Tag 조회
  async getTagsBySearchText(searchText) {
    try {
      return await apiClient2.get(`/community-api/v1/tags${objectToQuerystring(searchText)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Image Upload
  async uploadImage(files) {
    try {
      return await apiClient2.post(`/community-api/v1/resources`, { images: files });
    } catch (e) {
      console.log(e);
      throw Error(e.data.detail);
    }
  },
  // Feed 생성
  async createFeed(payload) {
    try {
      return await apiClient2.post(`/community-api/v1/feeds`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Feed 수정
  async modifyFeed(feedId, payload) {
    try {
      return await apiClient2.patch(`/community-api/v1/feeds/${feedId}`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Feed 삭제
  async deleteFeed(feedId) {
    try {
      return await apiClient2.delete(`/community-api/v1/feeds/${feedId}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Feed 조회
  async getFeed(feedId) {
    try {
      const res = await apiClient2.get(`/community-api/v1/feeds/${feedId}`);
      return res;
    } catch (e) {
      throw Error(e.detail);
    }
  },
  // Feed 리스트 조회
  async getFeeds(payload) {
    try {
      return await apiClient2.post(`/community-api/v1/feeds/find`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getPhoneNumber(feedId) {
    try {
      const res = await apiClient2.get(`/community-api/v1/feeds/${feedId}/writer/contact`);
      return res;
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Comment 생성
  async createComment(payload) {
    try {
      return await apiClient2.post(`/community-api/v1/comments`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Comment 수정
  async modifyComment(commentId, payload) {
    try {
      return await apiClient2.put(`/community-api/v1/comments/${commentId}`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Comment 삭제
  async deleteComment(commentId) {
    try {
      return await apiClient2.delete(`/community-api/v1/comments/${commentId}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Comment 리스트 조회
  async getComments(payload) {
    try {
      return await apiClient2.get(`/community-api/v1/comments${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Comment 좋아요
  async creatCommentReactions(commentId, payload) {
    try {
      return await apiClient2.post(`/community-api/v1/comments/${commentId}/reactions`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // Comment 좋아요 삭제
  async deleteCommentReactions(commentId) {
    try {
      return await apiClient2.delete(`/community-api/v1/comments/${commentId}/reactions`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // 신고
  async createReport(payload) {
    try {
      return await apiClient2.post(`/community-api/v1/reports`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 실거래가 feed 조회
  async getTradesFeedId(tradeId) {
    try {
      return await apiClient.get(`/apartment/${tradeId}/trade`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 실거래가 feed 생성
  async createTradesFeedId(tradeId) {
    try {
      return await apiClient.post(`/apartment/trades/${tradeId}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 유저별 피드 조회
  async getFeedsByUser(userId, by) {
    try {
      return await apiClient2.get(`/community-api/v1/users/${userId}/feeds?by=${by}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 유저별 댓글 조회
  async getCommentsByUser(userId) {
    try {
      return await apiClient2.get(`/community-api/v1/users/${userId}/comments`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 달별 로그인, 피드, 댓글 수  통계
  async getStatisticsByUser(userId) {
    try {
      return await apiClient2.get(`/community-api/v1/users/${userId}/statistics?day=3650`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 메인화면의 커뮤니티 인기글 조회
  async getPopularFeeds() {
    try {
      return await apiClient2.get(`/community-api/v1/feeds/hot-feeds`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 내 중개지역 집찾는 손님 통계 조회
  async getMyAreaFindDawin() {
    try {
      return await apiClient2.get(`/community-api/v1/feeds/count/buyer`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
