import {
  apiClient,
  // , kakaoClient
} from '@/apis/clients';

import { objectToQuerystring } from '@/apis/helpers';
import CONSTANTS from '../../pages/RegQNA/constants';

export const api = {
  // [1] 일반 문의에만 해당함
  async postQNA(payload) {
    try {
      const path = '/qna';
      return await apiClient.post(path + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // @todo [2] 중개사에게 문의는 어디서 하는건지 모르겠음 (마이페이지에 1:1 문의 페이지 하단에는 1:1문의하기 버튼만 있는 것으로 보임)
  // async postQNAAgent(payload) {
  //     try {
  //     const path = '/qna'
  //     return await apiClient.post(path + objectToQuerystring(payload));
  //     } catch (e) {
  //     throw Error(e.message);
  //     }
  // },
};
