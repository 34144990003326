import { v1, v4 } from 'uuid';

// sessionId localstorage key
const LOG_SESSION_ID = 'log_session_id';

/**
 * sessionId 생성
 */
const getSessionIdOrGenerate = () => {
  const sessionIdData = JSON.parse(sessionStorage.getItem(LOG_SESSION_ID));
  let sessionId = sessionIdData?.value;

  if (!sessionId) {
    try {
      sessionId = window.dawin_instance.bridge.appInfoFunction.getSessionId();
    } catch {
      sessionId = `${v1()}${v4()}`.replace(/-/gi, '');
    }
    sessionStorage.setItem(LOG_SESSION_ID, JSON.stringify({ value: sessionId }));
  }

  return sessionId;
};

export default getSessionIdOrGenerate;
