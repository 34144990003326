import { getComplexAddress, getComplexDetailBulk } from '@/data/user/services';
import apis from '@/apis';

export const getMyAreas = ({ myAreas }) => {
  const result = myAreas?.map((area) => {
    const mainName = area?.district[0]?.law_sido + ' ' + area?.district[0]?.law_sigungu + ' ' + area?.district[0]?.law_dong;
    const dongCode = (area?.b_dong_code).toString();
    const name = area?.district[0]?.law_dong;

    return { mainName, dongCode, name };
  });

  return result;
};

export const getMyComplexes = ({ myComplexes }) => {
  const result = myComplexes?.map((complex) => {
    const subName = complex?.complex?.complex_address;
    const mainName = complex?.complex?.complex_name;
    const dongCode = complex?.complex?.dong_code;
    const complexCode = complex?.tb_cb_complex_idx;
    const name = complex?.complex?.dong_name;
    const complexType = complex?.complex?.complex_type;

    return { subName, mainName, dongCode, complexCode, name, complexType };
  });

  return result;
};

export const getMyHome = async () => {
  try {
    const result = await apis.myApi.getMyHomeData({});
    return result?.map((item) => {
      const subName = `${item.dong}동 ${item.ho}호 ${item.area.pyeong_name}평(${item.area.supply_area}/${item.area.exclusive_area})`;
      const dong = item?.dong;
      const floor = item?.floor;
      const maxFloor = item?.maxFloor;
      const ho = item?.ho;
      const name = item?.dong_name;
      const dongCode = item?.b_dong_code;
      const mainName = item?.complex?.complex_name;
      const complexCode = item?.complex?.tb_cb_complex_idx;
      const complexType = item?.complex?.complex_type;
      const area_no = item?.area_no;
      return { mainName, subName, dongCode, complexCode, name, complexType, dong, floor, ho, maxFloor, area_no };
    });
  } catch (e) {
    throw Error('우리집 정보를 불러오는 중 오류가 발생하였습니다.');
  }
};

export const getRecentlyViewedAreas = async () => {
  let array = [];
  const recentlyViewedAreas = JSON.parse(localStorage.getItem('fav_local'));
  if (recentlyViewedAreas) {
    const result = await getComplexAddress(recentlyViewedAreas);
    if (result) {
      for (var key in result) {
        const mainName = result[key].fullname;
        const dongCode = (result[key]?.code).toString();
        const name = result[key].name;
        array.push({ mainName, dongCode, name });
      }
    }
  }

  return array;
};

export const getRecentlyViewedComplexes = async () => {
  let array = [];
  const recentlyViewedComplexes = JSON.parse(localStorage.getItem('fav_complex'));

  if (recentlyViewedComplexes) {
    const result = await getComplexDetailBulk(recentlyViewedComplexes);

    if (result) {
      for (var key in result) {
        const subName = result[key]?.complex_address;
        const mainName = result[key]?.complex_name;
        const dongCode = (result[key]?.dong_code).toString();
        const complexCode = result[key]?.complex_idx;
        const name = result[key]?.dong_name;
        const complexType = result[key]?.complex_type;
        array.push({ subName, mainName, dongCode, complexCode, name, complexType });
      }
    }
  }

  return array;
};
