import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { STEPS, COMPLEX_DETAIL_MODE, COMPLEX_DETAIL_MODE_STORAGE_KEY, COMPLEX_DETAIL_MODE_STORAGE_KEY_OLD } from '@/data/buying/utils';
import detailReducer from './detail/reducers';
import { SIZE_MARK_TYPE } from '@/resources/global';
import { DEFAULT_COORDINATE } from '@/services/Map';
import { getDefaultMarkerType } from '@/resources/global/constants/map';

export function getBuying(initialData) {
  const mapCookieInfo = initialData.mapData;
  const defaultCenter = [DEFAULT_COORDINATE.lat, DEFAULT_COORDINATE.lng];
  const initialCenter = mapCookieInfo && mapCookieInfo.center ? mapCookieInfo.center : defaultCenter;
  const initialLevel = mapCookieInfo && mapCookieInfo.zoomLevel ? mapCookieInfo.zoomLevel : 4;

  localStorage.removeItem(COMPLEX_DETAIL_MODE_STORAGE_KEY_OLD);

  const initialComplexDetailMode = JSON.parse(localStorage.getItem(COMPLEX_DETAIL_MODE_STORAGE_KEY)) || [
    COMPLEX_DETAIL_MODE.COMPLEX,
    COMPLEX_DETAIL_MODE.GOODS_LIST,
    COMPLEX_DETAIL_MODE.REALTOR,
  ];
  const initialMarkerType = getDefaultMarkerType(); //initialComplexDetailMode === COMPLEX_DETAIL_MODE.COMPLEX ? MARKER_TYPES.REALPRICE : MARKER_TYPES.GOODS;

  const INITIAL_STATE = {
    listWide: false, //펼치기
    step: STEPS.PREVIEW,
    sizemark: SIZE_MARK_TYPE.PYEONG, //평형표기 1:㎡ 2:평
    [AT.SEARCH]: Remote.NotAsked, //조회
    [AT.SEARCH_GOODS]: Remote.NotAsked, //매물리스트 조회
    newHighList: [],
    historyList: {},
    /* 필터 */
    searchWord: '', //[검색조건] 검색어
    category: 'APT', //[검색조건] 아파트:APT | 오피스텔:OFT
    dealtype: 'all',
    tradetype: ['all'], //[검색조건] 매매 | 전세 | 월세
    areasize: ['all'], //[검색조건] 아파트 크기
    // priceRange: null,
    // cntRange: null,
    // yearRange: null,
    /* 지도 */
    center: initialCenter,
    zoomlevel: initialLevel,
    region: null,
    mapMarkerType: initialMarkerType,
    bounds: [], //지도 경계 좌표
    complexDetailMode: initialComplexDetailMode,
    selectedItem: null, //선택된 단지(단지가 화면 밖으로 나갔을 때 모바일 뷰 preview 생성 용)
    selectedItemId: null, //선택된 단지 idx
    showMapLayerList: false, // `지도` 토글
    showSurroundings: false, //주변 토글
    surroundings: {},
    surrKeywords: [],
    moveCenterTo: null,
    // [네이버매물] 관련 state 추가
    isNaverCallAllowed: true,
    startProxyIndexRaw: 0,
    showPageDescription: false, // getCookieByKey(descriptionKey) !== 'Y',
  };

  return function buying(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
      case AT.SET_ERROR:
        return { ...state, error: action.error };
      case AT.SET_LIST_WIDE:
        return { ...state, listWide: action.data };
      case AT.SET_STEP_SUCCESS:
        // 2021-02-03 정석규: [네이버매물]에서 전 스텝이 뭔지 확인해야 해서 추가함.
        return { ...state, step: action.step };
      case AT.NAVER_CALL_DISABLED:
        // 2021-02-03 정석규: 선택단지가 바뀔때, 매물목록 클릭전 불필요하게 자동으로 Naver API 호출하는것을 막기위함.
        return { ...state, isNaverCallAllowed: false };
      case AT.NAVER_CALL_ENABLED:
        return { ...state, isNaverCallAllowed: true };
      // 2021-02-22 정석규: 한 프록시만 fail때까지 계속 쓰지않고, 순서를 바꿔가면서 요청하도록 함.
      case AT.INCREMENT_PROXY_INDEX:
        return { ...state, startProxyIndexRaw: state.startProxyIndexRaw + 1 };
      case AT.SEARCH_LOADING:
        return { ...state, [AT.SEARCH]: Remote.Loading };
      case AT.SEARCH_SUCCESS:
        return { ...state, [AT.SEARCH]: Remote.Success(action.list) };
      case AT.SEARCH_FAILURE:
        return { ...state, [AT.SEARCH]: Remote.Failure(action.error) };
      case AT.SEARCH_GOODS_LOADING:
        return { ...state, [AT.SEARCH_GOODS]: Remote.Loading };
      case AT.SEARCH_GOODS_SUCCESS:
        return { ...state, [AT.SEARCH_GOODS]: Remote.Success(action.data) };
      case AT.SEARCH_GOODS_FAILURE:
        return { ...state, [AT.SEARCH_GOODS]: Remote.Failure(action.error) };
      case AT.SET_NEW_HIGH_LIST:
        return { ...state, newHighList: action.data };
      case AT.SET_HISTORY_LIST:
        return { ...state, historyList: action.list };
      case AT.CHANGE_SEARCH_WORD:
        return { ...state, searchWord: action.searchWord };
      case AT.CHANGE_CATEGORY:
        return { ...state, category: action.category };
      case AT.CHANGE_DEALTYPE:
        return { ...state, dealtype: state.dealtype === action.dealtype ? 'all' : action.dealtype };
      case AT.INITIALIZE_DEALTYPE:
        return { ...state, dealtype: INITIAL_STATE.dealtype };
      case AT.CHANGE_TRADETYPE:
        var item = action.tradetype;
        //1.선택
        if (state.tradetype.indexOf(item) < 0) {
          //1.선택
          if (state.tradetype.indexOf('all') > -1 && state.tradetype.length === 1) {
            return { ...state, tradetype: [...state.tradetype.filter((type) => type !== 'all'), item] };
          } else if ((state.tradetype.indexOf('all') < 0 && state.tradetype.length === 2) || item === 'all') {
            return { ...state, tradetype: ['all'] };
          } else {
            return { ...state, tradetype: [...state.tradetype, item] };
          }
        } else {
          //2.해제
          if (state.tradetype.length === 1) {
            //모두 선택 해제한 경우
            return { ...state, tradetype: ['all'] };
          } else {
            return {
              ...state,
              tradetype: state.tradetype.filter((type) => type !== item && type !== 'all'),
            };
          }
        }
      case AT.INITIALIZE_TRADETYPE:
        return { ...state, tradetype: INITIAL_STATE.tradetype };
      case AT.CHANGE_AREASIZE:
        var areaitem = action.areasize;
        if (state.areasize.indexOf(areaitem) < 0) {
          //1.선택
          if (state.areasize.indexOf('all') > -1 && state.areasize.length === 1) {
            return { ...state, areasize: [...state.areasize.filter((size) => size !== 'all'), areaitem] };
          } else if ((state.areasize.indexOf('all') < 0 && state.areasize.length === 4) || areaitem === 'all') {
            return { ...state, areasize: ['all'] };
          } else {
            return { ...state, areasize: [...state.areasize, areaitem] };
          }
        } else {
          //2.해제
          if (state.areasize.length === 1) {
            //모두 선택 해제한 경우
            return { ...state, areasize: ['all'] };
          } else {
            return {
              ...state,
              areasize: state.areasize.filter((size) => size !== areaitem && size !== 'all'),
            };
          }
        }
      case AT.INITIALIZE_AREASIZE:
        return { ...state, areasize: INITIAL_STATE.areasize };
      case AT.CHANGE_PRICE_RANGE:
        return { ...state, priceRange: action.payload };
      case AT.CHANGE_CNT_RANGE:
        return { ...state, cntRange: action.payload };
      case AT.CHANGE_YEAR_RANGE:
        return { ...state, yearRange: action.payload };
      case AT.REFRESH_SEARCH_CONDITION:
        return {
          ...state,
          category: INITIAL_STATE.category,
          dealtype: INITIAL_STATE.dealtype,
          tradetype: INITIAL_STATE.tradetype,
          areasize: INITIAL_STATE.areasize,
          priceRange: INITIAL_STATE.priceRange,
          cntRange: INITIAL_STATE.cntRange,
          yearRange: INITIAL_STATE.yearRange,
        };
      case AT.SET_SIZEMARK_TYPE:
        return { ...state, sizemark: action.sizemark };
      case AT.CHANGE_MAP_MARKER_TYPE:
        return { ...state, mapMarkerType: action.mapMarkerType };
      case AT.SHOW_POSITIVE_MARKER:
        return { ...state, showPositiveMarker: action.show };
      case AT.CHANGE_MAP_STATE:
        return { ...state, zoomlevel: action.zoomlevel, bounds: action.bounds, center: action.center };
      case AT.CHANGE_MAP_ZOOMLEVEL:
        return { ...state, zoomlevel: action.zoomlevel };
      case AT.CHANGE_REGION_CODE:
        return { ...state, regionCode: action.payload };
      case AT.SET_SELECTED_ITEM:
        return { ...state, selectedItemId: action.complex_idx, selectedItem: action.item };
      case AT.MOVE_CENTER:
        return { ...state, moveCenterTo: action.center };
      case AT.TOGGLE_MAPLAYER_LIST: {
        if (action.manual !== undefined) {
          // 수동 토글링
          return { ...state, showMapLayerList: action.manual };
        }
        // 자동 토글링
        return { ...state, showMapLayerList: !state.showMapLayerList };
      }
      case AT.TOGGLE_SURROUNDING_LIST:
        if (action.manual !== undefined) {
          // 수동 토글링
          return { ...state, showSurroundings: action.manual };
        }
        // 자동 토글링
        return { ...state, showSurroundings: !state.showSurroundings }; //array.splice(index, 1); //arr: state.arr.concat(action.newItem)
      case AT.SET_SURROUNDING_ITEM:
        return { ...state, surroundings: action.list };
      case AT.RELOAD_SURROUNDINGS_SUCCESS:
        return { ...state, surroundings: action.list };
      case AT.SELECT_SURR_ITEM:
        return { ...state, selectedSurrItem: action.id };
      case AT.CARD_MOUSEOVER:
        return { ...state, cardMouseover: action.id };
      case AT.SET_SELECTED_EX_GOODS_DETAIL:
        return { ...state, selectedExGoodsDetail: action.data };
      case AT.SHOW_PAGE_DESCRIPTION:
        return { ...state, showPageDescription: action.show };
      case AT.SET_COMPLEX_DETAIL_MODE:
        var prev = [...state.complexDetailMode];
        var nextComplexDetailMode = prev.includes(action.mode) ? prev.filter((item) => item !== action.mode) : [...prev, action.mode];
        localStorage.setItem(COMPLEX_DETAIL_MODE_STORAGE_KEY, JSON.stringify(nextComplexDetailMode));
        return { ...state, complexDetailMode: nextComplexDetailMode };
      case AT.SET_SELECTED_DAWIN_BROKER:
        return { ...state, selectedDawinBroker: action.data };

      // 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
      case AT.SET_THIS_DONG_REALTOR_LIST:
        return { ...state, thisDongRealtorList: action.payload };
      case AT.RESET_THIS_DONG_REALTOR_LIST:
        return { ...state, thisDongRealtorList: undefined };

      default:
        return { ...state, detail: detailReducer(state.detail, action) };
    }
  };
}
