import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.ADDRESS_GET_COMPLEX_LIST, sagas.getComplexList);
  yield takeEvery(AT.ADDRESS_GET_COMPLEX_INFO, sagas.getComplexInfo);
  // yield takeEvery(AT.ADDRESS_GET_COMPLEX_LOCATION, sagas.getComplexLocation);

  // ============================ 단지 상세 정보 ============================ //
  yield takeEvery(AT.ADDRESS_GET_ADDR_SiDo, sagas.getAddrSiDo);
  yield takeEvery(AT.ADDRESS_GET_ADDR_SiGunGu, sagas.getAddrSiGunGu);
  yield takeEvery(AT.ADDRESS_GET_ADDR_SiGunGuFirst, sagas.getAddrSiGunGuFirst);
  yield takeEvery(AT.ADDRESS_GET_ADDR_SiGunGuSecond, sagas.getAddrSiGunGuSecond);
  yield takeEvery(AT.ADDRESS_GET_ADDR_DongRi, sagas.getAddrDongRi);
  yield takeEvery(AT.ADDRESS_GET_ADDR_DongRiFirst, sagas.getAddrDongRiFirst);
  yield takeEvery(AT.ADDRESS_GET_ADDR_DongRiSecond, sagas.getAddrDongRiSecond);

  // 배지율 추가
  yield takeEvery(AT.GET_SIDO_LIST, sagas.getSidoList);
  yield takeEvery(AT.GET_SIGUNGU_LIST1, sagas.getSigunguList1);
  yield takeEvery(AT.GET_SIGUNGU_LIST2, sagas.getSigunguList2);
  yield takeEvery(AT.GET_SIGUNGU2_OR_DONG_LIST, sagas.getSigungu2OrDongList);
  yield takeEvery(AT.GET_DONG_LIST1, sagas.getDongList1);
  yield takeEvery(AT.GET_DONG_LIST2, sagas.getDongList2);
  yield takeEvery(AT.GET_COMPLEX_LIST_BY_DONG, sagas.getComplexListByDong);

  yield takeEvery(AT.ADDRESS_GET_COMPLEX_DONG_LIST, sagas.getComplexDongList);
  yield takeEvery(AT.ADDRESS_GET_COMPLEX_DONG_LIST2, sagas.getComplexDongList2);
  yield takeEvery(AT.ADDRESS_GET_FLOOR_LIST, sagas.getFloorList);
  yield takeEvery(AT.ADDRESS_GET_FLOOR_LIST2, sagas.getFloorList2);
  yield takeEvery(AT.ADDRESS_GET_HO_LIST, sagas.getHoList);
  yield takeEvery(AT.ADDRESS_GET_HO_LIST2, sagas.getHoList2);
}
