import * as AT from './actionTypes';
//import { failure, loading, success } from '@/data/utils';

//가져오기
export const getList = () => ({
  type: AT.GET_ONEONONE_LIST,
});
export const setLimit = (cate, cnt) => ({
  type: AT.SET_ONEONONE_LIMIT,
  cate,
  cnt,
});
export const setAttach = (idx, file_name) => ({
  type: AT.SET_ONEONONE_NEW_ATTACH,
  idx,
  file_name,
});
export const setCategory = (val) => ({
  type: AT.SET_ONEONONE_CATEGORY,
  val,
});
export const setKeyword = (val) => ({
  type: AT.SET_ONEONONE_KEYWORD,
  val,
});
export const setTitle = (val) => ({
  type: AT.SET_ONEONONE_TITLE,
  val,
});
export const setCont = (val) => ({
  type: AT.SET_ONEONONE_CONT,
  val,
});
export const regQnA = (values, setLoadingStatus) => ({
  type: AT.SAVE_ONEONONE_NEW_QNA,
  values,
  setLoadingStatus,
});
export const getOneOnOneListLoading = () => ({
  type: AT.GET_ONEONONE_LIST_LOADING,
});
export const getOneOnOneListSuccess = (conlist, cnt) => ({
  type: AT.GET_ONEONONE_LIST_SUCCESS,
  conlist,
  cnt,
});
export const getOneOnOneListFailure = (error) => ({
  type: AT.GET_ONEONONE_LIST_FAILURE,
  error,
});
