import * as AT from './actionTypes';

export const getKeyword = (state) => state?.my?.keyword;

//안읽은 노티 카운트
export const getUnreadNotiCount = (state) => state?.my?.notiCount;

//우리집설정
export const getMyHomeData = (state) => state?.my?.myHomeData;
export const getMyHomeModiData = (state) => state?.my?.modiHomeData;
export const getMyHomePageStatus = (state) => state?.my?.myHomeStatus;
export const getMyHomePagePreStatus = (state) => state?.my?.myHomePreStatus;
export const getSelectedComplex = (state) => state?.my?.selected;

//관심지역 설정
export const getMyAreaData = (state) => state?.my?.myAreaData;
export const getMyAreaPageStatus = (state) => state?.my?.myAreaStatus;
export const getMyAreaPagePreStatus = (state) => state?.my?.myAreaPreStatus;
export const getMyNewArea = (state) => state?.my?.newArea;

//관심단지 설정
export const getMyCpData = (state) => state?.my?.myCpData;
export const getMyCpsData = (state) => state?.my?.myCpsData;
export const getMyCpPageStatus = (state) => state?.my?.myCpStatus;
export const getMyCpPagePreStatus = (state) => state?.my?.myCpPreStatus;
export const getMyNewCp = (state) => state?.my?.myCpData?.newCp;

//관심매물
export const getMyInterestGoods = (state) => state?.my?.myInterestGoods;

export const getSaveMyHomeLoading = (state) => state?.my[AT.SAVE_MY_HOME].isLoading();
export const getSaveMyHomeFailure = (state) => state?.my[AT.SAVE_MY_HOME].isFailure();
export const getSaveMyHomeSuccess = (state) => state?.my[AT.SAVE_MY_HOME].isSuccess();

export const getCurrentTab = (state) => state?.my?.currentTab;

export const getMyGoodsWithStatus = (state) => state?.my.goods?.getWithStatus();

export const getWebNotificationList = (state) => state.my.webNotification;
