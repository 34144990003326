import React, { useEffect, useState } from 'react';
import { DATE_FORMAT_TYPE, formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';

const PostDate = ({ data }) => {
  const [postDate, setPostDate] = useState();

  useEffect(() => {
    let rawDate;
    let text = '';

    if (data?.createdAt === data?.updatedAt) {
      rawDate = data?.createdAt;
    } else {
      rawDate = data?.updatedAt;
      if (data?.status === 'DELETED') {
        text = ' (삭제됨)';
      } else {
        text = ' (수정됨)';
      }
    }

    setPostDate(formatDate(rawDate, { type: DATE_FORMAT_TYPE.B }) + text);
  }, [data]);

  return (
    <span className="data-item posted">
      <span className="head">등록일</span>
      <span className="body">{postDate}</span>
    </span>
  );
};

export default PostDate;
