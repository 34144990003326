import React, { useState } from 'react';
import img_guide01 from '@/resources/design/mobile/images/sub/img_guide01.png';
import img_guide02 from '@/resources/design/mobile/images/sub/img_guide02.png';
import img_guide03 from '@/resources/design/mobile/images/sub/img_guide03.png';
import img_guide04 from '@/resources/design/mobile/images/sub/img_guide04.png';
import img_guide05 from '@/resources/design/mobile/images/sub/img_guide05.png';
import img_guide06 from '@/resources/design/mobile/images/sub/img_guide06.png';
import sellHouse from '@/resources/design/assets/images/img_sellhome.png';
import styled from 'styled-components';
import Mask from '@/components/Mask';
import ViewSafetyPop from '../../ViewSafetyPop';
import { useDispatch } from 'react-redux';
import { CreatePoint } from '@/services/Community';
import { routeToCreate } from '@/combinedComp/Community/helpers';
import handleGoBack from '@/services/Helper/handleGoBack';
import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';
import toggle from '@/hocs/toggle';

const Detail = ({ withGoPostButton = false }) => {
  const dispatch = useDispatch();
  const [safetyPop, setSafetyPop] = useState(false);

  const onClose = () => {
    if (withGoPostButton) {
      const query = parseCallBack();
      delete query.postForBuyNotice;
      dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
    } else {
      const goBackTo = location.pathname;
      dispatch(handleGoBack(goBackTo));
    }
  };

  const viewSafetyNumber = () => {
    setSafetyPop(true);
  };

  const goPost = () => {
    const query = parseCallBack();
    delete query.postForBuyNotice;
    dispatch(actions.router.replace(`${location.pathname}${objectToQuerystring(query)}`));
    dispatch(routeToCreate(CreatePoint.POST_FOR_BUY));
  };

  return (
    <>
      <Wrapper>
        <Mask show={true} zIndex={1000} />
        <div className="dimmed_layer" style={{ display: safetyPop && 'none' }}>
          <a onClick={onClose} className="btn-img btn-close-small">
            닫기
          </a>
          <div className="lyr_inner">
            <div className="guide-box">
              <div className="lyr-title">구해줘 다윈으로 집 구하는 법</div>
              <div className="lyr-content">
                <div className="lyr-block01">
                  <p className="p01">1. 원하는 집의 조건을 등록하세요.</p>
                  <p className="p02">
                    위치 : 구, 동 또는 단지를 기준으로 등록 가능합니다.
                    <br />
                    원하는 내용 : 집을 구할 때 원하는 조건이 있으면 자세히 적어주세요.
                    <br />
                    예) 고층을 선호합니다. 빠른 입주를 원합니다.
                  </p>
                  <span className="p03-img">
                    <img src={img_guide01} alt="원하는 집의 조건을 등록하세요." />
                  </span>
                </div>
                <div className="lyr-block02">
                  <p className="p01">2. 답변 받을 방법을 선택해 주세요.</p>
                  <p className="p02">
                    채팅 : 중개사가 다윈중개 앱으로 채팅을 보냅니다. <br />
                    전화번호 : 중개사가 고객님의
                    <b onClick={viewSafetyNumber} style={{ cursor: 'pointer' }}>
                      &nbsp;안심번호
                      <i className="sc-bkkeKt feVRGJ icon-help" />
                    </b>
                    로 전화합니다.
                  </p>
                  <span className="p03-img">
                    <img src={img_guide02} alt="답변 받을 방법을 선택해 주세요." />
                  </span>
                </div>
                <div className="lyr-block01">
                  <p className="p01">3. 인근 중개사들에게 알림이 발송됩니다.</p>
                  <p className="p02">중개사는 작성자의 전화번호나 실명을 알 수 없습니다.</p>
                  <span className="p03-img">
                    <img src={img_guide03} alt="인근 중개사들에게 알림이 발송됩니다." />
                  </span>
                </div>
                <div className="lyr-block02">
                  <p className="p01">4. 중개사가 오프라인 매물은 물론 네이버 부동산 등 온라인 매물까지 찾아드립니다.</p>
                  <p className="p02">고객님께 딱 맞는 매물을 찾아서 답변드립니다.</p>
                  <span className="p03-img">
                    <img src={img_guide04} alt="중개사가 오프라인 매물은 물론 네이버부동산 등 온라인 매물까지 찾아드립니다." />
                  </span>
                </div>
                <div className="lyr-block02">
                  <p className="p01">5. 연락을 받고 마음에 드는 중개사와 상담해 보세요.</p>
                  <span className="p03-img">
                    <img src={img_guide05} alt="연락을 받고 마음에 드는 중개사와 상담해 보세요." />
                  </span>
                </div>
                <div className="lyr-block02 lyr-block-sell">
                  <div className="lyr-block-text">
                    <p className="p01">집을 내놓으시는 경우 집내놓기를 이용해주세요.</p>
                    <p className="p02">초기화면 &gt; 우측하단 집내놓기 아이콘</p>
                  </div>
                  <div className="lyr-block-figure">
                    <img src={sellHouse} alt="집내놓기" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {withGoPostButton ? (
            <div className="btn-horiz btn-area-bottom">
              <button className="btn btn-third" onClick={onClose}>
                확인
              </button>
              <button className="btn btn-primary" onClick={goPost}>
                집 구하러 가기
              </button>
            </div>
          ) : (
            <div className="btn-horiz">
              <button className="btn btn-primary" onClick={onClose}>
                확인
              </button>
            </div>
          )}
        </div>
        <ViewSafetyPop safetyPop={safetyPop} setSafetyPop={setSafetyPop} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 3000;
  left: 0;
  top: 0;
`;

export default toggle(Detail);
