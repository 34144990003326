import { call, put, select } from 'redux-saga/effects';
import * as actions from '../actions';
import { actions as allActions } from '@/data';
import { selectors } from '@/data';
import apis from '@/apis';
import { STATUS } from '@/data/utils';

//gubun: USER, BROKER
export function* getFaqList() {
  try {
    let userType = 'USER';
    const user = yield select(selectors.auth.getUserInfo);
    if (user?.mbr_type === 'BU') userType = 'BROKER';
    const result = yield apis.commonApi.getFaqList(userType);
    yield put(actions.setFaqList(result?.list));
  } catch (e) {
    yield put(actions.setFaqList(null));
  }
}

export function* addQuestion({ values, setLoadingStatus, callback }) {
  try {
    yield put(actions.addQuestionLoading());
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);

    const data = yield { qna_cate: values.qType, qna_title: values.qTitle, qna_contents: values.qContents, images: values.images };
    const result = yield apis.commonApi.addQuestion(data);
    yield put(allActions.common.alert({ contents: '1:1 문의를 등록하였습니다.' }));
    yield put(actions.addQuestionSuccess(result?.list));
    // yield put(allActions.myPage.getMyQNAList());
    callback && callback();
    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    yield alert('1:1 문의 등록에 실패하였습니다.');
    yield put(actions.addQuestionFailure());
  }
}

export function* getQnaCategoryList() {
  try {
    const result = yield apis.commonApi.getQnaCategoryList();
    yield put(actions.setQnaCategoryList(result?.list));
  } catch (e) {
    yield put(actions.setQnaCategoryList(null));
  }
}

export function* reportBroker({ values }) {
  try {
    yield put(actions.reportBrokerLoading());
    yield call(apis.commonApi.reportBroker, values);
    yield put(actions.reportBrokerSuccess());
    yield put(allActions.common.alert({ contents: '중개사를 신고하였습니다.' }));
  } catch (e) {
    yield put(actions.reportBrokerFailure(e.message));
  }
}

export function* getMyBrokerList() {
  try {
    const result = yield apis.commonApi.getMyBrokers();
    yield put(actions.setMyBrokerList(result?.rltObj));
  } catch (e) {
    //error alert(e.message)
  }
}

export function* getSearchBrokerList(action) {
  try {
    const result = yield apis.commonApi.searchBroker(action);
    yield put(actions.setSearchBrokerList(result.rltObj));
  } catch (e) {
    //error alert(e.message)
  }
}
