import React from 'react';
import toggle from '@/hocs/toggle';
import mapping from '@/services/policy/mapping';
import TemplateContentsPopup from '@/components/templates/TemplateContentsPopup';

const PolicyPopups = ({ type, onClose }) => {
  const handleClose = () => {
    onClose('terms');
  };

  const targetPolicy = mapping[type];

  if (targetPolicy) {
    return (
      <>
        <TemplateContentsPopup onClose={handleClose} title={targetPolicy.name} zIndex={200}>
          <div className="popup-local-body">
            <div className="popup-content">
              <div className="popup-content-body">{targetPolicy.contents}</div>
            </div>
          </div>
        </TemplateContentsPopup>
      </>
    );
  } else {
    return <></>;
  }
};

export default toggle(PolicyPopups);
