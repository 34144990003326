import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { getPath } from '@/components/popups/SocialSharePopup/utils.js';
import { SHARE_FROM } from '../../constants';

const LinkShare = ({ shareData }) => {
  const dispatch = useDispatch();

  const handleCopy = () => {
    const { templateArgs } = shareData || {};
    const { COMMON_PATH } = templateArgs || {};

    const input = document.createElement('input');
    const newPath = `${getPath({ path: COMMON_PATH, from: SHARE_FROM.URL })}`;
    input.setAttribute('value', newPath);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    dispatch(
      actions.common.alert({
        contents: '링크를 복사하였습니다.',
      })
    );
  };

  return (
    <a onClick={handleCopy} className="link">
      링크복사
    </a>
  );
};

export default LinkShare;
