import React from 'react';
import { MODE } from '../../index.js';
import Tab from '../../Tab';
import Search from '@/combinedComp/Search';
import InputSearchView from './InputSearch';
import SelectSearchView from './SelectSearch';

// 개편 UI
const View2 = ({
  title,
  toSelect,
  toText,
  disabled,
  isMobile,
  markTypes,
  markRule,
  searchInputPlaceholder,
  mode,
  changeMode,
  complexData,
  setComplexData,
  addressData,
  setAddressData,
  onSearchComplexSelected,
  addAllOption,
  tenDigitCode,
  dongCode,
  preventUpdateComplexData,
  tags,
  feedType,
  brokerNumber,
  setBrokerNumber,
}) => {
  return (
    <>
      <Tab mode={mode} changeMode={changeMode} />
      {mode === MODE.TEXT ? (
        <Search
          View={InputSearchView}
          totalSearch={true}
          showFilter={false}
          showSearchHistory={false}
          onSelected={onSearchComplexSelected}
          markTypes={markTypes}
          markRule={markRule}
          isMobile={isMobile}
          placeholder={searchInputPlaceholder || '단지나 아파트명을 검색하세요.'}
          defaultInput={complexData?.complex_name}
          setBrokerNumber={setBrokerNumber}
          feedType={feedType}
        />
      ) : (
        <SelectSearchView
          addressData={addressData}
          setAddressData={setAddressData}
          addAllOption={addAllOption}
          disabled={disabled}
          tenDigitCode={tenDigitCode}
          dongCode={dongCode}
          complexData={complexData}
          setComplexData={setComplexData}
          markTypes={markTypes}
          preventUpdateComplexData={preventUpdateComplexData}
          brokerNumber={brokerNumber}
          setBrokerNumber={setBrokerNumber}
          feedType={feedType}
        />
      )}
    </>
  );
};

export default View2;
