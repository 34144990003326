import React from 'react';

// 이용약관
const termsOfUse = (
  <div className="docs-policy">
    <p className="section-head">
      <strong>제1장 총칙</strong>
    </p>

    <p className="subsection-head">
      <strong>제1조 (목적)</strong>
    </p>
    <p className="subsection-body">
      본 약관은 주식회사 다윈프로퍼티(이하 ‘회사’라 합니다)가 운영하는 인터넷 서비스(이하 ‘다윈중개’라합니다)를 이용함에 있어 회사와 이용자와의 권리,
      의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
    </p>

    <p className="subsection-head">
      <strong>제2조 (용어 정의)</strong>
    </p>
    <p className="subsection-body">
      이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
      <br />
      ①“다윈중개”라 함은 회사가 컴퓨터 등 정보 통신설비를 이용하여 서비스를 제공할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 인터넷 사이트 및
      모바일 어플리케이션을 운영하는a 사업자의 의미로도 사용합니다.
      <br />② “이용자”라 함은 다윈중개에 접속하여 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
      <br />③ “회원”이라 함은 다윈중개에 접속하여 회사에 개인 정보 또는 사무실 정보를 제공하여 회원 등록을 하고 회사가 제공하는 서비스를 이용하는
      고객으로 일반 개인회원(이하 ‘개인회원’이라고 합니다)과 중개사 회원(이하 ‘책임중개사’라고 합니다)을 의미합니다. 회사는 서비스의 원활한 제공을
      위해 회원의 등급을 회사 내부의 규정에 따라 나눌 수 있습니다.
      <br />④ “비회원’이라 함은 회원 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
      <br />⑤ “이메일”이라 함은 회원의 식별과 서비스 이용을 위하여 회원이 제공하고 회사가 승인한 회원 본인의 이메일 주소를 말합니다.
      <br />⑥ “비밀번호”라 함은 이메일로 식별되는 회원의 본인 여부를 검증하고 회원의 개인정보보호를 위해 회원 자신이 설정하여 회사에 등록한 고유의
      문자와 숫자의 조합을 말합니다.
      <br />⑦ “매물정보”라 함은 회사가 개인회원으로부터 제공받아 이용자에게 노출하는 부동산 거래 정보 및 거래 물건에 대한 다양한 부가 정보를 말합니다.
      <br />⑧ “콘텐츠”라 함은 다윈중개에 게시된 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보를 말합니다.
      <br />⑧ “서비스”라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 다윈중개의 서비스를
      의미합니다
      <br />⑨ “운영자”라 함은 회사가 제공하는 다윈중개 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 자를 말합니다.
      <br />위 항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 의합니다.
    </p>

    <p className="subsection-head">
      <strong>제3조 (약관 등의 명시와 개정)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 다윈중개 인터넷 사이트 및 모바일 어플리케이션에 공지합니다. 다만, 약관의 내용은 이용자가
      연결화면을 통하여 볼 수 있도록 할 수 있습니다.
      <br />② 회사는 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
      <br />③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 이용자가 알기 쉽도록 표시하여 공지합니다.
      <br />④ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제①항의 방식에 따라 그 개정약관의 적용일자 7일 전부터
      적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정인 경우 적용일자 30일 전부터 적용일자 전일까지 서비스 홈페이지에 공지하고 기존
      회원에게는 이메일 주소로 개정약관을 발송하여 고지합니다.
      <br />⑤ 회사가 전항에 따라 회원에게 통지하면서 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는
      뜻을 명확하게 고지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 변경된 약관을 승인한 것으로 봅니다. 비회원은 개정된 약관의
      적용일부터 회사의 콘텐츠 및 서비스를 이용하는 경우 개정된 약관이 적용됩니다.
      <br />⑥ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다.
      다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
      <br />⑦ 회사가 약관의 변경 시 본 조에 따른 조치를 하였음에도 불구하고 이용자가 변경된 약관을 인지하지 못하여 발생하는 손해에 대해 회사는
      책임지지 아니합니다.
    </p>

    <p className="subsection-head">
      <strong>제4조 (약관의 해석)</strong>
    </p>
    <p className="subsection-body">
      ① 이 약관은 이용자의 동의와 회사의 승낙으로 효력이 발생합니다. 이용자의 동의는 회원인 경우 회원가입 시 동의절차를 밟으며, 비회원인 경우
      웹사이트에 접속하여 서비스를 이용하는 동안 이 약관에 동의한 것으로 간주합니다.
      <br />② 회사는 필요한 경우 전체 서비스 중 일부 콘텐츠, 특정 서비스의 이용에 대하여 개별약관 또는 운영원칙, 이용안내(이하 개별약관)를 설정할 수
      있으며, 이 약관과 개별약관의 내용이 상충되는 경우에는 해당 콘텐츠, 특정 서비스에 적용되는 개별약관이 우선 적용됩니다.
      <br />③ 개별약관에서 정하지 아니한 사항에 대하여는 이 약관의 각 조건이 적용될 수 있는 사항인 경우에 이 약관이 공통적 또는 보충적으로 적용됩니다.
      <br />④ 본 약관에서 정하지 아니한 사항이나 해석에 대해서는 개별정책, 관계법령 또는 상관례에 따릅니다.
    </p>

    <p className="section-head">
      <strong>제2장 회원의 가입 및 관리</strong>
    </p>

    <p className="subsection-head">
      <strong>제5조 (회원가입)</strong>
    </p>
    <p className="subsection-body">
      ① 회원가입은 이용자가 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을 하고 휴대폰 인증을 한 후 회사가 이러한 신청에 대하여 승낙함으로써
      체결됩니다. 회사는 회원가입 승낙의 의사표시를 해당 서비스 화면에 게시하거나 이메일 또는 기타 방법으로 통지합니다.
      <br />② 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 가입신청자가 법령 또는 약관을 위반하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 재가입 승낙을 얻은 경우에는 예외로 함<br />
      2. 회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용 신청을 하는 경우
      <br />
      3. 타인의 명의를 이용한 경우
      <br />
      4. 허위의 정보를 입력하거나, 회사가 제시하는 내용을 기재하지 않은 경우
      <br />
      5. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
      <br />
      6. 부정한 목적 또는 용도로 이 서비스를 이용하고자 하는 경우
    </p>

    <p className="subsection-body">
      ③ 회원 가입 계약의 성립시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.
      <br />④ 회사는 회원가입 절차의 완료 이후에도 제2항 각 호에 따른 사유가 발견된 경우 회원가입 승낙을 철회할 수 있습니다.
      <br />⑤ 회사는 회원에 대한 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제6조 (회원정보의 변경)</strong>
    </p>
    <p className="subsection-body">
      ① 회원은 회사가 제공하는 화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.
      <br />② 회원은 회원가입 또는 서비스 신청 시 입력한 사항이 변경되었을 경우 온라인으로 수정을 하거나 기타 방법으로 회사에 대하여 그 변경사항을
      알려야 합니다.
      <br />③ 제②항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
    </p>

    <p className="subsection-head">
      <strong>제7조 (개인정보보호)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 “정보통신망법” 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및
      회사의 개인정보취급방침이 적용 됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다.
      <br />② 회사는 이용자의 회원가입 시 서비스 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로
      합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 개인회원
      <br />
      (1) 이메일주소
      <br />
      (2) 비밀번호
      <br />
      (3) 휴대폰 번호
      <br />
      2. 책임중개사회원
      <br />
      (1) 이메일주소
      <br />
      (2) 비밀번호
      <br />
      (3) 휴대폰 번호
      <br />
      (4) 사무소 정보(사무소명, 대표자명, 사무소 주소, 사업자종류, 사업자등록증, 개설등록증, 연락처 등)
    </p>

    <p className="subsection-body">
      ③ 회사가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
      <br />④ 제공된 개인정보 및 사무소정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로
      합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
      <br />
      2. 도용방지를 위하여 본인확인에 필요한 경우
      <br />
      3. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
    </p>

    <p className="subsection-body">
      ⑤ 회사가 제②항과 제③항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의
      수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 “정보통신망 이용촉진 및 정보보호 등에 관한
      법률” 에서 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
      <br />⑥ 회사는 이용자의 개인정보를 보호하기 위해 “개인정보처리방침”을 수립하고 개인정보보호책임자를 지정하여 이를 게시하고 운영합니다.
      <br />⑦ 이용자는 언제든지 회사가 갖고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를
      취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
      <br />⑧ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이
      파기합니다. 다만, 아래의 경우에는 회원 정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원 정보를 그 보관의 목적으로만 이용합니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간
      동안 회원 정보를 보관합니다.
      <br />
      2. 회사가 이용계약을 해지하거나 회사로부터 서비스 이용정지조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기
      위한 목적으로 이용계약종료 후 3년간 아이디, 전화번호를 비롯하여 이용계약 해지와 서비스 이용정지와 관련된 정보 등의 필요정보를 보관합니다.
    </p>

    <p className="subsection-body">
      ⑨ 회사는 새로운 업체가 제휴사 또는 제휴영업점의 지위를 취득할 경우 회사는 이메일, 이동전화 단문메시지서비스(SMS), 푸쉬알림(App push)등으로
      회원에게 공지합니다. 이 때 회원이 별도의 이의제기를 하지 않을 경우 다윈중개 서비스 제공이라는 필수적인 목적을 위해 해당 개인 정보 제공 및 활용에
      동의한 것으로 간주 합니다.
      <br />⑩ 모든 이용자는 다윈중개로부터 제공받은 정보를 다른 목적으로 이용하거나 타인에게 유출 또는 제공해서는 안되며, 위반 사용으로 인한 모든
      책임을 부담해야 합니다. 또한 회원은 자신의 개인정보를 책임 있게 관리하여 타인이 회원의 개인정보를 부정하게 이용하지 않도록 해야 합니다.
      <br />⑪ 회사는 회원의 개인정보를 보호하기 위해 “정보통신망 이용촉진 및 정보보호 등에 관한 법률”상의 개인정보 유효기간제도에 따라 1년간 미접속한
      회원의 개인정보를 파기 또는 분리하여 별도로 저장/관리하며(휴면계정), 회원의 별도의 요청이 없는 한 사용이 제한됩니다.
    </p>

    <p className="section-head">
      <strong>제3장 서비스의 이용</strong>
    </p>

    <p className="subsection-head">
      <strong>제8조 (서비스 정보 제공 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사가 서비스를 통하여 이용자 스스로 부동산 매물 정보를 확인 및 이용할 수 있도록 관련 정보를 제공하는 것입니다.
      <br />② 회사는 다윈중개 인터넷 사이트 및 모바일 어플리케이션 내에서 제공하는 모든 정보에 대해서 정확성이나 신뢰성이 있는 정보를 제공하기 위해
      노력하며, 신고 받은 허위매물 정보의 삭제 조치를 취하는 등의 서비스 관리자로서의 책임을 부담합니다.
    </p>

    <p className="subsection-head">
      <strong>제9조 (매물정보 등록)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 개인회원이 다윈중개에 매물정보 등록을 요청 할 때 진위여부 확인 후, 별도의 과정을 거쳐 다윈중개에 매물정보를 등록함을 원칙으로 합니다.
      <br />② 개인회원은 매물정보를 입력하는데 필요한 정보를 성실하게 제공하여야 합니다.
      <br />③ 개인회원이 제공한 정보가 실제 매물정보와 불일치 하는 경우 회사는 개인회원이 매물 등록 시 제공한 이동전화 단문메시지서비스(SMS) 또는
      푸시알림 등을 통해 개인 회원에게 매물정보를 다시 확인한 후 매물정보를 수정을 요청합니다. 회원이 매물정보 수정에 동의하지 않는 경우 회사는 해당
      매물정보 등록을 거절할 수 있습니다.
      <br />④ 매물 정보를 제공한 개인회원이 세입자 등 매물 소유자 이외의 제3자인 경우에는 매물의 소유자에게 매물 등록에 대한 동의 여부를 확인한 후
      매물 정보를 등록할 수 있습니다. 만약 매물 소유자가 매물 정보 등록을 동의하지 않거나 매물 소유자의 등록 의사를 확인할 수 없을 때 매물 정보 등록을
      거절할 수 있습니다.
      <br />⑤ 회사는 다윈중개에 등록된 매물 중 사회통념, 관례 및 회사의 합리적인 판단에 의하여 거래가 부적합하다고 판단되는 경우 이를 직권으로 삭제할
      수 있으며 해당 개인회원의 서비스 이용을 정지 혹은 탈퇴시킬 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제10조 (매물정보 노출 및 매물정보 등록 수정, 해지)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 회원이 등록한 매물정보 등에 대해 최대 7일 이내에 해당 매물정보 진위 여부를 확인하고, 진위 여부가 확인된 이후 회원이 등록한 매물을 매물
      주소지 인근 다윈중개 회원 책임중개사 또는 매물 등록시 선택한 다윈중개 회원 책임중개사들을 통해 중개를 진행할 수 있는 매물 정보를 노출할 수
      있습니다. <br />② 회사는 회원이 등록한 매물정보의 노출순서 및 영역의 추가 등에 대한 결정 권한을 갖고 있습니다. 또한, 노출 영역 추가 등에
      대해서는 회사는 사전 공지 후 추가 변경할 수 있습니다. 그리고 회사는 사전 통지없이 회원의 매물정보 등을 다윈중개 인터넷 사이트 및 모바일
      어플리케이션 이외의 다른 인터넷 사이트 등에 노출할 수 있습니다.
      <br />③ 회원이 등록한 매물정보가 실제 매물정보와 불일치 하는 경우 회사는 회원이 가입시 제공한 이메일 또는 단문메시지서비스(SMS) 등을 통해
      회원에게 매물정보의 수정을 요청합니다. 회사가 회원이 제공한 연락수단으로 2회 이상 연락하였음에도 불구하고 연락이 되지 않을 경우의 책임은
      회원에게 있습니다.
      <br />④ 전항에 따른 회사의 정당한 매물정보 수정 요청에도 불구하고 회원이 24시간 이내에 매물정보(거래완료 혹은 노출종료와 같은 매물상태 변경
      포함)를 수정하지 않을 경우, 회사는 해당 매물정보의 노출을 중지하고 회원의 서비스 이용을 제한 할 수 있습니다.
      <br />⑤ 회사는 다윈중개에 등록된 매물 중 사회통념, 관례 및 회사의 합리적인 판단에 의하여 거래가 부적합하다고 판단되는 경우 이의 삭제를
      요청하거나 직권으로 삭제할 수 있으며 해당 회원의 서비스 이용을 정지 혹은 탈퇴시킬 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제11조 (책임중개사 선택 등 기타 관련 서비스)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 개인회원이 매물정보를 등록한 후 매물 주소지 인근 지역에서 활동하는 일정 지역 내 책임중개사 또는 매물 정보 등록시 선택한 책임중개사에게
      매물을 노출시켜 줄 수 있습니다.
      <br />② 개인회원은 매물 정보 등록시 회사가 노출시켜 주는 책임중개사중 선택 여부는 전적으로 개인회원 스스로의 판단에 따라 결정하는 것으로 회사는
      개인회원이 선택한 책임중개사를 이용하여 발생하는 모든 직접, 파생적, 징벌적, 부수적인 손해에 대해 책임을 지지 않습니다.
    </p>

    <p className="subsection-head">
      <strong>제12조 (중개사 회원 서비스 이용)</strong>
    </p>
    <p className="subsection-body">
      ① 책임중개사 회원은 회사에서 제공하는 서비스를 통해 가입하여 발급된 ID로만 서비스를 이용할 수 있습니다.
      <br />② 회사에서 제공하는 각 서비스는 서로의 ID 및 책임중개사 회원 정보를 공유하지 않습니다.
      <br />③ 회사는 책임중개사 회원의 정보 및 ID도용(부정 접속)을 막고자 IP접속 보안과 맥(MAC)정보를 수집 보관하여 접속 PC의 IP주소와 맥(MAC)정보를
      관리 합니다.
    </p>

    <p className="subsection-head">
      <strong>제13조 (중개사 회원 서비스 이용료 및 유료정보 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사가 제공하는 매물 정보 확인 등 중개 거래에 필요한 서비스는 원칙적으로 유료입니다. 단, 회사가 책임중개사 회원 인증이 필요하다고 하지 않는
      서비스에 대해서는 무료로 서비스를 제공 받을 수 있습니다.
      <br />② 회사에서 제공하는 정보 중 회사에서 정한 정보는 별도의 추가 요금을 지불하여야 사용가능합니다.
      <br />③ 서비스 이용료는 책임중개사가 다윈중개 서비스에서 제공하는 매물 정보와 매물 의뢰자 또는 매수의뢰자 회원의 정보 등 다윈중개만이 제공할 수
      있는 콘텐츠를 이용하는 요금이기 때문에 다윈중개 서비스 이용 중 다음 각 호에 해당되는 경우 원칙적으로 이용료 환불은 되지 않습니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 책임중개사 회원이 회원 가입 탈퇴 등 자발적으로 이용 계약을 해지한 경우
      <br />
      2. 제17조 내지 제19조에 의해 계약해지 또는 책임중개사 회원의 의무 위반에 해당하는 경우
      <br />
      3. 책임중개사 회원의 사정으로 인해 일정 기간 영업활동을 일시 정지한 경우
    </p>
    <p className="subsection-body">
      ④ 서비스 이용료는 서비스를 이용하는 책임중개사 회원에 따라 차별화 될 수 있으며 회사는 이러한 서비스 이용료에 대해서 명시해야 합니다.
    </p>

    <p className="subsection-head">
      <strong>제14조 (정보의 제공 및 광고의 게재)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스 화면, 전자우편 등의 방법으로 회원에게 제공할 수
      있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위 정보제공에 대해서 수신 거절을 할 수
      있습니다.
      <br />② 회사는 서비스의 운영과 관련하여 회사가 제공하는 서비스의 화면 및 홈페이지 등에 광고를 게재할 수 있습니다.
    </p>

    <p className="section-head">
      <strong>제4장 책임</strong>
    </p>

    <p className="subsection-head">
      <strong>제15조 (회사의 의무)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며 계속적이고, 안정적으로 이용자에게 서비스를 제공하기 위해 최선을
      다합니다.
      <br />② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을
      공시하고 준수합니다.
      <br />③ 회사는 서비스 및 매물정보 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이
      제기한 의견이나 불만사항에 대해서는 다윈중개 인터넷사이트내 건의사항이나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
      <br />④ 회사는 이용자가 회사에서 제공하는 유료의 콘텐츠 및 서비스를 이용하는 경우 회원이 요청하는 때 그 이용내역을 확인할 수 있도록 조치합니다.
    </p>

    <p className="subsection-head">
      <strong>제16조 (회원의 아이디 및 비밀번호에 대한 의무)</strong>
    </p>
    <p className="subsection-body">
      ① 아이디와 비밀번호에 관한 관리책임은 회원에게 있습니다.
      <br />② 회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
      <br />③ 회원이 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는
      그에 따라야 합니다.
    </p>

    <p className="subsection-head">
      <strong>제17조 (이용자의 의무)</strong>
    </p>
    <p className="subsection-body">
      ① 이용자는 다음 각호의 행위를 하여서는 안됩니다. 만약 다음 각호와 같은 행위가 확인되면 회사는 해당 이용자에게 서비스 이용에 대한 제재를 가할 수
      있으며 민형사상의 책임을 물을 수 있습니다.
    </p>

    <p className="subsection-body attr-inddent">
      1. 신청 또는 변경 시 허위 내용의 등록
      <br />
      2. 타인의 정보 도용
      <br />
      3. 허위 매물 정보의 등록
      <br />
      4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
      <br />
      5. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
      <br />
      6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
      <br />
      7. 외설 또는 폭력적인 메시지, 화상, 음향, 기타 미풍양속에 반하는 정보를 다윈에 공개 또는 게시하는 행위
      <br />
      8. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
      <br />
      9. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
    </p>

    <p className="subsection-body">
      ②회사는 전항에 규정된 위반 행위를 하는 회원에 대해 서비스 일시 중단 등의 조치를 취할 수 있으며 필요한 경우 이에 대한 시정을 요구할 수 있습니다.
    </p>
    <p></p>
    <p className="subsection-head">
      <strong>제18조 (이용계약의 해지)</strong>
    </p>
    <p className="subsection-body">
      ① 회원이 서비스 이용계약을 해지하고자 하는 때에는 언제든지 회원정보관리에서 회사가 정한 절차에 따라 회원의 계정을 삭제하고 탈퇴할 수 있습니다.
      <br />② 회원이 제18조의 규정을 위반한 경우 회사는 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한
      민, 형사상 책임도 물을 수 있습니다.
      <br />③ 회원이 서비스를 이용하는 도중, 연속하여 1년 동안 서비스를 이용하기 위해 회사의 서비스에 로그인한 기록이 없는 경우 회사는 회원의
      회원자격을 휴면처리 시킬 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제19조 (중개사 회원 이용 계약의 종료, 해지)</strong>
    </p>
    <p className="subsection-body">① 책임중개사 회원의 자발적 탈퇴</p>

    <p className="subsection-body attr-inddent">
      1. 책임중개사는 언제든지 회사에게 회원 이용 계약 해지 및 회원 탈퇴 의사를 통지할 수 있고 회사는 특별한 사유가 없는 한 이를 즉시 수락하여야
      합니다. 다만, 책임중개사는 탈퇴 의사를 통지하기 전에 모든 진행중인 절차를 완료, 철회 또는 취소해야만 합니다. 이 경우 철회 또는 취소로 인한
      불이익은 책임중개사 회원 본인이 부담하여야 합니다.
      <br />
      2. 책임중개사가 발한 의사표시로 인해 발생한 불이익에 대한 책임은 책임중개사 본인이 부담하여야 하며, 이용계약이 종료되면 회사는 책임중개사에게
      부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
      <br />
      3. 책임중개사의 의사로 이용계약을 해지한 후, 추후 재가입을 희망할 경우에는 이용계약 해지 후 3개월이 지나야 재가입이 가능합니다. 재가입을 희망할
      경우, 신규 회원 가입 절차와 동일하게 다시 가입하여야 서비스 이용이 가능합니다.
    </p>

    <p className="subsection-body">② 회사의 강제 탈퇴 및 해지</p>

    <p className="subsection-body attr-inddent">
      1. 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 강제 탈퇴 및 해지할 수 있습니다
      <br />
      1) 다른 책임중개사 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 미풍양속에 위배되는 행위를 한 경우
      <br />
      2) 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
      <br />
      3) 제5조 제2항의 승낙거부 사유가 추후 발견된 경우
      <br />
      4) 회사가 정한 서비스 운영정책을 이행하지 않거나 위반한 경우
      <br />
      5) 회사의 확인 결과 책임중개사가 휴업 또는 폐업하거나 행정처분을 속이고 영업한 경우
      <br />
      6) 중개사 서비스 이용 약관 제14조 내지 제16조의 책임중개사 회원 의무 등을 위반한 경우
      <br />
      7) 기타 회사가 합리적 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우
      <br />
      2. 회사가 책임중개사를 강제 탈퇴 및 해지를 하는 경우 회사는 책임중개사에게 이메일, 전화, 기타의 방법을 통하여 강제 탈퇴 및 해지 사유를 밝혀 해지
      의사를 통지합니다. 이용계약은 회사의 해지의사를 책임중개사에게 통지한 시점에 종료됩니다.
      <br />
      3. 본 항에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 책임중개사에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다. 이용계약의 종료와
      관련하여 발생한 손해는 이용계약이 종료된 해당 책임중개사 회원이 책임을 부담하여야 하고, 회사는 일체의 책임을 지지 않습니다.
      <br />
      4. 본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, 책임중개사 회원의 재가입 신청에 대하여 회사는 이에 대한 승낙을 거절할 수 있습니다.
    </p>

    <p className="subsection-body">
      ③ 책임중개사가 계약을 해지하는 경우, 관련법 및 개인정보처리방침에 따라 회사가 책임중개사 회원 정보를 보유하는 경우를 제외하고는 해지 즉시
      책임중개사의 모든 데이터는 소멸됩니다.
    </p>

    <p className="subsection-head">
      <strong>제19조2 (탈퇴 또는 해지 전 개시된 중개행위)</strong>
    </p>
    <p className="subsection-body">
      제19조 제1항 1.2문에도 불구하고, 일반회원 및/또는 책임중개사가 회사의 서비스를 이용을 개시하여 그 이용으로 인해 매물에 관한 정보를 교환하거나
      매물을 확인하는 등 중개행위 등 여타의 절차가 개시되었으나 중개행위 등 여타의 절차가 완료되기 전에 일반회원 및/또는 책임중개사가 제19조에 따라
      자발적 또는 강제탈퇴 및 해지로 회사의 서비스를 이용할 수 없게 되었음에도, 진행 중인 중개행위 등 일체의 절차를 완료, 취소, 철회 등을 하지 않고
      중개행위 등 여타의 절차를 진행하여 완료한 경우, 이미 개시된 중개행위 등 여타의 절차에 대해서는 회사(다윈프로퍼티)의 서비스를 이용 중인 상태가
      계속되는 것이어서 이용약관 및 중개사 회원 약관이 모두 동일하게 적용됩니다. 이에 따라 회사가 정한 서비스 이용 방법, 서비스 준칙 등을 모두
      준수해야 합니다.
    </p>

    <p className="subsection-head">
      <strong>제20조 (양도금지)</strong>
    </p>
    <p className="subsection-body">회원의 서비스 받을 권리는 이를 양도 내지 증여하거나 질권의 목적으로 사용할 수 없습니다.</p>

    <p className="subsection-head">
      <strong>제21조 (저작권의 귀속 및 이용제한)</strong>
    </p>
    <p className="subsection-body">
      ① 서서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 회원이 다윈을 이용하여 작성한 저작물에 대한 저작권은 해당 회원에게 귀속됩니다.
      <br />② 이용자는 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타
      방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
    </p>

    <p className="subsection-head">
      <strong>제22조 (책임의 한계 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 무료로 제공하는 정보 및 서비스에 관하여 “개인정보처리방침” 또는 관계법령의 벌칙, 과태료 규정 등 강행규정에 위배되지 않는 한 원칙적으로
      손해를 배상할 책임이 없습니다.
      <br />② 회사는 이용자의 귀책사유로 인한 정보 및 서비스 이용의 장애에 관한 책임을 지지 않습니다.
      <br />③ 회사는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 회사의 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.
      <br />④ 서비스에서 제공하는 정보에 대한 신뢰 여부는 전적으로 이용자 본인의 책임이며, 회사는 매물정보를 등록한 회원에 의해 발생하는 어떠한 직접,
      간접, 부수적, 파생적, 징벌적 손해, 손실, 상해 등에 대하여 도덕적, 법적 책임을 부담하지 않습니다. 또한 서비스를 통하여 노출, 배포, 전송되는
      정보를 이용자가 이용하여 발생하는 부동산 거래 등에 대하여 회사는 어떠한 도덕적, 법적 책임이나 의무도 부담하지 아니합니다.
      <br />⑤ 회원이 매물 거래를 위해 선택한 책임중개사는 전적으로 회원 스스로의 판단에 따라 결정하는 것으로 회사는 회원이 해당 책임중개사를 통해
      발생하는 모든 직접, 파생적, 징벌적, 부수적인 손해에 대해 책임을 지지 않습니다.
    </p>

    <p className="subsection-head">
      <strong>제23조 (손해배상 등)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 회원이 서비스를 이용함에 있어 회사의 고의 또는 과실로 인해 손해가 발생한 경우에는 민법 등 관련 법령 범위 내에서 그 손해를 배상합니다.
      <br />② 회원이 이 약관을 위반하거나 관계 법령을 위반하여 회사에 손해가 발생한 경우에는 회사에 그 손해를 배상하여야 합니다.
    </p>

    <p className="subsection-head">
      <strong>제24조 (분쟁해결)</strong>
    </p>
    <p className="subsection-body">
      ① 회사는 이용자 상호간 분쟁에서 발생하는 문제에 대해서 일체의 책임을 지지 않습니다. 이용자 상호간 분쟁은 당사자간에 직접 해결해야 합니다.
      <br />② 이용자 상호간에 서비스 이용과 관련하여 발생한 분쟁에 대해 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는
      분쟁조정기관의 조정에 따를 수 있습니다.
    </p>

    <p className="subsection-head">
      <strong>제25조 (재판권 및 준거법)</strong>
    </p>
    <p className="subsection-body">
      ① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
      <br />② 회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시의 회사의 주소를 관할하는 지방법원의 전속관할로 합니다.
    </p>

    <p className="section-head">
      <strong>부칙</strong>
    </p>

    <p className="subsection-head">
      <strong>제1조 (시행일)</strong>
    </p>
    <p className="subsection-body">이 약관은 2019년 04월 01일부터 적용됩니다.</p>
  </div>
);

export default termsOfUse;
