import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  yield takeLatest(AT.CREATE_FEED, sagas.createFeed);
  yield takeLatest(AT.EDIT_FEED, sagas.editFeed);
  yield takeLatest(AT.DELETE_FEED, sagas.deleteFeed);
  yield takeLatest(AT.SEARCH_TAGS, sagas.searchTags);

  yield takeLatest(AT.GET_COMMUNITY_FEEDS, sagas.getFeeds);
  yield takeLatest(AT.FEED_LIKE, sagas.feedLike);
  yield takeLatest(AT.COMMENT_LIKE, sagas.commentLike);
  yield takeLatest(AT.FEED_REPORT, sagas.report);

  yield takeLatest(AT.GET_COMMUNITY_FEED, sagas.getFeed);
  yield takeLatest(AT.GET_PHONE_NUMBER, sagas.getPhoneNumber);
  yield takeLatest(AT.GET_COMMENTS, sagas.getComments);
  yield takeLatest(AT.CREATE_COMMENT, sagas.createComment);
}
