import { loading, success, failure } from '@/data/utils';

//공동중개

export const SET_NOTICE_KEYWORD = 'BROKERPAGE/SET_NOTICE_KEYWORD';
export const SET_NOTICE_CURRENT_PAGE = 'BROKERPAGE/SET_NOTICE_CURRENT_PAGE';
export const SET_NOTICE_TOTAL_CNT = 'BROKERPAGE/SET_NOTICE_TOTAL_CNT';
export const SET_NOTICE_NEW_TITLE = 'BROKERPAGE/SET_NOTICE_NEW_TITLE';
export const SET_NOTICE_NEW_CONT = 'BROKERPAGE/SET_NOTICE_NEW_CONT';
export const SET_NOTICE_NEW_ATTACH = 'BROKERPAGE/SET_NOTICE_NEW_ATTACH';

export const SET_NOTICE_REAL = 'BROKERPAGE/SET_NOTICE_REAL';
export const GET_NOTICE_LIST = 'BROKERPAGE/GET_NOTICE_LIST';
export const GET_NOTICE_LIST_LOADING = loading(GET_NOTICE_LIST);
export const GET_NOTICE_LIST_SUCCESS = success(GET_NOTICE_LIST);
export const GET_NOTICE_LIST_FAILURE = failure(GET_NOTICE_LIST);

export const GET_NOTICE_DETAIL = 'BROKERPAGE/GET_NOTICE_DETAIL';
export const GET_NOTICE_DETAIL_LOADING = loading(GET_NOTICE_DETAIL);
export const GET_NOTICE_DETAIL_SUCCESS = success(GET_NOTICE_DETAIL);
export const GET_NOTICE_DETAIL_FAILURE = failure(GET_NOTICE_DETAIL);
