import { apiClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import { reportBroker } from '@/data/common/footer/sagas';
import { MARKER_TYPES } from '@/resources/global/constants/map';
import CONSTANTS from '@/pages/Analysis/constants';

// @note auth dir 에 request 구현하여 사용!
// 추후 auth 와 분리하여 구현할 내용이 생긴다면 사용

export const api = {
  //   async postAgentSignUpReq({ formValues}) {
  //     try {
  //       return await apiClient.post('/auth/login',objectToQuerystring(formValues));
  //     } catch (e) {
  //       throw Error(e.message);
  //     }
  //   },

  async getCandidateListSearch(payload) {
    try {
      // @todo API 완성되면 호출 제대로 하기! 그 전까지는 더미 사용
      const list = CONSTANTS.DUMMY_SEARCH_CANDIDATE_LIST;
      return { list };

      // const prefix = '/'
      // return await apiClient.get(prefix, objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getDisplayListMap(payload) {
    try {
      let api_uri = null;

      if (payload) {
        switch (payload.category) {
          case CONSTANTS.ANALYSIS_JOB_NUM:
            api_uri = '/analysis/job-cnt' + objectToQuerystring(payload);
            break;
          case MARKER_TYPES.TRADE_VOLUME:
            api_uri = '/analysis/trade_volume' + objectToQuerystring({ ...payload, category: 'APT' });
            break;
          case MARKER_TYPES.TRADE_VOLUME_BY_ADDRESS:
            delete payload.category;
            api_uri = '/analysis/trade_volume_by_address' + objectToQuerystring({ ...payload });

            break;
          case MARKER_TYPES.PRICE_FLUC:
            api_uri = '/analysis/trade_price' + objectToQuerystring({ ...payload, category: 'APT' });
            break;
          case MARKER_TYPES.PRICE_FLUC_BY_ADDRESS:
            delete payload.category;
            api_uri = '/analysis/trade_price_by_address' + objectToQuerystring({ ...payload });
            break;
          case MARKER_TYPES.RECON_FEASIBILITY:
          case MARKER_TYPES.AREA_PER_HOUSEHOLD:
          case MARKER_TYPES.FLOOR_AREA_RATIO:
            api_uri = '/analysis/rebuild_business_eva' + objectToQuerystring(payload);
            break;
          case MARKER_TYPES.PUBLIC_NOTICE:
            api_uri = '/analysis/land_price' + objectToQuerystring({ ...payload, category: 'APT' });
            break;
          case CONSTANTS.ANALYSIS_POSITIVES: {
            const category = payload.temp;
            delete payload['temp'];
            api_uri = '/analysis/dev_plan' + objectToQuerystring({ ...payload, category: category });
            break;
          }
          case CONSTANTS.ANALYSIS_SCHOOL: {
            const category = payload.temp;
            delete payload['temp'];
            const nPayload = {
              ...payload,
              category: category,
            };
            api_uri = '/analysis/school_zone' + objectToQuerystring(nPayload);
            break;
          }
          case CONSTANTS.ANALYSIS_JEONSAE_PERCENTAGE:
            api_uri = '/analysis/charter_rate' + objectToQuerystring({ ...payload, category: 'APT' });
            break;
          default:
            break;
        }

        if (api_uri) {
          return await apiClient.get(api_uri);
        } else {
          return { list: null };
        }
      }
    } catch (e) {
      throw Error(e.message);
    }
  },
  // ===================== 개발호재 알리기 ===================== //
  async postAddPositives(payload) {
    try {
      const prefix = '/analysis/addPositives';
      return await apiClient.post(prefix, objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  // ===================== 주간 실거래가 ===================== //
  async getWeeklyPriceInfo(payload) {
    try {
      // return {
      //   generalInfo: CONSTANTS.DUMMY_WEEKLY_PRICE_GENERAL_INFO,
      //   list: CONSTANTS.DUMMY_WEEKLY_PRICE_LIST,
      // };
      // @todo API 완성되면 정상화 ㄱㄱ (<프론트_요청사항> idx : 7)
      const prefix = `/analysis/weekly_deal?dongCode=${payload?.dongCode}`;
      // return await apiClient.get(prefix, objectToQuerystring(payload));
      const result = await apiClient.get(prefix);
      return result;
    } catch (e) {
      // throw Error(e.message);
      throw e;
    }
  },

  // 랭킹
  async getRankingList(payload) {
    try {
      return await apiClient.get('/analysis/complex_rank' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 랭킹 (단지가 속한 시군구 내에서의 랭킹)
   * @param {number} tb_cb_complex_idx
   * @param {object} payload
   *    gubun:랭킹 구분 - 콤마(,)로 여러개 입력 가능
   *    selMonth: (기간 개월수)
   *    ex) ?gubun=1,2,3,4&selMonth=12
   */
  async getComplexRankingInArea(tb_cb_complex_idx, payload) {
    try {
      return await apiClient.get(`/analysis/complex_rank/${tb_cb_complex_idx}${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getMinMaxPrice(complexIdx) {
    try {
      return await apiClient.get('/analysis/dong_hi_low_price' + objectToQuerystring({ complexIdx }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getReconstructScoreByIdx(complexIdx) {
    try {
      return await apiClient.get('/analysis/rebuild_detail?complexIdx=' + complexIdx);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getReconstructInvestInfo(data) {
    try {
      return await apiClient.get('/analysis/rebuild_invest_info' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getReconstructNearComplex(data) {
    try {
      return await apiClient.get('/analysis/around_trade' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //아파트 비교
  async getComplexCompare(data) {
    try {
      return await apiClient.get('/analysis/complex_compare' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexCompareGraph(data) {
    try {
      return await apiClient.get('/analysis/complex_graph' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 제보하기
  async postReport(payload) {
    try {
      return await apiClient.post('/analysis/complex_report_prc', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 아파트비교 > 입지 상세분석
  async getComplexDetailCompare(payload) {
    // console.log('getComplexDetailCompare', payload);
    try {
      return await apiClient.get('/analysis/calculated_info' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 추천
  async getRecommend(payload) {
    try {
      return await apiClient('/analysis/recommended_complex' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 실거래가
   * payload:
   * @param page 페이지
   * @param perPage 페이지 당 개수
   * @param tradeTypes 매매 s, 전세 c, 월세 m
   **/
  async getRecentTrade(bdong_code, payload) {
    try {
      // page, perPage
      return await apiClient(`/analysis/recentTrade/${bdong_code}${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 신고가
   * payload:
   * tradeType=s : (option) s:매매, c:전세 default = s
   * complexType=ALL : (option) ALL:전체, APT:아파트, OFT:오피스텔 default = ALL
   * page=1 : (option) 페이지 default = 1
   * perPage=20 : (option) 페이지별 표시 수 default = 20
   */
  async getNewhighByDong(bdong_code, payload) {
    try {
      return await apiClient(`/analysis/newHighPrice/${bdong_code}${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getAnalysisLinkMenu() {
    try {
      return await apiClient(`/analysis/analysisLinkMenu`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getBestComplexByDate(payload) {
    try {
      return await apiClient(`/analysis/bestComplexByDate?from=${payload.from}&to=${payload.to}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getStatAccumulate() {
    try {
      return await apiClient(`/analysis/stat/accumulate`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
