import * as AT from './actionTypes';

export const getAttach = (state) => state?.brokerPage?.notice?.new?.attach;
export const getKeyword = (state) => state?.brokerPage?.notice?.keyword;
export const getPage = (state) => state?.brokerPage?.notice?.page;
export const getTotalCnt = (state) => state?.brokerPage?.notice?.totalCnt;
export const getList = (state) => state?.brokerPage?.notice?.conlist;
export const getRealList = (state) => state?.brokerPage?.notice?.real;
export const getDetail = (state) => state?.brokerPage?.notice?.detail;
export const getDetailIdx = (state) => state?.brokerPage?.notice?.detailIdx;
export const getPreIdx = (state) => state?.brokerPage?.notice?.detail?.pre_idx;
export const getPreTitle = (state) => state?.brokerPage?.notice?.detail?.pre_title;
export const getNextIdx = (state) => state?.brokerPage?.notice?.detail?.next_idx;
export const getNextTitle = (state) => state?.brokerPage?.notice?.detail?.next_title;
