export const sidoRegionCode = {
  sidoRegion: [
    {
      REGION_NUM: `11`,
      REGION_NAME: '서울',
    },
    {
      REGION_NUM: `26`,
      REGION_NAME: '부산',
    },
    {
      REGION_NUM: `27`,
      REGION_NAME: '대구',
    },
    {
      REGION_NUM: `28`,
      REGION_NAME: '인천',
    },
    {
      REGION_NUM: `29`,
      REGION_NAME: '광주',
    },
    {
      REGION_NUM: `30`,
      REGION_NAME: '대전',
    },
    {
      REGION_NUM: `31`,
      REGION_NAME: '울산',
    },
    {
      REGION_NUM: `36`,
      REGION_NAME: '세종',
    },
    {
      REGION_NUM: `41`,
      REGION_NAME: '경기',
    },
    {
      REGION_NUM: `42`,
      REGION_NAME: '강원',
    },
    {
      REGION_NUM: `43`,
      REGION_NAME: '충북',
    },
    {
      REGION_NUM: `44`,
      REGION_NAME: '충남',
    },
    {
      REGION_NUM: `45`,
      REGION_NAME: '전북',
    },
    {
      REGION_NUM: `46`,
      REGION_NAME: '전남',
    },
    {
      REGION_NUM: `47`,
      REGION_NAME: '경북',
    },
    {
      REGION_NUM: `48`,
      REGION_NAME: '경남',
    },
    {
      REGION_NUM: `50`,
      REGION_NAME: '제주',
    },
  ],
};

export const BuildingTypes = {
  APT: 'APT',
  OFT: 'OFT',
  ABYG: 'ABYG',
  JGC: 'JGC',
  OBYG: 'OBYG',
};

export const BuildingList = Object.keys(BuildingTypes);
