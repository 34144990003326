import React, { useEffect, useState } from 'react';
import DialogPopup from '../DialogPopup';
import buiToggle from '@/assets/js/bui.toggle';
import useAsyncPopup from '@/hooks/useAsyncPopup';

/**
 * dispatch(actions.common.confirm()) 과 같은 방식으로 파라미터 넘기면 됨.
 *
 * @param {String} popupId (필수) 해당 confirm 팝업의 id.
 * @returns ([ openAsyncPopup, AsyncPopupComponent ])
 */
const useAsyncConfirm = ({ popupId, ...rest }) => {
  if (!popupId) {
    throw Error('useAsyncConfirm 필수값 누락 (popupId)');
  }

  const { onCancel, onConfirm } = rest;

  const [dialogPopup, setDialogPopup] = useState();

  useEffect(() => {
    const newDialogPopup = new buiToggle(`[data-bui-toggle="${popupId}"]`, {
      reactTarget: 'html',
      reactTargetActiveClass: 'active-dialog-popup',
      focusin: true,
    });
    setDialogPopup(newDialogPopup);
  }, []);

  const clearPopup = (callback) => {
    dialogPopup.inactive(popupId);

    if (callback) {
      callback();
    }
  };

  const cancel = () => {
    resolver.resolve(false);
    clearPopup(onCancel);
  };

  const confirm = () => {
    resolver.resolve(true);
    clearPopup(onConfirm);
  };

  const Component = () => {
    if (dialogPopup) {
      return <DialogPopup dialogPopup={dialogPopup} popupId={popupId} show={true} onClose={cancel} onConfirm={confirm} {...rest} />;
    } else {
      return <></>;
    }
  };

  const { openAsyncPopup, AsyncPopupComponent, resolver } = useAsyncPopup({ PopupComponent: Component });

  return [openAsyncPopup, AsyncPopupComponent];
};

export default useAsyncConfirm;
