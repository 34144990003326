import React from 'react';
import useGetButtonName from '../../hooks/useGetButtonName';
import useSubmit from '../../hooks/useSubmit';

const Btn = ({ errors, handleSubmit, loginStep, loadingStatus, setLoadingStatus, getValues, reset, candidateList, selectedId, varificationCode }) => {
  const [buttonText] = useGetButtonName({ loadingStatus, loginStep });
  const [submit] = useSubmit({ errors, handleSubmit, loadingStatus, loginStep, getValues, setLoadingStatus, reset });

  return (
    <div className="button-display module-a style-a type-a">
      <div className="button-area">
        <button
          type="submit"
          className="btn module-b style-b type-fill accent-01 large-1x flex"
          onClick={handleSubmit(() => submit({ candidateList, selectedId, varificationCode }))}>
          <span className="btn-text">{buttonText({ loadingStatus, loginStep })}</span>
        </button>
      </div>
    </div>
  );
};

export default Btn;
