import autoComma from './autoComma';
import autoHypenPhone from './autoHypenPhone';
import getUrlParams from './getUrlParams';
import { OSTYPE } from '@/services/apps/types/OsType';
import { parseCallBack, objectToQuerystring } from './queryString';

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const jsonToKeyValue = (json) => {
  const newjson = [];
  for (var item in json) newjson.push({ key: item, value: json[item] });
  return newjson;
};

//폰번호 000-0000-0000 형식으로 변경
const hyphenPhoneNumber = (phone) => {
  return phone
    .replace(/[^0-9]/g, '')
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
    .replace('--', '-');
};

export const pressPhoneNumber = (e) => {
  const c = e.which;
  if (!((c > 47 && c < 58) || c === 229)) {
    e.preventDefault();
  }
};

export const validPhoneNumber = (e) => {
  const tv = numberInputOnly(e).substring(0, 11);
  e.target.value = tv;
  const valid = e.target.validity.valid;
  return valid;
};

export const numberInputOnly = (e) => {
  const tv = e.target.value.replace(/\D+/g, '');
  e.target.value = tv;
  return e.target.value;
};

/**
 * @param {Number} number 소수점 반올림 할 대상 숫자
 * @param {Number} number 소수점 몇 째 자리에서 반올림 할 것인지
 * ex) number: 40.474638580987 cnt:2  => 결과: 40.47
 */
export const roundDecimal = (number, cnt) => {
  let temp = Math.round(number * Math.pow(10, cnt));
  return parseFloat((temp / Math.pow(10, cnt)).toFixed(cnt));
};

export const detectIsMobileDevice = () => {
  const ostype = window.dawin_instance.osType;
  return ostype === OSTYPE.WEB_ANDROID || ostype === OSTYPE.WEB_IOS;
};

export function getPyeongNumeric(pyeong) {
  if (pyeong) {
    const match = pyeong?.match(/([0-9]+).*/);
    return match[1];
  } else {
    return;
  }
}

export { parseCallBack, objectToQuerystring, parseJwt, jsonToKeyValue, autoComma, autoHypenPhone, hyphenPhoneNumber, getUrlParams };
