import { actions, selectors } from '@/data';
import { getTokenCookie } from '@/data/cookie';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { DEV } from '@/resources/env/index.js';

const AVAILABLE_MESSAGE_RULE = {
  push: function({ method, path }) {
    // const USE_NOTI_PATH = ['/push/categories/chat', '/push/categories/favorite_complex_item', '/push/categories/favorite_area_item'];
    if (method === 'POST' && path === '/push/categories/chat') {
      return true;
    } else {
      return false;
    }
  },
  goods: function({ method }) {
    if (method === 'POST') {
      return true;
    } else {
      return false;
    }
  },
};

const useWebsocketNotificationData = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectors.auth.getUserInfo);
  const [socket, setSocket] = useState();
  const [connected, setConnected] = useState(false);

  const disconnect = () => {
    if (socket) {
      socket.close();
      setSocket(null);
      dispatch(actions.my.cleanWebNotification());
    }
  };

  function connect() {
    // 웹소켓 연결
    // wss://o53vfupin2.execute-api.ap-northeast-2.amazonaws.com/dev
    // wss://xip87n6bl0.execute-api.ap-northeast-2.amazonaws.com/test
    // wss://zx5q2u4ate.execute-api.ap-northeast-2.amazonaws.com/prod
    const socket = new WebSocket(
      DEV ? 'wss://xip87n6bl0.execute-api.ap-northeast-2.amazonaws.com/test' : 'wss://zx5q2u4ate.execute-api.ap-northeast-2.amazonaws.com/prod'
    );

    socket.onopen = () => {
      // console.log('[noti] websocket onopen');
      setConnected(true);
    };

    socket.onmessage = (e) => {
      // console.log('[noti] websocket onmessage', JSON.parse(e.data));

      const obj = JSON.parse(e.data);
      const path = obj.path;
      let body = obj.body;

      if (typeof body === 'string') {
        try {
          body = JSON.parse(body);
        } catch (e) {
          return;
        }
      }

      obj.body = body;

      const type = path.split('/')?.[1];
      const availableMessageRule = AVAILABLE_MESSAGE_RULE[type];
      const availableMessage = availableMessageRule(obj);

      if (!availableMessage) {
        return;
      }

      obj.idx = v4();
      obj.createdAt = new Date().getTime();

      dispatch(actions.my.addWebNotification(obj));
    };

    socket.onclose = (e) => {
      // console.log(`[noti] socket closed. reconnect. (${e.reason})`);
      setConnected(false);

      setTimeout(async () => {
        connect();
      }, 1000);
    };

    socket.onerror = (error) => {
      // console.log(`[noti] socket error: ${error}. socket close.`);
      disconnect();
      setConnected(false);

      setTimeout(async () => {
        connect();
      }, 1000);
    };

    setSocket(socket);
  }

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  useEffect(async () => {
    if (connected) {
      if (userInfo) {
        const token = await getTokenCookie();
        socket.send(JSON.stringify({ token: `Bearer ${token}` }));
      } else {
        socket.send(JSON.stringify({ guest: 'guest' }));
      }
    }
  }, [userInfo, connected]);

  useEffect(async () => {
    connect();
  }, []);
};

export default useWebsocketNotificationData;
