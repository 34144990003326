import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { buiFormRange } from '@/resources/design/v2/js/bui.template';

function valuetext(value) {
  return value;
}
const OptionSlider2 = ({ id, title, marks, range, rangeString, min, max, step, handleChange }) => {
  const minRef = useRef();
  const maxRef = useRef();

  const onChangeMin = (e) => {
    handleChange([Number(e.target.value), range?.[1] || 0]);
  };

  const onChangeMax = (e) => {
    handleChange([range?.[0] || -30, Number(e.target.value)]);
  };

  useEffect(() => {
    buiFormRange(`#${id}`);

    minRef.current.addEventListener('change', onChangeMin);
    maxRef.current.addEventListener('change', onChangeMax);

    return () => {
      minRef?.current?.removeEventListener('change', onChangeMin);
      maxRef?.current?.removeEventListener('change', onChangeMax);
    };
  }, [range]);

  const min_text = range?.[0] || -30;
  const max_text = range?.[1] || 0;

  return (
    <>
      <div className="form-item">
        <div className="form-wrap">
          <div className="form-head">
            <label className="form-name" htmlFor="#">
              {title}
            </label>
          </div>
          <div className="form-body">
            <div className="form-area">
              <div id={id} className="form range style-a type-a flex attr-tooltip" data-bui-step={rangeString}>
                <input type="hidden" value={`${JSON.stringify(range)}`} />
                <input
                  ref={minRef}
                  type="range"
                  className="form-elem range-min"
                  title="최소"
                  min={min}
                  max={max}
                  step={step}
                  defaultValue={min_text}
                />
                <input
                  ref={maxRef}
                  type="range"
                  className="form-elem range-max"
                  title="최대"
                  min={min}
                  max={max}
                  step={step}
                  defaultValue={max_text}
                />
                {/* <span className="form-range-slider">
                  <span className="entered-track"></span>
                  <span className="entered-value" style={{ left: '0%', right: '0%' }}></span>
                  <span className="thumb-minimum" data-bui-value="0" style={{ left: '0%' }}></span>
                  <span className="thumb-maximum" data-bui-value="3000" style={{ right: '0%' }}></span>
                </span>
                <span className="form-step-list">
                  <span className="form-step-item">0</span>
                  <span className="form-step-item">1,000</span>
                  <span className="form-step-item">2.000</span>
                  <span className="form-step-item">3,000 이상</span>
                </span> */}
              </div>
              {/* <SliderStyle className="slider-wrap">
                <Slider
                  value={range}
                  onChange={onChange}
                  marks={marks}
                  step={step}
                  min={min}
                  max={max}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={valuetext}
                />
              </SliderStyle> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SliderStyle = styled.div`
  width: 100%;

  .MuiSlider-root {
    color: white;
  }
  .MuiSlider-rail {
    color: #eaeaea;
    height: 3.5px;
    opacity: 1;
  }
  .MuiSlider-track {
    height: 2rem;
    color: #f64e23;
  }
  .MuiSlider-thumb {
    content: '';
    width: 24rem;
    height: 24rem;
    box-sizing: border-box;
    margin-top: -12rem;
    background-color: #fff;
    border: 8rem solid #f64e23;
    border-radius: 50%;
    display: block;
  }

  /* .MuiSlider-thumb:after {
    content: attr(data-bui-value);
    height: 24rem;
    color: #353535;
    text-align: center;
    opacity: 0;
    -o-transition: all 0.15s ease-out;
    background-color: #fff;
    border: 1rem solid #cecece;
    border-radius: 16rem;
    align-items: center;
    padding: 0 8rem;
    font-size: 13.3333rem;
    line-height: 20rem;
    transition: all 0.15s ease-out;
    display: flex;
    position: absolute;
    bottom: 100%;
    transform: scale(0);
  } */
  .MuiSlider-mark {
    height: 5px;
  }
  .MuiSlider-markLabel {
    font-family: unset;
  }
`;

export default OptionSlider2;
