import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';
import CONSTANTS from '../../pages/Rating/constants';

// ===================== 마이페이지 > 집구하기, 집내놓기 ===================== //

// @todo <프론트_요청사항> idx:14
export function* postRating() {
  try {
    // * goodsIdx : (매물 번호)
    // * brokerOfficeIdx : (중개소 idx)
    // * type : (판매 : 0 / 구매 : 1)
    // * rate1 : (첫번째 평가항목 점수)
    // * rate2 : (두번째 평가항목 점수)
    // * rate3 : (셋번째 평가항목 점수)
    // * rate4 : (넷번째 평가항목 점수)
    // * rate5Yn (다섯번째 평가항목 Y or N)
    // reason (평가 항목 이유)

    const ratingType = yield select(selectors.getRatingType);
    const rt = ratingType == CONSTANTS.RATING_SELLER ? 0 : ratingType == CONSTANTS.RATING_BUYER ? 1 : ratingType == CONSTANTS.SURVEY_NOMEM ? 2 : 3;

    if (rt != 3) {
      yield put(actions.postRatingLoading());
      const ratingList = yield select(selectors.getRatingList);
      const payload = {
        goodsIdx: yield select(selectors.getRatingTargetItemIdx),
        brokerIdx: yield select(selectors.getRatingTargetBrokerIdx),
        evaltype: rt === 0 ? 'sell' : 'buy',
        rateOverall: ratingList[0],
        rate1: ratingList[1],
        rate2: ratingList[2],
        rate3: ratingList[3],
        rate4: ratingList[4],
        rate5: ratingList[5],
        etc: ratingList[6],
      };

      const result = yield call(apis.ratingApi.postRating, payload);

      yield put(actions.postRatingSuccess(result));
    } else {
      yield put(actions.postRatingFailure(text.ratingText.ERROR_TEXT));
    }
  } catch (e) {
    yield put(actions.postRatingFailure(e.message));
  }
}
export function* postRatingUrl() {
  try {
    const ratingType = yield select(selectors.getRatingType);

    yield put(actions.postRatingUrlLoading());
    const ratingList = yield select(selectors.getRatingList);
    const gubun = yield select(selectors.getRatingGubun);
    const payload = {
      goodsIdx: yield select(selectors.getRatingTargetItemIdx),
      evaltype: gubun === 'B' ? 'buy' : 'sell',
      rateOverall: ratingList[0],
      rate1: ratingList[1],
      rate2: ratingList[2],
      rate3: ratingList[3],
      rate4: ratingList[4],
      rate5: ratingList[5],
      etc: ratingList[6],
    };

    const result = yield call(apis.ratingApi.postRatingUrl, payload);
    yield put(actions.postRatingUrlSuccess(result));
  } catch (e) {
    yield put(actions.postRatingUrlFailure(e.message));
  }
}
