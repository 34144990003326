import envs from '@/resources/env';
import { Map, Set, List } from 'immutable';
import { useEffect, useMemo, useState } from 'react';
import apis from '@/apis';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getTokenCookie } from '@/data/cookie';
import { STATUS } from '@/data/utils';

const ROOM_READ_LIMIT = 50;
const MESSAGE_READ_LIMIT = 50;

class UserRepository {
  constructor() {}

  init() {
    const [users, setUsers] = useState(Map());
    this._users = users;
    this._setUsers = setUsers;

    return users;
  }

  /**
   * 입력된 userIds 중에 존재하지 않는 userId만 load 한다.
   * @param {Immutable Set} userIds
   * @returns void
   */
  async loadUser(userIds) {
    if (!userIds || userIds.isEmpty()) {
      return;
    }

    const newUserIds = userIds.subtract(this._users.keys());

    if (newUserIds.isEmpty()) {
      return;
    }

    try {
      const newUsers = (await apis.commonApi.bulkGetUsers(newUserIds)).users;

      if (!newUsers || newUsers.length === 0) {
        return;
      }

      this._setUsers((prev) => {
        return prev.merge(newUsers.map((e) => [e.userId, e]));
      });
    } catch (e) {
      //
    }
  }
}

class SelectedRoomRepository {
  constructor() {}

  init() {
    // const { roomId } = useParams();

    const match = useRouteMatch('/chat/:roomId');
    const brokerMatch = useRouteMatch('/brokerPage/chat/:roomId');

    const roomId = match?.params?.roomId || brokerMatch?.params?.roomId;
    const history = useHistory();

    this._selectedRoomId = roomId;
    this._history = history;

    return roomId;
  }

  setSelectedRoom(roomId, isBroker) {
    const baseUrl = isBroker ? '/brokerPage/chat' : '/chat';

    if (/\/chat\/+/gi.test(location.pathname)) {
      this._history.replace(`${baseUrl}/${roomId}`);
    } else {
      this._history.push(`${baseUrl}/${roomId}`);
    }
  }
}

class MessageRepository {
  constructor() {}

  init() {
    const [messages, setMessages] = useState(Map());
    const [messageStatus, setMessageStatus] = useState(STATUS.NOTASKED);

    this._messages = messages;
    this._setMessages = setMessages;
    this._setMessageStatus = setMessageStatus;

    return { messages, messageStatus };
  }

  _getMessagesByRoom(roomId) {
    return this._messages.get(roomId, List());
  }
  _addMessages(roomId, messages) {
    if (!messages || messages.length === 0) {
      return;
    }

    this._setMessages((prev) => {
      const roomMessages = prev.get(roomId, List());
      const newRoomMessages = roomMessages
        .merge(messages.filter((e) => roomMessages.find((ie) => ie.id === e.id) === undefined))
        .sort(function(a, b) {
          return a.createdAt - b.createdAt;
        });

      return prev.merge([[roomId, newRoomMessages]]);
    });
  }

  async loadMessage(roomId, direction) {
    if (!roomId) {
      return;
    }

    try {
      this._setMessageStatus(STATUS.LOADING);

      const messages = this._messages.get(roomId, List());

      if (messages.isEmpty() || direction === undefined) {
        this._addMessages(
          roomId,
          await apis.chatApi.getMessage({
            roomId: roomId,
            limit: MESSAGE_READ_LIMIT,
            reverse: true,
          })
        );
      } else if (direction) {
        const oldestMsg = this._getMessagesByRoom(roomId).min((a, b) => a.createdAt - b.createdAt);

        this._addMessages(
          roomId,
          await apis.chatApi.getMessage({
            roomId: roomId,
            limit: MESSAGE_READ_LIMIT,
            reverse: true,
            cursor: oldestMsg.id,
          })
        );
      } else {
        const latestMsg = this._getMessagesByRoom(roomId).max((a, b) => a.createdAt - b.createdAt);
        this._addMessages(
          roomId,
          await apis.chatApi.getMessage({
            roomId: roomId,
            limit: MESSAGE_READ_LIMIT,
            reverse: false,
            cursor: latestMsg.id,
          })
        );
      }

      /**
       * 완료 후 notasked상태로 전환.
       * 전환해주지 않으면 발생하는 문제:
       *  web에서 채팅방이 A에서 B로 바로 swich 가능해서, A방 메세지가 success 상태인데 B에서 처음에 메세지를 모두 불러온 것으로 인식함.
       *  더 나은 방법 있을까?
       */
      this._setMessageStatus(STATUS.SUCCESS);
      // this._setMessageStatus(STATUS.NOTASKED);
    } catch (e) {
      this._setMessageStatus(STATUS.FAILURE);
    }
  }
}

class RoomRepository {
  constructor() {}

  init() {
    const [rooms, setRooms] = useState(Map());
    const [loadingRooms, setLoadingRooms] = useState(false);

    this._rooms = rooms;
    this._setRooms = setRooms;
    this._setLoadingRooms = setLoadingRooms;

    return { rooms, loadingRooms };
  }

  _getLastCreatedAt() {
    if (!this._rooms || this._rooms.isEmpty()) {
      return undefined;
    }

    return this._rooms.min((a, b) => a.lastMessage.createdAt - b.lastMessage.createdAt).lastMessage.createdAt;
  }

  async setRoom(roomId, room) {
    this._setRooms((prev) => {
      return prev.merge([[roomId, room]]);
    });
  }

  async patchRoom(roomId, patcher) {
    await this.loadRoom(roomId);

    this._setRooms((prev) => {
      const room = prev.get(roomId);
      if (!(room && patcher)) {
        return prev;
      }

      return prev.merge([[roomId, patcher(room)]]);
    });
  }

  async loadRoom(roomId) {
    if (!this._rooms || this._rooms.has(roomId)) {
      return;
    }

    const room = await apis.chatApi.getRoom({ roomId: roomId });
    this._setRooms((prev) => {
      return prev.merge([[room.roomId, room]]);
    });
  }

  async loadRooms(isInit = false) {
    this._setLoadingRooms(true);
    const lastMessageCreatedAt = this._getLastCreatedAt();

    const rooms = await apis.chatApi.getRooms({
      offsetTimestamp: isInit ? undefined : lastMessageCreatedAt,
      limit: ROOM_READ_LIMIT,
    });
    this._setRooms((prev) => {
      return prev.merge(rooms.map((e) => [e.roomId, e]));
    });
    this._setLoadingRooms(false);
  }

  async updateLastMessages(messages) {
    const notLoadedRooms = messages
      .keySeq()
      .toSet()
      .subtract(this._rooms.keys());
    if (!notLoadedRooms.isEmpty()) {
      await Promise.all(notLoadedRooms.map((e) => this.loadRoom(e)).toArray());
    }

    this._setRooms((prev) => {
      const neededToModify = prev
        .filter((v, k) => {
          const roomMessages = messages.get(k, List());
          const lastMessage = roomMessages.last();
          return lastMessage && lastMessage.id !== v.lastMessage.id && lastMessage.createdAt > v.lastMessage.createdAt;
        })
        .map((v, k) => {
          const roomMessages = messages.get(k, List());
          return {
            ...v,
            lastMessage: roomMessages.last(),
          };
        });

      if (neededToModify.isEmpty()) {
        return prev;
      } else {
        return prev.merge(neededToModify);
      }
    });
  }
}

export class ChatController {
  constructor() {
    this._selectedRoomRepository = new SelectedRoomRepository();
    this._userRepository = new UserRepository();
    this._roomRepository = new RoomRepository();
    this._messageRepository = new MessageRepository();
  }

  init(userId) {
    const [socket, setSocket] = useState();

    this._socket = socket;
    this._setSocket = setSocket;

    const selectedRoomId = this._selectedRoomRepository.init();
    const users = this._userRepository.init();
    const { rooms, loadingRooms } = this._roomRepository.init();
    const { messages, messageStatus } = this._messageRepository.init();

    const [sendingMessage, setSendingMessage] = useState(false);

    this._setSendingMessage = setSendingMessage;

    this._userId = userId;

    useEffect(async () => {
      this._afterInit();

      // 웹소켓 연결
      await this._connect();

      return () => {
        if (socket) {
          // console.log(`[chat] websocket unmount - close check - ${socket.close}`);
          socket.close?.();
          setSocket(null);
        }
      };
    }, []);

    useEffect(() => {
      this._afterModifiedSelectedRoomId(selectedRoomId);
    }, [selectedRoomId]);

    useEffect(() => {
      this._afterModifiedRooms(rooms);
    }, [rooms]);

    useEffect(() => {
      this._afterModifiedMessages(messages);
    }, [messages]);

    useEffect(() => {
      this._updateCursor(selectedRoomId, rooms, messages, userId);
    }, [selectedRoomId, messages]);

    const controller = useMemo(() => {
      return {
        users,
        rooms,
        loadingRooms,
        messages,
        messageStatus,
        selectedRoomId,
        sendingMessage,
      };
    }, [users, rooms, loadingRooms, messages, messageStatus, selectedRoomId, sendingMessage]);

    return controller;
  }

  async _connect() {
    const _socket = new WebSocket(envs.CHAT_SERVER_URL);

    const token = await getTokenCookie();

    _socket.onopen = () => {
      // console.log(`[chat] websocket onopen`);
      _socket.send(JSON.stringify({ token: `Bearer ${token}` }));
    };

    _socket.onmessage = (e) => {
      // console.log(`[chat] websocket onmessage`, JSON.parse(e.data));
      const obj = JSON.parse(e.data);
      const method = obj.method;
      const path = obj.path;
      const body = obj.body;

      if (method !== 'POST') {
        return;
      }

      if (/\/rooms\/[a-zA-Z0-9]+\/messages\/[a-zA-Z0-9]+/g.test(path)) {
        // 메세지 보내기 api 에서 false로 해제해줬을 때, 웹소켓 응답이 더 빨라 로딩중 표시가 적절한 타이밍에 사라지지 않는 현상 발생함.
        this._setSendingMessage(false);

        this._messageRepository._addMessages(body.roomId, [body]);

        if (body.senderId !== this._userId) {
          this._roomRepository.patchRoom(body.roomId, (room) => {
            return {
              ...room,
              notReadCount: room.notReadCount + 1,
            };
          });
        }
      } else if (/\/rooms\/[a-zA-Z0-9]+/g.test(path)) {
        // this._roomRepository.patchRoom(body.lastMessage.roomId, (room) => {
        //     return {
        //         ...room,
        //         ...body
        //     }
        // });
      }
    };

    _socket.onclose = (e) => {
      console.log(`chat closed. reconnect. (${e.reason})`);

      setTimeout(async () => {
        await this._connect();
      }, 1000);
    };

    _socket.onerror = (error) => {
      console.log(`chat error: ${error}. socket close.`);

      setTimeout(async () => {
        await this._connect();
      }, 1000);
    };

    this._setSocket(_socket);
  }

  async _updateCursor(selectedRoomId, rooms, messages, userId) {
    const room = rooms.get(selectedRoomId);
    const lastMessage = messages.get(selectedRoomId, List()).last();

    if (!(room && lastMessage && room.cursors[userId] !== lastMessage.id)) {
      return;
    }

    await apis.chatApi.updateCursor({ roomId: selectedRoomId, cursor: lastMessage.id });

    await this._roomRepository.patchRoom(selectedRoomId, (room) => {
      const cursors = {
        ...room.cursors,
      };
      cursors[userId] = lastMessage.id;
      return {
        ...room,
        cursors: cursors,
        notReadCount: 0,
      };
    });
  }

  async _afterInit() {
    // load rooms
    await this._roomRepository.loadRooms();
  }

  async _afterModifiedSelectedRoomId(selectedRoomId) {
    // load messages
    await this._messageRepository.loadMessage(selectedRoomId, undefined);
  }

  async _afterModifiedRooms(rooms) {
    // load user
    const userIds = rooms?.entrySeq()?.flatMap(([k, v]) => v.userIds);
    await this._userRepository.loadUser(Set(userIds));
  }

  async _afterModifiedMessages(messages) {
    await this._roomRepository.updateLastMessages(messages, this._userId);
  }

  async _sendImageMessage(roomId, content) {
    this._setSendingMessage(true);

    try {
      // signedUrl
      const signedUrl = await apis.resourceApi.getSingedUrl();

      // s3에 저장
      await apis.resourceApi.uploadImageToS3(signedUrl.url, content?.[0]);

      // 메세지 저장
      await apis.chatApi.pushMessage({
        messageType: 'IMAGE',
        roomId: roomId,
        content: signedUrl.id,
      });

      this._setSendingMessage(false);
    } catch (e) {
      this._setSendingMessage(false);
      throw e.message;
    }
  }

  async _sendTextMessage(roomId, content) {
    if (!(content.length > 0)) {
      return;
    }
    this._setSendingMessage(true);

    try {
      await apis.chatApi.pushMessage({
        messageType: 'TEXT',
        roomId: roomId,
        content: content,
      });

      this._setSendingMessage(false);
    } catch (e) {
      this._setSendingMessage(false);
      throw '메세지를 전송하는 중 오류가 발생하였습니다.';
    }
  }

  async _convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        resolve(error);
      };
    });
  }

  setSelectedRoomId(roomId, isBroker, isMobile) {
    this._selectedRoomRepository.setSelectedRoom(roomId, isBroker, isMobile);
  }

  async loadMessage(roomId, direction) {
    await this._messageRepository.loadMessage(roomId, direction);
  }

  async sendMessage({ type, roomId, content }) {
    if (!content) {
      return;
    }

    switch (type) {
      case 'TEXT':
        await this._sendTextMessage(roomId, content);
        break;
      case 'IMAGE':
        await this._sendImageMessage(roomId, content);
        break;
      default:
        break;
    }
  }

  async updateRoom({ roomId, key, value }) {
    await this._roomRepository.patchRoom(roomId, (room) => {
      return {
        ...room,
        [key]: value,
      };
    });
  }

  async reloadRooms() {
    await this._roomRepository.loadRooms(true);
    return true;
  }

  async reconnect() {
    await this._connect();
  }
}
