import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { pressPhoneNumber, validPhoneNumber } from '@/services/Helper';
import { TYPES } from '@/components/GlobalDualPopups/helper';
import CommnonSpinner from '@/components/Spinner/CommonSpinner';

const Step1 = ({ phoneNum, isBusy }) => {
  const dispatch = useDispatch();
  // const phoneNum = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.auth.findId1(phoneNum.current));
  };

  const handleInputChange = (e) => {
    const isValid = validPhoneNumber(e); ///아
    phoneNum.current = e.target.value;
  };

  const handleFindPassword = (e) => {
    e.preventDefault();
    dispatch(actions.common.openGlobalDualPopup(TYPES.FIND_PW));
  };

  return (
    <>
      <div className="popup-local-body">
        <div className="popup-content">
          <div className="popup-content-body">
            <fieldset className="submit-form module-a type-c medium">
              <legend>아이디찾기 입력서식</legend>
              <div className="form-list">
                <div className="form-item">
                  <div className="form-wrap">
                    <div className="form-head">
                      <label className="form-name" htmlFor="mobilePhoneNumber">
                        휴대폰 번호 입력
                      </label>
                      <span className="form-para">휴대폰 번호를 입력해 주세요.</span>
                    </div>
                    <div className="form-body">
                      <div className="form-area">
                        <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                          <input
                            type="tel"
                            className="form-elem"
                            id="mobilePhoneNumber"
                            maxLength="11"
                            placeholder="휴대폰 번호"
                            disabled=""
                            onKeyPress={pressPhoneNumber}
                            onChange={handleInputChange}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="popup-local-util">
        <div className="button-display module-a style-a type-d">
          <span className="button-area">
            <button type="submit" className="btn module-b style-a type-fill accent-01 large-1x flex" disabled={isBusy} onClick={handleClick}>
              <span className="btn-text">
                {isBusy ? (
                  <CommnonSpinner show={true} position="relative" padding="10px" spinnerWrapperBackground={'transparent'} />
                ) : (
                  '인증번호 요청'
                )}
              </span>
            </button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Step1;
