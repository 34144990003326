import React from 'react';
import MarketPriceSummaryItem from './MarketPriceSummaryItem';
import QuickSaleDescription from './QuickSaleDescription';

const RECENT_CONTRACT_COLUMN = {
  // 1년 이내 최고 최저 실거래가 데이터 칼럼 명
  s: { min: 'curr_sell_min_price', max: 'curr_sell_max_price' },
  c: { min: 'curr_chartered_min_price', max: 'curr_chartered_max_price' },
};

const QUICK_SALE_COLUMN = {
  s: 'quick_sell_price',
  c: 'quick_chartered_price',
};

const MarketPriceSummary = ({ marketPriceData = {}, tradeType, updatePrice }) => {
  const { marketData = {}, recentContractData = {} } = marketPriceData;

  // 최근 실거래가 최소 최대
  const recentContractMin = recentContractData[RECENT_CONTRACT_COLUMN[tradeType]?.min];
  const recentContractMax = recentContractData[RECENT_CONTRACT_COLUMN[tradeType]?.max];

  // 급매물가
  const quickSalePrice = recentContractData[QUICK_SALE_COLUMN[tradeType]];

  const { marketMin, marketMax, marketMonthlyMin, marketMonthlyMax } = marketData;

  return (
    <>
      <ul className="data-list module-a style-a type-c normal-03 small-2x">
        <MarketPriceSummaryItem title="실거래가" minPrice={recentContractMin} maxPrice={recentContractMax} updatePrice={updatePrice} />
        <MarketPriceSummaryItem
          title="매물시세"
          minPrice={marketMin}
          maxPrice={marketMax}
          minMonthly={marketMonthlyMin}
          maxMonthly={marketMonthlyMax}
          updatePrice={updatePrice}
        />
        <MarketPriceSummaryItem title="급매물가" minPrice={quickSalePrice} updatePrice={updatePrice} suffix=" 이하" />
      </ul>

      {/* 급매물가 설명 */}
      {quickSalePrice ? <QuickSaleDescription /> : <></>}
    </>
  );
};

export default MarketPriceSummary;
