/**
 * Infosection route
 *
 * 공통적으로
 * WEB인 경우 replace, MOBILE인 경우 push로 history를 변경한다.
 * 따라서 뒤로가기 역시 WEB은 replace, MOBILE인 경우 goBack.
 *
 * 분기한 이유는
 * WEB은 지도와 Infosection이 동시에 펼쳐져 있기 때문에 맵을 이용해 흐름이 끊기는 URL 변동이 있을 수 있고
 * UI상 모두 한 화면에서 이루어지는 동작으로 보임.
 * MOBILE은 이벤트 마다 화면 전체가 변경되며
 * 안드로이드 사용자의 경우 back 버튼을 사용할 가능성이 있어 history로 관리해주어야 함.
 *
 * WEB 예외: 단지상세
 *  단지상세로 갈 때는 한번 push 해준다. 그 외는 모두 replace 하여 history 하나에서 모두 이동
 **/

// 단지상세
import { routeToComplexDetail, getComplexDetailPath } from './toComplexDetail';
// 시세리스트
import { routeToMarketList, getMarketListPath } from './toMarketList';
// 매물리스트
import { routeToGoodsList, getGoodsListPath } from './toGoodsList';
// 매물리스트 바로가기
import { routeToGoodsListDirect, getGoodsListDirectPath } from './toGoodsListDirect';
// 매물상세
import { routeToGoodsDetail, getGoodsDetailPath } from './toGoodsDetail';
// 매물상세로 바로 이동 (마이페이지 등..)
import { routeToGoodsDetailDirect } from './toGoodsDetailDirect';
// 중개사상세
import { routeToBrokerDetail, getBrokerDetailPath } from './toBrokerDetail';
// 뒤로가기
import handleGoBack from './handleGoBack';
// 교육 상세
import { routeToEdu, getEduPath, getEduBackPath } from './toEdu';
// 그외. key value로 관리.
import { routeToSomething, getPath, getBackPath } from './toSomething';

export {
  routeToComplexDetail,
  getComplexDetailPath,
  routeToMarketList,
  getMarketListPath,
  routeToGoodsList,
  getGoodsListPath,
  routeToGoodsListDirect,
  getGoodsListDirectPath,
  routeToGoodsDetail,
  getGoodsDetailPath,
  routeToGoodsDetailDirect,
  routeToBrokerDetail,
  getBrokerDetailPath,
  handleGoBack,
  routeToEdu,
  getEduPath,
  getEduBackPath,
  routeToSomething,
  getPath,
  getBackPath,
};
