import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

import CONSTANTS from './constants';
// import text from '@/resources/text';

const INITIAL_STATE = {
  // 각 actionType 에 맞게 Remote.Failure(~) 로 설정되기도 하지만, 전체 error 상황을 확인하기 위한 필드도 함께 지정된다
  // (Alert component에 사용되기도 함)
  error: '',

  myAlarmList: [], // {'checked' : true, ...}

  // ===================== MyInfo ===================== //
  // 비밀번호변경상태
  [AT.MP_CHANGE_PASSWORD]: Remote.NotAsked,
  [AT.GET_SOCIAL_LIST]: Remote.NotAsked,
  mySocialList: null,
  // ===================== MyBuy ===================== //
  // CONSTANTS.FAVORITE_ITEMS_LIST,
  [AT.GET_FAVORITE_ITEMS_LIST]: Remote.NotAsked,
  // CONSTANTS.INTERESTED_COMPLEX_LIST
  [AT.GET_INTERESTED_COMPLEX_LIST]: Remote.NotAsked,
  // CONSTANTS.CONTRACTED_ITEMS_LIST
  [AT.GET_CONTRACTED_ITEMS_LIST]: Remote.NotAsked,

  // ===================== MySell ===================== //
  // CONSTANTS.MY_SELL_ITEMS_LIST
  [AT.GET_MY_SELL_ITEMS_LIST]: Remote.NotAsked,

  [AT.POST_DELETE_ITEM]: Remote.NotAsked,
  deleteItemIdx: 0,
  deleteReason: '',
  deleteReasonTxt: '',
  deleteStatus: '',

  // 매물등록취소
  [AT.POST_REG_CANCEL_ITEM]: Remote.NotAsked,
  regCancelItemIdx: 0,

  // 매물재등록
  [AT.POST_REREG_ITEM]: Remote.NotAsked,
  reRegItemIdx: 0,

  [AT.POST_REPORT_ITEM_TRADE_COMPLETE]: Remote.NotAsked,
  tradeItemIdx: 0,
  tradeAgentType: CONSTANTS.ITEM_REPORT_TRADE_COMPLETE_DAWIN_AGENT,
  tradeAgentIdx: 0,

  // ===================== rating ===================== //
  // ratingType : CONSTANTS.RATING_BUYER

  // ===================== 2.3 MySell ===================== //
  overall: [],
};

export default function myPageRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.MP_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case AT.MP_RESET_ERROR_STATE: {
      return {
        ...state,
        error: '',
      };
    }
    // ============================ MyInfo ============================ //

    case loading(AT.MP_CHANGE_PASSWORD):
      return { ...state, [AT.MP_CHANGE_PASSWORD]: Remote.Loading };
    case success(AT.MP_CHANGE_PASSWORD):
      return { ...state, [AT.MP_CHANGE_PASSWORD]: Remote.Success(action.data) };
    case failure(AT.MP_CHANGE_PASSWORD):
      return { ...state, [AT.MP_CHANGE_PASSWORD]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_SOCIAL_LIST):
      return { ...state, [AT.GET_SOCIAL_LIST]: Remote.Loading };
    case success(AT.GET_SOCIAL_LIST):
      return { ...state, mySocialList: action.data };
    case failure(AT.GET_SOCIAL_LIST):
      return { ...state, [AT.GET_SOCIAL_LIST]: Remote.Failure(action.error), error: action.error };

    // ============================ MyBuy ============================ //
    // case AT.SET_FAVORITE_ITEMS_LIST: {
    //   return {
    //     ...state,
    //     myFavoriteItemsList : action.list
    //   };
    // }

    case loading(AT.GET_FAVORITE_ITEMS_LIST):
      return { ...state, [AT.GET_FAVORITE_ITEMS_LIST]: Remote.Loading };
    case success(AT.GET_FAVORITE_ITEMS_LIST):
      return { ...state, [AT.GET_FAVORITE_ITEMS_LIST]: Remote.Success(action.list) };
    case failure(AT.GET_FAVORITE_ITEMS_LIST):
      return { ...state, [AT.GET_FAVORITE_ITEMS_LIST]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_INTERESTED_COMPLEX_LIST):
      return { ...state, [AT.GET_INTERESTED_COMPLEX_LIST]: Remote.Loading };
    case success(AT.GET_INTERESTED_COMPLEX_LIST):
      return { ...state, [AT.GET_INTERESTED_COMPLEX_LIST]: Remote.Success(action.list) };
    case failure(AT.GET_INTERESTED_COMPLEX_LIST):
      return { ...state, [AT.GET_INTERESTED_COMPLEX_LIST]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_CONTRACTED_ITEMS_LIST):
      return { ...state, [AT.GET_CONTRACTED_ITEMS_LIST]: Remote.Loading };
    case success(AT.GET_CONTRACTED_ITEMS_LIST):
      return { ...state, [AT.GET_CONTRACTED_ITEMS_LIST]: Remote.Success(action.list) };
    case failure(AT.GET_CONTRACTED_ITEMS_LIST):
      return { ...state, [AT.GET_CONTRACTED_ITEMS_LIST]: Remote.Failure(action.error), error: action.error };

    // ============================ MySell ============================ //
    // === 집내놓기 목록 가져오기 === //
    case loading(AT.GET_MY_SELL_ITEMS_LIST):
      return { ...state, [AT.GET_MY_SELL_ITEMS_LIST]: Remote.Loading };
    case success(AT.GET_MY_SELL_ITEMS_LIST):
      return { ...state, [AT.GET_MY_SELL_ITEMS_LIST]: Remote.Success(action.list) };
    case failure(AT.GET_MY_SELL_ITEMS_LIST):
      return { ...state, [AT.GET_MY_SELL_ITEMS_LIST]: Remote.Failure(action.error), error: action.error };

    // === 매물삭제 관련 === //
    case AT.SET_DELETE_ITEM_IDX: {
      return {
        ...state,
        deleteItemIdx: action.idx,
      };
    }
    case AT.SET_DELETE_REASON: {
      return {
        ...state,
        deleteReason: action.deleteReason,
      };
    }
    case AT.SET_DELETE_STATUS: {
      return {
        ...state,
        deleteStatus: action.deleteStatus,
      };
    }
    case AT.SET_DELETE_REASON_ETC: {
      return {
        ...state,
        deleteReasonTxt: action.reasonTxt,
      };
    }
    case loading(AT.POST_DELETE_ITEM):
      return { ...state, [AT.POST_DELETE_ITEM]: Remote.Loading };
    case success(AT.POST_DELETE_ITEM):
      return { ...state, [AT.POST_DELETE_ITEM]: Remote.Success(action.data) };
    case failure(AT.POST_DELETE_ITEM):
      return { ...state, [AT.POST_DELETE_ITEM]: Remote.Failure(action.error), error: action.error };

    // === 매물등록취소 관련 === //
    case AT.SET_REG_CANCEL_ITEM_IDX: {
      return {
        ...state,
        regCancelItemIdx: action.regCancelItemIdx,
      };
    }
    case loading(AT.POST_REG_CANCEL_ITEM):
      return { ...state, [AT.POST_REG_CANCEL_ITEM]: Remote.Loading };
    case success(AT.POST_REG_CANCEL_ITEM):
      return { ...state, [AT.POST_REG_CANCEL_ITEM]: Remote.Success(action.data) };
    case failure(AT.POST_REG_CANCEL_ITEM):
      return { ...state, [AT.POST_REG_CANCEL_ITEM]: Remote.Failure(action.error), error: action.error };
    case AT.POST_REG_CANCEL_ITEM_RESET:
      return { ...state, [AT.POST_REG_CANCEL_ITEM]: Remote.NotAsked };

    // === 매물재등록 관련 === //
    case AT.SET_REREG_ITEM_IDX: {
      return {
        ...state,
        reRegItemIdx: action.reRegItemIdx,
      };
    }

    case AT.POST_REREG_ITEM_NON: {
      return { ...state, [AT.POST_REREG_ITEM]: Remote.NotAsked };
    }
    case loading(AT.POST_REREG_ITEM):
      return { ...state, [AT.POST_REREG_ITEM]: Remote.Loading };
    case success(AT.POST_REREG_ITEM):
      return { ...state, [AT.POST_REREG_ITEM]: Remote.Success(true) };
    case failure(AT.POST_REREG_ITEM):
      return { ...state, [AT.POST_REREG_ITEM]: Remote.Failure(action.error), error: action.error };

    // === 거래완료신고 관련 === //
    case AT.SET_TRADE_ITEM_IDX: {
      return {
        ...state,
        tradeItemIdx: action.idx,
      };
    }
    case AT.SET_TRADE_AGENT_TYPE: {
      return {
        ...state,
        tradeAgentType: action.agentType,
      };
    }
    case AT.SET_TRADE_AGENT_IDX: {
      return {
        ...state,
        tradeAgentIdx: action.agentIdx,
      };
    }
    case loading(AT.POST_REPORT_ITEM_TRADE_COMPLETE):
      return { ...state, [AT.POST_REPORT_ITEM_TRADE_COMPLETE]: Remote.Loading };
    case success(AT.POST_REPORT_ITEM_TRADE_COMPLETE):
      return { ...state, [AT.POST_REPORT_ITEM_TRADE_COMPLETE]: Remote.Success(action.data) };
    case failure(AT.POST_REPORT_ITEM_TRADE_COMPLETE):
      return { ...state, [AT.POST_REPORT_ITEM_TRADE_COMPLETE]: Remote.Failure(action.error), error: action.error };

    // ============================ 2.3 MySell ============================ //
    case loading(AT.GET_MY_SELL_COUNT):
      return { ...state, [AT.GET_MY_SELL_COUNT]: Remote.Loading };
    case success(AT.GET_MY_SELL_COUNT):
      return { ...state, [AT.GET_MY_SELL_COUNT]: Remote.Success(action.data), overallCount: action.data };
    case failure(AT.GET_MY_SELL_COUNT):
      return { ...state, [AT.GET_MY_SELL_COUNT]: Remote.Failure(action.error), error: action.error };

    case loading(AT.GET_MY_SELL_LIST):
      return { ...state, [AT.GET_MY_SELL_LIST]: Remote.Loading };
    case success(AT.GET_MY_SELL_LIST):
      return { ...state, [AT.GET_MY_SELL_LIST]: Remote.Success(action.data), overall: [...state.overall, ...action.data] };

    case failure(AT.GET_MY_SELL_LIST):
      return { ...state, [AT.GET_MY_SELL_LIST]: Remote.Failure(action.error), error: action.error };

    case AT.RESET_MY_SELL_LIST:
      return { ...state, overall: [] };
    default: {
      return {
        ...state,
      };
    }
  }
}
