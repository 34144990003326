import { loading, success, failure } from '@/data/utils';

//공동중개

export const SET_BK_QNA_KEYWORD = 'BROKERPAGE/SET_BK_QNA_KEYWORD';
export const SET_BK_QNA_CURRENT_PAGE = 'BROKERPAGE/SET_CURRENT_PAGE';
export const SET_BK_QNA_TOTAL_CNT = 'BROKERPAGE/SET_TOTAL_CNT';
export const SET_BK_QNA_NEW_TITLE = 'BROKERPAGE/SET_BK_QNA_NEW_TITLE';
export const SET_BK_QNA_NEW_CONT = 'BROKERPAGE/SET_BK_QNA_NEW_CONT';
export const SET_BK_QNA_NEW_ATTACH = 'BROKERPAGE/SET_BK_QNA_NEW_ATTACH';

export const SAVE_BK_QNA_REPLY = 'BROKERPAGE/SAVE_BK_QNA_REPLY';
export const EDIT_BK_QNA_REPLY = 'BROKERPAGE/EDIT_BK_QNA_REPLY';
export const DEL_BK_QNA_REPLY = 'BROKERPAGE/DEL_BK_QNA_REPLY';
export const SAVE_BK_QNA_NEW = 'BROKERPAGE/SAVE_BK_QNA_NEW';

export const EDIT_BK_QNA = 'BROKERPAGE/EDIT_BK_QNA';
export const DEL_BK_QNA = 'BROKERPAGE/DEL_BK_QNA';

export const GET_BK_QNA_LIST = 'BROKERPAGE/GET_BK_QNA_LIST';
export const GET_BK_QNA_LIST_LOADING = loading(GET_BK_QNA_LIST);
export const GET_BK_QNA_LIST_SUCCESS = success(GET_BK_QNA_LIST);
export const GET_BK_QNA_LIST_FAILURE = failure(GET_BK_QNA_LIST);

export const GET_BK_QNA_DETAIL = 'BROKERPAGE/GET_BK_QNA_DETAIL';
export const GET_BK_QNA_DETAIL_LOADING = loading(GET_BK_QNA_DETAIL);
export const GET_BK_QNA_DETAIL_SUCCESS = success(GET_BK_QNA_DETAIL);
export const GET_BK_QNA_DETAIL_FAILURE = failure(GET_BK_QNA_DETAIL);
