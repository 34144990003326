import * as AT from './actionTypes';

const INITIAL_STATE = {};

export default function appRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // 주소 clear
    case AT.SET_OS_TYPE_INSTANCE: {
      return { ...state, osTypeInstance: action.osTypeInstance };
    }

    default: {
      return { ...state };
    }
  }
}
