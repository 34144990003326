import { ColorHex, rgba } from './color';
import { IconKeys, iconList } from './iconSetting';

type GetIconProps = {
  color?: ColorHex;
  backgroundColor?: ColorHex;
};

export const getIcon = (name: IconKeys, { color = '#000000', backgroundColor }: GetIconProps) => {
  const size = iconList[name].size;
  const path = iconList[name].path;

  const urlEscapeCodedColor = color.replace("#", "%23");
  const urlEscapeCodedBackgroundColor = backgroundColor?.replace("#", "%23");

  if (backgroundColor) {
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${size}'%3E %3Cpath fill='${urlEscapeCodedBackgroundColor}' d='M0,0h24v24H0V0z'/%3E %3Cpath fill='${urlEscapeCodedColor}' d='${path}'/%3E%3C/svg%3E")`;
  } else {
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${size}'%3E %3Cpath fill='none' d='M0,0h24v24H0V0z'/%3E %3Cpath fill='${urlEscapeCodedColor}' d='${path}'/%3E%3C/svg%3E")`;
  }
};
