import { apiClient2 } from '@/apis/clients';

export const api = {
  async getDynamicBannerData(location) {
    try {
      return await apiClient2.get(`/targeting-api/v1/users/self/usages/${location}/policies`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
