import { apiClient } from '@/apis/clients';

export const api = {
  async getNews(data) {
    try {
      return await apiClient.get('/info/newsTxt' + data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getNewsCategory() {
    try {
      return await apiClient.get('/info/news/category');
    } catch (e) {
      throw Error(e.message);
    }
  },
};
