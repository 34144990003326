import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/data/rootActions';
import debounce from 'lodash/debounce';
import imgClose from '@/resources/design/mobile/images//btn_close02.png';
import styled from 'styled-components';

const validate = (keyword) => {
  const last = keyword.substring(keyword.length - 1, keyword.length);
  const lastCode = last.charCodeAt(0);

  // 한글 자음으로 끝나는 검색어는 조회 X
  // 한글 자음 unicode 범위: 12593 12622
  const isKorConsonant = lastCode > 12592 && lastCode < 12623;
  return keyword.length > 0 && !isKorConsonant;
};

const SearchInput = ({
  inputRef,
  setShow,
  totalSearch,
  keyword,
  setKeyword,
  isMobile,
  highlightIdx,
  setHighlightIdx,
  maxLength,
  search,
  complexOnly,
  showKeywordDeleteButon,
  searchMode,
  showAnalysisLink,
  placeholder,
}) => {
  const dispatch = useDispatch();

  const changeKeyword = (data) => {
    const value = data.target.value;
    setKeyword(value);
  };

  const handleSearchText = (data) => {
    const value = data.target.value.trim();
    setKeyword(value);

    if (validate(value)) {
      dispatch(actions.common.searchKeyword({ keyword: value, totalSearch }));
      setShow(true);
    } else {
      // setShow(false);
    }
  };

  const forceSearchText = () => {
    const value = inputRef.current.value.trim();

    if (validate(value)) {
      dispatch(actions.common.searchKeyword({ keyword: value, totalSearch }));
      setShow(true);
    }
  };

  const handleKeyboardEvent = (e) => {
    const code = e.keyCode;

    if (code === 40 || code === 38 || code === 27) {
      e.preventDefault();
    }

    if (code === 40 && maxLength > highlightIdx + 1) {
      //위
      setHighlightIdx(highlightIdx + 1);
    } else if (code === 38 && highlightIdx > 0) {
      //아래
      setHighlightIdx(highlightIdx - 1);
    } else if (code === 13 && highlightIdx > -1) {
      //엔터
      search(highlightIdx);
      e.target.blur();
    } else if (code === 27) {
      //esc
      setShow(false);
      e.target.blur();
    }
  };

  useEffect(() => {
    if (showAnalysisLink && searchMode && inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [searchMode]);

  // const handleDelete = () => {
  //   var element = ref.current;
  //   element.value = '';
  //   var event = new Event('change');
  //   element.dispatchEvent(event);
  // };

  // 2022-02-16
  // 검색 버튼은 메인 화면의 디자인을 위해 추가됨
  // 메인에서만 사용하는 버튼이므로 props로 굳이 정의하지 않음.(추후 고려)
  const showSearchButton = location.pathname === '/';

  return (
    // <div className={isBuyingPage ? 'inp-textbox search' : 'inp-textbox'}>
    <div className="search_box find-search-box">
      <input
        ref={inputRef}
        type="search"
        onChange={debounce(handleSearchText, 300)}
        // value={keyword}
        placeholder={
          placeholder
            ? placeholder
            : complexOnly
            ? '단지명을 입력해주세요.'
            : isMobile
            ? '지역, 지하철역, 단지명을 입력해주세요.'
            : '지역, 지하철역, 지역+단지명으로 검색해주세요.'
        }
        title={
          complexOnly
            ? '단지명을 입력해주세요.'
            : isMobile
            ? '지역, 지하철역, 단지명을 입력해주세요.'
            : '지역, 지하철역, 지역+단지명으로 검색해주세요.'
        }
        className="inp"
        autoComplete="off"
        onKeyDown={handleKeyboardEvent}
        onFocus={() => setShow(true)}
      />
      {showSearchButton && (
        <button className="btn_sch" onClick={forceSearchText}>
          검색
        </button>
      )}
      {/* {showKeywordDeleteButon && (
        <button className="btn_remove" onClick={handleDelete}>
          검색어삭제
        </button>
      )} */}

      {/* <button className="btn_sch" onClick={handleSearchText} style={{ zIndex: 5 }}></button> */}
    </div>
  );
};

const Close = styled.div`
  content: '';
  width: 15px;
  height: 15px;
  background: url(${imgClose}) no-repeat;
  background-size: contain;
  margin: 5px;
  position: absolute;
  right: 89px;
  top: 0;
`;

export default SearchInput;
