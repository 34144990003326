import dayjs from 'dayjs';

// code -> text
export const LIVE_STATUS_TEXT = {
  owner: '주인거주중',
  chartered: '전세거주중',
  monthly: '월세거주중',
  empty: '공실',
};

export const DIRECTION_TEXT = {
  E: '동',
  W: '서',
  S: '남',
  N: '북',
};

export const ENTER_TYPE_TEXT = {
  1: '즉시입주',
  2: '협의가능',
  Y: '협의가능',
  N: '즉시입주',
};

export const OPTIONS_TEXT = {
  '01': '드레스룸',
  '02': '붙박이장',
  '03': '신발장',
  '04': '에어컨',
  '05': '세탁기',
  '06': '건조기',
  '07': '오븐',
  '08': '가스레인지',
  '09': '인덕션',
  '10': '식기세척기',
  '11': '욕조',
  '16': '디지털도어락',
};

export const TO_TEXT = {
  LIVE_STATUS: LIVE_STATUS_TEXT,
  DIRECTION: DIRECTION_TEXT,
  ENTER_TYPE: ENTER_TYPE_TEXT,
  OPTIONS: OPTIONS_TEXT,
};

export const getDirectionText = (direction = '') => {
  return [...direction].map((item) => DIRECTION_TEXT[item]).join('');
};

export const getEnterDateText = (enter_date, enter_type = 3) => {
  return enter_type == 3 ? dayjs(enter_date).format('YYYY-MM-DD') : null;
};
