import React from 'react';

export const text = {
  SELL_SIMPLE_LOGIN_NOTICE: '직접 집내놓기 서비스를 이용하기 위해서는 본인 인증을 거쳐야 합니다.',
  SELL_LIMIT_AREA_TEXT_MODAL_SIMPLE_LOGIN: (
    <>
      다윈중개는 안전한 거래를 위해 본인 인증 절차를 진행하고 있습니다.
      <br />
      아래 인증 버튼을 클릭해서 본인 인증 절차를 진행해 주세요.
    </>
  ),
  SELL_MAIN_TEXT_01_TITLE: <>집 내놓을 때 중개수수료 0원</>,
  SELL_MAIN_TEXT_01_DEC_MOBILE: (
    <>
      다윈중개는 IT기반 중개혁신을 통해
      <br />
      저렴한 비용으로 중개 가능한 시스템을 구축하였습니다.
      <br />
      집내놓을 때 중개수수료가<b>'0원'</b>입니다
    </>
  ),
  SELL_MAIN_TEXT_01_DEC_WEB: (
    <>
      다윈중개는 IT기반 중개혁신을 통해
      <br />
      저렴한 비용으로 중개 가능한 시스템을 구축하였습니다. <br />
      집내놓을 때 중개수수료가 <b>'0원'</b>입니다.
    </>
  ),
  SELL_MAIN_TEXT_02_TITLE: <>가장 빨리, 가장 좋은 가격으로 성사</>,
  SELL_MAIN_TEXT_02_DEC_MOBILE: (
    <>
      다윈중개는 매물이 등록됨과 동시에 인근 중개사들 모두에게
      <br />
      자동 노출됨으로써 중개사들간 경쟁을 유도합니다.
      <br />
      중개사들은 네이버 부동산 광고나 개인 블로그등을 통해
      <br />
      <strong>최대한 빠르게</strong> 매수자(임차인)를 확보하기 위해 경쟁합니다.
    </>
  ),
  SELL_MAIN_TEXT_02_DEC_WEB: (
    <>
      다윈중개는 매물이 등록됨과 동시에 인근 중개사들 모두에게
      <br />
      자동 노출됨으로써 중개사들간 경쟁을 유도합니다.
      <br />
      중개사들은 네이버 부동산 광고나 개인 블로그등을 통해
      <br />
      <strong>최대한 빠르게</strong> 매수자(임차인)를 확보하기 위해 경쟁합니다.
    </>
  ),
  SELL_MAIN_TEXT_03_TITLE: <>검증되고 신뢰할 수 있는 중개사</>,
  SELL_MAIN_TEXT_03_DEC_MOBILE: (
    <>
      다윈중개에는 고객의 직접평가를 비롯한
      <br />
      <b>다양한 중개사 평가 시스템</b>이 있습니다.
      <br />
      검증되고 신뢰할 수 있는 중개사님들을 선택할 수 있습니다.
    </>
  ),
  SELL_MAIN_TEXT_03_DEC_WEB: (
    <>
      다윈중개에는 고객의 직접평가를 비롯한 <b>다양한 중개사 평가 시스템</b>이 있습니다.
      <br />
      검증되고 신뢰할 수 있는 중개사님들을 선택할 수 있습니다.
    </>
  ),
  SELL_MAIN_TEXT_04_TITLE: '다윈중개 거래 프로세스',
  SELL_MAIN_TEXT_04_DEC: '',
};
