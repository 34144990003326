import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import * as rootSelectors from '../../rootSelectors';
import { routerActions as router } from 'connected-react-router';
import apis from '@/apis';
import { parseCallBack } from '@/services/Helper';
import getMessageByRequireCheck from '@/pages/BrokerPage/utils/permission/getMessageByRequireCheck';
import { stringUtil } from '@/services';

export function* getBrokerOfficeInfo() {
  try {
    // toogleGlobalSpinner을 이곳에서 사용함으로써 커뮤니티에서 불필요한 스피너가 보이는 현상 발생
    // 커뮤니티 페이지이거나 커뮤니티 상세페이지가 보이는곳에서는 자체적으로 spinner가 존재함으로써 이곳의 스피너는 보여주지 않도록 설정
    const query = parseCallBack();
    if (location.pathname !== '/community' && !query.feedDetail) {
      yield put(allActions.common.toggleGlobalSpinner({ theme: 'dark', show: true }));
    }
    const res = yield call(apis.brokerPageApi.brokerOfficeInfo);
    const data = res.brokerInfo;
    if (data != undefined || data != '' || data != null) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
    const require_check = res.require_check;

    const brokerInfo = {
      broker_office_idx: data.broker_office_idx,
      office_name: data.office_name,
      info_broker_office_code: data.info_broker_office_code,
      mbr_image_full_path: data.mbr_image_full_path,
      mbr_cp: data.mbr_cp,
      mbr_id: data.mbr_id,
      mbr_idx: data.mbr_idx,
      mbr_cp_flag: data.mbr_cp_flag,
      mbr_name: data.mbr_name,
      career: data.career,
      broker_reg_license_img: data.broker_reg_license_img,
      office_status: data.office_status,
      office_status_chg_date: data.office_status_chg_date,
      approval_status_chg_date: data.approval_status_chg_date,
      approval_status: data.approval_status,
      working_status: require_check == 'Y' ? '' : data.working_status,
      working_status_chg_date: data.working_status_chg_date,
      working_status_reason: data.working_status_reason,
      st_to_date: data.st_to_date,
      st_from_date: data.st_from_date,
      penalty_cnt: data.penalty_cnt,
      member_level: data.member_level,
      broker_point: data.broker_point,
      broker_point_cnt: data.broker_point_cnt,
      join_date: data.join_date,
      modify_date: data.modify_date,
      approval_hold: data.approval_hold,
      approval_hold_status: data.approval_hold_status,
      approval_hold_status_txt: data.approval_hold_status_txt,
      interYn: data.interYn,
      activeArea: data.officeArea,
      require_check: require_check,
      contract_info: data.contract_info,
    };

    const officeInfo = {
      broker_office_idx: data.broker_office_idx,
      biz_license_img: data.biz_license_img,
      broker_reg_license_img: data.broker_reg_license_img,
      biz_gbn: data.biz_gbn,
      addr1: data.addr1,
      addr2: data.addr2,
      post_code: data.post_code,
      addr_type: data.addr_type,
      phone: data.phone,
      law_code: data.law_code,
      law_addr: data.law_addr,
      law_dong_code: data.law_dong_code,
      law_dong: data.law_dong,
      addr_ro1: data.addr_ro1,
      addr_ro2: data.addr_ro2,
      addr_ro_full: data.addr_ro_full,
      latlng: { lat: data.lat, lng: data.lng },
      map_xy: data.map_xy,
      officeModifyYn: data.officeModifyYn,
    };

    const officeIntro = {
      broker_office_idx: data.broker_office_idx,
      oneline_intro: data.office_title,
      introduce: data.office_introduce,
      deduction_certificate: data.certificate_of_deduction_img,
      youtube_link: data.youtube,
      staff: data.belong,
      office_images: data.office_images,
    };

    if (!brokerInfo) {
      // yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
      yield put(actions.setBrokerInfo({}));
      yield put(actions.setOfficeInfo({}));
      yield put(actions.setOfficeIntro({}));
    } else {
      yield put(actions.setBrokerInfo(brokerInfo));
      yield put(actions.setOfficeInfo(officeInfo));
      yield put(actions.setOfficeIntro(officeIntro));
      if (require_check == 'Y') {
        yield put(
          allActions.common.alert({
            contents: getMessageByRequireCheck(require_check),
          })
        );
        yield put(allActions.router.replace('/brokerPage/broker_info'));
      }
    }
  } catch (e) {
    yield put(allActions.common.toggleGlobalSpinner(false));

    // broker-my/broker-info 에러 났을 때 brokerinfo 못받아옴
    // 팝업으로 다시 시도해주세요 노출 + 홈으로 이동
    yield put(
      allActions.common.alert({
        contents: '알 수 없는 오류가 발생했습니다. 다시 시도해주세요.',
      })
    );
    yield put(allActions.router.replace('/'));
    return;
  }
}
// 내정보 > 개인정보 > 비밀번호 변경
export function* brokerPassChange({ oldpass, newpass, newpass_repeat }) {
  try {
    const payload = {
      password: oldpass,
      newPassword: newpass,
      newConfirmedPassword: newpass_repeat,
    };
    yield call(apis.brokerPageApi.brokerPassChange, payload);
    yield put(allActions.common.alert({ contents: '비밀번호가 변경되었습니다.' }));
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}
// 내정보 > 개인정보 변경, 승인보류시 재승인요청
export function* brokerInfoChange({ alertText }) {
  try {
    const brokerInfo = yield select(selectors.brokerOfficeInfo.getBrokerInfo);
    const payload = yield {
      career: brokerInfo.career,
      mbr_img: brokerInfo.mbr_image_full_path,
      mbr_cp: brokerInfo.mbr_cp,
    };
    const res = yield call(apis.brokerPageApi.brokerInfoChange, payload);
    yield put(allActions.common.alert({ contents: alertText }));
    yield put(actions.getBrokerOfficeInfo());
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}

//내정보 - 중개사 탈퇴
export function* brokerSecession({ reason, reasonTxt }) {
  try {
    const param = {
      reason: reason,
      reasonTxt: reasonTxt,
    };
    const res = yield call(apis.brokerPageApi.brokerSecession, param);
    // const res = false;
    if (res.rltObj !== true) {
      console.log('error : secession');
      yield put(
        allActions.common.alert({
          contents: (
            <>
              탈퇴 처리 중 오류가 발생하였습니다.
              <br /> 오류가 반복적일 경우, 고객센터로 연락해주세요. (1544-6075)
            </>
          ),
        })
      );
    } else {
      //성공후 새롭게 불러와야지
      yield put(allActions.common.alert({ contents: '탈퇴 처리가 완료되었습니다.' }));
      yield put(allActions.auth.logout(true, { isBrokerPage: true }));
      yield put(router.replace('/'));
    }
  } catch (e) {
    console.log(e.message);
    yield put(allActions.common.alert({ contents: e.message }));
  }
}

//내정보 - 사무소정보 수정
export function* officeInfoChange({ confirmMessage }) {
  try {
    const officeInfo = yield select(selectors.brokerOfficeInfo.getOfficeInfo);
    const broker_office_address = [
      officeInfo.selectedOfficeInfo?.address ? officeInfo.selectedOfficeInfo?.address : officeInfo.addr1,
      officeInfo.selectedOfficeInfo?.address_detail,
    ]
      ?.filter((e) => e)
      .join(' ');

    const payload = {
      broker_office_idx: officeInfo.broker_office_idx,
      biz_gbn: officeInfo.biz_gbn,
      biz_license_img: officeInfo.biz_license_img,
      broker_reg_license_img: officeInfo.broker_reg_license_img,
      office_phone: stringUtil.removeDash(officeInfo.phone),
      selectedOfficeIdx: officeInfo.selectedOfficeIdx,
      broker_office_code: officeInfo.selectedOfficeInfo?.code,
      broker_name: officeInfo.selectedOfficeInfo?.name,
      broker_office_address,
      broker_office_name: officeInfo.selectedOfficeInfo?.broker_office_name,
      lat: officeInfo.selectedOfficeInfo?.lat,
      lng: officeInfo.selectedOfficeInfo?.lng,
      dong_code: officeInfo.selectedOfficeInfo?.b_dong_code,
    };

    const res = yield call(apis.brokerPageApi.officeInfoChange, payload);

    if (res.rltObj) {
      yield put(allActions.common.alert({ contents: confirmMessage }));
      yield put(actions.getBrokerOfficeInfo());
    } else {
      yield put(actions.getBrokerOfficeInfo());
    }
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}

//내정보 - 사무소소개 수정
export function* officeIntroChange() {
  try {
    const officeIntro = yield select(selectors.brokerOfficeInfo.getOfficeIntro);
    const brokerInfo = yield select(selectors.brokerOfficeInfo.getBrokerInfo);

    const payload = {
      broker_office_idx: officeIntro.broker_office_idx,
      office_title: officeIntro.oneline_intro,
      office_introduce: officeIntro.introduce,
      youtube_link: officeIntro.youtube,
      certificate_of_deduction_img: officeIntro.deduction_certificate,
      office_img_ch: officeIntro.office_img_ch === 'Y' ? 'Y' : 'N',
      belong_ch: officeIntro.belong_ch === 'Y' ? 'Y' : 'N',
      arr_office_img: officeIntro.office_images,
      arr_belong: officeIntro.staff,
    };

    const brokerInfoPayload = {
      career: brokerInfo.career,
      mbr_img: brokerInfo.mbr_image_full_path,
    };
    yield call(apis.brokerPageApi.brokerInfoChange, brokerInfoPayload);

    const res = yield call(apis.brokerPageApi.officeIntroChange, payload);
    //const res = true;
    yield put(actions.getBrokerOfficeInfo());
    yield put(allActions.common.alert({ contents: '중개사무소 소개 내용이 변경되었습니다.' }));
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
    //yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
  }
}
export function* getOfficeList({ searchWord, searchWord2 }) {
  try {
    const payload = {
      dong_code: yield select(rootSelectors.selling.getDongRiCode),
      keyword: searchWord ? searchWord : '',
      keyword2: searchWord2 ? searchWord2 : 0,
    };
    yield put(actions.getOfficeListLoading());
    //yield put(allActions.common.toggleGlobalSpinner(true));
    const { listObj } = yield call(apis.agentSignUpApi.getOfficeList, payload);
    if (!listObj) {
      yield put(actions.setOfficeList([]));
      //yield put(allActions.common.toggleGlobalSpinner(false));
    } else {
      yield put(actions.setOfficeList(listObj));
      //yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getOfficeListFail());
    //yield put(allActions.common.toggleGlobalSpinner(false));
    //yield put(actions.agentSignUpRequestERROR({ error: text.agentSignUpText.OFFICE_LIST_ERROR }));
  }
}
export function* getSearchXY({ address }) {
  try {
    const payload = {
      address: address,
    };
    // console.log('getOfficeList',payload)

    const { dataObj } = yield call(apis.agentSignUpApi.getSearchXY, payload);
    if (!dataObj) {
      yield put(actions.setOfficeXY(''));
    } else {
      yield put(actions.setOfficeXY(dataObj.resss));
    }
  } catch (e) {
    yield put(actions.getOfficeListFail());
  }
}
