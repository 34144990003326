import React, { useEffect, lazy, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from './data';

import ScrollToTop from '@/components/ScrollToTop';
import { SpinnerWithCover } from '@/components/Spinner';
import GlobalDualPopups from '@/components/GlobalDualPopups';
import GlobalComponents from '@/components/GlobalComponents';
import MobileFavoBox from '@/components/FavoButton/FavoBox/Mobile'; //관심단지 팝업
// import DownloadApp from '@/components/DownloadApp';
// import SnackBar from '@/components/Snackbar';
import IENotAvailable from '@/pages/Error/IENotAvailable';
import DawinLoading from './components/Spinner/DawinLoading';
import AppShouldLogin from './components/AppShouldLogin';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalGallery from './components/GlobalGallery';
import { Alert, Confirm, Notice } from './components/popups/InfoPopups';
import ChatInitializer from '@/pages/Chat/ChatInitializer';

import { useFbPixel } from '@/hooks/useFbPixel';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';

import useInitialize from '@/hooks/useInitialize';
import useInitializeByUser from '@/hooks/useInitializeByUser';
// import useMarketing from '@/hooks/useMarketing';
import useWebsocketNotificationData from './hooks/useWebNotification';

const AppRouter = lazy(() => import('./AppRouter'));
const Navigation = lazy(() => import('@/combinedComp/Navigation'));

dayjs.locale('ko');
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Seoul');

const isIe = !!document.documentMode;

const App = () => {
  const history = useHistory();
  const fbPixel = useFbPixel();

  const userInfo = useSelector(selectors.auth.getUserInfo);

  useEffect(() => {
    if (fbPixel) {
      const release = history.listen((e) => {
        fbPixel.pageView();
      });
      return () => {
        release();
      };
    }
  }, [fbPixel]);

  // initialize - 사이트 로딩시 실행해야 하는 action
  useInitialize();

  // initialize - 사이트 로딩시 실행해야 하는 action - 그 중에서도 로그인 상태에 따른 처리가 필요한 경우
  useInitializeByUser();

  useWebsocketNotificationData();

  return (
    <Suspense fallback={<DawinLoading />}>
      <ErrorBoundary
        errorPage={
          <>
            <div id="page">
              <Navigation />
            </div>
          </>
        }>
        {isIe ? (
          <IENotAvailable />
        ) : (
          <>
            {/* 앱 메인: 로그인 */}
            <AppShouldLogin />

            {/* Router */}
            <AppRouter />

            {/* 페이지 이동 시 스크롤 상단으로 이동 */}
            <ScrollToTop />

            {/* spinner */}
            <SpinnerWithCover />

            <GlobalDualPopups />

            <GlobalComponents />
            <MobileFavoBox />

            {/* 이미지 클릭시 확대되서 보여지는 기능 */}
            <GlobalGallery />

            <ChatInitializer show={userInfo} />

            {/* ---정리중--- */}

            {/* Popup Group */}
            <Alert />
            <Confirm />
            <Notice />
          </>
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
