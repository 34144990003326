import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const getCustom = () => ({
  type: AT.GET_MG_CUSTOM,
});
export const setKeyword = (keyword) => ({
  type: AT.SET_MG_CUSTOM_KEYWORD,
  keyword,
});

//고객정보 state 변경
export const setEditCustomInfo = (item) => ({
  type: AT.SET_EDIT_CUSTOMINFO,
  item,
});
export const delEditCustomInfo = (item) => ({
  type: AT.DELETE_CUSTOMINFO,
  item,
});

//신규고객상담 state 추가
export const setNewCustomName = (name) => ({
  type: AT.SET_NEW_CUSTOM_NAME,
  name,
});
export const setNewCustomCp = (cp) => ({
  type: AT.SET_NEW_CUSTOM_CP,
  cp,
});
export const setNewCustomContent = (content, goods_idx) => ({
  type: AT.SET_NEW_CUSTOM_CONTENT,
  content,
  goods_idx,
});

//기존 메모 수정
export const changeMemo = (content) => ({
  type: AT.SET_EDIT_MEMO,
  content,
});
export const editMemo = () => ({
  type: AT.EDIT_MEMO,
});
export const editMemoInfo = (goodsIdx, memoIdx) => ({
  type: AT.SET_MEMO_INFO,
  goodsIdx,
  memoIdx,
});
export const delMemo = (idx) => ({
  type: AT.DEL_MEMO,
  idx,
});

//신규상담 추가
export const newCustom = () => ({
  type: AT.REG_NEW_CUSTOM,
});
export const newCustomLoading = () => ({
  type: loading(AT.REG_NEW_CUSTOM),
  error,
});
export const newCustomFailure = (error) => ({
  type: failure(AT.REG_NEW_CUSTOM),
  error,
});

//고객정보 변경
export const editNameInfo = (name) => ({
  type: AT.EDIT_NAME_CUSTOMINFO,
  name,
});
export const editCpInfo = (cp) => ({
  type: AT.EDIT_CP_CUSTOMINFO,
  cp,
});
export const editCustormerInfo = () => ({
  type: AT.EDIT_CUSTOMINFO,
});
export const editCustormerInfoFailure = (error) => ({
  type: failure(AT.EDIT_CUSTOMINFO),
  error,
});

//고객리스트
export const getCustomerListLoading = () => ({
  type: loading(AT.GET_MG_CUSTOM),
});
export const getCustomerListSuccess = (mgcList) => ({
  type: success(AT.GET_MG_CUSTOM),
  mgcList,
});
export const getCustomerListFailure = (error) => ({
  type: failure(AT.GET_MG_CUSTOM),
  error,
});

//고객상담내용 리스트
export const getSelCustom = (item) => ({
  type: AT.GET_SEL_CUSTOM,
  item,
});
export const setselcustom = (item) => ({
  type: AT.SET_SEL_CUSTOM,
  item,
});
//고객상담내용 리스트
export const getSelCustomerLoading = () => ({
  type: loading(AT.GET_SEL_CUSTOM),
});
export const getSelCustomerSuccess = (selList) => ({
  type: success(AT.GET_SEL_CUSTOM),
  selList,
});
export const getSelCustomerFailure = (error) => ({
  type: failure(AT.GET_SEL_CUSTOM),
  error,
});
