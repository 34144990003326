import { apiClient2 } from '@/apis/clients';
import callActions from './callActions';

const contactApis = {
  createChatRoom: async (payload) => {
    try {
      payload.title = '';
      const createChatRes = await apiClient2.post(`/chat-api/v1/chat/rooms`, payload);
      if (createChatRes) {
        return createChatRes;
      } else {
        throw Error('채팅방 생성에 실패하였습니다.');
      }
    } catch (e) {
      throw Error('채팅방 생성에 실패하였습니다. ' + e.message);
    }
  },
};

export default { contactApis, callActions };
