import { PRICE_MAX_LENGTH } from '../goods/core';

export const validPhoneNumber = (value) => {
  return /^01([0|1|6|7|8|9])([0-9]{7,8})$/.test(value);
};

export const numberOnlyLengthSevenValidation = (e) => {
  let value = e?.target?.value;

  const regExp = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
  if (regExp.test(e?.nativeEvent?.data) && e?.nativeEvent?.data !== null) {
    e.preventDefault();
    return null;
  }

  // 숫자 7자리 넘지 않기
  if (value.length > PRICE_MAX_LENGTH) {
    value = e.target.value.slice(0, PRICE_MAX_LENGTH);
  }

  return value;
};
