import React from 'react';
import { TagType } from '@/services/Community';
import { useClickBtns } from './hooks';
import OtherGoodsTag from './components/OtherGoodsTag';
import SigunguTag from './components/SigunguTag';
import ComplexTag from './components/ComplexTag';

const RouteBtns = ({ feedData }) => {
  const feedType = feedData?.feedType;
  const typeArray = feedData?.tags?.map((item) => item.type);
  const sigunguTag = feedData?.tags[typeArray.indexOf(TagType.ADDRESS_TAG)];
  const complexTag = feedData?.tags[typeArray.indexOf(TagType.COMPLEX_TAG)];
  const { handleOtherGoods, handleSigungu, handleComplex } = useClickBtns({ feedData, typeArray, complexTag, sigunguTag });

  return feedData?.tags ? (
    <div className="button-area">
      <OtherGoodsTag feedType={feedType} handleOtherGoods={handleOtherGoods} />
      <SigunguTag sigunguTag={sigunguTag} handleSigungu={handleSigungu} />
      <ComplexTag complexTag={complexTag} handleComplex={handleComplex} />
    </div>
  ) : (
    false
  );
};

export default RouteBtns;
