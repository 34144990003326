import React from 'react';
import Input from '@/combinedComp/Search/Input';
import InputWrapper from '@/combinedComp/Search/Input/Wrapper';
import Result from '@/combinedComp/Search/Result';

const SearchView = (props) => {
  const { show, keyword, data } = props;

  return (
    <>
      <InputWrapper legend="단지명 검색 서식" wrapperClass="submit-form module-a type-c medium active">
        <div className="form-body">
          <div className="form-area">
            <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
              {/* 검색 */}
              <Input {...props} />
            </span>
          </div>

          {/* 검색 결과 */}
          {show && keyword && <Result {...props} show={show} data={data} keyword={keyword} wrapperClass="data-board data-finder-autocomplete" />}
        </div>
      </InputWrapper>
    </>
  );
};

export default SearchView;
