import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { STATUS } from '@/data/utils';
import toggle from '@/hocs/toggle';
import Title from './components/Title';
import Texts from './components/Texts';
import Input from './components/Input';
import DuplicateChekcBtn from './components/DuplicateCheckBtn';
import SubmitBtn from './components/SubmitBtn';
import CloseBtn from './components/CloseBtn';
import { useSetTexts, useInitializeInput, useSetAfterSuccessNicknameChange } from './hooks';

const NicknameSetting = ({ setShow, communityText, replace }) => {
  const dispatch = useDispatch();
  const nickname = useSelector(selectors.auth.getNickname);

  const [data, setData] = useState({ status: STATUS.NOTASKED });
  const [input, setInput] = useState(nickname || '');
  const [confirm, setConfirm] = useState(false);

  const isLoading = data.status === STATUS.LOADING;

  // 닉네임이 있을때 input에 초기값으로 넣어주기
  useInitializeInput({ nickname, setInput });

  // 조건에 맞는 테스트들 세팅
  const { titleText, boldText, guideText, btnText } = useSetTexts({ communityText });

  //  닉네임 없는 유저 글작성 > 닉네임 팝업 뜨기 > 닉네임 설정후 다시 글작성 페이지 그대로 보여주는 처리
  useSetAfterSuccessNicknameChange({ data, replace, setShow });

  useEffect(() => {
    const isFailed = data?.status === STATUS.FAILURE;
    const errorMessage = data?.error;
    if (isFailed && errorMessage) {
      dispatch(
        actions.common.alert({
          contents: errorMessage,
        })
      );
    }
  }, [data?.status]);

  return (
    <div className="content-popup module-a style-a type-a medium enabled active" id="changeNickname" data-bui-toggle="contentsPopup">
      <div className="popup-page-body">
        <div className="popup-local">
          <Title titleText={titleText} />
          <div className="popup-local-body">
            <form className="popup-content">
              <div className="popup-content-body">
                <fieldset className="submit-form module-a type-c medium">
                  <legend>매물 검색 서식</legend>
                  <div className="form-list">
                    <div className="form-item">
                      <div className="form-wrap">
                        <Texts boldText={boldText} guideText={guideText} />
                        <div className="form-body">
                          <div className="form-area">
                            <Input input={input} setInput={setInput} isLoading={isLoading} />
                            <DuplicateChekcBtn setData={setData} input={input} isLoading={isLoading} setConfirm={setConfirm} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="popup-content-util">
                <div className="button-display module-a style-a type-d">
                  <SubmitBtn isLoading={isLoading} btnText={btnText} input={input} setData={setData} confirm={confirm} />
                </div>
              </div>
            </form>
          </div>
          <CloseBtn setShow={setShow} />
        </div>
      </div>
    </div>
  );
};

export default toggle(NicknameSetting);
