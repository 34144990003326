import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import AgentCard from './AgentCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const Option1 = () => {
  const dispatch = useDispatch();
  const myBrokerList = useSelector(selectors.common.getMyBrokerList);

  useEffect(() => {
    dispatch(actions.common.getMyBrokerList());
  }, []);

  const brokerList = useMemo(() => myBrokerList?.map((item, key) => <AgentCard key={key} data={item} />), [myBrokerList]);

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: '',
    variableWidth: true,
    swipeToSlide: true,
  };

  return (
    <div className="selec-result">
      {brokerList && brokerList.length != 0 ? (
        <ul className="agent-list" style={{ padding: '0 20px' }}>
          <Slider {...settings}>{brokerList}</Slider>
        </ul>
      ) : (
        <div className="agent_lst_blank">
          <p>선택한 중개사가 없습니다.</p>
        </div>
      )}
    </div>
  );
};

export default Option1;
