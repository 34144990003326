import React from 'react';
import { base } from '@/pages/Analysis/constants';

export const text = {
  // ======================== 공통 ======================== //
  ANALYSIS: '분석',
  DATA_SOURCE_TXT: '* 출처: 국가통계포털',
  DATA_SOURCE_BUN_TXT: '* 출처: 분양정보',
  DATA_SOURCE_NT_TXT: '* 출처: 국토교통부',

  // ======================== 카테고리 이름 ======================== //

  ANALYSIS_WEEKLY_PRICE: '주간실거래가', // 주간실거래가
  ANALYSIS_TRADE_VOLUME: '거래량', // 거래량
  ANALYSIS_PRICE_FLUC: '가격변동', // 가격변동
  ANALYSIS_JEONSAE_PERCENTAGE: '전세가율', // 전세가율
  ANALYSIS_JOB_NUM: '일자리수', // 일자리수
  ANALYSIS_SCHOOL: '교육', // 교육
  ANALYSIS_SUPPLY_AMT: '공급량', // 공급량
  ANALYSIS_POSITIVES: '개발호재', // 개발호재
  ANALYSIS_RECON_FEASIBILITY: '재건축사업성', // 재건축사업성
  ANALYSIS_APT_COMP: '아파트비교', // 아파트비교
  ANALYSIS_RANKING: '랭킹', // 랭킹
  ANALYSIS_RECOMMEND: '추천', // 추천
  ANALYSIS_PUBLIC_NOTICE: '공시지가', // 공시지가

  // ======================== Notice PopUp ======================== //
  POPUP_RECOMM_NOT_YET: '추천 서비스는 추후 공개될 예정입니다.',
  POPUP_RECON_FEASIBILITY_YET: '재건축사업성은 추후 공개될 예정입니다.',
  POPUP_APT_COMP_YET: '아파트비교는 추후 공개될 예정입니다.',
  POPUP_POSITIVES_YET: '개발호재는 추후 공개될 예정입니다.',

  // ======================== Functional PopUp ======================== //

  // ======================== Analysis Desc ======================== //
  CURRENT: '현재',
  FLUC: '변동',
  TRAIN_SUBWAY: '기차/지하철/도로',
  CONSTRUCTION: '건설',
  RECONSTRUCTION: '재건축/재개발',
  SALES: '매매',
  JEONSAE: '전세',
  SCHOOL: '학교',
  CRAM_CLASS_ZONE: '학원가',

  DESC_GRAPH_2011_NOTICE: '그래프는 2011년 상반기부터 매 반기별 수치를 나타냅니다.',

  // === 일자리수 === //
  POP_UP_JOB_NUM_TITLE: '일자리수',
  POP_UP_JOB_NUM_BIG_DESC: '지역별 일자리 수가 지도에 표시됩니다.',

  POP_UP_JOB_NUM_MAIN_DESC: (
    <>
      일자리수가 많다는 것은 주택 가격에 가장 큰 영향을 미치는 직주근접에서 큰 장점을 가진 지역이라는 의미입니다.
      <br />
      <br />
      일자리 수가 많은 지역과 그 인근 지역은 향후 가격상승 잠재력을 보유한 것으로 판단할 수 있습니다.
    </>
  ),

  DESC_JOB_NUM_CURRENT_1: '1) 그래프는 2011년 상반기부터 매 반기별 수치를 나타냅니다.',
  DESC_JOB_NUM_CURRENT_2: '2) 숫자는 가장 최근 반기의 해당 행정구역의 일자리 수를 나타냅니다.',
  DESC_JOB_NUM_CURRENT_3: '3) 원이 클수록 상대적으로 일자리 수가 많다는 의미입니다.',
  DESC_JOB_NUM_CURRENT_4: '4) 지도를 축소시키면 시도별 일자리 수를 확인하실 수 있습니다.',

  DESC_JOB_NUM_FLUC_1: '1) 하단에서 원하는 기간을 선택하실 수 있습니다. 기간을 지정하시면 해당 기간동안의 일자리 수 변화가 지도에 표시됩니다.',
  DESC_JOB_NUM_FLUC_2: '2) 붉은색은 일자리 수 증가, 푸른색은 일자리 수 감소를 의미합니다.',
  DESC_JOB_NUM_FLUC_3: '3) 원이 클수록 상대적으로 일자리 수가 많다는 의미입니다.',
  DESC_JOB_NUM_FLUC_4: '4) 지도를 축소시키면 시도별 일자리 수 변화를 확인하실 수 있습니다.',

  // === 공급량 === //
  POP_UP_SUPPLY_AMT_TITLE: '공급량 (입주예정 아파트)',
  POP_UP_SUPPLY_AMT_BIG_DESC: '지역별 공급량이 지도에 표시됩니다.',

  POP_UP_SUPPLY_AMT_MAIN_DESC: (
    <>
      특정지역에 공급량이 늘어난다는 것은 ‘늘어난 세대수에 비례하여 지역인프라가 개선되는 긍정적인 면’과 ‘수요에 비해 공급이 많아짐으로써 가격 상승에
      부담이 되는 부정적인 면’을 동시에 갖습니다.
      <br />
      <br />그 지역이 충분한 수요가 뒷받침될 수 있는 지역이라면 향후 가격 상승잠재력이 커진다고 판단할 수 있고, 그렇지 않다면 공급과잉에 따라 가격
      상승이 제한적이 될 것이라고 판단할 수 있습니다.
    </>
  ),

  DESC_SUPPLY_AMT_1: '1) 하단에서 원하는 기간을 선택하실 수 있습니다. 기간을 지정하시면 해당 기간내 공급되는 아파트 세대수가 지도에 표시됩니다.',
  DESC_SUPPLY_AMT_2: '2) 원이 클수록 상대적으로 많은 세대수가 공급된다는 의미입니다.',
  DESC_SUPPLY_AMT_3: '3) 지도를 축소시키면 동,구,시별 공급량을 확인하실 수 있습니다.',

  // === 개발호재 === //
  POP_UP_POSITIVES_TITLE: '개발호재',
  POP_UP_POSITIVES_BIG_DESC: '주변 개발호재 내용이 지도에 표시됩니다.',
  POP_UP_POSITIVES_MAIN_DES:
    '개발호재 인근 지역은 향후 가격 상승잠재력을 보유하고 있는 것으로 판단할 수 있습니다. 다만 계획 후 변경되는 경우도 있고, 착공해서 준공까지 시간이 걸리기에 고려해서 판단해야 합니다.',

  DESC_POSITIVES_1: '1) 개발호재는 철도/도로, 건설, 재건축/재개발 등의 내용을 표시합니다.',
  DESC_POSITIVES_2_1: (
    <>
      2) (철도/도로)단계는 크게 3단계로 {'계획'}단계는 점선, {'확정'}단계는 실선, {'착공'}단계는 굵은 실선으로 표시합니다.
    </>
  ),
  DESC_POSITIVES_2_2: (
    <>
      (건설)단계는 크게 2단계로 {'계획'}단계는 점선, {'착공'}단계는 실선으로 표시합니다.
    </>
  ),
  DESC_POSITIVES_2_3: (
    <>
      (재건축/재개발)단계는 크게 2단계로 {'관리처분인가 이전'}단계는 점선, {'관리처분인가 이후(관리처분인가 단계표함)'}단계는 실선으로 표시합니다.
    </>
  ),
  DESC_POSITIVES_3: '3) 본 자료는 정부기관에서 공시된 내용과 뉴스 등을 근거 자료로 하며, 참고 자료로만 이용 바랍니다. (법적 효력 없음)',
  DESC_POSITIVES_4: '4) 등록되지 않은 개발호재를 알려주시면, 검토 후 지도에 반영하겠습니다.',
  DESC_POSITIVES_ADD: '개발호재내용 알리기',

  FUNCTIONAL_POPUP: {
    [base.ANALYSIS_POSITIVES]: {
      TITLE: '개발호재',
      BIG_DESC: '주변 개발호재 내용이 지도에 표시됩니다.',
      MAIN_DES:
        '개발호재 인근 지역은 향후 가격 상승잠재력을 보유하고 있는 것으로 판단할 수 있습니다. 다만 계획 후 변경되는 경우도 있고, 착공해서 준공까지 시간이 걸리기에 고려해서 판단해야 합니다.',
    },
    [base.ANALYSIS_JOB_NUM]: {
      TITLE: '일자리수',
    },
    [base.ANALYSIS_SUPPLY_AMT]: {
      TITLE: '공급량 (입주예정 아파트)',
    },
    [base.ANALYSIS_PUBLIC_NOTICE]: {
      // TITLE: '공시지가 (m²)',
      TITLE: '공시지가 (평)',
    },
    [base.ANALYSIS_PRICE_FLUC]: {
      TITLE: '가격 변동',
    },
    [base.ANALYSIS_TRADE_VOLUME]: {
      // TITLE: '거래량',
      TITLE: '거래량(매매)',
    },
    [base.ANALYSIS_JEONSAE_PERCENTAGE]: {
      TITLE: (
        <>
          <span>전세가율</span>
          <small>(전세가/매매가x100)</small>
        </>
      ),
    },
    [base.ANALYSIS_SCHOOL]: {
      TITLE: <>교육</>,
    },
    [base.ANALYSIS_RECON_FEASIBILITY]: {
      TITLE: '재건축 사업성',
    },
    [base.ANALYSIS_APT_COMP]: {
      TITLE: '아파트 비교',
    },
    [base.ANALYSIS_RANKING]: {
      TITLE: '랭킹',
    },
  },

  // === 아파트 비교 === //
  POP_UP_APT_COMPARE_TITLE: '아파트 비교',
  POP_UP_APT_COMPARE_BIG_DESC: '선택한 단지의 입지분석 결과와 평당가격, 거래량, 전세가율 추이가 화면에 표시됩니다.',
  POP_UP_APT_COMPARE_MAIN_DESC: '비슷한 조건의 여러 단지의 가격 추이를 비교함으로써 고평가 되거나 저평가된 아파트 단지를 찾을 수 있습니다.',
  // === 재건축 사업성 === //
  POP_UP_RECONSTRUCTION_SCORE_TITLE: '재건축 사업성',
  POP_UP_RECONSTRUCTION_SCORE_BIG_DESC: '준공 후 25년이 지난 단지의 재건축 사업성 지표가 지도에 표시됩니다.',
  // === 공시지가 === //
  // POP_UP_PUBLIC_NOTICE_TITLE: '공시지가 (m²)',
  // POP_UP_PUBLIC_NOTICE_BIG_DESC: '각 단지의 m² 당 공시지가가 지도에 표시됩니다.',
  POP_UP_PUBLIC_NOTICE_TITLE: '공시지가 (평)',
  POP_UP_PUBLIC_NOTICE_BIG_DESC: '각 단지의 평당 공시지가가 지도에 표시됩니다.',
  POP_UP_PUBLIC_NOTICE_MAIN_DESC:
    '시세가 비슷한 단지 중 공시지가가 높은 단지 또는 공시지가는 비슷한데 시세가 낮은 단지는 타 단지에 비해 저평가되었다고 볼 수 있습니다. 향후 가격 상승잠재력을 보유한 것으로 판단할 수 있습니다.',

  DESC_PUBLIC_NOTICE_1: '1) 원이 클수록 상대적으로 공시지가가 크다는 의미입니다.',
  DESC_PUBLIC_NOTICE_2: '2) 지도를 축소시키면 해당 동,구,시별 공시지가를 확인하실 수 있습니다.',

  // === 가격 변동 === //
  POP_UP_PRICE_FLUC_TITLE: '가격 변동',
  POP_UP_PRICE_FLUC_BIG_DESC: '단지별, 지역별 가격 변동 내역이 지도에 표시됩니다.',
  POP_UP_PRICE_FLUC_MAIN_DESC:
    '아파트는 입지에 따른 고정자산의 성격이 강하여, 지속적으로 가격 상승하는 단지가 향후 가격 상승잠재력이 큰 것으로 판단할 수 있습니다.',
  DESC_PRICE_FLUC_1: '1) 하단에서 원하는 기간을 선택하실 수 있습니다. 기간을 지정하시면 해당 기간부터 현재까지의 가격 변화가 지도에 표시됩니다.',
  DESC_PRICE_FLUC_2: '2) 붉은색은 가격 상승, 푸른색은 가격 하락을 의미합니다.',
  DESC_PRICE_FLUC_3: '3) 원이 클수록 상대적으로 많은 가격 변화가 있었다는 의미입니다.',
  DESC_PRICE_FLUC_4: '4) 지도를 축소시키면 동,구,시별 실거래가 변동 내역을 확인할 수 있습니다.',
  DESC_PRICE_FLUC_5: '5) 매매, 전세 버튼을 클릭해 매매 가격변동, 전세 가격변동을 확인하실 수 있습니다.',
  DESC_PRICE_SOURCE: '* 출처: 국토교통부 실거래가',

  // === 거래량 === //
  // POP_UP_TRADE_VOLUME_TITLE: '거래량',
  // POP_UP_TRADE_VOLUME_BIG_DESC: '단지별, 지역별 누적 거래량이 지도에 표시됩니다.',
  POP_UP_TRADE_VOLUME_TITLE: '거래량(매매)',
  POP_UP_TRADE_VOLUME_BIG_DESC: '단지별, 지역별 누적 매매 거래량이 지도에 표시됩니다.',
  POP_UP_TRADE_VOLUME_MAIN_DESC:
    '거래량이 증가함은 수요가 뒷받침되고 있다는 의미로 향후 가격상승 잠재력을 보유한 것으로 판단할 수 있습니다. 따라서 거래량이 많은 단지가 향후에도 가격상승 잠재력이 더 큰 단지라고 볼 수 있습니다.',
  DESC_TRADE_VOLUME_1: '1) 하단에서 원하는 기간을 선택하실 수 있습니다. 기간을 지정하시면 해당 기간동안의 누적 거래량이 지도에 표시됩니다.',
  DESC_TRADE_VOLUME_2: '2) 원이 클수록 상대적으로 거래량이 많이 증가했다는 의미입니다.',
  DESC_TRADE_VOLUME_3: '3) 지도를 축소시키면 동,구,시별 누적 거래량을 확인하실 수 있습니다.',
  DESC_TRADE_VOLUME_SOURCE: '* 출처: 국토교통부 실거래가',

  // === 전세가율 === //
  POP_UP_JEONSAE_PERCENTAGE_TITLE: (
    <>
      <span>전세가율</span>
      <small>(전세가/매매가x100)</small>
    </>
  ),
  POP_UP_JEONSAE_PERCENTAGE_BIG_DESC: '각 단지의 전세가율이 지도에 표시됩니다.',
  POP_UP_JEONSAE_PERCENTAGE_MAIN_DESC: (
    <>
      건축년도와 면적 등이 비슷할 때, 전세가율이 높다는 것은 실거주 수요가 크다는 의미로 향후 가격상승 잠재력을 보유하고 있는 것으로 판단할 수
      있습니다.
      <br />
      <br />
      다만 노후화된 단지는 재건축에 따른 자산가치의 상승 기대치에 따라 전세가율이 많이 달라질 수 있으므로 매매가의 변동과 전세가율의 변동을 함께
      고려해야 합니다.
    </>
  ),
  DESC_JEONSAE_PERCENTAGE_1: '1) 원이 클수록 상대적으로 전세가율이 높다는 의미입니다.',
  DESC_JEONSAE_PERCENTAGE_2: '2) 지도를 축소시키면 해당 동, 구, 시별 전세가율을 확인하실 수 있습니다.',
  DESC_JEONSAE_PERCENTAGE_SOURCE: '* 출처: 국토교통부',

  // === 교육 === //
  // SCHOOL : 학교, SCHOOL_CRAM : 학원
  POP_UP_SCHOOL_TITLE: <>교육</>,
  POP_UP_SCHOOL_BIG_DESC: '중학교, 고등학교의 학업 성취도 평가 결과가 지도에 표시됩니다.',
  POP_UP_SCHOOL_CRAM_BIG_DESC: '교과 학원의 위치가 지도에 표시됩니다.',

  POP_UP_SCHOOL_MAIN_DESC: (
    <>
      학업 성취도 평가 결과가 높은 학교 인근의 단지는 교육수요를 감안할 때 향후 가격 상승잠재력을 보유한 것으로 판단할 수 있습니다.
      <br />
      <br />
      100%에 가까울수록 학업성취도가 높은 학교입니다.
    </>
  ),
  POP_UP_SCHOOL_CRAM_MAIN_DESC: '학원이 많다는 것은 교육수요를 감안할 때, 향후 가격 상승잠재력을 보유한 것으로 판단할 수 있습니다.',

  DESC_SCHOOL_1:
    '1) 2017년 기준 학업성취도 평가결과가 제공됩니다. 2018년 이후는 해당자료가 공개되지 않고있지만 2017년 자료로도 충분히 의미있는 데이터라고 판단됩니다.',

  // DESC_SCHOOL_CRAM_1: '1) 교과 학원과, 예체능 학원이 구분되어 표시됩니다.',
  DESC_SCHOOL_CRAM_2: '1) 원은 주요 학원가 위치를 나타내며, 원이 클수록 학원가 규모가 크다는 의미입니다 ',
  DESC_SCHOOL_SOURCE: '* 출처: 교육부',

  // === 랭킹 === //
  RANKING_LIST_TRADE_VOLUME: '거래량 많은 단지 TOP 10',
  RANKING_LIST_PRICE_INCREASE: '가격 많이 오른 단지 TOP 10',
  RANKING_LIST_PRICE_PER_PYEONG: '(최근 1년간) 평당가격 가장 비싼 아파트 TOP 10',
  RANKING_RECONSTRUCT_SCORE: '재건축 사업성이 가장 높은 단지 TOP 10',
  RANKING_LIST_PRICE: '(최근 1년간) 가장 비싼 아파트 TOP 10',
  RANKING_LIST_JEONSAE_PRICE: '전세가율이 가장 높은 단지 TOP 10',
  // RANKING_LIST_OFFICIAL_PRICE: '공시지가가 가장 비싼 단지 TOP 5',
  RANKING_LIST_SCHOOL: '학업성취도 가장 높은 학교 TOP 10',
  RANKING_LIST_RECENT: '가장 최근에 지어진 단지 TOP 10',
  RANKING_LIST_FLOOR_AREA: '용적률이 가장 낮은 단지 TOP 10',
  RANKING_LIST_AREA_PER_HOUSEHOLD: '세대당 대지면적이 가장 큰 단지 TOP 10',

  // RECONN
  RECONN_CHART_REAL_PRICE_IS_NULL:
    '1년내 실거래가가 없어 정확한 분석이 어렵습니다. 현 매물 시세와 인근단지의 동일평형 실거래가를 참고하여 비교해 주세요.',
  RECONN_CHART_ADDITIONAL_IS_OVER_REALPRICE:
    '재건축사업성 분석 범위를 벗어나 정확한 결과 제공이 어렵습니다. 현 매물 시세와 인근단지의 실거래가를 참고하여 비교해 주세요.',

  // ======================== error ======================== //
  ANALYSIS_ERROR: '분석 서비스 이용 중 오류가 발생하였습니다.',
  ANALYSIS_POST_POSITIVES_ERROR: '개발호재 알리기 요청 중 오류가 발생하였습니다.',
  ANALYSIS_WEEKLY_PRICE_ERROR: '주간실거래가 정보를 받아오던 중 오류가 발생하였습니다.',
  ANALYSIS_RANKING_SELECT_ADDR_ERROR: '지역을 선택해주세요.',
  ANALYSIS_RANKING_ERROR: '랭킹 정보를 받아오던 중 오류가 발생하였습니다.',
  ANALYSIS_GET_SCHOOL_INFO_ERROR: '학교 정보 이용 중 오류가 발생하였습니다.',
  ANALYSIS_POST_REPORT: '제보하기 등록 중 오류가 발생하였습니다.',
  ANALYSIS_REQUEST_RECOMMEND: '추천 정보를 받아오던 중 오류가 발생하였습니다.',

  RECONN_POST_REPORT_SUCCESS: '재건축 관련 내용을 알려주셔서 감사합니다. 검토 후 재건축 정보 및 사업성에 반영 하겠습니다.',
  COMPARE_POST_REPORT_SUCCESS: '우리 아파트 입지 정보를 알려주셔서 감사합니다. 검토 후 사이트에 반영하도록 하겠습니다.',
  POSITIVE_POST_REPORT_SUCCESS: '개볼호재 관련 내용을 알려주셔서 감사합니다. 검토 후 사이트에 반영하도록 하겠습니다.',
};
