import React from 'react';

const Texts = ({ boldText, guideText }) => {
  return (
    <div className="form-head">
      <label className="form-name">{boldText}</label>
      <span className="form-para">{guideText}</span>
    </div>
  );
};

export default Texts;
