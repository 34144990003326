import * as AT from './actionTypes';

export const sellAddImageItem = (itemImageObj, intORext) => ({
  // intORext _ 0: 실내 / 1: 외부
  type: AT.SELL_ADD_IMAGE_ITEM,
  itemImageObj,
  intORext,
});
export const sellDeleteImageItem = (itemImageObj, intORext) => ({
  // intORext _ 0: 실내 / 1: 외부
  type: AT.SELL_DELETE_IMAGE_ITEM,
  itemImageObj,
  intORext,
});

export const uploadImageItem = (imageCategory, imageFileName, imageFile) => ({
  type: AT.UPLOAD_ITEM_IMAGE,
  imageCategory,
  imageFileName,
  imageFile,
});

export const errorUploadItemImage = ({ error }) => ({
  type: AT.ERROR_UPLOAD_ITEM_IMAGE,
  payload: {
    error,
  },
});

export const asAddItemItem = (itemImageObj, corpORopen) => ({
  // corpORopen _ 0: 사업자 / 1: 개설 / 2: 프로필
  type: AT.AS_ADD_IMAGE_ITEM,
  itemImageObj,
  corpORopen,
});
export const asDeleteItem = (itemImageObj, corpORopen) => ({
  // corpORopen _ 0: 사업자 / 1: 개설 / 2: 프로필
  type: AT.AS_DELETE_IMAGE_ITEM,
  itemImageObj,
  corpORopen,
});
export const agentSignUpReset = () => ({
  type: AT.DELETE_AGENT_SIGNUP_IMAGE,
});
