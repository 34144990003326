import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const getDailyReportList = () => ({
  type: AT.GET_DAILY_REPORT_LIST,
});

export const setReportDate = (val) => ({
  type: AT.SET_DAILY_REPORT_DATE,
  val,
});

export const setReportData = (type_val, val) => ({
  type: AT.SET_INSERT_DATA,
  type_val,
  val,
});
export const saveDailyReport = () => ({
  type: AT.SAVE_DAILY_REPORT,
});
export const dailyReportIni = () => ({
  type: AT.DAILY_REPORT_INI,
});

export const getDailyReportLoading = () => ({
  type: AT.GET_DAILY_REPORT_LIST_LOADING,
});
export const getDailyReportSuccess = (report) => ({
  type: AT.GET_DAILY_REPORT_LIST_SUCCESS,
  report,
});
export const getDailyReportFailure = (error) => ({
  type: AT.GET_DAILY_REPORT_LIST_FAILURE,
  error,
});
