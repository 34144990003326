import React from 'react';

// index.html의 로딩중 element와 같음
const DawinLoading = () => {
  return (
    <div className="dl" style={{ backgroundColor: 'white' }}>
      <div className="loading-bar">
        <span className="bar">
          <span className="loading"></span>
        </span>
      </div>
    </div>
  );
};

export default DawinLoading;
