import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const NOTICE = { page: 1, total: 40, detail: {}, conlist: {} };

export default function notice(state = NOTICE, action = {}) {
  switch (action.type) {
    case AT.GET_NOTICE_DETAIL: {
      return {
        ...state,
      };
    }
    case AT.GET_NOTICE_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_NOTICE_REAL: {
      return {
        ...state,
        real: action.realList,
      };
    }
    case AT.SET_NOTICE_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.SET_NOTICE_NEW_ATTACH: {
      return {
        ...state,
        new: { ...state.new, attach: action.file_url },
      };
    }
    case AT.SET_NOTICE_NEW_TITLE: {
      return {
        ...state,
        new: { ...state.new, title: action.val },
      };
    }
    case AT.SET_NOTICE_NEW_CONT: {
      return {
        ...state,
        new: { ...state.new, cont: action.val },
      };
    }
    case AT.SET_NOTICE_TOTAL_CNT: {
      return {
        ...state,
        totalCnt: action.totalCnt,
      };
    }
    case AT.SET_NOTICE_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }

    case loading(AT.GET_NOTICE_LIST):
      return { ...state, [AT.GET_NOTICE_LIST]: Remote.Loading };
    case success(AT.GET_NOTICE_LIST):
      return { ...state, conlist: action.conlist };
    case failure(AT.GET_NOTICE_LIST):
      return {
        ...state,
        [AT.GET_NOTICE_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.GET_NOTICE_DETAIL):
      return { ...state, [AT.GET_NOTICE_DETAIL]: Remote.Loading };
    case success(AT.GET_NOTICE_DETAIL):
      return { ...state, detail: action.detail };
    case failure(AT.GET_NOTICE_DETAIL):
      return {
        ...state,
        [AT.GET_NOTICE_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
