import * as AT from './actionTypes';

export const getSection = (state) => state?.brokerPage?.brokerOfficeInfo?.infoSection;

export const getBrokerInfo = (state) => state?.brokerPage?.brokerOfficeInfo?.brokerInfo;

export const getOfficeIntro = (state) => state?.brokerPage?.brokerOfficeInfo?.officeIntro;
export const getOfficeInfo = (state) => state?.brokerPage?.brokerOfficeInfo?.officeInfo;
export const getSelectedOfficeInfo = (state) => state?.brokerPage?.brokerOfficeInfo?.officeInfo?.selectedOfficeInfo;
export const getOfficeList = (state) => state?.brokerPage?.brokerOfficeInfo?.officeList;
export const getChangePassRes = (state) => state?.brokerPage?.brokerOfficeInfo?.resultpass;
export const getCorNumbers = (state) => state?.brokerPage?.brokerOfficeInfo?.corNumbers;
export const getOfficeLatLng = (state) => state?.brokerPage?.brokerOfficeInfo?.officeLatLng;
//export const getOfficeImages = state => state?.brokerPage?.brokerOfficeInfo?.officeIntro?.officeImages;
export const getOfficeType = (state) => state?.brokerPage?.brokerOfficeInfo?.officeType;
