import { loading, success, failure } from '@/data/utils';

export const GET_NEWSALES = 'GET_NEWSALES';
export const GET_NEWSALES_LOADING = loading('GET_NEWSALES');
export const GET_NEWSALES_SUCCESS = success('GET_NEWSALES');
export const GET_NEWSALES_FAILURE = failure('GET_NEWSALES');

export const GET_REBUILDS = 'GET_REBUILDS';
export const GET_REBUILDS_LOADING = loading('GET_REBUILDS');
export const GET_REBUILDS_SUCCESS = success('GET_REBUILDS');
export const GET_REBUILDS_FAILURE = failure('GET_REBUILDS');

export const NEWSALE_SELECT_ITEM = 'SELECT_ITEM';

export const GET_NEWSALE_PRICE = 'GET_NEWSALE_PRICE';
export const SET_NEWSALE_PRICE = 'SET_NEWSALE_PRICE';
export const GET_NEWSALE_SCHEDULE = 'GET_NEWSALE_SCHEDULE';
export const SET_NEWSALE_SCHEDULE = 'SET_NEWSALE_SCHEDULE';

export const GET_REBUILD_SCHEDULE = 'GET_REBUILD_SCHEDULE';
export const SET_REBUILD_SCHEDULE = 'SET_REBUILD_SCHEDULE';

export const GET_NEWS = 'GET_NEWS';
export const SET_NEWS = 'SET_NEWS';
export const GET_MK_LETTER_NEWS = 'GET_MK_LETTER_NEWS';
export const SET_MK_LETTER_NEWS = 'SET_MK_LETTER_NEWS';
export const GET_NEWS_SEARCH_CONDITIONS = 'GET_NEWS_SEARCH_CONDITIONS';
export const SET_NEWS_SEARCH_CONDITIONS = 'SET_NEWS_SEARCH_CONDITIONS';
