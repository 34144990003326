import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery } from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.LOG_IN, sagas.login);
  yield takeEvery(AT.LOG_OUT, sagas.logout);
  yield takeEvery(AT.CHECK_AUTH, sagas.checkAuth);
  yield takeEvery(AT.SIGNUP, sagas.signup);
  yield takeEvery(AT.AGENT_SIGNUP, sagas.agentSignUp);
  yield takeEvery(AT.SOCIAL1, sagas.social1);
  // yield takeEvery(AT.SOCIAL2, sagas.social2);
  yield takeEvery(AT.SMS1, sagas.sms1);
  yield takeEvery(AT.SMS2, sagas.sms2);
  yield takeEvery(AT.SMS3, sagas.sms3);
  yield takeEvery(AT.SMS4, sagas.sms4);
  yield takeEvery(AT.CHANGE_CELLPHONENUMBER, sagas.changeCellphoneNumber);
  yield takeEvery(AT.CHANGE_CELLPHONENUMBER_MATCH, sagas.changeCellphoneNumberMatch);
  yield takeEvery(AT.SOCIAL_SIGNIN, sagas.socialSignin);
  yield takeEvery(AT.DAWIN_LOGIN, sagas.dawinLogin);
  yield takeEvery(AT.CHECK_EMAIL, sagas.checkEmail);
  yield takeEvery(AT.BYE_DAWIN, sagas.byeDawin);
  yield takeEvery(AT.FIND_ID_1, sagas.find.findId1);
  yield takeEvery(AT.FIND_ID_2, sagas.find.findId2);
  yield takeEvery(AT.FIND_PW, sagas.find.findPw);
  yield takeEvery(AT.CHANGE_PW, sagas.find.changePw);
  yield takeEvery(AT.DISCONNECT_NAVER, sagas.disconnectNaver);
  yield takeEvery(AT.SAVE_NICK_NAME, sagas.saveNickname);
  yield takeEvery(AT.GET_NICK_NAME, sagas.getNickname);
}
