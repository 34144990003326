{
  "name": "dawin_2.3_front",
  "version": "2024.0927.1",
  "author": "Dawin Property",
  "private": true,
  "scripts": {
    "format": "prettier --write \"./**/*.{js,html}\"",
    "clear": "rimraf .parcel-cache && rimraf dist",
    "clear:all": "rimraf .parcel-cache && rimraf dist && rimraf node_modules",
    "dev": "concurrently \"cross-env NODE_ENV=development parcel -p 1235 index.html\"",
    "build:dev": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=development parcel build index.html --no-source-maps --log-level verbose && node .image",
    "build:dev-server": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=dev-server parcel build index.html --no-source-maps --no-scope-hoist --log-level verbose && node .image",
    "build:test": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=test parcel build index.html --no-scope-hoist --log-level verbose && node .image",
    "build:rc": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=production parcel build index.html --no-source-maps --no-scope-hoist --log-level info && node .image",
    "build:prod": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=production parcel build index.html --no-source-maps --no-scope-hoist --log-level info && node .image",
    "buildtest:log": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=development parcel build  --no-scope-hoist --log-level verbose index.html",
    "buildtest:hoist": "rimraf .parcel-cache && rimraf dist && cross-env NODE_ENV=development parcel build --log-level verbose index.html",
    "deploy:dev": "yarn build:dev && bash ./deploy_dev.sh",
    "deploy:test": "yarn build:test && bash ./deploy_test.sh",
    "deploy:dev-server": "yarn build:dev-server && bash ./deploy_dev.sh",
    "pub": "cross-env NODE_ENV=development parcel -p 1235 src/publish/index.html"
  },
  "staticFiles": {
    "staticPath": "public"
  },
  "devDependencies": {
    "@babel/core": "^7.0.0-0",
    "@babel/plugin-proposal-class-properties": "^7.7.0",
    "@babel/plugin-proposal-optional-chaining": "^7.8.3",
    "@babel/plugin-transform-runtime": "^7.12.1",
    "@babel/preset-env": "^7.11.5",
    "@babel/preset-react": "^7.12.7",
    "@eslint/js": "^9.7.0",
    "@parcel/compressor-brotli": "^2.5.0",
    "@parcel/compressor-gzip": "^2.5.0",
    "@parcel/packager-raw-url": "^2.5.0",
    "@parcel/transformer-jsonld": "^2.5.0",
    "@parcel/transformer-sass": "^2.5.0",
    "@plasmohq/parcel-transformer-inject-env": "^0.2.11",
    "@types/eslint__js": "^8.42.3",
    "assert": "^2.0.0",
    "babel-eslint": "^10.0.3",
    "babel-plugin-module-resolver": "^4.0.0",
    "buffer": "^5.5.0",
    "eslint": "^9.7.0",
    "eslint-config-prettier": "^6.5.0",
    "eslint-import-resolver-babel-module": "^5.1.0",
    "eslint-plugin-import": "^2.18.2",
    "eslint-plugin-jsx-a11y": "^6.2.3",
    "eslint-plugin-react": "^7.16.0",
    "eslint-plugin-react-hooks": "^2.2.0",
    "imagemin": "^7.0.1",
    "imagemin-pngquant": "^9.0.1",
    "parcel": "^2.5.0",
    "parcel-plugin-static-files-copy": "^2.5.0",
    "parcel-reporter-static-files-copy": "^1.3.4",
    "path-browserify": "^1.0.0",
    "prettier": "^1.19.1",
    "punycode": "^1.4.1",
    "redux-devtools": "^3.5.0",
    "sass": "^1.52.3",
    "stream-browserify": "^3.0.0",
    "stream-http": "^3.1.0",
    "typescript-eslint": "^7.17.0",
    "util": "^0.12.3"
  },
  "dependencies": {
    "@babel/polyfill": "^7.12.1",
    "@dawinproperty/dawin_apps_bridge": "^1.0.10",
    "@dawinproperty/simple-react-lightbox": "^3.8.97",
    "@emotion/core": "^10.0.28",
    "@material-ui/core": "^4.0.0",
    "@material-ui/icons": "^4.9.1",
    "@material-ui/lab": "^4.0.0-alpha.50",
    "@mui/icons-material": "^5.16.0",
    "@nivo/bar": "0.55.0",
    "@nivo/line": "0.55.0",
    "@nivo/pie": "0.55.0",
    "@nivo/radar": "0.55.0",
    "@tosspayments/sdk": "^1.2.0",
    "@types/react": "^16.8.6",
    "@types/styled-components": "^5.1.34",
    "axios": "^0.19.0",
    "chart.js": "^2.9.3",
    "classname": "^0.0.0",
    "classnames": "^2.2.6",
    "concurrently": "^8.2.0",
    "connected-react-router": "^6.6.1",
    "cross-env": "^7.0.3",
    "d3": "^5.16",
    "d3-format": "~1.3.2",
    "d3-scale": "~2.1.2",
    "d3-shape": "~1.3.7",
    "daggy": "^1.4.0",
    "dayjs": "^1.10.4",
    "dist": "^0.1.2",
    "dotenv": "^8.2.0",
    "es6-map": "^0.1.5",
    "express": "^4.17.1",
    "file-saver": "^2.0.5",
    "formik": "^2.0.6",
    "globals": "^15.8.0",
    "immer": "^10.0.2",
    "immutable": "^4.1.0",
    "js-cookie": "^2.2.1",
    "jszip": "^3.9.1",
    "lodash-es": "^4.17.15",
    "lottie-react": "^2.4.0",
    "main.css": "^2.0.0",
    "moment": "^2.24.0",
    "moment-timezone": "^0.5.31",
    "node_module": "^0.0.0",
    "optional-js": "^2.3.0",
    "prop-types": ">= 15.7.0",
    "public-ip": "^4.0.1",
    "ramda": "^0.26.1",
    "react": "^16.10.2 || ^17.0.0",
    "react-apexcharts": "^1.3.7",
    "react-app-polyfill": "^1.0.6",
    "react-beautiful-dnd": "^13.1.0",
    "react-calendar": "^3.1.0",
    "react-chartjs-2": "^2.10.0",
    "react-datepicker": "^3.1.3",
    "react-dom": "^16.10.2 || ^17.0.0",
    "react-easy-linkify": "^1.0.8",
    "react-facebook-pixel": "^1.0.4",
    "react-gtm-module": "^2.0.11",
    "react-helmet": "^6.1.0",
    "react-hook-form": "^7.44.3",
    "react-hot-toast": "^2.4.1",
    "react-joyride": "^2.7.1",
    "react-kakao-login": "^1.2.1",
    "react-lightgallery": "^0.9.0",
    "react-loader-spinner": "^5.3.4",
    "react-loading-skeleton": "^3.3.1",
    "react-modal": "^3.11.2",
    "react-owl-carousel": "^2.3.1",
    "react-redux": "^7.1.3",
    "react-router-dom": "^5.1.2",
    "react-select": "^4.3.1",
    "react-simple-pull-to-refresh": "^1.3.0",
    "react-slick": "^0.29.0",
    "react-spring": "^8.0.27",
    "react-stockcharts": "^0.7.8",
    "react-transition-group": "^4.4.5",
    "react-use-gesture": "^7.0.15",
    "recharts": "^1.8.5",
    "redux": "^4.0.4",
    "redux-saga": "^1.1.3",
    "redux-thunk": "^2.3.0",
    "regenerator-runtime": "^0.13.7",
    "reset.css": "^2.0.2",
    "rimraf": "^3.0.2",
    "serialize-to-js": "^3.1.1",
    "slick-carousel": "^1.8.1",
    "socket.io-client": "^4.4.0",
    "src": "^1.1.2",
    "style.css": "^1.0.0",
    "styled-component": "^2.8.0",
    "styled-components": "^5.1.0",
    "styled-jsx": "^3.3.2",
    "swiper": "^6.8.0",
    "terraformer-wkt-parser": "^1.2.1",
    "typescript": "^5.5.4",
    "uck": "^1.0.1",
    "unfetch": "^4.2.0",
    "uuid": "^9.0.0",
    "yup": "^0.29.3"
  },
  "type": "module",
  "proxy": "http://localhost:8888",
  "browserslist": {
    "production": [
      "> 1%",
      "last 2 versions",
      "not ie <= 10"
    ],
    "development": [
      "> 1%",
      "last 2 versions",
      "not ie <= 10"
    ]
  }
}
