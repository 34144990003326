import { loadTossPayments } from '@tosspayments/sdk';
import { api as paymentApis } from '@/apis/payment';

export const openTosspayWidow = ({ clientKey, customerKey, successUrl, failUrl, customerEmail, customerName }) => {
  // 클라이언트 키로 초기화하기
  loadTossPayments(clientKey).then((tossPayments) => {
    tossPayments
      .requestBillingAuth('카드', {
        // 결제 수단 파라미터
        // 빌링키 발급 요청을 위한 파라미터
        customerKey: customerKey,
        // customerEmail: customerEmail,
        // customerName: customerName,
        successUrl: successUrl,
        failUrl: failUrl,
      })
      .catch(function(error) {
        if (error.code === 'USER_CANCEL') {
          // 결제 고객이 결제창을 닫았을 때 에러 처리
        } else if (error.code === 'INVALID_CARD_COMPANY') {
          // 유효하지 않은 카드 코드에 대한 에러 처리
        }
      });
  });
};

export const getTossClientKey = (callback) => {
  paymentApis.getTossClientKey().then(callback);
};
