import React, { useState, useEffect } from 'react';
import TemplateContentsPopupForList from '@/components/templates/TemplateContentsPopupForList';
import { getEmptyMessage, getTitle } from '../util';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import useGetDataByType from '../hook/useGetDataByType';

const AddressSimpleSelectPopup = ({ type, onClosePopup, applyCallback }) => {
  const dispatch = useDispatch();
  const [maxCount, setMaxCount] = useState(3);
  const [popupOption, setPopupOption] = useState({});

  const getDataByType = useGetDataByType({ onClosePopup });

  useEffect(async () => {
    const data = await getDataByType(type);

    // zustand vs class
    if (data?.length === 0 || !data) {
      const contents = getEmptyMessage(type);
      dispatch(actions.common.alert({ contents }));
      onClosePopup();
    } else {
      const name = getTitle(type);
      setPopupOption({ title: name, list: data });
    }
  }, []);

  return (
    <>
      {popupOption.list && popupOption.list?.length > 0 ? (
        <TemplateContentsPopupForList
          title={popupOption.title}
          list={popupOption.list}
          maxCount={maxCount}
          setMaxCount={setMaxCount}
          closeCallback={onClosePopup}
          applyCallback={applyCallback}
        />
      ) : (
        false
      )}
    </>
  );
};

export default AddressSimpleSelectPopup;
