import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

/**
 * @desc 사용자에게 일방적인 알림 문구만 제공 후 일정시간 뒤 자동 닫기되는 팝업
 */
const Notice = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        success: {
          style: {
            backgroundColor: '#353535',
            color: 'white',
            borderRadius: '20px',
          },
        },
        error: {
          style: {
            backgroundColor: '#353535',
            color: 'white',
            borderRadius: '20px',
          },
        },
      }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              <div onClick={() => toast.dismiss()}>{message}</div>
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Notice;
