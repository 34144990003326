import React from 'react';

const ButtonClose = ({ onClickButton }) => {
  return (
    <div className="popup-local-func">
      <span className="button-area">
        <button type="button" className="btn popup-close" onClick={onClickButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
            <title>닫기</title>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
          </svg>
        </button>
      </span>
    </div>
  );
};

export default ButtonClose;
