import React from 'react';

const List = ({ list, maxCount, handleSelect }) => {
  return (
    <ul className="goods-list">
      {list?.slice(0, maxCount).map((data, key) => (
        <li className="goods-item" key={key}>
          <label className="goods-wrap" htmlFor={`listContentItem-${key}`}>
            <div className="goods-select">
              <div className="form radio module-a style-c type-line accent-01 small-1x">
                <input
                  type="radio"
                  className="form-elem"
                  name="checkListItem"
                  value={data}
                  onChange={() => handleSelect(data)}
                  id={`listContentItem-${key}`}
                />
              </div>
            </div>
            <div className="goods-inform">
              <div className="goods-head">
                <p className="goods-subject">
                  <span className="goods-name">{data?.mainName}</span>
                </p>
                {data?.subName && <p className="goods-summary">{data?.subName}</p>}
              </div>
            </div>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default List;
