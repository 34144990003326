const useSetTexts = ({ communityText }) => {
  const titleText = communityText ? communityText?.titleText : '닉네임 변경';
  const boldText = communityText ? communityText?.boldText : '새 닉네임 입력';
  const guideText = communityText ? communityText?.guideText : '변경하실 닉네임 입력 후 중복확인 버튼을 눌러주세요.';
  const btnText = communityText ? communityText?.btnText : '변경';

  return { titleText, boldText, guideText, btnText };
};

export default useSetTexts;
