import useFeedId from '@/combinedComp/Community/DetailNew/hooks/useFeedId';
import LikeButton from '@/combinedComp/Community/sharedComponents/Like/components/LikeButton';
import React from 'react';

const ReplyLike = ({ reply, deletedId, hasIcon, isFeedDeleted }) => {
  const feedId = useFeedId();

  const id = reply?.id;
  const reactionCount = reply?.reactionCount;
  const myReaction = reply?.myReaction;
  const status = reply?.status;
  const setDeletedStatus = deletedId?.indexOf(id) > -1;

  return (
    <div className="reply-data">
      <p className="data-list">
        {reactionCount.map((item, index) => {
          return item.type == 'LIKE' ? (
            <LikeButton
              key={index}
              my={myReaction}
              likeCount={item.count}
              commentId={id}
              feedId={feedId}
              publishStatus={status}
              setDeletedStatus={setDeletedStatus}
              hasIcon={hasIcon}
              isFeedDeleted={isFeedDeleted}
            />
          ) : (
            undefined
          );
        })}
      </p>
    </div>
  );
};

export default ReplyLike;
