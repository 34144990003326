import FavoButton from '@/components/FavoButton';
import { actions, selectors } from '@/data';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const WeeklyBestCard = ({ result, fav_complex_list }) => {
  const { data, name } = result;
  const dispatch = useDispatch();

  const pushToDetail = (target_url) => dispatch(actions.router.push(`/buying${target_url}`));
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  return (
    <div className="list_block">
      <div className="list_info">
        <span className="cate">{name}</span>
      </div>
      {data?.map((detail) => (
        <div key={detail.tb_cb_complex_idx}>
          <div className="list_info">
            <a className="aptname" onClick={() => pushToDetail(detail.target_url)}>
              {detail.complex_name}
            </a>
            <span className="favo_area">
              {isLoggedIn ? (
                <>
                  {fav_complex_list && <FavoButton is_favorite={fav_complex_list[detail.tb_cb_complex_idx]} complex_idx={detail.tb_cb_complex_idx} />}
                </>
              ) : (
                <FavoButton is_favorite={false} complex_idx={detail.tb_cb_complex_idx} />
              )}
            </span>
            {/* <!-- 220628 주소영역 추가 --> */}
            <span className="address"  onClick={() => pushToDetail(detail.target_url)}>{detail.display_address}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WeeklyBestCard;
