import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { groupBy } from 'ramda';
import { failure, loading, success } from '@/data/utils';

export const SETAREA_STATE = {};

export default function setArea(state = SETAREA_STATE, action = {}) {
  switch (action.type) {
    case AT.VIEW_CHECKED: {
      return {
        ...state,
        viewChecked: action.checked,
      };
    }
    case AT.SAVE_SET_DEFAULT_AREA: {
      return {
        ...state,
      };
    }
    case AT.SET_DATA_AREA: {
      let dongList;
      let sigunguNameList;
      let sigunguCodeList;
      let brokerageAreaList = [];

      dongList = action.data.result;
      sigunguNameList = Object.keys(groupBy((item) => item?.sigungu)(action.data.result));
      sigunguCodeList = Object.keys(groupBy((item) => item?.b_dong_code?.substring(0, 5))(action.data.result));

      sigunguNameList?.map((item, key) => {
        brokerageAreaList.push({ area: item, key: sigunguCodeList[key] + '00000' });
      });
      dongList?.map((item2) => {
        brokerageAreaList.push({ area: item2.ri, key: item2.b_dong_code });
      });

      return { ...state, brokerageAreaList: brokerageAreaList };
    }
    case AT.ADD_ACTIVE_AREA: {
      if (state?.areaList?.areaList?.some((ii) => ii.sigungu_code == action.sigunguCode)) {
        return { ...state };
      }
      let origin_areaList = state.areaList.areaList ?? [];
      let sigungu_arr = [];
      action.dong.map((item) => {
        var ll = {
          sigungu: action.sigunguName,
          sigungu_code: parseInt(action.sigunguCode),
          dong_name: item.name,
          dong_code: parseInt(item.dong_code),
        };
        sigungu_arr.push(ll);
      });
      sigungu_arr.sort((a, b) => {
        if (a.dong_name > b.dong_name) return 1;
        if (a.dong_name < b.dong_name) return -1;
      });
      var lll = {
        sigungu: sigungu_arr,
        sigungu_code: action.sigunguCode,
        sigungu_name: action.sigunguName,
        close: 'y',
      };
      origin_areaList.push(lll);

      // const code = groupBy((i) => i.sigungu_code)(action.dong_list);
      // const list = groupBy((i) => i.sigungu_code)(action.dong_list);
      // let code_arr = Object.keys(code);
      // for (var i = 0; i < code_arr.length; i++) {
      //   var name = '';
      //   action.res.areaList.forEach((iii, kkk) => {
      //     if (String(iii.sigungu_code) === String(code_arr[i])) {
      //       name = String(iii.sigungu);
      //       return;
      //     }
      //   });
      //   list[code_arr[i]].map((iii, kkk) => {
      //     if (iii.sett == 'y') setting_yn = true;
      //   });
      //   const arr = {
      //     sigungu: list[code_arr[i]],
      //     sigungu_code: code_arr[i],
      //     sigungu_name: name,
      //   };
      //   res.push(arr);
      // }
      return {
        ...state,
        areaList: { ...state.areaList, areaList: origin_areaList },
      };
    }

    case AT.DEL_ACTIVE_AREA: {
      let re_areaList = state.areaList.areaList.filter((ii) => ii.sigungu != action.item?.sigungu);

      return {
        ...state,
        areaList: { ...state.areaList, areaList: re_areaList },
      };
    }
    case AT.ADD_BROKER_DISTRICT: {
      if (state?.brokerDistrict.some((ii) => ii.districtCode == action.district.districtCode)) {
        return { ...state };
      }
      let originDistrict = state.brokerDistrict ?? [];
      originDistrict.push(action.district);

      return {
        ...state,
        brokerDistrict: [...originDistrict],
      };
    }
    case AT.DELETE_AREA_DISTIRCT: {
      let changeBrokerDistrict;
      if (Array.isArray(action.code)) {
        changeBrokerDistrict = state?.brokerDistrict?.filter((ii) => action.code.indexOf(ii.dsitrictCode) < -1);
      } else {
        changeBrokerDistrict = state?.brokerDistrict?.filter((ii) => ii.districtCode != action.code);
      }
      // return { ...state };
      return {
        ...state,
        brokerDistrict: changeBrokerDistrict,
      };
    }
    case AT.SET_PREV_POLYGONS: {
      return {
        ...state,
        prevPolygons: action.data,
      };
    }

    case loading(AT.GET_POSSIBLE_AREA):
      return { ...state, [AT.GET_POSSIBLE_AREA]: Remote.Loading };
    case success(AT.GET_POSSIBLE_AREA):
      let res = [];
      let setting_yn = false;
      const code = groupBy((i) => i.sigungu_code)(action.res.areaList);
      const list = groupBy((i) => i.sigungu_code)(action.res.areaList);
      let code_arr = Object.keys(code);
      for (var i = 0; i < code_arr.length; i++) {
        var name = '';
        action.res.areaList.forEach((iii, kkk) => {
          if (String(iii.sigungu_code) === String(code_arr[i])) {
            name = String(iii.sigungu);
            return;
          }
        });
        list[code_arr[i]].map((iii, kkk) => {
          if (iii.sett == 'y') setting_yn = true;
        });
        const arr = {
          sigungu: list[code_arr[i]],
          sigungu_code: code_arr[i],
          sigungu_name: name,
        };
        res.push(arr);
      }
      let re_res = res.sort((a, b) => {
        if (a.sigungu.find((x) => x.sett == 'y') && b.sigungu.find((x) => x.sett == 'n')) return -1;
        else if (a.sigungu.find((x) => x.sett == 'n') && b.sigungu.find((x) => x.sett == 'y')) return 1;
        else if (a.sigungu.find((x) => x.sett == 'y') && b.sigungu.find((x) => x.sett == 'y')) return 0;
        else if (a.sigungu.find((x) => x.sett == 'y') && b.sigungu.find((x) => x.sett == 'y')) return 0;
        else return 0;
      });

      return {
        ...state,
        areaList: { ...state.areaList, setyn: action.res.setyn, areaList: re_res },
        viewChecked: setting_yn ? 'setArea' : 'default',
        radioCheck: setting_yn ? 'setArea' : 'default',
      };

    case failure(AT.GET_POSSIBLE_AREA):
      return {
        ...state,
        [AT.GET_POSSIBLE_AREA]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_SET_AREA):
      return { ...state, [AT.GET_SET_AREA]: Remote.Loading };
    case success(AT.GET_SET_AREA):
      return { ...state, [AT.GET_SET_AREA]: Remote.Success(action.data), getSetArea: action.data };
    case failure(AT.GET_SET_AREA):
      return {
        ...state,
        [AT.GET_SET_AREA]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_BROKER_DISTRICT):
      return { ...state, [AT.GET_BROKER_DISTRICT]: Remote.Loading };
    case success(AT.GET_BROKER_DISTRICT):
      return { ...state, [AT.GET_BROKER_DISTRICT]: Remote.Success(action.data), brokerDistrict: action.data };
    case failure(AT.GET_BROKER_DISTRICT):
      return {
        ...state,
        [AT.GET_BROKER_DISTRICT]: Remote.Failure(action.error),
        error: action.error,
      };
    case loading(AT.GET_BROKER_AREA_DISTRICT):
      return { ...state, [AT.GET_BROKER_AREA_DISTRICT]: Remote.Loading };
    case success(AT.GET_BROKER_AREA_DISTRICT):
      return { ...state, [AT.GET_BROKER_AREA_DISTRICT]: Remote.Success(action.data), polygons: action.data };
    case failure(AT.GET_BROKER_AREA_DISTRICT):
      return {
        ...state,
        [AT.GET_BROKER_AREA_DISTRICT]: Remote.Failure(action.error),
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
