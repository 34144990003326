import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createRootReducer } from '@/data/rootReducer';
import rootSaga from '@/data/rootSaga';
import env from '@/resources/env';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
let store;

export default function configureStore(initialData) {
  const rootReducer = createRootReducer(history, initialData);
  store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history)),
      env.DEV && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({
            // [20210127 정석규 수정]
            // stack trace 옵션 추가
            // 해당 액션이 소스코드 어디에서 발생했는지 추적하는데 편리함.
            // parcel dev server의 sourcemap이 제대로 인식되려면 `npm run dev`의 `--public-url /dev/` 을 제거해야함
            // => `npm run temp` 실행 (`--public-url /dev/` 옵션을 사용하는곳이 있을지 몰라서 새로운 npm script를 생성함)
            // ref: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#trace
            trace: true,
            // max stack frame 갯수
            traceLimit: 20,
          })
        : (f) => f
    )
  );
  sagaMiddleware.run(rootSaga);
  return {
    store,
    history,
  };
}

export { store, history };
