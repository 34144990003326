import { savePushToken } from '@/data/notification/services';
import { COOKIE_CONSTS } from '@/resources/global';
import { setCookieByKey, getCookieByKey } from '@/data/cookie';
import { getPermissionAsBoolean } from '../types/PermissionType';

/**
 * 앱푸시 권한 요청 후 서버로 푸시토큰 저장
 */
export const notificationPrmsUpdate = async () => {
  try {
    // 앱 푸시 권한
    const notificationPrms = await window.dawin_instance.bridge.permissionFunction.getNotificationPermissionState();

    // 마지막 토큰 업데이트 date
    const appInfo_update_date = await getCookieByKey(COOKIE_CONSTS.APPINFO_UPDATE_DATE);

    // 허용 안 함인 경우
    if (!getPermissionAsBoolean(notificationPrms)) {
      if (appInfo_update_date) {
        setCookieByKey(COOKIE_CONSTS.APPINFO_UPDATE_DATE);
      }

      return;
    }

    // 오늘 date
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const epochtime = date.getTime().toString();

    // 하루 한번만 push token 업데이트. 하루 지났는지 비교. 날짜만 바뀌면 지난 것으로.
    if (!appInfo_update_date || appInfo_update_date < epochtime) {
      // 푸시토큰 조회
      const notificationToken = await window.dawin_instance.bridge.appInfoFunction.getNotificationToken();
      if (notificationToken) {
        // 푸시토큰 db에 업데이트
        await savePushToken(notificationToken); // notification api로 push token 업데이트

        // 비교를 위해 푸시토큰 업데이트 시간을 저장
        setCookieByKey(COOKIE_CONSTS.APPINFO_UPDATE_DATE, epochtime);
      }
    }
  } catch {
    const map = new Map();
    map.set('message', 'notificaion token 업데이트 실패');
    window.dawin_instance?.bridge?.loggingFunction.sendCustomLog('error', map);
  }
};
