import styled from 'styled-components';

export default styled.div`
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;

  .swiper-container {
    width: 100%;
    /* height: 100%; */
    padding-bottom: 45px;
  }

  .swiper-pagination-bullet {
    margin: 45px 4px 0 4px !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .swiper-pagination-bullet-active {
    background: #d72727 !important;
  }

  .swiper-slide,
  .swiper-slide-duplicate,
  .swiper-slide-prev {
    display: block;
    height: calc(100% - 60px);
  }
  .swiper-button-next,
  .swiper-button-prev {
    z-index: 101 !important;
    color: dimgray !important;
    top: 50% !important;
    margin-top: calc(1px - (var(--swiper-navigation-size) / 2));
  }

  .swiper-button-prev {
    left: 0 !important;
  }

  .swiper-button-next {
    right: 0 !important;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .swiper-button-disabled {
    /* display: none; */
    opacity: 0.15 !important;
    cursor: pointer !important;
    pointer-events: unset !important;
  }

  /* .slide-title {
    text-align: center;
    font-size: 16px;
  } */

  .slide-figure {
    height: calc(100% - 65px);
  }

  .slide-figure img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
