import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { getComplexAddress, getComplexDetailBulk } from '@/data/user/services';
import ComplexList from './ComplexList/ComplexList';

const MyComplexHistory = ({ userId }) => {
  const dispatch = useDispatch();

  // 해당 유저가 인증한 집 정보 가져오기
  const complexHistoryByUser = useSelector(selectors.user.complexHistoryByUser);
  const [complexList, setComplexList] = useState();
  const [addressList, setAddressList] = useState();

  useEffect(async () => {
    const complexByUser = complexHistoryByUser?.[userId];
    if (userId && complexByUser) {
      const complexIds = Object?.keys(complexByUser);
      const result = await getComplexDetailBulk(complexIds); // 단지아이디를 벌크로 주면 > 단지주소 + 단지이름

      let array = [];
      for (var key in result) {
        const dongCode = result[key].dong_code;
        array.push(dongCode);
      }

      const dongCodeList = [...new Set(array)];
      const complexAddressList = await getComplexAddress(dongCodeList); // 동코드를 벌크로 주면 > 단지주소
      setAddressList(complexAddressList);

      const newComplexByUser = Object.keys(complexByUser).map((key) => Object.assign({}, complexByUser[key], result[key]));
      const orderedDate = newComplexByUser?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
      setComplexList(Object.entries(orderedDate));
    } else {
      setComplexList([]);
    }
  }, [complexHistoryByUser, userId]);

  const [showMore, setShowMore] = useState(true);
  const [showList, setShowList] = useState([]);
  const [length, setLength] = useState(6);

  useEffect(() => {
    if (complexList?.length < 4) {
      setShowList(complexList);
      setShowMore(false);
    } else {
      const array = complexList?.slice(0, 3);
      setShowList(array);
      setShowMore(true);
    }
  }, [complexList]);

  const showMoreComplex = () => {
    const array = complexList.slice(0, length);
    setShowList(array);
    setLength(length + 3);

    if (showList?.length + 3 >= complexList?.length) {
      setShowMore(false);
    }
  };

  const showGuide = (e) => {
    e.preventDefault();
    dispatch(
      actions.common.alert({
        contents: (
          <>
            <div className="board-head">
              <h2 className="board-subject">
                <span className="board-name">집주인 인증</span>
              </h2>
            </div>
            <div className="board-body">
              <p className="para">다윈중개에서 해당 단지를 거래했거나, 등기부등본상 소유주와 대조(오픈 예정)를 통하여 집주인 인증이 된 경우입니다.</p>
              <p className="para">인증 시점의 소유자 여부만 표시되며, 인증 이후의 소유 변동은 나타나지 않습니다.</p>
            </div>
          </>
        ),
      })
    );
  };

  return (
    <div className="section module-a">
      <div className="section-wrap">
        <div className="section-head">
          <h3 className="section-subject">
            <span className="section-name">집주인 인증</span> <span className="section-data">{complexList?.length}</span>
            <a className="btn-ico small-1x normal-02 attr-help" href="#" data-bui-toggle-button="infoPopup" onClick={showGuide}>
              <span className="btn-text">자세히보기</span>
            </a>
          </h3>
        </div>
        <div className="section-body">
          {complexList && (
            <div className="posts-latest module-a style-a type-a">
              {complexList?.length === 0 ? (
                <div className="info-board no-data attr-estate">
                  <div className="board-wrap">
                    <div className="board-head">
                      <p className="board-summary">
                        <span className="wbr">목록에 표시할 정보가 없습니다.</span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="posts-list">
                  <ComplexList showList={showList} addressList={addressList} />
                </div>
              )}

              {showMore && (
                <div className="button-display module-a style-a type-d">
                  <span className="button-area">
                    <button className="btn module-a style-b type-line normal-04 large-1x flex" type="button" onClick={showMoreComplex}>
                      <span className="btn-text">더보기</span>
                    </button>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyComplexHistory;
