import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';

const usePersonalize = (isLoggedIn) => {
  const dispatch = useDispatch();
  const [interestComplex, setInterestComplex] = useState([]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(actions.personalize.getInterestComplex({ setData: setInterestComplex }));
    }
  }, []);

  return interestComplex;
};

export default usePersonalize;
