import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const BK_QNA = { page: 1, total: 40, detail: {}, conlist: {} };

export default function bkQnA(state = BK_QNA, action = {}) {
  switch (action.type) {
    case AT.GET_BK_QNA_DETAIL: {
      return {
        ...state,
        detailIdx: action.idx,
      };
    }
    case AT.GET_BK_QNA_LIST: {
      return {
        ...state,
      };
    }
    case AT.SET_BK_QNA_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case AT.SET_BK_QNA_NEW_ATTACH: {
      return {
        ...state,
        new: { ...state.new, file_name: action.file_name, attach: action.idx },
      };
    }
    case AT.SET_BK_QNA_NEW_TITLE: {
      return {
        ...state,
        new: { ...state.new, title: action.val },
      };
    }
    case AT.SET_BK_QNA_NEW_CONT: {
      return {
        ...state,
        new: { ...state.new, cont: action.val },
      };
    }
    case AT.SET_BK_QNA_TOTAL_CNT: {
      return {
        ...state,
        totalCnt: action.totalCnt,
      };
    }
    case AT.SET_BK_QNA_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.SAVE_BK_QNA_REPLY: {
      return {
        ...state,
        pu_idx: action.pu_idx,
        val: action.val,
      };
    }
    case AT.EDIT_BK_QNA_REPLY: {
      return {
        ...state,
      };
    }
    case AT.DEL_BK_QNA_REPLY: {
      return {
        ...state,
      };
    }
    case AT.SAVE_BK_QNA_NEW: {
      return {
        ...state,
      };
    }
    case AT.EDIT_BK_QNA: {
      return {
        ...state,
      };
    }
    case AT.DEL_BK_QNA: {
      return {
        ...state,
      };
    }

    case loading(AT.GET_BK_QNA_LIST):
      return { ...state, [AT.GET_BK_QNA_LIST]: Remote.Loading };
    case success(AT.GET_BK_QNA_LIST):
      return { ...state, conlist: action.conlist };
    case failure(AT.GET_BK_QNA_LIST):
      return {
        ...state,
        [AT.GET_BK_QNA_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.GET_BK_QNA_DETAIL):
      return { ...state, [AT.GET_BK_QNA_DETAIL]: Remote.Loading };
    case success(AT.GET_BK_QNA_DETAIL):
      return { ...state, detail: action.detail };
    case failure(AT.GET_BK_QNA_DETAIL):
      return {
        ...state,
        [AT.GET_BK_QNA_DETAIL]: Remote.Failure(action.error),
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
