import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  // ============================ 공통 ============================ //
  yield takeEvery(AT.MP_TOGGLE_FAVORITE, sagas.toggleFavorite);
  // ============================ MyInfo ============================ //
  yield takeEvery(AT.MP_CHANGE_PASSWORD, sagas.changePassword);
  yield takeEvery(AT.GET_SOCIAL_LIST, sagas.getSocialList);
  // ============================ MyBuy ============================ //
  yield takeEvery(AT.GET_FAVORITE_ITEMS_LIST, sagas.getFavoriteItemsList);
  yield takeEvery(AT.GET_INTERESTED_COMPLEX_LIST, sagas.getInterestedComplexList);
  yield takeEvery(AT.GET_CONTRACTED_ITEMS_LIST, sagas.getContractedItemsList);

  // ============================ MySell ============================ //
  yield takeEvery(AT.GET_MY_SELL_ITEMS_LIST, sagas.getMySellItemsList);

  // 매물삭제
  yield takeLatest(AT.POST_DELETE_ITEM, sagas.deleteItem);
  // 매물등록취소
  yield takeLatest(AT.POST_REG_CANCEL_ITEM, sagas.regCancelItem);
  // 매물재등록
  yield takeLatest(AT.POST_REREG_ITEM, sagas.reRegItem);

  // 거래완료신고
  yield takeLatest(AT.POST_REPORT_ITEM_TRADE_COMPLETE, sagas.postReportItemTradeComplete);

  // ============================ 2.3 MySell ============================ //
  yield takeEvery(AT.GET_MY_SELL_COUNT, sagas.getMySellCount);
  yield takeEvery(AT.GET_MY_SELL_LIST, sagas.getMySellList);
  yield takeLatest(AT.GET_MY_SELL_LIST2, sagas.getMySellList2);
}
