import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { TYPES } from '@/components/GlobalDualPopups/helper';
import TemplateContentsPopup from '@/components/templates/TemplateContentsPopup';
import { STATUS } from '@/data/utils';
import useShowLoadingBarOnLoading from '@/pages/Login/views/hooks/useShowLoadingBarOnLoading';

const FindPasswordPopup = ({ isBusy, setShow }) => {
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState(STATUS.NOTASKED);

  const emailRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (loadingStatus === STATUS.LOADING) return;

    if (!emailRef?.current?.value) {
      dispatch(actions.common.alert({ contents: '이메일을 입력해주세요.' }));
      return;
    }

    const callback = () => {
      setShow(false);
    };

    const options = { setLoadingStatus, callback };
    dispatch(actions.auth.findPw({ email: emailRef.current.value, options }));
  };

  const handleFindId = (e) => {
    e.preventDefault();
    dispatch(actions.common.openGlobalDualPopup(TYPES.FIND_ID));
  };

  const showLoadingBarOnLoading = useShowLoadingBarOnLoading();

  return (
    <TemplateContentsPopup title="비밀번호 찾기" onClose={() => setShow(false)}>
      <div className="popup-local-body">
        <div className="popup-content">
          <div className="popup-content-body">
            <fieldset className="submit-form module-a type-c medium">
              <legend>비밀번호찾기 입력서식</legend>
              <div className="form-list">
                <div className="form-item">
                  <div className="form-wrap">
                    <div className="form-head">
                      <label className="form-name" htmlFor="email">
                        이메일 입력
                      </label>
                      <span className="form-para">가입하신 이메일을 입력하시면 해당 이메일로 비밀번호 재설정 URL을 보내드립니다.</span>
                    </div>
                    <div className="form-body">
                      <div className="form-area">
                        <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
                          <input
                            type="email"
                            className="form-elem"
                            id="email"
                            // maxLength="11"
                            placeholder="이메일"
                            disabled=""
                            ref={emailRef}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="popup-local-util">
        <div className="button-display module-a style-a type-d">
          <span className="button-area">
            <button type="submit" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={handleClick}>
              <span className="btn-text">{showLoadingBarOnLoading({ loadingStatus, text: '보내기' })}</span>
            </button>
          </span>
        </div>
      </div>
    </TemplateContentsPopup>
  );
};

export default FindPasswordPopup;
