import * as AT from '@/data/auth/actionTypes';

export const setUserSession = (userSession) => ({
  type: AT.SET_USER_SESSION,
  userSession,
});
export const resetAuth = () => ({
  type: AT.RESET_AUTH,
});
export const setAuthToken = (token) => ({
  type: AT.SET_AUTH_TOKEN, //for socialToken, smsToken
  token,
});
export const setLoginStep = (step, cellphone, name) => ({
  type: AT.SET_LOGIN_STEP,
  step,
  cellphone,
  name,
});
export const setAgreeAction = (nextAction) => ({
  type: AT.SET_AGREE_ACTION,
  nextAction,
});
export const setUserInfoForLogin = (data) => ({
  type: AT.SET_USERINFO_FOR_LOGIN,
  data,
});
export const dawinLogin = ({ email, password, saveID }) => ({
  type: AT.DAWIN_LOGIN,
  email,
  password,
  saveID,
});
export const login = (data) => ({
  type: AT.LOG_IN,
  data,
});
export const loginLoading = () => ({
  type: AT.LOG_IN_LOADING,
});
export const loginSuccess = (data) => ({
  type: AT.LOG_IN_SUCCESS,
  data,
});
export const loginFailure = (error) => ({
  type: AT.LOG_IN_FAILURE,
  error,
});

export const sms1 = ({ name, cellphone, nextStep, resend, options }) => ({
  type: AT.SMS1,
  name,
  cellphone,
  nextStep,
  resend,
  options,
});
export const sms2 = ({ name, cellphone, authNum, options }) => ({
  type: AT.SMS2,
  name,
  cellphone,
  authNum,
  options,
});
export const sms3 = ({ smsAuthToken, memberIdx, options }) => ({
  type: AT.SMS3,
  smsAuthToken,
  memberIdx,
  options,
});
export const sms4 = ({ memberIdx, uid, type, email, profile, afterAgree }) => ({
  type: AT.SMS4,
  memberIdx,
  uid,
  stype: type,
  email,
  profile,
  afterAgree,
});

export const socialSignin = (params = {}) => ({
  type: AT.SOCIAL_SIGNIN,
  ...params,
});

export const changeCellphoneNumber = ({ cellphone, changeModalType }) => ({
  type: AT.CHANGE_CELLPHONENUMBER,
  cellphone,
  changeModalType,
});

export const changeCellphoneNumberMatch = ({ cellphone, authNum, setCurrent, successCallback }) => ({
  type: AT.CHANGE_CELLPHONENUMBER_MATCH,
  cellphone,
  authNum,
  setCurrent,
  successCallback,
});

export const smsLoading = () => ({
  type: AT.SMS_LOADING,
});
export const smsSuccess = (data) => ({
  type: AT.SMS_SUCCESS,
  data,
});
export const smsFailure = (error) => ({
  type: AT.SMS_FAILURE,
  error,
});
export const setIdCandidates = (data) => ({
  type: AT.SET_ID_CANDIDATES,
  data,
});

export const checkEmail = (mailToken) => ({
  type: AT.CHECK_EMAIL,
  mailToken,
});
export const emailConfirmed = (data) => ({
  type: AT.EMAIL_CONFIRMED,
  data,
});

export const logout = (redirect, option) => ({
  type: AT.LOG_OUT,
  redirect,
  option,
});

export const checkAuth = () => ({
  type: AT.CHECK_AUTH,
});
export const checkAuthloading = () => ({
  type: AT.CHECK_AUTH_LOADING,
});
export const checkAuthSuccess = () => ({
  type: AT.CHECK_AUTH_SUCCESS,
});
export const checkAuthFailure = () => ({
  type: AT.CHECK_AUTH_FAILURE,
});

export const signup = (formValues) => ({
  type: AT.SIGNUP,
  formValues,
});
export const signupLoading = () => ({
  type: AT.SIGNUP_LOADING,
});
export const signupSuccess = (data) => ({
  type: AT.SIGNUP_SUCCESS,
  data,
});
export const signupFailure = (error) => ({
  type: AT.SIGNUP_FAILURE,
  error,
});

export const agentSignUp = (formValues) => ({
  type: AT.AGENT_SIGNUP,
  formValues,
});
export const agentSignUpLoading = () => ({
  type: AT.AGENT_SIGNUP_LOADING,
});
export const agentSignUpSuccess = (data) => ({
  type: AT.AGENT_SIGNUP_SUCCESS,
  data,
});
export const agentSignUpFailure = (error) => ({
  type: AT.AGENT_SIGNUP_FAILURE,
  error,
});
export const agentSignUpResetError = () => ({
  type: AT.AGENT_SIGNUP_RESET_ERROR,
});

export const social1 = ({ stype, uid, email, profile, afterAgree, accessToken }) => ({
  type: AT.SOCIAL1,
  stype,
  uid,
  email,
  profile,
  afterAgree,
  accessToken,
});
export const social2 = (cellphone, name, authNum) => ({
  type: AT.SOCIAL2,
  cellphone,
  name,
  authNum,
});

export const findId1 = (cellphone) => ({
  type: AT.FIND_ID_1,
  cellphone,
});

export const findId1Notasked = () => ({
  type: AT.FIND_ID_1_NOTASKED,
});

export const findId1Success = (data) => ({
  type: AT.FIND_ID_1_SUCCESS,
  data,
});

export const findId1Loading = () => ({
  type: AT.FIND_ID_1_LOADING,
});

export const findId1Failure = (error) => ({
  type: AT.FIND_ID_1_FAILURE,
  error,
});

export const findId2 = (authNum) => ({
  type: AT.FIND_ID_2,
  authNum,
});

export const findId2Notasked = () => ({
  type: AT.FIND_ID_2_NOTASKED,
});

export const findId2Success = (data) => ({
  type: AT.FIND_ID_2_SUCCESS,
  data,
});

export const findId2Loading = () => ({
  type: AT.FIND_ID_2_LOADING,
});

export const findId2Failure = (error) => ({
  type: AT.FIND_ID_2_FAILURE,
  error,
});

export const findPw = ({ email, options }) => ({
  type: AT.FIND_PW,
  email,
  options,
});

export const findPwNotasked = () => ({
  type: AT.FIND_PW_NOTASKED,
});

export const findPwSuccess = (data) => ({
  type: AT.FIND_PW_SUCCESS,
  data,
});

export const findPwLoading = () => ({
  type: AT.FIND_PW_LOADING,
});

export const findPwFailure = (error) => ({
  type: AT.FIND_PW_FAILURE,
  error,
});

export const changePw = (newPw, setLoadingStatus, callback) => ({
  type: AT.CHANGE_PW,
  newPw,
  setLoadingStatus,
  callback,
});

export const changePwNotasked = () => ({
  type: AT.CHANGE_PW_NOTASKED,
});

export const changePwSuccess = (data) => ({
  type: AT.CHANGE_PW_SUCCESS,
  data,
});

export const changePwLoading = () => ({
  type: AT.CHANGE_PW_LOADING,
});

export const changePwFailure = (error) => ({
  type: AT.CHANGE_PW_FAILURE,
  error,
});

export const setAppInfo = (data) => ({
  type: AT.SET_APP_INFO,
  data,
});

//STATE 업데이트
export const toggleAlarmOn = () => ({
  type: AT.TOGGLE_ALARM_ON,
});

//회원탈퇴
export const byeDawin = () => ({
  type: AT.BYE_DAWIN,
});

export const appSetting = () => ({
  type: AT.APP_SETTING,
});

export const disconnectNaver = (accessToken, closeThisWindow) => ({
  type: AT.DISCONNECT_NAVER,
  accessToken,
  closeThisWindow,
});

export const getNickname = (setStatus) => ({
  type: AT.GET_NICK_NAME,
  setStatus,
});

export const setNickname = (nickname) => ({
  type: AT.SET_NICK_NAME,
  nickname,
});

export const saveNickname = (nickname, setStatus, callback) => ({
  type: AT.SAVE_NICK_NAME,
  nickname,
  setStatus,
  callback,
});

export const setMarketingUserInfo = (payload) => ({
  type: AT.SET_MARKETING_USER_INFO,
  payload,
});

export const isSignUpUser = (payload) => ({
  type: AT.IS_SIGN_UP_USER,
  payload,
});

export const phoneNumberAuthOnly = (value) => ({
  type: AT.PHONE_NUMBER_AUTH_ONLY,
  value,
});
