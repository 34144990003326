import { loading, success, failure } from '@/data/utils';
import * as detail from './detail/actionTypes';

export { detail };

export const SET_ERROR = 'SET_ERROR';

export const SET_LIST_WIDE = 'SET_LIST_WIDE';
export const SET_STEP = 'SET_STEP';
export const SET_STEP_SUCCESS = success('SET_STEP');

export const SEARCH = 'SEARCH';
export const SEARCH_LOADING = loading(SEARCH);
export const SEARCH_SUCCESS = success(SEARCH);
export const SEARCH_FAILURE = failure(SEARCH);
export const SEARCH_GOODS = 'SEARCH_GOODS';
export const SEARCH_GOODS_LOADING = loading(SEARCH_GOODS);
export const SEARCH_GOODS_SUCCESS = success(SEARCH_GOODS);
export const SEARCH_GOODS_FAILURE = failure(SEARCH_GOODS);

export const SEARCH_FAV_COMPLEX_UPDATE = 'SEARCH_FAV_COMPLEX_UPDATE';

export const GET_NEW_HIGH_LIST = 'GET_NEW_HIGH_LIST';
export const SET_NEW_HIGH_LIST = 'SET_NEW_HIGH_LIST';

export const SET_HISTORY_LIST = 'SET_HISTORY_LIST';

export const GET_CHART = 'GET_CHART';
export const GET_CHARTS = 'GET_CHARTS';

export const CHANGE_MAP_MARKER_TYPE = 'CHANGE_MAP_MARKER_TYPE';
export const SHOW_POSITIVE_MARKER = 'SHOW_POSITIVE_MARKER';
export const CHANGE_MAP_STATE = 'CHANGE_MAP_STATE';
export const CHANGE_MAP_ZOOMLEVEL = 'CHANGE_MAP_ZOOMLEVEL';
export const CHANGE_MAP_BOUNDS = 'CHANGE_MAP_BOUNDS';
export const CHANGE_REGION_CODE = 'CHANGE_REGION_CODE';
export const MOVE_CENTER = 'MOVE_CENTER';

export const SELECT_ITEM = 'SELECT_ITEM';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';

export const TOGGLE_MAPLAYER_LIST = 'TOGGLE_MAPLAYER_LIST';

export const TOGGLE_SURROUNDING_LIST = 'TOGGLE_SURROUNDING_LIST';
export const TOGGLE_SURROUNDING_ITEM = 'TOGGLE_SURROUNDING_ITEM';
export const SET_SURROUNDING_ITEM = 'SET_SURROUNDING_ITEM';
export const RELOAD_SURROUNDINGS = 'RELOAD_SURROUNDINGS';
export const RELOAD_SURROUNDINGS_SUCCESS = success(RELOAD_SURROUNDINGS);

export const CHANGE_SEARCH_WORD = 'CHANGE_SEARCH_WORD';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const CHANGE_DEALTYPE = 'CHANGE_DEALTYPE';
export const INITIALIZE_DEALTYPE = 'INITIALIZE_DEALTYPE';
export const CHANGE_TRADETYPE = 'CHANGE_TRADETYPE';
export const INITIALIZE_TRADETYPE = 'INITIALIZE_TRADETYPE';
export const CHANGE_AREASIZE = 'CHANGE_AREASIZE';
export const INITIALIZE_AREASIZE = 'INITIALIZE_AREASIZE';
export const CHANGE_PRICE_RANGE = 'CHANGE_PRICE_RANGE'; // 검색필터 > 가격 범위
export const CHANGE_CNT_RANGE = 'CHANGE_CNT_RANGE'; // 검색필터 > 세대수 범위
export const CHANGE_YEAR_RANGE = 'CHANGE_YEAR_RANGE'; // 검색필터 > 준공년도 범위
export const REFRESH_SEARCH_CONDITION = 'REFRESH_SEARCH_CONDITION';
export const SET_SIZEMARK_TYPE = 'SET_SIZEMARK_TYPE';

export const SELECT_SURR_ITEM = 'SELECT_SURR_ITEM';
export const CARD_MOUSEOVER = 'CARD_MOUSEOVER';

export const NAVER_CALL_DISABLED = 'NAVER_CALL_DISABLED';
export const NAVER_CALL_ENABLED = 'NAVER_CALL_ENABLED';
export const INCREMENT_PROXY_INDEX = 'INCREMENT_PROXY_INDEX';

export const SET_SELECTED_EX_GOODS_DETAIL = 'SET_SELECTED_EX_GOODS_DETAIL';

export const SHOW_PAGE_DESCRIPTION = 'BUYING/SHOW_PAGE_DESCRIPTION';

// 단지 상세보기 모드 (단지상세, 매물리스트)
export const SET_COMPLEX_DETAIL_MODE = 'SET_COMPLEX_DETAIL_MODE';

// 지도 > 다윈 중개사
export const SET_SELECTED_DAWIN_BROKER = 'SET_SELECTED_DAWIN_BROKER';

// 일반유저의 매물 신고
export const REPORT_GOOD = 'REPORT_GOOD';

// 중심 동코드가 바뀔때 마다 해당동을 중개하는 중개사 리스트 조회
export const GET_THIS_DONG_REALTOR_LIST = 'GET_THIS_DONG_REALTOR_LIST';
export const SET_THIS_DONG_REALTOR_LIST = 'SET_THIS_DONG_REALTOR_LIST';
export const RESET_THIS_DONG_REALTOR_LIST = 'RESET_THIS_DONG_REALTOR_LIST';

export const GET_REALTORLIST_ON_MAP = 'GET_REALTORLIST_ON_MAP';
