import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { actions, selectors, actionTypes as AT } from '@/data';
import classNames from 'classnames';
import KakaoLogin from '@/pages/Login/BasicForm/Kakao';
import NaverLogin from '@/pages/Login/BasicForm/Naver';
import AppleLogin from '@/pages/Login/BasicForm/Apple';

const Step4 = ({ data, isBusy }) => {
  return (
    <section id="container" className="container">
      <div className="sub_container">
        <div className="cont_wrap">
          <div className="vt-area">
            <form>
              <div className="ntc_txt">
                <p className="ntc_p">
                  휴대폰번호로 최초 로그인시에는
                  <br />
                  소셜로그인을 해주셔야 합니다.
                </p>
              </div>
              <div className="btn-group easylogin">
                <NaverLogin data={data} />
                <KakaoLogin data={data} />
                <AppleLogin data={data} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step4;
