import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from '@/data';

import NavWithTitle from './NavWithTitle';
import NavWithTitle2 from './NavWithTitle2';
import NavWithAlarm from './NavWithAlarm';
import NavOnlyTitle from './NavOnlyTitle';
import NavNoBackground from './NavNoBackground';
import NavWithClose from './NavWithClose';
import text from '@/resources/text';
import AGENT_CONSTANTS from '@/pages/AgentSignUp/constants';
import NavWithAlram2 from './NavWithAlarm2';

// DawinLayout 사용하는 경우만
const MobileNav = ({ user, type = '', title }) => {
  let { stepId, option } = useParams();

  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);

  const isApp = osTypeInstance?.isApp;

  if (
    type === 'Home' ||
    type === 'Login' ||
    type === 'NaverAppCallback' ||
    type === 'NewSale' ||
    type === 'Reconstruct' ||
    type === 'BrokerDetail' ||
    type.indexOf('Analysis') > -1
  ) {
    return false; //없음
  } else if (type === 'AboutUs') {
    return <NavNoBackground />; //  jy _ 수정
  } else if (type == 'AddPositives') {
    return <NavWithTitle title={text.analysisText.ANALYSIS_POSITIVES} />; // jy _ 개발호재 구분
  } else if (type == 'WeeklyPrice') {
    return <NavWithTitle title={text.analysisText.ANALYSIS_WEEKLY_PRICE} />; // jy _ 주간실거래가 구분
  } else if (type == 'RankingResult') {
    return false; // jy _ 랭킹 결과 구분   // 자체 header RankingResult.js 안에 구현되어 있음
  } else if (type.indexOf('Selling') > -1) {
    // 집내놓기 메인
    if (type === 'Selling_Main') return <NavNoBackground user={user} goBackTo="/" />;
    // 전화로 집내놓기
    if (type === 'Selling_Phone') return <NavNoBackground user={user} />;
  } else if (type === 'MyPage') {
    return <NavWithAlarm user={user} />;
  } else if (type === 'Rating') {
    let t = '중개평가';
    return <NavOnlyTitle title={t} />;
  } else if (type === 'RegQNA') {
    let t = '문의하기';
    return <NavOnlyTitle title={t} />;
  } else if (type.indexOf('Modify') > -1) {
    let t = '매물정보수정';
    return <NavOnlyTitle title={t} />;
  } else if (type.indexOf('AgentSignUp') > -1) {
    if (type === 'AgentSignUp_Academy') {
      return <NavNoBackground />;
    } else if (type.indexOf('Step') > -1) {
      return <NavWithTitle title={'중개사 회원가입'} />;
    } else if (type.indexOf('BkPee') > -1) {
      return <NavWithTitle title={'중개보수요율표'} />;
    } else if (stepId == AGENT_CONSTANTS.AGENT_SIGNUP_SHARE_HOME) {
      return <NavNoBackground title={'중개사 회원가입'} goBackTo="/agentSignUp" />;
    } else if (type === 'AgentSignUpMainStar' || type === 'AgentSignUpMainStartup') {
      return <NavNoBackground />;
    } else if (!stepId) {
      const goBackTo = isApp && !isLoggedIn ? null : '/';
      return <NavNoBackground goBackTo={goBackTo} />;
    } else {
      return <NavWithTitle title={''} type={type} />;
    }
  } else if (type.indexOf('Helper') > -1) {
    if (stepId != '2') {
      return <NavWithTitle title="FAQ" />;
    } else {
      return <NavWithTitle title="문의하기" />;
    }
  } else if (type === 'FindId') {
    return <NavWithTitle title="아이디찾기" />;
  } else if (type === 'FindPw') {
    return <NavWithTitle title="비밀번호찾기" />;
  } else if (type === 'NewPassword') {
    return <NavWithTitle title="비밀번호 재설정" />;
  } else if (type === 'ReportBroker') {
    return <NavWithTitle title="중개사 신고하기" />;
  } else if (type === 'EmailResult') {
    return <NavWithClose title="비밀번호 재설정" />;
  } else if (type === 'News') {
    return <NavWithTitle title="부동산뉴스" />;
  } else if (type === 'Setting') {
    return <NavWithClose title="설정" />;
  } else if (type === 'My') {
    return <NavWithTitle2 title={title} />;
  } else if (type === 'MySell') {
    return <NavWithAlram2 />;
  } else {
    return false;
  }
};

export default MobileNav;
