import toggle from '@/hocs/toggle';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '@/data/utils';
import { getBestComplexByDate } from '@/data/analysis/services';
import Spinner from '@/components/Spinner';
import WeeklyBestCard from './components/WeeklyBestCard';
import Mask from '@/components/Mask';
import { actions, selectors } from '@/data';
import router from '@/services/router';

const WeekendPush = (show) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ status: STATUS.NOTASKED });
  const fav_complex_list = useSelector(selectors.common.getTempFavCompList); //리로드하기 전 관심단지 상태 변화 표시용

  useEffect(() => {
    getBestComplexByDate(payload, setData);
  }, []);

  const queryDate = router.querystring.parse().queryDate;
  const today = dayjs(queryDate) ?? dayjs().format('YYYY-MM-DD');

  function calcDate(d) {
    let day = d.get('d');
    let from, to;
    if (day === 0) {
      from = d.add(-6, 'd').format('YYYY-MM-DD');
      to = d.format('YYYY-MM-DD');
    } else {
      from = d
        .day(0)
        .add(1, 'd')
        .format('YYYY-MM-DD');
      to = d
        .day(6)
        .add(1, 'd')
        .format('YYYY-MM-DD');
    }

    return {
      from,
      to,
    };
  }

  const payload = {
    from: calcDate(today).from,
    to: calcDate(today).to,
  };

  const List = useMemo(() => {
    const status = data.status;
    const result = data.result;
    if (status === STATUS.SUCCESS) {
      return result.map((result) => {
        return <WeeklyBestCard result={result} key={result.name} fav_complex_list={fav_complex_list} />;
      });
    } else if (status === STATUS.SUCCESS) {
      return <div style={{ textAlign: 'center', padding: '10px', fontSize: '15px' }}>정보 없음</div>;
    } else if (status === STATUS.FAILURE) {
      return <div>죄송합니다. 데이터 조회 중 오류가 발생하였습니다.</div>;
    } else if (status === STATUS.LOADING) {
      return <Spinner show={true} />;
    }
  }, [data.result, fav_complex_list]);

  const close = () => {
    dispatch(actions.router.replace());
  };

  return (
    <div style={{ position: 'fixed', zIndex: 1001, width: '100%', height: '100%', top: 0, left: 0 }}>
      <Mask setComponentShow={close} />

      <div className="addview weekend_review" id={'weekend_review'} style={{ zIndex: 1000 }}>
        <header className="header_type01">
          <h2 className="title">이번주 최고 아파트</h2>
          <span className="btn-left" onClick={close}>
            <a className="btn-img btn-close-wh">닫기</a>
          </span>
        </header>

        <div id="container" className="container">
          <div className="list_summary">
            ({dayjs(calcDate(today).from).format('YYYY.MM.DD')}~{dayjs(calcDate(today).to).format('YYYY.MM.DD')})
          </div>
          <div>{List}</div>
        </div>
      </div>
    </div>
  );
};

export default toggle(WeekendPush);
