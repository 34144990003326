import React from 'react';
import AddressSelect from './AddressSelect';
import ComplexSelect from './ComplexSelect';

const SelectSearchView = ({
  addressData,
  setAddressData,
  addAllOption,
  disabled,
  tenDigitCode,
  dongCode,
  complexData,
  setComplexData,
  markTypes,
  preventUpdateComplexData,
  brokerNumber,
  setBrokerNumber,
  feedType,
}) => {
  return (
    <fieldset className="submit-form module-a type-c medium" id="tabContents_02">
      <div className="form-list">
        <AddressSelect
          data={addressData}
          setData={setAddressData}
          addAllOptionOnDong={addAllOption}
          disabled={disabled}
          tenDigitCode={tenDigitCode}
        />
        <ComplexSelect
          selectedIdx={`${complexData?.complex_idx}`}
          dongCode={dongCode}
          setData={setComplexData}
          markTypes={markTypes}
          addAllOption={addAllOption}
          preventUpdateComplexData={preventUpdateComplexData}
          disabled={disabled}
          addressData={addressData}
          brokerNumber={brokerNumber}
          setBrokerNumber={setBrokerNumber}
          feedType={feedType}
        />
      </div>
    </fieldset>
  );
};

export default SelectSearchView;
