import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { STEPS } from '@/pages/Login/BasicForm/helpers';

import policyService from '@/services/policy';
import routerService from '@/services/router';

const Agree = () => {
  const dispatch = useDispatch();

  const [checkList, setCheckList] = useState([]);

  const nextAction = useSelector(selectors.auth.getNextAction);
  const marketingUserInfo = useSelector(selectors.auth.getMarketingUserInfo);

  const allChecked =
    checkList.indexOf('agree01') > -1 && checkList.indexOf('agree02') > -1 && checkList.indexOf('agree03') > -1 && checkList.indexOf('agree04') > -1;

  const handleSubmit = () => {
    if (
      checkList.indexOf('agree01') > -1 &&
      checkList.indexOf('agree02') > -1 &&
      checkList.indexOf('agree03') > -1 &&
      checkList.indexOf('agree04') > -1
    ) {
      dispatch(nextAction());
      // 회원가입 유저라는 상태 정보 저장
      dispatch(actions.auth.isSignUpUser());
      // signUpProcessing: 마케팅 유입 유저 중 얼마나 회원가입 전환하는지 확인을 위한 flag
      if (marketingUserInfo?.isMarketing) {
        dispatch(actions.auth.setMarketingUserInfo({ ...marketingUserInfo, signUpProcessing: true }));
      }
    } else {
      dispatch(actions.common.alert({ contents: '이용약관에 모두 동의해주세요.' }));
    }
  };

  const checkAll = () => {
    setCheckList(['agree01', 'agree02', 'agree03', 'agree04']);
  };

  const checkItem = (e) => {
    const value = e.target.value;
    if (checkList.indexOf(value) > -1) {
      const temp = checkList.filter((e) => e !== value);
      setCheckList(temp);
    } else {
      setCheckList([...checkList, value]);
    }
  };

  const onClickPolicyDetail = (e) => {
    e.preventDefault();
    const type = e.target.getAttribute('data-id');
    dispatch(routerService.replace(routerService.querystring.add('terms', type)));
  };

  return (
    <>
      <div className="popup-local-body">
        <div className="popup-content">
          <form className="popup-content-body">
            <div className="info-board module-b style-a type-c">
              <div className="board-wrap">
                <div className="board-head">
                  <p className="board-subject">
                    <span className="wbr">환영합니다!</span>
                  </p>
                  <p className="board-summary">
                    <span className="wbr">다윈중개 서비스 이용약관에 동의해주세요.</span>
                  </p>
                </div>
              </div>
            </div>

            <fieldset className="option-display module-b type-c">
              <legend>이용약관 동의 서식</legend>
              {/* <!-- button-display --> */}
              <div className="button-display module-a style-a type-d">
                <span className="button-area">
                  {/* <button type="button" className="btn module-a style-b type-line large-2x normal-02 flex symbol-ltr-check-fill-round"><span className="btn-text">모두 동의합니다.</span></button> */}
                  <button
                    type="button"
                    className="btn module-a style-b type-fill large-2x accent-01 flex symbol-ltr-check-fill-round"
                    onClick={checkAll}>
                    <span className="btn-text">모두 동의합니다.</span>
                  </button>
                </span>
              </div>
              {/* <!-- //button-display --> */}
              <div className="option-list">
                <div className="option-item">
                  <span className="option-wrap">
                    <span className="option-head">
                      <span className="option-name">
                        <label className="form checkbox module-a style-c type-fill accent-01 small-1x">
                          <input
                            type="checkbox"
                            className="form-elem"
                            name="agree"
                            id="agree01"
                            value="agree01"
                            checked={checkList.indexOf('agree01') > -1}
                            onChange={checkItem}
                          />
                          <span className="form-text">
                            만 14세 이상입니다. <span className="em accent-01">[필수]</span>
                          </span>
                        </label>
                      </span>
                    </span>
                  </span>
                </div>
                <div className="option-item">
                  <span className="option-wrap">
                    <span className="option-head">
                      <span className="option-name">
                        <label className="form checkbox module-a style-c type-fill accent-01 small-1x">
                          <input
                            type="checkbox"
                            className="form-elem"
                            name="agree"
                            id="agree02"
                            value="agree02"
                            checked={checkList.indexOf('agree02') > -1}
                            onChange={checkItem}
                          />
                          <span className="form-text">
                            이용약관 동의 <span className="em accent-01">[필수]</span>
                          </span>
                        </label>
                      </span>
                      <span className="option-util">
                        <a className="em normal-03 small-2x" onClick={onClickPolicyDetail} data-id={policyService.types.TermsOfUse}>
                          보기
                        </a>
                      </span>
                    </span>
                  </span>
                </div>
                <div className="option-item">
                  <span className="option-wrap">
                    <span className="option-head">
                      <span className="option-name">
                        <label className="form checkbox module-a style-c type-fill accent-01 small-1x">
                          <input
                            type="checkbox"
                            className="form-elem"
                            name="agree"
                            id="agree03"
                            value="agree03"
                            checked={checkList.indexOf('agree03') > -1}
                            onChange={checkItem}
                          />
                          <span className="form-text">
                            개인정보 처리방침 동의 <span className="em accent-01">[필수]</span>
                          </span>
                        </label>
                      </span>
                      <span className="option-util">
                        <a className="em normal-03 small-2x" onClick={onClickPolicyDetail} data-id={policyService.types.PrivacyPolicy}>
                          보기
                        </a>
                      </span>
                    </span>
                    <span className="option-body">
                      <span className="form-docs">
                        - 목적 : 개인식별, 부동산중개서비스 이용을 위한 연락 목적
                        <br />
                        - 항목: 이름, 이메일주소, 휴대폰번호
                        <br />
                        - 보유기간 : 회원탈퇴 시 즉시 파기 <br />* 단, 제공 서비스별 관계법령이 요구하는 기간동안 보관
                      </span>
                    </span>
                  </span>
                </div>
                <div className="option-item">
                  <span className="option-wrap">
                    <span className="option-head">
                      <span className="option-name">
                        <label className="form checkbox module-a style-c type-fill accent-01 small-1x">
                          <input
                            type="checkbox"
                            className="form-elem"
                            name="agree"
                            id="agree04"
                            value="agree04"
                            checked={checkList.indexOf('agree04') > -1}
                            onChange={checkItem}
                          />
                          <span className="form-text">
                            위치정보사용동의 <span className="em accent-01">[필수]</span>
                          </span>
                        </label>
                      </span>
                      <span className="option-util">
                        <a className="em normal-03 small-2x" onClick={onClickPolicyDetail} data-id={policyService.types.LocationBasedServiceTerm}>
                          보기
                        </a>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div className="popup-local-util">
        <div className="button-display module-a style-a type-d">
          <span className="button-area">
            <button type="submit" className="btn module-b style-a type-fill large-3x normal-02 flex" onClick={handleSubmit} disabled={!allChecked}>
              <span className="btn-text">시작하기</span>
            </button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Agree;

// <ContentsPopup_policyTermsService />
// <ContentsPopup_policyPrivacy />
// <ContentsPopup_policyLocation />
