import * as AT from './actionTypes';
import * as brokerOfficeInfo from './brokerOfficeInfo/actions';
import * as bkQnA from './bkQnA/actions';
import * as jointBrokerage from './jointBrokerage/actions';
import * as mgCounseling from './mgCounseling/actions';
import * as mgCustomer from './mgCustomer/actions';
import * as mgGoods from './mgGoods/actions';
import * as mgContract from './mgContract/actions';
import * as mgQa from './mgQa/actions';
import * as mgRate from './mgRate/actions';
import * as mgComplexImg from './mgComplexImg/actions';
import * as dataRoom from './dataRoom/actions';
import * as suggestion from './suggestion/actions';
import * as notice from './notice/actions';
import * as internalNotice from './internalNotice/actions';
import * as oneOnOne from './oneOnOne/actions';
import * as setArea from './setArea/actions';
import * as dailyReport from './dailyReport/actions';
import * as community from './community/actions';

export { brokerOfficeInfo };
export { bkQnA };
export { jointBrokerage };
export { mgCounseling };
export { mgCustomer };
export { mgGoods };
export { mgContract };
export { mgQa };
export { mgRate };
export { mgComplexImg };
export { dataRoom };
export { suggestion };
export { notice };
export { internalNotice };
export { oneOnOne };
export { setArea };
export { dailyReport };
export { community };

export const brokerPageRequestError = ({ error }) => ({
  type: AT.BROKER_PAGE_REQUEST_ERROR,
  payload: {
    error,
  },
});

//=====================================alarm======================================
export const getBrokerAlarmList = (broker_office_idx) => ({
  type: AT.GET_BROKER_ALARM_LIST,
  broker_office_idx,
});
export const setBrokerAlarmList = (brokerAlarmList) => ({
  type: AT.SET_BROKER_ALARM_LIST,
  brokerAlarmList,
});
export const deleteAlarm = (broker_office_idx, goods_idx) => ({
  type: AT.DELETE_ALARM,
  broker_office_idx,
  goods_idx,
});
export const readAlarm = (broker_office_idx, goods_idx) => ({
  type: AT.DELETE_ALARM,
  broker_office_idx,
  goods_idx,
});
export const deleteRAAlarm = (broker_office_idx, read) => ({
  type: AT.DELETE_RA_ALARM,
  broker_office_idx,
  read,
});

export const getBoardNew = () => ({
  type: AT.GET_BROKER_BOARD_NEW,
});
export const setBoardNew = (board_new) => ({
  type: AT.SET_BOARD_NEW,
  board_new,
});
export const setOneonOnenew = () => ({
  type: AT.SET_ONEONEONE_NEW,
});
