import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';
import CONSTANTS from '../../pages/RegQNA/constants';

// ===================== post ===================== //
export function* postQNA() {
  try {
    // @todo 확인필요 : 잘못된 접근이라는 메시지 받음
    const typeTxt = 'inqeury';
    // const typeTxt = 'inquiry'
    const payload = {
      type: typeTxt,
      category: CONSTANTS.REGQNA_CODES[yield select(selectors.getRegQNACategoryIdx)],
      title: yield select(selectors.getRegQNATitle),
      contents: yield select(selectors.getRegQNABody),
    };

    yield call(apis.regQNAApi.postQNA, payload);
  } catch (e) {
    yield put(actions.regQNARequestError({ error: text.regQNAText.POST_ERROR_TEXT }));
  }
}

// @todo [2] 중개사에게 문의는 어디서 하는건지 모르겠음 (마이페이지에 1:1 문의 페이지 하단에는 1:1문의하기 버튼만 있는 것으로 보임)
// export function * postQNAAgent() {
//     try {
//         const payload = {
//             type : 'broker-office',
//             category : CONSTANTS.REGQNA_CODES[yield select(selectors.getRegQNACategoryIdx)],
//             title : yield select(selectors.getRegQNATitle),
//             contents : yield select(selectors.getRegQNABody)
//         }

//         yield call(apis.regQNAApi.postQNA, payload);

//     } catch (e) {
//         yield put(actions.regQNARequestError({error: text.regQNAText.POST_ERROR_TEXT}));
//     }
// }
