import { loading, success, failure } from '@/data/utils';

//자료실 - 서식및 양식
export const SET_DR_FORM_KEYWORD = 'BROKERPAGE/SET_DR_FORM_KEYWORD';
export const SET_DR_FORM_CURRENT_PAGE = 'BROKERPAGE/SET_DR_FORM_CURRENT_PAGE';
export const SET_DR_FORM_TOTAL_CNT = 'BROKERPAGE/SET_DR_FORM_TOTAL_CNT';
export const SET_DR_FORM_NEW_TITLE = 'BROKERPAGE/SET_DR_FORM_NEW_TITLE';
export const SET_DR_FORM_NEW_CONT = 'BROKERPAGE/SET_DR_FORM_NEW_CONT';
export const SET_DR_FORM_NEW_ATTACH = 'BROKERPAGE/SET_DR_FORM_NEW_ATTACH';

export const GET_DR_FORM_LIST = 'BROKERPAGE/GET_DR_FORM_LIST';
export const GET_DR_FORM_LIST_LOADING = loading(GET_DR_FORM_LIST);
export const GET_DR_FORM_LIST_SUCCESS = success(GET_DR_FORM_LIST);
export const GET_DR_FORM_LIST_FAILURE = failure(GET_DR_FORM_LIST);

export const GET_DR_FORM_DETAIL = 'BROKERPAGE/GET_DR_FORM_DETAIL';
export const GET_DR_FORM_DETAIL_LOADING = loading(GET_DR_FORM_DETAIL);
export const GET_DR_FORM_DETAIL_SUCCESS = success(GET_DR_FORM_DETAIL);
export const GET_DR_FORM_DETAIL_FAILURE = failure(GET_DR_FORM_DETAIL);

//자료실 - 관련판례
export const SET_DR_PRECEDENT_KEYWORD = 'BROKERPAGE/SET_DR_PRECEDENT_KEYWORD';
export const SET_DR_PRECEDENT_CURRENT_PAGE = 'BROKERPAGE/SET_DR_PRECEDENT_CURRENT_PAGE';
export const SET_DR_PRECEDENT_TOTAL_CNT = 'BROKERPAGE/SET_DR_PRECEDENT_TOTAL_CNT';
export const SET_DR_PRECEDENT_NEW_TITLE = 'BROKERPAGE/SET_DR_PRECEDENT_NEW_TITLE';
export const SET_DR_PRECEDENT_NEW_CONT = 'BROKERPAGE/SET_DR_PRECEDENT_NEW_CONT';
export const SET_DR_PRECEDENT_NEW_ATTACH = 'BROKERPAGE/SET_DR_PRECEDENT_NEW_ATTACH';

export const GET_DR_PRECEDENT_LIST = 'BROKERPAGE/GET_DR_PRECEDENT_LIST';
export const GET_DR_PRECEDENT_LIST_LOADING = loading(GET_DR_PRECEDENT_LIST);
export const GET_DR_PRECEDENT_LIST_SUCCESS = success(GET_DR_PRECEDENT_LIST);
export const GET_DR_PRECEDENT_LIST_FAILURE = failure(GET_DR_PRECEDENT_LIST);

export const GET_DR_PRECEDENT_DETAIL = 'BROKERPAGE/GET_DR_PRECEDENT_DETAIL';
export const GET_DR_PRECEDENT_DETAIL_LOADING = loading(GET_DR_PRECEDENT_DETAIL);
export const GET_DR_PRECEDENT_DETAIL_SUCCESS = success(GET_DR_PRECEDENT_DETAIL);
export const GET_DR_PRECEDENT_DETAIL_FAILURE = failure(GET_DR_FORM_DETAIL);

//자료실 - 유용한사이트
export const SET_DR_USEFUL_KEYWORD = 'BROKERPAGE/SET_DR_USEFUL_KEYWORD';
export const SET_DR_USEFUL_CURRENT_PAGE = 'BROKERPAGE/SET_DR_USEFUL_CURRENT_PAGE';
export const SET_DR_USEFUL_TOTAL_CNT = 'BROKERPAGE/SET_DR_USEFUL_TOTAL_CNT';
export const SET_DR_USEFUL_NEW_TITLE = 'BROKERPAGE/SET_DR_USEFUL_NEW_TITLE';
export const SET_DR_USEFUL_NEW_CONT = 'BROKERPAGE/SET_DR_USEFUL_NEW_CONT';
export const SET_DR_USEFUL_NEW_ATTACH = 'BROKERPAGE/SET_DR_USEFUL_NEW_ATTACH';

export const GET_DR_USEFUL_LIST = 'BROKERPAGE/GET_DR_USEFUL_LIST';
export const GET_DR_USEFUL_LIST_LOADING = loading(GET_DR_USEFUL_LIST);
export const GET_DR_USEFUL_LIST_SUCCESS = success(GET_DR_USEFUL_LIST);
export const GET_DR_USEFUL_LIST_FAILURE = failure(GET_DR_USEFUL_LIST);

export const GET_DR_USEFUL_DETAIL = 'BROKERPAGE/GET_DR_USEFUL_DETAIL';
export const GET_DR_USEFUL_DETAIL_LOADING = loading(GET_DR_USEFUL_DETAIL);
export const GET_DR_USEFUL_DETAIL_SUCCESS = success(GET_DR_USEFUL_DETAIL);
export const GET_DR_USEFUL_DETAIL_FAILURE = failure(GET_DR_USEFUL_DETAIL);
