import { useDispatch } from 'react-redux';
import { showUserHistory } from '@/combinedComp/Community/helpers';

const useUserPopup = () => {
  const dispatch = useDispatch();

  const viewUserDetail = (e, id, writer, type) => {
    e.preventDefault(e);
    dispatch(showUserHistory({ id, writer, type }));
  };

  return viewUserDetail;
};

export default useUserPopup;
