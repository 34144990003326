import React from 'react';

const Paragraph = ({ values = [] }) => {
  return (
    <>
      <div className="board-body">
        <p className="para">
          {values.map((item, index) => (
            <span key={index} className="wbr">
              {item}
            </span>
          ))}
        </p>
      </div>
    </>
  );
};

export default Paragraph;
