import { useEffect } from 'react';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';

const useShowLoginPopupOnLoginUrl = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectors.common.isMobile);
  const isLoginUrl = location.pathname.indexOf('/login') === 0;

  useEffect(() => {
    if (!isMobile && isLoginUrl) {
      // 최선인지?
      dispatch(actions.common.toggleLoginPopup(true));
    }
  }, []);

  // const user = useSelector(selectors.auth.getUserInfo);
  // useEffect(() => {
  //   // 로그인 한 유저는 로그인 팝업 닫기
  //   if (user) {
  //     dispatch(actions.common.toggleLoginPopup(false));
  //   }
  // }, [user]);

  return;
};

export default useShowLoginPopupOnLoginUrl;
