import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import apis from '@/apis';

export function* mgCustomerList() {
  try {
    const mgstate = yield select(selectors.mgCustomer.getMgCustomer);
    console.log(mgstate);
    const payload = {
      keyword: mgstate.keyword ? mgstate.keyword : '',
    };
    //yield put(actions.getCustomerListLoading());
    const response = yield call(apis.brokerPageApi.mgCustomerList, payload);
    const list = response.rltObj;
    yield put(actions.getCustomerListSuccess(list));
  } catch (e) {
    yield put(actions.getCustomerListFailure({ error: '잘못됐음1.' }));
  }
}
//선택한거 상담 내역
export function* getSelCustom({ item }) {
  try {
    if (!item) {
      yield put(allActions.common.alert({ contents: '회원 정보가 없습니다.' }));
      return;
    }
    const payload = {
      idx: item.idx,
    };
    yield put(actions.setselcustom(payload));
    //yield put(actions.getSelCustomerLoading());
    const response = yield call(apis.brokerPageApi.getSelCustom, payload);
    const list = response.rltObj;
    if (list && list.length > 0) {
      yield put(actions.getSelCustomerSuccess(list[0]));
    } else {
      yield put(actions.getSelCustomerSuccess(''));
    }
  } catch (e) {
    yield put(actions.getSelCustomerFailure({ error: '잘못됐음3.' }));
  }
}

//선택한거 상담 내역 수정
export function* editmemo() {
  try {
    const editmemo = yield select(selectors.mgCustomer.getEditMemo);
    const payload = {
      actiontype: 'E',
      goods_idx: editmemo.goodsIdx,
      memo_idx: editmemo.idx,
      content: editmemo.content,
    };
    const response = yield call(apis.brokerPageApi.editmemo, payload);
    const sel = yield select(selectors.mgCustomer.getSel);
    yield put(actions.getSelCustom(sel));
  } catch (e) {
    yield put(actions.getSelCustomerFailure({ error: '잘못됐음4.' }));
  }
}

//선택한거 상담 내역 삭제
export function* delmemo({ idx }) {
  try {
    const payload = {
      actiontype: 'D',
      memo_idx: idx,
    };
    const response = yield call(apis.brokerPageApi.delmemo, payload);
    const sel = yield select(selectors.mgCustomer.getSel);
    yield put(actions.getSelCustom(sel));
  } catch (e) {
    yield put(actions.getSelCustomerFailure({ error: '잘못됐음5.' }));
  }
}

//고객정보 변경
export function* editCustomerInfo() {
  try {
    const editInfo = yield select(selectors.mgCustomer.getEditCustomer);
    const payload = {
      type: 'E',
      cu_idx: editInfo.idx,
      cu_name: editInfo.cust_name,
      cu_cp: editInfo.mbr_cp,
    };
    const response = yield call(apis.brokerPageApi.editCustomerInfo, payload);
    if (response.rltObj > 0) {
      yield put(allActions.common.alert({ contents: '성공하였습니다.' }));
      yield put(actions.setKeyword(''));
      yield put(actions.getCustom());
    } else {
      yield put(allActions.common.alert({ contents: '실패하였습니다. 다시 시도해 주세요.(1)' }));
      return;
    }
  } catch (e) {
    yield put(actions.editCustormerInfoFailure({ error: '잘못됐음2.' }));
  }
}
//고객정보 삭제
export function* deleteCustomerInfo() {
  try {
    const delInfo = yield select(selectors.mgCustomer.getDelCustomer);
    const payload = {
      type: 'D',
      cu_idx: delInfo.idx,
    };

    const response = yield call(apis.brokerPageApi.delCustomerInfo, payload);
    if (response.rltObj > 0) {
      yield put(allActions.common.alert({ contents: '고객정보를 삭제하였습니다.' }));
      yield put(actions.setKeyword(''));
      yield put(actions.getCustom());
    } else {
      yield put(allActions.common.alert({ contents: '실패하였습니다. 다시 시도해 주세요.(1)' }));
      return;
    }
  } catch (e) {
    yield put(actions.editCustormerInfoFailure({ error: '잘못됐음2.' }));
  }
}

//고객정보 등록
export function* regNewCustom() {
  try {
    const newCustom = yield select(selectors.mgCustomer.getNewCustomer);
    const selcustom = yield select(selectors.mgCustomer.getSelCustom);
    const payload = {
      goods_idx: newCustom?.goods_idx,
      cu_name: newCustom.name,
      cu_cp: newCustom.cp,
      content: newCustom.content,
      cust_type: selcustom.cust_type,
    };
    const response = yield call(apis.brokerPageApi.regNewCustom, payload);
    if (response.rltObj > 0) {
      yield put(actions.setKeyword(''));
      yield put(actions.getCustom());
      const sel = yield select(selectors.mgCustomer.getSel);
      yield put(actions.getSelCustom(sel));
    } else {
      yield put(allActions.common.alert({ contents: '실패하였습니다. 다시 시도해 주세요.(2)' }));
      return;
    }
  } catch (e) {
    yield put(actions.newCustomFailure({ error: '잘못됐음3.' }));
  }
}
