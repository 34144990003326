import { apiClient, apiClient2, apiClientNoAuth } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import { stringify } from '@/services/router/querystring';

export const api = {
  async getBrokerBoardNew() {
    try {
      return await apiClient.get('/broker-my/board-new');
    } catch (e) {
      throw Error(e.message);
    }
  },
  async brokerOfficeInfo() {
    try {
      return await apiClient.get('/broker-my/broker-info');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async alarmList(broker_office_idx) {
    try {
      if (broker_office_idx != '') {
        return await apiClient.get('/broker_alaram' + objectToQuerystring(broker_office_idx));
      } else {
        return [];
      }
    } catch (e) {
      throw Error(e.message);
    }
  },
  async readAlarm(param) {
    try {
      return await apiClient.get('/broker_alaram_read' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteAlarm(param) {
    try {
      return await apiClient.get('/broker_alaram_delete' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteRAAlarm(param) {
    try {
      return await apiClient.get('/broker_alaram_read_delete' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================ my dawin start ============================
  async brokerPassChange(param) {
    try {
      const result = await apiClient.patch('/auth/password', param);
      return result;
    } catch (e) {
      throw Error(e.message);
    }
  },

  //개인정보 저장
  async brokerInfoChange(param) {
    try {
      return await apiClient.post('/broker-my/broker-info-modify', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //사무정보 저장
  async officeInfoChange(param) {
    try {
      return await apiClient.post('/broker-my/broker-office-modify', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //사무소소개 저장
  async officeIntroChange(param) {
    try {
      return await apiClient.post('/broker-my/broker-office-info-modify', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //중개사 탈퇴
  async brokerSecession(param) {
    try {
      return await apiClient.post('/auth/broker-secession', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //중개 설정한 데이터 가져오기
  async getSetArea() {
    try {
      return await apiClient.get('/broker-my/getSetArea');
    } catch (e) {
      throw Error(e.message);
    }
  },
  //중개 가능 지역 불러오기
  async getPossibleArea() {
    try {
      return await apiClient.get('/broker-my/getPossibleArea');
    } catch (e) {
      throw Error(e.message);
    }
  },
  //중개 가능 지역 불러오기
  async getBrokerAreaDistrict(param) {
    try {
      return await apiClient.get('/broker_area/district' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //중개사 중개지역
  async getBrokerDistrict() {
    try {
      return await apiClient.get('/broker_area/brokerDistrict');
    } catch (e) {
      throw Error(e.message);
    }
  },
  //중개사 중개지역
  async getBrokerDistrictChangeInfo() {
    try {
      return await apiClient.get('/broker_area/brokerDistrict/changeInfo');
    } catch (e) {
      throw Error(e.message);
    }
  },
  //활동지역삭제
  async deleteBrokerDistrict(param) {
    try {
      return await apiClient.delete('/broker_area/brokerDistrict' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //중개 가능 지역 설정하기
  // async saveActiveArea(param) {
  //   try {
  //     return await apiClient.post('/broker-my/saveActiveArea', param);
  //   } catch (e) {
  //     throw Error(e.message);
  //   }
  // },
  async saveActiveArea(param) {
    try {
      return await apiClient.put('/broker_area/brokerDistrict', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //중개지역 default 설정
  async saveSetDefaultArea() {
    try {
      return await apiClient.post('/broker-my/saveSetDefaultArea');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getBrokerAreaInfo(param) {
    try {
      return await apiClient.get(`/broker_area/addressCode${objectToQuerystring(param)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //============================ my dawin end ============================

  //============================업무관리 - 계약관리 start============================
  async getContractList(param) {
    try {
      return await apiClient.get('/broker-my/managementContract' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getContractDetail(param) {
    try {
      return await apiClient.get(`/broker-my/contract/${param.contractIdx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  ///********************************************************************************계약취소*/
  async cancelContract(param) {
    try {
      const reasonObj = { reason: param.reason };
      return await apiClient.delete(`/broker-my/contract/${param.contractIdx}` + stringify(reasonObj));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveContactUs(param) {
    try {
      return await apiClient.post(`/broker-my/contractMember/`, param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editContactUs(param, contractMemberIdx) {
    try {
      return await apiClient.put(`/broker-my/contractMember/${contractMemberIdx}`, param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteContactUs(contractMemberIdx) {
    try {
      return await apiClient.delete(`/broker-my/contractMember/${contractMemberIdx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveContractDetailInfo(param) {
    try {
      return await apiClient.post(`/broker-my/contractDetail`, param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editContractDetailInfo(param, contractDetailIdx) {
    try {
      return await apiClient.put(`/broker-my/contractDetail/${contractDetailIdx}`, param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async deleteContractDetailInfo(contractDetailIdx) {
    try {
      return await apiClient.delete(`/broker-my/contractDetail/${contractDetailIdx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  ///********************************************************************************계약삭제 */
  async deleteContract(contractIdx) {
    try {
      return await apiClient.delete(`/broker-my/contract/${contractIdx}/my`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async savePreContract(body) {
    try {
      return await apiClient.post(`/broker-my/contract`, body);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 계약관리 start============================

  //============================업무관리 - 매물관리 start============================
  async keywordSearch(param) {
    try {
      return await apiClient.get('/analysis/search' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * param 정의 https://dawinsoft.atlassian.net/wiki/spaces/DEV/pages/131268609/API
   */
  async search(param) {
    try {
      return await apiClient.get('/broker-my/managementGoods' + stringify(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getGoodsCnt(param) {
    try {
      return await apiClient.get('/broker-my/managementGoods/count' + stringify(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async searchNoAuth(param) {
    try {
      return await apiClientNoAuth.get('/broker-my/managementGoods' + stringify(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async searchNoAuthCnt(param) {
    try {
      return await apiClientNoAuth.get('/broker-my/managementGoods/count' + stringify(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async searchKeyword(param) {
    try {
      return await apiClient.get('/broker-my/managementGoods/search' + stringify(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async savebrokerComment(param) {
    try {
      return await apiClient.post('/broker-my/broker_comment', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editbrokerComment(param) {
    try {
      return await apiClient.put('/broker-my/broker_comment', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delbrokerComment(param) {
    try {
      return await apiClient.delete('/broker-my/broker_comment' + stringify(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async memo(param) {
    try {
      return await apiClient.post('/broker-my/customer_memo_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveGoodsMemo(param) {
    try {
      const goods_common_idx = param.goods_common_idx;
      const payload = {
        memo_type: param.memo_type ? param.memo_type : 'BRK',
        memo_target: param.memo_target,
        extra_contents: param.extra_contents,
        contents: param.contents,
      };
      return await apiClient.post(`/goods_common/${goods_common_idx}/memo`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editGoodsMemo(param) {
    try {
      const goods_common_idx = param.goods_common_idx;
      const goods_memo_idx = param.goods_memo_idx;
      const payload = {
        memo_type: 'BRK',
        memo_target: param.memo_target,
        extra_contents: param.extra_contents,
        contents: param.contents,
      };
      return await apiClient.put(`/goods_common/${goods_common_idx}/memo/${goods_memo_idx}`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delGoodsMemo(param) {
    try {
      return await apiClient.delete(`/goods_common/${param.goods_common_idx}/memo/${param.goods_memo_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async brokerFavorite(param) {
    try {
      return await apiClient.post(`/broker-my/favoriteGoods/${param.goods_common_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async brokerFavoriteCancel(param) {
    try {
      return await apiClient.delete(`/broker-my/favoriteGoods/${param.goods_common_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async fakeSaleReport(param) {
    try {
      return await apiClient.post('/broker-my/report_fake_goods', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getAssociatedCommunity(feed_id) {
    try {
      return await apiClient2.get(`/community-api/v1/feeds/${feed_id}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getManageGoodsDetail(param) {
    try {
      return await apiClient.get('/broker-my/goodsDetail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.data?.code || e.message);
    }
  },
  async getGoodsMemo(param) {
    try {
      return await apiClient.get('/broker-my/goodsMemo' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getGoodsContacts(goods_common_idx) {
    try {
      return await apiClient.get(`/broker-my/goods-commons/${goods_common_idx}/contacts`);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getGoodsAuthority(param) {
    try {
      return await apiClient.get('/broker-my/goodsAuthority' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getGoodsHistory(param) {
    try {
      return await apiClient.get('/broker-my/goodsChangeHistory' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getBrokerComments(param) {
    try {
      return await apiClient.get('/broker-my/broker_comment' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getCallReport(param) {
    try {
      return await apiClient.get('/broker-my/goodsContactHistory' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveContractInfo(payload) {
    try {
      return await apiClient.post('/broker-my/contract', payload);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async updateContractInfo(contractIdx, contract_info) {
    try {
      console.log('contract info: ', contract_info);
      return await apiClient.put(`/broker-my/contract/${contractIdx}`, contract_info);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveExContractInfo(param) {
    try {
      return await apiClient.post('/broker-my/external_contract', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveModifyGoods(param, goods_common_idx) {
    try {
      // console.log(param);
      // console.log(goods_common_idx);
      return await apiClient.put(`/goods_common/${goods_common_idx}/goods`, param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 매물관리 end============================

  //============================업무관리 - 상담관리 start============================
  async mgCounselingList(param) {
    try {
      return await apiClient.get('/broker-my/broker_cus_memo_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getMgCounselingGoodsList(param) {
    try {
      return await apiClient.get('/broker-my/get_goods_list_cpx' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveMGC(param) {
    try {
      return await apiClient.post('/broker-my/broker_cus_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveMGC2(param, goods_common_idx) {
    try {
      return await apiClient.post(`/goods_common/${goods_common_idx}/memo`, param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 상담관리 end============================

  //============================업무관리 - 고객관리 start============================
  async mgCustomerList(param) {
    try {
      return await apiClient.get('/broker-my/customer_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //선택한 고객 상담 내용 리스트
  async getSelCustom(param) {
    try {
      return await apiClient.get('/broker-my/customer_memo_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //메모수정하기
  async editmemo(param) {
    try {
      return await apiClient.post('/broker-my/customer_memo_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delmemo(param) {
    try {
      return await apiClient.post('/broker-my/customer_memo_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async regNewCustom(param) {
    try {
      return await apiClient.post('/broker-my/broker_cus_etc_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async editCustomerInfo(param) {
    try {
      return await apiClient.post('/broker-my/customer_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delCustomerInfo(param) {
    try {
      return await apiClient.post('/broker-my/customer_prc', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 고객관리 end============================

  //============================업무관리 - 문의관리 start============================
  async getMgQaList(param) {
    try {
      return await apiClient.get('/broker-my/broker_qa_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getQaDetail(param) {
    try {
      return await apiClient.get('/broker-my/broker_qa_detail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async regQaAnswer(param) {
    try {
      return await apiClient.post('/broker-my/broker_qa_add_answer', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editQaAnswer(param) {
    try {
      return await apiClient.post('/broker-my/broker_qa_answer_modify', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delQaAnswer(param) {
    try {
      return await apiClient.post('/broker-my/broker_qa_answer_del', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 문의관리 end============================

  //============================업무관리 - 평가관리 start============================
  async getBrEvaluation(param) {
    try {
      return await apiClient.get('/broker-my/broker_evaluation' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 평가관리 end============================

  //============================업무관리 - 매물정보관리 start============================
  async getComplexImgList(param) {
    try {
      return await apiClient.get('/complex/getComplexImage' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async uploadComplexImage(param) {
    try {
      return await apiClient.post('/complex/uploadComplexImage', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delComplexImage(param) {
    try {
      return await apiClient.post('/complex/delComplexImage', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================업무관리 - 매물정보관리 end============================

  //============================Daily Report - start============================
  async getDailyReport(param) {
    try {
      return await apiClient.get('/broker-my/getDailyReport' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveDailyReport(param) {
    try {
      return await apiClient.post('/broker-my/setDailyReport', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================Daily Report end============================

  //============================업무관리 - 단지정보관리 start============================
  async getComplexSiteDescript(param) {
    try {
      return await apiClient.get('/broker-my/getCpSiteDescript' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async addComplexSiteDescript(param) {
    try {
      return await apiClient.post('/broker-my/addCpSiteDescript', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  // async delComplexImage(param) {
  //   try {
  //     return await apiClient.get('/delComplexImage' + objectToQuerystring(param));
  //   } catch (e) {
  //     throw Error(e.message);
  //   }
  // },
  //============================업무관리 - 매물정보관리 end============================

  //============================공동중개 start============================
  async getJointBrokerageList(param) {
    try {
      return await apiClient.get('/broker-my/joint_brokerage' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getJointBrokerageDetail(param) {
    try {
      return await apiClient.get('/broker-my/joint_brokerage_detail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveJointReply(param) {
    try {
      return await apiClient.post('/broker-my/joint_brokerage_cmd_add', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveJointNew(param) {
    try {
      return await apiClient.post('/broker-my/joint_brokerage_add', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editJoint(param) {
    try {
      return await apiClient.post('/broker-my/joint_brokerage_edit', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delJoint(param) {
    try {
      return await apiClient.post('/broker-my/joint_brokerage_del', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================공동중개 end============================

  //============================중개QnA start============================
  async getbkQnAList(param) {
    try {
      return await apiClient.get('/broker-my/broker_qna' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getbkQnADetail(param) {
    try {
      return await apiClient.get('/broker-my/broker_qna_detail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async savebkQnAReply(param) {
    try {
      return await apiClient.post('/broker-my/broker_qna_cmd_add', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delbkQnAReply(param) {
    try {
      return await apiClient.post('/broker-my/broker_qna_cmd_del', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editbkQnAReply(param) {
    try {
      return await apiClient.post('/broker-my/broker_qna_cmd_edit', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async editbkQnA(param) {
    try {
      return await apiClient.post('/broker-my/broker_qna_edit', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async delbkQnA(param) {
    try {
      return await apiClient.post('/broker-my/broker_qna_del', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async savebkQnANew(param) {
    try {
      return await apiClient.post('/broker-my/broker_qna_add', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================중개QnA end============================

  //============================자료실 start============================
  async dataRoom_getList(param) {
    try {
      return await apiClient.get('/broker-my/com_board_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async dataRoom_getDetail(param) {
    try {
      return await apiClient.get('/broker-my/com_board_detail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================자료실 end============================

  //============================건의사항 start============================
  async getSuggestionList(param) {
    try {
      return await apiClient.get('/broker-my/com_board_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getSuggestionDetail(param) {
    try {
      return await apiClient.get('/broker-my/com_board_detail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveNewSuggestion(param) {
    try {
      return await apiClient.post('/broker-my/com_board_add', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //============================건의사항 end============================

  //============================1:1문의 start============================
  async getOneOnOneList(param) {
    try {
      return await apiClient.get('/broker-my/com_board_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async saveNewQna(param) {
    try {
      return await apiClient.post('/broker-my/question_board_add', param);
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================1:1문의 end============================

  //============================공지사항 start============================
  async getNoticeList(param) {
    try {
      return await apiClient.get('/broker-my/com_board_list' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getNoticeDetail(param) {
    try {
      return await apiClient.get('/broker-my/com_board_detail' + objectToQuerystring(param));
    } catch (e) {
      throw Error(e.message);
    }
  },
  //============================공지사항 end============================

  async aaaaaaaaaaa(param) {
    try {
      return await apiClient.post('/aiskodjfpoiqueopifoi', param);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //동별 단지리스트 조회
  async getComplexListWithBcfiCnt(data) {
    try {
      return await apiClient.get('/complex-facility/select-list' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //동별 중학교 리스트 조회
  async getMiddleShoolsByDong(data) {
    try {
      return await apiClient.get('/complex-facility/ms-list' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //코드 조회
  async getCode(data) {
    try {
      return await apiClient.get('/complex-facility/code' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  //동 리스트
  async getDongList(complexIdx) {
    try {
      return await apiClient.get(`/complex-facility/select-dong-list/${complexIdx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //동 리스트
  async checkComplexFacility(mbrIdx, complexIdx) {
    try {
      return await apiClient.get(`/complex-facility/check-record/${mbrIdx}/${complexIdx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /*
  async surroundingItem(keyword, category, bounds) {
    try {
      const query = encodeURI(
        "query=" + keyword + "&category_group_code=" + category + "&rect=" + bounds.join(",")
      );
      const result = await kakaoClient.get("v2/local/search/keyword.json?" + query);
      return result.data.documents;
    } catch (e) {
      throw Error(e.message);
    }
  }
  */
};
