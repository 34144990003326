import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

const INITIAL_STATE = {};

export default function addressRequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case loading(AT.UPLOAD_RESOURCES):
      return { ...state, [AT.UPLOAD_RESOURCES]: Remote.Loading };
    case success(AT.UPLOAD_RESOURCES):
      return { ...state, [AT.UPLOAD_RESOURCES]: Remote.Success(action.result) };
    case failure(AT.UPLOAD_RESOURCES):
      return { ...state, [AT.UPLOAD_RESOURCES]: Remote.Failure(action.error), error: action.error };
    default: {
      return { ...state };
    }
  }
}
