import * as AT from './actionTypes';

export const getMyMembership = () => ({
  type: AT.GET_MY_MEMBERSHIP,
});

export const setMyMembership = (data) => ({
  type: AT.SET_MY_MEMBERSHIP,
  data,
});
