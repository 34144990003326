export const codes = {
  used: 'Y',
  notused: 'N',
  dontknow: 'U',
};

const _names = {
  [codes.used]: '사용',
  [codes.notused]: '미사용',
  [codes.dontknow]: '모름',
};

export const getName = (code) => {
  return _names[code];
};

export default {
  codes,
  getName,
};
