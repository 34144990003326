import { actions, selectors } from '@/data';
import { getSpecificLocation } from '@/data/address/services';
import { routeToComplexDetail, routeToGoodsList } from '@/pages/Buying/route';
import { KorTradeType, SQUARE_METER_UNIT, TagType } from '@/services/Community';
import { useDispatch, useSelector } from 'react-redux';

const useClickBtns = ({ feedData, typeArray, complexTag, sigunguTag }) => {
  const dispatch = useDispatch();
  const saleTypeTag = feedData?.tags[typeArray.indexOf(TagType.SALE_TYPE_TAG)];
  const trade_type = KorTradeType[saleTypeTag?.key];
  const areaTag = feedData?.tags[typeArray.indexOf(TagType.AREA_TAG)];
  const pyeong_metric = areaTag?.key / SQUARE_METER_UNIT;
  const pyeong = `${Math.floor(pyeong_metric)}`;
  const isMobile = useSelector(selectors.common.isMobile);
  const initial_path = '/buying';

  const handleOtherGoods = (e) => {
    e.preventDefault();
    dispatch(routeToComplexDetail(complexTag?.key, pyeong, trade_type, { initial_path }));
    dispatch(routeToGoodsList(trade_type, pyeong, isMobile, { initial_path, d_complex: complexTag?.key }));
  };

  const handleSigungu = async (e) => {
    e.preventDefault();

    const areaCode = sigunguTag?.key;
    const result = await getSpecificLocation(areaCode);
    const lng = result?.lng;
    const lat = result?.lat;

    try {
      if (!!lng && !!lat) {
        dispatch(actions.buying.moveCenter([lat, lng]));
      }
      dispatch(actions.router.push('/buying?localInfoType=localAnalysis'));
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleComplex = (e) => {
    e.preventDefault();

    const initial_area = isNaN(pyeong) ? null : pyeong;
    dispatch(routeToComplexDetail(complexTag?.key, initial_area, trade_type, { initial_path }));
  };
  return { handleOtherGoods, handleSigungu, handleComplex };
};

export default useClickBtns;
