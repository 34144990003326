import React, { useState, useEffect } from 'react';
import ButtonSubmit from './components/ButtonSubmit';
import ButtonClose from './components/ButtonClose';
import StyleContentsPopup from '@/publish/Front/_Component/popup/ContentsPopup';
import BuiToggle from '@/assets/js/bui.toggle.js';

const DEFAULT_POPUP_ID = 'contentsPopup';
const delay = 250; // layout.front.scss > .front-popup.module-a
const DEFULT_SUBMIT_BUTTON_PROPS = { text: '확인', disabled: false, onClick: () => {}, preventDefaultClose: false };
const DEFULT_CLOSE_BUTTON_PROPS = { onClick: () => {}, preventDefaultClose: false };
/**
 * [탬플릿] 컨텐츠 팝업
 *
 * @param {string} popupId (optional) 팝업 Id | default: "contentsPopup"
 * @param {JSX} children 내용 (popup-local-body 영역부터 children에 포함됨)
 * @param {JSX | string} title 제목
 * @param {boolean} showCloseButton 닫기 버튼 영역 노출 여부 | default: true
 * @param {boolean} showSubmitButton submit 버튼 영역 노출 여부 | default: false
 * @param {function} onClose 닫기 action ({ onClick, preventDefault })
 * @param {object} submitButtonProps (optional) submit 버튼 props ({ text, disabled, onClick, preventDefaultClose })
 * @param {object} buiToggleOptions (optional) BuiToggle 객체 옵션 (@/assets/js/bui.toggle.js 참고)
 * @param {string} defaultActive (optional) mount 시 바로 active 시킬것인지 여부 | default: true
 *
 */
const TemplateContentsPopup = ({
  popupId = DEFAULT_POPUP_ID,
  children,
  title,
  showCloseButton = true,
  showSubmitButton = false,
  onClose,
  submitButtonProps = {},
  closeButtonProps = {},
  buiToggleOptions,
  defaultActive = true,
  zIndex,
}) => {
  submitButtonProps = { ...DEFULT_SUBMIT_BUTTON_PROPS, ...submitButtonProps };
  closeButtonProps = { ...DEFULT_CLOSE_BUTTON_PROPS, ...closeButtonProps };

  const [contentsPopup] = useState(
    new BuiToggle('[data-bui-toggle="contentsPopup"]', {
      reactTarget: 'html',
      reactTargetActiveClass: 'active-content-popup',
      focusin: true,
      ...buiToggleOptions,
    })
  );

  useEffect(() => {
    contentsPopup.update(popupId);

    if (defaultActive) {
      contentsPopup.active(popupId);
    }
  }, [popupId]);

  const handleClose = () => {
    if (closeButtonProps.preventDefaultClose) {
      if (onClose) onClose();
    } else {
      contentsPopup.inactive(popupId);

      setTimeout(() => {
        if (onClose) onClose();
      }, delay);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    submitButtonProps?.onClick && submitButtonProps.onClick();

    if (!submitButtonProps.preventDefaultClose) {
      handleClose();
    }
  };

  return (
    <StyleContentsPopup popupName={popupId} zIndex={zIndex}>
      <div className="popup-local">
        <div className="popup-local-head">
          <h2 className="popup-local-subject">
            <span className="popup-local-name">{title}</span>
          </h2>
        </div>

        {children}

        {/* 적용 버튼 */}
        <ButtonSubmit show={showSubmitButton} text={submitButtonProps.text} disabled={submitButtonProps.disabled} onClick={handleSubmit} />

        {/* 닫기 버튼 */}
        <ButtonClose show={showCloseButton} onClick={handleClose} />
      </div>
    </StyleContentsPopup>
  );
};

export default TemplateContentsPopup;
