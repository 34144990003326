import React from 'react';
import { commonText } from '../selling_common';
import { SERVICE_AREA } from '@/resources/text/common';

export const text = {
  COMMON_TEXT: {
    SELL_MANUAL_AVAILABLE_AREA: <>* 등록가능지역 : {SERVICE_AREA}</>,
    SELL_MANUAL_BOTTOM_CAUTION: (
      <>
        * 호수는 층을 포함한 실제 호수로 입력해주세요
        <br />
        (ex.1703호)
        <br />
      </>
    ),
    WHOLE_FLOOR: '전체층(ex.15)',
    ITEM_FLOOR: '매물있는 층(ex.5)',
    ITEM_NUMBER: '매물호수(ex.503)',
    BASIC_MODAL_TEXT: 'asdf',
  },
  STEP1_TEXT: {
    SELLER_CONFIRMATION:
      '다윈중개는 안전한 거래를 위하여 매도(임대)인 확인 절차를 거치고 있습니다.집주인 또는 세입자가 아닌 제 3 자가 등록할 경우, 매물등록이 거부됩니다.',
    OWNER_PHONE: '집주인연락처(세입자일 경우)',
    OWNER_PHONE_REASON: '* 집주인이 매물등록을 동의하였는지 확인하기 위해 필요합니다.',
    REG_TYPE_LIMIT: '* 본인 또는 세입자 이외의 대리인(법정대리인 포함)은 집내놓기 신청이 불가합니다.',
    NOT_IN_COMPLEX_LIST: <>* 검색하는 단지목록이 없을 경우, 오피스텔로 검색해주세요. (다세대, 다가구의 경우 등록이 되지 않습니다.)</>,
  },
  STEP2_TEXT: {
    OPTION_INFO: '옵션정보(복수선택가능)',
    OPTION_ETC_PLACEHOLDER: '기타선택시 입력하세요.',
  },
  STEP3_TEXT: {
    MAIN_TEXT: (
      <>
        아파트 내부와 단지 사진을 등록해주세요. 사진은 jpg, png 파일로 업로드해주세요.
        <br />
        <small className="t_red">* 사진등록은 선택사항이지만 좋은 사진을 올릴수록 빨리 거래될 확률이 높아집니다.</small>
      </>
    ),
    EXTERIOR_TEXT: '* 주출입구, 전경, 주도록/산책로, 주차장, 현관입구, 관리사무소, 놀이터 등 최대 10장까지 등록가능',
    INTERIOR_TEXT: '* 다양한 사진을 올려주실수록 매수(임차)인의 관심도가 올라갑니다.',
  },
  STEP4_TEXT: {
    MAIN_TEXT: (
      <>
        매물을 내놓을 중개사를 선택하실 수 있습니다 (이 경우 선택하신 중개사들에게만 연락처가 공개됩니다.)
        <br />
        중개사를 선택하지 않은 경우에는 인근 중개사 모두에게 연락처가 공개되며, 매물 확인 전화를 여러번 받으실 수 있습니다.
      </>
    ),
    NEARBY_ALL: <>인근 모든 중개사 자동 선택</>,
    SPECIFIC_AGENT: <>특정 중개사 선택</>,
    ADD_AGENT_BTN: '+ 중개사 추가하기',
    NO_AGENTS_SELECTED: <>등록된 중개사가 없습니다. 중개사를 등록해주세요.</>,
  },
  STEP5_TEXT: {
    MAIN_TEXT_MOBILE: (
      <>
        <big>
          <b>매물등록 신청</b>이<br />
          완료되었습니다.
        </big>
        <br />
        실소유자 확인 후 매물이 등록되며
        <br />
        SMS로 알려드립니다.
      </>
    ),
    MAIN_TEXT_WEB: (
      <>
        <big>매물등록 신청이 완료되었습니다.</big>
        <br />
        <br />
        실소유자 확인 후 사이트에 매물이 등록되며
        <br /> SMS로 알려드립니다.
        <br />
        <br />
        허위매물이 없는 클린 사이트를 위하여
        <br />
        매물정보 변경과 게약체결 여부 확인을 위해
        <br />주 1회 SMS가 발송됩니다.
      </>
    ),
  },
  STEP5_TEXT_M: {
    MAIN_TEXT_MOBILE: (
      <>
        <big>
          <b>매물 정보 수정이</b>
          <br />
          완료되었습니다.
        </big>
      </>
    ),
    MAIN_TEXT_WEB: (
      <>
        <big>매물 정보 수정이 완료되었습니다.</big>
        <br />
        <br />
        허위매물이 없는 클린 사이트를 위하여
        <br />
        매물정보 변경과 게약체결 여부 확인을 위해
        <br />주 1회 SMS가 발송됩니다.
      </>
    ),
  },
};
