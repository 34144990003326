import React from 'react';

const Input = ({ input, setInput, isLoading }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    setInput(value);
  };

  return (
    <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
      <input
        type="text"
        className="form-elem"
        id="inputNicname"
        placeholder=""
        value={input}
        onChange={handleChange}
        disabled={isLoading}
        autoComplete="off"
      />
    </span>
  );
};

export default Input;
