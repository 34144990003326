import { getGoodsDetailDirectPath } from '@/pages/Buying/route/toGoodsDetailDirect';
import getSubjectDetailApi from './getSubjectDetailApi';

// 코드
const codes = {
  USER_GOODS: 'USER_GOODS',
  COMMUNITY_POST_FOR_BUY: 'COMMUNITY_POST_FOR_BUY',
  CONSULTING: 'CONSULTING',
  GOODS: 'GOODS',
  COMMUNITY: 'COMMUNITY',
};

// 이름 - 일반유저
const namesForCommon = {
  USER_GOODS: '내 매물',
  COMMUNITY_POST_FOR_BUY: '구해줘다윈',
  CONSULTING: '일반상담', // 일반상담
  GOODS: '매물문의',
  COMMUNITY: '커뮤니티',
};

// 이름 - 중개사
const namesForRealtor = {
  USER_GOODS: '다윈매물',
  COMMUNITY_POST_FOR_BUY: '집찾는손님',
  CONSULTING: '일반상담', // 일반상담
  GOODS: '매물문의',
  COMMUNITY: '커뮤니티',
};

const typeForTagExplanation = {
  USER_GOODS: '이 매물을 올린 손님입니다.',
  COMMUNITY_POST_FOR_BUY: '',
  CONSULTING: '검색 혹은 추천을 통해 중개사님을 찾은 손님입니다.',
  GOODS: '이 매물에 관심있는 손님입니다.',
  COMMUNITY: '',
};

const getTagName = (subjectType, isBroker) => {
  return isBroker ? namesForRealtor[subjectType] : namesForCommon[subjectType];
};

const getTagExplanation = (subjectType, isBroker, partnerNickName) => {
  const communityUserNickName = partnerNickName || '';
  return isBroker ? (subjectType === 'COMMUNITY_POST_FOR_BUY' ? communityUserNickName : typeForTagExplanation[subjectType]) : null;
};

// 링크 - 일반
const linksForCommon = {
  USER_GOODS: () => `/my/mysell`,
  COMMUNITY_POST_FOR_BUY: (result) => `/community?feedDetail=${result.id}`,
  GOODS: (result) => getGoodsDetailDirectPath(result.tb_cb_complex_idx, result.trade_type, result.areaNo, result.goods_idx, result.areaNo, '/buying'),
  COMMUNITY: (result) => `/community?feedDetail=${result.id}`,
  CONSULTING: null,
};

// 링크 - 중개사
const linksForRealtor = {
  USER_GOODS: (result) => `/brokerPage/mng_goods?detail=${result.goods_common_idx}`,
  COMMUNITY_POST_FOR_BUY: (result) => `/brokerPage/community?feedDetail=${result.id}`,
  GOODS: (result) => getGoodsDetailDirectPath(result.tb_cb_complex_idx, result.trade_type, result.areaNo, result.goods_idx, result.areaNo, '/buying'),
  COMMUNITY: (result) => `/brokerPage/community?feedDetail=${result.id}`,
  CONSULTING: null,
};

const getLink = (type, result, isBroker) => {
  const getLinkFunc = isBroker ? linksForRealtor[type] : linksForCommon[type];
  const link = getLinkFunc ? getLinkFunc(result) : null;
  return link;
};

const getSubjectLogic = async (subjectType, id, { isBroker }) => {
  const userType = isBroker ? 'realtor' : 'guest';

  const getDetailApi = getSubjectDetailApi(subjectType, userType);

  if (getDetailApi) {
    const detail = await getDetailApi(id, isBroker);
    const link = getLink(subjectType, detail, isBroker);

    return { detail, link };
  } else {
    throw Error;
  }
};

export default {
  codes,
  getTagName,
  getSubjectLogic,
  getTagExplanation,
};
