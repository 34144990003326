import * as AT from './actionTypes';

export const getKeyword = (state) => state?.brokerPage?.oneOnOne?.keyword;
export const getList = (state) => state?.brokerPage?.oneOnOne?.qnaList;
export const getTotalCnt = (state) => state?.brokerPage?.oneOnOne?.totalCnt;
export const getLimit = (state) => state?.brokerPage?.oneOnOne?.limit;
export const getNew = (state) => state?.brokerPage?.oneOnOne?.new;
export const getAttach = (state) => state?.brokerPage?.oneOnOne?.new?.file_name;
export const getNewTitle = (state) => state?.brokerPage?.oneOnOne?.new?.title;
export const getNewCategory = (state) => state?.brokerPage?.oneOnOne?.new?.category;
export const getNewCont = (state) => state?.brokerPage?.oneOnOne?.new?.cont;
