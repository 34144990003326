import apis from '@/apis';
import { STATUS } from '@/data/utils';
import { useEffect, useState } from 'react';

const useRealtorData = (realtorIdx) => {
  const [data, setData] = useState();
  const [status, setStatus] = useState(STATUS.NOTASKED);

  useEffect(async () => {
    if (realtorIdx) {
      try {
        setStatus(STATUS.LOADING);
        const result = await apis.brokerApi.getBrokerDetail(realtorIdx);
        setData(result?.brokerInfo);
        setStatus(STATUS.SUCCESS);
      } catch (e) {
        //
        setStatus(STATUS.FAILURE);
      }
    }

    return () => {
      setData(null);
      setStatus(STATUS.NOTASKED);
    };
  }, [realtorIdx]);

  return { data, status };
};

export default useRealtorData;
