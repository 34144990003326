import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const setCurrentPage = (page) => ({
  type: AT.SET_RATE_PAGE,
  page,
});
export const setTotalPage = (page) => ({
  type: AT.SET_RATE_TOTAL_PAGE,
  page,
});

//중개평가
export const getBrokerageEvaluation = () => ({
  type: AT.GET_BROKERAGE_EVALUATION,
});
export const getBrEvaluationLoading = () => ({
  type: AT.GET_BROKERAGE_EVALUATION_LOADING,
});
export const getBrEvaluationSuccess = (brEvaluation) => ({
  type: AT.GET_BROKERAGE_EVALUATION_SUCCESS,
  brEvaluation,
});
export const getBrEvaluationFailure = (error) => ({
  type: AT.GET_BROKERAGE_EVALUATION_FAILURE,
  error,
});
