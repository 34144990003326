import React from 'react';
import { SERVICE_AREA } from '@/resources/text/common';

export const text = {
  ERROR_TEXT: '마이페이지 정보를 불러오는 중 오류가 발생했습니다.',
  MAIN_TEXT: '',

  MP_REDIRECT_TXT: '로그인 후 이용해주세요.',

  TOGGLE_FAVORITE_ERROR: '매물 찜하기 중 오류가 발생했습니다.',
  // =================== MyInfo =================== //

  MODAL_PASSWORD_CURRENT_NOT_GIVEN: '현재 비밀번호를 입력해주세요.',
  PASSWORD_CURRENT_INCORRECT_ERROR: '현재 비밀번호를 정확히 입력해주세요.',

  MODAL_PASSWORD_NEW_NOT_GIVEN: '변경하고자 하는 비밀번호를 입력해주세요.',
  MODAL_PASSWORD_NEW_NOT_MATCH: '변경하고자 하는 비밀번호가 일치하지 않습니다.',

  MODAL_PASSWORD_CHANGE_LOADING: '비밀번호를 변경중입니다.',
  MODAL_PASSWORD_CHANGE_DONE: '비밀번호 변경이 완료되었습니다.',

  PASSWORD_CHANGE_ERROR: '비밀번호 변경 중 오류가 발생했습니다.',

  MODAL_PHONE_CHANGE: '휴대폰 번호 변경하는 화면',
  MODAL_PHONE_CHANGED: '휴대폰번호가 변경되었습니다.',

  MY_INFO_NAME: '* 이름은 수정할 수 없습니다.',
  MY_INFO_EMAIL: '* 이메일은 수정할 수 없습니다.',
  MY_INFO_NAVER_EMAIL:
    '네이버로 로그인시 이메일 정보는 본인 네이버 계정과 다를 수 있습니다. 네이버 사이트> 로그인 > 개인정보에서 "연락처 이메일" 정보를 확인해주세요.',
  MY_INFO_PASSWORD: '* 비밀번호는 영문, 숫자 조합으로 8~15자리로 입력해주세요.',

  MODAL_USER_WITHDRAWAL: (
    <>
      <b>회원탈퇴</b> 하시겠습니까?
    </>
  ),

  // =================== MyBuy =================== //

  MY_BUY_FAVORITE_LOADING: '찜한매물 목록을 불러오는 중입니다.',
  MY_BUY_INTERESTED_LOADING: '관심단지 목록을 불러오는 중입니다.',
  MY_BUY_CONTRACTED_LOADING: '계약매물 목록을 불러오는 중입니다.',

  MY_BUY_ERROR: '매물 정보를 불러오는 중 오류가 발생했습니다.',
  MY_BUY_NONE_REGISTERED: '등록된 정보가 없습니다.',

  MY_BUY_FAVORITE_ITEMS_ERROR: '찜한 매물 정보를 불러오는 중 오류가 발생했습니다.',
  MY_BUY_INTERESTED_COMPLEX_ERROR: '관심 단지 정보를 불러오는 중 오류가 발생했습니다.',
  MY_BUY_CONTRACTED_ITEMS_ERROR: '계약 매물 정보를 불러오는 중 오류가 발생했습니다.',

  MY_BUY_FAVORITE: (
    <>
      관심매물은 <b>최대 30개</b>까지 보관 가능합니다. 30개가 넘을 경우 기존 관심매물을 삭제 후 이용해주세요.
    </>
  ),
  MY_BUY_INTERESTED: (
    <>
      관심단지는 <b>최대 30개</b>까지 보관 가능합니다. 30개가 넘을 경우 기존 관심단지를 삭제 후 이용해주세요.
    </>
  ),
  MY_BUY_CONTRACTED: '내가 계약한 매물 리스트입니다.',
  MY_BUY_RECHIS: '추천받은 내역이 표시되며 클릭 시 상위 5개 단지가 노출됩니다.',

  MODAL_RATING_COMPLETED: (
    <>
      <b>중개평가</b>가 완료되었습니다.
      <br />
      감사합니다.
    </>
  ),

  // =================== MySell =================== //
  MY_SELL_FLAG_BEFORE_REG: '등록대기중',
  MY_SELL_FLAG_REG_CANCELED: '등록취소완료',
  MY_SELL_FLAG_TRADE_COMPLETED: '거래완료',
  MY_SELL_FLAG_DELETE_COMPLETED: '매물삭제완료',
  MY_SELL_FLAG_RATE_COMPLETED: '중개평가완료',

  MY_SELL_BTN_REG: '+ 매물등록하기',
  MY_SELL_BTN_VIEW_ITEM: '매물정보보기',
  MY_SELL_BTN_MODI_ITEM: '매물정보수정',
  MY_SELL_BTN_RATE_BROKER: '중개평가하기',
  MY_SELL_BTN_REPORT_COMPLETE: '거래완료신고',
  MY_SELL_BTN_REREG: '재등록하기',
  MY_SELL_BTN_REG_CANCEL: '등록요청취소',
  MY_SELL_BTN_DELETE_ITEM: '매물삭제',
  MY_SELL_BTN_SELECT_COMPLETE: '선택완료',

  MY_SELL_LOADING_DEFAULT: '내가 등록한 매물 정보를 불러오는 중입니다.',

  MY_SELL_ERROR: '매물 정보를 불러오는 중 오류가 발생했습니다.',

  MY_SELL_UPPER_TEXT: '내가 등록한 매물리스트입니다.',
  MY_SELL_NONE_REGISTERED: '내가 내놓은 매물이 없습니다.',
  MY_SELL_AREAS_RESTRICTED_1: (
    <>
      * 집내놓기 서비스는 현재 {SERVICE_AREA}에서 제공 중입니다.
      <br />
    </>
  ),
  // '** 타 지역은 금년 하반기 중 매물접수를 시작할 예정이니 미리 회원가입을 해주시면 서비스 시작 전 따로 안내해드리겠습니다.',

  MY_ITEM_REPORT_TRADE_COMPLETE_AGENT_NOT_SELECTED: '중개사를 먼저 선택해주세요.',

  ITEM_REPORT_TRADE_COMPLETE_DAWIN_AGENT: '다윈 중개사와 거래',
  ITEM_REPORT_TRADE_COMPLETE_NON_DAWIN_AGENT: '타중개사와 거래',
  MODAL_ITEM_REPORT_TRADE_COMPLETE_ASK: '거래완료 처리하시겠습니까?',
  MODAL_ITEM_REPORT_TRADE_COMPLETE_LOADING: '거래완료 처리중입니다.',
  MODAL_ITEM_REPORT_TRADE_COMPLETE_DONE: (
    <>
      <b>거래완료 신고해 주셔서 감사합니다.</b>
      <br />
      앞으로도 허위매물 없는 다윈중개를 만들기 위해 최선을 다하겠습니다.
    </>
  ),
  MY_ITEM_REPORT_TRADE_COMPLETE_ERROR: '매물 거래완료 처리 중 오류가 발생했습니다.',

  DELETE_REASON_1: '다윈내 중개사무소와 거래',
  DELETE_REASON_2: '다윈외 중개사무소와 거래',
  DELETE_REASON_3: '거래 의사 철회',
  DELETE_REASON_4: '거래 가격이 맞지 않아서',
  DELETE_REASON_5: '빠른 거래가 이루어지지 않아서',
  DELETE_REASON_ETC: '기타',
  DELETE_REASON_ETC_PLACEHOLDER: '기타 선택 시 내용을 입력해주요.',
  DELETE_SELECT_REASON_FIRST: '매물 삭제 이유를 선택해주세요.',

  MODAL_ITEM_REG_CANCEL: '매물등록요청을 취소하시겠습니까?',
  MODAL_ITEM_REG_CANCEL_LOADING: '매물등록요청을 취소중입니다.',
  MODAL_ITEM_REG_CANCEL_DONE: '매물등록요청이 취소되었습니다.',
  MY_SELL_ITEM_REG_CANCEL_ERROR: '매물등록요청 취소 중 오류가 발생했습니다.',

  MODAL_ITEM_DELETE: '매물을 삭제하시겠습니까?',
  MODAL_ITEM_DELETE_LOADING: '매물을 삭제중입니다.',
  MODAL_ITEM_DELETE_DONE: '매물이 삭제되었습니다.',
  MY_SELL_ITEM_DELETE_ERROR: '매물 삭제 중 오류가 발생했습니다.',

  MODAL_ITEM_REREG1: '매물을 재등록 하시겠습니까?',
  MODAL_ITEM_REREG2: (
    <>
      임시저장 되어있는 매물 정보가 있습니다. <br /> 매물 재등록시 현재 임시저장 되어있는 매물 정보는 삭제됩니다. <br />
      매물 재등록을 진행하시겠습니까?
    </>
  ),
  MODAL_ITEM_REREG_LOADING: '매물을 재등록 중입니다.',
  MODAL_ITEM_REREG_DONE: '매물 재등록이 완료되었습니다.',
  MY_SELL_ITEM_REREG_ERROR: '매물 재등록 중 오류가 발생했습니다.',

  // =================== MyQNA =================== //
  MY_QNA_LIST_LOADING: '문의 내역을 불러오는 중입니다.',
  MY_QNA_LIST_ERROR: '문의 내역을 불러오던 중 오류가 발생했습니다.',
  MY_QNA_ANS_LIST_ERROR: '답변 내역을 불러오던 중 오류가 발생했습니다.',
  MY_QNA_NO_INQUERY: '문의 내역이 존재하지 않습니다.',

  MY_QNA_CODE_FQ1: '시스템오류',
  MY_QNA_CODE_FQ2: '집구하기',
  MY_QNA_CODE_FQ3: '집내놓기',
  MY_QNA_CODE_FQ4: '마이페이지',
  MY_QNA_CODE_FQ5: '결제오류',
  MY_QNA_CODE_ETC: '기타',
};
