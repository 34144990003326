import * as AT from '@/data/rootActionTypes';

const INITIAL_STATE = {
  personalSummary: {},
  interestComplex: [],
  myHome: [],
  myComplex: [],
};

export default function auth(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.SET_PERSONAL_SUMMARY:
      return { ...state, personalSummary: action.data };
    case AT.SET_INTEREST_COMPLEX:
      return { ...state, interestComplex: action.data };
    case AT.SET_MY_HOME:
      return { ...state, myHome: action.data };
    default:
      return { ...state };
  }
}
