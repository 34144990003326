import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { STATUS } from '@/data/utils';
import { parseCallBack } from '@/services/Helper';

const useGetInitialFeeds = ({ feeds, setFeeds, payload, setIsDisabled, setKeyword, setKeywordTag }) => {
  const dispatch = useDispatch();
  const queryString = parseCallBack();
  const localComplexTag = useSelector(selectors.community.localComplexTag);
  const isFeedLoading = feeds?.status === STATUS.LOADING;

  useEffect(() => {
    // 유저가 url에 바로 '?complecTalkMode=true'를 치고 들어오면 전체 불러오기를 해주지 않는다.
    const complexTalkMode = queryString['complexTalkMode'];

    // isFeedLoading을 saga에서 막을 방법은?
    if (!isFeedLoading && !complexTalkMode) {
      let newPayload;
      if (localComplexTag) {
        newPayload = localComplexTag;
        setIsDisabled(true);
        setKeywordTag({ name: localComplexTag?.tags[0]?.name });
        setKeyword(localComplexTag?.tags[0]?.name);
      } else {
        newPayload = payload;
      }
      dispatch(actions.community.getFeeds(newPayload, undefined, setFeeds));
    } else {
      return;
    }
  }, [localComplexTag]);

  // 지도에서 넘어올때 가져온 태그 clean up
  useEffect(() => {
    if (location.pathname === '/community') {
      return () => {
        dispatch(actions.community.setLocalComplexTag(undefined));
      };
    }
  }, []);

  return;
};

export default useGetInitialFeeds;
