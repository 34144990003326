import React, { useEffect } from 'react';
import Comment from './Comment';
import { useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import { useGetComplexHistoryBulkByUserIds } from '@/combinedComp/Community/hooks';
import { TagType } from '@/services/Community';
// import { postsCommentItem } from '@/resources/design/v2/js/bui.template';

const CommentArea = ({ commentCount, setCommentCount, feedData }) => {
  const comments = useSelector(selectors.community.getComments);
  useEffect(() => {
    // postsCommentItem('[data-bui-expand="postsCommentItem"]');
  }, [comments]);

  const hasComplex = feedData?.tags?.filter((tag) => tag.type == TagType.COMPLEX_TAG).length > 0;

  // 집주인 인증배지 데이터 요청
  const getFilteredId = () => {
    const filteredIds = comments?.data?.filter((comment) => comment?.writer?.type === 'CUSTOMER').map((comment) => comment?.writer?.id);
    return filteredIds;
  };
  const getStatus = () => {
    const isReady = comments !== undefined && comments !== null && hasComplex;
    return isReady;
  };
  useGetComplexHistoryBulkByUserIds({ feeds: comments, userIds: getFilteredId(), isReady: getStatus() });

  return comments ? (
    comments?.data.map((commentData, index) => (
      <Comment commentData={commentData} key={index} commentCount={commentCount} setCommentCount={setCommentCount} feedData={feedData} />
    ))
  ) : (
    <></>
  );
};

export default CommentArea;
