import React from 'react';

export const WriterType = {
  CUSTOMER: 'CUSTOMER', //일반 유저
  BROKER: 'BROKER', //중개사
};

export const FeedType = {
  // TODO: @커뮤니티테스트
  FREESTYLE_POST: 'FREESTYLE_POST',
  INFO_POST: 'INFO_POST',
  POST_FOR_SALE: 'POST_FOR_SALE', //등록매물
  POST_FOR_BUY: 'POST_FOR_BUY', //구해줘다윈
  COMPLEX_POST: 'COMPLEX_POST', //단지톡
  LOCAL_POST: 'LOCAL_POST', //지역톡
  ONE_ROOM_POST: 'ONE_ROOM_POST', //원룸톡
  SYSTEM_POSTED_PRICE: 'SYSTEM_POSTED_PRICE', //실거래가
  QNA: 'QNA', //부동산 QNA
};

export const FeedTypeKor = {
  // TODO: @커뮤니티테스트
  FREESTYLE_POST: '자유게시판',
  INFO_POST: '정보공유',
  POST_FOR_SALE: '등록매물', //등록매물
  POST_FOR_BUY: '구해줘다윈', //구해줘다윈
  COMPLEX_POST: '단지톡', //단지톡
  LOCAL_POST: '지역톡', //지역톡
  ONE_ROOM_POST: '원룸톡', //원룸톡
  SYSTEM_POSTED_PRICE: '실거래가', //실거래가
  // TODO: @커뮤니티테스트
  // QNA: '부동산Q&A', //부동산 QNA
  QNA: 'Q&A', //부동산 QNA
};

export const FeedStatus = {
  PUBLISHED: 'PUBLISHED', //공개상태
  BLINDED: 'BLINDED', //비공개 상태
  DELETED: 'DELETED', // 삭제 상태
};

export const TagType = {
  HASH_TAG: 'HASH_TAG', //일반 해쉬태그(문자)
  ADDRESS_SiGunGu_TAG: 'ADDRESS_SiGunGu_TAG', //시군구 코드 태그
  ADDRESS_Dong_TAG: 'ADDRESS_Dong_TAG', //동 코드 태그
  COMPLEX_TAG: 'COMPLEX_TAG', //단지 태그
  SALE_TYPE_TAG: 'SALE_TYPE_TAG', //거래 유형 태그(전제 / 월세 / 매매)
  FLAG_TAG: 'FLAG_TAG', //전화가능, 채팅가능 태그(hidden 처리)
  GOODS_TYPE_TAG: 'GOODS_TYPE_TAG', //매물 유형 태그(아파트 / 오피스텔 …)
  GOODS_TAG: 'GOODS_TAG', //매물 ID 태그
  PRICE_TAG: 'PRICE_TAG', //가격
  AREA_TAG: 'AREA_TAG', //면적
  ADDRESS_TAG: 'ADDRESS_TAG', //주소 (주소는 sigungu, dong 으로 분리하지 않고 주소 태그 하나로 통일 DX-2874)
  TRANSACTION_HISTORY_TAG: 'TRANSACTION_HISTORY_TAG', //실거래가 아이디가 입력된 특수 TAG
};

export const ReactionType = {
  LIKE: 'LIKE', //좋아요
  HATE: 'HATE', //싫어요(아직없음)
};

export const TargetType = {
  FEED: 'FEED',
  COMMENT: 'COMMENT',
};

export const CommentStatus = {
  PUBLISHED: 'PUBLISHED',
  BLINDED: 'BLINDED',
  DELETED: 'DELETED',
};

// feed 작성 시점
export const CreatePoint = {
  COMMUNITY: 'COMMUNITY', // 커뮤니티
  POST_FOR_BUY: 'POST_FOR_BUY', // 구해줘다윈
  COMPLEX_POST: 'COMPLEX_POST', // 단지톡 (단지상세)
  LOCAL_POST: 'LOCAL_POST', // 지역톡
  SELLING_COMPLEX: 'SELLING_COMPLEX',
  SELLING_ONEROOM: 'SELLING_ONEROOM',
  BROKER_MY: 'BROKER_MY',
};

export const CreatePointKor = {
  COMMUNITY: '커뮤니티', // 커뮤니티
  POST_FOR_BUY: '구해줘다윈', // 구해줘다윈
  COMPLEX_POST: '단지톡', // 단지톡 (단지상세)
  LOCAL_POST: '지역톡', // 지역톡
  // SELLING_COMPLEX: 'SELLING_COMPLEX',
  // SELLING_ONEROOM: 'SELLING_ONEROOM',
  // BROKER_MY: 'BROKER_MY',
};

// 기획 - 커뮤니티 structure (Input)
// feed 작성 시점에 따른 전환 가능한 피트 타입 리스트
// 원룸톡은 추후 추가
export const FeedTypeChangePossibleList = {
  // 커뮤니티 게시판
  COMMUNITY: [FeedType.POST_FOR_BUY, FeedType.COMPLEX_POST, FeedType.LOCAL_POST, FeedType.QNA], //, FeedType.ONE_ROOM_POST
  // 구해줘 다윈
  POST_FOR_BUY: [FeedType.POST_FOR_BUY],
  // 단지톡
  COMPLEX_POST: [FeedType.POST_FOR_BUY, FeedType.COMPLEX_POST, FeedType.LOCAL_POST, FeedType.QNA], //, FeedType.ONE_ROOM_POST
  // 지역톡
  LOCAL_POST: [FeedType.POST_FOR_BUY, FeedType.COMPLEX_POST, FeedType.LOCAL_POST, FeedType.QNA], //, FeedType.ONE_ROOM_POST
  // 집내놓기
  SELLING_COMPLEX: [FeedType.COMPLEX_POST, FeedType.LOCAL_POST],
  SELLING_ONEROOM: [FeedType.ONE_ROOM_POST, FeedType.LOCAL_POST],
  // (원룸/투룸) 매물정보
  // [FeedType.ONE_ROOM_POST, FeedType.LOCAL_POST] // + 등록매물
  // 중개사마이페이지 : 확인필요
  BROKER_MY: [FeedType.LOCAL_POST, FeedType.COMPLEX_POST], //, FeedType.ONE_ROOM_POST
};

// feed 작성 시점에 따른 디폴트 feed type
export const DefaultFeedTypeByCreatePoint = {
  COMMUNITY: FeedType.POST_FOR_BUY,
  POST_FOR_BUY: FeedType.POST_FOR_BUY,
  COMPLEX_POST: FeedType.COMPLEX_POST,
  LOCAL_POST: FeedType.LOCAL_POST,
  SELLING_COMPLEX: FeedType.COMPLEX_POST,
  SELLING_ONEROOM: FeedType.ONE_ROOM_POST,
  BROKER_MY: FeedType.LOCAL_POST,
};

export const GoodsTypeKor = {
  APT: '아파트',
  OFT: '오피스텔',
  VILLA: '주택/빌라',
  STUDIO: '원룸/투룸',
  OFFICE: '상가/사무실',
};

export const ExpandGoodsKorType = {
  아파트: 'APT',
  오피스텔: 'OFT',
  '상가/사무실': 'OFFICE',
  '원룸/투룸': 'STUDIO',
  빌라: 'VILLA',
  다가구: 'MULTY',
  단독주택: 'HOUSE',
};

export const TradeTypeKor = {
  s: '매매',
  c: '전세',
  m: '월세',
};

export const KorTradeType = {
  매매: 's',
  전세: 'c',
  월세: 'm',
};

export const SQUARE_METER_UNIT = 3.305785;
export const HUNDRED_MILLION = 100000000; // 1억

// 구해줘다윈 글쓰기 팝업에서 이용되는 버튼들
export const MY_AREA_BTNS = [
  {
    type: 'INTERESTED_AREAS',
    name: '관심지역',
    contents: (
      <>
        등록된 지역이 없습니다. <br />
        자주 찾는 지역을 등록해 보세요.
      </>
    ),
  },
  {
    type: 'INTERESTED_COMPLEXES',
    name: '관심단지',
    contents: (
      <>
        등록된 단지가 없습니다. <br />
        자주 찾는 단지를 등록해 보세요.
      </>
    ),
  },
  { type: 'RECENTLY_VIEWED_AREAS', name: '최근 본 지역', contents: <>최근에 본 지역이 없습니다.</> },
  { type: 'RECENTLY_VIEWED_COMPLEXES', name: '최근 본 단지', contents: <>최근에 본 단지가 없습니다.</> },
];

// 여기서부터 새로 정리 시작

// ABOUT REALTY_TYPES
export const codes = {
  apt: 'APT',
  oft: 'OFT',
  vll: 'VLL',
  one: 'ONE',
  sto: 'STO',
  off: 'OFF',
};

const getValidRealtyTypes = (obj) => {
  const values = [];

  for (const key in obj) {
    const value = obj[key];
    values.push(value);
  }

  return values;
};

export const validRealtyTypes = getValidRealtyTypes(codes);

const _names = {
  [codes.apt]: '아파트',
  [codes.oft]: '오피스텔',
  [codes.vll]: '주택/빌라',
  [codes.one]: '원룸/투룸',
  [codes.sto]: '상가',
  [codes.off]: '사무실',
};

const _codes = {
  ['아파트']: codes.apt,
  ['오피스텔']: codes.oft,
  ['주택/빌라']: codes.vll,
  ['원룸/투룸']: codes.one,
  ['상가']: codes.sto,
  ['사무실']: codes.off,
};

const _icons = {
  ['아파트']: 'apt',
  ['오피스텔']: 'oft',
  ['주택/빌라']: 'vll',
  ['원룸/투룸']: 'one',
  ['상가']: 'sto',
  ['사무실']: 'off',
};

/**
 *
 * @param {String} code ex)"APT"
 * @returns {String} ex)"아파트"
 */
export const getRealtyName = (code) => {
  return _names[code];
};

/**
 *
 * @param {String} name ex)"아파트"
 * @returns {String} ex)"APT"
 */
export const getRealtyCode = (name) => {
  return _codes[name];
};

/**
 *
 * @param {String} name ex)"아파트"
 * @returns {String} ex)"aft"
 */
export const getRealtyIcon = (name) => {
  return _icons[name];
};

export default {
  codes,
  getRealtyName,
  getRealtyCode,
  getRealtyIcon,
  validRealtyTypes,
};
