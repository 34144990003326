import { loading, success, failure } from '@/data/utils';

export const COMMUNITY_SEARCH = 'COMMUNITY/COMMUNITY_SEARCH';

export const SHOW_CREATE_FEED = 'COMMUNITY/SHOW_CREATE_FEED';

//feed 좋아요
export const FEED_LIKE = 'COMMUNITY/FEED_LIKE';
//댓글 좋아요
export const COMMENT_LIKE = 'COMMUNITY/COMMENT_LIKE';
//신고하기
export const FEED_REPORT = 'COMMUNITY/FEED_REPORT';

//feed 조회시 검색어
export const SEARCH_TAGS = 'COMMUNITY/SEARCH_TAGS';
export const SEARCH_TAGS_SUCCESS = success(SEARCH_TAGS);
export const SEARCH_TAGS_LOADING = loading(SEARCH_TAGS);
export const SEARCH_TAGS_FAILURE = failure(SEARCH_TAGS);

//feed 조회
export const GET_COMMUNITY_FEEDS = 'COMMUNITY/GET_COMMUNITY_FEEDS';

// feed 작성 시점 (커뮤니티, 단지톡 등등..)
export const CHANGE_CREATE_POINT = 'COMMUNITY/CHANGE_CREATE_POINT';

// Feed 생성
export const CREATE_FEED = 'COMMUNITY/CREATE_FEED';
export const EDIT_FEED = 'COMMUNITY/EDIT_FEED';
export const DELETE_FEED = 'COMMUNITY/DELETE_FEED';
export const SET_CREATED_FEED = 'COMMUNITY/SET_CREATED_FEED'; // feed 생성 후 유저들에게 새로운 피드를 바로 보여주기 위한 처리
export const SET_DELETED_FEEDS = 'COMMUNITY/SET_DELETED_FEEDS'; // feed 삭제 후 임시적으로 id 여기 저장 > list에서 해당 feed 안 보여주기 위한 처리

// feed 상세보기
export const GET_COMMUNITY_FEED = 'COMMUNITY/GET_COMMUNITY_FEED';
export const SET_COMMUNITY_FEED = 'COMMUNITY/SET_COMMUNITY_FEED';
export const SET_FEED_DETAIL_ITEM = 'COMMUNITY/SET_FEED_DETAIL_ITEM';

// 안심번호 조회
export const GET_PHONE_NUMBER = 'COMMUNITY/GET_PHONE_NUMBER';
export const SET_PHONE_NUMBER = 'COMMUNITY/SET_PHONE_NUMBER';

// comments list 조회
export const GET_COMMENTS = 'COMMUNITY/GET_COMMENTS';
export const SET_COMMENTS = 'COMMUNITY/SET_COMMENTS';

// comment생성
export const CREATE_COMMENT = 'COMMUNITY/CREATE_COMMENT';

// 글 작성 후 리스트 재조회
export const FIRE_RELOAD = 'FIRE_RELOAD';

export const SET_USER_HISTORY_TARGET = 'SET_USER_HISTORY_TARGET';

export const COMMUNITY_CHANGE_REGION_CODE = 'COMMUNITY_CHANGE_REGION_CODE';

// test
// export const REPLY_MODE = 'REPLY_MODE';

export const SET_FEED_STATUS = 'COMMUNITY/SET_FEED_STATUS';

// 댓글 생성 후 로딩바
export const SET_COMMENT_STATUS = 'SET_COMMENT_STATUS';

// 댓글 더보기 버튼 클릭 후 로딩 바
export const SET_MORE_COMMENT_STATUS = 'SET_MORE_COMMENT_STATUS';

// 지도의 게시판에서 커뮤니티로 이동할때 해당 지역/단지 tag 임시 저장
export const SET_LOCAL_COMPLEX_TAG = 'SET_LOCAL_COMPLEX_TAG';
