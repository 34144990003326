import tradeTypes from '../tradeTypes';

export const getPriceKeys = (tradeType) => {
  if (tradeType === tradeTypes.codes.s) return ['price1'];
  if (tradeType === tradeTypes.codes.c) return ['price2'];
  if (tradeType === tradeTypes.codes.m) return ['price2', 'price3'];
};

export const getPrice = (data, options = {}) => {
  const {
    formatter = function(value) {
      return value;
    },
    joinAs = ' / ',
  } = options;

  return getPriceKeys(getTradeType(data))
    ?.map((item) => formatter(data[item]))
    .join(joinAs);
};

export const getPriceInfo = (goods) => {
  return goods.map((item) => {
    const priceKeys = getPriceKeys(item.trade_type);
    const prices = priceKeys.map((key) => item[key]);

    return {
      tradeType: item.trade_type,
      tradeTypeName: tradeTypes.getName(item.trade_type),
      prices,
      priceText: prices.map((price) => priceFormat(price)).join(' / '),
    };
  });
};

function getTradeType(data) {
  return data.tradeType || data.trade_type;
}

export function priceFormat(p) {
  if (!p) return;
  if (p < 10000) {
    return p + '만원';
  } else {
    return `${parseInt(p / 10000)}억${p % 10000 > 0 ? `${p % 10000}` : ''}`;
  }
}
