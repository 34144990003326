import React from 'react';

const CommentCount = ({ item, onClickFeedDetail }) => {
  return (
    <span className="data-item comment" onClick={(e) => onClickFeedDetail(e, item?.id)} style={{ cursor: 'pointer' }}>
      <span className="head">댓글</span>
      <span className="body">{item?.commentCount}</span>
    </span>
  );
};

export default CommentCount;
