import { useState, useEffect } from 'react';

const useInfiniteScroll = (callback, element) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [element]);

  useEffect(() => {
    if (!isFetching) return;
    callback(() => {
      console.log('called back');
    });
  }, [isFetching]);

  function handleScroll() {
    // console.log(element.clientHeight);
    // console.log(element.scrollTop);
    // console.log(element.clientHeight + element.scrollTop);
    // console.log(element.scrollHeight);
    // [배지율 수정] 안드로이드에서 계산 값에 오차가 있음
    // if (element.clientHeight + element.scrollTop < element.scrollHeight - 20 || isFetching) return;
    if (element.clientHeight + element.scrollTop < element.scrollHeight - 20) {
      setIsFetching(false);
      return;
    } else {
      setIsFetching(true);
    }
    // console.log('isFetching : ' + isFetching);
  }

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
