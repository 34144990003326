import React from 'react';
import { TagType, getRealtyName } from '@/services/Community';

const GoodsTypeItem = ({ type, tags, setTags, value, selected, selectedGoodsTypes, setSelectedGoodsTypes, ...rest }) => {
  const complex = tags?.filter((tag) => tag.type === TagType.COMPLEX_TAG)?.[0];
  // const selectedType = tags.filter((item) => item.type === TagType.GOODS_TYPE_TAG);

  // 매물 타입 변경
  const changeGoodsType = ({ goodsType }) => {
    const goodsTypeKor = getRealtyName(type);
    if (!complex) {
      const filteredTags = tags?.filter((tag) => tag.key !== goodsTypeKor);
      setTags([...filteredTags, { type: TagType.GOODS_TYPE_TAG, key: goodsTypeKor, name: goodsTypeKor }]);
    }

    if (selectedGoodsTypes?.includes(goodsType)) {
      const newSelectedList = selectedGoodsTypes?.filter((item) => item !== goodsType);
      setSelectedGoodsTypes(newSelectedList);

      const filteredTags = tags?.filter((tag) => tag.key !== goodsTypeKor);
      setTags(filteredTags);
    } else {
      setSelectedGoodsTypes([...selectedGoodsTypes, goodsType]);
      setTags([...tags, { type: TagType.GOODS_TYPE_TAG, key: goodsTypeKor, name: goodsTypeKor }]);
    }
  };

  const handleChange = (e) => {
    const goodsType = e.target.value;
    changeGoodsType({ goodsType });
  };

  return (
    <span className="option-item">
      <label className="checkbox form module-b style-c accent-01 medium">
        <input
          type="checkbox"
          className="form-elem"
          name="radio-0001"
          onChange={handleChange}
          value={type}
          checked={selectedGoodsTypes?.includes(type)}
          {...rest}
        />
        <span className="form-text">{getRealtyName(type)}</span>
      </label>
    </span>
  );
};

export default GoodsTypeItem;
