import * as AT from './actionTypes';
export const getERROR = (state) => state?.rating?.error;

// ===================== Rating ===================== //
export const getRatingType = (state) => state?.rating?.ratingType;
export const getRatingTargetItemIdx = (state) => state?.rating?.ratingTargetItemIdx;
export const getRatingGubun = (state) => state?.rating?.gubun;
export const getRatingTargetBrokerIdx = (state) => state?.rating?.ratingTargetBrokerIdx;

export const getRatingList = (state) => state?.rating?.ratingList;

export const getPostStateIsLoading = (state) => state?.rating[AT.POST_RATING].isLoading();
export const getPostStateIsSuccess = (state) => state?.rating[AT.POST_RATING].isSuccess();
export const getPostStateIsFailure = (state) => state?.rating[AT.POST_RATING].isFailure();

export const getPostUrlIsLoading = (state) => state?.rating[AT.POST_RATING_URL].isLoading();
export const getPostUrlStateIsSuccess = (state) => state?.rating[AT.POST_RATING_URL].isSuccess();
export const getPostUrlStateIsFailure = (state) => state?.rating[AT.POST_RATING_URL].isFailure();
export const getsucess = (state) => state?.rating[AT.POST_RATING_URL].getOrElse([]);
