import React from 'react';
import { TagType, TradeTypeKor } from '@/services/Community';

const TradeTypeItem = ({ type, tags, setTags, ...rest }) => {
  const selectedTypeArray = tags.filter((item) => item.type === TagType.SALE_TYPE_TAG)?.map((item) => item.key);
  const tradeTypeKor = TradeTypeKor[type];

  const changeTradeType = () => {
    const filteredTags = tags?.filter((tag) => tag.type !== TagType.SALE_TYPE_TAG || tag.key !== tradeTypeKor);
    const already = tags?.filter((tag) => tag.type === TagType.SALE_TYPE_TAG && tag.key === tradeTypeKor)?.[0];
    if (already) {
      setTags([...filteredTags]);
    } else {
      setTags([...filteredTags, { type: TagType.SALE_TYPE_TAG, key: tradeTypeKor, name: tradeTypeKor }]);
    }
  };

  return (
    <>
      <span className="option-item">
        <label className="checkbox form module-b style-c accent-01 medium">
          <input
            type="checkbox"
            className="form-elem"
            checked={selectedTypeArray.indexOf(tradeTypeKor) > -1}
            onChange={() => changeTradeType()}
            value={type}
            {...rest}
          />
          <span className="form-text">{tradeTypeKor}</span>
        </label>
      </span>
    </>
  );
};

export default TradeTypeItem;
