import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';
import CONSTANTS from '@/pages/BrokerPage/constants';
import * as brokerOfficeInfo from './brokerOfficeInfo/sagas';
import * as jointBrokerage from './jointBrokerage/sagas';
import * as bkQnA from './bkQnA/sagas';
import * as mgCounseling from './mgCounseling/sagas';
import * as mgCustomer from './mgCustomer/sagas';
import * as mgGoods from './mgGoods/sagas';
import * as mgContract from './mgContract/sagas';
import * as mgQa from './mgQa/sagas';
import * as mgRate from './mgRate/sagas';
import * as mgComplexImg from './mgComplexImg/sagas';
import * as dataRoom from './dataRoom/sagas';
import * as suggestion from './suggestion/sagas';
import * as notice from './notice/sagas';
import * as internalNotice from './internalNotice/sagas';
import * as oneOnOne from './oneOnOne/sagas';
import * as setArea from './setArea/sagas';
import * as dailyReport from './dailyReport/sagas';
import * as community from './community/sagas';

export { brokerOfficeInfo };
export { bkQnA };
export { jointBrokerage };
export { mgCounseling };
export { mgCustomer };
export { mgGoods };
export { mgContract };
export { mgQa };
export { mgRate };
export { mgComplexImg };
export { dataRoom };
export { suggestion };
export { notice };
export { internalNotice };
export { oneOnOne };
export { setArea };
export { dailyReport };
export { community };

//알람 리스트 get
export function* getBrokerAlarmList(broker_office_idx) {
  try {
    //const { list } = yield call(apis.brokerPageApi.alarmList(broker_office_idx));
    const brokerAlarmList = CONSTANTS.BROKER_ALARM_LIST;
    if (!brokerAlarmList) {
      yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
      yield put(actions.setBrokerAlarmList([]));
    } else {
      yield put(actions.setBrokerAlarmList(brokerAlarmList));
    }
  } catch (e) {
    yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
  }
}

//단일 알람 삭제
export function* deleteAlarm({ broker_office_idx, goods_idx }) {
  try {
    const payload = {
      goods_idx: goods_idx,
      broker_office_idx: broker_office_idx,
    };
    yield call(apis.brokerPageApi.deleteAlarm, payload);
    const { list } = yield call(apis.brokerPageApi.alarmList(broker_office_idx));
    if (!list) {
      yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
      yield put(actions.setBrokerAlarmList([]));
    } else {
      yield put(actions.setBrokerAlarmList(list));
    }
  } catch (e) {
    yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
  }
}

//읽음 알람 삭제 or 전체 알람 삭제
export function* deleteRAAlarm({ broker_office_idx, read }) {
  try {
    const payload = {
      read: read,
      broker_office_idx: broker_office_idx,
    };
    yield call(apis.brokerPageApi.deleteRAAlarm, payload);
    const { list } = yield call(apis.brokerPageApi.alarmList(broker_office_idx));
    if (!list) {
      yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
      yield put(actions.setBrokerAlarmList([]));
    } else {
      yield put(actions.setBrokerAlarmList(list));
    }
  } catch (e) {
    yield put(actions.brokerPageRequestError({ error: text.brokerPageText.BROKER_ALARM_ERROR }));
  }
}

//현황판
export function* getBrokerBoardNew() {
  try {
    const board_new = yield call(apis.brokerPageApi.getBrokerBoardNew);
    yield put(actions.setBoardNew(board_new.board_new));
  } catch (e) {
    console.log('no new data');
  }
}
