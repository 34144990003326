import React from 'react';

const defaultMessage = (
  <>
    죄송합니다.
    <br />
    해당 기능에 오류가 발생하였습니다.
  </>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트 합니다.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 에러 리포팅 서비스에 에러를 기록할 수도 있습니다.
    // logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      this.state = { hasError: false };

      // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
      if (this.props.noReturn) {
        // 아무것도 반환X
        return false;
      } else {
        return this.props.errorPage ? (
          this.props.errorPage
        ) : (
          <div style={{ padding: '20px', backgroundColor: 'white', margin: '10px' }}>
            <div style={{ fontSize: '1em' }}>{this.props.errorMessage ? this.props.errorMessage : defaultMessage}</div>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
