import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const DAILY_REPORT = {
  report_date: '',
  report: [
    { category: 'counseling', sub_category: 'seller', execute_count: 0, comment: '' },
    { category: 'counseling', sub_category: 'buyer', execute_count: 0, comment: '' },
    { category: 'counseling', sub_category: 'etc', execute_count: 0, comment: '' },
    { category: 'advertisement', sub_category: 'advertisement', execute_count: 0, comment: '' },
    { category: 'advertisement', sub_category: 'visit', execute_count: 0, comment: '' },
    { category: 'contract', sub_category: 'tmp_contract', execute_count: 0, comment: '' },
    { category: 'contract', sub_category: 'contract', execute_count: 0, comment: '' },
    { category: 'contract', sub_category: 'balance', execute_count: 0, comment: '' },
    { category: 'local', sub_category: 'local', execute_count: 0, comment: '' },
  ],
};

export default function dailyReport(state = DAILY_REPORT, action = {}) {
  switch (action.type) {
    case AT.SET_DAILY_REPORT_DATE: {
      let refine_date = new Date(action.val);
      refine_date.setTime(refine_date.getTime() + 60 * 60 * 9 * 1000);
      refine_date = refine_date.toISOString().substring(0, 10);
      return {
        ...state,
        report_date: action.val,
        report: {
          ...state.report,
          seller: { ...state.report.sller, report_date: refine_date },
          buyer: { ...state.report.buyer, report_date: refine_date },
          etc: { ...state.report.etc, report_date: refine_date },
          advertisement: { ...state.report.advertisement, report_date: refine_date },
          visit: { ...state.report.visit, report_date: refine_date },
          tmp_contract: { ...state.report.tmp_contract, report_date: refine_date },
          contract: { ...state.report.contract, report_date: refine_date },
          balance: { ...state.report.balance, report_date: refine_date },
          local: { ...state.report.local, report_date: refine_date },
        },
        // report: state.report.map((ii, kk) => {
        //   ii['report_date'] = refine_date;
        //   return ii;
        // }),
      };
    }

    case AT.GET_DAILY_REPORT_LIST: {
      return {
        ...state,
        page: action.page,
      };
    }

    case AT.DAILY_REPORT_INI: {
      let refine_date = new Date(state.report_date);
      refine_date.setTime(refine_date.getTime() + 60 * 60 * 9 * 1000);
      refine_date = refine_date.toISOString().substring(0, 10);
      return {
        ...state,
        report: {
          seller: { report_date: refine_date, category: 'counseling', sub_category: 'seller', execute_count: 0, comment: '' },
          buyer: { report_date: refine_date, category: 'counseling', sub_category: 'buyer', execute_count: 0, comment: '' },
          etc: { report_date: refine_date, category: 'counseling', sub_category: 'etc', execute_count: 0, comment: '' },
          advertisement: { report_date: refine_date, category: 'advertisement', sub_category: 'advertisement', execute_count: 0, comment: '' },
          visit: { report_date: refine_date, category: 'advertisement', sub_category: 'visit', execute_count: 0, comment: '' },
          tmp_contract: { report_date: refine_date, category: 'contract', sub_category: 'tmp_contract', execute_count: 0, comment: '' },
          contract: { report_date: refine_date, category: 'contract', sub_category: 'contract', execute_count: 0, comment: '' },
          balance: { report_date: refine_date, category: 'contract', sub_category: 'balance', execute_count: 0, comment: '' },
          local: { report_date: refine_date, category: 'local', sub_category: 'local', execute_count: 0, comment: '' },
        },
      };
    }

    case AT.SET_INSERT_DATA: {
      let key = 0;
      let cate = '';
      let category = '';
      let sub_category = '';
      let refine_date = new Date(state.report_date);
      refine_date.setTime(refine_date.getTime() + 60 * 60 * 9 * 1000);
      refine_date = refine_date.toISOString().substring(0, 10);
      switch (action.type_val) {
        case 'seller': {
          key = 'seller';
          cate = 'execute_count';
          category = 'counseling';
          sub_category = 'seller';
          break;
        }
        case 'buyer': {
          key = 'buyer';
          cate = 'execute_count';
          category = 'counseling';
          sub_category = 'buyer';
          break;
        }
        case 'etc': {
          key = 'etc';
          cate = 'execute_count';
          category = 'counseling';
          sub_category = 'etc';
          break;
        }
        case 'advertisement': {
          key = 'advertisement';
          cate = 'execute_count';
          category = 'advertisement';
          sub_category = 'advertisement';
          break;
        }
        case 'visit': {
          key = 'visit';
          cate = 'execute_count';
          category = 'advertisement';
          sub_category = 'visit';
          break;
        }
        case 'tmp_contract': {
          key = 'tmp_contract';
          cate = 'execute_count';
          category = 'contract';
          sub_category = 'tmp_contract';
          break;
        }
        case 'contract': {
          key = 'contract';
          cate = 'execute_count';
          category = 'contract';
          sub_category = 'contract';
          break;
        }
        case 'balance': {
          key = 'balance';
          cate = 'execute_count';
          category = 'contract';
          sub_category = 'balance';
          break;
        }
        case 'local_comment': {
          key = 'local';
          cate = 'comment';
          category = 'local';
          sub_category = 'local';
          break;
        }
        case 'seller_comment': {
          key = 'seller';
          cate = 'comment';
          category = 'counseling';
          sub_category = 'seller';
          break;
        }
        case 'buyer_comment': {
          key = 'buyer';
          cate = 'comment';
          category = 'counseling';
          sub_category = 'buyer';
          break;
        }
        case 'etc_comment': {
          key = 'etc';
          cate = 'comment';
          category = 'counseling';
          sub_category = 'etc';
          break;
        }
        case 'advertisement_comment': {
          key = 'advertisement';
          cate = 'comment';
          category = 'advertisement';
          sub_category = 'advertisement';
          break;
        }
        case 'visit_comment': {
          key = 'visit';
          cate = 'comment';
          category = 'advertisement';
          sub_category = 'visit';
          break;
        }
        case 'tmp_contract_comment': {
          key = 'tmp_contract';
          cate = 'comment';
          category = 'contract';
          sub_category = 'tmp_contract';
          break;
        }
        case 'contract_comment': {
          key = 'contract';
          cate = 'comment';
          category = 'contract';
          sub_category = 'contract';
          break;
        }
        case 'balance_comment': {
          key = 'balance';
          cate = 'comment';
          category = 'contract';
          sub_category = 'balance';
          break;
        }
        default: {
          break;
        }
      }
      return {
        ...state,
        report: {
          ...state.report,
          [key]: {
            ...state.report[key],
            [cate]: cate != 'comment' ? parseInt(action.val) : action.val,
            report_date: refine_date,
            category: category,
            sub_category: sub_category,
          },
        },
      };
      //   report: state.report.map((ii, kk) => {
      //     if (kk == key) {
      //       if (cate != 'comment') ii[cate] = parseInt(action.val);
      //       else ii[cate] = action.val;
      //     }
      //     ii['report_date'] = refine_date;
      //     return ii;
      //   }),
      // };
    }

    case loading(AT.GET_DAILY_REPORT_LIST):
      return { ...state, [AT.GET_DAILY_REPORT_LIST]: Remote.Loading };
    case success(AT.GET_DAILY_REPORT_LIST):
      let data = {};
      if (action.report) {
        action.report.map((ii, kk) => {
          let refine_date = new Date(ii.report_date);
          refine_date.setTime(refine_date.getTime() + 60 * 60 * 9 * 1000);
          ii['report_date'] = refine_date.toISOString().substring(0, 10);

          data = { ...data, [ii.sub_category]: ii };
        });
      } else {
        data = {};
      }
      return { ...state, report: data };
    case failure(AT.GET_DAILY_REPORT_LIST):
      return {
        ...state,
        [AT.GET_DAILY_REPORT_LIST]: Remote.Failure(action.error),
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
