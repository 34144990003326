export const STEPS = {
  EDU: 'edu', //모바일의 경우 - 하단 카드만 보이는 상태
  EDU_M: 'edu_m', //모바일의 경우 - 펼침 상태
  SCHOOL: 'school',
  KINDER: 'kindergarten',
  DAYCARE: 'daycare',
};
export const EDU_STEP_LIST = [STEPS.SCHOOL, STEPS.EDU, STEPS.EDU_M, STEPS.KINDER, STEPS.DAYCARE];

//교육 상세보기 querystring 파라미터 명
export const EDU_DETAIL = 'edu_detail';

// 단지 상세 보기 모드 (단지 | 매물)
export const COMPLEX_DETAIL_MODE = { COMPLEX: 'COMPLEX', GOODS_LIST: 'GOODS_LIST', REALTOR: 'REALTOR' };

export const COMPLEX_DETAIL_MODE_STORAGE_KEY = 'buying.map-marker-mode'; // 단지를 클릭했을 때 어떤 정보로 랜딩 시킬지 (단지상세 or 매물리스트)
export const COMPLEX_DETAIL_MODE_STORAGE_KEY_OLD = 'buying.complex-detail-mode'; // 단지를 클릭했을 때 어떤 정보로 랜딩 시킬지 (단지상세 or 매물리스트)
export const COMPLEX_DETAIL_TRADETYPE_STORAGE_KEY = 'buying.complex-detail-tradetype'; // 단지상세 > 유저가 선택한 거래유형 (유저 관심 거래유형 유지가 목적)
export const COMPLEX_DETAIL_AREASIZE_STORAGE_KEY = 'buying.complex_detail-areasize'; // 단지상세 > 유저가 선택한 평형 (유저 관심 평형 유지가 목적)

/**
 * PS3, SC4 (학교류) 카테고리 코드의 경우, category_name 을 기준으로 분류가 필요
 * @param {String} targetCategory : 입력으로 들어온 카테고리 ex. SC4
 * @param {Array} categories : 점검해야하는 카테고리 후보군 ex. [PS3,SC4]
 * @param {String} targetKeyword : 입력으로 들어온 키워드 ex. 초등학교
 * @param {Array} originalList : 입력으로 들어온 데이터 목록
 */
export const filterSurroundings = (targetCategory, categories, targetKeyword, originalList) => {
  const result = [];
  if (categories.indexOf(targetCategory) > -1) {
    originalList.forEach((el) => {
      if (el.category_name.indexOf(targetKeyword) > -1) result.push(el);
    });
    return result;
  } else {
    return originalList;
  }
};
