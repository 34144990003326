import React from 'react';
import { useDispatch } from 'react-redux';
import routerService from '@/services/router';

const CloseButton = () => {
  const dispatch = useDispatch();

  function close() {
    dispatch(routerService.goBack());
  }
  return (
    <a onClick={close} className="btn_close_m">
      닫기
    </a>
  );
};

export default CloseButton;
