import React, { useState, useEffect } from 'react';
// import toggle from '@/hocs/toggle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/data/rootActions';
import * as selectors from '@/data/rootSelectors';
import { UserView } from '@/components/Views';
import classNames from 'classnames';

const Alarm = ({ color }) => {
  const [alarmShow, setAlarmShow] = useState(false);

  const myAlarmList = useSelector(selectors.myPage.getMyAlarmList);

  const handleAlarmClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAlarmShow(!alarmShow);
  };

  let uncheckedAlarms = [];

  useEffect(() => {
    uncheckedAlarms = myAlarmList.filter((x) => x.checked == false);
  }, [myAlarmList]);

  // return (
  //   <UserView>
  //     <span className="alarm">
  //       <a className={classNames('btn-img btn-alarm', { 'btn-alarm-wh': color === 'white' })} onClick={handleAlarmClick}>
  //         알람
  //       </a>
  //       {alarmShow ? (
  //         <>
  //           <div className="mask"></div>
  //           <div className="box_submenu">
  //             <span>
  //               <a className="btn-img btn-close-wh" onClick={handleAlarmClick}>
  //                 알람
  //               </a>
  //             </span>
  //             <ul>
  //               <li>
  //                 <a href="">알람1</a>
  //               </li>
  //               <li>
  //                 <a href="">알람2</a>
  //               </li>
  //               <li>
  //                 <a href="">알람3</a>
  //               </li>
  //               <li>
  //                 <a href="">알람4</a>
  //               </li>
  //               <li>
  //                 <a href="">디자인 변경은</a>
  //               </li>
  //               <li>
  //                 <a href="">나중에!</a>
  //               </li>
  //             </ul>
  //           </div>
  //         </>
  //       ) : (
  //         false
  //       )}

  //       {uncheckedAlarms.length ? <span className="new">N</span> : null}
  //       {/* <span className="new">N</span> */}
  //     </span>
  //   </UserView>
  // );
  return false;
};

export default Alarm;
