import React, { useEffect } from 'react';
import { USER_TYPES } from '@/pages/Login/views/constants';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { STEPS } from '@/pages/Login/BasicForm/helpers';

const UserTypeSelection = ({ userType, setUserType }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();

    const { name } = e.target;
    setUserType(name);

    if (name === 'realtor') {
      dispatch(actions.auth.setLoginStep(STEPS.BROKER));
    } else {
      dispatch(actions.auth.setLoginStep(STEPS.FIRST));
    }
  };

  const userTypes = [
    { type: USER_TYPES.NORMAL, label: '일반회원' },
    { type: USER_TYPES.REALTOR, label: '중개사회원' },
  ];
  const step = useSelector(selectors.auth.getLoginStep);

  useEffect(() => {
    if (step === '0') {
      setUserType(USER_TYPES.NORMAL);
    }
  }, [step]);

  return (
    <div className="popup-content-head">
      <div className="tab-display module-c style-a type-a large-2x" data-bui-tab="contentTab">
        <div className="tab-list">
          {userTypes?.map(({ type, label }) => {
            return (
              <React.Fragment key={type}>
                <span className={`tab-item ${userType === type && 'current'}`}>
                  <a className="tab-text" href="#" name={type} onClick={handleClick} style={{ cursor: 'pointer' }}>
                    {label}
                  </a>
                </span>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UserTypeSelection;
