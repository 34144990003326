import * as AT from './actionTypes';

export const regQNARequestError = ({ error }) => ({
  type: AT.REGQNA_REQUEST_ERROR,
  payload: {
    error,
  },
});

export const resetErrorState = () => ({
  type: AT.REGQNA_RESET_ERROR_STATE,
});

// ===================== 문의 제목,본문 ===================== //
export const setRegQNACategoryIdx = (category_idx) => ({
  type: AT.SET_REGQNA_CATEGORY_IDX,
  category_idx,
});
export const setRegQNATitle = (title) => ({
  type: AT.SET_REGQNA_TITLE,
  title,
});
export const setRegQNABody = (body) => ({
  type: AT.SET_REGQNA_BODY,
  body,
});

// ===================== 보내기 ===================== //
export const postRegQNA = () => ({
  type: AT.POST_REGQNA,
});
