import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as aActions from '@/data/rootActions';
import * as selectors from '../selectors';
import apis from '@/apis';

//Noticelist
export function* getNoticeList() {
  try {
    const page = yield select(selectors.internalNotice.getPage);
    const keyword = yield select(selectors.internalNotice.getKeyword);
    const payload = {
      page: page,
      keyword: keyword ? keyword : '',
      category: 'B',
    };
    const response = yield call(apis.brokerPageApi.getNoticeList, payload);
    //const { list } = response;
    yield put(actions.getNoticeListSuccess(response.rltObj));
    yield put(actions.setNoticeTotalCnt(response.totalCnt));
    yield put(actions.setNoticeKeyword(''));
    yield put(actions.setNoticeReal(response.rltObj2));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.getNoticeListFailure(e));
  }
}
export function* getNoticeDetail({ idx }) {
  try {
    const payload = {
      category: 'B',
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.getNoticeDetail, payload);
    //const { list } = response;
    yield put(actions.getNoticeDetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.getNoticeDetailFailure(e));
  }
}
