import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { naver } from '@/resources';
import { actions, selectors } from '@/data';
import { STEPS, makeProfile } from '@/pages/Login/BasicForm/helpers';
import env from '@/resources/env';
import { routeToNextStep } from '@/pages/Login/helpsers';

const Naver = ({ isBusy, data, isApp }) => {
  let callbackUrl = location.origin + '/login/naver';
  const dispatch = useDispatch();
  const step = useSelector(selectors.auth.getLoginStep);
  const smsAuthToken = useSelector(selectors.auth.getAuthToken);

  useEffect(() => {
    if (!isApp) {
      const naverLogin = new naver.LoginWithNaverId({
        clientId: env.NAVER_CLIENT_ID,
        callbackUrl: callbackUrl,
        isPopup: true,
        callbackHandle: false,
      });

      naverLogin.init();

      window.naverLogin = function(status, naverObj) {
        try {
          if (status) {
            const user = naverObj.user;
            const gender = user.getGender();
            const profile = makeProfile(gender, user.birthday);
            if (step === STEPS.PHONE4) {
              dispatch(
                actions.auth.sms4({
                  smsAuthToken: smsAuthToken,
                  memberIdx: data.memberIdx,
                  uid: user.id,
                  type: 'Naver',
                  email: user.email,
                  profile: profile,
                })
              );
            } else {
              dispatch(actions.auth.social1({ stype: 'Naver', uid: user.id, email: user.email, profile }));
            }
          }
        } catch {
          dispatch(actions.common.alert({ contents: '오류로 인해 네이버로그인에 실패하였습니다.' }));
          throw Error();
        }
      };
    }
  }, []);

  const handleClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(routeToNextStep('naver'));
    if (isApp) {
      try {
        const result = await window.dawin_instance.bridge.thirdPartyFunction.naverSignIn();
        dispatch(actions.auth.social1({ stype: 'Naver', accessToken: result.accessToken }));
      } catch {
        if (e.message !== 'user cancelled') {
          dispatch(actions.common.alert({ contents: '네이버로 로그인 처리 중 오류가 발생하였습니다.' }));
        }
      }
    }
  };

  return (
    <>
      <span className="button-area">
        <button
          type="submit"
          id="naverIdLogin_loginButton"
          className="btn module-b style-b type-fill social-02 large-1x flex symbol-ltr-brand-naver"
          onClick={handleClick}
          disabled={isBusy ? true : false}>
          <span className="btn-text">네이버로 시작하기</span>
        </button>
      </span>
    </>
  );
};

export default Naver;
