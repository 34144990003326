import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import useOptionalPhoneConfirm from '@/pages/Login/hooks/useOptionalPhoneConfirm';

const useCloseLoginPopup = () => {
  const dispatch = useDispatch();
  const { onOptionalPhoneConfirmAction } = useOptionalPhoneConfirm();

  const handleClose = () => {
    // 창 닫을 때 && 휴대폰 인증 단계 -> 로그인 완료
    onOptionalPhoneConfirmAction();

    // 창 닫기
    dispatch(actions.common.toggleLoginPopup(false));
  };
  return [handleClose];
};

export default useCloseLoginPopup;
