import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import DialogPopup from '@/components/popups/InfoPopups/Confirm/DialogPopup';
import { dialogPopup } from '@/publish/Front/_Component/popup/DialogPopup';

/**
 * @desc 사용자에게 알림 문구 제공과 예, 아니요등 응답을 받기위한 팝업
 * * 호출예시: common.confirm 검색
 */
const Confirm = () => {
  const dispatch = useDispatch();
  const { contents, cancelButtonText, confirmButtonText, onCancel, onConfirm, options } = useSelector(selectors.common.getConfirm);

  const clearConfirm = (callback) => {
    dispatch(actions.common.clearConfirm());
    if (callback) {
      callback();
    }
  };

  const onClose = () => {
    dialogPopup.inactive('dialogPopup');
    clearConfirm(onCancel);
  };

  const onConfirmWithClose = () => {
    dialogPopup.inactive('dialogPopup');
    clearConfirm(onConfirm);
  };

  return (
    <DialogPopup
      dialogPopup={dialogPopup}
      show={contents}
      contents={contents}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      onClose={onClose}
      onConfirm={onConfirmWithClose}
      clearConfirm={clearConfirm}
      {...options}
    />
  );
};

export default Confirm;
