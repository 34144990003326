import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as aActions from '@/data/rootActions';
import * as selectors from '../selectors';
import apis from '@/apis';

//dataRoom - formNformat
export function* form_getList() {
  try {
    const page = yield select(selectors.dataRoom.form_getPage);
    const keyword = yield select(selectors.dataRoom.form_getKeyword);
    const payload = {
      page: page,
      keyword: keyword ? keyword : '',
      category: 'A',
      subCategory: 'RG1',
    };
    const response = yield call(apis.brokerPageApi.dataRoom_getList, payload);
    //const { list } = response;
    yield put(actions.form_getListSuccess(response.rltObj));
    yield put(actions.form_setTotalCnt(response.totalCnt));
    yield put(actions.form_setKeyword(''));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.form_getListFailure(e));
  }
}
export function* form_getDetail({ idx }) {
  try {
    const payload = {
      category: 'A',
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.dataRoom_getDetail, payload);
    //const { list } = response;
    yield put(actions.form_getDetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.form_getDetailFailure(e));
  }
}
//dataRoom - precedent
export function* precedent_getList() {
  try {
    const page = yield select(selectors.dataRoom.precedent_getPage);
    const keyword = yield select(selectors.dataRoom.precedent_getKeyword);
    const payload = {
      page: page,
      keyword: keyword ? keyword : '',
      category: 'A',
      subCategory: 'RG3',
    };
    const response = yield call(apis.brokerPageApi.dataRoom_getList, payload);
    //const { list } = response;
    yield put(actions.precedent_getListSuccess(response.rltObj));
    yield put(actions.precedent_setTotalCnt(response.totalCnt));
    yield put(actions.precedent_setKeyword(''));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.precedent_getListFailure(e));
  }
}
export function* precedent_getDetail({ idx }) {
  try {
    const payload = {
      category: 'A',
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.dataRoom_getDetail, payload);
    //const { list } = response;
    yield put(actions.precedent_getDetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.precedent_getDetailFailure(e));
  }
}
//dataRoom - useful site
export function* useful_getList() {
  try {
    const page = yield select(selectors.dataRoom.useful_getPage);
    const keyword = yield select(selectors.dataRoom.useful_getKeyword);
    const payload = {
      page: page,
      keyword: keyword ? keyword : '',
      category: 'A',
      subCategory: 'RG2',
    };
    const response = yield call(apis.brokerPageApi.dataRoom_getList, payload);
    //const { list } = response;
    yield put(actions.useful_getListSuccess(response.rltObj));
    yield put(actions.useful_setTotalCnt(response.totalCnt));
    yield put(actions.useful_setKeyword(''));
    yield put(aActions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.useful_getListFailure(e));
  }
}
export function* useful_getDetail({ idx }) {
  try {
    const payload = {
      category: 'A',
      board_idx: idx,
    };
    //call api
    const response = yield call(apis.brokerPageApi.dataRoom_getDetail, payload);
    //const { list } = response;
    yield put(actions.useful_getDetailSuccess(response.rltObj[0]));
  } catch (e) {
    yield put(actions.useful_getDetailFailure(e));
  }
}
