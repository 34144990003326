import React from 'react';
import { actions } from '@/data';
import { OSTYPE } from '@/services/apps/types/OsType';
import call from '@/services/apps/call';

export const userPhonePopUp = ({ userName, phoneNumber }) => (dispatch) => {
  const osType = window.dawin_instance.osType;

  if (osType === OSTYPE.DESKTOP) {
    dispatch(
      actions.common.alert({
        contents: (
          <>
            <div className="board-head">
              <p className="board-subject">
                <span className="board-name">{phoneNumber}</span>
              </p>
            </div>
            <div className="board-body">
              <p className="para">
                <span className="wbr">고객님의 전화번호입니다.</span>
                <span className="wbr">개인 정보 보호를 위해</span>
                <span className="wbr">안심번호 형태로 제공됩니다.</span>
              </p>
            </div>
          </>
        ),
      })
    );
  } else {
    call(phoneNumber);
  }
};

export const userNoPhonePopUp = ({ userName, chat }) => (dispatch) => {
  dispatch(
    actions.common.confirm({
      contents: (
        <>
          <div className="board-head">
            <p className="board-subject">
              <span className="board-name">{userName}</span>
            </p>
          </div>
          <div className="board-body">
            <p className="para">
              <span className="wbr">전화번호가 등록되지 않았습니다.</span>
              <span className="wbr">채팅으로 문의해보세요</span>
            </p>
          </div>
        </>
      ),
      cancelButtonText: '창닫기',
      confirmButtonText: '채팅하기',
      onConfirm: () => {
        chat();
      },
    })
  );
};
