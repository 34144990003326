import { useState } from 'react';

const useQuickSettingPopup = () => {
  const [quickSettingPopupType, setQuickSettingPopupType] = useState(false);

  const handleQuickSettingPopup = async (type) => {
    setQuickSettingPopupType(type);
  };

  const handleClosePopup = () => {
    setQuickSettingPopupType(false);
  };

  return { quickSettingPopupType, handleQuickSettingPopup, handleClosePopup };
};

export default useQuickSettingPopup;
