import { parseCallBack, objectToQuerystring } from '@/services/Helper';
import { actions } from '@/data';

// querystring에 key=value 추가
// value값 없는 경우 value = true
export const routeToSomething = (isMobile, key, value = true) => (dispatch) => {
  if (key) {
    const path = getPath(key, value);
    const querystring = window.location.search;
    const queryObject = parseCallBack(querystring) || {};
    const watching = !!queryObject[key];

    if (watching || !isMobile) {
      dispatch(actions.router.replace(path));
    } else {
      dispatch(actions.router.push(path));
    }
  }
};

// URL
export const getPath = (key, value) => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  queryObject[key] = value;

  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};

// GOBACK URL
export const getBackPath = (key) => {
  const querystring = window.location.search;
  const queryObject = parseCallBack(querystring) || {};

  delete queryObject[key];

  const newQuerystring = objectToQuerystring(queryObject);
  return `${location.pathname}${newQuerystring}`;
};
