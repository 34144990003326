import toggle from '@/hocs/toggle';
import React, { useMemo } from 'react';

const Step3 = ({ memberList, selectedId, setSelectedId }) => {
  const handleSelect = (idx) => {
    setSelectedId(idx);
    // setData({ ...data, memberIdx: idx, email: email });
  };

  const selectList = useMemo(() => {
    if (memberList) {
      return memberList.map((item, key) => (
        <div className="option-item" key={key} onClick={() => handleSelect(item.mbr_idx, item.mbr_id)}>
          <div className="option-wrap">
            <span className="option-head">
              <span className="option-name">
                <label className="form radio module-a style-c type-line accent-01 small-1x">
                  <input type="radio" className="form-elem" name="select-id" onClick={() => handleSelect(item.mbr_idx, item.mbr_id)} />{' '}
                  <span className="form-text">{item.mbr_id}</span>
                </label>
              </span>
            </span>
          </div>
        </div>
      ));
    }
  }, [memberList, selectedId]);

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="">
            아이디 선택
          </label>
          {/* <span className="form-para">입력하신 정보와 일치하는 계정 중 문자인증 방식으로 사용하실 아이디를 선택해주세요.</span> */}
        </div>
        <div className="form-body">
          <div className="option-display module-a style-a type-c">
            <div className="option-list">{selectList}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default toggle(Step3);
