export const codes = {
  PlatArea: 'PlatArea',
  TotalArea: 'TotalArea',
  ExclusiveArea: 'ExclusiveArea',
  SupplyiveArea: 'SupplyiveArea',
  ContractArea: 'ContractArea',
};

const _names = {
  [codes.PlatArea]: '대지',
  [codes.TotalArea]: '연',
  [codes.ExclusiveArea]: '전용',
  [codes.SupplyiveArea]: '공급',
  [codes.ContractArea]: '계약',
};

export const getName = (code) => {
  return _names[code];
};

const apiKeys = {
  [codes.PlatArea]: 'plat_area',
  [codes.TotalArea]: 'total_area',
  [codes.ExclusiveArea]: 'exclusive_area',
  [codes.SupplyiveArea]: 'supply_area',
  [codes.ContractArea]: 'supply_area',
};

const getApiKey = (code) => {
  return apiKeys[code];
};

export default { codes, getName, getApiKey };
