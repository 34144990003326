import * as sagas from './sagas';
import * as AT from './actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';

export default function*() {
  yield takeLatest(AT.GET_ANALYSIS_CANDIDATE_LIST_SEARCH, sagas.getCandidateListSearch);
  yield takeEvery(AT.GET_ANALYSIS_DISPLAY_LIST_MAP, sagas.getDisplayListMap);
  yield takeLatest(AT.POST_ADD_POSITIVES, sagas.postAddPositives); //개발호재
  yield takeLatest(AT.GET_ANALYSIS_WEEKLY_PRICE_INFO, sagas.getWeeklyPriceInfo); //주간 실거래가
  yield takeLatest(AT.GET_ANALYSIS_RANKING_RESULT_LIST, sagas.getRankingList); //랭킹
  yield takeLatest(AT.COMPARE_APT, sagas.compareApt); //아파트 비교
  yield takeLatest(AT.GET_MIN_MAX_PRICE, sagas.getMinMaxPrice); //최저가 최고가
  yield takeLatest(AT.GET_RECONSTRUCT_SCORE_DETAIL, sagas.getReconstructScoreDetail); //재건축 사업성 상세
  yield takeLatest(AT.GET_RECONSTRUCT_INVEST_INFO, sagas.getReconstructInvestInfo); //재건축 사업성 상세
  yield takeLatest(AT.GET_RECONSTRUCT_INVEST_INFO_WITH_SELECT_CHANGE, sagas.getReconstructInvestInfoWithSelectChange);
  yield takeLatest(AT.GET_SCHOOL_WITH_DETAIL, sagas.getSchoolWithDetail);
  yield takeLatest(AT.POST_REPORT, sagas.postReport);
  yield takeLatest(AT.REQ_GET_RECOMMEND_RESULT, sagas.reqGetRecommend);
}
