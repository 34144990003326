import React, { useEffect } from 'react';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';
import { STEPS, makeProfile } from '@/pages/Login/BasicForm/helpers';
import { parseCallBack } from '@/services/Helper';
import { objectToQuerystring } from '@/apis/helpers';
import { routeToNextStep } from '@/pages/Login/helpsers';
import { OSTYPE } from '@/services/apps/types/OsType';

const Kakao = ({ data, isApp }) => {
  const dispatch = useDispatch();
  const step = useSelector(selectors.auth.getLoginStep);
  const smsAuthToken = useSelector(selectors.auth.getAuthToken);
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);

  const params = parseCallBack(window.location.search);

  useEffect(() => {
    if (params.code) {
      fetch('https://kauth.kakao.com/oauth/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
        body: objectToQuerystring({
          grant_type: 'authorization_code',
          // client_id: '913b0f99229e37de7116db23161bd0a7',
          client_id: 'b4f31ddaca78377142ab00ecfc2e3cd3',
          redirect_uri: window.location.origin + '/login',
          code: params.code,
        }).replace('?', ''),
      })
        .then((res) => res.json())
        .then((result) => {
          window.Kakao.Auth.setAccessToken(result.access_token);
          window.Kakao.API.request({
            url: '/v2/user/me',
            success: function(response) {
              const state = JSON.parse(params.state);

              const kProfile = makeProfile(response.kakao_account.gender, response.kakao_account.birthday);
              if (state.step == STEPS.PHONE4) {
                dispatch(
                  actions.auth.sms4({
                    smsAuthToken: state.smsAuthToken,
                    memberIdx: state.memberIdx,
                    uid: response.id,
                    type: 'Kakao',
                    email: response.kakao_account.email,
                    profile: kProfile,
                  })
                );
              } else {
                dispatch(actions.auth.social1({ stype: 'Kakao', uid: response.id, email: response.kakao_account.email, profile: kProfile }));
              }
            },
            fail: function() {
              alert('카카오 로그인에 실패하였습니다.');
              window.Kakao.API.request({
                url: '/v1/user/unlink',
              });
              dispatch(actions.router.replace('/login'));
            },
          });
        });
    }
  }, [params.code]);

  function handleLogin(e) {
    e.preventDefault();

    const isApp = osTypeInstance?.isApp;

    // SignIn Cancel에 대한 예외처리
    // Thirdparty signin 실행시에 유저가 취소 버튼을 누른 경우 code USE_100 으로 error가 발생하도록 대응
    // 이외의 다른 signin 관련 에러는 code USE_10 으로 error가 발생함
    dispatch(loginWithKakao(isApp, data, step, smsAuthToken));
  }

  return (
    <span className="button-area">
      <button type="submit" className="btn module-b style-b type-fill social-01 large-1x flex symbol-ltr-brand-kakao" onClick={handleLogin}>
        <span className="btn-text">카카오톡으로 시작하기</span>
      </button>
    </span>
  );
};

export default Kakao;

const loginWithKakao = (isApp, memberData, step, smsAuthToken) => async (dispatch) => {
  dispatch(routeToNextStep('kakao'));
  const memberIdx = memberData?.memberIdx;

  if (isApp) {
    try {
      const result = await window.dawin_instance.bridge.thirdPartyFunction.kakaoSignIn();
      dispatch(actions.auth.social1({ stype: 'Kakao', accessToken: result.accessToken }));
    } catch (e) {
      if (e.message !== 'user cancelled') {
        dispatch(actions.common.alert({ contents: '카카오로 로그인 처리 중 오류가 발생하였습니다.' }));
      }
    }
  } else {
    window.Kakao.Auth.login({
      success: function() {
        window.Kakao.API.request({
          url: '/v2/user/me',
          success: function(response) {
            // age_range: "30~39"
            // age_range_needs_agreement: false
            // birthday: "0109"
            const kProfile = makeProfile(response.kakao_account.gender, response.kakao_account.birthday);
            if (step === STEPS.PHONE4) {
              dispatch(
                actions.auth.sms4({
                  smsAuthToken: smsAuthToken,
                  memberIdx: memberIdx,
                  uid: response.id,
                  type: 'Kakao',
                  email: response.kakao_account.email,
                  profile: kProfile,
                })
              );
            } else {
              dispatch(actions.auth.social1({ stype: 'Kakao', uid: response.id, email: response.kakao_account.email, profile: kProfile }));
            }
          },
          fail: function(e) {
            alert('카카오 로그인에 실패하였습니다.');
            window.Kakao.API.request({
              url: '/v1/user/unlink',
            });
          },
        });
      },
      fail: function() {
        alert('카카오 로그인에 실패하였습니다.');
      },
    });
  }
};
