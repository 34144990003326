import React from 'react';
import { actions } from '@/data';
import { FeedTypeKor, TagType } from '@/services/Community';
import { useDispatch } from 'react-redux';
import { routeToFeedDetail, tagStyle } from '@/combinedComp/Community/helpers';
import { Link } from 'react-router-dom';
import { backToText } from '@/services/Helper/linkify';
import router from '@/services/router';
import { formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';

const PostCard = ({ item, isLoggedIn }) => {
  const dispatch = useDispatch();

  const querystring = router.querystring.parse();

  const onClickFeedDetail = (e) => {
    e.preventDefault();
    handleClose(e);
    if (!isLoggedIn) {
      dispatch(actions.common.pleaseLoginPopup(true));
    } else {
      dispatch(routeToFeedDetail(item.id, false));
      dispatch(actions.community.setUserHistoryTarget(null));
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    delete querystring['userHistory'];
    dispatch(actions.router.replace(`${location.pathname}${router.querystring.stringify(querystring)}`));
    dispatch(actions.community.setUserHistoryTarget(null));
  };

  let contents = '';
  if (item?.contents) {
    const text = backToText(item?.contents)
      .replace(/(<\/br>|<br\/>|<br \/>|<br>)/g, '\n')
      .replace(/<script>/g, '');
    contents = text;
  }
  const likeCount = item?.reactionCount?.map((i, index) => {
    return i?.type == 'LIKE' && i?.count;
  });

  return (
    <>
      <div className="posts-item">
        <div className="posts-wrap">
          <div className="posts-inform">
            <div className="posts-info">
              <p className="data-list">
                <span className="data-item posted">
                  <span className="head">등록일</span>
                  <span className="body">{formatDate(item?.updatedAt)}</span>
                </span>
              </p>
            </div>

            <div className="posts-head">
              {item?.images?.length > 0 ? (
                <p className="posts-picture">
                  <Link className="posts-thumbnail" to="#" onClick={onClickFeedDetail}>
                    <img className="image" src={item?.images[0]?.url} alt="" />
                  </Link>
                </p>
              ) : (
                <></>
              )}
              <p className="posts-subject">
                {item?.feedType === 'POST_FOR_SALE' ? (
                  <Link to="#" className="posts-type" onClick={(e) => onClickFeedDetail(e, item)}>
                    <span className="wbr">새로 등록된 다윈매물입니다.</span>
                  </Link>
                ) : (
                  <></>
                )}
                {item?.status === 'DELETED' && (
                  <Link to="#" className="posts-type" onClick={(e) => onClickFeedDetail(e, item)}>
                    <span className="wbr">삭제된 게시글입니다.</span>
                  </Link>
                )}
                {contents ? (
                  <Link className="posts-name" to="#" onClick={onClickFeedDetail}>
                    <span className="wbr">{contents}</span>
                  </Link>
                ) : (
                  false
                )}
              </p>
            </div>
            <div className="posts-tags">
              <p className="data-list">
                {item?.tags
                  ?.filter((item) => item.type !== TagType.FLAG_TAG)
                  ?.map((ff) => ff?.name)
                  .filter(String) // 공백태그 제거
                  .map((name) => (
                    <span className="data-item" key={name}>
                      #{name}
                    </span>
                  ))}
              </p>
            </div>
            <div className="posts-data">
              <p className="data-list">
                <span className="data-item type">
                  <span className="head">분류</span>
                  <span className="body">
                    <span className={`mark module-c style-a type-fill ${tagStyle(item?.feedType)} small-2x`}>
                      <span className="mark-text">{FeedTypeKor[item?.feedType]}</span>
                    </span>
                  </span>
                </span>
              </p>
              <p className="data-list">
                <span className="data-item likeit">
                  <span className="head">좋아요</span>
                  <span className="body">{likeCount}</span>
                </span>
                <span className="data-item comment">
                  <span className="head">댓글</span>
                  <span className="body">{item?.commentCount}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCard;
