import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from '@/data';
import ReportBroker from '@/pages/ReportBroker';
import { TYPES } from './helper';
import { DAWIN_URL } from '@/resources/global';
import Mask from '@/components/Mask';

const candidates = { [TYPES.REPORT_BROKER]: ReportBroker };

const GlobalDualPopups = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectors.common.isMobile);
  const globalDualPopup = useSelector(selectors.common.getGlobalDualPopup);
  const Compo = candidates[globalDualPopup];

  useEffect(() => {
    if (globalDualPopup && isMobile) {
      const url = DAWIN_URL[globalDualPopup];
      //모바일이면 url 이동
      if (url) {
        dispatch(actions.router.push(url));
        dispatch(actions.common.closeGlobalDualPopup());
      }
    }
  }, [globalDualPopup]);

  //팝업 창 닫기
  const handleClose = (e) => {
    if (e) e.preventDefault();
    dispatch(actions.common.closeGlobalDualPopup());
  };

  return globalDualPopup && Compo && !isMobile ? (
    <>
      <Mask show={true} zIndex="5001" />
      <Compo handleClose={handleClose} />
    </>
  ) : (
    false
  );
};

export default GlobalDualPopups;
