import { TagType, getRealtyCode, validRealtyTypes } from '@/services/Community';
import React, { useEffect } from 'react';
import GoodsTypeItem from './GoodsTypeItem';

const GoodsType = ({ tags, setTags, isUpdate, data, setData, selectedGoodsTypes, setSelectedGoodsTypes }) => {
  useEffect(() => {
    if (isUpdate) {
      const complex = tags?.filter((tag) => tag.type === TagType.GOODS_TYPE_TAG);

      let complexArray = [];
      complex?.map((tag) => {
        complexArray?.push(getRealtyCode(tag?.key));
      });

      setSelectedGoodsTypes(...selectedGoodsTypes, complexArray);
    }
  }, [isUpdate]);

  useEffect(() => {
    const goodsTypeTags = tags?.filter((tag) => tag?.type === 'GOODS_TYPE_TAG');
    const complexTags = tags?.filter((tag) => tag?.type === 'COMPLEX_TAG');

    if (goodsTypeTags?.length >= 2 && complexTags?.length >= 1) {
      setData({ ...data, selectedComplex: {} });
    }
  }, [tags]);

  return (
    <div className="form-item">
      <div className="form-wrap">
        <div className="form-head">
          <label className="form-name" htmlFor="#">
            매물유형
          </label>
          <p className="form-summary">복수 선택 가능합니다.</p>
        </div>
        <div className="form-body">
          <div className="form-area">
            <div className="option-list module-b style-a type-a">
              {validRealtyTypes?.map((type, index) => (
                <GoodsTypeItem
                  type={type}
                  tags={tags}
                  setTags={setTags}
                  key={index}
                  selectedGoodsTypes={selectedGoodsTypes}
                  setSelectedGoodsTypes={setSelectedGoodsTypes}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodsType;
