import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//검색 조건
export const delImage = (complex_upload_img_idx) => ({
  type: AT.DEL_COMPLEX_IMAGE,
  complex_upload_img_idx,
});
export const setCpImage = (fullurl, file_name, content_type, complexIdx) => ({
  type: AT.SET_COMPLEX_IMAGE,
  complexIdx,
  fullurl,
  file_name,
  content_type,
});

//요약본
export const getCpBasicInfo = (info) => ({
  type: AT.GET_COMPLEX_BASIC_INFO,
  info,
});
export const getComplexImgList = (complexIdx) => ({
  type: AT.GET_COMPLEX_IMG_LIST,
  complexIdx,
});
export const getComplexImgListLoading = () => ({
  type: AT.GET_COMPLEX_IMG_LIST_LOADING,
});
export const getComplexImgListSuccess = (complexImgList) => ({
  type: AT.GET_COMPLEX_IMG_LIST_SUCCESS,
  complexImgList,
});
export const getComplexImgListFailure = (error) => ({
  type: AT.GET_COMPLEX_IMG_LIST_FAILURE,
  error,
});
