import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';

import useWindowSize from './useWindowSize';
import useAuthCheck from './useAuthCheck';

import useAppVersionCheck from './useAppVersionCheck';
import useAppOsType from './useAppOsType';
import useAppPermission from './useAppPermission';
import useAppCamera from './useAppCamera';

// App.js에서 호출
// 사이트 로딩시 실행해야 하는 action들
const useInitialize = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  // 엑세스토큰 체크
  useAuthCheck({ dispatch, actions, isLoggedIn });

  // 앱 osType instance redux state에 저장
  useAppOsType();

  // 앱 권한
  useAppPermission({ useSelector, selectors, isLoggedIn });

  // 윈도우 사이즈에 따른 event listener 추가
  useWindowSize(dispatch, actions);

  // 앱 버전체크 (iOS) & 앱 업데이트 유도
  useAppVersionCheck({ dispatch });

  // 앱 카메라 권한 event listener 추가 (오직 file type input 클릭시)
  useAppCamera({ useSelector, selectors });
};

export default useInitialize;
