import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';
import { STATUS } from '../utils';

export function* getComplexList({ payload = {} }) {
  const { showSpinner, dongCode } = payload;
  try {
    const data = {
      dong_code: yield select(selectors.getDongRiCode),
    };
    yield put(actions.getComplexListLoading());

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }
    const response = yield call(apis.addressApi.getComplexList, data);
    const { list } = response;

    yield put(actions.getComplexListSuccess(list));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getComplexListFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_LIST_ERROR));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getComplexInfo() {
  try {
    const { info } = yield call(apis.addressApi.getComplexInfo, {
      type: yield select(selectors.getItemType),
      complexIdx: yield select(selectors.getComplexIdx),
    });

    // console.log('SAGAS_getComplexInfo', info)

    if (!info) {
      yield put(actions.setComplexInfo({}));
    } else {
      yield put(actions.setComplexInfo(info));
    }
  } catch (e) {
    console.log('error');
  }
}

// export function* getComplexLocation() {
//   try {
//     const itemInfo = yield select(selectors.getItemInfo);
//     const complexIdx = yield itemInfo[CONSTANTS.TARGET_DATA_KEY_COMPLEX_IDX];

//     if (complexIdx) {
//       const payload = {
//         complexIdx: complexIdx,
//       };

//       yield put(actions.getComplexLocationLoading());

//       const response = yield call(apis.addressApi.getComplexLocation, payload);
//       const { complexData } = response;

//       // 위치 받아오면, 직접 집내놓기 내에서 사용되는 지도의 중심점도 설정
//       yield put(actions.getComplexLocationSuccess(complexData));
//     } else {
//       yield put(actions.getComplexLocationSuccess(null));
//     }
//   } catch (e) {
//     yield put(actions.getComplexLocationFailure(text.addressText.addressTextCommon.GET_COMPLEX_LOCATION_ERROR));
//   }
// }

// ============================ 단지 상세 정보 ============================ //
export function* getAddrSiDo({ only_available, only_broker, payload = {} }) {
  const { showSpinner } = payload;
  try {
    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(true));
    }
    const { list } = yield call(apis.addressApi.getAddrSiDo, only_available, only_broker);
    if (!list) {
      console.log('error');
      yield put(actions.setAddrSiDo([]));
    } else {
      yield put(actions.setAddrSiDo(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getAddrSiGunGu({ sido_code, only_available, only_broker, payload = {} }) {
  const { showSpinner } = payload;

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.addressApi.getAddrSiGunGu, {
      si_do_code: sido_code ? sido_code : yield select(selectors.getSiDoCode),
      only_available,
      only_broker,
    });

    if (!list) {
      console.log('error');
      yield put(actions.setAddrSiGunGu([]));
    } else {
      yield put(actions.setAddrSiGunGu(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

/**
 * TODO: 위의 getAddrSiGunGu 를 아래 2단계로 나눔 위 코드는 테스트 후 삭제?
 * 시군구 2 step으로 쪼개기
 */
export function* getAddrSiGunGuFirst({ sido_code, payload = {} }) {
  const { showSpinner } = payload;

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.addressApi.getAddrSiGunGuFirst, {
      // si_do_code: sido_code ? sido_code : yield select(selectors.getSiDoCode),
      si_do_code: sido_code,
    });

    if (!list) {
      console.log('error');
      yield put(actions.setAddrSiGunGu([]));
    } else {
      yield put(actions.setAddrSiGunGu(list));
      const sigungu = select(selectors.getSiDoCode);
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getAddrSiGunGuSecond({ sigungu_four_digit_code, payload = {} }) {
  const { showSpinner } = payload;
  // 처음부터 4자리 코드
  //  const code = sido_code ? sido_code : yield select(selectors.getSiDoCode);
  const code = sigungu_four_digit_code ? sigungu_four_digit_code : yield select(selectors.getSiGunGuFourDigitCode);

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.addressApi.getAddrSiGunGuSecond, {
      //si_do_code: sido_code ? sido_code : yield select(selectors.getSiDoCode),
      sigungu_four_digit_code: code,
    });
    if (!list) {
      console.log('2차 시군구 필요없음');
    } else {
      yield put(actions.setAddrSiGunGuSecond(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getAddrDongRi({ si_gun_gu_code, only_available, only_broker, payload = {} }) {
  const { showSpinner } = payload;

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.addressApi.getAddrDongRi, {
      si_gun_gu_code: si_gun_gu_code ? si_gun_gu_code : yield select(selectors.getSiGunGuCode),
      only_available,
      only_broker,
    });
    if (!list) {
      console.log('error');
      yield put(actions.setAddrDongRi([]));
    } else {
      yield put(actions.setAddrDongRi(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

/**
 * 2021-08-13 @장혁진 수정
 * getAddrDongRi를 First와 Second로 쪼갬
 */

export function* getAddrDongRiFirst({ si_gun_gu_code, payload = {} }) {
  const { showSpinner } = payload;
  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.addressApi.getAddrDongRiFirst, {
      si_gun_gu_code,
    });

    if (!list) {
      console.log('error');
      yield put(actions.setAddrDongRi([]));
    } else {
      yield put(actions.setAddrDongRi(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

export function* getAddrDongRiSecond({ dongri_eight_digit_code, payload = {} }) {
  const { showSpinner } = payload;
  const code = dongri_eight_digit_code ? dongri_eight_digit_code : yield select(selectors.getDongRiEightDigitCode);

  try {
    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    const { list } = yield call(apis.addressApi.getAddrDongRiSecond, {
      dongri_eight_digit_code: code,
    });
    if (!list) {
      console.log('error');
      yield put(actions.setAddrDongRi([]));
    } else {
      // 2번째 리스트 저장
      yield put(actions.setAddrDongRiSecond(list));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}

// 배지율 추가
export function* getSidoList({ onlyAvailable, onlyBroker, setter, showGlobalSpinner }) {
  try {
    // 로딩중 on
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(true));
    if (setter) setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const { list } = yield call(apis.addressApi.getAddrSiDo, onlyAvailable, onlyBroker);
    if (setter) setter({ status: STATUS.SUCCESS, list: list ? list : [] });
    else yield put(actions.setAddrSiDo(list ? list : []));

    // 로딩중 off
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    // 실패 처리
    if (setter) setter({ status: STATUS.FAILURE });
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getSigunguList1({ sidoCode, setter, showGlobalSpinner }) {
  try {
    // 로딩중 on
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner({ show: true, theme: 'light' }));
    if (setter) setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const { list } = yield call(apis.addressApi.getAddrSiGunGuFirst, { si_do_code: sidoCode });
    if (setter) setter({ status: STATUS.SUCCESS, list: list ? list : [] });
    else yield put(actions.setAddrSiGunGu(list ? list : []));

    // 로딩중 off
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    // 실패 처리
    if (setter) setter({ status: STATUS.FAILURE });
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getSigunguList2({ sigungu_four_digit_code, setter, showGlobalSpinner }) {
  // 처음부터 4자리 코드
  const code = sigungu_four_digit_code;

  try {
    // 로딩중 on
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner({ show: true, theme: 'light' }));
    if (setter) setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const { list } = yield call(apis.addressApi.getAddrSiGunGuSecond, { sigungu_four_digit_code: code });
    if (list) {
      if (setter) setter({ status: STATUS.SUCCESS, result: list });
      else yield put(actions.setAddrSiGunGuSecond(list));
    }

    // 로딩중 off
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    // 실패 처리
    if (setter) setter({ status: STATUS.FAILURE });
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getSigungu2OrDongList({ sigunguCode, sigungu2Setter, dongSetter, showGlobalSpinner }) {
  // 처음부터 4자리 코드
  const sigungu_four_digit_code = sigunguCode.substr(0, 4);

  try {
    // 로딩중 on
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner({ show: true, theme: 'light' }));
    if (sigungu2Setter) sigungu2Setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const { list } = yield call(apis.addressApi.getAddrSiGunGuSecond, { sigungu_four_digit_code });
    if (sigungu2Setter) sigungu2Setter({ status: STATUS.SUCCESS, list: list });
    else yield put(actions.setAddrSiGunGuSecond(list));

    if (list && list.length > 0) {
      if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
    } else {
      // 데이터가 없는 경우 바로 동 코드 리스트 조회
      yield put(actions.getDongList1({ sigunguCode, setter: dongSetter, showGlobalSpinner }));
    }
  } catch (e) {
    // 실패 처리
    if (sigungu2Setter) sigungu2Setter({ status: STATUS.FAILURE });
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getDongList1({ sigunguCode, setter, showGlobalSpinner }) {
  try {
    // 로딩중 on
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner({ show: true, theme: 'light' }));
    if (setter) setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const { list } = yield call(apis.addressApi.getAddrDongRiFirst, { si_gun_gu_code: sigunguCode });
    if (setter) setter({ status: STATUS.SUCCESS, list: list ? list : [] });
    else yield put(actions.setAddrDongRi(list ? list : []));

    // 로딩중 off
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    // 실패 처리
    if (setter) setter({ status: STATUS.FAILURE });
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getDongList2({ dongri_eight_digit_code, setter, showGlobalSpinner }) {
  const code = dongri_eight_digit_code;
  try {
    // 로딩중 on
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner({ show: true, theme: 'light' }));
    if (setter) setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const { list } = yield call(apis.addressApi.getAddrDongRiSecond, { dongri_eight_digit_code: code });
    if (list) {
      if (setter) setter({ status: STATUS.SUCCESS, list: list });
      else yield put(actions.setAddrDongRiSecond(list));
    }

    // 로딩중 off
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    // 실패 처리
    if (setter) setter({ status: STATUS.FAILURE });
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getComplexListByDong({ dongCode, setter, showGlobalSpinner }) {
  try {
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner({ show: true, theme: 'light' }));
    if (setter) setter({ status: STATUS.LOADING });

    // 데이터 조회, 결과값 반환
    const data = { dong_code: dongCode };
    const response = yield call(apis.addressApi.getComplexList, data);
    const { list } = response;

    if (setter) setter({ status: STATUS.SUCCESS, list: list });
    else yield yield put(actions.getComplexListSuccess(list));

    // 로딩중 off
    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  } catch (e) {
    if (setter) setter({ status: STATUS.FAILURE });
    else yield put(actions.getComplexListFailure(text.sellingText.sellingTextCommon.GET_COMPLEX_LIST_ERROR));

    if (showGlobalSpinner) yield put(allActions.common.toggleGlobalSpinner(false));
  }
}

export function* getComplexDongList() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const complexIdx = yield itemInfo.tb_cb_complex_idx; //select(selectors.getComplexIdx);
    if (complexIdx) {
      const payload = { tb_cb_complex_idx: complexIdx };
      yield put(actions.getComplexDongListLoading());
      const response = yield call(apis.addressApi.getComplexDongList, payload);
      const { list } = response;
      yield put(actions.getComplexDongListSuccess(list));
    } else {
      yield put(actions.getComplexDongListSuccess(false));
    }
  } catch (e) {
    yield put(actions.getComplexDongListFailure(text.addressText.addressTextCommon.GET_COMPLEX_DETAIL_ERROR));
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------
export function* getComplexDongList2({ payload = {} }) {
  const { showSpinner } = payload;

  try {
    const savedForm = yield select(selectors.getSavedForm);
    const complexIdx = savedForm?.tb_cb_complex_idx;

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    if (complexIdx) {
      const payload = { tb_cb_complex_idx: complexIdx };
      yield put(actions.getComplexDongListLoading());
      const response = yield call(apis.addressApi.getComplexDongList, payload);
      const { list } = response;
      yield put(actions.getComplexDongListSuccess(list));
    } else {
      yield put(actions.getComplexDongListSuccess(false));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    yield put(actions.getComplexDongListFailure(text.addressText.addressTextCommon.GET_COMPLEX_DETAIL_ERROR));

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------

export function* getFloorList() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const tb_cb_complex_idx = yield select(selectors.getComplexIdx);
    const dong = itemInfo.dong;
    const data = {
      tb_cb_complex_idx: tb_cb_complex_idx,
      complex_dong: dong,
    };

    if (tb_cb_complex_idx && dong) {
      const result = yield call(apis.addressApi.getFloorList, data);
      if (!result?.list) {
        yield put(actions.setFloorList(false));
      } else {
        const list = result?.list;
        yield put(actions.setFloorList(list));

        const total_floor_no = itemInfo.total_floor;
        if (list.length > 0 && !total_floor_no) {
          // total_floor_no가 있는 경우 업데이트 X, 사용자가 이미 변경한 데이터일 수 있음
          yield put(actions.changeItemInfo('total_floor', list[list.length - 1].floor));
        }
      }
    } else {
      yield put(actions.setFloorList(false));
    }
  } catch (e) {
    console.log('error');
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------
export function* getFloorList2({ payload = {} }) {
  const { showSpinner } = payload;
  try {
    const savedForm = yield select(selectors.getSavedForm);
    const complexIdx = savedForm?.tb_cb_complex_idx;
    const dong = savedForm?.dong;

    const data = {
      tb_cb_complex_idx: complexIdx,
      complex_dong: dong,
    };

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }

    if (complexIdx && dong) {
      const result = yield call(apis.addressApi.getFloorList, data);
      if (!result?.list) {
        yield put(actions.setFloorList(false));
      } else {
        const list = result?.list;
        yield put(actions.setFloorList(list));

        // const total_floor_no = itemInfo[CONSTANTS.TARGET_DATA_KEY_TOTAL_FLOOR_NO];
        const total_floor_no = savedForm?.total_floor;

        if (list.length > 0 && !total_floor_no) {
          // total_floor_no가 있는 경우 업데이트 X, 사용자가 이미 변경한 데이터일 수 있음
          // yield put(actions.changeItemInfo(CONSTANTS.TARGET_DATA_KEY_TOTAL_FLOOR_NO, list[list.length - 1].floor));
          yield put(
            actions.updateSavedForm({
              total_floor: list[list.length - 1].floor,
            })
          );
        }
      }
    } else {
      yield put(actions.setFloorList(false));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}
// [[NEW LOGIC]]-----------------------------------------------------------

export function* getHoList() {
  try {
    const itemInfo = yield select(selectors.getItemInfo);
    const tb_cb_complex_idx = yield select(selectors.getComplexIdx);
    const complex_dong = yield itemInfo.dong;
    const complex_floor = yield itemInfo.floor;
    if (tb_cb_complex_idx && complex_dong && complex_floor) {
      const data = { tb_cb_complex_idx, complex_dong, complex_floor };
      const { list } = yield call(apis.addressApi.getHoList, data);
      yield put(actions.setHoList(list));
    } else {
      yield put(actions.setHoList(false));
    }
  } catch (e) {
    console.log('error');
  }
}

// [[NEW LOGIC]]-----------------------------------------------------------
export function* getHoList2({ payload = {} }) {
  const { showSpinner } = payload;

  try {
    const savedForm = yield select(selectors.getSavedForm);
    const tb_cb_complex_idx = savedForm?.tb_cb_complex_idx;
    const complex_dong = savedForm?.dong;
    const complex_floor = savedForm?.floor;

    if (showSpinner) {
      // [DX-1757] [w] 집내놓기 > 수정 내용 (1. step1에서 잠시만 기다려주세요 화면 없어도 될 듯.)
      yield put(
        allActions.common.toggleGlobalSpinner({
          show: true,
          theme: 'light',
        })
      );
    }
    if (tb_cb_complex_idx && complex_dong && complex_floor) {
      const data = { tb_cb_complex_idx, complex_dong, complex_floor };
      const { list } = yield call(apis.addressApi.getHoList, data);
      yield put(actions.setHoList(list));
    } else {
      yield put(actions.setHoList(false));
    }

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  } catch (e) {
    console.log('error');

    if (showSpinner) {
      yield put(allActions.common.toggleGlobalSpinner(false));
    }
  }
}
