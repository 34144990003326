import React from 'react';

export const TYPES = {
  myArea: 'MY_AREA',
  myComplex: 'MY_COMPLEX',
  myHome: 'MY_HOME',
  recentArea: 'RECENT_AREA',
  recentComplex: 'RECENT_COMPLEX',
};

export const getTitle = (code) => {
  const quickSettingTitles = {
    [TYPES.myArea]: '관심지역',
    [TYPES.myComplex]: '관심단지',
    [TYPES.myHome]: '우리집',
    [TYPES.recentArea]: '최근 본 지역',
    [TYPES.recentComplex]: '최근 본 단지',
  };
  return quickSettingTitles[code];
};

export const getEmptyMessage = (code) => {
  const quickSettingContents = {
    [TYPES.myArea]: (
      <>
        <div className="board-body">
          <p className="para">
            <span className="wbr">등록된 지역이 없습니다.</span>
          </p>
          <p className="para">
            <span className="wbr">마이페이지에서 추가할 수 있습니다.</span>
          </p>
        </div>
      </>
    ),
    [TYPES.myComplex]: (
      <>
        <div className="board-body">
          <p className="para">
            <span className="wbr">등록된 단지가 없습니다.</span>
          </p>
          <p className="para">
            <span className="wbr">마이페이지에서 추가할 수 있습니다.</span>
          </p>
        </div>
      </>
    ),
    [TYPES.myHome]: (
      <>
        <div className="board-body">
          <p className="para">
            <span className="wbr">우리집이 등록되지 않았습니다.</span>
          </p>
          <p className="para">
            <span className="wbr">마이페이지에서 추가할 수 있습니다.</span>
          </p>
        </div>
      </>
    ),
    [TYPES.recentArea]: (
      <>
        <div className="board-body">
          <p className="para">
            <span className="wbr">최근에 본 지역이 없습니다.</span>
          </p>
        </div>
      </>
    ),
    [TYPES.recentComplex]: (
      <>
        <div className="board-body">
          <p className="para">
            <span className="wbr">최근에 본 단지가 없습니다.</span>
          </p>
        </div>
      </>
    ),
  };

  return quickSettingContents[code];
};
