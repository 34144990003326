import * as AT from './actionTypes';

export const getCheckedValue = (state) => state?.brokerPage?.setArea?.viewChecked;
export const getRadioCheck = (state) => state?.brokerPage?.setArea?.radioCheck;
//export const getSettingYn = (state) => state?.brokerPage?.setArea?.setting_yn;
export const getSetAreaListSuccess = (state) => state?.brokerPage?.setArea[AT.GET_SET_AREA]?.isSuccess();
export const getSetAreaListLoading = (state) => state?.brokerPage?.setArea[AT.GET_SET_AREA]?.isLoading();
export const getBrokerDistrictLoading = (state) => state?.brokerPage?.setArea[AT.GET_BROKER_AREA_DISTRICT]?.isLoading();
export const getPossibleAreaList = (state) => state?.brokerPage?.setArea?.areaList;
export const getSetAreaList = (state) => state?.brokerPage?.setArea?.getSetArea;
export const getBrokerDistrict = (state) => state?.brokerPage?.setArea?.brokerDistrict;
export const getPrevPolygons = (state) => state?.brokerPage?.setArea?.prevPolygons;
export const getSetAreaPolygonList = (state) => state?.brokerPage?.setArea?.polygons;
export const getBrokerageAreaList = (state) => state?.brokerPage?.setArea?.brokerageAreaList;
