import { FeedType } from '@/services/Community';
import React from 'react';

const NewGoodsMessage = ({ feedData, isFeedDeleted }) => {
  const feedType = feedData?.feedType;
  const isPostForSale = feedType === FeedType.POST_FOR_SALE;

  return isFeedDeleted ? (
    <div className="posts-head">
      <p className="posts-subject">
        <span className="posts-type">삭제된 게시글입니다.</span>
      </p>
    </div>
  ) : (
    <>
      {isPostForSale ? (
        <div className="posts-head">
          <p className="posts-subject">
            <span className="posts-type">새로 등록된 다윈매물입니다.</span>
          </p>
        </div>
      ) : (
        false
      )}
    </>
  );
};

export default NewGoodsMessage;
