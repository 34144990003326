/* eslint-disable react/react-in-jsx-scope */
import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions } from '@/data';
import * as selectors from '../selectors';
import apis from '@/apis';
import { STATUS } from '@/data/utils';
import { useDispatch } from 'react-redux';

//매물정보
/**
 * @payload
 * payload.setLoadingStatus
 * 1.계약관리 테이블안에서 로딩바를 보여주는 state
 * 2.더보기 버튼에서 로딩바를 보여주는 state
 * 중 한가지 함수가 들어온다.
 */

export function* manageContractList({ payload }) {
  const { setLoadingStatus, pageNumber } = payload || {};

  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);

    const keyword = yield select(selectors.mgContract.getKeyword);
    const page = yield select(selectors.mgContract.getManageContractPage);
    const gclLoading = yield select(selectors.mgContract.getContractListLoading);
    const originalContractList = yield select(selectors.mgContract.getContractList);

    const data = {
      searchText: keyword,
      perPage: 20,
      page: pageNumber ? pageNumber : page,
    };

    yield put(actions.getContractListLoading());
    if (gclLoading === true) return;
    const result = yield call(apis.brokerPageApi.getContractList, data);
    const list = result.data;
    yield put(actions.setNextData(result.hasNextPage));

    if (list) {
      yield put(actions.setCurrentPage(pageNumber ? pageNumber : page));
      yield put(actions.getContractListSuccess(list));
      setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
    }
  } catch (e) {
    // yield put(actions.brokerSearchFailure(e));
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
  }
}

//계약상세
export function* manageContractDetail({ contract_idx, onSuccess }) {
  try {
    const param = {
      contractIdx: contract_idx,
    };
    const result = yield call(apis.brokerPageApi.getContractDetail, param);
    yield put(actions.getContractDetailSuccess(result));
    if (onSuccess) onSuccess(result);
    // const result2 = yield call(apis.brokerPageApi.getGoodsCommonDetail, detail?.goods_common_idx);
    // yield put(actions.setGoodsCommonDetail(result2));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    console.log(e.message);
  }
}

//계약상세
export function* cancelContract({ payload, callback }) {
  try {
    // 계약취소
    const param = payload;
    yield call(apis.brokerPageApi.cancelContract, param);
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    // yield put(allActions.common.alert({ contents: e.message }));
    console.log('asdfasdf ', e.message);
    yield put(allActions.common.alert({ contents: '계약 취소 중 문제가 발생하였습니다.' }));
    callback(false);
    return;
  }
  // 계약취소 사유를 memo로 입력
  const param = {
    memo_type: 'CC',
    memo_target: '일반',
    goods_common_idx: payload.goodsCommonIdx,
    contents: payload.reason,
  };
  console.log('param: ', param);
  yield call(apis.brokerPageApi.saveGoodsMemo, param);
  console.log('asdfasdfasdf11111');
  const result = yield call(apis.brokerPageApi.getGoodsMemo, { goodsCommonIdx: payload.goodsCommonIdx });
  console.log('asdfasdfasdf22222');

  if (result) {
    yield put(allActions.brokerPage.mgGoods.getGoodsMemoSuccess(result));
    console.log('asdfasdfasdf33333');
  }
  console.log('dddddddddddddd');

  // 팝업 닫힐 때 계약 상세 페이지 닫기
  yield put(
    allActions.common.alert({
      contents: '계약이 정상적으로 취소되었습니다.',
      onConfirm: () => {
        if (callback) {
          callback(false);
        }
      },
    })
  );
  yield put(actions.getContractList());
}
//계약자 정보 추가
export function* saveContactUs({ param }) {
  try {
    yield call(apis.brokerPageApi.saveContactUs, param);
    yield put(actions.getContractDetail(param.contractIdx));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    console.log(e.message);
  }
}
//가계약체결
export function* savePreContract({ payload, redirect }) {
  try {
    let member_arr = [];
    if (payload?.seller_name) {
      member_arr.push({
        memberType: 'contractor',
        contractSide: 'seller',
        name: payload?.seller_name,
        phoneNumber: payload?.seller_phone,
        evaluation: true,
      });
    }
    if (payload?.buyer_name) {
      member_arr.push({
        memberType: 'contractor',
        contractSide: 'buyer',
        name: payload?.buyer_name,
        phoneNumber: payload?.buyer_phone,
        evaluation: true,
      });
    }
    const body = {
      goodsCommonIdx: payload?.goods_common_idx,
      tradeType: payload?.trade_type,
      price1: payload?.sellPrice ? parseInt(payload?.sellPrice) : undefined,
      price2: payload?.chartedPrice ? parseInt(payload?.chartedPrice) : undefined,
      price3: payload?.monthlyPrice ? parseInt(payload?.monthlyPrice) : undefined,
      contractSide: 'both',
      details: [
        {
          detailType: 'PC',
          schedule: payload.schedule,
          price: payload?.price ? parseInt(payload?.price) : undefined,
        },
      ],
      members: member_arr,
      memo: payload?.memo,
    };

    const result = yield call(apis.brokerPageApi.savePreContract, body);
    if (result) {
      yield put(
        allActions.common.alert({
          contents: '가계약이 체결되었습니다.',
          onConfirm: () => {
            redirect();
          },
        })
      );
      // yield put(allActions.router.push('/brokerPage/mng_contract'));
    }
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    console.log(e.message);
  }
}
//계약자 정보 삭제
export function* editContactUs({ param }) {
  try {
    const contractMemberIdx = param.contractMemberIdx;
    delete param.contractMemberIdx;
    yield call(apis.brokerPageApi.editContactUs, param, contractMemberIdx);
    yield put(actions.getContractDetail(param.contractIdx));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    console.log(e.message);
  }
}
//계약자 정보 삭제
export function* deleteContactUs({ param }) {
  try {
    const contractMemberIdx = param.contractMemberIdx;
    yield call(apis.brokerPageApi.deleteContactUs, contractMemberIdx);
    yield put(actions.getContractDetail(param.contractIdx));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    console.log(e.message);
  }
}

//계약상세 내용 추가, 수정, 삭제
export function* saveContractDetailInfo({ param }) {
  try {
    yield call(apis.brokerPageApi.saveContractDetailInfo, param);
    yield put(actions.getContractDetail(param.contractIdx));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    console.log(e.message);
  }
}
//계약자 정보 삭제
export function* editContractDetailInfo({ param }) {
  try {
    const contractDetailIdx = param.contractDetailIdx;
    delete param.contractDetailIdx;
    yield call(apis.brokerPageApi.editContractDetailInfo, param, contractDetailIdx);
    yield put(actions.getContractDetail(param.contractIdx));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    yield put(allActions.common.alert({ contents: e.message }));
    console.log(e.message);
  }
}
//계약자 정보 삭제
export function* deleteContractDetailInfo({ param }) {
  try {
    const contractDetailIdx = param.contractDetailIdx;
    yield call(apis.brokerPageApi.deleteContractDetailInfo, contractDetailIdx);
    yield put(actions.getContractDetail(param.contractIdx));
  } catch (e) {
    // yield put(actions.setContractDetail(false, e.message));
    yield put(allActions.common.alert({ contents: e.message }));
  }
}

export function* deleteContract({ payload }) {
  try {
    if (!payload) {
      return;
    }
    const contractIdx = payload.contractIdx;
    yield call(apis.brokerPageApi.deleteContract, contractIdx);
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}

const setPrices = (data) => {
  let price1;
  let price2;
  let price3;

  switch (data.contractTradeType) {
    case 's':
      price1 = data.amount.price1;
      break;
    case 'c':
      price2 = data.amount.price2;
      break;
    case 'm':
      price2 = data.amount.price3;
      price3 = data.amount.price4;
      break;
    default:
      break;
  }

  return { price1, price2, price3 };
};

const setDetails = (data) => {
  const { downPayment, from } = data || {};

  if (from === 'preContract') {
    return downPayment
      .filter((payment) => payment.detailType === 'PC' && payment.schedule && payment.price)
      ?.map((payment) => {
        // delete payment.id;
        return payment;
      });
  } else {
    return downPayment
      .filter((payment) => payment.detailType !== 'PC' && payment.schedule && payment.price)
      ?.map((payment) => {
        // delete payment.id;
        return payment;
      });
  }
};

const setMembers = (data) => {
  const { buyers, sellers, anotherBrokerInfo, myBrokerInfo, buyerTextAgree, sellerTextAgree, from, contractSide } = data || {};

  const filteredBuyers = buyers
    ?.filter((buyer) => buyer.name && buyer.phoneNumber)
    .map((buyer) => {
      return { ...buyer, evaluation: buyerTextAgree };
    });

  const filteredSellers = sellers
    ?.filter((seller) => seller.name && seller.phoneNumber)
    .map((seller) => {
      return { ...seller, evaluation: sellerTextAgree };
    });
  const filteredBuyerBrokers = anotherBrokerInfo
    ?.filter((agent) => agent.contractSide === 'buyer')
    ?.filter((broker) => broker.name || broker.phoneNumber);
  const filteredSellerBrokers = anotherBrokerInfo
    ?.filter((agent) => agent.contractSide === 'seller')
    ?.filter((broker) => broker.name || broker.phoneNumber);

  let members;
  if (from === 'preContract') {
    members = [...filteredBuyers, ...filteredSellers, ...myBrokerInfo];
  } else if (from !== 'preContract' && contractSide === 'both') {
    members = [...filteredSellers, ...filteredBuyers, ...myBrokerInfo];
  } else if (from !== 'preContract' && contractSide === 'seller') {
    members = [...filteredSellers, ...filteredBuyerBrokers, ...myBrokerInfo];
  } else if (from !== 'preContract' && contractSide === 'buyer') {
    members = [...filteredBuyers, ...filteredSellerBrokers, ...myBrokerInfo];
  }

  return members;
};

// 계약등록필요 계약체결
export function* postContract({ data, setViewOn, setSubmitStatus, dispatch }) {
  try {
    setSubmitStatus(STATUS.LOADING);
    const { contractTradeType: tradeType, memo, contractDetail, contractSide, goodsCommonIdx, from } = data || {};
    const { contractIdx } = contractDetail || {};

    const prices = setPrices(data);
    const details = setDetails(data);
    const members = setMembers(data);

    const finalData = {
      goodsCommonIdx,
      tradeType,
      ...prices,
      contractSide,
      details,
      members,
      memo,
    };

    if (!goodsCommonIdx) {
      yield put(allActions.common.alert({ contents: '매물 정보가 올바르지 않습니다.' }));
      return;
    }

    // 계약관리를 통한거는 put
    let response;
    if (contractIdx) {
      response = yield call(apis.brokerPageApi.updateContractInfo, contractIdx, finalData);
    } else {
      response = yield call(apis.brokerPageApi.saveContractInfo, finalData);
    }

    if (response) {
      yield put(allActions.brokerPage.mgContract.getContractList());
      yield put(
        allActions.common.alert({
          contents: `${from === 'preContract' ? '가' : ''}계약체결등록이 완료되었습니다.`,
          onConfirm: () => {
            setSubmitStatus(STATUS.SUCCESS);
            setViewOn(false);
            if (response?.contractIdx != '' && response?.goodsCommonIdx != '') {
              dispatch(allActions.router.replace(`/brokerPage/mng_contract?cidx=${response?.contractIdx}&detail=${response?.goodsCommonIdx}`));
            } else {
              dispatch(allActions.router.replace(`/brokerPage/mng_contract`));
            }
          },
        })
      );
    } else {
      yield put(allActions.common.alert({ contents: '계약체결등록 중 오류가 발생했습니다. 다시 시도해주세요.' }));
      setSubmitStatus(STATUS.FAILURE);
    }
  } catch (e) {
    const isDuplicatedPhoneNumberError = e.message === '계약자 전화번호에서 중복이 발생했습니다.';

    yield put(
      allActions.common.alert({
        contents: isDuplicatedPhoneNumberError ? e.message : `계약체결등록 중 오류가 발생했습니다. 다시 시도해주세요.`,
      })
    );
    setSubmitStatus(STATUS.FAILURE);
    console.log(e.message);
  }
}
