import * as sagas from './sagas';
import * as AT from './actionTypes';
import {
  takeEvery,
  //   takeLatest,
} from 'redux-saga/effects';

export default function*() {
  yield takeEvery(AT.UPLOAD_ITEM_IMAGE, sagas.uploadImageItem);
}
