import React, { useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { equals } from 'ramda';
import { addToQuerystring } from '@/services/Helper/queryString';

const FilterButton = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectors.buying.getCategory);
  const selectedDealtype = useSelector(selectors.buying.getDealtype);
  const selectedTradetype = useSelector(selectors.buying.getTradetype);
  const selectedAreasize = useSelector(selectors.buying.getAreasize);
  const selectedCntRange = useSelector(selectors.buying.getCntRange);
  const selectedYearRange = useSelector(selectors.buying.getYearRange);

  const filterInitialValue = useRef(
    {
      category: selectedCategory,
      dealtype: selectedDealtype,
      tradetype: selectedTradetype,
      areasize: selectedAreasize,
      cntRange: selectedCntRange,
      yearRange: selectedYearRange,
    },
    []
  );

  const filtered = useMemo(() => {
    const initial = filterInitialValue.current;
    const test =
      equals(initial.category, selectedCategory) &&
      equals(initial.dealtype, selectedDealtype) &&
      equals(initial.tradetype, selectedTradetype) &&
      equals(initial.areasize, selectedAreasize) &&
      equals(initial.cntRange, selectedCntRange) &&
      equals(initial.yearRange, selectedYearRange);

    return !test;
  }, [selectedCategory, selectedDealtype, selectedTradetype, selectedAreasize, selectedCntRange, selectedYearRange]);

  const handleClick = () => {
    if (location.search?.indexOf('searchfilter') > -1) {
      dispatch(actions.router.goBack());
    } else {
      dispatch(actions.router.push(`${location.pathname}${addToQuerystring('searchfilter', true)}`));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(actions.buying.refreshSearchCondition());
    };
  }, []);

  return (
    <>
      {/* href="#dataFinderFilter"  */}
      <a onClick={handleClick} className="btn filter" title={filtered ? '적용됨' : ''}>
        <span className="btn-text">필터</span>
      </a>
    </>
  );
};

export default FilterButton;
