import React, { useEffect, useState } from 'react';
import toggle from '@/hocs/toggle';
import Mask from '@/components/Mask';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import { getMoreNotiHistory, getNotiHistory, readAllNoti, unreadNotiCount } from '@/data/notification/services';
import { STATUS } from '@/data/utils';
import useNotiList from './useNotiList';
import { parseCallBack } from '@/services/Helper';
import styled from 'styled-components';

const PushNotificationHistory = () => {
  const dispatch = useDispatch();
  const params = parseCallBack();

  const [notiHistory, setNotiHistory] = useState({ status: STATUS.NOTASKED });
  const [option, setOption] = useState(params?.option || 'ALL');
  const [lastTimeStamp, setLastTimeStamp] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [unseenNoti, setUnseenNoti] = useState({ status: STATUS.NOTASKED, hasUnRead: false, hasUnReadMajor: false });

  const close = () => {
    params?.option ? dispatch(actions.router.replace('/')) : dispatch(actions.router.goBack());
  };
  const setting = () => {
    dispatch(actions.router.replace('/my/notification/setting'));
  };

  useEffect(() => {
    getNotiHistory(setNotiHistory, option, setShowMore);
    unreadNotiCount(setUnseenNoti);
  }, [option, unseenNoti.hasUnRead]);

  const onClickOption = (opt) => setOption(opt);
  const onClickReadAll = () => {
    if (unseenNoti.hasUnRead) {
      const callback = () => {
        setUnseenNoti({ status: STATUS.SUCCESS, hasUnRead: false });
        dispatch(actions.my.getUnreadNotiCountSuccess(0));
      };
      return readAllNoti(callback);
    }
  };

  const getMore = (e) => {
    e.preventDefault();
    getMoreNotiHistory(setNotiHistory, notiHistory, option, lastTimeStamp, setShowMore);
  };

  const { NotiList } = useNotiList({ notiHistory, setLastTimeStamp, setNotiHistory });

  return (
    <div style={{ position: 'fixed', zIndex: 1001, width: '100%', height: '100%', top: 0, left: 0 }}>
      <Mask setComponentShow={close} />
      <div className="mypage" style={{ zIndex: 1000 }}>
        <header id="header" className="header_type01">
          <span className="btn-left">
            <a className="btn-img btn-back" onClick={close}>
              뒤로
            </a>
          </span>
          <h2 className="title">다윈중개 알림센터</h2>

          <span className="btn-right">
            <a className="btn-img btn-setting" onClick={setting}>
              설정
            </a>
          </span>
        </header>

        <div id="container" className="container">
          <RedDot>
            <section id="" className="sub-content">
              <div className="tab_link_wrap">
                <a className={option === 'ALL' ? 'tab_link on' : ' tab_link false'} onClick={() => onClickOption('ALL')}>
                  모든알림
                </a>
                <a
                  className={`tab_link ${option === 'SELECTED' ? 'on' : 'false'} ${unseenNoti.hasUnReadMajor ? 'noseen' : ''}`}
                  onClick={() => onClickOption('SELECTED')}>
                  주요알림
                </a>
                <a className={option === 'UNREAD' ? 'tab_link on' : ' tab_link false'} onClick={() => onClickOption('UNREAD')}>
                  안읽음
                </a>
                <a className={'tab_link_allchk'} onClick={onClickReadAll}>
                  모두 읽음
                </a>
              </div>
              <div className="noticenter-box">{NotiList}</div>
              {showMore ? (
                <a className="btn_moreview" style={{ margin: '15px 0' }} onClick={getMore}>
                  <span>더보기 +</span>
                </a>
              ) : (
                <></>
              )}
            </section>
          </RedDot>
        </div>
      </div>
    </div>
  );
};

const RedDot = styled.div`
  .tab_link_wrap a.on:after {
    border-bottom: unset;
    left: unset;
  }
`;

export default toggle(PushNotificationHistory);
