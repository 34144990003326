import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';
import { routeToComplexDetail } from '@/pages/Buying/route';
import { COOKIE_CONSTS } from '@/resources/global';
import { getCookieByKey, setCookieByKey } from '@/data/cookie';
import usePersonalize from './usePersonalize';
import useDocumentEvent from '@/hooks/useDocumentEvent';
import useScrollToHighlight from './useScrollToHighlight';
import { routerUtil } from '@/services';

const useSearch = ({ defaultInput, complexOnly, markRule, markTypes, onSelected, redirectToBuying, handleRedirectToOther, enableSearchTracking }) => {
  const dispatch = useDispatch();

  let result = useSelector(selectors.common.getSearchResult);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const isMobile = useSelector(selectors.common.isMobile);

  const [show, setShow] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [highlightIdx, setHighlightIdx] = useState(-1);
  // const [showFilterOption, setShowFilterOption] = useState(false);

  // 검색어 입력 창 Ref
  const inputRef = useRef();

  // wrapper Ref
  const wrapperRef = useRef();
  const clickOutside = (event) => {
    const searchArea = wrapperRef.current;
    const isInside = searchArea?.contains(event.target);

    if (!isInside) {
      show && setShow(false);
    }
  };

  useEffect(() => {
    if (defaultInput) {
      inputRef.current.value = defaultInput;
    }
  }, []);

  useDocumentEvent('click', clickOutside);

  // 관심단지 조회
  const interestComplex = usePersonalize(isLoggedIn);

  //하이라이트 된 아이템 따라 스크롤
  useScrollToHighlight({ highlightIdx });

  // 검색결과
  const data = useMemo(() => {
    const list = result?.list;

    let _data = result;
    // 단지 결과만 ==== markTypes = [APT, OFT]
    if (complexOnly && Array.isArray(list)) {
      const filtered = list?.filter((item) => item.estate === 'APT' || item.estate === 'OFT');
      _data = { ..._data, list: filtered };
    }

    // 표시할 타입 제한
    if (markRule) {
      _data = markRule(_data);
    } else if (markTypes && Array.isArray(markTypes) && Array.isArray(list)) {
      const filtered = list?.filter((item) => markTypes.indexOf(item.estate) > -1);
      _data = { ..._data, list: filtered };
    }

    return _data;
  }, [result, complexOnly, markRule, markTypes]);

  // GA 검색어 추적
  const gaTracking = useCallback(
    (keyword) => {
      if (!keyword) {
        return;
      }

      if (enableSearchTracking) {
        const GA_SEARCH_TERM = 'keyword';
        // routerUtil.querystring.add 에는 encodeURIComponent 함수를 포함하고 있음.
        // q는 GA 확인용이므로 문자열을 그대로 살려서 보냄. encoding 문자열을 GA에서 추적할 수 있는지 확인필요.
        const queryObject = routerUtil.querystring.parse();
        queryObject[GA_SEARCH_TERM] = keyword.replace(/ /g, '_');
        const querystring = `${routerUtil.querystring.stringify(queryObject)}`;

        dispatch(routerUtil.replace(`${location.pathname}${querystring}`));
      }
    },
    [enableSearchTracking]
  );

  // 검색
  const search = (idx) => {
    const list = data?.list;
    const selected = list?.[idx];
    const title = selected?.title || '';

    if (selected) {
      setKeyword(title);
      inputRef.current.value = title;

      setShow(false);
      // setSearchMode(false);

      if (onSelected) {
        // 검색결과 기본동작이 아닐 경우
        onSelected(selected);
      } else {
        // 검색결과 기본동작: 단지 상세로 이동
        dispatch(goToDetail(selected, redirectToBuying, handleRedirectToOther));

        // 최근검색어 업데이트
        update_latest_search({ ...selected });
      }

      gaTracking(title);
    }
  };

  return {
    show,
    setShow,
    keyword,
    setKeyword,
    highlightIdx,
    setHighlightIdx,
    inputRef,
    data,
    isMobile,
    wrapperRef,
    interestComplex,
    search,
    gaTracking,
  };
};

export default useSearch;

/**
 * 최근검색어 업데이트
 */
async function update_latest_search({ title, estate, lat, lng, complex_idx, complex_name }) {
  const epochTime = new Date().getTime();

  let searchHistory = await getCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY);
  searchHistory = searchHistory ? searchHistory : [];

  if (searchHistory.length > 2) searchHistory.splice(0, 1);
  searchHistory.push({ createdAt: epochTime, title, estate, lat, lng, complex_idx, complex_name });
  setCookieByKey(COOKIE_CONSTS.SEARCH_HISTORY, searchHistory);
}

/**
 * 상세 보기로 이동
 */
export const goToDetail = (selected, redirectToBuying, handleRedirectToOther) => (dispatch) => {
  if (selected) {
    const { title, estate, lat, lng, complex_idx } = selected;

    if (lat && lng) {
      if (redirectToBuying) {
        dispatch(actions.router.push('/buying'));
      }

      const goComplexDetail = () => {
        if (!handleRedirectToOther) {
          dispatch(routeToComplexDetail(complex_idx));
        } else {
          handleRedirectToOther(complex_idx);
        }
      };

      switch (estate) {
        case 'STREET':
        case 'STATION':
          dispatch(actions.buying.moveCenter([lat, lng]));
          break;
        case 'OFT':
        case 'APT':
        default:
          dispatch(actions.buying.moveCenter([lat, lng]));
          goComplexDetail();
          break;
      }
    } else {
      alert('위치정보가 없습니다.');
    }
  }
};
