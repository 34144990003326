import * as AT from './actionTypes';

export const initialize = () => ({
  type: AT.INITIALIZE,
});

//상세보기
export const getDetail = (complexId, initial_area) => ({
  type: AT.GET_DETAIL,
  complexId,
  initial_area,
});

export const getDetailSuccess = (data) => ({
  type: AT.GET_DETAIL_SUCCESS,
  data,
});

export const getDetailLoading = () => ({
  type: AT.GET_DETAIL_LOADING,
});

export const getDetailFailure = ({ error }) => ({
  type: AT.GET_DETAIL_SUCCESS,
  error,
});

export const getAreaList = (complex_idx) => ({
  type: AT.GET_AREA_LIST,
  complex_idx,
});

export const setAreaList = (list) => ({
  type: AT.SET_AREA_LIST,
  list,
});

export const changeSize = (size) => ({
  type: AT.CHANGE_SIZE,
  size,
});

export const changeType = (tradetype) => ({
  type: AT.CHANGE_TYPE,
  tradetype,
});

export const getTradehistory = (givenAreaNo, year) => ({
  type: AT.GET_TRADE_HISTORY,
  givenAreaNo,
  year,
});

export const getTradehistoryLoading = () => ({
  type: AT.GET_TRADE_HISTORY_LOADING,
});

export const getTradehistorySuccess = (tradeHistory) => ({
  type: AT.GET_TRADE_HISTORY_SUCCESS,
  tradeHistory,
});
export const getTradehistoryFailure = () => ({});

/**
 * @param {String} passedAreaNo
 * state로 관리되는 areaNo가 아닌 1회성 조회 시
 * 해당하는 areNo를 파라미터로 넘겨야 함(ex:매물상세)
 */
export const getTrade = ({ page, options, passedParams }) => ({
  type: AT.GET_TRADE,
  page,
  options,
  passedParams,
});

export const getTradeSuccess = (trade) => ({
  type: AT.GET_TRADE_SUCCESS,
  trade,
});

export const setCharterPrediction = (data) => ({
  type: AT.SET_CHARTER_PREDICTION,
  data,
});

export const getTax = (areaSize) => ({
  type: AT.GET_TAX,
  areaSize,
});

export const setTax = (data) => ({
  type: AT.SET_TAX,
  data,
});

export const setBrokerList = (data) => ({
  type: AT.SET_BROKER_LIST,
  data,
});

export const setSchool = (data) => ({
  type: AT.SET_SCHOOL,
  data,
});

export const getSchoolDetail = (id, onSuccess) => ({
  type: AT.GET_SCHOOL_DETAIL,
  id,
  onSuccess,
});

export const setSchoolDetail = (data) => ({
  type: AT.SET_SCHOOL_DETAIL,
  data,
});

export const setKindergarten = (data) => ({
  type: AT.SET_KINDERGARTEN,
  data,
});

export const getKindergartenDetail = (id, onSuccess) => ({
  type: AT.GET_KINDERGARTEN_DETAIL,
  id,
  onSuccess,
});

export const setKindergartenDetail = (data) => ({
  type: AT.SET_KINDERGARTEN_DETAIL,
  data,
});

export const setDaycare = (data) => ({
  type: AT.SET_DAYCARE,
  data,
});

export const getDaycareDetail = (id, onSuccess) => ({
  type: AT.GET_DAYCARE_DETAIL,
  id,
  onSuccess,
});

export const setDaycareDetail = (data) => ({
  type: AT.SET_DAYCARE_DETAIL,
  data,
});

export const getSaleDetail = (sale_id) => ({
  type: AT.GET_SALE_DETAIL,
  sale_id,
});

export const getSaleDetailSuccess = (data) => ({
  type: AT.GET_SALE_DETAIL_SUCCESS,
  data,
});
export const getSales = (complex_idx) => ({
  type: AT.GET_SALES,
  complex_idx,
});

export const getSalesSuccess = (data) => ({
  type: AT.GET_SALES_SUCCESS,
  data,
});

export const getSalesLoading = (data) => ({
  type: AT.GET_SALES_LOADING,
  data,
});

// 매물시세
/** @param setMarketData (optional) market data setter. market data를 buying redux에 쌓을 필요가 없을 경우 */
export const getMarketData = (payload, setMarketData) => ({
  type: AT.GET_MARKET_DATA,
  payload,
  setMarketData,
});

export const setMarketData = (payload) => ({
  type: AT.SET_MARKET_DATA,
  payload,
});

export const setSelectedMarketItems = (items) => ({
  type: AT.SET_SELECTED_MARKET_ITEMS,
  items,
});

export const getFacilityData = (payload) => ({
  type: AT.GET_FACILITY_DATA,
  payload,
});

export const setFacilityData = (payload) => ({
  type: AT.SET_FACILITY_DATA,
  payload,
});

export const setFacilitySchoolData = (payload) => ({
  type: AT.SET_FACILITY_SCHOOL_DATA,
  payload,
});

export const getFacilityCode = (payload) => ({
  type: AT.GET_FACILITY_CODE,
  payload,
});

export const setFacilityCode = (payload) => ({
  type: AT.SET_FACILITY_CODE,
  payload,
});

export const getFacilityDataUser = (payload) => ({
  type: AT.GET_FACILITY_DATA_USER,
  payload,
});

export const setFacilityDataUser = (payload) => ({
  type: AT.SET_FACILITY_DATA_USER,
  payload,
});

export const uploadFacilityDataUser = (payload) => ({
  type: AT.UPLOAD_FACILITY_DATA_USER,
  payload,
});

export const setMarketDataStatus = (payload) => ({
  type: AT.SET_MARKET_DATA_STATUS,
  payload,
});

export const getNaverSales = (data) => ({
  type: AT.GET_NAVER_SALES,
  data,
});

export const getNaverSalesPending = () => ({
  type: AT.GET_NAVER_SALES_PENDING,
});

export const getNaverSalesSuccess = (data) => ({
  type: AT.GET_NAVER_SALES_SUCCESS,
  data,
});

export const getNaverSaleDetail = (data) => ({
  type: AT.GET_NAVER_SALE_DETAIL,
  data,
});

export const getNaverSaleDetailSuccess = (data) => ({
  type: AT.GET_NAVER_SALE_DETAIL_SUCCESS,
  data,
});

export const toggleShowNaverItems = (bool) => ({
  type: AT.TOGGLE_SHOW_NAVER_ITEMS,
  data: bool,
});
// 네이버매물 - 작업중

export const getBrokers = (goods_idx) => ({
  type: AT.GET_BROKERS,
  goods_idx,
});

export const getBrokersSuccess = (data) => ({
  type: AT.GET_BROKERS_SUCCESS,
  data,
});

export const getBrokerDetail = (broker_office_idx, goods_idx) => ({
  type: AT.GET_BROKER_DETAIL,
  broker_office_idx,
  goods_idx,
});

export const getBrokerDetailSuccess = (data) => ({
  type: AT.GET_BROKER_DETAIL_SUCCESS,
  data,
});

export const askToBroker = (broker_office_idx, goods_idx, contents, resetForm) => ({
  type: AT.ASK_TO_BROKER,
  broker_office_idx,
  goods_idx,
  contents,
  resetForm,
});

export const askToBrokerSuccess = (data) => ({
  type: AT.ASK_TO_BROKER_SUCCESS,
  data,
});

export const askToBrokerFailure = (error) => ({
  type: AT.ASK_TO_BROKER_FAILURE,
  error,
});

export const askToBrokerLoading = () => ({
  type: AT.ASK_TO_BROKER_LOADING,
});

export const getSchoolInfoWithKakao = (gubun, dongcode, keyword, id) => ({
  type: AT.GET_SCHOOL_INFO_WITH_KAKAO,
  gubun,
  dongcode,
  keyword,
  id,
});

export const setSchoolInfoWithKakao = (data) => ({
  type: AT.SET_SCHOOL_INFO_WITH_KAKAO,
  data,
});

//단지 시설 조회
export const getComplexFacility = (complex_idx) => ({
  type: AT.GET_COMPLEX_FACILITY,
  complex_idx,
});
export const setComplexFacility = (data) => ({
  type: AT.SET_COMPLEX_FACILITY,
  data,
});

//단지 시설 알리기
export const reportComplexFacility = (complex_idx, options, text) => ({
  type: AT.REPORT_COMPLEX_FACILITY,
  complex_idx,
  options,
  text,
});
export const reportComplexFacilitySuccess = () => ({
  type: AT.REPORT_COMPLEX_FACILITY_SUCCESS,
});

export const updateNewFeed = (data) => ({
  type: AT.UPDATE_NEW_FEED,
  data,
});

export const setBrokerListBySelectedGoods = (data) => ({
  type: AT.SET_BROKER_LIST_BY_SELECTED_GOODS,
  data,
});
