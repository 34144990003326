import { priceToText2 } from '@/pages/Buying/util';
import dayjs from 'dayjs';
import { clone, isEmpty } from 'ramda';

/**
 * 멤버십 종류
 */
export const ProductId = {
  free: 'free',
  basic: 'basic_monthly',
  plus: 'plus_monthly',
  premium: 'premium_monthly',
  year_basic: 'basic_monthly_for_year',
  year_plus: 'plus_monthly_for_year',
  year_premium: 'premium_monthly_for_year',
};

/**
 * 멤버십 이름
 */
const _NAME = {
  [ProductId.free]: '무료',
  [ProductId.basic]: '베이직',
  [ProductId.plus]: '플러스',
  [ProductId.premium]: '프리미엄',
  [ProductId.year_basic]: '베이직',
  [ProductId.year_plus]: '플러스',
  [ProductId.year_premium]: '프리미엄',
};

export const getName = (id, withPeriod = false) => {
  const periodText = getIsYearly(id) ? ' (연간)' : ' (월간)';
  const name = _NAME[id];
  return name ? `${name}${withPeriod && !getIsFree(id) ? periodText : ''}` : '';
};

/**
 * 멤버십 아이콘
 */
const _ICON = {
  [ProductId.free]: '',
  [ProductId.basic]: 'basic',
  [ProductId.plus]: 'plus',
  [ProductId.premium]: 'premium',
  [ProductId.year_basic]: 'basic',
  [ProductId.year_plus]: 'plus',
  [ProductId.year_premium]: 'premium',
};
export const getIcon = (id) => {
  const icon = _ICON[id];
  return icon ? icon : '';
};

export const getIsFree = (id) => {
  return id === ProductId.free;
};

/**
 *
 * @param {string} productId
 * @returns productId가 월간베이직인 경우 연간베이직, 연간플러스인 경우 월간플러스를 return
 */
const suffix = '_for_year';
export const getSiblingProductId = (productId) => {
  const isYearly = getIsYearly(productId);
  return isYearly ? productId.replace(suffix, '') : `${productId}${suffix}`;
};

/**
 * 멤버십별 설정 지역 limit
 */
const _DISTRICT_LIMIT = {
  [ProductId.basic]: 3,
  [ProductId.plus]: 7,
  [ProductId.premium]: 15,
  [ProductId.year_basic]: 3,
  [ProductId.year_plus]: 7,
  [ProductId.year_premium]: 15,
};

export const getDistrictLimit = (id) => {
  return _DISTRICT_LIMIT[id];
};

/**
 * 멤버십별 월별 열람권 limit
 */
const _MONTHLY_ACCESS_LIMIT = {
  [ProductId.free]: 5,
  [ProductId.basic]: 50,
  [ProductId.plus]: 200,
  [ProductId.premium]: 1000,
  [ProductId.year_basic]: 50,
  [ProductId.year_plus]: 200,
  [ProductId.year_premium]: 1000,
};

export const getMonthlyAccessLimit = (id) => {
  return _MONTHLY_ACCESS_LIMIT[id];
};

/**
 * 멤버십 별 티켓 개수
 */
const _TICKET_AMOUNT = {
  [ProductId.free]: 0,
  [ProductId.basic]: 50,
  [ProductId.plus]: 200,
  [ProductId.premium]: 1000,
  [ProductId.year_basic]: 50,
  [ProductId.year_plus]: 200,
  [ProductId.year_premium]: 1000,
};

export const getTicketAmount = (id) => {
  return _TICKET_AMOUNT[id];
};

/**
 * 연간 멤버십 가격 - 백엔드에서 1년치 총 가격만 내려오기 때문에 뷰 구성을 위해 정의함.
 */
const _YEARLY_PRICE = {
  [ProductId.year_basic]: {
    productId: ProductId.year_basic,
    price: 50000,
    discountAmount: 20000,
  },
  [ProductId.year_plus]: {
    productId: ProductId.year_plus,
    price: 90000,
    discountAmount: 30000,
  },
  [ProductId.year_premium]: {
    productId: ProductId.year_premium,
    price: 120000,
    discountAmount: 80000,
  },
};

export const getYearlyPrice = (id) => {
  return _YEARLY_PRICE[id];
};

/**
 * 멤버십 기간 타입
 */
export const PeriodType = {
  yearly: 'yearly',
  monthly: 'monthly',
};

const _YEARLY_PERIOD_TYPES = [ProductId.year_basic, ProductId.year_plus, ProductId.year_premium];

export const getIsYearly = (productId) => {
  return _YEARLY_PERIOD_TYPES.includes(productId);
};

export const getPeriodType = (productId) => {
  return _YEARLY_PERIOD_TYPES.includes(productId) ? PeriodType.yearly : PeriodType.monthly;
};

/**
 * 중개사 현재 멤버십 조회
 */
export function getBrokerMembership(myMembershipInfo) {
  // return memberships?.filter?.((item) => item.packageId === 'broker_membership')?.[0];
  return myMembershipInfo?.presentMembership;
}

export function getFormattedProductInfo(membership, products) {
  const dataFromMembership = {};

  if (membership) {
    const { productId, expirationDate, autoBillingDate } = membership;
    const formatExpirationDate = dayjs(expirationDate).format('YYYY.MM.DD');
    const formatAutoBillingDate = dayjs(autoBillingDate).format('YYYY.MM.DD');
    dataFromMembership.expirationDate = formatExpirationDate;
    dataFromMembership.autoBillingDate = formatAutoBillingDate;

    // membership, product 둘 중 하나만 정보가 있는 경우를 대비해 둘 다 set.
    dataFromMembership.productName = getName(productId, true);
    dataFromMembership.productId = productId;
  }

  const product = clone(products?.find((item) => item.productId === membership?.productId));

  if (membership && product) {
    const { productId, discountAmount, price, totalDiscountAmount, totalPrice } = product || {};

    product.productName = getName(productId, true);

    const isYearly = getIsYearly(productId);
    const finalPrice = isYearly ? totalPrice : price,
      discountPrice = isYearly ? totalDiscountAmount : discountAmount;

    const priceText = priceToText2(finalPrice + discountPrice),
      discountText = priceToText2(discountPrice),
      finalPriceText = priceToText2(finalPrice);

    product.priceLabel = { priceText, discountText, finalPriceText };
  }

  if (isEmpty(product) && isEmpty(dataFromMembership)) {
    return null;
  } else {
    return { ...product, ...dataFromMembership };
  }
}
