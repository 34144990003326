import React from 'react';
import Mask from '@/components/Mask';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import useFavoBox from '../useFavoBox';

/**@comment 모바일 관심단지 팝업
 *  집구하기, 분양, 재건축 등 여러 화면에서 사용
 */
const FavoBoxMobile = () => {
  const dispatch = useDispatch();
  const { complex_idx, is_favorite, complex_name } = useSelector(selectors.common.openMobileFavoBox) || {};
  const [chkList, onSubmit] = useFavoBox(complex_idx);
  const tempFavCompList = useSelector(selectors.common.getTempFavCompList);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const isMobile = useSelector(selectors.common.isMobile);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.common.openMobileFavoBox(null));
  };

  const handleCancel = (e) => {
    if (is_favorite || (isLoggedIn && tempFavCompList[complex_idx])) {
      dispatch(actions.common.addFavComplex(complex_idx, null, is_favorite));
    }
    handleClose(e);
  };

  return isMobile && complex_idx ? (
    <>
      <Mask show={true} />
      <div className="lyr">
        <a className="btn-img btn-close-small" onClick={handleClose}>
          닫기
        </a>
        <div className="lyr_inner itm_alert_box">
          <h2 className="tit">{complex_name}</h2>
          <div className="check-box03 four">{chkList}</div>
        </div>
        <div className="btn-horiz">
          <button className="btn btn-second" onClick={handleCancel}>
            취소
          </button>
          <button className="btn btn-primary" onClick={onSubmit}>
            관심단지추가
          </button>
        </div>
      </div>
    </>
  ) : (
    false
  );
};

export default FavoBoxMobile;
