import { useEffect } from 'react';

const useInitializeInput = ({ nickname, setInput }) => {
  useEffect(() => {
    if (nickname) {
      setInput(nickname);
    }
  }, [nickname]);

  return;
};

export default useInitializeInput;
