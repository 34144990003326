import dayjs from 'dayjs';
import reviewitem_01 from '@/assets/images/@temp/ico_reviewitems01.svg';
import reviewitem_02 from '@/assets/images/@temp/ico_reviewitems02.svg';
import reviewitem_03 from '@/assets/images/@temp/ico_reviewitems03.svg';
import reviewitem_04 from '@/assets/images/@temp/ico_reviewitems04.svg';
import reviewitem_05 from '@/assets/images/@temp/ico_reviewitems05.svg';
import reviewitem_06 from '@/assets/images/@temp/ico_reviewitems06.svg';
import reviewitem_07 from '@/assets/images/@temp/ico_reviewitems07.svg';
import { DATE_FORMAT_TYPE, formatDate } from '@/pages/BrokerPage/utils/changeDateFormat';

export const TOTAL_SCORE_OPTIONS = [
  { value: 5, text: '최고예요' },
  { value: 4, text: '좋았어요' },
  { value: 3, text: '그저 그래요' },
  { value: 2, text: '아쉬워요' },
  { value: 1, text: '실망했어요' },
];

export const GOOD_POINT_OPTIONS = [
  { id: '친절', text: '친절하게 상담해주세요.', keyword: '친절한 상담', icon: reviewitem_01 },
  { id: '신속답변', text: '빠르게 답변해주세요.', keyword: '신속한 답변', icon: reviewitem_02 },
  { id: '현황공유', text: '진행현황을 수시로 알려주세요.', keyword: '진행현황 공유', icon: reviewitem_03 },
  { id: '적극성', text: '매물/손님을 적극적으로 연결해주세요.', keyword: '매물/손님 연결', icon: reviewitem_04 },
  { id: '시간준수', text: '약속시간을 잘 지키세요.', keyword: '약속시간 준수', icon: reviewitem_05 },
  { id: '전문성', text: '전문적 지식과 경험이 높아요.', keyword: '전문성', icon: reviewitem_06 },
  { id: '의견조율', text: '거래 당사자간 의견을 잘 조율해주세요.', keyword: '거래당사자간 조율', icon: reviewitem_07 },
];

export const DAWIN_FEE_CHECK_OPTIONS = [
  { value: 'Y', text: '예' },
  { value: 'N', text: '아니요' },
];

export const CUSTOMER_TYPES = { BUYER: '매수자', SELLER: '매도자', TENANT: '임차인', LANDLORD: '임대인' };

export const REVIEW_CAUSE = { CONTACT: '상담', CONTRACT: '계약' };

const options = {
  totalScore: { 1: '실망했어요', 2: '아쉬워요', 3: '그저 그래요', 4: '좋았어요', 5: '최고예요' },
  goodPoint: {
    친절: { text: '친절하게 상담해주세요.', keyword: '친절한 상담' },
    신속답변: { text: '빠르게 답변해주세요.', keyword: '신속한 답변' },
    현황공유: { text: '진행현황을 수시로 알려주세요.', keyword: '진행현황 공유' },
    적극성: { text: '매물/손님을 적극적으로 연결해주세요.', keyword: '매물/손님 연결' },
    시간준수: { text: '약속시간을 잘 지키세요.', keyword: '약속시간 준수' },
    전문성: { text: '전문적 지식과 경험이 높아요.', keyword: '전문성' },
    의견조율: { text: '거래 당사자간 의견을 잘 조율해주세요.', keyword: '거래당사자간 조율' },
  },
  dawinFeeCheck: {
    true: '예',
    false: '아니요',
  },
};

const register_form_structure = {
  totalScore: Object.keys(options.totalScore).map((id) => ({ [id]: options.totalScore.id })),
};

const formatReviewData = (data) => {
  const { multipleValues = {}, numericValues = {}, stringValues = {}, comment = {}, reviewer = {}, createdAt, reviewCause, customerType } = data;

  const { totalScore } = numericValues;
  const { goodPoint } = multipleValues;
  const { dawinFeeCheck, detailReview } = stringValues;
  const { message: commentMessage, updatedAt: commentUpdatedAt } = comment || {};
  const { name: reviewerName } = reviewer;

  const createdDate = formatDate(createdAt, { type: DATE_FORMAT_TYPE.C });
  const commentUpdatedDate = formatDate(commentUpdatedAt, { type: DATE_FORMAT_TYPE.A });
  const customerTypeText = reviewCause === 'CONTACT' ? '상담고객' : CUSTOMER_TYPES[customerType];

  const result = {
    ...data,
    totalScore,
    goodPoint,
    dawinFeeCheck,
    detailReview,
    createdDate,
    customerTypeText,
    commentMessage,
    commentUpdatedDate,
    reviewerName,
  };

  delete result.multipleValues;
  delete result.numericValues;
  delete result.stringValues;

  return result;
};

const formatReviewScore = (score) => {
  const scoreNum = Number(score);

  if (score === 0) {
    return 0;
  }

  return (Math.round(scoreNum * 10) / 10).toFixed(1);
};

export default { options, register_form_structure, formatReviewData, formatReviewScore };
