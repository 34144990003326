import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';
import dataRoom from '../brokerPage/dataRoom/reducers';
// @note sign-up request 보내는건 auth dir 에서 이루어짐
// @todo 따라서, auth 에서 발생하는 오류를 여기다 맞춰줄 필요가 있음

export const agentSignUpReset = () => ({
  type: AT.AGENT_SIGNUP_RESET,
});

export const handleAgentSignUpNextStep = (currentStep, isTemp, changeStep) => ({
  type: AT.AGENT_SIGNUP_HANDLE_NEXT_STEP,
  currentStep,
  isTemp,
  changeStep,
});
export const handleAgentSignUpNextStepLoading = () => ({
  type: loading(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP),
});
export const handleAgentSignUpNextStepSuccess = (data) => ({
  type: success(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP),
  data,
});
export const handleAgentSignUpNextStepFailure = (error) => ({
  type: failure(AT.AGENT_SIGNUP_HANDLE_NEXT_STEP),
  error,
});

export const saveLoginInfo = (obj) => ({
  type: AT.SAVE_LOGIN_INFO,
  obj,
});

//start tour 신청
export const getTourOfficeList = () => ({
  type: AT.GET_TOUR_OFFICE_LIST,
});
export const getTourOfficeListSuccess = (data) => ({
  type: AT.SET_TOUR_OFFICE_LIST,
  data,
});
export const setNewTourName = (data) => ({
  type: AT.SET_TOUR_NAME,
  data,
});
export const setNewTourNum = (data) => ({
  type: AT.SET_TOUR_NUM,
  data,
});
export const setNewTourOffice = (data) => ({
  type: AT.SET_TOUR_OFFICE,
  data,
});
export const setNewTourOfficeName = (data) => ({
  type: AT.SET_TOUR_OFFICE_NAME,
  data,
});
export const setNewTourOfficeName2 = (data) => ({
  type: AT.SET_TOUR_OFFICE_NAME2,
  data,
});
export const setNewTourDate = (data) => ({
  type: AT.SET_TOUR_DATE,
  data,
});
export const applyTourOffice = () => ({
  type: AT.APPLY_TOUR_OFFICE,
});
export const applyOfficeTourLoading = () => ({
  type: loading(AT.APPLY_TOUR_OFFICE),
});
export const applyOfficeTourSuccess = (data) => ({
  type: success(AT.APPLY_TOUR_OFFICE),
  data,
});
export const applyOfficeTourFailure = (error) => ({
  type: failure(AT.APPLY_TOUR_OFFICE),
  error,
});
export const setQqqq = (exist) => ({
  type: AT.SET_TOUR_EXIST,
  exist,
});
export const resetNewtour = () => ({
  type: AT.RESET_TOUR,
});
//end tour신청

export const resetNew = () => ({
  type: AT.RESET_NEW,
});
export const setNewDong = (data) => ({
  type: AT.SET_SHARE_DONG,
  data,
});
export const setNewName = (data) => ({
  type: AT.SET_SHARE_NAME,
  data,
});
export const setNewPhoneNum = (data) => ({
  type: AT.SET_SHARE_NUM,
  data,
});
export const setNewYear = (data) => ({
  type: AT.SET_SHARE_YEAR,
  data,
});
export const applyShareOffice = () => ({
  type: AT.APPLY_SHARE_OFFICE,
});
export const applyShareOfficeLoading = () => ({
  type: loading(AT.APPLY_SHARE_OFFICE),
});
export const applyShareOfficeSuccess = (data) => ({
  type: success(AT.APPLY_SHARE_OFFICE),
  data,
});
export const applyShareOfficeFailure = (error) => ({
  type: failure(AT.APPLY_SHARE_OFFICE),
  error,
});

//아카데미 신청
export const checkAcademy = (name, phone) => ({
  type: AT.CHECK_ACADEMY,
  name,
  phone,
});
export const setCheckResult = (result) => ({
  type: AT.SET_CHECK_ACADEMY_RESULT,
  result,
});
export const getAcademyDate = (callback, updateLoading) => ({
  type: AT.GET_ACADEMY_DATE,
  callback,
  updateLoading,
});
export const setAcademyDate = (data) => ({
  type: AT.SET_ACADEMY_DATE,
  data,
});
export const resetAcademy = () => ({
  type: AT.RESET_ACADEMY,
});
export const resetAlready = () => ({
  type: AT.RESET_ALREADY,
});

export const setAcademyName = (data) => ({
  type: AT.SET_ACADEMY_NAME,
  data,
});
export const setAcademyPhoneNum = (data) => ({
  type: AT.SET_ACADEMY_NUM,
  data,
});
export const setAcademyInfo = (data) => ({
  type: AT.SET_ACADEMY_INFO,
  data,
});

export const getUserApplyAcademyInfoSuccess = (data) => ({
  type: success(AT.GET_USER_APPLY_ACADEMY_INFO),
  data,
});

export const editAcademy = () => ({
  type: AT.EDIT_ACADEMY,
});
export const editAcademySuccess = (data) => ({
  type: success(AT.EDIT_ACADEMY),
  data,
});
export const cancelAcademy = () => ({
  type: AT.CANCEL_ACADEMY,
});
export const cancelAcademySuccess = (data) => ({
  type: success(AT.CANCEL_ACADEMY),
  data,
});
export const preBill = (setPrePop) => ({
  type: AT.APPLY_ACADEMY_PRE_BILL,
  setPrePop,
});
export const setPreBillData = (data) => ({
  type: AT.SET_APPLY_ACADEMY_PRE_BILL_DATA,
  data,
});
export const requestBill = (billId, orderId, paymentKey, amount) => ({
  type: AT.APPLY_ACADEMY_BILL,
  billId,
  orderId,
  paymentKey,
  amount,
});

export const alreadyAcadmey = () => ({
  type: AT.ALREADY_ACADEMY,
});
export const alreadyAcadmeyLoading = () => ({
  type: loading(AT.ALREADY_ACADEMY),
});
export const alreadyAcadmeySuccess = (data) => ({
  type: success(AT.ALREADY_ACADEMY),
  data,
});
export const alreadyAcadmeyFailure = (error) => ({
  type: failure(AT.ALREADY_ACADEMY),
  error,
});

export const agentSignUpRequestERROR = ({ error }) => ({
  type: AT.AGENT_SIGNUP_ERROR,
  payload: {
    error,
  },
});

export const resetErrorState = () => ({
  type: AT.AGENT_SIGNUP_RESET_ERROR_STATE,
});

export const setIsFeeAgreed = (isFeeAgreed) => ({
  type: AT.SET_IS_FEE_AGREED,
  isFeeAgreed,
});
export const setIsAllAgreed = (isAllAgreed) => ({
  type: AT.SET_IS_ALL_AGREED,
  isAllAgreed,
});
export const setIsUsageTermAgreed = (isUsageTermAgreed) => ({
  type: AT.SET_IS_USAGE_TERM_AGREED,
  isUsageTermAgreed,
});
export const setAgentServiceAgreed = (isAgentServiceAgreed) => ({
  type: AT.SET_IS_AGENT_SERVICE_AGREED,
  isAgentServiceAgreed,
});
export const setInfoAgreed = (isInfoAgreed) => ({
  type: AT.SET_IS_INFO_AGREED,
  isInfoAgreed,
});

export const getOfficeList = (searchWord, searchWord2) => ({
  type: AT.GET_OFFICE_LIST,
  searchWord,
  searchWord2,
});
export const delOfficeSet = () => ({
  type: AT.DEL_OFFICE_SET,
});
export const delAddressSet = () => ({
  type: AT.DEL_ADDRES_SET,
});
export const getOfficeListLoading = () => ({
  type: loading(AT.GET_OFFICE_LIST),
});
export const getOfficeListFail = () => ({
  type: failure(AT.GET_OFFICE_LIST),
});
export const getBrokerOfficeList = () => ({
  type: AT.GET_BROKER_OFFICE_LIST,
});
export const setOfficeList = (list) => ({
  type: AT.SET_OFFICE_LIST,
  list,
});
export const setAddressList = (list) => ({
  type: AT.SET_ADDRESS_LIST,
  list,
});
export const getAddressListLoading = () => ({
  type: loading(AT.GET_ADDRESS_LIST),
});
export const getAddressListFail = () => ({
  type: failure(AT.GET_ADDRESS_LIST),
});
export const setBrorkerOfficeList = (list) => ({
  type: AT.SET_BROKER_OFFICE_LIST,
  list,
});

export const setOfficeType = (officeType) => ({
  type: AT.SET_OFFICE_TYPE,
  officeType,
});

export const checkCode = (code) => ({
  type: AT.CHECK_CODE,
  code,
});
export const setCheckCode = (list) => ({
  type: AT.SET_CHECK_CODE,
  list,
});
export const setSelectedOffice = (code) => ({
  type: AT.SET_SELECTED_OFFICE,
  code,
});
export const setSelectedOfficeAddress = (address) => ({
  type: AT.SET_SELECTED_OFFICE_ADDRESS,
  address,
});
export const setAddressDetail = (value) => ({
  type: AT.SET_SELECTED_OFFICE_ADDRESS_DETAIL,
  value,
});
export const setOfficeXY = (latlng) => ({
  type: AT.SET_OFFICE_XY,
  latlng,
});
export const setOfficeDongCode = (b_dong_code) => ({
  type: AT.SET_B_DONG_CODE,
  b_dong_code,
});
export const setLevel = (level) => ({
  type: AT.SET_MAP_LEVEL,
  level,
});
export const searchXY = (address) => ({
  type: AT.SEARCH_XY,
  address,
});
export const getAddressList = (address) => ({
  type: AT.GET_ADDRESS_LIST,
  address,
});
export const changeBrokerIdx = (broker_office_idx) => ({
  type: AT.CHANGE_BROKER_IDX,
  broker_office_idx,
});

export const setSelectedOfficeInfo = (broker_office_info) => ({
  type: AT.SET_SELECTED_OFFICE_INFO,
  broker_office_info,
});

export const setMapError = ({ error }) => ({
  type: AT.AS_SET_MAP_ERROR,
  payload: {
    error,
  },
});

export const setDwcd = (dwcd) => ({
  type: AT.SET_DWCD,
  dwcd,
});
export const setAcademyDwcd = (dwcd) => ({
  type: AT.SET_ACADEMY_DWCD,
  dwcd,
});
export const setOfficePhoneNum = (officePhoneNum) => ({
  type: AT.SET_OFFICE_PHONE_NUM,
  officePhoneNum,
});

export const setBrokerId = (brokerId) => ({
  type: AT.SET_BROKER_ID,
  brokerId,
});
export const setBrokerPassword = (brokerPassword) => ({
  type: AT.SET_BROKER_PASSWORD,
  brokerPassword,
});
export const setBrokerCareerText = (brokerCareerText) => ({
  type: AT.SET_BROKER_CAREER_TEXT,
  brokerCareerText,
});
export const chekId = (brokerId, changeModalType, toggleModalOpen) => ({
  type: AT.CHECK_ID_DUP,
  brokerId,
  changeModalType,
  toggleModalOpen,
});
export const chekIdSuccess = (data) => ({
  type: success(AT.CHECK_ID_DUP),
  data,
});
export const chekIdFailure = (error) => ({
  type: failure(AT.CHECK_ID_DUP),
  error,
});
export const setChekId = () => ({
  type: AT.SET_CHECK_ID_DUP,
});

//==================================================================
//시도, 시군구, 읍면동 관련 action
export const getRecommendAddrSiDo = (only_available = false) => ({
  // 시, 도 목록 받아오기.
  type: AT.GET_RECOMMEND_ADDR_SiDo,
  only_available,
});

export const setAddrSiDo = (list) => ({
  // 시, 도 목록 받아온거 저장
  type: AT.SET_RECOMMEND_ADDR_SiDo,
  list,
});

export const changeSiDoCode = (si_do_code, si_do_name) => ({
  // 사용자 선택에 따라 시, 도 code 변경 (step1)
  type: AT.CHANGE_RECOMMEND_SiDoCode,
  si_do_code,
  si_do_name,
});

export const changeSiDoName = (si_do_name) => ({
  type: AT.CHANGE_RECOMMEND_SiDoName,
  si_do_name,
});

export const getAddrSiGunGu = (sido_code, only_available = false) => ({
  // 시, 군, 구 목록 받아오기.
  type: AT.GET_RECOMMEND_ADDR_SiGunGu,
  sido_code,
  only_available,
});

export const setAddrSiGunGu = (list) => ({
  // 시, 군, 구 목록 받아온거 저장.
  type: AT.SET_RECOMMEND_ADDR_SiGunGu,
  list,
});

export const changeSiGunGuCode = (si_gun_gu_code, si_gun_gu_name) => ({
  // 사용자 선택에 따라 시, 군, 구 code 변경 (step1)
  type: AT.CHANGE_RECOMMEND_SiGunGuCode,
  si_gun_gu_code,
  si_gun_gu_name,
});

export const changeSiGunGuName = (si_gun_gu_name) => ({
  type: AT.CHANGE_RECOMMEND_SiGunGuName,
  si_gun_gu_name,
});

export const getAddrDongRi = (si_gun_gu_code, only_available = false) => ({
  // 동, 리 목록 받아오기.
  type: AT.GET_RECOMMEND_ADDR_DongRi,
  si_gun_gu_code,
  only_available,
});

export const setAddrDongRi = (list) => ({
  // 동, 리 목록 받아온거 저장.
  type: AT.SET_RECOMMEND_ADDR_DongRi,
  list,
});

export const changeDongRiCode = (dong_ri_code, dong_ri_name) => ({
  // 사용자 선택에 따라 동, 리 code 변경 (step1)
  type: AT.CHANGE_RECOMMEND_DongRiCode,
  dong_ri_code,
  dong_ri_name,
});

export const addDongRi = (sigunguName, sigunguCode, dong) => ({
  type: AT.ADD_AGENT_SIGNUP_ACTIVE_AREA,
  sigunguName,
  sigunguCode,
  dong,
});

export const setBrokerAciveArea = (sigungu, dong_list, dong_code) => ({
  type: AT.SAVE_AGENT_SIGNUP_BROKER_ACTIVE_AREA,
  sigungu,
  dong_list,
  dong_code,
});
export const saveAciveArea = (dong_list) => ({
  type: AT.SAVE_AGENT_SIGNUP_ACTIVE_AREA,
  dong_list,
});
export const deleteDongRi = (item) => ({
  type: AT.DEL_AGENT_SIGNUP_ACTIVE_AREA,
  item,
});
export const delDongRi = (b_dong_code) => ({
  type: AT.DEL_AGENT_SIGNUP_B_DONG_CODE,
  b_dong_code,
});
export const clearActiveArea = () => ({
  type: AT.CLEAR_AGENT_SGINUP_ACTIVE_AREA,
});
export const clearActiveArea2 = () => ({
  type: AT.CLEAR_AGENT_SGINUP_ACTIVE_AREA2,
});
