import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//=============================brokeroffice info==================================
export const getBrokerOfficeInfo = () => ({
  type: AT.GET_BROKER_OFFICE_INFO,
});

export const setBrokerInfo = (brokerInfo) => ({
  type: AT.SET_BROKER_INFO,
  brokerInfo,
});

export const setOfficeIntro = (officeIntro) => ({
  type: AT.SET_OFFICE_INTRO,
  officeIntro,
});
export const setOfficeInfo = (officeInfo) => ({
  type: AT.SET_OFFICE_INFO,
  officeInfo,
});

//start intro 변경
export const addStaff = () => ({
  type: AT.ADD_STAFF,
});
export const saveOfficeIntro = () => ({
  type: AT.SAVE_OFFICE_INTRO,
});
export const setStaffList = (delList) => ({
  type: AT.SET_STAFF_LIST,
  delList,
});
export const setIntro = (intro) => ({
  type: AT.SET_INTRO,
  intro,
});
export const setOneLine = (oneline) => ({
  type: AT.SET_ONE_LINE,
  oneline,
});
export const setYoutube = (youtube) => ({
  type: AT.SET_YOUTUBE,
  youtube,
});
export const delded_certi = () => ({
  type: AT.DEL_DED_CERTI,
});
export const delOffice_image = (img_idx) => ({
  type: AT.DEL_OFFICE_IMAGE,
  img_idx,
});
export const setStaffName = (index, name) => ({
  type: AT.SET_STAFF_NAME,
  index,
  name,
});
export const delStaffProfile = (index) => ({
  type: AT.DEL_STAFF_PROFILE,
  index,
});
export const delStaff = (index) => ({
  type: AT.DEL_STAFF,
  index,
});
export const setStaffCp = (index, cp) => ({
  type: AT.SET_STAFF_CP,
  index,
  cp,
});
export const setDedCertiImage = (img_url) => ({
  type: AT.SET_DED_CERTI_IMAGE,
  img_url,
});
export const setStaffImage = (img_url, index) => ({
  type: AT.SET_STAFF_IMAGE,
  img_url,
  index,
});

//사업자등록증
export const setBizImage = (img_url) => ({
  type: AT.SET_BIZ_IMAGE,
  img_url,
});
export const setBrokerRegImage = (img_url) => ({
  type: AT.SET_BROKER_REG_IMAGE,
  img_url,
});
export const setOfficeImage = (img_url) => ({
  type: AT.SET_OFFICE_IMAGE,
  img_url,
});
export const setStaffGubun = (index, gubun) => ({
  type: AT.SET_STAFF_GUBUN,
  index,
  gubun,
});
//end intro 변경

export const changeBrokerInfoData = (alertText) => ({
  type: AT.CHANGE_BROKER_INFO_DATA,
  alertText,
});

export const brokerSecession = (reason, reasonTxt) => ({
  type: AT.BROKER_SECESSION,
  reason,
  reasonTxt,
});

export const setCorNumber = (corNumbers) => ({
  type: AT.SET_COR_NUMBER,
  corNumbers,
});

export const setOfficeType = (officeType) => ({
  type: AT.SET_EDIT_OFFICE_TYPE,
  officeType,
});
export const changeCareer = (value) => ({
  type: AT.SET_EDIT_CAREER,
  value,
});
export const setOfficeList = (list) => ({
  type: AT.SET_OFFICE_MODI_LIST,
  list,
});
export const getOfficeListLoading = () => ({
  type: loading(AT.GET_MY_OFFICE_LIST),
});
export const getOfficeListFail = () => ({
  type: failure(AT.GET_MY_OFFICE_LIST),
});
export const getOfficeList = (searchWord, searchWord2) => ({
  type: AT.GET_MY_OFFICE_LIST,
  searchWord,
  searchWord2,
});
export const delOfficeSet = () => ({
  type: AT.DEL_MY_OFFICE_SET,
});
export const delAddressSet = () => ({
  type: AT.DEL_MY_OFFICE_ADDRESS_SET,
});
export const setAddressDetail = (value) => ({
  type: AT.SET_SELECTED_MODI_OFFICE_ADDRESS_DETAIL,
  value,
});
export const setSelectedOffice = (code) => ({
  type: AT.SET_SELECTED_MODI_OFFICE,
  code,
});
export const setSelectedOfficeAddress = (payload) => ({
  type: AT.SET_SELECTED_MODI_OFFICE_ADDRESS,
  payload,
});
export const updateMyOfficeAddress = (payload) => ({
  type: AT.UPDATE_MY_OFFICE_ADDRESS,
  payload,
});
export const setOfficeXY = (latlng) => ({
  type: AT.SET_OFFICE_MODI_XY,
  latlng,
});
export const setOfficeDongCode = (b_dong_code) => ({
  type: AT.SET_OFFICE_B_DONG_CODE,
  b_dong_code,
});
export const searchXY = (address) => ({
  type: AT.SEARCH_XY_MODI,
  address,
});

export const officeInfoChange = ({ confirmMessage }) => ({
  type: AT.OFFICE_INFO_CHANGE,
  confirmMessage,
});
export const setOfficeAddress = (addr, lat, lng, b_dong_code) => ({
  type: AT.CH_OFFICE_ADDRESS,
  addr,
  lat,
  lng,
  b_dong_code,
});

export const setOfficePhoneNum = (officePhoneNum) => ({
  type: AT.SET_EDIT_OFFICE_PHONE_NUM,
  officePhoneNum,
});
export const setOfficeLatLng = (officeLatLng) => ({
  type: AT.SET_EDIT_OFFICE_LAT_LNG,
  officeLatLng,
});
export const setOfficeImages = (officeImages) => ({
  type: AT.SET_EDIT_OFFICE_IMAGES,
  officeImages,
});

//미사용 - 이건 진영님 소스 가져와서
export const asAddItemItem = (itemImageObj, corpORopen) => ({
  // corpORopen _ 0: 사업자 / 1: 개설 / 2: 프로필
  type: AT.AS_EDIT_IMAGE_ITEM,
  itemImageObj,
  corpORopen,
});
export const asDeleteItem = (itemImageObj, corpORopen) => ({
  // corpORopen _ 0: 사업자 / 1: 개설 / 2: 프로필
  type: AT.AS_DEL_IMAGE_ITEM,
  itemImageObj,
  corpORopen,
});
export const delProfile = () => ({
  type: AT.DEL_PROFILE_IMAGE,
});
export const setProfileImg = (img_url) => ({
  type: AT.SET_PROFILE_IMAGE,
  img_url,
});

//내정보 - 개인정보
export const changeBrokerPass = (oldpass, newpass, newpass_repeat) => ({
  type: AT.BROKER_PASS_CHANGE,
  oldpass,
  newpass,
  newpass_repeat,
});
export const chPhone = (phonenum) => ({
  type: AT.SET_EDIT_PHONE_NUM,
  phonenum,
});
export const setBrokerPassChange = (resultpass) => ({
  type: AT.SET_BROKER_PASS_CHANGE_RESULT,
  resultpass,
});

export const infoSection = (section) => ({
  type: AT.SET_INFO_SECTION,
  section,
});
