import { DEV } from '@/resources/env';

function _gtag() {
  if (!DEV) {
    console.log('send');
    window.dataLayer.push(arguments);
  }
}

const GA4 = {
  event: (eventAction, data = {}) => {
    _gtag('event', eventAction, data);
  },
};

export default GA4;
