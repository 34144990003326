import React from 'react';
import buiToggle from '@/assets/js/bui.toggle.js';

export const designPopup = new buiToggle('[data-bui-toggle="designPopup"]', {
	// inactiveButton: true,
	// inactiveButtonClass: 'btn popup-close',
	// inactiveButtonText: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><title>닫기</title><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>',
	// inactiveButtonArea: '.popup-local-func .button-area',
	reactTarget: 'html',
	reactTargetActiveClass: 'active-content-popup',
	focusin: true,

	onloadCallBack: function(myToggle) {
		let popupFuncContainer = myToggle.toggleTarget.querySelector('.popup-local');
		let popupFunc = myToggle.toggleTarget.querySelector('.popup-local-func');
		if (!popupFunc) {
			popupFunc = document.createElement('div');
			popupFunc.className = 'popup-local-func'
			popupFunc.innerHTML = '<span class="button-area"></span>';
			popupFuncContainer.appendChild(popupFunc);
		};
	}
});

const DesignPopup = ({ popupName, popupInactive, children }) => {
	return (
	<>
		{/* <!-- design-popup --> */}
		<div className="design-popup module-a style-a type-a medium" data-bui-toggle="designPopup" data-bui-toggle-inactive={ popupInactive } id={ popupName }>
			<div className="popup-page-body">
				{ children }
			</div>
		</div>
		{/* <!-- //design-popup --> */}
	</>
	);
};

export default DesignPopup;
