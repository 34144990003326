import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Mask = ({
  show = true,
  setComponentShow,
  onClick, // setComponentShow -> onClick 명명 변경하고싶음
  children,
  zIndex,
  className,
  preventScroll, // background 스크롤 막기. 테스트 중. children에 스크롤이 생기는 경우에는 여전히 막을 수 없다.
  style = {},
}) => {
  const onOutFocus = setComponentShow || onClick;
  const ref = useRef();

  const handleOutFocuse = (e) => {
    if (onOutFocus) {
      onOutFocus(e);
    }
  };

  useEffect(() => {
    if (preventScroll) {
      if (ref?.current) {
        disableScroll(ref.current);
      }

      return () => {
        if (ref?.current) {
          enableScroll(ref.current);
        }
      };
    }
  }, [ref?.current, preventScroll]);
  // preventScroll옵션... 다른 div의 스크롤이 bubbling 되는 것 까지 막지 못함.
  // window에 옵션을 주는 경우 스크롤이 필요한 요소까지 막음.
  // 방법 찾아봐야함..

  return show ? (
    <Wrapper zIndex={zIndex}>
      <StyledMask ref={ref} className={className ? className : ''} style={style} onClick={handleOutFocuse} />
      {children}
    </Wrapper>
  ) : (
    false
  );
};

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${(props) => (props?.zIndex ? props.zIndex : 100)};
`;

const StyledMask = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 1000;
`;
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
  e.stopPropagation();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;

var wheelOpt = { passive: false };
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll(ref) {
  ref.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  ref.addEventListener('wheel', preventDefault, wheelOpt); // modern desktop
  ref.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  ref.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll(ref) {
  ref.removeEventListener('DOMMouseScroll', preventDefault, false);
  ref.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  ref.removeEventListener('touchmove', preventDefault, wheelOpt);
  ref.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
export default Mask;
