import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import AddrSelectCompWithArea from '../components/AddrSelectCompWithArea';

// import DualAddress from '@/combinedComp/DualAddress';
/* 
    관심지역을 등록하기 
 */

const RegiArea = ({ onClose = false }) => {
  const dispatch = useDispatch();
  const selectedComplex = useSelector(selectors.my.getSelectedComplex);
  const newArea = useSelector(selectors.my.getMyNewArea);
  const myAreaStatus = useSelector(selectors.my.getMyAreaPageStatus);
  const myAreaPreStatus = useSelector(selectors.my.getMyAreaPagePreStatus);

  const [data, setData] = useState({ mode: 'SELECT' });

  const goToBack = (e) => {
    e.preventDefault();
    if (window.location.pathname.indexOf('my') > 0) {
      dispatch(actions.address.clearAddress());
      dispatch(actions.my.myAreaPageStatus('main'));
    } else {
      dispatch(actions.router.goBack());
    }
  };

  const onSuccess = () => {
    dispatch(
      actions.common.alert({
        contents: '관심지역이 등록되었습니다.',
        onConfirm: () => {
          dispatch(actions.my.getMyAreas());
          dispatch(actions.my.myAreaPageStatus('main'));
        },
      })
    );
  };
  const onSelected = (item) => {
    console.log(item);
    setData(item);
  };
  const saveMyArea = (e) => {
    e.preventDefault();
    if (!newArea) {
      dispatch(
        actions.common.alert({
          contents: '관심지역을 선택해주세요',
        })
      );
      return;
    } else {
      dispatch(actions.my.saveMyArea(onSuccess));
      dispatch(actions.address.clearAddress());
    }
  };

  return (
    <>
      <div
        className="content-popup module-a style-a type-a medium enabled active"
        id="registerInterestLocal"
        data-bui-toggle="contentsPopup"
        tabIndex="0">
        <div className="popup-page-body">
          {/* <!-- popup-local --> */}
          <div className="popup-local">
            {/* <!-- popup-local-head --> */}
            <div className="popup-local-head">
              <h2 className="popup-local-subject">
                <span className="popup-local-name">관심지역 등록하기</span>
              </h2>
            </div>
            {/* <!-- //popup-local-head --> */}
            {/* <!-- popup-local-body --> */}
            <div className="popup-local-body">
              <form className="popup-content">
                {/* <!-- popup-content-body --> */}
                <div className="popup-content-body">
                  {/* <!-- submit-form --> */}
                  <fieldset className="submit-form module-a type-c medium">
                    <legend>지역 선택 서식</legend>
                    <div className="form-list">
                      <div className="form-item">
                        <div className="form-wrap">
                          <div className="form-head">
                            <label className="form-name" htmlFor="selectCity">
                              주소
                            </label>
                          </div>
                          <AddrSelectCompWithArea shouldUpdateMyBDongCode={true} isActive={true} />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  {/* <!-- //submit-form --> */}
                </div>
                {/* <!-- //popup-content-body --> */}
                {/* <!-- popup-content-util --> */}
                <div className="popup-content-util">
                  {/* <!-- button-display --> */}
                  <div className="button-display module-a style-a type-d">
                    <span className="button-area">
                      <button type="submit" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={(e) => saveMyArea(e)}>
                        <span className="btn-text">등록</span>
                      </button>
                    </span>
                  </div>
                  {/* <!-- //button-display --> */}
                </div>
                {/* <!-- //popup-content-util --> */}
              </form>
            </div>
            {/* <!-- //popup-local-body --> */}
            <div className="popup-local-func">
              <span className="button-area">
                <button type="button" className="btn popup-close" onClick={(e) => goToBack(e)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                    <title>닫기</title>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                  </svg>
                </button>
              </span>
            </div>
          </div>
          {/* <!-- //popup-local --> */}
        </div>
      </div>
    </>
  );
};

export default RegiArea;
