import * as AT from './actionTypes';

const INITIAL_STATE = {};

export default function agentSginUp(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    default: {
      return {
        ...state,
      };
    }
  }
}
