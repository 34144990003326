import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import { STATUS } from '@/data/utils';
import { STEPS } from '@/pages/Login/BasicForm/helpers';
import React from 'react';

const useGetButtonName = () => {
  const buttonText = ({ loadingStatus, loginStep }) => {
    if (loadingStatus === STATUS.LOADING) {
      return <CommnonSpinner show={true} position="relative" padding="10px" spinnerWrapperBackground={'transparent'} />;
    }
    switch (loginStep) {
      case STEPS.PHONE3:
        return '로그인';
      case STEPS.PHONE2:
      case STEPS.SOCIAL3:
        return '인증하기';
      default:
        return '인증번호 요청';
    }
  };
  return [buttonText];
};

export default useGetButtonName;
