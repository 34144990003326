import React from 'react';
import LikeButton from '@/combinedComp/Community/sharedComponents/Like/components/LikeButton';

const LikeComment = ({ feedData, commentCount, detail_item, hasIcon }) => {
  const isFeedDeleted = feedData?.status;

  return !detail_item || detail_item.feedId ? (
    <p className="data-list">
      <span className="data-item comment">
        <span className="head">댓글</span> <span className="body">{commentCount}</span>
      </span>

      {feedData?.reactionCount?.map((reaction, index) => {
        return reaction?.type == 'LIKE' ? (
          <LikeButton
            key={index}
            my={feedData?.myReaction}
            likeCount={reaction?.count}
            feedId={feedData?.id}
            hasIcon={hasIcon}
            publishStatus={isFeedDeleted}
          />
        ) : (
          false
        );
      })}
    </p>
  ) : (
    false
  );
};

export default LikeComment;
