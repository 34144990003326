import React, { useEffect } from 'react';
import { INFO_POPUP_NAMES } from '../../../utils';

const DEFAULT_POPUP_ID = INFO_POPUP_NAMES.CONFIRM;

const DialogPopup = ({
  dialogPopup,
  popupId = DEFAULT_POPUP_ID,
  show,
  contents,
  cancelButtonText = '취소',
  confirmButtonText = '확인',
  onClose,
  onConfirm,
  clearConfirm,
  ...options
}) => {
  const { originalCancelForX } = options;

  useEffect(() => {
    dialogPopup.update(popupId);
  }, []);

  // show가 변경이되면 active 시킴으로써 유저들에게 팝업을 보여주게 하는 코드
  useEffect(() => {
    if (show) {
      dialogPopup.active(popupId);
    }
  }, [show]);

  // contents가 단순히 한줄이라면 string으로 받아옴.
  // 아니라면 마크업 자체로 받아옴
  const popupContent = () => {
    if (typeof contents === 'string') {
      const bodyMarkUp = (
        <div className="board-body">
          <p className="para">
            <span className="wbr">{contents}</span>
          </p>
        </div>
      );
      return bodyMarkUp;
    } else {
      return contents;
    }
  };

  // 우측 상단에 x버튼이 있을때 누르면 실행되는 함수 부분
  const xBtnHandler = () => {
    dialogPopup.inactive(popupId);
    if (originalCancelForX) {
      clearConfirm();
    }
  };

  return (
    <div className="dialog-popup module-a style-b type-b small" data-bui-toggle={popupId} id={popupId}>
      <div className="popup-page-body">
        <div className="popup-local">
          <div className="popup-local-body">
            <div className="popup-content">
              <div className="popup-content-body">
                <div className="info-board module-a style-a type-c">
                  <div className="board-wrap">
                    {popupContent()}
                    <div className="board-util">
                      <div className="button-display module-a style-a type-c">
                        <span className="button-area">
                          <button className="btn module-a style-c type-line normal-02 small flex" type="button" onClick={() => onClose(popupId)}>
                            <span className="btn-text">{cancelButtonText}</span>
                          </button>
                          <button className="btn module-a style-c type-fill normal-02 small flex" type="button" onClick={() => onConfirm(popupId)}>
                            <span className="btn-text">{confirmButtonText}</span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* originalCancelForX를 true로 받아오면 팝업의 우측상단 부분에 x버튼 생성 (최대한 사용하지 않기를 추천)*/}
          {originalCancelForX && (
            <div className="popup-local-func">
              <div className="button-area">
                <button className="btn popup-close" type="button" onClick={xBtnHandler}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                    <title>닫기</title>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DialogPopup;
