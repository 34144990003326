import { actions } from '@/data';
import router from '@/services/router';

// 중개사 상세로 이동
export const routeToBrokerDetail = (broker_office_idx, payload) => (dispatch) => {
  const path = getBrokerDetailPath(broker_office_idx, payload);
  dispatch(actions.router.push(path));
};

export const getBrokerDetailPath = (broker_office_idx, payload) => {
  const querystring = getBrokerDetailQuerystring(payload);
  return `/profile/realtor/${broker_office_idx}${querystring}`;
};

export const getBrokerDetailQuerystring = (payload = {}) => {
  const { chatParam = {}, fromData = {} } = payload;
  const params = { from: fromData.from, 'from-id': fromData.id, ...chatParam };
  return router.querystring.stringify(params);
};
