import { actions, selectors } from '@/data';

import { useDispatch, useSelector } from 'react-redux';
import { getMyAreas, getMyComplexes, getRecentlyViewedAreas, getRecentlyViewedComplexes } from '../../components/Address/MyAddressListBtns/utils';
import { MY_AREA_BTNS } from '@/services/Community';
import { contentsPopup } from '@/publish/Front/_Component/popup/ContentsPopup';

const useSetOpenPopup = () => {
  const dispatch = useDispatch();
  const myAreas = useSelector(selectors.my.getMyAreaData);
  const myComplexes = useSelector(selectors.my.getMyCpsData);

  const openPopupCallback = async (e, type, setTitle, setDataList) => {
    e.preventDefault();

    let data;
    switch (type) {
      case 'INTERESTED_AREAS':
        data = getMyAreas({ myAreas });
        break;
      case 'INTERESTED_COMPLEXES':
        data = getMyComplexes({ myComplexes });
        break;
      case 'RECENTLY_VIEWED_AREAS':
        data = await getRecentlyViewedAreas();
        break;
      case 'RECENTLY_VIEWED_COMPLEXES':
        data = await getRecentlyViewedComplexes();
        break;
      default:
        break;
    }

    const constants = MY_AREA_BTNS?.find((car) => car?.type === type);
    setTitle(constants?.name);
    const alertContents = constants?.contents;

    if (data?.length === 0 || !data) {
      dispatch(actions.common.alert({ contents: alertContents }));
    } else {
      setDataList(data);
      contentsPopup.active('myAreaPopups');
    }
  };

  return [openPopupCallback];
};

export default useSetOpenPopup;
