import * as AT from './actionTypes';
import { failure, loading, success, notAsked } from '@/data/utils';

export const createGoods = ({ savedForm, setStatus }) => ({
  type: AT.CREATE_GOODS,
  savedForm,
  setStatus,
});

export const createReRegisterGoods = ({ goods_common_idx, goods_name, dispatch, setMySellList, callback }) => ({
  type: AT.CREATE_REREGISTER_GOODS,
  goods_common_idx,
  goods_name,
  dispatch,
  setMySellList,
  callback,
});

export const updateGoods = ({ goods_common_idx, savedForm, setStatus, updateGoodsStatus, callback }) => ({
  type: AT.UPDATE_GOODS,
  goods_common_idx,
  savedForm,
  setStatus,
  updateGoodsStatus,
  callback,
});

export const getGoods = (goods_common_idx, setStatus) => ({
  type: AT.GET_GOODS,
  goods_common_idx,
  setStatus,
});

export const getGoodsMemo = (goods_common_idx, setStatus) => ({
  type: AT.GET_GOODS_MEMO,
  goods_common_idx,
  setStatus,
});

// 전화로 집내놓기
export const sellCallRequest = (payload) => ({ type: AT.SELL_CALL_REQUEST, payload });
export const sellCallNotAsked = () => ({ type: notAsked(AT.SELL_CALL_REQUEST) });
export const sellCallRequestLoading = () => ({ type: loading(AT.SELL_CALL_REQUEST) });
export const sellCallRequestSuccess = () => ({ type: success(AT.SELL_CALL_REQUEST) });
export const sellCallRequestFailure = (error) => ({ type: failure(AT.SELL_CALL_REQUEST), error });

// [[OLD LOGIC]]-----------------------------------------------------------
//직접 집내놓기 : 임시저장 단계 제거 후 STEP1로
export const initAndGoFirstStep = () => ({
  type: AT.INIT_AND_GO_FIRST_STEP,
});
export const setSellerType = (sellerType) => ({
  type: AT.SELL_MANUAL_SET_SELLER_TYPE,
  sellerType,
});
export const storeSavedForm = (savedForm) => ({
  type: AT.SELL_MANUAL_STORE_SAVED_FORM,
  savedForm,
});
export const updateSavedForm = (payload) => ({
  type: AT.SELL_MANUAL_UPDATE_SAVED_FORM,
  payload,
});

export const updateLastSavedForm = (payload) => ({
  type: AT.UPDATE_LAST_SAVED_FORM,
  payload,
});
export const clearSavedForm = () => ({
  type: AT.SELL_MANUAL_CLEAR_SAVED_FORM,
});
// 직접 집내놓기 : 각 단계 정보 받아오기
export const getManualStepData = (currentStep) => ({
  type: AT.SELL_MANUAL_GET_STEP_DATA,
  currentStep,
});
export const getManualStepDataLoading = () => ({
  type: loading(AT.SELL_MANUAL_GET_STEP_DATA),
});
export const getManualStepDataSuccess = (data) => ({
  type: success(AT.SELL_MANUAL_GET_STEP_DATA),
  data,
});
export const getManualStepDataFailure = (error) => ({
  type: failure(AT.SELL_MANUAL_GET_STEP_DATA),
  error,
});
// 직접 집내놓기 : 다음 단계로 이동 혹은 임시저장 isTemp _ 'saved' : 다음 단계 이동, 'true' : 임시저장
export const handleManualNextStep = (currentStep, isTemp) => ({
  type: AT.SELL_MANUAL_HANDLE_NEXT_STEP,
  currentStep,
  isTemp,
});
export const handleManualNextStepLoading = () => ({
  type: loading(AT.SELL_MANUAL_HANDLE_NEXT_STEP),
});
export const handleManualNextStepSuccess = () => ({
  type: success(AT.SELL_MANUAL_HANDLE_NEXT_STEP),
});
export const handleManualNextStepFailure = (error) => ({
  type: failure(AT.SELL_MANUAL_HANDLE_NEXT_STEP),
  error,
});
export const sellRequestError = ({ error }) => ({
  type: AT.SELL_REQUEST_ERROR,
  payload: {
    error,
  },
});

export const resetErrorState = () => ({ type: AT.SELL_RESET_ERROR_STATE });
// 매물 정보를 하나의 객체로 관리, 키 값을 받아서 해당 키값의 데이터를 수정하는 형태
export const changeItemInfo = (targetDataKey, changeContent, gubun) => ({
  type: AT.CHANGE_ITEM_INFO,
  targetDataKey,
  changeContent,
  gubun,
});

// ============================ Step 1 ============================ //
// ============================ 시,도,군,구,동,리 목록 가져오기 및 선택
// ============================ //
export const getAddrSiDo = (only_available = false, only_broker = false, payload) => ({
  // 시, 도 목록 받아오기.
  type: AT.GET_ADDR_SiDo,
  only_available,
  only_broker,
  payload,
});

export const setAddrSiDo = (list) => ({
  // 시, 도 목록 받아온거 저장
  type: AT.SET_ADDR_SiDo,
  list,
});

export const changeSiDoCode = (si_do_code, si_do_name) => ({
  // 사용자 선택에 따라 시, 도 code 변경 (step1)
  type: AT.CHANGE_SiDoCode,
  si_do_code,
  si_do_name,
});

export const getAddrSiGunGu = (sido_code, only_available = false, only_broker = false, payload) => ({
  // 시, 군, 구 목록 받아오기.
  type: AT.GET_ADDR_SiGunGu,
  sido_code,
  only_available,
  only_broker,
  payload,
});

export const setAddrSiGunGu = (list) => ({
  // 시, 군, 구 목록 받아온거 저장.
  type: AT.SET_ADDR_SiGunGu,
  list,
});

export const changeSiGunGuCode = (si_gun_gu_code, si_gun_gu_name) => ({
  // 사용자 선택에 따라 시, 군, 구 code 변경 (step1)
  type: AT.CHANGE_SiGunGuCode,
  si_gun_gu_code,
  si_gun_gu_name,
});
export const getAddrDongRi = (si_gun_gu_code, only_available = false, only_broker = false, payload) => ({
  // 동, 리 목록 받아오기.
  type: AT.GET_ADDR_DongRi,
  si_gun_gu_code,
  only_available,
  only_broker,
  payload,
});

export const setAddrDongRi = (list) => ({
  // 동, 리 목록 받아온거 저장.
  type: AT.SET_ADDR_DongRi,
  list,
});

export const changeDongRiCode = (dong_ri_code, dong_ri_name) => ({
  // 사용자 선택에 따라 동, 리 code 변경 (step1)
  type: AT.CHANGE_DongRiCode,
  dong_ri_code,
  dong_ri_name,
});

// 단지 세부정보 ============================ //
// 단지 목록 가져오기 && 단지 선택 선택 && 단지 정보, 위치 정보 가져오기
export const getComplexList = (payload) => ({ type: AT.GET_COMPLEX_LIST, payload });
export const getComplexListLoading = () => ({
  type: loading(AT.GET_COMPLEX_LIST),
});
export const getComplexListSuccess = (complexList) => ({
  type: success(AT.GET_COMPLEX_LIST),
  complexList,
});
export const getComplexListFailure = (error) => ({
  type: failure(AT.GET_COMPLEX_LIST),
  error,
});

export const changeComplexIdx = (complexIdx) => ({ type: AT.CHANGE_COMPLEX_IDX, complexIdx });
export const getComplexLocation = () => ({ type: AT.GET_COMPLEX_LOCATION });
export const getComplexLocation2 = () => ({ type: AT.GET_COMPLEX_LOCATION2 });
export const getComplexLocationLoading = () => ({
  type: loading(AT.GET_COMPLEX_LOCATION),
});
export const getComplexLocationSuccess = (complexLocation) => ({
  type: success(AT.GET_COMPLEX_LOCATION),
  complexLocation,
});
export const getComplexLocationFailure = (error) => ({
  type: failure(AT.GET_COMPLEX_LOCATION),
  error,
});

// ============================ 단지 세부정보 ============================ //
export const getComplexDongList2 = (payload) => ({ type: AT.GET_COMPLEX_DONG_LIST2, payload });
export const getComplexDongList = () => ({ type: AT.GET_COMPLEX_DONG_LIST });
export const getComplexDongListLoading = () => ({
  type: loading(AT.GET_COMPLEX_DONG_LIST),
});
export const getComplexDongListSuccess = (complexDongList) => ({
  type: success(AT.GET_COMPLEX_DONG_LIST),
  complexDongList,
});
export const getComplexDongListFailure = (error) => ({
  type: failure(AT.GET_COMPLEX_DONG_LIST),
  error,
});
export const getFloorList = () => ({ type: AT.GET_FLOOR_LIST });
export const getFloorList2 = (payload) => ({ type: AT.GET_FLOOR_LIST2, payload });
export const setFloorList = (list) => ({ type: AT.SET_FLOOR_LIST, list });
export const changeFloorNo = (floorNo) => ({ type: AT.CHANGE_FLOOR_NO, floorNo });
export const getHoList = () => ({ type: AT.GET_HO_LIST });
export const getHoList2 = (payload) => ({ type: AT.GET_HO_LIST2, payload });
export const setHoList = (list) => ({ type: AT.SET_HO_LIST, list });
export const changeItemSize = (itemSize) => ({ type: AT.CHANGE_ITEM_SIZE, itemSize });
export const saveImage = (inout, key, img_full_pateh, idxSetter) => ({
  type: AT.SAVE_IMAGE,
  inout,
  key,
  img_full_pateh,
  idxSetter,
});
export const saveImage2 = (inout, key, img_full_pateh, idxSetter, userInfo, payload) => {
  return {
    type: AT.SAVE_IMAGE2,
    inout,
    key,
    img_full_pateh,
    idxSetter,
    userInfo,
    payload,
  };
};
export const saveIamgeSuccess = () => ({
  type: AT.SAVE_IMAGE_SUCCESS,
});
export const deleteImage = (inout, tmpIdx, imgSetter, idxSetter) => ({
  type: AT.DELETE_IMAGE,
  inout,
  tmpIdx,
  imgSetter,
  idxSetter,
});

export const deleteImageSuccess = () => ({
  type: AT.DELETE_IMAGE_SUCCESS,
});
export const getNearybyAgents = () => ({ type: AT.GET_NEARBY_AGENTS });
export const getNearybyAgentsLoading = () => ({
  type: loading(AT.GET_NEARBY_AGENTS),
});
export const getNearybyAgentsSuccess = (list) => ({
  type: success(AT.GET_NEARBY_AGENTS),
  list,
});
export const getNearybyAgentsFailure = (error) => ({
  type: failure(AT.GET_NEARBY_AGENTS),
  error,
});

export const changeSelectedAgents = (idx) => ({ type: AT.CHANGE_SELECTED_AGENTS, idx });
export const setSelectedAgents = (data) => ({ type: AT.SET_SELECTED_AGENTS, data });

// 인근 자동 선택 시 redux 에 있는 nearbyAgents 가 들어오고, 그게 아니면 사용자가 선택한 중개사가 array 에 담겨 옴
export const changeFocusedAgentIndex = (idx) => ({ type: AT.CHANGE_FOCUSED_AGENT_INDEX, idx });

export const changeMapCenter = (info) => ({ type: AT.CHANGE_MAP_CENTER, info });
