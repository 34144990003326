import { apiClient } from '@/apis/clients';

export const api = {
  // 개인화 요약정보
  async getPersonalizationSummary() {
    try {
      return await apiClient.get('/personalization/summary');
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 관심단지
  async getInterestComplex(querystring = '') {
    try {
      return await apiClient.get('/personalization/interestComplex' + querystring);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async postInterestComplex(tb_cb_complex_idx, noti_yn, reg_type = undefined) {
    try {
      return await apiClient.post('/personalization/interestComplex', { tb_cb_complex_idx, noti_yn, reg_type });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async deleteInterestComplex(tb_cb_complex_idx) {
    try {
      return await apiClient.delete(`/personalization/interestComplex?interestComplexIdx=${tb_cb_complex_idx}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 우리집 정보
  async getMyHome({ recent_trade, goods_idx }) {
    try {
      // return await apiClient.get(`/personalization/myHome?recentTrade=${recent_trade}&goods=${goods_idx}`);
      return await apiClient.get(`/personalization/myHome?recentTrade=2&goods=1`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 관심지역
  async getInterestDistrict() {
    try {
      return await apiClient.get('/personalization/interestDistrict');
    } catch (e) {
      throw Error(e.message);
    }
  },

  //
  // async social1(stype, uid, email, profile) {
  //   return await apiClient
  //     .post('/auth/login/social/step1', {
  //       type: stype,
  //       uid: uid,
  //       email: email,
  //       profile: profile,
  //     })
  //     .catch(function(e) {
  //       throw Error(e.message);
  //     });
  // },
};
