import apis from '@/apis';

export const getComplexDetailBulk = async (complexIds) => {
  try {
    const result = await apis.buyingApi.getComplexDetailBulk(complexIds); // 단지아이디를 벌크로 주면 > 단지주소 + 단지이름
    return result;
  } catch (e) {
    console.error(e.message);
  }
};

export const getComplexAddress = async (dongCodeList) => {
  try {
    const result = await apis.addressApi.getComplexAddress(dongCodeList); // 단지아이디를 벌크로 주면 > 단지주소 + 단지이름
    return result;
  } catch (e) {
    console.error(e.message);
  }
};
