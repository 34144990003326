import { loading, success, failure } from '@/data/utils';

export const GET_DETAIL_ = 'GET_DETAIL';
export const GET_DETAIL_SUCCESS_ = success('GET_DETAIL');
export const GET_DETAIL_LOADING_ = loading('GET_DETAIL');

export const GET_MG_COUNSELING_LIST = 'BROKERPAGE/GET_MG_COUNSELING_LIST';
export const GET_MG_COUNSELING_LIST_SUCCESS = success('SELLING/GET_MG_COUNSELING_LIST');
export const GET_MG_COUNSELING_LIST_FAILURE = failure('SELLING/GET_MG_COUNSELING_LIST');
export const GET_MG_COUNSELING_LIST_LOADING = loading('SELLING/GET_MG_COUNSELING_LIST');

export const GET_MG_COUNSELING_GOODS_LIST = 'BROKERPAGE/GET_MG_COUNSELING_GOODS_LIST';
export const GET_MG_COUNSELING_GOODS_LIST_SUCCESS = success('SELLING/GET_MG_COUNSELING_GOODS_LIST');
export const GET_MG_COUNSELING_GOODS_LIST_FAILURE = failure('SELLING/GET_MG_COUNSELING_GOODS_LIST');
export const GET_MG_COUNSELING_GOODS_LIST_LOADING = loading('SELLING/GET_MG_COUNSELING_GOODS_LIST');

export const CHANGE_GOODS_IDX = 'BROKERPAGE/CHANGE_GOODS_IDX';
export const CHANGE_GOODS_COMMON_IDX = 'BROKERPAGE/CHANGE_GOODS_COMMON_IDX';
export const SET_KEYWORD = 'BROKERPAGE/SET_KEYWORD';
export const SET_LIMIT = 'BROKERPAGE/SET_LIMIT';
export const RESET_LIMIT = 'BROKERPAGE/RESET_LIMIT';

export const SAVE_MGC = 'BROKERPAGE/SAVE_MGC';
export const SAVE_MGC_SUCCESS = success('BROKERPAGE/SAVE_MGC');
export const SAVE_MGC_FAILURE = failure('BROKERPAGE/SAVE_MGC');
export const SAVE_MGC_LOADING = loading('BROKERPAGE/SAVE_MGC');
export const SET_IN_SEL = 'BROKERPAGE/SET_IN_SEL';
export const SET_IN_CP = 'BROKERPAGE/SET_IN_CP';
export const SET_IN_CONTENTS = 'BROKERPAGE/SET_IN_CONTENTS';

//내정보 - 사무실정보
//export const SET_COR_NUMBER = "BROKERPAGE/SET_COR_NUMBER";
