import { getTokenCookie } from '@/data/cookie';
import { getDeviceIdOrGenerate, getSessionIdOrGenerate } from '@/services/Device';
import { history } from '@/data/configureStore';
import packageJson from '@dawinfront/package-json';
import AuthSession from '@/services/apps/load/AuthSession';
import { OSTYPE } from '@/services/apps/types/OsType';

/**
 * @deprecated
 * /services/router/querystring 사용 권장
 */
export const objectToQuerystring = (obj) => {
  return Object.keys(obj).reduce(function(str, key, i) {
    var delimiter, val;
    delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(typeof obj[key] === 'undefined' ? '' : obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
};

export const apiRequestInterceptor = async (request) => {
  const authData = new AuthSession();
  const token = authData.getAccessToken();

  addRequiredHeaders(authData, request);

  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }

  return request;
};

export const apiRequestInterceptorNoAuth = async (request) => {
  const authData = new AuthSession();
  addRequiredHeaders(authData, request);

  return request;
};

const addRequiredHeaders = (data, request) => {
  const deviceId = data.getDeviceId();
  const sessionId = data.getSessionId();

  request.headers['accept'] = 'application/json';
  // request.headers['x-dawin-device-id'] = deviceId;
  // request.headers['x-dawin-session-id'] = sessionId;
  // request.headers['x-dawin-device-type'] = window.dawin_instance.osType;
  // request.headers['x-dawin-web-version'] = packageJson.version;
};

const handleError = async (error) => {
  const res = error.response;
  let { status } = res || {};

  if (status === 401 && !res?.data?.detail) {
    history.push('/logout');
    return Promise.reject(res.data);
  } else if (status === 403) {
    const type = res.data?.type || res.data?.data?.type;
    if (type === 'OLD_VERSION') {
      const osType = window?.dawin_instance?.osType;
      if (osType === OSTYPE.APP_ANDROID || osType === OSTYPE.APP_IOS) {
        await window.dawin_instance?.bridge?.webViewControlFunction.clearCache();
      }
      location.reload();
    }

    return Promise.reject(res.data);
  } else {
    return Promise.reject(res.data);
  }
};

export const apiResponseInterceptor = [
  (res) => {
    // 2.3.0 이전 API는 모든 데이터가 res.data.data 에 담겨 넘어옴.
    const { data } = res.data;
    return data;
  },
  handleError,
];

export const apiResponseInterceptor2 = [
  (res) => {
    return res.data;
  },
  handleError,
];
