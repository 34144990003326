// Font theme
const _fontTheme = {
  buiBody: {
    fontFamily: 'Roboto, Noto Sans KR, Apple SD Gothic Neo, Malgun Gothic, 맑은 고딕, dotum, 돋움, sans-serif',
    letterSpacing: 'normal',
    fontWeight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 500,
      bold: 700,
      black: 900,
    },
  },
  buiHead: {
    fontFamily: 'Roboto Mono, Noto Sans KR, Apple SD Gothic Neo, Malgun Gothic, 맑은 고딕, dotum, 돋움, sans-serif',
    letterSpacing: 'normal',
    fontWeight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      black: 700,
    },
  },
  code: {
    fontFamily: 'Consolas, Courier New, monospace, Apple SD Gothic Neo, Malgun Gothic, 맑은 고딕, dotum, 돋움, sans-serif',
    letterSpacing: 'normal',
    fontWeight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 500,
      bold: 700,
      black: 900,
    },
  },
  default: {
    fontFamily: `'Open Sans', 'Noto Sans KR', sans-serif`,
    // "fontFamily": #{"Spoqa Han Sans Neo", sans-serif},
    // "fontFamily": #{'Noto Sans KR', sans-serif},
    letterSpacing: 'normal',
    fontWeight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  },
  brand: {
    fontFamily: `'Jua', sans-serif`,
    letterSpacing: 'normal',
    fontWeight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 400,
      bold: 700,
      black: 900,
    },
  },
};

const _fontOutline = {
  head: {
    'large-5x': { lineHeight: '80rem', fontSize: 'calc(80rem/1.25)' },
    'large-4x': { lineHeight: '72rem', fontSize: 'calc(60rem/1.25)' },
    'large-3x': { lineHeight: '64rem', fontSize: 'calc(56rem/1.25)' },
    'large-2x': { lineHeight: '56rem', fontSize: 'calc(48rem/1.25)' },
    'large-1x': { lineHeight: '48rem', fontSize: 'calc(44rem/1.25)' },
    large: { lineHeight: '40rem', fontSize: 'calc(40rem/1.25)' },
    medium: { lineHeight: '32rem', fontSize: 'calc(32rem/1.25)' },
    small: { lineHeight: '28rem', fontSize: 'calc(28rem/1.25)' },
    'small-1x': { lineHeight: '24rem', fontSize: 'calc(24rem/1.25)' },
    'small-2x': { lineHeight: '20rem', fontSize: 'calc(20rem/1.25)' },
    'small-3x': { lineHeight: '18rem', fontSize: 'calc(18rem/1.25)' },
    'small-4x': { lineHeight: '16rem', fontSize: 'calc(16rem/1.25)' },
    'small-5x': { lineHeight: '14rem', fontSize: 'calc(14rem/1.25)' },
  },
  body: {
    'large-5x': { lineHeight: '80rem', fontSize: 'calc(68rem/1.5)' },
    'large-4x': { lineHeight: '72rem', fontSize: 'calc(60rem/1.5)' },
    'large-3x': { lineHeight: '64rem', fontSize: 'calc(56rem/1.5)' },
    'large-2x': { lineHeight: '56rem', fontSize: 'calc(48rem/1.5)' },
    'large-1x': { lineHeight: '48rem', fontSize: 'calc(44rem/1.5)' },
    large: { lineHeight: '40rem', fontSize: 'calc(40rem/1.5)' },
    medium: { lineHeight: '32rem', fontSize: 'calc(32rem/1.5)' },
    small: { lineHeight: '28rem', fontSize: 'calc(28rem/1.5)' },
    'small-1x': { lineHeight: '24rem', fontSize: 'calc(24rem/1.5)' },
    'small-2x': { lineHeight: '20rem', fontSize: 'calc(20rem/1.5)' },
    'small-3x': { lineHeight: '18rem', fontSize: 'calc(18rem/1.5)' },
    'small-4x': { lineHeight: '16rem', fontSize: 'calc(16rem/1.5)' },
    'small-5x': { lineHeight: '14rem', fontSize: 'calc(14rem/1.5)' },
  },
};

type GetFontProps = {
  outline?: 'head' | 'body';
  size?:
    | 'large-5x'
    | 'large-4x'
    | 'large-3x'
    | 'large-2x'
    | 'large-1x'
    | 'large'
    | 'medium'
    | 'small'
    | 'small-1x'
    | 'small-2x'
    | 'small-3x'
    | 'small-4x'
    | 'small-5x';
  lineHeight?: string;
  style?: 'normal' | 'italic' | 'oblique' | string;
  weight?: 'thin' | 'extraLight' | 'light' | 'regular' | 'medium' | 'semiBold' | 'bold' | 'black';
  family?: 'buiBody' | 'buiHead' | 'code' | 'default' | 'brand';
  letterSpacing?: string;
};

export const getFont = ({ outline = 'body', size, lineHeight, style, weight, family, letterSpacing }: GetFontProps): string => {
  const fontStyleList: string[] = [];

  if (size) {
    fontStyleList.push(`font-size: ${_fontOutline[outline][size].fontSize};`);
    fontStyleList.push(`line-height: ${lineHeight || _fontOutline[outline][size].lineHeight};`);
  }

  if (style) {
    fontStyleList.push(`font-style: ${style};`);
  }

  if (weight) {
    fontStyleList.push(`font-weight: ${_fontTheme[family || 'default']['fontWeight'][weight]};`);
  }

  if (family) {
    fontStyleList.push(`font-family: ${_fontTheme[family]['fontFamily']};`);
  }

  if (letterSpacing) {
    fontStyleList.push(`letter-spacing: ${letterSpacing};`);
  }

  return fontStyleList.join('\n');
};
