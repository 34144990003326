import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { alertForDeniedPrms } from '@/services/apps/permission';
import getCameraPrms from '@/services/apps/permission/getCameraPrms';
import { getPermissionAsBoolean } from '@/services/apps/types/PermissionType';

const useAppCamera = ({ useSelector, selectors }) => {
  const dispatch = useDispatch();
  const cameraPrmsRef = useRef();
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const isApp = osTypeInstance?.isApp;

  // 앱 카메라 권한 요청 event listener
  useEffect(() => {
    const action = async (e) => {
      if (isApp) {
        if (e.target && e.target.getAttribute('type') === 'file') {
          if (!cameraPrmsRef.current) {
            e.preventDefault();
            const result = await getCameraPrms();

            if (getPermissionAsBoolean(result)) {
              if (e.target) {
                cameraPrmsRef.current = e.target;
                e.target.click();
              }
            } else {
              dispatch(alertForDeniedPrms('camera'));
            }
          } else {
            cameraPrmsRef.current = null;
          }
        }
      }
    };

    document.addEventListener('click', action);

    return () => document.removeEventListener('click', action);
  }, []);
};

export default useAppCamera;
