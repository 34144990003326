import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions, selectors } from '@/data';

const Alarm = ({ isSimple = false }) => {
  const dispatch = useDispatch();

  const notiCount = useSelector(selectors.my.getUnreadNotiCount);

  const showPushHistory = (e) => {
    e.preventDefault();
    const querystring = location.search ? `${location.search}&` : '?';
    dispatch(actions.router.push(`${location.pathname}${querystring}pushNotificationHistory=true`));
  };

  const showNotiCount = notiCount !== 0 && notiCount !== undefined;

  return (
    <li className="navi-item">
      <Link className="navi-name alarm" to="#" onClick={showPushHistory}>
        {isSimple ? '' : '알림'}
        {showNotiCount && <span className="count">{notiCount}</span>}
      </Link>
    </li>
  );
};

export default Alarm;
