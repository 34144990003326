import React, { useEffect, useReducer, useState } from 'react';
import TemplateContentsPopup from '../templates/TemplateContentsPopup';
import TemplateFormItem from '../templates/TemplateFormItem';
import toggle from '@/hocs/toggle';
import { useDispatch } from 'react-redux';
import router from '@/services/router';
import { calcBrokerFeeDawin } from '@/services/Helper/calcBrokerFee';
import OptionItem from './components/OptionItem';
import { autoComma } from '@/services/Helper';
import GoodsPriceForm from '../Form/GoodsPriceForm/GoodsPriceForm';
import { getPriceKeys } from '@/services/goods/price';
import { actions } from '@/data';
import policyService from '@/services/policy';

const DEFAULT_STATE = { contractor: 'seller', isJointBrokerage: false };

const FeeCalculator = ({ initialValues = {}, onClose }) => {
  const dispatch = useDispatch();

  const [result, setResult] = useState(0);

  const initialState = { ...DEFAULT_STATE, ...initialValues };

  const [formValue, updateFormValue] = useReducer((state, action) => {
    switch (action.type) {
      case 'changeRealtyType':
        return { ...state, realtyType: action.payload };
      case 'toggleResidental':
        return { ...state, isResidental: action.payload };
      case 'changeTradeType':
        return { ...state, tradeType: action.payload, prices: null };
      case 'changePrices':
        return { ...state, prices: { ...(state.prices || {}), ...action.payload } };
      case 'changeContractor':
        return { ...state, contractor: action.payload };
      case 'toggleJointBrokerage':
        return { ...state, isJointBrokerage: action.payload };
      case 'refresh':
        return initialState;
      default:
        return { ...state };
    }
  }, initialState);

  const { realtyType, tradeType, prices, contractor, isJointBrokerage, isResidental } = formValue;

  const updateRealtyType = (e) => {
    updateFormValue({ type: 'changeRealtyType', payload: e.target.value });
  };

  const updateResidental = (e) => {
    updateFormValue({ type: 'toggleResidental', payload: e.target.checked });
  };

  const updateTradeType = (e) => {
    updateFormValue({ type: 'changeTradeType', payload: e.target.value });

    setTimeout(() => {
      const element = document.getElementById('feeCalculatorPriceForm');
      const inputs = element.getElementsByTagName('input');
      inputs?.[0]?.focus?.();
    }, 100);
  };

  const updatePrices = (value, { priceKey }) => {
    updateFormValue({ type: 'changePrices', payload: { [priceKey]: value } });
  };

  const updateContractor = (e) => {
    updateFormValue({ type: 'changeContractor', payload: e.currentTarget.getAttribute('data-contractor-type') });
  };

  const updateJointBrokerage = (e) => {
    updateFormValue({ type: 'toggleJointBrokerage', payload: e.target.checked });
  };

  const routeToFeePolicy = (e) => {
    e.preventDefault();
    dispatch(router.replace(router.querystring.add('terms', policyService.types.BrokerageCommisionRate)));
  };

  useEffect(() => {
    if (!realtyType || !tradeType || !contractor) return;

    if (!isJointBrokerage && contractor === 'seller') {
      setResult(0);
    } else {
      const priceKeys = getPriceKeys(tradeType);

      const fee = calcBrokerFeeDawin({
        price: prices?.[priceKeys[0]],
        monthly: prices?.[priceKeys[1]],
        category: realtyType,
        trade_type: tradeType,
        area: isResidental ? 85 : 86,
        isJointBrokerage: contractor === 'seller' ? isJointBrokerage : false,
        returnOriginalValue: true,
      });

      if (typeof fee === 'number') {
        setResult(Math.floor(fee * 10000));
      } else {
        setResult(fee);
      }
    }
  }, [formValue]);

  return (
    <TemplateContentsPopup
      popupId="Calculator"
      title={'중개수수료 계산기'}
      onClose={() => {
        if (onClose) onClose();
        else dispatch(router.goBack());
      }}>
      <div className="popup-local-body">
        <div className="popup-content">
          <div className="popup-content-body">
            <fieldset className="submit-form module-a type-c large">
              <legend>중개수수료 계산 서식</legend>
              <div className="form-list">
                <TemplateFormItem title="매물유형">
                  <div className="form-area">
                    <div className="option-list module-b style-a type-a">
                      {[
                        { label: '주택', key: 'APT' },
                        { label: '상가', key: 'STO' },
                        { label: '오피스텔', key: 'OFT' },
                      ].map((item) => (
                        <OptionItem key={item.key} name="radio-0001" value={item.key} checked={realtyType === item.key} onClick={updateRealtyType}>
                          {item.label}
                        </OptionItem>
                      ))}
                    </div>
                  </div>
                  {realtyType === 'OFT' ? (
                    <div className="form-area">
                      <div className="option-list module-b style-a type-a">
                        <span className="option-item">
                          <label className="form checkbox module-a style-b type-line accent-01 small-1x">
                            <input type="checkbox" className="form-elem" checked={isResidental} onClick={updateResidental} />
                            <span className="form-text">주거용이며, 전용면적 32평(85m²)이하입니다.</span>
                          </label>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </TemplateFormItem>
                <TemplateFormItem title="거래유형">
                  <div className="form-area">
                    <div className="option-list module-b style-a type-a">
                      {[
                        { key: 's', label: '매매' },
                        { key: 'c', label: '전세' },
                        { key: 'm', label: '월세' },
                      ].map((item) => (
                        <OptionItem key={item.key} name="radio-0002" value={item.key} checked={tradeType === item.key} onClick={updateTradeType}>
                          {item.label}
                        </OptionItem>
                      ))}
                    </div>
                  </div>
                </TemplateFormItem>

                <TemplateFormItem title="거래금액">
                  <div className="submit-form module-b style-a type-a large" id="feeCalculatorPriceForm">
                    {tradeType ? (
                      <GoodsPriceForm goods={[{ trade_type: tradeType, ...prices }]} onChangeCallback={updatePrices} />
                    ) : (
                      <GoodsPriceForm
                        goods={[{ trade_type: 's' }]}
                        onChangeCallback={() => dispatch(actions.common.alert({ contents: '거래유형을 선택해주세요' }))}
                      />
                    )}
                  </div>
                </TemplateFormItem>
              </div>
            </fieldset>
            <div className="tab-display module-c style-a type-a large-2x" data-bui-tab="contentTab">
              <div className="tab-list">
                <span className={`tab-item ${contractor === 'seller' ? 'current' : ''}`}>
                  <a className="tab-text" onClick={updateContractor} data-contractor-type="seller">
                    집내놓은 손님<em className="small-2x">(매도/임대인)</em>
                  </a>
                </span>
                <span className={`tab-item ${contractor === 'buyer' ? 'current' : ''}`}>
                  <a className="tab-text" onClick={updateContractor} data-contractor-type="buyer">
                    집구하는 손님<em className="small-2x">(매수인/임차인)</em>
                  </a>
                </span>
              </div>
            </div>
            <div className="info-board calculation-result">
              <div className="board-wrap">
                <div className="board-head">
                  <p className="board-summary">
                    {!isJointBrokerage && contractor === 'seller' && prices ? (
                      <>
                        중개수수료 <br />
                        <strong className="em large">무료</strong>
                      </>
                    ) : (
                      <>
                        중개수수료
                        <br />
                        <strong className="em large">{autoComma(result)}원</strong>
                      </>
                    )}
                    {contractor === 'buyer' ? (
                      <div>
                        <small>* 다윈중개로 구한 손님인 경우</small>
                      </div>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
                <div className="board-util">
                  <div className="button-display module-a style-a type-c">
                    <span className="button-area">
                      <a className="btn module-a style-c type-line normal-04 small-1x" onClick={routeToFeePolicy}>
                        <span className="btn-text">다윈중개 중개보수율표 보기</span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {contractor === 'seller' && (
              <div className="option-display">
                <div className="option-list module-b style-a type-a">
                  <span className="option-item">
                    <label className="form checkbox module-a style-b type-line accent-01 small-1x">
                      <input type="checkbox" className="form-elem" checked={isJointBrokerage} onClick={updateJointBrokerage} />{' '}
                      <span className="form-text">공동중개 여부</span>
                    </label>
                  </span>
                </div>
              </div>
            )}
            {contractor === 'seller' && (
              <div className="guide-board module-a style-a type-a">
                <div className="board-wrap">
                  <div className="board-body">
                    <p className="para normal-03 small-2x">
                      <span className="wbr">
                        공동 중개시 집주인 동의 하에 일부 중개보수를 받으실 수 있습니다. 집 보여주기 전 공동중개라는 점을 집주인에게 알려주세요.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {contractor === 'buyer' && (
              <div className="guide-board module-a style-a type-a">
                <div className="board-wrap">
                  <div className="board-body">
                    <p className="para normal-03 small-2x">
                      <span className="wbr">
                        다윈매물이라 하더라도 집구하는 손님을 직접 구하셨다면, 다윈중개수수료 대신 법정 수수료 이내로 협의하시면 됩니다.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </TemplateContentsPopup>
  );
};

export default toggle(FeeCalculator);
