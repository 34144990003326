import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/data';
import SideNavWithBtn from '@/components/Navigation/items/SideNavWithBtn';
/**
 * 뒤로가기, 제목, 메뉴 header
 */
const GoBackMenuWithTitle = ({ title, goBackTo }) => {
  const dispatch = useDispatch();
  const onGoBack = () => {
    if (goBackTo) {
      dispatch(actions.router.replace(goBackTo));
    } else {
      // 뒤로가기
      if (history.length === 1) {
        // 뒤로 갈 history가 없는 경우 메인으로(공유된 링크로 접속 시)
        dispatch(actions.router.push('/'));
      } else {
        dispatch(actions.router.goBack());
      }
    }
  };
  return (
    <header id="header" className={'header_type01'}>
      <h2 className="title">{title}</h2>
      <a className="btn_close_m" onClick={onGoBack}>
        닫기
      </a>
    </header>
  );
};

export default GoBackMenuWithTitle;
