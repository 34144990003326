import Cookies from 'js-cookie';
import appStorage from '@/services/apps/storage';
import { COOKIE_CONSTS } from '@/resources/global';
import AuthSession from '@/services/apps/load/AuthSession';

const ACCESS_TOKEN = COOKIE_CONSTS.ACCESS_TOKEN;
const USER_INFO = COOKIE_CONSTS.USER_INFO;
const MAP_INFO = COOKIE_CONSTS.MAP_INFO;
const SAVE_ID = COOKIE_CONSTS.SAVE_ID;
const ANALYSIS = COOKIE_CONSTS.ANALYSIS;
// const APPINFO = COOKIE_CONSTS.APPINFO;
// const DEVICE_ID = 'devideId'
// const PUSH_TOKEN = 'pushToken'

// 쿠키 getter
export async function getCookieByKey(key) {
  return await appStorage.get(key);
}

// 쿠키 setter
export async function setCookieByKey(key, value, options = {}) {
  if (value) {
    await appStorage.set(key, value, options);
  } else {
    await appStorage.del(key);
  }
}

//  인증 getter
export async function getTokenCookie() {
  return await getCookieByKey(ACCESS_TOKEN);
}

// 인증 setter
export async function setTokenCookie(accessToken) {
  await setCookieByKey(ACCESS_TOKEN, accessToken);

  // update access token
  var initialData = new AuthSession();
  initialData.updateAccessToken(accessToken);
}

// 유저 getter
export async function getUserCookie() {
  return await getCookieByKey(USER_INFO);
}

// 유저 setter
export async function setUserCookie(userInfo) {
  await setCookieByKey(USER_INFO, userInfo);
}

// 인증 및 유저 setter
export async function setAuthCookie(accessToken, userInfo) {
  await setTokenCookie(accessToken);
  await setUserCookie(userInfo);
}

// 인증 및 유저 reset
export async function clearAuthCookie() {
  await appStorage.del(ACCESS_TOKEN);
  await appStorage.del(USER_INFO);
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(USER_INFO);
}

// 지도 getter
export async function getMapCookie() {
  return await getCookieByKey(MAP_INFO);
}

// 지도 setter
export async function setMapCookie(_zoomLevel, _center) {
  let center = _center.length < 2 ? null : _center;
  center = _center && _center[0] && _center[1] ? _center : null;

  const mapInfo = { zoomLevel: _zoomLevel, center };

  await setCookieByKey(MAP_INFO, mapInfo);
}

// 아이디 getter
export async function getSaveIdCookie() {
  return await getCookieByKey(SAVE_ID);
}

// 아이디 setter
export async function setSaveIdCookie(email) {
  await setCookieByKey(SAVE_ID, email);
}

// 분석 getter
export async function isVisitedAnalysis(category) {
  const visitedAnalysCookie = await appStorage.get(ANALYSIS);

  if (visitedAnalysCookie) {
    return visitedAnalysCookie.indexOf(category) > -1;
  } else {
    return false;
  }
}

// 분석 setter
export async function setAnalysisVisitedCookie(category) {
  const visitedAnalysCookie = (await appStorage.get(ANALYSIS)) || '';
  if (visitedAnalysCookie.indexOf(category) < 0) {
    await appStorage.set(ANALYSIS, visitedAnalysCookie + ',' + category);
  }
}
