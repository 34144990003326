import React from 'react';
import Input from '@/combinedComp/Search/Input';
import Result from '@/combinedComp/Search/Result';

const SearchView = (props) => {
  const { show, keyword, data, wrapperRef } = props;

  return (
    <>
      <div className="form-area" ref={wrapperRef}>
        <span className="form textfield module-b style-b type-line normal-04 large-1x flex">
          {/* 검색 */}
          <Input {...props} />
        </span>
      </div>

      {/* 검색 결과 */}
      {show && keyword && <Result {...props} show={show} data={data} keyword={keyword} wrapperClass="data-board data-finder-autocomplete" />}
    </>
  );
};

export default SearchView;
