import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { actions as allActions, selectors } from '@/data';
import { setCookieByKey } from '@/data/cookie';
import apis from '@/apis';
import { STATUS } from '../utils';
import React from 'react';
import { FeedType } from '@/services/Community';
import { paramsLogger } from '@/services/Logger/utils/paramsLogger';

export function* searchTags({ keyword, totalSearch }) {
  try {
    yield put(actions.searchTagLoading());
    const result = yield apis.communityApi.getTagsBySearchText({ searchText: keyword });
    yield put(actions.searchTagSuccess(result));
  } catch (e) {
    yield put(actions.searchTagFailure(e));
  }
}

export function* createFeed({ payload, setStatus, location, e }) {
  try {
    setStatus && setStatus({ status: STATUS.LOADING });
    const result = yield call(apis.communityApi.createFeed, payload);
    if (setStatus) setStatus({ status: STATUS.SUCCESS, result });
    yield put(allActions.community.setCreatedFeed({ status: true, data: payload }));
    paramsLogger({ location, e, payload });
  } catch (e) {
    yield put(
      allActions.common.alert({
        contents: (
          <>
            죄송합니다.
            <br />
            작성한 글 등록 중 오류가 발생하였습니다.
            <br />
          </>
        ),
      })
    );
    setStatus && setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}
export function* editFeed({ feedId, payload, setStatus }) {
  try {
    yield setStatus({ status: STATUS.LOADING });
    const result = yield apis.communityApi.modifyFeed(feedId, payload);
    yield put(allActions.community.setFeed(result));
    yield put(allActions.community.fireReload(true));
    yield put(allActions.community.setCreatedFeed({ status: true, data: payload }));
    if (setStatus) setStatus({ status: STATUS.SUCCESS, result });
  } catch (e) {
    yield put(
      allActions.common.alert({
        contents: (
          <>
            죄송합니다.
            <br />
            작성한 글 등록 중 오류가 발생하였습니다.
            <br />
          </>
        ),
      })
    );
    yield setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}
export function* deleteFeed({ feedId, setStatus }) {
  try {
    yield setStatus({ status: STATUS.LOADING });
    const result = yield apis.communityApi.deleteFeed(feedId);
    if (setStatus) setStatus({ status: STATUS.SUCCESS, result });
    const deletedFeeds = yield select(selectors.community.deletedFeeds);
    const newDeletedFeeds = [...deletedFeeds, feedId];
    yield put(actions.setDeletedFeeds(newDeletedFeeds));
  } catch (e) {
    yield put(
      allActions.common.alert({
        contents: (
          <div className="board-body">
            <p className="para">
              <span className="wbr">죄송합니다.</span>
              <span className="wbr">작성한 글 삭제 중 오류가 발생하였습니다.</span>
            </p>
          </div>
        ),
      })
    );
    yield setStatus({ status: STATUS.FAILURE, error: e.message });
  }
}

/**
 * @param {object} payload (required): { feedTypes, priorityTag, limit, cursor } 형태의 json
 *    - feedTypes: [] - 조회하는 글 타입
 *    - priorityTag: {} - 조회하는 검색어
 *    - limit: 12 - 조회하는 게시물의 개수
 *    - cursor: "" - 더보기
 * @param {object} feeds (optional): { data, status, next } 형태의 json
 *    - data: [] - 게시물 더보기 할 시점까지의 게시물 데이터
 *    - status: "LOADING/FAILURE/SUCCESS"
 *    - next: "" - 뒤에 더 불러올 글이 있는지 없는지 판단
 * @param {function} setFeeds (required): { data, status, next } 형태의 json 데이터를 전달받는 함수
 */
export function* getFeeds({ payload, feeds, setFeeds, setNext }) {
  // 여기에 파람스로 어떤 경우의 getFeeds인지 타입을 보내는건 어떤가요? !feeds 이렇게 구분하면 이해가 잘 안되는 듯 한데...
  // 아니면 무조건 feeds를 앞에서 보내도록 하고, !feeds가 아니라 if (payload?.priorityTag) 이런식으로 갈까?
  // setFeeds하는 부분을 공통으로 쓰려면 어떻게 해야할까요?
  // api에서는 call, setFeeds는 action을 dispatch하는게 아니어도 put을 사용해야하나?
  // 근데 여기에 call 넣으면 오류 뜸!
  try {
    // 여기 다이어트 가능
    if (!feeds) {
      // 검색어가 포함되어있을때에는 무조건 이곳 통과
      if (payload?.priorityTag) {
        setFeeds({ status: STATUS.LOADING });
        const result = yield call(apis.communityApi.getFeeds, payload);
        setFeeds({ data: result?.data, next: result?.next, status: STATUS.SUCCESS });
        if (setNext) setNext(result?.next);
      } else {
        // 카테고리를 버튼으로 타입 변경하면 무조건 이곳 통과
        setFeeds({ status: STATUS.LOADING });
        const result = yield apis.communityApi.getFeeds(payload);
        setFeeds({ data: result?.data, next: result?.next, status: STATUS.SUCCESS });
        if (setNext) setNext(result?.next);
      }
    } else {
      // 더보기 일때
      if (payload?.cursor) {
        setFeeds({ ...feeds, status: STATUS.LOADING });
        const result = yield apis.communityApi.getFeeds(payload);
        const newData = [...feeds?.data, ...result?.data];
        setFeeds({ data: newData, next: result?.next, status: STATUS.SUCCESS });
        if (setNext) setNext(result?.next);
      }
    }

    // 넥스트가 더이상 없을 경우는?

    // let re_payload = {}; //payload;
    // if (payload?.searchTag?.key) {
    //   // let tmp_tags = payload?.tags?.length > 0 ? [...payload?.tags] : [];
    //   //tmp_tags.push(payload?.searchTag);
    //   re_payload = { ...payload, priorityTag: payload?.searchTag };
    // } else {
    //   re_payload = payload;
    // }
    // if (re_payload?.searchTag) delete re_payload?.searchTag;
    // // setNext('');
    // let origin_data = feeds?.data ? feeds?.data : [];
    // yield setFeeds({ status: STATUS.LOADING, data: origin_data });
    // const result = yield apis.communityApi.getFeeds(re_payload);
    // if (result?.data && result?.data?.length > 0) {
    //   if (window.location.pathname.indexOf('/community') > -1) {
    //     let cookie_payload = { ...re_payload, cursor: undefined, priorityTag: undefined, searchTag: undefined };
    //     // setCookieByKey('community_filter', cookie_payload);
    //   }
    //   if (origin_data && origin_data?.length > 0 && payload?.cursor) {
    //     result.data.map((item) => origin_data.push(item));
    //     yield setFeeds({ status: STATUS.SUCCESS, data: origin_data });
    //   } else {
    //     // setFeeds({ status: STATUS.SUCCESS, data: result?.data });
    //   }
    //   // yield setNext(result?.next);
    // } else {
    //   // console.log('payload : ');
    //   // console.log(payload);
    //   if (window.location.pathname.indexOf('/community') > -1) {
    //     let cookie_payload = { ...payload, cursor: undefined, priorityTag: undefined, searchTag: undefined };
    //     // setCookieByKey('community_filter', cookie_payload);
    //   }
    //   if (payload?.cursor) setFeeds({ status: STATUS.SUCCESS, data: origin_data });
    //   else setFeeds({ status: STATUS.SUCCESS, data: [] });
    //   // setNext('');
    // }
    // yield put(allActions.community.fireReload(false));
    // // console.log(payload);
  } catch (e) {
    yield setFeeds({ ...feeds, status: STATUS.FAILURE });
    console.log(e.message);
  }
}

//feed like
export function* feedLike({ feedId, reactionType, setStatus }) {
  try {
    yield setStatus({ status: STATUS.LOADING, reactionType: reactionType });
    let result;
    let payload = {};
    if (reactionType == 'LIKE') {
      payload = {
        reaction: reactionType,
      };
      result = yield apis.communityApi.createReaction(feedId, payload);
    } else if (reactionType == 'UNLIKE') {
      result = yield apis.communityApi.deleteReaction(feedId);
    }
    yield setStatus({ status: STATUS.SUCCESS, reactionType: reactionType, result: result?.data });
  } catch (e) {
    yield setStatus({ status: STATUS.FAILURE, reactionType: reactionType });
    console.log(e.message);
  }
}
//comment like
export function* commentLike({ commentId, reactionType, setStatus }) {
  try {
    yield setStatus({ status: STATUS.LOADING, reactionType: reactionType });
    let result;
    let payload = {};
    if (reactionType == 'LIKE') {
      payload = {
        reaction: reactionType,
      };
      result = yield apis.communityApi.creatCommentReactions(commentId, payload);
    } else if (reactionType == 'UNLIKE') {
      result = yield apis.communityApi.deleteCommentReactions(commentId);
    }
    yield setStatus({ status: STATUS.SUCCESS, reactionType: reactionType, result: result?.data });
  } catch (e) {
    yield setStatus({ status: STATUS.FAILURE, reactionType: reactionType });
    console.log(e.message);
  }
}
//신고하기
export function* report({ payload }) {
  try {
    const result = yield apis.communityApi.createReport(payload);
    if (result) {
      // 2022.9.28 (임시) 신고 후 "신고해 주셔서 감사합니다" 팝업 안 나오고 + 강제 리로드 + 커뮤니티 메인 페이지로 이동 처리
      yield put(
        allActions.common.alert({
          contents: (
            <>
              <div className="board-body">
                <p className="para">
                  <span className="wbr">신고해 주셔서 감사합니다.</span>
                  <span className="wbr">깨끗한 사이트 운영을 위해 더 노력하는 다윈중개가 되겠습니다.</span>
                </p>
              </div>
            </>
          ),
          onConfirm: () => {
            window.location.replace(location.pathname);
          },
        })
      );
      // yield put(allActions.router.goBack());
    }
  } catch (e) {
    yield put(allActions.common.alert({ contents: e.message }));
  }
}

export function* getFeed({ feedId, setFeed }) {
  try {
    yield put(actions.setFeedStatus(STATUS.LOADING));
    const result = yield apis.communityApi.getFeed(feedId);
    if (setFeed) {
      setFeed(result);
      yield put(allActions.community.setFeedStatus(STATUS.SUCCESS));
    } else {
      yield put(actions.setFeed(result));
      yield put(allActions.community.setFeedStatus(STATUS.SUCCESS));
    }
  } catch (e) {
    yield put(allActions.community.setFeedStatus(STATUS.FAILURE));
    console.log(e.message);
  }
}

export function* getPhoneNumber({ data, callback }) {
  try {
    const result = yield apis.communityApi.getPhoneNumber(data);
    yield put(actions.setPhoneNumber(result.phone));
    if (callback) {
      callback(result.phone);
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* getComments({ payload, comments }) {
  try {
    yield put(actions.setMoreCommentStatus(STATUS.LOADING));
    const result = yield apis.communityApi.getComments(payload);
    let body = {
      data: [],
      next: '',
    };
    if (result?.next) {
      // 처음에 콜했을때는 undefined
      if (comments) {
        body.data = comments.data.concat(result.data);
      } else {
        body.data = result.data;
      }
      body.next = result.next;

      yield put(actions.setComments(body));
      yield put(actions.setMoreCommentStatus(STATUS.SUCCESS));
    } else {
      delete body.next;
      if (comments) {
        const temp = [...comments.data, ...result.data];
        body.data = temp;
      } else {
        body.data = result.data;
      }
      yield put(actions.setComments(body));
      yield put(actions.setMoreCommentStatus(STATUS.SUCCESS));
    }
  } catch (e) {
    console.log(e.message);
    yield put(actions.setMoreCommentStatus(STATUS.FAILURE));
  }
}

export function* createComment({ payload, comments }) {
  try {
    yield put(actions.setCommentStatus(STATUS.LOADING));
    if (comments) {
      const created = yield apis.communityApi.createComment(payload);
      yield put(actions.setComments({ data: comments.data.concat(created), next: comments.next }));
      yield put(actions.setCommentStatus(STATUS.SUCCESS));
    } else {
      // 첫댓글이면 조회
      yield apis.communityApi.createComment(payload);
      const res = yield apis.communityApi.getComments(payload);

      yield put(actions.setComments(res));
      yield put(actions.setCommentStatus(STATUS.SUCCESS));
    }
  } catch (e) {
    console.log(e.message);
    yield put(actions.setCommentStatus(STATUS.FAILURE));
  }
}
