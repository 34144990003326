import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as selectors from '@/data/rootSelectors';
import Spinner from '@/components/Spinner';

const SpinnerBackdrop = styled.div`
  width: 100%;
  height: 100%;

  background: ${(props) => (props.theme === 'dark' ? `#0000009e` : `transparent`)};

  position: fixed;
  top: 0;
  z-index: 6000;

  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: unset;

    .sellingSpinner {
      position: unset;
      transform: unset;
      padding: 10px;
      background: #404040;
      border-radius: 25px;
      opacity: 0.8;
    }

    .text {
      margin-left: 10px;
      font-size: 20px;
      color: ${(props) => (props.theme === `dark` ? `#fff` : `#0000004f`)};
    }
  }
`;

export default function SpinnerWithCover({ show: showInit }) {
  const showSpinner = useSelector(selectors.common.showSpinner);

  let show;
  if (showInit) {
    show = showInit;
  } else {
    typeof showSpinner === 'boolean' && (show = showSpinner);
    typeof showSpinner === 'object' && (show = showSpinner.show);
  }

  let theme = 'light'; //'dark';
  typeof showSpinner === 'object' && (theme = showSpinner.theme);
  const spinnerColor = theme === 'dark' ? `#fff` : 'white'; //`#0000004f`;

  // return (
  //   <SpinnerBackdrop theme={'light'}>
  //     <div className="container">
  //       <Spinner className="sellingSpinner" width={3} size={20} color={`#0000004f`} />
  //       <span className="text">잠시만 기다려주세요</span>
  //     </div>
  //   </SpinnerBackdrop>
  // );

  return show ? (
    <SpinnerBackdrop theme={theme}>
      <div className="container">
        <Spinner className="sellingSpinner" width={3} size={20} color={spinnerColor} />
        {/* <span className="text">잠시만 기다려주세요</span> */}
      </div>
    </SpinnerBackdrop>
  ) : null;
}
