import { OSTYPE } from '../types/OsType';

/**
 * 웹인 경우 새 창에 열기
 * 앱인 경우 새 웹뷰에서 열기 (인앱에서 열림)
 *
 * @param {string} url
 * @param {Object} [options] - 옵션
 *  - isDawinUrl: true 인 경우 url에 location.protocol + location.host 를 붙여준다.
 *  - webViewType:  "fullscreenHeadless" | "cardViewFullControllable" | "cardViewSimpleControllable"
 *                  app bridge - webViewControlFunction.newWebView 의 type이면 모두 가능
 *  - target: default는 "_blank" javascript window.open 함수의 target 파라미터
 *  - features: javascript window.open 함수의 feature 파라미터
 */
const openWindow = async (url, options = {}) => {
  const { isDawinUrl = false, webViewType = 'cardViewSimpleControllable', target = '_blank', features = 'noopener noreferrer' } = options;

  const ostype = window.dawin_instance?.osType;
  const isApp = ostype === OSTYPE.APP_ANDROID || ostype === OSTYPE.APP_IOS;

  if (isApp) {
    const fullUrl = isDawinUrl ? `${location.protocol}//${location.host}${url}` : url;
    await window.dawin_instance.bridge.webViewControlFunction.newWebView(webViewType, fullUrl);
  } else {
    window.open(url, target, features);
  }
};

export default openWindow;
