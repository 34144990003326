import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const MG_RATE = { page: 1, total: 40 };

export default function mgRate(state = MG_RATE, action = {}) {
  switch (action.type) {
    case AT.SET_RATE_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case AT.SET_RATE_TOTAL_PAGE: {
      return {
        ...state,
        total: action.page,
      };
    }
    case loading(AT.GET_BROKERAGE_EVALUATION):
      return { ...state, [AT.GET_BROKERAGE_EVALUATION]: Remote.Loading };
    case success(AT.GET_BROKERAGE_EVALUATION):
      return { ...state, brEvaluation: action.brEvaluation };
    case failure(AT.GET_BROKERAGE_EVALUATION):
      return {
        ...state,
        [AT.GET_BROKERAGE_EVALUATION]: Remote.Failure(action.error),
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
