import React from 'react';

const Html = ({ contents }) => {
  //const dispatch = useDispatch();

  //gmh
  /*
  리액트에서는 일반적인 data안에 <br/> 등의 html 태그는 제대로 표기를 못해줌
  해서 아래와 같은 방식으로 표기해야 <br/>이 제대로 적용된 상태로 볼수 있음.
  
  text 정렬은 임의대로 left로 했는데 필요시 prop등으로 조절해야할듯.
  */
  let re_cont = '';
  if (contents) {
    if (contents.indexOf('<br/>') > -1 || contents.indexOf('</br>') > -1 || contents.indexOf('<br>') > -1) {
      re_cont = contents;
    } else {
      re_cont = contents.replace(/\n/gi, '<br/>');
    }

    re_cont = re_cont.replace('<script>', '').replace('</script>', '');
  }

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: re_cont }}></p>
      {/* <div dangerouslySetInnerHTML={{ __html: contents.replace(/\n/gi, '<br/>') }} style={{ textAlign: 'left' }}></div> */}
    </>
  );
};

export default Html;
