import * as sagas from './sagas';
import * as AT from './actionTypes';
import {
  // takeEvery,
  takeLatest,
} from 'redux-saga/effects';

export default function*() {
  yield takeLatest(AT.POST_RATING, sagas.postRating);
  yield takeLatest(AT.POST_RATING_URL, sagas.postRatingUrl);
}
