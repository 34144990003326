import React, { useState } from 'react';
import ButtonApply from './components/ButtonApply';
import ButtonLoadMore from './components/ButtonLoadMore';
import ButtonClose from './components/ButtonClose';
import List from './components/List';
import WrapperContentsPopup from '../WrapperContentsPopup';

const TemplateContentsPopupForList = ({ title, list, maxCount, setMaxCount, closeCallback, applyCallback }) => {
  const [selectedItem, setSelectedItem] = useState();

  const showMoreBtn = list?.length > maxCount;

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  const handleApply = (e) => {
    e.preventDefault();
    if (selectedItem) applyCallback(selectedItem);
    closeCallback();
  };

  const handleClose = (e) => {
    e.preventDefault();
    closeCallback();
  };

  return (
    <WrapperContentsPopup popupId="listContentsPopup">
      <form className="popup-local">
        <div className="popup-local-head">
          <h2 className="popup-local-subject">
            <span className="popup-local-name">{title}</span>
          </h2>
        </div>

        <div className="popup-local-body">
          <div className="popup-content">
            <div className="popup-content-body">
              <div className="goods-display module-a style-a type-a medium">
                {/* 리스트 */}
                <List list={list} maxCount={maxCount} selectedItem={selectedItem} handleSelect={handleSelect} />

                {/* 더 보기 버튼 */}
                {showMoreBtn && <ButtonLoadMore maxCount={maxCount} setMaxCount={setMaxCount} />}
              </div>
            </div>
          </div>
        </div>

        {/* 적용 버튼 */}
        <ButtonApply onClickButton={handleApply} disabled={!selectedItem} />

        {/* 닫기 버튼 - 퍼블에는 닫기 버튼이 없어서 임의로 프론트에서 생성한 버튼*/}
        <ButtonClose onClickButton={handleClose} />
      </form>
    </WrapperContentsPopup>
  );
};

export default TemplateContentsPopupForList;
