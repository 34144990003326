export const calcBrokerFeeDawin = ({ price, monthly, category, trade_type, area, isJointBrokerage, returnOriginalValue = false }) => {
  if (!category || !trade_type || !price || price < 0) {
    return null;
  }

  let result;

  price = Number(price);
  monthly = Number(monthly);

  //월세 가격 계산
  //월세는 (보증금 + 월세 * 100)을  거래금액으로 한다.
  //이 거래 금액이 5천만원 이하일 때, (보증금 + 월세 * 70)으로 한다.
  if (trade_type === 'm') {
    price = price + monthly * 100;
    if (price < 5000) {
      price = price + monthly * 70;
    }
  }

  if (category === 'APT') {
    //아파트
    if (trade_type === 's') {
      if (isJointBrokerage) {
        //매매
        if (price < 5000) {
          result = price * 0.004;
        } else if (price >= 5000 && price < 20000) {
          result = price * 0.003;
        } else if (price >= 20000 && price < 60000) {
          result = price * 0.002;
        } else if (price >= 60000 && price < 90000) {
          result = price * 0.002;
        } else if (price >= 90000 && price < 120000) {
          result = price * 0.002;
        } else if (price >= 120000 && price < 150000) {
          result = price * 0.002;
        } else if (price >= 150000) {
          result = price * 0.002;
        }
      } else {
        //매매
        if (price < 5000) {
          result = price * 0.006;
        } else if (price >= 5000 && price < 20000) {
          result = price * 0.005;
        } else if (price >= 20000 && price < 60000) {
          result = price * 0.003;
        } else if (price >= 60000 && price < 90000) {
          result = price * 0.003;
        } else if (price >= 90000 && price < 120000) {
          result = price * 0.0035;
        } else if (price >= 120000 && price < 150000) {
          result = price * 0.0035;
        } else if (price >= 150000) {
          result = price * 0.0035;
        }
      }
    } else {
      if (isJointBrokerage) {
        //전 월세
        if (price < 5000) {
          result = price * 0.003;
        } else if (price >= 5000 && price < 10000) {
          result = price * 0.002;
        } else if (price >= 10000 && price < 30000) {
          result = price * 0.002;
        } else if (price >= 30000 && price < 60000) {
          result = price * 0.002;
        } else if (price >= 60000 && price < 90000) {
          result = price * 0.002;
        } else if (price >= 90000 && price < 120000) {
          result = price * 0.002;
        } else if (price >= 120000 && price < 150000) {
          result = price * 0.002;
        } else if (price >= 150000) {
          result = price * 0.002;
        }
      } else {
        //전 월세
        if (price < 5000) {
          result = price * 0.005;
        } else if (price >= 5000 && price < 10000) {
          result = price * 0.004;
        } else if (price >= 10000 && price < 30000) {
          result = price * 0.003;
        } else if (price >= 30000 && price < 60000) {
          result = price * 0.003;
        } else if (price >= 60000 && price < 90000) {
          result = price * 0.003;
        } else if (price >= 90000 && price < 120000) {
          result = price * 0.003;
        } else if (price >= 120000 && price < 150000) {
          result = price * 0.003;
        } else if (price >= 150000) {
          result = price * 0.003;
        }
      }
    }
  } else if (category === 'OFT') {
    //주거용, 비주거용에 대한 구분은 할 수 없음(데이터X)
    if (isJointBrokerage) {
      result = price * 0.002;
    } else if (area <= 85) {
      //85 이하
      if (trade_type === 's') {
        if (price < 10000) {
          //1억 미만
          result = price * 0.004;
        } else {
          result = price * 0.003;
        }
      } else {
        if (price < 10000) {
          //1억 미만
          result = price * 0.003;
        } else {
          result = price * 0.0025;
        }
      }
    } else {
      //85초과
      if (price < 3000) {
        //3천 미만
        result = price * 0.009;
      } else if (price >= 3000 && price < 10000) {
        result = price * 0.006;
      } else {
        result = price * 0.0045;
      }
    }
  } else if (category === 'STO') {
    if (isJointBrokerage) {
      if (price < 3000) {
        //3천 미만
        result = price * 0.005;
      } else if (price >= 3000 && price < 10000) {
        result = price * 0.004;
      } else {
        result = price * 0.002;
      }
    } else {
      if (price < 3000) {
        //3천 미만
        result = price * 0.009;
      } else if (price >= 3000 && price < 10000) {
        result = price * 0.006;
      } else {
        result = price * 0.0045;
      }
    }
  }

  if (returnOriginalValue) {
    return result;
  } else {
    return Math.round(result);
  }
};

export const calcBrokerFeeCommon = ({ price, monthly, category, trade_type, area }) => {
  let result;

  price = Number(price);
  monthly = Number(monthly);
  // 2021-02-15 정석규 수정
  // [DX-1551] [네이버매물] 매물상세의 중개수수료 오류
  // `trade_type`을 integer로 넘겨주는데 여기서 비교를 string과 strict equality (===) 로 해서 버그가 발생함.
  // i.e.) 1 === '1' -> false
  // 따라서 비교를 진행하기 전에 안전하게 `trade_type`을 string type으로 type cast를 진행.

  //월세 가격 계산
  //월세는 (보증금 + 월세 * 100)을  거래금액으로 한다.
  //이 거래 금액이 5천만원 이하일 때, (보증금 + 월세 * 70)으로 한다.
  if (trade_type === 'm') {
    price = price + monthly * 100;
    if (price < 5000) {
      price = price + monthly * 70;
    }
  }
  if (category === 'APT') {
    //아파트
    if (trade_type === 's') {
      //매매
      if (price < 5000) {
        result = price * 0.006;
      } else if (price >= 5000 && price < 20000) {
        result = price * 0.005;
      } else if (price >= 20000 && price < 60000) {
        result = price * 0.004;
      } else if (price >= 60000 && price < 90000) {
        result = price * 0.004;
      } else if (price >= 90000 && price < 120000) {
        result = price * 0.005;
      } else if (price >= 120000 && price < 150000) {
        result = price * 0.006;
      } else if (price >= 150000) {
        result = price * 0.007;
      }
    } else {
      //전 월세
      if (price < 5000) {
        result = price * 0.005;
      } else if (price >= 5000 && price < 10000) {
        result = price * 0.004;
      } else if (price >= 10000 && price < 30000) {
        result = price * 0.003;
      } else if (price >= 30000 && price < 60000) {
        result = price * 0.003;
      } else if (price >= 60000 && price < 90000) {
        result = price * 0.004;
      } else if (price >= 90000 && price < 120000) {
        result = price * 0.004;
      } else if (price >= 120000 && price < 150000) {
        result = price * 0.005;
      } else if (price >= 150000) {
        result = price * 0.006;
      }
    }
  } else if (category === 'OFT') {
    //주거용, 비주거용에 대한 구분은 할 수 없음(데이터X)
    if (area <= 85) {
      //85 이하
      if (trade_type === 's') {
        result = price * 0.005;
      } else {
        result = price * 0.004;
      }
    } else {
      //85초과
      result = price * 0.009;
    }
  }
  return Math.round(result);
};
