// 분석 상세 메뉴 구분자
export const base = {
  ANALYSIS: 'ANALYSIS', // '분석' 기본값
  ANALYSIS_WEEKLY_PRICE: 'ANALYSIS_WEEKLY_PRICE', // 주간실거래가
  ANALYSIS_TRADE_VOLUME: 'ANALYSIS_TRADE_VOLUME', // 거래량
  ANALYSIS_TRADE_VOLUME_BY_ADDRESS: 'ANALYSIS_TRADE_VOLUME_BY_ADDRESS', // 주소별 거래량
  ANALYSIS_PRICE_FLUC: 'ANALYSIS_PRICE_FLUC', // 가격변동
  ANALYSIS_PRICE_FLUC_BY_ADDRESS: 'ANALYSIS_PRICE_FLUC_BY_ADDRESS', // 주소별 가격변동
  ANALYSIS_JEONSAE_PERCENTAGE: 'ANALYSIS_JEONSAE_PERCENTAGE', // 전세가율
  ANALYSIS_JOB_NUM: 'ANALYSIS_JOB_NUM', // 일자리수
  ANALYSIS_SCHOOL: 'ANALYSIS_SCHOOL', // 교육
  ANALYSIS_SUPPLY_AMT: 'ANALYSIS_SUPPLY_AMT', // 공급량
  ANALYSIS_POSITIVES: 'ANALYSIS_POSITIVES', // 개발호재
  ANALYSIS_RECON_FEASIBILITY: 'ANALYSIS_RECON_FEASIBILITY', // 재건축사업성
  ANALYSIS_APT_COMP: 'ANALYSIS_APT_COMP', // 아파트비교
  ANALYSIS_RANKING: 'ANALYSIS_RANKING', // 랭킹
  ANALYSIS_PUBLIC_NOTICE: 'ANALYSIS_PUBLIC_NOTICE', // 공시지가
  ANALYSIS_RECOMMEND: 'ANALYSIS_RECOMMEND', // 추천
};
const constants = {
  ...base,
  // MAP
  MAP_TYPE_EMPTY: 'MAP_TYPE_EMPTY',
  MAP_DYNAMIC_CIRCLE_LIST: [
    base.ANALYSIS_TRADE_VOLUME, // 거래량
    base.ANALYSIS_PRICE_FLUC, //가격 변동
    base.ANALYSIS_JEONSAE_PERCENTAGE, //전세가율
    base.ANALYSIS_JOB_NUM, //일자리 수
    base.ANALYSIS_SCHOOL, //교육 => 단, 교육은 조금 다르기 때문에, 별도로 구분
    base.ANALYSIS_SUPPLY_AMT, //공급량
    base.ANALYSIS_RECON_FEASIBILITY, //재건축사업성
    base.ANALYSIS_PUBLIC_NOTICE, //공시지가
    base.ANALYSIS_RECOMMEND, // 추천
  ],
  MAP_HISTORY_SUMMARY_GRAPH_LIST: [
    base.ANALYSIS_JOB_NUM, //일자리
    base.ANALYSIS_PUBLIC_NOTICE, //공시지가
    base.ANALYSIS_TRADE_VOLUME, // 거래량
    base.ANALYSIS_PRICE_FLUC, //가격 변동
    base.ANALYSIS_JEONSAE_PERCENTAGE, //전세가율
    base.ANALYSIS_SUPPLY_AMT, //공급량
    base.ANALYSIS_RECON_FEASIBILITY, //재건축사업성
    base.ANALYSIS_RECOMMEND, // 추천
  ],

  // 지도 최초 줌 레벨
  MAP_INITIAL_LEVEL: {
    [base.ANALYSIS_TRADE_VOLUME]: 7, // 거래량
    [base.ANALYSIS_PRICE_FLUC]: 7, // 가격변동
    [base.ANALYSIS_RECON_FEASIBILITY]: 5, // 재건축사업성
    [base.ANALYSIS_POSITIVES]: 7, // 개발호재
    [base.ANALYSIS_JOB_NUM]: 7, // 일자리수
    [base.ANALYSIS_APT_COMP]: 4, //아파트비교
    [base.ANALYSIS_SCHOOL]: 5, // 교육
    [base.ANALYSIS_PUBLIC_NOTICE]: 7, // 공시지가
    [base.ANALYSIS_RANKING]: 6, // 랭킹
  },

  // 지도 최대 줌 레벨
  MAP_MAX_LEVEL: {
    [base.ANALYSIS]: 4,
    [base.ANALYSIS_TRADE_VOLUME]: 8, // 거래량
    [base.ANALYSIS_PRICE_FLUC]: 8, // 가격변동
    [base.ANALYSIS_RECON_FEASIBILITY]: 6, // 재건축사업성
    [base.ANALYSIS_POSITIVES]: 9, // 개발호재
    [base.ANALYSIS_APT_COMP]: 4, //아파트비교
    [base.ANALYSIS_SCHOOL]: 5, // 교육
    // 그 외, 최대 제한 필요없음
    // [base.ANALYSIS_JOB_NUM]: 7, // 일자리수
    // [base.ANALYSIS_PUBLIC_NOTICE]: 7, // 공시지가
    // [base.ANALYSIS_RANKING]: 6, // 랭킹
  },

  // PATH
  PATH: {
    [base.ANALYSIS_WEEKLY_PRICE]: '/weeklyprice', // 주간실거래가
    [base.ANALYSIS_TRADE_VOLUME]: '/volume', // 거래량
    [base.ANALYSIS_PRICE_FLUC]: '/price', // 가격변동
    [base.ANALYSIS_RECON_FEASIBILITY]: '/reconstruction', // 재건축사업성
    [base.ANALYSIS_POSITIVES]: '/positive', // 개발호재
    [base.ANALYSIS_JOB_NUM]: '/job', // 일자리수
    [base.ANALYSIS_APT_COMP]: '/compare', //아파트비교
    [base.ANALYSIS_SCHOOL]: '/edu', // 교육
    [base.ANALYSIS_PUBLIC_NOTICE]: '/public', // 공시지가
    [base.ANALYSIS_RANKING]: '/ranking', // 랭킹
    [base.ANALYSIS_RECOMMEND]: '/recommend', // 추천
  },
  PATH_DEF: {
    weeklyprice: base.ANALYSIS_WEEKLY_PRICE, // 주간실거래가
    volume: base.ANALYSIS_TRADE_VOLUME, // 거래량
    price: base.ANALYSIS_PRICE_FLUC, // 가격변동
    reconstruction: base.ANALYSIS_RECON_FEASIBILITY, // 재건축사업성
    positive: base.ANALYSIS_POSITIVES, // 개발호재
    job: base.ANALYSIS_JOB_NUM, // 일자리수
    compare: base.ANALYSIS_APT_COMP, //아파트비교
    edu: base.ANALYSIS_SCHOOL, // 교육
    public: base.ANALYSIS_PUBLIC_NOTICE, // 공시지가
    ranking: base.ANALYSIS_RANKING, // 랭킹
    recommend: base.ANALYSIS_RECOMMEND,
  },
  URL_DEF: {
    complex: 'COMPLEX_DETAIL', //단지 상세보기
    reconstruction: base.ANALYSIS_RECON_FEASIBILITY, //재건축사업성
    compare: base.ANALYSIS_APT_COMP, //아파트 비교
    ranking: base.ANALYSIS_RANKING, //랭킹
    weeklyprice: base.ANALYSIS_WEEKLY_PRICE, // 주간 실거래가
    edu: base.ANALYSIS_SCHOOL, // 교육
    school: 'SCHOOL_DETAIL',
    kinder: 'KINDERGARTEN_DETAIL',
    daycare: 'DAYCARE_DETAIL',
  },

  // 줌레벨 제한 있는 카테고리 목록 (지도 확대해달라는 텍스틒 표시해야 하는 목록)
  MAP_ZOOMLEVEL_LIMIT_LIST: [base.ANALYSIS, base.ANALYSIS_APT_COMP],

  // @todo 정해야함 => 임의로 1~3은 도단위, 4~6은 시,군,구 단위, 7~9는 동단위, 10~14 는 단지단위로 나눠놓음
  MAP_ZOOM_THRESHOLDS: [3, 6, 9, 14],

  MAP_SINGLE_MARKER_MULTIPLE: 1.5,
  MAP_RECON_FEASIBILITY_THRES: 50,

  TRAIN_SUBWAY: 'TRAIN_SUBWAY',
  CONSTRUCTION: 'CONSTRUCTION',
  RECONSTRUCTION: 'RECONSTRUCTION',
  // ======================== POPUP ======================== // @06/14 추천 탭으로 전환
  // 시도 시, 아직 구현되지 않았음을 표현하는 것으로 대체
  POPUP_RECOMM_NOT_YET: 'POPUP_RECOMM_NOT_YET',

  // ======================== 개발호재 ======================== //
  POSITIVES_TYPE_TS: 'POSITIVES_TYPE_TS',
  POSITIVES_TYPE_CONST: 'POSITIVES_TYPE_CONST',
  POSITIVES_TYPE_RECON: 'POSITIVES_TYPE_RECON',
  POSITIVES_TYPE_ETC: 'POSITIVES_TYPE_ETC',

  POSITIVES_TYPES_LIST: [
    {
      value: 'POSITIVES_TYPE_TS',
      txt: '기차/지하철',
    },
    {
      value: 'POSITIVES_TYPE_CONST',
      txt: '건설',
    },
    {
      value: 'POSITIVES_TYPE_RECON',
      txt: '재건축,재개발',
    },
    {
      value: 'POSITIVES_TYPE_ETC',
      txt: '기타',
    },
  ],

  // 랭킹 타입
  RANKING_TRADE_VOLUME: '1', //'RANKING_TRADE_VOLUME',
  RANKING_PRICE_INCREASE: '2', //'RANKING_PRICE_INCREASE',
  RANKING_PRICE_PER_PYEONG: '3', //'RANKING_PRICE_PER_PYEONG',
  RANKING_PRICE: '4', //'RANKING_PRICE',
  RANKING_JEONSAE_PRICE: '5', //'RANKING_JEONSAE_PRICE',
  // RANKING_OFFICIAL_PRICE: '6', //'RANKING_OFFICIAL_PRICE',
  RANKING_SCHOOL: '6', //'RANKING_SCHOOL',
  RANKING_RECENT: '7', //'RANKING_RECENT',
  RANKING_FLOOR_AREA: '8', //'RANKING_FLOOR_AREA',
  RANKING_AREA_PER_HOUSEHOLD: '9',
  RANKING_RECONSTRUCT_SCORE: '10', //'RANKING_PRICE_PER_PYEONG',

  CATEGORY_NEED_PERIOD_PREFIX: ['1', '2'], //0, 1 = 거래량 많은 단지 TOP5, 가격 많이 오른 단지 TOP5

  // ======================== 교육 ======================== //
  // 학원가 원이 보이기 시작하는 줌레벨 값
  SCHOOL_CRAM_ZOME_ZOOM_LIMIT: 7,
  SCHOOL_CRAM_ZONE_LIST: [
    // 1군
    {
      lat: '37.492964',
      lng: '127.059780',
      area_count: 761,
      area_size: 2.5,
    },
    // 2군
    {
      lat: '37.535935',
      lng: '126.879293',
      area_count: 134,
      area_size: 1.8,
    },
    {
      lat: '37.524702',
      lng: '126.873059',
      area_count: 50,
      area_size: 1.8,
    },
    {
      lat: '37.518633',
      lng: '126.872225',
      area_count: 50,
      area_size: 1.8,
    },
    {
      lat: '37.517463',
      lng: '126.865184',
      area_count: 66,
      area_size: 1.8,
    },
    // 3군
    {
      lat: '37.650650',
      lng: '127.076691',
      area_count: 215,
      area_size: 2,
    },
    {
      lat: '37.383167',
      lng: '126.960155',
      area_count: 281,
      area_size: 2,
    },
    {
      lat: '37.678138',
      lng: '126.766743',
      area_count: 207,
      area_size: 2,
    },
    {
      lat: '37.656058',
      lng: '126.789129',
      area_count: 149,
      area_size: 1.8,
    },
    {
      lat: '37.378186',
      lng: '126.789129',
      area_count: 82,
      area_size: 1.8,
    },
    // 4군
    {
      lat: '37.322507',
      lng: '127.095086',
      area_count: 110,
      area_size: 1.5,
    },
    {
      lat: '37.501739',
      lng: '126.789129',
      area_count: 128,
      area_size: 1.5,
    },
    {
      lat: '37.511039',
      lng: '127.124166',
      area_count: 117,
      area_size: 1.5,
    },
    {
      lat: '37.251767',
      lng: '127.074437',
      area_count: 48,
      area_size: 1,
    },
    {
      lat: '37.544892',
      lng: '126.944723',
      area_count: 64,
      area_size: 1,
    },
  ],
};

export default constants;
