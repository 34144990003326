import React from 'react';

const SelectItem = ({ id, name, value, onChange, text, selected, ...rest }) => {
  return (
    <div className="chk01">
      <input type="checkbox" id={id} name={name ? name : id} onChange={onChange} value={value} checked={selected} {...rest} />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

export default SelectItem;
