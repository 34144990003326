import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import ErrorBoundary from '../ErrorBoundary';

const SimpleReactLightbox = lazy(() => import('@dawinproperty/simple-react-lightbox'));
const Gallery = lazy(() => import('./Gallery'));

const GlobalGallery = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const galleryData = useSelector(selectors.common.getGlobalGallery);

  useEffect(() => {
    if (galleryData?.elements?.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [galleryData]);

  const closeGallery = () => {
    dispatch(actions.common.closeGlobalGallery());
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <SimpleReactLightbox>
          <Gallery show={show} close={closeGallery} index={galleryData?.options?.index} {...galleryData} />
        </SimpleReactLightbox>
      </Suspense>
    </ErrorBoundary>
  );
};

export default GlobalGallery;
