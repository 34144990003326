import { put, select } from 'redux-saga/effects';
import { actions, selectors } from '..';
import apis from '@/apis';
import { STATUS } from '../utils';

export function* getContractReview({ payload }) {
  const { origin, dataLength, newPage, setter } = payload || {};

  try {
    // -- 더보기 클릭시에는  ThreeDots, 전체 조회에는 skeleton 보여줌 -- //
    setter && setter(STATUS.LOADING);

    const savedRealtorsData = yield select(selectors.user.getRealtorsInfo);
    const { realtorsId: savedRealtorsId, realtorsInfo: savedRealtorsInfo } = savedRealtorsData || {};

    // -- 180일 전의 날짜의 타임스탬프 가져오기 --- //
    const currentDate = new Date();
    const previousDate = new Date();
    previousDate.setDate(currentDate.getDate() - 180);
    const timestampOf180DaysAgo = previousDate.getTime();

    // -- 4.5점 이상의 계약후기중 주관식 리뷰가 있는것만 조회 -- //
    const result = yield apis.reviewApi.getDawinReviews({
      targetUserId: undefined,
      'paging[offset]': newPage,
      'paging[limit]': dataLength,
      'createdAt[gte]': timestampOf180DaysAgo,
      'compositeValue[gte]': '4.5',
      'durationToContract[lt]': 60,
      reviewCause: 'CONTRACT',
      isBlinded: 'false',
    });
    const { data = [], meta } = result || {};
    const { total = 0 } = meta || {};

    // -- 중개사 정보 조회 -- //
    const realtorsId = data?.map((contractReview) => contractReview.targetUserId); // 리뷰 데이터에서 중개사 아이디 추출
    const nonDuplicatedRealtorsId = [...new Set(realtorsId)]; // 중복 중개사 아이디 제거
    const newRealtorsId = nonDuplicatedRealtorsId?.filter((realtorId) => !savedRealtorsId?.includes(realtorId)); // 이미 저장되어있는 중개사 정보가 있는지 확인후 새로운 중개사들만 추출

    // -- 새로 중개사 정보를 가져와야 하는 경우 api 요청, 그렇지 않은 경우 이미 저장되어 있는 중개사 정보 사용 -- //
    let realtorsInfo = [];
    if (newRealtorsId?.length > 0) {
      const res = yield apis.brokerApi.getBulkRealtorsInfo(newRealtorsId);
      const newData = [...savedRealtorsInfo, ...res];
      realtorsInfo = newData;
      yield put(actions.user.setRealtorsInfo({ realtorsId: newRealtorsId, realtorsInfo: newData }));
    } else {
      realtorsInfo = savedRealtorsInfo;
    }
    // -- 매물의 단지 조회 -- //
    const goodsCommonIdx = data?.map((item) => item.associatedIds.goodsCommonIdx)?.filter((item) => item); // 리뷰 데이터에서 매물 아이디 추출
    const complexInfo = yield apis.goodsApi.getBulkGoodsInfo(goodsCommonIdx); // 해당 매물의 단지 정보 조회

    // -- 데이터 최종 조합 -- //
    const finalData = data?.map((review) => {
      const matchingRealtor = realtorsInfo?.find((realtor) => review.targetUserId == realtor.broker_office_idx); // 중개사 정보가 조합되어 있는 리뷰 데이터와 매물 단지 정보를 조합
      const matchingComplex = complexInfo?.find((complex) => review.associatedIds.goodsCommonIdx == complex.goods_common_idx); // 리뷰 데이터와 중개사 정보를 조합, complex_name 안 들어오는 경우 goods_name 사용
      return { ...review, targetUserInfo: matchingRealtor, complexName: matchingComplex?.complex_name || matchingComplex?.goods_name };
    });

    // -- 계약 후기 reducer에 최종값 저장 -- //
    yield put(actions.review.setContractReviews({ origin, data: finalData, total }));

    setter && setter(STATUS.SUCCESS);
  } catch (e) {
    setter && setter(STATUS.FAILURE);
    console.error(e);
  }
}
