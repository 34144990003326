import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//요약본
export const getList = () => ({
  type: AT.GET_BK_QNA_LIST,
});
export const getDetail = (idx) => ({
  type: AT.GET_BK_QNA_DETAIL,
  idx,
});
export const setAttach = (idx, file_name) => ({
  type: AT.SET_BK_QNA_NEW_ATTACH,
  idx,
  file_name,
});
export const setTitle = (val) => ({
  type: AT.SET_BK_QNA_NEW_TITLE,
  val,
});
export const setCont = (val) => ({
  type: AT.SET_BK_QNA_NEW_CONT,
  val,
});
export const setKeyword = (keyword) => ({
  type: AT.SET_BK_QNA_KEYWORD,
  keyword,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_BK_QNA_CURRENT_PAGE,
  page,
});
export const setTotalCnt = (totalCnt) => ({
  type: AT.SET_BK_QNA_TOTAL_CNT,
  totalCnt,
});
export const saveReply = (val, pu_idx) => ({
  type: AT.SAVE_BK_QNA_REPLY,
  val,
  pu_idx,
});
export const delReply = (c_idx) => ({
  type: AT.DEL_BK_QNA_REPLY,
  c_idx,
});
export const editReply = (c_idx, val) => ({
  type: AT.EDIT_BK_QNA_REPLY,
  c_idx,
  val,
});
export const saveNew = () => ({
  type: AT.SAVE_BK_QNA_NEW,
});
export const editBoard = (board_idx, title, content, setViewEditWrite) => ({
  type: AT.EDIT_BK_QNA,
  board_idx,
  title,
  content,
  setViewEditWrite,
});
export const delBoard = (pu_idx, setViewDetail) => ({
  type: AT.DEL_BK_QNA,
  pu_idx,
  setViewDetail,
});

export const getbkQnAListLoading = () => ({
  type: AT.GET_BK_QNA_LIST_LOADING,
});
export const getbkQnAListSuccess = (conlist) => ({
  type: AT.GET_BK_QNA_LIST_SUCCESS,
  conlist,
});
export const getbkQnAListFailure = (error) => ({
  type: AT.GET_BK_QNA_LIST_FAILURE,
  error,
});

export const getbkQnADetailLoading = () => ({
  type: AT.GET_BK_QNA_DETAIL_LOADING,
});
export const getbkQnADetailSuccess = (detail) => ({
  type: AT.GET_BK_QNA_DETAIL_SUCCESS,
  detail,
});
export const getbkQnADetailFailure = (error) => ({
  type: AT.GET_BK_QNA_DETAIL_FAILURE,
  error,
});
