import * as AT from './actionTypes';
import CONSTANTS from '../../pages/RegQNA/constants';

// CONSTANTS 에서 꺼내서 목록 집어넣는건 테스트 용도임 서버에서 개발 끝나면 [] 로 변경 필
const INITIAL_STATE = {
  error: '',
  regQNACategoryIdx: 1,
  regQNATitle: CONSTANTS.REGQNA_TITLE_PLACEHOLDER,
  regQNABody: CONSTANTS.REGQNA_BODY_PLACEHOLDER,
};

export default function regQNARequest(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AT.REGQNA_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case AT.REGQNA_RESET_ERROR_STATE: {
      return {
        ...state,
        error: '',
      };
    }

    case AT.SET_REGQNA_CATEGORY_IDX: {
      return {
        ...state,
        regQNACategoryIdx: action.category_idx,
      };
    }
    case AT.SET_REGQNA_TITLE: {
      return {
        ...state,
        regQNATitle: action.title,
      };
    }
    case AT.SET_REGQNA_BODY: {
      return {
        ...state,
        regQNABody: action.body,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
