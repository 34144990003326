import * as AT from './actionTypes';

// [GET] 나의 실무교육 신청 내역
export const getEnrollments = ({ setShowRegisterPopup, setShowPaymentPopup, setShowChangeCancelPopup, setEnrollmentData }) => ({
  type: AT.GET_ENROLLMENTS,
  setShowRegisterPopup,
  setShowPaymentPopup,
  setShowChangeCancelPopup,
  setEnrollmentData,
});

// [POST] 실무교육 신청
export const applyAcademy = ({ data, setLoadingStatus, setEnrollmentData, callback }) => ({
  type: AT.APPLY_ACADEMY,
  data,
  setLoadingStatus,
  setEnrollmentData,
  callback,
});

// [POST] 실무교육 결제하기 STEP1
export const payAcademyFeeStepOne = ({ currentEnrollment, setLoadingStatus }) => ({
  type: AT.PAY_ACADEMY_FEE_STEP_ONE,
  currentEnrollment,
  setLoadingStatus,
});

// [POST] 실무교육 결제하기 STEP2
export const payAcademyFeeStepTwo = (billId, orderId, paymentKey, amount, redirectUrl) => ({
  type: AT.PAY_ACADEMY_FEE_STEP_TWO,
  billId,
  orderId,
  paymentKey,
  amount,
  redirectUrl,
});

// [PUT] 실무교육 일정 변경
export const editCourse = ({ currentEnrollment, selectedCourse, callback }) => ({
  type: AT.EDIT_COURSE,
  currentEnrollment,
  selectedCourse,
  callback,
});

// [POST] 실무교육 취소
export const cancelCourse = ({ currentCourse, callback }) => ({
  type: AT.CANCEL_COURSE,
  currentCourse,
  callback,
});

// [POST] 실무교육 신청 로깅
export const applyLog = () => ({
  type: AT.APPLY_ACADEMY_LOG,
});
