import useFeedId from '@/combinedComp/Community/DetailNew/hooks/useFeedId';
import LikeButton from '@/combinedComp/Community/sharedComponents/Like/components/LikeButton';
import { STATUS } from '@/data/utils';
import React from 'react';

const CommentLike = ({
  comment,
  deletedId,
  replyCount,
  setStatus,
  showReplies,
  setReply,
  reply,
  commentRef,
  active,
  setActive,
  hasIcon,
  isFeedDeleted,
}) => {
  const feedId = useFeedId();
  const reactionCount = comment?.reactionCount;
  const myReaction = comment?.myReaction;
  const id = comment?.id;
  const setDeletedStatus = deletedId?.indexOf(id) > -1;
  const status = comment?.status;

  const openReply = (e) => {
    if (!isFeedDeleted || (isFeedDeleted && replyCount !== 0)) {
      setStatus(STATUS.LOADING);
      showReplies();
      setReply(!reply);
      setStatus(STATUS.SUCCESS);
      setActive(!active);
    }
  };

  return (
    <div className="comment-data">
      <p className="data-list">
        <span className="data-item reply">
          <button type="button" className={`btn ${active ? 'active' : ''}`} onClick={openReply}>
            <span className="btn-text">
              답글 <span className="em">{replyCount}</span>
            </span>
          </button>
        </span>
        {reactionCount.map((item, index) => {
          return item.type == 'LIKE' ? (
            <LikeButton
              key={index}
              my={myReaction}
              likeCount={item.count}
              commentId={id}
              feedId={feedId}
              publishStatus={status}
              setDeletedStatus={setDeletedStatus}
              hasIcon={hasIcon}
              isFeedDeleted={isFeedDeleted}
            />
          ) : (
            <></>
          );
        })}
      </p>
    </div>
  );
};

export default CommentLike;
