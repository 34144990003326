import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@/data';
import { STEPS } from '../BasicForm/helpers';
import router from '@/services/router';

const PHONE_STEPS = [STEPS.SOCIAL2, STEPS.SOCIAL3]; // 소셜 > 휴대폰 인증하는 단계

/**
 * 소셜 로그인 중 휴대폰인증 완료하지 않고 나가도 로그인 완료 시키는 함수 생성
 * (휴대폰 인증 필수 아님)
 * @returns { allowLogin, allowOptionalPhoneConfirm }
 */
const useOptionalPhoneConfirm = () => {
  const dispatch = useDispatch();

  const isMobile = useSelector(selectors.common.isMobile);
  const step = useSelector(selectors.auth.getLoginStep);
  const loginData = useSelector(selectors.auth.getLogin);
  const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const isApp = osTypeInstance?.isApp;

  const allowLogin = useMemo(() => {
    const isPhoneConfirmStep = PHONE_STEPS.indexOf(step) > -1;
    const hasUserInfo = !!loginData.userInfo;

    return isPhoneConfirmStep && hasUserInfo && !isLoggedIn;
  }, [step, loginData, isLoggedIn]);

  // 소셜인증 과정 중 휴대폰 인증 단계 && 완료하지 않고 나가는 경우 -> 로그인 시킴
  // loginData에 userInfo가 있다는 것은 회원가입이 완료되었다는 의미. 즉 로그인 시킬 요건이 충족됨
  const onOptionalPhoneConfirmAction = useCallback(() => {
    if (isApp) {
      dispatch(router.push('/'));
    } else if (isMobile) {
      dispatch(router.goBack());
    } else if (location.pathname === '/login') {
      dispatch(router.goBack());
    }

    if (allowLogin) {
      dispatch(actions.auth.login(loginData));
    }
  }, [allowLogin, isLoggedIn]);

  return { allowLogin, onOptionalPhoneConfirmAction };
};

export default useOptionalPhoneConfirm;
