import { apiClient, apiClient2 } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import { createRoom, getRoomList, getUserConnection } from '@/data/chat/sagas';
import axios from 'axios';
import { actions } from '@/data';
import * as querystring from '@/services/router/querystring';

export const api = {
  async getRoomList() {
    try {
      return apiClient.get('/chat-rooms');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async sendPushMessage(userId, title, message, roomId) {
    try {
      return apiClient.post('push_token/pushSendMbrIdxs', {
        mbrIdxList: userId,
        title: title,
        message: message,
        url_link: `https://dawin.xyz/chat/${roomId}`,
      });
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 새로운 API

  async getRooms({ offsetTimestamp = undefined, limit = undefined }) {
    try {
      const query = [
        ['offsetTimestamp', offsetTimestamp],
        ['limit', limit],
      ]
        .filter(([k, v]) => v !== undefined)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');

      if (query && query !== '') {
        return await apiClient2.get(`/chat-api/v1/chat/rooms?${query}`);
      } else {
        return await apiClient2.get(`/chat-api/v1/chat/rooms`);
      }
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getRoom({ roomId }) {
    try {
      return await apiClient2.get(`/chat-api/v1/chat/rooms/${roomId}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getCursor({ roomId }) {
    try {
      return await apiClient2.get(`/chat-api/v1/chat/rooms/${roomId}/cursors`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updateCursor({ roomId, cursor }) {
    const updateCursorBody = {
      cursor: cursor,
    };
    try {
      await apiClient2.put(`/chat-api/v1/chat/rooms/${roomId}/cursors`, updateCursorBody);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getMessage({ roomId, limit, reverse = true, cursor = undefined }) {
    try {
      // console.log(querystring.stringify({ limit, reverse, cursor }));

      let getMessageRes;
      if (cursor) {
        getMessageRes = await apiClient2.get(`/chat-api/v1/chat/rooms/${roomId}/messages?limit=${limit}&reverse=${reverse}&cursor=${cursor}`);
      } else {
        getMessageRes = await apiClient2.get(`/chat-api/v1/chat/rooms/${roomId}/messages?limit=${limit}&reverse=${reverse}`);
      }

      return getMessageRes;
    } catch (e) {
      throw Error(e.message);
    }
  },

  async pushMessage({ messageType, roomId, content }) {
    // console.log('리소스메시지: ', content);
    const pushMeesageBody = {
      type: messageType,
      content: content,
    };
    try {
      await apiClient2.post(`/chat-api/v1/chat/rooms/${roomId}/messages`, pushMeesageBody);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async chatResource({ file }) {
    const imageBase64File = {
      file: file,
      ttl: 86400,
    };
    try {
      return await apiClient2.post(`/resource-api/v1/resources`, imageBase64File);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 현황판 -> 응답을 기다리는 손님
  async getNotReadChatRooms() {
    try {
      return await apiClient2.get('/chat-api/v1/chat/statistic/not-read-rooms');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async updateFavoriteRoom(roomId, payload) {
    try {
      return await apiClient2.post(`/chat-api/v1/chat/rooms/${roomId}/favorites`, payload);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getChatCount() {
    try {
      return await apiClient2.get(`/chat-api/v1/chat/rooms/count`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
