import React, { useEffect, useState } from 'react';
import ContentsPopup, { contentsPopup } from '@/publish/Front/_Component/popup/ContentsPopup';
import ApplyBtn from './ApplyBtn';
import LoadMoreListBtn from './LoadMoreListBtn';
import List from './List';
import CloseBtn from '../CloseBtn';

const InterestedAreas = ({
  title,
  dataList,
  applyBtnCallback,
  displayCount,
  setDisplayCount,
  setAddressData,
  data,
  setDualAddressData,
  tags,
  setTags,
  setSelectedGoodsTypes,
}) => {
  const [selectedRadioItem, setSelectedRadioItem] = useState();
  const showMoreBtn = dataList?.length > displayCount;

  const handleApply = (e) => {
    e.preventDefault();
    applyBtnCallback({ selectedRadioItem, tags, data, setDualAddressData, setTags, setSelectedGoodsTypes, setAddressData });
    contentsPopup.inactive('myAreaPopups');
    setSelectedRadioItem(undefined);
    setDisplayCount(3);
  };

  const handleClosePopup = (e) => {
    e.preventDefault();
    contentsPopup.inactive('myAreaPopups');
    setSelectedRadioItem(undefined);
    setDisplayCount(3);
  };

  useEffect(() => {
    contentsPopup.update('myAreaPopups');
  }, []);

  return (
    <ContentsPopup popupName="myAreaPopups">
      <form className="popup-local">
        <div className="popup-local-head">
          <h2 className="popup-local-subject">
            <span className="popup-local-name">{title}</span>
          </h2>
        </div>

        <div className="popup-local-body">
          <div className="popup-content">
            <div className="popup-content-body">
              <div className="goods-display module-a style-a type-a medium">
                {/* 리스트 */}
                <List dataList={dataList} displayCount={displayCount} setSelectedItem={setSelectedRadioItem} selectedItem={selectedRadioItem} />

                {/* 더 보기 버튼 */}
                {showMoreBtn && <LoadMoreListBtn displayCount={displayCount} setDisplayCount={setDisplayCount} />}
              </div>
            </div>
          </div>
        </div>

        {/* 적용 버튼 */}
        <ApplyBtn handleApply={handleApply} selectedRadioItem={selectedRadioItem} />

        {/* 닫기 버튼 - 퍼블에는 닫기 버튼이 없어서 임의로 프론트에서 생성한 버튼*/}
        <CloseBtn handleClosePopup={handleClosePopup} />
      </form>
    </ContentsPopup>
  );
};

export default InterestedAreas;
