import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

export const myPageRequestError = ({ error }) => ({
  type: AT.MP_REQUEST_ERROR,
  payload: {
    error,
  },
});

export const resetErrorState = () => ({
  type: AT.MP_RESET_ERROR_STATE,
});

// ============================ MyInfo ============================ //
// @params
// targetType _ 0 : 정, 1: 단지
export const toggleFavorite = (itemIdx, targetType, isCurrentlyFav) => ({
  type: AT.MP_TOGGLE_FAVORITE,
  itemIdx,
  targetType,
  isCurrentlyFav,
});

// 비밀번호변경
export const changePassword = (currentPwd, newPassword, newConfirmedPassword) => ({
  type: AT.MP_CHANGE_PASSWORD,
  currentPwd,
  newPassword,
  newConfirmedPassword,
});
export const changePasswordLoading = () => ({
  type: loading(AT.MP_CHANGE_PASSWORD),
});
export const changePasswordSuccess = () => ({
  type: success(AT.MP_CHANGE_PASSWORD),
});
export const changePasswordFailure = (error) => ({
  type: failure(AT.MP_CHANGE_PASSWORD),
  error,
});

//소셜리스트
export const getSocialList = () => ({
  type: AT.GET_SOCIAL_LIST,
});
export const getSocialListLoading = () => ({
  type: loading(AT.GET_SOCIAL_LIST),
});
export const getSocialListSuccess = (data) => ({
  type: success(AT.GET_SOCIAL_LIST),
  data,
});
export const getSocialListFailure = (error) => ({
  type: failure(AT.GET_SOCIAL_LIST),
  error,
});

// ============================ MyBuy ============================ //

// 찜한매물
export const getFavoriteItemsList = () => ({
  type: AT.GET_FAVORITE_ITEMS_LIST,
});
export const getFavoriteItemsListLoading = () => ({
  type: loading(AT.GET_FAVORITE_ITEMS_LIST),
});
export const getFavoriteItemsListSuccess = (list) => ({
  type: success(AT.GET_FAVORITE_ITEMS_LIST),
  list,
});
export const getFavoriteItemsListFailure = (error) => ({
  type: failure(AT.GET_FAVORITE_ITEMS_LIST),
  error,
});

// 관심단지
export const getInterestedComplexList = () => ({
  type: AT.GET_INTERESTED_COMPLEX_LIST,
});
export const getInterestedComplexListLoading = () => ({
  type: loading(AT.GET_INTERESTED_COMPLEX_LIST),
});
export const getInterestedComplexListSuccess = (list) => ({
  type: success(AT.GET_INTERESTED_COMPLEX_LIST),
  list,
});
export const getInterestedComplexListFailure = (error) => ({
  type: failure(AT.GET_INTERESTED_COMPLEX_LIST),
  error,
});

// 내계약매물
export const getContractedItmesList = () => ({
  type: AT.GET_CONTRACTED_ITEMS_LIST,
});
export const getContractedItmesListLoading = () => ({
  type: loading(AT.GET_CONTRACTED_ITEMS_LIST),
});
export const getContractedItmesListSuccess = (list) => ({
  type: success(AT.GET_CONTRACTED_ITEMS_LIST),
  list,
});
export const getContractedItmesListFailure = (error) => ({
  type: failure(AT.GET_CONTRACTED_ITEMS_LIST),
  error,
});

// ============================ MySell ============================ //

// 집내놓기
export const getMySellItemsList = (payload) => ({
  type: AT.GET_MY_SELL_ITEMS_LIST,
  payload,
});
export const getMySellItemsListLoading = () => ({
  type: loading(AT.GET_MY_SELL_ITEMS_LIST),
});
export const getMySellItemsListSuccess = (list) => ({
  type: success(AT.GET_MY_SELL_ITEMS_LIST),
  list,
});
export const getMySellItemsListFailure = (error) => ({
  type: failure(AT.GET_MY_SELL_ITEMS_LIST),
  error,
});

// === 정 삭제 관련 === //
export const setDeleteItemIdx = (idx) => ({
  type: AT.SET_DELETE_ITEM_IDX,
  idx,
});
export const setDeleteReason = (deleteReason) => ({
  type: AT.SET_DELETE_REASON,
  deleteReason,
});
export const setDeleteReasonETC = (reasonTxt) => ({
  type: AT.SET_DELETE_REASON_ETC,
  reasonTxt,
});
export const setDeleteStatus = (deleteStatus) => ({
  type: AT.SET_DELETE_STATUS,
  deleteStatus,
});

export const postDeleteItem = () => ({
  type: AT.POST_DELETE_ITEM,
});
export const postDeleteItemLoading = () => ({
  type: loading(AT.POST_DELETE_ITEM),
});
export const postDeleteItemSuccess = (data) => ({
  type: success(AT.POST_DELETE_ITEM),
  data,
});
export const postDeleteItemFailure = (error) => ({
  type: failure(AT.POST_DELETE_ITEM),
  error,
});

export const setRegCancelItemIdx = (regCancelItemIdx) => ({
  type: AT.SET_REG_CANCEL_ITEM_IDX,
  regCancelItemIdx,
});
export const postRegCancelItem = () => ({
  type: AT.POST_REG_CANCEL_ITEM,
});
export const postRegCancelItemLoading = () => ({
  type: loading(AT.POST_REG_CANCEL_ITEM),
});
export const postRegCancelItemSuccess = (data) => ({
  type: success(AT.POST_REG_CANCEL_ITEM),
  data,
});
export const postRegCancelItemFailure = (error) => ({
  type: failure(AT.POST_REG_CANCEL_ITEM),
  error,
});
export const postRegCancelItemReset = () => ({
  type: AT.POST_REG_CANCEL_ITEM_RESET,
});

export const setReRegItemIdx = (reRegItemIdx) => ({
  type: AT.SET_REREG_ITEM_IDX,
  reRegItemIdx,
});

// @todo <프론트_요청사항> idx:16
export const postReRegItem = () => ({
  type: AT.POST_REREG_ITEM,
});
export const postReRegItemLoading = () => ({
  type: loading(AT.POST_REREG_ITEM),
});
export const postReRegItemSuccess = () => ({
  type: success(AT.POST_REREG_ITEM),
});
export const postReRegItemFailure = (error) => ({
  type: failure(AT.POST_REREG_ITEM),
  error,
});
export const postReRegItemNonAsk = () => ({
  type: AT.POST_REREG_ITEM_NON,
});

// === 거래완료신고 관련 === //

// 현재 거래완료 처리 중인 매물의 index 를 set 하는 것
export const setTradeItemIdx = (idx) => ({
  type: AT.SET_TRADE_ITEM_IDX,
  idx,
});

// ITEM_REPORT_TRADE_COMPLETE_DAWIN_AGENT or ITEM_REPORT_TRADE_COMPLETE_NON_DAWIN_AGENT 중에 정하는 것
export const setTradeAgentType = (agentType) => ({
  type: AT.SET_TRADE_AGENT_TYPE,
  agentType,
});

export const setTradeAgentIdx = (agentIdx) => ({
  type: AT.SET_TRADE_AGENT_IDX,
  agentIdx,
});

// 거래완료신고
export const postReportItemTradeComplete = () => ({
  type: AT.POST_REPORT_ITEM_TRADE_COMPLETE,
});
export const postReportItemTradeCompleteLoading = () => ({
  type: loading(AT.POST_REPORT_ITEM_TRADE_COMPLETE),
});
export const postReportItemTradeCompleteSuccess = (data) => ({
  type: success(AT.POST_REPORT_ITEM_TRADE_COMPLETE),
  data,
});
export const postReportItemTradeCompleteFailure = (error) => ({
  type: failure(AT.POST_REPORT_ITEM_TRADE_COMPLETE),
  error,
});

// ============================ Rating ============================ //
// export const setRatingType = (ratingType) => ({
//   type: AT.SET_RATING_TYPE,
//   ratingType
// });

// ============================ 2.3 MySell ============================ //
export const getMySellCount = (statusArr) => ({
  type: AT.GET_MY_SELL_COUNT,
  statusArr,
});
export const getMySellCountLoading = () => ({
  type: loading(AT.GET_MY_SELL_COUNT),
});
export const getMySellCountSuccess = (data) => ({
  type: success(AT.GET_MY_SELL_COUNT),
  data,
});
export const getMySellCountFailure = (error) => ({
  type: failure(AT.GET_MY_SELL_COUNT),
  error,
});
export const getMySellList = ({ limit, offset, status, setter, setHasMore }) => ({
  type: AT.GET_MY_SELL_LIST,
  limit,
  offset,
  status,
  setter,
  setHasMore,
});
export const getMySellList2 = ({ limit, offset, goodsStatus, setStatus }) => ({
  type: AT.GET_MY_SELL_LIST2,
  limit,
  offset,
  goodsStatus,
  setStatus,
});
export const resetMySellList = () => ({
  type: AT.RESET_MY_SELL_LIST,
});
export const getMySellListLoading = () => ({
  type: loading(AT.GET_MY_SELL_LIST),
});
export const getMySellListSuccess = (data) => ({
  type: success(AT.GET_MY_SELL_LIST),
  data,
});
export const getMySellListFailure = (error) => ({
  type: failure(AT.GET_MY_SELL_LIST),
  error,
});
