import React from 'react';
import { SERVICE_AREA } from '@/resources/text/common';

export const commonText = {
  SELL_LIMIT_AREA: SERVICE_AREA,
  APARTMENT: '아파트',
  OFFICETEL: '오피스텔',
  VILLA: '주택/빌라',
  STUDIO: '원룸/투룸',
  SELL_REQUEST_CALL_ERROR: '전화로 매물 등록 중 오류가 발생하였습니다.',
  SELL_REQUEST_MANUAL_ERROR: '직접 매물 등록 중 오류가 발생하였습니다.',
  SELL_REQUEST_CHECK_TEMP_ERROR: '임시저장 여부 확인 중 오류가 발생하였습니다.',
  SELL_REQUEST_GET_PAGE_DATA_ERROR: '직접 매물 등록 데이터 요청 중 오류가 발생하였습니다.',
  SELL_REQUEST_TEMP_ERROR: '임시 저장 중 오류가 발생하였습니다.',
  SELL_REQUEST_DULPLICATED_ERROR: (
    <>
      현재 동일한 매물이 다윈중개에 등록되어 있어 등록이 불가능합니다. <br />
      거래금액을 변경하고자 하시는 분은 마이페이지>내집내놓기>매물정보수정에서 가능합니다.
      <br />
      혹시 집주인이시고 다윈중개에 의뢰하신 적이 없다면 고객센터로 연락 부탁드립니다. 1544-6075
    </>
  ),
  SELL_REQUEST_PHONE_TEXT: '전화로 집내놓기',
  SELL_REQUEST_MANUAL_TEXT: '직접 집내놓기',
  SELL_ACTION_ERROR: '사이트 동작 오류',
  OWNER: '본인(집주인)',
  TENANT: '세입자',

  SALES: '매매',
  JEONSAE: '전세',
  LEASE: '월세',

  SELL_RQUEST_COMPLETE: '매물 등록이 완료되었습니다.',
  GET_ADDR_ERROR: '지역 목록을 불러오는 중 오류가 발생하였습니다.',
  GET_COMPLEX_LIST_ERROR: '매물 목록을 불러오는 중 오류가 발생하였습니다.',
  GET_COMPLEX_INFO_ERROR: '매물 정보를 불러오는 중 오류가 발생하였습니다.',
  GET_COMPLEX_LOCATION_ERROR: '매물 위치를 불러오는 중 오류가 발생하였습니다.',
  GET_COMPLEX_DETAIL_ERROR: '매물 상세 정보를 불러오는 중 오류가 발생하였습니다.',
  GET_NEARBY_AGENTS_ERROR: '중개사 목록을 불러오는 중 오류가 발생하였습니다.',

  MAP_ERROR: '맵 생성 중 오류가 발생하였습니다.',
};
