import { combinedLogger } from '@/services/Logger/combinedLogger';

export const paramsLogger = ({ location, e, payload }) => {
  const logType = 'REQUEST_WITH_PARAMS';
  const generalLocation = location.pathname + location.search;
  const specificLocation = e.target.getAttribute('data-log-id');
  const action = 'CLICK';

  combinedLogger({
    logType,
    from: [generalLocation, specificLocation].filter((e) => e),
    action,
    params: payload,
  });
};
