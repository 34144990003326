import React, { useMemo, useState } from 'react';
import NoticeA from '../../components/PostForBuy/Notice/TypeA';
import Contents from '../components/Contents/index.js';
import { FeedType, TagType } from '@/services/Community';
import Images from '../components/Images';
import HashTagFixed from '../components/HashTagFixed';
import HashTagInput from '../components/HashTagInput';
import AnswerType from '../components/AnswerType';
import GoodsType from '../components/GoodsType';
import TradeType from '../components/TradeType';
import Address from '@/combinedComp/Community/Create/Post/PostForBuy/components/Address';
import { useSetAddress } from './hooks';

const OneStepPostForBuy = ({
  feedType,
  addedTagsRef,
  contents,
  setContents,
  tags,
  setTags,
  numericTags,
  imagesRef,
  dualAddressData,
  setDualAddressData,
  isUpdate,
  updateFeedDetail,
  openPhoneChangePop,
  setOpenPhoneChangePop,
  current,
  setCurrent,
  // setFeedType,
  // setNumericTags,
  // safetyPop,
  // setSafetyPop,
  // headerHashtags,
}) => {
  const [selectedGoodsTypes, setSelectedGoodsTypes] = useState([]);

  const needToMarkAddressKor = useMemo(() => feedType === FeedType.COMPLEX_POST || feedType === FeedType.LOCAL_POST, [feedType]);

  const addressTagsKor = useMemo(() => {
    const filteredTags = tags.filter((tag) => tag.type === TagType.ADDRESS_Dong_TAG || tag.type === TagType.COMPLEX_TAG).map((item) => item.name);
    return filteredTags.join(' ');
  }, [tags]);

  const handleContents = (e) => {
    setContents(e.target.value ? e.target.value : '');
  };

  useSetAddress({ dualAddressData, tags, setTags });

  // 평형 Range, 가격 Range
  // const { areaRange, priceRange, handleAreaRange, handlePriceRange } = useRange({ numericTags, setNumericTags });

  return (
    <>
      <NoticeA />
      <div className="form-list">
        <GoodsType
          tags={tags}
          setTags={setTags}
          isUpdate={isUpdate}
          data={dualAddressData}
          setData={setDualAddressData}
          selectedGoodsTypes={selectedGoodsTypes}
          setSelectedGoodsTypes={setSelectedGoodsTypes}
        />

        <Address
          dualAddressData={dualAddressData}
          setDualAddressData={setDualAddressData}
          title="지역선택"
          addAllOption={true}
          tags={tags}
          setTags={setTags}
          feedType={feedType}
          setSelectedGoodsTypes={setSelectedGoodsTypes}
        />

        <TradeType tags={tags} setTags={setTags} />

        <Contents
          needToMarkAddressKor={needToMarkAddressKor}
          addressTagsKor={addressTagsKor}
          contents={contents}
          handleContents={handleContents}
          feedType={feedType}
        />

        <Images isUpdate={isUpdate} imagesRef={imagesRef} updateFeedDetail={updateFeedDetail} feedType={feedType} />

        <AnswerType
          tags={tags}
          setTags={setTags}
          openPhoneChangePop={openPhoneChangePop}
          setOpenPhoneChangePop={setOpenPhoneChangePop}
          current={current}
          setCurrent={setCurrent}
        />

        {/* TODO: @커뮤니티테스트 */}
        {/* <HashTagFixed tags={tags} feedType={feedType} numericTags={numericTags} /> */}

        <HashTagInput addedTags={addedTagsRef} feedType={feedType} isUpdate={isUpdate} updateFeedDetail={updateFeedDetail} />

        {/* 매물유형확대 개편때 주석처리됨 */}
        {/* <Area
          title="평형"
          marks={houseAreaMarks}
          range={areaRange}
          rangeString="0, 10평, 20평, 30평, 40평, 50평 이상"
          min={0}
          max={50}
          step={10}
          handleChange={handleAreaRange}
        />
        <Price
          title="가용금액"
          marks={housePriceMarks}
          range={priceRange}
          rangeString="0, 5억, 10억, 15억, 20억, 25억 이상"
          min={0}
          max={25}
          step={5}
          handleChange={handlePriceRange}
        /> */}
      </div>
    </>
  );
};

export default OneStepPostForBuy;

// const houseAreaMarks = [
//   { value: 0, label: '0' },
//   { value: 10, label: '10평' },
//   { value: 20, label: '20평' },
//   { value: 30, label: '30평' },
//   { value: 40, label: '40평' },
//   { value: 50, label: '50평 이상' },
// ];

// const housePriceMarks = [
//   { value: 0, label: '0' },
//   { value: 5, label: '5억' },
//   { value: 10, label: '10억' },
//   { value: 15, label: '15억' },
//   { value: 20, label: '20억' },
//   { value: 25, label: '25억 이상' },
// ];
