import * as AT from './actionTypes';
// export const getERROR = (state) => state?.agentSignUp?.error;
export const getLoginInfo = (state) => state?.agentSignUp?.loginInfo;

export const getIsFeeAgreed = (state) => state?.agentSignUp?.isFeeAgreed;
export const getIsAllAgreed = (state) => state?.agentSignUp?.isAllAgreed;
export const getIsUsageTermAgreed = (state) => state?.agentSignUp?.isUsageTermAgreed;
export const getIsAgentServiceAgreed = (state) => state?.agentSignUp?.isAgentServiceAgreed;
export const getIsInfoAgreed = (state) => state?.agentSignUp?.isInfoAgreed;
export const getDwcd = (state) => state?.agentSignUp?.dwcd;
export const getAcademyDwcd = (state) => state?.agentSignUp?.academy_dwcd;

export const getOfficeList = (state) => state?.agentSignUp?.officeList;
export const getAddressList = (state) => state?.agentSignUp?.addressList;
export const getCheckBroker = (state) => state?.agentSignUp?.exsist_broker;
export const getBrokerOfficeList = (state) => state?.agentSignUp?.brokerOfficeList;
export const getBrokerOfficeIdx = (state) => state?.agentSignUp?.recommend_idx;

export const getOfficeType = (state) => state?.agentSignUp?.officeType;

export const getSelectedOfficeIdx = (state) => state?.agentSignUp?.selectedOfficeIdx;
export const getSelectedOfficeInfo = (state) => state?.agentSignUp?.selectedOfficeInfo;
export const getMapLevel = (state) => state?.agentSignUp?.maplevel;

export const getOfficePhoneNum = (state) => state?.agentSignUp?.officePhoneNum;

export const getBrokerId = (state) => state?.agentSignUp?.brokerId;
export const getBrokerPassword = (state) => state?.agentSignUp?.brokerPassword;
export const getBrokerCareerText = (state) => state?.agentSignUp?.brokerCareerText;

export const getOpenCertImage = (state) => state?.uploadImage?.openCertImage;
export const getCorpCertImage = (state) => state?.uploadImage?.corpCertImage;
export const getProfileImage = (state) => state?.uploadImage?.profileImage;

//중개사 아카데미
export const getAcademyInfo = (state) => state?.agentSignUp?.academy;
export const getAcademyDong = (state) => state?.agentSignUp?.academy?.dong;
export const getAcademyName = (state) => state?.agentSignUp?.academy?.name;
export const getAcademyNum = (state) => state?.agentSignUp?.academy?.num;
export const getAcademyYear = (state) => state?.agentSignUp?.academy?.year;
export const getAcademyEduIdx = (state) => state?.agentSignUp?.academy?.date;
//export const getSelectedEduIdx = (state) => state?.agentSignUp?.academy?.tb_ab_education_idx;
export const getSelectedEduIdx = (state) => state?.agentSignUp?.academy?.selectedDate.id;
export const getSelectedDate = (state) => state?.agentSignUp?.academy?.selectedDate;
export const getEducationText = (state) => state?.agentSignUp?.academy?.education_text;
export const getUserInfo = (state) => state?.agentSignUp?.academy?.userInfo;
export const getApplySuccess = (state) => state?.agentSignUp?.academy?.success;
export const getAcademyMemo = (state) => state?.agentSignUp?.academy?.memo;
export const getPreBillData = (state) => state?.agentSignUp?.academy?.prebilldata;

export const alreadyAcademySuccess = (state) => state?.agentSignUp[AT.ALREADY_ACADEMY]?.isSuccess();
export const editAcademySuccess = (state) => state?.agentSignUp[AT.EDIT_ACADEMY]?.isSuccess();
export const cancelAcademySuccess = (state) => state?.agentSignUp[AT.CANCEL_ACADEMY]?.isSuccess();

export const getUserApplyAcademyInfo = (state) => state?.agentSignUp?.academy?.userApplyAcademyInfo;
export const getChceckAcademyResult = (state) => state?.agentSignUp?.academy?.checkResult;

//합동사무소 신청을 위한 param
export const getShareNewDong = (state) => state?.agentSignUp?.new?.dong;
export const getShareNewName = (state) => state?.agentSignUp?.new?.name;
export const getShareNewNum = (state) => state?.agentSignUp?.new?.num;
export const getShareNewYear = (state) => state?.agentSignUp?.new?.year;

//합동사무소 tour 신청을 위한 param
export const getTourNewName = (state) => state?.agentSignUp?.tourNew?.name;
export const getTourNewNum = (state) => state?.agentSignUp?.tourNew?.num;
export const getTourNewTourDate = (state) => state?.agentSignUp?.tourNew?.tourdate;
export const getTourOffice = (state) => state?.agentSignUp?.tourNew?.office;
export const getTourOfficeName = (state) => state?.agentSignUp?.tourNew?.office_name;
export const getTourOfficeName2 = (state) => state?.agentSignUp?.office_name;

export const getSiDoCode = (state) => state?.selling?.si_do_code;
export const getSiGunGuCode = (state) => state?.selling?.si_gun_gu_code;
export const getDongRiCode = (state) => state?.selling?.dong_ri_code;

export const getSavedSiDoCode = (state) => state?.agentSignUp?.sidoCode;
export const getSavedSiGunGuCode = (state) => state?.agentSignUp?.sigugunCode;
export const getSavedDongRiCode = (state) => state?.agentSignUp?.dongCode;

export const getCheckId = (state) => state?.agentSignUp?.checkIdState;
export const getCheckIdSuccess = (state) => state?.agentSignUp[AT.CHECK_ID_DUP].isSuccess();
export const getCheckIdFailure = (state) => state?.agentSignUp[AT.CHECK_ID_DUP].isFailure();

export const applyShareOfficeLoading = (state) => state?.agentSignUp[AT.APPLY_SHARE_OFFICE].isLoading();
export const applyShareOfficeSuccess = (state) => state?.agentSignUp[AT.APPLY_SHARE_OFFICE].isSuccess();
export const applyShareOfficeFailure = (state) => state?.agentSignUp[AT.APPLY_SHARE_OFFICE].isFailure();
export const getresult = (state) => state?.agentSignUp[AT.APPLY_SHARE_OFFICE]?.getOrElse([]) ?? [];

export const getTourOfficeList = (state) => state?.agentSignUp.tourOfficeList;
export const getChkOffice = (state) => state?.agentSignUp.tourNew?.office;
export const getChkTourdate = (state) => state?.agentSignUp.tourNew?.tourdate;
export const applyOfficeTourLoading = (state) => state?.agentSignUp[AT.APPLY_TOUR_OFFICE].isLoading();
export const applyOfficeTourSuccess = (state) => state?.agentSignUp[AT.APPLY_TOUR_OFFICE].isSuccess();
export const applyOfficeTourFailure = (state) => state?.agentSignUp[AT.APPLY_TOUR_OFFICE].isFailure();
export const tourdate = (state) => state?.agentSignUp[AT.APPLY_TOUR_OFFICE]?.getOrElse([]) ?? [];

//시도 시군구 읍면동
export const getRecommendSiDoList = (state) => state?.agentSignUp?.si_do_list;
export const getRecommendSiGunGuList = (state) => state?.agentSignUp?.si_gun_gu_list;
export const getRecommendDongRiList = (state) => state?.agentSignUp?.dong_ri_list;
export const getRecommendSiDoCode = (state) => state?.agentSignUp?.si_do_code;
export const getRecommendSiGunGuCode = (state) => state?.agentSignUp?.si_gun_gu_code;
export const getRecommendDongRiCode = (state) => state?.agentSignUp?.dong_ri_code;
export const getRecommendSiDoName = (state) => state?.agentSignUp?.si_do_name;
export const getRecommendSiGunGuName = (state) => state?.agentSignUp?.si_gun_gu_name;
export const getRecommendDongRiName = (state) => state?.agentSignUp?.dong_ri_name;

export const getAreaList = (state) => state?.agentSignUp?.areaList;
export const getSetServiceArea = (state) => state?.agentSignUp?.service_area;
export const getSetServiceDongList = (state) => state?.agentSignUp?.service_dong_list;
