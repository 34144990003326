import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actionTypes as AT, actions } from '@/data';
import { createLoadingSelector } from '@/data/utils';
import useOptionalPhoneConfirm from './hooks/useOptionalPhoneConfirm';
import LoginApp from './views/LoginApp';
import LoginWeb from './views/LoginWeb';
import { STEPS } from './BasicForm/helpers';

const selectIsSubmitting = createLoadingSelector([AT.LOG_IN]);

const Login = () => {
  const osTypeInstance = useSelector(selectors.app.getOsTypeInstance);
  const isApp = osTypeInstance?.isApp;

  const isMobile = useSelector(selectors.common.isMobile);
  const isSubmitting = useSelector(selectIsSubmitting);
  const isPhoneNumberAuthOnly = useSelector(selectors.auth.isPhoneNumberAuthOnly);

  const { allowLogin: allowLoginBeforePhoneConfirm, onOptionalPhoneConfirmAction } = useOptionalPhoneConfirm();

  const commonProps = { isApp, isMobile, isSubmitting, isPhoneNumberAuthOnly, allowLoginBeforePhoneConfirm, onOptionalPhoneConfirmAction };

  const showLoginPopup = useSelector(selectors.common.showLoginPopup);

  const dispatch = useDispatch();

  const pathName = location.pathname;

  useEffect(() => {
    if (pathName === '/login') {
      dispatch(actions.common.toggleLoginPopup(true));
    }
  }, [pathName]);

  useEffect(() => {
    return () => {
      dispatch(actions.auth.setLoginStep(STEPS.FIRST));
      dispatch(actions.auth.setUserInfoForLogin({}));
      dispatch(actions.common.actionAfterLogin(null));
      dispatch(actions.auth.phoneNumberAuthOnly(false));
    };
  }, []);

  if (isApp) {
    return <LoginApp {...commonProps} />;
  } else {
    return <LoginWeb {...commonProps} show={isMobile || showLoginPopup} />;
  }
};

export default Login;
