import React from 'react';

const Wrapper = ({
  legend="매물 검색 서식", 
  wrapperClass = "submit-form data-finder-search",
  formName="검색어",
  children
}) => {
  return (
    <>
      <fieldset className={wrapperClass}>
        <legend>{legend}</legend>
        <div className="form-list">
          <div className="form-item">
            <div className="form-wrap">
              {/* <div className="form-head">
                <label className="form-name" htmlFor="searchKeyword">
                  {formName}
                </label>
              </div> */}
              {children}
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};


export default Wrapper;
