import { text as sellingText } from './selling';
import { text as uploadImageText } from './uploadImage';
import { text as myPageText } from './myPage';
import { text as ratingText } from './rating';
import { text as regQNAText } from './regQNA';
import { text as agentSignUpText } from './agentSignUp';
import { text as analysisText } from './analysis';

const text = {
  sellingText,
  uploadImageText,
  myPageText,
  ratingText,
  regQNAText,
  agentSignUpText,
  analysisText,
};
export default text;
