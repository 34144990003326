import { TagType, getRealtyName } from '@/services/Community';
import { useEffect } from 'react';

const useSetAddress = ({ dualAddressData, tags, setTags }) => {
  useEffect(() => {
    const address = dualAddressData?.selectedAddress; // 시군구, 동
    const complex = dualAddressData?.selectedComplex; // 단지

    let filteredTags = tags?.filter((tag) => tag.type.indexOf('ADDRESS') !== 0 && tag.type !== TagType.COMPLEX_TAG);

    const sigungu = address?.sigungu;
    const dong = address?.dong;
    const newTags = [];

    if (dong?.code && dong?.code !== 'all') {
      newTags.push({ type: TagType.ADDRESS_TAG, key: dong?.code, name: dong?.name });
    } else if (sigungu?.code) newTags.push({ type: TagType.ADDRESS_TAG, key: sigungu?.code, name: sigungu?.name });

    if (complex?.complex_idx && complex?.complex_idx !== 'all') {
      filteredTags = filteredTags?.filter((tag) => tag.type !== TagType.GOODS_TYPE_TAG);
      newTags.push({ type: TagType.COMPLEX_TAG, key: `${complex.complex_idx}`, name: complex.complex_name });
      newTags.push({ type: TagType.GOODS_TYPE_TAG, key: getRealtyName(complex.complex_type), name: getRealtyName(complex.complex_type) });
    }

    setTags([...filteredTags, ...newTags]);
  }, [dualAddressData]);

  return;
};

export default useSetAddress;
