import { useEffect } from 'react';

const useWindowSize = (dispatch, actions) => {
  const handleWindowSizeChange = () => {
    dispatch(actions.common.isMobile(window.innerWidth));
  };

  useEffect(() => {
    // 윈도우 사이즈
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
};

export default useWindowSize;
