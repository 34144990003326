const constants = {
  MY_PAGE: 'MY_PAGE',

  MY_INFO: 'MY_INFO',
  MY_BUY: 'MY_BUY',
  MY_SELL: 'MY_SELL',
  MY_QNA: 'MY_QNA',
  MY_MODIFY_ITEM: 'MY_MODIFY_ITEM',
  MY_DELETE_ITEM: 'MY_DELETE_ITEM',
  MY_RATING: 'MY_RATING',

  // 매물 상황 코드
  REG_REQUESTED: '승인요청',
  REG_COMPLETED: '승인완료',
  REG_SUSPENDED: '승인보류',
  REG_DENIED: '승인거절',

  BEFORE_REG: '승인전',
  NORMAL_STATE: '정상',
  TRADE_COMPLETED: '거래완료',
  REG_CANCELED: '등록취소',
  DELETE_REQUESTED: '삭제요청',
  DELETE_COMPLETED: '삭제완료',

  // =================== MyInfo =================== //
  PASSWORD_CURRENT_NOT_GIVEN: 'PASSWORD_CURRENT_NOT_GIVEN',
  PASSWORD_CURRENT_INCORRECT: 'PASSWORD_CURRENT_INCORRECT',

  PASSWORD_NEW_NOT_GIVEN: 'PASSWORD_NEW_NOT_GIVEN',
  PASSWORD_NEW_NOT_MATCH: 'PASSWORD_NEW_NOT_MATCH',

  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  PASSWORD_CHANGE_LOADING: 'PASSWORD_CHANGE_LOADING',
  PASSWORD_CHANGE_DONE: 'PASSWORD_CHANGE_DONE',

  PHONE_CHANGE: 'PHONE_CHANGE',
  PHONE_CHANGE_LOADING: 'PHONE_CHANGE_LOADING',
  PHONE_CHANGE_DONE: 'PHONE_CHANGE_DONE',

  USER_WITHDRAWAL: 'USER_WITHDRAWAL',
  USER_WITHDRAWAL_LOADING: 'USER_WITHDRAWAL_LOADING',
  USER_WITHDRAWAL_DONE: 'USER_WITHDRAWAL_DONE',

  // =================== MyBuy =================== //

  MY_BUY_FAVORITE: 'MY_BUY_FAVORITE', // 찜한 매물
  MY_BUY_INTERESTED: 'MY_BUY_INTERESTED', // 관심 단지
  MY_BUY_CONTRACTED: 'MY_BUY_CONTRACTED', // 계약매물
  MY_BUY_RECHIS: 'MY_BUY_RECHIS', // 추천히스토리

  RATING_COMPLETED: 'RATING_COMPLETED',
  RATING_BUYER: 'RATING_BUYER',

  // =================== MyBuy_Dummy data =================== //
  FAVORITE_ITEMS_LIST: [
    {
      goods_idx: 111111,
      trade_completed: false,
      is_favorite: true,
      traded_date: '',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '정자역엠코헤리츠(찜test1)',
      ho_name: '401호',
      // "trade_type" : '전세',
      complex_price: '2억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '원룸(오픈형)',
      floor_no: '3층',
      total_floor: '12층',
      // 위는 더미, 아래는 진짜 response 양식

      // "goods_idx": 11111,
      goods_no: 11111,
      category: 'APT',
      // "trade_type": '',
      tb_cb_complex_idx: 111,
      complex_idx: 1821,
      reg_mbr_idx: 123,
      reg_mbr_name: '다윈',
      reg_cp: '010-1111-2222',
      reg_type: '1',
      owner_cp: '',
      addr_type: '2',
      zip_code: null,
      law_addr1: '경기도 성남시 분당구 분당동',
      law_addr2: '34',
      law_addr3: null,
      law_sido_code: '41',
      law_sido: null,
      law_sigungu_code: '41135',
      law_sigungu: null,
      law_dong_code: '4113510100',
      law_dong: '분당동',
      law_ri_code: null,
      law_ri: null,
      zip_code_ro: null,
      road_addr: null,
      road_addr1: '경기도 성남시 분당구 분당로 190',
      road_addr2: null,
      lat: '37.37353600000000',
      lng: '127.12993900000000',
      map_xy: null,
      price1: '30000',
      price2: '0',
      price3: '0',
      price_modify_count: 0,
      susuryo_agree: '',
      // "area1": "104.17",
      // "area2": "85.00",
      space_idx: 3,
      dong: '106',
      floor: 5,
      ho: '505',
      enter_type: null,
      enter_date: '',
      room_cnt: 2,
      bathroom_cnt: 2,
      owner_live: null,
      direction: null,
      balcony: null,
      animal: null,
      options: '',
      goods_feature: '',
      goods_feature_etc: '',
      // "total_floor": 20,
      floor_kind: '중',
      front_door_type: '계단식',
      room_type: '',
      expense: null,
      expense_item: '',
      parking_flag: null,
      heat_type: null,
      elevator_flag: null,
      owner_comment: '',
      agency_open_flag: 'y',
      goods_status: 'DR',
      goods_status_chg_date: '',
      trade_date: null,
      goods_process_status: 'PS2',
      goods_process_status_chg_date: null,
      default_img_path: null,
      default_img: null,
      map_display_end_date: '2999-12-31T00:00:00.000Z',
      deleted_date: '',
      reg_date: '2019-12-09T13:56:38.000Z',
      update_date: '2020-06-17T10:31:54.000Z',
      broker_office_idx: 86,
      isfav: 'n',
      iseval: 'n',
      // "complex_name": "샛별라이프",
      trade_date_format: null,
      imgsrc: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/1633/1633_01.jpg',
      real_estate_type: 'APT',
    },
    {
      goods_idx: 111112,
      trade_completed: true,
      is_favorite: true,
      traded_date: '2017.11.23',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '판교원10단지휴먼시아힐스테이트(test)',
      ho_name: '102호',
      trade_type: '매매',
      complex_price: '7억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '아파트',
      floor_no: '13층',
      total_floor: '28층',
    },
    {
      goods_idx: 111113,
      trade_completed: true,
      is_favorite: true,
      traded_date: '2017.11.24',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '판교원10단지휴먼시아힐스테이트(test)',
      ho_name: '803호',
      trade_type: '매매',
      complex_price: '8억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '아파트',
      floor_no: '14층',
      total_floor: '28층',
    },
  ],

  INTERESTED_COMPLEX_LIST: [
    {
      goods_idx: 111111,
      trade_completed: false,
      is_favorite: false,
      is_onsale: false,
      traded_date: '',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '정자역엠코헤리츠(관심1)',
      ho_name: '401호',
      trade_type: '전세',
      complex_price: '2억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '원룸(오픈형)',
      floor_no: '3층',
      total_floor: '12층',
      // 위 dummy, 아래 response field
      tb_cb_complex_idx: 44967,
      area_no: 0,
      regdate: '2020-01-19T14:02:26.000Z',
      area_name: null,
      supply_area: null,
      pyeong_name: null,
      supply_pyeong: null,
      map_title: '이매한신',
      map_type: 'APT',
      lat: '37.388439000000000',
      lng: '127.12609600000000',
      dong_cnt: 12,
      house_cnt: 1184,
      low_floor: 12,
      high_floor: 25,
      real_estate_type: 'APT',
      imgsrc: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/2578/2578_01.jpg',
      completion_year: '1993',
      completion_month: '11',
      min_area: 67,
      max_area: 104,
      min_area_p: 20,
      max_area_p: 31,
      pyeong_sell_price: 2824,
    },
    {
      goods_idx: 111112,
      trade_completed: true,
      is_favorite: true,
      is_onsale: true,
      traded_date: '2017.11.23',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '판교원10단지휴먼시아힐스테이트(test)',
      ho_name: '102호',
      trade_type: '매매',
      complex_price: '7억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '아파트',
      floor_no: '13층',
      total_floor: '28층',
    },
    {
      goods_idx: 111113,
      trade_completed: true,
      is_favorite: true,
      is_onsale: true,
      traded_date: '2017.11.24',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '판교원10단지휴먼시아힐스테이트(test)',
      ho_name: '803호',
      trade_type: '매매',
      complex_price: '8억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '아파트',
      floor_no: '14층',
      total_floor: '28층',
    },
  ],

  CONTRACTED_ITEMS_LIST: [
    {
      goods_idx: 111111,
      trade_completed: true,
      is_favorite: false,
      traded_date: '2017.11.21',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '정자역엠코헤리츠(내계약1)',
      ho_name: '401호',
      trade_type: '전세',
      complex_price: '2억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '원룸(오픈형)',
      floor_no: '3층',
      total_floor: '12층',
    },
    {
      goods_idx: 111112,
      trade_completed: true,
      is_favorite: false,
      traded_date: '2017.11.23',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '판교원10단지휴먼시아힐스테이트(내계약2)',
      ho_name: '102호',
      trade_type: '매매',
      complex_price: '7억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '아파트',
      floor_no: '13층',
      total_floor: '28층',
    },
    {
      goods_idx: 111113,
      trade_completed: true,
      is_favorite: true,
      traded_date: '2017.11.24',
      image_url: 'https://s3.ap-northeast-2.amazonaws.com/dawins3data/complex/apt/101536/101536_01.jpg',
      complex_name: '판교원10단지휴먼시아힐스테이트(test)',
      ho_name: '803호',
      trade_type: '매매',
      complex_price: '8억 5,000',
      area1: '135m²',
      area2: '80m²',
      item_type: '아파트',
      floor_no: '14층',
      total_floor: '28층',
    },
  ],
  // =================== MySell =================== //
  // === 거래완료신고 ===
  // IS_TRADE_DAWIN_OR_NOT : 'IS_TRADE_DAWIN_OR_NOT', // 거래 완료 시, 다윈 중개사냐 타 중개사냐?
  ITEM_REPORT_TRADE_COMPLETE: 'ITEM_REPORT_TRADE_COMPLETE', // 거래 완료 시, 다윈 중개사냐 타 중개사냐?

  // TRADED_DAWIN_AGENT : 'TRADED_DAWIN_AGENT', // 다윈 중개사와 거래
  // TRADED_NON_DAWIN_AGENT : 'TRADED_NON_DAWIN_AGENT', // 타중개사와 거래
  ITEM_REPORT_TRADE_COMPLETE_DAWIN_AGENT: 'ITEM_REPORT_TRADE_COMPLETE_DAWIN_AGENT',
  ITEM_REPORT_TRADE_COMPLETE_NON_DAWIN_AGENT: 'ITEM_REPORT_TRADE_COMPLETE_NON_DAWIN_AGENT',

  // IS_TRADE_COMPLETE : 'IS_TRADE_COMPLETE', // 거래 완료 할건지 물어봄
  ITEM_REPORT_TRADE_COMPLETE_ASK: 'ITEM_REPORT_TRADE_COMPLETE_ASK', // 거래 완료 할건지 물어봄

  // 로딩
  ITEM_REPORT_TRADE_COMPLETE_LOADING: 'ITEM_REPORT_TRADE_COMPLETE_LOADING',
  // 완료
  ITEM_REPORT_TRADE_COMPLETE_DONE: 'ITEM_REPORT_TRADE_COMPLETE_DONE',

  // === 매물삭제 ===
  ITEM_DELETE: 'ITEM_DELETE',
  ITEM_DELETE_LOADING: 'ITEM_DELETE_LOADING',
  ITEM_DELETE_DONE: 'ITEM_DELETE_DONE',

  // === 매물등록취소 ===
  ITEM_REG_CANCEL: 'ITEM_REG_CANCEL',
  ITEM_REG_CANCEL_LOADING: 'ITEM_REG_CANCEL_LOADING',
  ITEM_REG_CANCEL_DONE: 'ITEM_REG_CANCEL_DONE',

  // === 매물재등록 ===
  ITEM_REREG: 'ITEM_REREG',
  ITEM_REREG_LOADING: 'ITEM_REREG_LOADING',
  ITEM_REREG_DONE: 'ITEM_REREG_DONE',

  // 서버에 요청할 때도 'DR1' 이런 형태의 값으로 쓰인다
  DELETE_REASON_1: 'DR1',
  DELETE_REASON_2: 'DR2',
  DELETE_REASON_3: 'DR3',
  DELETE_REASON_4: 'DR4',
  DELETE_REASON_5: 'DR5',
  DELETE_REASON_ETC: 'ETC',

  // === 인증번호등록===
  ITEM_AUTH_NUM: 'ITEM_AUTH_NUM',
  PHONE_CHANGED: 'PHONE_CHANGED',

  // =================== MyQNA =================== //

  // qna 유형, type code
  MY_QNA_CODE_ADMIN: 'GD', // 일반문의
  MY_QNA_CODE_ITEM: 'GN', // 매물문의

  MY_QNA_ANS_LIST_KEY: 'MY_QNA_ANS_LIST_KEY',
  // 일반문의의 경우, GD 코드 + FAQ 에도 사용돠는 하단 코드가 함께 들어온다 (두 코드의 구분자는 '_' 이라고 가정)
  MY_QNA_CODE_SYS_ERROR: 'FQ1',
  MY_QNA_CODE_BUYING: 'FQ2',
  MY_QNA_CODE_SELLING: 'FQ3',
  MY_QNA_CODE_MYPAGE: 'FQ4',
  MY_QNA_CODE_PAYMENT_ERROR: 'FQ5',
  MY_QNA_CODE_ETC: 'ETC',

  // =================== MyQNA_Dummy data =================== //
  DUMMY_MY_QNA_LIST: [
    {
      idx: 97,
      goods_no: 'A4113510519026574310',
      goods_idx: '397',
      goods_status: 'SB',
      goods_process_status: 'PS2',
      tbname: 'ua',
      qna_category: 'GD_FQ4',
      title: 'test',
      cont: '',
      ans: 'y',
      regdate: '2020-02-24T09:27:33.000Z',
      complex_name: '효자촌화성,럭키',
      category: 'APT',
      addr: '경기도 성남시 분당구 서현동 308',
      lastdate: '2020-02-24T09:27:33.000Z',
      regdate_format: '20.02.24',
      isActive: false,
      MY_QNA_ANS_LIST_KEY: [
        {
          qna_idx: 97,
          qna_answer_idx: 112,
          qna_answer: '더미 답변',
          reg_date: '99.12.31',
        },
      ],
    },
    {
      idx: 98,
      goods_no: 'A4113510519026574310',
      goods_idx: '397',
      goods_status: 'SB',
      goods_process_status: 'PS2',
      tbname: 'ua',
      qna_category: 'GN',
      title: 'test',
      cont: '',
      ans: 'n',
      regdate: '2020-02-24T09:27:33.000Z',
      complex_name: '효자촌화성,럭키',
      category: 'APT',
      addr: '경기도 성남시 분당구 서현동 308',
      lastdate: '2020-02-24T09:27:33.000Z',
      regdate_format: '20.02.24',
      isActive: false,
      MY_QNA_ANS_LIST_KEY: [
        {
          qna_idx: 98,
          qna_answer_idx: 1233,
          qna_answer: '1차 답변을 했다',
          broker_office_idx: 1234,
          reg_date: '99.12.31',
          qna_office_name: '더미중개사무소',
          qna_broker_name: '더미중개원',
          qna_broker_img: '이미지이미지',
        },
        {
          qna_idx: 98,
          qna_answer_idx: 1234,
          qna_answer: '재질문재질문_아직 질문이 더 있다',
          broker_office_idx: 0,
          reg_date: '99.12.31',
          qna_office_name: '더미중개사무소',
          qna_broker_name: '아직 질문이 더 있다',
          qna_broker_img: '이미지이미지',
        },
        {
          qna_idx: 98,
          qna_answer_idx: 1235,
          qna_answer: '더미더미_답변을 또 했다',
          broker_office_idx: 1234,
          reg_date: '99.12.31',
          qna_office_name: '더미중개사무소',
          qna_broker_name: '더미중개원',
          qna_broker_img: '이미지이미지',
        },
      ],
    },
  ],
};

export default constants;
