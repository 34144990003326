import CommnonSpinner from '@/components/Spinner/CommonSpinner';
import { actions } from '@/data';
import React from 'react';
import { useDispatch } from 'react-redux';

const SubmitBtn = ({ isLoading, btnText, input, setData, confirm }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (input?.length < 2) {
      dispatch(
        actions.common.alert({
          contents: '닉네임은 두 글자 이상으로 설정해주세요.',
        })
      );
    } else if (!confirm) {
      dispatch(
        actions.common.alert({
          contents: '닉네임 중복확인 버튼을 클릭해주세요.',
        })
      );
    } else {
      const callback = () => {
        dispatch(
          actions.common.alert({
            contents: '닉네임을 저장하였습니다.',
          })
        );
      };
      dispatch(actions.auth.saveNickname(input, setData, callback));
    }
  };

  return (
    <span className="button-area">
      <button type="button" className="btn module-b style-a type-fill accent-01 large-1x flex" onClick={handleSubmit}>
        {isLoading ? (
          <CommnonSpinner show={true} position="relative" zIndex={100} spinnerWrapperBackground={'transparent'} />
        ) : (
          <span className="btn-text">{btnText}</span>
        )}
      </button>
    </span>
  );
};

export default SubmitBtn;
