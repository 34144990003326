import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from '../selectors';
import { actions as allActions } from '@/data';

import apis from '@/apis';
import { STATUS } from '@/data/utils';

//OneOnOnelist
export function* getOneOnOneList() {
  try {
    const keyword = yield select(selectors.oneOnOne.getKeyword);
    const limit = yield select(selectors.oneOnOne.getLimit);
    const payload = {
      keyword: keyword ? keyword : '',
      category: 'Q',
      page: limit ? limit : '',
    };

    const response = yield call(apis.brokerPageApi.getOneOnOneList, payload);
    console.log('res:', response);
    yield put(actions.getOneOnOneListSuccess(response.rltObj, response.totalCnt));
    // yield put(actions.brokerPage.getBoardNew());
  } catch (e) {
    yield put(actions.getOneOnOneListFailure(e));
  }
}

export function* saveNewQna({ values, setLoadingStatus }) {
  try {
    setLoadingStatus && setLoadingStatus(STATUS.LOADING);
    const payload = {
      category: 'Q',
      subCategory: values.qType,
      title: values.qTitle,
      contents: values.qContents,
      images: values.images,
      // file_idx: values.attach,
    };
    const response = yield call(apis.brokerPageApi.saveNewQna, payload);
    yield put(allActions.common.alert({ contents: '1:1 문의를 등록하였습니다.' }));
    yield put(actions.setLimit('refresh', 5));
    yield put(actions.setKeyword(''));
    yield put(actions.getList());

    setLoadingStatus && setLoadingStatus(STATUS.SUCCESS);
  } catch (e) {
    setLoadingStatus && setLoadingStatus(STATUS.FAILURE);
    console.log(e);
  }
}
