import React, { useState, useEffect } from 'react';
import StyleContentsPopup from '@/publish/Front/_Component/popup/ContentsPopup';
import BuiToggle from '@/assets/js/bui.toggle.js';

const WrapperContentsPopup = ({ popupId, children, buiToggleOptions }) => {
  const [contentsPopup] = useState(
    new BuiToggle('[data-bui-toggle="contentsPopup"]', {
      reactTarget: 'html',
      reactTargetActiveClass: 'active-content-popup',
      focusin: true,
      ...buiToggleOptions,
    })
  );

  useEffect(() => {
    contentsPopup.update(popupId);
    contentsPopup.active(popupId);

    return () => {
      // if (actionAfterInactive) {
      // console.log('actionAfterInactive');
      //   actionAfterInactive();
      // }
    };
  }, []);

  return <StyleContentsPopup popupName={popupId}>{children}</StyleContentsPopup>;
};

export default WrapperContentsPopup;
