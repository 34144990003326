import * as AT from './actionTypes';

// ===================== 공통 ===================== //
export const getERROR = (state) => state?.analysis?.error;
export const getDetailSection = (state) => state?.analysis?.detailSection; // @TODO_LAYOUT detailSection 관련 다 삭제

// ===================== 지도 동작 ===================== //
export const getSelectedCategory = (state) => state?.analysis?.categorySelected;
export const getMapCenter = (state) => state?.analysis?.mapCenter;
export const getMapZoomLevel = (state) => state?.analysis?.mapZoomLevel;
// export const getMapClickedLatLng = (state) => state?.analysis?.mapClickedLatLng;

// ===================== 지도 표시 내용 ===================== //
export const getShowAnalysis = (state) => state?.analysis?.showAnalysis;
export const getShowFunctionalPopup = (state) => state?.analysis?.showFunctionalPopup;
export const getShowFunctionalPopupDesc = (state) => state?.analysis?.showFunctionalPopupDesc;
export const getDisplayList = (state) => state?.analysis?.displayList;
export const getCandidateList = (state) => state?.analysis?.candidateList;
export const getTargetCandidate = (state) => state?.analysis?.targetCandidate;

// ===================== 하단 Notice PopUp (카테고리 선택 팝업과 동일한 자리) ===================== //
export const getPopUpType = (state) => state?.analysis?.popUpType;

// ===================== 개발호재 알리기 ===================== //
export const getPositivesType = (state) => state?.analysis?.positivesType;
export const getPositivesTxt = (state) => state?.analysis?.positivesTxt;
export const getPositivesUrl = (state) => state?.analysis?.positivesUrl;

// ===================== 주간 실거래가 ===================== //
export const getWeeklyPriceList = (state) => state?.analysis?.weeklyPriceList;
export const getWeeklyPriceGeneralInfo = (state) => state?.analysis?.weeklyPriceGeneralInfo;

// ===================== 교육 ===================== //
export const getSchoolTypeList = (state) => state?.analysis?.schoolTypeList;
export const getAcademyMarkerList = (state) => state?.analysis?.academyMarkerList;
export const getIsFirstSchoolType = (state) => state?.analysis.isFirstSchoolType;
export const getSchoolInfo = (state) => state?.analysis.schoolInfo;

//랭킹
export const getRankingType = (state) => state?.analysis?.rankingType;
export const getRankingSpan = (state) => state?.analysis?.rankingSpan;
export const getRankingList = (state) => state?.analysis?.rankingList;
export const getRankingCnt = (state) => state?.analysis?.rankingCnt;
export const searchAnotherRankingForMobile = (state) => state?.analysis?.searchAnotherRankingForMobile;

//아파트 비교
export const getAptCompare = (state) => state?.analysis?.aptCompare;
export const getAptCompareResult = (state) => state?.analysis?.[AT.COMPARE_APT].getOrElse([]);
export const getAptCompareCategoryTemplate = (state) => state?.analysis?.aptCategoryTemplate;
export const getAptColor = (state) => state?.analysis?.aptColors;

//재건축 사업성
export const getMinMaxPrice = (state) => state?.analysis?.minMaxPrice; //최저가 최고가
export const getReconstructType = (state) => state?.analysis?.reconstructType;
export const getReconstructScoreDetail = (state) => state?.analysis?.reconstructScoreDetail; //상세정보
export const getReconstructInvestInfo = (state) => state?.analysis?.reconstructInvestInfo; //투자정보
export const getReconstructNearComplex = (state) => state?.analysis?.reconstructNearComplex; //주변 실거래가 리스트
export const getIsSizeMeter = (state) => state?.analysis?.isSizeMeter;

// 거래량
export const getTradeVolumeMonthRange = (state) => state?.analysis?.tradeVolumeMonthRange;

// 가격변동
export const getPriceFlucRange = (state) => state?.analysis?.priceFlucRange;
export const getPriceFlucIsSales = (state) => state?.analysis?.priceFlucIsSales;

// 일자리 수
export const getJobNumberRange = (state) => state?.analysis?.jobNumberRange;
export const getJobNumberIsCurrent = (state) => state?.analysis?.jobNumberIsCurrent;

// 개발호재
export const getPrevPositivesBuildPolygons = (state) => state?.analysis?.prevPositivesBuildPolygons;
export const getPrevPositivesReBuildPolygons = (state) => state?.analysis?.prevPositivesReBuildPolygons;
export const getPrevPositivesSubwayPolygons = (state) => state?.analysis?.prevPositivesSubwayPolygons;

export const getPositivesSelectList = (state) => state?.analysis?.positivesSelectList;
export const getIsPositivesFirstSelect = (state) => state.analysis?.isPositivesFirstSelect;

export const getReconDetailFrom = (state) => state.analysis?.reconDetailFrom;

// 추천
export const getIsRecommendPopup = (state) => state?.analysis?.isRecommendPopup;
export const getRecommendStep = (state) => state?.analysis?.recommendStep;
export const getRecommendSelectedInfo = (state) => state?.analysis?.recommendSelectedInfo;
export const getRecommendResult = (state) => state?.analysis?.recommendResult;
export const getIsRecommendResultPopup = (state) => state?.analysis?.isRecommendResultPopup;
export const getIsCompleteSelect = (state) => state?.analysis?.isCompleteSelect;
export const getCompleteSelectFilterOrd = (state) => state?.analysis?.completeSelectFilterOrd;
export const getSelectedFilterData = (state) => state?.analysis?.selectedFilterData;
