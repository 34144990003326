import { call, put } from 'redux-saga/effects';
import apis from '@/apis';
import { actions } from '@/data';

export function* getMyMembership() {
  try {
    const result = yield call(apis.paymentApi.getMyMembership);
    yield put(actions.payment.setMyMembership(result?.data));
  } catch (e) {
    console.log('error', e.message);
  }
}
