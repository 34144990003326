import React from 'react';
import { PostDate, WriterInfo } from '@/combinedComp/Community/sharedComponents';

const CommentWriterInfo = ({ commentData, deletedId, isOwner, parentData }) => {
  return (
    <>
      {commentData?.status === 'DELETED' || commentData?.status === 'BLINDED' || deletedId?.indexOf(commentData?.id) > -1 ? null : (
        <p className="data-list">
          <WriterInfo data={commentData} isOwner={isOwner} parentData={parentData} />
          <PostDate data={commentData} />
        </p>
      )}
    </>
  );
};

export default CommentWriterInfo;
