import * as AT from './actionTypes';
import Remote from '@/data/remote';
import { failure, loading, success } from '@/data/utils';

export const MG_CUSTOMER = {
  keyword: '',
  mgCustomerList: {},
};

export default function mgCustomer(state = MG_CUSTOMER, action = {}) {
  switch (action.type) {
    case AT.GET_MG_CUSTOM: {
      return {
        ...state,
      };
    }

    case AT.SET_MG_CUSTOM_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }

    case AT.SET_EDIT_CUSTOMINFO: {
      return {
        ...state,
        editInfo: action.item,
      };
    }
    case AT.DELETE_CUSTOMINFO: {
      return {
        ...state,
        delInfo: action.item,
      };
    }
    case AT.EDIT_CP_CUSTOMINFO: {
      return {
        ...state,
        editInfo: { ...state.editInfo, mbr_cp: action.cp },
      };
    }
    case AT.EDIT_NAME_CUSTOMINFO: {
      return {
        ...state,
        editInfo: { ...state.editInfo, cust_name: action.name },
      };
    }

    case AT.SET_NEW_MEMO_CONTENT: {
      return {
        ...state,
        newMemo: { ...state.newMemo, newmemo: action.content },
      };
    }
    case AT.SET_NEW_MEMO_CUSTOMER_IDX: {
      return {
        ...state,
        newMemo: { ...state.newMemo, customerIdx: action.customerIdx },
      };
    }
    case AT.SET_NEW_CUSTOM_CP: {
      return {
        ...state,
        newCustom: { ...state.newCustom, cp: action.cp },
      };
    }
    case AT.SET_NEW_CUSTOM_NAME: {
      return {
        ...state,
        newCustom: { ...state.newCustom, name: action.name },
      };
    }
    case AT.SET_NEW_CUSTOM_CONTENT: {
      return {
        ...state,
        newCustom: { ...state.newCustom, content: action.content, goods_idx: action.goods_idx },
      };
    }

    case AT.SET_EDIT_MEMO: {
      return {
        ...state,
        editmemo: { ...state.editmemo, content: action.content },
      };
    }
    case AT.EDIT_MEMO: {
      return {
        ...state,
      };
    }
    case AT.SET_MEMO_INFO: {
      return {
        ...state,
        editmemo: { ...state.editmemo, goodsIdx: action.goodsIdx, idx: action.memoIdx },
      };
    }

    case AT.SET_SEL_CUSTOM: {
      return {
        ...state,
        selcustom: action.item,
      };
    }

    case loading(AT.GET_SEL_CUSTOM):
      return { ...state, [AT.GET_SEL_CUSTOM]: Remote.Loading };
    case success(AT.GET_SEL_CUSTOM):
      return { ...state, selList: action.selList };
    case failure(AT.GET_SEL_CUSTOM):
      return {
        ...state,
        [AT.GET_SEL_CUSTOM]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.EDIT_CUSTOMINFO):
      return { ...state, [AT.EDIT_CUSTOMINFO]: Remote.Loading };
    case success(AT.EDIT_CUSTOMINFO):
      return { ...state, [AT.EDIT_CUSTOMINFO]: Remote.Success(true) };
    case failure(AT.EDIT_CUSTOMINFO):
      return {
        ...state,
        [AT.EDIT_CUSTOMINFO]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.REG_NEW_CUSTOM):
      return { ...state, [AT.REG_NEW_CUSTOM]: Remote.Loading };
    case success(AT.REG_NEW_CUSTOM):
      return { ...state, [AT.REG_NEW_CUSTOM]: Remote.Success(true) };
    case failure(AT.REG_NEW_CUSTOM):
      return {
        ...state,
        [AT.REG_NEW_CUSTOM]: Remote.Failure(action.error),
        error: action.error,
      };

    case loading(AT.GET_MG_CUSTOM):
      return { ...state, [AT.GET_MG_CUSTOM]: Remote.Loading };
    case success(AT.GET_MG_CUSTOM):
      return { ...state, mgcList: action.mgcList };
    case failure(AT.GET_MG_CUSTOM):
      return {
        ...state,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
