import { call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as rootActions from '../rootActions';
import * as rootSelectors from '../rootSelectors';
import * as selectors from './selectors';
import apis from '@/apis';
import text from '@/resources/text';
import CONSTANTS from '../../pages/Analysis/constants';
import { COLORS } from './helpers';
import { STATUS } from '@/data/utils';
import { eduDataStandardiztion } from '../buying/detail/utils';
import { objectToQuerystring, parseCallBack } from '@/services/Helper';
import { getDisplayListMapPayload } from './services';

// const TZ = 'Asia/Seoul';
// @note google 스프레트시트 <프론트_요청사항> idx:3
export function* getCandidateListSearch({ searchWord }) {
  try {
    const payload = {
      keyword: searchWord,
    };

    const { list } = yield call(apis.analysisApi.getCandidateListSearch, payload);
    if (!list) {
      yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_ERROR }));
      yield put(actions.setCandidateListSearch([]));
    } else {
      yield put(actions.setCandidateListSearch(list));
    }
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_ERROR }));
  }
}

// 분석 데이터 조회
export function* getDisplayListMap({ setStates, callback, map, dataType: _dataType, passedData = {} }) {
  try {
    if (setStates) setStates(STATUS.LOADING);

    let bounds, zoomLevel;

    const dataType = _dataType ? _dataType : yield select(selectors.getSelectedCategory);
    const tradeVolumeMonthRange = yield select(selectors.getTradeVolumeMonthRange);
    const priceFlucRange = yield select(selectors.getPriceFlucRange);
    const priceFlucIsSales = yield select(selectors.getPriceFlucIsSales);
    const jobNumberRange = yield select(selectors.getJobNumberRange);
    const jobNumberIsCurrent = yield select(selectors.getJobNumberIsCurrent);
    const globalSchoolTypeList = yield select(selectors.getSchoolTypeList);
    const positivesSelectList = yield select(selectors.getPositivesSelectList);
    const prevPositivesBuildPolygons = yield select(selectors.getPrevPositivesBuildPolygons);
    const prevPositivesReBuildPolygons = yield select(selectors.getPrevPositivesReBuildPolygons);
    const prevPositivesSubwayPolygons = yield select(selectors.getPrevPositivesSubwayPolygons);

    if (map) {
      const sw = map.getBounds().getSouthWest();
      const ne = map.getBounds().getNorthEast();
      bounds = [sw.getLng(), sw.getLat(), ne.getLng(), ne.getLat()];
      zoomLevel = map.getLevel();
    } else {
      bounds = yield select(rootSelectors.buying.getBounds);
      zoomLevel = yield select(rootSelectors.buying.getZoomlevel);
    }
    // 좌표가 없으면 조회 X
    if (!bounds || bounds?.length === 0) {
      throw Error('좌표없음');
    }

    const payload = yield getDisplayListMapPayload({
      dataType,
      bounds,
      zoomLevel,
      passedData,
      tradeVolumeMonthRange,
      priceFlucRange,
      priceFlucIsSales,
      jobNumberRange,
      jobNumberIsCurrent,
      globalSchoolTypeList,
      positivesSelectList,
      prevPositivesBuildPolygons,
      prevPositivesReBuildPolygons,
      prevPositivesSubwayPolygons,
    });

    const res = yield call(apis.analysisApi.getDisplayListMap, payload);

    const result = res?.list || [];

    if (callback) {
      callback(result);
    } else {
      yield put(actions.setDisplayListMap(result));
    }

    yield put(rootActions.common.toggleGlobalSpinner(false));
    if (setStates) setStates(STATUS.SUCCESS);
  } catch (e) {
    console.debug(e.message);
    yield put(rootActions.common.toggleGlobalSpinner(false));
    if (setStates) setStates(STATUS.FAILURE);
    if (e.message !== '좌표없음') {
      yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_ERROR }));
    }
  }
}

// ===================== 개발호재 알리기 ===================== //

// @note google 스프레트시트 <프론트_요청사항> idx:1
export function* postAddPositives() {
  try {
    const payload = {
      si_di_code: yield select(rootSelectors.selling.getSiDoCode),
      si_gun_gu_code: yield select(rootSelectors.selling.getSiGunGuCode),
      dong_ri_code: yield select(rootSelectors.selling.getDongRiCode),
      positivesType: yield select(selectors.getPositivesType),
      positivesTxt: yield select(selectors.getPositivesTxt),
      positivesUrl: yield select(selectors.getPositivesUrl),
    };

    yield call(apis.analysisApi.postAddPositives, payload);
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_POST_POSITIVES_ERROR }));
  }
}
// ===================== 주간 실거래가 ===================== //
// @note google 스프레트시트 <프론트_요청사항> idx:7
export function* getWeeklyPriceInfo({ addressCode }) {
  try {
    const dongCode = yield select(rootSelectors.selling.getDongRiCode);
    const payload = {
      // si_di_code: yield select(rootSelectors.selling.getSiDoCode),
      // si_gun_gu_code: yield select(rootSelectors.selling.getSiGunGuCode),
      // dong_ri_code: yield select(rootSelectors.selling.getDongRiCode),
      // startDate: sd_formatted,
      // endDate: ed_formatted,
      dongCode: addressCode || dongCode,
    };

    const weeklyPrice = yield call(apis.analysisApi.getWeeklyPriceInfo, payload);

    // let generalInfo = {};
    // let list = [];
    // const { generalInfo, list } = yield call(apis.analysisApi.getWeeklyPriceInfo, payload);
    // const weeklyPrice = yield call(apis.analysisApi.getWeeklyPriceInfo, payload);

    // 2021-11-26 배지율 수정
    // 2.3 기획에 따라, 주간실거래가가 단독 화면에서 -> 여러 데이터가 함께 보여지는 지역분석 안으로 들어감
    // 따라서 0건인 경우에 alert 대신 마크업으로 정보를 찍어주는 형대로 변경
    // if (weeklyPrice == null || (weeklyPrice?.rltObj?.listData || []).length === 0) {
    if (weeklyPrice == null) {
      // data(weeklyPrice) == null
      const isMobile = yield select(rootSelectors.common.isMobile);
      if (!isMobile) {
        yield put(rootActions.analysis.setWeeklyPriceGeneralInfo({}));
        yield put(rootActions.analysis.setWeeklyPriceList([]));
      }

      return;
    }

    if (weeklyPrice) {
      const list = weeklyPrice?.rltObj?.listData;
      // console.log('getWeeklyPrice()', list);
      const generalInfo = {
        charterCnt: weeklyPrice?.rltObj?.charterCnt,
        dongName: weeklyPrice?.rltObj?.dongName,
        startDay: weeklyPrice?.rltObj?.startDay,
        endDay: weeklyPrice?.rltObj?.endDay,
        sellCnt: weeklyPrice?.rltObj?.sellCnt,
        weeklyNo: weeklyPrice?.rltObj?.weeklyNo,
      };

      if (!generalInfo) {
        // yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_WEEKLY_PRICE_ERROR }));

        yield put(actions.setWeeklyPriceGeneralInfo({}));
      } else {
        yield put(actions.setWeeklyPriceGeneralInfo({}));
        yield put(actions.setWeeklyPriceGeneralInfo(generalInfo));
      }

      if (!list) {
        yield put(actions.setWeeklyPriceList([]));
      } else {
        if (list?.length === 0) {
          yield put(actions.setWeeklyPriceList([]));
        } else {
          yield put(actions.setWeeklyPriceList([]));
          yield put(actions.setWeeklyPriceList(list));
        }
      }
    } else {
    }
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_WEEKLY_PRICE_ERROR }));
  }
}

// 랭킹
export function* getRankingList({ addressCode }) {
  try {
    yield put(actions.setRankingResultList(null));
    const rankingType = yield select(selectors.getRankingType);
    const rankingSpan = yield select(selectors.getRankingSpan);
    const guCode = addressCode ? addressCode : yield select(rootSelectors.selling.getSiGunGuCode);
    const month = CONSTANTS.CATEGORY_NEED_PERIOD_PREFIX.indexOf(rankingType) > -1 ? rankingSpan : '';
    const school = rankingType == CONSTANTS.RANKING_SCHOOL ? rankingSpan : '';

    if (rankingType) {
      const payload = { gubun: rankingType, guCode: guCode, selMonth: month, selSchool: school };
      const result = yield call(apis.analysisApi.getRankingList, payload);

      const queryObject = parseCallBack(location.search);

      const queryData = {
        ...queryObject,
        analysisType: 'ranking',
        type: rankingType,
      };

      // 구 코드
      if (guCode) queryData.address = typeof guCode === 'object' ? guCode.join('-') : guCode;
      // 기간
      if (month) queryData.month = month;
      else delete queryData.month;
      // 학교 타입
      if (school) queryData.school = school;
      else delete queryData.school;

      const path = location.pathname + objectToQuerystring(queryData) + (location.search.indexOf('socialshare') > -1 ? '&socialshare' : '');

      yield put(rootActions.router.push(path));
      yield put(actions.setRankingResultList(result.rltObj));
    }
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_RANKING_ERROR }));
  }
}

// 랭킹
export function* getRankingList_OLD() {
  try {
    yield put(actions.setRankingResultList(null));
    const gubun = yield select(selectors.getRankingType);
    const rankingSpan = yield select(selectors.getRankingSpan);
    const guCode = yield select(rootSelectors.selling.getSiGunGuCode);
    const month = gubun != CONSTANTS.RANKING_SCHOOL ? rankingSpan : '';
    const school = gubun == CONSTANTS.RANKING_SCHOOL ? rankingSpan : '';

    if (gubun) {
      const payload = { gubun: gubun, guCode: guCode, selMonth: month, selSchool: school };
      const result = yield call(apis.analysisApi.getRankingList, payload);

      const queryData = {
        type: gubun,
      };
      if (guCode) queryData.address = typeof guCode === 'object' ? guCode.join('-') : guCode;
      if (month) queryData.month = month;
      if (school) queryData.school = school;

      const path = '/analysis/ranking' + objectToQuerystring(queryData) + (location.search.indexOf('socialshare') > -1 ? '&socialshare' : '');

      if (location.search === '') {
        yield put(rootActions.router.push(path));
      } else {
        yield put(rootActions.router.replace(path));
      }

      // if (!result.rltObj) {
      //   yield put(actions.analysisRequestError({ error: '조건에 해당하는 랭킹 정보가 없습니다.' }));
      //   yield put(actions.setRankingResultList(null));
      // } else {
      yield put(actions.setRankingResultList(result.rltObj));
      // }
    }
  } catch (e) {
    console.debug(e.message);
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_RANKING_ERROR }));
  }
}

export function* compareApt({ aptList }) {
  try {
    yield put(actions.compareAptLoading());
    const result = yield call(apis.analysisApi.getComplexCompare, { complexs: aptList.join('|') });
    const categoryTemplate = result?.categoryObj;
    const list = result?.rltObj;
    const tempColors = {};
    for (let i = 0; i < list.length; i++) {
      tempColors[list[i].tb_cb_complex_idx] = COLORS[i];
    }
    yield put(actions.setCompareAptCategoryTemplate(categoryTemplate));
    yield put(actions.setAptColor(tempColors));

    yield put(actions.compareAptSuccess(result?.rltObj));
  } catch (e) {
    yield put(actions.compareAptFailure(e.message));
  }
}

//재건축 사업성
export function* getMinMaxPrice({ complex_idx }) {
  try {
    const data = yield apis.analysisApi.getMinMaxPrice(complex_idx);
    yield put(actions.setMinMaxPrice(data));
  } catch (e) {
    yield put(actions.setMinMaxPrice(null));
  }
}

export function* getReconstructScoreDetail({ complex_idx }) {
  try {
    const detail = yield apis.analysisApi.getReconstructScoreByIdx(complex_idx);
    yield put(actions.setReconstructScoreDetail(detail?.rltObj?.[0]));
  } catch (e) {
    yield put(actions.setReconstructScoreDetail(null));
  }
}

export function* getReconstructInvestInfo({ complex_idx }) {
  try {
    const areaList = yield apis.buyingApi.getAreaList(complex_idx); //평형

    let areaNo = 1;
    (areaList.list || []).map((area, index) => {
      if (area?.pyeong === areaList.rep) {
        // areaNo = index + 1;
        areaNo = area['area_no'];
      }
    });

    // console.log('-------------------------');
    // console.log(areaList);
    // console.log(areaList.rep);
    // console.log(areaNo);
    // console.log('-------------------------');

    yield put(rootActions.buying.detail.changeSize(areaList.rep));
    yield put(rootActions.buying.detail.setAreaList(areaList.list));

    // console.log('평형', areaList.rep);
    const data = { complexIdx: complex_idx, areaNo: areaNo }; //areaList?.rep };
    const investData = yield apis.analysisApi.getReconstructInvestInfo(data);
    yield put(actions.setReconstructInvestInfo(investData?.rltObj?.[0]));

    // console.log('투자데이터', investData?.rltObj);
    const tradeData = yield apis.analysisApi.getReconstructNearComplex(data);
    yield put(actions.setReconstructNearComplex(tradeData.rltObj));
  } catch (e) {
    //yield put(actions.setReconstructScoreDetail(null));
  }
}

export function* getReconstructInvestInfoWithSelectChange({ complex_idx, selectedSize }) {
  try {
    const areaList = yield select(rootSelectors.buying.detail.getAreaList);
    // yield put(rootActions.buying.detail.setAreaList(areaList.list));

    let areaNo = 1;
    (areaList || []).map((area, index) => {
      if (area?.pyeong === selectedSize) {
        // areaNo = index + 1;
        areaNo = area['area_no'];
      }
    });

    // console.log('areaNo', areaNo);
    // console.log('평형', areaList.rep);
    const data = { complexIdx: complex_idx, areaNo: areaNo }; //areaList?.rep };
    const investData = yield apis.analysisApi.getReconstructInvestInfo(data);
    yield put(actions.setReconstructInvestInfo(investData?.rltObj?.[0]));
    // console.log('rowInvest', investData?.rltObj?.[0]);

    // console.log('투자데이터', investData?.rltObj);
    const tradeData = yield apis.analysisApi.getReconstructNearComplex(data);
    yield put(actions.setReconstructNearComplex(tradeData.rltObj));
  } catch (e) {
    //yield put(actions.setReconstructScoreDetail(null));
  }
}

export function* getSchoolWithDetail({ dongcode, schoolName }) {
  try {
    // import * as buyingActions from '../buying/actions';
    // import * as detailActions from '../buying/detail/actions';
    const result = yield call(apis.buyingApi.getSchoolInfoWithKakao, { gubun: 'S', dongcode, keyword: schoolName.replace(/ /gi, '') });
    const data = eduDataStandardiztion('S', result);
    if (data.detail) {
      yield put(rootActions.buying.detail.setSchoolInfoWithKakao(data));
      // console.log(result?.list?.[0]);
      yield put(rootActions.buying.detail.setSchoolDetail(result?.list?.[0]));
    } else {
      yield put(rootActions.common.alert({ contents: '해당 시설은 상세정보가 없습니다.' }));
    }
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_GET_SCHOOL_INFO_ERROR }));
  }
}

export function* postReport({ complexIdx, report_type, contents, report_url, sigungu_code, positive_type }) {
  try {
    const data = yield call(apis.analysisApi.postReport, {
      complexIdx,
      contents,
      report_type,
      report_url,
      sigungu_code,
      positive_type,
    });
    if (data?.rltData) {
      yield put(
        rootActions.common.alert({
          contents:
            report_type === 'RE'
              ? text.analysisText.RECONN_POST_REPORT_SUCCESS
              : report_type === 'CO'
              ? text.analysisText.COMPARE_POST_REPORT_SUCCESS
              : text.analysisText.POSITIVE_POST_REPORT_SUCCESS,
        })
      );
      yield put(rootActions.router.goBack());
    }
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_POST_REPORT }));
  }
}

export function* reqGetRecommend({ sigunguCode, minSupplyPyeong, maxSupplyPyeong, budget, buying, living, kids, prefer }) {
  try {
    // console.log('sigunguCode', sigunguCode);
    // console.log('minSupplyPyeong', minSupplyPyeong);
    // console.log('maxSupplyPyeong', maxSupplyPyeong);
    // console.log('budget', budget);
    // console.log('buying', buying);
    // console.log('living', living);
    // console.log('kids', kids);
    // console.log('prefer', prefer);

    const data = yield call(apis.analysisApi.getRecommend, {
      sigunguCode,
      minSupplyPyeong,
      maxSupplyPyeong,
      budget,
      buying,
      living,
      kids,
      prefer,
    });

    if (data?.rltObj) {
      if (Array.isArray(data?.rltObj) && data?.rltObj.length > 0) {
        // console.log('RESULT', data?.rltObj);
        yield put(rootActions.analysis.setRecommendResult(data?.rltObj));
        // yield put(rootActions.analysis.setIsRecommendResultPopup(true));
      }
    } else {
      // yield put(rootActions.analysis.setIsRecommendResultPopup(false));
      yield put(
        rootActions.common.alert({
          contents: '추천 아파트가 없습니다.',
        })
      );
    }
    // console.log('GET_RECOMMEND_RESULT', data?.rltData)
    // https://api-dev.dawinapps.com/v1/analysis/recommended_complex?sigunguCode=11110%7C11680&minSupplyPyeong=20&maxSupplyPyeong=30&budget=150000&buying=true&living=true&kids=false&prefer=2%7C3%7C4%7C5%7C9
  } catch (e) {
    yield put(actions.analysisRequestError({ error: text.analysisText.ANALYSIS_REQUEST_RECOMMEND }));
  }
}
