import { useSelector } from 'react-redux';
import { selectors } from '@/data';

const useCheckIsOwner = ({ complexId, writerId }) => {
  // 이 작성자가 단지를 소유한 사람인지 redux 저장된 정보로 참고해서 판단
  const complexHistoryByUser = useSelector(selectors.user.complexHistoryByUser);
  const isOwner = complexHistoryByUser?.[writerId]?.[complexId];

  return isOwner;
};

export default useCheckIsOwner;
