import apis from '@/apis';
import { store, history } from '@/data/configureStore';
import * as actions from '@/data/rootActions';
import { STEPS } from '@/pages/Login/BasicForm/helpers';
import GA4 from '@/services/GA';
import { logEvent, appLogEvent } from '@/services/Logger';

export const defaultRoutingAfterLogin = ({ path, isBroker, isApp }) => {
  const { dispatch } = store;

  if (isApp) {
    // else if (path === '/login/naver/iosCallback' || path === '/login/naver/androidCallback' || path.indexOf('/login/apple') > -1) {
    // 2021-04-07
    // go(-2) 는 redirect 처럼 동작함..추가확인필요
    // saga도 끝까지 실행X, 요청중이던 api도 응답 안받고 중간에 끝남
    // 네이버 카카오 일반 로그인 모바일 웹 모두 따로 테스트 필요
    // dispatch(actions.router.go(-2));
    dispatch(actions.router.replace('/'));
  } else if (path === '/login' && !isBroker) {
    dispatch(actions.router.goBack());
  }
};

export const checkMemberRequiredData = ({ contents, onSuccess } = {}) => {
  try {
    const { dispatch, getState } = store;
    const state = getState();
    const userInfo = state?.auth?.userSession || {};

    const mbr_phone = userInfo?.mbr_phone || userInfo?.mbr_cp;

    if (mbr_phone && mbr_phone !== 'no') {
      if (onSuccess) onSuccess();
      return true;
    } else {
      dispatch(
        actions.common.alert({
          contents,
          onConfirm: () => {
            const isMobile = state?.common?.isMobile;

            apis.authApi.checkMemberRequiredData(userInfo?.mbr_idx).then((result) => {
              if (onSuccess) dispatch(actions.common.actionAfterLogin(onSuccess));
              redirectToPhoneConfirm({ result, isMobile, dispatch });
            });
          },
          confirmButtonText: '확인',
          strictClose: true,
        })
      );
      return false;
    }
  } catch (e) {
    //
    return false;
  }
};

const redirectToPhoneConfirm = ({ result, isMobile, dispatch }) => {
  // 휴대폰 인증 단계에 필요한 state들
  dispatch(actions.auth.setAuthToken(result.socialToken));
  // phoneNumberAuthOnly: 오직 휴대폰 인증 단계 만 거친다는 의미의 flag
  dispatch(actions.auth.phoneNumberAuthOnly(true));
  // step: 휴대폰 인증 첫 단계(social2)
  // 휴대폰 인증 단계는 sms1,2..도 있지만 이 경우에 해당하지 않음.
  dispatch(actions.auth.setLoginStep(STEPS.SOCIAL2));

  // 로그인 화면으로 이동
  if (isMobile) {
    history.push(`/login`);
  } else {
    dispatch(actions.common.toggleLoginPopup(true));
    history.push(`${location.pathname}`);
  }
};

export const sendLoginLogs = ({ isApp, isSignUpUser, data }) => {
  // 회원가입한 경우
  if (isSignUpUser) {
    // 로그
    logEvent({
      logType: 'SIGN_UP',
      from: [],
      action: 'SIGN_UP',
      params: { type: data.userInfo?.social_type || '', userId: data.userInfo?.mbr_idx },
    });

    window.dawin_instance.bridge?.loggingFunction.sendSignUpLog(data.userInfo?.social_type);

    GA4.event('conversion', {
      send_to: 'AW-443108036/PmgECNSpjocZEMSVpdMB',
      category: 'category_conversion_event_signup',
      label: 'label',
      userId: data.userInfo?.mbr_idx,
      socialType: data.userInfo?.social_type,
    });
  }

  // 앱 로그 - 로그인 이력
  if (isApp) {
    appLogEvent({ key: 'login_step_success' });
    window.dawin_instance.bridge?.loggingFunction.setSignInUser(data.userInfo?.social_type, data.userInfo?.mbr_idx);
  }
};
